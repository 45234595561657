import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import "./productdetailsmodal.css";
import ProductDetails from "../../ProductDetails/ProductDetails";

export default function DescriptionModal({ open, setOpen, setSnack }) {
  const [data, setData] = useState({});

  const handleClose = () => {
    setOpen(false);
    setData({});
  };

  useEffect(() => {
    setData(open);
  }, [open]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="proddetails__desc_modal product_view_modal_wrap">
          {/* <div className="proddetails__desc_modalheading">Product Details</div> */}
          <div className="proddetails__desc_modalcontent">
            <ProductDetails data={data} />
          </div>
          {/* <div className="proddetails__desc_modalfooter">
            <div className="proddetails__desc_modalcncl" onClick={handleClose}>
              Close
            </div>
          </div> */}
        </div>
      </Modal>
    </div>
  );
}
