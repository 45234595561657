import axios from "./axiosInstance";

export const registerUser = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`register`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const registerWarehouseClient = async (id, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`warehouse/create-shipper-user/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const signinUser = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`login`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const logoutUser = async () => {
  let response = { data: null, error: null };
  const res = await axios.post(`logout`);
  try {
    if (res.data?.status) {
      response = { ...response, data: res.data };
    } else {
      response = {
        ...response,
        error: res.data.message,
        errorMessage: res.data?.message,
      };
    }
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const generateCode = async (data) => {
  let response = { data: null, error: null };
  const res = await axios.post(`forget/password`, data);
  try {
    if (res.data?.status) {
      response = { ...response, data: res.data };
    } else {
      response = {
        ...response,
        error: res.data.message,
        errorMessage: res.data?.message,
      };
    }
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const resetPassword = async (data) => {
  let response = { data: null, error: null };
  const res = await axios.post(`forget/change-password`, data);
  try {
    if (res.data?.status) {
      response = { ...response, data: res.data };
    } else {
      response = {
        ...response,
        error: res.data.message,
        errorMessage: res.data?.message,
      };
    }
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const refreshUser = async (token) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`me`, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
