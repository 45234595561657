import React, { useState } from "react";
import "./blogs.css";

// components imports
import BlogssTable from "../../../../../Components/BoltTable/BoltTable";
import BlogDetails from "./Components/BlogDetails/BlogDetails";
import UpdateBlog from "./Components/AddBlog/UpdateBlog";
import Confirm from "../../../../../Components/Popups/Confirm";
import AddBlog from "../Blogs/Components/AddBlog/AddBlog";
import Tooltip from "../../../../../Components/Tooltip/Tooltip";
import SwitchButton from "../../../../../Components/SwitchButton/SwitchButton";
// apis imports
import {
  fetchAllBlogs,
  deleteBlog,
  updateBlogStatus,
} from "../../../../../services/admin.services";
// library imports
import { FcPlus } from "react-icons/fc";
import { FaRegEdit } from "react-icons/fa";
import noImage from "../../../../../assets/images/noImage.png";
import { MdRemoveRedEye, MdOutlineCancel } from "react-icons/md";

const Blogs = ({ setSnack }) => {
  const [statusUpdating, setStatusUpdating] = useState();

  const [activeBlogs, setActiveBlogs] = useState(0);
  const [blogs, setBlogs] = useState([]);

  const [blogDetailsModal, setBlogDetailsModal] = useState();
  const [updateBlogModal, setUpdateBlogModal] = useState();
  const [confirmBox, setConfirmBox] = useState();
  const [addBlogModal, setAddBlogModal] = useState();
  const [refresh, setRefresh] = useState();
  const [loader, setloader] = useState();

  const columns = [
    {
      id: 1,
      label: "Image",
      render: (row) => (
        <>
          <img
            src={row?.thumb_img ?? noImage}
            onError={(e) => {
              e.target.src = noImage;
            }}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 2,
      label: "Title",
      render: (row) => <>{row?.title ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Slug",
      render: (row) => <>{row?.slug ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "Active",
      render: (row) => (
        <>
          <SwitchButton
            onChange={(e) => handleStatusChange(e, row)}
            checked={[true, "true", "1"].includes(row.is_approved)}
            disabled={statusUpdating}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 5,
      label: "Body",
      render: (row) => <>{row?.body ?? "-"}</>,
      width: "300px",
    },
    {
      id: 6,
      label: "Action",
      render: (row) => (
        <>
          <div className="table_action__wrap">
            <Tooltip title="View User Details">
              <div
                className="table_action__btn"
                onClick={() => setBlogDetailsModal(row)}
              >
                <MdRemoveRedEye size={10} />
                View
              </div>
            </Tooltip>
            <Tooltip title="Edit Blog Details">
              <div
                className="table_action__btn"
                onClick={() => setUpdateBlogModal(row)}
              >
                <FaRegEdit size={10} />
                Edit
              </div>
            </Tooltip>
            <Tooltip title="Delete Blog">
              <div
                className="table_action__btn"
                onClick={() => setConfirmBox(row)}
              >
                <MdOutlineCancel size={10} />
                Delete
              </div>
            </Tooltip>
          </div>
        </>
      ),
      width: "300px",
    },
  ];

  const fetchBlogs = async (page, perPage, filter) => {
    const response = await fetchAllBlogs(page, perPage, filter);
    if (!response.error) {
      setBlogs(response?.data?.result?.blog);
      setActiveBlogs(response?.data?.result?.count ?? 0);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const handleDeleteBlog = async (blog) => {
    setloader(true);
    let id = blog.id;
    const deleted = await deleteBlog(id);
    if (deleted.error) {
      setloader(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Couldn't delete this blog.",
          severity: "error",
        };
      });
    } else {
      setActiveBlogs(
        [true, "true", "1"].includes(blog?.is_approved)
          ? activeBlogs - 1
          : activeBlogs
      );
      setBlogs((prevBlogs) => {
        return {
          ...prevBlogs,
          data: prevBlogs?.data.filter((item) => item.id !== id),
        };
      });
      setloader(false);
      setConfirmBox(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Blog deleted successfully",
          severity: "success",
        };
      });
    }
  };

  const handleStatusChange = async (e, record) => {
    setStatusUpdating(true);

    const updated = await updateBlogStatus({
      id: parseInt(record.id),
      is_approved: e.target.checked,
    });

    if (updated.error) {
      setStatusUpdating(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: updated.error.message,
          severity: "error",
        };
      });
    } else {
      setStatusUpdating(false);
      setActiveBlogs(updated?.data?.result?.count);
      setBlogs((prevBlogs) => {
        return {
          ...prevBlogs,
          data: prevBlogs?.data.map((item) =>
            item.id === record.id
              ? {
                  ...item,
                  is_approved: [true, "true", "1"]?.includes(
                    updated?.data?.result?.blog?.is_approved
                  ),
                }
              : item
          ),
        };
      });
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `Status of blog changed to ${
            [true, "true", "1"].includes(
              updated?.data?.result?.blog?.is_approved
            )
              ? "Active"
              : "Inactive"
          }`,
          severity: [true, "true", "1"].includes(
            updated?.data?.result?.blog?.is_approved
          )
            ? "success"
            : "info",
        };
      });
    }
  };
  return (
    <>
      <div className="blogs_header">
        <div>
          <div className="blogs_badge">
            <div className="blogs_badgecount">{activeBlogs}</div>
            <div className="">Active Blogs</div>
          </div>
        </div>
        <div className="blogs__adpage_buttonsWrapper">
          <div onClick={() => setAddBlogModal(true)}>
            <div className="blogs__adpage_hdraddprod">
              <FcPlus size={20} />
              <span>ADD BLOG</span>
            </div>
          </div>
        </div>
      </div>

      <div className="blogs__main_wrap">
        <div className="blogs__table_list">
          <div>
            <BlogssTable
              message={"No Blogs Available To Display!"}
              searchPlaceHolder="Search Blogs"
              columns={columns}
              data={blogs}
              getAllData={fetchBlogs}
              refresh={refresh}
            />
          </div>
        </div>
      </div>
      <BlogDetails
        open={!!blogDetailsModal}
        blogData={blogDetailsModal}
        setOpen={setBlogDetailsModal}
      />
      <UpdateBlog
        open={!!updateBlogModal}
        blogData={updateBlogModal}
        setOpen={setUpdateBlogModal}
        setBlogs={setBlogs}
        setSnack={setSnack}
      />
      <Confirm
        open={confirmBox}
        setOpen={setConfirmBox}
        handleConfirm={() => {
          handleDeleteBlog(confirmBox);
        }}
        loader={loader}
      />
      <AddBlog
        open={addBlogModal}
        setOpen={setAddBlogModal}
        setRefresh={setRefresh}
        setSnack={setSnack}
      />
    </>
  );
};

export default Blogs;
