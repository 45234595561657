import axios from "./axiosInstance";

export const createNewDriver = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`drivers/create`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const editDriver = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`drivers/edit/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchAllDrivers = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `drivers/get?page=${page}&&perPage=${perPage}&filterValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const deleteDriver = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`drivers/delete/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const changeStatus = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`drivers/edit/${data.record_id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
