import React, { useRef, useEffect } from "react";
import "../InvoiceModal.css";
import PrintableInvoice from "./PrintableInvoice";
import html2pdf from "html2pdf.js";
import { FaRegTimesCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { MdArrowCircleDown } from "react-icons/md";
import { BsArrowRepeat } from "react-icons/bs";
import International from "./InvoiceSubComponents/International";
import ShipmentSummary from "./InvoiceSubComponents/ShipmentSummary";
import Tracking from "./InvoiceSubComponents/Tracking";
import Header from "./InvoiceSubComponents/Header";
import ShipmentDetails from "./InvoiceSubComponents/ShipmentDetails";
import Footer from "./InvoiceSubComponents/Footer";
import BillingUser from "./InvoiceSubComponents/BillingUser";
import Notes from "./InvoiceSubComponents/NotesUser";

const FullPageInvoice = ({ data, Reset }) => {
  const invoiceRef = useRef(null);
  const navigate = useNavigate();

  function handleClick() {
    navigate("/shipments");
  }

  const printLabel = async (data) => {
    try {
      fetch(data.url, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      })
        .then((url) => url.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${data.tattension + " " + data.shipment_tracking_id} Label.pdf`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
        navigate("/Login");
      } else {
        console.log(error);
      }
    }
  };

  const downloadPdf = async (data) => {
    const input = invoiceRef.current;
    const options = {
      filename: `${
        data.tattension + " " + data.shipment_tracking_id
      } Invoice.pdf`,
      format: "A4",
      jsPDF: { unit: "px", format: [830, 1000], orientation: "p" },
      image: { type: "jpeg", quality: 0.98 },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      html2canvas: { scale: 2, useCORS: true },
    };

    await html2pdf().set(options).from(input).save();
  };

  useEffect(() => {
    if (data) {
      downloadPdf(data);
      printLabel(data);
    }
  }, []);

  return (<>
  

  <div
        style={{
          zIndex: "-1000",
          position: "absolute",
          visibility: "hidden",
          left: "-1000",
        }}
      >
        <div className="fpinvoice__modal_wrap shipment__fp_invoice_print_wrap">
        <PrintableInvoice invoiceRef={invoiceRef} data={data} />
      </div>
  
  </div>
  

  <div className="fpinvoice__modal_wrap shipment__fp_invoice_custom_wrap">
   
      <div className="fpinvoice__modal_box shipment__fp_invoice_custom_box">
        <div className="invoice__modal_downloadinvoicewrap">
          {/* <AiOutlineCloudDownload
            size={25}
            color="#0c69f3"
            onClick={downloadPdf}
          /> */}
          {/* <img src={Download} width="25px" onClick={downloadPdf} /> */}
          <button
            onClick={() => downloadPdf(data)}
            className="invoicemdl__download_btn"
          >
           <MdArrowCircleDown size={16} />
              <div className="labeltxt">
                <span className="invoivemdl__btn_dwn_text">
                    Download
                  </span> {" "} Invoice
              </div>
          </button>
          <button
            onClick={() => printLabel(data)}
            className="invoicemdl__download_btnoutlined"
          >
            <MdArrowCircleDown size={16} />
              <div className="labeltxt">
                <span className="invoivemdl__btn_dwn_text">
                    Download
                  </span> {" "} Label
              </div>
          </button>
          <button onClick={Reset} className="invoicemdl__download_btnoutlined">
            <BsArrowRepeat size={16} />
            <div className="labeltxt">
                 Ship More
              </div>
            
          </button>
          <button
            onClick={handleClick}
            className="invoicemdl__download_btn_danger"
          >
             <FaRegTimesCircle size={16} />
              <div className="labeltxt">
                Close
              </div>
          </button>
        </div>

        <Header data={data} />
        <ShipmentSummary data={data} />
        <International data={data} />
        <ShipmentDetails data={data} />
        <BillingUser data={data} />
        <Notes data={data} />
        <Tracking data={data} />
        <Footer data={data} />
      </div>
    </div>
    </>
    
  );
};

export default FullPageInvoice;
