import TextField from "./elements/TextField";
import PlacesAutoComplete from "./elements/PlacesAutoComplete";
import Autocomplete from "./elements/AutoComplete";
import AddressFormCard from "./elements/AddressFormCard";

function AddNewProductForm(props) {
  const { List, SetList, StepCheck, SetStepCheck, Flist } = props;

  const handlechange = (e) => {};

  return (
    <>
      <div className="productShipment__main_wrap">
        <div className="productShipment__main_pickup">
          <AddressFormCard StepCheck={""} SetStepCheck={""} Type={"Flist"}>
            <div className="fullname__main_wrap">
              <PlacesAutoComplete
                List={""}
                HandleAddressSelect={""}
                HandleAddress={""}
                HandleResidential={""}
                Color={""}
                
              />
            </div>
            <div className="country__main_wrap">
             <Autocomplete Option={""} Handler={""} Value={""} Type="Country" />
            </div>
            <div className="state__main_wrap">
            <Autocomplete Option={""} Handler={""} Value={""} Type="State" />
            </div>
            <div className="city__main_wrap">
            <TextField Label="City/Town" Name="city" Handler={''} />
            </div>
            <div className="zip__main_wrap">
            <TextField Label="Zip/Postal" Name="zip" Handler={''} />
            </div>
            <div className="instruction__main_wrap">
            <TextField
              Label="Instructions"
              Name="instruction"
              Handler={''}
              Multiline={true}
              Row={2}
            /></div>
            <div className="reference__main_wrap">
            <TextField
              Label="Reference"
              Name="ref"
              Handler={''}
              Multiline={true}
              Row={2}
            /></div>
          </AddressFormCard>
        </div>
        <div className="productShipment__main_delivery">
          <AddressFormCard StepCheck={""} SetStepCheck={""} Type={"Tlist"}>
            <div className="fullname__main_wrap">
              <PlacesAutoComplete
                List={""}
                HandleAddressSelect={""}
                HandleAddress={""}
                HandleResidential={""}
                Color={""}
                
              />
            </div>
            <div className="country__main_wrap">
             <Autocomplete Option={""} Handler={""} Value={""} Type="Country" />
            </div>
            <div className="state__main_wrap">
            <Autocomplete Option={""} Handler={""} Value={""} Type="State" />
            </div>
            <div className="city__main_wrap">
            <TextField Label="City/Town" Name="city" Handler={''} />
            </div>
            <div className="zip__main_wrap">
            <TextField Label="Zip/Postal" Name="zip" Handler={''} />
            </div>
            <div className="orderId__main_wrap">
            <TextField
              Label="Order Id"
              Name="OrderId"
              Handler={''}
              Row={2}
            /></div>
          </AddressFormCard>
        </div>
      </div>
    </>
  );
}

export default AddNewProductForm;
