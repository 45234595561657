import React, { Fragment, useEffect, useState, useRef } from "react";
import DiscussionBody from "../DiscussionBody/DiscussionBody";
import {
  MdOutlineWifiCalling3,
  MdOutlineMailOutline,
  MdClose,
} from "react-icons/md";
import { useSelector } from "react-redux";
import loaderImage from "../images/dispatcher-empty.gif";
import {deleteTicketMedia, closeTicketService, updateTicketStatusService, getTicketMediaService, getTicketDetailsService} from '../../../../services/Tickets/ticket.service';
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Snackbar from "../../../SnackBar/SnackBar";
const DiscussionTickets = ({ ticketId, parentLoading, setParentLoading, setDefaultLoaderImage }) => {
  const [open, setOpen] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });
  const [change,setChange]=useState(false)
  const [ticketStatus,setTicketStatus] =useState('')
  const [ticketInfo, setTicketInfo] = useState("order");
  const [shipmentMedia, setShipmentMedia] = useState({});
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imggloading,setImggLoading]=useState(false)
  const [shipperfiles, setShipperfiles] = useState("all");
  const [isMedia,setIsMedia]=useState(false)
  const [ticketResponses, setTicketResponses] = useState([]);
  const [secondsAgo, setSecondsAgo] = useState(0);
  const user = useSelector((state) => state.user);

  //creating a reference object
  const ticketIdRef = useRef(ticketId);
  ticketIdRef.current = ticketId;
 
  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    const year = String(date.getFullYear()).slice(-2); // Get the last two digits of the year
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 to the month because it's zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes}`;

    return `${formattedDate} ${formattedTime}`;
  }

   const handleSnackClose = () => {
    setSnack({ ...snack, open: false });
  };

  // change ticket status 
  const changeTicketStatus = (e)=> {
    setTicketStatus(e.target.value)
    setChange(true)
  }

  // check if media is added in child componet to update latest media
  const checkMediaAdded = (data) => {
    setIsMedia(data);
    if(data===true) {
      getTicketMedia(ticketId)
    }
  };

  // close ticket
  const closeTicket = async() => {
    setLoading(true);

    try {
      const response = await closeTicketService(ticketId);
      if (response?.data?.success === true) {
           
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: `${response?.data?.message}`,
            severity: "success",
          };
        });
        
      }
      
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
    
  };

  //update status api
  const updateTicketStatus = async () => {
    const formData = new FormData();
    formData.append('status',ticketStatus)
    setLoading(true);
try {
  const response = await updateTicketStatusService(ticketId, formData)
  if (response?.data?.success === true) {
    setChange(false)
    setSnack((snack) => {
      return {
        ...snack,
        open: true,
        text: `${response?.data?.message}`,
        severity: "success",
      };
    });
  }
  setLoading(false);
} catch (error) {
  console.error("Error:", error);
}
    

  };

// delete media
const deleteMediaTicket =async (mediaId) => {
  setImggLoading(true);
 
try {
  const response = await deleteTicketMedia(mediaId)
  if (response?.data?.success === true) {
    getTicketMedia(ticketId);
    setImggLoading(false); 
    setIsMedia(true) 
    setSnack((snack) => {
      return {
        ...snack,
        open: true,
        text: `${response?.data?.message}`,
        severity: "success",
      };
    });
    
  }
} catch (error) {
  console.error("Error:", error);
}
 
  
};

// Media api call
const getTicketMedia = async (ticketId)=> {
  try {
    const response = await getTicketMediaService(ticketId);
    if(response?.data?.success === true){
      setShipmentMedia(response?.data?.result?.files_and_images);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }

}     
    
const getTicketDetails = async (isReloadResponses = false)=> {

  try {
    if (tickets?.status == null || tickets?.status == 'undefined')
      setDefaultLoaderImage(true);
    if (isReloadResponses == false)
      setTicketResponses([]); // empty only if the ticket gets changed

    if (ticketId != null && ticketId != 'undefined'){
      const response = await getTicketDetailsService(ticketId);
      setDefaultLoaderImage(false);
      if(response?.data?.success === true){ 
        setParentLoading(false);
        if (isReloadResponses == false)
          getTicketMedia(ticketId);
        setTickets(response.data.result.ticket);
        setTicketStatus(response.data.result.ticket?.status)
        setTicketResponses(response.data.result.ticket.ticket_responses);
      }
    }
  } catch (error) {
    setDefaultLoaderImage(false);
    console.error("Error fetching data:", error);
  }
}

const getTicketResponses = async (ticket_id)=> {
  try {           
    if (ticket_id != null && ticket_id != 'undefined' && ticket_id != '' && ticket_id > 0){
      const response = await getTicketDetailsService(ticket_id);      
      if(response?.data?.success === true){
        setTicketResponses(response.data.result.ticket.ticket_responses);
      }
    }
  } catch (error) {    
    console.error("Error fetching data:", error);
  }
}

  useEffect(() => {
    if (ticketId != null && ticketId != '')
      getTicketDetails()
  }, [ticketId]);

  const MINUTE_MS = 15000;
  useEffect(() => {
    const interval = setInterval(() => {
      getTicketResponses(ticketIdRef.current);
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  return (
    <div className="disussion_shipping_details_container">
     
    
      {tickets?.status && (
        <>
       
      <Snackbar Snack={snack} SetSnack={setSnack} />
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            // onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div className="invoices__modal_wrapper">
                <div className="allTickets__shipment_modal">
                  <div className="allTickets__shipment_modal_header">
                    <button onClick={() => setOpen(false)}>
                      <MdClose />
                    </button>
                  </div>
                  <div className="allTickets__shipment_modal_body">
                    <div className="company-info">
                      <img
                        src={
                          tickets?.shipment?.shipping_company?.shipping_company
                            ?.logo_url
                        }
                        alt=""
                      />
                      <span className="name">
                        {
                          tickets?.shipment?.shipping_company?.shipping_company
                            ?.name
                        }
                      </span>
                    </div>
                    <div className="data">
                      <div className="data-item">
                        <span className="title">Tracking id:</span>
                        <span className="value">
                          {tickets?.shipment?.tracking_id}
                        </span>
                      </div>

                      <div className="data-item">
                        <span className="title">Shipment tracking id:</span>
                        <span className="value">
                          {tickets?.shipment?.shipment_tracking_id}
                        </span>
                      </div>
                      <div className="data-item">
                        <span className="title">Shipment status:</span>
                        <span className="value">
                          {tickets?.shipment?.shipment_status}
                        </span>
                      </div>
                      <div className="data-item">
                        <span className="title">Unit:</span>
                        <span className="value">{tickets?.shipment?.unit}</span>
                      </div>
                      <div className="data-item">
                        <span className="title">Package type:</span>
                        <span className="value">
                          {tickets?.shipment?.package_type}
                        </span>
                      </div>
                      <div className="data-item">
                        <span className="title">Package volume:</span>
                        <span className="value">
                          {tickets?.shipment?.volume}
                        </span>
                      </div>
                      <div className="data-item">
                        <span className="title">Package weight:</span>
                        <span className="value">
                          {tickets?.shipment?.total_weight} lb
                        </span>
                      </div>
                      <div className="data-item">
                        <span className="title">Service type:</span>
                        <span className="value">
                          {tickets?.shipment?.service_type}
                        </span>
                      </div>

                      <div className="data-item">
                        <span className="title">Ready open time:</span>
                        <span className="value">
                          {tickets?.shipment?.readyopentime}
                        </span>
                      </div>
                      <div className="data-item">
                        <span className="title">Ready close time:</span>
                        <span className="value">
                          {tickets?.shipment?.readyclosetime}
                        </span>
                      </div>
                      <div className="data-item">
                        <span className="title">Delivery open time:</span>
                        <span className="value">
                          {tickets?.shipment?.deliveryopentime}
                        </span>
                      </div>
                      <div className="data-item">
                        <span className="title">Delivery date:</span>
                        <span className="value">
                          {tickets?.shipment?.deliverydate}
                        </span>
                      </div>
                      <div className="data-item">
                        <span className="title">Shipment date:</span>
                        <span className="value">
                          {tickets?.shipment?.shipdate}
                        </span>
                      </div>
                      <div className="data-item">
                        <span className="title">Pickup date:</span>
                        <span className="value">
                          {tickets?.shipment?.PickupDate}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>
          <div className="disussion_main_wrap">
          {loading && (
             
             <div className='loader__image_large'>
               {/* {ticketDiscussion?.data?.message}  */}
              <img src={loaderImage} className='__image' alt=""/>
             </div>
           
         )}
          {parentLoading && (
             
             <div className='loader__image_large'>
               {/* {ticketDiscussion?.data?.message}  */}
              <img src={loaderImage} className='__image' alt=""/>
             </div>
           
         )}
            <div className="disussion__main__header">
              <div className="disussion__main__header_left_col">
                <div className="disussion__header_left_title">
                  {tickets?.user?.firstname} {tickets?.user?.lastname}
                  <span>({tickets?.user?.role}) </span>
                </div>
                <div className="disussion__header_left_Id">
                  ID {tickets?.ticket_no}
                </div>
                <div className="disussion__header_left_subject">
                  {tickets?.description}
                </div>
              </div>
              <div className="disussion__main__header_right_col">
                <div className="disussion__header_right_contact">
                  <div className="disussion__header_right_email">
                    {tickets?.user?.email && (
                      <>
                        <MdOutlineMailOutline size={20} />{" "}
                        <span>{tickets?.user?.email}</span>
                      </>
                    )}
                  </div>
                  <div className="disussion__header_right_phone">
                    {tickets?.user?.phone_number && (
                      <>
                        <MdOutlineWifiCalling3 size={20} />{" "}
                        <span>{tickets?.user?.phone_number}</span>
                      </>
                    )}
                  </div>
                </div>
                
                <div className="disussion__header_right__stautses">
               
                <div>
                  <select name="" id="" value={ticketStatus} onChange={changeTicketStatus}>
                    <option value="Open">Open</option>
                    <option value="Resolved">Resolved</option>
                    <option value="Disputed">Disputed</option>
                    <option value="Closed">Closed</option>
                  </select>
                </div>
              {change && <button onClick={()=>updateTicketStatus()}>Change</button>}
               
                </div>
              
                <div className="disussion__header_right_actions">
               
                  <button onClick={() => setOpen((pre) => !pre)}>
                    View Shipment
                  </button>
                  {tickets?.status !== "Closed" && (
                    <button
                      className="close_ticket"
                      onClick={() => closeTicket()}
                    >
                      Close Ticket
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="disussion__main__body">
              <DiscussionBody 
                ticketId={ticketId}  
                checkMediaAdded = {checkMediaAdded}
                ticketResponses={ticketResponses}
                setTicketResponses={setTicketResponses}
              />
            </div>
          </div>
          <div className="disussionShipping__details_main_wrap">
            <h2 className="disussionShipping__details_heading">
              Ticket Details
            </h2>
            <ul className="disussionShipping__details_tab">
              <li
                onClick={() => setTicketInfo("order")}
                className={ticketInfo === "order" ? "active" : ""}
              >
                Order
              </li>
              <li
                onClick={() => setTicketInfo("billing")}
                className={ticketInfo === "billing" ? "active" : ""}
              >
                Billing
              </li>
            </ul>
            {ticketInfo === "order" && (
              <div className="disussionShipping__details_order_details">
                <div className="disussionShipping__details_tickenum">
                  <span className="disussionShipping__details_label">
                    Ticket No
                  </span>
                  <span className="disussionShipping__details_data">
                    {tickets?.ticket_no}
                  </span>
                </div>
                <div className="disussionShipping__details_shippingId">
                  <span className="disussionShipping__details_label">
                    Shipment ID
                  </span>
                  <span className="disussionShipping__details_data">
                    {tickets?.shipment_id}
                  </span>
                </div>
                <div className="disussionShipping__details_order_status">
                  <span className="disussionShipping__details_label">
                    Order Status
                  </span>
                  <span className="disussionShipping__details_data">
                    {tickets?.shipment?.shipment_status}
                  </span>
                </div>
                <div className="disussionShipping__details_orderplaced">
                  <span className="disussionShipping__details_label">
                    Order placed
                  </span>
                  <span className="disussionShipping__details_data">
                    {tickets?.shipment?.shipdate}
                  </span>
                </div>
                <div className="disussionShipping__details_master_tracking">
                  <span className="disussionShipping__details_label">
                    Master Tracking
                  </span>
                  <span className="disussionShipping__details_data">
                    {tickets?.shipment?.tracking_id}
                  </span>
                </div>
                <div className="disussionShipping__details_carrier_racking">
                  <span className="disussionShipping__details_label">
                    Carrier Tracking
                  </span>
                  <span className="disussionShipping__details_data">
                    {tickets?.shipment?.shipment_id}
                  </span>
                </div>
              </div>
            )}
            {ticketInfo === "billing" && (
              <div className="disussionShipping__details_billing_details_wrapper">
                {tickets?.shipment?.shipment_charges?.length > 0 &&
                  tickets?.shipment?.shipment_charges?.map((charges) => (
                    <div className="disussionShipping__details_billing_details" key={charges?.id}>
                      <div className="shipment_charges_header">
                        Shipment Id: {charges?.shipment_id}
                      </div>
                      <div className="shimpent_charges_data">
                        <span className="title">Base price</span>
                        <span className="value">{charges?.base_price}</span>
                      </div>
                      <div className="shimpent_charges_data">
                        <span className="title">Fuel charges</span>
                        <span className="value">{charges?.fuel_charge}</span>
                      </div>
                      <div className="shimpent_charges_data">
                        <span className="title">Pickup Charges</span>
                        <span className="value">{charges?.PickupCharge}</span>
                      </div>
                      <div className="shimpent_charges_data">
                        <span className="title">Residential charges</span>
                        <span className="value">
                          {charges?.residential_charge}
                        </span>
                      </div>
                      <div className="shimpent_charges_data">
                        <span className="title">Signature charges</span>
                        <span className="value">
                          {charges?.signature_charge}
                        </span>
                      </div>
                      <div className="shimpent_charges_data">
                        <span className="title">Additional price</span>
                        <span className="value">
                          {charges?.additional_price}
                        </span>
                      </div>
                      <div className="shimpent_charges_data">
                        <span className="title">Transportation charge</span>
                        <span className="value">
                          {charges?.transportation_charge}
                        </span>
                      </div>
                      <div className="shimpent_charges_data">
                        <span className="title">
                          Commercial seasonal surcharge
                        </span>
                        <span className="value">
                          {charges?.commercial_seasonal_surcharge}
                        </span>
                      </div>
                      <div className="shimpent_charges_data">
                        <span className="title">Freight charge</span>
                        <span className="value">{charges?.freight_charge}</span>
                      </div>

                      {charges?.total_updated_charges?.tax_charges?.length >
                        0 && (
                        <div className="shimpent_charges_text_charges">
                          <span className="title_main">Total tax charges</span>
                          {charges?.total_updated_charges?.tax_charges?.map(
                            (tax) => (
                              <div className="shimpent_charges_data" key={tax?.id}>
                                <span className="title">{tax?.NameEn}</span>
                                <span className="value">{tax?.Charge}</span>
                              </div>
                            )
                          )}
                        </div>
                      )}

                      <hr />
                      <div className="shimpent_charges_data">
                        <span className="title">Total</span>
                        <span className="value">{charges?.total}</span>
                      </div>
                    </div>
                  ))}
              </div>
            )}
            <div className="disussionShipping__package_details table-responsive">
              <table className="table-borderless">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Description</th>
                    <th>barcode</th>
                    <th>Size</th>
                    <th>Weight</th>
                    <th>Date/Time</th>
                  </tr>
                </thead>
                <tbody>
                  {tickets?.shipment?.shipment_details?.length > 0 &&
                    tickets?.shipment?.shipment_details?.map(
                      (shipment,index) => (
                        <tr key={shipment?.id}>
                          <td>
                            <span className="count">{index + 1}</span>
                          </td>
                          <td>{shipment?.description?.slice(0, 11)}</td>
                          <td>
                            {shipment?.barcode !== null
                              ? shipment?.barcode
                              : "NA"}
                          </td>
                          <td>
                            {shipment?.height} * {shipment?.width} *{" "}
                            {shipment?.length}{" "}
                          </td>
                          <td>
                            {shipment?.weight ? `${shipment?.weight}lb` : "NA"}
                          </td>
                          <td>
                            <div className="icon-wrapper">
                              <span>
                                {formatTimestamp(shipment?.created_at)}
                              </span>
                              {/* <span className="icon">
                       <MdCheck />
                      </span> */}
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
            <div className="disussionShipping__package_files_wrapper">
            {imggloading && (
             
             <div className='loader__image_img'>
               {/* {ticketDiscussion?.data?.message}  */}
              <img src={loaderImage} className='__image' alt=""/>
             </div>
           
         )}
              <h2 className="title">Files and images from</h2>
              <ul className="disussionShipping__package_type">
                <li
                  onClick={() => setShipperfiles("all")}
                  className={shipperfiles === "all" ? "active" : ""}
                >
                  All
                </li>
                <li
                  onClick={() => setShipperfiles("shipper")}
                  className={shipperfiles === "shipper" ? "active" : ""}
                >
                  Shipper
                </li>
                <li
                  onClick={() => setShipperfiles("recipient")}
                  className={shipperfiles === "recipient" ? "active" : ""}
                >
                  Recipient
                </li>
                <li
                  onClick={() => setShipperfiles("carrier")}
                  className={shipperfiles === "carrier" ? "active" : ""}
                >
                  Carrier
                </li>
                <li
                  onClick={() => setShipperfiles("admin")}
                  className={shipperfiles === "admin" ? "active" : ""}
                >
                  Admin 
                </li>
              </ul>
              {shipperfiles === "all" && shipmentMedia?.all?.length > 0 && (
                <div className="disussionShipping__package_files">
                  {shipmentMedia?.all?.map((media) => (
                     <Fragment key={media?.id}>
                      {media?.response_body !== null && (
                        <div className="disussionShipping__package_file_holder">
                          {media?.response_type === "image" ? (
                           <div className="image_wrapper">
                            
                        
                            {(media?.response_by === user?.id || user?.role === 'Admin') &&  <div className="icon" onClick={()=>deleteMediaTicket(media?.id) }>
                            <MdClose />
                           
                            </div>}
                             <img src={media?.response_body?.file_url} />
                            <span className="name">{media?.response_body?.file_name.slice(0,10)}</span>
                           </div>
                          ) : (
                            <div className="file_wrapper">
                               {(media?.response_by === user?.id || user?.role === 'Admin') &&  <div className="icon" onClick={()=>deleteMediaTicket(media?.id) }>
                            <MdClose />
                           
                            </div>}
                              <a
                              href={media?.response_body?.file_url}
                              target="_"
                            >{media?.response_body?.file_name.slice(0,9)}</a>
                            </div>
                          )}
                        </div>
                      )}
                    </Fragment>
                  ))}
                </div>
              )}

{shipperfiles === "shipper" && shipmentMedia?.shipper?.length > 0 && (
                <div className="disussionShipping__package_files">
                  {shipmentMedia?.shipper?.map((media) => (
                    <Fragment key={media?.id}>
                      {media?.response_body !== null && (
                        <div className="disussionShipping__package_file_holder">
                        {media?.response_type === "image" ? (
                         <div className="image_wrapper">
                          {(media?.response_by === user?.id || user?.role === 'Admin') &&  <div className="icon" onClick={()=>deleteMediaTicket(media?.id) }>
                            <MdClose />
                           
                            </div>}
                           <img src={media?.response_body?.file_url} />
                          <span className="name">{media?.response_body?.file_name.slice(0,10)}</span>
                         </div>
                        ) : (
                          <div className="file_wrapper">
                            {(media?.response_by === user?.id || user?.role === 'Admin' ) &&  <div className="icon" onClick={()=>deleteMediaTicket(media?.id) }>
                            <MdClose />
                           
                            </div>}
                            <a
                            href={media?.response_body?.file_url}
                            target="_"
                          >{media?.response_body?.file_name.slice(0,9)}</a>
                          </div>
                        )}
                      </div>
                      )}
                    </Fragment>
                  ))}
                </div>
              )}

{shipperfiles === "recipient" && shipmentMedia?.recipient?.length > 0 && (
                <div className="disussionShipping__package_files">
                  {shipmentMedia?.recipient?.map((media) => (
                     <Fragment key={media?.id}>
                      {media?.response_body !== null && (
                        <div className="disussionShipping__package_file_holder">
                        {media?.response_type === "image" ? (
                         <div className="image_wrapper">
                          {(media?.response_by === user?.id || user?.role === 'Admin' ) &&  <div className="icon" onClick={()=>deleteMediaTicket(media?.id) }>
                            <MdClose />
                           
                            </div>}
                           <img src={media?.response_body?.file_url} />
                          <span className="name">{media?.response_body?.file_name.slice(0,10)}</span>
                         </div>
                        ) : (
                          <div className="file_wrapper">
                            {(media?.response_by === user?.id || user?.role === 'Admin' ) &&  <div className="icon" onClick={()=>deleteMediaTicket(media?.id) }>
                            <MdClose />
                           
                            </div>}
                            <a
                            href={media?.response_body?.file_url}
                            target="_"
                          >{media?.response_body?.file_name.slice(0,9)}</a>
                          </div>
                        )}
                      </div>
                      )}
                    </Fragment>
                  ))}
                </div>
              )}


{shipperfiles === "carrier" && shipmentMedia?.carrier?.length > 0 && (
                <div className="disussionShipping__package_files">
                  {shipmentMedia?.carrier?.map((media) => (
                     <Fragment key={media?.id}>
                      {media?.response_body !== null && (
                         <div className="disussionShipping__package_file_holder">
                         {media?.response_type === "image" ? (
                          <div className="image_wrapper">
                            {(media?.response_by === user?.id || user?.role === 'Admin' ) &&  <div className="icon" onClick={()=>deleteMediaTicket(media?.id) }>
                            <MdClose />
                           
                            </div>}
                            <img src={media?.response_body?.file_url} />
                           <span className="name">{media?.response_body?.file_name.slice(0,10)}</span>
                          </div>
                         ) : (
                           <div className="file_wrapper">
                            {(media?.response_by === user?.id || user?.role === 'Admin') &&  <div className="icon" onClick={()=>deleteMediaTicket(media?.id) }>
                            <MdClose />
                           
                            </div>}
                             <a
                             href={media?.response_body?.file_url}
                             target="_"
                           >{media?.response_body?.file_name.slice(0,9)}</a>
                           </div>
                         )}
                       </div>
                      )}
                    </Fragment>
                  ))}
                </div>
              )}
              {shipperfiles === "admin" && shipmentMedia?.admin?.length > 0 && (
                <div className="disussionShipping__package_files">
                  {shipmentMedia?.admin?.map((media) => (
                     <Fragment key={media?.id}>
                      {media?.response_body !== null && (
                        <div className="disussionShipping__package_file_holder">
                        {media?.response_type === "image" ? (
                         <div className="image_wrapper">
                          {(media?.response_by === user?.id || user?.role === 'Admin' ) &&  <div className="icon" onClick={()=>deleteMediaTicket(media?.id) }>
                            <MdClose />
                           
                            </div>}
                           <img src={media?.response_body?.file_url} />
                          <span className="name">{media?.response_body?.file_name.slice(0,10)}</span>
                         </div>
                        ) : (
                          <div className="file_wrapper">
                            {(media?.response_by === user?.id || user?.role === 'Admin' ) &&  <div className="icon" onClick={()=>deleteMediaTicket(media?.id) }>
                            <MdClose />
                           
                            </div>}
                            <a
                            href={media?.response_body?.file_url}
                            target="_"
                          >{media?.response_body?.file_name.slice(0,9)}</a>
                          </div>
                        )}
                      </div>
                      )}
                    </Fragment>
                  ))}
                </div>
              ) }



            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DiscussionTickets;









