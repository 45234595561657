import React, { useState } from "react";
import "./warehouseList.css";
// components imports
import WarehousesTable from "../../../../../../Components/BoltTable/BoltTable";
import WarehouseClientList from "../Components/WarehouseClientList/WarehouseClientList";
import WarehouseProductList from "../Components/WarehouseProductList/WarehouseProductList";
import WarehouseDetails from "../Components/WarehouseDetails/WarehouseDetails";
import AddBalance from "../../AddBalance/AddBalance";
import SubtractBalance from "../../SubtractBalance/SubtractBalance";
import PaymentsHistory from "../../PaymentsHistory/PaymentsHistory";
import ChangePassword from "../../ChangePassword/ChangePassword";
import EditUser from "../../EditUser/EditUser";
import Tooltip from "../../../../../../Components/Tooltip/Tooltip";
import SwitchButton from "../../../../../../Components/SwitchButton/SwitchButton";
import Confirm from "../../../../../../Components/Popups/Confirm";
import ManageWarehouseFormula from "../Components/ManageWarehouseFormula/ManageWarehouseFormula";
import InvoicesHistory from "../../InvoicesHistory/InvoicesHistory";
// apis imports
import {
  getAllWarehouseUsers,
  changeWarehouseStatus,
  updateAccountStatus,
  deleteUserByAdmin,
} from "../../../../../../services/AdminServices/Users/users.services";
// library imports
import { IoInformationCircleOutline } from "react-icons/io5";
import { IconButton } from "@mui/material";
import CustomMenu from "../Components/ActionsDropDown/ActionsDropDown";

const WarehouseList = ({ setSnack }) => {
  const [searchLoader, setSearchLoader] = useState(false);

  const [warehouses, setWarehouses] = useState();

  const [openClientsModal, setOpenClientsModal] = useState(false);
  const [openProductsModal, setOpenProductsModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [addBalanceModal, setAddBalanceModal] = useState(false);
  const [subtractBalanceModal, setSubtractBalanceModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState();
  const [statusUpdating, setStatusUpdating] = useState();
  const [formulaModal, setFormulaModal] = useState(false);
  const [invoicesHistoryModal, setInvoicesHistoryModal] = useState(false);

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 1,
      label: "Name",
      render: (row) => <>{row?.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 2,
      label: "Code",
      render: (row) => <>{row?.code ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Email",
      render: (row) => <>{row?.user?.email ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "Balance	",
      render: (row) => <>{row?.user?.balance ?? "-"}</>,
      width: "200px",
    },
    {
      id: 5,
      label: (
        <>
          Warehouse Activation
          <Tooltip title="Warehouse Account Approved / Disapproved">
            <IconButton>
              <IoInformationCircleOutline size={10} />
            </IconButton>
          </Tooltip>
        </>
      ),
      render: (row) => (
        <>
          <SwitchButton
            onChange={(e) => handleStatusChange(e, row)}
            checked={row?.status === "active" ? true : false}
            disabled={searchLoader === "WarehouseActivation" ? true : false}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 6,
      label: (
        <>
          Activate
          <Tooltip title="Account Approved / Disapproved">
            <IconButton>
              <IoInformationCircleOutline size={10} />
            </IconButton>
          </Tooltip>
        </>
      ),
      render: (row) => (
        <>
          <SwitchButton
            onChange={(e) => handleUserStatusChange(e, row)}
            checked={
              ![false, "false", "0", null, undefined].includes(
                row?.user?.account_status
              )
            }
            disabled={searchLoader === "UserActivation" ? true : false}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 7,
      label: "Actions",
      render: (row) => (
        <CustomMenu
          row={row}
          setAddBalanceModal={setAddBalanceModal}
          setSubtractBalanceModal={setSubtractBalanceModal}
          setChangePassword={setChangePassword}
          setEditUserModal={setEditUserModal}
          setOpenDetailsModal={setOpenDetailsModal}
          setOpenClientsModal={setOpenClientsModal}
          setOpenProductsModal={setOpenProductsModal}
          setHistoryModal={setHistoryModal}
          setConfirmDelete={setConfirmDelete}
          setFormulaModal={setFormulaModal}
          setInvoicesHistoryModal={setInvoicesHistoryModal}
        />
      ),
      width: "200px",
      fixed: "right",
    },
  ];

  const fetchAllWarehouses = async (page, perPage, filter) => {
    const response = await getAllWarehouseUsers(page, perPage, filter);
    if (!response.error) {
      setWarehouses(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const handleStatusChange = async (e, record) => {
    setSearchLoader("WarehouseActivation");
    const updated = await changeWarehouseStatus(
      record?.id,
      e.target.checked ? "active" : "inactive"
    );

    if (updated.error) {
      setSearchLoader(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: updated.error.message,
          severity: "error",
        };
      });
    } else {
      setWarehouses((prevWarehouses) => {
        return {
          ...prevWarehouses,
          data: prevWarehouses?.data?.map((item) =>
            item.id === record.id
              ? {
                  ...item,
                  status: updated?.data?.result?.status,
                }
              : item
          ),
        };
      });
      setSearchLoader(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `Status of ${
            updated?.data?.result?.name ?? "Warehouse"
          } changed to ${
            updated?.data?.result?.status === "active" ? "Active" : "Inactive"
          }`,
          severity:
            updated?.data?.result?.status === "active" ? "success" : "info",
        };
      });
    }
  };

  const handleUserStatusChange = async (e, record) => {
    setSearchLoader("UserActivation");
    const updated = await updateAccountStatus(record?.user);
    if (updated.error) {
      setSearchLoader(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: updated.error.message,
          severity: "error",
        };
      });
    } else {
      setWarehouses((prevWarehouses) => {
        return {
          ...prevWarehouses,
          data: prevWarehouses?.data?.map((item) =>
            item.id === record.id
              ? {
                  ...item,
                  user: {
                    ...item.user,
                    account_status: updated?.data?.result?.account_status,
                  },
                }
              : item
          ),
        };
      });
      setSearchLoader(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `Status of ${
            updated?.data?.result?.username ?? "user"
          } changed to ${
            ![false, "false", "0", null, undefined].includes(
              updated?.data?.result?.account_status
            )
              ? "Active"
              : "Inactive"
          }`,
          severity: ![false, "false", "0", null, undefined].includes(
            updated?.data?.result?.account_status
          )
            ? "success"
            : "info",
        };
      });
    }
  };

  const handleDeleteUser = async (record) => {
    // console.log("logger===", record);
    setStatusUpdating(true);
    const response = await deleteUserByAdmin(record.user.id);
    if (response.error) {
      setStatusUpdating(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    } else {
      setWarehouses((prevData) => {
        return {
          ...prevData,
          data: prevData?.data.filter(
            (item) => item.user.id !== record.user.id
          ),
        };
      });
      setStatusUpdating(false);
      setConfirmDelete(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `User Deleted Successfully `,
          severity: "success",
        };
      });
    }
  };

  const updateIndex = (id, formula) => {
    // console.log("updatedfrmula", id, formula);
    setFormulaModal({
      ...formulaModal,
      user: { ...formulaModal.user, formula_with_data: formula },
    });
    setWarehouses((prevShippers) => {
      return {
        ...prevShippers,
        data: prevShippers?.data.map((item) =>
          item.id === id
            ? { ...item, user: { ...item.user, formula_with_data: formula } }
            : item
        ),
      };
    });
  };
  // console.log("invoicesHistoryModal", invoicesHistoryModal);
  return (
    <div>
      <div className="warehouses_header">
        <div>
          <span className="warehouses_heading">Warehouses List</span>
        </div>
      </div>

      <InvoicesHistory
        open={invoicesHistoryModal.user}
        setOpen={setInvoicesHistoryModal}
        User={invoicesHistoryModal.user}
        setSnack={setSnack}
      />
      <ManageWarehouseFormula
        open={formulaModal}
        setOpen={setFormulaModal}
        setSnack={setSnack}
        updateIndex={updateIndex}
      />
      <AddBalance
        open={addBalanceModal.user}
        setOpen={setAddBalanceModal}
        setSnack={setSnack}
        updateUsers={setWarehouses}
      />
      <SubtractBalance
        open={subtractBalanceModal.user}
        setOpen={setSubtractBalanceModal}
        setSnack={setSnack}
        updateUsers={setWarehouses}
      />
      <PaymentsHistory
        open={historyModal}
        setOpen={setHistoryModal}
        setSnack={setSnack}
        User={historyModal.user}
      />
      <ChangePassword
        open={!!changePassword}
        setOpen={setChangePassword}
        previousData={changePassword}
        setSnack={setSnack}
        setUsers={setWarehouses}
      />
      <EditUser
        open={!!editUserModal}
        setOpen={setEditUserModal}
        previousData={editUserModal?.user}
        setSnack={setSnack}
        setUsers={setWarehouses}
      />
      <Confirm
        open={confirmDelete}
        setOpen={setConfirmDelete}
        handleConfirm={() => handleDeleteUser(confirmDelete)}
        text="Do you want to delete this user?"
        loader={statusUpdating}
      />
      <WarehouseDetails open={openDetailsModal} setOpen={setOpenDetailsModal} />
      <WarehouseClientList
        open={openClientsModal}
        setOpen={setOpenClientsModal}
        setSnack={setSnack}
      />
      <WarehouseProductList
        open={openProductsModal}
        setOpen={setOpenProductsModal}
        setSnack={setSnack}
      />
      <div className="actionbtn__needtobe_fixwidth">
        <WarehousesTable
          message={"No Warehouse Available To Display!"}
          searchPlaceHolder="Search Warehouses"
          columns={columns}
          data={warehouses}
          getAllData={fetchAllWarehouses}
        />
      </div>
    </div>
  );
};

export default WarehouseList;
