import React, { useState } from "react";
import "./FullfilmentBillingStatusHistoryModal.css";
// components imports
import HistoryTable from "../../../../../../../Components/BoltTable/BoltTable";
import StatusIcons from "../../../../../../../Components/StatusIcons/StatusIcons";

// apis imports
import { fetchOrderFullfillmentBillingStatusHistory } from "../../../../../../../services/orders.services";
// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";

const FullfilmentBillingStatusHistoryModal = ({ open, setOpen, setSnack }) => {
  const [data, setData] = useState({});
  const handleClose = () => {
    setOpen(false);
    setData({});
  };

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "130px",
      fixed: "left",
    },
    {
      id: 1,
      label: "Previous Status",
      field_name: "previous_status",
      render: (row) => (
        <StatusIcons status={row?.properties?.previous_status} />
      ),
      width: "200px",
    },
    {
      id: 2,
      label: "Updated Status",
      field_name: "updated_status",
      render: (row) => <StatusIcons status={row?.properties?.updated_status} />,
      width: "200px",
    },
    {
      id: 3,
      label: "Created By",
      field_name: "created_by",
      render: (row) => <>{row?.user?.username ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "Created At",
      field_name: "created_at",
      render: (row) => <>{row?.created_at?.slice(0, 10) ?? "-"}</>,
      width: "200px",
    },
    {
      id: 5,
      label: "Notes",
      field_name: "notes",
      render: (row) => <>{row?.properties?.notes ?? "-"}</>,
      width: "200px",
    },
    {
      id: 6,
      label: "Description",
      field_name: "description",
      render: (row) => <>{row?.description ?? "-"}</>,
      width: "200px",
    },
  ];

  const getFullfilmentBillingNotesHistory = async (page, perPage, filter) => {
    let response = await fetchOrderFullfillmentBillingStatusHistory(
      page,
      perPage,
      filter,
      open?.id
    );

    if (!response.error) {
      setData(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };
  return (
    <>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <div className="full_bill_sts_history_modal">
            <div
              className="full_bill_sts_history_modalheading history__product_heading  history__main_heading"
              style={{
                border: "none",
              }}
            >
              Fulfilment Billing Status History
            </div>
            <div className="full_bill_sts_history_modal_orderId">
              {open?.order_id}
            </div>
            <div className="full_bill_sts_history_modal_content history__product_content">
              <HistoryTable
                message={
                  "No Fulfilment Billing Status History Available To Display!"
                }
                searchPlaceHolder={"Search Not Available"}
                getAllData={getFullfilmentBillingNotesHistory}
                data={data}
                columns={columns}
              />
            </div>
            <div className="full_bill_sts_history_modal_footer">
              <div
                className="full_bill_sts_history_modal_footer_btn"
                onClick={handleClose}
              >
                x
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default FullfilmentBillingStatusHistoryModal;
