import React, { useState } from "react";
import "./Sections.css";
import { MdErrorOutline } from "react-icons/md";
import { useEffect } from "react";
import { getUserWarehousesByWar_Id } from "../../../../services/warehouse.services";
import { useSelector } from "react-redux";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const ClientSection = ({ formData, setFormData, errors, mode }) => {
  const [clients, setClients] = useState([]);

  const user = useSelector((state) => state.user);

  const handleUser = (e) => {
    setFormData({ ...formData, user: e.target.value });
  };

  useEffect(() => {
    getAllWarehouseClients();
  }, []);

  const getAllWarehouseClients = async () => {
    const response = await getUserWarehousesByWar_Id(
      user?.warehouse_id,
      1,
      50,
      ""
    );
    if (!response.error) {
      setClients(response?.data?.result?.data);
    }
  };

  return (
    <section
      className="addprod__fsidesection_wrap"
      id="addproduct_basic_status"
    >
      <div className="addprod__custominput_flexrow">
        <div className="addprod__custominput_flexcol">
          <div className="addprod__custominput_box">
            <label className="addprod__custominput_title">Client</label>
            <Select
              value={formData?.user}
              onChange={(e) => handleUser(e)}
              name="client"
              style={{ width: "100%" }}
              size="small"
              disabled={mode === "edit" ? true : false}
              displayEmpty
              renderValue={(selected) => {
                const selectedClient = clients.find(
                  (client) => client?.shipper?.id === selected
                );
                return selectedClient?.shipper?.username?.toUpperCase();
              }}
            >
              {clients.map((client) => (
                <MenuItem key={client?.id} value={client?.shipper?.id}>
                  {client?.shipper?.username?.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
            {Object.keys(errors)?.includes("user") && (
              <div className="addprod__custominputfield_error">
                <MdErrorOutline className="addprod__custominputfield_erroricn" />
                {errors?.user?.msg}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientSection;
