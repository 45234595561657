import React, { useState, useEffect, useRef } from "react";
import Modal from "@mui/material/Modal";
import { MdCloudDownload } from "react-icons/md";
import TextField from "@mui/material/TextField";
import { Button, Input, Typography } from "@mui/material";
import { styled } from "@mui/system";
import Buttons from "@mui/material/Button";
import CircularLoader from "../../../../Components/CircularLoader/CircularLoader";

import { updateCountStock } from "../../../../services/warehouse.services";

const StyledUploadContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: "2px dashed #2d76bf",
  borderRadius: "8px",
  backgroundColor: "#fff",
  cursor: "pointer",
  marginBottom: "15px",
});

const FinalizeStockCount = ({
  finalizeHandleClose,
  open,
  setSnack,
  setPscList,
}) => {
  const [formData, setFormData] = useState({
    file: "",
    description: "",
  });
  const [loader, setloader] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.name.endsWith(".xlsx")) {
      setFormData({ ...formData, file: file });
    } else {
      setFormData({ ...formData, file: "" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloader(true);
    if (formData.file === "") {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please Upload File",
          severity: "error",
        };
      });
      setloader(false);
      return;
    }

    const Formdata = new FormData();
    Formdata.append("file", formData.file);
    Formdata.append("description", formData.description);

    const response = await updateCountStock(open?.id, Formdata);
    // console.log("response", response.data.result);
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
      setloader(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Stock Count Finalized Successfully",
          severity: "success",
        };
      });
      setloader(false);
      handleModalClose();
      // setPscList((psc) => {
      //   return [response.data.result, ...psc];
      // });
    }
  };
  const handelChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleModalClose = () => {
    setFormData({
      file: "",
      description: "",
    });
    finalizeHandleClose();
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleModalClose}
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit}>
        <div className="finalizeStockCount__desc_modal">
          <div className="finalizeStockCount__desc_modalheading">
            Finalize Stock Count
          </div>
          <div className="finalizeStockCount__desc_body">
            <div className="finalizeStockCount__desc_body_list">
              <div className="finalizeStockCount__desc_body_list_title">
                Warehouse
              </div>
              <div className="finalizeStockCount__desc_body_list_data">
                {open?.warehouse?.name ?? "-"}
              </div>
            </div>
            <div className="finalizeStockCount__desc_body_list">
              <div className="finalizeStockCount__desc_body_list_title">
                Date
              </div>
              <div className="finalizeStockCount__desc_body_list_data">
                {open?.created_at?.slice(0, 10) ?? "-"}
              </div>
            </div>
            <div className="finalizeStockCount__desc_body_list">
              <div className="finalizeStockCount__desc_body_list_title">
                Reference
              </div>
              <div className="finalizeStockCount__desc_body_list_data">
                {open?.reference_no ?? "-"}
              </div>
            </div>
            <div className="finalizeStockCount__desc_body_list">
              <div className="finalizeStockCount__desc_body_list_title">
                Categories
              </div>
              <div className="finalizeStockCount__desc_body_list_data">
                {open?.category_ids?.map((c) => c + ",") ?? "-"}
              </div>
            </div>
            <div className="finalizeStockCount__desc_body_list">
              <div className="finalizeStockCount__desc_body_list_title">
                Sub Categories
              </div>
              <div className="finalizeStockCount__desc_body_list_data">
                {open?.sub_category_ids?.map((s_c) => s_c + ",") ?? "-"}
              </div>
            </div>
            <div className="finalizeStockCount__desc_body_list">
              <div className="finalizeStockCount__desc_body_list_title">
                Brands
              </div>
              <div className="finalizeStockCount__desc_body_list_data">
                {open?.brand_ids?.map((s_c) => s_c + ",") ?? "-"}
              </div>
            </div>
          </div>
          <div className="file__download_main_wrap">
            <div className="file__download_left_content">
              <h5 className="introContent__file_download">
                The First line in the download csv file should remain as it is.
                Please do not change the order of columns.
              </h5>
              <h5 className="infoContent__file_download">
                You just need to update the COUNTED column in the download File.
              </h5>
            </div>
            <div className="file__download_right_btn">
              <div className="file__download_btn_box">
                <a href={open.file} download={open.file}>
                  <MdCloudDownload /> Download file of current stock
                </a>
              </div>
            </div>
          </div>

          <div className="fileupload__main_wrap">
            <div className="csvfileupload__main_wrap">
              <h2 className="file__upload_csv_title">
                Upload File * {"(Required)"}
              </h2>

              {/* <label className="file__upload_csv_row">
              {selectedFile ? (
                <p> {selectedFile.name}</p>
              ) : (
                <p>
                  Upload File to Submit <strong>OR</strong> Reset the Form{" "}
                </p>
              )}
              <input
                required
                type="file"
                accept=".csv"
                onChange={handleFileChange}
              />
            </label> */}

              <StyledUploadContainer className="file__upload_csv_row">
                <label htmlFor="file-upload">
                  <Input
                    id="file-upload"
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  {/* <Typography variant="h6" color="primary" component="div">
                    Upload File to Submit <strong>OR</strong> Reset the Form{" "}
                  </Typography> */}
                  {formData?.file != "" ? (
                    <Typography variant="body1">
                      {formData.file?.name}
                    </Typography>
                  ) : (
                    <Typography variant="h6" color="primary" component="div">
                      Upload File to Submit <strong>OR</strong> Reset the Form{" "}
                    </Typography>
                  )}
                </label>
              </StyledUploadContainer>
            </div>
            <div className="notes__contentarea_sec">
              <TextField
                multiline
                rows={5}
                label="Note"
                variant="outlined"
                className="csvUpload__content"
                name="description"
                onChange={(e) => handelChange(e)}
              />
            </div>
            <div className="finalizeStock__footer_btn_sec">
              <Buttons
                type="button"
                variant="contained"
                color="error"
                onClick={handleModalClose}
                size="small"
                disabled={loader ? true : false}
              >
                Close
              </Buttons>
              <Buttons
                type="submit"
                variant="contained"
                size="small"
                style={{ marginLeft: "5px" }}
                disabled={loader ? true : false}
              >
                {loader ? <CircularLoader /> : "Submit"}
              </Buttons>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
export default FinalizeStockCount;
