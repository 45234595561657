import React, { useState } from "react";
import "./addunits.css";

// components imports
import Loader from "../../../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import { addTransferUnits } from "../../../../../../../services/admin.services";
// library imports
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Modal from "@mui/material/Modal";
import { FaBullseye } from "react-icons/fa";

const AddUnits = ({ setOpen, open, setSnack, setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    code: "",
  });

  const handelChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.name === "") {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please Enter Name For Unit",
          severity: "error",
        };
      });
      return;
    }
    setLoading(true);
    const response = await addTransferUnits(formData);
    // console.log("response", response.data.result);
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
      setLoading(false);
    } else {
      setRefresh(Math.floor(Math.random() * 100) + 1);
      setLoading(false);
      setOpen(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Unit Added Successfully",
          severity: "success",
        };
      });
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="add-unit-modal-title"
        aria-describedby="add-unit-modal-description"
      >
        <div className="unitStock__add_new_unit_modal">
          <div className="unitStock__add_new_unit_modal_wrap">
            <div className="unitStock__add_new_unit">
              <div className="unitStock__add_new_unit_header">
                <h3 className="unitStock__add_new_unit_title">Add Unit</h3>
              </div>
              <div className="unitStock__add_new_unit_body">
                <FormControl
                  variant="outlined"
                  className="unitStock__add_new_unit_code"
                >
                  <InputLabel htmlFor="addUnitCode" size="small">
                    Code *
                  </InputLabel>
                  <OutlinedInput
                    id="addUnitCode"
                    name="code"
                    value={formData.code}
                    onChange={(e) => handelChange(e)}
                    size="small"
                    label="Code *"
                  />
                </FormControl>

                <FormControl
                  variant="outlined"
                  className="unitStock__add_new_unit_name"
                >
                  <InputLabel htmlFor="addUnitName" size="small">
                    Name *
                  </InputLabel>
                  <OutlinedInput
                    id="addUnitName"
                    name="name"
                    value={formData.name}
                    onChange={(e) => handelChange(e)}
                    size="small"
                    label="Name *"
                  />
                </FormControl>

                <div className="addStockCount__desc_modalfooter">
                  <div
                    className="addStockCount__desc_modalreset"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </div>
                  <div
                    className="addStockCount__desc_modalsubmit"
                    onClick={handleSubmit}
                  >
                    {loading ? <Loader /> : "Submit"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddUnits;
