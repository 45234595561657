import React from "react";
import "./ScreenLoader.css";
const ScreenLoader = ({ text }) => {
  return (
    <div className="screen__loader_wrap">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default ScreenLoader;
