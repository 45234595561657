import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";

export default function AutoComplete(props) {
  const { Option, Value, Type, Handler, HelperText = "" } = props;
  return (
    <>
      <Autocomplete
        freeSolo
        id="State-select-demo"
        sx={{ width: "100%" }}
        options={Option}
        autoHighlight
        getOptionLabel={(option) => option}
        onChange={(event, newValue) => Handler(newValue)}
        value={Value || null}
        isOptionEqualToValue={(option, value) => option === value}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{
              "& > img": { mr: 2, flexShrink: 0 },
            }}
            {...props}
          >
            {option}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            // label={Type}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            placeholder={Type}
            // required
            sx={{ input: { font: "menu" } }}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // Disable autocomplete and autofill
            }}
            size="small"
          />
        )}
      />
    </>
  );
}
