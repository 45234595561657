import React, { useState, useEffect } from "react";
import "./transferrequest.css";
// components imports
import TransferNotesTable from "../../../../Components/BoltTable/BoltTable";
import TransferRequestDetails from "./Components/TransferRequestDetails/TransferRequestDetails";
import Tooltip from "../../../../Components/Tooltip/Tooltip";
import StatusIcons from "../../../../Components/StatusIcons/StatusIcons";
// apis imports
import { getAllTransferNotesRequest } from "../../../../services/WarehouseServices/warehouse.services";
// library imports
import { useSelector } from "react-redux";
import { MdRemoveRedEye } from "react-icons/md";

const TransferRequest = ({ snack, setSnack }) => {
  const user = useSelector((state) => state.user);

  const [open, setOpen] = useState(false);

  const [transferNotes, setTransferNotes] = useState([]);

  const columns = [
    {
      id: 1,
      label: "Date",
      render: (row) => <>{row?.created_at?.slice(0, 10) ?? "-"}</>,
      width: "200px",
    },
    {
      id: 2,
      label: "Reference",
      render: (row) => <>{row?.reference_no ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "From Warehouse",
      render: (row) => <>{row?.from_warehouse?.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "To Warehouse",
      field_name: "to_warehouse",
      render: (row) => <>{row?.to_warehouse?.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 5,
      label: "Created By",
      render: (row) => <>{user?.username ?? "-"}</>,
      width: "200px",
    },
    {
      id: 6,
      label: "Status",
      render: (row) => (
        <>
          <StatusIcons status={row?.status} />
        </>
      ),
      width: "200px",
    },
    {
      id: 7,
      label: "Actions",
      render: (row) => (
        <>
          <div className="table_action__wrap">
            <Tooltip title="View Transfer Details">
              <div className="table_action__btn" onClick={() => setOpen(row)}>
                <MdRemoveRedEye size={10} />
                Details
              </div>
            </Tooltip>
          </div>
        </>
      ),
      width: "200px",
    },
  ];

  const fetchAllTransferNotesRequest = async (page, perPage, filter) => {
    const response = await getAllTransferNotesRequest(page, perPage, filter);

    if (!response.error) {
      setTransferNotes(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  return (
    <>
      <div className="">
        <>
          <div className="transferrequest_header">
            <div>
              <span className="transferrequest_hdrheading">
                Transfer Request
              </span>
            </div>
          </div>
          <div>
            <TransferNotesTable
              message={"No Transfer Request Available To Display!"}
              searchPlaceHolder={"Search Transfer Request"}
              columns={columns}
              data={transferNotes}
              getAllData={fetchAllTransferNotesRequest}
            />
          </div>
        </>
      </div>
      <TransferRequestDetails
        open={open}
        setOpen={setOpen}
        setSnack={setSnack}
        setTransferNotes={setTransferNotes}
      />
    </>
  );
};

export default TransferRequest;
