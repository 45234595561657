import ExcelJS from "exceljs";
import { calculateCBMAndCBF } from "../../../../Products/Product/ProductService";

export const generateExcelFile = async (Products, selectedRows, setSnack) => {
  try {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Products");

    const flattenedProductArray = await flattenProducts(Products, selectedRows);

    if (flattenedProductArray.length === 0) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Select Pallets to export.",
          severity: "error",
        };
      });
      return;
    }

    const data = flattenedProductArray.map((product) => {
      const Details = product?.product_pallets
        ?.map(
          (pallet) =>
            `${pallet?.product_warehouse?.product?.product_sku ?? ""} (${
              pallet?.quantity ?? "0"
            })`
        )
        .join(", ");

      const { cbm, cbf } = calculateCBMAndCBF(
        product?.length,
        product?.width,
        product?.height,
        product?.unit === "imperial" ? "inch" : "cm",
        1
      );

      const percentage =
        (product?.total_product_cbm_volume / cbm?.toFixed(2)) * 100;

      return {
        "General Information": {
          Client: product?.shipper?.username ?? "",
          Code: product?.code ?? "",
          Name: product?.name ?? "",
          Location: product?.location?.name ?? "",
          "Created At": product?.created_at?.slice(0, 10) ?? "",
        },
        Dimensions: {
          Length: product?.length ?? "0",
          Width: product?.width ?? "0",
          Height: product?.height ?? "0",
          Unit: product?.unit === "imperial" ? "inch" : "cm",
        },
        Weight: {
          Weight: product?.weight ?? "0",
          Unit: product?.unit === "imperial" ? "lb" : "kg",
        },
        Volume: {
          "Pallet (CBM)": cbm?.toFixed(2),
          "Pallet (CBF)": cbf?.toFixed(2),
          "Product (CBM)": product?.total_product_cbm_volume ?? "0",
          "Product (CBF)": product?.total_product_cbf_volume ?? "0",
          "Space Consumed": percentage ? percentage?.toFixed(0) + "%" : "0",
        },
        Quantity: {
          "Products Count": product?.product_pallets_count ?? "0",
          "Product Quantity": product?.product_quantity ?? "0",
          "Product BreakDown": Details,
        },
      };
    });

    // Determine headers and subheaders dynamically
    const headers = Object.keys(data[0]);
    const subheaders = headers.map((header) => Object.keys(data[0][header]));

    // Set styles for headers and subheaders
    const headerStyle = {
      font: { bold: true, color: { argb: "FFFFFF" } },
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "3498db" } },
      alignment: { horizontal: "center", vertical: "middle" },
      border: {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      },
    };

    const subheaderStyle = {
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "2ecc71" } },
      alignment: { horizontal: "center", vertical: "middle", wrapText: true },
      border: {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      },
    };

    // Write headers and subheaders to worksheet
    let columnIndex = 1;
    headers.forEach((header, index) => {
      const subheaderCount = subheaders[index].length;
      if (subheaderCount > 1) {
        worksheet.mergeCells(
          1,
          columnIndex,
          1,
          columnIndex + subheaderCount - 1
        );
      }
      worksheet.getCell(1, columnIndex).value = header;
      worksheet.getCell(1, columnIndex).style = headerStyle;

      subheaders[index].forEach((subheader, subheaderIndex) => {
        worksheet.getCell(2, columnIndex + subheaderIndex).value = subheader;
        worksheet.getCell(2, columnIndex + subheaderIndex).style =
          subheaderStyle;
      });

      columnIndex += subheaderCount;
    });

    // Write data to worksheet
    data.forEach((item, rowIndex) => {
      let columnIndex = 1;
      headers.forEach((header, headerIndex) => {
        subheaders[headerIndex].forEach((subheader) => {
          const cellValue = item[header][subheader];
          const cell = worksheet.getCell(rowIndex + 3, columnIndex);

          if (
            [
              "Length",
              "Width",
              "Height",
              "Weight",
              "Pallet (CBM)",
              "Pallet (CBF)",
              "Product (CBM)",
              "Product (CBF)",
              "Products Count",
              "Product Quantity",
            ].includes(subheader)
          ) {
            cell.value = Number(cellValue); // Convert to number
            cell.numFmt = "0.00"; // Format to 2 decimal places for CBM, CBF
          } else if (subheader === "Space Consumed") {
            const percentage = parseFloat(cellValue); // Convert percentage string to number

            // Apply conditional formatting based on percentage ranges
            if (percentage >= 80 || percentage > 100) {
              cell.font = { color: { argb: "FF228B22" } }; // Dark green
            } else if (percentage >= 60) {
              cell.font = { color: { argb: "FFDAA520" } }; // Dark yellow
            } else if (percentage >= 40) {
              cell.font = { color: { argb: "FFFF8C00" } }; // Dark orange
            } else {
              cell.font = { color: { argb: "FFB22222" } }; // Dark red
            }
            cell.value = percentage * 0.01; // Convert to percentage
            cell.numFmt = "0%"; // Format as percentage without decimal places
          } else {
            cell.value = cellValue;
          }

          cell.alignment = {
            horizontal: "center",
            vertical: "middle",
            wrapText: true,
          };

          const column = worksheet.getColumn(columnIndex);
          column.width = subheader.length + 2;

          columnIndex++;
        });
      });
    });

    // Add a totals row at the end
    const totalsRow = [];
    headers.forEach((header, headerIndex) => {
      subheaders[headerIndex].forEach((subheader) => {
        if (
          subheader === "Pallet (CBM)" ||
          subheader === "Pallet (CBF)" ||
          subheader === "Product (CBM)" ||
          subheader === "Product (CBF)" ||
          subheader === "Weight" ||
          subheader === "Products Count" ||
          subheader === "Product Quantity"
        ) {
          // Sum numerical values for the totals row
          const total = data.reduce((sum, item) => {
            return sum + (Number(item[header][subheader]) || 0);
          }, 0);

          totalsRow.push(total.toFixed(2)); // Use fixed 2 decimal places
        } else if (subheader === "Space Consumed") {
          const percentage = (
            (totalsRow?.["13"] / totalsRow?.["11"]) *
            100
          )?.toFixed(2);
          totalsRow.push(percentage + "%"); // Add space consumed total
        } else {
          totalsRow.push(""); // Leave empty for non-numerical columns
        }
      });
    });

    // Write totals row to worksheet
    worksheet.addRow(totalsRow);
    const totalsRowIndex = worksheet.rowCount; // Get the index of the totals row

    // Apply alignment and conditional formatting to the entire totals row
    worksheet.getRow(totalsRowIndex).eachCell((cell, colNumber) => {
      cell.alignment = { horizontal: "center", vertical: "middle" };

      // Apply conditional font color formatting for "Space Consumed" column
      if (colNumber === 16) {
        // Ensure we are working with a valid percentage value
        const percentageStr = cell.value.replace("%", ""); // Remove % symbol
        const percentage = parseFloat(percentageStr); // Convert to number
        if (!isNaN(percentage)) {
          if (percentage > 100 || percentage >= 80) {
            cell.font = { color: { argb: "FF228B22" } }; // Dark green for 80%-100% or more
          } else if (percentage >= 60) {
            cell.font = { color: { argb: "FFDAA520" } }; // Dark yellow for 60%-80%
          } else if (percentage >= 40) {
            cell.font = { color: { argb: "FFFF8C00" } }; // Dark orange for 40%-60%
          } else {
            cell.font = { color: { argb: "FFB22222" } }; // Dark red for less than 40%
          }
        }
      } else {
        cell.font = { bold: true };
      }
    });

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "pallets.xlsx";
    a.click();
  } catch (error) {
    console.error("Error generating Excel file", error);
    setSnack((snack) => ({
      ...snack,
      open: true,
      text: "Error generating Excel file.",
      severity: "error",
    }));
  }
};

async function flattenProducts(products, selectedRows) {
  const filteredProducts = products.filter((product) =>
    selectedRows.includes(product.id)
  );
  return filteredProducts;
}
