import React, { useState } from "react";
import "./AddBlog.css";

// components imports
import Loader from "../../../../../../../Components/CircularLoader/CircularLoader";

// apis imports
import { createNewBlog } from "../../../../../../../services/admin.services";

// library imports

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import Upload from "@mui/icons-material/CloudUpload";

const initialData = {
  title: "",
  slug: "",
  body: "",
  desc: "",
  thumb_img: null,
};

export default function AddBlog({ open, setOpen, setSnack, setRefresh }) {
  const user = useSelector((state) => state.user);

  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setData(initialData);
    setOpen(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleFileChange = (e) => {
    setData({ ...data, thumb_img: e.target.files[0] });
  };

  const handleSave = async () => {
    setLoading(true);
    for (const key in data) {
      if (data[key] === "") {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: `${key} is required!`,
            severity: "error",
          };
        });
        setLoading(false);
        return;
      }
    }

    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("slug", data.slug);
    formData.append("desc", data.desc);
    formData.append("body", data.body);
    data.thumb_img && formData.append("thumb_img", data.thumb_img);
    const saved = await createNewBlog(user.token, formData);
    if (!saved.error) {
      setRefresh(Math.floor(Math.random() * 100) + 1);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Blog Created successfully",
          severity: "success",
        };
      });
      setData(initialData);
      setLoading(false);
      handleClose();
    } else {
      setLoading(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Couldn't create blog",
          severity: "error",
        };
      });
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="admnadd__blog_modal">
          <div className="admnadd__blog_modalheading">Create Blog</div>
          <div className="admnadd__blog_modalcontent">
            <div className="admnadd__blog_modalrow">
              <div
                className="admnadd__blog_modalinputwrap"
                style={{ minWidth: "65%" }}
              >
                <input
                  className="admnadd__blog_modalinput"
                  placeholder="Title"
                  name="title"
                  value={data.title}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="admnadd__blog_modalrow">
              <div className="admnadd__blog_modalinputwrap">
                <input
                  className="admnadd__blog_modalinput"
                  placeholder="Slug"
                  name="slug"
                  value={data.slug}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="admnadd__blog_modalrow">
              <div className="admnadd__blog_modalinputwrap">
                <input
                  className="admnadd__blog_modalinput"
                  placeholder="Body"
                  name="body"
                  value={data.body}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="admnadd__blog_modalrow">
              <div className="admnadd__blog_modalinputwrap">
                <textarea
                  className="admnadd__blog_modalinput"
                  placeholder="Description"
                  name="desc"
                  value={data.desc}
                  onChange={handleChange}
                  rows={5}
                ></textarea>
              </div>
            </div>

            <div className="admnadd__blog_modalrow">
              <div className="admnadd__blog_modalinputwrap">
                <label className="admnadd__blog_profimagelbl">
                  <Upload /> {data.thumb_img?.name ?? "Upload Image"}
                  <input
                    type="file"
                    size="60"
                    className="admnadd__blog_profimageinput"
                    onChange={handleFileChange}
                    accept=".jpg,.png"
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="admnadd__blog_modalfooter">
            <div className="admnadd__blog_modalcncl" onClick={handleClose}>
              Cancel
            </div>
            <div className="admnadd__blog_modalsave" onClick={handleSave}>
              {loading ? <Loader /> : "Save"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
