import React, { useState, useEffect } from "react";
import "./warehouseproductlist.css";

// components imports
import FullPageModal from "../../../../../../../Components/Modals/FullPageModal/FullPageModal";
import WarehouseProductsTable from "../../../../../../../Components/BoltTable/BoltTable";
// apis imports
import { getProductsByWarehouses } from "../../../../../../../services/AdminServices/Users/users.services";
// library imports
import noImage from "../../../../../../../assets/images/noImage.png";

const WarehouseProductList = ({ open, setOpen, setSnack }) => {
  const [warehouseProducts, setWarehouseProducts] = useState([]);

  const ImageUrl = (row) => {
    const url =
      row?.media.find((media) => media.is_primary === "true")?.file ||
      row?.media[0]?.file;
    return url || noImage;
  };

  const columns = [
    {
      id: 1,
      label: "Client",
      render: (row) => <>{row?.shipper?.username ?? "-"}</>,
      width: "200px",
    },
    {
      id: 2,
      label: "Product ID",
      render: (row) => <>{row?.code ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Image",
      render: (row) => (
        <>
          <img
            src={ImageUrl(row)}
            onError={(e) => {
              e.target.src = noImage;
            }}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 4,
      label: "Name",
      render: (row) => <>{row?.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 5,
      label: "Dimensions",
      render: (row) => (
        <>
          L:{row.length ?? "0"} W:{row.width ?? "0"} H:{row.height ?? "0"} (
          {row.dimension_unit?.toUpperCase() ?? "N/A"})
        </>
      ),
      width: "200px",
    },
    {
      id: 6,
      label: "Product Brand",
      render: (row) => <>{row?.brand?.name?.toUpperCase() ?? "-"}</>,
      width: "200px",
    },
    {
      id: 7,
      label: "Weight",
      render: (row) => (
        <>
          <div className="warehouses__products_weight__button_wrapper">
            <input
              type="text"
              value={row?.weight || "0"}
              disabled
              className="warehouses__products_editble_input"
            />
            <button
              className={
                row?.weight_unit?.toLowerCase() == "lb"
                  ? `warehouses__products_weight_unitbtn_true`
                  : `warehouses__products_weight_unitbtn`
              }
            >
              lb
            </button>
            <button
              className={
                row?.weight_unit?.toLowerCase() == "kg"
                  ? `warehouses__products_weight_unitbtn_true`
                  : `warehouses__products_weight_unitbtn`
              }
            >
              kg
            </button>
          </div>
        </>
      ),
      width: "200px",
    },
    {
      id: 8,
      label: "Quantity",
      render: (row) => <>{row?.product_warehouse[0]["quantity"] ?? "-"}</>,
      width: "200px",
    },
  ];

  const fetchProductsByWarehouses = async (page, perPage, filter) => {
    const response = await getProductsByWarehouses(
      open?.id,
      page,
      perPage,
      filter
    );
    if (!response.error) {
      setWarehouseProducts(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  return (
    <FullPageModal open={open} setOpen={setOpen} heading={"Warehouse Products"}>
      <div className="warehouses__products_wrapper">
        <div>
          <WarehouseProductsTable
            message={"No Warehouse Products Available To Display!"}
            searchPlaceHolder="Search Products"
            columns={columns}
            data={warehouseProducts}
            getAllData={fetchProductsByWarehouses}
          />
        </div>
      </div>
    </FullPageModal>
  );
};

export default WarehouseProductList;
