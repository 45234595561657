import React, { useState } from "react";
import "./Vehicles.css";

// components imports
import VehiclesTable from "../../../Components/BoltTable/BoltTable";
import Snackbar from "../../../Components/SnackBar/SnackBar";
import SwitchButton from "../../../Components/SwitchButton/SwitchButton";
import Tooltip from "../../../Components/Tooltip/Tooltip";
import Confirm from "../../../Components/Popups/Confirm";
import VehicleForm from "./Components/VehicleForm/VehicleForm";

// apis imports
import {
  deleteVehicle,
  fetchAllVehicles,
  changeStatus,
} from "../../../services/vehicles.services";

// library imports
import { FaRegEdit } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import { FcPlus } from "react-icons/fc";

const Vehicle = () => {
  const [Change, setChange] = useState();
  const [manageVehicleModal, setManageVehicleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteVehicleModal, setDeleteVehicleModal] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [refresh, setRefresh] = useState([]);

  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 1,
      label: "Active",
      render: (row) => (
        <>
          <SwitchButton
            onChange={(e) => handleStatusChange(e, row)}
            checked={row.active === "true"}
            disabled={Change === "Status" ? true : false}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 2,
      label: "License Plate",
      render: (row) => <>{row?.license_plate ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Year",
      render: (row) => <>{row?.year ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "Type",
      render: (row) => <>{row?.type ?? "-"}</>,
      width: "200px",
    },
    {
      id: 5,
      label: "Make",
      render: (row) => <>{row?.make ?? "-"}</>,
      width: "200px",
    },
    {
      id: 6,
      label: "Model",
      render: (row) => <>{row?.model ?? "-"}</>,
      width: "200px",
    },
    {
      id: 7,
      label: "Color",
      render: (row) => (
        <>
          <div
            className="dispscreen__tdata_vcolor "
            style={{ background: row?.color }}
          ></div>
        </>
      ),
      width: "200px",
    },
    {
      id: 8,
      label: "Weight",
      render: (row) => <>{`${row.weight_in_lbs} lb`}</>,
      width: "200px",
    },
    {
      id: 9,
      label: "Volume",
      render: (row) => <>{`${row.volume_in_cbm} cbm`}</>,
      width: "200px",
    },
    {
      id: 10,
      label: "Actions",
      render: (row) => (
        <div className="table_action__wrap">
          <Tooltip title="Edit Vehicle">
            <div
              className="table_action__btn"
              onClick={() => setManageVehicleModal(row)}
            >
              <FaRegEdit size={7} />
              Edit
            </div>
          </Tooltip>
          <Tooltip title="Delete Vehicle">
            <div
              className="table_action__btn"
              onClick={() => setDeleteVehicleModal(row.id)}
            >
              <BsTrash size={7} />
              Delete
            </div>
          </Tooltip>
        </div>
      ),
      width: "200px",
      fixed: "right",
    },
  ];

  const deleteSingleVehicle = async () => {
    try {
      setLoading(true);
      const deleted = await deleteVehicle(deleteVehicleModal);
      if (deleted.error) {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: deleted?.error?.message || "Couldn't delete vehicle",
            severity: "error",
          };
        });
        setLoading(false);
      } else {
        setVehicles((prevData) => {
          return {
            ...prevData,
            data: prevData?.data?.filter(
              (vehicle) => vehicle.id !== deleteVehicleModal
            ),
          };
        });
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: deleted?.data?.message,
            severity: "success",
          };
        });
        setLoading(false);
        setDeleteVehicleModal(false);
      }
    } catch (error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: error?.message || "Something went wrong",
          severity: "error",
        };
      });
      setLoading(false);
    }
  };

  const getAllVehicles = async (page, perPage, filter) => {
    const response = await fetchAllVehicles(page, perPage, filter);
    if (!response.error) {
      setVehicles(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const handleStatusChange = async (e, record) => {
    try {
      setChange(true);
      setVehicles((prevData) => {
        return {
          ...prevData,
          data: prevData?.data?.map((item) =>
            item.id == record.id
              ? {
                  ...item,
                  active: e.target.checked ? "true" : "false",
                }
              : item
          ),
        };
      });
      const updated = await changeStatus({
        record_id: parseInt(record.id),
        active: e.target.checked ? "true" : "false",
      });
      if (updated.error) {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: updated.error.message,
            severity: "error",
          };
        });
      } else {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: `Status of vehicle changed to ${
              e.target.checked ? "Active" : "Inactive"
            }`,
            severity: e.target.checked ? "success" : "info",
          };
        });
      }
      setChange(false);
    } catch (error) {
      setSnack((snack) => ({
        ...snack,
        open: true,
        text: error?.message || "Something went wrong",
        severity: "error",
      }));
    }
  };

  return (
    <div className="dispatcher__screen" style={{ maxWidth: "100%" }}>
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <VehicleForm
        open={manageVehicleModal}
        setOpen={setManageVehicleModal}
        setSnack={setSnack}
        setRefresh={setRefresh}
      />
      <Confirm
        open={deleteVehicleModal}
        setOpen={setDeleteVehicleModal}
        handleConfirm={deleteSingleVehicle}
        text="Do you want to delete this vehicle?"
        loader={loading}
      />
      <div className="vehicle_header">
        <div>
          <span className="vehicle_hdrheading">Vehicles List</span>
        </div>
        <div className="vehicle_page_headerrt_wrap">
          <div className="vehicle_page_headerrt">
            <div
              onClick={() => setManageVehicleModal(true)}
              className="vehicle_page_hdraddprod"
            >
              <FcPlus size={10} />
              <span>ADD VEHICLE</span>
            </div>
          </div>
        </div>
      </div>
      <div className="custom__table_size1 actionbtn__needtobe_fixwidth">
        <VehiclesTable
          message={"No Vehicles Available To Display!"}
          searchPlaceHolder={"Search Vehicles"}
          getAllData={getAllVehicles}
          data={vehicles}
          columns={columns}
          refresh={refresh}
        />
      </div>
    </div>
  );
};

export default Vehicle;
