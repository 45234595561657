import React, { useState } from "react";
import Person4Icon from "@mui/icons-material/Person4";
import { MdOutlineLibraryAdd, MdOutlineListAlt } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import "./TableOfContent.css";
import { useEffect } from "react";

const TableOfContent = ({
  setNavToggle,
  navToggle,
  active,
  handelActive,
  contentList,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={!navToggle ? "toc__sidebar_wrapper" : "toc__sidebar_wrapper2"}
    >
      <div className="toc__nav_items_box ">
        {contentList.map(({ heading, menus }, index) => {
          return (
            <div className="toc__nav_items_active" key={index}>
              <div className="toc__sidebar_heading">{heading}</div>
              {menus.map(({ name, id }, mindex) => {
                return (
                  <a
                    className={
                      false
                        ? "toc__mitems_parent active__class"
                        : "toc__mitems_parent"
                    }
                    key={id}
                    href={`#${id}`}
                    // onClick={() =>
                    //   handelActive((index + "_" + mindex).toString())
                    // }
                  >
                    <div className="toc__mitems">{name}</div>
                  </a>
                );
              })}

              <div className="toc__divider_line"></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TableOfContent;
