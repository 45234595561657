import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const TabsComponent = ({ items, tab, setTab, handleTabChange }) => {
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };
    return (
        <div className="w-full flex justify-center">
            <Tabs value={tab} onChange={handleTabChange ?? handleChange} variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile  >
                {items.map(({ label, style, value, name }) => {
                    return (
                        <Tab
                            label={label}
                            key={value}
                            style={{
                                ...style,
                            }}
                            value={value}
                            name={name}
                        />
                    );
                })}
            </Tabs>
        </div>
    );
};

export default TabsComponent;
