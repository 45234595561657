import React from "react";
import "./Welcome.css";
import Tick from "../../../../assets/icons/tickIcon.png";
import Logo from "../../../../assets/images/logo_login.png";
import { useLocation, useNavigate } from "react-router-dom";
const Welcome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const redirectMe = (url) => {
    window.location.href = url;
  };
  return (
    <div className="welcome_reguser_screen_wrap">
      <div className="welcome_reguser_screen">
        <div className="welcome_reguser_header">
          <img
            src={Logo}
            alt=""
            className="regform_sheader_image"
            onClick={() => redirectMe("https://hajexbolt.com/")}
          />
        </div>
        <div className="welcome_reguserscreen_heading">
          {location.state?.username}, You are registered successfully!
        </div>
        <div className="welcome_reguserscreen_subheading">
          We will notify you on "{location.state?.email}" once your account is
          activated
        </div>
        <div className="welcome_reguser_screentickwrapper">
          <div className="welcome_reguser_screentickwrap">
            <img src={Tick} alt="" className="welcome_reguser_screentick" />
          </div>
        </div>
        <div className="welcome_reguser_screenbtnwrapper">
          <div
            className="welcome_reguser_screenbtn"
            onClick={() => {
              redirectMe("https://hajexbolt.com/");
            }}
          >
            Go back to Home
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
