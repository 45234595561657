import ListGroup from "react-bootstrap/ListGroup";
import Tooltip from "../../../../../../../Components/Tooltip/Tooltip";
import { GoPackage } from "react-icons/go";
import React, { useState, useEffect } from "react";
import { setdata } from "../../../../../../../helpers/helpers";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import Sad from "@mui/icons-material/SentimentVeryDissatisfied";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import { fetchAllPackages } from "../../../../../../../services/ShipperServices/Packages/packages.services";

export default function PackageGridView(props) {
  const { handlescrollpackage, setFilter, refresh } = props;

  const [page, setPage] = useState(1);
  const [Packages, setPackages] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchPackages, setsearchPackages] = useState("");
  const [Packageloader, setPackageloader] = useState(false);

  const handlePackageschange = (e) => {
    const { value } = e.target;
    setsearchPackages(value);
  };

  const handlePrevPage = () => {
    setPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const getAllPackages = async (page, perPage, filter) => {
    setPackageloader(true);
    const response = await fetchAllPackages(page, perPage, filter);

    if (!response.error) {
      setPackages(response.data.result.data);
      setTotalPages(
        Math.ceil(response.data.result.total / response.data.result.per_page)
      );
      setPackageloader(false);
    } else {
      setPackageloader(false);
    }
  };

  useEffect(() => {
    getAllPackages(page, 20, searchPackages);
  }, [page, searchPackages, refresh]);

  useEffect(() => {
    const filterElement = (
      <>
        {(Packageloader === true ||
          searchPackages !== "" ||
          totalPages > 0) && (
          <div className="product__scrollbar_list_filter_field_wrap">
            <input
              type="text"
              value={searchPackages}
              onChange={(e) => handlePackageschange(e)}
              placeholder="Filter Packages"
              className="product__scrollbar_list_filter_field"
            />
            {Packageloader === true && (
              <CircularProgress
                size={12}
                style={{
                  color: "#C1C1C1",
                  marginLeft: "-20px",
                  marginTop: "15px",
                  transform: "translateY(-50%)",
                }}
              />
            )}
          </div>
        )}
      </>
    );

    setFilter(filterElement);
  }, [Packageloader, searchPackages, totalPages]);

  return (
    <>
      {totalPages > 0 ? (
        <div className="d-flex flex-row mt-2">
          <IconButton
            className="mr-1 icon_btn_arrow"
            disabled={page === 1}
            onClick={handlePrevPage}
            disableFocusRipple
          >
            <FaChevronLeft />
          </IconButton>
          <ListGroup
            horizontal
            className="overflow-auto horiscl p-2 package__scoller_list_main_wrap"
            style={{
              maxHeight: "108px",
              cursor: "pointer",
              display: "-webkit-box",
              overflowX: "auto",
              overflowY: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              WebkitScrollbarTrack: {
                background: "#000000",
              },
            }}
          >
            {Packages.map((data, index) => (
              <Tooltip title={setdata(() => data.name, false, "")} key={index}>
                <ListGroup.Item
                  className="mr-2 mb-1 shadow single_list package__scoller_list_box_wrap"
                  style={{
                    width: "150px",
                    border: "1px solid #C8C9CA",
                    borderRadius: "4px",
                    padding: "2px",
                    flexShrink: 0,
                  }}
                  onClick={() => handlescrollpackage(data)}
                >
                  <div className="productList__card_wrap">
                    <div className="productList__card_details_wrap">
                      <div className="productList__card_details_img">
                        {data.file ? (
                          <img src={data.file} loading="lazy" />
                        ) : (
                          <GoPackage size={25} style={{ color: "#C8C9CA" }} />
                        )}
                      </div>
                      <div className="productList__card_title">
                        <span>{setdata(() => data.name, false, "")}</span>
                      </div>
                      <div className="productList__card_details_measures">
                        <div className="productList__card_details_measures_weight">
                          <span> {setdata(() => data.weight, false, "0")}</span>
                          W
                        </div>
                        <div className="productList__card_details_measures_LWH">
                          <div className="productList__card_details_measures_L">
                            <span>
                              {setdata(() => data.length, false, "0")}
                            </span>
                            L
                          </div>
                          <div className="productList__card_details_measures_W">
                            <span>{setdata(() => data.width, false, "0")}</span>
                            W
                          </div>
                          <div className="productList__card_details_measures_H">
                            <span>
                              {setdata(() => data.height, false, "0")}
                            </span>
                            H
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ListGroup.Item>
              </Tooltip>
            ))}
          </ListGroup>
          <IconButton
            className="mr-1  icon_btn_arrow"
            disabled={page === totalPages || totalPages === 0}
            onClick={handleNextPage}
            disableFocusRipple
          >
            <FaChevronRight />
          </IconButton>
        </div>
      ) : (
        <div
          className="driver__screen_empty"
          style={{ minHeight: "12vh", width: "100%" }}
        >
          <div className="driver__scrnempt_content">
            <Sad className="driver__scrnempt_contenticon" />
            <div>No Packages to display!</div>
          </div>
        </div>
      )}
    </>
  );
}
