import React, { useState, useEffect } from "react";
import { BiCloudUpload } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import noImage from "../../../../assets/images/noImage.png";
import pdfIcon from "../../../../assets/icons/pdfIcon.png";
import excelIcon from "../../../../assets/icons/excelIcon.png";
import { checkFileType } from "../../../../helpers/helpers";
import TextField from "@mui/material/TextField";

const NotesAndAttachments = ({ formData, setFormData }) => {
  const [uploadedImages, setUploadedImages] = useState([]);

  const handleImageUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files);

    setUploadedImages([...uploadedFiles, ...uploadedImages]);
  };

  const handleFieldChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRemoveImage = (index) => {
    if (uploadedImages[0]?.file_url) {
      setFormData({
        ...formData,
        deletedMediaIds: [
          ...formData?.deletedMediaIds,
          uploadedImages[index]?.id,
        ],
      });
    }
    const updatedImages = [...uploadedImages];
    updatedImages.splice(index, 1);

    setUploadedImages(updatedImages);
  };

  useEffect(() => {
    setFormData({
      ...formData,
      grnNotesMedia: uploadedImages,
    });
  }, [uploadedImages]);

  return (
    <div>
      <section className="sss__payment_section">
        <div className="sss__prodsection_heading">Note's & Attachment's</div>
        <div
          className="sss__addressection_inputrow"
          style={{ marginTop: "10px", marginBottom: "0px" }}
        >
          <div
            className="sss__addressection_inputcol"
            style={{ minWidth: "200px" }}
          >
            <div className="sss__addressection_inputbox">
              <TextField
                size="small"
                multiline
                rows={4}
                maxRows={4}
                fullWidth
                id="outlined-basic"
                label="Add Note"
                name="description"
                value={formData.notes}
                onChange={(e) => handleFieldChange("notes", e.target.value)}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  input: { font: "menu" },
                }}
              />
            </div>
          </div>
        </div>
        <div className="attachments__formsection_mediabox">
          <>
            <div className="attachments__formsec_imagebtns">
              <label className="attachments__formsec_uploadimagebtn">
                <BiCloudUpload
                  size={20}
                  className="attachments__formsection_mdbtnicon"
                />
                Upload Attachments
                <input
                  type="file"
                  hidden
                  multiple
                  id="product_media"
                  onChange={handleImageUpload}
                />
              </label>
            </div>
            <div className="attachments__formsection_mdbtmtext">
              You can upload multiple Attachments.
            </div>
          </>
        </div>
        <div
          className="attachments__secformrow_images"
          style={{
            marginBottom: uploadedImages?.length < 1 ? "0px" : "",
          }}
        >
          {formData?.grnNotesMedia?.map((image, index) => (
            <div className="attachments__secformrow_imagewrap">
              <MdClose
                className="attachments__secformrow_imageclose"
                size={20}
                onClick={() => handleRemoveImage(index)}
              />
              <img
                src={
                  checkFileType(image?.name) === "Image"
                    ? URL.createObjectURL(image)
                    : checkFileType(image?.name) === "PDF"
                    ? pdfIcon
                    : checkFileType(image?.name) === "Excel"
                    ? excelIcon
                    : noImage
                }
                accept="image/*"
                loading="lazy"
                className="attachments__secformrow_image"
                onError={(e) => {
                  e.target.src = noImage;
                }}
              />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default NotesAndAttachments;
