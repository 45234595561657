import { useState, useEffect, useRef } from "react";

function useAutoAdjustTableHeight(bottomRef) {
  const [tableHeight, setTableHeight] = useState(0);
  const tableRef = useRef(null);

  useEffect(() => {
    const calculateHeight = () => {
      const viewportHeight = window.innerHeight;
      const offsetTop = tableRef.current?.getBoundingClientRect().top || 0;
      const bottomOffset =
        bottomRef.current?.getBoundingClientRect().height || 0;
      const availableHeight = viewportHeight - offsetTop - bottomOffset - 20;
      setTableHeight(availableHeight);
    };

    calculateHeight();

    window.addEventListener("resize", calculateHeight);

    const resizeObserver = new ResizeObserver(calculateHeight);
    if (tableRef.current) {
      resizeObserver.observe(document.body);
    }

    return () => {
      window.removeEventListener("resize", calculateHeight);
      resizeObserver.disconnect();
    };
  }, [bottomRef, tableRef.current]);

  return { tableHeight, tableRef };
}

export default useAutoAdjustTableHeight;
