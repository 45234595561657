import TextField from "@mui/material/TextField";

export default function AddressForm(props) {
  const {
    Label,
    Width = "100%",
    SX = {
      width: Width,
      input: { font: "menu" },
    },
    Name,
    Data,
    Handler,
    Required = true,
    Type = "text",
    InputProps = {},
    Multiline = false,
    Row = 0,
    PlaceHolder = Label,
    Disabled = false,
    Validation = {},
    HelperText = "",
  } = props;

  return (
    <>
      <TextField
        label={Label}
        type={Type}
        name={Name}
        value={Data}
        onChange={(e) => Handler(e)}
        placeholder={PlaceHolder}
        required={Required}
        sx={SX}
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
        InputProps={InputProps}
        multiline={Multiline}
        rows={Row}
        disabled={Disabled}
        inputProps={Validation}
        helperText={
          HelperText ? (
            <span
              style={{
                color: "gray",
                fontSize: "10px",
                lineHeight: "1",
                letterSpacing: "0",
              }}
            >
              <small>{HelperText}</small>
            </span>
          ) : (
            ""
          )
        }
      />
    </>
  );
}
