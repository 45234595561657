import React, { useState, useEffect } from "react";
import "./invoices.css";

// components imports
import UserInvoicsTable from "../../../../../../../Components/BoltTable/BoltTable";
import Tooltip from "../../../../../../../Components/Tooltip/Tooltip";

// apis imports
import { fetchUserInvoices } from "../../../../../../../services/ShipperServices/Payments/payments.services";

// library imports
import { MdOutlineStickyNote2 } from "react-icons/md";
import CircularProgress from "@mui/material/CircularProgress";

const Invoices = ({ setSnack }) => {
  const [transactions, settransactions] = useState([]);
  const [actionLoader, setActionLoader] = useState(false);

  const getAllUserInvoices = async (page, perPage, filter) => {
    const response = await fetchUserInvoices(page, perPage, filter);
    if (!response.error) {
      settransactions(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 1,
      label: "Invoice Number",
      field_name: "invoice_no",
      render: (row, rowIndex, handleFieldChange) => (
        <>{row?.invoice_no ?? "-"}</>
      ),
      width: "200px",
    },
    {
      id: 2,
      label: "Sub Total",
      field_name: "actual_price",
      render: (row, rowIndex, handleFieldChange) => (
        <>{Number(row?.actual_price)?.toFixed(2) ?? "-"}</>
      ),
      width: "200px",
    },
    {
      id: 3,
      label: "Taxes",
      field_name: "tax_details",
      render: (row, rowIndex, handleFieldChange) => (
        <>
          {row?.tax_details?.length > 0 ? (
            <>
              {row?.tax_details?.map(({ name }, index) => {
                return (
                  <>
                    <span key={index}>
                      {name}
                      {index === 0 ? "" : ","}
                    </span>
                  </>
                );
              })}
            </>
          ) : (
            "-"
          )}
        </>
      ),
      width: "200px",
    },
    {
      id: 4,
      label: "Tax Total",
      field_name: "tax_price",
      render: (row, rowIndex, handleFieldChange) => (
        <>{Number(row?.tax_price)?.toFixed(2) ?? "-"}</>
      ),
      width: "200px",
    },
    {
      id: 5,
      label: "Total",
      field_name: "total_price",
      render: (row, rowIndex, handleFieldChange) => (
        <>{Number(row?.total_price)?.toFixed(2) ?? "-"}</>
      ),
      width: "200px",
    },
    {
      id: 6,
      label: "Created At",
      field_name: "created_at",
      render: (row, rowIndex, handleFieldChange) => (
        <>{row?.created_at?.slice(0, 10) ?? "-"}</>
      ),
      width: "200px",
    },
    {
      id: 7,
      label: "Actions",
      field_name: "actions",
      render: (row, rowIndex, handleFieldChange) => (
        <div className="table_action__wrap">
          <Tooltip title="Download Invoice">
            <div className="table_action__btn" onClick={() => downloadPdf(row)}>
              {actionLoader === row.id ? (
                <CircularProgress size={10} />
              ) : (
                <MdOutlineStickyNote2 size={10} />
              )}
              INVOICE
            </div>
          </Tooltip>
        </div>
      ),
      width: "200px",
    },
  ];

  const downloadPdf = async (data) => {
    setActionLoader(data.id);
    const urlfull = data?.file;
    const urlParts = urlfull.split("/");
    const filename = urlParts[urlParts.length - 1];
    try {
      fetch(urlfull, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      })
        .then((url) => url.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${filename}`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setActionLoader(false);
        });
    } catch (error) {
      setActionLoader(false);
    }
  };

  return (
    <div>
      <div>
        <div className="payment__invoices_header">
          <div>
            <span className="payment__invoices_heading">Invoices List</span>
          </div>
        </div>

        <UserInvoicsTable
          message={"No Invoice Available To Display!"}
          searchPlaceHolder={"Search Invoices"}
          getAllData={getAllUserInvoices}
          data={transactions}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default Invoices;
