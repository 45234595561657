import React, { useState, useRef, useEffect } from "react";
import "./Category.css";

// components imports
import CategoryTable from "../../../Components/BoltTable/BoltTable";
import Confirm from "../../../Components/Popups/Confirm";
import AddCategory from "./Components/AddCategory/AddCategory";
import EditCategory from "./Components/EditCategory/EditCategory";
import AddSubCategory from "./Components/AddSubCategory/AddSubCategory";
import Tooltip from "../../../Components/Tooltip/Tooltip";
// apis imports
import {
  getAllCategoryList,
  deleteCategory,
} from "../../../services/warehouse.services";
import Expandable from "./Components/Expandable/Expandable";
// library imports
import { MdOutlineCancel, MdAdd } from "react-icons/md";
import { FcPlus } from "react-icons/fc";
import { FaRegEdit } from "react-icons/fa";
import { showSnackBar } from "../../../store/common/snackBarSlice";
import { useDispatch } from "react-redux";

const Category = () => {
  const [addCategoryOpen, setAddCategoryOpen] = useState(false);
  const [editCategoryOpen, setEditCategoryOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [addSubCategoryOpen, setAddSubCategoryOpen] = useState(false);
  const [deleteLoader, setdeleteLoader] = useState(false);

  const [confirmDelete, setConfirmDelete] = useState(false);

  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();

  const columns = [
    {
      id: 1,
      label: "Name",
      render: (row) => <>{row?.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 2,
      label: "Action",
      render: (row) => (
        <>
          <div className="table_action__wrap">
            <Tooltip title="Add Sub Category">
              <div
                className="table_action__btn"
                onClick={() => setAddSubCategoryOpen(row)}
              >
                <MdAdd size={10} />
                Add Sub Category
              </div>
            </Tooltip>

            <Tooltip title="Edit Category Details">
              <div
                className="table_action__btn"
                onClick={() => setEditCategoryOpen(row)}
              >
                <FaRegEdit size={10} />
                Edit
              </div>
            </Tooltip>

            <Tooltip title="Delete Category">
              <div
                className="table_action__btn"
                onClick={() => setConfirmDelete(row)}
              >
                <MdOutlineCancel size={10} />
                Delete
              </div>
            </Tooltip>
          </div>
        </>
      ),
      width: "200px",
    },
  ];

  const fetchAllCategories = async (page, perPage, filter) => {
    const response = await getAllCategoryList(page, perPage, filter);

    if (!response.error) {
      setCategories(response?.data?.result);
    } else {
      dispatch(showSnackBar({
        text: "Something went wrong",
        severity: "error",
      }))
    }
  };

  const handleDeleteRequest = async (data) => {
    if (data.id) {
      setdeleteLoader(true);
      const response = await deleteCategory(data.id);

      if (!response.error && response?.data?.success) {
        setCategories((prevCategories) => {
          return {
            ...prevCategories,
            data: prevCategories?.data?.filter((d) => d?.id != data?.id),
          };
        });
        setdeleteLoader(false);
        setConfirmDelete(false);
        dispatch(showSnackBar({
          text: "Request deleted successfully",
          severity: "success",
        }))
      } else {
        setdeleteLoader(false);
        dispatch(showSnackBar({
          text:
            response?.error?.response?.data?.message ||
            "Couldn't cancel this request!",
          severity: "error",
        }))
      }
    }
  };
  return (
    <>
      <Confirm
        open={confirmDelete}
        setOpen={setConfirmDelete}
        handleConfirm={() => handleDeleteRequest(confirmDelete)}
        text="Do You Want To Delete This Request ?"
        loader={deleteLoader}
      />
      <div className="category_header">
        <div>
          <span className="category_hdrheading">Category List</span>
        </div>
        <div className="category_buttonsWrapper">
          <div onClick={() => setAddCategoryOpen(true)}>
            <div className="category_hdraddprod">
              <FcPlus size={10} />
              <span>ADD CATEGORY</span>
            </div>
          </div>
        </div>
      </div>

      <div>
        <CategoryTable
          message={"No Category To Display!"}
          searchPlaceHolder={"Search Categories"}
          expandable={true}
          detailsComponent={(data) => (
            <Expandable
              data={data}
              categories={categories}
              setCategories={setCategories}
            />
          )}
          columns={columns}
          data={categories}
          getAllData={fetchAllCategories}
          refresh={refresh}
        />
      </div>

      <AddCategory
        open={addCategoryOpen}
        setOpen={setAddCategoryOpen}
        setRefresh={setRefresh}
      />
      <EditCategory
        open={editCategoryOpen}
        setOpen={setEditCategoryOpen}
        setCategories={setCategories}
      />
      <AddSubCategory
        open={addSubCategoryOpen}
        setOpen={setAddSubCategoryOpen}
        setCategories={setCategories}
      />
    </>
  );
};
export default Category;
