import React, { useEffect, useState } from "react";
import "./UpdateBundle.css";

// components imports
import Loader from "../../../../../../../Components/CircularLoader/CircularLoader";
import Confirm from "../../../../../../../Components/Popups/Confirm";

// apis imports
import {
  updateBundle,
  deletePackage,
  updatePackage,
  addNewPackage,
} from "../../../../../../../services/ShipperServices/Packages/packages.services";
import { generateUniqueId } from "../../../../../../../helpers/helpers";

// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import Upload from "@mui/icons-material/CloudUpload";
import {
  MdDeleteForever,
  MdClose,
  MdMode,
  MdDone,
  MdAdd,
} from "react-icons/md";
import CircularProgress from "@mui/material/CircularProgress";

const initialData = {
  name: "",
  description: "",
  file: "",
  packages: [
    {
      id: 1,
      name: "",
      description: "",
      save_name: null,
      length: "",
      weight: "",
      width: "",
      height: "",
      unit: "Imperial",
    },
  ],
};

export default function UpdateBundle({
  open,
  setOpen,
  setSnack,
  setPackages,
  bundlesData,
}) {
  const [deleteLoader, setDeleteLoader] = useState(false);

  const [data, setData] = useState(initialData);
  const [savedData, setSavedData] = useState();
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editPackage, setEditPackage] = useState();
  const [updatingPackage, setUpdatingPackage] = useState();
  const [addingPackage, setAddingPackage] = useState();
  // const [deletingPackage, setDeletingPackage] = useState();
  const [deletePackageModal, setDeletePackageModal] = useState();
  const [unit, setUnit] = useState("Imperial");

  const handleClose = () => {
    setEditPackage(false);
    setData(initialData);
    setOpen(false);
  };
  const user = useSelector((state) => state.user);
  const handleChange = (e) => {
    setChanged(true);
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handlePackageChange = (e, pkgidx) => {
    const { name, value } = e.target;
    setData((prevData) => {
      const newPackages = [...prevData.packages];
      newPackages[pkgidx] = {
        ...newPackages[pkgidx],
        [name]: value,
      };
      return {
        ...prevData,
        packages: newPackages,
      };
    });
  };

  const handleFileChange = (e) => {
    setChanged(true);
    setData({ ...data, file: e.target.files[0] });
  };
  const handleEditPackage = (pkg) => {
    setEditPackage(pkg);
  };
  const handlePackageUpdate = async (pkg) => {
    setUpdatingPackage(true);
    const {
      created_at,
      updated_at,
      deleted_at,
      file,
      remember_token,
      save_name,
      ...pakg
    } = pkg;
    const updated = await updatePackage({ ...pakg, unit: unit }, pkg.id);
    setUpdatingPackage(false);
    if (!updated.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Package updated successfully",
          severity: "success",
        };
      });
      setSavedData(data);
      setEditPackage(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong!",
          severity: "error",
        };
      });
      setEditPackage(false);
    }
  };
  const handleSave = async () => {
    if (!changed) {
      handleClose();
      return;
    }
    setLoading(true);

    const isDataValid = Object.entries(data).every(([key, value]) => {
      //   if (key === "packages") {
      //     return value.every((pkg) => {
      //       return Object.values(pkg).every((pkgValue) => pkgValue !== "");
      //     });
      //   }
      return key === "file" || value !== "";
    });

    if (!isDataValid) {
      setLoading(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "All fields are required",
          severity: "error",
        };
      });
      return;
    }

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);

    if (data.file) {
      formData.append("file", data.file);
    }
    const saved = await updateBundle(formData, data.id);
    setLoading(false);
    if (!saved.error) {
      setChanged(false);
      setPackages(saved.data?.result);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Bundle Updated successfully",
          severity: "success",
        };
      });
      handleClose();
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };
  const handleAddPackage = () => {
    setData({
      ...data,
      packages: [
        ...data.packages,
        {
          id: generateUniqueId(),
          name: "",
          description: "",
          save_name: null,
          length: "",
          weight: "",
          width: "",
          height: "",
          unit: "Imperial",
        },
      ],
    });
  };
  function areFieldsNotEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (!obj[key] || obj[key].trim() === "") {
          return false;
        }
      }
    }
    return true;
  }
  const handleAddNewPackage = async (pkg) => {
    const {
      created_at,
      updated_at,
      deleted_at,
      file,
      remember_token,
      save_name,
      ...pakg
    } = pkg;
    const valid = areFieldsNotEmpty(pakg);
    if (!valid) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "All fields are required!",
          severity: "error",
        };
      });
      return;
    }
    setAddingPackage(pkg?.id);
    const added = await addNewPackage({
      ...pakg,
      unit: unit,
      bundle_id: savedData.id,
    });
    setAddingPackage(false);
    if (!added.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Package Added successfully",
          severity: "success",
        };
      });
      setSavedData({ ...savedData, packages: [...savedData.packages, pakg] });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong!",
          severity: "error",
        };
      });
    }
  };
  const handleDeletePackage = (id) => {
    setData((prevData) => ({
      ...prevData,
      packages: prevData.packages.filter((pkg) => id !== pkg.id),
    }));
  };

  const deleteSelectedPackage = async (id) => {
    setDeleteLoader(true);
    const updated = await deletePackage(id);

    if (!updated.error) {
      handleDeletePackage(id);
      setSavedData((prevData) => ({
        ...prevData,
        packages: prevData.packages.filter((pkg) => id !== pkg.id),
      }));
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Package deleted successfully",
          severity: "success",
        };
      });
      setDeleteLoader(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong!",
          severity: "error",
        };
      });
      setDeleteLoader(false);
      setDeletePackageModal(false);
    }
  };

  useEffect(() => {
    if (bundlesData) {
      setData({ ...bundlesData, file: null });
      setSavedData(bundlesData);
      if (bundlesData?.packages?.length) {
        setUnit(bundlesData?.packages[0]?.unit);
      }
    }
  }, [bundlesData]);
  useEffect(() => {
    setPackages((prevState) => {
      return {
        ...prevState,
        data: prevState?.data?.map((bdl) => {
          if (bdl?.id === savedData?.id) {
            return savedData;
          } else {
            return bdl;
          }
        }),
      };
    });

    // setPackages((prevData) => {
    //   // console.log("prevData", prevData?.data);
    //   return prevData?.data?.map((bdl) => {
    //     if (bdl?.id === savedData?.id) {
    //       return savedData;
    //     } else {
    //       return bdl;
    //     }
    //   });
    // });
  }, [savedData]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="update__bundle_modal">
          <Confirm
            open={deletePackageModal}
            setOpen={setDeletePackageModal}
            handleConfirm={() => {
              deleteSelectedPackage(deletePackageModal.id);
            }}
            text="Do you want to delete selected packages?"
            loader={deleteLoader}
          />
          <div className="update__bundle_modalheading">Update Bundle</div>
          <div className="update__bundle_modalcontent">
            <div className="update__bundle_modalrow">
              <div className="update__bundle_modalinputwrap">
                <label className="update__bundle_modalinputlbl">
                  Bundle Name
                </label>
                <input
                  className="update__bundle_modalinput"
                  placeholder="Bundle Name"
                  name="name"
                  value={data.name}
                  onChange={handleChange}
                />
              </div>
              <div className="update__bundle_modalinputwrap">
                <label className="update__bundle_profimagelbl">
                  <Upload /> {data.file?.name ?? "UPLOAD BUNDLE IMAGE"}
                  <input
                    type="file"
                    size="60"
                    className="update__bundle_profimageinput"
                    onChange={handleFileChange}
                    accept=".jpg,.png"
                  />
                </label>
              </div>
            </div>

            <div className="update__bundle_modalrow">
              <div
                className="update__bundle_modalinputwrap"
                style={{ minWidth: "65%" }}
              >
                <label className="update__bundle_modalinputlbl">
                  Bundle Description
                </label>
                <input
                  className="update__bundle_modalinput"
                  placeholder="Bundle Description"
                  name="description"
                  value={data.description}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="update__bundle_modalrowspacebetween">
              <h6 className="update__bundle_mrpkgsheading">Packages</h6>
              <div className="add_bundle__unitbtnswrap">
                <div
                  className={`add_bundle__unitbtn ${
                    unit === "Imperial" && "add_bundle__unitbtnactive"
                  }`}
                  onClick={() => setUnit("Imperial")}
                >
                  Imperial
                </div>
                <div
                  className={`add_bundle__unitbtn ${
                    unit === "Metric" && "add_bundle__unitbtnactive"
                  }`}
                  onClick={() => setUnit("Metric")}
                >
                  Metric
                </div>
              </div>
            </div>

            <div className="update__bundle_mrpkgslist">
              {data.packages?.map((pkg, pkgidx) => {
                return (
                  <div
                    className={`update__bundle_modalrow ${
                      editPackage?.id !== pkg?.id &&
                      pkgidx < savedData?.packages?.length
                        ? "update__bundle_modalrowdisabled"
                        : "update__bundle_modalrowenabled"
                    } ${
                      updatingPackage || deleteLoader
                        ? "update__bundle_modalwholerowdisabled"
                        : ""
                    }`}
                    key={pkg?.id}
                  >
                    <div
                      className={`update__bundle_modalinputwrap `}
                      style={{ maxWidth: "180px" }}
                    >
                      <label className="update__bundle_modalinputlbl">
                        Package Name
                      </label>
                      <input
                        className="update__bundle_modalinput"
                        placeholder="Package Name"
                        name="name"
                        onChange={(e) => handlePackageChange(e, pkgidx)}
                        value={pkg.name}
                      />
                    </div>
                    <div
                      className="update__bundle_modalinputwrap"
                      style={{ maxWidth: "80px" }}
                    >
                      <label className="update__bundle_modalinputlbl">
                        Length
                      </label>
                      <input
                        className="update__bundle_modalinput"
                        placeholder="Length"
                        name="length"
                        value={pkg.length}
                        type="number"
                        onChange={(e) => handlePackageChange(e, pkgidx)}
                      />
                    </div>
                    <div
                      className="update__bundle_modalinputwrap"
                      style={{ maxWidth: "80px" }}
                    >
                      <label className="update__bundle_modalinputlbl">
                        Width
                      </label>
                      <input
                        className="update__bundle_modalinput"
                        placeholder="Width"
                        name="width"
                        value={pkg.width}
                        type="number"
                        onChange={(e) => handlePackageChange(e, pkgidx)}
                      />
                    </div>
                    <div
                      className="update__bundle_modalinputwrap"
                      style={{ maxWidth: "80px" }}
                    >
                      <label className="update__bundle_modalinputlbl">
                        Height
                      </label>
                      <input
                        className="update__bundle_modalinput"
                        placeholder="Height"
                        name="height"
                        value={pkg.height}
                        type="number"
                        onChange={(e) => handlePackageChange(e, pkgidx)}
                      />
                    </div>
                    <div
                      className="update__bundle_modalinputwrap"
                      style={{ maxWidth: "80px" }}
                    >
                      <label className="update__bundle_modalinputlbl">
                        Weight
                      </label>
                      <input
                        className="update__bundle_modalinput"
                        placeholder="Weight"
                        name="weight"
                        value={pkg.weight}
                        type="number"
                        onChange={(e) => handlePackageChange(e, pkgidx)}
                      />
                    </div>
                    <div
                      className="update__bundle_modalinputwrap"
                      style={{ maxWidth: "250px" }}
                    >
                      <label className="update__bundle_modalinputlbl">
                        Description
                      </label>
                      <input
                        className="update__bundle_modalinput"
                        placeholder="Package Description"
                        name="description"
                        value={pkg.description}
                        onChange={(e) => handlePackageChange(e, pkgidx)}
                      />
                    </div>

                    {editPackage?.id !== pkg?.id &&
                      pkgidx === data?.packages?.length - 1 &&
                      pkgidx < savedData?.packages?.length && (
                        <div
                          className="update__bundle_modalinputwrap"
                          style={{ maxWidth: "50px" }}
                        >
                          <button
                            className={`update__bundle_addpkgbtn ${
                              pkgidx !== data?.packages?.length - 1 &&
                              "disable__click_fade"
                            }`}
                            onClick={handleAddPackage}
                          >
                            <MdAdd size={25} />
                          </button>
                        </div>
                      )}
                    {editPackage?.id !== pkg?.id &&
                      pkgidx < savedData?.packages?.length && (
                        <div
                          className="update__bundle_modalinputwrap"
                          style={{ maxWidth: "50px" }}
                        >
                          <button
                            className={`update__bundle_addpkgbtn `}
                            onClick={() => handleEditPackage(pkg)}
                          >
                            <MdMode size={25} />
                          </button>
                        </div>
                      )}
                    {editPackage?.id === pkg?.id && !updatingPackage && (
                      <div
                        className="update__bundle_modalinputwrap"
                        style={{ maxWidth: "50px" }}
                      >
                        <button
                          className={`update__bundle_addpkgbtn ${
                            pkgidx === 0 &&
                            data?.packages?.length === 1 &&
                            "disable__click_fade"
                          }`}
                          onClick={() => handlePackageUpdate(pkg)}
                        >
                          <MdDone size={30} />
                        </button>
                      </div>
                    )}
                    {pkgidx >= savedData?.packages?.length &&
                      addingPackage !== pkg?.id && (
                        <div
                          className="update__bundle_modalinputwrap"
                          style={{ maxWidth: "50px" }}
                        >
                          <button
                            className={`update__bundle_addpkgbtn ${
                              pkgidx === 0 &&
                              data?.packages?.length === 1 &&
                              "disable__click_fade"
                            }`}
                            onClick={() => handleAddNewPackage(pkg)}
                          >
                            <MdDone size={30} />
                          </button>
                        </div>
                      )}
                    {((editPackage?.id === pkg?.id && updatingPackage) ||
                      addingPackage === pkg?.id) && (
                      <div
                        className="update__bundle_modalinputwrap"
                        style={{ maxWidth: "50px" }}
                      >
                        <button
                          className={`update__bundle_addpkgbtn ${
                            pkgidx === 0 &&
                            data?.packages?.length === 1 &&
                            "disable__click_fade"
                          }`}
                        >
                          <CircularProgress color="inherit" size={20} />
                        </button>
                      </div>
                    )}
                    {editPackage?.id !== pkg?.id &&
                      data?.packages?.length !== 1 &&
                      pkgidx < savedData?.packages?.length && (
                        <div
                          className="update__bundle_modalinputwrap"
                          style={{ maxWidth: "50px" }}
                        >
                          <button
                            className={`update__bundle_delpkgbtn`}
                            onClick={() => setDeletePackageModal(pkg)}
                          >
                            <MdDeleteForever size={25} />
                          </button>
                        </div>
                      )}
                    {editPackage?.id !== pkg?.id &&
                      data?.packages?.length !== 1 &&
                      pkgidx >= savedData?.packages?.length && (
                        <div
                          className="update__bundle_modalinputwrap"
                          style={{ maxWidth: "50px" }}
                        >
                          <button
                            className={`update__bundle_delpkgbtn`}
                            onClick={() => handleDeletePackage(pkg?.id)}
                          >
                            <MdClose size={25} />
                          </button>
                        </div>
                      )}

                    <div
                      className="update__bundle_modalinputwrap"
                      style={{ maxWidth: "60px" }}
                    ></div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="update__bundle_modalfooter">
            <div className="update__bundle_modalcncl" onClick={handleClose}>
              Cancel
            </div>
            <div
              className={`update__bundle_modalsave ${
                loading && "update__bundle_disabled"
              }`}
              onClick={handleSave}
            >
              {loading ? <Loader /> : "Save"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
