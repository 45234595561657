import React, { useState } from "react";
import ProductTable from "../../../../../../../Components/BoltTable/BoltTable";
import { fetchAllBundles } from "../../../../../../../services/ShipperServices/Packages/packages.services";
import noImage from "../../../../../../../assets/images/noImage.png";
import { BsPlus } from "react-icons/bs";
import { PiPackageLight } from "react-icons/pi";

export default function BundleListView(props) {
  const { handlescrollpackage, refresh } = props;
  const [bundles, setBundles] = useState([]);

  const columns = [
    // {
    //   id: 0,
    //   label: "#",
    //   render: (row) => <>{row?.id ?? "-"}</>,
    //   width: "200px",
    // },
    {
      id: 1,
      label: " ",
      render: (row) => (
        <img
          src={row?.file ?? noImage}
          onError={(e) => {
            e.target.src = noImage;
          }}
          loading="lazy"
          style={{ maxWidth: "100%" }}
        />
      ),
      fixed: "left",
      width: "2vw",
    },
    {
      id: 2,
      label: "Name",
      render: (row) => (
        <>
          <div className="ship__page_myproduct_listing_bolt_table_name">
            {row.name ?? "-"}
          </div>
        </>
      ),
      width: "15vw",
    },
    {
      id: 4,
      label: "Packages",
      render: (row) => (
        <>
          <div
            className="product_dimension_parent_wrapper"
            style={{ maxWidth: "200px" }}
          >
            <div className="product__notification_circle_wrapper">
              <PiPackageLight size={10} />
              {row?.packages && Object?.values(row?.packages)?.length > 0 && (
                <div className="product__notification_circle">
                  {Object.values(row?.packages)?.length}
                </div>
              )}
            </div>
          </div>
        </>
      ),
      width: "5vw",
    },
    {
      id: 5,
      label: "Weight",
      render: (row) => (
        <>
          {row?.packages
            .map((packageItem) => parseFloat(packageItem.weight))
            .reduce((accumulator, weight) => accumulator + weight, 0)}
        </>
      ),
      width: "5vw",
    },
    // {
    //   id: 6,
    //   label: "Quantity",
    //   render: (row) => (
    //     <>
    //       <div className="product_quantity_parent_wrapper">
    //         <div className="product__textalign_center">
    //           {row?.product_warehouse?.reduce(
    //             (total, warehouse) =>
    //               total + parseInt(warehouse.quantity, 10) || 0,
    //             0
    //           )}
    //         </div>
    //       </div>
    //     </>
    //   ),
    //   width: "200px",
    // },
    // {
    //   id: 8,
    //   label: "Actions",
    //   render: (row) => (
    //     <>
    //       {" "}
    //       <div className="table_action__wrap">
    //         <div
    //           className="table_action__btn"
    //           onClick={() => handlescrollpackage(row)}
    //         >
    //           <BsPlus size={10} />
    //           Add
    //         </div>
    //       </div>
    //     </>
    //   ),
    //   width: "200px",
    // },
  ];

  const getAllBundles = async (page, perPage, filter) => {
    const response = await fetchAllBundles(page, perPage, filter);
    if (!response.error) {
      setBundles(response?.data?.result);
    } else {
    }
  };

  return (
    <>
      <div className="custom__table_size1 actionbtn__needtobe_fixwidth">
        <ProductTable
          message={"No Bundles Available To Display!"}
          searchPlaceHolder={"Search Bundles"}
          getAllData={getAllBundles}
          data={bundles}
          columns={columns}
          refresh={refresh}
          handleRowClick={handlescrollpackage}
          isRowClick="Click To Add"
        />
      </div>
    </>
  );
}
