import React, { useState } from "react";
import { IoInformationCircleOutline } from "react-icons/io5";
import Divider from "@mui/material/Divider";
import Tooltip from "../../../../../../Components/Tooltip/Tooltip";
import RadioStack from "./RadioStack";
import PackageList from "./PackageList";
import ProductList from "./ProductList";
import Buttons from "./Buttons";
import "./Package.css";
import { Stack, Typography, IconButton } from "@mui/material";

export default function Package(props) {
  const {
    Plist,
    StepCheck,
    SetStepCheck,
    SetPlist,
    Alist,
    SetAlist,
    Stepper,
    SelectedProducts,
    setSelectedProducts,
    isInternational,
    setbundleRefresh,
    setpackageRefresh,
  } = props;
  const [bundleShow, setbundleShow] = useState(false);
  const handleBundleShow = () => {
    setbundleShow(!bundleShow);
  };

  return (
    <div className="card bg-white shadow wapper_like_shopify">
      <div className="card-header package__dimension_header_wrap">
        <div className="package__dimension_header_content">
          <span>DIMENSIONS & WEIGHT</span>
          <Tooltip title="Details Of The Package you Want to Ship">
            <IconButton>
              <IoInformationCircleOutline size={15} />
            </IconButton>
          </Tooltip>
        </div>

        <div className="package__dimension_unit package__unit_btn_wrap">
          <span>Unit</span>
          <RadioStack
            List={StepCheck}
            SetList={SetStepCheck}
            Type="unit"
            Type1="Imperial"
            Type2="Metric"
          />
        </div>

        <div className="package__dimension_unit_bundle_btn">
          {SelectedProducts.length <= 0 && (
            <div className="package__dimension_wrap dimension_weight_card_wrap">
              <div className="package__dimension_weight_volume">
                <div className="package__dimension_weight_volume_label">
                  <div className="package__dimension_weight">
                    <span>Weight</span>
                    <small style={{ whiteSpace: "nowrap" }}>
                      {parseFloat(StepCheck["TotalWeight"] ?? 0).toFixed(2)}{" "}
                      {StepCheck["unit"] === "Imperial" ? "lb" : "kg"}
                    </small>
                  </div>
                  <div className="package__dimension_volume">
                    <span>Volume</span>
                    <small style={{ whiteSpace: "nowrap" }}>
                      {parseFloat(StepCheck["TotalVolume"] ?? 0).toFixed(2)}{" "}
                      {StepCheck["unit"] === "Imperial" ? "Cbm" : "Cbf"}
                    </small>
                  </div>
                  <div className="package__dimension_volume">
                    <span>Fulfilment Cost</span>
                    <small style={{ whiteSpace: "nowrap" }}>
                      {parseFloat(
                        StepCheck["TotalFulfilmentCost"] ?? 0
                      ).toFixed(2)}{" "}
                      $
                    </small>
                  </div>
                </div>
              </div>
            </div>
          )}

          {Stepper["Packages"] === 1 && StepCheck["Packages_bit"] === 0 && (
            <div className="package__dimension_weight_btn">
              <Buttons
                Name="Create A Bundle"
                Variant="contained"
                Color="primary"
                HandleChange={handleBundleShow}
                className="make__bundle_btn"
              />
            </div>
          )}
        </div>
      </div>
      <div className="card-body dimension_weight_card_body">
        {StepCheck["Warehouses"] && SelectedProducts.length > 0 && (
          <>
            <ProductList
              Plist={Plist}
              SetPlist={SetPlist}
              StepCheck={StepCheck}
              SetStepCheck={SetStepCheck}
              SelectedProducts={SelectedProducts}
              setSelectedProducts={setSelectedProducts}
            />
            <Divider component="li" style={{ marginBottom: "25px" }} />
          </>
        )}

        <PackageList
          Plist={Plist}
          SetPlist={SetPlist}
          Alist={Alist}
          StepCheck={StepCheck}
          SetStepCheck={SetStepCheck}
          setpackageRefresh={setpackageRefresh}
          Stepper={Stepper}
          bundleShow={bundleShow}
          setbundleShow={setbundleShow}
          isInternational={isInternational}
          setbundleRefresh={setbundleRefresh}
        />

        {StepCheck["TotalWeight"] > 0 && StepCheck["QuoteCheck"] === false && (
          <>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="center"
              mt={2}
              mb={2}
              sx={{ width: "100%" }}
            >
              <Buttons
                Name="Request A Quote"
                Variant="contained"
                Color="primary"
                TYPE="quote"
              />
            </Stack>
          </>
        )}
      </div>
    </div>
  );
}
