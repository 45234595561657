import React, { useState, useEffect } from "react";
import "./fulfilmentbydimension.css";
// components imports
import TextFeild from "../../Components/CustomTextFeild/TextFeild";
import AddDetailsByDimension from "./Components/AddDetailsByDimension/AddDetailsByDimension";
// apis imports
import {
  addFulfilmentTierFormula,
  updateFulfilmentTierFormula,
} from "../../../../../../../services/warehouse.services";
// library imports
import { MdClose, MdOutlineErrorOutline } from "react-icons/md";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import Loader from "../../../../../../../Components/CircularLoader/CircularLoader.jsx";

const FulfilmentByDimension = ({
  formulaCoppy,
  setFormulaCoppy,
  tierFormula,
  setTierFormula,
  isEditable,
  error,
  setError,
  setIsEditable,
  setOpen,
  open,
  setSnack,
}) => {
  const [loader, setLoader] = useState(false);
  const [lastDetailedIndexData, setLastDetailedIndexData] = useState(null);

  const [dimensionDetails, setDimensionDetails] = useState({
    min_length: "",
    min_width: "",
    min_height: "",
    max_length: "",
    max_width: "",
    max_height: "",
    value: "",
  });
  const handleChangeDetails = (e) => {
    setError(false);
    setDimensionDetails({
      ...dimensionDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleFieldChangeWeightFormula = (e, index) => {
    setTierFormula((formula) => {
      const updatedDimensions = [...formula.dimensions];
      const updatedDimension = {
        ...updatedDimensions[index],
        value: e.target.value,
      };
      updatedDimensions[index] = updatedDimension;

      return {
        ...formula,
        dimensions: updatedDimensions,
      };
    });
  };

  const addDetail = (e) => {
    e.preventDefault();
    if (
      dimensionDetails?.min_length === "" ||
      dimensionDetails?.min_width === "" ||
      dimensionDetails?.min_height === "" ||
      dimensionDetails?.max_length === "" ||
      dimensionDetails?.max_width === "" ||
      dimensionDetails?.max_height === ""
    ) {
      setError("Please fill all feilds");
      return;
    }
    // for length
    if (
      Number(dimensionDetails?.min_length) <
      Number(
        tierFormula?.dimensions[tierFormula?.dimensions?.length - 1]?.max_length
      )
    ) {
      setError("min length must be grateer then last max length");
      return;
    }
    if (
      Number(dimensionDetails?.max_length) <=
      Number(dimensionDetails?.min_length)
    ) {
      setError("max length must be grater then min length");
      return;
    }
    // for width
    if (
      Number(dimensionDetails?.min_width) <
      Number(
        tierFormula?.dimensions[tierFormula?.dimensions?.length - 1]?.max_width
      )
    ) {
      setError("min width must be grateer then last max width");
      return;
    }
    if (
      Number(dimensionDetails?.max_width) <= Number(dimensionDetails.min_width)
    ) {
      setError("max width must be grater then min width");
      return;
    }
    // for height
    if (
      Number(dimensionDetails?.min_height) <
      Number(
        tierFormula?.dimensions[tierFormula?.dimensions?.length - 1]?.max_height
      )
    ) {
      setError("min height must be grateer then last max height");
      return;
    }
    if (
      Number(dimensionDetails?.max_height) <=
      Number(dimensionDetails?.min_height)
    ) {
      setError("max height must be grater then min height");
      return;
    }

    if (lastDetailedIndexData != null) {
      setTierFormula((formula) => ({
        ...formula,
        dimensions: [
          {
            ...dimensionDetails,
            id: lastDetailedIndexData?.id,
            formula_id: lastDetailedIndexData?.formula_id,
          },
        ],
      }));
      setLastDetailedIndexData(null);
    } else {
      setTierFormula((formula) => ({
        ...formula,
        dimensions: [...formula?.dimensions, dimensionDetails],
      }));
    }
  };
  const handleRemove = (id, indexToRemove) => {
    if (indexToRemove === 0) {
      setLastDetailedIndexData(tierFormula?.dimensions[0]);
    }

    setTierFormula((formula) => {
      const updatedDimensions = [...formula?.dimensions];
      updatedDimensions.splice(indexToRemove, 1);
      return {
        ...formula,
        dimensions: updatedDimensions,
        deletedDimensionIds:
          id && indexToRemove != 0
            ? [...formula?.deletedDimensionIds, id]
            : [...formula?.deletedDimensionIds],
      };
    });
  };

  const createFulfilmentTierFormula = async (e) => {
    e.preventDefault();
    if (tierFormula?.dimensions?.length === 0) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please add atleast 1 formula  dimension",
          severity: "error",
        };
      });
      return;
    }
    setLoader(true);

    const response = await addFulfilmentTierFormula(open?.shipper_id, {
      type: "by_dimensions",
      unit: tierFormula?.unit,
      dimensions: tierFormula?.dimensions,
    });
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text:
            response?.error?.response?.data?.message ?? "Something went wrong",
          severity: "error",
        };
      });
      setLoader(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.data?.message,
          severity: "success",
        };
      });

      // setFormulaCoppy({ ...tierFormula });
      setFormulaCoppy({
        ...response.data.result,
        formula_details: [],
        deletedDetailsIds: [],
      });
      setTierFormula({
        ...response.data.result,
        formula_details: [],
        deletedDetailsIds: [],
      });
      setIsEditable(false);
      setLoader(false);
    }
  };
  const updateTierFormula = async (e) => {
    e.preventDefault();
    if (tierFormula?.dimensions?.length === 0) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please add atleast 1 formula  dimension",
          severity: "error",
        };
      });
      return;
    }
    setLoader(true);

    const response = await updateFulfilmentTierFormula(tierFormula?.id, {
      type: "by_dimensions",
      unit: tierFormula?.unit,
      dimensions: tierFormula?.dimensions,
      deletedDimensionIds: tierFormula?.deletedDimensionIds,
    });
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text:
            response?.error?.response?.data?.message ?? "Something went wrong",
          severity: "error",
        };
      });
      setLoader(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.data?.message,
          severity: "success",
        };
      });

      setFormulaCoppy({
        ...tierFormula,
        deletedDimensionIds: [],
        deletedDetailsIds: [],
      });
      setIsEditable(false);
      setLoader(false);
      // setOpen(false);
    }
  };

  useEffect(() => {
    if (tierFormula) {
      // length calculations
      let nextMinLength = Number(
        tierFormula?.dimensions[tierFormula?.dimensions?.length - 1]?.max_length
      );
      let nextMaxLength =
        (tierFormula?.dimensions?.length <= 1 ? 1 : 0) +
        nextMinLength +
        Number(
          tierFormula?.dimensions[tierFormula?.dimensions?.length - 1]
            ?.max_length
        ) -
        Number(
          tierFormula?.dimensions[tierFormula?.dimensions?.length - 1]
            ?.min_length
        );
      // width calculations
      let nextMinWidth = Number(
        tierFormula?.dimensions[tierFormula?.dimensions?.length - 1]?.max_width
      );
      let nextMaxWidth =
        (tierFormula?.dimensions?.length <= 1 ? 1 : 0) +
        nextMinWidth +
        Number(
          tierFormula?.dimensions[tierFormula?.dimensions?.length - 1]
            ?.max_width
        ) -
        Number(
          tierFormula?.dimensions[tierFormula?.dimensions?.length - 1]
            ?.min_width
        );
      // height calculations
      let nextMinHeight = Number(
        tierFormula?.dimensions[tierFormula?.dimensions?.length - 1]?.max_height
      );
      let nextMaxHeight =
        (tierFormula?.dimensions?.length <= 1 ? 1 : 0) +
        nextMinHeight +
        Number(
          tierFormula?.dimensions[tierFormula?.dimensions?.length - 1]
            ?.max_height
        ) -
        Number(
          tierFormula?.dimensions[tierFormula?.dimensions?.length - 1]
            ?.min_height
        );

      setDimensionDetails({
        ...dimensionDetails,
        min_length: nextMinLength ? nextMinLength : "1",
        max_length: nextMaxLength ? nextMaxLength : "10",
        min_width: nextMinWidth ? nextMinWidth : "1",
        max_width: nextMaxWidth ? nextMaxWidth : "10",
        min_height: nextMinHeight ? nextMinHeight : "1",
        max_height: nextMaxHeight ? nextMaxHeight : "10",
      });
    }
  }, [tierFormula, isEditable]);

  return (
    <>
      <Form
        onSubmit={
          tierFormula?.id === null || tierFormula?.id === "empty"
            ? createFulfilmentTierFormula
            : updateTierFormula
        }
      >
        {isEditable === true && (
          <>
            <div className="fulfilment_byweight__kg_lb_label">Select Unit</div>
            <div className="fulfilment_byweight__kg_lb_wrapper">
              <div
                className={`${
                  tierFormula?.unit === "in"
                    ? "fulfilment_byweight__kg_lb fulfilment_byweight__kg_lb_active"
                    : "fulfilment_byweight__kg_lb"
                } }`}
                onClick={() => {
                  setTierFormula((formula) => ({
                    ...formula,
                    unit: "in",
                  }));
                }}
              >
                In
              </div>
              <div
                className={`${
                  tierFormula?.unit === "cm"
                    ? "fulfilment_byweight__kg_lb fulfilment_byweight__kg_lb_active"
                    : "fulfilment_byweight__kg_lb"
                } }`}
                onClick={() => {
                  setTierFormula((formula) => ({
                    ...formula,
                    unit: "cm",
                  }));
                }}
              >
                Cm
              </div>
            </div>
            <AddDetailsByDimension
              addDetail={addDetail}
              tierFormula={tierFormula}
              formulaDetails={dimensionDetails}
              handleChangeDetails={handleChangeDetails}
            />

            <div
              style={{ justifyContent: "start", padding: "0px" }}
              className={`fulfilment_bydimension_modalerrorwrap ${
                error && "fulfilment_bydimension_modalerrorwrapopen"
              }`}
            >
              <div className="fulfilment_bydimension_modalerrorbox">
                <span>
                  <MdOutlineErrorOutline
                    size={16}
                    style={{ marginTop: "2px" }}
                  />
                </span>
                {error}
              </div>
            </div>
          </>
        )}

        <div className="fulfilment_dimension_box_wrapper">
          <div className="fulfilment_dimension_formbox1">
            <h5 className="fulfilment_dimension_formboxheading">
              <div>Fulfilment Tier Details</div>
            </h5>

            <div className="customer_formula_value_table">
              <table id="customers" className="w-50">
                <tr>
                  <th className="fulfilment_bydimension_cell__width">
                    Min(L,W,H)
                  </th>
                  <th className="fulfilment_bydimension_cell__width">
                    Max(L,W,H)
                  </th>
                  <th className="fulfilment_bydimension_cell__width">Price</th>
                  <th className="fulfilment_bydimension_cell__width">Action</th>
                </tr>
                {tierFormula?.dimensions?.map((detail, index) => (
                  <tr>
                    <td>
                      {"L:" +
                        detail?.min_length +
                        " " +
                        "W:" +
                        detail?.min_width +
                        " " +
                        "H:" +
                        detail?.min_height}
                    </td>
                    <td>
                      {index === tierFormula?.dimensions?.length - 1 ? (
                        <span style={{ fontSize: "30px" }}>∞</span>
                      ) : (
                        "L:" +
                        detail?.max_length +
                        " " +
                        "W:" +
                        detail?.max_width +
                        " " +
                        "H:" +
                        detail?.max_height
                      )}
                    </td>
                    <td>
                      <TextFeild
                        PlaceHolder={"Price"}
                        Data={detail?.value}
                        Disabled={isEditable ? false : true}
                        Handler={(e) =>
                          handleFieldChangeWeightFormula(e, index)
                        }
                      />
                    </td>
                    <td>
                      {tierFormula?.dimensions?.length - 1 === index &&
                        isEditable && (
                          <div className="">
                            <button
                              className="fulfilment_bydimension_cancel_btn"
                              onClick={(e) => {
                                handleRemove(detail?.id, index);
                              }}
                            >
                              <MdClose size={22} />
                            </button>
                          </div>
                        )}
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
        {isEditable === true && (
          <div className="fulfilment_bydimension_modalfooter">
            <button
              className="fulfilment_bydimension_modalcncl"
              onClick={(e) => {
                e.preventDefault();
                setIsEditable(false);
                setTierFormula((formula) => ({
                  ...formula,
                  id: formulaCoppy?.id,
                  type: formulaCoppy?.type,
                  unit: formulaCoppy?.unit,
                  dimensions: [...formulaCoppy?.dimensions],
                  formula_details: [...formulaCoppy?.formula_details],
                }));
              }}
            >
              Cancel
            </button>
            <button className="fulfilment_bydimension_modalsave">
              {loader ? <Loader /> : "Save"}
            </button>
          </div>
        )}
      </Form>
    </>
  );
};

export default FulfilmentByDimension;
