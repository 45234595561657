import React, { useEffect, useState } from "react";
import "./AddBundle.css";

// components imports
import Loader from "../../../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import { addNewBundle } from "../../../../../../../services/ShipperServices/Packages/packages.services";

// library imports

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Upload from "@mui/icons-material/CloudUpload";
import { MdAdd, MdClose } from "react-icons/md";
import { generateUniqueId } from "../../../../../../../helpers/helpers";

const initialData = {
  name: "",
  description: "",
  file: "",
  packages: [
    {
      id: 1,
      name: "",
      description: "",
      save_name: null,
      length: "",
      weight: "",
      width: "",
      height: "",
      unit: "Imperial",
    },
  ],
};

export default function AddBundle({ open, setOpen, setSnack, setRefresh }) {
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [unit, setUnit] = useState("Imperial");
  const handleClose = () => {
    setData(initialData);
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handlePackageChange = (e, pkgidx) => {
    const { name, value } = e.target;
    setData((prevData) => {
      const newPackages = [...prevData.packages];
      newPackages[pkgidx] = {
        ...newPackages[pkgidx],
        [name]: value,
      };
      return {
        ...prevData,
        packages: newPackages,
      };
    });
  };
  // const handleUnitChange = (e) => {
  //   // console.log(e.target.value);
  //   setData({ ...data, unit: e.target.value });
  // };
  const handleFileChange = (e) => {
    setData({ ...data, file: e.target.files[0] });
  };

  const handleSave = async () => {
    setLoading(true);
    const isDataValid = Object.entries(data).every(([key, value]) => {
      if (key === "packages") {
        return value.every((pkg) => {
          return Object.values(pkg).every((pkgValue) => pkgValue !== "");
        });
      }
      return key === "file" || value !== "";
    });

    if (!isDataValid) {
      setLoading(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "All fields are required",
          severity: "error",
        };
      });
      return;
    }
    const newData = {
      ...data,
      packages: [],
    };

    data.packages.forEach((pkg) => {
      const { id, ...rest } = pkg;
      newData.packages.push({
        ...rest,
        unit: unit,
      });
    });
    const formData = new FormData();
    formData.append("name", newData.name);
    formData.append("type", "bundle");
    formData.append("description", newData.description);

    if (newData.file) {
      formData.append("file", newData.file);
    }

    newData.packages.forEach((pkg, index) => {
      const pkgData = JSON.stringify(pkg);
      formData.append(`packages[${index}]`, pkgData);
    });
    const saved = await addNewBundle(formData);
    setLoading(false);
    if (!saved.error) {
      setRefresh(Math.floor(Math.random() * 100) + 1);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Bundle Added successfully",
          severity: "success",
        };
      });
      handleClose();
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };
  const handleAddPackage = () => {
    setData({
      ...data,
      packages: [
        ...data.packages,
        {
          id: generateUniqueId(),
          name: "",
          description: "",
          save_name: null,
          length: 0,
          weight: 0,
          width: 0,
          height: 0,
          unit: "Imperial",
        },
      ],
    });
  };
  const handleDeletePackage = (id) => {
    setData((prevData) => ({
      ...prevData,
      packages: prevData.packages.filter((pkg) => id !== pkg.id),
    }));
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="add__bundle_modal">
          <div className="add__bundle_modalheading">Add Bundle</div>
          <div className="add__bundle_modalcontent">
            <div className="add__bundle_modalrow">
              <div className="add__bundle_modalinputwrap">
                <input
                  className="add__bundle_modalinput"
                  placeholder="Bundle Name"
                  name="name"
                  value={data.name}
                  onChange={handleChange}
                />
              </div>
              <div className="add__bundle_modalinputwrap">
                <label className="add__bundle_profimagelbl">
                  <Upload /> {data.file?.name ?? "UPLOAD BUNDLE IMAGE"}
                  <input
                    type="file"
                    size="60"
                    className="add__bundle_profimageinput"
                    onChange={handleFileChange}
                    accept=".jpg,.png"
                  />
                </label>
              </div>
            </div>

            <div className="add__bundle_modalrow">
              <div
                className="add__bundle_modalinputwrap"
                style={{ minWidth: "65%" }}
              >
                <input
                  className="add__bundle_modalinput"
                  placeholder="Bundle Description"
                  name="description"
                  value={data.description}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="add__bundle_modalrowspacebetween">
              <h6 className="add__bundle_mrpkgsheading">Packages</h6>
              <div className="add_bundle__unitbtnswrap">
                <div
                  className={`add_bundle__unitbtn ${
                    unit === "Imperial" && "add_bundle__unitbtnactive"
                  }`}
                  onClick={() => setUnit("Imperial")}
                >
                  Imperial
                </div>
                <div
                  className={`add_bundle__unitbtn ${
                    unit === "Metric" && "add_bundle__unitbtnactive"
                  }`}
                  onClick={() => setUnit("Metric")}
                >
                  Metric
                </div>
              </div>
            </div>
            <div className="add__bundle_mrpkgslist">
              {data.packages?.map((pkg, pkgidx) => {
                return (
                  <div className="add__bundle_modalrow" key={pkg?.id}>
                    <div
                      className="add__bundle_modalinputwrap"
                      style={{ maxWidth: "180px" }}
                    >
                      <input
                        className="add__bundle_modalinput"
                        placeholder="Package Name"
                        name="name"
                        onChange={(e) => handlePackageChange(e, pkgidx)}
                      />
                    </div>
                    <div
                      className="add__bundle_modalinputwrap"
                      style={{ maxWidth: "80px" }}
                    >
                      <input
                        className="add__bundle_modalinput"
                        placeholder="Length"
                        name="length"
                        type="number"
                        onChange={(e) => handlePackageChange(e, pkgidx)}
                      />
                    </div>
                    <div
                      className="add__bundle_modalinputwrap"
                      style={{ maxWidth: "80px" }}
                    >
                      <input
                        className="add__bundle_modalinput"
                        placeholder="Width"
                        name="width"
                        type="number"
                        onChange={(e) => handlePackageChange(e, pkgidx)}
                      />
                    </div>
                    <div
                      className="add__bundle_modalinputwrap"
                      style={{ maxWidth: "80px" }}
                    >
                      <input
                        className="add__bundle_modalinput"
                        placeholder="Height"
                        name="height"
                        type="number"
                        onChange={(e) => handlePackageChange(e, pkgidx)}
                      />
                    </div>
                    <div
                      className="add__bundle_modalinputwrap"
                      style={{ maxWidth: "80px" }}
                    >
                      <input
                        className="add__bundle_modalinput"
                        placeholder="Weight"
                        name="weight"
                        type="number"
                        onChange={(e) => handlePackageChange(e, pkgidx)}
                      />
                    </div>
                    <div
                      className="add__bundle_modalinputwrap"
                      style={{ maxWidth: "300px" }}
                    >
                      <input
                        className="add__bundle_modalinput"
                        placeholder="Package Description"
                        name="description"
                        //   value={data.description}
                        onChange={(e) => handlePackageChange(e, pkgidx)}
                      />
                    </div>

                    {pkgidx === data?.packages?.length - 1 && (
                      <div
                        className="add__bundle_modalinputwrap"
                        style={{ maxWidth: "50px" }}
                      >
                        <button
                          className={`add__bundle_addpkgbtn ${
                            pkgidx !== data?.packages?.length - 1 &&
                            "disable__click_fade"
                          }`}
                          onClick={handleAddPackage}
                        >
                          <MdAdd size={25} />
                        </button>
                      </div>
                    )}

                    {data?.packages?.length !== 1 && (
                      <div
                        className="add__bundle_modalinputwrap"
                        style={{ maxWidth: "50px" }}
                      >
                        <button
                          className={`add__bundle_delpkgbtn ${
                            pkgidx === 0 &&
                            data?.packages?.length === 1 &&
                            "disable__click_fade"
                          }`}
                          onClick={() => handleDeletePackage(pkg?.id)}
                        >
                          <MdClose size={25} />
                        </button>
                      </div>
                    )}
                    <div
                      className="add__bundle_modalinputwrap"
                      style={{ maxWidth: "60px" }}
                    ></div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="add__bundle_modalfooter">
            <div className={`add__bundle_modalcncl`} onClick={handleClose}>
              Cancel
            </div>
            <div
              className={`add__bundle_modalsave  ${
                loading && "add_bundle_modalsavedisabled"
              }`}
              onClick={handleSave}
            >
              {loading ? <Loader /> : "Save"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
