import React from "react";
import "../InvoiceModal.css";
import Logo from "../../../../../../../../../assets/images/hajex_logo.png";

const Footer = ({ data, type = "Show" }) => {
  let customDecoration = { textDecoration: "underline" };
  let customPosition = { position: "relative", top: "-8px" };
  let customPadding = { paddingTop: "5px" };

  if (type === "Show") {
    customDecoration = {};
    customPosition = {};
    customPadding = {};
  }

  return (
    <>
      <div className="invmodal__invoicedashed_line"></div>
      <div
        className="invmodal__bottom_titletxt"
        style={{ justifyContent: "center" }}
      >
        <b>
          GST#: 1229652857TQ0001 | QST# 726351505RT0001 | Inquiry:
          info@hajexbolt.com
        </b>
      </div>
    </>
  );
};

export default Footer;
