import React, { useState } from "react";
import "./Sections.css";
import { BiCloudUpload } from "react-icons/bi";
import {
  MdOutlineAttachment,
  MdClose,
  MdOutlineArrowBack,
  MdErrorOutline,
} from "react-icons/md";
import { useEffect } from "react";
import noImage from "../../../../assets/images/noImage.png";
const MediaSection = ({
  formData,
  setFormData,
  uploadedImages,
  setUploadedImages,
  errors,
}) => {
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [addUrlBox, setAddUrlBox] = useState(false);
  const handleImageUpload = (e) => {
    const uploadedImgs = Array.from(e.target.files);
    // Filter out non image files
    const imageFiles = uploadedImgs.filter((file) =>
      file.type.startsWith("image/")
    );
    if (uploadedImages[0]?.file) {
      setUploadedImages((prevImages) => [
        ...prevImages,
        ...imageFiles.map((img, idx) => {
          return { media: img, is_primary: false };
        }),
      ]);
    } else {
      setUploadedImages((prevImages) => [
        ...prevImages,
        ...imageFiles.map((img, idx) => {
          return { media: img, is_primary: idx === 0 };
        }),
      ]);
    }
  };
  const handleRemoveImage = (index) => {
    if (uploadedImages[index]?.file) {
      setFormData({
        ...formData,
        deletedMediaIds: [
          ...formData?.deletedMediaIds,
          uploadedImages[index]?.id,
        ],
      });
    }
    const updatedImages = [...uploadedImages];
    updatedImages.splice(index, 1);

    // If the removed image was the main image, reset to the first image as the main image
    if (index === mainImageIndex) {
      setMainImageIndex(0);
    }

    setUploadedImages(
      updatedImages?.map((image, index) => {
        return {
          ...image,
          is_primary: index === 0 ? true : false,
        };
      })
    );
  };
  const handleSetMainImage = (index) => {
    setUploadedImages((prevList) =>
      prevList.map((img, idx) => {
        return { ...img, is_primary: idx === index };
      })
    );
  };
  useEffect(() => {
    setFormData({
      ...formData,
      media: uploadedImages,
    });
  }, [uploadedImages]);
  return (
    <section className="addprod__formsection_wrap" id="addproduct_media">
      <div className="addprod__formsection_heading">Media</div>
      <div className="addprod__formsection_mediabox">
        {!addUrlBox && (
          <>
            <div className="addprod__formsec_imagebtns">
              <label className="addprod__formsec_uploadimagebtn">
                <BiCloudUpload
                  size={20}
                  className="addprod__formsection_mdbtnicon"
                />
                Upload Images
                <input
                  type="file"
                  hidden
                  multiple
                  id="product_media"
                  onChange={handleImageUpload}
                />
              </label>
              {/* <div
                className="addprod__formsec_addurlbtn"
                onClick={() => setAddUrlBox(true)}
              >
                <MdOutlineAttachment
                  size={20}
                  className="addprod__formsection_mdbtnicon"
                />
                Add Url
              </div> */}
            </div>
            <div className="addprod__formsection_mdbtmtext">
              ACCEPTING IMAGE FILES ONLY
            </div>
          </>
        )}
        {/* {addUrlBox && (
          <div className="addprod__formsec_addimgurl">
            <MdOutlineArrowBack
              size={22}
              className="addprod__formsec_addimgurlback"
              onClick={() => setAddUrlBox(false)}
            />
            <input
              className="addprod__formsec_addimgurlfield"
              type="text"
              placeholder="Paste Image url for the product"
            />
            <button className="addprod__formsec_addimgurbtn">ADD URL</button>
          </div>
        )} */}
      </div>
      <div
        className="addprod__secformrow_images"
        style={{
          marginBottom: uploadedImages?.length < 1 ? "0px" : "",
        }}
      >
        {formData?.media?.map((image, index) => (
          <div key={index} className="addprod__secformrow_imagewrap">
            <MdClose
              className="addprod__secformrow_imageclose"
              size={20}
              onClick={() => handleRemoveImage(index)}
            />
            <img
              src={
                image && image.file
                  ? image.file
                  : image.media instanceof Blob
                    ? URL.createObjectURL(image.media)
                    : noImage
              }
              accept="image/*"
              className="addprod__secformrow_image"
              onError={(e) => {
                e.target.src = noImage;
              }}
            />
            {![true, "true"].includes(image?.is_primary) && (
              <button
                className="addprod__secformimg_setmainbtn"
                onClick={() => handleSetMainImage(index)}
              >
                SET MAIN
              </button>
            )}
            {[true, "true"].includes(image?.is_primary) && (
              <button className="addprod__secformimg_mainbadge">
                MAIN IMAGE
              </button>
            )}
          </div>
        ))}
      </div>
      {Object.keys(errors)?.includes("media") && (
        <div className="addprod__custominputfield_error">
          <MdErrorOutline className="addprod__custominputfield_erroricn" />
          Add Product Images
        </div>
      )}
    </section>
  );
};

export default MediaSection;
