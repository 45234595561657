import React, { useEffect, useState } from "react";
import "./OrderHistoryModal.css";

// components imports
import ImagesModal from "./ImagesModal/ImagesModal";
import AddNoteModal from "./AddNoteModal";
// apis imports
import { fetchOrderNotes } from "../../../../../../../services/orders.services";
import { checkFileType } from "../../../../../../../helpers/helpers";
// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import noImage from "../../../../../../../assets/images/noImage.png";
import pdfIcon from "../../../../../../../assets/icons/pdfIcon.png";
import docsIcon from "../../../../../../../assets/icons/docsIcon.png";
import excelIcon from "../../../../../../../assets/icons/excelIcon.png";
import { FcPlus } from "react-icons/fc";
import Skeleton from "@mui/material/Skeleton";

export default function OrderHistoryModal({ open, setOpen, setSnack ,setOrders}) {
  const [data, setData] = useState([]);
  const [fullview, setfullview] = useState();
  const handleClose = () => {
    setOpen(false);
    setData({});
  };
  const [loading, setLoading] = useState(false);

  const [addNoteModal, setAddNoteModal] = useState(false);

  const getOrderNotes = async () => {
    setLoading(true);
    let response = await fetchOrderNotes(open?.id);
    if (!response.error) {
      setLoading(false);
      setData(response?.data?.result);
    } else {
      setLoading(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  useEffect(() => {
    if (open && open?.id) {
      getOrderNotes();
    }
  }, [open]);

  return (
    <>
      <ImagesModal setOpen={setfullview} open={fullview} />
      <AddNoteModal
        open={addNoteModal}
        setOpen={setAddNoteModal}
        setSnack={setSnack}
        orderData={open}
        setNotesData={setData}
        setOrders={setOrders}
      />
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <div className="prdtable__hist_modal notes__attachments_modal_container">
            <div className="prdtable__hist_modalheading">
              <div>Note's & Attachment's</div>
              <div className="prdtable__hist_modal_buttonsWrapper">
                <div onClick={() => setAddNoteModal(data)}>
                  <div className="prdtable__hist_modal_hdraddprod">
                    <FcPlus size={10} />
                    <span>Add Note</span>
                  </div>
                </div>
              </div>
            </div>

            <>
              <div className="prdtable__hist_modalcontent notes__attachments_modal_wrap">
                <TableContainer
                  component={Paper}
                  className="popup_history_main"
                >
                  <Table
                    sx={{ minWidth: 550 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Created Date</TableCell>
                        <TableCell>Created Time</TableCell>
                        <TableCell>Created By</TableCell>
                        <TableCell>Attachment</TableCell>
                        <TableCell>Note</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading &&
                        // Render 10 rows of Skeletons for each cell when data is loading
                        Array.from({ length: 10 }).map((_, rowIndex) => (
                          <TableRow key={rowIndex}>
                            {Array.from({ length: 5 }).map(
                              (
                                _,
                                cellIndex // Assuming 6 cells per row
                              ) => (
                                <TableCell key={cellIndex}>
                                  <Skeleton
                                    variant="text"
                                    width="100%"
                                    height={40}
                                  />
                                </TableCell>
                              )
                            )}
                          </TableRow>
                        ))}
                      {!loading &&
                        data?.length > 0 &&
                        data?.map((notes, index) => {
                          return (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ width: "120px" }}
                                className="notes__attachments_modal_attachment_thumbnail_cell"
                              >
                                {notes?.created_at?.slice(0, 10) ?? "-"}
                              </TableCell>
                              <TableCell
                                style={{ width: "120px" }}
                                className="notes__attachments_modal_attachment_thumbnail_cell"
                              >
                                {notes?.created_at?.slice(11, 16) ?? "-"}
                              </TableCell>
                              <TableCell
                                style={{ width: "120px" }}
                                className="notes__attachments_modal_attachment_thumbnail_cell"
                              >
                                {notes?.created_by?.username ?? "-"}
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: "100px",
                                  width: "100px",
                                }}
                                className="notes__attachments_modal_attachment_thumbnail_cell"
                              >
                                <div className="notes__attachments_modal_attachment_thumbnail_wrap">
                                  <div className="notes__attachments_modal_attachment_thumbnail">
                                    {notes?.order_attachments?.map(
                                      (attachment, index) => (
                                        <img
                                          key={index}
                                          src={
                                            attachment?.file &&
                                            checkFileType(attachment.file) ===
                                              "Image"
                                              ? attachment.file
                                              : checkFileType(
                                                  attachment.file
                                                ) === "PDF"
                                              ? pdfIcon
                                              : checkFileType(
                                                  attachment.file
                                                ) === "Excel"
                                              ? excelIcon
                                              : checkFileType(
                                                  attachment.file
                                                ) === "Document"
                                              ? docsIcon
                                              : noImage
                                          }
                                          alt={`Attachment ${index + 1}`}
                                          onError={(e) => {
                                            e.target.src = noImage;
                                          }}
                                          onClick={() => {
                                            if (
                                              checkFileType(attachment.file) ===
                                              "Image"
                                            ) {
                                              setfullview(
                                                attachment?.file || ""
                                              );
                                            } else {
                                              window.open(
                                                attachment?.file,
                                                "_blank"
                                              );
                                            }
                                          }}
                                        />
                                      )
                                    )}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{ width: "120px" }}
                                className="notes__attachments_modal_attachment_thumbnail_cell"
                              >
                                <div className="notes__attachments_modal_content_notes">
                                  {notes?.note ?? "-"}
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </>

            <div className="prdtable__hist_modalfooter notes__attachments_modal_modal_footer">
              <div className="prdtable__hist_modalcncl" onClick={handleClose}>
                Close
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
