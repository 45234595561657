import React from "react";
import "./adddetailsbyweight.css";
import TextFeild from "../../../CustomTextFeild/TextFeild";

const AddDetailsByWeight = ({
  addDetail,
  handleChangeDetails,
  formulaDetails,
  tierFormula,
}) => {
  return (
    <>
      <div className="details_byweight_formrow">
        <div className="">
          <TextFeild
            Name="min"
            Label="Min"
            Required={false}
            Disabled={tierFormula?.formula_details?.length === 0}
            Data={formulaDetails.min}
            Adroment={tierFormula?.unit}
            Handler={(e) => handleChangeDetails(e)}
          />
        </div>
        <div className="">
          <TextFeild
            Label="Max"
            Data={formulaDetails.max}
            Name="max"
            Required={false}
            Adroment={tierFormula?.unit}
            Handler={(e) => handleChangeDetails(e)}
          />
        </div>

        <button
          className="details_byweight_modalsave details_byweight_details"
          onClick={(e) => addDetail(e)}
        >
          <span>Add </span> +
        </button>
      </div>
    </>
  );
};

export default AddDetailsByWeight;
