// import React from "react";
// import "./CarrierTableLoader.css";
// const CarrierTableLoader = () => {
//   return (
//     <div className="carrier__table_loader">
//       <div></div>
//       <div></div>
//       <div></div>
//       <div></div>
//       <div></div>
//       <div></div>
//       <div></div>
//       <div></div>
//       <div></div>
//     </div>
//   );
// };

// export default CarrierTableLoader;
import React from "react";
import Empty from "../../assets/images/dispatcher-empty.gif";
import LinearProgress from "@mui/material/LinearProgress";
import "./CarrierTableLoader.css";
const CarrierTableLoader = () => {
  return (
    <div className="carrier__table_loader">
      <div className="carrier__table_loadertimg">
        <img src={Empty} />
      </div>
      <div className="carrier__table_loadertxt"></div>
    </div>
  );
};

export default CarrierTableLoader;
