import React, { useEffect } from "react";
import "./Sections.css";
import { MdErrorOutline, MdAddCircleOutline } from "react-icons/md";
import CustomDropdown from "../Components/CustomDropdown/CustomDropdown";
import { FaRegTrashAlt } from "react-icons/fa";
const DimensionsSection = ({
  formData,
  setFormData,
  errors,
  packageDetails,
  setPackageDetails,
}) => {
  const handleFieldChange = (name, e) => {
    setFormData({
      ...formData,
      [name]: e.target.value,
    });
  };
  const handlePackageDimension = (name, value, index) => {
    setPackageDetails(
      packageDetails?.map((dim, idx) => {
        if (index === idx) {
          return { ...dim, [name]: value };
        } else {
          return dim;
        }
      })
    );
  };
  useEffect(() => {
    setFormData({
      ...formData,
      package_details: packageDetails,
    });
  }, [packageDetails]);
  return (
    <section className="addprod__formsection_wrap" id="addproduct_dimensions">
      <div className="addprod__formsection_heading">Product Dimensions</div>
      <div className="addprod__custominput_flexrow">
        <div
          className="addprod__custominput_flexcol"
          style={{ minWidth: "100px" }}
        >
          <div className="addprod__custominput_box">
            <label className="addprod__custominput_title">
              Dimensions Unit
            </label>
            <CustomDropdown
              optionObjects={[
                { title: "Inch (in)", value: "in" },
                { title: "Centemeter (cm)", value: "cm" },
              ]}
              onSelect={(option) => {
                setFormData({ ...formData, dimension_unit: option });
              }}
              selectedOption={formData?.dimension_unit}
            />
            {Object.keys(errors)?.includes("dimension_unit") && (
              <div className="addprod__custominputfield_error">
                <MdErrorOutline className="addprod__custominputfield_erroricn" />
                {errors?.dimension_unit?.msg}
              </div>
            )}
          </div>
        </div>
        <div
          className="addprod__custominput_flexcol"
          style={{ minWidth: "100px" }}
        >
          <div className="addprod__custominput_box">
            <label className="addprod__custominput_title">Weight Unit</label>
            <CustomDropdown
              optionObjects={[
                { title: "Kilogram (kg)", value: "kg" },
                { title: "Pound (lb)", value: "lb" },
              ]}
              onSelect={(option) => {
                setFormData({ ...formData, weight_unit: option });
              }}
              selectedOption={formData?.weight_unit}
            />
            {Object.keys(errors)?.includes("weight_unit") && (
              <div className="addprod__custominputfield_error">
                <MdErrorOutline className="addprod__custominputfield_erroricn" />
                {errors?.weight_unit?.msg}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="addprod__custominput_flexrow">
        <div
          className="addprod__custominput_flexcol"
          style={{ minWidth: "100px" }}
        >
          <div className="addprod__custominput_box">
            <label className="addprod__custominput_title">Length</label>
            <input
              className="addprod__custominputfield"
              type="number"
              placeholder="Enter Length"
              onChange={(e) => handleFieldChange("length", e)}
              value={formData?.length}
            />
            {Object.keys(errors)?.includes("length") && (
              <div className="addprod__custominputfield_error">
                <MdErrorOutline className="addprod__custominputfield_erroricn" />
                {errors?.length?.msg}
              </div>
            )}
          </div>
        </div>
        <div
          className="addprod__custominput_flexcol"
          style={{ minWidth: "100px" }}
        >
          <div className="addprod__custominput_box">
            <label className="addprod__custominput_title">Width</label>
            <input
              className="addprod__custominputfield"
              type="number"
              placeholder="Enter Width"
              onChange={(e) => handleFieldChange("width", e)}
              value={formData?.width}
            />
            {Object.keys(errors)?.includes("width") && (
              <div className="addprod__custominputfield_error">
                <MdErrorOutline className="addprod__custominputfield_erroricn" />
                {errors?.width?.msg}
              </div>
            )}
          </div>
        </div>
        <div
          className="addprod__custominput_flexcol"
          style={{ minWidth: "100px" }}
        >
          <div className="addprod__custominput_box">
            <label className="addprod__custominput_title">Height</label>
            <input
              className="addprod__custominputfield"
              type="number"
              placeholder="Enter Height"
              onChange={(e) => handleFieldChange("height", e)}
              value={formData?.height}
            />
            {Object.keys(errors)?.includes("height") && (
              <div className="addprod__custominputfield_error">
                <MdErrorOutline className="addprod__custominputfield_erroricn" />
                {errors?.height?.msg}
              </div>
            )}
          </div>
        </div>
        <div
          className="addprod__custominput_flexcol"
          style={{ minWidth: "100px" }}
        >
          <div className="addprod__custominput_box">
            <label className="addprod__custominput_title">Weight</label>
            <input
              className="addprod__custominputfield"
              type="number"
              placeholder="Enter Weight"
              onChange={(e) => handleFieldChange("weight", e)}
              value={formData?.weight}
            />
            {Object.keys(errors)?.includes("weight") && (
              <div className="addprod__custominputfield_error">
                <MdErrorOutline className="addprod__custominputfield_erroricn" />
                {errors?.weight?.msg}
              </div>
            )}
          </div>
        </div>
      </div>{" "}
      <br />
      <div className="addprod__custominput_flexrow">
        <label className="addprod__formsection_heading">
          Packages Dimensions
        </label>
      </div>
      {packageDetails?.map((dimension, index) => (
        <div key={index} className="addprod__custominput_flexrow">
          <div
            className="addprod__custominput_flexcol"
            style={{ minWidth: "100px" }}
          >
            <div className="addprod__custominput_box">
              <label className="addprod__custominput_title">Length</label>
              <input
                className="addprod__custominputfield"
                type="number"
                placeholder="Enter Length"
                onChange={(e) =>
                  handlePackageDimension("length", e.target.value, index)
                }
                value={dimension?.length}
              />
              {Object.keys(errors)?.includes("length") && (
                <div className="addprod__custominputfield_error">
                  <MdErrorOutline className="addprod__custominputfield_erroricn" />
                  {errors?.length?.msg}
                </div>
              )}
            </div>
          </div>
          <div
            className="addprod__custominput_flexcol"
            style={{ minWidth: "100px" }}
          >
            <div className="addprod__custominput_box">
              <label className="addprod__custominput_title">Width</label>
              <input
                className="addprod__custominputfield"
                type="number"
                placeholder="Enter Width"
                onChange={(e) =>
                  handlePackageDimension("width", e.target.value, index)
                }
                value={dimension?.width}
              />
              {Object.keys(errors)?.includes("width") && (
                <div className="addprod__custominputfield_error">
                  <MdErrorOutline className="addprod__custominputfield_erroricn" />
                  {errors?.width?.msg}
                </div>
              )}
            </div>
          </div>
          <div
            className="addprod__custominput_flexcol"
            style={{ minWidth: "100px" }}
          >
            <div className="addprod__custominput_box">
              <label className="addprod__custominput_title">Height</label>
              <input
                className="addprod__custominputfield"
                type="number"
                placeholder="Enter Height"
                onChange={(e) =>
                  handlePackageDimension("height", e.target.value, index)
                }
                value={dimension?.height}
              />
              {Object.keys(errors)?.includes("height") && (
                <div className="addprod__custominputfield_error">
                  <MdErrorOutline className="addprod__custominputfield_erroricn" />
                  {errors?.height?.msg}
                </div>
              )}
            </div>
          </div>
          <div
            className="addprod__custominput_flexcol"
            style={{ minWidth: "100px" }}
          >
            <div className="addprod__custominput_box">
              <label className="addprod__custominput_title">Weight</label>
              <input
                className="addprod__custominputfield"
                type="number"
                placeholder="Enter Weight"
                onChange={(e) =>
                  handlePackageDimension("weight", e.target.value, index)
                }
                value={dimension?.weight}
              />
              {Object.keys(errors)?.includes("weight") && (
                <div className="addprod__custominputfield_error">
                  <MdErrorOutline className="addprod__custominputfield_erroricn" />
                  {errors?.weight?.msg}
                </div>
              )}
            </div>
          </div>
          <div
            className="addprod__custominput_flexcol"
            style={{ minWidth: "250px" }}
          >
            <div className="addprod__custominput_box">
              <label className="addprod__custominput_title">Description</label>
              <input
                className="addprod__custominputfield"
                type="text"
                placeholder="Write Description"
                onChange={(e) =>
                  handlePackageDimension("description", e.target.value, index)
                }
                value={dimension?.description}
              />
            </div>
          </div>
          <FaRegTrashAlt
            size={13}
            className="addprod__idensec_delrowicn"
            onClick={(e) =>
              setPackageDetails(
                packageDetails?.filter((item, idx) => idx !== index)
              )
            }
          />
        </div>
      ))}
      <div className="addprod__custominput_flexrow">
        <button
          className="addprod__formsection_addvarbtn"
          onClick={() =>
            setPackageDetails([
              ...packageDetails,
              {
                length: "",
                width: "",
                height: "",
                weight: "",
                description: "",
              },
            ])
          }
        >
          <MdAddCircleOutline
            className="addprod__formsection_addvarbtnicn"
            size={14}
          />
          Add Dimension
        </button>
      </div>
    </section>
  );
};

export default DimensionsSection;
