import React from "react";
import "./manageuserformula.css";
// components imports
import UserFormula from "../../../../../Payments/Components/UserFormula/UserFormula";
import TabsComponent from "../../../../../../../Components/Common/TabsComponent";
import UserMarkup from "../../../../../Payments/Components/UserMarkup/UserMarkup";

// apis imports
// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";

const ManageUserFormula = ({ open, setOpen, setSnack, updateIndex }) => {
  // const handleClose = () => setOpen(false);
  const [nestedtab, setNestedTab] = React.useState(0);

  const handleNestedTabChange = (event, newValue) => {
    setNestedTab(newValue);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      // onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className="manage__userformula_modal">
        <TabsComponent
          items={[
            {
              label: "Tier",
              style: { fontWeight: "bold" },
              value: 0,
            },
            { label: "Markup", style: { fontWeight: "bold" }, value: 1 },
          ]}
          tab={nestedtab}
          handleTabChange={handleNestedTabChange}
        />
        {nestedtab === 0 && (
          <UserFormula
            user={open}
            setSnack={setSnack}
            setOpen={setOpen}
            updateIndex={updateIndex}
          />
        )}
        {nestedtab === 1 && (
          <>
            <UserMarkup
              setSnack={setSnack}
              user={open}
              setOpen={setOpen}
              updateIndex={updateIndex}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default ManageUserFormula;
