import React, { useState, useEffect } from "react";
import "./cardmanagement.css";

// components imports
import CreditCard from "../../Components/CreditCard/CreditCard";
import AddCard from "../../Components/AddBalance/AddCard";

// apis imports
import {
  deleteStripeCard,
  setDefaultCard,
  fetchCreditCards,
} from "../../../../../../../services/ShipperServices/Payments/payments.services";

// library imports
import { MdAdd } from "react-icons/md";

const CardManagement = ({ setSnack }) => {
  const [settingDefault, setSettingDefault] = useState();
  const [transectionCards, setTransectionCards] = useState();

  const [addCardModal, setAddCardModal] = useState();

  const getAllShippersTransections = async (page, perPage) => {
    const response = await fetchCreditCards();
    if (!response.error) {
      setTransectionCards(response.data.result?.payment_methods);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  useEffect(() => {
    getAllShippersTransections();
  }, []);

  const handleDeleteCard = async (id, isDefault) => {
    try {
      const response = await deleteStripeCard(id);
      setSettingDefault(false);
      setTransectionCards((methods) =>
        methods.filter((method) => {
          return method.id !== id;
        })
      );
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Payment method deleted successfully",
          severity: "success",
        };
      });
    } catch (error) {
      setSettingDefault(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };
  const handleDefaultCard = async (id) => {
    setSettingDefault(id);
    try {
      const response = await setDefaultCard(id);
      setSettingDefault(false);
      setTransectionCards((methods) =>
        methods.map((method) => {
          if (method.id === id) {
            return { ...method, card: { ...method.card, is_default: true } };
          } else {
            return { ...method, card: { ...method.card, is_default: false } };
          }
        })
      );
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Default payment method changed successfully",
          severity: "success",
        };
      });
    } catch (error) {
      setSettingDefault(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };
  return (
    <>
      <div
        className="payment__add__card_btn"
        onClick={() => setAddCardModal(true)}
      >
        <MdAdd size={20} />
        ADD NEW CARD
      </div>
      {addCardModal && (
        <AddCard
          open={!!addCardModal}
          setOpen={setAddCardModal}
          setCards={setTransectionCards}
        />
      )}
      <div className="payment__credit_cards">
        {
          // !loading &&
          transectionCards?.map((method) => (
            <CreditCard
              id={method?.id}
              last_digits={method?.card?.last4}
              brand={method?.card?.brand}
              exp_year={method?.card?.exp_year}
              exp_month={method?.card?.exp_month}
              isDefault={method?.card?.is_default}
              handleDefaultCard={handleDefaultCard}
              handleDeleteCard={handleDeleteCard}
              loading={settingDefault === method?.id}
              total={transectionCards?.length}
            />
          ))
        }
      </div>
    </>
  );
};

export default CardManagement;
