import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import "./ZoneSettings.css";
import { Swiper, SwiperSlide } from "swiper/react";
import Paste from "@mui/icons-material/ContentPasteGo";
import { styled } from "@mui/material/styles";
import { ReactComponent as Save } from "../../assets/icons/save.svg";
import { ReactComponent as Question } from "../../assets/icons/question.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as Copy } from "../../assets/icons/copy.svg";
import { ReactComponent as Delete } from "../../assets/icons/trash.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import DownIcon from "@mui/icons-material/KeyboardArrowDown";
import Switch from "@mui/material/Switch";
import { Pagination, Navigation } from "swiper";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  fetchAllQuestions,
  fetchTempZoneSettings,
  saveZoneSettings,
  tempSaveZoneSettings,
} from "../../services/zones.services";
import { fullSizeUnit, fullUnit, units } from "../../helpers/helpers";
import { useSelector } from "react-redux";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#131466" : "#131466",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const schema = {
  settings: [
    {
      question_id: 1,
      unit: "$",
      type: "package",
      rangeData: [
        {
          min: 0,
          max: 0,
          zoneData: [
            {
              price: 0,
              zone_id: 1,
            },
            {
              price: 0,
              zone_id: 2,
            },
          ],
        },
        {
          min: 0,
          max: 0,
          zoneData: [
            {
              price: 0,
              zone_id: 1,
            },
            {
              price: 0,
              zone_id: 2,
            },
          ],
        },
      ],
      dimensions: [
        {
          min_length: 0,
          min_width: 0,
          min_height: 0,
          max_length: 0,
          max_width: 0,
          max_height: 0,
          zoneData: [
            {
              price: 0,
              zone_id: 1,
            },
            {
              price: 0,
              zone_id: 2,
            },
          ],
        },
      ],
      zoneData: [
        {
          price: 0,
          zone_id: 1,
        },
      ],
    },
  ],
};

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const FieldLoading = () => {
  return <div className="lds-dual-ring"></div>;
};

const ZoneSettings = ({
  zones,
  newZoneAdded,
  setNewZoneAdded,
  zoneDeleted,
  setZoneDeleted,
  maps,
  map,
  reloadMap,
}) => {
  const [age, setAge] = useState("");
  const [expandDropdown, setExpandDropdown] = useState();
  const [rangeDropdown, setRangeDropdown] = useState();
  const [settingsLoader, setSettingsLoader] = useState(false);
  const [data, setData] = useState(schema.settings);
  const [focused, setFocused] = useState("");
  const [zonePerPage, setZonePerPage] = useState(2);
  const [questions, setQuestions] = useState([]);
  const [loadingField, setLoadingField] = useState(false);
  const state = useSelector((state) => state);
  const [activeQuestion, setActiveQuestion] = useState();
  const [hoverQuestion, setHoverQuestion] = useState();
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    text: "",
    severity: "",
  });
  const [rangeData, setRangeData] = useState([
    {
      min: 0,
      max: 15,
      zoneData: zones.map((item) => {
        return { zone_id: item.id, price: 0, enabled: false, is_active: 0 };
      }),
    },
  ]);
  const [dimensions, setDimensions] = useState([
    {
      min_length: 1,
      min_width: 1,
      min_height: 1,
      max_length: 5,
      max_width: 5,
      max_height: 5,
      zoneData: zones.map((item) => {
        return { zone_id: item.id, price: 0, enabled: false, is_active: 0 };
      }),
    },
  ]);
  const [cod, setCod] = useState([
    {
      min: 0,
      max: 15,
      zoneData: zones.map((item) => {
        return { zone_id: item.id, price: 0, enabled: false, is_active: 0 };
      }),
    },
  ]);
  const [protection, setProtection] = useState([
    {
      min: 0,
      max: 15,
      zoneData: zones.map((item) => {
        return { zone_id: item.id, price: 0, enabled: false, is_active: 0 };
      }),
    },
  ]);

  const tableSetup = () => {
    setData(
      questions.map((quest) => {
        return {
          question_id: quest.id,
          unit: quest.units[0],
          type: "package",
          rangeData,
          dimensions,
          cod,
          shipmentProtectionRange: protection,
          zoneData: zones.map((zone) => {
            return {
              price: 0,
              zone_id: zone.id,
              enabled: false,
              is_active: 0,
            };
          }),
        };
      })
    );
  };

  const addNewZone = (zone) => {
    setData(
      data.map((quest, index) => {
        if (questions[index].type === "simple") {
          return {
            ...quest,
            zoneData: [
              ...quest.zoneData,
              {
                price: 0,
                zone_id: zone.id,
                enabled: false,
                is_active: 0,
              },
            ],
          };
        } else if (questions[index].type === "weight") {
          setRangeData(
            rangeData.map((itm) => {
              return {
                ...itm,
                zoneData: [
                  ...itm.zoneData,
                  {
                    price: "0",
                    zone_id: zone.id,
                    enabled: false,
                    is_active: 0,
                  },
                ],
              };
            })
          );
          return {
            ...quest,
            rangeData: quest.rangeData.map((itm) => {
              return {
                ...itm,
                zoneData: [
                  ...itm.zoneData,
                  {
                    price: 0,
                    zone_id: zone.id,
                    enabled: false,
                    is_active: 0,
                  },
                ],
              };
            }),
          };
        } else if (questions[index].type === "size") {
          setDimensions(
            dimensions.map((itm) => {
              return {
                ...itm,
                zoneData: [
                  ...itm.zoneData,
                  {
                    price: "0",
                    zone_id: zone.id,
                    enabled: false,
                    is_active: 0,
                  },
                ],
              };
            })
          );
          return {
            ...quest,
            dimensions: quest.dimensions.map((itm) => {
              return {
                ...itm,
                zoneData: [
                  ...itm.zoneData,
                  {
                    price: 0,
                    zone_id: zone.id,
                    enabled: false,
                    is_active: 0,
                  },
                ],
              };
            }),
          };
        } else if (questions[index].type === "cod") {
          setCod(
            cod.map((itm) => {
              return {
                ...itm,
                zoneData: [
                  ...itm.zoneData,
                  {
                    price: "0",
                    zone_id: zone.id,
                    enabled: false,
                    is_active: 0,
                  },
                ],
              };
            })
          );
          return {
            ...quest,
            cod: quest.cod?.map((itm) => {
              return {
                ...itm,
                zoneData: [
                  ...itm.zoneData,
                  {
                    price: 0,
                    zone_id: zone.id,
                    enabled: false,
                    is_active: 0,
                  },
                ],
              };
            }),
          };
        } else if (questions[index].type === "protection") {
          setProtection(
            protection.map((itm) => {
              return {
                ...itm,
                zoneData: [
                  ...itm.zoneData,
                  {
                    price: "0",
                    zone_id: zone.id,
                    enabled: false,
                    is_active: 0,
                  },
                ],
              };
            })
          );
          return {
            ...quest,
            shipmentProtectionRange: quest.shipmentProtectionRange.map(
              (itm) => {
                return {
                  ...itm,
                  zoneData: [
                    ...itm.zoneData,
                    {
                      price: 0,
                      zone_id: zone.id,
                      enabled: false,
                      is_active: 0,
                    },
                  ],
                };
              }
            ),
          };
        }
      })
    );
    handleTempSave();
    setNewZoneAdded(false);
  };

  const validatePrices = () => {
    let status = true;
    data.forEach((item, index) => {
      if (questions[index].type === "weight") {
        let temp = rangeData;
        temp.forEach((itm) => {
          itm.zoneData.forEach((zd, idxx) => {
            if ([undefined, null, ""].includes(zd.price)) {
              status = false;
            }
          });
        });
      }
      if (questions[index].type === "size") {
        let temp = dimensions;
        temp.forEach((itm) => {
          itm.zoneData.forEach((zd) => {
            if ([undefined, null, ""].includes(zd.price)) {
              status = false;
            }
          });
        });
      }
      if (questions[index].type === "cod") {
        let temp = cod;
        temp.forEach((itm) => {
          itm.zoneData.forEach((zd) => {
            if ([undefined, null, ""].includes(zd.price)) {
              status = false;
            }
          });
        });
      }
      if (questions[index].type === "protection") {
        let temp = protection;
        temp.forEach((itm) => {
          itm.zoneData.forEach((zd) => {
            if ([undefined, null, ""].includes(zd.price)) {
              status = false;
            }
          });
        });
      }
      if (questions[index].type === "simple") {
        item.zoneData.forEach((zd) => {
          if ([undefined, null, ""].includes(zd.price)) {
            status = false;
          }
        });
      }
    });
    return status;
  };

  const handleTempSave = async () => {
    const priceValidated = validatePrices();
    if (!priceValidated) {
      return false;
    }
    let payload = {
      settings: [],
    };
    let settings = [];
    data.forEach((item, index) => {
      if (questions[index].type === "weight") {
        let temp = rangeData;
        let updated = [];
        temp.forEach((itm) => {
          let zoneData = [];
          itm.zoneData.forEach((zd, idxx) => {
            let zoneExists = false;
            zones.forEach((zn) => {
              if (zn.id === zd.zone_id) {
                zoneExists = true;
              }
            });
            if (zoneExists) {
              zoneData.push({
                ...zd,
                unit: item.unit,
                is_active: zd.enabled ? 1 : 0,
              });
            }
          });
          updated.push({ ...itm, zoneData });
        });
        settings.push({
          ...item,
          rangeData: updated,
        });
      }
      if (questions[index].type === "size") {
        let temp = dimensions;
        let updated = [];
        temp.forEach((itm) => {
          let zoneData = [];
          itm.zoneData.forEach((zd) => {
            let zoneExists = false;
            zones.forEach((zn) => {
              if (zn.id === zd.zone_id) {
                zoneExists = true;
              }
            });
            if (zoneExists) {
              zoneData.push({
                ...zd,
                unit: item.unit,
                is_active: zd.enabled ? 1 : 0,
              });
            }
          });
          updated.push({ ...itm, zoneData });
        });
        settings.push({ ...item, dimensions: updated });
      }
      if (questions[index].type === "cod") {
        let temp = cod;
        let updated = [];
        temp.forEach((itm) => {
          let zoneData = [];
          itm.zoneData.forEach((zd) => {
            let zoneExists = false;
            zones.forEach((zn) => {
              if (zn.id === zd.zone_id) {
                zoneExists = true;
              }
            });
            if (zoneExists) {
              zoneData.push({
                ...zd,
                unit: item.unit,
                is_active: zd.enabled ? 1 : 0,
              });
            }
          });
          updated.push({ ...itm, zoneData });
        });
        settings.push({ ...item, cod: updated });
      }
      if (questions[index].type === "protection") {
        let temp = protection;
        let updated = [];
        temp.forEach((itm) => {
          let zoneData = [];
          itm.zoneData.forEach((zd) => {
            let zoneExists = false;
            zones.forEach((zn) => {
              if (zn.id === zd.zone_id) {
                zoneExists = true;
              }
            });
            if (zoneExists) {
              zoneData.push({
                ...zd,
                unit: item.unit,
                is_active: zd.enabled ? 1 : 0,
              });
            }
          });
          updated.push({ ...itm, zoneData });
        });
        settings.push({ ...item, shipmentProtectionRange: updated });
      }
      if (questions[index].type === "simple") {
        let zoneData = [];
        item.zoneData.forEach((zd) => {
          let zoneExists = false;
          zones.forEach((zn) => {
            if (zn.id === zd.zone_id) {
              zoneExists = true;
            }
          });
          if (zoneExists) {
            zoneData.push({
              ...zd,
              unit: item.unit,
              is_active: zd.enabled ? 1 : 0,
            });
          }
        });
        settings.push({ ...item, zoneData });
      }
    });
    const response = await tempSaveZoneSettings(state.user.token, { settings });
    setLoadingField(false);
  };
  const getTempSettings = async () => {
    setSettingsLoader("Generating Table For Created Zones...");
    const response = await fetchTempZoneSettings(state.user.token);
    if (!response.error) {
      if (response.data?.result?.length > 0) {
        const res = response.data?.result;
        setData(res);
        res[3].rangeData && setRangeData(res[3].rangeData);
        res[4].dimensions && setDimensions(res[4].dimensions);
        res[5].cod && setCod(res[5].cod);
        res[19].shipmentProtectionRange &&
          setProtection(res[19].shipmentProtectionRange);
      } else {
        tableSetup();
      }
    }
    setSettingsLoader(false);
  };
  const replicateZone = (idx) => {
    let temp = {};
    setData((data) => {
      return data.map((dataItem) => {
        return {
          ...dataItem,
          zoneData: dataItem.zoneData.map((zItem, index) => {
            if (idx === index) {
              temp = zItem;
              return zItem;
            }
            if (idx + 1 === index) {
              return {
                ...zItem,
                price: temp.price,
                enabled: temp.enabled,
                is_active: temp.enabled ? 1 : 0,
              };
            } else {
              return zItem;
            }
          }),
        };
      });
    });
    setRangeData((rData) => {
      return rData.map((rItem) => {
        return {
          ...rItem,
          zoneData: rItem.zoneData.map((zItem, index) => {
            if (idx + 1 === index) {
              return {
                ...zItem,
                price: rItem.zoneData[idx].price,
                enabled: rItem.zoneData[idx].enabled,
                is_active: rItem.zoneData[idx].enabled ? 1 : 0,
              };
            } else {
              return zItem;
            }
          }),
        };
      });
    });
    setDimensions((rData) => {
      return rData.map((rItem) => {
        return {
          ...rItem,
          zoneData: rItem.zoneData.map((zItem, index) => {
            if (idx + 1 === index) {
              return {
                ...zItem,
                price: rItem.zoneData[idx].price,
                enabled: rItem.zoneData[idx].enabled,
                is_active: rItem.zoneData[idx].enabled ? 1 : 0,
              };
            } else {
              return zItem;
            }
          }),
        };
      });
    });
    setCod((rData) => {
      return rData.map((rItem) => {
        return {
          ...rItem,
          zoneData: rItem.zoneData.map((zItem, index) => {
            if (idx + 1 === index) {
              return {
                ...zItem,
                price: rItem.zoneData[idx].price,
                enabled: rItem.zoneData[idx].enabled,
                is_active: rItem.zoneData[idx].enabled ? 1 : 0,
              };
            } else {
              return zItem;
            }
          }),
        };
      });
    });
    setProtection((rData) => {
      return rData.map((rItem) => {
        return {
          ...rItem,
          zoneData: rItem.zoneData.map((zItem, index) => {
            if (idx + 1 === index) {
              return {
                ...zItem,
                price: rItem.zoneData[idx].price,
                enabled: rItem.zoneData[idx].enabled,
                is_active: rItem.zoneData[idx].enabled ? 1 : 0,
              };
            } else {
              return zItem;
            }
          }),
        };
      });
    });
  };
  const replicateZoneField = (idx, qIndex) => {
    let temp = {};
    setData((data) => {
      return data.map((dataItem, dataIndex) => {
        if (qIndex === dataIndex) {
          return {
            ...dataItem,
            zoneData: dataItem.zoneData.map((zItem, index) => {
              if (idx === index) {
                temp = zItem;
                return zItem;
              }
              if (idx + 1 === index) {
                return {
                  ...zItem,
                  price: temp.price,
                  enabled: temp.enabled,
                  is_active: temp.enabled ? 1 : 0,
                };
              } else {
                return zItem;
              }
            }),
          };
        } else {
          return dataItem;
        }
      });
    });
  };
  const handleSnackClose = () => {
    setSnack({ ...snack, open: false });
  };
  const replicateRangeDataField = (idx, qIndex, rIndex) => {
    setRangeData((rData) => {
      return rData.map((rItem, ridx) => {
        if (rIndex === ridx) {
          return {
            ...rItem,
            zoneData: rItem.zoneData.map((zItem, index) => {
              if (idx + 1 === index) {
                return {
                  ...zItem,
                  price: rItem.zoneData[idx].price,
                  enabled: rItem.zoneData[idx].enabled,
                  is_active: rItem.zoneData[idx].enabled ? 1 : 0,
                };
              } else {
                return zItem;
              }
            }),
          };
        } else {
          return rItem;
        }
      });
    });
  };
  const replicateDimensionsField = (idx, qIndex, rIndex) => {
    setDimensions((rData) => {
      return rData.map((rItem, ridx) => {
        if (rIndex === ridx) {
          return {
            ...rItem,
            zoneData: rItem.zoneData.map((zItem, index) => {
              if (idx + 1 === index) {
                return {
                  ...zItem,
                  price: rItem.zoneData[idx].price,
                  enabled: rItem.zoneData[idx].enabled,
                  is_active: rItem.zoneData[idx].enabled ? 1 : 0,
                };
              } else {
                return zItem;
              }
            }),
          };
        } else {
          return rItem;
        }
      });
    });
  };
  const replicateCodField = (idx, qIndex, rIndex) => {
    setCod((rData) => {
      return rData.map((rItem, ridx) => {
        if (rIndex === ridx) {
          return {
            ...rItem,
            zoneData: rItem.zoneData.map((zItem, index) => {
              if (idx + 1 === index) {
                return {
                  ...zItem,
                  price: rItem.zoneData[idx].price,
                  enabled: rItem.zoneData[idx].enabled,
                  is_active: rItem.zoneData[idx].enabled ? 1 : 0,
                };
              } else {
                return zItem;
              }
            }),
          };
        } else {
          return rItem;
        }
      });
    });
  };
  const replicateProtectionField = (idx, qIndex, rIndex) => {
    setProtection((rData) => {
      return rData.map((rItem, ridx) => {
        if (rIndex === ridx) {
          return {
            ...rItem,
            zoneData: rItem.zoneData.map((zItem, index) => {
              if (idx + 1 === index) {
                return {
                  ...zItem,
                  price: rItem.zoneData[idx].price,
                  enabled: rItem.zoneData[idx].enabled,
                  is_active: rItem.zoneData[idx].enabled ? 1 : 0,
                };
              } else {
                return zItem;
              }
            }),
          };
        } else {
          return rItem;
        }
      });
    });
  };
  const handleZoneFieldChecked = (e, idx, index, item, quest) => {
    setData((d) => {
      return d.map((dItem, dIndex) => {
        return index === dIndex
          ? {
            ...dItem,
            zoneData: data[index]?.zoneData?.map((zdata, zIndex) => {
              return zIndex === idx
                ? {
                  ...zdata,
                  zone_id: item.id,
                  enabled: !zdata.enabled,
                  is_active: !zdata.enabled ? 1 : 0,
                }
                : zdata;
            }),
          }
          : dItem;
      });
    });
    setSnack({
      ...snack,
      open: false,
    });

    setTimeout(() => {
      setSnack({
        ...snack,
        open: true,
        text: `${quest.name} is ${e.target.checked ? "enabled" : "disabled"
          } for "${item.name}"`,
        severity: "info",
      });
    }, 500);
  };

  const handleZoneWeightFieldChecked = (e, idx, rdIndex, item, quest, unit) => {
    setRangeData((pData) => {
      return pData.map((pItem, pIndex) => {
        return rdIndex === pIndex
          ? {
            ...pItem,
            zoneData: pItem.zoneData.map((zItem, zIdx) => {
              return zIdx === idx
                ? {
                  ...zItem,
                  zone_id: item.id,
                  enabled: !zItem.enabled,
                  is_active: !zItem.enabled ? 1 : 0,
                }
                : zItem;
            }),
          }
          : pItem;
      });
    });
    setSnack({
      ...snack,
      open: false,
    });

    setTimeout(() => {
      setSnack({
        ...snack,
        open: true,
        text: `${item.name} is ${e.target.checked ? "enabled" : "disabled"
          } for  package weight between "${rangeData[rdIndex].min}${unit} - ${rangeData[rdIndex].max
          }${unit}" `,
        severity: "info",
      });
    }, 500);
  };
  const handleZoneSizeFieldChecked = (e, idx, dmIndex, item, quest, unit) => {
    setDimensions((pData) => {
      return pData.map((pItem, pIndex) => {
        return dmIndex === pIndex
          ? {
            ...pItem,
            zoneData: pItem.zoneData.map((zItem, zIdx) => {
              return zIdx === idx
                ? {
                  ...zItem,
                  zone_id: item.id,
                  enabled: !zItem.enabled,
                  is_active: !zItem.enabled ? 1 : 0,
                }
                : zItem;
            }),
          }
          : pItem;
      });
    });
    setSnack({
      ...snack,
      open: false,
    });

    setTimeout(() => {
      setSnack({
        ...snack,
        open: true,
        text: `${item.name} is ${e.target.checked ? "enabled" : "disabled"
          } for  package Size between "${dimensions[dmIndex].min_length}${unit}-
        ${dimensions[dmIndex].min_width}${unit}-
        ${dimensions[dmIndex].min_height}${unit}
        to ${dimensions[dmIndex].max_length}${unit}-
        ${dimensions[dmIndex].max_width}${unit}-
        ${dimensions[dmIndex].max_height}${unit}
        " `,
        severity: "info",
      });
    }, 500);
  };
  const handleZoneCodFieldChecked = (e, idx, dmIndex, item, quest, unit) => {
    setCod((pData) => {
      return pData.map((pItem, pIndex) => {
        return dmIndex === pIndex
          ? {
            ...pItem,
            zoneData: pItem.zoneData.map((zItem, zIdx) => {
              return zIdx === idx
                ? {
                  ...zItem,
                  zone_id: item.id,
                  enabled: !zItem.enabled,
                  is_active: !zItem.enabled ? 1 : 0,
                }
                : zItem;
            }),
          }
          : pItem;
      });
    });
    setSnack({
      ...snack,
      open: false,
    });

    setTimeout(() => {
      setSnack({
        ...snack,
        open: true,
        text: `${quest.name} is ${e.target.checked ? "enabled" : "disabled"
          } in ${item.name} for price between "${cod[dmIndex].min}${unit} - ${cod[dmIndex].max
          }${unit}" `,
        severity: "info",
      });
    }, 500);
  };
  const handleZoneProtectionFieldChecked = (
    e,
    idx,
    dmIndex,
    item,
    quest,
    unit
  ) => {
    setProtection((pData) => {
      return pData.map((pItem, pIndex) => {
        return dmIndex === pIndex
          ? {
            ...pItem,
            zoneData: pItem.zoneData.map((zItem, zIdx) => {
              return zIdx === idx
                ? {
                  ...zItem,
                  zone_id: item.id,
                  enabled: !zItem.enabled,
                  is_active: !zItem.enabled ? 1 : 0,
                }
                : zItem;
            }),
          }
          : pItem;
      });
    });
    setSnack({
      ...snack,
      open: false,
    });

    setTimeout(() => {
      setSnack({
        ...snack,
        open: true,
        text: `${quest.name} is ${e.target.checked ? "enabled" : "disabled"
          } in ${item.name} for price between "${protection[dmIndex].min
          }${unit} - ${protection[dmIndex].max}${unit}" `,
        severity: "info",
      });
    }, 500);
  };
  const handleUnitChange = (quest_id, unit) => {
    setData((d) =>
      d.map((itm) => {
        return itm.question_id === quest_id ? { ...itm, unit } : itm;
      })
    );
  };

  const handleCarrierType = (quest_id, type) => {
    setData((d) =>
      d.map((itm) => {
        return itm.question_id === quest_id ? { ...itm, type } : itm;
      })
    );
    setExpandDropdown(false);
  };
  const handleAddRangeData = (preData) => {
    const rng = rangeData[rangeData.length - 1];
    setRangeData([
      ...rangeData,
      // rng,
      {
        ...rng,
        min: Number(rng.max) + 1,
        max:
          Number(rng.min) === 0
            ? Number(rng.max) + (Number(rng.max) - Number(rng.min))
            : Number(rng.max) + (Number(rng.max) - Number(rng.min)) + 1,
      },
    ]);
  };
  const handleRemoveRangeData = (index) => {
    setRangeData(rangeData.filter((rng, ind) => index !== ind));
  };
  const handleAddDimension = (dm) => {
    // setDimensions([...dimensions, dm]);
    const rng = dimensions[dimensions.length - 1];
    setDimensions([
      ...dimensions,
      {
        ...rng,
        min_width: Number(rng.max_width) + 1,
        min_length: Number(rng.max_length) + 1,
        min_height: Number(rng.max_height) + 1,
        max_width:
          Number(rng.max_width) +
          (Number(rng.max_width) - Number(rng.min_width)) +
          1,
        max_length:
          Number(rng.max_length) +
          (Number(rng.max_length) - Number(rng.min_length)) +
          1,
        max_height:
          Number(rng.max_height) +
          (Number(rng.max_height) - Number(rng.min_height)) +
          1,
      },
    ]);
  };
  const handleRemoveDimension = (index) => {
    setDimensions(dimensions.filter((rng, ind) => index !== ind));
  };
  const handleAddCod = (codItm) => {
    // setCod([...cod, codItm]);
    const rng = cod[cod.length - 1];
    setCod([
      ...cod,
      {
        ...rng,
        min: Number(rng.max) + 1,
        max:
          Number(rng.min) === 0
            ? Number(rng.max) + (Number(rng.max) - Number(rng.min))
            : Number(rng.max) + (Number(rng.max) - Number(rng.min)) + 1,
      },
    ]);
  };
  const handleRemoveCod = (index) => {
    setCod(cod.filter((rng, ind) => index !== ind));
  };
  const handleAddProtection = (protectionItm) => {
    // setProtection([...protection, protectionItm]);
    const rng = protection[protection.length - 1];
    setProtection([
      ...protection,
      {
        ...rng,
        min: Number(rng.max) + 1,
        max:
          Number(rng.min) === 0
            ? Number(rng.max) + (Number(rng.max) - Number(rng.min))
            : Number(rng.max) + (Number(rng.max) - Number(rng.min)) + 1,
      },
    ]);
  };
  const handleRemoveProtection = (index) => {
    setProtection(protection.filter((rng, ind) => index !== ind));
  };
  const handleRangeType = (type) => {
    setRangeDropdown(false);
  };
  const handleDimensionChange = (e, index) => {
    setDimensions((dim) => {
      return dim.map((itm, idx) => {
        return index === idx
          ? { ...itm, [e.target.name]: e.target.value }
          : itm;
      });
    });
  };
  const handleCodChange = (e, index) => {
    setCod((dim) => {
      return dim.map((itm, idx) => {
        return index === idx
          ? { ...itm, [e.target.name]: e.target.value }
          : itm;
      });
    });
  };
  const handleProtectionChange = (e, index) => {
    setProtection((dim) => {
      return dim.map((itm, idx) => {
        return index === idx
          ? { ...itm, [e.target.name]: e.target.value }
          : itm;
      });
    });
  };
  useEffect(() => {
    if (questions) {
      // tableSetup();
      getTempSettings();
    }
  }, [questions]);

  useEffect(() => {
    if (questions && questions?.length > 0) {
      handleTempSave();
    }
  }, [zones]);
  const handleDeleteZoneEffect = async () => {
    if (zoneDeleted) {
      const res = await handleTempSave();
      reloadMap();
      setZoneDeleted(false);
      // maps.event.trigger(map, "resize");
    }
  };
  useEffect(() => {
    handleDeleteZoneEffect();
  }, [zoneDeleted]);

  useEffect(() => {
    if (newZoneAdded && questions?.length > 0) {
      addNewZone(newZoneAdded);
    }
  }, [newZoneAdded, questions]);
  const handleSaveZoneSettings = async () => {
    setSettingsLoader("Saving Zones Settings...");
    let payload = {
      settings: [],
    };
    let settings = [];
    data.forEach((item, index) => {
      if (questions[index].type === "weight") {
        let temp = rangeData;
        let updated = [];
        temp.forEach((itm) => {
          let zoneData = [];
          itm.zoneData.forEach((zd) => {
            zoneData.push({
              ...zd,
              unit: item.unit,
              is_active: zd.enabled ? 1 : 0,
            });
          });
          updated.push({ ...itm, zone_data: zoneData });
        });
        settings.push({
          ...item,
          range_data: updated,
          dimensions: [],
        });
      }
      if (questions[index].type === "size") {
        let temp = dimensions;
        let updated = [];
        temp.forEach((itm) => {
          let zoneData = [];
          itm.zoneData.forEach((zd) => {
            zoneData.push({
              ...zd,
              unit: item.unit,
              is_active: zd.enabled ? 1 : 0,
            });
          });
          updated.push({ ...itm, zone_data: zoneData });
        });
        settings.push({ ...item, dimensions: updated, range_data: [] });
      }
      if (questions[index].type === "cod") {
        let temp = cod;
        let updated = [];
        temp.forEach((itm) => {
          let zoneData = [];
          itm.zoneData.forEach((zd) => {
            zoneData.push({
              ...zd,
              unit: item.unit,
              is_active: zd.enabled ? 1 : 0,
            });
          });
          updated.push({ ...itm, zone_data: zoneData });
        });
        settings.push({ ...item, range_data: updated, dimensions: [] });
      }
      if (questions[index].type === "protection") {
        let temp = protection;
        let updated = [];
        temp.forEach((itm) => {
          let zoneData = [];
          itm.zoneData.forEach((zd) => {
            zoneData.push({
              ...zd,
              unit: item.unit,
              is_active: zd.enabled ? 1 : 0,
            });
          });
          updated.push({ ...itm, zone_data: zoneData });
        });
        settings.push({ ...item, range_data: updated, dimensions: [] });
      }
      if (questions[index].type === "simple") {
        let zoneData = [];
        item.zoneData.forEach((zd) => {
          zoneData.push({
            ...zd,
            unit: item.unit,
            is_active: zd.enabled ? 1 : 0,
          });
        });
        settings.push({
          ...item,
          zone_data: zoneData,
          dimensions: [],
          range_data: [],
        });
      }
    });
    const response = await saveZoneSettings(state.user.token, { settings });
    if (!response.error) {
      setSnack({
        ...snack,
        open: true,
        text: "Zone saved successfully",
        severity: "success",
      });
    }
    if (response.error) {
      setSnack({
        ...snack,
        open: true,
        text: "Couldn't save zone",
        severity: "error",
      });
    }
    setSettingsLoader(false);
  };
  const getAllQuestions = async () => {
    const response = await fetchAllQuestions(state.user.token);
    if (response.data) {
      setQuestions(response.data.result);
    }
  };
  const InputValidator = (value) => {
    if (value !== "") {
      let numb = value.match(/^[0-9]+(\.[0-9]*)?/g);
      numb = numb.join("");
      // console.log(numb);
      return numb;
    } else if (value === undefined) {
      return "";
    } else {
      return "";
    }
  };

  useEffect(() => {
    getAllQuestions();
    if (window.innerWidth < 1600) {
      setZonePerPage(3);
    }
    if (window.innerWidth > 1600) {
      setZonePerPage(3);
    }
  }, []);
  useEffect(() => {
    !settingsLoader && focused === "" && handleTempSave();
  }, [data, cod, dimensions, rangeData, protection]);

  useEffect(() => {
    setSettingsLoader(
      questions.length < 1 ? "Generating Table For Created Zones..." : false
    );
  }, [questions]);

  return (
    <div className="zone_settings_table">
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.text}
        </Alert>
      </Snackbar>
      {!settingsLoader && (
        <div className="zsettings_table_icons">
          <LightTooltip placement="top" arrow title="Save these settings">
            <div
              className="zsettings_table_iconwrap"
              onClick={handleSaveZoneSettings}
            >
              <Save />
              <span>Save</span>
            </div>
          </LightTooltip>
          {/* <div className="zsettings_table_iconwrap">
            <Download />
            <span>Download</span>
          </div> */}
        </div>
      )}
      {!settingsLoader && (
        <div className="zsettings_table_contentwrap">
          <div className="zsettings_table_content">
            <div className="zstable_content_left">
              <div className="zstc_col_questions">
                <div className="zstc_col_heading">Questions</div>
                {questions.map((item, questIndex) => (
                  // <LightTooltip
                  //   placement="top"
                  //   arrow
                  //   title={`Click to highlight fields for ${item.name}`}
                  // >
                  <div
                    className={`zstc_col_data ${[activeQuestion, hoverQuestion].includes(item.id) &&
                      "zstc_col_dataactive"
                      }`}
                    style={{
                      minHeight:
                        item.type === "weight"
                          ? `${rangeData.length * 50}px`
                          : item.type === "size"
                            ? `${dimensions.length * 60}px`
                            : item.type === "cod"
                              ? `${cod.length * 50 + 50}px`
                              : item.type === "protection"
                                ? `${protection.length * 50 + 50}px`
                                : "50px",
                      paddingBottom: ["cod", "protection"].includes(item.type)
                        ? "100px"
                        : item.type === "size"
                          ? "0px"
                          : "0px",
                      marginBottom: item.type === "size" ? "50px" : "0px",
                    }}
                    onClick={() => setActiveQuestion(item.id)}
                    onMouseEnter={() => setHoverQuestion(item.id)}
                    onMouseLeave={() => setHoverQuestion(item.id)}
                  >
                    {item.name} <Question className="zstc_col_quest" />
                  </div>
                  // </LightTooltip>
                ))}
              </div>
              <div className="zstc_col_settings">
                <div className="zstc_col_heading">Settings</div>
                {questions.map((item, index) => (
                  <div
                    className={`zstc_colset_data `}
                    style={{
                      minHeight:
                        item.type === "weight"
                          ? `${rangeData.length * 50}px`
                          : item.type === "size"
                            ? `${dimensions.length * 50}px`
                            : "50px",
                    }}
                  >
                    <div className="zstc_col_settingsdata">
                      <div className="zstc_col_settingsdataunits">
                        {item.units.map((unit, idx) => (
                          <div
                            className={`zstc_col_settingsdataunit ${data[index]?.unit === unit &&
                              "zstc_col_settingsdataunitactive"
                              } ${(item.id === activeQuestion ||
                                item.id === hoverQuestion) &&
                              "active__question_setfield"
                              }`}
                            onClick={() => handleUnitChange(item.id, unit)}
                          >
                            {unit}
                          </div>
                        ))}
                      </div>
                      <div
                        className={`zs_dropdown_wrap ${(item.id === activeQuestion ||
                          item.id === hoverQuestion) &&
                          "active__question_setfield"
                          }`}
                      >
                        <div className="zszone_select_wrap">
                          <div
                            className="zszone_select_value"
                            onClick={() =>
                              setExpandDropdown(
                                expandDropdown === item.id ? false : item.id
                              )
                            }
                          >
                            {data[index]?.type}
                          </div>

                          <DownIcon
                            className="zszone_select_icon"
                            onClick={() =>
                              setExpandDropdown(
                                expandDropdown === item.id ? false : item.id
                              )
                            }
                          />
                          {expandDropdown === item.id && (
                            <div className="zs_zone_options_list">
                              <div
                                className="zs_zone_option"
                                onClick={() =>
                                  handleCarrierType(item.id, "package")
                                }
                              >
                                Package
                              </div>
                              <div
                                className="zs_zone_option"
                                onClick={() =>
                                  handleCarrierType(item.id, "shipment")
                                }
                              >
                                Shipment
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {item.type === "weight" && (
                        <div className="zs_rangedata_list">
                          {rangeData.map((rng, idx) => (
                            <div className="zs_rangedata_wrap">
                              <input
                                type="text"
                                className={`zs_rangedata_input ${(item.id === activeQuestion ||
                                  item.id === hoverQuestion) &&
                                  "active__question_setfield"
                                  }`}
                                value={rng.min}
                                onChange={(e) => {
                                  setRangeData(
                                    rangeData.map((item, index) => {
                                      return index === idx
                                        ? { ...item, min: e.target.value }
                                        : item;
                                    })
                                  );
                                }}
                              />
                              <span className="zs_rangedata_to">to</span>
                              <input
                                type="text"
                                className={`zs_rangedata_input ${(item.id === activeQuestion ||
                                  item.id === hoverQuestion) &&
                                  "active__question_setfield"
                                  }`}
                                value={rng.max}
                                onChange={(e) =>
                                  setRangeData(
                                    rangeData.map((item, index) => {
                                      return index === idx
                                        ? { ...item, max: e.target.value }
                                        : item;
                                    })
                                  )
                                }
                              />
                              <Copy
                                className="zs_rangedata_copy"
                                onClick={() => handleAddRangeData(rng)}
                              />
                              {idx > 0 && (
                                <Delete
                                  className="zs_rangedata_delete"
                                  onClick={() => handleRemoveRangeData(idx)}
                                />
                              )}
                              {idx < 1 && (
                                <div className="zs_rangedata_deleteplaceholder"></div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                      {["size", "cod", "protection"].includes(item.type) && (
                        <div
                          className={`zs_dropdown_wrap ${(item.id === activeQuestion ||
                            item.id === hoverQuestion) &&
                            "active__question_setfield"
                            }`}
                        >
                          <div className="zszone_select_wrap">
                            <div
                              className="zszone_select_value"
                              onClick={() =>
                                setRangeDropdown(
                                  rangeDropdown === item.id ? false : item.id
                                )
                              }
                            >
                              Range
                            </div>

                            <DownIcon
                              className="zszone_select_icon"
                              onClick={() =>
                                setRangeDropdown(
                                  rangeDropdown === item.id ? false : item.id
                                )
                              }
                            />
                            {rangeDropdown === item.id && (
                              <div className="zs_zone_options_list">
                                <div
                                  className="zs_zone_option"
                                  onClick={() => handleRangeType("range")}
                                >
                                  Range
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    {item.type === "size" &&
                      dimensions.map((dimension, indx) => (
                        <div className="zstcc_settings_dimensionsdata">
                          <div className="zstcc_settings_dimensionsdataleft">
                            <div className="zstccsddl_dimension_item">
                              <div className="zstccsddl_dimension_itemtop">
                                <span>L</span>
                                <span>W</span>
                                <span>H</span>
                              </div>
                              <div className="zstccsddl_dimension_itembottom">
                                <input
                                  type="text"
                                  className={`zs_rangedata_input ${(item.id === activeQuestion ||
                                    item.id === hoverQuestion) &&
                                    "active__question_setfield"
                                    }`}
                                  name="min_length"
                                  onChange={(e) =>
                                    handleDimensionChange(e, indx)
                                  }
                                  value={dimension.min_length}
                                />
                                <input
                                  type="text"
                                  className={`zs_rangedata_input ${(item.id === activeQuestion ||
                                    item.id === hoverQuestion) &&
                                    "active__question_setfield"
                                    }`}
                                  onChange={(e) =>
                                    handleDimensionChange(e, indx)
                                  }
                                  name="min_width"
                                  value={dimension.min_width}
                                />
                                <input
                                  type="text"
                                  className={`zs_rangedata_input ${(item.id === activeQuestion ||
                                    item.id === hoverQuestion) &&
                                    "active__question_setfield"
                                    }`}
                                  onChange={(e) =>
                                    handleDimensionChange(e, indx)
                                  }
                                  name="min_height"
                                  value={dimension.min_height}
                                />
                              </div>
                            </div>
                            <span className="zstccsddl_dimension_middletext">
                              to
                            </span>
                            <div className="zstccsddl_dimension_item">
                              <div className="zstccsddl_dimension_itemtop">
                                <span>L</span>
                                <span>W</span>
                                <span>H</span>
                              </div>
                              <div className="zstccsddl_dimension_itembottom">
                                <input
                                  type="text"
                                  className={`zs_rangedata_input ${(item.id === activeQuestion ||
                                    item.id === hoverQuestion) &&
                                    "active__question_setfield"
                                    }`}
                                  onChange={(e) =>
                                    handleDimensionChange(e, indx)
                                  }
                                  name="max_length"
                                  value={dimension.max_length}
                                />
                                <input
                                  type="text"
                                  className={`zs_rangedata_input ${(item.id === activeQuestion ||
                                    item.id === hoverQuestion) &&
                                    "active__question_setfield"
                                    }`}
                                  onChange={(e) =>
                                    handleDimensionChange(e, indx)
                                  }
                                  name="max_width"
                                  value={dimension.max_width}
                                />
                                <input
                                  type="text"
                                  className={`zs_rangedata_input ${(item.id === activeQuestion ||
                                    item.id === hoverQuestion) &&
                                    "active__question_setfield"
                                    }`}
                                  onChange={(e) =>
                                    handleDimensionChange(e, indx)
                                  }
                                  name="max_height"
                                  value={dimension.max_height}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="zstcc_settings_dimensionsdataright">
                            <Copy
                              onClick={() => handleAddDimension(dimension)}
                            />
                            {indx > 0 && (
                              <Delete
                                onClick={() => handleRemoveDimension(indx)}
                              />
                            )}
                            {indx < 1 && (
                              <div className="zs_rangedata_deleteplaceholder"></div>
                            )}
                          </div>
                        </div>
                      ))}
                    {item.type === "cod" &&
                      cod.map((codItem, indx) => (
                        <div className="zstcc_settings_coddata">
                          <div className="zstcc_settings_coddataleft">
                            <input
                              type="text"
                              className={`zs_coddata_input ${(item.id === activeQuestion ||
                                item.id === hoverQuestion) &&
                                "active__question_setfield"
                                }`}
                              name="min"
                              value={codItem.min}
                              onChange={(e) => handleCodChange(e, indx)}
                            />
                            <span>to</span>
                            <input
                              type="text"
                              className={`zs_coddata_input ${(item.id === activeQuestion ||
                                item.id === hoverQuestion) &&
                                "active__question_setfield"
                                }`}
                              name="max"
                              value={codItem.max}
                              onChange={(e) => handleCodChange(e, indx)}
                            />
                          </div>
                          <div className="zstcc_settings_coddataright">
                            <Copy onClick={() => handleAddCod(codItem)} />
                            {indx > 0 && (
                              <Delete onClick={() => handleRemoveCod(indx)} />
                            )}
                            {indx < 1 && (
                              <div className="zs_rangedata_deleteplaceholder"></div>
                            )}
                          </div>
                        </div>
                      ))}
                    {item.type === "protection" &&
                      protection.map((protectionItem, indx) => (
                        <div className="zstcc_settings_coddata">
                          <div className="zstcc_settings_coddataleft">
                            <input
                              type="text"
                              className={`zs_coddata_input ${(item.id === activeQuestion ||
                                item.id === hoverQuestion) &&
                                "active__question_setfield"
                                }`}
                              name="min"
                              value={protectionItem.min}
                              onChange={(e) => handleProtectionChange(e, indx)}
                            />
                            <span>to</span>
                            <input
                              type="text"
                              className={`zs_coddata_input ${(item.id === activeQuestion ||
                                item.id === hoverQuestion) &&
                                "active__question_setfield"
                                }`}
                              name="max"
                              value={protectionItem.max}
                              onChange={(e) => handleProtectionChange(e, indx)}
                            />
                          </div>
                          <div className="zstcc_settings_coddataright">
                            <Copy
                              onClick={() =>
                                handleAddProtection(protectionItem)
                              }
                            />
                            {indx > 0 && (
                              <Delete
                                onClick={() => handleRemoveProtection(indx)}
                              />
                            )}
                            {indx < 1 && (
                              <div className="zs_rangedata_deleteplaceholder"></div>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            </div>
            <div className="zstcontent_border_center"></div>
            <div className="zstable_content_right">
              <Swiper
                spaceBetween={10}
                slidesPerView={zonePerPage}
                // onSlideChange={() => console.log("slide change")}
                // onSwiper={(swiper) => console.log(swiper)}
                pagination={{
                  type: "progressbar",
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
              >
                {zones.map((item, idx) => (
                  <SwiperSlide>
                    <div className="zstcr_zone_col">
                      <div className="zstc_col_heading">
                        {item.name.length < 8 && item.name}
                        {item.name.length > 7 && (
                          <LightTooltip placement="top" arrow title={item.name}>
                            <span className="zstc_col_headingwrap">
                              {item.name.substr(0, 7)}
                            </span>
                          </LightTooltip>
                        )}
                        {item.name.length > 7 && "..."}

                        {idx + 1 < zones.length && (
                          <LightTooltip
                            placement="top"
                            arrow
                            title="Replicate data to next zone"
                          >
                            <Paste
                              className="zstc_col_headingpasteicon"
                              onClick={() => replicateZone(idx)}
                            />
                          </LightTooltip>
                        )}
                      </div>
                      <br />
                      {questions.map((quest, index) => (
                        <>
                          {quest.type === "simple" && (
                            <div className="zstc_zcol_data">
                              {/* <input
                              type="checkbox"
                              className="zstc_col_datacheckbox"
                            /> */}
                              <LightTooltip
                                placement="top"
                                arrow
                                title={`${data[index]?.zoneData[idx]?.enabled
                                  ? "Disable"
                                  : "Enable"
                                  } ${quest.name}`}
                              >
                                <AntSwitch
                                  checked={data[index]?.zoneData[idx]?.enabled}
                                  onChange={(e) =>
                                    handleZoneFieldChecked(
                                      e,
                                      idx,
                                      index,
                                      item,
                                      quest
                                    )
                                  }
                                />
                              </LightTooltip>
                              <LightTooltip
                                placement="top"
                                arrow
                                title={`Price for ${quest.name}`}
                              >
                                <input
                                  type="text"
                                  className={`zstc_col_datafield ${!data[index]?.zoneData[idx]?.enabled
                                    ? "zstcc_disabled_datafield"
                                    : ""
                                    } ${(quest.id === activeQuestion ||
                                      quest.id === hoverQuestion) &&
                                    "active__question_field"
                                    }`}
                                  onFocus={() =>
                                    setFocused(
                                      data[index]?.zoneData[idx]?.zone_id +
                                      `${quest.id}` + `${idx}`
                                    )
                                  }
                                  onBlur={() => {
                                    setLoadingField(focused);
                                    setFocused("");
                                    handleTempSave();
                                  }}
                                  value={`${![undefined, null, "",].includes(
                                    data[index]?.zoneData[idx]?.price
                                  )
                                    ? data[index]?.zoneData[idx]?.price
                                    : ""
                                    }${focused !==
                                      data[index]?.zoneData[idx]?.zone_id +
                                      `${quest.id}` + `${idx}` &&
                                      data[index]?.zoneData[idx]?.price &&
                                      data[index]?.zoneData[idx]?.price !== ""
                                      ? `${!["$", "%"].includes(
                                        data[index]?.unit
                                      )
                                        ? `/${data[index]?.unit}`
                                        : data[index]?.unit
                                      }`
                                      : ""
                                    }`}
                                  placeholder="0$"
                                  onChange={(e) => {
                                    const val = InputValidator(e.target.value);
                                    setData((d) => {
                                      return d.map((dItem, dIndex) => {
                                        return index === dIndex
                                          ? {
                                            ...dItem,
                                            zoneData: data[
                                              index
                                            ]?.zoneData?.map(
                                              (zdata, zIndex) => {
                                                return zIndex === idx
                                                  ? {
                                                    ...zdata,
                                                    zone_id: item.id,
                                                    price: val ?? "",
                                                  }
                                                  : zdata;
                                              }
                                            ),
                                          }
                                          : dItem;
                                      });
                                    });
                                  }}
                                />
                              </LightTooltip>
                              {data[index]?.zoneData[idx]?.zone_id +
                                `${quest.id}` + `${idx}` ==
                                loadingField && (
                                  <span className="zstc_col_datafieldloading">
                                    <FieldLoading />
                                  </span>
                                )}
                              <div className="zstc_col_databottomtext">
                                Per&nbsp;
                                {Object.keys(units).includes(
                                  data[index]?.unit.toLowerCase()
                                )
                                  ? fullUnit(data[index]?.unit)
                                  : data[index]?.type}
                              </div>
                              <LightTooltip
                                placement="top"
                                arrow
                                title="Replicate data to next zone"
                              >
                                <Copy
                                  className="zstc_col_datacopyicon"
                                  onClick={() => replicateZoneField(idx, index)}
                                />
                              </LightTooltip>
                            </div>
                          )}
                          {quest.type === "weight" &&
                            rangeData.map((rd, rdIndex) => (
                              <div className="zstc_zcol_data">
                                {/* <input
                                type="checkbox"
                                className="zstc_col_datacheckbox"
                              /> */}
                                <LightTooltip
                                  placement="top"
                                  arrow
                                  title={`${rd.zoneData[idx]?.enabled
                                    ? "Disable"
                                    : "Enable"
                                    } for Weight between ${rd.min}${data[index]?.unit
                                    } - ${rd.max}${data[index]?.unit}`}
                                >
                                  <AntSwitch
                                    checked={rd.zoneData[idx]?.enabled}
                                    onChange={(e) =>
                                      handleZoneWeightFieldChecked(
                                        e,
                                        idx,
                                        rdIndex,
                                        item,
                                        quest,
                                        data[index]?.unit
                                      )
                                    }
                                  />
                                </LightTooltip>
                                <LightTooltip
                                  placement="top"
                                  arrow
                                  title={`Price for ${rd.min}${data[index]?.unit} - ${rd.max}${data[index]?.unit}`}
                                >
                                  <input
                                    type="text"
                                    className={`zstc_col_datafield ${!rd.zoneData[idx]?.enabled
                                      ? "zstcc_disabled_datafield"
                                      : ""
                                      } ${(quest.id === activeQuestion ||
                                        quest.id === hoverQuestion) &&
                                      "active__question_field"
                                      }`}
                                    onFocus={() =>
                                      setFocused(
                                        `${rangeData[rdIndex]?.zoneData[idx]?.zone_id}` +
                                        `${quest.id}` +
                                        `${rdIndex}`
                                      )
                                    }
                                    onBlur={() => {
                                      setLoadingField(focused);
                                      setFocused("");
                                      handleTempSave();
                                    }}
                                    value={`${![undefined, null, "", 0, "0"].includes(
                                      rd.zoneData[idx]?.price
                                    )
                                      ? rd.zoneData[idx]?.price
                                      : ""
                                      }${focused !==
                                        `${rangeData[rdIndex]?.zoneData[idx]?.zone_id}` +
                                        `${quest.id}` +
                                        `${rdIndex}` &&
                                        rd.zoneData[idx]?.price &&
                                        rd.zoneData[idx]?.price !== ""
                                        ? //  `${
                                        //     !["$", "%"].includes(
                                        //       data[index]?.unit
                                        //     )
                                        //       ? `/${data[index]?.unit}`
                                        //       : data[index]?.unit
                                        //   }`
                                        "$"
                                        : ""
                                      }`}
                                    placeholder="0$"
                                    onChange={(e) => {
                                      const val = InputValidator(
                                        e.target.value
                                      );
                                      setRangeData((pData) => {
                                        return pData.map((pItem, pIndex) => {
                                          return rdIndex === pIndex
                                            ? {
                                              ...pItem,
                                              zoneData: pItem.zoneData.map(
                                                (zItem, zIdx) => {
                                                  return zIdx === idx
                                                    ? {
                                                      ...zItem,
                                                      zone_id: item.id,
                                                      price: val ?? "",
                                                    }
                                                    : zItem;
                                                }
                                              ),
                                            }
                                            : pItem;
                                        });
                                      });
                                    }}
                                  />
                                </LightTooltip>
                                {`${rangeData[rdIndex]?.zoneData[idx]?.zone_id}${quest.id}${rdIndex}` === loadingField && (
                                  <span className="zstc_col_datafieldloading">
                                    <FieldLoading />
                                  </span>
                                )}
                                <div className="zstc_col_databottomtext">
                                  Per &nbsp;
                                  {Object.keys(units).includes(
                                    data[index]?.unit.toLowerCase()
                                  )
                                    ? fullUnit(data[index]?.unit)
                                    : data[index]?.type}
                                </div>
                                <LightTooltip
                                  placement="top"
                                  arrow
                                  title="Replicate data to next zone"
                                >
                                  <Copy
                                    className="zstc_col_datacopyicon"
                                    onClick={() =>
                                      replicateRangeDataField(
                                        idx,
                                        index,
                                        rdIndex
                                      )
                                    }
                                  />
                                </LightTooltip>
                              </div>
                            ))}
                          {quest.type === "size" && (
                            <div className="zstc_dimensions_placeholder"></div>
                          )}
                          {quest.type === "cod" && (
                            <div className="zstc_cod_placeholder"></div>
                          )}
                          {quest.type === "protection" && (
                            <div className="zstc_cod_placeholder"></div>
                          )}
                          {quest.type === "size" &&
                            dimensions.map((dm, dmIndex) => (
                              <div className="zstc_zcol_data2">
                                {/* <input
                                type="checkbox"
                                className="zstc_col_datacheckbox"
                              /> */}
                                <LightTooltip
                                  placement="top"
                                  arrow
                                  title={`${dm.zoneData[idx]?.enabled
                                    ? "Disable"
                                    : "Enable"
                                    } for Size between ${dm.min_length}-${dm.min_width
                                    }-${dm.min_height} to ${dm.max_length}-${dm.max_width
                                    }-${dm.max_height} ${fullSizeUnit(
                                      data[index]?.unit
                                    )}`}
                                >
                                  <AntSwitch
                                    checked={dm.zoneData[idx]?.enabled}
                                    onChange={(e) =>
                                      handleZoneSizeFieldChecked(
                                        e,
                                        idx,
                                        dmIndex,
                                        item,
                                        quest,
                                        data[index]?.unit
                                      )
                                    }
                                  />
                                </LightTooltip>
                                <LightTooltip
                                  placement="top"
                                  arrow
                                  title={`Price for ${dm.min_length}-${dm.min_width
                                    }-${dm.min_height} to ${dm.max_length}-${dm.max_width
                                    }-${dm.max_height} ${fullSizeUnit(
                                      data[index]?.unit
                                    )}`}
                                >
                                  <input
                                    type="text"
                                    className={`zstc_col_datafield ${!dm.zoneData[idx]?.enabled
                                      ? "zstcc_disabled_datafield"
                                      : ""
                                      } ${(quest.id === activeQuestion ||
                                        quest.id === hoverQuestion) &&
                                      "active__question_field"
                                      }`}
                                    onFocus={() =>
                                      setFocused(
                                        dimensions[dmIndex]?.zoneData[idx]
                                          ?.zone_id +
                                        `${quest.id}` +
                                        `${dmIndex}` +
                                        `${idx}`
                                      )
                                    }
                                    onBlur={() => {
                                      setLoadingField(focused);
                                      setFocused("");
                                      handleTempSave();
                                    }}
                                    value={`${![undefined, null, "", 0, "0"].includes(
                                      dm.zoneData[idx]?.price
                                    )
                                      ? dm.zoneData[idx]?.price
                                      : ""
                                      }${focused !==
                                        dimensions[dmIndex]?.zoneData[idx]
                                          ?.zone_id +
                                        `${quest.id}` +
                                        `${dmIndex}` + `${idx}` &&
                                        dm.zoneData[idx]?.price &&
                                        dm.zoneData[idx]?.price !== ""
                                        ? "$"
                                        : // ? `${
                                        //     !["$", "%"].includes(
                                        //       data[index]?.unit
                                        //     )
                                        //       ? `/${data[index]?.unit}`
                                        //       : data[index]?.unit
                                        //   }`
                                        ""
                                      }`}
                                    placeholder="0$"
                                    onChange={(e) => {
                                      const val = InputValidator(
                                        e.target.value
                                      );
                                      setDimensions((pData) => {
                                        return pData.map((pItem, pIndex) => {
                                          return dmIndex === pIndex
                                            ? {
                                              ...pItem,
                                              zoneData: pItem.zoneData.map(
                                                (zItem, zIdx) => {
                                                  return zIdx === idx
                                                    ? {
                                                      ...zItem,
                                                      zone_id: item.id,
                                                      price: val ?? "",
                                                    }
                                                    : zItem;
                                                }
                                              ),
                                            }
                                            : pItem;
                                        });
                                      });
                                    }}
                                  />
                                </LightTooltip>
                                {dimensions[dmIndex]?.zoneData[idx]?.zone_id +
                                  `${quest.id}` +
                                  `${dmIndex}` +
                                  `${idx}` ===
                                  loadingField && (
                                    <span className="zstc_col_datafieldloading">
                                      <FieldLoading />
                                    </span>
                                  )}
                                <div className="zstc_col_databottomtext">
                                  Per &nbsp;
                                  {Object.keys(units).includes(
                                    data[index]?.unit.toLowerCase()
                                  )
                                    ? fullUnit(data[index]?.unit)
                                    : data[index]?.type}
                                </div>
                                <LightTooltip
                                  placement="top"
                                  arrow
                                  title="Replicate data to next zone"
                                >
                                  <Copy
                                    className="zstc_col_datacopyicon"
                                    onClick={() =>
                                      replicateDimensionsField(
                                        idx,
                                        index,
                                        dmIndex
                                      )
                                    }
                                  />
                                </LightTooltip>
                              </div>
                            ))}
                          {quest.type === "cod" &&
                            cod.map((dm, dmIndex) => (
                              <div className="zstc_zcol_data3">
                                {/* <input
                                type="checkbox"
                                className="zstc_col_datacheckbox"
                              /> */}
                                <LightTooltip
                                  placement="top"
                                  arrow
                                  title={`${dm.zoneData[idx]?.enabled
                                    ? "Disable"
                                    : "Enable"
                                    } COD for ${dm.min}${data[index]?.unit} - ${dm.max
                                    }${data[index]?.unit}`}
                                >
                                  <AntSwitch
                                    checked={dm.zoneData[idx]?.enabled}
                                    onChange={(e) =>
                                      handleZoneCodFieldChecked(
                                        e,
                                        idx,
                                        dmIndex,
                                        item,
                                        quest,
                                        data[index]?.unit
                                      )
                                    }
                                  />
                                </LightTooltip>
                                <LightTooltip
                                  placement="top"
                                  arrow
                                  title={`Extra COD charges for ${dm.min}${data[index]?.unit} - ${dm.max}${data[index]?.unit}`}
                                >
                                  <input
                                    type="text"
                                    className={`zstc_col_datafield ${!dm.zoneData[idx]?.enabled
                                      ? "zstcc_disabled_datafield"
                                      : ""
                                      } ${(quest.id === activeQuestion ||
                                        quest.id === hoverQuestion) &&
                                      "active__question_field"
                                      }`}
                                    onFocus={() =>
                                      setFocused(
                                        cod[dmIndex]?.zoneData[idx]?.zone_id +
                                        `${quest.id}` +
                                        `${dmIndex}` +
                                        `${idx}`
                                      )
                                    }
                                    onBlur={() => {
                                      setLoadingField(focused);
                                      setFocused("");
                                      handleTempSave();
                                    }}
                                    value={`${![undefined, null, "", 0, "0"].includes(
                                      dm.zoneData[idx]?.price
                                    )
                                      ? dm.zoneData[idx]?.price
                                      : ""
                                      }${focused !==
                                        cod[dmIndex]?.zoneData[idx]?.zone_id +
                                        `${quest.id}` +
                                        `${dmIndex}` + `${idx}` &&
                                        dm.zoneData[idx]?.price &&
                                        dm.zoneData[idx]?.price !== ""
                                        ? //  `${
                                        //     !["$", "%"].includes(
                                        //       data[index]?.unit
                                        //     )
                                        //       ? `/${data[index]?.unit}`
                                        //       : data[index]?.unit
                                        //   }`
                                        data[index]?.unit
                                        : ""
                                      }`}
                                    placeholder="0$"
                                    onChange={(e) => {
                                      const val = InputValidator(
                                        e.target.value
                                      );
                                      setCod((pData) => {
                                        return pData.map((pItem, pIndex) => {
                                          return dmIndex === pIndex
                                            ? {
                                              ...pItem,
                                              zoneData: pItem.zoneData.map(
                                                (zItem, zIdx) => {
                                                  return zIdx === idx
                                                    ? {
                                                      ...zItem,
                                                      zone_id: item.id,
                                                      price: val ?? "",
                                                    }
                                                    : zItem;
                                                }
                                              ),
                                            }
                                            : pItem;
                                        });
                                      });
                                    }}
                                  />
                                </LightTooltip>
                                {cod[dmIndex]?.zoneData[idx]?.zone_id +
                                  `${quest.id}` +
                                  `${dmIndex}` +
                                  `${idx}` ===
                                  loadingField && (
                                    <span className="zstc_col_datafieldloading">
                                      <FieldLoading />
                                    </span>
                                  )}
                                <div className="zstc_col_databottomtext">
                                  Per &nbsp;
                                  {Object.keys(units).includes(
                                    data[index]?.unit.toLowerCase()
                                  )
                                    ? fullUnit(data[index]?.unit)
                                    : data[index]?.type}
                                </div>
                                <LightTooltip
                                  placement="top"
                                  arrow
                                  title="Replicate data to next zone"
                                >
                                  <Copy
                                    className="zstc_col_datacopyicon"
                                    onClick={() =>
                                      replicateCodField(idx, index, dmIndex)
                                    }
                                  />
                                </LightTooltip>
                              </div>
                            ))}
                          {quest.type === "protection" &&
                            protection.map((dm, dmIndex) => (
                              <div className="zstc_zcol_data3">
                                {/* <input
                                type="checkbox"
                                className="zstc_col_datacheckbox"
                              /> */}
                                <LightTooltip
                                  placement="top"
                                  arrow
                                  title={`${dm.zoneData[idx]?.enabled
                                    ? "Disable"
                                    : "Enable"
                                    } Shipment Value Protection for ${dm.min}${data[index]?.unit
                                    } - ${dm.max}${data[index]?.unit}`}
                                >
                                  <AntSwitch
                                    checked={dm.zoneData[idx]?.enabled}
                                    onChange={(e) =>
                                      handleZoneProtectionFieldChecked(
                                        e,
                                        idx,
                                        dmIndex,
                                        item,
                                        quest,
                                        data[index]?.unit
                                      )
                                    }
                                  />
                                </LightTooltip>
                                <LightTooltip
                                  placement="top"
                                  arrow
                                  title={`Extra Shipment Value Protection Charges for ${dm.min}${data[index]?.unit} - ${dm.max}${data[index]?.unit}`}
                                >
                                  <input
                                    type="text"
                                    className={`zstc_col_datafield ${!dm.zoneData[idx]?.enabled
                                      ? "zstcc_disabled_datafield"
                                      : ""
                                      } ${(quest.id === activeQuestion ||
                                        quest.id === hoverQuestion) &&
                                      "active__question_field"
                                      }`}
                                    onFocus={() =>
                                      setFocused(
                                        protection[dmIndex]?.zoneData[idx]
                                          ?.zone_id +
                                        `${quest.id}` +
                                        `${dmIndex}` +
                                        `${idx}`
                                      )
                                    }
                                    onBlur={() => {
                                      setLoadingField(focused);
                                      setFocused("");
                                      handleTempSave();
                                    }}
                                    value={`${![undefined, null, "", 0, "0"].includes(
                                      dm.zoneData[idx]?.price
                                    )
                                      ? dm.zoneData[idx]?.price
                                      : ""
                                      }${focused !==
                                        protection[dmIndex]?.zoneData[idx]
                                          ?.zone_id +
                                        `${quest.id}` +
                                        `${dmIndex}` + `${idx}` &&
                                        dm.zoneData[idx]?.price &&
                                        dm.zoneData[idx]?.price !== ""
                                        ? // `${
                                        //     !["$", "%"].includes(
                                        //       data[index]?.unit
                                        //     )
                                        //       ? `/${data[index]?.unit}`
                                        //       : data[index]?.unit
                                        //   }`
                                        data[index]?.unit
                                        : ""
                                      }`}
                                    placeholder="0$"
                                    onChange={(e) => {
                                      const val = InputValidator(
                                        e.target.value
                                      );
                                      setProtection((pData) => {
                                        return pData.map((pItem, pIndex) => {
                                          return dmIndex === pIndex
                                            ? {
                                              ...pItem,
                                              zoneData: pItem.zoneData.map(
                                                (zItem, zIdx) => {
                                                  return zIdx === idx
                                                    ? {
                                                      ...zItem,
                                                      zone_id: item.id,
                                                      price: val ?? "",
                                                    }
                                                    : zItem;
                                                }
                                              ),
                                            }
                                            : pItem;
                                        });
                                      });
                                    }}
                                  />
                                </LightTooltip>
                                {protection[dmIndex]?.zoneData[idx]?.zone_id +
                                  `${quest.id}` +
                                  `${dmIndex}` +
                                  `${idx}` ===
                                  loadingField && (
                                    <span className="zstc_col_datafieldloading">
                                      <FieldLoading />
                                    </span>
                                  )}
                                <div className="zstc_col_databottomtext">
                                  Per &nbsp;
                                  {Object.keys(units).includes(
                                    data[index]?.unit.toLowerCase()
                                  )
                                    ? fullUnit(data[index]?.unit)
                                    : data[index]?.type}
                                </div>
                                <LightTooltip
                                  placement="top"
                                  arrow
                                  title="Replicate data to next zone"
                                >
                                  <Copy
                                    className="zstc_col_datacopyicon"
                                    onClick={() =>
                                      replicateProtectionField(
                                        idx,
                                        index,
                                        dmIndex
                                      )
                                    }
                                  />
                                </LightTooltip>
                              </div>
                            ))}
                        </>
                      ))}
                    </div>
                  </SwiperSlide>
                ))}
                <SwiperSlide>
                  <div className="zstcontent_zone_placeholder"></div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      )}
      {settingsLoader && (
        <div className="settings__loader_wrap">
          <div className="lds-grid">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="settings__loader_text">{settingsLoader}</div>
        </div>
      )}
    </div>
  );
};

export default ZoneSettings;
