import React, { useState, useEffect, useRef } from "react";
import "./CreateZone.css";
import "../SelectZone/SelectZone.css";
import GoogleMapReact from "google-map-react";
import { styled } from "@mui/material/styles";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
import { ReactComponent as Delete } from "../../../assets/icons/trash.svg";
import Down from "@mui/icons-material/KeyboardArrowDownOutlined";
import Zoom from "@mui/material/Zoom";
import Up from "@mui/icons-material/KeyboardArrowUpOutlined";
import Hand from "../../../assets/icons/hand.png";
import Drag from "../../../assets/icons/drag.png";
import Pencil from "../../../assets/icons/pencil.png";
import Data from "../../../data/data";
import Typography from "@mui/material/Typography";
import Eraser from "../../../assets/icons/eraser.png";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ReactComponent as Question } from "../../../assets/icons/question.svg";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Colours from "../../../data/colors";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import {
  createNewZone,
  deleteZoneById,
  fetchAllZones,
  fetchPostalCodes,
  updateZoneArea,
  updateZoneById,
} from "../../../services/zones.services";
import { color } from "@mui/system";
import ZoneSettings from "../../../Components/ZoneSettings/ZoneSettings";
import ColorBox from "../../../Components/ColorBox/ColorBox";
import CitySearch from "../../../Components/CitySearch/CitySearch";
import ScreenLoader from "../../../Components/ScreenLoader/ScreenLoader";
import Confirm from "../../../Components/Popups/Confirm";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import { removeDuplicates } from "../../../helpers/helpers";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Loader = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "flex-end",
      }}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        <LinearProgress />
      </div>
    </Box>
  );
};

const DeleteArea = ({ onClick }) => {
  return (
    <div className="delete_area_component" onClick={onClick}>
      <LightTooltip placement="top" arrow title="Delete area">
        <DoNotDisturbOnIcon
          sx={{ color: "tomato", width: "100%", height: "100%" }}
        />
      </LightTooltip>
    </div>
  );
};
const AreaLabel = ({ name, color }) => {
  return (
    <>
      {name.length > 5 ? (
        <LightTooltip placement="top" arrow title={name}>
          <div
            className="zone_arealabel_component"
            style={{ backgroundColor: color, borderColor: color }}
          >
            <FmdGoodIcon className="zone_arealabelc_marker " sx={{ color }} />
            {name.substr(0, 4)}..
          </div>
        </LightTooltip>
      ) : (
        <div
          className="zone_arealabel_component"
          style={{ backgroundColor: color, borderColor: color }}
        >
          <FmdGoodIcon className="zone_arealabelc_marker " sx={{ color }} />
          {name}
        </div>
      )}
    </>
  );
};

const CreateZone = () => {
  const [center, setCenter] = useState({
    lat: 45.508888,
    lng: -73.561668,
  });
  const [zones, setZones] = useState([]);
  const [creatingZone, setCreatingZone] = useState();
  const [zone, setZone] = useState({});
  const [screenLoader, setScreenLoader] = useState(false);
  const [disableZoneSave, setDisableZoneSave] = useState(true);
  const [mapKey, setMapKey] = useState(1);
  const [drawingManager, setDrawingManager] = useState({});
  const [editNameMode, setEditNameMode] = useState();
  const [expanded, setExpanded] = useState([]);
  const [city, setCity] = useState("montreal");
  const [expandedItem, setExpandedItem] = useState();
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [confirmDeleteArea, setConfirmDeleteArea] = useState();
  const [confirmDeleteZone, setConfirmDeleteZone] = useState();
  const [newZone, setNewZone] = useState(false);
  const [maps, setMaps] = useState();
  const [markersLoaded, setMarkersLoaded] = useState(false);
  const [displayMap, setDisplayMap] = useState(true);
  const [map, setMap] = useState();
  const [colorPicker, setColorPicker] = useState(true);
  const [postalCodesData, setPostalCodesData] = useState([]);
  const [newZoneAdded, setNewZoneAdded] = useState(false);
  const [isDrawing, setDrawing] = useState(false);
  const [zoneDeleted, setZoneDeleted] = useState(false);
  const state = useSelector((state) => state);
  const [mapHeight, setMapHeight] = useState("calc(100vh - 76px)");

  const defaultProps = {
    center,
    zoom: 12,
  };
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    text: "",
    severity: "",
  });

  const selectedZone = useRef();

  const zoneNameField = useRef();
  const zonesLoaded = useRef(false);
  const postalCodesRef = useRef([]);
  const zonesRef = useRef([]);
  const deleteMarkerRef = useRef([]);
  const zoneMarkerRef = useRef([]);
  const drawing = useRef(false);
  const drawingManagerRef = useRef(null);

  const handleApiLoaded = (mapData, mapsData) => {
    setMaps(mapsData);
    setMap(mapData);

    deleteMarkerRef.current = [];
    zoneMarkerRef.current = [];
    if (zones !== null && zones?.length > 0) {
      zones?.forEach((zoneObject) => {
        let color = zoneObject.color;
        zoneObject.coordinates?.forEach((crd) => {
          var myPolygon = new maps.Polygon({
            paths: crd,
            strokeColor: color,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: color,
            fillOpacity: 0.35,
          });
          deleteMarkerRef.current = [
            ...deleteMarkerRef.current,
            { id: zoneObject.id, coords: crd[0] },
          ];
          zoneMarkerRef.current = [
            ...zoneMarkerRef.current,
            { color, name: zoneObject.name, coordinates: crd[1] },
          ];
          // console.log(map, maps);
          myPolygon.setMap(mapData);
        });
      });
    }
  };

  const handleAutoComplete = () => {
    const input = document.getElementById("locationSearch");
    const options = {
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
      types: ["establishment"],
    };
    var autocomplete = new maps.places.Autocomplete(input);
    var lat = "";
    var lng = "";
    maps.event.addListener(autocomplete, "place_changed", function () {
      var place = autocomplete.getPlace();
      lat = place.geometry.location.lat();
      lng = place.geometry.location.lng();
      setCenter({ lat, lng });
    });
  };
  const handleZoneTypeChange = (newType, id) => {
    // setZones((zones) => {
    //   return zones.map((zn) =>
    //     zn.id === id
    //       ? {
    //           ...zn,
    //           type: zn.type?.includes(newType)
    //             ? zn.type?.filter((type) => type !== newType)
    //             : [...zn.type, newType],
    //         }
    //       : zn
    //   );
    // });
    if (!newZone) {
      setZone({
        ...zone,
        type: zone.type?.includes(newType)
          ? zone.type?.filter((type) => type !== newType)
          : zone.type !== null
            ? [...zone.type, newType]
            : [newType],
      });
      const updated = updateZone(
        {
          ...zone,
          type: zone.type?.includes(newType)
            ? zone.type?.filter((type) => type !== newType)
            : zone.type !== null
              ? [...zone.type, newType]
              : [newType],
          postal_codes: zone.postal_codes
            ? zone.postal_codes.map((ps) => ps.id)
            : [],
        },
        false,
        zone.id
      );
    } else {
      setNewZone({
        ...newZone,
        type: newZone.type?.includes(newType)
          ? newZone.type?.filter((type) => type !== newType)
          : newZone.type !== null
            ? [...newZone.type, newType]
            : [newType],
      });
    }
  };
  const handleCreateZone = () => {
    const availableColors = [];
    Colours.forEach((color) => {
      let colorExist = false;
      zones.forEach((zone) => {
        if (zone.color === color) colorExist = true;
      });
      if (!colorExist) availableColors.push(color);
    });
    const zone = {
      id: zones.length + 1,
      name: ``,
      type: ["DELIVERY", "PICKUP"],
      color: availableColors.length > 0 ? availableColors[0] : "grey",
    };
    setExpandedItem(zones.length + 1);
    setZones([...zones, zone]);
    setNewZone(zone);
  };
  useEffect(() => {
    if (newZone && zoneNameField.length < 1) {
      zoneNameField.current.focus();
    }
  }, [newZone]);

  const createZone = async (e) => {
    e.stopPropagation();
    setCreatingZone(newZone.id);
    let id = newZone.id;
    let data = newZone;
    delete data.id;
    const response = await createNewZone(state.user.token, {
      ...data,
      color: newZone.color,
      postal_code_ids: [],
      coordinates: [],
    });
    if (!response.error) {
      // setZones((zn) =>
      //   zn.map((item) => (item?.id === id ? response.data?.result : item))
      // );

      getAllZones();
      setNewZoneAdded(response.data?.result);
      setSnack({
        ...snack,
        open: true,
        text: "New zone created successfully..",
        severity: "success",
      });
    } else {
      setSnack({
        ...snack,
        open: true,
        text: "Couldn't create zone",
        severity: "error",
      });
      getAllZones();
    }
    setCreatingZone(false);
    setEditNameMode(false);
    setNewZone(false);
  };
  const getAllZones = async () => {
    setScreenLoader("Fetching Zones...");
    const response = await fetchAllZones(state.user.token);
    if (response.data) {
      setZones(response.data.result);
      zonesRef.current = response.data.result;
    }
    setScreenLoader(false);
  };
  const deleteZoneArea = async (coords, zoneId) => {
    zones.forEach((zn) => {
      if (zn.id === zoneId) {
        zn.coordinates.forEach((crd) => {
          if (crd[0].lat === coords.lat && crd[0].lng === coords.lng) {
            // var newArea = new maps.Polygon({
            //   paths: crd,
            //   strokeColor: zone.color,
            //   strokeOpacity: 0.8,
            //   strokeWeight: 2,
            //   fillColor: zone.color,
            //   fillOpacity: 0.35,
            // });
            // let existedPostalCodes = [];
            // postalCodesRef.current.forEach((item, index) => {
            //   if (
            //     maps.geometry.poly.containsLocation(
            //       { lat: Number(item.lat), lng: Number(item.lng) },
            //       newArea
            //     )
            //   ) {
            //     existedPostalCodes.push(item.id);
            //   }
            // });
            const updated = setConfirmDeleteArea({
              zoneId: zn.id,
              coordinates: zn.coordinates.filter(
                (item) =>
                  item[0].lat !== coords.lat && item[0].lng !== coords.lng
              ),
              // postalCodeIds: existedPostalCodes,
              postalCodeIds: [],
            });
          }
        });
      }
    });
  };

  const getAllPostalCodes = async () => {
    setScreenLoader("Fetching Postal Codes...");
    const response = await fetchPostalCodes(state.user.token, city);
    if (response.data) {
      setPostalCodesData(response.data.result);
    }
    setScreenLoader(false);
  };
  const deleteZone = async (id) => {
    setCreatingZone(id);
    const response = await deleteZoneById(state.user.token, id);
    zonesRef.current = zones.filter((item) => item.id !== id);
    if (response?.error === null) {
      setZones((zones) => zones.filter((item) => item.id !== id));
    }
    setCreatingZone(false);
  };
  const updateZone = async (data, loading, id, cb) => {
    loading && setCreatingZone(data.id);
    const response = await updateZoneById(state.user.token, data, id);
    if (response?.error === null) {
      // setZones((zones) =>
      //   zones.map((item) => (item.id === data.id ? data : item))
      // );
      getAllZones();
    }
    setCreatingZone(false);
    return response;
  };
  const reloadMap = async () => {
    setMapKey((prevKey) => prevKey + 1);
  };
  const deleteZoneRegion = async (data, cb) => {
    setConfirmDeleteArea(false);
    const response = await updateZoneArea(state.user.token, data);
    if (response?.error === null) {
      // setZones((zones) =>
      //   zones.map((item) => (item.id === data.id ? data : item))
      // );
      setSnack({
        ...snack,
        open: true,
        text: "Area Deleted Successfully!",
        severity: "success",
      });
      const res = await getAllZones();
      reloadMap();
    } else {
      setSnack({
        ...snack,
        open: true,
        text: "Something went wrong!",
        severity: "error",
      });
    }
    return response;
  };
  const handleNameChange = (e) => {
    e.stopPropagation();
    updateZone(
      {
        ...zone,
        postal_codes: zone.postal_codes
          ? zone.postal_codes.map((ps) => ps.id)
          : [],
      },
      true,
      zone.id
    );
    setEditNameMode(false);
  };
  const handleAccordionChange = (itm) => (event, isExpanded) => {
    setExpandedItem(isExpanded ? itm : false);
  };
  const handleDeleteZone = async (e, id) => {
    e.stopPropagation();
    // const res = await deleteZone(id);
    setConfirmDeleteZone(id);
  };
  const checkExistedCodes = async (current, zns) => {
    const res = await fetchAllZones(state.user.token);
    let response = [];
    let postal_codes = [];
    res.data.result.forEach((zn) => {
      zn.postal_codes?.forEach((ps) => {
        postal_codes.push(ps.id);
      });
    });
    current.forEach((item, index) => {
      if (!postal_codes.includes(item)) {
        response.push(item);
      }
    });
    return response;
  };
  const handleColorChange = (color, zone) => {
    if (newZone?.id !== zone.id) {
      updateZone(
        {
          ...zone,
          color,
          postal_codes: zone.postal_codes
            ? zone.postal_codes.map((ps) => ps.id)
            : [],
        },
        true,
        zone.id
      );
    } else {
      setNewZone({ ...newZone, color }, true, zone.id);
    }
    setColorPicker(false);
  };
  const drawNewZoneArea = (szone) => {
    drawingManagerRef.current = drawingManager;
    drawingManager.setDrawingMode(maps.drawing.OverlayType.POLYGON);
    setSnack({
      ...snack,
      open: true,
      text: "Click on map to draw zone area",
      severity: "info",
    });
    drawing.current = szone;
    setDrawing(szone);
    selectedZone.current = szone;
  };
  const handleCancelDrawing = () => {
    drawingManagerRef.current.setDrawingMode(null);
    drawing.current = false;
    setDrawing(false);
  };
  const handleEditable = () => {
    // console.log(zones[0].areas[0].getEditable(map));
  };
  const getZones = () => {
    if (zones !== null && zones?.length > 0) {
      zones?.forEach((zoneObject) => {
        let color = zoneObject.color;
        zoneObject.coordinates?.forEach((crd) => {
          var myPolygon = new maps.Polygon({
            paths: crd,
            strokeColor: color,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: color,
            fillOpacity: 0.35,
          });
          deleteMarkerRef.current = [
            ...deleteMarkerRef.current,
            { id: zoneObject.id, coords: crd[0] },
          ];
          zoneMarkerRef.current = [
            ...zoneMarkerRef.current,
            { color, name: zoneObject.name, coordinates: crd[1] },
          ];
          myPolygon.setMap(map);
        });
      });
    }
  };
  const handleSnackClose = () => {
    setSnack({ ...snack, open: false });
  };
  const addRegion = async (coords, zone, postalCodes) => {
    setScreenLoader("Creating New Area");
    var newArea = new maps.Polygon({
      paths: coords,
      strokeColor: zone.color,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: zone.color,
      fillOpacity: 0.35,
    });
    // let existedCodes = [];
    // let existedPostalCodes = [];

    // postalCodes.forEach((item, index) => {
    //   if (
    //     maps.geometry.poly.containsLocation(
    //       { lat: Number(item.lat), lng: Number(item.lng) },
    //       newArea
    //     )
    //   ) {
    //     existedCodes.push(item.id);
    //     existedPostalCodes.push(item.postal_code);
    //   }
    // });
    // existedCodes = await checkExistedCodes(existedCodes);

    const res = await updateZone(
      {
        coordinates: zone.coordinates
          ? [...zone.coordinates, coords]
          : [coords],
        // postal_codes: existedCodes,
        postal_codes: [],
      },
      false,
      zone.id
    );
    // console.log(zones, zone);
    if (res?.error === null) {
      setZones((zn) => {
        return zn.map((item) => {
          if (item.id === zone.id) {
            return {
              ...zone,
              coordinates:
                zone.coordinates?.length > 0
                  ? [...zone.coordinates, coords]
                  : [coords],
              // postal_codes:
              //   zone.postal_codes?.length > 0
              //     ? [...zone.postal_codes, ...existedCodes]
              //     : existedCodes,
              postal_codes: [],
            };
          } else {
            return item;
          }
        });
      });
    }

    newArea.setMap(map);
    deleteMarkerRef.current = [
      ...deleteMarkerRef.current,
      { id: zone.id, coords: coords[0] },
    ];
    zoneMarkerRef.current = [
      ...zoneMarkerRef.current,
      { color: zone.color, name: zone.name, coordinates: coords[1] },
    ];
    // var marker = new maps.Marker({
    //   position: coords[1],
    //   map: map,
    //   icon: {
    //     size: new maps.Size(50, 50),
    //   },
    //   label: {
    //     text: zone.name,
    //     color: "black",
    //     labelClass: "",
    //     fontWeight: "bold",
    //     fontSize: "20px",
    //   },
    // });

    // setZone({
    //   name: "",
    //   type: zone.type,
    //   color: zone.color,
    // });
    getZones();
    setScreenLoader(false);
    setSnack({
      ...snack,
      open: true,
      text: "Area Added Successfully!",
      severity: "success",
    });

    // if (existedCodes.length < existedPostalCodes.length) {
    //   setSnack({
    //     ...snack,
    //     open: true,
    //     text: `Area Created! ${
    //       existedPostalCodes.length - existedCodes.length
    //     } Overlaping postal codes are excluded from this area...`,
    //     severity: "warning",
    //   });
    // } else {
    //   setSnack({
    //     ...snack,
    //     open: true,
    //     text: "Area Added Successfully!",
    //     severity: "success",
    //   });
    // }
  };
  function handleResize() {
    setMapHeight("calc(100vh - 76px)");
  }
  const _initMap = () => {
    const drawingManager = new maps.drawing.DrawingManager({
      drawingMode: maps.drawing.OverlayType.MARKER,
      drawingControl: false,
      drawingControlOptions: {
        position: maps.ControlPosition.TOP_CENTER,
        drawingModes: [maps.drawing.OverlayType.POLYGON],
      },
      markerOptions: {},
      polygonOptions: {
        strokeColor: selectedZone.current
          ? selectedZone.current.color
          : zone.color
            ? zone.color
            : "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: selectedZone.current
          ? selectedZone.current.color
          : zone.color
            ? zone.color
            : "#FF0000",
        fillOpacity: 0.35,
        clickable: true,
        dragable: true,
        editable: true,
        zIndex: 1,
      },
    });
    setDrawingManager(drawingManager);
    drawingManager.setMap(map);
    maps.event.addListener(map, "click", function (event) {
      if (drawingManager.getDrawingMode() !== null) {
        let latLng = event.latLng;
        if (!map.getBounds().contains(latLng)) {
          drawingManager.setDrawingMode(null);
          drawing.current = false;
          setDrawing(false);
        }
      }
    });
    maps.event.addListener(drawingManager, "overlaycomplete", function (event) {
      if (drawingManager.getDrawingMode() !== null) {
        let arr = event.overlay.getPath().getArray();
        let coords = [];
        arr.forEach((coord) => {
          coords.push({ lat: coord.lat(), lng: coord.lng() });
        });
        addRegion(coords, selectedZone.current, postalCodesRef.current);
        drawingManager.setDrawingMode(null);
        drawing.current = false;
        setDrawing(false);
      } else {
        reloadMap();
      }
    });
  };

  useEffect(() => {
    if (map && maps && maps?.drawing) {
      _initMap();
    }
  }, [maps, map, zone.color, zone]);
  useEffect(() => {
    if (zones.length > 0 && !zonesLoaded.current && map && maps) {
      getZones();
      zonesLoaded.current = true;
      zonesRef.current = zones;
    }
  }, [zones, maps, map, mapKey]);

  useEffect(() => {
    if (!newZone) {
      setDisableZoneSave(zone?.name ? zone?.name?.length < 1 : true);
    } else {
      setDisableZoneSave(newZone?.name ? newZone?.name?.length < 1 : true);
    }
  }, [zone.name, newZone]);
  useEffect(() => {
    getAllZones();
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      setCenter({
        lat: latitude,
        lng: longitude,
      });
    });
  }, []);
  useEffect(() => {
    setMapHeight("calc(100vh - 76px)");
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (zones.length > 0 && maps && map && !markersLoaded) {
      reloadMap();
      setMarkersLoaded(true);
    }
  }, [zones, maps]);
  useEffect(() => {
    setDrawing(drawing.current);
  }, [drawing.current]);
  useEffect(() => {
    if (zones.length > 0) {
      zonesRef.current = zones;
    }
  }, [zones]);

  useEffect(() => {
    if (zone.name !== "" && maps && map) {
      selectedZone.current = zone;
      _initMap();
    }
  }, [zone]);

  return (
    <>
      {displayMap && (
        <div className="create_zone_screen" style={{ height: mapHeight }}>
          <Snackbar
            open={snack.open}
            autoHideDuration={6000}
            onClose={handleSnackClose}
          >
            <Alert
              onClose={handleSnackClose}
              severity={snack.severity}
              sx={{ width: "100%" }}
            >
              {snack.text}
            </Alert>
          </Snackbar>
          {(screenLoader || zoneDeleted) && (
            <ScreenLoader text={screenLoader} />
          )}
          {confirmDeleteArea && (
            <Confirm
              open={confirmDeleteArea}
              setOpen={setConfirmDeleteArea}
              handleConfirm={async () => {
                setScreenLoader(true);
                const res = await deleteZoneRegion(
                  confirmDeleteArea,
                  getAllZones
                );
                // !res.error && window.location.reload(false);
                setScreenLoader(false);
              }}
              text="Are you sure you want to delete this area?"
            />
          )}
          {confirmDeleteZone && (
            <Confirm
              open={confirmDeleteZone}
              setOpen={setConfirmDeleteZone}
              handleConfirm={async () => {
                setConfirmDeleteZone(false)
                const res = await deleteZone(confirmDeleteZone);
                setZoneDeleted(true);

                // console.log(zones.length);
                // zones.length < 2 && window.location.reload(false);
              }}
              text="Are you sure you want to delete this Zone?"
            />
          )}
          <div className="czs_city_searchboxwrap">
            <input
              type="text"
              className="czs_city_searchboxfield"
              placeholder="Search City"
              id="locationSearch"
              onChange={handleAutoComplete}
            />
            <Search className="czs_csb_icon" />
          </div>
          <div className="czs_createzone_wrap">
            <LightTooltip placement="top" arrow title="Create New Zone">
              <div
                className={`czs_createzone_btn ${newZone ? "czs_createzone_btndisabled" : ""
                  }`}
                onClick={handleCreateZone}
              >
                Create Zone
              </div>
            </LightTooltip>
            {/* <div className="czs_createzone_globalpallet">
              <LightTooltip placement="top" arrow title="Edit Zone Area">
                <img alt="" src={Hand} className="czs_czgp_icon" />
              </LightTooltip>
              <LightTooltip placement="top" arrow title="Drag Zone Area">
                <img alt="" src={Drag} className="czs_czgp_icon " />
              </LightTooltip>
              <LightTooltip placement="top" arrow title="Delete Zone Area">
                <img alt="" src={Eraser} className="czs_czgp_icon" />
              </LightTooltip>
              <LightTooltip placement="top" arrow title="Draw Zone Area">
                <img
                  alt=""
                  src={Pencil}
                  className="czs_czgp_icon"
                  onClick={drawNewZoneArea}
                />
              </LightTooltip>

              <Question className="czs_czgp_iconqs" />
            </div> */}

            {zones?.map((item, index) => {
              return (
                <>
                  {creatingZone !== item?.id && (
                    <Accordion
                      sx={{
                        width: "100%",
                        // border: "1px solid tomato",
                        borderLeft: `4px solid ${item.color}`,
                      }}
                      disableGutters={true}
                      // defaultExpanded={newZone.id === item.id}
                      expanded={expandedItem === item.id}
                      onChange={handleAccordionChange(item.id)}
                    >
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={() => {
                          setZone(item);
                          if (!expanded.includes(item.id)) {
                            setExpanded([...expanded, item.id]);
                          } else {
                            setExpanded((val) => {
                              return val.filter((id) => item.id !== id);
                            });
                          }
                        }}
                      >
                        <div className="czscz_zoneheading_wrap">
                          {editNameMode !== item.id &&
                            newZone.id !== item.id && (
                              <div className="czscz_zoneheading_wrapleft">
                                {item.name.length < 11 &&
                                  item.name.substr(0, 10)}
                                {item.name.length > 10 && (
                                  <LightTooltip
                                    placement="top"
                                    arrow
                                    title={item.name}
                                  >
                                    <span>{item.name.substr(0, 10)}</span>
                                  </LightTooltip>
                                )}
                                {item.name.length > 10 && "..."}
                                <LightTooltip
                                  placement="top"
                                  arrow
                                  title="Edit Zone Name"
                                >
                                  <img
                                    alt=""
                                    src={Pencil}
                                    className="czscz_zoneheading_wrapleftpncl"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setZone(item);
                                      setEditNameMode(item.id);
                                    }}
                                  />
                                </LightTooltip>
                                {expandedItem !== item.id && (
                                  <Down className="czscz_zoneheading_downarrow" />
                                )}
                                {expandedItem === item.id && (
                                  <Up className="czscz_zoneheading_downarrow" />
                                )}
                              </div>
                            )}
                          {(editNameMode === item.id ||
                            newZone.id === item.id) && (
                              <div className="czscz_zoneheading_wrapleft">
                                <div
                                  className="czscz_zoneheading_inputwrap"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <input
                                    type="text"
                                    className="czscz_zoneheading_editznfield"
                                    value={newZone ? newZone.name : zone.name}
                                    ref={zoneNameField}
                                    onChange={(e) => {
                                      e.stopPropagation();
                                      if (newZone) {
                                        setNewZone({
                                          ...newZone,
                                          name: e.target.value,
                                        });
                                      } else {
                                        setZone({
                                          ...zone,
                                          name: e.target.value,
                                        });
                                      }
                                    }}
                                  />
                                  <button
                                    className="czscz_zoneheading_editznbtn"
                                    onClick={
                                      newZone ? createZone : handleNameChange
                                    }
                                    disabled={disableZoneSave}
                                    style={
                                      disableZoneSave
                                        ? {
                                          backgroundColor: "grey",
                                        }
                                        : {}
                                    }
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            )}
                          <div className="czscz_zoneheading_wrapright">
                            {colorPicker === item.id && (
                              <div
                                className="czscz_colorpicker"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <ColorBox
                                  handleColorChange={(color) =>
                                    handleColorChange(color, item)
                                  }
                                />
                              </div>
                            )}
                            <LightTooltip
                              placement="top"
                              arrow
                              title="Select Zone Colour"
                            >
                              <div
                                className="czscz_zoneheading_clrbox"
                                style={{
                                  backgroundColor:
                                    newZone.id === item.id
                                      ? newZone.color
                                      : item.color,
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setColorPicker(
                                    colorPicker !== item.id ? item.id : false
                                  );
                                }}
                              ></div>
                            </LightTooltip>
                            <LightTooltip
                              placement="top"
                              arrow
                              title="Delete This Zone"
                            >
                              <Delete
                                className="czscz_zoneheading_trash"
                                onClick={(e) => handleDeleteZone(e, item.id)}
                              />
                            </LightTooltip>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div
                          className="czscz_zonebody_wrap"
                          id="czscz_zonebody_wrap"
                        >
                          <div className="czscz_zonebody_checkboxes">
                            <div className="czscz_zonebody_checkbox">
                              <input
                                type="checkbox"
                                className="czscz_zonebody_check mr-1"
                                id="pickup"
                                checked={
                                  newZone
                                    ? newZone.type?.includes("PICKUP")
                                    : zone.type?.includes("PICKUP")
                                }
                                onChange={() =>
                                  handleZoneTypeChange("PICKUP", item.id, index)
                                }
                              />
                              <label for="pickup">Pick Up</label>
                            </div>
                            <div className="czscz_zonebody_checkbox">
                              <input
                                type="checkbox"
                                className="czscz_zonebody_check mr-1"
                                id="delivery"
                                checked={
                                  newZone
                                    ? newZone.type?.includes("DELIVERY")
                                    : zone.type?.includes("DELIVERY")
                                }
                                onChange={() =>
                                  handleZoneTypeChange("DELIVERY", item.id)
                                }
                              />
                              <label for="delivery">Delivery</label>
                            </div>
                          </div>
                          {/* <div className="czscz_zonebody_infobox">
                            <span>No of Postal Codes</span>
                            {item.postal_codes?.length > 0 ? (
                              <Zoom
                                in={item.postal_codes?.length > 0}
                                style={{
                                  transitionDelay:
                                    item.postal_codes?.length > 0
                                      ? "500ms"
                                      : "0ms",
                                }}
                              >
                                <span> {item.postal_codes?.length}</span>
                              </Zoom>
                            ) : (
                              <span>-</span>
                            )}
                          </div> */}
                          <div className="czscz_zonebody_infobox">
                            <span>No of Regions Selected</span>
                            <span>
                              {item.coordinates?.length > 0
                                ? // <Zoom
                                //   in={item.coordinates?.length > 0}
                                //   style={{
                                //     transitionDelay:
                                //       item.coordinates?.length > 0
                                //         ? "500ms"
                                //         : "0ms",
                                //   }}
                                // >
                                //   {item.coordinates?.length}
                                // </Zoom>
                                item.coordinates?.length
                                : "-"}
                            </span>
                          </div>
                          {!newZone && isDrawing.id !== item.id && (
                            // <div className="czs_createzone_globalpallet2">
                            //   <LightTooltip
                            //     placement="top"
                            //     arrow
                            //     title="Edit Zone Area"
                            //   >
                            //     <img
                            //       alt=""
                            //       src={Hand}
                            //       className="czs_czgp_icon"
                            //       onClick={() => handleEditable()}
                            //     />
                            //   </LightTooltip>
                            //   <LightTooltip
                            //     placement="top"
                            //     arrow
                            //     title="Drag Zone Area"
                            //   >
                            //     <img
                            //       alt=""
                            //       src={Drag}
                            //       className="czs_czgp_icon "
                            //     />
                            //   </LightTooltip>
                            //   <LightTooltip
                            //     placement="top"
                            //     arrow
                            //     title="Delete Zone Area"
                            //   >
                            //     <img
                            //       alt=""
                            //       src={Eraser}
                            //       className="czs_czgp_icon"
                            //     />
                            //   </LightTooltip>
                            //   <LightTooltip
                            //     placement="top"
                            //     arrow
                            //     title="Draw Zone Area"
                            //   >
                            //     <img
                            //       alt=""
                            //       src={Pencil}
                            //       className={`czs_czgp_icon `}
                            //       onClick={() => drawNewZoneArea(item)}
                            //     />
                            //   </LightTooltip>
                            // </div>
                            <div
                              className="is__drawing_zonetab bg-primary text-light rounded"
                              onClick={() => drawNewZoneArea(item)}
                            >
                              Click to draw on map
                            </div>
                          )}
                          {isDrawing.id === item.id && (
                            <div
                              className="is__drawing_zonetab bg-danger text-white rounded"
                              onClick={handleCancelDrawing}
                            >
                              Cancel Drawing
                            </div>
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  )}
                  {creatingZone === item?.id && <Loader />}
                </>
              );
            })}
            {zones?.length < 1 && (
              <div className="no_zone_createdplaceholder">No Zone Created!</div>
            )}
          </div>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyC8NLz7EN8o0S_eZhwVDunyl0ut7o7vehI&libraries=drawing,places,geometry&v=weekly",
            }}
            key={mapKey}
            center={defaultProps.center}
            zoom={defaultProps.zoom}
            options={{
              gestureHandling: "greedy",
              fullscreenControl: false,
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            libraries={["drawing", "places", "geometry"]}
          >
            {deleteMarkerRef.current.map((item) => (
              <DeleteArea
                lat={item.coords.lat}
                lng={item.coords.lng}
                onClick={() =>
                  deleteZoneArea(
                    { lat: item.coords.lat, lng: item.coords.lng },
                    item.id
                  )
                }
              />
            ))}
            {zoneMarkerRef.current.map((item) => (
              <AreaLabel
                lat={item.coordinates?.lat}
                lng={item.coordinates?.lng}
                name={item.name}
                color={item.color}
              />
            ))}
          </GoogleMapReact>
          {(zones?.length > 0 || zoneDeleted) && (
            <div className="zszs_zones_section" id="zones_table_section">
              <Accordion sx={{ zIndex: "15" }}>
                <AccordionSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  sx={{ maxHeight: "15px", minHeight: "15px" }}
                >
                  <Typography
                    sx={{
                      width: "100%",
                      position: "relative",
                      zIndex: "16",
                    }}
                  >
                    <div className="cz_expandaccor_arrowup">
                      {!accordionExpanded && (
                        <Up
                          sx={{ fontSize: "30px", fill: "grey" }}
                          onClick={() => setAccordionExpanded(true)}
                        />
                      )}
                      {accordionExpanded && (
                        <Down
                          sx={{ fontSize: "30px", fill: "grey" }}
                          onClick={() => setAccordionExpanded(false)}
                        />
                      )}
                    </div>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    height: "100vh",
                    overflowY: "auto",
                    position: "relative",
                    top: "-40px",
                  }}
                >
                  <ZoneSettings
                    zones={zones}
                    newZoneAdded={newZoneAdded}
                    setNewZoneAdded={setNewZoneAdded}
                    zoneDeleted={zoneDeleted}
                    setZoneDeleted={setZoneDeleted}
                    maps={maps}
                    map={map}
                    reloadMap={reloadMap}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CreateZone;
