import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { hideSnackBar } from "../../store/common/snackBarSlice.js";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomSnackbar = () => {
    const dispatch = useDispatch();
    const { open, vertical, horizontal, text, severity } = useSelector(
        (state) => state.snackBar
    );

    const handleClose = () => {
        dispatch(hideSnackBar());
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity={severity}>
                {text}
            </Alert>
        </Snackbar>
    );
};

export default CustomSnackbar;
