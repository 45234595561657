import React, { useEffect } from "react";
import "./transferNoteDetails.css";

// components imports
// apis imports
// library imports
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";

const TransferNoteDetails = ({ open, setOpen }) => {
  const user = useSelector((state) => state.user);
  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="transfer_note_details_modal_title"
        aria-describedby="transfer_note_details_modal_description"
      >
        <div className="details_container_modal">
          <h3 className="details_container_modal_title">
            Details for # {open?.reference_no ?? "-"}
          </h3>

          <div className="details_container_modal_body">
            <div className="custabscreen__rdverttable_row">
              <div className="custabscreen__rdverttable_heading">Date:</div>
              <div className="custabscreen__rdverttable_data">
                {open?.created_at?.slice(0, 10) ?? "-"}
              </div>
            </div>

            <div className="custabscreen__rdverttable_row">
              <div className="custabscreen__rdverttable_heading">
                Reference Number:
              </div>
              <div className="custabscreen__rdverttable_data">
                {open?.reference_no ?? "-"}
              </div>
            </div>

            <div className="custabscreen__rdverttable_row">
              <div className="custabscreen__rdverttable_heading">
                From Warehouse:
              </div>
              <div className="custabscreen__rdverttable_data">
                {open?.from_warehouse?.name ?? "-"}
              </div>
            </div>

            <div className="custabscreen__rdverttable_row">
              <div className="custabscreen__rdverttable_heading">
                To Warehouse:
              </div>
              <div className="custabscreen__rdverttable_data">
                {open?.to_warehouse?.name ?? "-"}
              </div>
            </div>

            <div className="custabscreen__rdverttable_row">
              <div className="custabscreen__rdverttable_heading">
                Created By:
              </div>
              <div className="custabscreen__rdverttable_data">
                {user?.username ?? "-"}
              </div>
            </div>
          </div>
          <div className="details_container_modal_body">
            <TableContainer>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <strong>Product Code</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Name</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Quantity</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {open?.transfer_note_details?.map((transferDetail, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell
                          classes={{ padding: "0px 16px" }}
                          align="center"
                        >
                          {transferDetail?.id ?? "-"}
                        </TableCell>
                        <TableCell
                          classes={{ padding: "0px 16px" }}
                          align="center"
                        >
                          {transferDetail?.product?.full_name ?? "-"}
                        </TableCell>

                        <TableCell
                          classes={{ padding: "0px 16px" }}
                          align="center"
                        >
                          {transferDetail.quantity ?? "-"}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="transfer__note_modalfooter">
            <div
              className="transfer__note_modalreset"
              onClick={() => setOpen(false)}
            >
              Close
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TransferNoteDetails;
