import React, { useEffect, useState } from "react";
import "../settings.css";
import { Button, Typography, Box, CircularProgress } from "@mui/material"; // MUI imports
import SideBar from "../Components/SideBar/SideBar";
import { useDispatch } from "react-redux";
import { showSnackBar } from "../../../../../../store/common/snackBarSlice";
import { updateGeneralSettings } from "../../../../../../services/settings.services";
import { useSelector } from "react-redux";
import { findSettingByType } from "../../../../../../helpers/helpers";
import { Select, MenuItem } from "@mui/material";
import { addUser } from "../../../../../../store/userSlice";

function TableSettings(props) {
  const [formData, setFormData] = useState({
    setting_type: "perPage",
    value: "20",
  });

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);

  const handleFieldChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleSave = async () => {
    if (formData.value === "") {
      dispatch(
        showSnackBar({
          text: "Value Should Not Be Empty",
          severity: "error",
        })
      );
      return;
    }

    setLoading(true); // Start loading before sending the request

    const response = await updateGeneralSettings(formData);
    setLoading(false); // Stop loading after the request completes

    if (!response?.data?.success) {
      dispatch(
        showSnackBar({
          text: "Something went wrong",
          severity: "error",
        })
      );
      return;
    }

    let value = response?.data?.result;
    const updatedUser = {
      ...user,
      general_settings: [value],
    };
    dispatch(addUser(updatedUser));
    localStorage.setItem("user", JSON.stringify(updatedUser));

    dispatch(
      showSnackBar({
        text: "Settings updated successfully",
        severity: "success",
      })
    );
  };

  useEffect(() => {
    if (user?.general_settings?.length) {
      const perPageSetting = findSettingByType(
        user?.general_settings,
        "perPage"
      );
      setFormData({ ...formData, value: perPageSetting?.value });
    }
  }, [user]);

  return (
    <>
      <div className="parent_wraper">
        {/* sidebar code  */}
        <SideBar active="3_0" />
        <div className="dash_table_wrapper">
          {/* right side section */}
          <Box
            sx={{
              padding: "16px",
              maxWidth: "400px",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Set Number of Records Per Page
            </Typography>
            <Select
              labelId="number-of-records-label"
              id="number-of-records"
              value={formData.value}
              label="Number of Records"
              onChange={(e) => handleFieldChange("value", e.target.value)}
              size="small"
            >
              {[10, 20, 40, 60, 80, 100, 120, 140, 160, 180, 200].map(
                (value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                )
              )}
            </Select>

            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              sx={{ alignSelf: "flex-start" }}
              size="small"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Save"
              )}
            </Button>
          </Box>
        </div>
      </div>
    </>
  );
}

export default TableSettings;
