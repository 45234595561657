import React from "react";
import International from "./InvoiceSubComponents/International";
import ShipmentSummary from "./InvoiceSubComponents/ShipmentSummary";
import Tracking from "./InvoiceSubComponents/Tracking";
import Header from "./InvoiceSubComponents/Header";
import ShipmentDetails from "./InvoiceSubComponents/ShipmentDetails";
import Footer from "./InvoiceSubComponents/Footer";
import BillingUser from "./InvoiceSubComponents/BillingUser";
import Notes from "./InvoiceSubComponents/NotesUser";

import "../InvoiceModal.css";
const PrintableInvoice = ({ data, invoiceRef }) => {
  return (
    <div className="invoice__modal_wrap invoice__modal_style_optamize_wrap">
      <div className="invoice__modal_box printable__invoice invoice__shipment_style_optamize_wrap" ref={invoiceRef}>
        <Header data={data} type="Print" />
        <ShipmentSummary data={data} />
        <International data={data} />
        <BillingUser data={data} type="Print" />
        <Notes data={data} type="Print" />
        <Tracking data={data} />
        <ShipmentDetails data={data} type="Print" />
        <Footer data={data} type="Print" />
      </div>
    </div>
  );
};

export default PrintableInvoice;
