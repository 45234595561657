import React, { useState } from "react";
import "./subscribers.css";

// components imports
import SubscribersTable from "../../../../../Components/BoltTable/BoltTable";
// apis imports
import { fetchAllNewsLetters } from "../../../../../services/admin.services";
// library imports
import { MdVerified, MdUnpublished } from "react-icons/md";

const Subscribers = ({ setSnack }) => {
  const [subscribersList, setSubscribersList] = useState([]);

  const columns = [
    {
      id: 1,
      label: "Created At",
      render: (row) => (
        <>
          {row?.created_at?.split("T") &&
            (row?.created_at?.split("T")[0] ?? "-")}
        </>
      ),
      width: "200px",
    },
    {
      id: 2,
      label: "Email",
      render: (row) => <>{row?.email ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Verified",
      render: (row) => (
        <>
          {["1", "true", true].includes(row.is_verified) ? (
            <MdVerified size={15} color="teal" />
          ) : (
            <MdUnpublished size={15} color="lightgrey" />
          )}
        </>
      ),
      width: "200px",
    },
  ];

  const fetchAllSubscribers = async (page, perPage, filter) => {
    const response = await fetchAllNewsLetters(page, perPage, filter);
    if (!response.error) {
      setSubscribersList(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  return (
    <>
      <div className="subscribers_header">
        <div>
          <span className="subscribers_hdrheading">Subscribers List</span>
        </div>
      </div>

      <div className="subscribers__main_wrap">
        <div className="subscribers__table_list">
          <div>
            <SubscribersTable
              message={"No Subscribers Available To Display!"}
              searchPlaceHolder="Search Subscribers"
              columns={columns}
              data={subscribersList}
              getAllData={fetchAllSubscribers}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribers;
