import axios from "../../axiosInstance";

export const fetchShippingCompanies = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/shipping-company/get?perPage=${perPage}&page=${page}&name=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateShippingCompanyStatus = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `admin/shipping-company/update-status/${id}`,
      data
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const updateShippingCompany = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/shipping-company/update/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateServiceStatusByAdmin = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `admin/shipping-company/update-services/${id}`,
      data
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
