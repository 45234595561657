import React, { useState, useEffect, useRef } from "react";
import "./Drivers.css";

// components imports
import Snackbar from "../../../Components/SnackBar/SnackBar";
import DriversTable from "../../../Components/BoltTable/BoltTable";
import SwitchButton from "../../../Components/SwitchButton/SwitchButton";
import Confirm from "../../../Components/Popups/Confirm";
import Tooltip from "../../../Components/Tooltip/Tooltip";
import DriverForm from "./Components/DriverForm/DriverForm";
// import UpdateDriver from "./Components/UpdateDriver/UpdateDriver";

// apis imports
import {
  deleteDriver,
  fetchAllDrivers,
  changeStatus,
} from "../../../services/drivers.services";

// library imports
import noImage from "../../../assets/images/noImage.png";
import { FaRegEdit } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import { FcPlus } from "react-icons/fc";

const Drivers = () => {
  const [Change, setChange] = useState();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [drivers, setDrivers] = useState([]);

  const [manageDriverModal, setManageDriverModal] = useState(false);
  const [deleteDriverModal, setDeleteDriverModal] = useState(false);

  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 1,
      label: "Active",
      render: (row) => (
        <>
          <SwitchButton
            onChange={(e) => handleStatusChange(e, row)}
            checked={row?.driver?.active === "true"}
            disabled={Change ? true : false}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 2,
      label: "Profile Picture",
      render: (row) => (
        <>
          <img
            src={row?.driver?.picture ?? noImage}
            onError={(e) => {
              e.target.src = noImage;
            }}
            loading="lazy"
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 3,
      label: "Username",
      render: (row) => <>{row?.username ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "Name",
      render: (row) => <>{row?.driver?.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 5,
      label: "Phone Number",
      render: (row) => <>{row?.driver?.phone ?? "-"}</>,
      width: "200px",
    },
    {
      id: 6,
      label: "Email",
      render: (row) => <>{row?.email ?? "-"}</>,
      width: "200px",
    },
    {
      id: 7,
      label: "Vehicle",
      render: (row) => (
        <>
          {(() => {
            if (row?.driver?.vehicle) {
              const { license_plate, model, type } = row?.driver?.vehicle;
              const vehicleString = `${license_plate}, ${model}, ${type}`;
              return vehicleString;
            } else {
              return "No vehicle selected";
            }
          })()}
        </>
      ),
      width: "200px",
    },
    {
      id: 8,
      label: "Actions",
      render: (row) => (
        <div className="table_action__wrap">
          <Tooltip title="Edit Driver">
            <div
              className="table_action__btn"
              onClick={() => setManageDriverModal(row)}
            >
              <FaRegEdit size={7} />
              Edit
            </div>
          </Tooltip>
          <Tooltip title="Delete Driver">
            <div
              className="table_action__btn"
              onClick={() => setDeleteDriverModal(row.id)}
            >
              <BsTrash size={7} />
              Delete
            </div>
          </Tooltip>
        </div>
      ),
      width: "200px",
      fixed: "right",
    },
  ];

  const deleteSingleDriver = async () => {
    try {
      setLoading(true);
      const deleted = await deleteDriver(deleteDriverModal);
      if (deleted.error) {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: deleted?.error?.message || "Couldn't delete driver",
            severity: "error",
          };
        });
        setLoading(false);
      } else {
        setDrivers((prevData) => {
          return {
            ...prevData,
            data: prevData?.data?.filter(
              (driver) => driver.id !== deleteDriverModal
            ),
          };
        });
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: deleted?.data?.message,
            severity: "success",
          };
        });
        setLoading(false);
        setDeleteDriverModal(false);
      }
    } catch (error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: error?.message || "Something went wrong",
          severity: "error",
        };
      });
      setLoading(false);
    }
  };

  const getAllDrivers = async (page, perPage, filter) => {
    const response = await fetchAllDrivers(page, perPage, filter);
    if (!response.error) {
      setDrivers(response.data.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const handleStatusChange = async (e, record) => {
    try {
      setChange(true);
      setDrivers((prevData) => {
        return {
          ...prevData,
          data: prevData?.data?.map((item) =>
            item.id == record.id
              ? {
                  ...item,
                  driver: {
                    ...item.driver,
                    active: e.target.checked ? "true" : "false",
                  },
                }
              : item
          ),
        };
      });
      const updated = await changeStatus({
        record_id: parseInt(record?.id),
        active: e.target.checked ? "true" : "false",
      });
      if (updated.error) {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: updated.error.message,
            severity: "error",
          };
        });
      } else {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: `Status of vehicle changed to ${
              e.target.checked ? "Active" : "Inactive"
            }`,
            severity: e.target.checked ? "success" : "info",
          };
        });
      }
      setChange(false);
    } catch (error) {
      setSnack((snack) => ({
        ...snack,
        open: true,
        text: error?.message || "Something went wrong",
        severity: "error",
      }));
    }
  };

  return (
    <div className="dispatcher__screen" style={{ maxWidth: "100%" }}>
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <DriverForm
        setRefresh={setRefresh}
        open={manageDriverModal}
        setOpen={setManageDriverModal}
        setSnack={setSnack}
      />
      <Confirm
        open={deleteDriverModal}
        setOpen={setDeleteDriverModal}
        handleConfirm={deleteSingleDriver}
        text="Do you want to delete this driver?"
        loader={loading}
      />

      <div className="vehicle_header">
        <div>
          <span className="vehicle_hdrheading">Drivers List</span>
        </div>
        <div className="vehicle_page_headerrt_wrap">
          <div className="vehicle_page_headerrt">
            <div
              onClick={() => setManageDriverModal(true)}
              className="vehicle_page_hdraddprod"
            >
              <FcPlus size={10} />
              <span>ADD DRIVER</span>
            </div>
          </div>
        </div>
      </div>
      <div className="custom__table_size1 actionbtn__needtobe_fixwidth">
        <DriversTable
          message={"No Drivers Available To Display!"}
          searchPlaceHolder={"Search Drivers"}
          getAllData={getAllDrivers}
          data={drivers}
          columns={columns}
          refresh={refresh}
        />
      </div>
    </div>
  );
};

export default Drivers;
