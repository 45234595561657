import axios from "../../axiosInstance";
// common services
export const updateAccountStatus = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/update-account-status/${data.id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchUsers = async (page, perPage, filter, type) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/get-users/${type}?perPage=${perPage}&page=${page}&name=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchShipperUsers = async () => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`admin/get-shipper-users`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const deleteUserByAdmin = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`admin/delete-user/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchTransactionswithuserID = async (
  id,
  page,
  perPage,
  filter
) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/get-transaction-by-user-id/${id}?perPage=${perPage}&page=${page}&name=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateBalance = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/payment/balance/add/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const subtractBalance = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/payment/balance/subtract/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updatePasswordByAdmin = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/change-user-password`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

// shipper services
export const updateServicecharge = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.put(
      `admin/payment-formula/user/is-service-charge/${data.id}/${data.is_service_charge}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchShipperInvoicesHistoryByID = async (
  id,
  page,
  perPage,
  filter
) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/payment-formula/user-invoice/get/${id}?perPage=${perPage}&page=${page}&filterValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateCompanyStatus = async (name, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/shipping-company/update-user-company-status/${name}/${id}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateUser = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/update-user-details/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateServiceStatusForUser = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `admin/shipping-company/update-user-company-services/${id}`,
      data
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

// warehouses services
export const getAllWarehouseUsers = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/warehouse/get-all-warehouses?perPage=${perPage}&page=${page}&filterValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const getClientsByWarehouses = async (id, page, perPage, filter) => {
  let response = { data: null, error: null };

  try {
    const res = await axios.get(
      `admin/warehouse/get-clients-by-warehouse-id/${id}?perPage=${perPage}&page=${page}&filterValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const getProductsByWarehouses = async (id, page, perPage, filter) => {
  let response = { data: null, error: null };

  try {
    const res = await axios.get(
      `admin/warehouse/get-products-by-warehouse-id/${id}?perPage=${perPage}&page=${page}&filterValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const changeWarehouseStatus = async (id, status) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/warehouse/update-status/${id}/${status}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

// trucker services
export const updateZonesPermission = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/update-zone-status/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
