import { PaymentElement } from "@stripe/react-stripe-js";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";

const StripeCreditCardForm = forwardRef((props, ref) => {
  const { CreateShipment, StepCheck, SetStepCheck } = props;

  const stripe = useStripe();
  const elements = useElements();
  const [globalEventHolder, setGlobalEventHolder] = useState(null);

  useImperativeHandle(ref, () => ({
    fireSubmit(event) {
      setGlobalEventHolder(event);
      handleSubmit(event);
    },
  }));

  const [message, setMessage] = useState(null);

  useEffect(() => {
    CreateShipment(globalEventHolder);
  }, [StepCheck["credit_card_payment_method_id"]]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const response = await stripe.confirmSetup({
      elements,
      confirmParams: {},
      redirect: "if_required",
    });

    if (
      (response.error && response.error.type === "card_error") ||
      (response.error && response.error.type === "validation_error")
    ) {
      setMessage(response.error.message);
    } else if (response.setupIntent.payment_method) {
      SetStepCheck((prevStepCheck) => ({
        ...prevStepCheck,
        credit_card_payment_method_id: response.setupIntent.payment_method,
      }));
    }
  };

  return (
    <>
      <form
        id="payment-form"
        onSubmit={handleSubmit}
        className="payment__stripe_main"
      >
        <PaymentElement id="payment-element" />

        <div className="pmtsadd__strpcard_btnwrap"></div>
        {message && <div id="payment-message">{message}</div>}
      </form>
    </>
  );
});

export default StripeCreditCardForm;
