import React, { useEffect, useState } from "react";
import "./ManageServices.css";

// components imports
// apis imports
import { updateServiceStatusForUser } from "../../../../../../../services/AdminServices/Users/users.services";
// library imports

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import { FaRegTimesCircle } from "react-icons/fa";

export default function ManageServices({
  open,
  setOpen,
  carrier,
  Android12Switch,
  // setCarriers,
  setSnack,
}) {
  const [data, setData] = useState({});

  const handleClose = () => {
    setOpen(false);
  };
  const handleServiceChange = async (e, service) => {
    setData((data) => {
      return {
        ...data,
        services: {
          ...data.services,
          admin: { ...data.services?.admin, [service]: e.target.checked },
        },
      };
    });
    const response = await updateServiceStatusForUser(
      { [service]: e.target.checked },
      data?.id
    );
    if (!response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `${service} service ${
            e.target.checked ? "enabled" : "disabled"
          } for ${data.name ?? "this carrier"}`,
          severity: "success",
        };
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `Something went wrong!`,
          severity: "error",
        };
      });
      setData((data) => {
        return {
          ...data,
          services: {
            ...data.services,
            admin: { ...data.services?.admin, [service]: !e.target.checked },
          },
        };
      });
    }
  };
  useEffect(() => {
    if (carrier) {
      setData(carrier);
    }
  }, [carrier]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="manage__services_modal">
          <div className="manage__services_closewrap" onClick={handleClose}>
            <IconButton>
              <FaRegTimesCircle size={25} color="lightgrey" />
            </IconButton>
          </div>
          <div className="manage__services_modalheader">
            <h2>
              <span className="manage__services_mhheadingcarr">
                {carrier?.name ? `${carrier?.name} ` : ""}
              </span>
              Services
            </h2>
            <img
              className="manage__services_modalhdrimg"
              src={data?.shipping_company_for_user?.logo_url}
            />
          </div>
          <div className="manage__services_modalcontent">
            <div className="manage__services_srvcsgrid">
              {data?.services?.admin &&
                Object.entries(data?.services?.admin)?.map(
                  ([service, status]) => {
                    return (
                      <div>
                        <div className="manage__services_srvcsgitem">
                          <Android12Switch
                            onChange={(e) => handleServiceChange(e, service)}
                            checked={status}
                          />
                          <span className="manage__services_srvcsgitxt">
                            {service.toUpperCase()}
                          </span>
                        </div>
                      </div>
                    );
                  }
                )}
            </div>
            {(data?.services?.length < 1 || !data?.services) && (
              <div className="manage__services_srvcsempt">
                No Services Available!
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
