import React, { useState } from "react";
import "./attachdocuments.css";

import { BiCloudUpload } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import { useEffect } from "react";
import noImage from "../../../../assets/images/noImage.png";
import pdfIcon from "../../../../assets/icons/pdfIcon.png";
import excelIcon from "../../../../assets/icons/excelIcon.png";
import docsIcon from "../../../../assets/icons/docsIcon.png";
import { checkFileType } from "../../../../helpers/helpers";

// can make it general
function returnAttachments(attachment, isImage = false) {
  if (!attachment) {
    return noImage;
  }
  const fileType = checkFileType(attachment);
  switch (fileType) {
    case "Image":
      return isImage ? URL.createObjectURL(isImage) : attachment;
    case "PDF":
      return pdfIcon;
    case "Excel":
      return excelIcon;
    case "Document":
      return docsIcon;
    default:
      return noImage;
  }
}

const AttachDocument = ({
  formData,
  setFormData,
  modeType,
  setfullview,
  user,
}) => {
  const [uploadedImages, setUploadedImages] = useState([]);
  const [addUrlBox, setAddUrlBox] = useState(false);

  const handleImageUpload = (e) => {
    const imageFiles = Array.from(e.target.files);

    setUploadedImages((prevImages) => [...prevImages, ...imageFiles]);

    setFormData({
      ...formData,
      media: [...formData.media, ...imageFiles],
    });
  };
  const handleRemoveImage = (index, image) => {
    const updatedImages = [...uploadedImages];
    updatedImages.splice(index, 1);

    setUploadedImages(updatedImages);
    if (image.id) {
      setFormData({
        ...formData,
        media: updatedImages,
        deleted_media_ids: [...formData.deleted_media_ids, image.id],
      });
    } else {
      setFormData({
        ...formData,
        media: updatedImages,
      });
    }
  };

  useEffect(() => {
    setUploadedImages(formData.media);
  }, [formData.media]);

  return (
    <>
      <div
        className="card bg-white shadow"
        style={{
          borderRadius: "15px",
          padding: "15px",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <b>Media</b>
        {user.role != "warehouseUser" && modeType != "view" && (
          <div className="add__adj_byform_mediabox">
            {!addUrlBox && (
              <>
                <div className="add__adj_byform_imagebtns">
                  <input
                    type="file"
                    hidden
                    multiple
                    id="file-input"
                    onChange={handleImageUpload}
                  />
                  <label
                    htmlFor="file-input"
                    className="add__adj_byform_uploadimagebtn"
                  >
                    <BiCloudUpload
                      size={20}
                      className="add__adj_byform_mdbtnicon"
                    />
                    Upload Media
                  </label>
                </div>
                <div className="add__adj_byform_mdbtmtext">
                  You can upload multiple Attachments.
                </div>
              </>
            )}
          </div>
        )}
        <div
          className="add__adj_byform_images"
          style={{
            marginBottom: uploadedImages?.length < 1 ? "0px" : "",
          }}
        >
          {uploadedImages.length === 0 && modeType === "view" ? (
            <div
              style={{
                textAlign: "center",
                height: "40px",
                width: "100%",
                fontSize: "13px",
              }}
            >
              No Media Available!
            </div>
          ) : (
            <>
              {uploadedImages?.map((image, index) => (
                <div className="add__adj_byform_imagewrap">
                  {user.role != "warehouseUser" && modeType != "view" && (
                    <MdClose
                      className="add__adj_byform_imageclose"
                      size={20}
                      onClick={() => handleRemoveImage(index, image)}
                    />
                  )}
                  {image.id ? (
                    <img
                      src={returnAttachments(image?.file)}
                      className="add__adj_byform_image"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (
                          user?.role === "warehouseUser" ||
                          modeType === "view"
                        ) {
                          if (checkFileType(image.file) === "Image") {
                            setfullview(image?.file || "");
                          } else {
                            window.open(image?.file, "_blank");
                          }
                        } else {
                          return;
                        }
                      }}
                    />
                  ) : (
                    <img
                      src={returnAttachments(
                        image?.type?.replace(/\//g, "."),
                        image
                      )}
                      className="add__adj_byform_image"
                      onClick={() => {
                        if (user.role === "warehouseUser") {
                          if (checkFileType(image.file) === "Image") {
                            setfullview(image?.file || "");
                          } else {
                            window.open(image?.file, "_blank");
                          }
                        } else {
                          return;
                        }
                      }}
                    />
                  )}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AttachDocument;
