import React, { useEffect, useState } from "react";
import "./ImagesModal.css";

// components imports
import CircularLoader from "../../../../Components/CircularLoader/CircularLoader";
import SkeletonLoading from "../../../../Components/SkeletonLoading/SkeletonLoading";
// apis imports
import {
  updateProductsDetails,
  fetchProductMedia,
} from "../../../../services/product.service";
// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import AddImage from "../../../../assets/images/add-image.png";
import { MdOutlineCancel, MdArrowBack } from "react-icons/md";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import { FaRegStar, FaStar } from "react-icons/fa6";
import { HiMiniMinusSmall } from "react-icons/hi2";
import Tooltip from "@mui/material/Tooltip";
import noImage from "../../../../assets/images/noImage.png";

export default function ImagesModal({
  open,
  setOpen,
  setSnack,
  setProducts,
  Editable = true,
}) {
  const [data, setData] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const [fullView, setFullView] = useState();

  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPrimary, setloadingPrimary] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const handleClose = () => {
    setOpen(false);
    setData([]);
    setDeleted([]);
    setLoading(false);
    setSelectedImages([]);
    setFullView();
  };

  useEffect(() => {
    const getAllProducts = async () => {
      setSkeletonLoading(true);
      const response = await fetchProductMedia(open);
      if (!response.error) {
        setSkeletonLoading(false);
        setData(response.data.result);
      } else {
        setSkeletonLoading(false);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Something went wrong",
            severity: "error",
          };
        });
      }
    };
    if (open) {
      getAllProducts();
    }
  }, [open]);

  const handleRemove = (index, id) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData.splice(index, 1);
      return newData;
    });
    setDeleted((prevDeleted) => {
      // If the deleted array is empty, add the id as the first element
      if (prevDeleted.length === 0) {
        return [id];
      }
      // Otherwise, add the id to the existing array
      return [...prevDeleted, id];
    });
  };

  const handleRemoveSeleceted = (id) => {
    const updatedImages = selectedImages.filter((image) => image.id !== id);
    setSelectedImages(updatedImages);
  };

  const handleSave = async () => {
    setLoading(true);
    const formData = new FormData();
    if (deleted.length > 0) {
      formData.append(`deletedMediaIds`, JSON.stringify(deleted));
    }

    if (selectedImages.length > 0) {
      selectedImages.forEach((image, index) => {
        const base64String = image.src.split(",")[1]; // Get the base64 string after the comma
        const blob = atob(base64String); // Decode the base64 string
        const arrayBuffer = new ArrayBuffer(blob.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < blob.length; i++) {
          uint8Array[i] = blob.charCodeAt(i);
        }

        const fileBlob = new Blob([arrayBuffer], { type: "image/png" }); // Adjust the type accordingly

        formData.append(`media[${index}]`, fileBlob, `image_${index}.png`);
      });
    }
    const response = await updateProductsDetails(formData, open);
    if (!response.error) {
      setProducts((prevProducts) => {
        return {
          ...prevProducts,
          data: prevProducts?.data?.map((product, index) => {
            if (product.id === open) {
              return {
                ...product,
                media: response?.data?.result?.media?.filter(
                  (p) => p.is_primary === "true"
                ),
              };
            }
            return product;
          }),
        };
      });
      setData(response?.data?.result?.media);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Product Updated successfully",
          severity: "success",
        };
      });
      setSelectedImages([]);
      setDeleted([]);
      setLoading(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
      setLoading(false);
    }
  };

  const handlePrimary = async (id) => {
    if (Editable === false) {
      return;
    }
    setloadingPrimary(true);
    const formData = new FormData();
    formData.append(`primary_media_id`, id);
    const response = await updateProductsDetails(formData, open);
    if (!response.error) {
      setProducts((prevProducts) => {
        return {
          ...prevProducts,
          data: prevProducts?.data?.map((product, index) => {
            if (product.id === open) {
              return {
                ...product,
                media: response?.data?.result?.media?.filter(
                  (p) => p.is_primary === "true"
                ),
              };
            }
            return product;
          }),
        };
      });
      setData(response?.data?.result?.media);

      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Product Updated successfully",
          severity: "success",
        };
      });
      setDeleted([]);
      setloadingPrimary(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
      setloadingPrimary(false);
    }
  };

  const handleImageSelect = (event) => {
    const files = event.target.files;
    const imagesArray = [];

    for (const file of files) {
      const reader = new FileReader();

      reader.onload = function (e) {
        imagesArray.push({
          src: e.target.result,
          id: Math.random().toString(),
        });
        setSelectedImages([...imagesArray]);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="prdtable__images_modal">
          <div className="prdtable__images_modaltopbar">
            <div className="imageSelection__title">Product Images</div>
            <MdOutlineCancel
              size={25}
              className="prdtable__images_modalclose"
              onClick={handleClose}
            />
            {fullView && (
              <MdArrowBack
                size={25}
                className="prdtable__images_modalback"
                onClick={() => setFullView(false)}
              />
            )}
          </div>
          {!fullView ? (
            <div className="prdtable__images_modalcontent">
              <div className="prdtable__images_modalimgs">
                {skeletonLoading && (
                  <div className="prdtable__images_skeletonwrapper">
                    {["", ""].map(() => {
                      return <SkeletonLoading height={100} width="100px" />;
                    })}
                  </div>
                )}
                {data?.map((image, index) => (
                  <div
                    className={
                      image?.is_primary === "true"
                        ? "primary__image_wrap"
                        : "ordinary__image_wrap"
                    }
                  >
                    <Badge
                      badgeContent={
                        <HiMiniMinusSmall
                          size={20}
                          onClick={() => handleRemove(index, image?.id)}
                          style={{ cursor: "pointer" }}
                        />
                      }
                      color="error"
                      sx={{
                        ".MuiBadge-badge": {
                          right: "12px",
                          top: "12px",
                          padding: "0px",
                        },
                      }}
                      invisible={!Editable}
                    >
                      <div key={index}>
                        <ImageListItem
                          key={index}
                          className="prdtable__images_modalimg"
                        >
                          <img
                            src={image?.file || noImage}
                            onClick={() => setFullView(image?.file || "")}
                            className="uploaded__image"
                            onError={(e) => {
                              e.target.src = noImage;
                            }}
                            style={{ cursor: "pointer", objectFit: "contain" }}
                          />
                          {image?.file && (
                            <ImageListItemBar
                              sx={{
                                background:
                                  "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                                  "rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%)",
                              }}
                              // title={
                              //   image?.is_primary === "true"
                              //     ? "Primary"
                              //     : " "
                              // }
                              position="top"
                              actionIcon={
                                <>
                                  {Editable && (
                                    <div>
                                      <Tooltip
                                        title={
                                          image?.is_primary === "true"
                                            ? "Primary Image"
                                            : "Set as Primary Image"
                                        }
                                        placement="top"
                                      >
                                        <IconButton
                                          sx={{ color: "white" }}
                                          disabled={
                                            loadingPrimary ||
                                            image?.is_primary === "true"
                                          }
                                          onClick={() =>
                                            handlePrimary(image?.id)
                                          }
                                        >
                                          {image?.is_primary === "true" ? (
                                            <FaStar />
                                          ) : (
                                            <FaRegStar />
                                          )}
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  )}
                                </>
                              }
                              actionPosition="left"
                            />
                          )}
                        </ImageListItem>
                      </div>
                    </Badge>
                  </div>
                ))}
                {selectedImages?.map((image, index) => (
                  <>
                    <Badge
                      badgeContent={
                        <HiMiniMinusSmall
                          size={20}
                          onClick={() => handleRemoveSeleceted(image.id)}
                          style={{ cursor: "pointer" }}
                        />
                      }
                      color="error"
                      sx={{
                        ".MuiBadge-badge": {
                          right: "12px",
                          top: "12px",
                          padding: "0px",
                        },
                      }}
                    >
                      <div key={index}>
                        <ImageListItem
                          key={index}
                          className="prdtable__images_modalimg"
                        >
                          <img
                            src={image.src || noImage}
                            onClick={() => setFullView(image.src || noImage)}
                            onError={(e) => {
                              e.target.src = noImage;
                            }}
                            style={{ cursor: "pointer", objectFit: "contain" }}
                          />
                          <ImageListItemBar
                            sx={{
                              background:
                                "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                                "rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%)",
                            }}
                            title={"New " + (index + 1)}
                            position="top"
                          />
                        </ImageListItem>
                      </div>
                    </Badge>
                  </>
                ))}
                {Editable && (
                  <>
                    <label
                      className="prdtable__images_addnewbtn"
                      for="upload_new_img"
                    >
                      <img src={AddImage} />
                      <div>
                        {selectedImages.length > 0
                          ? selectedImages.length + " Images Selected"
                          : "Add New Image"}
                      </div>
                    </label>
                    <input
                      type="file"
                      hidden
                      id="upload_new_img"
                      multiple
                      onChange={handleImageSelect}
                    />
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="prdtable__images_fullimgview">
              <img
                src={fullView || noImage}
                onError={(e) => {
                  e.target.src = noImage;
                }}
              />
            </div>
          )}
          <div className="prod__imag_es_modalfooter">
            <div
              className="prod__imag_es_modalfootercncl"
              onClick={handleClose}
            >
              Close
            </div>
            {(selectedImages.length > 0 || deleted.length > 0) && (
              <div
                className="prod__imag_es_modalfootersave"
                onClick={handleSave}
              >
                {loading ? <CircularLoader /> : "Save"}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
