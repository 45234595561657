import React, { useState, useEffect } from "react";
import "./Forms.css";
const IcsForm = ({ carrier, setFinalPayload }) => {
  const [payload, setPayload] = useState({
    account_name: "",
    contract_id: "",
    access_key: "",
    user_name: "",
    password: "",
    account_no: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };
  useEffect(() => {
    setFinalPayload(payload);
  }, [payload]);
  return (
    <div className="cuscarriers__form_wrap">
      <div className="cuscarriers__form_logo">
        <img src={carrier?.logo_url} />
      </div>
      <div className="cuscarriers__form_content">
        <div className="cuscarriers__fcntnt_fieldwrap">
          <label className="cuscarriers__fcntnt_fieldlbl">Account Name*</label>
          <input
            type="text"
            className="cuscarriers__fcntnt_field"
            placeholder="Account Name To Be Displayed"
            name="account_name"
            onChange={handleChange}
            required
            value={payload?.account_name ?? ""}
          />
        </div>
        <div className="cuscarriers__fcntnt_fieldwrap">
          <label className="cuscarriers__fcntnt_fieldlbl">Account ID*</label>
          <input
            type="text"
            className="cuscarriers__fcntnt_field"
            placeholder="Account ID"
            name="user_name"
            onChange={handleChange}
            required
            value={payload?.user_name ?? ""}
          />
        </div>
        <div className="cuscarriers__fcntnt_fieldwrap">
          <label className="cuscarriers__fcntnt_fieldlbl">Password*</label>
          <input
            type="password"
            className="cuscarriers__fcntnt_field"
            placeholder="Password"
            name="password"
            onChange={handleChange}
            required
            value={payload?.password ?? ""}
          />
        </div>
      </div>
    </div>
  );
};

export default IcsForm;
