import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SearchIcon from "../../../assets/icons/dispatcher-search.svg";
import FilterIcon from "../../../assets/icons/dispatcher-filter.svg";
import SizeIcon from "../../../assets/icons/dispatcher-size.svg";
import downloadIcon from "../../../assets/icons/dispatcher-download.svg";
import BarcodeIcon from "../../../assets/icons/dispatcher-barcode.svg";
import DragIcon from "../../../assets/icons/dispatcher-drag.svg";
import DropdownIcon from "../../../assets/icons/dispatcher-dropdown.svg";
import CheckIcon from "../../../assets/icons/dispatcher-check.svg";
import LoadingIcon from "../../../assets/icons/dispatcher-loading.svg";
import { styled } from "@mui/material/styles";
import FailedIcon from "../../../assets/icons/dispatcher-failed.svg";
import FileIcon from "../../../assets/icons/dispatcher-file.svg";
import LabelIcon from "../../../assets/icons/dispatcher-label.svg";
import DetailsIcon from "../../../assets/icons/dispatcher-details.svg";
import InfoIcon from "../../../assets/icons/dispatcher-info.svg";
import SmallIcon from "../../../assets/icons/dispatcher-stable.svg";
import MediumIcon from "../../../assets/icons/dispatcher-mtable.svg";
import LargeIcon from "../../../assets/icons/dispatcher-ltable.svg";
import Empty from "../../../assets/images/dispatcher-empty.gif";
import Logo from "../../../assets/images/hajex_logo.png";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import "./Dispatcher.css";
import shipmentsData from "./data";
import Flag from "react-world-flags";
import { cbmToCbf, lbsToKgs, toggleValue } from "../../../helpers/helpers";
import TableSize1 from "./Components/TableSizes/TableSize1";
import TableSize2 from "./Components/TableSizes/TableSize2";
import TableSize3 from "./Components/TableSizes/TableSize3";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { fetchAllShipments } from "../../../services/shipments.services";
import { useSelector } from "react-redux";
import CarrierTableLoader from "../../../Components/CarrierTableLoader/CarrierTableLoader";
import Sad from "@mui/icons-material/SentimentVeryDissatisfied";
import { fetchAllDrivers } from "../../../services/drivers.services";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const initialCols = [
  {
    id: 1,
    name: "Dropdown",
    checked: true,
    visible: true,
    label: " ",
    mediumWidth: "50px",
    largeWidth: "70px",
    width: "35px",
  },
  {
    id: 2,
    name: "Checkbox",
    checked: true,
    visible: false,
    label: <input type="checkbox" className="dispscreen__theader_hcheck" />,
    mediumWidth: "50px",
    largeWidth: "50px",
    width: "35px",
  },
  {
    id: 3,
    name: "Id",
    checked: true,
    visible: false,
    label: "#",
    mediumWidth: "50px",
    largeWidth: "50px",
    width: "50px",
  },
  {
    id: 4,
    name: "Shipment Id",
    checked: true,
    visible: true,
    mediumWidth: "150px",
    largeWidth: "170px",
    width: "130px",
  },
  {
    id: 5,
    name: "Carrier",
    checked: true,
    visible: false,
    mediumWidth: "120px",
    largeWidth: "140px",
    width: "78px",
  },
  {
    id: 6,
    name: "Created",
    checked: true,
    visible: true,
    infoBadge: true,
    mediumWidth: "140px",
    largeWidth: "160px",
    width: "118px",
  },
  {
    id: 7,
    name: "Tracking",
    checked: true,
    visible: true,
    mediumWidth: "220px",
    largeWidth: "245px",
    width: "163px",
  },
  {
    id: 8,
    name: "Pickup",
    checked: true,
    visible: true,
    infoBadge: true,
    mediumWidth: "200px",
    largeWidth: "240px",
    width: "162px",
  },
  {
    id: 9,
    name: "Delivery",
    checked: true,
    visible: true,
    infoBadge: true,
    mediumWidth: "200px",
    largeWidth: "240px",
    width: "162px",
  },
  {
    id: 10,
    name: "Weight",
    checked: true,
    visible: true,
    mediumWidth: "120px",
    largeWidth: "140px",
    width: "110px",
  },
  {
    id: 11,
    name: "Volume",
    checked: true,
    visible: true,
    mediumWidth: "160px",
    largeWidth: "180px",
    width: "140px",
  },
  {
    id: 12,
    name: "Packages",
    checked: true,
    visible: true,
    mediumWidth: "80px",
    largeWidth: "85px",
    width: "75px",
  },
  {
    id: 13,
    name: "Pickup Zone",
    checked: true,
    visible: true,
    mediumWidth: "130px",
    largeWidth: "150px",
    width: "100px",
  },
  {
    id: 14,
    name: "Delivery Zone",
    checked: true,
    visible: true,
    mediumWidth: "130px",
    largeWidth: "150px",
    width: "100px",
  },
  {
    id: 15,
    name: "Pickup Country",
    checked: true,
    visible: true,
    mediumWidth: "130px",
    largeWidth: "150px",
    width: "100px",
  },
  {
    id: 16,
    name: "Delivery Country",
    checked: true,
    visible: true,
    mediumWidth: "130px",
    largeWidth: "150px",
    width: "107px",
  },
  {
    id: 17,
    name: "Pickup City",
    checked: true,
    visible: true,
    mediumWidth: "120px",
    largeWidth: "140px",
    width: "101px",
  },
  {
    id: 18,
    name: "Delivery City",
    checked: true,
    visible: true,
    mediumWidth: "120px",
    largeWidth: "140px",
    width: "105px",
  },
  {
    id: 19,
    name: "Pickup Address",
    checked: true,
    visible: false,
    mediumWidth: "150px",
    largeWidth: "180px",
    width: "120px",
  },
  {
    id: 20,
    name: "Delivery Address",
    checked: true,
    visible: false,
    mediumWidth: "150px",
    largeWidth: "180px",
    width: "112px",
  },
  {
    id: 21,
    name: "Pickup Postal/Zip",
    checked: true,
    visible: true,
    mediumWidth: "156px",
    largeWidth: "180px",
    width: "126px",
  },
  {
    id: 22,
    name: "Delivery Postal/Zip",
    checked: true,
    visible: true,
    mediumWidth: "153px",
    largeWidth: "176px",
    width: "123px",
  },
  {
    id: 23,
    name: "Pickup Full Address",
    checked: true,
    visible: true,
    mediumWidth: "240px",
    largeWidth: "280px",
    width: "180px",
  },
  {
    id: 24,
    name: "Delivery Full Address",
    checked: true,
    visible: true,
    mediumWidth: "240px",
    largeWidth: "280px",
    width: "191px",
  },
  {
    id: 25,
    name: "Packages Status",
    checked: true,
    visible: false,
    mediumWidth: "135px",
    largeWidth: "160px",
    width: "108px",
  },
  {
    id: 26,
    name: "Pickup Notes",
    checked: true,
    visible: true,
    mediumWidth: "350px",
    largeWidth: "400px",
    width: "300px",
  },
  {
    id: 27,
    name: "Delivery Notes",
    checked: true,
    visible: true,
    mediumWidth: "350px",
    largeWidth: "400px",
    width: "300px",
  },
  {
    id: 28,
    name: "Additional Services",
    checked: true,
    visible: true,
    mediumWidth: "320px",
    largeWidth: "360px",
    width: "275px",
  },
  {
    id: 29,
    name: "Total",
    checked: true,
    visible: true,
    mediumWidth: "115px",
    largeWidth: "125px",
    width: "104px",
  },
  {
    id: 30,
    name: "Payment Status",
    checked: true,
    visible: true,
    mediumWidth: "162px",
    largeWidth: "182px",
    width: "142px",
  },
  {
    id: 31,
    name: "Shipment Status",
    checked: true,
    visible: true,
    mediumWidth: "152px",
    largeWidth: "172px",
    width: "132px",
  },
  {
    id: 32,
    name: "Priority",
    checked: true,
    visible: false,
    mediumWidth: "100px",
    largeWidth: "120px",
    width: "79px",
  },
  {
    id: 33,
    name: "Pickup Driver",
    checked: true,
    visible: true,
    mediumWidth: "180px",
    largeWidth: "200px",
    width: "162px",
  },
  {
    id: 34,
    name: "Delivery Driver",
    checked: true,
    visible: true,
    mediumWidth: "180px",
    largeWidth: "200px",
    width: "162px",
  },
  {
    id: 35,
    name: "Action",
    checked: true,
    visible: true,
    mediumWidth: "110px",
    largeWidth: "130px",
    width: "90px",
  },
  {
    id: 36,
    name: "Offers",
    checked: true,
    visible: false,
    mediumWidth: "120px",
    largeWidth: "140px",
    width: "100px",
  },
];

const Dispatcher = () => {
  const [search, setSearch] = useState();
  const [optionModal, setOptionModal] = useState();
  const [colTab, setColTab] = useState(0);
  const [expandedRow, setExpandedRow] = useState();
  const [cols, setCols] = useState(initialCols);
  const [filteredCols, setFilteredCols] = useState(initialCols);
  const [weightUnit, setWeightUnit] = useState("lb");
  const [volumeUnit, setVolumeUnit] = useState("cbm");
  const [checkedRows, setCheckedRows] = useState([]);
  const [shipments, setShipments] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [pagination, setPagination] = useState({});
  const [tableSize, setTableSize] = useState("small");
  const [loading, setLoading] = useState(true);
  const [pickupDrivers, setPickupDrivers] = useState([
    "Ali",
    "Ahmad",
    "Abdul Qadir",
  ]);
  const [deliveryDrivers, setDeliveryDrivers] = useState([
    "Ali",
    "Ahmad",
    "Abdul Qadir",
  ]);
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    text: "",
    severity: "",
  });
  const [pickupDriver, setPickupDriver] = useState(pickupDrivers[0]);
  const [deliveryDriver, setDeliveryDriver] = useState(deliveryDrivers[0]);
  const user = useSelector((state) => state.user);

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(shipments);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Shipments");
    XLSX.writeFile(wb, "shipments.xlsx");
  };

  const exportToCsv = async () => {
    const csvData = Papa.unparse(
      shipments.map((row) => {
        return Object.keys(row).reduce((acc, key) => {
          if (Array.isArray(row[key])) {
            acc[key] = row[key].map((item) => JSON.stringify(item)).join(", ");
          } else {
            acc[key] =
              typeof row[key] === "object"
                ? JSON.stringify(row[key])
                : row[key];
          }
          return acc;
        }, {});
      })
    );

    const handleSnackClose = () => {
      setSnack({ ...snack, open: false });
    };
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "shipments.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const getAllShipments = async (page, preventLoading) => {
    !preventLoading && setLoading(true);
    const response = await fetchAllShipments(
      user.token,
      page ?? pagination.current_page
    );
    // console.log(response.data.result.data);
    if (!response.error) {
      const { data, ...newObject } = response.data.result.shipments;
      setPagination(newObject);
      setShipments(response.data.result.shipments.data);
      setDrivers(response.data.result.drivers);
    }
    setLoading(false);
  };
  const [isEmpty, setEmpty] = useState(false);
  const getAllDrivers = async (page) => {
    try {
      const response = await fetchAllDrivers(user.token);
      setDrivers(response.data.result.Drivers.data);
    } catch (error) {
      console.log(error);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Can not load drivers",
          severity: "error",
        };
      });
    }
  };
  const handleOptionModal = (modal) => {
    if (modal === optionModal) {
      setOptionModal(false);
    } else {
      setOptionModal(modal);
    }
  };
  const handleColTab = (tab) => {
    setColTab(tab);
  };
  const handleTableSize = (size) => {
    handleOptionModal("size");
    setTableSize(size);
  };
  const handleColSearch = (e) => {
    let filtered = cols.filter((item) =>
      item.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredCols(filtered);
  };
  const handleColChecked = (id, checked) => {
    setFilteredCols((prevCols) =>
      prevCols.map((col) => (col.id === id ? { ...col, checked } : col))
    );
    setCols((prevCols) =>
      prevCols.map((col) => (col.id === id ? { ...col, checked } : col))
    );
  };

  const handleRowChecked = (value) => {
    let arr = checkedRows;
    const index = arr.indexOf(value);
    if (index === -1) {
      arr.push(value);
    } else {
      arr.splice(index, 1);
    }
    setCheckedRows([...arr]);
  };

  const handleAllChecked = () => {
    if (checkedRows.length === shipments.length) {
      setCheckedRows([]);
    } else {
      setCheckedRows(shipments.map((item) => item.id));
    }
  };

  const handleShowAllCols = (show) => {
    setCols((prevCols) => {
      return prevCols.map((col) => {
        return { ...col, checked: show };
      });
    });
    setFilteredCols((prevCols) => {
      return prevCols.map((col) => {
        return { ...col, checked: show };
      });
    });
  };
  const handleRowExpand = (id) => {
    setExpandedRow(expandedRow === id ? false : id);
  };
  const handleSnackClose = () => {
    setSnack({ ...snack, open: false });
  };
  const handleDriverChange = (type, shipmentId, driver) => {
    setShipments((preData) => {
      return preData.map((shipment) => {
        if (shipment.id === shipmentId) {
          if (type === "pickup") {
            return { ...shipment, pickup_driver: driver };
          } else {
            return { ...shipment, delivery_driver: driver };
          }
        } else {
          return shipment;
        }
      });
    });
  };

  useEffect(() => {
    if (cols.every((obj) => obj.checked === false) || shipments.length === 0) {
      setEmpty(true);
    } else {
      setEmpty(false);
    }
  }, [cols, shipments]);
  useEffect(() => {
    getAllShipments(1);
  }, []);

  return (
    <div className="dispatcher__screen">
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.text}
        </Alert>
      </Snackbar>
      <div className="dispatcher__screen_searchwrap">
        <img
          src={SearchIcon}
          className="dispatcher__screen_searchicon"
          alt=""
        />
        <input
          type="text"
          placeholder="Search"
          className="dispatcher__screen_searchfield"
        />
      </div>
      <div className="dispatcher__screen_options">
        <div
          className="dispatcher__screen_option"
          onClick={() => handleOptionModal("columns")}
        >
          <span className="dispatcher__screen_optcolicon">
            <span className="dispatcher__screen_optcolbar"></span>
            <span className="dispatcher__screen_optcolbar"></span>
            <span className="dispatcher__screen_optcolbar"></span>
          </span>
          <span className="dispatcher__screen_optiontext">COLUMNS</span>
          {optionModal === "columns" && (
            <div
              className="dispscreen__cols_modal"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="dispscreen__colsmodal_autocomplt">
                <input
                  type="text"
                  onChange={handleColSearch}
                  placeholder="Search Column"
                  className="dispscreen__colsmodal_autocompltfield"
                />
              </div>
              {/* <div className="dispscreen__colsmodal_tabs">
                <div
                  className={`dispscreen__colsmodal_tab ${
                    colTab === 0 && "dispscreen__colsmodal_tabactive"
                  }`}
                  onClick={() => handleColTab(0)}
                >
                  Desktop
                </div>
                <div
                  className={`dispscreen__colsmodal_tab ${
                    colTab === 1 && "dispscreen__colsmodal_tabactive"
                  }`}
                  onClick={() => handleColTab(1)}
                >
                  Mobile
                </div>
              </div> */}
              <div className="dispscreen__colsmodal_colslist">
                {filteredCols.map((item) => {
                  if (item.visible) {
                    return (
                      <div className="dispscreen__colsmodal_colslistitem">
                        <span className="dispscreen__colsmodal_colslistitemleft">
                          <input
                            type="checkbox"
                            className="dispscreen__colsmodal_colslistitemcheck"
                            checked={item.checked}
                            onChange={(e) =>
                              handleColChecked(item.id, e.target.checked)
                            }
                          />
                          {item.name}
                        </span>
                        <span className="dispscreen__colsmodal_colslistitemright">
                          <img
                            src={DragIcon}
                            alt=""
                            className="dispscreen__colsmodal_colslistitemdrag"
                          />
                        </span>
                      </div>
                    );
                  }
                })}
              </div>
              <div className="dispscreen__colsmodal_footer">
                <div
                  className="dispscreen__colsmodal_ftrleft"
                  onClick={() => handleShowAllCols(false)}
                >
                  HIDE ALL
                </div>
                <div
                  className="dispscreen__colsmodal_ftrright"
                  onClick={() => handleShowAllCols(true)}
                >
                  SHOW ALL
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <div className="dispatcher__screen_option">
          <img src={FilterIcon} className="dispatcher__screen_optionicon" />
          <span className="dispatcher__screen_optiontext">FILTER</span>
        </div> */}
        <div
          className="dispatcher__screen_option"
          onClick={() => handleOptionModal("size")}
        >
          <img src={SizeIcon} className="dispatcher__screen_optionicon" />
          <span className="dispatcher__screen_optiontext">SIZE</span>
          {optionModal === "size" && (
            <div
              className="dispscreen__size_modal"
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className="dispscreen__size_modalitem"
                onClick={() => handleTableSize("small")}
              >
                <div className="dispscreen__size_mileft">
                  <img src={SmallIcon} alt="" />
                </div>
                <div className="dispscreen__size_miright">Small</div>
              </div>
              <div
                className="dispscreen__size_modalitem"
                onClick={() => handleTableSize("medium")}
              >
                <div className="dispscreen__size_mileft">
                  <img src={MediumIcon} alt="" />
                </div>
                <div className="dispscreen__size_miright">Medium</div>
              </div>
              <div
                className="dispscreen__size_modalitem"
                onClick={() => handleTableSize("large")}
              >
                <div className="dispscreen__size_mileft">
                  <img src={LargeIcon} alt="" />
                </div>
                <div className="dispscreen__size_miright">Large</div>
              </div>
            </div>
          )}
        </div>
        <div
          className="dispatcher__screen_option"
          onClick={() => handleOptionModal("export")}
        >
          <img src={downloadIcon} className="dispatcher__screen_optionicon" />
          <span className="dispatcher__screen_optiontext">EXPORT</span>
          {optionModal === "export" && (
            <div
              className="dispscreen__export_modal"
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className="dispscreen__export_modalitem"
                onClick={() => exportToExcel()}
              >
                <div className="dispscreen__export_mileft"></div>
                <div className="dispscreen__export_miright">
                  Download Excel File
                </div>
              </div>
              <div
                className="dispscreen__export_modalitem"
                onClick={() => exportToCsv()}
              >
                <div className="dispscreen__export_mileft"></div>
                <div className="dispscreen__export_miright">
                  Download CSV File
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <div className="dispatcher__screen_option">
          <img src={BarcodeIcon} className="dispatcher__screen_optionicon" />
          <span className="dispatcher__screen_optiontext">SCAN BARCODE</span>
        </div> */}
      </div>
      {shipments.length > 0 && tableSize === "small" && (
        <TableSize1
          shipments={shipments}
          setShipments={setShipments}
          handleRowChecked={handleRowChecked}
          handleRowExpand={handleRowExpand}
          checkedRows={checkedRows}
          handleAllChecked={handleAllChecked}
          handleDriverChange={handleDriverChange}
          cols={cols}
          pickupDrivers={pickupDrivers}
          deliveryDrivers={deliveryDrivers}
          expandedRow={expandedRow}
          weightUnit={weightUnit}
          setWeightUnit={setWeightUnit}
          volumeUnit={volumeUnit}
          setVolumeUnit={setVolumeUnit}
          isEmpty={isEmpty}
          pagination={pagination}
          getAllShipments={getAllShipments}
          loading={loading}
          drivers={drivers}
          setSnack={setSnack}
        />
      )}
      {shipments.length > 0 && tableSize === "medium" && (
        <TableSize2
          shipments={shipments}
          setShipments={setShipments}
          handleRowChecked={handleRowChecked}
          handleRowExpand={handleRowExpand}
          checkedRows={checkedRows}
          handleAllChecked={handleAllChecked}
          handleDriverChange={handleDriverChange}
          cols={cols}
          pickupDrivers={pickupDrivers}
          deliveryDrivers={deliveryDrivers}
          expandedRow={expandedRow}
          weightUnit={weightUnit}
          setWeightUnit={setWeightUnit}
          volumeUnit={volumeUnit}
          setVolumeUnit={setVolumeUnit}
          isEmpty={isEmpty}
          pagination={pagination}
          getAllShipments={getAllShipments}
          loading={loading}
          drivers={drivers}
          setSnack={setSnack}
        />
      )}
      {shipments.length > 0 && tableSize === "large" && (
        <TableSize3
          shipments={shipments}
          setShipments={setShipments}
          handleRowChecked={handleRowChecked}
          handleRowExpand={handleRowExpand}
          checkedRows={checkedRows}
          handleAllChecked={handleAllChecked}
          handleDriverChange={handleDriverChange}
          cols={cols}
          pickupDrivers={pickupDrivers}
          deliveryDrivers={deliveryDrivers}
          expandedRow={expandedRow}
          weightUnit={weightUnit}
          setWeightUnit={setWeightUnit}
          volumeUnit={volumeUnit}
          setVolumeUnit={setVolumeUnit}
          isEmpty={isEmpty}
          pagination={pagination}
          getAllShipments={getAllShipments}
          loading={loading}
          drivers={drivers}
          setSnack={setSnack}
        />
      )}
      {shipments.length < 1 && loading && (
        <div className="driver__screen_loading">
          <CarrierTableLoader />
        </div>
      )}
      {shipments.length < 1 && !loading && (
        <div className="driver__screen_empty">
          <div className="driver__scrnempt_content">
            <Sad className="driver__scrnempt_contenticon" />
            <div>No Records to display!</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dispatcher;
