import React, { useEffect, useRef, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { MdClose } from "react-icons/md";
import "./UpdateVariantModal.css";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import { MdAddCircleOutline } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import { BiCloudUpload } from "react-icons/bi";
import noImage from "../../../../../assets/images/noImage.png";
const initialProductIdentifier = { marketplace: "", sku: "", slug: "" };
const UpdateVariantModal = ({
  open,
  setOpen,
  setVariants,
  variant,
  variantIndex,
  variants,
  formData,
  setFormData,
  handleVariantFieldChange,
  productSuppliers,
  editMode,
}) => {
  const [cost, setCost] = useState(0.0);
  const [supplierSections, setSupplierSections] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [productIdentifiers, setProductIdentifiers] = useState([
    initialProductIdentifier,
  ]);
  const prepopulated = useRef(false);

  const handleClose = () => {
    setOpen(false);
    setProductIdentifiers([]);
    prepopulated.current = false;
  };
  const handleIdentifierFieldChange = (name, value, index) => {
    const data = productIdentifiers;
    setProductIdentifiers(
      data?.map((item, idx) => {
        if (index === idx) {
          return { ...item, [name]: value };
        } else {
          return item;
        }
      })
    );
  };
  const handleRemoveIdentifier = (index) => {
    setProductIdentifiers((identifiers) => {
      const updatedIdentifiers = [...identifiers];
      updatedIdentifiers.splice(index, 1);
      return updatedIdentifiers;
    });
  };
  function calculateMarginPercentage(salePrice, originalCost) {
    // Check if the original cost is greater than zero to avoid division by zero
    if (originalCost > 0) {
      // Calculate the profit
      const profit = salePrice - originalCost;

      // Calculate the margin percentage
      const marginPercentage = (profit / salePrice) * 100;
      const margin = marginPercentage.toFixed(2);
      if (margin !== "NaN") {
        return margin;
      } else {
        return "-";
      }
    } else {
      // Handle the case where originalCost is zero or negative
      return "-";
    }
  }
  function calculateProfit(price, cost) {
    const profit = (parseFloat(price) - parseFloat(cost)).toFixed(2);
    if (profit === "NaN") {
      return "-";
    } else {
      return profit;
    }
  }

  const handleImageUpload = (e) => {
    const uploadedImgs = Array.from(e.target.files);
    const imageFiles = uploadedImgs.filter((file) =>
      file.type.startsWith("image/")
    );
    if (uploadedImages[0]?.file) {
      setUploadedImages((prevImages) => [
        ...prevImages,
        ...imageFiles.map((img, idx) => {
          return { media: img, is_primary: false };
        }),
      ]);
    } else {
      setUploadedImages((prevImages) => [
        ...prevImages,
        ...imageFiles.map((img, idx) => {
          return { media: img, is_primary: idx === 0 };
        }),
      ]);
    }
  };
  const handleRemoveImage = (index) => {
    if (uploadedImages[index]?.file) {
      // setFormData({
      //   ...formData,
      //   variants: variants?.map((variant, idx) => {
      //     if (variantIndex === idx) {
      //       return {
      //         ...variant,
      //         deletedMediaIds: [
      //           ...(Array.isArray(variant?.deletedMediaIds)
      //             ? variant.deletedMediaIds
      //             : []),
      //           uploadedImages[index]?.id,
      //         ],
      //       };
      //     } else {
      //       return variant;
      //     }
      //   }),
      // });
      setVariants(
        variants?.map((variant, idx) => {
          if (variantIndex === idx) {
            return {
              ...variant,
              deletedMediaIds: [
                ...(Array.isArray(variant?.deletedMediaIds)
                  ? variant.deletedMediaIds
                  : []),
                uploadedImages[index]?.id,
              ],
            };
          } else {
            return variant;
          }
        })
      );
    }
    const updatedImages = [...uploadedImages];
    updatedImages.splice(index, 1);

    // If the removed image was the main image, reset to the first image as the main image
    if (index === mainImageIndex) {
      setMainImageIndex(0);
    }

    setUploadedImages(
      updatedImages?.map((image, index) => {
        return {
          ...image,
        };
      })
    );
  };
  const handleSetMainImage = (index) => {
    setUploadedImages((prevList) =>
      prevList.map((img, idx) => {
        return { ...img, is_primary: idx === index };
      })
    );
  };
  useEffect(() => {
    setSupplierSections((sections) => {
      return sections.map((section) => {
        return {
          ...section,
          profit: calculateProfit(
            variants[variantIndex]?.sales_price,
            section?.cost
          ),
          margin: calculateMarginPercentage(
            variants[variantIndex]?.sales_price,
            section?.cost
          ),
        };
      });
    });
  }, [variants[variantIndex]?.sales_price]);
  //   useEffect(() => {
  //     setFormData({ ...formData, suppliers: supplierSections });
  //   }, [supplierSections]);
  useEffect(() => {
    if (prepopulated.current) {
      let temp = variants;
      temp[variantIndex] = {
        ...variant,
        product_identifiers: productIdentifiers,
      };
      setVariants(temp);
    }
  }, [productIdentifiers]);
  useEffect(() => {
    if (prepopulated.current) {
      let temp = variants;
      temp[variantIndex] = {
        ...variant,
        suppliers: supplierSections,
      };
      setVariants(temp);
    }
  }, [supplierSections]);
  useEffect(() => {
    if (prepopulated.current) {
      let temp = variants;
      temp[variantIndex] = {
        ...variant,
        media: uploadedImages,
      };
      setVariants([...temp]);
    }
  }, [uploadedImages]);

  useEffect(() => {
    if (
      variants &&
      variant &&
      open &&
      variantIndex !== false &&
      !prepopulated.current
    ) {
      if (variant?.product_identifiers?.length > 0) {
        setProductIdentifiers(variant?.product_identifiers);
      } else {
        setProductIdentifiers([initialProductIdentifier]);
      }
      if (variant?.media?.length > 0) {
        setUploadedImages(variant?.media);
      } else {
        setUploadedImages([]);
      }
      if (variant?.suppliers?.length > 0) {
        setSupplierSections(variant?.suppliers);
      } else {
        setSupplierSections([]);
      }
      prepopulated.current = true;
    }
  }, [variants, variant, variantIndex]);

  useEffect(() => {
    setMainImageIndex(
      variant?.media?.findIndex((image) => image?.is_primary === "true")
    );
  }, []);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <div className="addorid__updatevariant_modal">
          <MdClose
            size={22}
            className="addorid__updateattrmodal_modalclose"
            onClick={handleClose}
          />
          <div className="addorid__updateattrmodal_modalheading">
            {`${formData?.title} - ${variant?.variant ?? ""}`}
          </div>
          <div className="addorid__updateattrmodal_modalmedia">
            <label className="addprod__formsec_uploadimagebtn">
              <BiCloudUpload
                size={20}
                className="addprod__formsection_mdbtnicon"
              />
              Upload Images
              <input
                type="file"
                hidden
                multiple
                id="product_media"
                onChange={handleImageUpload}
              />
            </label>
            <div
              className="addprod__secformrow_images"
              style={{
                marginBottom: uploadedImages?.length < 1 ? "0px" : "",
              }}
            >
              {variant?.media?.map((image, index) => (
                <div key={index} className="addprod__secformrow_imagewrap">
                  <MdClose
                    className="addprod__secformrow_imageclose"
                    size={20}
                    onClick={() => handleRemoveImage(index)}
                  />
                  <img
                    src={
                      image && image.file
                        ? image.file
                        : image.media instanceof Blob
                        ? URL.createObjectURL(image.media)
                        : noImage
                    }
                    accept="image/*"
                    className="addprod__secformrow_image"
                  />
                  {![true, "true"].includes(image?.is_primary) && (
                    <button
                      className="addprod__secformimg_setmainbtn"
                      onClick={() => handleSetMainImage(index)}
                    >
                      SET MAIN
                    </button>
                  )}
                  {[true, "true"].includes(image?.is_primary) && (
                    <button className="addprod__secformimg_mainbadge">
                      MAIN IMAGE
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="addprod__custominput_flexrow">
            <div
              className="addprod__custominput_flexcol"
              style={{ minWidth: "100px" }}
            >
              <div className="addprod__custominput_box">
                <label className="addprod__custominput_title">Variant ID</label>
                <input
                  className={`addprod__custominputfield ${
                    editMode ? "disable__click_fade" : ""
                  }`}
                  type="text"
                  placeholder="Enter Variant's SKU"
                  value={variant?.code}
                  onChange={(e) =>
                    handleVariantFieldChange(variantIndex, "code", e)
                  }
                />
              </div>
            </div>
            <div
              className="addprod__custominput_flexcol"
              style={{ minWidth: "100px" }}
            >
              <div className="addprod__custominput_box">
                <label className="addprod__custominput_title">SKU</label>
                <input
                  className={`addprod__custominputfield`}
                  type="text"
                  placeholder="Enter Variant's SKU"
                  value={variant?.sku}
                  onChange={(e) =>
                    handleVariantFieldChange(variantIndex, "sku", e)
                  }
                />
              </div>
            </div>
          </div>
          <div className="addorid__updateattrmodal_mdlsubheading">
            Variant Product Dimensions
          </div>
          <div className="addprod__custominput_flexrow">
            <div
              className="addprod__custominput_flexcol"
              style={{ minWidth: "100px" }}
            >
              <div className="addprod__custominput_box">
                <label className="addprod__custominput_title">
                  Dimensions Unit
                </label>
                <CustomDropdown
                  optionObjects={[
                    { title: "Inch (in)", value: "in" },
                    { title: "Centemeter (cm)", value: "lb" },
                  ]}
                  onSelect={(option) => {
                    setVariants((vars) =>
                      vars.map((variant, idx) => {
                        if (variantIndex === idx) {
                          return { ...variant, dimension_unit: option };
                        } else {
                          return variant;
                        }
                      })
                    );
                  }}
                  selectedOption={
                    variant?.dimension_unit ?? {
                      title: "Inch (in)",
                      value: "in",
                    }
                  }
                />
              </div>
            </div>
            <div
              className="addprod__custominput_flexcol"
              style={{ minWidth: "100px" }}
            >
              <div className="addprod__custominput_box">
                <label className="addprod__custominput_title">
                  Weight Unit
                </label>
                <CustomDropdown
                  optionObjects={[
                    { title: "Kilogram (kg)", value: "kg" },
                    { title: "Pound (lb)", value: "lb" },
                  ]}
                  onSelect={(option) => {
                    setVariants((vars) =>
                      vars.map((variant, idx) => {
                        if (variantIndex === idx) {
                          return { ...variant, weight_unit: option };
                        } else {
                          return variant;
                        }
                      })
                    );
                  }}
                  selectedOption={variant?.weight_unit}
                />
              </div>
            </div>
          </div>
          <div className="addprod__custominput_flexrow">
            <div
              className="addprod__custominput_flexcol"
              style={{ minWidth: "100px" }}
            >
              <div className="addprod__custominput_box">
                <label className="addprod__custominput_title">Length</label>
                <input
                  className="addprod__custominputfield"
                  type="number"
                  placeholder="Enter Length"
                  value={variant?.length}
                  onChange={(e) =>
                    handleVariantFieldChange(variantIndex, "length", e)
                  }
                />
              </div>
            </div>
            <div
              className="addprod__custominput_flexcol"
              style={{ minWidth: "100px" }}
            >
              <div className="addprod__custominput_box">
                <label className="addprod__custominput_title">Width</label>
                <input
                  className="addprod__custominputfield"
                  type="number"
                  placeholder="Enter Width"
                  value={variant?.width}
                  onChange={(e) =>
                    handleVariantFieldChange(variantIndex, "width", e)
                  }
                />
              </div>
            </div>
            <div
              className="addprod__custominput_flexcol"
              style={{ minWidth: "100px" }}
            >
              <div className="addprod__custominput_box">
                <label className="addprod__custominput_title">Height</label>
                <input
                  className="addprod__custominputfield"
                  type="number"
                  placeholder="Enter Height"
                  value={variant?.height}
                  onChange={(e) =>
                    handleVariantFieldChange(variantIndex, "height", e)
                  }
                />
              </div>
            </div>
            <div
              className="addprod__custominput_flexcol"
              style={{ minWidth: "100px" }}
            >
              <div className="addprod__custominput_box">
                <label className="addprod__custominput_title">Weight</label>
                <input
                  className="addprod__custominputfield"
                  type="number"
                  placeholder="Enter Weight"
                  value={variant?.weight}
                  onChange={(e) =>
                    handleVariantFieldChange(variantIndex, "weight", e)
                  }
                />
              </div>
            </div>
          </div>
          <div className="addorid__updateattrmodal_mdlsubheading">
            Identifiers
          </div>
          {productIdentifiers?.map((identifier, index) => (
            <div key={index} className="addprod__custominput_flexrow">
              <div className="addprod__custominput_flexcol">
                <div className="addprod__custominput_box">
                  <label className="addprod__custominput_title">
                    Marketplace
                  </label>
                  <CustomDropdown
                    optionObjects={[
                      { id: 1, title: "Amazon" },
                      { id: 2, title: "Woocommerce" },
                      { id: 3, title: "Ebay" },
                      { id: 4, title: "Walmart" },
                      { id: 5, title: "Shopify" },
                      { id: 6, title: "Best Buy" },
                    ]}
                    onSelect={(option) => {
                      handleIdentifierFieldChange("marketplace", option, index);
                    }}
                    selectedOption={identifier.marketplace}
                  />
                </div>
              </div>
              <div className="addprod__custominput_flexcol">
                <div className="addprod__custominput_box">
                  <label className="addprod__custominput_title">SKU</label>
                  <input
                    className="addprod__custominputfield"
                    type="text"
                    placeholder="Enter SKU"
                    value={identifier.sku}
                    onChange={(e) =>
                      handleIdentifierFieldChange("sku", e.target.value, index)
                    }
                  />
                </div>
              </div>
              <div className="addprod__custominput_flexcol">
                <div className="addprod__custominput_box">
                  <label className="addprod__custominput_title">Slug</label>
                  <input
                    className="addprod__custominputfield"
                    type="text"
                    value={identifier.slug}
                    placeholder="Enter Slug"
                    onChange={(e) =>
                      handleIdentifierFieldChange("slug", e.target.value, index)
                    }
                  />
                </div>
              </div>
              {variants[variantIndex]?.product_identifiers?.length > 1 && (
                <FaRegTrashAlt
                  size={13}
                  className="addprod__idensec_delrowicn"
                  onClick={(e) => {
                    handleRemoveIdentifier(index);
                  }}
                />
              )}
            </div>
          ))}

          <button
            className="addprod__formsection_addvarbtn"
            onClick={() => {
              let maxId = 0;

              for (const obj of productIdentifiers) {
                if (obj.id > maxId) {
                  maxId = obj.id;
                }
              }
              setProductIdentifiers(() => [
                ...productIdentifiers,
                {
                  ...initialProductIdentifier,
                },
              ]);
            }}
          >
            <MdAddCircleOutline
              className="addprod__formsection_addvarbtnicn"
              size={14}
            />
            Add More
          </button>
          <div className="addorid__updateattrmodal_mdlsubheading">Pricing</div>
          <div className="addprod__custominput_flexrow">
            <div className="addprod__custominput_flexcol">
              <div className="addprod__custominput_box">
                <label className="addprod__custominput_title">
                  Regular Price
                </label>
                <input
                  className="addprod__custominputfield"
                  type="text"
                  placeholder="Enter Regular price"
                  value={variant?.regular_price ?? "0"}
                  onChange={(e) =>
                    handleVariantFieldChange(variantIndex, "regular_price", e)
                  }
                />
              </div>
            </div>
            <div className="addprod__custominput_flexcol">
              <div className="addprod__custominput_box">
                <label className="addprod__custominput_title">
                  Sales Price
                </label>
                <input
                  className="addprod__custominputfield"
                  type="text"
                  placeholder="Enter Sales price"
                  value={variants[variantIndex]?.sales_price ?? "0"}
                  onChange={(e) =>
                    handleVariantFieldChange(variantIndex, "sales_price", e)
                  }
                />
              </div>
            </div>
          </div>
          {supplierSections?.map((section, index) => (
            <div key={index} className="addprod__custominput_supcostsection">
              <span className="addprod__custominput_supcostsectionclose">
                <FaRegTrashAlt
                  size={14}
                  onClick={() => {
                    if (section.id) {
                      setFormData({
                        ...formData,
                        variants: variants?.map((variant, idx) => {
                          if (variantIndex === idx) {
                            return {
                              ...variant,
                              deletedSuppliersIds: [
                                ...variant?.deletedSuppliersIds,
                                section.id,
                              ],
                            };
                          } else {
                            return variant;
                          }
                        }),
                      });
                    }
                    setSupplierSections([
                      ...supplierSections?.filter((sec, idx) => idx !== index),
                    ]);
                  }}
                />
              </span>
              <div className="addprod__custominput_flexrow">
                <div
                  className="addprod__custominput_flexcol"
                  style={{ minWidth: "120px" }}
                >
                  <div className="addprod__custominput_box">
                    <label className="addprod__custominput_title">
                      Supplier
                    </label>
                    <CustomDropdown
                      optionObjects={productSuppliers}
                      onSelect={(option) => {
                        setSupplierSections([
                          ...supplierSections.map((sec, idx) =>
                            idx === index
                              ? { ...section, supplier: option }
                              : sec
                          ),
                        ]);
                      }}
                      selectedOption={section.supplier}
                    />
                  </div>
                </div>
                <div
                  className="addprod__custominput_flexcol"
                  style={{ minWidth: "120px" }}
                >
                  <div className="addprod__custominput_box">
                    <label className="addprod__custominput_title">
                      Cost per item
                    </label>
                    <input
                      className="addprod__custominputfield"
                      onChange={(e) => {
                        setSupplierSections([
                          ...supplierSections.map((sec, idx) =>
                            idx === index
                              ? {
                                  ...section,
                                  cost: e.target.value,
                                  profit: calculateProfit(
                                    variants[variantIndex]?.sales_price,
                                    e.target.value
                                  ),
                                  margin: calculateMarginPercentage(
                                    variants[variantIndex]?.sales_price,
                                    e.target.value
                                  ),
                                }
                              : sec
                          ),
                        ]);
                      }}
                      value={section.cost ?? ""}
                      type="text"
                      placeholder="Enter per item cost"
                    />
                  </div>
                </div>
                <div
                  className="addprod__custominput_flexcol"
                  style={{ minWidth: "120px" }}
                >
                  <div className="addprod__custominput_box">
                    <label className="addprod__custominput_title">Profit</label>
                    <input
                      className="addprod__customnoneditablefield"
                      type="text"
                      value={section.profit ?? "-"}
                    />
                  </div>
                </div>
                <div
                  className="addprod__custominput_flexcol"
                  style={{ minWidth: "120px" }}
                >
                  <div className="addprod__custominput_box">
                    <label className="addprod__custominput_title">Margin</label>
                    <input
                      className="addprod__customnoneditablefield"
                      type="text"
                      value={`${section.margin ?? "-"}${
                        section.margin > 0 || section.margin < 0 ? "%" : ""
                      }`}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
          <button
            className="addprod__formsection_addvarbtn"
            onClick={() =>
              setSupplierSections([
                ...supplierSections,
                { supplier: "", unit_cost: "", profit: "", margin: "" },
              ])
            }
          >
            <MdAddCircleOutline
              className="addprod__formsection_addvarbtnicn"
              size={14}
            />
            Add Supplier & Cost
          </button>
          <div className="add__prod_bottomsec">
            <button className="add__prod_saveprodbtn" onClick={handleClose}>
              Done
            </button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
export default UpdateVariantModal;
