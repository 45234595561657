import React, { useState } from "react";
import "./TableSize1.css";
import Empty from "../../../../../assets/images/dispatcher-empty.gif";
import { styled } from "@mui/material/styles";
import InfoIcon from "../../../../../assets/icons/dispatcher-info.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  cbmToCbf,
  lbsToKgs,
  toggleValue,
} from "../../../../../helpers/helpers";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Avatar from "../../../../../assets/images/avatar.png";
import EditIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import { changeStatus } from "../../../../../services/drivers.services";
import FileIcon from "../../../../../assets/icons/dispatcher-file.svg";
import LabelIcon from "../../../../../assets/icons/dispatcher-label.svg";
import DetailsIcon from "../../../../../assets/icons/dispatcher-details.svg";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import CarrierInnerTableLoader from "../../../../../Components/CarrierInnerTableLoader/CarrierInnerTableLoader";
import data from "../../data";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

function Loader(props) {
  return (
    <Box
      sx={{
        position: "relative",
        top: "2.5px",
        left: "10px",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 600 : 800],
        }}
        size={16}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "lightgrey" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={16}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

const TableSize1 = ({
  list,
  handleRowChecked,
  handleAllChecked,
  checkedRows,
  cols,
  isEmpty,
  setUpdateDriverModal2,
  setDeleteDriverModal,
  vehicles,
  setSnack,
  pagination,
  getAllDrivers,
  loading,
}) => {
  // const [data, setData] = useState(list);
  const [statusUpdating, setStatusUpdating] = useState(false);
  const user = useSelector((state) => state.user);
  const handlePageChange = (event, value) => {
    getAllDrivers(value);
  };
  const addDefaultSrc = (ev) => {
    ev.target.src = Avatar;
  };
  const handleStatusChange = async (e, record) => {
    // setData(
    //   data.map((item) =>
    //     item.id === record.id
    //       ? { ...item, active: e.target.checked ? "1" : "0" }
    //       : item
    //   )
    // );
    setStatusUpdating(true);
    const updated = await changeStatus(user.token, {
      record_id: parseInt(record.id),
      active: e.target.checked ? 1 : 0,
    });
    if (updated.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: updated.error.message,
          severity: "error",
        };
      });
      // setData(
      //   data.map((item) =>
      //     item.id === record.id
      //       ? { ...item, active: !e.target.checked ? "1" : "0" }
      //       : item
      //   )
      // );
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `Status of ${record.username} changed to ${
            e.target.checked ? "Active" : "Inactive"
          }`,
          severity: e.target.checked ? "success" : "info",
        };
      });
    }
    setStatusUpdating(false);
  };
  useEffect(() => {
    // setData(list);
  }, [list]);
  return (
    <div className="driver__table_size1">
      <div className="dispatcher__screen_tablewrap">
        <div className="dispscreen__table_topbar">
          Invoices
          {statusUpdating && (
            <span>
              <Loader />
            </span>
          )}
        </div>
        <div
          className="dispatcher__screen_tablecontent"
          style={{ maxHeight: 400, overflowY: "auto", height: "45vh" }}
        >
          {!isEmpty && !loading && (
            <div className="dispscreen__table_main">
              <div className="dispscreen__table_header">
                {cols[0].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      minWidth: cols[0].width,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {cols[0].name}
                  </div>
                )}
                {cols[1].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      minWidth: cols[1].width,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {cols[1].name}
                  </div>
                )}
                {cols[2].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      minWidth: cols[2].width,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {cols[2].name}
                  </div>
                )}
                {cols[3].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      minWidth: cols[3].width,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {cols[3].name}
                  </div>
                )}
                {cols[4].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      minWidth: cols[4].width,
                    }}
                  >
                    {cols[4].name}
                  </div>
                )}
                {cols[5].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      minWidth: cols[5].width,
                    }}
                  >
                    {cols[5].name}
                  </div>
                )}
                {cols[6].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      minWidth: cols[6].width,
                    }}
                  >
                    {cols[6].name}
                  </div>
                )}
                {cols[7].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      minWidth: cols[7].width,
                    }}
                  >
                    {cols[7].name}
                  </div>
                )}
                {cols[8].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      minWidth: cols[8].width,
                    }}
                  >
                    {cols[8].name}
                  </div>
                )}
                {cols[9].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      minWidth: cols[9].width,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {cols[9].name}
                  </div>
                )}
                {cols[10].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      minWidth: cols[10].width,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {cols[10].name}
                  </div>
                )}
                {cols[11].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      minWidth: cols[11].width,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {cols[11].name}
                  </div>
                )}
              </div>
              {data?.map((item) => (
                <div className="dispscreen__table_row">
                  <div className="dispscreen__table_innerrow">
                    {cols[0].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          minWidth: cols[0].width,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {item.id}
                      </div>
                    )}
                    {cols[1].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          minWidth: cols[1].width,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {item.master_tracking}
                      </div>
                    )}
                    {cols[2].checked && (
                      <div
                        className={`dispscreen__theader_data `}
                        style={{
                          minWidth: cols[2].width,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {item.carrier_tracking}
                      </div>
                    )}
                    {cols[3].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          minWidth: cols[3].width,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={
                            item.picture
                              ? `${process.env.REACT_APP_IMG_URL}${item.picture}`
                              : Avatar
                          }
                          onError={addDefaultSrc}
                          className="dispscreen__theader_driverprofile"
                        />
                      </div>
                    )}
                    {cols[4].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          minWidth: cols[4].width,
                        }}
                      >
                        {item.reference_id}
                      </div>
                    )}
                    {cols[5].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          minWidth: cols[5].width,
                        }}
                      >
                        {item.pickup_date}
                      </div>
                    )}
                    {cols[6].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          minWidth: cols[6].width,
                        }}
                      >
                        {item.delivery_date}
                      </div>
                    )}
                    {cols[7].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          minWidth: cols[7].width,
                        }}
                      >
                        {item.status}
                      </div>
                    )}
                    {cols[8].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          minWidth: cols[8].width,
                        }}
                      >
                        {item.total}
                      </div>
                    )}
                    {cols[9].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          minWidth: cols[9].width,
                        }}
                      >
                        <div className="dispscreen__thdata_actions">
                          <LightTooltip placement="top" arrow title="Invoice">
                            <div className="dispscreen__thdata_action">
                              <img src={FileIcon} />
                            </div>
                          </LightTooltip>
                          <LightTooltip placement="top" arrow title="Label">
                            <div className="dispscreen__thdata_action">
                              <img src={LabelIcon} />
                            </div>
                          </LightTooltip>
                          <LightTooltip placement="top" arrow title="Details">
                            <div className="dispscreen__thdata_action">
                              <img src={DetailsIcon} />
                            </div>
                          </LightTooltip>
                        </div>
                      </div>
                    )}
                    {cols[10].checked && (
                      <div
                        className="dispscreen__theader_data dispscreen__theader_dataedit"
                        style={{
                          minWidth: cols[10].width,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <EditIcon
                          style={{ fontSize: "20px" }}
                          onClick={() => setUpdateDriverModal2(item)}
                        />
                      </div>
                    )}
                    {cols[11].checked && (
                      <div
                        className="dispscreen__theader_data dispscreen__theader_datadelete"
                        style={{
                          minWidth: cols[11].width,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <DeleteIcon
                          style={{ fontSize: "25px" }}
                          onClick={() => setDeleteDriverModal(item.id)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
          {/* {isEmpty && (
            <div className="dispscreen__table_placeholder">
              <img
                src={Empty}
                alt="Nothing to show"
                className="dispscreen__table_emptimg"
              />
            </div>
          )} */}
          {loading && (
            <div className="driver__screen_tableloading">
              <CarrierInnerTableLoader />
            </div>
          )}
        </div>

        {!isEmpty && (
          <div className="pagination__wrap">
            <Stack spacing={2}>
              <Pagination
                count={pagination.last_page}
                page={pagination.current_page}
                onChange={handlePageChange}
              />
            </Stack>
          </div>
        )}
      </div>
    </div>
  );
};

export default TableSize1;
