import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { IoInformationCircleOutline } from "react-icons/io5";
import Tooltips from "../../../../../../Components/Tooltip/Tooltip";

export default function Buttons(props) {
  const {
    List,
    Name,
    HandleChange,
    Content = Name,
    Tooltip = false,
    Title = "",
    Variant = List[Name] ? "contained" : "outlined",
    Color = List[Name] ? "primary" : "inherit",
    TYPE = "button",
    Disabled = false,
  } = props;

  return (
    <>
      {TYPE === "button" && (
        <span>
          <Button
            variant={Variant}
            color={Color}
            name={Name}
            id={Name}
            size="small"
            style={{ borderColor: "#ced4da", borderRadius: "3px" }}
            onClick={(e) => HandleChange(e)}
            disableElevation
          >
            {Content}
            {Tooltip === true && (
              <Tooltips title={Title}>
                <IconButton>
                  <IoInformationCircleOutline size={15} />
                </IconButton>
              </Tooltips>
            )}
          </Button>
        </span>
      )}
      {TYPE === "submit" && (
        <Button
          variant={Variant}
          color={Color}
          name={Name}
          id={Name}
          size="small"
          style={{ borderColor: "#0c69f3", borderRadius: "3px" }}
          type="submit"
          disableElevation
        >
          {Content}
          {Tooltip === true && (
            <Tooltips title={Title}>
              <IconButton>
                <IoInformationCircleOutline size={15} />
              </IconButton>
            </Tooltips>
          )}
        </Button>
      )}
      {TYPE === "quote" && (
        <Button
          variant={Variant}
          color={Color}
          name={Name}
          id={Name}
          size="small"
          style={{
            borderColor: "#0c69f3",
            borderRadius: "3px",
            minWidth: "80%",
          }}
          type="submit"
          disableElevation
        >
          {Content}
          {Tooltip === true && (
            <Tooltips title={Title}>
              <IconButton>
                <IoInformationCircleOutline size={15} />
              </IconButton>
            </Tooltips>
          )}
        </Button>
      )}
      {TYPE === "notavailable" && (
        <Tooltips title="Available Soon">
          <span>
            <Button
              variant={Variant}
              color={Color}
              name={Name}
              id={Name}
              size="small"
              style={{
                borderColor: "#ced4da",
                borderRadius: "3px",
              }}
              // onClick={(e) => HandleChange(e)}
              disabled
              disableElevation
            >
              {Content}
            </Button>
          </span>
        </Tooltips>
      )}
      {TYPE === "services" && (
        <span>
          <Button
            variant={Variant}
            color={Color}
            name={Name}
            id={Name}
            size="small"
            style={{
              borderColor: "#ced4da",
              borderRadius: "3px",
              marginRight: "16px",
              marginBottom: "12px",
            }}
            onClick={(e) => HandleChange(e)}
            disableElevation
            disabled={Disabled}
            className={List[Name] ? "serviceBtnActive" : "serviceBtnNotsActive"}
          >
            {Content}
            {Tooltip === true && (
              <Tooltips title={Title}>
                <IconButton>
                  <IoInformationCircleOutline size={15} />
                </IconButton>
              </Tooltips>
            )}
          </Button>
        </span>
      )}
      {TYPE === "servicesnotavailable" && (
        <Tooltips title="Available Soon">
          <span>
            <Button
              variant={Variant}
              color={Color}
              name={Name}
              id={Name}
              size="small"
              style={{
                borderColor: "#ced4da",
                borderRadius: "3px",
                marginRight: "16px",
                marginBottom: "12px",
              }}
              // onClick={(e) => HandleChange(e)}
              disabled
              disableElevation
              className={List[Name] ? "serviceBtnActive" : "serviceBtnNotsActive"}
            >
              {Content}
            </Button>
          </span>
        </Tooltips>
      )}
    </>
  );
}
