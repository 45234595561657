import React, { useState } from "react";
import "./Companies.css";

// components imports
import CarriersTable from "../../../Components/BoltTable/BoltTable";
import Snackbar from "../../../Components/SnackBar/SnackBar";
import Tooltip from "../../../Components/Tooltip/Tooltip";
import SwitchButton from "../../../Components/SwitchButton/SwitchButton";
import EditCompany from "./Components/EditCompany/EditCompany";
import ManageServices from "./Components/ManageServices/ManageServices";

// apis imports
import {
  fetchShippingCompanies,
  updateShippingCompanyStatus,
} from "../../../services/AdminServices/Companies/companies.services";

// library imports
import PlaceholderImage from "../../../assets/images/placeholder-image.png";
import { MdOutlineEditCalendar } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";

const Companies = () => {
  const [editCompanyModal, setEditCompanyModal] = useState();
  const [manageServicesModal, setManageServicesModal] = useState();
  const [loader, setLoader] = useState(false);
  const [shippingCompanies, setShippingCompanies] = useState([]);

  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  const UpdateCompanyStatus = async (check, id) => {
    setLoader("status");
    const res = await updateShippingCompanyStatus(
      { is_enable: `${check}` },
      id
    );
    if (res.error) {
      setLoader(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    } else {
      setShippingCompanies((prevShippingCompanies) => {
        return {
          ...prevShippingCompanies,
          data: prevShippingCompanies?.data.map((company) => {
            return company.id === id
              ? { ...company, is_enable: `${check}` }
              : company;
          }),
        };
      });
      setLoader(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Status Updated Successfully",
          severity: "success",
        };
      });
    }
  };

  const getAllShippingCompanies = async (page, perPage, filter) => {
    const response = await fetchShippingCompanies(page, perPage, filter);
    if (!response.error) {
      setShippingCompanies(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 1,
      label: "Status",
      render: (row) => (
        <>
          <SwitchButton
            checked={[true, "true", "1"].includes(row.is_enable)}
            onChange={(e, value) => UpdateCompanyStatus(value, row.id)}
            disabled={loader === "status" ? true : false}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 2,
      label: "Name",
      render: (row) => <>{row?.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Logo",
      render: (row) => (
        <>
          <img
            src={row.logo_url}
            onError={(e) => {
              e.target.src = PlaceholderImage;
            }}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 4,
      label: "Sandbox URL",
      render: (row) => (
        <div className="companies_text_blur">{row?.api_url ?? "-"}</div>
      ),
      width: "200px",
    },
    {
      id: 5,
      label: "Production URL",
      render: (row) => (
        <div className="companies_text_blur">{row?.production_url ?? "-"}</div>
      ),
      width: "200px",
    },
    {
      id: 6,
      label: "Username",
      render: (row) => (
        <div className="companies_text_blur">{row?.user_name ?? "-"}</div>
      ),
      width: "200px",
    },
    {
      id: 7,
      label: "Password",
      render: (row) => (
        <div className="companies_text_blur">{row?.password ?? "-"}</div>
      ),
      width: "200px",
    },
    {
      id: 8,
      label: "Account No",
      render: (row) => (
        <div className="companies_text_blur">{row?.account_no ?? "-"}</div>
      ),
      width: "200px",
    },
    {
      id: 6,
      label: "Actions",
      render: (row) => (
        <div className="table_action__wrap">
          <Tooltip title="Manage Services">
            <div
              className="table_action__btn"
              onClick={() => {
                setManageServicesModal(row);
              }}
            >
              <MdOutlineEditCalendar size={10} />
              Services
            </div>
          </Tooltip>
          <Tooltip title="Edit Company">
            <div
              className="table_action__btn"
              onClick={() => {
                setEditCompanyModal(row);
              }}
            >
              <FaRegEdit size={10} />
              Edit
            </div>
          </Tooltip>
        </div>
      ),
      width: "200px",
      fixed: "right",
    },
  ];

  return (
    <div className="dispatcher__screen" style={{ minWidth: "100%" }}>
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <div className="companies_header">
        <div>
          <span className="companies_heading">Carriers List</span>
        </div>
      </div>
      <EditCompany
        open={editCompanyModal}
        setOpen={setEditCompanyModal}
        setShippingCompanies={setShippingCompanies}
        previousData={editCompanyModal}
        setSnack={setSnack}
      />
      <ManageServices
        open={manageServicesModal}
        setOpen={setManageServicesModal}
        carrier={manageServicesModal}
        setShippingCompanies={setShippingCompanies}
        Android12Switch={SwitchButton}
        setSnack={setSnack}
      />

      <CarriersTable
        message={"No Carriers Available To Display!"}
        searchPlaceHolder="Search Carriers"
        columns={columns}
        data={shippingCompanies}
        getAllData={getAllShippingCompanies}
      />
    </div>
  );
};

export default Companies;
