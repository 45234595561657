import React from "react";
import "../../InvoiceModal.css";

const Tracking = ({ data }) => {
  return (
    <>
      <div className="invmodal__shipment_details_wrap">
        <div className="invmodal__shipment_detailshding">Shipment Tracking</div>
        <div className="invmodal__shshiptracking_grid">
          <div className="invmodal__shdetails_gridhd">Shipment Status</div>
          <div className="invmodal__shdetails_gridhd">Status Date</div>
          <div className="invmodal__shdetails_gridhd">Status Time</div>
          <div className="invmodal__shdetails_gridhd">Last Location</div>
          {data?.shipment_histories?.map((history) => {
            return (
              <>
                <div className="invmodal__shdetails_griditem">
                  {history?.ShipmentStatus ?? "-"}
                </div>
                <div className="invmodal__shdetails_griditem">
                  {history?.StatusDate?.slice(0, 10) ?? "-"}
                </div>
                <div className="invmodal__shdetails_griditem">
                  {" "}
                  {history?.StatusDate?.slice(11, 16) ?? "-"}
                </div>
                <div className="invmodal__shdetails_griditem">
                  {" "}
                  {history?.LastLocation ?? "-"}
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Tracking;
