import React, { useState } from "react";
import "./ResetPassword.css";
import Logo from "../../assets/images/logo_login.png";
import bgTab from "../../assets/images/login_tab_bg.png";
import bg from "../../assets/images/logistic.png";
import { Link, useNavigate } from "react-router-dom";
import { generateCode } from "../../services/auth.services";
import { ReactComponent as Envelop } from "../../assets/icons/envelop.svg";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GetCode = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ email: "" });
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    text: "",
    severity: "",
  });
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await generateCode(data);
      if (res.error) {
        if (res.errorMessage) {
          setSnack({
            ...snack,
            open: true,
            text: res.errorMessage,
            severity: "error",
          });
        } else {
          setSnack({
            ...snack,
            open: true,
            text: "Something went wrong!",
            severity: "error",
          });
        }
      } else {
        navigate("/reset-password");
      }
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        text: "Something went wrong!",
        severity: "error",
      });
    }
    setLoading(false);
  };
  const handleSnackClose = () => {
    setSnack({ ...snack, open: false });
  };
  return (
    <div className="reset__password_screen" style={ { backgroundImage: `url(${bg})`, backgroundColor: `#f7f7f7` } }>
      <Snackbar
        open={snack.open}
        autoHideDuration={3000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.text}
        </Alert>
      </Snackbar>
     <div className="resetp__main_wrapper">
      <div className="resetp__left_col">
        <div className="resetp__screen_logowrap">
          <img
            src={Logo}
            alt=""
            className="resetp__sheader_image"
            onClick={() => {
              window.location.href = "https://www.hajexbolt.com";
            }}
          />
        </div>
        <div className="resetp__tab_left_image">
          <img src={bgTab} alt=""/>
        </div>
      </div>
      <div className="resetp__right_col">
        <div className="resetp__screen_formwrap">
          <div className="resetps__formwrap_title">
            Enter the email address associated with your account and we'll send
            you a code to reset your password.
          </div>
          <form className="resetp__screen_form" onSubmit={handleSubmit}>
            <label className="resetp__screen_formlabel">Email</label>
            <div className="resetp__screen_formfield">
            <Envelop />
            <input
              type="email"
              name="email"
              className="resetp__screen_formfield_field"
              placeholder="Enter Email"
              value={data.email}
              onChange={handleChange}
            />
            </div>
            <button type="submit" className="resetp__screen_formbtn">
              {!loading ? "Get Code" : "Sending code..."}
            </button>
            <Link className="resetp__screen_formlink" to="/register">
              Don't have an account? <span>Signup</span> .
            </Link>
          </form>
        </div>
      </div>
     </div>
    </div>
  );
};

export default GetCode;
