import React, { useState, useEffect, useRef } from "react";

// components imports
import Loader from "../../../../Components/CircularLoader/CircularLoader";
// apis imports
import {
  addCountStock,
  getCategorySubCategoryData,
} from "../../../../services/warehouse.services";
// library imports
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Buttons from "@mui/material/Button";
import { useSelector } from "react-redux";

const AddStockCount = ({
  open,
  stockAddingHandleClose,
  setRefresh,
  // approvedWareHouses,
  brandList,
  categoryList,
  shippersList,
  setSnack,
}) => {
  const user = useSelector((state) => state.user);

  const [clientId, setClientId] = useState("");
  const [loader, setloader] = useState(false);

  const [data, setData] = useState({
    brandsList: [],
    categoriesList: [],
    subCategoriesList: [],
  });

  const [formData, setFormData] = useState({
    warehouse_id: user.warehouse_id,
    brand_ids: [],
    category_ids: [],
    sub_category_ids: [],
  });

  const handleModalClose = () => {
    stockAddingHandleClose();
    setClientId("");
    setData({
      brandsList: [],
      categoriesList: [],
      subCategoriesList: [],
    });
    setFormData({
      warehouse_id: "",
      brand_ids: [],
      category_ids: [],
      sub_category_ids: [],
    });
  };

  const handelChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name === "category_ids") {
      let c_list = data.categoriesList.filter((c) =>
        e.target.value.includes(c.id)
      );
      let sub_list = c_list.map((c) => c.subcategory).flat();
      setData({ ...data, subCategoriesList: sub_list });
    }
  };

  const handelChangeClient = (e) => {
    setClientId(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setloader(true);
      const response = await addCountStock("user.warehouse_id", {
        brand_ids: formData.brand_ids,
        category_ids: formData.category_ids,
        sub_category_ids: formData.sub_category_ids,
      });
      if (response.error) {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Something went wrong",
            severity: "error",
          };
        });
        setloader(false);
      } else {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: response?.data?.message,
            severity: "success",
          };
        });
        setRefresh(Math.floor(Math.random() * 100) + 1);
        setloader(false);
        handleModalClose();
      }
    } catch (error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: error.response.data.message,
          severity: "error",
          vertical: "top",
          horizontal: "right",
        };
      });
    }
  };

  useEffect(() => {
    if (clientId != "") {
      fetchCategorySubCategoryData();
    }
  }, [clientId]);

  useEffect(() => {
    if (!user?.warehouse_id) {
      setData({
        ...data,
        brandsList: brandList,
        categoriesList: categoryList,
      });
    }
  }, [brandList, categoryList]);

  const fetchCategorySubCategoryData = async (page, perPage) => {
    const response = await getCategorySubCategoryData(clientId);
    if (!response.error) {
      setData({
        ...data,
        brandsList: response?.data?.result?.brands,
        categoriesList: response?.data?.result?.categories,
        // subCategoriesList: [],
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={stockAddingHandleClose}
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit}>
        <div className="addStockCount__desc_modal">
          <div className="addStockCount__desc_modalheading">Count Stock</div>
          <div className="addStockCount__desc_modalcontent">
            {/* {!user.warehouse_id && (
              <FormControl variant="outlined">
                <InputLabel id="addStockCountWarehouse" size="small">
                  Warehouse *
                </InputLabel>
                <Select
                  labelId="addStockCountWarehouse"
                  id="addStockCountWarehouse"
                  size="small"
                  label="Warehouse *"
                  name="warehouse_id"
                  onChange={handelChange}
                  required
                >
                  {approvedWareHouses?.map((warehouse, index) => (
                    <MenuItem key={index} value={warehouse?.warehouse?.id}>
                      {warehouse?.warehouse?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )} */}
            {/* {user.warehouse_id && ( */}
            <FormControl variant="outlined">
              <InputLabel id="addStockCountWarehouse" size="small">
                Clients *
              </InputLabel>
              <Select
                labelId="addStockCountWarehouse"
                id="addStockCountWarehouse"
                size="small"
                label="Clients *"
                name="client_ids"
                onChange={handelChangeClient}
                required
              >
                {shippersList?.map((shipper, index) => (
                  <MenuItem key={index} value={shipper?.id}>
                    {shipper?.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* )} */}

            <FormControl variant="outlined">
              <InputLabel id="addStockCountBrandName" size="small">
                Brand Name
              </InputLabel>
              <Select
                labelId="addStockCountBrandName"
                id="addStockCountBrandName"
                value={formData?.brand_ids}
                label="Brand Name *"
                onChange={handelChange}
                size="small"
                name="brand_ids"
                multiple
                // required
              >
                {data?.brandsList?.map((brand, index) => (
                  <MenuItem key={index} value={brand.id}>
                    {brand?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined">
              <InputLabel id="addStockCountCategories" size="small">
                Categories
              </InputLabel>
              <Select
                labelId="addStockCountCategories"
                id="addStockCountCategories"
                size="small"
                value={formData.category_ids}
                label="Categories *"
                onChange={handelChange}
                name="category_ids"
                multiple
                // required
              >
                {data?.categoriesList?.map((category, index) => (
                  <MenuItem key={index} value={category.id}>
                    {category?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined">
              <InputLabel id="addStockCountSubCategories" size="small">
                Sub Categories
              </InputLabel>
              <Select
                labelId="addStockCountSubCategories"
                id="addStockCountCategories"
                size="small"
                value={formData.sub_category_ids}
                label="Sub Categories *"
                onChange={handelChange}
                name="sub_category_ids"
                multiple
                // required
              >
                {data?.subCategoriesList?.map((subcategory) => (
                  <MenuItem key={subcategory.id} value={subcategory.id}>
                    {subcategory.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="addStockCount__desc_modalfooter">
            <Buttons
              type="button"
              variant="contained"
              color="error"
              onClick={handleModalClose}
              size="small"
              disabled={loader}
            >
              Cancel
            </Buttons>
            <Buttons
              type="submit"
              variant="contained"
              size="small"
              style={{ marginLeft: "5px" }}
              disabled={loader}
            >
              {loader ? <Loader /> : "Submit"}
            </Buttons>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AddStockCount;
