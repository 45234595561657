import React, { useEffect, useState } from "react";
import "./ManageServices.css";

// components imports
import { updateServiceStatus } from "../../../../../../../services/ShipperServices/Carriers/carriers.services";
// apis imports
// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import { FaRegTimesCircle } from "react-icons/fa";

export default function ManageServices({
  open,
  setOpen,
  Android12Switch,
  setCarriers,
  setSnack,
}) {
  const [data, setData] = useState({});
  const handleClose = () => {
    setOpen(false);
    setData({});
  };
  const handleServiceChange = async (e, service) => {
    const response = await updateServiceStatus(
      { [service]: e.target.checked },
      data?.id
    );
    if (!response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `${service} service ${
            !e.target.checked ? "enabled" : "disabled"
          } for ${data.name ?? "this carrier"}`,
          severity: !e.target.checked ? "success" : "info",
        };
      });
      setCarriers((prevState) => {
        return {
          ...prevState,
          data: prevState?.data.map((carr) => {
            console.log(carr.id === data.id);
            if (carr.id === data.id) {
              return {
                ...data,
                services: response?.data?.result?.services,
              };
            } else {
              return carr;
            }
          }),
        };
      });
      setData((prevState) => {
        return {
          ...prevState,
          services: response?.data?.result?.services,
        };
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `Something went wrong!`,
          severity: "error",
        };
      });
    }
  };
  useEffect(() => {
    setData(open);
  }, [open]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="manage__services_modal">
          <div className="manage__services_closewrap" onClick={handleClose}>
            <IconButton>
              <FaRegTimesCircle size={25} color="lightgrey" />
            </IconButton>
          </div>
          <div className="manage__services_modalheader">
            <h2>
              <span className="manage__services_mhheadingcarr">
                {open?.name ? `${open?.name} ` : ""}
              </span>
              Services
            </h2>
            <img
              className="manage__services_modalhdrimg"
              src={data?.shipping_company_for_user?.logo_url}
            />
          </div>
          <div className="manage__services_modalcontent">
            <div className="manage__services_srvcsgrid">
              {data?.services?.user &&
                Object.entries(data?.services?.user)?.map(
                  ([service, status]) => {
                    return (
                      <div>
                        <div className="manage__services_srvcsgitem">
                          <Android12Switch
                            onChange={(e) => handleServiceChange(e, service)}
                            checked={status}
                          />
                          <span className="manage__services_srvcsgitxt">
                            {service.toUpperCase()}
                          </span>
                        </div>
                      </div>
                    );
                  }
                )}
            </div>
            {(data?.services?.length < 1 || !data?.services) && (
              <div className="manage__services_srvcsempt">
                No Services Available!
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
