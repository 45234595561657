import React, { useState } from "react";
import "./addCategory.css";

// components imports
import Loader from "../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import { addCategory } from "../../../../../services/warehouse.services";
// library imports
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { showSnackBar } from "../../../../../store/common/snackBarSlice";
import { useDispatch } from "react-redux";

const AddCategory = ({ open, setOpen, setRefresh }) => {
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
  });
  const dispatch = useDispatch();

  const handelChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (formData.name === "") {
      dispatch(
        showSnackBar({
          text: "Please enter category name",
          severity: "error",
        })
      );
      setLoading(false);
      return;
    }

    const Formdata = new FormData();
    Formdata.append("name", formData.name);

    const response = await addCategory(Formdata);
    // console.log("response", response.data.result);
    if (response.error) {
      dispatch(
        showSnackBar({
          text:
            response?.error?.response?.data?.message || "Something went wrong",
          severity: "error",
        })
      );
      setLoading(false);
    } else {
      dispatch(
        showSnackBar({
          text: "Category Added Successfully",
          severity: "success",
        })
      );

      setRefresh(Math.floor(Math.random() * 100) + 1);
      setLoading(false);
      setFormData({ name: "" });
      setOpen(false);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen()}
        aria-labelledby="add-currency-modal-title"
        aria-describedby="add-currency-modal-description"
      >
        <div className="categoryPop__add_new_category_modal">
          <div className="categoryPop__add_new_category_modal_wrap">
            <div className="categoryPop__add_new_currency">
              <div className="categoryPop__add_new_category_header">
                <h3 className="categoryPop__add_new_category_title">
                  Add Category
                </h3>
              </div>
              <div className="categoryPop__add_new_category_body">
                <FormControl variant="outlined" className="">
                  <InputLabel htmlFor="addCategoryName" size="small">
                    Category Name
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    id="addCategoryName"
                    name="name"
                    value={formData.name}
                    size="small"
                    onChange={(e) => handelChange(e)}
                    label="Category Name"
                  />
                </FormControl>

                {/* <button
                  className="Category__add_new_category_create_btn"
                  onClick={handleSubmit}
                >
                  Save
                </button> */}
                <div className="categoryPop__add_new_category_modalfooter">
                  <div
                    className="categoryPop__add_new_category_modalreset"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </div>
                  <div
                    className="categoryPop__add_new_category_modalsubmit"
                    onClick={handleSubmit}
                  >
                    {loading ? <Loader /> : "Save"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddCategory;
