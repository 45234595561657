import React, { useEffect } from 'react'
import GoogleMapReact from "google-map-react";

const LoadMap = () => {

    return (
        <GoogleMapReact
            bootstrapURLKeys={{
                key: "AIzaSyC8NLz7EN8o0S_eZhwVDunyl0ut7o7vehI&libraries=drawing,places,geometry&v=weekly",
            }}
            options={{
                gestureHandling: "greedy",
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => console.log(map, maps)}
            libraries={["drawing", "places", "geometry"]}
        >
        </GoogleMapReact>
    )
}

export default LoadMap