import React from "react";
import "./Requirements.css";
const LoomisRequirements = () => {
  return (
    <div className="cuscarrier__reqrmnts_box">
      These are some requirements for Loomis
    </div>
  );
};

export default LoomisRequirements;
