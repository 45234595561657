import axios from "./axiosInstance";

export const fetchAllProducts = async (token, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`company/product`, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const updateAccountName = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`user-shipping-companies/edit`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const deleteCarrier = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`user-shipping-companies/delete`, {
      record_id: id,
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const addCustomCarrier = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`user-shipping-companies/store`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
// GRN
export const getWareHouseList = async () => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`get-all-warehouse`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const getUserWarehouses = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`warehouse/get-user-warehouses`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const getUserWarehousesByWar_Id = async (id, page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `userWarehouse/get-user-warehouses/${id}?perPage=${perPage}&page=${page}&filterValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const getRequestWarehouses = async () => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`warehouse/get-user-warehouses`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const sendWareHouseRequest = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`userWarehouse/send-request/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const approveWareHouseRequest = async (id, flag) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`userWarehouse/approve-request/${id}/${flag}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const deleteWareHouseRequest = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`warehouse/delete-user-warehouse/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
// export const getSuppliers = async (id) => {
//   let response = { data: null, error: null };
//   try {
//     const res = await axios.get(`supplier`);
//     response = { ...response, data: res.data };
//   } catch (error) {
//     response = { ...response, error: error };
//   }
//   return response;
// };
export const addGRNByForm = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`grn`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const getGRNByID = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`grn/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const manageWarehouseGRN = async (id, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`warehouse/grn/manage-quantity/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const editGRNByForm = async (id, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`grn/update/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const deleteGRN = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.delete(`grn/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

// stock count
export const addCountStock = async (id, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`stock-count/create/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateCountStock = async (id, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`stock-count/import-file/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const importCountStockFile = async (id, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`stock-count/import-file/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const getCountStock = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `stock-count/get-stock-count?perPage=${perPage}&page=${page}&filterValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
// brands
export const addBrand = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`product/shipper-brands/store`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const editBrand = async (id, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`product/shipper-brands/update/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const getBrandsList = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `product/shipper-brands/get?perPage=${perPage}&page=${page}&filterValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const deleteBrand = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.delete(`product/shipper-brands/delete/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const addTransferNote = async (from, to, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `warehouse/transfer-quantity/${from}/${to}`,
      data
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const getAllTransferNotesList = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `warehouse/get-transfer-notes?perPage=${perPage}&page=${page}&filterValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const getWarehouseShippers = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`warehouse/get-shippers/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const getCategorySubCategoryData = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`shipper-user/get-data/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

// category tab
export const getAllCategoryList = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `category?perPage=${perPage}&page=${page}&filterValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const addCategory = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`category/store`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const deleteCategory = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.delete(`category/delete/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateCategory = async (id, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`category/update/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const addSubCategory = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`category/sub-category/create`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateSubCategory = async (id, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`category/sub-category/update/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const deleteSubCategory = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`category/sub-category/delete/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

// fulfilment tier
export const getFulfilmentTierFormula = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`warehouse/formula/get/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const addFulfilmentTierFormula = async (id, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`warehouse/formula/create/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateFulfilmentTierFormula = async (id, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`warehouse/formula/update/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchAllWarehouseOrders = async (
  page,
  perPage,
  filter,
  warehouse,
  Nested
) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `order/get-by-warehouse/${warehouse}?perPage=${perPage}&page=${page}&filterValue=${filter}${Nested}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
// suppliers tab
export const getAllSuppliersList = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `supplier/get-paginated?perPage=${perPage}&page=${page}&filterValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const deleteSupplier = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.delete(`supplier/delete/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateSupplier = async (id, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`supplier/update/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const addSupplier = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`supplier/create`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchStockRecNotesHistory = async (grnId) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`/grn/get-notes/${grnId}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const createStockRecNote = async (grnId, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`grn/save-grn-notes/${grnId}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
