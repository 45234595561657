import React from "react";
import Colours from "../../data/colors";
import Check from "@mui/icons-material/CheckCircleOutline";
import "./ColorBox.css";
const ColorBox = ({ color, handleColorChange }) => {
  const handleClose = () => {};
  const handleSelect = (clr) => {
    handleColorChange(clr);
  };
  return (
    <div className="color_box_wrap">
      {Colours.map((clr, index) => (
        <div
          className="zncb_wrap_color"
          style={{ background: clr, outline: `2px solid ${clr}` }}
          key={index + 1}
          onClick={() => handleSelect(clr)}
        >
          {/* {clr === color && <Check sx={{ fill: "white" }} />} */}
        </div>
      ))}
    </div>
  );
};

export default ColorBox;
