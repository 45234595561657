import React from "react";
import "./Requirements.css";

const XPORequirements = () => {
  return (
    <div className="cuscarrier__reqrmnts_box">
      Contact XPO
      {/* at
      <a href={`mailto:  XYZ@XOP.com`} className="email_space">
        XYZ@XOP.com
      </a> */}
      to obtain your User Name , Password , Account Number , Bearer Token. These
      API credentials will allow you to connect your custom XPO Transport
      account to the application, enabling you to access XPO Transport shipping
      rates for your account and display them as quotes.
    </div>
  );
};

export default XPORequirements;
