import React, { useState, useEffect } from "react";
import "./approvedpaymentlist.css";

// components imports
import ApprovedTransectionTable from "../../../../../../../Components/BoltTable/BoltTable";
import AddBalance from "./AddBalance/AddBalance";
import DetailsModal from "../../../../../../Warehouse/WarehouseOrders/Components/OrderDetails/DetailsModal";
// apis imports
import { fetchLtlTransactions } from "../../../../../../../services/ShipperServices/Payments/payments.services";
import { getUserWarehousesByWar_Id } from "../../../../../../../services/warehouse.services";
import { removeUnderscoresAndCapitalize } from "../../../../../../../helpers/helpers";

// library imports
import { useSelector } from "react-redux";
import { MdOutlineStickyNote2 } from "react-icons/md";
import {
  FaPlus,
  FaArrowAltCircleUp,
  FaArrowAltCircleDown,
} from "react-icons/fa";

import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import IconButton from "@mui/material/IconButton";
import Tooltip from "../../../../../../../Components/Tooltip/Tooltip";

const LtlApprovedPaymentList = ({ setSnack }) => {
  const user = useSelector((state) => state.user);

  const [transactions, setTransactions] = useState([]);
  const [clients, setClients] = useState([]);
  const [details, setDetails] = useState(false);

  const [detailModal, setdetailModal] = useState(false);
  const [addBalanceModal, setAddBalanceModal] = useState();
  const [refresh, setRefresh] = useState();

  const getAllShippersTransactions = async (page, perPage, filter) => {
    const response = await fetchLtlTransactions(page, perPage, filter);
    if (!response.error) {
      setTransactions(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const getAllWarehouseClients = async () => {
    const response = await getUserWarehousesByWar_Id(
      user?.warehouse_id,
      1,
      50,
      ""
    );
    if (!response.error) {
      setClients(response?.data?.result?.data);
    }
  };

  function getLtlbalance() {
    const totalBalance = user?.user_approved_warehouses?.reduce(
      (total, current) => total + Number(current.balance),
      0
    );

    return totalBalance ? totalBalance.toFixed(2) : "0.00";
  }

  function getUserbalance() {
    const totalBalance = clients?.reduce((total, current) => {
      const balance = Number(current.balance);
      // Adjust balance sign
      const adjustedBalance = balance >= 0 ? -balance : Math.abs(balance);
      return total + adjustedBalance;
    }, 0);
    return totalBalance ? totalBalance.toFixed(2) : "0.00";
  }

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "130px",
    },
    {
      id: 1,
      label: "Transaction ID",
      field_name: "transaction_no",
      render: (row) => <>{row?.transaction_no ?? "-"}</>,
      width: "130px",
    },
    {
      id: 2,
      label: "Date",
      field_name: "date",
      render: (row) => <>{row.created_at?.slice(0, 10) ?? "-"}</>,
      width: "140px",
    },
    {
      id: 3,
      label: "Method",
      field_name: "method",
      render: (row) => (
        <>
          {row.transaction_type
            ? removeUnderscoresAndCapitalize(row.transaction_type)
            : "-"}
        </>
      ),
      width: "200px",
    },
    {
      id: 4,
      label: "Payment Type",
      field_name: "payment_type",
      render: (row) => (
        <>
          {row.payment_type
            ? removeUnderscoresAndCapitalize(row.payment_type)
            : "-"}
        </>
      ),
      width: "200px",
    },
    {
      id: 5,
      label: "Order ID",
      field_name: "order_id",
      render: (row) => <>{row?.order_id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 6,
      label: <>{user?.role === "warehouseUser" ? "User" : "Warehouse"}</>,
      field_name: "warehouse_name",
      render: (row) => (
        <>
          {user?.role === "warehouseUser"
            ? row?.user?.username ?? "-"
            : row?.warehouse?.name ?? "-"}
        </>
      ),
      width: "200px",
    },
    {
      id: 7,
      label: "Amount",
      field_name: "amount",
      render: (row) => (
        <div
          style={{
            color: row?.transaction_type?.startsWith("credit")
              ? "teal"
              : "tomato",
            display: "flex",
          }}
        >
          <>
            {row.amount ?? "-"}
            {row?.transaction_type?.startsWith("credit") ? (
              <FaArrowAltCircleUp
                style={{ marginTop: "2px", marginLeft: "4px" }}
              />
            ) : (
              <FaArrowAltCircleDown
                style={{ marginTop: "2px", marginLeft: "4px" }}
              />
            )}
          </>
        </div>
      ),
      width: "200px",
    },
    {
      id: 8,
      label: "Balance",
      field_name: "balance",
      render: (row) => <>{row.balance ?? "-"}</>,
      width: "200px",
    },
    {
      id: 9,
      label: "Order",
      field_name: "order",
      render: (row) => (
        <div className="table_action__wrap">
          <div
            className={`table_action__btn   ${
              !row.order_id && "table_action__btn__disabled"
            }`}
            onClick={() => {
              setdetailModal([row?.order_id, false]);
            }}
          >
            <MdOutlineStickyNote2 size={10} />
            Order
          </div>
        </div>
      ),
      width: "200px",
    },
    {
      id: 10,
      label: "Description",
      field_name: "description",
      render: (row) => <>{row.description ?? "-"}</>,
      width: "200px",
    },
  ];

  useEffect(() => {
    if (user?.role === "warehouseUser") {
      getAllWarehouseClients();
    }
  }, []);

  function formatBalance(balance) {
    return balance >= 0 ? -Math.abs(balance) : Math.abs(balance);
  }

  return (
    <div>
      <div>
        <div className="payment__approve_header">
          <div>
            <span className="payment__approve_heading">Transaction List</span>
          </div>
          {user?.role === "Shipper" && (
            <div className="payment__approve_box">
              <div className="payment__approve_boxleft">
                <div className="payment__approve_boxleftlbl">
                  Available 3PL Balance
                </div>
                <div className="payment__approve_boxleftprice">
                  ${getLtlbalance()}
                  <Tooltip title={details === true ? "Collapse" : "Expand"}>
                    <IconButton onClick={() => setDetails(!details)}>
                      {details === true ? (
                        <MdOutlineKeyboardArrowUp size={25} />
                      ) : (
                        <MdOutlineKeyboardArrowDown size={25} />
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
                {details && (
                  <>
                    {user?.user_approved_warehouses.map((warehouse, index) => (
                      <div key={index}>
                        <b>{warehouse?.warehouse?.name}</b> : $
                        {warehouse?.balance ?? 0}
                      </div>
                    ))}
                  </>
                )}
              </div>

              <div className="payment__approve_boxright">
                <div
                  className="payment__approve_boxbtn"
                  onClick={() => setAddBalanceModal(true)}
                >
                  <FaPlus color="#0c6efd" size={20} />
                </div>
              </div>
            </div>
          )}
          {user?.role === "warehouseUser" && (
            <div className="payment__approve_box">
              <div className="payment__approve_boxleft">
                <div className="payment__approve_boxleftlbl">
                  Available 3PL Balance
                </div>
                <div className="payment__approve_boxleftprice">
                  ${getUserbalance()}
                  <Tooltip title={details === true ? "Collapse" : "Expand"}>
                    <IconButton onClick={() => setDetails(!details)}>
                      {details === true ? (
                        <MdOutlineKeyboardArrowUp size={25} />
                      ) : (
                        <MdOutlineKeyboardArrowDown size={25} />
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
                {details && (
                  <>
                    {clients?.map((client, index) => (
                      <div key={index}>
                        <b>{client?.shipper?.username}</b> : $
                        {formatBalance(client?.balance ?? 0)}
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
        <DetailsModal
          open={detailModal}
          setOpen={setdetailModal}
          setSnack={setSnack}
        />
        <AddBalance
          open={addBalanceModal}
          setOpen={setAddBalanceModal}
          setSnack={setSnack}
          setRefresh={setRefresh}
        />

        <ApprovedTransectionTable
          message={"No Transactions Available To Display!"}
          searchPlaceHolder={"Search Transactions"}
          getAllData={getAllShippersTransactions}
          data={transactions}
          columns={columns}
          refresh={refresh}
        />
      </div>
    </div>
  );
};

export default LtlApprovedPaymentList;
