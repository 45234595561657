import React, { useState } from "react";
import "./bundleslist.css";

// components imports
import PackagesTable from "../../../../../../../Components/BoltTable/BoltTable";
import Tooltip from "../../../../../../../Components/Tooltip/Tooltip";
import Confirm from "../../../../../../../Components/Popups/Confirm";
import UpdateBundle from "../UpdateBundle/UpdateBundle";
import BundleDetails from "../BundleDetails/BundleDetails";
import AddBundle from "../AddBundle/AddBundle";

// apis imports
import {
  fetchAllBundles,
  deleteBundle,
} from "../../../../../../../services/ShipperServices/Packages/packages.services";

// library imports
import { FaRegEdit } from "react-icons/fa";
import { FcPlus } from "react-icons/fc";
import { MdOutlineCancel, MdRemoveRedEye } from "react-icons/md";
import noImage from "../../../../../../../assets/images/noImage.png";

const BundlesList = ({ snack, setSnack }) => {
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [bundleDetailsModal, setBundleDetailsModal] = useState();
  const [deleteBundleModal, setDeleteBundleModal] = useState(false);
  const [updateBundleModal, setUpdateBundleModal] = useState(false);
  const [addBundleModal, setAddBundleModal] = useState(false);

  const [bundles, setBundles] = useState([]);

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 1,
      label: "image",
      field_name: "Image",
      render: (row, rowIndex, handleFieldChange) => (
        <>
          <img
            src={row.file ?? noImage}
            onError={(e) => {
              e.target.src = noImage;
            }}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 2,
      label: "Bundle Name",
      field_name: "bundle_name",
      render: (row, rowIndex, handleFieldChange) => <>{row.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Description",
      field_name: "description",
      render: (row, rowIndex, handleFieldChange) => (
        <>{row.description ?? "-"}</>
      ),
      width: "200px",
    },
    {
      id: 4,
      label: "Packages",
      field_name: "total_packages",
      render: (row, rowIndex, handleFieldChange) => (
        <>{row?.packages?.length ?? "-"}</>
      ),
      width: "200px",
    },
    {
      id: 5,
      label: "Total Weight",
      field_name: "total_weight",
      render: (row, rowIndex, handleFieldChange) => (
        <>
          {row?.packages
            ?.map((packageItem) => parseFloat(packageItem.weight))
            ?.reduce((accumulator, weight) => accumulator + weight, 0)
            ?.toFixed(2) ??
            "-" ??
            "-"}
        </>
      ),
      width: "200px",
    },
    {
      id: 6,
      label: "Action",
      field_name: "action",
      render: (row, rowIndex, handleFieldChange) => (
        <>
          <div className="table_action__wrap">
            <Tooltip title="View Details">
              <div
                className="table_action__btn"
                onClick={() => setBundleDetailsModal(row)}
              >
                <MdRemoveRedEye size={10} />
                Details
              </div>
            </Tooltip>
            <Tooltip title="Edit Bundle">
              <div
                className="table_action__btn"
                onClick={() => setUpdateBundleModal(row)}
              >
                <FaRegEdit size={10} />
                Edit
              </div>
            </Tooltip>
            <Tooltip title="Delete Bundle">
              <div
                className="table_action__btn"
                onClick={() => setDeleteBundleModal(row.id)}
              >
                <MdOutlineCancel size={10} />
                Delete
              </div>
            </Tooltip>
          </div>
        </>
      ),
      width: "200px",
    },
  ];

  const getAllBundles = async (page, perPage, filter) => {
    const response = await fetchAllBundles(page, perPage, filter);

    if (!response.error) {
      setBundles(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const deleteSingleBundle = async () => {
    setDeleteLoader(true);
    let id = deleteBundleModal;
    const deleted = await deleteBundle(deleteBundleModal);
    if (deleted.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Couldn't delete Bundle",
          severity: "error",
        };
      });
      setDeleteLoader(false);
    } else {
      setBundles((prevState) => {
        return {
          ...prevState,
          data: prevState?.data?.filter((bndl) => bndl?.id !== id),
        };
      });
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Bundle deleted successfully",
          severity: "success",
        };
      });
      setDeleteLoader(false);
      setDeleteBundleModal(false);
    }
  };

  return (
    <div>
      <div className="bundles__header">
        <div>
          <span className="bundles__hdrheading">Bundles List</span>
        </div>

        <div className="bundles__buttonsWrapper">
          <div onClick={() => setAddBundleModal(true)}>
            <div className="bundles__hdraddprod">
              <FcPlus size={20} />
              <span>Add Bundle</span>
            </div>
          </div>
        </div>
      </div>

      <Confirm
        open={deleteBundleModal}
        setOpen={setDeleteBundleModal}
        handleConfirm={deleteSingleBundle}
        text="Do you want to delete this Bundle?"
        loader={deleteLoader}
      />

      <AddBundle
        open={addBundleModal}
        setOpen={setAddBundleModal}
        setSnack={setSnack}
        setRefresh={setRefresh}
      />
      <BundleDetails
        open={!!bundleDetailsModal}
        bundleData={bundleDetailsModal}
        setOpen={setBundleDetailsModal}
      />
      <UpdateBundle
        open={updateBundleModal}
        bundlesData={updateBundleModal}
        setOpen={setUpdateBundleModal}
        setSnack={setSnack}
        setPackages={setBundles}
      />

      <div>
        <PackagesTable
          message={`No Bundles Available To Display!`}
          searchPlaceHolder={"Search Bundles"}
          columns={columns}
          data={bundles}
          getAllData={getAllBundles}
          refresh={refresh}
        />
      </div>
    </div>
  );
};

export default BundlesList;
