import React, { useState } from "react";
import { BsPlus, BsTrash, BsLayers, BsSave } from "react-icons/bs";
import Tooltip from "../../../../../../Components/Tooltip/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "./TextField";
import { useEffect } from "react";
import PackageModel from "./AddPackageModel";
import AddBundle from "./AddDataBundle/AddBundle";
import Stack from "@mui/material/Stack";
import { BiSave } from "react-icons/bi";
import Button from "@mui/material/Button";
const initialData = {
  name: "",
  description: "",
  length: "",
  width: "",
  height: "",
  weight: "",
  unit: "Imperial",
  picture: null,
};

export default function PackageList(props) {
  const {
    Plist,
    StepCheck,
    SetStepCheck,
    SetPlist,
    Alist,
    Stepper,
    setpackageRefresh,
    bundleShow,
    setbundleShow,
    isInternational,
    setbundleRefresh,
  } = props;

  const [show, setshow] = useState(false);
  const [data, setdata] = useState(initialData);

  const handleStepper = () => {
    SetStepCheck((prevStepCheck) => ({
      ...prevStepCheck,
      Packages_bit: 0,
      QuoteCheck: false,
    }));
  };

  const handlechange = (e, index) => {
    const { name, value } = e.target;
    // console.log(name);
    // [name]: Math.ceil(value),
    handleStepper();
    const updatedList = Plist.map((item, i) => {
      if (i === index) {
        const updatedItem = {
          ...item,
          [name]: value,
        };
        updatedItem.volume = (
          (updatedItem.length * updatedItem.width * updatedItem.height) /
          1000000
        ).toFixed(2);
        return updatedItem;
      }
      return item;
    });
    SetPlist(updatedList);
  };

  const handleDuplicate = (index) => {
    handleStepper();
    const list = [...Plist];
    SetPlist([
      ...Plist,
      {
        name: list[index]["name"],
        length: list[index]["length"],
        width: list[index]["width"],
        height: list[index]["height"],
        weight: list[index]["weight"],
        volume: list[index]["volume"],
        insurance: list[index]["insurance"],
        description: list[index]["description"],
        feright: list[index]["feright"],
        nmfc: list[index]["nmfc"],
        type: list[index]["type"],
        pieces: list[index]["pieces"],
        declaration: list[index]["declaration"],
      },
    ]);
  };

  const handleRemove = (index) => {
    handleStepper();
    const list = [...Plist];
    list.splice(index, 1);
    SetPlist(list);
  };

  const handleReset = () => {
    handleStepper();
    SetPlist([
      {
        name: "",
        length: "",
        width: "",
        height: "",
        weight: "",
        volume: "",
        insurance: "",
        description: "",
        feright: "",
        nmfc: "",
        type: "",
        pieces: "",
        declaration: "",
      },
    ]);
  };

  const handleAdd = () => {
    handleStepper();
    SetPlist([
      ...Plist,
      {
        name: "",
        length: "",
        width: "",
        height: "",
        weight: "",
        volume: "",
        insurance: "",
        description: "",
        feright: "",
        nmfc: "",
        type: "",
        pieces: "",
      },
    ]);
  };

  useEffect(() => {
    if (StepCheck["unit"] === "Metric") {
      SetPlist((prevState) =>
        prevState.map((element) => ({
          ...element,
          length:
            element.length !== ""
              ? parseFloat(element.length * 2.54).toFixed(2)
              : "",
          height:
            element.height !== ""
              ? parseFloat(element.height * 2.54).toFixed(2)
              : "",
          width:
            element.width !== ""
              ? parseFloat(element.width * 2.54).toFixed(2)
              : "",
          weight:
            element.weight !== ""
              ? parseFloat(element.weight / 2.205).toFixed(2)
              : "",
        }))
      );
    } else {
      SetPlist((prevState) =>
        prevState.map((element) => ({
          ...element,
          length:
            element.length !== ""
              ? parseFloat(element.length / 2.54).toFixed(2)
              : "",
          height:
            element.height !== ""
              ? parseFloat(element.height / 2.54).toFixed(2)
              : "",
          width:
            element.width !== ""
              ? parseFloat(element.width / 2.54).toFixed(2)
              : "",
          weight:
            element.weight !== ""
              ? parseFloat(element.weight * 2.205).toFixed(2)
              : "",
        }))
      );
    }
  }, [StepCheck["unit"]]);

  useEffect(() => {
    let totalWeight = 0;
    let totalCbm = 0;
    let totalDeclaration = 0;
    Plist.forEach((element) => {
      if (element.weight !== "") {
        totalWeight += parseFloat(element.weight);
      }
      if (element.volume !== "") {
        totalCbm += parseFloat(element.volume);
      }
      if (element.declaration !== "") {
        totalDeclaration += parseFloat(element.declaration);
      }
    });
    SetStepCheck((prevStepCheck) => ({
      ...prevStepCheck,
      TotalWeight: totalWeight,
      TotalVolume: totalCbm,
      TotalDeclaration: totalDeclaration,
      International: {
        ...prevStepCheck.International,
        product: {
          ...prevStepCheck.International["product"],
          TotalPriceAmount: parseFloat(totalDeclaration / Plist.length).toFixed(
            1
          ),
        },
      },
    }));
  }, [Plist]);

  const handleshow = () => {
    setshow(!show);
  };

  const handleAddPackage = (row) => {
    setdata((prevData) => ({
      ...prevData,
      description: row.description,
      length: row.length,
      width: row.width,
      height: row.height,
      weight: row.weight,
      name: row.name,
    }));
    handleshow();
  };

  return (
    <>
      <div className="package__list_conatiner_wrap">
        <div className="package__list_conatiner_box">
          <div className="package__list_heading_row">
            <div className="package__list_heading_col package__list_heading_my_package">
              <h6>My Packages</h6>
            </div>
            <div className="package__list_heading_col package__list_heading_size">
              <h6>Size</h6>
            </div>
            <div className="package__list_heading_col package__list_heading_weight">
              <h6>Weight</h6>
            </div>
            <div className="package__list_heading_col package__list_heading_description">
              <h6>Description</h6>
            </div>
            {StepCheck["Pallet"] === true && (
              <>
                <div className="package__list_heading_col package__list_heading_peices">
                  <h6>Pieces</h6>
                </div>
                <div className="package__list_heading_col package__list_heading_nmfc">
                  <h6>NMFC</h6>
                </div>
              </>
            )}
            {Alist["insurance"] === true &&
              StepCheck["insurancetype"] === "Package" && (
                <>
                  <div className="package__list_heading_col package__list_heading_insurance">
                    <h6>Insurance</h6>
                  </div>
                </>
              )}
            {isInternational && (
              <div className="package__list_heading_col package__list_heading_declaration">
                <h6>Declaration</h6>
              </div>
            )}

            <div className="package__list_heading_col package__list_action">
              <h6>Action</h6>
            </div>
          </div>

          <div className="package__list_box">
            {Plist.map((singlelist, index) => (
              <div className="package__list_item_container" key={index}>
                <div className="package__list_item package__list_index">
                  {"#"} {index + 1}
                </div>
                <div className="package__list_item package__list_package_name">
                  <TextField
                    Label="Package Name"
                    Name="name"
                    Data={singlelist.name}
                    Required={false}
                    Handler={(e) => handlechange(e, index)}
                  />
                </div>
                <div className="package__list_item package__list_length">
                  <TextField
                    Label="Length"
                    Name="length"
                    Data={singlelist.length}
                    Type="number"
                    PlaceHolder="L"
                    SX={{
                      input: { font: "menu" },
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ color: "#1976D2" }}
                        >
                          <b>
                            <small>
                              {StepCheck["unit"] === "Imperial" ? "in" : "cm"}
                            </small>
                          </b>
                        </InputAdornment>
                      ),
                    }}
                    Handler={(e) => handlechange(e, index)}
                  />
                </div>
                <div className="package__list_item package__list_width">
                  <TextField
                    Label="Width"
                    Name="width"
                    Data={singlelist.width}
                    Type="number"
                    PlaceHolder="W"
                    SX={{
                      input: { font: "menu" },
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ color: "#1976D2" }}
                        >
                          <b>
                            <small>
                              {StepCheck["unit"] === "Imperial" ? "in" : "cm"}
                            </small>
                          </b>
                        </InputAdornment>
                      ),
                    }}
                    Handler={(e) => handlechange(e, index)}
                  />
                </div>
                <div className="package__list_item package__list_height">
                  <TextField
                    Label="Height"
                    Name="height"
                    Data={singlelist.height}
                    Type="number"
                    PlaceHolder="H"
                    SX={{
                      input: { font: "menu" },
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ color: "#1976D2" }}
                        >
                          <b>
                            <small>
                              {StepCheck["unit"] === "Imperial" ? "in" : "cm"}
                            </small>
                          </b>
                        </InputAdornment>
                      ),
                    }}
                    Handler={(e) => handlechange(e, index)}
                  />
                </div>

                <div className="package__list_item package__list_weight">
                  <TextField
                    Label="Weight"
                    Name="weight"
                    Data={singlelist.weight}
                    Type="number"
                    PlaceHolder="W"
                    SX={{
                      width: "100%",
                      input: { font: "menu" },
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ color: "#1976D2" }}
                        >
                          <b>
                            <small>
                              {StepCheck["unit"] === "Imperial" ? "lb" : "kg"}
                            </small>
                          </b>
                        </InputAdornment>
                      ),
                    }}
                    Handler={(e) => handlechange(e, index)}
                  />
                </div>

                <div className="package__list_item package__list_description">
                  <TextField
                    Label="Description"
                    Name="description"
                    Data={singlelist.description}
                    Required={false}
                    Handler={(e) => handlechange(e, index)}
                  />
                </div>

                {StepCheck["Pallet"] === true && (
                  <>
                    <div className="package__list_item package__list_pieces">
                      <TextField
                        Label="Pieces"
                        Name="pieces"
                        Data={singlelist.pieces}
                        Handler={(e) => handlechange(e, index)}
                      />
                    </div>
                    <div className="package__list_item package__list_nmfc">
                      <TextField
                        Label="Nmfc"
                        Name="nmfc"
                        Data={singlelist.nmfc ?? ""}
                        Handler={(e) => handlechange(e, index)}
                      />
                    </div>
                  </>
                )}

                {Alist["insurance"] === true &&
                  StepCheck["insurancetype"] === "Package" && (
                    <div className="package__list_item package__list_insurance">
                      <TextField
                        Label="Insurance"
                        Type="number"
                        Name="insurance"
                        PlaceHolder="Insurance"
                        Data={singlelist.insurance}
                        Handler={(e) => handlechange(e, index)}
                        SX={{
                          width: "100%",
                          input: { font: "menu" },
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                            {
                              display: "none",
                            },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{ color: "#1976D2" }}
                            >
                              <b>
                                <small>$</small>
                              </b>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  )}

                {isInternational && (
                  <div className="package__list_item package__list_declaration">
                    <TextField
                      Label="Declaration"
                      Name="declaration"
                      Data={singlelist.declaration}
                      Type="number"
                      Required={true}
                      Handler={(e) => handlechange(e, index)}
                      SX={{
                        width: "100%",
                        input: { font: "menu" },
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{ color: "#1976D2" }}
                          >
                            <b>
                              <small>$</small>
                            </b>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}

                <div className="package__list_item package__list_action__btn_col">
                  <Tooltip title="Save Package">
                    <a
                      type="button"
                      onClick={() => handleAddPackage(singlelist)}
                      className="mr-1"
                    >
                      <BiSave
                        size={35}
                        style={{
                          border: "2px solid #E2EDFB",
                          borderRadius: "25%",
                        }}
                        className="p-2 overlaybtn"
                      />
                    </a>
                  </Tooltip>

                  <Tooltip title="Duplicate Package">
                    <a
                      type="button"
                      className="mr-1"
                      onClick={() => handleDuplicate(index)}
                    >
                      <BsLayers
                        size={35}
                        style={{
                          border: "2px solid #E2EDFB",
                          borderRadius: "25%",
                        }}
                        className="p-2 overlaybtn"
                      />
                    </a>
                  </Tooltip>

                  {Plist.length - 1 !== 0 && (
                    <Tooltip title="Delete Package">
                      <a type="button" onClick={() => handleRemove(index)}>
                        <BsTrash
                          size={35}
                          style={{
                            border: "2px solid #E2EDFB",
                            borderRadius: "25%",
                          }}
                          className="p-2 overlaybtn"
                        />
                      </a>
                    </Tooltip>
                  )}
                  {Plist.length - 1 === 0 && (
                    <Tooltip title="Delete Package">
                      <a type="button" onClick={() => handleReset()}>
                        <BsTrash
                          size={35}
                          style={{
                            border: "2px solid #E2EDFB",
                            borderRadius: "25%",
                          }}
                          className="p-2 overlaybtn"
                        />
                      </a>
                    </Tooltip>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Stack direction="row" spacing={1} justifyContent="center" mt={2}>
        <Button
          variant="text"
          startIcon={<BsPlus />}
          onClick={handleAdd}
          className="dimension_weight_card_add_new_row"
        >
          Add new row
        </Button>
      </Stack>

      <PackageModel
        Show={show}
        initialData={data}
        Handler={handleshow}
        setpackageRefresh={setpackageRefresh}
      />

      <AddBundle
        open={bundleShow}
        setOpen={setbundleShow}
        Plist={Plist}
        setbundleRefresh={setbundleRefresh}
      />
    </>
  );
}
