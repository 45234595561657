import React, { useEffect, useState } from "react";
import "./editSubCategory.css";
// components imports
import Loader from "../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import { updateSubCategory } from "../../../../../services/warehouse.services";
// library imports
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { showSnackBar } from "../../../../../store/common/snackBarSlice";
import { useDispatch } from "react-redux";

const EditSubCategory = ({ data, open, setOpen, setCategories }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    id: "",
  });
  const dispatch = useDispatch();

  const handelChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (formData.name === "") {

      dispatch(showSnackBar({
        text: "Please enter subcategory name",
        severity: "error",
      }))
      setLoading(false);
      return;
    }

    const response = await updateSubCategory(formData?.id, {
      name: formData?.name,
    });
    // console.log("response", response.data.result);
    if (response.error) {
      dispatch(showSnackBar({
        text:
          response?.error?.response?.data?.message || "Something went wrong",
        severity: "error",
      }))
      setLoading(false);
    } else {
      setCategories((prevCategories) => {
        return {
          ...prevCategories,
          data: prevCategories?.data?.map((category) => {
            if (category.id === data.id) {
              const updatedSubcategories = category?.subcategory?.map(
                (sub_cat) =>
                  sub_cat.id === open?.id ? response?.data?.result : sub_cat
              );
              return { ...category, subcategory: updatedSubcategories };
            }
            return category;
          }),
        };
      });
      dispatch(showSnackBar({
        text: "SubCategory Added Successfully",
        severity: "success",
      }))
      setLoading(false);
      setFormData({
        name: "",
        id: "",
      });
      setOpen(false);
    }
  };

  useEffect(() => {
    setFormData({
      id: open?.id,
      name: open?.name,
    });
  }, [open]);

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen()}
        aria-labelledby="add-subcategory-modal-title"
        aria-describedby="add-subcategory-modal-description"
      >
        <div className="categoryPop__add_new_category_modal">
          <div className="categoryPop__add_new_category_modal_wrap">
            <div className="categoryPop__add_new_currency">
              <div className="categoryPop__add_new_category_header">
                <h3 className="categoryPop__add_new_category_title">
                  Edit Sub Category
                </h3>
              </div>
              <div className="categoryPop__add_new_category_body">
                <FormControl variant="outlined">
                  <InputLabel htmlFor="addSubcategoryName" size="small">
                    Subcategory Name
                  </InputLabel>
                  <OutlinedInput
                    id="addSubcategoryName"
                    name="name"
                    value={formData.name}
                    size="small"
                    onChange={(e) => handelChange(e)}
                    label="Subcategory Name"
                  />
                </FormControl>
                <div className="category__subcategory_edit_modalfooter">
                  <div
                    className="category__subcategory_edit_modalreset"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </div>
                  <div
                    className="category__subcategory_edit_modalsubmit"
                    onClick={handleSubmit}
                  >
                    {loading ? <Loader /> : "Save"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditSubCategory;
