import { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import IconButton from "@mui/material/IconButton";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { BsFillNodePlusFill, BsFillXCircleFill } from "react-icons/bs";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const CostFulfilmentCard = ({
  totalWeight,
  onTotalProductCount,
  onTotalWeightChange,
  totalProductPrice,
  selectedProducts,
  setSelectedProducts,
}) => {
  // console.log(totalProductPrice + "old");
  const [costTypeDrop, setCostTypeDrop] = useState("");
  const [totalWeightFulfillment, setTotalWeightFulfillmentKg] = useState(0);
  const [totalWeightFulfillmentLb, setTotalWeightFulfillmentLb] = useState(0);
  const [totalCountFulfillment, setTotalCountFulfillment] = useState(0);
  const [subtotal, setsubtotal] = useState(0);
  const [fulfillmentGST, setFulfillmentGST] = useState(0);
  const [Total, setTotal] = useState(0);
  const [fulfilmentStatusDrop, setFulfilmentStatusDrop] = useState("");
  const [costValue, setCostValue] = useState("");
  const [costList, setCostList] = useState([]);
  const [weightUnit, setWeightUnit] = useState("kg");
  const [additionalWeightkg, setAdditionalWeightkg] = useState(0);
  const [additionalWeightlb, setAdditionalWeightlb] = useState(0);
  const [customOption, setCustomOption] = useState("");
  const [selectedTaxRate, setSelectedTaxRate] = useState(0);
  const [isAdditionalTaxVisible, setIsAdditionalTaxVisible] = useState(false);
  const [selectedTaxRates, setSelectedTaxRates] = useState([]);
  const [selectedTaxOptions, setSelectedTaxOptions] = useState([]);
  const [tierweight, settierweight] = useState();
  const [fulfilmentweight, setfulfilmentweight] = useState();
  const [quantity, setquantity] = useState();
  const [fulfilmenttotal, setfulfilmenttotal] = useState(0);
  const [price, setprice] = useState(0);
  const [taxTotal, settaxTotal] = useState(0);

  const [dropdownOptions, setDropdownOptions] = useState([
    "Additional Fulfilment",
    "Delivery",
    "Remove Fulfilment Fee",
    "Shipping Overcharge",
  ]);
  const [showCustomField, setShowCustomField] = useState(false);
  const [customTaxName, setCustomTaxName] = useState("");
  const [customTaxValue, setCustomTaxValue] = useState("");
  const [taxOptions, setTaxOptions] = useState([
    { name: "PST", value: 1 },
    { name: "GST", value: 5 },
    { name: "QST", value: 10 },
  ]);

  const handleChangeCostTypeDrop = (event) => {
    const selectedOption = event.target.value;
    if (!costList.some((cost) => cost.costType === selectedOption)) {
      setCostTypeDrop(selectedOption);
    } else {
    }
  };

  const handleChangeFulfilmentStatus = (event) => {
    setFulfilmentStatusDrop(event.target.value);
  };

  const handleWeightUnitChange = (event) => {
    setWeightUnit(event.target.value);
    setAdditionalWeightkg(additionalWeightkg);
    setAdditionalWeightlb((additionalWeightkg * 2.24).toFixed(2));
  };

  const handleAddToCart = () => {
    if (costTypeDrop && costValue) {
      const newCost = {
        costType: costTypeDrop,
        costPrice: costValue,
      };

      setCostList([...costList, newCost]);
      setCostTypeDrop("");
      setCostValue("");
      calculateTotalCost();
    }
  };

  const handleCustomTaxAdd = () => {
    if (customTaxName && customTaxValue) {
      const newTaxOption = {
        name: customTaxName,
        value: parseFloat(customTaxValue).toFixed(2),
      };
      setTaxOptions([...taxOptions, newTaxOption]);
      setCustomTaxName("");
      setCustomTaxValue("");
      toggledditionalTaxVisibility();
    }
  };

  const handleTaxRateChange = (event) => {
    const selectedRates = event.target.value;
    let taxes = 0;
    selectedRates.forEach((tax) => {
      taxes += tax;
    });
    settaxTotal(taxes);
    setSelectedTaxRates(selectedRates);
  };

  const calculateOverallTotal = (selectedRates) => {
    let totalWithTaxes = subtotal;
    for (const rate of selectedRates) {
      totalWithTaxes += (subtotal * rate) / 100;
    }
    setTotal(totalWithTaxes.toFixed(2));
  };

  const calculateTotalCost = () => {
    const totalCost = costList.reduce((accumulator, cost) => {
      return accumulator + parseFloat(cost.costPrice);
    }, 0);
    let subTotal = price + totalCost + totalProductPrice + fulfilmenttotal;
    setsubtotal(subTotal);
    let taxtotal = subTotal * (taxTotal / 100);
    setTotal((subTotal + taxtotal).toFixed(2));
    return totalCost;
  };

  const handleCustomOptionChange = (event) => {
    setCustomOption(event.target.value);
  };

  const handleAddCustomOption = () => {
    if (customOption.trim() !== "" && !dropdownOptions.includes(customOption)) {
      setDropdownOptions([...dropdownOptions, customOption]);
      setCustomOption("");
      setShowCustomField(!showCustomField);
    }
  };

  const handleRemoveItem = (index) => {
    const updatedList = [...costList];
    updatedList.splice(index, 1);
    setCostList(updatedList);
  };

  const toggledditionalTaxVisibility = () => {
    setIsAdditionalTaxVisible(!isAdditionalTaxVisible);
  };

  useEffect(() => {
    let tierWeight = 0;
    let fulfilmentWeight = 0;
    let tiertotal = 0;
    let fulfilmentTotal = 0;
    let quantity = 0;

    selectedProducts.forEach((product) => {
      if (product.fulfilmentcost == "") {
        tierWeight += product.weight * product.quantity;
        tiertotal += product.price * product.quantity;
      } else {
        fulfilmentWeight += product.weight * product.quantity;
        fulfilmentTotal += product.fulfilmentcost * product.quantity;
        quantity += product.quantity;
      }
    });
    if (tierWeight < 1) {
      setprice(0);
    } else if (tierWeight >= 1 && tierWeight <= 51) {
      setprice(15);
    } else if (tierWeight >= 52 && tierWeight <= 101) {
      setprice(25);
    } else if (tierWeight >= 102 && tierWeight <= 150) {
      setprice(50);
    } else {
      setprice(100);
    }

    settierweight(parseFloat(tierWeight)+parseFloat(additionalWeightkg));
    setfulfilmentweight(fulfilmentWeight);
    setfulfilmenttotal(fulfilmentTotal);
    setquantity(quantity);
    // console.log(selectedProducts);
    // console.log(additionalWeightkg + "fulfilmentWeight" + tierWeight + "tierWeight");
    // console.log(tiertotal + "tiertotal");
    // console.log(fulfilmentWeight + "fulfilmentWeight");
    // console.log(fulfilmentTotal + "fulfilmentTotal");
  }, [
    selectedProducts,
    totalWeight,
    onTotalWeightChange,
    onTotalProductCount,
    totalProductPrice,
    additionalWeightkg,
  ]);

  useEffect(() => {
    setTotalWeightFulfillmentKg(
      parseFloat(additionalWeightkg) + parseFloat(onTotalWeightChange)
    );
    setTotalWeightFulfillmentLb(
      parseFloat(additionalWeightlb) + parseFloat(onTotalWeightChange * 2.24)
    );
    setTotalCountFulfillment(onTotalProductCount);
    calculateTotalCost();
  }, [
    totalWeight,
    onTotalWeightChange,
    onTotalProductCount,
    totalProductPrice,
    selectedTaxRates,
    taxTotal,
    costList,
  ]);

  return (
    <>
      <div className="paymentShipping__costFulfilment_wrap_main">
        <div className="paymentShipping__costFulfilment_card_wrap">
          <div className="paymentShipping__costFulfilment_header_main">
            <h2>Cost Fulfilment </h2>
          </div>
          <div className="paymentShipping__costFulfilment_body_main">
            <div className="paymentShipping__costFulfilment_fields">
              <div className="paymentShipping__costFulfilment_fields_cost_wrap">
                <div className="paymentShipping__costFulfilment_fields_cost_type">
                  <FormControl variant="outlined">
                    <InputLabel id="paymentShipping__costFulfilment_cost_type">
                      Cost Type
                    </InputLabel>
                    <Select
                      labelId="paymentShipping__costFulfilment_cost_type"
                      id="paymentShipping__costFulfilment_cost_type"
                      value={costTypeDrop}
                      label="Cost Type"
                      onChange={handleChangeCostTypeDrop}
                      style={{ height: "45px" }}
                    >
                      {dropdownOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    className="paymentShipping__costFulfilment_add_more_Btn"
                    onClick={() => setShowCustomField(!showCustomField)}
                    disabled={costList.some(
                      (cost) => cost.costType === costTypeDrop
                    )}
                  >
                    {showCustomField ? "+" : "+"}
                  </Button>
                  {showCustomField && (
                    <div className="paymentShipping__costFulfilment_add_more_panel">
                      <div
                        className="paymentShipping__costFulfilment_add_more_panel_overlay"
                        onClick={() => setShowCustomField(!showCustomField)}
                      ></div>
                      <div className="paymentShipping__costFulfilment_add_more_panel_wrap">
                        <h3>Add Custom Cost Type</h3>
                        <div className="paymentShipping__costFulfilment_add_more_panel_body">
                          <TextField
                            label="Custom Option"
                            value={customOption}
                            onChange={handleCustomOptionChange}
                          />
                          <Button
                            variant="contained"
                            onClick={handleAddCustomOption}
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="paymentShipping__costFulfilment_fields_cost_input">
                  <FormControl
                    variant="outlined"
                    className="paymentShipping__costFulfilment_cost_value"
                  >
                    <InputLabel
                      className="paymentShipping__costFulfilment_cost_value_label"
                      htmlFor="paymentShipping__costFulfilment_cost_value"
                    >
                      Cost Price
                    </InputLabel>
                    <OutlinedInput
                      id="paymentShipping__costFulfilment_cost_value"
                      className="paymentShipping__costFulfilment_cost_value_field"
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      label="Cost Price"
                      value={costValue}
                      onChange={(e) => setCostValue(e.target.value)}
                      style={{ height: "45px" }}
                    />
                  </FormControl>
                </div>
                <div className="paymentShipping__costFulfilment_fields_cost_add_btn">
                  <IconButton
                    color="primary"
                    aria-label="add to shopping cart"
                    onClick={handleAddToCart}
                  >
                    Add
                  </IconButton>
                </div>
              </div>
              <div className="fulfilment_status_addional_weight_wrap">
                <div className="fulfilment_status_field_field">
                  <div className="fulfilment_status_field_dropdown">
                    <FormControl variant="outlined">
                      <InputLabel id="paymentShipping__costFulfilment_status">
                        Status
                      </InputLabel>
                      <Select
                        labelId="paymentShipping__costFulfilment_status"
                        id="paymentShipping__costFulfilment_status"
                        value={fulfilmentStatusDrop}
                        label="Status"
                        onChange={handleChangeFulfilmentStatus}
                        style={{ height: "45px" }}
                      >
                        <MenuItem value={10}>Paid</MenuItem>
                        <MenuItem value={20}>Unpaid</MenuItem>
                        <MenuItem value={30}>Disputed</MenuItem>
                        <MenuItem value={40}>Disputed Won</MenuItem>
                        <MenuItem value={50}>Disputed Lost</MenuItem>
                        <MenuItem value={60}>In Process</MenuItem>
                        <MenuItem value={70}>Pending</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="paymentShipping__costFulfilment_fields_tax_rate">
                    <FormControl variant="outlined">
                      <InputLabel id="paymentShipping__costFulfilment_tax_rate">
                        Tax Rate
                      </InputLabel>
                      <Select
                        labelId="paymentShipping__costFulfilment_tax_rate"
                        id="paymentShipping__costFulfilment_tax_rate"
                        value={selectedTaxRates}
                        label="Tax Rate"
                        onChange={handleTaxRateChange}
                        style={{ height: "45px" }}
                        multiple
                      >
                        {taxOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div
                      className="toggle__additional_Tax_btn"
                      onClick={toggledditionalTaxVisibility}
                    >
                      {" "}
                      +{" "}
                    </div>
                    {isAdditionalTaxVisible && (
                      <div className="additional__tax_popup_wrap">
                        <div
                          className="additional__tax_popup_overlay"
                          onClick={toggledditionalTaxVisibility}
                        ></div>
                        <div className="additional__tax_popup_fields">
                          <h3>Add Custom Tax</h3>
                          <div className="additional__tax_popup_body">
                            <TextField
                              label="Custom Tax Name"
                              value={customTaxName}
                              onChange={(e) => setCustomTaxName(e.target.value)}
                            />
                            <TextField
                              label="Custom Tax Value"
                              value={customTaxValue}
                              onChange={(e) =>
                                setCustomTaxValue(e.target.value)
                              }
                            />
                            <Button
                              variant="contained"
                              onClick={handleCustomTaxAdd}
                            >
                              Add
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="fulfilment_status_additional_weight_wrapper">
                <div className="fulfilment_status_additional_weight_field">
                  {weightUnit === "kg" ? (
                    <FormControl
                      variant="outlined"
                      className="paymentShipping__additional_weight_kg"
                    >
                      <InputLabel htmlFor="paymentShipping__additional_weight_kg">
                        Additional Weight {"(kg)"}
                      </InputLabel>
                      <OutlinedInput
                        id="paymentShipping__additional_weight_kg"
                        startAdornment={
                          <InputAdornment
                            position="start"
                            className="labels_Addition"
                          >
                            kg
                          </InputAdornment>
                        }
                        label="Additional Weight (kg)"
                        value={additionalWeightkg}
                        onChange={(e) => setAdditionalWeightkg(e.target.value)}
                        style={{ height: "45px" }}
                      />
                      {/* <span onClick={(e) => AddAddionalWeightkg(additionalWeightkg)} className='labels_Addition_addBtn'>+</span> */}
                    </FormControl>
                  ) : (
                    <FormControl
                      variant="outlined"
                      className="paymentShipping__additional_weight_lb"
                    >
                      <InputLabel htmlFor="paymentShipping__additional_weight_lb">
                        Additional Weight {"(lb)"}
                      </InputLabel>
                      <OutlinedInput
                        id="paymentShipping__additional_weight_lb"
                        startAdornment={
                          <InputAdornment
                            position="start"
                            className="labels_Addition"
                          >
                            lb
                          </InputAdornment>
                        }
                        label="Additional Weight (lb)"
                        value={additionalWeightlb}
                        onChange={(e) => setAdditionalWeightlb(e.target.value)}
                        style={{ height: "45px" }}
                        disabled={true}
                      />
                      {/* <span onClick={(e) => AddAddionalWeightlb(additionalWeightlb)} className='labels_Addition_addBtn'>+</span> */}
                    </FormControl>
                  )}
                </div>
                <div className="fulfilment_status_additional_weight_btn">
                  <ToggleButtonGroup
                    value={weightUnit}
                    color="primary"
                    exclusive
                    onChange={handleWeightUnitChange}
                    aria-label="Weight Unit"
                    style={{ height: "45px" }}
                  >
                    <ToggleButton
                      value="kg"
                      aria-label="kg"
                      className="custom_button_Unit"
                    >
                      kg
                    </ToggleButton>
                    <ToggleButton
                      value="lb"
                      aria-label="lb"
                      className="custom_button_Unit"
                    >
                      lb
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                </div>
              </div>
            </div>
            <div className="paymentShipping__fulfillment_data_wrap">
                <strong>Fulfilment Cost{" "}</strong> 
              <div className="paymentShipping__fulfillment_delivery_nogst_total">
                <label>Weight: {tierweight}</label>
                <span>{price} CAD </span>
              </div>
              <div className="paymentShipping__fulfillment_delivery_nogst_total">
                <label>Item Quantity: {quantity}</label>
                <span>{fulfilmenttotal} CAD </span>
              </div>
              <div className="paymentShipping__fulfillment_delivery_nogst_total">
                <label>Total Fulfilment COST: </label>
                <span>{price + fulfilmenttotal} CAD </span>
              </div>
              <div className="paymentShipping__fulfillment_delivery_wrap">
                <div className="paymentShipping__fulfillment_cost_list">
                  <ul>
                    {costList.map((cost, index) => (
                      <li
                        className="paymentShipping__fulfillment_cost_list_li"
                        key={index}
                      >
                        <div className="paymentShipping__fulfillment_cost_list_title">
                          {cost.costType}
                        </div>
                        <div className="paymentShipping__fulfillment_cost_list_value">
                          ${cost.costPrice}
                        </div>
                        <button
                          className="paymentShipping__fulfillment_cost_list_remove"
                          onClick={() => handleRemoveItem(index)}
                        >
                          <BsFillXCircleFill />
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="paymentShipping__fulfillment_delivery_nogst_total">
                  <label>Total Product Price</label>
                  <span>{totalProductPrice } CAD </span>
                </div>
                <div className="paymentShipping__fulfillment_delivery_nogst_total">
                  <label>Sub Total</label>
                  <span>{subtotal} CAD </span>
                </div>
                <div className="paymentShipping__fulfillment_delivery_gst">
                  {selectedTaxRates.map((rate, index) => {
                    const taxOption = taxOptions.find(
                      (option) => option.value === rate
                    );
                    return (
                      <label key={index}>
                        {taxOption.name} @ &nbsp; {taxOption.value} %{" "}
                        <span>{(subtotal * (taxOption.value / 100)).toFixed(2)} CAD </span>
                      </label>
                    );
                  })}
                </div>
                <div className="paymentShipping__fulfillment_delivery_total_overall">
                  <label>Total </label>
                  <span>{Total} CAD </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CostFulfilmentCard;
