import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DiscussionTickets from '../DiscussionTickets/DiscussionTickets';
import './AllTickets.css';
import loaderImage from "../images/dispatcher-empty.gif";
import {MdSearch} from "react-icons/md";
import { getTickets } from '../../../../services/Tickets/ticket.service';
import { useSearchParams } from "react-router-dom";

function AllTickets() {
  const [tickets, setTickets] = useState([]);
  const [TicketDetails, getTicketDetails] = useState();
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [status,setStatus]=useState('');
  const [keyword,setKeyword]=useState('');
  const [ticketStats,setTicketStats]=useState({})
  const [totalPages, setTotalPages] = useState(1);
  const [defaultLoaderImage, setDefaultLoaderImage] = useState(true);
  const [parentLoading,setParentLoading] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams(); 

  const clearFilters = () => {
    searchParams.delete('ticket_no');
    setSearchParams(searchParams);
  }

  const handleTicketClick = (ticketId) => {
    setParentLoading(true)
    getTicketDetails(ticketId);
    setSelectedTicketId(ticketId);
  };

  const handleKeywordChange = (e)=>{
    setKeyword(e?.target?.value);
    searchParams.delete('ticket_no');
    setSearchParams(searchParams);
  }

  const getTicketsList = async() => {
    try {          
      const response = await getTickets(currentPage, status, keyword, searchParams.get("ticket_no"));
      if(response?.data?.success === true){
        setLoading(false);
      }
      setTickets(response.data.result.tickets.data);
      setTotalPages(response.data.result.tickets.last_page);
      setTicketStats(response.data.result.tickets_stats)
      
      if (response?.data?.result?.tickets?.data?.length == 0)
        setDefaultLoaderImage(false);

      if (response?.data.result.tickets.data.length > 0) {
        handleTicketClick(response.data.result.tickets.data[0].id);
      }
    } catch (error) {
      setDefaultLoaderImage(false);
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    if(!keyword) {
      setLoading(true);
    }
    getTicketsList();
  }, [currentPage, status, keyword, searchParams.get('ticket_no')]);

  // Function to format the date and time
  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp);
        const formattedDate = date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        });
        return formattedDate;
  };

  return (
    <>
 {loading && (
        <div className='discussion_default_loader'>
        <img src={loaderImage} alt='-' className='discussion_default_loader_image'/>
      </div>
      )}

      <div className='allTickets__main_wrap'>
        <div className='allTickets__main_tabs'></div>
        <div className='allTickets__main_list'>
        <div className='allTickets__main_list_title'>Tickets</div>
        <ul className='allTickets__status_filter'>
          <li onClick={()=> { setStatus(''); searchParams.delete('ticket_no');
    setSearchParams(searchParams); }} className={`${status==='' ?'active' : ''}`}>
            All <span className="count">{ticketStats?.All  > 0 ? ticketStats?.All : 0 }</span>
          </li>
          <li onClick={()=> { setStatus('Open'); searchParams.delete('ticket_no');
    setSearchParams(searchParams); }} className={`${status==='Open' ?'active' : ''}`}>
            Open <span className="count">{ticketStats?.Open > 0 ? ticketStats?.Open : 0}</span>
          </li>
          <li onClick={()=> { setStatus('Resolved'); searchParams.delete('ticket_no');
    setSearchParams(searchParams); }} className={`${status==='Resolved' ?'active' : ''}`}>
            Resolved  <span className="count">{ticketStats?.Resolved > 0 ? ticketStats?.Resolved : 0}</span>
          </li>
          <li onClick={()=> { setStatus('Disputed'); searchParams.delete('ticket_no');
    setSearchParams(searchParams); }} className={`${status==='Disputed' ?'active' : ''}`}>
            Disputed <span className="count">{ticketStats?.Disputed > 0 ? ticketStats?.Disputed : 0}</span>
          </li>
        </ul>
        <div className="allTickets__main_list_search">
          <MdSearch />
<input type="text" placeholder='Search' value={keyword} onChange={handleKeywordChange} />
        </div>
        { searchParams.get('ticket_no') &&
          <div style={{marginBottom:'8px', textAlign:'center', color:'blue', fontSize:'15px'}}> Filter by ticket no = {searchParams.get('ticket_no')} <span onClick={clearFilters} title="Clear Filter" style={{color:'red', paddingLeft:'3px', cursor:'pointer', fontWeight:'600'}}> X</span></div>
        }
          <ul className='allTickets__main_list_wrapper'>
            {tickets.map((ticket,index) => (
              <li className={`allTickets__main_list_li ${selectedTicketId === ticket.id ? 'selected' : ''}`} key={ticket?.id} onClick={() => handleTicketClick(ticket?.id)} >
                <div className='allTickets__main_list_left_col'>
                  
                  <div className='allTickets__ticket_name'>
                    <div className='allTickets__icon_counter'>{ticket?.user.firstname.charAt(0)}{ticket?.user.lastname.charAt(0)} <span>{ticket?.total_responses}</span></div>
                    <div className='allTickets__name_id'>
                      <div className='allTickets__name_role'>
                        <span className='allTickets__name'>{ticket?.user?.firstname}</span>
                        <span className='allTickets__role'> ({ticket?.user?.role})</span>
                      </div>
                      <div className="allTickets__name_Ids">
                      <div className="allTickets__main_list_right_left">
                    <span className='title'>Ticket Id: <span className='value'> {ticket?.ticket_no}</span></span>
                    </div>

                    <div className="allTickets__main_list_right_left">
                    <span className='title'>Shipment Id: <span className='value'> {ticket?.shipment?.id}</span></span>
                    </div>
                      </div>
                     
                    </div>
                  </div>
                  <div className='allTickets__ticket_description'>{ticket?.description.slice(0,40)}</div>
                  <div className='allTickets__ticket_status'>
                    {/* {ticket?.reason && <div className="allTickets__ticket_item">
                      <div className="title">Reason</div>
                      <div className={`allTickets__ticket_item_tag ${ticket?.reason}`}>
                     { ticket?.reason}
                      </div>
                    </div>
                       } */}
                        {/* {ticket?.priority && <div className="allTickets__ticket_item">
                      <div className="title">priority</div>
                      <div className={`allTickets__ticket_item_tag ${ticket?.priority}`}>
                     { ticket?.priority}
                      </div>
                    </div>
                       } */}
                        {/* {ticket?.status && <div className="allTickets__ticket_item">
                      <div className="title">status</div>
                      <div className={`allTickets__ticket_item_tag ${ticket?.status}`}>
                     { ticket?.status}
                      </div>
                    </div>
                       } */}
                  </div>
                </div>
            
                <div className='allTickets__main_list_right_col'>
                  <div className={ticket?.priority + ' allTickets__date_time'}>{formatDateTime(ticket?.updated_at)}</div>
                  {/* <div className='allTickets__status'>{ticket?.status}</div> */}
                  <div className="allTickets__main_list_right_item_wrapper">
                    <div className="allTickets__main_list_right_title">
                    Tracking
                    </div>
                    <div className="allTickets__main_list_right_value">
                    {ticket?.shipment?.tracking_id}
                    </div>
                  
                  </div>
                 
                 

                  
                  <div className='allTickets__shipment_logo'>{ticket?.shipment?.shipping_company?.shipping_company?.logo_url && (<img src={ticket?.shipment?.shipping_company?.shipping_company?.logo_url} alt="-"/>)}</div>
                </div>
              </li>
            ))}
          </ul>
          <div className="pagination__forList_main_wrap">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              className={currentPage === 1 ? "disable__btn_arrow" : ""}
            >
              <div className='pagination__forList_main_Icon'>{"<"}</div>
            </button>
            <span>{`Page ${currentPage} of ${totalPages}`}</span>
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              className={currentPage === totalPages ? "disable__btn_arrow" : ""}
            >
              <div className='pagination__forList_main_Icon'>{">"}</div>
            </button>
          </div>
        </div>
      </div>
      <div className='disussion_shipping_details_box'>
        <DiscussionTickets
          ticketId={TicketDetails}
          parentLoading={parentLoading}
          setParentLoading={setParentLoading}
          setDefaultLoaderImage={setDefaultLoaderImage}
        />  
      </div>

      {defaultLoaderImage && (
        <>
          <div className='discussion_default_loader'>
            <img src={loaderImage} alt='-' className='discussion_default_loader_image'/>
          </div>
        </>
      )}
    </>
  );
}

export default AllTickets;
