import React from "react";
import "./CustomStepper.css";
import { FaPenFancy, FaBuilding, FaDoorOpen } from "react-icons/fa";
const CustomStepper = ({ step, setStep }) => {
  return (
    <div className="custom__rstepper_component">
      <div className="crstepper__component_item">
        <div
          className={`crstepper__component_itembottom ${
            step > 0 && "crstprcomp_itembottomactive"
          }`}
        ></div>
        <div
          className={`crstepper__component_itemtop ${
            step > 0 && "crstepper__component_itemtop active"
          }`}
          onClick={() => setStep(step > 1 ? 1 : step)}
        >
          {" "}
          <FaPenFancy size={28} />
          <span>Register</span>
        </div>
      </div>
      <div className="crstepper__component_item">
        <div
          className={`crstepper__component_itembottom ${
            step > 1 && "crstprcomp_itembottomactive"
          }`}
        ></div>
        <div
          className={`crstepper__component_itemtop ${
            step > 1 && "crstepper__component_itemtop active"
          }`}
          onClick={() => setStep(step > 2 ? 2 : step)}
        >
          <FaBuilding size={28} /> <span>Business information</span>
        </div>
      </div>
      <div className="crstepper__component_item">
        <div
          className={`crstepper__component_itembottom ${
            step > 2 && "crstprcomp_itembottomactive"
          }`}
        ></div>
        <div
          className={`crstepper__component_itemtop ${
            step > 2 && "crstepper__component_itemtop active"
          }`}
        >
          <FaDoorOpen size={28} /> <span>Login</span>
        </div>
      </div>
    </div>
  );
};

export default CustomStepper;
