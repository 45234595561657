import React, { useState, useEffect } from "react";
import "../RegisterSteps/RegisterSteps.css";
import { ReactComponent as User } from "../../../../assets/icons/user.svg";
import { ReactComponent as Address } from "../../../../assets/icons/address.svg";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Countries from "../../../../data/countries";
import CustomTextField from "../CustomTextField/CustomTextField";
const RegisterLStep1 = ({ setCurrentLStep, formData, handleFieldChange }) => {
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (
      [
        formData.warehousename,
        formData.warehouseaddress,
        formData.city,
        formData.country,
        formData.state,
        formData.passcode,
      ].includes("")
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formData]);
  return (
    <div className="rfsregister__steps_wrap">
      <div className="rfsregister__steps_fieldsrow">
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            icon={<User />}
            placeholder="Warehouse Name"
            type="text"
            name="warehousename"
            value={formData.warehousename}
            onChange={(e) => handleFieldChange("warehousename", e.target.value)}
          />
        </div>
      </div>
      <div className="rfsregister__steps_fieldsrow">
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            icon={<Address />}
            placeholder="Warehouse Address"
            onChange={(e) =>
              handleFieldChange("warehouseaddress", e.target.value)
            }
            name="warehouseaddress"
            value={formData.warehouseaddress}
          />
        </div>
      </div>

      <div className="rfsregister__steps_fieldsrow">
        <div className="rfsregister__steps_fieldscol">
          <label>Warehouse Country</label>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={Countries}
            getOptionLabel={(option) => option.name}
            sx={{}}
            renderInput={(params) => (
              <TextField {...params} placeholder="Country" />
            )}
            onChange={(e, country) =>
              handleFieldChange("country", country.code)
            }
          />
        </div>
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            icon={<Address />}
            placeholder="Warehouse State"
            type="text"
            name="state"
            value={formData.province}
            onChange={(e) => handleFieldChange("state", e.target.value)}
          />
        </div>
      </div>
      <div className="rfsregister__steps_fieldsrow">
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            icon={<Address />}
            placeholder="Warehouse City"
            onChange={(e) => handleFieldChange("city", e.target.value)}
            name="city"
            value={formData.city}
          />
        </div>
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            icon={<Address />}
            placeholder="Warehouse Postal Code"
            type="text"
            name="passcode"
            value={formData.passcode}
            onChange={(e) => handleFieldChange("passcode", e.target.value)}
          />
        </div>
      </div>

      <div
        className={`rfsregister__steps_nextbtn ${
          disabled && "rfsregister__steps_nextbtndisabled"
        }`}
        onClick={() => {
          setCurrentLStep(2);
        }}
      >
        Next
      </div>
    </div>
  );
};

export default RegisterLStep1;
