import axios from 'axios';
import React, { useEffect, useState } from 'react';
import loaderImage from "../images/dispatcher-empty.gif";
import {BiImageAdd} from "react-icons/bi";
import { BsPaperclip } from 'react-icons/bs';
import { useSelector } from "react-redux";
import {MdClose } from "react-icons/md";
import { sendReply } from '../../../../services/Tickets/ticket.service';

const SendReply = ({ ticketId, setTicketResponses, ticketResponses, divRef, checkMediaAdded }) => {
  const [ticketDiscussion, setTicketDiscussion] = useState([]);
  const [fileState,setFileState]=useState(null)
  const [selectedFile,setSelectedFile]=useState(null)
  const [fileName,setFileName]=useState(null)
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    response_body: '',
    response_type: 'text',
    hide_from_recipient: false,
    hide_from_shipper: false,
    // hide_from_carrier: false
  });  
  
  const user = useSelector((state) => state.user);  

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
  
    if (type === 'checkbox') {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else if (type === 'file') {
     
      setFormData({
        ...formData,
        response_type:'image'
      })
      const file = files[0];
        setFileState(file)
        if (file.type.startsWith('image/')) {
          setSelectedFile(URL.createObjectURL(file))
          setFileName(null)
        }
       else if (file.type === 'application/pdf') {
          setFileName(file.name)
          setSelectedFile(null)
        }
       
    } else {
      setFileState(null);
      setFormData({
        ...formData,
        response_type:'text',
        [name]: value,
       
      });
    }
  }; 

  const handleSubmit  = async(e) => {
    e.preventDefault();
    setLoading(true);
    const _formData = new FormData();
    if(fileState){
      _formData.append("response_body", fileState);
      _formData.append('response_type','image');
     
      
    }
    else{
      _formData.append("response_body", formData?.response_body);
      _formData.append('response_type','text');
      
    }
    _formData.append('hide_from_recipient',formData?.hide_from_recipient==false ? 0 : 1)
    _formData.append('hide_from_shipper',formData?.hide_from_shipper ==false ? 0 : 1)
    // _formData.append('hide_from_carrier',formData?.hide_from_carrier ==false ? 0 : 1) will implement later
    
    try {
      const response = await sendReply(ticketId, _formData);
      if (response?.data?.success === true) {
        checkMediaAdded(true)
        setTicketResponses([...ticketResponses,response?.data?.result?.ticketReply]);
        divRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });

        setTicketDiscussion(response);
        setSelectedFile(null)
        setFileName(null)
        setFormData({
          ...formData,
          response_body: '', 
          response_type:'text'
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }   
         
  };
  

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='discussionBody__chats_form_message_box'>
        {loading && (
             
                <div className='loader__image'>
                  {/* {ticketDiscussion?.data?.message}  */}
                 <img src={loaderImage} className='__image' alt=""/>
                </div>
              
            )}
          {formData?.response_type === 'text' ?   <textarea
            type="text"
            id="response_body"
            name="response_body"
            placeholder='Write a message...'
            value={formData.response_body}
            onChange={handleChange}
            required
          ></textarea> : ''}
     {selectedFile &&  <div className='discussionBody__chats_form_selected_file'>
      <div className="icon" onClick={()=>{setSelectedFile(null);setFileState(null);setFormData({...formData,response_type:'text'})}}>
        <MdClose />
      </div>
      <img src={selectedFile} /></div>
     }
     {fileName &&  <div className='discussionBody__chats_form_selected_file'>
      <div className="icon" onClick={()=>{setFileName(null);setFileState(null);setFormData({...formData,response_type:'text'})}}>
        <MdClose />
      </div>
      <span className="name">{fileName}</span></div>
     }
         
        </div>
       <div className='discussion__visisble_roles_box'>
        <div className="discussion__visisble_roles_box_left">
        <input
  type="file"
  name="response_body"
  accept="image/*,.pdf"
  onChange={handleChange}
/>
<BiImageAdd /> <BsPaperclip />
        </div>
        <div className="discussion__visisble_roles_box_right">
        <div className='discussion__visisble_roles_box_title'>Send to:</div>
        <div className='discussionBody__chats_form_checkbox_recipent'>
          <label>
            <input
              type="checkbox"
              name="hide_from_recipient"
              defaultChecked={!formData.hide_from_recipient}
              onChange={handleChange}
            />Recipient
          </label>
        </div>
        { user?.role != 'Shipper' && 
          <div className='discussionBody__chats_form_checkbox_shipper'>
            <label>
              <input
                type="checkbox"
                name="hide_from_shipper"
                defaultChecked={!formData.hide_from_shipper}
                onChange={handleChange}
              />Shipper
            </label>
          </div>
        }
        <div className='discussionBody__chats_form_checkbox_shipper'>
          {/* <label>
            <input
              type="checkbox"
              name="hide_from_shipper"
              defaultChecked={!formData.hide_from_carrier}
              onChange={handleChange}
            />Carrier
          </label> */}
        </div>
        <button type="submit" className='discussionBody__chats_form_submit'>Send</button>
        </div>
        
       </div>
       
      </form>
    </>
  );
};

export default SendReply;
