import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import "./PackageModel.css";
import Upload from "@mui/icons-material/CloudUpload";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { addNewPackage } from "../../../../../../services/ShipperServices/Packages/packages.services";
import { useEffect } from "react";
import { showSnackBar } from "../../../../../../store/common/snackBarSlice";
import { useDispatch } from "react-redux";
import CircularLoader from "../../../../../../Components/CircularLoader/CircularLoader";

export default function AddPackage({
  Show,
  Handler,
  setpackageRefresh,
  initialData,
}) {
  const dispatch = useDispatch();

  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleUnitChange = (e) => {
    setData({ ...data, unit: e.target.value });
  };
  const handleFileChange = (e) => {
    setData({ ...data, picture: e.target.files[0] });
  };

  const handleSave = async () => {
    setLoading(true);
    for (const key in data) {
      if (data[key] === "") {
        dispatch(
          showSnackBar({
            text: `${key} is required!`,
            severity: "error",
          })
        );
        setLoading(false);
        return;
      }
    }

    const payload = {
      length: data.length,
      name: data.name,
      save_name:
        data.name +
        "    " +
        "   " +
        data.length +
        "*" +
        data.width +
        "*" +
        data.height +
        "    " +
        data.weight +
        (data.unit === "Imperial" ? " lbs" : " kg"),
      weight: data.weight,
      width: data.width,
      height: data.height,
      unit: data.unit,
      description: data.description,
      file: data.picture,
    };
    const formData = new FormData();
    formData.append("name", payload.name);
    formData.append("save_name", payload.save_name);
    formData.append("description", payload.description);
    formData.append("length", payload.length);
    formData.append("width", payload.width);
    formData.append("height", payload.height);
    formData.append("weight", payload.weight);
    formData.append("unit", payload.unit);
    payload.file && formData.append("file", payload.file);
    const saved = await addNewPackage(formData);
    if (!saved.error) {
      setpackageRefresh(Math.floor(Math.random() * 100) + 1);
      dispatch(
        showSnackBar({
          text: "Package Added successfully",
          severity: "success",
        })
      );
      setData(initialData);
      setLoading(false);
      Handler();
    } else {
      setLoading(false);
      dispatch(
        showSnackBar({
          text: "Something went wrong!",
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    setData({ ...initialData, picture: null });
  }, [initialData]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={Show}
        onClose={Handler}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="add__package_modal">
          <div className="add__package_modalheading">Add Package</div>
          <div className="add__package_modalcontent">
            <div className="add__package_modalrow">
              <div
                className="add__package_modalinputwrap"
                style={{ minWidth: "65%" }}
              >
                <input
                  className="add__package_modalinput"
                  placeholder="Package Name"
                  name="name"
                  value={data.name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="add__package_modalrow">
              <div
                className="add__package_modalinputwrap"
                style={{ minWidth: "65%" }}
              >
                <input
                  className="add__package_modalinput"
                  placeholder="Description"
                  name="description"
                  value={data.description}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="add__package_modalrow">
              <div className="add__package_modalinputwrap">
                <input
                  className="add__package_modalinput"
                  placeholder="Length"
                  name="length"
                  value={data.length}
                  onChange={handleChange}
                  type="number"
                />
              </div>
              <div className="add__package_modalinputwrap">
                <input
                  className="add__package_modalinput"
                  placeholder="Width"
                  name="width"
                  value={data.width}
                  onChange={handleChange}
                  type="number"
                />
              </div>
              <div className="add__package_modalinputwrap">
                <input
                  className="add__package_modalinput"
                  placeholder="Height"
                  name="height"
                  value={data.height}
                  onChange={handleChange}
                  type="number"
                />
              </div>
              <div className="add__package_modalinputwrap">
                <input
                  className="add__package_modalinput"
                  type="number"
                  value={data.weight}
                  name="weight"
                  placeholder="Weight"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="add__package_modalrow">
              <div className="add__package_modalinputwrap">
                <FormControl
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingLeft: "10px",
                  }}
                >
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Unit:
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={data.unit}
                    onChange={handleUnitChange}
                    sx={{ flexDirection: "row", alignItems: "center" }}
                  >
                    <FormControlLabel
                      value="Imperial"
                      control={<Radio />}
                      label="Imperial"
                      sx={{ marginLeft: "20px" }}
                    />
                    <FormControlLabel
                      value="Metric"
                      control={<Radio />}
                      label="Metric"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="add__package_modalrow">
              <div className="add__package_modalinputwrap">
                <label className="add__package_profimagelbl">
                  <Upload /> {data.picture?.name ?? "UPLOAD PACKAGE IMAGE"}
                  <input
                    type="file"
                    size="60"
                    className="add__package_profimageinput"
                    onChange={handleFileChange}
                    accept=".jpg,.png"
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="add__package_modalfooter">
            <div className="add__package_modalcncl" onClick={Handler}>
              Cancel
            </div>
            <div className="add__package_modalsave" onClick={handleSave}>
              {loading ? <CircularLoader /> : "Save"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
