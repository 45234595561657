import React from "react";

const SkeletonTable = ({ rows = 12, columns = 7 }) => {
  return (
    <div className="w-full p-4">
      <div className="w-full" style={{ height: "calc(100vh - 370px)" }}>
        <table className="min-w-full table-auto">
          <thead>
            <tr>
              {Array(columns)
                .fill("")
                .map((_, index) => (
                  <th key={index} className="py-2">
                    <div className="h-4 bg-gray-100 animate-pulse rounded-sm"></div>
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {Array(rows)
              .fill("")
              .map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {Array(columns)
                    .fill("")
                    .map((_, colIndex) => (
                      <td key={colIndex} className="px-4 py-2">
                        <div className="h-3 bg-gray-100 rounded-sm animate-pulse"></div>
                      </td>
                    ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SkeletonTable;
