import React from "react";

import Header from "./Components/header";
import OrderDetails from "./Components/orderdetails";
import ShipmentSummary from "./Components/shipmentSummary";
import Trackingandnotes from "./Components/trackingandnotes";
import Fulfilment from "./Components/fulfilment";

export default function PrintableOrderDetails({
  data,
  AdditionalCostTotal,
  setadditionalCosts,
  additionalCosts,
  deletedids,
  setdeletedids,
  setSnack,
  setData,
  setOrders,
  cost,
  setcost,
  value,
  setValue,
  open,
  additonalPrice,
  setadditonalPrice,
  setAdditionalCostTotal,
  selectedStatus,
  setselectedStatus,
  handleClose,
  invoiceRef,
  Packinglist,
  orderStatus,
  setorderStatus,
}) {
  return (
    <>
      <div
        className="invoice__modal_box printable__invoice printable__order__details"
        ref={invoiceRef}
      >
        {/* <Row className="mx-auto">
          <Col md={12} className="mx-auto">
            <Row> */}
        <Header
          orderId={data?.order_id}
          handleClose={handleClose}
          type="print"
        />
        <OrderDetails
          data={data}
          AdditionalCostTotal={AdditionalCostTotal}
          type="print"
          Packinglist={Packinglist}
        />
        <ShipmentSummary
          data={data}
          selectedStatus={selectedStatus}
          orderStatus={orderStatus}
          type="print"
        />

        <Trackingandnotes data={data} type="print" />
        {!Packinglist && (
          <Fulfilment
            editable={false}
            data={data}
            AdditionalCostTotal={AdditionalCostTotal}
            setadditionalCosts={setadditionalCosts}
            additionalCosts={additionalCosts}
            deletedids={deletedids}
            setdeletedids={setdeletedids}
            setSnack={setSnack}
            setData={setData}
            setOrders={setOrders}
            cost={cost}
            setcost={setcost}
            value={value}
            setValue={setValue}
            open={open}
            additonalPrice={additonalPrice}
            setadditonalPrice={setadditonalPrice}
            setAdditionalCostTotal={setAdditionalCostTotal}
            selectedStatus={selectedStatus}
            setselectedStatus={setselectedStatus}
            type="print"
            orderStatus={orderStatus}
            setorderStatus={setorderStatus}
          />
        )}
        {/* </Row>
          </Col>
        </Row> */}
      </div>
    </>
  );
}
