import React, { useRef, useState } from "react";
import "./AddProduct.css";
import { BiArrowBack } from "react-icons/bi";
import InfoSection from "./Sections/InfoSection";
import MediaSection from "./Sections/MediaSection";
import PricingSection from "./Sections/PricingSection";
import VariantSection from "./Sections/VariantsSection";
import TableOfContent from "../Components/TableOfContent/TableOfContent";
import StatusSection from "./Sections/StatusSection";
import CategorySection from "./Sections/CategorySection";
import { Link, useNavigate } from "react-router-dom";
import IdentifiersSection from "./Sections/IdentifiersSection";
import DimensionsSection from "./Sections/DimensionsSection";
import { useEffect } from "react";
import ComboSection from "./Sections/ComboSection";
import { useSelector } from "react-redux";
import {
  createProduct,
  fetchRawProductData,
} from "../../../services/product.service";
import CircularProgress from "@mui/material/CircularProgress";
import { showSnackBar } from "../../../store/common/snackBarSlice";
import { useDispatch } from "react-redux";
const initialContentList = [
  {
    heading: "Table of Content",
    menus: [
      {
        name: "Basic Information",
        id: "addproduct_basic_info",
      },
      {
        name: "Product's Media",
        id: "addproduct_media",
      },
      {
        name: "Product's Pricing",
        id: "addproduct_pricing",
      },
      {
        name: "Dimensions",
        id: "addproduct_dimensions",
      },
      {
        name: "Identifiers",
        id: "addproduct_identifiers",
      },
      {
        name: "Product's Combo",
        id: "addproduct_combosection",
      },
      {
        name: "Variants",
        id: "addproduct_variants",
      },
    ],
  },
];
const initialFormData = {
  code: "",
  category: "",
  attributes: [],
  variants: [],
  title: "",
  description: "",
  images: [],
  media: null,
  main_image: {},
  regular_price: 0,
  warehouse_ids: [],
  sales_price: 0,
  suppliers: [],
  length: "",
  width: "",
  height: "",
  weight: "",
  product_identifiers: [],
  status: "Active",
  type: "",
  brand: "",
  currency: "cad",
  dimension_unit: "",
  weight_unit: "",
  product_combo: [],
  sku: "",
};

const initialProductIdentifier = { marketplace: "", sku: "", slug: "" };
const AddProduct = () => {
  const [attributes, setAttributes] = useState([]);
  const [variationAttributes, setVariationAttributes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [productTypes, setProductTypes] = useState();
  const [productBrands, setProductBrands] = useState();
  const [productSuppliers, setProductSuppliers] = useState();
  const [variants, setVariants] = useState([]);
  const [contentList, setContentList] = useState(initialContentList);
  const [errors, setErrors] = useState({});
  const [uploadedImages, setUploadedImages] = useState([]);
  const [savingProduct, setSavingProduct] = useState(false);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [packageDetails, setPackageDetails] = useState([
    { length: "", width: "", height: "", weight: "", description: "" },
  ]);
  const [productIdentifiers, setProductIdentifiers] = useState([
    initialProductIdentifier,
  ]);

  const [supplierSections, setSupplierSections] = useState([]);
  const user = useSelector((state) => state.user);
  const [formData, setFormData] = useState(initialFormData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  function navigateToSection(data) {
    let sectionId = null;

    if (data.title || data.description || data.code) {
      sectionId = "addproduct_basic_info";
    } else if (data.regular_price || data.sales_price) {
      sectionId = "addproduct_pricing";
    } else if (
      data.length ||
      data.width ||
      data.height ||
      data.weight ||
      data.unit
    ) {
      sectionId = "addproduct_dimensions";
    } else if (data.children) {
      sectionId = "addproduct_variants";
    } else if (data.category || data.sub_category || data.type) {
      sectionId = "addproduct_basic_category";
    } else if (data.status) {
      sectionId = "addproduct_basic_status";
    } else if (data.warehouse_ids) {
      sectionId = "addproduct_basic_category";
    }

    if (sectionId) {
      // Change the URL hash to navigate to the desired section
      window.location.hash = sectionId;
    }

    return sectionId;
  }

  function processErrorResponse(errorResponse) {
    const { message, success, request, ...rest } = errorResponse;
    const result = {};
    for (const key in rest) {
      if (Array.isArray(rest[key]) && rest[key].length > 0) {
        result[key] = { msg: rest[key][0] };
      }
    }
    navigateToSection(result);
    return result;
  }
  const fieldsToCheck = [
    "title",
    "code",
    "category",
    "brand",
    "dimension_unit",
    "weight_unit",
    "media",
    "regular_price",
    "warehouse_ids",
    "sku",
  ];
  function checkEmptyFields(inputObject, fieldsToValidate) {
    const emptyFields = {};

    function checkFieldsRecursive(obj, path = "") {
      for (const key in obj) {
        const fieldPath = path ? `${path}.${key}` : key;
        const value = obj[key];

        // Check if the current path is in the list of fields to validate
        if (fieldsToValidate.includes(fieldPath)) {
          if (
            value === null ||
            value === undefined ||
            value === "" ||
            value?.length === 0
          ) {
            emptyFields[fieldPath] = {
              msg: `Field is required.`,
            };
          }
        }

        // Uncomment and update the following if nested object validation is needed
        // else if (typeof value === "object" && !Array.isArray(value) && value !== null) {
        //   checkFieldsRecursive(value, fieldPath);
        // }
      }
    }

    checkFieldsRecursive(inputObject);
    navigateToSection(emptyFields);
    setErrors(emptyFields);
    if (Object.keys(emptyFields).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  const createFormData = (payload) => {
    const formData = new FormData();

    const replaceUndefinedWithEmptyString = (value) => {
      return value === undefined ? "" : value;
    };

    const appendToFormData = (key, value) => {
      value = replaceUndefinedWithEmptyString(value);

      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (typeof item === "object") {
            for (const itemKey in item) {
              formData.append(
                `${key}[${index}][${itemKey}]`,
                replaceUndefinedWithEmptyString(item[itemKey])
              );
            }
          } else {
            formData.append(
              `${key}[${index}]`,
              replaceUndefinedWithEmptyString(item)
            );
          }
        });
      } else if (typeof value === "object" && value !== null) {
        for (const nestedKey in value) {
          formData.append(
            `${key}[${nestedKey}]`,
            replaceUndefinedWithEmptyString(value[nestedKey])
          );
        }
      } else {
        formData.append(key, value);
      }
    };

    for (const key in payload) {
      if (key === "product_variations") {
        payload[key].forEach((variation, index) => {
          for (const variationKey in variation) {
            appendToFormData(
              `product_variations[${index}][${variationKey}]`,
              variation[variationKey]
            );
          }
        });
      } else {
        appendToFormData(key, payload[key]);
      }
    }

    return formData;
  };

  const handleSaveProduct = async () => {
    let valid = checkEmptyFields(formData, fieldsToCheck);
    if (formData?.variants?.length > 0) {
      let variantError = false;
      formData?.variants.forEach((variant) => {
        if (["", null, undefined].includes(variant?.code)) {
          variantError = "Variants SKU is Required";
        }
      });
      if (variantError) {
        dispatch(
          showSnackBar({
            text: variantError || "Something went wrong",
            severity: "error",
          })
        );
        return;
      }
    }

    if (!valid) {
      return;
    }
    const payload = {
      product_sku: formData?.sku,
      code: formData?.code,
      name: formData?.title,
      description: formData?.description,
      additional_information: formData?.additional_information,
      product_variation_attribute_id: "null",
      full_name: formData?.title,
      warehouse_ids: formData?.warehouse_ids,
      category_id: formData?.category?.value ?? "",
      subcategory_id: formData?.sub_category?.value ?? "",
      type: formData?.type?.value ?? "",
      length: formData?.length,
      width: formData?.width,
      height: formData?.height,
      weight: formData?.weight,
      product_type_id: formData?.type?.value ?? "",
      product_unit_id: "",
      brand_id: formData?.brand?.value ?? "",
      regular_price: formData?.regular_price,
      sale_price: formData?.sales_price,
      brand: formData?.brand,
      currency: formData?.currency,
      dimension_unit: formData?.dimension_unit?.value ?? "",
      weight_unit: formData?.weight_unit?.value ?? "",
      package_details: formData?.package_details ?? "",
      fulfillment_cost: "",
      has_variants: "true",
      attributes,

      product_identifiers: formData?.identifiers?.map((identifier) => {
        return {
          market_place_id: identifier.marketplace.id,
          sku: identifier.sku,
          slug: identifier.slug,
        };
      }),
      media: formData?.media,
      product_suppliers: formData?.suppliers?.map((supplier) => {
        return {
          cost: supplier.cost,
          supplier_id: supplier.supplier?.value ?? "",
        };
      }),
      product_combo:
        formData?.type?.label?.toLowerCase() === "combo"
          ? formData?.product_combo
          : [],
      has_variants: `${formData.variants?.length > 0}`,
      product_variations:
        formData.variants?.length > 0 &&
        formData?.type?.label?.toLowerCase() !== "combo"
          ? formData.variants?.map((variant) => {
              return {
                code: variant?.code,
                product_sku: variant?.sku,
                name: variant.variant,
                // product_variation_attribute_id: [1, 2, 3],
                full_name: `${formData.title ? formData.title + " - " : ""}${
                  variant.variant
                }`,
                category_id: formData?.category?.value,
                subcategory_id: "",
                length: variant.length,
                width: variant.width,
                height: variant.height,
                weight: variant.weight,
                dimension_unit:
                  variant.dimension_unit?.value ??
                  formData?.dimension_unit?.value,
                weight_unit:
                  variant?.weight_unit?.value ?? formData?.weight_unit?.value,
                warehouse_ids: formData?.warehouse_ids,
                product_type_id: "",
                product_unit_id: "",
                brand_id: formData?.brand?.value ?? "",
                regular_price: variant.regular_price,
                sale_price: variant.sales_price,
                fulfillment_cost: "",
                product_identifiers: variant?.product_identifiers?.map(
                  (identifier) => {
                    return {
                      market_place_id: identifier.marketplace.id,
                      sku: identifier.sku,
                      slug: identifier.slug,
                    };
                  }
                ),
                media: variant.media,
                product_suppliers: variant?.suppliers?.map((supplier) => {
                  return {
                    cost: supplier.unit_cost,
                    supplier_id: supplier.supplier?.value,
                  };
                }),
              };
            })
          : [],
    };
    const formDataPayload = createFormData(payload);
    try {
      setSavingProduct(true);
      const response = await createProduct(formDataPayload);
      if (!response?.error) {
        dispatch(
          showSnackBar({
            text: "Product Created Successfully",
            severity: "success",
          })
        );
        navigate("/products");
        setSelectedCategory("");
        setSelectedType("");
        setSelectedBrand("");
        setFormData(initialFormData);
        setSupplierSections([]);
        setProductIdentifiers([initialProductIdentifier]);
        setVariants([]);
        setAdditionalFields([]);
        setSavingProduct(false);
        setAttributes([]);
      } else {
        setSavingProduct(false);

        setErrors(processErrorResponse(response?.error?.response?.data));
        dispatch(
          showSnackBar({
            text:
              response?.error?.response?.data?.message ||
              "Something went wrong",
            severity: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        showSnackBar({
          text: "Something went wrong",
          severity: "error",
        })
      );
    }
  };
  const fetchInitialData = async () => {
    try {
      const response = await fetchRawProductData();
      if (!response.error) {
        setVariationAttributes(
          response?.data?.result?.allVariations?.map((attr) => {
            return { value: attr.id, title: attr.name };
          })
        );
        setPackageDetails([]);
        setCategories(
          response?.data?.result?.categories?.map((category) => {
            return { value: category.id, title: category.name };
          })
        );
        setProductTypes(
          response?.data?.result?.productTypes?.map((type) => {
            return { value: type.id, title: type.name };
          })
        );
        setSelectedType({
          value: response?.data?.result?.productTypes[0].id,
          title: response?.data?.result?.productTypes[0].name,
        });
        setProductBrands(
          response?.data?.result?.brands?.map((brand) => {
            return { value: brand.id, title: brand.name };
          })
        );
        setProductSuppliers(
          response?.data?.result?.suppliers?.map((supplier) => {
            return { value: supplier.id, title: supplier.name };
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    if (formData?.type?.id === 2) {
      setContentList(initialContentList);
    } else {
      setContentList((prevList) => {
        const menus = prevList[0].menus?.filter(
          (item) => item?.id !== "addproduct_combosection"
        );
        return [
          {
            ...prevList[0],
            menus,
          },
        ];
      });
    }
  }, [formData?.type]);

  useEffect(() => {
    fetchInitialData();
  }, []);
  return (
    <div className="add__prod_screen">
      <div className="add__prod_screenleft">
        <TableOfContent contentList={contentList} />
      </div>
      <div className="add__prod_screencenter">
        <div className="add__prod_sheader hide_mobile_btn">
          <Link
            to={user.role == "Shipper" ? "/products" : "/warehouse-products"}
          >
            <BiArrowBack size={22} className="add__prod_sheaderback" />
          </Link>
          Add product
        </div>
        <InfoSection
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          additionalFields={additionalFields}
          setAdditionalFields={setAdditionalFields}
        />
        <MediaSection
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          uploadedImages={uploadedImages}
          setUploadedImages={setUploadedImages}
        />
        <PricingSection
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          productSuppliers={productSuppliers}
          setSupplierSections={setSupplierSections}
          supplierSections={supplierSections}
        />
        <DimensionsSection
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          packageDetails={packageDetails}
          setPackageDetails={setPackageDetails}
        />
        <IdentifiersSection
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          productIdentifiers={productIdentifiers}
          setProductIdentifiers={setProductIdentifiers}
        />
        {formData?.type?.title?.toLowerCase() === "combo" && (
          <ComboSection
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            setErrors={setErrors}
          />
        )}

        {formData?.type?.title?.toLowerCase() !== "combo" && (
          <VariantSection
            attributes={attributes}
            setAttributes={setAttributes}
            variants={variants}
            setVariants={setVariants}
            formData={formData}
            setFormData={setFormData}
            variationAttributes={variationAttributes}
            setVariationAttributes={setVariationAttributes}
            productSuppliers={productSuppliers}
          />
        )}
        <div className="add__prod_bottomsec">
          <button className="add__prod_saveprodbtn" onClick={handleSaveProduct}>
            {!savingProduct ? (
              "Save"
            ) : (
              <CircularProgress color="inherit" size={16} />
            )}
          </button>
        </div>
      </div>
      <div className="add__prod_screenright">
        <div className="add__prod_sheader show_mobile_btn">
          <Link
            to={user.role == "Shipper" ? "/products" : "/warehouse-products"}
          >
            <BiArrowBack size={22} className="add__prod_sheaderback" />
          </Link>
          Add product
        </div>
        <StatusSection
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
        />
        <CategorySection
          formData={formData}
          productTypes={productTypes}
          productBrands={productBrands}
          setProductBrands={setProductBrands}
          categories={categories}
          setCategories={setCategories}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          selectedSubCategory={selectedSubCategory}
          setSelectedSubCategory={setSelectedSubCategory}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
        />
      </div>
    </div>
  );
};

export default AddProduct;
