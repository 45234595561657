import React, { useState } from "react";
import "./Client.css";
// components imports
import FulfilmentTier from "./Components/FulfilmentTier/FulfilmentTier";
import ClientsTable from "../../../Components/BoltTable/BoltTable";
// apis imports
import {
  getUserWarehousesByWar_Id,
  approveWareHouseRequest,
} from "../../../services/warehouse.services";
// library imports
import Snackbar from "../../../Components/SnackBar/SnackBar";
import { useSelector } from "react-redux";
import Tooltip from "../../../Components/Tooltip/Tooltip";
import { IconButton } from "@mui/material";
import { IoInformationCircleOutline } from "react-icons/io5";
import { MdOutlineEditCalendar, MdAdd } from "react-icons/md";
import SwitchButton from "../../../Components/SwitchButton/SwitchButton";
import StatusIcons from "../../../Components/StatusIcons/StatusIcons";
import WarehouseAddBalance from "./Components/AddBalance/AddBalance";
import { FcPlus } from "react-icons/fc";
import AddShipper from "./Components/AddShipper/AddShipper";

const UserRegistration = () => {
  const [fulfilmenttierModal, setFulfilmenttierModal] = useState(false);

  const [users, setUsers] = useState([]);
  const [Change, setChange] = useState(false);
  const [addBalanceModal, setAddBalanceModal] = useState(false);
  const [addClientModal, setAddClientModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });
  const user = useSelector((state) => state.user);

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 1,
      label: "User Name",
      field_name: "username",
      render: (row) => <>{row?.shipper?.username ?? "-"}</>,
      width: "200px",
      fixed: "left",
    },
    {
      id: 2,
      label: "Bussiness Name",
      field_name: "business_name",
      render: (row) => <>{row?.shipper?.business_name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Email",
      field_name: "email",
      render: (row) => <>{row?.shipper?.email ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "Country",
      field_name: "country",
      render: (row) => <>{row?.shipper?.country ?? "-"}</>,
      width: "200px",
    },
    {
      id: 5,
      label: "State",
      field_name: "province",
      render: (row) => <>{row?.shipper?.province ?? "-"}</>,
      width: "200px",
    },
    {
      id: 6,
      label: "City",
      field_name: "city",
      render: (row) => <>{row?.shipper?.city ?? "-"}</>,
      width: "200px",
    },
    {
      id: 7,
      label: "Address",
      field_name: "address",
      render: (row) => <>{row?.shipper?.address ?? "-"}</>,
      width: "200px",
    },
    {
      id: 8,
      label: "Balance",
      field_name: "balance",
      render: (row) => <>{row?.balance ?? "-"}</>,
      width: "200px",
    },
    {
      id: 9,
      label: "Status",
      field_name: "status",
      render: (row) => (
        <>
          <StatusIcons
            status={
              row?.is_approved === "false"
                ? "Pending"
                : row?.is_approved === "notapplied"
                ? "Not Applied"
                : row?.is_approved === "true"
                ? "Approved"
                : ""
            }
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 10,
      label: (
        <div>
          Activate
          <Tooltip title="Approved / Disapproved">
            <IconButton>
              <IoInformationCircleOutline size={15} />
            </IconButton>
          </Tooltip>
        </div>
      ),
      field_name: "activate",
      render: (row, rowIndex, handleFieldChange) => (
        <div>
          <SwitchButton
            onChange={() => approveRequest(row)}
            checked={
              ![false, "false", "0", null, undefined].includes(row?.is_approved)
            }
            disabled={Change === "activate" ? true : false}
          />
        </div>
      ),
      width: "200px",
    },
    {
      id: 11,
      label: "Created At",
      render: (row) => <>{row?.created_at?.slice(0, 10) ?? "-"}</>,
      width: "200px",
    },
    {
      id: 12,
      label: "Actions",
      field_name: "actions",
      render: (row) => (
        <div className="table_action__wrap">
          <Tooltip title="View Fulfillment Tier">
            <div
              className="table_action__btn"
              onClick={() => setFulfilmenttierModal(row)}
            >
              <MdOutlineEditCalendar
                size={10}
                style={{
                  cursor: "pointer",
                }}
              />
              <span>Tier</span>
            </div>
          </Tooltip>
          <Tooltip title="View Fulfillment Tier">
            <div
              className="table_action__btn"
              onClick={() => setAddBalanceModal(row)}
            >
              <MdAdd
                size={10}
                style={{
                  cursor: "pointer",
                }}
              />
              <span>Add Balance</span>
            </div>
          </Tooltip>
        </div>
      ),
      width: "200px",
      fixed: "right",
    },
  ];

  const fetchWareHouseClientsList = async (page, perPage, filter) => {
    const response = await getUserWarehousesByWar_Id(
      user?.warehouse_id,
      page,
      perPage,
      filter
    );
    if (!response.error) {
      setUsers(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const approveRequest = async (row) => {
    setChange("activate");
    const saved = await approveWareHouseRequest(
      row?.id,
      row?.is_approved === "true" ? "false" : "true"
    );
    if (!saved.error) {
      setUsers((prevUser) => {
        return {
          ...prevUser,
          data: prevUser?.data.map((user, index) => {
            if (user.id === row.id) {
              return {
                ...user,
                is_approved: saved?.data?.result?.is_approved,
              };
            }
            return user;
          }),
        };
      });
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `Status of ${row?.shipper?.username} changed to ${
            [false, "false", "0", null, undefined].includes(row?.is_approved)
              ? "Active"
              : "Inactive"
          }`,
          severity: [false, "false", "0", null, undefined].includes(
            row?.is_approved
          )
            ? "success"
            : "info",
        };
      });
      setChange(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: saved?.error?.response?.data?.message ?? "Something went wrong",
          severity: "error",
        };
      });
      setChange(false);
    }
  };

  return (
    <div className="warehouse_clients__page_wrap">
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <FulfilmentTier
        open={fulfilmenttierModal}
        setOpen={setFulfilmenttierModal}
        setSnack={setSnack}
      />
      <WarehouseAddBalance
        open={addBalanceModal}
        setOpen={setAddBalanceModal}
        setSnack={setSnack}
        setTableData={setUsers}
        tableData={users}
      />
      <AddShipper
        open={addClientModal}
        setOpen={setAddClientModal}
        setSnack={setSnack}
        setRefresh={setRefresh}
      />
      <div className="warehouse_clients__adpage_header">
        <div>
          <span className="warehouse_clients__adpage_hdrheading">
            Clients List
          </span>
        </div>
        <div className="products__adpage_buttonsWrapper">
          <div onClick={() => setAddClientModal(true)}>
            <div className="products__adpage_hdraddprod">
              <FcPlus size={20} />
              <span>Add Client</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ClientsTable
          message="No Clients Available to display!"
          searchPlaceHolder="Search Clients"
          columns={columns}
          data={users}
          getAllData={fetchWareHouseClientsList}
          refresh={refresh}
        />
      </div>
    </div>
  );
};

export default UserRegistration;
