import Steps from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Box from "@mui/material/Box";
import { GiCheckMark } from "react-icons/gi";
import { RiCloseFill } from "react-icons/ri";
import "./StepperRow.css"

const data = [
  "Pickup Address",
  "Delivery Address",
  "Additional Services",
  "Dimension And Weight",
  "Select Quote",
  "Book Shipment",
  "Print Label",
];

export default function Stepper(props) {
  const { stepcheck } = props;

  return (
    <>
      <Box sx={{ width: "100%", marginTop: "3rem" }} className="mainStepper__wrapper">
        <Steps alternativeLabel>
          {data.map((label, index) => {
            const labelProps = {};
            // if (isStepFailed(index)) {
            //   // labelProps.optional = (
            //   //   <Typography variant="caption" color="error">
            //   //     Alert message
            //   //   </Typography>
            //   // );

            //   labelProps.error = true;
            //   labelProps.icon = <div style={{border : "1px solid" , padding : "5px" , backgroundColor: "#C61111" , color: "#ffffff" , padding: "6px 13px"}}>{index + 1}</div>;
            // }else{
            //   labelProps.icon = <div style={{border : "1px solid" , padding : "5px" , backgroundColor: "#1976D2" , color: "#ffffff" , padding: "6px 13px"}}>{index + 1}</div>;
            // }

            if (index === 0 && stepcheck["Pickup_Address"] === 0) {
              labelProps.icon = (
                <div
                  className="shadow shipper__step_num_count"
                  style={{
                    padding: "5px",
                    backgroundColor: "#1976D2",
                    color: "#ffffff",
                  }}
                >
                  {index + 1}
                </div>
              );
            } else if (index === 0 && stepcheck["Pickup_Address"] === 1) {
              labelProps.icon = (
                <div
                  className="shadow shipper__step_num_count"
                  style={{
                    padding: "5px",
                    backgroundColor: "#1976D2",
                    color: "#ffffff",
                    borderRadius:"20px",
                    display:"inline-flex",
                    justifyContent:"center",
                    alignItems:"center",
                    fontSize:"10px",
                  }}
                >
                  <GiCheckMark />
                </div>
              );
            } else if (index === 0 && stepcheck["Pickup_Address"] === 2) {
              labelProps.icon = (
                <div
                  className="shadow shipper__step_num_count"
                  style={{
                    padding: "5px",
                    backgroundColor: "#C61111",
                    color: "#ffffff",
                    borderRadius:"20px",
                    display:"inline-flex",
                    justifyContent:"center",
                    alignItems:"center",
                    fontSize:"14px",
                  }}
                >
                  <RiCloseFill />
                </div>
              );
            } else if (index === 1 && stepcheck["Delivery_Address"] === 0) {
              labelProps.icon = (
                <div
                  className="shadow shipper__step_num_count"
                  style={{
                    padding: "5px",
                    backgroundColor: "#C0C0C0",
                    color: "#ffffff",
                  }}
                >
                  {index + 1}
                </div>
              );
            } else if (index === 1 && stepcheck["Delivery_Address"] === 1) {
              labelProps.icon = (
                <div
                  className="shadow shipper__step_num_count"
                  style={{
                    padding: "5px",
                    backgroundColor: "#1976D2",
                    color: "#ffffff",
                    borderRadius:"20px",
                    display:"inline-flex",
                    justifyContent:"center",
                    alignItems:"center",
                    fontSize:"10px",
                  }}
                >
                  <GiCheckMark />
                </div>
              );
            } else if (index === 1 && stepcheck["Delivery_Address"] === 2) {
              labelProps.icon = (
                <div
                  className="shadow shipper__step_num_count"
                  style={{
                    padding: "5px",
                    backgroundColor: "#C61111",
                    color: "#ffffff",
                    borderRadius:"20px",
                    display:"inline-flex",
                    justifyContent:"center",
                    alignItems:"center",
                    fontSize:"14px",
                  }}
                >
                  <RiCloseFill />
                </div>
              );
            } else if (index === 2 && stepcheck["Additional_services"] === 0) {
              labelProps.icon = (
                <div
                  className="shadow shipper__step_num_count"
                  style={{
                    padding: "5px",
                    backgroundColor: "#C0C0C0",
                    color: "#ffffff",
                  }}
                >
                  {index + 1}
                </div>
              );
            } else if (index === 2 && stepcheck["Additional_services"] === 1) {
              labelProps.icon = (
                <div
                  className="shadow shipper__step_num_count"
                  style={{
                    padding: "5px",
                    backgroundColor: "#1976D2",
                    color: "#ffffff",
                    borderRadius:"20px",
                    display:"inline-flex",
                    justifyContent:"center",
                    alignItems:"center",
                    fontSize:"10px",
                  }}
                >
                  <GiCheckMark />
                </div>
              );
            } else if (index === 2 && stepcheck["Additional_services"] === 2) {
              labelProps.icon = (
                <div
                  className="shadow shipper__step_num_count"
                  style={{
                    padding: "5px",
                    backgroundColor: "#C61111",
                    color: "#ffffff",
                    borderRadius:"20px",
                    display:"inline-flex",
                    justifyContent:"center",
                    alignItems:"center",
                    fontSize:"14px",
                  }}
                >
                  <RiCloseFill />
                </div>
              );
            } else if (index === 3 && stepcheck["Packages"] === 0) {
              labelProps.icon = (
                <div
                  className="shadow shipper__step_num_count"
                  style={{
                    padding: "5px",
                    backgroundColor: "#C0C0C0",
                    color: "#ffffff",
                  }}
                >
                  {index + 1}
                </div>
              );
            } else if (index === 3 && stepcheck["Packages"] === 1) {
              labelProps.icon = (
                <div
                  className="shadow shipper__step_num_count"
                  style={{
                    padding: "5px",
                    backgroundColor: "#1976D2",
                    color: "#ffffff",
                    borderRadius:"20px",
                    display:"inline-flex",
                    justifyContent:"center",
                    alignItems:"center",
                    fontSize:"10px",
                  }}
                >
                  <GiCheckMark />
                </div>
              );
            } else if (index === 3 && stepcheck["Packages"] === 2) {
              labelProps.icon = (
                <div
                  className="shadow shipper__step_num_count"
                  style={{
                    padding: "5px",
                    backgroundColor: "#C61111",
                    color: "#ffffff",
                    borderRadius:"20px",
                    display:"inline-flex",
                    justifyContent:"center",
                    alignItems:"center",
                    fontSize:"14px",
                  }}
                >
                  <RiCloseFill />
                </div>
              );
            } else if (index === 4 && stepcheck["Quote"] === 1) {
              labelProps.icon = (
                <div
                  className="shadow shipper__step_num_count"
                  style={{
                    padding: "5px",
                    backgroundColor: "#1976D2",
                    color: "#ffffff",
                    borderRadius:"20px",
                    display:"inline-flex",
                    justifyContent:"center",
                    alignItems:"center",
                    fontSize:"10px",
                  }}
                >
                  <GiCheckMark />
                </div>
              );
            } else if (index === 5 && stepcheck["ShipmentCheck"] === true) {
              labelProps.icon = (
                <div
                  className="shadow shipper__step_num_count"
                  style={{
                    padding: "5px",
                    backgroundColor: "#1976D2",
                    color: "#ffffff",
                  }}
                >
                  {index + 1}
                </div>
              );
            } else if (index === 6 && stepcheck["ShipmentCheck"] === true) {
              labelProps.icon = (
                <div
                  className="shadow shipper__step_num_count"
                  style={{
                    padding: "5px",
                    backgroundColor: "#1976D2",
                    color: "#ffffff",
                  }}
                >
                  {index + 1}
                </div>
              );
            } else {
              labelProps.icon = (
                <div
                  className="shadow shipper__step_num_count"
                  style={{
                    padding: "5px",
                    backgroundColor: "#C0C0C0",
                    color: "#ffffff",
                  }}
                >
                  {index + 1}
                </div>
              );
            }

            return (
              <Step key={label}>
                <StepLabel {...labelProps} className="content__stepper_main shipper__stepepr_wrap">{label}</StepLabel>
              </Step>
            );
          })}
        </Steps>
      </Box>
    </>
  );
}
