import React from 'react'

function Carriership() {
  return (
    <div>
      I am carrier
    </div>
  )
}

export default Carriership
