import axios from "../../axiosInstance";

export const fetchHajexCarriers = async (type, page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `user-shipping-companies/get/${type}?page=${page}&perPage=${perPage}&name=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const updateCarrierStatus = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `user-shipping-companies/update-status/${data.record_id}`,
      data
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateServiceStatus = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `user-shipping-companies/update-services/${id}`,
      data
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const deleteCarrier = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`user-shipping-companies/delete`, {
      record_id: id,
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const addCustomCarrier = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`user-shipping-companies/store`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateAccountName = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`user-shipping-companies/edit`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
