import React, { useState } from "react";
import { FcCheckmark } from "react-icons/fc";
import { TiTimes, TiTick } from "react-icons/ti";
import { MdOutlineContentCopy } from "react-icons/md";
import Tooltip from "../../../../../../Components/Tooltip/Tooltip";
import noImage from "../../../../../../assets/images/noImage.png";
import ImagesModal from "../../../../../Shipper/Customer/MainPages/Orders/Components/OrderHistoryModal/ImagesModal/ImagesModal";
import ShipmentHistoryModal from "../../../../../../Components/ShipmentHistoryModal/ShipmentHistoryModal";
import StatusIcons from "../../../../../../Components/StatusIcons/StatusIcons";
import { MdOutlineHistory } from "react-icons/md";

export default function ShipmentSummary({
  data,
  selectedStatus,
  type = "show",
  orderStatus,
}) {
  const [copied, setCopied] = useState(false);
  const [fullview, setfullview] = useState();
  function findOrderById(orderId) {
    const foundOrder = orderStatus.find((order) => order.id === orderId);
    return foundOrder?.name?.toUpperCase() ?? "";
  }

  const [detailHistoryModal, setDetailHistoryModal] = useState(false);

  const handleCopy = () => {
    let shipmentIds = [];
    for (let i = 0; i < data?.c_p_shipment_details.length; i++) {
      let shipmentId = data?.c_p_shipment_details[i]?.shipment_tracking_id;
      shipmentIds.push(shipmentId);
    }
    let shipmentIdsString = shipmentIds.join(",\n");
    navigator.clipboard
      .writeText(shipmentIdsString)
      .then(() => {
        setCopied("sid");
      })
      .catch((error) => {
        console.error(
          "Failed to copy shipment tracking IDs to clipboard: " + error
        );
      });

    setTimeout(() => setCopied(false), 2000);
  };

  const handleCopyParcelId = () => {
    let parcelIds = [];
    data?.shipment?.shipment_details.map((item) => {
      parcelIds.push(item?.parcel_id);
    });
    // for (let i = 0; i < data?.shipment?.parcel_id?.length; i++) {
    //   let parcelId = data?.shipment?.parcel_id[i] ?? "";
    //   parcelIds.push(parcelId);
    // }
    let parcelIdsString = parcelIds.join(",\n");
    navigator.clipboard
      .writeText(parcelIdsString)
      .then(() => {
        setCopied("pid");
      })
      .catch((error) => {
        console.error("Failed to copy parcel IDs to clipboard: " + error);
      });

    setTimeout(() => setCopied(false), 2000);
  };

  function getMediaSrc(singlelist) {
    const primaryMedia = singlelist?.media?.find(
      (media) => media.is_primary === "true"
    );
    const file = primaryMedia?.file || singlelist?.media?.[0]?.file;

    return file ?? noImage;
  }

  function getFulfilledQty(pallet, index) {
    const order_id = data?.id;

    const matchingHistory = pallet?.pallet_history
      ?.sort((a, b) => b.id - a.id) // Sorting in descending order by id
      ?.find((history) => history?.properties?.order_id === order_id);

    if (matchingHistory) {
      const total =
        (matchingHistory?.properties?.previous_quantity || 0) -
        (matchingHistory?.properties?.updated_quantity || 0);

      return total > 0 ? (
        <>
          <tr
            style={{
              borderTop: "0.5px solid grey",
            }}
            key={index}
          >
            <td style={{ borderRight: "0.5px solid grey" }}>
              {pallet?.pallet?.name ?? "N/A"}
            </td>
            <td style={{ borderRight: "0.5px solid grey" }}>
              {pallet?.product_lot?.name ?? "N/A"}
            </td>
            <td style={{ borderRight: "0.5px solid grey" }}>
              {pallet?.pallet?.location?.name ?? "N/A"}
            </td>
            <td>{total ?? "N/A"}</td>
          </tr>
        </>
      ) : null;
    }

    return null;
  }

  return (
    <>
      <ShipmentHistoryModal
        open={detailHistoryModal}
        setOpen={setDetailHistoryModal}
      />
      <ImagesModal setOpen={setfullview} open={fullview} />
      <div className="invmodal__shipment_details invmodal__ship_details_wrap">
        <div className="invmodal__shipment_detailshding order_detail_heading">
          Order Details
        </div>
        <div className="invmodal__shdetails_content">
          <div className="invmodal__shdetails_cntleft">
            <table className="invmodal__shdetails_table order__detail_table_wrap">
              <thead>
                <th className="invmodal__shdetails_tablehd">Order Type</th>
                {/* <th className="invmodal__shdetails_tablehd">Order Id</th> */}
                <th className="invmodal__shdetails_tablehd">Order Status</th>
                <th className="invmodal__shdetails_tablehd">Payment Method</th>
                <th className="invmodal__shdetails_tablehd">Amount Pending</th>
                {data?.typeamount_received_by_warehouse && (
                  <th className="invmodal__shdetails_tablehd">
                    Amount Received
                  </th>
                )}
                <th className="invmodal__shdetails_tablehd">
                  Fulfilment Status
                </th>
                {/* <th className="invmodal__shdetails_tablehd">Fulfilment Cost</th> */}
              </thead>
              <tbody>
                <tr>
                  <td className="invmodal__shdetails_tableitem">
                    {data?.type ?? "-"}
                  </td>
                  {/* <td className="invmodal__shdetails_tableitem">
                    {data?.order_id ?? "-"}
                  </td> */}
                  <td className="invmodal__shdetails_tableitem">
                    {data?.status ?? "-"}
                  </td>
                  <td className="invmodal__shdetails_tableitem">
                    {data?.payment_method_title ?? "-"}
                  </td>
                  <td className="invmodal__shdetails_tableitem">
                    {data?.amount_pending ?? "0"} $
                  </td>
                  {data?.typeamount_received_by_warehouse && (
                    <td className="invmodal__shdetails_tableitem">
                      {data?.amount_received_by_warehouse ?? "0"} $
                    </td>
                  )}
                  <td className="invmodal__shdetails_tableitem">
                    {findOrderById(selectedStatus) ?? "-"}
                  </td>
                  {/* <td className="invmodal__shdetails_tableitem">
                    {data?.fulfillment_cost ?? "0"} $
                  </td> */}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="invmodal__shipment_summaryhding order_detail_heading">
          Shipment Details
        </div>
        {data?.shipment && (
          <div className="invmodal__shdetails_content">
            <div className="invmodal__shdetails_cntleft">
              <table className="invmodal__shdetails_table order__detail_table_wrap">
                <thead>
                  <th className="invmodal__shdetails_tablehd">Carrier</th>
                  <th className="invmodal__shdetails_tablehd">Service Type</th>
                  <th className="invmodal__shdetails_tablehd">
                    Shipment Status
                  </th>
                  <th className="invmodal__shdetails_tablehd">Pickup ID</th>
                  <th className="invmodal__shdetails_tablehd">Pickup Date</th>
                  <th className="invmodal__shdetails_tablehd">Bolt Tracking</th>
                  <th className="invmodal__shdetails_tablehd">
                    Carrier Tracking
                  </th>
                  <th className="invmodal__shdetails_tablehd">Time</th>
                </thead>
                <tbody>
                  {" "}
                  <tr>
                    {" "}
                    <td className="invmodal__shdetails_tableitem">
                      <img
                        src={
                          `${data?.shipment?.shipping_company?.shipping_company?.logo_url}` ||
                          noImage
                        }
                        alt=""
                        loading="lazy"
                        onError={(e) => {
                          e.target.src = noImage;
                        }}
                        className="invmodal__shdetailgi_carrlogo"
                      />
                    </td>
                    <td className="invmodal__shdetails_tableitem">
                      {data?.shipment?.service_type ?? "-"}
                    </td>
                    <td className="invmodal__shdetails_tableitem">
                      {data?.shipment?.shipment_status ?? "-"}
                    </td>
                    <td className="invmodal__shdetails_tableitem">
                      {data?.shipment?.PickupID ?? "-"}
                    </td>
                    <td className="invmodal__shdetails_tableitem">
                      {data?.shipment?.PickupDate?.slice(0, 10) ?? "-"}
                    </td>
                    <td className="invmodal__shdetails_tableitem">
                      {data?.shipment?.tracking_id ?? "-"}
                    </td>
                    <td className="invmodal__shdetails_tableitem">
                      {data?.shipment?.shipment_tracking_id ?? "-"}
                    </td>
                    <td className="invmodal__shdetails_tableitem">
                      {data?.shipment?.readyopentime ?? "-"} to{" "}
                      {data?.shipment?.readyclosetime ?? "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        {data?.manual_shipment && (
          <div className="invmodal__shdetails_content">
            <div className="invmodal__shdetails_cntleft">
              <table className="invmodal__shdetails_table order__detail_table_wrap">
                <thead>
                  <th className="invmodal__shdetails_tablehd">Carrier</th>
                  <th className="invmodal__shdetails_tablehd">Type</th>
                  <th className="invmodal__shdetails_tablehd">
                    Shipment Status
                  </th>
                  <th className="invmodal__shdetails_tablehd">Pickup ID</th>
                  <th className="invmodal__shdetails_tablehd">Pickup Date</th>
                  <th className="invmodal__shdetails_tablehd">
                    Carrier Tracking
                  </th>
                </thead>
                <tbody>
                  {" "}
                  <tr>
                    {" "}
                    <td className="invmodal__shdetails_tableitem">
                      {data?.manual_shipment?.shipping_company
                        ?.shipping_company && (
                        <img
                          src={
                            `${data?.manual_shipment?.shipping_company?.shipping_company?.logo_url}` ||
                            noImage
                          }
                          alt=""
                          loading="lazy"
                          onError={(e) => {
                            e.target.src = noImage;
                          }}
                          className="invmodal__shdetailgi_carrlogo"
                        />
                      )}
                      {data?.manual_shipment?.manual_shipping_company &&
                        (data?.manual_shipment?.manual_shipping_company
                          ?.shipping_company_name ??
                          "-")}
                      {!data?.manual_shipment?.shipping_company &&
                        !data?.manual_shipment?.manual_shipping_company &&
                        "-"}
                    </td>
                    <td className="invmodal__shdetails_tableitem">
                      {data?.manual_shipment ? "Manual Shipment" : "-"}
                    </td>
                    <td className="invmodal__shdetails_tableitem">
                      {data?.manual_shipment?.shipment_status ?? "-"}
                    </td>
                    <td className="invmodal__shdetails_tableitem">
                      {data?.manual_shipment?.pickup_id ?? "-"}
                    </td>
                    <td className="invmodal__shdetails_tableitem">
                      {data?.manual_shipment?.pickup_date ?? "-"}
                    </td>
                    <td className="invmodal__shdetails_tableitem">
                      {data?.manual_shipment?.shipment_tracking_id ?? "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        {!data?.shipment && !data?.manual_shipment && (
          <div className="shipment_order_not_available">
            <p>Shipment Not Available </p>
          </div>
        )}

        <div
          className="invmodal__shipment_summaryhding product_detail_heading"
          style={{ marginTop: "5px" }}
        >
          Product Details
        </div>
        {data?.order_details?.length > 0 ? (
          <div className="invmodal__shdetails_content product_detail_table_box">
            <div className="invmodal__shdetails_cntleft">
              <table className="invmshdetails_cnleft_table order__detail_product_detail_table">
                <thead>
                  <th className="ordedetail__shdetails_tablehd">#</th>
                  <th className="ordedetail__shdetails_tablehd">Name</th>
                  <th className="ordedetail__shdetails_tablehd">Sku</th>
                  <th className="ordedetail__shdetails_tablehd">Qty</th>
                  <th className="ordedetail__shdetails_tablehd">Lot/Batch</th>
                  <th className="ordedetail__shdetails_tablehd">Desc</th>
                  <th className="ordedetail__shdetails_tablehd">Dim (LxWxH)</th>
                  <th className="ordedetail__shdetails_tablehd">Weight</th>
                  <th className="ordedetail__shdetails_tablehd">Pre Cost</th>
                  <th className="ordedetail__shdetails_tablehd">
                    Product Notes
                  </th>
                  {selectedStatus == "5" && (
                    <>
                      <th
                        className="ordedetail__shdetails_tablehd"
                        // style={{ maxWidth: "150px" }}
                      >
                        Fulfilled
                      </th>
                      <th
                        className="ordedetail__shdetails_tablehd"
                        // style={{ color: "red" }}
                      >
                        Fulfilled Qty
                      </th>
                      <th
                        className="ordedetail__shdetails_tablehd"
                        // style={{ color: "red" }}
                      >
                        Fulfilment Notes
                      </th>
                    </>
                  )}
                  <th className="ordedetail__shdetails_tablehd">
                    Location History
                  </th>
                </thead>

                <tbody>
                  {data?.order_details?.map((item, index) => {
                    return (
                      <tr key={index}>
                        {" "}
                        <td className="ordedetail__shdetails_tableitem">
                          <img
                            src={getMediaSrc(item?.products)}
                            loading="lazy"
                            onError={(e) => {
                              e.target.src = noImage;
                            }}
                            style={{ cursor: "pointer" }}
                            alt=""
                            className="product__table_mainimg"
                            onClick={() => {
                              setfullview(getMediaSrc(item?.products));
                            }}
                          />
                        </td>
                        <td
                          className="ordedetail__shdetails_tableitem"
                          // style={{ maxWidth: "150px" }}
                        >
                          {item?.products?.name ?? "-"}
                        </td>
                        <td
                          className="ordedetail__shdetails_tableitem"
                          // style={{ maxWidth: "150px" }}
                        >
                          {item?.products?.code ?? "-"}
                        </td>
                        <td className="ordedetail__shdetails_tableitem">
                          {item?.item_quantity ?? "-"}
                        </td>
                        <td
                          className="ordedetail__shdetails_tableitem"
                          // style={{ maxWidth: "150px" }}
                        >
                          {item?.product_lot?.name ?? "-"}
                        </td>
                        <td
                          className="ordedetail__shdetails_tableitem"
                          // style={{ maxWidth: "150px" }}
                        >
                          {item?.products?.description ?? "-"}
                        </td>
                        <td className="ordedetail__shdetails_tableitem">
                          {item?.products?.length ?? "0"} x
                          {item?.products?.width ?? "0"} x
                          {item?.products?.height ?? "0"}
                        </td>
                        <td className="ordedetail__shdetails_tableitem">
                          {item?.products?.weight ?? "0"}
                          {item?.products?.weight_unit ?? ""}
                        </td>
                        <td className="ordedetail__shdetails_tableitem">
                          {item?.products?.fulfillment_cost ?? "-"}{" "}
                        </td>
                        <td
                          className="ordedetail__shdetails_tableitem"
                          // style={{ maxWidth: "150px" }}
                        >
                          {item?.note ?? "-"}
                        </td>
                        {selectedStatus == "5" && (
                          <>
                            <td className="ordedetail__shdetails_tableitem">
                              <div
                                style={{
                                  color:
                                    item?.is_saleable != 1 ? "red" : "green",
                                  display: "flex",
                                }}
                              >
                                {item?.is_saleable == 1 ? (
                                  <>
                                    <TiTick size={15} /> Yes
                                  </>
                                ) : (
                                  <>
                                    <TiTimes size={15} /> No
                                  </>
                                )}
                              </div>
                            </td>
                            <td
                              className="ordedetail__shdetails_tableitem"
                              style={{
                                color: item?.is_saleable != 1 ? "red" : "",
                              }}
                            >
                              {item?.saleable_item_quantity ?? "-"}
                            </td>
                            <td
                              className="ordedetail__shdetails_tableitem"
                              style={{
                                color:
                                  item?.note && item?.is_saleable != 1
                                    ? "red"
                                    : "",
                              }}
                            >
                              {item?.note ?? "-"}
                            </td>
                          </>
                        )}
                        {item?.products?.product_pallets?.length > 0 ? (
                          <td
                            className="ordedetail__shdetails_tableitem"
                            style={{ maxWidth: "235px" }}
                          >
                            <table>
                              <thead className="ssummary_innertable_head">
                                <th
                                  style={{
                                    borderRight: "0.5px solid grey",
                                    width: "50px",
                                    height: "20px",
                                  }}
                                  className="min-w-[50px]"
                                >
                                  Unit
                                </th>
                                <th
                                  style={{
                                    borderRight: "0.5px solid grey",
                                    width: "80px",
                                    height: "20px",
                                  }}
                                  className="min-w-[80px] "
                                >
                                  Lot/Batch
                                </th>
                                <th
                                  style={{
                                    borderRight: "0.5px solid grey",
                                    width: "50px",
                                    height: "20px",
                                  }}
                                  className="min-w-[50px] "
                                >
                                  Spot
                                </th>
                                <th
                                  style={{ width: "50px", height: "20px" }}
                                  className="min-w-[50px] "
                                >
                                  Qty
                                </th>
                              </thead>
                              <tbody>
                                {item?.products?.product_pallets?.map(
                                  (pallet, index) => {
                                    return (
                                      <>{getFulfilledQty(pallet, index)}</>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </td>
                        ) : (
                          <td className="ordedetail__shdetails_tableitem"></td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="invmodal__shdetails_cntright">
              {/* <div className="add__dimension_button">
                <div className="add__dimension_buttonicon">
                  <AddIcon />{" "}
                </div>
                <div className="add__dimension_buttontext">
                  Billed Dimension, Weight and Volume
                </div>
              </div> */}
            </div>
          </div>
        ) : (
          <div className="shipment_order_not_available">
            <p>Products Not Available </p>
          </div>
        )}
        <div className="invmodal__shipment_summaryhding package_detail_heading">
          Shipment Packages Details
        </div>
        {data?.shipment ? (
          <>
            <div className="invmodal__shdetails_content shipment__summary_package_details">
              <div className="invmodal__shdetails_cntleft">
                <div className="orderdetails__cnleft_heading">Declared</div>
                <table className="invmshdetails_cnleft_table">
                  <thead>
                    <th className="ordedetail__shdetails_tablehd">#</th>
                    <th className="ordedetail__shdetails_tablehd">
                      Description
                    </th>
                    <th className="ordedetail__shdetails_tablehd parsel_id_heading">
                      {type == "show" ? (
                        <div className="invmodal__shdetails_tablehdinner ">
                          Parcel ID{" "}
                          {data?.shipment?.parcel_id && copied !== "pid" ? (
                            <Tooltip title="Copy All Parcel IDs">
                              <span
                                className="invmodal__shdetails_modaltxtrtcopy"
                                onClick={handleCopyParcelId}
                              >
                                <MdOutlineContentCopy
                                  size={16}
                                  style={{ marginLeft: "10px" }}
                                />
                              </span>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                          {data?.shipment?.parcel_id && copied === "pid" ? (
                            <span className="invmodal__shdetails_modaltxtrtcopy">
                              <FcCheckmark
                                size={16}
                                style={{ marginLeft: "10px" }}
                              />
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        "Parcel ID"
                      )}
                    </th>
                    <th className="ordedetail__shdetails_tablehd">
                      Parcel Status
                    </th>
                    {/* <th className="ordedetail__shdetails_tablehd">
                      {type == "show" ? (
                        <div className="invmodal__shdetails_tablehdinner ">
                          Carrier Tracking
                          {data?.shipment?.c_p_shipment_details?.length &&
                          copied !== "sid" ? (
                            <Tooltip title="Copy All Tracking IDs">
                              <span
                                className="invmodal__shdetails_modaltxtrtcopy"
                                onClick={handleCopy}
                              >
                                <MdOutlineContentCopy
                                  size={16}
                                  style={{ marginLeft: "10px" }}
                                />
                              </span>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                          {data?.shipment?.c_p_shipment_details?.length &&
                          copied === "sid" ? (
                            <span className="invmodal__shdetails_modaltxtrtcopy">
                              <FcCheckmark
                                size={16}
                                style={{ marginLeft: "10px" }}
                              />
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        "Carrier Tracking"
                      )}
                    </th> */}
                    <th
                      className="ordedetail__shdetails_tablehd"
                      // style={{
                      //   borderTop: "2px solid #0c69f3",
                      // }}
                    >
                      Dim (LxWxH)
                    </th>
                    <th
                      className="ordedetail__shdetails_tablehd"
                      // style={{
                      //   borderTop: "2px solid #0c69f3",
                      // }}
                    >
                      Weight (Lbs)
                    </th>
                    <th
                      className="ordedetail__shdetails_tablehd"
                      // style={{
                      //   borderTop: "2px solid #0c69f3",
                      // }}
                    >
                      Volume (Cbf)
                    </th>
                  </thead>

                  <tbody>
                    {data?.shipment?.shipment_details?.map((item, index) => {
                      return (
                        <tr key={index}>
                          {" "}
                          <td className="ordedetail__shdetails_tableitem">
                            {index + 1}
                          </td>
                          <td className="ordedetail__shdetails_tableitem">
                            {item.description ?? "-"}
                          </td>
                          <td className="ordedetail__shdetails_tableitem">
                            {/* {data?.shipment?.parcel_id
                              ? data?.shipment?.parcel_id?.[index] ?? "-"
                              : "-"} */}
                            {item?.parcel_id ?? "-"}
                          </td>
                          <td className="ordedetail__shdetails_tableitem">
                            <div style={{ display: "flex" }}>
                              <StatusIcons
                                status={item?.tracking_status ?? "N/A"}
                              />
                              {item?.parcel_id &&
                                item?.tracking_status &&
                                type == "show" && (
                                  <>
                                    {" "}
                                    <Tooltip title="Click To View History">
                                      <div>
                                        <MdOutlineHistory
                                          size={15}
                                          className="parcel__history_icone"
                                          onClick={() =>
                                            setDetailHistoryModal(item?.id)
                                          }
                                        />
                                      </div>
                                    </Tooltip>
                                  </>
                                )}
                            </div>
                          </td>
                          {/* <td className="ordedetail__shdetails_tableitem">
                            {data?.shipment?.c_p_shipment_details
                              ? data?.c_p_shipment_details?.[index]
                                  ?.shipment_tracking_id ?? "-"
                              : "-"}
                          </td> */}
                          <td className="ordedetail__shdetails_tableitem">
                            {item.length ?? "-"} x{item.width ?? "-"} x
                            {item.height ?? "-"}
                          </td>
                          <td className="ordedetail__shdetails_tableitem">
                            {item.weight ?? "-"}
                            {/* {item.weight && "Lbs"} */}
                          </td>
                          <td className="ordedetail__shdetails_tableitem">
                            {item.volume ?? "-"} {/* {item.volume && "Cbf"} */}
                          </td>
                        </tr>
                      );
                    })}

                    <tr className="invmshdetails_cnleft_footer">
                      <td></td>
                      <td className="ordedetail__shdetails_tableitem invmshdetails_cnleft_fitemttl">
                        Total
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="ordedetail__shdetails_tableitem">
                        {data?.shipment?.total_weight ?? ""}{" "}
                        {data?.shipment?.total_weight && "Lbs"}
                      </td>
                      <td className="ordedetail__shdetails_tableitem">
                        {" "}
                        {data?.shipment?.volume ?? "-"}{" "}
                        {data?.shipment?.volume && "Cbf"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="invmodal__shdetails_cntright"></div>
            </div>
          </>
        ) : (
          <div className="shipment_order_not_available">
            <p>Shipment Not Available </p>
          </div>
        )}
      </div>
    </>
  );
}
