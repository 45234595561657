import React, { useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Chip,
  Modal,
  Box,
  Typography,
  Divider,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const RearrangeModal = ({ open, setOpen, items = [], onRearrange }) => {
  const [localItems, setLocalItems] = React.useState([]);

  const handleDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination || destination.index === source.index) {
      return;
    }

    const reorderedItems = Array.from(localItems);
    const [movedItem] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, movedItem);

    setLocalItems(reorderedItems);
  };

  const arraysEqual = (a, b) => {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (a[i].id !== b[i].id) return false;
    }
    return true;
  };
  useEffect(() => {
    if (items && !arraysEqual(items, localItems)) {
      setLocalItems(items);
    }
  }, [items]);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box
        sx={{
          width: 350,
          margin: "auto",
          marginTop: "10%",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: 24,
          padding: "24px",
          outline: "none",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" gutterBottom>
          Rearrange Levels
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          sx={{ marginBottom: "16px" }}
        >
          Drag and drop the Levels below to rearrange them in your preferred
          order.
        </Typography>
        <Divider sx={{ width: "100%", marginBottom: "16px" }} />
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="chips-droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ width: "100%" }}
              >
                {localItems.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          marginBottom: "8px",
                        }}
                      >
                        <Chip
                          label={item.name}
                          sx={{
                            cursor: "pointer",
                            width: "100%",
                            padding: "8px",
                            borderRadius: "4px",
                            boxShadow: "0px 1px 3px rgba(0,0,0,0.2)",
                            backgroundColor: "#f0f0f0",
                            userSelect: "none",
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "16px",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onRearrange(localItems);
              setOpen(false);
            }}
            sx={{ width: "48%" }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            onClick={() => setOpen(false)}
            sx={{
              width: "48%",
              backgroundColor: "#d32f2f",
              "&:hover": { backgroundColor: "#d32f2f" },
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RearrangeModal;
