import React from "react";
import Header from "./Components/Header";
import Details from "./Components/Details";
import Footer from "./Components/Footer";

import "./InvoiceModal.css";
const PrintableSlip = ({ data, invoiceRef, dates, Role }) => {
  return (
    <div className="ltlinvoice__modal_wrap warehouse__invoice_interval_print ">
      <div
        className="ltlinvoice__modal_box printable__invoice warehouse__invoice_interval_wrap"
        ref={invoiceRef}
      >
        <div className="printable__wrap_for_print">
          <Header data={data} dates={dates} type="Print" />
          <div className="warehouse__content_interval_wrap">
            <Details data={data} type="Print" Role={Role} />
          </div>

          {/* <Footer data={data} type="Print" /> */}
        </div>
      </div>
    </div>
  );
};

export default PrintableSlip;
