import React, { useState, useEffect, useRef } from "react";
import "./ProductStockCount.css";

// components imports
import AddStockCount from "./Components/AddStockCount";
import FinalizeStockCount from "./Components/FinalizeStockCount";
import ProductStockCountTable from "../../../Components/BoltTable/BoltTable";
import Tooltip from "../../../Components/Tooltip/Tooltip";
import Attachments from "./Components/Attachments/Attachments";
// apis imports
import {
  getCountStock,
  getWarehouseShippers,
} from "../../../services/warehouse.services";
// library imports
import { MdOutlineFilePresent } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import Badge from "@mui/material/Badge";
import { useSelector } from "react-redux";
import { FcPlus } from "react-icons/fc";

const ProductStockCount = ({ NonEditable = false, setSnack }) => {
  const user = useSelector((state) => state.user);

  // const [approvedWareHouses, setApprovedWareHouses] = useState([]);
  const [brandList, setBrandsList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [shippersList, setShippersList] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [open, setOpen] = useState(false);
  const [openAddStockCountModal, setOpenAddStockCountModal] = useState(false);

  const [attachmentsModal, setAttachmentsModal] = useState();

  const [pscList, setPscList] = useState([]);

  const columns = [
    {
      id: 1,
      label: "Date",
      render: (row) => <>{row?.created_at?.slice(0, 10) ?? "-"}</>,
      width: "200px",
    },
    {
      id: 2,
      label: "Reference",
      render: (row) => <>{row?.reference_no ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Warehouse",
      render: (row) => <>{row.warehouse.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "Brand",
      render: (row) => <>{row?.brands?.map((b) => b?.name + ",") ?? "-"}</>,
      width: "200px",
    },
    {
      id: 5,
      label: "Categories",
      render: (row) => <>{row?.categories?.map((c) => c?.name + ",") ?? "-"}</>,
      width: "200px",
    },
    {
      id: 6,
      label: "Sub Categories",
      render: (row) => (
        <>{row?.subCategories?.map((sc) => sc?.name + ",") ?? "-"}</>
      ),
      width: "200px",
    },
    {
      id: 7,
      label: "Uploaded File",
      render: (row) => (
        <div className="download__uploaded_files">
          {row?.stock_count_details?.length > 0 ? (
            <Tooltip title="Download Uploaded Files">
              <Badge
                badgeContent={row?.stock_count_details?.length}
                color="primary"
              >
                <MdOutlineFilePresent
                  size={15}
                  onClick={() => setAttachmentsModal(row?.stock_count_details)}
                  style={{ cursor: "pointer" }}
                />
              </Badge>
            </Tooltip>
          ) : (
            "-"
          )}
        </div>
      ),
      width: "200px",
    },
    {
      id: 8,
      label: "Generated File",
      render: (row) => (
        <div className="download__generated_files">
          {row?.file ? (
            <a href={row.file} download={row.file} target="_black">
              <Tooltip title="Download Generated File">
                <MdOutlineFilePresent size={15} style={{ cursor: "pointer" }} />
              </Tooltip>
            </a>
          ) : (
            "-"
          )}
        </div>
      ),
      width: "200px",
    },
    {
      id: 9,
      label: "Actions",
      render: (row) => (
        <>
          <div className="table_action__wrap">
            <Tooltip title="Edit Product Stock Count">
              <div className="table_action__btn" onClick={() => setOpen(row)}>
                <FaRegEdit size={10} />
                Edit
              </div>
            </Tooltip>
          </div>
        </>
      ),
      width: "200px",
      fixed: "right",
    },
  ];

  const columnsnonEditable = [
    {
      id: 1,
      label: "Date",
      render: (row) => <>{row?.created_at?.slice(0, 10) ?? "-"}</>,
      width: "200px",
    },
    {
      id: 2,
      label: "Reference",
      render: (row) => <>{row?.reference_no ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Warehouse",
      render: (row) => <>{row.warehouse.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "Brand",
      render: (row) => <>{row?.brands?.map((b) => b?.name + ",") ?? "-"}</>,
      width: "200px",
    },
    {
      id: 5,
      label: "Categories",
      render: (row) => <>{row?.categories?.map((c) => c?.name + ",") ?? "-"}</>,
      width: "200px",
    },
    {
      id: 6,
      label: "Sub Categories",
      render: (row) => (
        <>{row?.subCategories?.map((sc) => sc?.name + ",") ?? "-"}</>
      ),
      width: "200px",
    },
    {
      id: 7,
      label: "Uploaded File",
      render: (row) => (
        <div className="download__uploaded_files">
          {row?.stock_count_details?.length > 0 ? (
            <Tooltip title="Download Uploaded Files">
              <Badge
                badgeContent={row?.stock_count_details?.length}
                color="primary"
              >
                <MdOutlineFilePresent
                  size={10}
                  onClick={() => setAttachmentsModal(row?.stock_count_details)}
                  style={{ cursor: "pointer" }}
                />
              </Badge>
            </Tooltip>
          ) : (
            "-"
          )}
        </div>
      ),
      width: "200px",
    },
    {
      id: 8,
      label: "Generated File",
      render: (row) => (
        <div className="download__generated_files">
          {row?.file ? (
            <a href={row.file} download={row.file} target="_black">
              <Tooltip title="Download Generated File">
                <MdOutlineFilePresent size={10} />
              </Tooltip>
            </a>
          ) : (
            "-"
          )}
        </div>
      ),
      width: "200px",
    },
  ];

  const getStockCountList = async (page, perPage, filter) => {
    const response = await getCountStock(page, perPage, filter);
    if (!response.error) {
      setPscList(response?.data?.result?.stockCounts);
      // setApprovedWareHouses(
      //   response?.data?.result?.wareHouses?.data?.filter(
      //     (w) => w?.is_approved === "true"
      //   )
      // );
      setBrandsList(response?.data?.result?.brands);
      setCategoryList(response?.data?.result?.categories);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const fetchWarehouseShippers = async (id) => {
    try {
      let response = await getWarehouseShippers(id);
      setShippersList(response?.data?.result?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (user?.warehouse_id) {
      fetchWarehouseShippers(user.warehouse_id);
    }
  }, [user]);

  const stockAddingHandleClose = () => {
    setOpenAddStockCountModal(false);
  };

  const finalizeHandleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="product__stock_count_main_wrap">
        <div className="stockCount__main_wrap">
          <div className="product_stockcount_header">
            <div>
              <span className="product_stockcount_hdrheading">
                Stock Counts {NonEditable ? "(All Warehouse)" : "(All Clients)"}
              </span>
            </div>
            {!NonEditable && (
              <div className="product_stockcount_buttonsWrapper">
                <div onClick={() => setOpenAddStockCountModal(true)}>
                  <div className="product_stockcount_hdraddprod">
                    <FcPlus size={10} />
                    <span>ADD STOCK COUNT</span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>
            <ProductStockCountTable
              message={"No Product Count Stock Available To Display!"}
              searchPlaceHolder={"Search Product Count Stock"}
              columns={NonEditable ? columnsnonEditable : columns}
              data={pscList}
              getAllData={getStockCountList}
              refresh={refresh}
            />
          </div>
          <AddStockCount
            open={openAddStockCountModal}
            stockAddingHandleClose={stockAddingHandleClose}
            setRefresh={setRefresh}
            // approvedWareHouses={approvedWareHouses}
            brandList={brandList}
            categoryList={categoryList}
            setSnack={setSnack}
            shippersList={shippersList}
          />
          <FinalizeStockCount
            finalizeHandleClose={finalizeHandleClose}
            open={open}
            setPscList={setPscList}
            setSnack={setSnack}
          />
          <Attachments
            attachments={attachmentsModal}
            onClose={() => setAttachmentsModal()}
          />
        </div>
      </div>
    </>
  );
};

export default ProductStockCount;
