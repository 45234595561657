import React, { useState } from "react";
import "./Products.css";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { fetchAllWarehouseProducts } from "../../../services/product.service";
import { generateExcelFile } from "./productExportWarehouse";
import { TotalSpaceColumn, Dimensions, Weight } from "./ProductService";

import ProductTable from "../../../Components/BoltTable/BoltTable";
import Tooltip from "../../../Components/Tooltip/Tooltip";
import Snackbar from "../../../Components/SnackBar/SnackBar";

import ImagesModal from "../Components/ImagesModal/ImagesModal";
import ProductDetailsModal from "../Components/ProductDetailsModal/ProductDetailsModal";
import ProductHistoryModal from "../Components/HistoryModal/HistoryModal";
import WarehouseProductQty from "../Components/ProductQuantityModal/WarehouseProductQty";
import ProductSKUModal from "../Components/ProductSKUModal/ProductSKUModal";
import ProductLocationModal from "../Components/LocationModal/LocationModal";
import ProductFulfilmentCostModal from "../Components/FulfilmentCostModal/FulfilmentCostModal";
import LabelModal from "../Components/LabelModal/LabelModal";
import ProductDimensionModal from "../Components/ProductDimensionModal/ProductDimensionModal";
import ProductWeightModal from "../Components/ProductWeightModal/ProductWeightModal";
import StorageBreakdownModal from "../StorageBreakdown/StorageBreakdownModal";

import noImage from "../../../assets/images/noImage.png";
import { AiOutlineBarcode } from "react-icons/ai";
import { FaPallet } from "react-icons/fa";
import { FcPlus } from "react-icons/fc";
import {
  MdEditCalendar,
  MdHistory,
  MdOutlineEditCalendar,
} from "react-icons/md";
import { PiExportLight } from "react-icons/pi";

const WarehouseProduct = () => {
  const [Products, setProducts] = useState([]);
  const [imagesModal, setImagesModal] = useState();
  const [prodDetailsModal, setProdDetailsModal] = useState(false);
  const [prodHistoryModal, setProdHistoryModal] = useState(false);
  const [prodStorageModal, setProdStorageModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [updateQuantityModal, setUpdateQuantityModal] = useState(false);
  const [updateSKUModal, setUpdateSKUModal] = useState(false);
  const [updateLocationModal, setUpdateLocationModal] = useState(false);
  const [labelModal, setLabelModal] = useState(false);
  const [updateFulfilmentCostModal, setUpdateFulfilmentCostModal] =
    useState(false);
  const [prodDimensionModal, setProdDimensionModal] = useState(false);
  const [productWeightModal, setProductWeightModal] = useState(false);

  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const ImageUrl = (row) => {
    const url =
      row?.media.find((media) => media.is_primary === "true")?.file ||
      row?.media[0]?.file;
    return url || noImage;
  };

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 1,
      label: "Client",
      field_name: "username",
      searchable: true,
      render: (row) => <> {row.shipper?.username ?? "-"}</>,
      width: "150px",
      fixed: "left",
    },
    {
      id: 2,
      label: "Product ID",
      field_name: "code",
      searchable: true,
      render: (row) => <>{row.code ?? "-"}</>,
      width: "150px",
    },
    {
      id: 3,
      label: "Image",
      field_name: "image",
      render: (row) => (
        <img
          src={ImageUrl(row)}
          style={{ cursor: "pointer" }}
          onClick={() => setImagesModal(row?.id)}
          onError={(e) => {
            e.target.src = noImage;
          }}
          loading="lazy"
        />
      ),
    },
    {
      id: 4,
      label: "Name",
      field_name: "name",
      searchable: true,
      render: (row) => <>{row.name ?? "-"}</>,
      width: "140px",
    },
    {
      id: 5,
      label: "Sku",
      field_name: "product_sku",
      searchable: true,
      render: (row) => <>{row.product_sku ?? "-"}</>,
      width: "150px",
    },
    {
      id: 6,
      label: "Dimensions (L*W*H)",
      field_name: "dimensions",
      render: (row, rowIndex, handleFieldChange) => (
        <div
          onClick={() =>
            setProdDimensionModal({ row, rowIndex, handleFieldChange })
          }
          style={{ cursor: "pointer" }}
        >
          <Dimensions
            length={row?.length ?? 0}
            width={row?.width ?? 0}
            height={row?.height ?? 0}
            unit={row?.dimension_unit?.toLowerCase() === "in" ? "inch" : "cm"}
            space={true}
          />
        </div>
      ),
      width: "200px",
    },
    {
      id: 7,
      label: "Volume",
      field_name: "total-volume",
      render: (row) => (
        <>
          <TotalSpaceColumn
            length={row?.length ?? 0}
            width={row?.width ?? 0}
            height={row?.height ?? 0}
            unit={row?.dimension_unit === "in" ? "inch" : "cm"}
            quantity={row?.product_warehouse?.[0]?.["quantity"] ?? 0}
            space={true}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 8,
      label: "Weight",
      field_name: "weight",
      render: (row) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setProductWeightModal(row)}
        >
          <Weight
            weight={row?.weight ?? 0}
            unit={row?.weight_unit?.toLowerCase() === "lb" ? "lb" : "kg"}
            space={true}
          />
        </div>
      ),
    },
    {
      id: 9,
      label: "Quantity",
      field_name: "quantity",
      render: (row) => (
        <div
          onClick={() =>
            setUpdateQuantityModal({
              row,
            })
          }
          className="wptable__quantity_column"
        >
          {row?.product_warehouse[0]["quantity"] || ""}
        </div>
      ),
    },
    {
      id: 10,
      label: "Lot/Batch",
      field_name: "product_lots",
      render: (row) => (
        <>
          {row?.product_warehouse?.[0]?.product_lots?.length > 0 && (
            <>
              {row?.product_warehouse?.[0]?.product_lots?.map((lot, index) => (
                <div key={index}>
                  {lot?.name} ({lot?.product_pallets_sum_quantity})
                </div>
              ))}
            </>
          )}
        </>
      ),
      width: "140px",
    },
    {
      id: 11,
      label: "Pallet",
      field_name: "Pallet",
      render: (row) => (
        // <div
        //   className="product_dimension_parent_wrapper"
        //   style={{ maxWidth: "200px" }}
        // >
        //   <div
        //     className="product__notification_circle_wrapper"
        //     onClick={() => setProdStorageModal(row)}
        //   >
        //     <FaPallet
        //       style={{
        //         color: row?.product_warehouse?.[0]?.palletise ? "#0d6efd" : "",
        //       }}
        //       size={10}
        //     />
        //     {row?.product_warehouse?.[0]?.palletise ? (
        //       <div className="product__notification_circle">
        //         {row?.product_warehouse?.[0]?.product_pallets_count}
        //       </div>
        //     ) : null}
        //   </div>
        // </div>
        <div
          onClick={() => setProdStorageModal(row)}
          style={{ cursor: "pointer" }}
        >
          {row?.product_warehouse?.[0]?.product_pallets_count ?? "0"}
        </div>
      ),
    },
    {
      id: 12,
      label: "Brand",
      field_name: "brand",
      searchable: true,
      render: (row) => <>{row?.brand?.name.toUpperCase() || ""}</>,
      width: "140px",
    },
    {
      id: 13,
      label: "Categ / Div",
      field_name: "category",
      searchable: true,
      render: (row) => <>{row?.category?.name.toUpperCase() || ""}</>,
      width: "140px",
    },
    {
      id: 14,
      label: "Internal Sku",
      field_name: "internal_sku",
      render: (row) => (
        <div
          onClick={() =>
            setUpdateSKUModal({
              row,
            })
          }
          className="wptable__quantity_column"
        >
          {row?.product_warehouse[0]["warehouse_sku"] || "-"}
        </div>
      ),
    },
    {
      id: 15,
      label: "Location",
      field_name: "location",
      render: (row) => (
        <div
          onClick={() =>
            setUpdateLocationModal({
              row,
            })
          }
          className="wptable__quantity_column"
        >
          {row?.product_warehouse[0]["location"] || "-"}
        </div>
      ),
    },
    {
      id: 16,
      label: "Fulfilment Cost",
      field_name: "fulfilment_cost",
      render: (row) => (
        <div
          onClick={() =>
            setUpdateFulfilmentCostModal({
              row,
            })
          }
          className="wptable__quantity_column"
        >
          {row?.fulfillment_cost || "-"}
        </div>
      ),
    },
    {
      id: 17,
      label: "Action",
      field_name: "action",
      render: (row, rowIndex, handleFieldChange) => (
        <>
          <div className="table_action__wrap">
            <Tooltip title="View Product Details">
              <div
                className="table_action__btn"
                onClick={() =>
                  setProdDetailsModal({ row, rowIndex, handleFieldChange })
                }
              >
                <MdEditCalendar size={10} />
                VIEW
              </div>
            </Tooltip>
            <Tooltip title="Edit Product">
              <div
                className="table_action__btn"
                onClick={() =>
                  navigate("/warehouse-AddProduct", { state: row })
                }
              >
                <MdOutlineEditCalendar size={10} />
                Edit
              </div>
            </Tooltip>
            <Tooltip title="View Product History">
              <div
                className="table_action__btn"
                onClick={() =>
                  setProdHistoryModal({ row, rowIndex, handleFieldChange })
                }
              >
                <MdHistory
                  size={10}
                  style={{
                    // color: "#0C6EFD",
                    cursor: "pointer",
                  }}
                />
                History
              </div>
            </Tooltip>
            <Tooltip title="Download Product Barcodes">
              <div
                className="table_action__btn"
                onClick={() =>
                  setLabelModal({ row, rowIndex, handleFieldChange })
                }
              >
                <AiOutlineBarcode size={10} />
                Barcode
              </div>
            </Tooltip>
          </div>
        </>
      ),
      width: "400px",
      fixed: "right",
    },
  ];

  const getAllProducts = async (page, perPage, filter, nestedSearch) => {
    const response = await fetchAllWarehouseProducts(
      page,
      perPage,
      filter,
      user.warehouse_id,
      nestedSearch
    );
    if (!response.error) {
      setProducts(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  return (
    <>
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <LabelModal
        open={labelModal}
        setOpen={setLabelModal}
        setSnack={setSnack}
        labelData={{
          code: labelModal?.row?.code,
          name: labelModal?.row?.name,
          image: labelModal?.row?.media?.find((image) => image?.is_primary)
            ?.file,
        }}
        title="Product's Barcode"
        subtitle={`${labelModal?.row?.full_name ?? ""} (${
          labelModal?.row?.code ?? ""
        })`}
      />
      <ProductDimensionModal
        open={prodDimensionModal}
        setOpen={setProdDimensionModal}
        Products={Products}
        setProducts={setProducts}
        setSnack={setSnack}
      />
      <ProductWeightModal
        open={productWeightModal}
        setOpen={setProductWeightModal}
        Products={Products}
        setProducts={setProducts}
        setSnack={setSnack}
      />
      <ProductFulfilmentCostModal
        open={updateFulfilmentCostModal}
        setOpen={setUpdateFulfilmentCostModal}
        setProducts={setProducts}
        setSnack={setSnack}
      />
      <ProductLocationModal
        open={updateLocationModal}
        setOpen={setUpdateLocationModal}
        setProdHistoryModal={setProdHistoryModal}
        setProducts={setProducts}
        setSnack={setSnack}
      />
      <ProductSKUModal
        open={updateSKUModal}
        setOpen={setUpdateSKUModal}
        setProdHistoryModal={setProdHistoryModal}
        setProducts={setProducts}
        setSnack={setSnack}
      />
      <WarehouseProductQty
        open={updateQuantityModal}
        setOpen={setUpdateQuantityModal}
        setProdHistoryModal={setProdHistoryModal}
        setProducts={setProducts}
        setSnack={setSnack}
      />
      <ProductDetailsModal
        open={prodDetailsModal}
        setOpen={setProdDetailsModal}
        setSnack={setSnack}
      />
      <ProductHistoryModal
        open={prodHistoryModal}
        setOpen={setProdHistoryModal}
      />
      <ImagesModal
        open={imagesModal}
        setOpen={setImagesModal}
        setSnack={setSnack}
        Products={Products}
        setProducts={setProducts}
      />
      <StorageBreakdownModal
        open={prodStorageModal}
        setOpen={setProdStorageModal}
        setProducts={setProducts}
        setSnack={setSnack}
      />
      <div className="products__page_header">
        <div className="products__page_headerlft">
          <span className="products__page_hdrheading">Products List</span>
        </div>

        <div className="products__page_headerrt_wrap">
          <div
            className="products__page_headerrt products__page_hdraddprod"
            onClick={() =>
              generateExcelFile(Products?.data, selectedRows, setSnack)
            }
          >
            <PiExportLight size={10} />
            <span>Export Selected</span>
          </div>
          <Link
            to="/warehouse-AddProduct"
            className="products__page_hdraddprod"
          >
            <FcPlus size={10} />
            <span>ADD PRODUCT</span>
          </Link>
        </div>
      </div>
      <div className="custom__table_size1 actionbtn__needtobe_fixwidth">
        <ProductTable
          message="No Products to display!"
          searchPlaceHolder="Search Products"
          columns={columns}
          data={Products}
          getAllData={getAllProducts}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          selectable={true}
        />
      </div>
    </>
  );
};

export default WarehouseProduct;
