import * as React from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { HiMiniXCircle } from "react-icons/hi2";
import { IoMdWarning } from "react-icons/io";
import { MdPending } from "react-icons/md";
import creationIcon from "../../assets/icons/creationIcon.png";

export default function StatusIcons({ status }) {
  const statusLower = status?.toLowerCase() ?? "";
  const statusUpper = status?.toUpperCase() ?? "-";
  if (status) {
    if (
      [
        "delivered",
        "confirmed",
        "fulfilled",
        "paid",
        "extra charge paid",
        "success",
        "approved",
        "received",
      ].includes(statusLower)
    ) {
      return (
        <div style={{ display: "flex", color: "#000" }}>
          <IoIosCheckmarkCircle
            style={{ color: "#24A149", marginRight: "1px" }}
            size={13}
          />
          <div className="content__wrapping_boltTable">{statusUpper}</div>
        </div>
      );
    } else if (
      ["failed", "cancelled", "dispute lost", "false"].includes(statusLower)
    ) {
      return (
        <div style={{ display: "flex", color: "#000" }}>
          <HiMiniXCircle
            style={{ color: "#DA1E27", marginRight: "1px" }}
            size={13}
          />
          <div className="content__wrapping_boltTable">{statusUpper}</div>
        </div>
      );
    } else if (
      ["attention", "disputed", "notReceived", "dispute"].includes(statusLower)
    ) {
      return (
        <div style={{ display: "flex", color: "#000" }}>
          <IoMdWarning className="icon__status_boltTable"
            style={{ color: "#DA1E27", marginRight: "1px" }}
            size={13}
          />
          <div className="content__wrapping_boltTable">{statusUpper}</div>
        </div>
      );
    } else if (["creation"].includes(statusLower)) {
      return (
        <div style={{ display: "flex", color: "#000" }}>
          <MdPending className="icon__status_boltTable"
            style={{ color: "#24A149", marginRight: "1px" }}
            size={13}
          />
          <div className="content__wrapping_boltTable">{statusUpper}</div>
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex", color: "#000" }}>
          <MdPending className="icon__status_boltTable"
            style={{ color: "#0043CE", marginRight: "1px" }}
            size={13}
          />
          <div className="content__wrapping_boltTable">{statusUpper}</div>
        </div>
      );
    }
  }
}
