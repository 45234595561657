import React, { useState } from "react";
import AttachmentsDownloadModal from "../Attachments/Attachments";
import noImage from "../../../../../../assets/images/noImage.png";
import pdfIcon from "../../../../../../assets/icons/pdfIcon.png";
import excelIcon from "../../../../../../assets/icons/excelIcon.png";
import docsIcon from "../../../../../../assets/icons/docsIcon.png";
import { checkFileType } from "../../../../../../helpers/helpers";
import ImagesModal from "../../../../../Shipper/Customer/MainPages/Orders/Components/OrderHistoryModal/ImagesModal/ImagesModal";

export default function Trackingandnotes({ data, type = "show" }) {
  const [attachmentsModal, setAttachmentsModal] = useState();
  const [fullview, setfullview] = useState();

  return (
    <>
      <ImagesModal setOpen={setfullview} open={fullview} />
      <div className="shipment_attachments_main_wrap">
        <div className="shipment_tracking_wrap">
          {/* shipment tracking */}
          <div className="invmodal__shipment_detailshding">
            Shipment Tracking
          </div>
          {data?.shipment?.shipment_histories?.length > 0 ? (
            <>
              <div className="invmodal__shshiptracking_grid  shipment_tracking_wrap">
                <div className="invmodal__shdetails_gridhd">
                  Shipment Status
                </div>
                <div className="invmodal__shdetails_gridhd">Status Date</div>
                <div className="invmodal__shdetails_gridhd">Status Time</div>
                <div className="invmodal__shdetails_gridhd">Last Location</div>
                {data?.shipment?.shipment_histories?.map((history) => {
                  return (
                    <>
                      <div className="invmodal__shdetails_griditem">
                        {history?.ShipmentStatus ?? "-"}
                      </div>
                      <div className="invmodal__shdetails_griditem">
                        {history?.StatusDate?.slice(0, 10) ?? "-"}
                      </div>
                      <div className="invmodal__shdetails_griditem">
                        {" "}
                        {history?.StatusDate?.slice(11, 16) ?? "-"}
                      </div>
                      <div className="invmodal__shdetails_griditem">
                        {" "}
                        {history?.LastLocation ?? "-"}
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          ) : (
            <div className="shipment_order_not_available">
              <p>
                {data?.shipment?.shipment_histories
                  ? "No Shipment History Availabe"
                  : "Shipment Not Available"}
              </p>
            </div>
          )}
          {/* shipment tracking */}
        </div>
        <div className="notes_attachments_wrap notes_attachment_col">
          {/* Notes Attachment */}

          <div className="invmodal__shipment_detailshding">
            Order Notes & Attachments
          </div>
          {data?.order_notes?.length > 0 ? (
            <>
              <div className="invmodal__shshiptracking_grid   shipment_tracking_wrap">
                <div className="invmodal__shdetails_gridhd">Note</div>
                <div className="invmodal__shdetails_gridhd">Note Date</div>
                <div className="invmodal__shdetails_gridhd">Note Time</div>
                <div className="invmodal__shdetails_gridhd">
                  Attachments Included
                </div>
                {data?.order_notes?.map((note, index) => {
                  return (
                    <>
                      <div className="invmodal__shdetails_griditem">
                        <div className="tracking_notes_conten_box">
                          {note?.note ?? "-"}
                        </div>
                      </div>
                      <div className="invmodal__shdetails_griditem">
                        {note?.created_at?.slice(0, 10) ?? "-"}
                      </div>
                      <div className="invmodal__shdetails_griditem">
                        {" "}
                        {note?.created_at?.slice(11, 16) ?? "-"}
                      </div>
                      <div className="invmodal__shdetails_griditem">
                        {" "}
                        {index === attachmentsModal && (
                          <AttachmentsDownloadModal
                            onClose={() => setAttachmentsModal()}
                            open={note?.order_attachments}
                            type="user"
                          />
                        )}
                        {note?.order_attachments?.length > 0 &&
                        type !== "print" ? (
                          <div className="order_detail_view_thumbnails_wrap_main">
                            <div className="order_detail_view_thumbnails_wrap">
                              {note?.order_attachments?.map((list, index) => (
                                <img
                                  src={
                                    list?.file &&
                                    checkFileType(list.file) === "Image"
                                      ? list.file
                                      : checkFileType(list.file) === "PDF"
                                      ? pdfIcon
                                      : checkFileType(list.file) === "Excel"
                                      ? excelIcon
                                      : checkFileType(list.file) === "Document"
                                      ? docsIcon
                                      : noImage
                                  }
                                  alt={`Attachment ${index + 1}`}
                                  onError={(e) => {
                                    e.target.src = noImage;
                                  }}
                                  loading="lazy"
                                  onClick={() => {
                                    if (checkFileType(list.file) === "Image") {
                                      setfullview(list?.file || "");
                                    } else {
                                      window.open(list?.file, "_blank");
                                    }
                                  }}
                                />
                              ))}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          ) : (
            <div className="not_data_found_shipment_attachment">
              <p>Notes & Attachments Not Available</p>
            </div>
          )}
          {/* Notes Attachment */}
        </div>
      </div>
    </>
  );
}
