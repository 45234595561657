import React, { useState } from "react";
import "./pendingpayments.css";

// components imports
import PendingPaymentsTable from "../../../../../Components/BoltTable/BoltTable";
import ImageModal from "../ImageModal/ImageModal";
import ApprovePayment from "../ApprovePayment/ApprovePayment";
import InvoiceModal from "../../../../../Components/InvoiceModal/InvoiceModal";
import { removeUnderscoresAndCapitalize } from "../../../../../helpers/helpers";

// apis imports
import { fetchPendingPayments } from "../../../../../services/AdminServices/PaymentsAdmin/payments.services";

// library imports
import { MdOutlineStickyNote2 } from "react-icons/md";
import noImage from "../../../../../assets/images/noImage.png";
import Tooltip from "../../../../../Components/Tooltip/Tooltip";

const PendingPayments = ({ setSnack, snack }) => {
  const [payments, setPayments] = useState([]);

  const [imageModal, setImageModal] = useState();
  const [invoiceModal, setInvoiceModal] = useState();
  const [approvePaymentModal, setApprovePaymentModal] = useState();
  const [refresh, setRefresh] = useState("0");

  const getShippersPendingPayments = async (page, perPage, filter) => {
    const response = await fetchPendingPayments(page, perPage, filter);
    if (!response.error) {
      setPayments(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const columns = [
    {
      id: 1,
      label: "Client Name",
      render: (row) => <>{row?.user?.username ?? "-"}</>,
      width: "200px",
    },
    {
      id: 2,
      label: "Transaction ID",
      render: (row) => <>{row?.transaction_id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Date",
      render: (row) => <>{row.created_at?.slice(0, 10) ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "Payment Type",
      render: (row) => (
        <>
          {row?.payment_type
            ? removeUnderscoresAndCapitalize(row.payment_type)
            : "-"}
        </>
      ),
      width: "200px",
    },
    {
      id: 5,
      label: "Bolt Tracking ID",
      render: (row) => <>{row.shipment?.tracking_id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 6,
      label: "Current Balance",
      render: (row) => <>{row?.user?.balance ?? "-"}</>,
      width: "200px",
    },
    {
      id: 7,
      label: "Amount Paid",
      render: (row) => <>{row?.balance ?? ""}</>,
      width: "200px",
    },
    {
      id: 8,
      label: "Status",
      field_name: "status",
      render: (row) => <>{row?.status?.toUpperCase() ?? "-"}</>,
      width: "200px",
    },
    {
      id: 9,
      label: "Reference",
      render: (row) => (
        <>
          {row?.shipment_id ? (
            <div className="table_action__wrap">
              <Tooltip title="Invoice">
                <div
                  className="table_action__btn"
                  onClick={() => setInvoiceModal(row?.shipment_id)}
                >
                  <MdOutlineStickyNote2 size={10} />
                  Invoice
                </div>
              </Tooltip>
            </div>
          ) : (
            <img
              src={row.file || noImage}
              style={{ cursor: "pointer" }}
              onClick={() => setImageModal(row.file || noImage)}
              onError={(e) => {
                e.target.src = noImage;
              }}
            />
          )}
        </>
      ),
      width: "200px",
    },
    {
      id: 10,
      label: "Actions",
      render: (row) => (
        <div className="table_action__wrap">
          <Tooltip title="Approve Balance">
            <div
              className="table_action__btn"
              onClick={() => setApprovePaymentModal(row)}
            >
              <MdOutlineStickyNote2 size={10} />
              Approve
            </div>
          </Tooltip>
        </div>
      ),
      width: "200px",
      fixed: "right",
    },
  ];
  return (
    <div>
      <div>
        <div className="payment_pending_header">
          <div>
            <span className="payment_pending_heading">Payments List</span>
          </div>
        </div>
        <ImageModal
          open={imageModal}
          setOpen={setImageModal}
          setSnack={setSnack}
        />
        <InvoiceModal
          open={!!invoiceModal}
          data={invoiceModal}
          setOpen={setInvoiceModal}
          // type="editable"
          actions={false}
        />
        <ApprovePayment
          open={!!approvePaymentModal}
          setOpen={setApprovePaymentModal}
          previousData={approvePaymentModal}
          getShippersPendingPayments={() =>
            setRefresh(Math.floor(Math.random() * 100) + 1)
          }
        />
        <PendingPaymentsTable
          message={"No Payments Available To Display!"}
          searchPlaceHolder="Search Pending Payments"
          columns={columns}
          data={payments}
          getAllData={getShippersPendingPayments}
          refresh={refresh}
        />
      </div>
    </div>
  );
};

export default PendingPayments;
