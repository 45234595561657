import React, { useEffect, useState } from "react";
import "./ApprovePayment.css";

// components imports
import Loader from "../../../../../../../../Components/CircularLoader/CircularLoader";

// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";

import { updateLTlBalance } from "../../../../../../../../services/ShipperServices/Payments/payments.services";

export default function ApprovePayment({
  open,
  setOpen,
  setSnack,
  setRefresh,
  previousData,
}) {
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = async () => {
    setLoading(true);
    const response = await updateLTlBalance(previousData?.id);
    if (!response.error) {
      setLoading(false);
      setRefresh(Math.floor(Math.random() * 100) + 1);
      handleClose();
    } else {
      setLoading(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="apprv__payment_modal">
          <div className="apprv__payment_modalheading">Approve Payment</div>
          <div className="apprv__payment_modalcontent">
            <div className="apprv__payment_modalrow">
              <div className="apprv__payment_modalinputwrap">
                Amount Paid: {previousData?.balance}
              </div>
            </div>
            <div className="apprv__payment_modalfooter">
              <div className="apprv__payment_modalcncl" onClick={handleClose}>
                Cancel
              </div>
              <div
                className={`apprv__payment_modalsave ${
                  loading && "add__vehmodal_savedisabled"
                }`}
                onClick={handleSave}
              >
                {loading ? <Loader /> : "Save"}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
