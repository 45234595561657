import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { FaRegTimesCircle } from "react-icons/fa";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import "./fullpagemodal.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullPageModal({ children, open, setOpen, heading }) {
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div className="full__screen_modalheader">
          <h2 className="full__screen_modalheaderheading">{heading}</h2>
          <div className="full__screen_modalheaderclose">
            <div
              className="full__screen_modalheaderclosebtn"
              onClick={handleClose}
            >
              <IconButton>
                <FaRegTimesCircle size={25} color="lightgrey" />
              </IconButton>
            </div>
          </div>
        </div>
        <div className="full__screen_modalcontent">{children}</div>
      </Dialog>
    </div>
  );
}
