import axios from "./axiosInstance";

export const fetchAllShipments = async (token, page, param) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `get-all-shipments/all?${page ? `page=${page}` : ""}${param ?? ""}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchHajexShipments = async (token, page, param) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `get-all-shipments/Hajex?${page ? `page=${page}` : ""}${param ?? ""}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchCustomShipments = async (token, page, param) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `get-all-shipments/Custom?${page ? `page=${page}` : ""}${param ?? ""}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const searchAllShipments = async (token, name, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `get-all-shipments/all?name=${name}&&${page && `page=${page}`}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const searchHajexShipments = async (token, name, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `get-all-shipments/Hajex?name=${name}&&${page && `page=${page}`}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const searchCustomShipments = async (token, name, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `get-all-shipments/Custom?name=${name}&&${page && `page=${page}`}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const searchCarrierShipments = async (token, name, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `get-all-shipments/carrier?name=${name}&&${page && `page=${page}`}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchCarrierShipments = async (token, page, param) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `get-all-shipments/carrier?${page ? `page=${page}` : ""}${param ?? ""}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const assignDriver = async (token, shipment, driver, type) => {
  let response = { data: null, error: null };
  let data =
    type.toLowerCase() === "pickup"
      ? { pickup_driver: parseInt(driver) }
      : { delivery_driver: parseInt(driver) };
  try {
    const res = await axios.post(`custom-shipment/update/${shipment}`, data, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error.response.data.message };
  }
  return response;
};

export const arrangePickup = async (token, companyId, shipmentId, data) => {
  let response = { data: null, error: null };

  try {
    const res = await axios.post(
      `create-pickup/${companyId}/${shipmentId}`,
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
    // console.log(res);
  } catch (error) {
    response = { ...response, error: error.response.data.message };
  }
  return response;
};

export const addBalance = async (token, data) => {
  let response = { data: null, error: null };

  try {
    const res = await axios.post(`payment/store`, data, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
    // console.log(res);
  } catch (error) {
    response = { ...response, error: error.response.data.message };
  }
  return response;
};

export const fetchHajexCarriers = async (token, page, param) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `user-shipping-companies/get/1?${page ? `page=${page}` : ""}${
        param ?? ""
      }`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchCustomCarriers = async (token, page, param) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `user-shipping-companies/get/0?${page ? `page=${page}` : ""}${
        param ?? ""
      }`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchTruckerCarriers = async (token, page, param) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `users/get-all-truckers?${page ? `page=${page}` : ""}${param ?? ""}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const searchHajexCarriers = async (token, name, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `user-shipping-companies/get/1?name=${name}&&${page && `page=${page}`}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const searchCustomCarriers = async (token, name, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `user-shipping-companies/get/0?name=${name}&&${page && `page=${page}`}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const updateCarrierStatus = async (token, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `user-shipping-companies/update-status/${data.record_id}`,
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const cancelShipment = async (token, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`cancel-shipment/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const updateServiceStatus = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `user-shipping-companies/update-services/${id}`,
      data
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateAccountName = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`user-shipping-companies/edit`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const deleteCarrier = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`user-shipping-companies/delete`, {
      record_id: id,
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const addCustomCarrier = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`user-shipping-companies/store`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
