import Stack from "@mui/material/Stack";
import Buttons from "./Buttons";

export default function RadioStack(props) {
  const {
    List,
    SetList,
    Type,
    Type1,
    Type2,
    Type3,
    Disable = "button",
  } = props;

  const handletype = (e) => {
    const { name } = e.target;
    SetList({
      ...List,
      [Type]: name,
      QuoteCheck: false,
    });
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      <Buttons
        List={List}
        Name={Type1}
        HandleChange={handletype}
        Variant={List[Type] === Type1 ? "contained" : "outlined"}
        Color={List[Type] === Type1 ? "primary" : "inherit"}
      />

      <Buttons
        List={List}
        Name={Type2}
        HandleChange={handletype}
        Variant={List[Type] === Type2 ? "contained" : "outlined"}
        Color={List[Type] === Type2 ? "primary" : "inherit"}
        TYPE={Disable}
      />

      {Type3 && (
        <Buttons
          List={List}
          Name={Type3}
          HandleChange={handletype}
          Variant={List[Type] === Type3 ? "contained" : "outlined"}
          Color={List[Type] === Type3 ? "primary" : "inherit"}
          TYPE={Disable}
        />
      )}
    </Stack>
  );
}
