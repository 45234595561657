import React, { useEffect, useState } from "react";
import "./packagesdimensionsmodal.css";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { updateProductsDetails } from "../../../../services/product.service";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdAddCircleOutline } from "react-icons/md";
import CircularLoader from "../../../../Components/CircularLoader/CircularLoader";

export default function PackagesDimensionsModal({
  open,
  setOpen,
  setSnack,
  setProducts,
}) {
  const [PKGDimensions, setPKGDimensions] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [changed, setchanged] = useState(false);

  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    let clone = [...PKGDimensions];
    clone[index][name] = value;
    setPKGDimensions(clone);
  };

  const handleClose = () => {
    setOpen(false);
    setData([]);
    setPKGDimensions([]);
  };

  const handleSave = async () => {
    setLoading(true);
    const response = await updateProductsDetails(
      { package_details: PKGDimensions },
      open?.id
    );

    if (!response.error) {
      setProducts((prevProducts) => {
        return {
          ...prevProducts,
          data: prevProducts?.data?.map((product, index) => {
            if (product.id === open?.id) {
              return {
                ...product,
                package_details: response?.data?.result?.package_details,
              };
            } else if (open?.parent_id == product.id) {
              let result = product.children.map((children, idx) => {
                if (children.id === open?.id) {
                  return {
                    ...children,
                    package_details: response?.data?.result?.package_details,
                  };
                } else {
                  return {
                    ...children,
                  };
                }
              });
              return {
                ...product,
                children: result,
              };
            }
            return product;
          }),
        };
      });
      // setOpen(data);
      setData(response?.data?.result?.package_details);
      setchanged(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Product Updated successfully",
          severity: "success",
        };
      });
      setLoading(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open?.package_details) {
      let deepObj = deepClone(open.package_details);
      setData(open.package_details ? Object.values(deepObj) : []);
      setPKGDimensions(Object.values(open?.package_details));
    }
  }, [open]);

  useEffect(() => {
    let hasChanges = false;
    // if (open?.package_details) {
    for (let i = 0; i < data?.length; i++) {
      if (
        data[i]?.weight != PKGDimensions[i]?.weight ||
        data[i]?.length != PKGDimensions[i]?.length ||
        data[i]?.width != PKGDimensions[i]?.width ||
        data[i]?.height != PKGDimensions[i]?.height ||
        data[i]?.description != PKGDimensions[i]?.description
      ) {
        hasChanges = true;
      }
    }
    setchanged(hasChanges);
    // }
  }, [open, PKGDimensions, data]);

  function deepClone(obj) {
    if (obj === null || typeof obj !== "object") {
      return obj; // Return non-objects and null as is
    }
    if (Array.isArray(obj)) {
      return obj.map(deepClone); // Recursively deep clone each array element
    }
    // Recursively deep clone each object property
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, deepClone(value)])
    );
  }

  const handelRemove = (indexToRemove) => {
    setPKGDimensions((prevArray) =>
      prevArray.filter((_, index) => index !== indexToRemove)
    );
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="prod__pckgdetails_modal">
          <div className="prod__dimension_modalheading">
            Edit Package Dimension's
          </div>
          <div className="prod__dimensionmdl_formbox">
            {PKGDimensions?.map((item, index) => {
              return (
                <div className="prod__pckgdetails_formrow" key={index}>
                  <TextField
                    size="small"
                    className="prod__dimensionmdl_formbox_field"
                    type="number"
                    onChange={(e) => handleFieldChange(e, index)}
                    label="Length"
                    name="length"
                    value={item?.length || ""}
                    variant="outlined"
                  />
                  <TextField
                    size="small"
                    className="prod__dimensionmdl_formbox_field"
                    type="number"
                    onChange={(e) => handleFieldChange(e, index)}
                    label="Width"
                    name="width"
                    value={item?.width || ""}
                    variant="outlined"
                  />
                  <TextField
                    size="small"
                    className="prod__dimensionmdl_formbox_field"
                    type="number"
                    onChange={(e) => handleFieldChange(e, index)}
                    label="Height"
                    name="height"
                    value={item?.height || ""}
                    variant="outlined"
                  />
                  <TextField
                    size="small"
                    className="prod__dimensionmdl_formbox_field"
                    type="number"
                    onChange={(e) => handleFieldChange(e, index)}
                    label="Weight"
                    name="weight"
                    value={item?.weight || ""}
                    variant="outlined"
                  />
                  <TextField
                    size="small"
                    className="prod__dimensionmdl_formbox_field"
                    type="text"
                    onChange={(e) => handleFieldChange(e, index)}
                    label="Description"
                    name="description"
                    value={item?.description || ""}
                    variant="outlined"
                  />
                  <div style={{ cursor: "pointer" }}>
                    <FaRegTrashAlt
                      size={13}
                      onClick={() => handelRemove(index)}
                    />
                  </div>
                </div>
              );
            })}
            <div
              className="prod__pckgdetails_addmore"
              onClick={() => {
                setPKGDimensions([
                  ...PKGDimensions,
                  {
                    length: null,
                    width: null,
                    height: null,
                    weight: null,
                    description: null,
                  },
                ]);
                setData([
                  ...data,
                  {
                    length: null,
                    width: null,
                    height: null,
                    weight: null,
                    description: null,
                  },
                ]);
              }}
            >
              <MdAddCircleOutline
                className="prod__pckgdetails_addvarbtnicn"
                size={14}
              />
              Add More
            </div>
          </div>
          <div className="prod__dimension_modalfooter">
            <div className="prod__dimension_modalcncl" onClick={handleClose}>
              Close
            </div>
            {changed && (
              <div className="prod__dimension_modalsave" onClick={handleSave}>
                {loading ? <CircularLoader /> : "Save"}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
