import React from "react";
import "./Requirements.css";
const DayAndRoseRequirements = () => {
  return (
    <div className="cuscarrier__reqrmnts_box">
      Contact Day & Ross at
      <a href={`mailto:mydayross@dayandrossinc.ca`} className="email_space">
        mydayross@dayandrossinc.ca
      </a>
      to obtain your Email Address, Password and Billing Account. These API
      credentials will allow you to connect your custom Day & Ross account to
      the application, enabling you to access Day & Ross shipping rates for your
      account and display them as quotes.
    </div>
  );
};

export default DayAndRoseRequirements;
