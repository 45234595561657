import React from "react";
import "./PaymentsSettings.css";

// components imports
import UserFormula from "../UserFormula/UserFormula";
import UserMarkup from "../UserMarkup/DefaultMarkup";
import WarehouseFormula from "../WarehouseFormula/WarehouseFormula";
// apis imports
// library imports

import TabsComponent from "../../../../../Components/Common/TabsComponent";

const PaymentsSettings = ({ setSnack }) => {
  const [tab, setTab] = React.useState(0);
  const [nestedtab, setNestedTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleNestedTabChange = (event, newValue) => {
    setNestedTab(newValue);
  };

  return (
    <div className="dispatcher__screen" style={{ maxWidth: "100%" }}>
      <TabsComponent
        items={[
          {
            label: "Shipper",
            style: { fontWeight: "bold" },
            value: 0,
          },
          { label: "Warehouse", style: { fontWeight: "bold" }, value: 1 },
        ]}
        tab={tab}
        handleTabChange={handleTabChange}
      />

      {tab === 0 && (
        <div className="payments__settings_wrap">
          <div className="payments__settings_card">
            <div className="admnedit__user_modalheading">Default</div>
            <TabsComponent
              items={[
                {
                  label: "Tier",
                  style: { fontWeight: "bold" },
                  value: 0,
                },
                { label: "Markup", style: { fontWeight: "bold" }, value: 1 },
              ]}
              tab={nestedtab}
              handleTabChange={handleNestedTabChange}
            />
            {nestedtab === 0 && (
              <UserFormula setSnack={setSnack} user={false} />
            )}
            {nestedtab === 1 && <UserMarkup setSnack={setSnack} user={false} />}
          </div>
        </div>
      )}
      {tab === 1 && (
        <div className="payments__settings_wrap">
          <div className="payments__settings_card">
            <WarehouseFormula setSnack={setSnack} user={false} />
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentsSettings;
