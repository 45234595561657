import React, { useState, useEffect } from "react";
import "./settings.css";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  fetchAllPermissions,
  fetchAllRoles,
  fetchShipperUsers,
} from "../../../../../services/settings.services";
import { useSelector } from "react-redux";
import SideBar from "./Components/SideBar/SideBar";
import ManagePermissions from "./Components/ManagePermissions/ManagePermissions";
import CarrierTableLoader from "../../../../../Components/CarrierTableLoader/CarrierTableLoader";
import Sad from "@mui/icons-material/SentimentVeryDissatisfied";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function PermissionsSettings(props) {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  //create roles

  const [permisions, setPermissions] = useState([]);
  const [permisionsAlter, setPermissionsAlter] = useState([]);
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    text: "",
    severity: "",
  });

  const fetchPermissionsData = async () => {
    const saved = await fetchAllPermissions(user.token);
    setPermissions(saved?.data?.result?.permissions?.data);
    const array = saved?.data?.result?.permissions?.data?.map((obj) => {
      const newKey1 = false;
      const newKey2 = obj.children.map(() => ({
        subKey: false,
      }));
      return {
        key1: newKey1,
        key2: newKey2,
      };
    });
    setPermissionsAlter(array);
  };
  const fetchAllRolesData = async () => {
    const saved = await fetchAllRoles(user.token);
    setRoles(
      saved?.data?.result?.data.map((role) => {
        return {
          id: role.id,
          name: role.name,
          permissions: role.permissions.map((p) => p.id),
        };
      })
    );
  };
  const fetchShipperUsersData = async () => {
    const saved = await fetchShipperUsers(user.token);
    setUsers(
      saved?.data?.result?.data.map((user) => {
        return {
          id: user.id,
          name: user.username,
          roleName: user?.roles?.length > 0 ? user?.roles[0]?.name : null,
          roles: user.roles.map((r) => r.id),
          permissions: [
            ...user.permissions.map((p) => p.id),
            ...user.roles.flatMap((role) =>
              role.permissions.map((permission) => permission.id)
            ),
          ],
          rolesPermissions: user.roles.flatMap((role) =>
            role.permissions.map((permission) => permission.id)
          ),
        };
      })
    );
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      // Use Promise.all to wait for both requests to complete
      const [permissionsData, rolesData] = await Promise.all([
        fetchPermissionsData(),
        fetchAllRolesData(),
        fetchShipperUsersData(),
      ]);

      // Turn off the loading state
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error here
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleSnackClose = () => {
    setSnack({ ...snack, open: false });
  };
  return (
    <>
      <div className="parent_wraper">
        {/* sidebar code  */}
        <SideBar active="0_1" />
        <div className="dash_table_wrapper">
          {/* right side secions  */}
          <Snackbar
            open={snack.open}
            autoHideDuration={6000}
            onClose={handleSnackClose}
          >
            <Alert
              onClose={handleSnackClose}
              severity={snack.severity}
              sx={{ width: "100%" }}
            >
              {snack.text}
            </Alert>
          </Snackbar>
          {roles.length >= 1 && permisions.length >= 1 && users.length >= 1 && (
            <>
              <ManagePermissions
                isShipper={user.role === "Shipper" ? true : false}
                users={
                  user.role === "Shipper"
                    ? users
                    : [
                        {
                          ...user,
                          id: user.id,
                          name: user.username,
                          roleName:
                            user?.roles?.length > 0
                              ? user?.roles[0]?.name
                              : null,
                          roles: user.roles.map((r) => r.id),
                          permissions: [
                            ...user.permissions.map((p) => p.id),
                            ...user.roles.flatMap((role) =>
                              role.permissions.map(
                                (permission) => permission.id
                              )
                            ),
                          ],
                          rolesPermissions: user.roles.flatMap((role) =>
                            role.permissions.map((permission) => permission.id)
                          ),
                        },
                      ]
                }
                roles={roles}
                permisions={permisions}
                setUsers={setUsers}
                setSnack={setSnack}
              />
            </>
          )}
          {roles.length < 1 && permisions.length < 1 && loading && (
            <div style={{ width: "60vw" }} className="driver__screen_loading">
              <CarrierTableLoader />
            </div>
          )}
          {roles.length < 1 && permisions.length < 1 && !loading && (
            <div className="driver__screen_empty" style={{ width: "auto" }}>
              <div className="driver__scrnempt_content">
                <Sad className="driver__scrnempt_contenticon" />
                <div>
                  You currently do not have any users to assign permissions!
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PermissionsSettings;

function Loader(props) {
  return (
    <Box
      sx={{
        position: "relative",
        top: "2px",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={20}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={20}
        thickness={4}
        {...props}
      />
    </Box>
  );
}
