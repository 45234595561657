import React, { useState, useRef, useEffect } from "react";
import "./InvoiceModal.css";

// components imports

import StaticInvoice from "./Components/StaticInvoice";
import EditableInvoice from "./Components/EditableInvoice";
import PrintableInvoice from "./Components/PrintableInvoice";
// apis imports
import {
  fetchShipmentDataById,
  fetchAdminShipmentDataById,
} from "../../services/ShipperServices/Shipments/shipments.services";
// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import html2pdf from "html2pdf.js";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function InvoiceModal({
  open,
  setOpen,
  data,
  type,
  id,
  setData,
  setShipments,
  getAllShipments,
  actions,
  setSnack,
}) {
  const handleClose = () => setOpen(false);
  const progressRef = useRef(null);
  const invoiceRef = useRef(null);

  const printLabel = async (data) => {
    try {
      fetch(data?.single_shipping_labels.file, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      })
        .then((url) => url.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${data.tattension + " " + data.shipment_tracking_id} Label.pdf`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
    } catch (error) {}
  };

  const downloadPdf = async (data) => {
    const input = invoiceRef.current;
    const options = {
      filename: `${
        data.tattension + " " + data.shipment_tracking_id
      } Invoice.pdf`,
      format: "A4",
      jsPDF: { unit: "px", format: [830, 1000], orientation: "p" },
      image: { type: "jpeg", quality: 0.98 },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      html2canvas: { scale: 2, useCORS: true },
    };

    await html2pdf().set(options).from(input).save();
  };

  const [shipmentData, setShipmentData] = useState(null);
  const [loading, setLoading] = useState(true);

  const getShipmentDataById = async (id) => {
    setLoading(true);
    let response;
    if (type === "editable") {
      response = await fetchAdminShipmentDataById(id);
    } else {
      response = await fetchShipmentDataById(id);
    }
    if (!response.error) {
      setLoading(false);
      setShipmentData(response?.data?.result);
    } else {
      setLoading(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  useEffect(() => {
    if (data) {
      getShipmentDataById(data);
    }
  }, [data]);
  // console.log("orderData1122", data);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="invoices__modal_wrapper">
          {(!type || type === "static") && (
            <StaticInvoice
              data={shipmentData}
              handleClose={handleClose}
              downloadPdf={downloadPdf}
              printLabel={printLabel}
              progress={progressRef.current}
              setData={setShipmentData}
              setShipments={setShipments}
              getAllShipments={getAllShipments}
              actions={actions}
              setSnack={setSnack}
              loading={loading}
            />
          )}
          {type === "editable" && (
            <EditableInvoice
              data={shipmentData}
              handleClose={handleClose}
              downloadPdf={downloadPdf}
              printLabel={printLabel}
              id={id}
              setData={setShipmentData}
              setShipments={setShipments}
              getAllShipments={getAllShipments}
              type="admin"
              loading={loading}
            />
          )}
          <div
            style={{
              zIndex: "-1000",
              position: "absolute",
              visibility: "hidden",
              left: "-1000",
            }}
          >
            <PrintableInvoice invoiceRef={invoiceRef} data={shipmentData} />
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
