import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import "./locationmodal.css";
import { updateWarehouseProductsDetails } from "../../../../services/product.service";
import CircularLoader from "../../../../Components/CircularLoader/CircularLoader";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";

export default function LocationModal({
  open,
  setOpen,
  setProducts,
  setSnack,
}) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [changed, setchanged] = useState(false);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const response = await updateWarehouseProductsDetails(
        {
          location: data?.location,
        },

        data?.id
      );
      if (!response.error) {
        setProducts((prevProducts) => {
          return {
            ...prevProducts,
            data: prevProducts?.data?.map((product) => {
              if (product.id == open?.row?.product_warehouse[0]?.product_id) {
                return {
                  ...product,
                  product_warehouse: [
                    {
                      ...product?.product_warehouse[0],
                      location: data?.location,
                    },
                  ],
                };
              }
              return product;
            }),
          };
        });
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: response?.data?.message,
            severity: "success",
          };
        });
        setLoading(false);
        handleClose();
      } else {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: response?.data?.message ?? "Something went wrong",
            severity: "error",
          };
        });
        setLoading(false);
      }
    } catch (error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: error?.response?.data?.message || "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setData({});
  };

  useEffect(() => {
    if (open) {
      setData({
        code: open?.row?.code,
        full_name: open?.row?.full_name,
        id: open?.row?.product_warehouse[0]?.id,
        productId: open?.row?.id,
        location: open?.row?.product_warehouse[0]?.location ?? "",
      });
    }
  }, [open]);

  useEffect(() => {
    let hasChanges = false;
    if (data?.location != (open?.row?.product_warehouse[0]?.location ?? "")) {
      hasChanges = true;
    }
    setchanged(hasChanges);
  }, [data, open]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="prod__location_modal">
          <div className="prod__location_modalheading">
            Product Warehouse Location
          </div>
          <div className="prod__location__sub_heading">
            {data?.full_name ?? ""}({data?.code ?? ""})
          </div>
          <div className="product_location_box_line" />

          <div className={"product_location_box_wrapper"}>
            <div className={"product_location_box_active"}>
              <div>
                <Form.Label className="prod__location_box_label">
                  Product Warehouse Location
                </Form.Label>
                <Form.Control
                  as="textarea"
                  type="number"
                  value={data?.location}
                  className="product_location_box__input"
                  placeholder="Product Warehouse Location"
                  name="location"
                  onChange={(e) => handleFieldChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="prod__location_desc_modalfooter">
            <div className="prod__location_modalcncl" onClick={handleClose}>
              Close
            </div>
            {changed && (
              <Button
                disabled={loading}
                className="product_location_box_modalsave"
                onClick={handleSave}
              >
                {loading ? <CircularLoader /> : "Save"}
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
