import React from "react";
import "../../InvoiceModal.css";
import Logo from "../../../../assets/images/hajex_logo.png";

const Footer = ({ data, type = "Show" }) => {
  let customDecoration = { textDecoration: "underline" };
  let customPosition = { position: "relative", top: "-8px" };
  let customPadding = { paddingTop: "5px" };

  if (type === "Show") {
    customDecoration = {};
    customPosition = {};
    customPadding = {};
  }

  return (
    <>
      <div className="invmodal__invoicedashed_line"></div>
      <div className="invmodal__bottom_titletxt">
        Return this slip with your payment
      </div>
      <div className="invmodal_footer">
        <div className="invmodal_footertop">
          <div className="invmodal_footertop_left">
            <img src={Logo} alt="" />
          </div>
          <div className="invmodal_footertop_right">
            <div className="invmodal_footertop_ritem">Customer ID</div>
            <div className="invmodal_footertop_ritem bold_700">
              {data?.created_by}
            </div>
            <div className="invmodal_footertop_ritem">Amount due</div>
            <div className="invmodal_footertop_ritem bold_700 invmodal_footertop_ritemdanger">
              {data?.Total}$
            </div>
            <div className="invmodal_footertop_ritem">Invoice number</div>
            <div className="invmodal_footertop_ritem bold_700 ">
              {data?.invoice_id}
            </div>
            <div className="invmodal_footertop_ritem">Due date</div>
            <div className="invmodal_footertop_ritem bold_700 invmodal_footertop_ritemdanger">
              -
            </div>
          </div>
        </div>
        <div className="invmodal_footerbottom">
          <div className="invmodal__paymentterms_box">
            <div
              className="invmodal__paymentterms_boxleft"
              style={customPadding}
            >
              <span className="bold_700">Payment terms:</span>&nbsp; The net
              amount is due in 15 days from the invoice date. A late payment fee
              at a rate of 18% per year will be charged on any invoice paid
              after the due date.
            </div>
            <div className="invmodal__paymentterms_boxright">
              <div>Amount Paid</div>
              <div
                className="invmodal__paymentterms_brapprice"
                style={customPosition}
              >
                {" "}
                $
                <span>
                  {data?.Total}
                  <sup className="invmodal__paymentterms_editcount">
                    {data?.shipment_charges?.length > 1
                      ? `*${data?.shipment_charges?.length}`
                      : ""}
                  </sup>
                </span>
                <span
                  className={
                    type == "Show"
                      ? "invmodal__paymentterms_brprevcharge"
                      : "invmodal__paymentterms_brprevcharge invmodal__paymentterms_linethrough "
                  }
                  
                >
                  {data?.shipment_charges?.length > 1
                    ? data?.shipment_charges[data?.shipment_charges?.length - 2]
                        ?.total
                    : ""}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
