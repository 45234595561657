import Modal from "react-bootstrap/Modal";
import Buttons from "@mui/material/Button";
import { Spinnerbtn } from "./Customloader";

export default function EditModel(props) {
  const { Show, HandleAddressEdit, HandleClose, AddressId, Disable, children } =
    props;

  return (
    <Modal show={Show} size="lg">
      <Modal.Header>
        <Modal.Title>Edit Address {AddressId}</Modal.Title>
      </Modal.Header>
      <form>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Buttons
            onClick={() => HandleAddressEdit()}
            type="button"
            variant="primary"
            disabled={Disable === "edit" ? true : false}
          >
            {Disable === "edit" && (
              <Spinnerbtn
                style={{
                  position: "absolute",
                  minHeight: "24px",
                  minWidth: "24px",
                  marginLeft: "2px",
                  zIndex: 1,
                }}
              />
            )}
            Edit
          </Buttons>

          <Buttons
            type="button"
            variant="danger"
            onClick={HandleClose}
            disabled={Disable === "edit" ? true : false}
          >
            Cancel
          </Buttons>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
