import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice.js";
import snackBarReducer from "./common/snackBarSlice.js";

const store = configureStore({
  reducer: {
    user: userReducer,
    snackBar: snackBarReducer,
  },
  devTools: true,
});
export default store;
