import React from "react";
import "./CustomTextField.css";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

const CustomTextField = ({
  icon,
  placeholder,
  value,
  onChange,
  type,
  name,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <lable>{placeholder}</lable>
      <TextField
        required
        id="input-with-icon-textfield"
        sx={{
          width: "100%",
          "& .MuiTextField-root": {
            outline: "2px solid #131466",
            border: "2px solid #131466",
            color: "2px solid #131466",
          },
          "& *::placeholder": {
            color: "#131466",
          },
        }}
        InputProps={{
          startAdornment: icon ? (
            <InputAdornment position="start">{icon}</InputAdornment>
          ) : null,
          endAdornment:
            type === "password" ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOff stroke="#0d6efd" />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            ) : null,
        }}
        type={type === "password" && !showPassword ? "password" : "text"}
        variant="outlined"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
      />
    </>
  );
};

export default CustomTextField;
