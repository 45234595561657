import React, { useState, useEffect, useRef } from "react";
import "./PaymentsHistory.css";
// components imports
import PaymentHistoryTable from "../../../../../Components/BoltTable/BoltTable";
import FullPageModal from "../../../../../Components/Modals/FullPageModal/FullPageModal";
import InvoiceModal from "../../../../../Components/InvoiceModal/InvoiceModal";
// apis imports
import { fetchTransactionswithuserID } from "../../../../../services/AdminServices/Users/users.services";
import { removeUnderscoresAndCapitalize } from "../../../../../helpers/helpers";

// library imports
import CircularProgress from "@mui/material/CircularProgress";
import { FaArrowAltCircleUp, FaArrowAltCircleDown } from "react-icons/fa";
import { MdOutlineStickyNote2 } from "react-icons/md";

const PaymentsHistory = ({ open, setOpen, User, setSnack }) => {
  // new code
  const [payments, setPayments] = useState([]);
  const [invoiceLoader, setinvoiceLoader] = useState(false);
  const [invoiceModal, setInvoiceModal] = React.useState();

  const columns = [
    {
      id: 1,
      label: "Transaction ID",
      render: (row) => <>{row?.transaction_no ?? "-"}</>,
      width: "200px",
    },
    {
      id: 2,
      label: "Date",
      render: (row) => <>{row.created_at?.slice(0, 10) ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Method",
      render: (row) => (
        <>
          {row.transaction_type
            ? removeUnderscoresAndCapitalize(row.transaction_type)
            : "-"}
        </>
      ),
      width: "200px",
    },
    {
      id: 4,
      label: "Payment Type",
      render: (row) => (
        <>
          {row.payment_type
            ? removeUnderscoresAndCapitalize(row.payment_type)
            : "-"}
        </>
      ),
      width: "200px",
    },
    {
      id: 5,
      label: "Bolt Tracking ID",
      render: (row) => <>{row.shipment?.tracking_id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 6,
      label: "Amount",
      render: (row) => (
        <div
          style={{
            color: row?.transaction_type?.startsWith("credit")
              ? "teal"
              : "tomato",
            display: "flex",
          }}
        >
          <>
            {row.amount ?? "-"}
            {row?.transaction_type?.startsWith("credit") ? (
              <FaArrowAltCircleUp
                style={{ marginTop: "2px", marginLeft: "4px" }}
              />
            ) : (
              <FaArrowAltCircleDown
                style={{ marginTop: "2px", marginLeft: "4px" }}
              />
            )}
          </>
        </div>
      ),
      width: "200px",
    },
    {
      id: 7,
      label: "Balance",
      render: (row) => <>{row.user_balance ?? "-"}</>,
      width: "200px",
    },
    {
      id: 8,
      label: "Invoice",
      render: (row) => (
        <div className="table_action__wrap">
          <div
            className={`table_action__btn   ${
              !row.shipment_id &&
              !row.formula_invoice &&
              !row.file &&
              "table_action__btn__disabled"
            }`}
            onClick={() => {
              if (row.shipment_id) {
                setInvoiceModal(row.shipment_id);
              } else {
                if (row.formula_invoice?.file) {
                  downloadPdf(row.id, row?.formula_invoice?.file);
                } else {
                  downloadPdf(row.id, row?.file);
                }
              }
            }}
          >
            {invoiceLoader === row.id ? (
              <CircularProgress size={10} />
            ) : (
              <MdOutlineStickyNote2 size={10} />
            )}
            Invoice
          </div>
        </div>
      ),
      width: "200px",
    },
    {
      id: 9,
      label: "Description",
      render: (row) => <>{row.description ?? "-"}</>,
      width: "200px",
    },
  ];

  const getShippersPayments = async (page, perPage, filter) => {
    const response = await fetchTransactionswithuserID(
      User?.id,
      page,
      perPage,
      filter
    );

    if (!response.error) {
      setPayments(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const downloadPdf = async (id, urlfull) => {
    setinvoiceLoader(id);
    const urlParts = urlfull.split("/");
    const filename = urlParts[urlParts.length - 1];
    try {
      fetch(urlfull, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      })
        .then((url) => url.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${filename}`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setinvoiceLoader(false);
        });
    } catch (error) {
      setinvoiceLoader(false);
    }
  };

  return (
    <FullPageModal
      open={open}
      setOpen={setOpen}
      heading={"Transactions History"}
    >
      <div className="transhistory__screen_wrapper">
        <InvoiceModal
          open={!!invoiceModal}
          data={invoiceModal}
          setOpen={setInvoiceModal}
          // type="editable"
          actions={false}
          id={User?.id}
        />
        <div>
          <PaymentHistoryTable
            message={"No Transection History Available To Display!"}
            searchPlaceHolder="Search Transections"
            columns={columns}
            data={payments}
            getAllData={getShippersPayments}
          />
        </div>
      </div>
    </FullPageModal>
  );
};

export default PaymentsHistory;
