import React, { useState } from "react";
import ProductTable from "../../../../../../../Components/BoltTable/BoltTable";
import { fetchAllPackages } from "../../../../../../../services/ShipperServices/Packages/packages.services";
import noImage from "../../../../../../../assets/images/noImage.png";
import { BsPlus } from "react-icons/bs";

export default function PackageListView(props) {
  const { handlescrollpackage, refresh } = props;
  const [packages, setPackages] = useState([]);

  const columns = [
    // {
    //   id: 0,
    //   label: "#",
    //   render: (row) => <>{row?.id ?? "-"}</>,
    //   width: "200px",
    // },
    {
      id: 1,
      label: " ",
      render: (row) => (
        <img
          src={row?.file ?? noImage}
          onError={(e) => {
            e.target.src = noImage;
          }}
          loading="lazy"
          style={{ maxWidth: "100%" }}
        />
      ),
      fixed: "left",
      width: "2vw",
    },
    {
      id: 2,
      label: "Name",
      render: (row) => <>{row?.name ?? "-"}</>,
      width: "15vw",
    },
    {
      id: 4,
      label: "Dimentions (L*W*H)",
      render: (row) => (
        <>
          {row?.length ?? "0"}*{row?.width ?? "0"}*{row?.height ?? "0"} (
          {row?.unit?.toUpperCase() == "Imperial" ? "In" : "Cm"})
        </>
      ),
      width: "5vw",
    },
    {
      id: 5,
      label: "Weight",
      render: (row) => (
        <>
          {row?.weight ?? "0"}{" "}
          {row?.unit?.toUpperCase() == "Imperial" ? "Lb" : "Kg"}
        </>
      ),
      width: "5vw",
    },
    // {
    //   id: 8,
    //   label: "Actions",
    //   render: (row) => (
    //     <>
    //       {" "}
    //       <div className="table_action__wrap">
    //         <div
    //           className="table_action__btn"
    //           onClick={() => handlescrollpackage(row)}
    //         >
    //           <BsPlus size={10} />
    //           Add
    //         </div>
    //       </div>
    //     </>
    //   ),
    //   width: "200px",
    // },
  ];

  const getAllPackages = async (page, perPage, filter) => {
    const response = await fetchAllPackages(page, perPage, filter);
    if (!response.error) {
      setPackages(response?.data?.result);
    } else {
    }
  };

  return (
    <>
      <div className="custom__table_size1 actionbtn__needtobe_fixwidth">
        <ProductTable
          message={"No Packages Available To Display!"}
          searchPlaceHolder={"Search Packages"}
          getAllData={getAllPackages}
          data={packages}
          columns={columns}
          refresh={refresh}
          handleRowClick={handlescrollpackage}
          isRowClick="Click To Add"
        />
      </div>
    </>
  );
}
