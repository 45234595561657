import React, { useState, useEffect, useRef } from "react";
import "./BrandStock.css";

// components imports
import BrandsTable from "../../../Components/BoltTable/BoltTable";
import Confirm from "../../../Components/Popups/Confirm";
import AddBrand from "./Components/AddBrand/AddBrand";
import EditBrand from "./Components/EditBrand/EditBrand";
import Tooltip from "../../../Components/Tooltip/Tooltip";
// apis imports
import {
  getBrandsList,
  deleteBrand,
} from "../../../services/warehouse.services";
// library imports

import { MdOutlineCancel } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { FcPlus } from "react-icons/fc";
import noImage from "../../../assets/images/noImage.png";
import { showSnackBar } from "../../../store/common/snackBarSlice";
import { useDispatch } from "react-redux";

const BrandStock = ({ snack }) => {
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteLoader, setdeleteLoader] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [brands, setBrands] = useState([]);
  const dispatch = useDispatch();
  const columns = [
    {
      id: 1,
      label: "Name",
      render: (row) => <>{row?.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 2,
      label: "Code",
      render: (row) => <>{row?.code ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "logo",
      render: (row) => (
        <>
          <img
            src={row?.file || noImage}
            onError={(e) => {
              e.target.src = noImage;
            }}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 4,
      label: "Description",
      render: (row) => <>{row?.description ?? "-"}</>,
      width: "200px",
    },
    {
      id: 5,
      label: "Action",
      render: (row) => (
        <>
          <div className="table_action__wrap">
            <Tooltip title="Edit Brand Details">
              <div
                className="table_action__btn"
                onClick={() => setEditOpen(row)}
              >
                <FaRegEdit size={10} />
                Edit
              </div>
            </Tooltip>

            <Tooltip title="Delete Brand">
              <div
                className="table_action__btn"
                onClick={() => setConfirmDelete(row)}
              >
                <MdOutlineCancel size={10} />
                Delete
              </div>
            </Tooltip>
          </div>
        </>
      ),
      width: "200px",
    },
  ];

  const fetchBrandsList = async (page, perPage, filter) => {
    const response = await getBrandsList(page, perPage, filter);

    if (!response.error) {
      setBrands(response?.data?.result);
    } else {
      dispatch(showSnackBar({
        text: "Something went wrong",
        severity: "error",
      }))
    }
  };

  const handleDeleteRequest = async (data) => {
    if (data.id) {
      setdeleteLoader(true);
      const response = await deleteBrand(data.id);
      if (!response.error && response?.data?.success) {
        setBrands((prevBrands) => {
          return {
            ...prevBrands,
            data: prevBrands?.data?.filter((b) => b?.id != data?.id),
          };
        });
        setdeleteLoader(false);
        setConfirmDelete(false);

        dispatch(showSnackBar({
          text: "Request deleted successfully",
          severity: "success",
        }))
      } else {
        setdeleteLoader(false);
        dispatch(showSnackBar({
          text:
            response?.error?.response?.data?.message ||
            "Couldn't cancel this request!",
          severity: "error",
        }))
      }
    }
  };

  return (
    <>
      <Confirm
        open={confirmDelete}
        setOpen={setConfirmDelete}
        handleConfirm={() => handleDeleteRequest(confirmDelete)}
        text="Do You Want To Delete This Request ?"
        loader={deleteLoader}
      />
      <div className="brands_header">
        <div>
          <span className="brands_hdrheading">Brand List</span>
        </div>
        <div className="brands_buttonsWrapper">
          <div onClick={() => setOpen(true)}>
            <div className="brands_hdraddprod">
              <FcPlus size={10} />
              <span>ADD BRAND</span>
            </div>
          </div>
        </div>
      </div>
      <div className="brandStock__main_wrap">
        <div className="brandStock__table_list">
          <div>
            <BrandsTable
              message={"No Brands Available To Display!"}
              searchPlaceHolder={"Search Brands"}
              columns={columns}
              data={brands}
              getAllData={fetchBrandsList}
              refresh={refresh}
            />
          </div>
        </div>
        <AddBrand
          open={open}
          setOpen={setOpen}
          setRefresh={setRefresh}
        />
        <EditBrand
          open={editOpen}
          setOpen={setEditOpen}
          setBrands={setBrands}
        />
      </div>
    </>
  );
};

export default BrandStock;
