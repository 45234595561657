import axios from "./axiosInstance";
// manage roles and permissions
export const createNewRole = async (token, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`/role/create`, data, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateRoleData = async (token, data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`role/update/${id}`, data, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchAllPermissions = async (token, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`/permissions`, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchAllRoles = async (token, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`/role/get`, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const deleteRoleData = async (token, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.delete(`role/delete/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
// shipper users
export const createNewShipperUser = async (token, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`/shipper-user/create`, data, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchShipperUsers = async (token, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`/shipper-user`, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const searchShipperUsers = async (token, name, page, param) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `/shipper-user?name=${name}&&${page ? `page=${page}` : ""}${param ?? ""}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateShipperUserData = async (token, data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`shipper-user/update/${id}`, data, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const deleteShipperUser = async (token, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.delete(`shipper-user/delete/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateAccountStatus = async (token, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.put(
      `shipper-user/status/${data.status}/${data.id}`,
      // data,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateProfileData = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`update/profile`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateGeneralSettings = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`general-setting/set-setting`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
