import axios from "../../axiosInstance";

export const fetchAllPackages = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `packages/show?page=${page}&perPage=${perPage}&name=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const deletePackage = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`packages/delete/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const addNewPackage = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`packages/add`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updatePackage = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`packages/update/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchAllBundles = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `bundles/show/bundle?page=${page}&perPage=${perPage}&name=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const deleteBundle = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`bundles/delete/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const addNewBundle = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`bundles/add`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const updateBundle = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`bundles/update/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
