import React, { useState } from "react";
import "./truckerdetails.css";

// components imports
// apis imports
// library imports
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import { FaRegTimesCircle } from "react-icons/fa";
import { BiUser } from "react-icons/bi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  boxShadow: 24,
  p: 4,
  padding: "30px",
  backgroundColor: "white",
  maxHeight: "95vh",
  overflowY: "auto",
  "&::scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar": {
    width: "5",
  },
};

export default function TruckerDetails({ open, setOpen, data }) {
  const handleClose = () => setOpen(false);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="aduser__details_modal">
            <div className="aduser__details_modalclose" onClick={handleClose}>
              <IconButton>
                <FaRegTimesCircle size={25} color="lightgrey" />
              </IconButton>
            </div>
            <div className="aduser__details_heading">
              <BiUser size={35} /> <h2>Carrier Details</h2>
            </div>
            <div className="aduser__details_modalrow">
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">ID:</div>
                <div className="aduser__details_modalcol">
                  {data?.id ?? "-"}
                </div>
              </div>
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">User Name:</div>
                <div className="aduser__details_modalcol">
                  {data?.username ?? "-"}
                </div>
              </div>
            </div>
            <div className="aduser__details_modalrow">
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">First Name:</div>
                <div className="aduser__details_modalcol">
                  {data?.firstname ?? "-"}
                </div>
              </div>
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">Last Name:</div>
                <div className="aduser__details_modalcol">
                  {data?.lastname ?? "-"}
                </div>
              </div>
            </div>
            <div className="aduser__details_modalrow">
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">
                  Company Name:
                </div>
                <div className="aduser__details_modalcol">
                  {data?.business_name ?? "-"}
                </div>
              </div>
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">
                  No Of Vehicles:
                </div>
                <div className="aduser__details_modalcol">
                  {data?.vehicles_qty ?? "-"}
                </div>
              </div>
            </div>
            <div className="aduser__details_modalrow">
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">Email:</div>
                <div className="aduser__details_modalcol">
                  {data?.email ?? "-"}
                </div>
              </div>
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">
                  Phone Number:
                </div>
                <div className="aduser__details_modalcol">
                  {data?.phone_number ?? "-"}
                </div>
              </div>
            </div>
            <div className="aduser__details_modalrow">
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">Address:</div>
                <div className="aduser__details_modalcol">
                  {data?.address ?? "-"}
                </div>
              </div>
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">Country:</div>
                <div className="aduser__details_modalcol">
                  {data?.Country ?? "-"}
                </div>
              </div>
            </div>
            <div className="aduser__details_modalrow">
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">Province:</div>
                <div className="aduser__details_modalcol">
                  {data?.province ?? "-"}
                </div>
              </div>
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">City:</div>
                <div className="aduser__details_modalcol">
                  {data?.city ?? "-"}
                </div>
              </div>
            </div>
            <div className="aduser__details_modalrow">
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">Zip Code:</div>
                <div className="aduser__details_modalcol">
                  {data?.zip ?? "-"}
                </div>
              </div>
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">
                  Industry Experience:
                </div>
                <div className="aduser__details_modalcol">
                  {data?.experience ?? "-"}
                </div>
              </div>
            </div>
            <div className="aduser__details_modalrow">
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">
                  Gross Revenue Per Year:
                </div>
                <div className="aduser__details_modalcol">
                  {data?.revenue && "$"}
                  {data?.revenue ?? "-"}
                </div>
              </div>
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">
                  Hear About Us:
                </div>
                <div className="aduser__details_modalcol">
                  {data?.about_us ?? "-"}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
