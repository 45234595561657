import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import "./productidentifiermodal.css";
import { marketplacesFormat } from "../../../../helpers/helpers";
import { productSKU } from "../../../../services/product.service";

import { BsWordpress } from "react-icons/bs";
import { FaEbay } from "react-icons/fa";
import { FaShopify } from "react-icons/fa";
import { AiOutlineAmazon } from "react-icons/ai";
import { TbBrandWalmart } from "react-icons/tb";
import Bestbuy from "./images/bestbuy.svg";
import CircularLoader from "../../../../Components/CircularLoader/CircularLoader";

const marketplaceIcons = {
  Amazon: <AiOutlineAmazon size={40} />,
  Shopify: <FaShopify size={40} />,
  Walmart: <TbBrandWalmart size={40} />,
  Bestbuy: <img src={Bestbuy} alt="" />,
  Woocommerce: <BsWordpress size={40} />,
  Ebay: <FaEbay size={40} />,
};

export default function ProductIdentifierModal({
  open,
  setOpen,
  setSnack,
  setProducts,
}) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [changed, setchanged] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setData({});
  };

  const handleSave = async () => {
    setLoading(true);
    // console.log(data?.row);
    const updatedSKU =
      data?.row?.product_skus?.map((item) => ({
        sku_id: item.id,
        market_place_id: item.market_place.id,
        sku: item.sku,
        slug: item.slug,
      })) || [];
    const response = await productSKU(updatedSKU);
    console.log(response);
    if (!response.error) {
      setProducts((prevProducts) => {
        return {
          ...prevProducts,
          data: prevProducts?.data?.map((product, index) => {
            if (product.id === data?.row?.id) {
              return {
                ...product,
                product_skus: data?.row?.product_skus,
              };
            }
            return product;
          }),
        };
      });
      setOpen(data);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Product Identifiers Updated successfully",
          severity: "success",
        };
      });
      // setchanged(false);
      setLoading(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
      setLoading(false);
    }
  };

  const handleIdentifierFieldChange = (e, index) => {
    const { name, value } = e.target;
    // console.log(name + " " + value);
    setData((prevData) => {
      const updatedProductSKU = [...prevData.row.product_skus];
      updatedProductSKU[index] = {
        ...updatedProductSKU[index],
        [name]: value === "" ? null : value,
      };

      return {
        ...prevData,
        row: {
          ...prevData.row,
          product_skus: updatedProductSKU,
        },
      };
    });
  };

  useEffect(() => {
    setData(open);
  }, [open]);

  useEffect(() => {
    let hasChanges = false;

    data?.row?.product_skus.forEach((Sku, index) => {
      if (
        open?.row?.product_skus[index] &&
        (Sku.sku !== open.row.product_skus[index].sku ||
          Sku.slug !== open.row.product_skus[index].slug)
      ) {
        hasChanges = true;
      }
    });
    setchanged(hasChanges);
  }, [open?.row?.product_skus, data?.row?.product_skus]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="prodidentifier__desc_modal ">
          <div className="prodidentifier__desc_modalheading">
            Edit Product Identifier's
          </div>
          <div className="prodidentifier__desc_modalcontent content_wrapper">
            {data?.row?.product_skus?.map((identifier, index) => (
              <div className="addprod__custominput_col">
                <div className="addprod__custominput_block">
                  <div className="addprod__custominput_box icon_img">
                    <span>
                      {
                        marketplaceIcons[
                          marketplacesFormat(identifier?.market_place?.market)
                        ]
                      }
                    </span>
                  </div>
                </div>
                <div className="addprod__custominput_block">
                  <div className="addprod__custominput_box">
                    <label className="addprod__custominput_title">SKU</label>
                    <input
                      className="addprod__custominputfield"
                      type="text"
                      placeholder="Enter SKU"
                      name="sku"
                      onChange={(e) => handleIdentifierFieldChange(e, index)}
                      value={identifier?.sku}
                    />
                  </div>
                </div>
                <div className="addprod__custominput_block">
                  <div className="addprod__custominput_box">
                    <label className="addprod__custominput_title">Slug</label>
                    <input
                      className="addprod__custominputfield"
                      type="text"
                      placeholder="Enter Slug"
                      name="slug"
                      onChange={(e) => handleIdentifierFieldChange(e, index)}
                      value={identifier?.slug}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="prodidentifier__desc_modalfooter">
            <div
              className="prodidentifier__desc_modalcncl"
              onClick={handleClose}
            >
              Cancel
            </div>
            {changed && (
              <div
                className="prodidentifier__desc_modalsave"
                onClick={handleSave}
              >
                {loading ? <CircularLoader /> : "Save"}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
