import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import "./productquantitymodal.css";
import { productQuantity } from "../../../../services/product.service";
import CircularLoader from "../../../../Components/CircularLoader/CircularLoader";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Tooltips from "@mui/material/Tooltip";
import { IoIosAdd, IoIosRemove } from "react-icons/io";

export default function ProductQuantityModal({
  open,
  setOpen,
  setProducts,
  setSnack,
  setProdHistoryModal,
}) {
  const [data, setData] = useState({});
  const [activeVariation, setActiveVariation] = useState(0);
  const [loading, setLoading] = useState(false);
  const [changed, setchanged] = useState(false);

  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setData((prevData) => {
      const updatedProductWarehouse = [...prevData.row.product_warehouse];
      updatedProductWarehouse[index] =
        name === "adjQuantity"
          ? {
              ...updatedProductWarehouse[index],
              adjQuantity: value,
              ["quantity"]: (
                Number(value) +
                Number(open?.row?.product_warehouse[index]?.quantity)
              ).toString(),
            }
          : {
              ...updatedProductWarehouse[index],
              [name]: value,
            };

      return {
        ...prevData,
        row: {
          ...prevData.row,
          product_warehouse: updatedProductWarehouse,
        },
      };
    });
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const productWarehouseArray =
        data?.row?.product_warehouse?.map((item, index) => ({
          id: item.id,
          quantity: item.quantity,
          notes: item?.notes,
        })) || [];

      const response = await productQuantity(productWarehouseArray);
      if (!response.error) {
        if (!data?.row?.parent_id) {
          setProducts((prevProducts) => {
            return {
              ...prevProducts,
              data: prevProducts?.data?.map((product, index) => {
                if (product.id === data?.row?.id) {
                  return {
                    ...product,
                    product_warehouse: product.product_warehouse?.map(
                      (item1) => {
                        const match = data?.row?.product_warehouse.find(
                          (item2) => item2.id === item1.id
                        );
                        return match ? match : item1;
                      }
                    ),
                  };
                }
                return product;
              }),
            };
          });
        } else {
          setProducts((prevProducts) => {
            return {
              ...prevProducts,
              data: prevProducts?.data?.map((product) => {
                if (product.id === parseInt(data?.row?.parent_id)) {
                  return {
                    ...product,
                    children: product.children.map((children, idx) => {
                      if (children.id === parseInt(data?.row?.id)) {
                        return {
                          ...children,
                          product_warehouse: children?.product_warehouse?.map(
                            (item1) => {
                              const match = data?.row?.product_warehouse.find(
                                (item2) => item2.id === item1.id
                              );
                              return match ? match : item1;
                            }
                          ),
                        };
                      }
                      return children;
                    }),
                  };
                }
                return product;
              }),
            };
          });
        }
        // setOpen(data);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: response?.data?.message,
            severity: "success",
          };
        });
        setLoading(false);
        setProdHistoryModal(open);
        handleClose();
      } else {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: response?.data?.message ?? "Something went wrong",
            severity: "error",
          };
        });
        setLoading(false);
      }
    } catch (error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: error?.response?.data?.message || "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setData({});
  };

  useEffect(() => {
    if (open) {
      setData((prevData) => {
        return {
          ...prevData,
          row: {
            ...open.row,
            product_warehouse: open?.row?.product_warehouse?.map((item) => ({
              ...item,
              adjQuantity: "",
              notes: "",
            })),
          },
        };
      });
    }
  }, [open]);

  useEffect(() => {
    let hasChanges = false;
    data?.row?.product_warehouse.forEach((warehouse, index) => {
      if (
        (open?.row?.product_warehouse[index] &&
          parseInt(warehouse.quantity, 10) !==
            parseInt(open.row.product_warehouse[index].quantity, 10)) ||
        warehouse?.notes != ""
      ) {
        hasChanges = true;
      }
    });

    setchanged(hasChanges);
  }, [open?.row?.product_warehouse, data?.row?.product_warehouse]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="prod__quantity_desc_modal ">
          <div
            className="prod__quantity_desc_modalheading"
            style={{ border: "0px", marginBottom: "8px" }}
          >
            Product Quantity In Warehouse
          </div>
          <div
            className="prod__quantity_desc_modalheading"
            style={{ fontWeight: "100", marginBottom: "24px" }}
          >
            {open?.row?.full_name ?? ""}({open?.row?.code ?? ""})
          </div>
          <div className="product_quantity_box_line" />
          <div className="prod__quantity_desc_modalcontent">
            <div className={"product_quantity_box_wrapper"}>
              {data?.row?.product_warehouse?.map((warehouse, index) => {
                return (
                  <div
                    // onClick={() => setActiveVariation(index)}
                    className={
                      activeVariation === index
                        ? "product_quantity_box_active"
                        : "product_quantity_box"
                    }
                    key={index}
                  >
                    <div className="product_quantity_box_innerwrapper">
                      <div>
                        <Form.Label className="prod__quantity_box_label">
                          Name
                        </Form.Label>
                        <div className={"product_quantity_box_name"}>
                          #:{warehouse?.warehouse?.id}{" "}
                          {warehouse?.warehouse?.name ?? "-"}
                        </div>
                      </div>

                      <div className="product_quantity_box_adjquantity_wrapper">
                        <div className="product_quantity_box_button">
                          <Tooltips title="Remove Quantity" placement="top">
                            <a
                              type="button"
                              onClick={() =>
                                handleFieldChange(
                                  {
                                    target: {
                                      name: "adjQuantity",
                                      value: Number(warehouse?.adjQuantity) - 1,
                                    },
                                  },
                                  index
                                )
                              }
                            >
                              <IoIosRemove
                                size={25}
                                style={{
                                  border: "2px solid #E2EDFB",
                                  borderRadius: "25%",
                                  marginLeft: "5px",
                                }}
                                className="p-1 overlaybtn"
                              />
                            </a>
                          </Tooltips>
                        </div>

                        <div>
                          <Form.Label className="prod__quantity_box_label">
                            Adjusted By
                          </Form.Label>
                          <Form.Control
                            type="number"
                            className="product_quantity_box_adj_input"
                            value={Number(warehouse?.adjQuantity)}
                            placeholder="Adjusted By"
                            name="adjQuantity"
                            onChange={(e) => handleFieldChange(e, index)}
                          />
                        </div>
                        <div className="product_quantity_box_button">
                          <Tooltips title="Add Quantity" placement="top">
                            <a
                              type="button"
                              onClick={() =>
                                handleFieldChange(
                                  {
                                    target: {
                                      name: "adjQuantity",
                                      value: Number(warehouse?.adjQuantity) + 1,
                                    },
                                  },
                                  index
                                )
                              }
                            >
                              <IoIosAdd
                                size={25}
                                style={{
                                  border: "2px solid #E2EDFB",
                                  borderRadius: "25%",
                                  marginRight: "5px",
                                }}
                                className="p-1 overlaybtn"
                              />
                            </a>
                          </Tooltips>
                        </div>
                      </div>

                      <div>
                        <Form.Label className="prod__quantity_box_label">
                          New
                        </Form.Label>
                        <div className="product_quantity_box_newquantitybox_wrapper">
                          <div
                            className="product_quantity_box_newquantitybox"
                            style={{
                              color:
                                Number(warehouse?.adjQuantity) +
                                  Number(warehouse.quantity) >=
                                Number(warehouse.quantity)
                                  ? "blue"
                                  : "red",
                            }}
                          >
                            {warehouse.quantity}{" "}
                          </div>
                          {Number(warehouse?.adjQuantity) != 0 && (
                            <div className="product_quantity_box_newquantitybox">
                              <span className="product_quantity_box_actualquantity">
                                {Number(warehouse.quantity) -
                                  Number(warehouse?.adjQuantity)}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="product_quantity_box_line" />
                    <div className="product_quantity_box_innerwrapper">
                      <div className="product_quantity_box_reason_wrapper">
                        <label>Reason</label>
                        <textarea
                          type="text"
                          className="product_quantity_box_textarea"
                          value={warehouse?.notes}
                          placeholder="Reason"
                          name="notes"
                          onChange={(e) => handleFieldChange(e, index)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="prod__quantity_desc_modalfooter">
            <div
              className="prod__quantity_desc_modalcncl"
              onClick={handleClose}
            >
              Close
            </div>
            {changed && (
              <Button
                disabled={loading}
                className="product_quantity_box_modalsave"
                onClick={handleSave}
              >
                {loading ? <CircularLoader /> : "Save"}
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
