import React, { useState, useEffect } from "react";
import "./RegisterSteps.css";
import { ReactComponent as User } from "../../../../assets/icons/user.svg";
import { ReactComponent as Phone } from "../../../../assets/icons/phone.svg";
import { ReactComponent as Industry } from "../../../../assets/icons/industry.svg";
import { ReactComponent as Briefcase } from "../../../../assets/icons/briefcase.svg";
import { ReactComponent as Envelop } from "../../../../assets/icons/envelop.svg";
import CustomTextField from "../CustomTextField/CustomTextField";
const RegisterStep1 = ({ setCurrentStep, formData, handleFieldChange }) => {
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (
      [
        formData.firstname,
        formData.lastname,
        formData.business_name,
        formData.industry_type,
        formData.email,
        formData.phone_number,
      ].includes("")
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formData]);
  return (
    <div className="rfsregister__steps_wrap">
      <div className="rfsregister__steps_fieldsrow">
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            icon={<User />}
            placeholder="First Name"
            type="text"
            name="firstname"
            value={formData.firstname}
            onChange={(e) => handleFieldChange("firstname", e.target.value)}
          />
        </div>
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            icon={<User />}
            placeholder="Last Name"
            type="text"
            onChange={(e) => handleFieldChange("lastname", e.target.value)}
            name="lastname"
            value={formData.lastname}
          />
        </div>
      </div>
      <div className="rfsregister__steps_fieldsrow">
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            icon={<Briefcase />}
            placeholder="Business Name"
            type="text"
            onChange={(e) => handleFieldChange("business_name", e.target.value)}
            name="business_name"
            value={formData.business_name}
          />
        </div>
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            icon={<Industry />}
            placeholder="Industry Type"
            type="text"
            onChange={(e) => handleFieldChange("industry_type", e.target.value)}
            name="industry_type"
            value={formData.industry_type}
          />
        </div>
      </div>
      <div className="rfsregister__steps_fieldsrow">
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            icon={<Envelop />}
            placeholder="Email"
            type="email"
            onChange={(e) => handleFieldChange("email", e.target.value)}
            name="email"
            value={formData.email}
          />
        </div>
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            icon={<Phone />}
            placeholder="Phone Number"
            type="tel"
            onChange={(e) => handleFieldChange("phone_number", e.target.value)}
            name="phone_number"
            value={formData.phone_number}
          />
        </div>
      </div>
      <div
        className={`rfsregister__steps_nextbtn ${
          disabled && "rfsregister__steps_nextbtndisabled"
        }`}
        onClick={() => {
          setCurrentStep(2);
        }}
      >
        Next
      </div>
    </div>
  );
};

export default RegisterStep1;
