import React, { useState, useEffect } from "react";
import "./fulfilmenttier.css";
// components imports
import FulfilmentByWeight from "./Components/FulfilmentByWeight/FulfilmentByWeight";
import FulfilmentByDimension from "./Components/FulfilmentByDimension/FulfilmentByDimension";
import CarrierTableLoader from "../../../../../Components/CarrierInnerTableLoader/CarrierInnerTableLoader";
// apis imports
import { getFulfilmentTierFormula } from "../../../../../services/warehouse.services";
// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import { FaRegTimesCircle } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import Sad from "@mui/icons-material/SentimentVeryDissatisfied";
import { useSelector } from "react-redux";

const FulfilmentTier = ({ open, setOpen, setSnack }) => {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const [formulaCoppy, setFormulaCoppy] = useState(null);
  const [tierFormula, setTierFormula] = useState({
    type: "by_weight",
    dimensions: [],
    formula_details: [],
    deletedDetailsIds: [],
    deletedDimensionIds: [],
    unit: "kg",
    id: null,
  });

  const [isEditable, setIsEditable] = useState(false);
  const [error, setError] = useState(false);
  const fetchFulfilmentTierFormula = async () => {
    setLoading(true);
    const response = await getFulfilmentTierFormula(open?.shipper_id);
    if (!response.error) {
      setTierFormula(
        response?.data?.result === null
          ? {
              type: "by_weight",
              dimensions: [],
              formula_details: [],
              deletedDetailsIds: [],
              deletedDimensionIds: [],
              unit: "kg",
              id: null,
            }
          : {
              ...response?.data?.result,
              deletedDetailsIds: [],
              deletedDimensionIds: [],
              formula_details: response?.data?.result?.formula_details,
            }
      );
      setFormulaCoppy(
        response?.data?.result === null
          ? {
              type: "by_weight",
              dimensions: [],
              formula_details: [],
              deletedDetailsIds: [],
              deletedDimensionIds: [],
              unit: "kg",
              id: null,
            }
          : {
              ...response?.data?.result,
              deletedDetailsIds: [],
              deletedDimensionIds: [],
              formula_details: response?.data?.result?.formula_details,
            }
      );
      setLoading(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open?.id) {
      fetchFulfilmentTierFormula();
    } else {
      setTierFormula({
        type: "by_weight",
        dimensions: [],
        formula_details: [],
        deletedDetailsIds: [],
        deletedDimensionIds: [],
        unit: "kg",
        id: null,
      });
      setFormulaCoppy(null);
    }
    setIsEditable(false);
  }, [open.id]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className="fulfilment_tier_modal">
        {loading === false && (
          <>
            <div style={{ position: "relative" }}>
              <div className="fulfilment_tier_modalheading">
                Fulfilment Tier
              </div>
              <div
                className="fulfilment_tier_modalclose"
                onClick={() => setOpen(false)}
              >
                <IconButton>
                  <FaRegTimesCircle size={25} color="lightgrey" />
                </IconButton>
              </div>
            </div>
            {isEditable === false && tierFormula?.id != null && (
              <div className="fulfilment_tier_updatewrapper">
                <button
                  className="fulfilment_tier_btn"
                  onClick={() => {
                    setIsEditable(true);
                  }}
                >
                  <MdAdd className="fulfilment_tier_btnicon" size={18} />
                  Update Fulfilment Tier
                </button>
              </div>
            )}
            {isEditable === false && tierFormula?.id === null && (
              <div className="fulfilment_tier_updatewrapper">
                <button
                  className="fulfilment_tier_btn"
                  onClick={() => {
                    setTierFormula({
                      ...tierFormula,
                      type: "by_weight",
                      dimensions: [],
                      formula_details: [],
                      unit: "kg",
                      id: "empty",
                    });
                    setIsEditable(true);
                  }}
                >
                  <MdAdd className="fulfilment_tier_btnicon" size={18} />
                  Create Fulfilment Tier
                </button>
              </div>
            )}

            {tierFormula?.id != null ? (
              <>
                <div className="fulfilment_tier_switch_wrapper">
                  <div
                    onClick={() => {
                      if (isEditable) {
                        setTierFormula({
                          ...tierFormula,
                          dimensions: formulaCoppy?.dimensions,
                          formula_details: formulaCoppy?.formula_details,
                          deletedDimensionIds: [],
                          deletedDetailsIds: [],
                          type: "by_weight",
                          unit: "kg",
                        });
                      }
                    }}
                    className={
                      tierFormula?.type === "by_weight"
                        ? "fulfilment_tier_switch_btn_active"
                        : "fulfilment_tier_switch_btn"
                    }
                  >
                    By Weight
                  </div>
                  <div
                    onClick={() => {
                      if (isEditable) {
                        setTierFormula({
                          ...tierFormula,
                          dimensions: formulaCoppy?.dimensions,
                          formula_details: formulaCoppy?.formula_details,
                          deletedDimensionIds: [],
                          deletedDetailsIds: [],
                          type: "by_dimensions",
                          unit: "in",
                        });
                      }
                    }}
                    className={
                      tierFormula?.type === "by_dimensions"
                        ? "fulfilment_tier_switch_btn_active"
                        : "fulfilment_tier_switch_btn"
                    }
                  >
                    By Dimension
                  </div>
                </div>
                {tierFormula?.type === "by_weight" ? (
                  <FulfilmentByWeight
                    open={open}
                    setOpen={setOpen}
                    formulaCoppy={formulaCoppy}
                    setFormulaCoppy={setFormulaCoppy}
                    tierFormula={tierFormula}
                    setTierFormula={setTierFormula}
                    isEditable={isEditable}
                    error={error}
                    setError={setError}
                    setIsEditable={setIsEditable}
                    setSnack={setSnack}
                  />
                ) : (
                  <FulfilmentByDimension
                    open={open}
                    setOpen={setOpen}
                    formulaCoppy={formulaCoppy}
                    setFormulaCoppy={setFormulaCoppy}
                    tierFormula={tierFormula}
                    setTierFormula={setTierFormula}
                    isEditable={isEditable}
                    error={error}
                    setError={setError}
                    setIsEditable={setIsEditable}
                    setSnack={setSnack}
                  />
                )}
              </>
            ) : (
              <div className="fulfilment_tier_empty_wrapper">
                <div className="fulfilment_tier_empty_content">
                  <Sad className="fulfilment_tier_icon" />
                  <div>No Default Formula available to display!</div>
                </div>
              </div>
            )}
          </>
        )}
        {loading && (
          <div className="fulfilment_tier_loader">
            <CarrierTableLoader />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default FulfilmentTier;
