import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import "./fulfilment_cost.css";
import CircularLoader from "../../../../Components/CircularLoader/CircularLoader";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { updateProductsDetails } from "../../../../services/product.service";

export default function FulfilmentCostModal({
  open,
  setOpen,
  setProducts,
  setSnack,
}) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [changed, setchanged] = useState(false);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const response = await updateProductsDetails(
        {
          fulfillment_cost: data?.fulfillment_cost,
        },
        data?.id
      );
      if (!response.error) {
        setProducts((prevProducts) => {
          return {
            ...prevProducts,
            data: prevProducts?.data?.map((product) => {
              if (product.id == open?.row?.id) {
                return {
                  ...product,
                  fulfillment_cost: data?.fulfillment_cost,
                };
              }
              return product;
            }),
          };
        });
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: response?.data?.message,
            severity: "success",
          };
        });
        setLoading(false);
        handleClose();
      } else {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: response?.data?.message ?? "Something went wrong",
            severity: "error",
          };
        });
        setLoading(false);
      }
    } catch (error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: error?.response?.data?.message || "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setData({});
  };

  useEffect(() => {
    if (open) {
      setData({
        id: open?.row?.id,
        code: open?.row?.code,
        full_name: open?.row?.full_name,
        fulfillment_cost: open?.row?.fulfillment_cost ?? "",
      });
    }
  }, [open]);

  useEffect(() => {
    let hasChanges = false;
    if (data?.fulfillment_cost != (open?.row?.fulfillment_cost ?? "")) {
      hasChanges = true;
    }
    setchanged(hasChanges);
  }, [data, open]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="prod__fulfilment_cost_modal">
          <div className="prod__fulfilment_cost_modalheading">
            Product Fulfilment Cost
          </div>
          <div className="prod__fulfilment_cost__sub_heading">
            {data?.full_name ?? ""}({data?.code ?? ""})
          </div>
          <div className="product_fulfilment_cost_box_line" />

          <div className={"product_fulfilment_cost_box_wrapper"}>
            <div className={"product_fulfilment_cost_box_active"}>
              <div>
                <Form.Label className="prod__fulfilment_cost_box_label">
                  Fulfilment Cost
                </Form.Label>
                <Form.Control
                  type="number"
                  value={data?.fulfillment_cost}
                  className="product_fulfilment_cost_box__input"
                  placeholder="Fulfilment Cost"
                  name="fulfillment_cost"
                  onChange={(e) => handleFieldChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="prod__fulfilment_cost_desc_modalfooter">
            <div
              className="prod__fulfilment_cost_modalcncl"
              onClick={handleClose}
            >
              Close
            </div>
            {changed && (
              <Button
                disabled={loading}
                className="product_fulfilment_cost_box_modalsave"
                onClick={handleSave}
              >
                {loading ? <CircularLoader /> : "Save"}
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
