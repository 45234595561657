import React, { useState } from "react";
import "./Sections.css";
import { MdErrorOutline } from "react-icons/md";
import CustomDropdown from "../Components/CustomDropdown/CustomDropdown";
import { useEffect } from "react";
const StatusSection = ({ formData, setFormData, errors }) => {
  const [selectedOption, setSelectedOption] = useState("Active");
  useEffect(() => {
    setFormData({ ...formData, status: selectedOption });
  }, [selectedOption]);
  return (
    <section
      className="addprod__fsidesection_wrap"
      id="addproduct_basic_status"
    >
      <div className="addprod__custominput_flexrow">
        <div className="addprod__custominput_flexcol">
          <div className="addprod__custominput_box">
            <label className="addprod__custominput_title">Product Status</label>
            <CustomDropdown
              options={["Active", "Draft"]}
              onSelect={(option) => {
                setSelectedOption(option);
              }}
              selectedOption={selectedOption}
            />
            {Object.keys(errors)?.includes("status") && (
              <div className="addprod__custominputfield_error">
                <MdErrorOutline className="addprod__custominputfield_erroricn" />
                {errors?.status?.msg}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default StatusSection;
