import React, { useState } from "react";
import "./attachdoc.css";
import { BiCloudUpload } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import { useEffect } from "react";

const AttachDocument = ({ formData, setFormData }) => {
  const [uploadedImages, setUploadedImages] = useState([]);
  const [addUrlBox, setAddUrlBox] = useState(false);

  const handleImageUpload = (e) => {
    const uploadedImages = Array.from(e.target.files);
    // Filter out non image files
    const imageFiles = uploadedImages.filter((file) =>
      file.type.startsWith("image/")
    );
    setUploadedImages((prevImages) => [...prevImages, ...imageFiles]);

    setFormData({
      ...formData,
      media: [...formData.media, ...imageFiles],
    });
  };
  const handleRemoveImage = (index, image) => {
    const updatedImages = [...uploadedImages];
    updatedImages.splice(index, 1);

    setUploadedImages(updatedImages);
    setFormData({
      ...formData,
      media: updatedImages,
    });
  };

  useEffect(() => {
    setUploadedImages(formData.media);
  }, [formData.media]);
  return (
    <section className="transfer_note_section_wrap">
      <div className="transfer_note_heading">Media</div>
      <div className="transfer_note_mediabox">
        {!addUrlBox && (
          <>
            <div className="transfer_note_imagebtns">
              <label className="transfer_note_uploadimagebtn">
                <BiCloudUpload size={20} className="transfer_note_mdbtnicon" />
                Upload Images
                <input
                  type="file"
                  hidden
                  multiple
                  id="product_media"
                  onChange={handleImageUpload}
                />
              </label>
            </div>
            <div className="transfer_note_mdbtmtext">
              ACCEPTING IMAGE FILES ONLY
            </div>
          </>
        )}
      </div>
      <div
        className="transfer_note_images"
        style={{
          marginBottom: uploadedImages?.length < 1 ? "0px" : "",
        }}
      >
        {uploadedImages?.map((image, index) => (
          <div className="transfer_note_imagewrap">
            <MdClose
              className="transfer_note_imageclose"
              size={20}
              onClick={() => handleRemoveImage(index, image)}
            />
            {image.id ? (
              <img
                src={image.file}
                accept="image/*"
                className="transfer_note_image"
              />
            ) : (
              <img
                src={URL.createObjectURL(image)}
                accept="image/*"
                className="transfer_note_image"
              />
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default AttachDocument;
