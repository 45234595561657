import React, { useState, useEffect } from "react";
import "../RegisterSteps/RegisterSteps.css";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Countries from "../../../../data/countries";

import { ReactComponent as Address } from "../../../../assets/icons/address.svg";
import CustomTextField from "../CustomTextField/CustomTextField";
const RegisterStep2 = ({ setCurrentStep, formData, handleFieldChange }) => {
  const [disabled, setDisabled] = useState(true);
  const [recieveEmail, setRecieveEmail] = useState(false);
  const [terms, setTerms] = useState(false);
  useEffect(() => {
    if (
      [
        formData.address,
        formData.country,
        formData.city,
        formData.province,
        formData.zip,
      ].includes("") ||
      !recieveEmail ||
      !terms
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formData, terms, recieveEmail]);
  return (
    <div className="rfsregister__steps_wrap">
      <div className="rfsregister__steps_fieldsrow">
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            icon={<Address />}
            placeholder="Address"
            type="text"
            name="address"
            value={formData.address}
            onChange={(e) => handleFieldChange("address", e.target.value)}
          />
        </div>
      </div>
      <div className="rfsregister__steps_fieldsrow">
        <div className="rfsregister__steps_fieldscol">
          <label>Country</label>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={Countries}
            getOptionLabel={(option) => option.name}
            sx={{}}
            renderInput={(params) => (
              <TextField {...params} placeholder="Country" />
            )}
            onChange={(e, country) =>
              handleFieldChange("country", country.code)
            }
          />
        </div>
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            placeholder="Province"
            type="text"
            name="province"
            value={formData.province}
            onChange={(e) => handleFieldChange("province", e.target.value)}
          />
        </div>
      </div>
      <div className="rfsregister__steps_fieldsrow">
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            placeholder="City"
            type="text"
            name="city"
            value={formData.city}
            onChange={(e) => handleFieldChange("city", e.target.value)}
          />
        </div>
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            placeholder="Zip Code"
            type="text"
            name="zip"
            value={formData.zip}
            onChange={(e) => handleFieldChange("zip", e.target.value)}
          />
        </div>
      </div>
      <div className="rfsregister__steps_next_previous">
        <div
          className="rfsregister__steps_nextbtn"
          onClick={() => {
            setCurrentStep(1);
          }}
        >
          Previous
        </div>
        <div
          className={`rfsregister__steps_nextbtn ${
            disabled && "rfsregister__steps_nextbtndisabled"
          }`}
          onClick={() => {
            setCurrentStep(3);
          }}
        >
          Next
        </div>
      </div>
      <div className="rfsregister__steps_fieldscol">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox onChange={(e) => setRecieveEmail(e.target.checked)} />
            }
            sx={{
              alignItems: "flex-start",
              "& .MuiTypography-root": {
                fontSize: 13,
                color: "#131466",
                marginTop: "5px",
              },
              "&.Mui-checked": {
                color: "#131466",
              },
            }}
            label="I understand that I will be receiving emails/sms related to freight quotes and my booked shipments and that I can unsubscribe at any time.
            "
          />
          <FormControlLabel
            control={<Checkbox onChange={(e) => setTerms(e.target.checked)} />}
            sx={{
              alignItems: "flex-start",
              "& .MuiTypography-root": {
                fontSize: 13,
                color: "#131466",
                marginTop: "5px",
              },
              "&.Mui-checked": {
                color: "#131466",
              },
            }}
            label="I have read and accept to be bound by the Terms and Conditions of Use with respect to the services being offered.
"
          />
        </FormGroup>
      </div>
    </div>
  );
};

export default RegisterStep2;
