import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import debounce from "lodash/debounce";
import { searchProducts } from "../../../../../services/product.service";

function SearchProduct({ setSelectedProducts }) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const debouncedSearchProducts = debounce(async (query) => {
    if (query?.length > 0) {
      try {
        setLoading(true);
        const response = await searchProducts(query);
        if (!response?.error) {
          setOptions(response.data?.result?.products?.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
  }, 300);

  const handleInputChange = (event, value) => {
    if (value === null) {
      setOpen(false);
    } else {
      debouncedSearchProducts(value);
    }
  };
  const handleOptionChange = (event, value) => {
    if (value) {
      setSelectedProducts((prevProducts) => [
        ...prevProducts,
        { product_id: value.id, quantity: 1, name: value.name },
      ]);
    }
  };

  return (
    <Autocomplete
      id="asynchronous-demo"
      sx={{ width: "100%", marginTop: "10px" }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option.title === value}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      onChange={handleOptionChange}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          placeholder="Enter Product Title"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default SearchProduct;
