import React, { useEffect, useState } from "react";
import "./ApprovePayment.css";

// components imports
import Loader from "../../../../../Components/CircularLoader/CircularLoader";

// apis imports
import { updateBalance } from "../../../../../services/AdminServices/PaymentsAdmin/payments.services";

// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";

const initialData = {
  balance: "",
  notes: "",
  payment_type: "",
};

export default function ApprovePayment({
  open,
  setOpen,
  setSnack,
  previousData,
  getShippersPendingPayments,
}) {
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setData(initialData);
    setOpen(false);
  };
  const user = useSelector((state) => state.user);
  const handleChange = (e) => {
    // console.log(e.target.value);
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleSave = async () => {
    setLoading(true);
    console.log(data);
    const response = await updateBalance(data, previousData?.user_id);
    getShippersPendingPayments();
    setData(initialData);
    setLoading(false);
    handleClose();
  };

  useEffect(() => {
    setData((data) => {
      return {
        ...data,
        payment_type: previousData?.payment_type,
        payment_id: previousData?.id,
      };
    });
    console.log(previousData);
  }, [previousData]);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="apprv__payment_modal">
          <div className="apprv__payment_modalheading">Approve Payment</div>
          <div className="apprv__payment_modalcontent">
            <div className="apprv__payment_modalrow">
              <div className="apprv__payment_modalinputwrap">
                Amount Paid: {previousData?.balance}
              </div>
            </div>
            <div className="apprv__payment_modalrow">
              <div className="apprv__payment_modalinputwrap">
                <input
                  className="apprv__payment_modalinput"
                  placeholder="Final Amount"
                  name="balance"
                  value={data.balance}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="apprv__payment_modalrow">
              <div className="apprv__payment_modalinputwrap">
                <input
                  className="apprv__payment_modalinput"
                  placeholder="Notes"
                  name="notes"
                  value={data.notes}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="apprv__payment_modalfooter">
            <div className="apprv__payment_modalcncl" onClick={handleClose}>
              Cancel
            </div>
            <div
              className={`apprv__payment_modalsave ${
                loading && "add__vehmodal_savedisabled"
              }  ${
                data.notes === "" &&
                data.balance === "" &&
                "add__vehmodal_savedisabled"
              }`}
              onClick={handleSave}
            >
              {loading ? <Loader /> : "Save"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
