import React from "react";

export default function orderDetails({
  data,
  AdditionalCostTotal,
  type = "show",
  Packinglist,
}) {
  return (
    <>
      <div className="invmodal__ship_details_top_wrap">
        {/* order details */}
        <div className="invoice__modal_order_shipment_summary order_detail_modal_wrap">
          <div className="invmodal__shipment_summarycntnt">
            <div className="invmodal__shipment_summaryfrom">
              <div className="invmodal__shipment_sumitemhding">Shipper</div>
              <div className="invmodal__shipment_sumitem">
                <div className="invmodal__shipment_sumitemleft" hidden>
                  Name
                </div>
                <div className="invmodal__shipment_sumitemright">
                  {data?.order_pickup_details?.[0]?.["attention"] ?? ""}{" "}
                  {data?.order_pickup_details?.[0]?.["company"] && (
                    <> ( {data?.order_pickup_details?.[0]?.["company"]} )</>
                  )}
                  {/* ( {data?.order_pickup_details?.[0]?.["company"] ?? " "} ) */}
                </div>
              </div>
              <div className="invmodal__shipment_sumitem">
                <div className="invmodal__shipment_sumitemright">
                  {data?.order_pickup_details?.[0]?.["email"] ?? ""}{" "}
                  {data?.order_pickup_details?.[0]?.["email"] &&
                    data?.order_pickup_details?.[0]?.["phone"] && (
                      <>
                        {" "}
                        <span> - </span>{" "}
                      </>
                    )}
                  {data?.order_pickup_details?.[0]?.["phone"] ?? ""}
                </div>
              </div>
              <div className="invmodal__shipment_sumitem">
                <div className="invmodal__shipment_sumitemleft" hidden>
                  Address
                </div>
                <div className="invmodal__shipment_sumitemright">
                  {data?.order_pickup_details?.[0]?.["full_address"] ?? ""}{" "}
                  {data?.order_pickup_details?.[0]?.["zip_postal"] ?? ""}
                </div>
              </div>
            </div>
            <div className="invmodal__shipment_summaryto">
              <div className="invmodal__shipment_sumitemhding">Recipient</div>
              <div className="invmodal__shipment_sumitem">
                <div className="invmodal__shipment_sumitemleft" hidden>
                  Name
                </div>
                <div className="invmodal__shipment_sumitemright">
                  {data?.order_billing_address_details?.first_name ?? ""}{" "}
                  {data?.order_billing_address_details?.company && (
                    <> ( {data.order_billing_address_details.company} )</>
                  )}
                </div>
              </div>

              <div className="invmodal__shipment_sumitem">
                <div className="invmodal__shipment_sumitemright">
                  {data?.order_billing_address_details?.email ?? ""}{" "}
                  {data?.order_billing_address_details?.email &&
                    data?.order_billing_address_details?.phone && (
                      <>
                        <span> - </span>
                      </>
                    )}
                  {data?.order_billing_address_details?.phone ?? ""}
                </div>
              </div>
              <div className="invmodal__shipment_sumitem">
                <div className="invmodal__shipment_sumitemleft" hidden>
                  Address
                </div>
                <div className="invmodal__shipment_sumitemright">
                  {data?.order_billing_address_details?.address_1 ?? ""}{" "}
                  {data?.order_billing_address_details?.postcode ?? ""}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* order details */}
        {/* header */}
        <div className="invoice__modal_order_details_header">
          <div className="invoice__modal_header_date_details content__orderid_date">
            <div className="invmodal__hright_infoitem">
            <div className="invmodal__hright_infoitem_item_wrapper">
              <div className="invmodal__hright_infoitemleft">Order Date</div>
              <div className="invmodal__hright_infoitemright">
                {data?.created_at ? data?.created_at.slice(0, 10) : ""}
              </div>
              </div>
              </div>
              <div className="invmodal__hright_infoitem">

            <div className="invmodal__hright_infoitem_item_wrapper">
                <div className="invmodal__hright_infoitemleft">Order ID</div>
                <div className="invmodal__hright_infoitemright">
                  {data?.order_id ?? ""}
                </div>
            </div>
</div>
              <div className="invmodal__hright_infoitem">
              <div className="invmodal__hright_infoitem_item_wrapper">
                <div className="invmodal__hright_infoitemleft">Order Type</div>
                <div className="invmodal__hright_infoitemright">
                  {data?.type ?? ""}
                </div>
              </div>
              </div>
              <div className="invmodal__hright_infoitem">
              <div className="invmodal__hright_infoitem_item_wrapper  invmodal__hright_infoitem_item_wrapper_with_bg">
                <div className="invmodal__hright_infoitemleft">Order Status</div>
                <div className="invmodal__hright_infoitemright">
                {data?.status ?? "-"}
                </div>
              </div>
            </div>

            
{data?.amount_pending? (<>
<div className="invmodal__hright_infoitem">
        
              <div className="invmodal__hright_infoitem_item_wrapper invmodal__hright_infoitem_item_wrapper_with_bg">
                <div className="invmodal__hright_infoitemleft">Amount Pending</div>
                <div className="invmodal__hright_infoitemright">
                {data?.amount_pending ?? "0"} $
                </div>
              </div>
           
</div>
</>) : ""}
{data?.fullfilment_status?.name?.toUpperCase() === "PENDING" && (<>
<div className="invmodal__hright_infoitem">
          
              <div className="invmodal__hright_infoitem_item_wrapper invmodal__hright_infoitem_item_wrapper_with_bg">
                <div className="invmodal__hright_infoitemleft">Fulfilment Status</div>
                <div className="invmodal__hright_infoitemright">
                {data?.fullfilment_status?.name?.toUpperCase() ?? "-"}
                </div>
              </div> 
            </div>
            </>) }
          </div>
          {!Packinglist && (
            <div className="invoice__modal_header_content">
              <div className="invmodal__hleft_infoitems">
                <div className="invmodal__hleft_infoitem">
                  {data?.user?.username ?? ""}
                </div>
                <div className="invmodal__hleft_infoitem">
                  {data?.user?.business_name ?? ""}
                </div>
                <div className="invmodal__hleft_infoitem">
                  {data?.user?.address ?? ""}
                </div>
                <div className="invmodal__hleft_infoitem">
                  {data?.user?.zip ?? ""}
                </div>
                <div className="invmodal__hleft_infoitem">
                  {data?.user?.email ?? ""}
                </div>
                <div className="invmodal__hleft_infoitem">
                  {data?.user?.phone ?? ""}
                </div>
              </div>
              <div className="invmodal__hleft_amntitems">
                <div className="invmodal__hleft_amntitem">
                  <div className="invmodal__hleft_amntitemleft bold_700">
                    Total Fulfilment Charge Of Order
                  </div>
                  <div className="invmodal__hleft_amntitemright">
                    {parseFloat(
                      parseFloat(data?.fulfillment_cost) +
                        parseFloat(AdditionalCostTotal)
                    ).toFixed(2) + "$" ?? ""}
                  </div>
                  {/* <div className="invmodal__hleft_amntitemright">
                            {data?.Total}$
                            <sup>
                              {data?.shipment_charges?.length > 1
                                ? `*${data?.shipment_charges?.length}`
                                : ""}
                            </sup>
                            <span
                              className={
                                type == "Show"
                                  ? "invmodal__hleft_airprevcharge"
                                  : "invmodal__hleft_airprevcharge invmodal__paymentterms_linethrough2"
                              }
                            >
                              {data?.shipment_charges?.length > 1
                                ? data?.shipment_charges[
                                    data?.shipment_charges?.length - 2
                                  ]?.total
                                : ""}
                            </span>
                          </div> */}
                </div>
                {/* <div className="invmodal__hleft_amntitem">
                          <div className="invmodal__hleft_amntitemleft">
                            Your payment is due by (Y-M-D)
                          </div>
                          <div className="invmodal__hleft_amntitemright">-</div>
                        </div> */}
              </div>
            </div>
          )}
        </div>
        {/* header */}
      </div>
    </>
  );
}
