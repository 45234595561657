import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import "./productquantitymodal.css";
import { productQuantity } from "../../../../services/product.service";
import CircularLoader from "../../../../Components/CircularLoader/CircularLoader";
import Form from "react-bootstrap/Form";
import Tooltips from "@mui/material/Tooltip";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import { Button } from "react-bootstrap";

export default function WarehouseProductQty({
  open,
  setOpen,
  setProducts,
  setSnack,
  setProdHistoryModal,
}) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [changed, setchanged] = useState(false);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
      ...(name === "adjQuantity" && {
        quantity: (
          Number(value) + Number(open?.row?.product_warehouse[0]?.quantity)
        ).toString(),
      }),
    }));
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const response = await productQuantity([
        {
          id: data?.id,
          quantity: data?.quantity,
          notes: data?.notes,
        },
      ]);
      if (!response.error) {
        setProducts((prevProducts) => {
          return {
            ...prevProducts,
            data: prevProducts?.data?.map((product) => {
              if (product.id == open?.row?.product_warehouse[0]?.product_id) {
                return {
                  ...product,
                  product_warehouse: [
                    {
                      ...product?.product_warehouse[0],
                      quantity: data?.quantity,
                    },
                  ],
                };
              }
              return product;
            }),
          };
        });
        // setOpen(data);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: response?.data?.message,
            severity: "success",
          };
        });
        setLoading(false);
        setProdHistoryModal(open);
        handleClose();
      } else {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: response?.data?.message ?? "Something went wrong",
            severity: "error",
          };
        });
        setLoading(false);
      }
    } catch (error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: error?.response?.data?.message || "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setData({});
  };

  useEffect(() => {
    if (open) {
      setData({
        id: open?.row?.product_warehouse[0]?.id,
        productId: open?.row?.id,
        quantity: open?.row?.product_warehouse[0]?.quantity,
        warehouse: open?.row?.product_warehouse[0]?.warehouse,
        adjQuantity: "",
        notes: "",
      });
    }
  }, [open]);

  useEffect(() => {
    let hasChanges = false;
    if (
      parseInt(data?.quantity, 10) !==
        parseInt(open?.row?.product_warehouse[0]?.quantity, 10) ||
      data?.notes != ""
    ) {
      hasChanges = true;
    }

    setchanged(hasChanges);
  }, [data, open]);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="prod__quantity_desc_modal ">
          <div className="prod__quantity_desc_modalheading">
            Product Quantity In Warehouse
          </div>
          <div className="product_quantity_box_line" />

          <div className="prod__quantity_desc_modalcontent">
            <div className={"product_quantity_box_wrapper"}>
              <div className={"product_quantity_box_active"}>
                <div className="product_quantity_box_innerwrapper">
                  <div>
                    <Form.Label className="prod__quantity_box_label">
                      Name
                    </Form.Label>
                    <div className={"product_quantity_box_name"}>
                      #:{data?.warehouse?.id} {data?.warehouse?.name ?? "-"}
                    </div>
                  </div>

                  <div className="product_quantity_box_adjquantity_wrapper">
                    <div className="product_quantity_box_button">
                      <Tooltips title="Remove Quantity" placement="top">
                        <a
                          type="button"
                          onClick={() =>
                            handleFieldChange({
                              target: {
                                name: "adjQuantity",
                                value: Number(data?.adjQuantity) - 1,
                              },
                            })
                          }
                        >
                          <IoIosRemove
                            size={25}
                            style={{
                              border: "2px solid #E2EDFB",
                              borderRadius: "25%",
                              marginLeft: "5px",
                            }}
                            className="p-1 overlaybtn"
                          />
                        </a>
                      </Tooltips>
                    </div>

                    <div>
                      <Form.Label className="prod__quantity_box_label">
                        Adjusted By
                      </Form.Label>
                      <Form.Control
                        type="number"
                        className="product_quantity_box_adj_input"
                        value={Number(data?.adjQuantity)}
                        placeholder="Adjusted By"
                        name="adjQuantity"
                        onChange={(e) => handleFieldChange(e)}
                      />
                    </div>
                    <div className="product_quantity_box_button">
                      <Tooltips title="Add Quantity" placement="top">
                        <a
                          type="button"
                          onClick={() =>
                            handleFieldChange({
                              target: {
                                name: "adjQuantity",
                                value: Number(data?.adjQuantity) + 1,
                              },
                            })
                          }
                        >
                          <IoIosAdd
                            size={25}
                            style={{
                              border: "2px solid #E2EDFB",
                              borderRadius: "25%",
                              marginRight: "5px",
                            }}
                            className="p-1 overlaybtn"
                          />
                        </a>
                      </Tooltips>
                    </div>
                  </div>

                  <div>
                    <Form.Label className="prod__quantity_box_label">
                      New
                    </Form.Label>
                    <div className="product_quantity_box_newquantitybox_wrapper">
                      <div
                        className="product_quantity_box_newquantitybox"
                        style={{
                          color:
                            Number(data?.adjQuantity) +
                              Number(data?.quantity) >=
                            Number(data?.quantity)
                              ? "blue"
                              : "red",
                        }}
                      >
                        {Number(data?.quantity)}
                      </div>
                      {Number(data?.adjQuantity) != 0 && (
                        <div className="product_quantity_box_newquantitybox">
                          <span className="product_quantity_box_actualquantity">
                            {Number(data?.quantity) - Number(data?.adjQuantity)}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="product_quantity_box_line" />
                <div className="product_quantity_box_innerwrapper">
                  <div className="product_quantity_box_reason_wrapper">
                    <label>Reason</label>
                    <textarea
                      type="text"
                      className="product_quantity_box_textarea"
                      value={data?.notes}
                      placeholder="Reason"
                      name="notes"
                      onChange={(e) => handleFieldChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="prod__quantity_desc_modalfooter">
            <div
              className="prod__quantity_desc_modalcncl"
              onClick={handleClose}
            >
              Close
            </div>
            {changed && (
              <Button
                disabled={loading}
                className="product_quantity_box_modalsave"
                onClick={handleSave}
              >
                {loading ? <CircularLoader /> : "Save"}
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
