import React, { useState, useEffect } from "react";
import "./ManageRoles.css";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Backdrop from "@mui/material/Backdrop";
import IconButton from "@mui/material/IconButton";
import { FaRegTimesCircle } from "react-icons/fa";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import {
  fetchAllPermissions,
  createNewRole,
  fetchAllRoles,
} from "../../../../../../../services/settings.services";
import { useSelector } from "react-redux";

const AddRole = ({ permisions, open, handleClose, setRoles, setSnack }) => {
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const [createRole, setCreateRole] = useState({
    name: "",
    permissions: [],
  });

  // methods

  const handelParentChange = (e, elements) => {
    console.log("value==", e.target.checked, elements);
    let roleArray = [...createRole.permissions];

    if (e.target.checked) {
      roleArray = Array.from(new Set([...createRole.permissions, ...elements]));
      //   roleArray.push(...elements);
    } else {
      roleArray = roleArray.filter((item) => !elements.includes(item));
    }
    setCreateRole({ ...createRole, permissions: roleArray });
  };

  const handelChildChange = (e, data) => {
    console.log("checked");
    let roleArray = [...createRole.permissions];
    if (e.target.checked) {
      roleArray.push(data.id);
    } else {
      roleArray = roleArray.filter((e) => e !== data.id);
    }
    setCreateRole({ ...createRole, permissions: roleArray });
  };

  const handleCreateRoles = async () => {
    setIsLoading(true);
    const saved = await createNewRole(user.token, createRole);

    if (!saved.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Role Added successfully",
          severity: "success",
        };
      });
      setIsLoading(false);
      setRoles((prevRoles) => [
        {
          ...saved.data.result,
          permissions: saved.data.result.permissions.map((p) => p.id),
        },
        ...prevRoles,
      ]);
      closeModal();
      setIsAllSelected(false);
    } else {
      console.log(saved);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: saved?.error?.response?.data?.message ?? "Something went wrong",
          severity: "error",
        };
      });
      setIsLoading(false);
      closeModal();
    }
  };

  const handelInputChange = (e) => {
    setCreateRole({ ...createRole, [e.target.name]: e.target.value });
  };

  const selectAllPermissions = (flag) => {
    setIsAllSelected(flag);

    let roleArray = [...createRole.permissions];
    if (!isAllSelected) {
      permisions.forEach((parent) => {
        roleArray.push(parent.id, ...parent.children.map((child) => child.id));
      });
      // roleArray.push(
      //   ...permisions.flatMap(
      //     (parent) => parent.id,
      //     parent.children.map((child) => child.id)
      //     // parent.children.map((child) => [parent.id, child.id])
      //   )
      // );
    } else {
      roleArray = [];
    }
    setCreateRole({ ...createRole, permissions: roleArray });
  };
  const closeModal = () => {
    handleClose();
    setCreateRole({ ...createRole, name: "", permissions: [] });
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="create__role_modal">
          <div className="role__modalheading">Create Role</div>
          <div className="manage__services_closewrap" onClick={closeModal}>
            <IconButton>
              <FaRegTimesCircle size={25} color="lightgrey" />
            </IconButton>
          </div>
          <div className="role__form_content">
            <div className="role__fieldwrap">
              <label className="role__fieldlbl">Role Name*</label>
              <input
                type="text"
                className="role__inputfield"
                placeholder="Role Name"
                value={createRole?.name}
                name="name"
                onChange={(e) => handelInputChange(e)}
              />
            </div>
            <div className="permission_heading">Permissions</div>
            <div className="card__wrapper">
              {permisions?.map((item, index) => {
                return (
                  <Card className="card__parent" key={index}>
                    <CardContent>
                      <div className="permission__wraper">
                        <div className="parent__label">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={createRole.permissions.includes(
                                    item.id
                                  )}
                                  onChange={(e) =>
                                    handelParentChange(e, [
                                      ...item.children.map((e) => e.id),
                                      item.id,
                                    ])
                                  }
                                />
                              }
                              label={item?.description}
                            />
                          </FormGroup>
                        </div>
                        <div className="child__wraper">
                          {item.children.map((data, ind) => {
                            return (
                              <FormGroup key={ind}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={createRole.permissions.includes(
                                        data.id
                                      )}
                                      onChange={(e) =>
                                        handelChildChange(e, data)
                                      }
                                    />
                                  }
                                  label={data?.description}
                                />
                              </FormGroup>
                            );
                          })}
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                );
              })}
            </div>
          </div>

          <div
            className="addcus__account_modalfooter"
            style={{
              justifyContent: "space-between",
            }}
          >
            <div
              className="permissions__button"
              onClick={() => selectAllPermissions(!isAllSelected)}
            >
              {!isAllSelected ? "Select All" : "UnSelect All"}
            </div>
            <div className="permissions__button" onClick={handleCreateRoles}>
              {isLoading ? <Loader /> : "Save"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddRole;

function Loader(props) {
  return (
    <Box
      sx={{
        position: "relative",
        top: "2px",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={20}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={20}
        thickness={4}
        {...props}
      />
    </Box>
  );
}
