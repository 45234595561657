import React, { useState } from "react";
import "../../Products/AddProduct/Sections/Sections.css";
import { BiCloudUpload } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import { useEffect } from "react";
import noImage from "../../../assets/images/noImage.png";
import pdfIcon from "../../../assets/icons/pdfIcon.png";
import excelIcon from "../../../assets/icons/excelIcon.png";
import { checkFileType } from "../../../helpers/helpers";

const Attachments = ({
  data,
  setData,
  notesHistoryModa,
  setNotesHistoryModal,
  editMode,
}) => {
  const [uploadedImages, setUploadedImages] = useState();
  const handleImageUpload = (e) => {
    const uploadedImages = Array.from(e.target.files);
    // Filter out non image files
    // const imageFiles = uploadedImages.filter((file) =>
    //   file.type.startsWith("image/")
    // );
    setUploadedImages(uploadedImages);
  };
  const handleFieldChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };
  const handleRemoveImage = (index) => {
    if (uploadedImages[0]?.file_url) {
      setData({
        ...data,
        deletedMediaIds: [...data?.deletedMediaIds, uploadedImages[index]?.id],
      });
    }
    const updatedImages = [...uploadedImages];
    updatedImages.splice(index, 1);

    setUploadedImages(updatedImages);
  };

  useEffect(() => {
    setData({
      ...data,
      attachments: uploadedImages,
    });
    // console.log(uploadedImages);
  }, [uploadedImages]);

  // console.log("attachments", data?.attachments);
  return (
    <section className="sss__payment_section">
      <div className="sss__prodsection_heading">Attachments</div>
      <div
        className="sss__addressection_inputrow"
        style={{ marginTop: "10px", marginBottom: "0px" }}
      >
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "200px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">Add Note</label>
            {editMode && (
              <span
                className="sss__addressection_prevnotesbtn"
                onClick={() => setNotesHistoryModal(data)}
              >
                Previous Notes
              </span>
            )}
            <textarea
              className=" sss__addressection_textarea"
              rows="3"
              onChange={(e) => handleFieldChange("note", e.target.value)}
              value={data.note}
            ></textarea>
            {/* <div className="sss__addressection_inputfielderror">
              <MdErrorOutline className="sss__addressection_inputfielderroricn" />
              Supplier Name is required
            </div> */}
          </div>
        </div>
        {/* <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "120px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">Reference</label>
            <textarea
              className=" sss__addressection_textarea"
              rows="3"
            ></textarea>
            <div className="sss__addressection_inputfielderror">
              <MdErrorOutline className="sss__addressection_inputfielderroricn" />
              Supplier Cost is required
            </div>
          </div>
        </div> */}
      </div>
      <div className="attachments__formsection_mediabox">
        <>
          <div className="attachments__formsec_imagebtns">
            <label className="attachments__formsec_uploadimagebtn">
              <BiCloudUpload
                size={20}
                className="attachments__formsection_mdbtnicon"
              />
              Upload Attachments
              <input
                type="file"
                hidden
                multiple
                id="product_media"
                onChange={handleImageUpload}
              />
            </label>
            {/* <div
                className="attachments__formsec_addurlbtn"
                onClick={() => setAddUrlBox(true)}
              >
                <MdOutlineAttachment
                  size={20}
                  className="attachments__formsection_mdbtnicon"
                />
                Add Url
              </div> */}
          </div>
          <div className="attachments__formsection_mdbtmtext">
            You can upload multiple Attachments.
          </div>
        </>
      </div>
      <div
        className="attachments__secformrow_images"
        style={{
          marginBottom: uploadedImages?.length < 1 ? "0px" : "",
        }}
      >
        {data?.attachments?.map((image, index) => (
          <div className="attachments__secformrow_imagewrap">
            <MdClose
              className="attachments__secformrow_imageclose"
              size={20}
              onClick={() => handleRemoveImage(index)}
            />
            <img
              src={
                checkFileType(image?.name) === "Image"
                  ? URL.createObjectURL(image)
                  : checkFileType(image?.name) === "PDF"
                  ? pdfIcon
                  : checkFileType(image?.name) === "Excel"
                  ? excelIcon
                  : noImage
              }
              accept="image/*"
              loading="lazy"
              className="attachments__secformrow_image"
              onError={(e) => {
                e.target.src = noImage;
              }}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Attachments;
