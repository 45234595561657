import React, { useState } from "react";
import "../../InvoiceModal.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { MdAdd } from "react-icons/md";
import Select from "@mui/material/Select";
import Attachments from "../Attachments/Attachments";
import {
  updateShipment,
  updateAdminShipment,
} from "../../../../services/admin.services";

import Snackbar from "../../../SnackBar/SnackBar";
import CircularLoader from "../../../CircularLoader/CircularLoader";
import { useEffect } from "react";
import { checkFileType } from "../../../../helpers/helpers";
import noImage from "../../../../assets/images/noImage.png";
import pdfIcon from "../../../../assets/icons/pdfIcon.png";
import excelIcon from "../../../../assets/icons/excelIcon.png";
import ImagesModal from "../../../../Pages/Shipper/Customer/MainPages/Orders/Components/OrderHistoryModal/ImagesModal/ImagesModal";
import { TextField } from "@mui/material";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import IconButton from "@mui/material/IconButton";
import Tooltip from "../../../Tooltip/Tooltip";

const AdminActions = ({
  data,
  setData,
  setShipments,
  getAllShipments,
  type,
}) => {
  const [loading, setLoading] = useState();
  const [attachmentsModal, setAttachmentsModal] = useState();
  const [fullview, setfullview] = useState();
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });
  const [isModified, setIsModified] = useState(false);
  const [isIdModified, setIsIdModified] = useState(false);
  const [InvoiceDescription, setInvoiceDescription] = useState("");
  const [adminInvoiceId, setAdminInvoiceId] = useState("");
  const [visible, setVisible] = useState(
    type === "admin" || type === "user" ? true : false
  );

  const handleDescriptionChange = (event) => {
    const newDescription = event.target.value;
    const isDescriptionModified =
      (data?.invoice_description !== null &&
        newDescription !== data?.invoice_description) ||
      (data?.invoice_description === null && newDescription !== "");
    // console.log(isDescriptionModified);
    setInvoiceDescription(newDescription);
    setIsModified(isDescriptionModified);
  };
  const handleAdminIdChange = (value) => {
    const newId = value;
    const isModified =
      (data?.admin_invoice_id !== null && newId !== data?.admin_invoice_id) ||
      (data?.admin_invoice_id === null && newId !== "");
    setAdminInvoiceId(newId);
    setIsIdModified(isModified);
  };

  const handleAdminId = async () => {
    setLoading("Admin_id");

    try {
      const formData = new FormData();
      formData.append("admin_invoice_id", adminInvoiceId);
      let response = "";
      response = await updateAdminShipment(formData, data?.id);
      setLoading(false);
      if (!response?.error) {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Id updated Successfully",
            severity: "success",
          };
        });
        setIsIdModified(false);
        setInvoiceDescription("");

        setData({
          ...data,
          admin_invoice_id: response?.data?.result?.admin_invoice_id,
        });
        setShipments((prevShipments) => {
          return {
            ...prevShipments,
            data: prevShipments?.data?.map((shipment) => {
              if (shipment.id === data.id) {
                return {
                  ...shipment,
                  admin_invoice_id: response?.data?.result?.admin_invoice_id,
                };
              } else {
                return shipment;
              }
            }),
          };
        });
      } else {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Something went wrong",
            severity: "error",
          };
        });
      }
    } catch (error) {
      setLoading(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };
  const handleAdminNotes = async () => {
    setLoading("Admin_notes");

    try {
      const formData = new FormData();
      formData.append("invoice_description", InvoiceDescription);
      // formData.append("type", type ?? "admin");

      let response = "";
      if (type === "user") {
        response = await updateShipment(formData, data?.id);
      } else {
        response = await updateAdminShipment(formData, data?.id);
      }
      // console.log(response?.data?.result?.invoice_description);
      setLoading(false);
      if (!response?.error) {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Notes updated Successfully",
            severity: "success",
          };
        });
        setIsModified(false);
        setInvoiceDescription("");

        setData({
          ...data,
          shipment_notes: response?.data?.result?.shipment_notes,
        });
        setShipments((prevShipments) => {
          return {
            ...prevShipments,
            data: prevShipments?.data?.map((shipment) => {
              if (shipment.id === data.id) {
                return {
                  ...shipment,
                  shipment_notes: response?.data?.result?.shipment_notes,
                };
              } else {
                return shipment;
              }
            }),
          };
        });
      } else {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Something went wrong",
            severity: "error",
          };
        });
      }
    } catch (error) {
      setLoading(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const handleAdminAttachmentChange = async (e) => {
    const files = e.target.files;
    if (files.length === 0) {
      return;
    }
    setLoading("attachment");
    try {
      const files = e.target.files;
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`attachment[${index}]`, file);
      });
      // formData.append("type", type ?? "admin");
      let response = "";
      if (type === "user") {
        response = await updateShipment(formData, data?.id);
      } else {
        response = await updateAdminShipment(formData, data?.id);
      }

      setLoading(false);
      if (!response?.error) {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Attachment Added Successfully",
            severity: "success",
          };
        });
        setData({
          ...data,
          media: response?.data?.result?.media,
        });
        setShipments((prevShipments) => {
          return {
            ...prevShipments,
            data: prevShipments?.data?.map((shipment) => {
              if (shipment.id === data.id) {
                return {
                  ...shipment,
                  media: response?.data?.result?.media,
                };
              } else {
                return shipment;
              }
            }),
          };
        });
      } else {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Something went wrong",
            severity: "error",
          };
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };
  const handleBillingVerfication = async (e) => {
    setLoading("verification");
    const statusObj =
      type === "user"
        ? { user_invoice_status: e.target.value }
        : { admin_invoice_status: e.target.value };
    setData({
      ...data,
      ...statusObj,
    });
    try {
      const formData = new FormData();
      const statusKey =
        type === "user" ? "user_invoice_status" : "admin_invoice_status";
      formData.append(statusKey, e.target.value);
      // formData.append("type", type ?? "admin");
      let response = "";
      if (type === "user") {
        response = await updateShipment(formData, data?.id);
      } else {
        response = await updateAdminShipment(formData, data?.id);
      }
      setLoading(false);
      if (!response?.error) {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Status updated Successfully",
            severity: "success",
          };
        });
        setShipments((prevShipments) => {
          return {
            ...prevShipments,
            data: prevShipments?.data?.map((shipment) => {
              if (shipment.id === data.id) {
                const statusObj =
                  type === "user"
                    ? {
                        user_invoice_status:
                          response?.data?.result?.user_invoice_status,
                      }
                    : {
                        admin_invoice_status:
                          response?.data?.result?.admin_invoice_status,
                      };

                return {
                  ...shipment,
                  ...statusObj,
                };
              } else {
                return shipment;
              }
            }),
          };
        });
      } else {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Something went wrong",
            severity: "error",
          };
        });
      }
    } catch (error) {
      setLoading(false);
      const statusObj =
        type === "user"
          ? {
              admin_invoice_status: !e.target.checked
                ? "verified"
                : "unverified",
            }
          : {
              user_invoice_status: !e.target.checked
                ? "verified"
                : "unverified",
            };
      setData({
        ...data,
        ...statusObj,
      });
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };
  useEffect(() => {
    if (data?.invoice_description?.trim() && type === "admin") {
      const invoiceDescription = {
        id: Math.floor(Math.random() * 10000000),
        notes: data.invoice_description,
        category: "description",
      };

      const lastShipmentNote = data?.shipment_notes?.length
        ? data.shipment_notes[data.shipment_notes.length - 1]
        : null;

      if (lastShipmentNote?.category !== "description") {
        setData((prevData) => ({
          ...prevData,
          shipment_notes: [
            ...(prevData.shipment_notes || []),
            invoiceDescription,
          ],
        }));
      }
    }
    if (data?.admin_invoice_id && type === "admin") {
      setAdminInvoiceId(data?.admin_invoice_id);
    }
  }, [data]);

  return (
    <>
      <ImagesModal setOpen={setfullview} open={fullview} />
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <div className="invmodal__editableinv_actions">
        <div className="invmodal__shipment_detailshding">
          Invoice Actions{" "}
          {type === "admin"
            ? "(By Admin)"
            : type === "user" || type === "admin-user"
            ? "(By User)"
            : ""}
          <Tooltip title={visible ? "Collapse" : "Expand"}>
            <IconButton onClick={() => setVisible(!visible)}>
              {visible ? (
                <MdOutlineKeyboardArrowUp size={25} />
              ) : (
                <MdOutlineKeyboardArrowDown size={25} />
              )}
            </IconButton>
          </Tooltip>
        </div>
        {visible && (
          <>
            <div className="invmodal__einvactions_content">
              <div className="invmodal__einvactions_contentlft">
                <div
                  className={`invmodal__shippymnt_verifiedtoggle ${
                    type === "admin-user" ? "disable__click_fade" : ""
                  }`}
                >
                  <label>Invoice Status </label>
                  <FormControl
                    sx={{ width: "100%", textTransform: "capitalize" }}
                    variant="outlined"
                    size="small"
                  >
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={
                        (type === "user" || type === "admin-user"
                          ? data?.user_invoice_status
                          : data?.admin_invoice_status) ?? "none"
                      }
                      onChange={handleBillingVerfication}
                    >
                      <MenuItem disabled value="none">
                        <em>Invoice Status</em>
                      </MenuItem>
                      <MenuItem value="Unpaid">Unpaid</MenuItem>
                      <MenuItem value="Extra Charge Paid">
                        Extra Charge Paid
                      </MenuItem>
                      <MenuItem value="Less Charge Paid">
                        Less Charge Paid
                      </MenuItem>
                      <MenuItem value="Paid">Paid</MenuItem>
                      <MenuItem value="Disputed">Disputed</MenuItem>
                      <MenuItem value="Dispute Won">Dispute Won</MenuItem>
                      <MenuItem value="Dispute Lost">Dispute Lost</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div
                  className="invmodal__shippymnt_atbtnloaderwrap"
                  style={{
                    visibility: ["verification"].includes(loading)
                      ? "visible"
                      : "hidden",
                  }}
                >
                  <CircularLoader size={16} />
                </div>
              </div>
              {type === "admin" && (
                <div className="invmodal__einvactions_contentrt">
                  <div className="invmodal__einvactions_contentrtformgroup">
                    <label>Admin Invoice ID </label>

                    <div className="invmodal__einvactions_contentrtformfieldwrap">
                      <TextField
                        placeholder="Enter Admin Invoice ID"
                        onChange={(e) => handleAdminIdChange(e.target.value)}
                        size="small"
                        sx={{ flex: 1 }}
                        value={adminInvoiceId}
                      />
                      {isIdModified && (
                        <button
                          className="update__charge_rblftbtnsave"
                          onClick={handleAdminId}
                        >
                          {loading === "Admin_id" ? (
                            <CircularLoader size={16} color="grey" />
                          ) : (
                            "Save"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {type !== "admin" && (
                <div className="invmodal__einvactions_contentrt">
                  <div className="invmodal__shippymnt_atchmentbtnwrap">
                    {attachmentsModal === "admin" && (
                      <Attachments
                        onClose={() => setAttachmentsModal(false)}
                        attachments={data?.media}
                        getAllShipments={getAllShipments}
                        setSnack={setSnack}
                        setData={setData}
                        setShipments={setShipments}
                        data={data}
                        type="admin"
                        size="small"
                      />
                    )}
                    <label>Attachments </label>
                    {type === "admin" && (
                      <>
                        {
                          <div className="notes__attachments_modal_attachment_thumbnail_wrap">
                            <div className="notes__attachments_modal_attachment_thumbnail notes__attachments_modal_attachment_thumbnail2">
                              {data?.media
                                ?.filter(
                                  (attachment) => attachment?.meta === "admin"
                                )
                                .map((attachment, index) => (
                                  <img
                                    key={attachment?.id}
                                    src={
                                      attachment?.file &&
                                      (checkFileType(attachment.file) ===
                                      "Image"
                                        ? attachment.file
                                        : checkFileType(attachment.file) ===
                                          "PDF"
                                        ? pdfIcon
                                        : checkFileType(attachment.file) ===
                                          "Excel"
                                        ? excelIcon
                                        : noImage)
                                    }
                                    alt={`Attachment ${index + 1}`}
                                    onError={(e) => {
                                      e.target.src = noImage;
                                    }}
                                    onClick={() => {
                                      if (
                                        checkFileType(attachment.file) ===
                                        "Image"
                                      ) {
                                        setfullview(attachment?.file || "");
                                      } else {
                                        window.open(attachment?.file, "_blank");
                                      }
                                    }}
                                  />
                                ))}
                              <div className="invmodal__shippymnt_addattachmnetbtnwrap">
                                <label className="invmodal__shippymnt_addattachmnetbtn2">
                                  <input
                                    type="file"
                                    hidden
                                    multiple
                                    id="update_charge_attachment2"
                                    onChange={handleAdminAttachmentChange}
                                  />

                                  <MdAdd size={20} />

                                  {/* <span>CLICK TO UPLOAD FILES</span> */}
                                </label>
                              </div>
                            </div>
                          </div>
                        }
                      </>
                    )}

                    {["user", "admin-user"].includes(type) && (
                      <div className="notes__attachments_modal_attachment_thumbnail_wrap">
                        <div className="notes__attachments_modal_attachment_thumbnail notes__attachments_modal_attachment_thumbnail2">
                          {data?.media
                            ?.filter(
                              (attachment) => attachment?.meta === "user"
                            )
                            .map((attachment, index) => (
                              <img
                                key={attachment?.id}
                                src={
                                  attachment?.file &&
                                  (checkFileType(attachment.file) === "Image"
                                    ? attachment.file
                                    : checkFileType(attachment.file) === "PDF"
                                    ? pdfIcon
                                    : checkFileType(attachment.file) === "Excel"
                                    ? excelIcon
                                    : noImage)
                                }
                                alt={`Attachment ${index + 1}`}
                                onError={(e) => {
                                  e.target.src = noImage;
                                }}
                                onClick={() => {
                                  if (
                                    checkFileType(attachment.file) === "Image"
                                  ) {
                                    setfullview(attachment?.file || "");
                                  } else {
                                    window.open(attachment?.file, "_blank");
                                  }
                                }}
                              />
                            ))}
                          {type === "user" && (
                            <div className="invmodal__shippymnt_addattachmnetbtnwrap">
                              <label className="invmodal__shippymnt_addattachmnetbtn2">
                                <input
                                  type="file"
                                  hidden
                                  multiple
                                  id="update_charge_attachment2"
                                  onChange={handleAdminAttachmentChange}
                                />

                                <MdAdd size={20} />
                              </label>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <span
                    className="invmodal__shippymnt_atbtnloaderwrap"
                    style={{
                      visibility: ["attachment"].includes(loading)
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    <CircularLoader size={16} />
                  </span>
                </div>
              )}
            </div>
            {type === "admin" && (
              <div className="invmodal__einvactions_content">
                <div className="invmodal__einvactions_contentrt">
                  <div className="invmodal__shippymnt_atchmentbtnwrap">
                    {attachmentsModal === "admin" && (
                      <Attachments
                        onClose={() => setAttachmentsModal(false)}
                        attachments={data?.media}
                        getAllShipments={getAllShipments}
                        setSnack={setSnack}
                        setData={setData}
                        setShipments={setShipments}
                        data={data}
                        type="admin"
                        size="small"
                      />
                    )}
                    <label>Attachments </label>
                    {type === "admin" && (
                      <>
                        {
                          <div className="notes__attachments_modal_attachment_thumbnail_wrap">
                            <div className="notes__attachments_modal_attachment_thumbnail notes__attachments_modal_attachment_thumbnail2">
                              {data?.media
                                ?.filter(
                                  (attachment) => attachment?.meta === "admin"
                                )
                                .map((attachment, index) => (
                                  <img
                                    key={attachment?.id}
                                    src={
                                      attachment?.file &&
                                      (checkFileType(attachment.file) ===
                                      "Image"
                                        ? attachment.file
                                        : checkFileType(attachment.file) ===
                                          "PDF"
                                        ? pdfIcon
                                        : checkFileType(attachment.file) ===
                                          "Excel"
                                        ? excelIcon
                                        : noImage)
                                    }
                                    alt={`Attachment ${index + 1}`}
                                    onError={(e) => {
                                      e.target.src = noImage;
                                    }}
                                    onClick={() => {
                                      if (
                                        checkFileType(attachment.file) ===
                                        "Image"
                                      ) {
                                        setfullview(attachment?.file || "");
                                      } else {
                                        window.open(attachment?.file, "_blank");
                                      }
                                    }}
                                  />
                                ))}
                              <div className="invmodal__shippymnt_addattachmnetbtnwrap">
                                <label className="invmodal__shippymnt_addattachmnetbtn2">
                                  <input
                                    type="file"
                                    hidden
                                    multiple
                                    id="update_charge_attachment2"
                                    onChange={handleAdminAttachmentChange}
                                  />

                                  <MdAdd size={20} />

                                  {/* <span>CLICK TO UPLOAD FILES</span> */}
                                </label>
                              </div>
                            </div>
                          </div>
                        }
                      </>
                    )}

                    {["user", "admin-user"].includes(type) && (
                      <div className="notes__attachments_modal_attachment_thumbnail_wrap">
                        <div className="notes__attachments_modal_attachment_thumbnail notes__attachments_modal_attachment_thumbnail2">
                          {data?.media
                            ?.filter(
                              (attachment) => attachment?.meta === "user"
                            )
                            .map((attachment, index) => (
                              <img
                                key={attachment?.id}
                                src={
                                  attachment?.file &&
                                  (checkFileType(attachment.file) === "Image"
                                    ? attachment.file
                                    : checkFileType(attachment.file) === "PDF"
                                    ? pdfIcon
                                    : checkFileType(attachment.file) === "Excel"
                                    ? excelIcon
                                    : noImage)
                                }
                                alt={`Attachment ${index + 1}`}
                                onError={(e) => {
                                  e.target.src = noImage;
                                }}
                                onClick={() => {
                                  if (
                                    checkFileType(attachment.file) === "Image"
                                  ) {
                                    setfullview(attachment?.file || "");
                                  } else {
                                    window.open(attachment?.file, "_blank");
                                  }
                                }}
                              />
                            ))}
                          {type === "user" && (
                            <div className="invmodal__shippymnt_addattachmnetbtnwrap">
                              <label className="invmodal__shippymnt_addattachmnetbtn2">
                                <input
                                  type="file"
                                  hidden
                                  multiple
                                  id="update_charge_attachment2"
                                  onChange={handleAdminAttachmentChange}
                                />

                                <MdAdd size={20} />

                                {/* <span>CLICK TO UPLOAD FILES</span> */}
                              </label>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {/* <Tooltip title="Uploaded Attachments">
                <span
                  className="invmodal__shippymnt_attachmenicon"
                  // onClick={() => downloadFiles(data?.media)}
                  onClick={() => setAttachmentsModal("admin")}
                  style={{
                    visibility: data?.media?.length ? "visible" : "hidden",
                  }}
                >
                  {data?.media?.length ? (
                    <span className="invmodal__shippymnt_attachmenbadge">
                      {data?.media?.length ?? ""}
                    </span>
                  ) : (
                    ""
                  )}
                  <MdAttachment size={22} />
                </span>
              </Tooltip> */}
                  </div>
                  <span
                    className="invmodal__shippymnt_atbtnloaderwrap"
                    style={{
                      visibility: ["attachment"].includes(loading)
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    <CircularLoader size={16} />
                  </span>
                </div>
              </div>
            )}
            {data?.shipment_notes?.length > 0 && (
              <div
                className="invmodal__note_user_box invoice__modal_user_box"
                style={{ height: "auto" }}
              >
                <div
                  className="invmodal__note_boxdesc"
                  style={{ minHeight: "fit-content" }}
                >
                  <table className="invmodal__spnote_table">
                    <tr className="invmodal__spnote_tableheading">
                      <th className="invmodal__spnote_tablecell">Note</th>
                      <th className="invmodal__spnote_tablecell">Added By</th>
                      <th className="invmodal__spnote_tablecell">Created At</th>
                    </tr>
                    {["user", "admin-user"].includes(type) &&
                      data?.shipment_notes
                        ?.filter((item) => item.type === "user")
                        ?.map((note, index) => (
                          <tr key={index}>
                            <td className="invmodal__spnote_tablecell">
                              <div className="bolt__tablemax_fixed_height">
                                {note?.notes}
                              </div>
                            </td>
                            <td className="invmodal__spnote_tablecell">
                              <div className="bolt__tablemax_fixed_height">
                                {note?.type}
                              </div>
                            </td>
                            {note?.created_at ? (
                              <td className="invmodal__spnote_tablecell">
                                <div className="bolt__tablemax_fixed_height">
                                  {note?.created_at?.slice(0, 10) ?? "-"} (
                                  {note?.created_at?.slice(11, 16) ?? "-"})
                                </div>
                              </td>
                            ) : (
                              <td></td>
                            )}
                          </tr>
                        ))}
                    {["admin"].includes(type) &&
                      data?.shipment_notes
                        ?.filter(
                          (item) =>
                            item.type === "admin" ||
                            item.category === "description"
                        )
                        ?.map((note, index) => (
                          <tr key={index}>
                            <td className="invmodal__spnote_tablecell">
                              <div className="bolt__tablemax_fixed_height">
                                {note?.notes}
                              </div>
                            </td>
                            <td className="invmodal__spnote_tablecell">
                              <div className="bolt__tablemax_fixed_height">
                                {note?.type}
                              </div>
                            </td>
                            {note?.created_at ? (
                              <td className="invmodal__spnote_tablecell">
                                <div className="bolt__tablemax_fixed_height">
                                  {note?.created_at?.slice(0, 10) ?? "-"} (
                                  {note?.created_at?.slice(11, 16) ?? "-"})
                                </div>
                              </td>
                            ) : (
                              <td></td>
                            )}
                          </tr>
                        ))}
                  </table>
                </div>{" "}
              </div>
            )}

            {type !== "admin-user" && (
              <div className="update__charge_reasonbox">
                <div className="update__charge_reasonboxlft">
                  <label>Add a Note </label>
                  <textarea
                    className="invmodal__charge_desc"
                    placeholder="Write a Note."
                    name="description"
                    onChange={handleDescriptionChange}
                    value={InvoiceDescription ?? ""}
                  ></textarea>
                </div>
              </div>
            )}
            <div className="update__charge_rblftbtns">
              {isModified && (
                <button
                  className="update__charge_rblftbtnsave"
                  onClick={handleAdminNotes}
                >
                  {loading === "Admin_notes" ? (
                    <CircularLoader size={16} color="grey" />
                  ) : (
                    "Save"
                  )}
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AdminActions;
