import React, { useState, useEffect } from "react";
import "./editbrand.css";

// components imports
import Loader from "../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import { editBrand } from "../../../../../services/warehouse.services";
// library imports
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Button, Input, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { showSnackBar } from "../../../../../store/common/snackBarSlice";
import { useDispatch } from "react-redux";

const StyledUploadContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: "2px dashed #2d76bf",
  borderRadius: "8px",
  backgroundColor: "#fff",
  cursor: "pointer",
  marginBottom: "15px",
});

const EditBrand = ({ open, setOpen, setBrands }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    file: "",
    name: "",
    description: "",
    code: "",
  });

  const dispatch = useDispatch();

  const handelChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading("main");
    if (formData.name === "") {
      dispatch(showSnackBar({
        text: "Please enter brand name",
        severity: "error",
      }))
      setLoading(false);
      return;
    }
    if (formData.file === "") {
      dispatch(showSnackBar({
        text: "Please Select brand logo ",
        severity: "error",
      }))
      setLoading(false);
      return;
    }
    const Formdata = new FormData();
    Formdata.append("file", formData.file);
    Formdata.append("name", formData.name);
    Formdata.append("description", formData.description);

    const response = await editBrand(open?.id, Formdata);
    // console.log("response", response.data.result);
    if (response.error) {
      dispatch(showSnackBar({
        text:
          response?.error?.response?.data?.message || "Something went wrong",
        severity: "error",
      }))
      setLoading(false);
    } else {
      dispatch(showSnackBar({
        text: "Brand Edit Successfully",
        severity: "success",
      }))
      setBrands((prevbrands) => {
        return {
          ...prevbrands,
          data: prevbrands?.data?.map((p) => {
            return p.id === open?.id ? response?.data?.result : p;
          }),
        };
      });
      setFormData({
        file: "",
        name: "",
        description: "",
        code: "",
      });
      setLoading(false);
      setOpen(false);
    }
  };
  useEffect(() => {
    setFormData({
      file: open?.file,
      name: open?.name,
      description: open?.description,
      code: open?.code,
    });
  }, [open]);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="add-brand-modal-title"
        aria-describedby="add-brand-modal-description"
      >
        <div className="brandStock__add_new_brand_modal">
          <div className="brandStock__add_new_brand_modal_wrap">
            <div className="brandStock__add_new_brand">
              <div className="brandStock__add_new_brand_header">
                <h3 className="brandStock__add_new_brand_title">Edit Brand</h3>
              </div>
              <div className="brandStock__add_new_brand_body">
                <FormControl
                  variant="outlined"
                  className="brandStock__add_new_brand_code"
                >
                  <InputLabel htmlFor="addStockCountCode" size="small">
                    Code *
                  </InputLabel>
                  <OutlinedInput
                    id="addStockCountCode"
                    name="code"
                    value={formData?.code}
                    // onChange={(e) => handelChange(e)}
                    disabled={"true"}
                    size="small"
                    inputProps={{ pattern: "[0-9]*" }}
                    label="Code *"
                  />
                </FormControl>
                <FormControl
                  variant="outlined"
                  className="brandStock__add_new_brand_name"
                >
                  <InputLabel htmlFor="addStockCountName" size="small">
                    Name *
                  </InputLabel>
                  <OutlinedInput
                    id="addStockCountName"
                    name="name"
                    value={formData?.name}
                    onChange={(e) => handelChange(e)}
                    size="small"
                    label="Name *"
                  />
                </FormControl>
                <StyledUploadContainer className="file__upload_csv_row">
                  <label htmlFor="file-upload">
                    <Input
                      id="file-upload"
                      type="file"
                      accept=".jpg,.JPG,.png,.PNG,.gif,.GIF"
                      style={{ display: "none" }}
                    //   onChange={handleImageChange}
                    />
                    <Typography variant="h6" color="primary" component="div">
                      Upload Logo to Submit
                    </Typography>
                  </label>
                </StyledUploadContainer>

                <FormControl
                  variant="outlined"
                  fullWidth
                  className="brandStock__add_new_brand_description"
                >
                  <InputLabel htmlFor="addStockCountDescription" size="small">
                    Description
                  </InputLabel>
                  <OutlinedInput
                    id="addStockCountDescription"
                    name="description"
                    value={formData?.description}
                    onChange={(e) => handelChange(e)}
                    size="small"
                    label="Description"
                    multiline
                    fullWidth
                    rows={5}
                    rowsMax={5}
                  />
                </FormControl>

                <div className="edit_brand_modalfooter">
                  <div
                    className="edit_brand_modalreset"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </div>
                  <div
                    className="edit_brand_modalsubmit"
                    onClick={handleSubmit}
                  >
                    {loading ? <Loader /> : "Save"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditBrand;
