import React from "react";
import "./Requirements.css";
const NationxRequirements = () => {
  return (
    <div className="cuscarrier__reqrmnts_box">
      These are some requirements for Nationx
    </div>
  );
};

export default NationxRequirements;
