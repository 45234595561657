import React, { useState, useEffect } from "react";
import "./availableproducts.css";

import Tooltips from "../../../../Components/Tooltip/Tooltip";
import IconButton from "@mui/material/IconButton";
import GridViewIcon from "@mui/icons-material/GridView";
import ListIcon from "@mui/icons-material/List";
import AvailableGridView from "./AvailableGridView";
import AvailableListView from "./AvailableListView";

export default function ProductScroller(props) {
  const { SelectedProducts, setSelectedProducts, formData } = props;

  const [view, setView] = useState("List");
  const [filterComponent, setFilterComponent] = useState(null);

  const setFilter = (filterElement) => {
    setFilterComponent(filterElement);
  };

  const handlescrollpackage = (data) => {
    if (!formData?.warehouse_id) {
      return;
    }
    const productId = data.id;
    const existingProductIndex = SelectedProducts.findIndex(
      (product) => product.product_id === productId
    );
    if (existingProductIndex !== -1) {
      const updatedProducts = [...SelectedProducts];
      updatedProducts[existingProductIndex].quantity += 1;
      setSelectedProducts(updatedProducts);
    } else {
      const newData = {
        id: data?.id,
        quantity: 1,
        shipper_notes: data?.shipper_notes,
        warehouse_notes: data?.warehouse_notes,
        media: data?.media,
        name: data?.name,
        length: data?.length,
        width: data?.width,
        height: data?.height,
        weight: data?.weight,
        sale_price: data?.sale_price,
        product_id: data?.id,
        weight_unit: data?.weight_unit,
        code: data?.code,
      };
      setSelectedProducts([...SelectedProducts, newData]);
    }
  };

  const handleView = () => {
    if (view == "Grid") {
      setFilterComponent(null);
      setView("List");
    } else if (view == "List") {
      setView("Grid");
    }
  };

  return (
    <div
      className="card bg-white shadow"
      style={{
        borderRadius: "15px",
        padding: "15px",
        marginTop: "10px",
        minHeight: "160px",
      }}
    >
      <div
        style={{
          paddingBottom: "10px",
          marginBottom: "10px",
          borderBottom: "1px solid #d2d2d2",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <b>Products</b>
        {/* <div className="expand_btn" onClick={handleExpandToggle}>
          {isExpanded ? (
            <>
              <Tooltips title="Collapse">
                <IconButton>
                  <MdOutlineKeyboardArrowUp size={25} />
                </IconButton>
              </Tooltips>
            </>
          ) : (
            <>
              <Tooltips title="Expand">
                <IconButton className="fullfilment__form_expand_btn">
                  <MdOutlineKeyboardArrowDown size={25} />
                </IconButton>
              </Tooltips>
            </>
          )}
        </div> */}
      </div>

      <div className="product__scroller_wrap_main_container">
        {filterComponent}
        <div
          className="grid__list_btns_wrap"
          style={{ justifyContent: "flex-start" }}
        >
          <Tooltips title="List View">
            <IconButton
              className="fullfilment__form_expand_btn"
              color={view == "List" ? "primary" : "default"}
              onClick={handleView}
            >
              <ListIcon size={15} />
            </IconButton>
          </Tooltips>
          <Tooltips title="Grid View">
            <IconButton
              className="fullfilment__form_expand_btn"
              color={view == "Grid" ? "primary" : "default"}
              onClick={handleView}
            >
              <GridViewIcon size={15} />
            </IconButton>
          </Tooltips>
        </div>
      </div>

      {view == "Grid" && (
        <>
          <AvailableGridView
            handlescrollpackage={handlescrollpackage}
            setFilter={setFilter}
            formData={formData}
          />
        </>
      )}
      {view == "List" && (
        <>
          <AvailableListView
            formData={formData}
            handlescrollpackage={handlescrollpackage}
          />
        </>
      )}
    </div>
  );
}
