import React, { useState, useEffect } from "react";
import noImage from "../../../../../../../assets/images/noImage.png";
import ProductTable from "../../../../../../../Components/BoltTable/BoltTable";
import { PiPackageLight } from "react-icons/pi";
import { useSelector } from "react-redux";
import { fetchOnlyProducts } from "../../../../../../../services/product.service";
import Tooltip from "../../../../../../../Components/Tooltip/Tooltip";
import { BsPlus } from "react-icons/bs";

export default function ProductListView(props) {
  const { handlescrollpackage, StepCheck } = props;
  const user = useSelector((state) => state.user);
  const [productData, setproductData] = useState([]);

  const columns = [
    // {
    //   id: 0,
    //   label: "#",
    //   render: (row) => <>{row?.id ?? "-"}</>,
    //   width: "200px",
    // },
    {
      id: 1,
      label: " ",
      render: (row) => (
        <img
          src={ImageUrl(row)}
          onError={(e) => {
            e.target.src = noImage;
          }}
          loading="lazy"
          style={{ maxWidth: "100%" }}
        />
      ),
      fixed: "left",
      width: "2vw",
    },
    {
      id: 2,
      label: "Name",
      render: (row) => (
        <>
          <div className="ship__page_myproduct_listing_bolt_table_name">
            {row.name ?? "-"}
          </div>
        </>
      ),
      width: "15vw",
    },
    {
      id: 3,
      label: "Sku",
      render: (row) => <>{row.code ?? "-"}</>,
      width: "15vw",
    },
    {
      id: 4,
      label: "Dimensions (L*W*H)",
      render: (row) => (
        <>
          <div className="product_dimension_parent_wrapper">
            <div>
              {row?.length ?? "0"}*{row?.width ?? "0"}*{row?.height ?? "0"} (
              {row?.dimension_unit?.toUpperCase() ?? "N/A"})
            </div>
            <div className="product__notification_circle_wrapper">
              <PiPackageLight size={10} />
              {row?.package_details &&
                Object?.values(row?.package_details)?.length > 0 && (
                  <div className="product__notification_circle">
                    {Object.values(row?.package_details)?.length}
                  </div>
                )}
            </div>
          </div>
        </>
      ),
      width: "5vw",
    },
    {
      id: 5,
      label: "Weight",
      render: (row) => (
        <>
          {row?.weight ?? "0"} {row?.weight_unit}
        </>
      ),
      width: "5vw",
    },
    {
      id: 6,
      label: "Quantity",
      render: (row) => (
        <>
          {row?.product_warehouse?.reduce(
            (total, warehouse) => total + parseInt(warehouse.quantity, 10) || 0,
            0
          )}
        </>
      ),
      width: "3vw",
    },
    // {
    //   id: 8,
    //   label: "Actions",
    //   render: (row) => (
    //     <>
    //       {" "}
    //       <div className="table_action__wrap">
    //         <Tooltip
    //           title={
    //             StepCheck["Warehouses"] ? "Click To Add" : "Select Warehouse"
    //           }
    //         >
    //           <div
    //             className="table_action__btn"
    //             onClick={() => handlescrollpackage(row)}
    //           >
    //             <BsPlus size={10} />
    //             Add
    //           </div>
    //         </Tooltip>
    //       </div>
    //     </>
    //   ),
    //   width: "200px",
    //   fixed: "right",
    // },
  ];

  const warehouses = user?.user_approved_warehouses;
  const insertIndex = columns.length;
  warehouses?.forEach((warehouse, index) => {
    columns.splice(insertIndex, 0, {
      id: 1000 + index,
      label: warehouse?.warehouse?.name,
      render: (row) => {
        const matchedWarehouse = row?.product_warehouse?.find(
          (item) => item?.warehouse_id == warehouse?.warehouse_id
        );

        return (
          <>
            {matchedWarehouse && (
              <>
                {
                  <div
                    className="product__textalign_center"
                    style={{
                      fontWeight:
                        matchedWarehouse?.warehouse_id ==
                        StepCheck["Warehouses"]
                          ? "bold"
                          : "",
                    }}
                  >
                    {matchedWarehouse?.quantity ?? "-"}
                  </div>
                }
              </>
            )}
          </>
        );
      },
      width: "5vw",
    });
    // columns.splice(insertIndex + 1, 0, {
    //   id: 2000 + index,
    //   label: warehouse?.warehouse?.name + " Lot", // Add label for product lots
    //   render: (row) => {
    //     const matchedWarehouse = row?.product_warehouse?.find(
    //       (item) => item?.warehouse_id == warehouse?.warehouse_id
    //     );

    //     return (
    //       <>
    //         {matchedWarehouse && matchedWarehouse?.product_lots?.length > 0 && (
    //           <>
    //             {matchedWarehouse?.product_lots?.map((lot, index) => (
    //               <div key={index}>
    //                 {lot?.name} ({lot?.product_pallets_sum_quantity})
    //               </div>
    //             ))}
    //           </>
    //         )}
    //       </>
    //     );
    //   },
    //   width: "5vw",
    // });
  });

  const ImageUrl = (row) => {
    const url =
      row?.media.find((media) => media.is_primary === "true")?.file ||
      row?.media[0]?.file;
    return url || noImage;
  };

  const getAllProducts = async (page, perPage, filter) => {
    const response = await fetchOnlyProducts(page, perPage, filter);
    if (!response.error) {
      setproductData(response?.data?.result);
    } else {
    }
  };

  return (
    <>
      <div className="custom__table_size1 actionbtn__needtobe_fixwidth">
        <ProductTable
          message={"No Products Available To Display!"}
          searchPlaceHolder={"Search Products"}
          getAllData={getAllProducts}
          data={productData}
          columns={columns}
          handleRowClick={handlescrollpackage}
          disableAutoResizing={true}
          isRowClick={
            StepCheck["Warehouses"] ? "Click To Add" : "Select Warehouse"
          }
        />
      </div>
    </>
  );
}
