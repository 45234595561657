import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Autocomplete from "@mui/material/Autocomplete";
import "./ChangePassword.css";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import Upload from "@mui/icons-material/CloudUpload";
import { editDriver } from "../../../../../../../../services/drivers.services";
import {
  updatePasswordByAdmin,
  updateShippingCompany,
  updateUser,
} from "../../../../../../../../services/admin.services";
import Countries from "../../../../../../../../data/countries";
import { TextField } from "@mui/material";

function Loader(props) {
  return (
    <Box
      sx={{
        position: "relative",
        top: "2px",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={20}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={20}
        thickness={4}
        {...props}
      />
    </Box>
  );
}
const initialData = {
  user_id: "",
  password: "",
};

export default function ChangePassword({
  open,
  setOpen,
  setSnack,
  setUsers,
  previousData,
}) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setData(initialData);
    setOpen(false);
  };
  const user = useSelector((state) => state.user);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleFieldChange = (field, value) => {
    setData({ ...data, [field]: value });
  };
  const handleFileChange = (e) => {
    setData({ ...data, logo_url: e.target.files[0] });
  };
  const handleUpdate = async () => {
    // setLoading(true);
    // const response = await updatePasswordByAdmin(data);
    // setLoading(false);
    // console.log(response);
    // if (!response?.data?.status || response.error) {
    //   setSnack((snack) => {
    //     return {
    //       ...snack,
    //       open: true,
    //       text: "Something went wrong!",
    //       severity: "error",
    //     };
    //   });
    // } else {
    //   handleClose();
    //   setSnack((snack) => {
    //     return {
    //       ...snack,
    //       open: true,
    //       text: "Password Updated Successfully!",
    //       severity: "success",
    //     };
    //   });
    // }
  };

  useEffect(() => {
    if (previousData) {
      setData({ ...data, user_id: previousData });
    }
  }, [previousData]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="shipper__users__pass_modal">
          <div className="shipper__users__pass_modalheading">
            Change Password
          </div>
          <div className="shipper__users__pass_modalcontent">
            <div className="shipper__users__pass_modalrow">
              <div className="shipper__users__pass_modalinputwrap">
                <label>New Password</label>
                <input
                  className="shipper__users__pass_modalinput"
                  placeholder="New Password"
                  name="password"
                  value={data.password}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="shipper__users__pass_modalfooter">
            <div
              className="shipper__users__pass_modalcncl"
              onClick={handleClose}
            >
              Cancel
            </div>
            <div
              className="shipper__users__pass_modalsave"
              onClick={handleUpdate}
            >
              {loading ? <Loader /> : "Update"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
