import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import "./AddBundle.css";
import Upload from "@mui/icons-material/CloudUpload";
import { addNewBundle } from "../../../../../../../services/ShipperServices/Packages/packages.services";

import { showSnackBar } from "../../../../../../../store/common/snackBarSlice";
import { useDispatch } from "react-redux";
import CircularLoader from "../../../../../../../Components/CircularLoader/CircularLoader";

const initialData = {
  name: "",
  description: "",
  file: "",
  packages: [
    {
      id: 1,
      name: "",
      description: "",
      save_name: null,
      length: "",
      weight: "",
      width: "",
      height: "",
      unit: "Imperial",
    },
  ],
};

export default function AddBundle({ open, setOpen, Plist, setbundleRefresh }) {
  const dispatch = useDispatch();

  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [unit, setUnit] = useState("Imperial");
  const handleClose = () => {
    setData(initialData);
    setOpen(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleFileChange = (e) => {
    setData({ ...data, file: e.target.files[0] });
  };

  const handleSave = async () => {
    setLoading(true);
    // const isDataValid = Object.entries(data).every(([key, value]) => {
    //   if (key === "packages") {
    //     return value.every((pkg) => {
    //       return Object.values(pkg).every((pkgValue) => pkgValue !== "");
    //     });
    //   }
    //   return key === "file" || value !== "";
    // });

    // if (!isDataValid) {
    //   setLoading(false);
    // dispatch(
    //   showSnackBar({
    //       text: "All fields are required",
    //       severity: "error",
    //   })
    // );
    //   return;
    // }
    const newData = {
      ...data,
      packages: [],
    };

    data.packages.forEach((pkg) => {
      const { id, ...rest } = pkg;
      newData.packages.push({
        ...rest,
        unit: unit,
      });
    });
    const formData = new FormData();
    formData.append("name", newData.name);
    formData.append("description", newData.description);
    formData.append("type", "bundle");

    if (newData.file) {
      formData.append("file", newData.file);
    }

    newData.packages.forEach((pkg, index) => {
      const pkgData = JSON.stringify(pkg);
      formData.append(`packages[${index}]`, pkgData);
    });
    const saved = await addNewBundle(formData);

    if (!saved.error) {
      setbundleRefresh(Math.floor(Math.random() * 100) + 1);
      dispatch(
        showSnackBar({
          text: "Bundle Added successfully",
          severity: "success",
        })
      );
      setLoading(false);
      handleClose();
    } else {
      setLoading(false);
      dispatch(
        showSnackBar({
          text: "Something went wrong",
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    setData({ ...data, packages: Plist });
  }, [open]);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="add__bundle_modal add__bundle_modal_wrap">
          <div className="add__bundle_modalheading">Add Bundle</div>
          <div className="add__bundle_modalcontent">
            <div className="add__bundle_modalrow">
              <div className="add__bundle_modalinputwrap">
                <input
                  className="add__bundle_modalinput"
                  placeholder="Bundle Name"
                  name="name"
                  value={data.name}
                  onChange={handleChange}
                />
              </div>
              <div className="add__bundle_modalinputwrap">
                <label className="add__bundle_profimagelbl">
                  <Upload /> {data.file?.name ?? "UPLOAD BUNDLE IMAGE"}
                  <input
                    type="file"
                    size="60"
                    className="add__bundle_profimageinput"
                    onChange={handleFileChange}
                    accept=".jpg,.png"
                  />
                </label>
              </div>
            </div>

            <div className="add__bundle_modalrow">
              <div
                className="add__bundle_modalinputwrap"
                style={{ minWidth: "65%" }}
              >
                <input
                  className="add__bundle_modalinput"
                  placeholder="Bundle Description"
                  name="description"
                  value={data.description}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="add__bundle_modalrowspacebetween">
              <h6 className="add__bundle_mrpkgsheading">Packages</h6>
              <div className="add_bundle__unitbtnswrap">
                <div
                  className={`add_bundle__unitbtn ${
                    unit === "Imperial" && "add_bundle__unitbtnactive"
                  }`}
                  onClick={() => setUnit("Imperial")}
                >
                  Imperial
                </div>
                <div
                  className={`add_bundle__unitbtn ${
                    unit === "Metric" && "add_bundle__unitbtnactive"
                  }`}
                  onClick={() => setUnit("Metric")}
                >
                  Metric
                </div>
              </div>
            </div>
            <div className="pkgbundle__details_modalcontent">
              <div className="pkgbundle__details_modalcontentrow">
                <div className="pkgbundle__details_modalcontentlft">
                  <div className="pkgbundle__details_pkgslist_wrap">
                    <div className="pkgbundle__details_pkgslist">
                      <div className="pkgbundle__details_pkgslistheading">
                        Name
                      </div>
                      <div className="pkgbundle__details_pkgslistheading">
                        Description
                      </div>
                      <div className="pkgbundle__details_pkgslistheading">
                        Length
                      </div>
                      <div className="pkgbundle__details_pkgslistheading">
                        Width
                      </div>
                      <div className="pkgbundle__details_pkgslistheading">
                        Height
                      </div>
                      <div className="pkgbundle__details_pkgslistheading">
                        Weight
                      </div>
                      {data?.packages?.map((pkg, index) => (
                        <React.Fragment key={index}>
                          <div className="pkgbundle__details_pkgslistdata ">
                            {pkg.name}
                          </div>
                          <div className="pkgbundle__details_pkgslistdata">
                            {pkg.description}
                          </div>
                          <div className="pkgbundle__details_pkgslistdata">
                            {pkg.length}
                          </div>
                          <div className="pkgbundle__details_pkgslistdata">
                            {pkg.width}
                          </div>
                          <div className="pkgbundle__details_pkgslistdata">
                            {pkg.height}
                          </div>
                          <div className="pkgbundle__details_pkgslistdata">
                            {pkg.weight}
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>

                  {!data?.packages?.length && (
                    <div className="pkgbundle__details_pkgslistdataempt">
                      No Packages To Display!
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="add__bundle_modalfooter">
            <div className="add__bundle_modalcncl" onClick={handleClose}>
              Cancel
            </div>
            <div className="add__bundle_modalsave" onClick={handleSave}>
              {loading ? <CircularLoader /> : "Save"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
