import React, { useEffect, useState } from "react";

// components imports
import SkeletonLoading from "../../../../../Components/SkeletonLoading/SkeletonLoading";
import CircularLoader from "../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import { checkFileType } from "../../../../../helpers/helpers";
import {
  fetchStockRecNotesHistory,
  createStockRecNote,
} from "../../../../../services/warehouse.services";

// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import { MdOutlineCancel, MdArrowBack } from "react-icons/md";
import ImageListItem from "@mui/material/ImageListItem";
import noImage from "../../../../../assets/images/noImage.png";
import excelIcon from "../../../../../assets/icons/excelIcon.png";
import pdfIcon from "../../../../../assets/icons/pdfIcon.png";
import Badge from "@mui/material/Badge";
import { HiMiniMinusSmall } from "react-icons/hi2";
import AddImage from "../../../../../assets/images/add-image.png";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import docsIcon from "../../../../../assets/icons/docsIcon.png";

export default function StockRecAttachmentsHistoryModal({
  open,
  setOpen,
  setSnack,
}) {
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [data, setData] = useState([]);
  // const [deleted, setDeleted] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [fullView, setFullView] = useState();

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setData([]);
    setFullView();
    setSelectedImages([]);
  };

  useEffect(() => {
    const getStockRecNotesHistory = async () => {
      setSkeletonLoading(true);
      const response = await fetchStockRecNotesHistory(open);
      if (!response.error) {
        setSkeletonLoading(false);
        setData(
          response?.data?.result?.flatMap(
            (attachments) => attachments?.media
          ) ?? []
        );
      } else {
        setSkeletonLoading(false);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Something went wrong",
            severity: "error",
          };
        });
      }
    };
    if (open) {
      getStockRecNotesHistory();
    }
  }, [open]);

  const handleImageSelect = (event) => {
    const files = event.target.files;
    setSelectedImages([...selectedImages, ...files]);
  };

  const handleRemoveSeleceted = (index) => {
    const updatedFiles = [...selectedImages];
    updatedFiles.splice(index, 1);
    setSelectedImages(updatedFiles);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const formData = new FormData();

      formData.append(`notes`, "-");
      if (selectedImages.length > 0) {
        selectedImages?.map((file, index) => {
          formData.append(`grnNotesMedia[${index}]`, file);
        });
      } else {
        formData.append(`grnNotesMedia`, []);
      }
      let response = await createStockRecNote(open, formData);
      if (!response.error) {
        setData((prevAttachments) => {
          return [...response?.data?.result?.media, ...prevAttachments];
        });
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: response?.data?.message,
            severity: "success",
          };
        });
        setSelectedImages([]);
        setLoading(false);
      } else {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text:
              response?.error?.response?.data?.message ||
              "Something went wrong",
            severity: "error",
          };
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: error.response.data.message || "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="prdtable__0rder_attachments_modal edit_table_attach_img_thumb_modal_wrap">
          <div className="prdtable__0rder_attachments_modaltopbar">
            <div className="imageSelection_orderAttachments_title edit_table_attach_img_thumb_modal">
              Attachments
            </div>
            <MdOutlineCancel
              size={25}
              className="prdtable__0rder_attachments_modalclose"
              onClick={handleClose}
            />
            {fullView && (
              <MdArrowBack
                size={25}
                className="prdtable__0rder_attachments_modalback"
                onClick={() => setFullView(false)}
              />
            )}
          </div>
          {!fullView ? (
            <div className="prdtable__0rder_attachments_modalcontent">
              <div className="prdtable__0rder_attachments_modalimgs">
                {skeletonLoading && (
                  <div className="prdtable__0rder_attachments_skeletonwrapper">
                    {["", ""].map(() => {
                      return (
                        <SkeletonLoading
                          className={
                            "prdtable__0rder_attachments_skeletonloader"
                          }
                        />
                      );
                    })}
                  </div>
                )}
                {data &&
                  data?.map((image, index) => (
                    <div className={"ordinary__image_wrap"}>
                      <div key={index}>
                        <ImageListItem
                          key={index}
                          className="prdtable__0rder_attachments_modalimg"
                        >
                          <img
                            src={
                              image?.file &&
                              checkFileType(image.file) === "Image"
                                ? image.file
                                : checkFileType(image.file) === "PDF"
                                ? pdfIcon
                                : checkFileType(image.file) === "Excel"
                                ? excelIcon
                                : checkFileType(image.file) === "Document"
                                ? docsIcon
                                : noImage
                            }
                            onClick={() => {
                              if (checkFileType(image.file) === "Image") {
                                setFullView(image?.file || "");
                              } else {
                                window.open(image?.file, "_blank");
                              }
                            }}
                            className="uploaded_order__attachment_img"
                            onError={(e) => {
                              e.target.src = noImage;
                            }}
                            style={{ cursor: "pointer", objectFit: "contain" }}
                            loading="lazy"
                          />
                        </ImageListItem>
                      </div>
                    </div>
                  ))}
                {selectedImages?.map((image, index) => (
                  <>
                    <Badge
                      badgeContent={
                        <HiMiniMinusSmall
                          size={20}
                          onClick={() => handleRemoveSeleceted(index)}
                          style={{ cursor: "pointer" }}
                        />
                      }
                      color="error"
                      sx={{
                        ".MuiBadge-badge": {
                          right: "12px",
                          top: "12px",
                          padding: "0px",
                        },
                      }}
                    >
                      <div key={index}>
                        <ImageListItem
                          key={index}
                          className="prdtable__0rder_attachments_modalimg"
                        >
                          <img
                            src={URL.createObjectURL(image) || noImage}
                            onClick={() =>
                              setFullView(URL.createObjectURL(image) || noImage)
                            }
                            onError={(e) => {
                              e.target.src = noImage;
                            }}
                            style={{ cursor: "pointer", objectFit: "contain" }}
                          />
                          <ImageListItemBar
                            sx={{
                              background:
                                "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                                "rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%)",
                            }}
                            title={"New " + (index + 1)}
                            position="top"
                          />
                        </ImageListItem>
                      </div>
                    </Badge>
                  </>
                ))}
                <>
                  <label
                    className="prdtable__0rder_attachments_addnewbtn"
                    for="upload_new_img"
                  >
                    <img src={AddImage} />
                    <div>
                      {selectedImages.length > 0
                        ? selectedImages.length + " Images Selected"
                        : "Add New Image"}
                    </div>
                  </label>
                  <input
                    type="file"
                    hidden
                    id="upload_new_img"
                    multiple
                    onChange={handleImageSelect}
                  />
                </>
              </div>
            </div>
          ) : (
            <div className="prdtable__0rder_attachments_fullimgview">
              <img
                src={fullView || noImage}
                onError={(e) => {
                  e.target.src = noImage;
                }}
                loading="lazy"
              />
            </div>
          )}

          <div className="prdtable__0rder_attachments_modalfooter">
            <div
              className="prdtable__0rder_attachments_modalfootercncl"
              onClick={handleClose}
            >
              Close
            </div>
            {selectedImages.length > 0 && (
              <div
                className="prdtable__0rder_attachments_modalfootersave"
                onClick={handleSave}
              >
                {loading ? <CircularLoader /> : "Save"}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
