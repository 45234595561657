import React, { useState } from "react";
import { useEffect } from "react";
import "../InvoiceModal.css";
import StatusIcons from "../../../../../../../../../Components/StatusIcons/StatusIcons";

const ShipmentDetails = ({ data, Role, type = "not Print" }) => {
  const [totalFulfilment, settotalFulfilment] = useState(0);
  const [totalWeight, settotalWeight] = useState(0);
  const [totalItems, settotalItems] = useState(0);

  const formatValue = (value) => {
    return typeof value === "number" ? value.toFixed(2) : "N/A";
  };
  function sumItemQuantities(orderDetails) {
    return orderDetails.reduce(
      (total, current) => total + Number(current.item_quantity),
      0
    );
  }
  function sumTotalWeight(orderDetails) {
    return orderDetails.reduce((total, current) => {
      const weight = Number(current.weight);
      const quantity = Number(current.item_quantity);
      return total + weight * quantity;
    }, 0);
  }
  const calculateTotalCost = (data) => {
    let totalCost = 0;
    let total = 0;
    if (data?.additional_costs?.length > 0) {
      data.additional_costs.forEach((currentItem) => {
        const cost = parseFloat(currentItem.cost ?? 0);
        totalCost += cost;
      });
    }
    total = totalCost + parseFloat(data.fulfillment_cost ?? 0);
    return parseFloat(total).toFixed(2);
  };

  useEffect(() => {
    if (data) {
      let items = 0;
      let weight = 0;
      let cost = 0;
      data.forEach((element) => {
        const singleCost = parseFloat(calculateTotalCost(element) ?? 0);
        items = sumItemQuantities(element?.order_details) + items;
        weight = sumTotalWeight(element?.order_details) + weight;
        cost += singleCost;
      });
      settotalFulfilment(cost);
      settotalWeight(weight);
      settotalItems(items);
    }
  }, [data]);

  let th_style =
    type === "Print"
      ? "invmodal__shdetails_tablehd_print"
      : "invmodal__shdetails_tablehd";
  let td_style =
    type === "Print"
      ? "ltlinvmodal__shdetails_tableitem_print"
      : "ltlinvmodal__shdetails_tableitem";

  return (
    <>
      <div className="invmodal__shipment_details">
        <div className="invmodal__shipment_detailshding">Order Details</div>
        <table className="invmodal__shdetails_table">
          <thead>
            <th className={th_style}>#</th>
            <th className={th_style}>Order Id</th>
            <th className={th_style}>Order Type</th>
            <th className={th_style}>Order Status</th>
            <th className={th_style}>Customer Name</th>
            <th className={th_style}>Fullfilment Status</th>
            <th className={th_style}>Fullfilment Cost</th>
            <th className={th_style}>{Role}</th>
            <th className={th_style}>Order Items</th>
            <th className={th_style}>Order Weight</th>
            <th className={th_style}>Fullfilment Billing Status</th>
            <th className={th_style}>Created At</th>
          </thead>
          <tbody>
            {" "}
            {data?.map((item, index) => (
              <tr key={index}>
                {" "}
                <td className={td_style}>{index + 1}</td>
                <td className={td_style}>{item?.order_id ?? "-"}</td>
                <td className={td_style}>{item?.type ?? "-"}</td>
                <td className={td_style}>
                  <StatusIcons status={item?.status} />
                </td>
                <td className={td_style}>
                  {item?.order_shipping_address_details?.first_name ?? "-"}
                </td>
                <td className={td_style}>
                  <StatusIcons status={item?.fullfilment_status?.name} />
                </td>
                <td className={td_style}>{calculateTotalCost(item) ?? "0"}$</td>
                <td className={td_style}>
                  {Role === "Warehouse"
                    ? item?.warehouse?.name ?? "-"
                    : item?.user?.username ?? "-"}
                </td>
                <td className={td_style}>
                  {item?.order_details
                    ? sumItemQuantities(item.order_details)
                    : 0}
                </td>
                <td className={td_style}>
                  {item?.order_details ? sumTotalWeight(item.order_details) : 0}
                </td>
                <td className={td_style}>
                  <StatusIcons status={item?.fulfillment_billing_status} />
                </td>
                <td className={td_style}>
                  {item?.created_at?.slice(0, 10) ?? "-"} (
                  {item?.created_at?.slice(11, 16) ?? "-"})
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td className={td_style}>
                <b>{data?.length ?? 0}</b>
              </td>
              <td className={td_style}></td>
              <td className={td_style}></td>
              <td className={td_style}></td>
              <td className={td_style}></td>
              <td className={td_style}></td>
              <td className={td_style}>
                <b>{totalFulfilment}$</b>
              </td>
              <td className={td_style} colSpan="1"></td>
              <td className={td_style}>
                <b>{totalItems}</b>
              </td>
              <td className={td_style}>
                <b>{totalWeight}</b>
              </td>
              <td className={td_style}></td>
              <td className={td_style}></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
};

export default ShipmentDetails;
