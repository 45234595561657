import React, { useState, useEffect } from "react";
// import "./AddPackage.css";

// components imports
import Loader from "../../../Components/CircularLoader/CircularLoader";
// apis imports
import { createWarehousePallet } from "../../../services/product.service";
// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import LocationDropdown from "../../Warehouse/Products/Pallets/Components/LocationDropdown";

const initialData = {
  name: "",
  length: "",
  width: "",
  height: "",
  weight: "",
  unit: "imperial",
};

export default function AddPallet({ open, setOpen, setSnack, setPallets }) {
  const [data, setData] = useState(initialData);
  const [location, setLocation] = useState();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setData(initialData);
    setLocation();
    setLoading(false);
    setOpen(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleUnitChange = (e) => {
    setData({ ...data, unit: e.target.value });
  };

  const handleSave = async () => {
    setLoading(true);
    for (const key in data) {
      if (data[key] === "") {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: `${key.toUpperCase()} is required!`,
            severity: "error",
          };
        });
        setLoading(false);
        return;
      }
    }

    const payload = {
      name: data.name,
      length: data.length,
      width: data.width,
      height: data.height,
      weight: data.weight,
      unit: data.unit,
      location_id: location?.id ?? "",
      shipper_id: open,
    };
    const saved = await createWarehousePallet(payload);
    setLoading(false);
    if (!saved.error) {
      const newPallet = {
        lot_number: "",
        quantity: 0,
        pallet_id: saved?.data?.result?.id,
        pallet: {
          ...saved?.data?.result,
          location: location,
        },
      };
      setPallets((prevPallets) => [...prevPallets, newPallet]);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Pallet Added successfully",
          severity: "success",
        };
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong!",
          severity: "error",
        };
      });
    }

    handleClose();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="add__package_modal add__pallet_modal_wrapper">
          <div className="add__package_modalheading">Add Pallet</div>
          <div className="add__package_modalcontent">
            <div className="add__package_modalrow">
              <div
                className="add__package_modalinputwrap"
                style={{ minWidth: "65%" }}
              >
                <input
                  className="add__package_modalinput"
                  placeholder="Pallet Name"
                  name="name"
                  value={data.name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="add__package_modalrow">
              <div className="add__package_modalinputwrap">
                <input
                  className="add__package_modalinput"
                  placeholder="Length"
                  name="length"
                  value={data.length}
                  onChange={handleChange}
                  type="number"
                />
              </div>
              <div className="add__package_modalinputwrap">
                <input
                  className="add__package_modalinput"
                  placeholder="Width"
                  name="width"
                  value={data.width}
                  onChange={handleChange}
                  type="number"
                />
              </div>
              <div className="add__package_modalinputwrap">
                <input
                  className="add__package_modalinput"
                  placeholder="Height"
                  name="height"
                  value={data.height}
                  onChange={handleChange}
                  type="number"
                />
              </div>
              <div className="add__package_modalinputwrap">
                <input
                  className="add__package_modalinput"
                  type="number"
                  value={data.weight}
                  name="weight"
                  placeholder="Weight"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="add__package_modalrow">
              <div className="add__package_modalinputwrap">
                <FormControl
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingLeft: "10px",
                  }}
                >
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Unit:
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={data.unit}
                    onChange={handleUnitChange}
                    sx={{ flexDirection: "row", alignItems: "center" }}
                  >
                    <FormControlLabel
                      value="imperial"
                      control={<Radio />}
                      label="Imperial"
                      sx={{ marginLeft: "20px" }}
                    />
                    <FormControlLabel
                      value="metric"
                      control={<Radio />}
                      label="Metric"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="add__package_modalrow">
              <div className="add__package_modalinputwrap add__package_modalinputwrap_location">
                <LocationDropdown
                  setValue={setLocation}
                  value={location}
                  setSnack={setSnack}
                />
              </div>
            </div>
          </div>
          <div className="add__package_modalfooter add__package_modalinputwrap_modalfooter">
            <div className="add__package_modalcncl" onClick={handleClose}>
              Cancel
            </div>
            <div className="add__package_modalsave" onClick={handleSave}>
              {loading ? <Loader /> : "Save"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
