import Tooltip from "../../../../../../../Components/Tooltip/Tooltip";
import React, { useState, useEffect } from "react";
import { setdata } from "../../../../../../../helpers/helpers";
import IconButton from "@mui/material/IconButton";
import ProductGridView from "./ProductGridView";
import ProductListView from "./ProductListView";
import { useSelector } from "react-redux";
import { showSnackBar } from "../../../../../../../store/common/snackBarSlice";
import { useDispatch } from "react-redux";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { IoInformationCircleOutline } from "react-icons/io5";
import ProductTypeSelector from "../ProductTypeSelector";

export default function ProductScroller(props) {
  const {
    Plist,
    SetPlist,
    StepCheck,
    SetStepCheck,
    SelectedProducts,
    setSelectedProducts,
    view,
    setView,
  } = props;
  const dispatch = useDispatch();
  const [filterComponent, setFilterComponent] = useState(null);

  const setFilter = (filterElement) => {
    setFilterComponent(filterElement);
  };

  const user = useSelector((state) => state.user);

  const handleStepper = () => {
    SetStepCheck((prevStepCheck) => ({
      ...prevStepCheck,
      Packages_bit: 0,
      QuoteCheck: false,
    }));
  };

  const handlescrollpackage = (data) => {
    if (!StepCheck["Warehouses"]) {
      dispatch(
        showSnackBar({
          text: "Select A Warehouse",
          severity: "info",
        })
      );
      return;
    }
    dispatch(
      showSnackBar({
        text: `${data?.name ?? "Product"} Added Successfully`,
        severity: "success",
      })
    );
    const productId = data.id;

    const existingProductIndex = SelectedProducts.findIndex(
      (product) => product.id === productId
    );

    if (existingProductIndex !== -1) {
      const updatedProducts = [...SelectedProducts];
      updatedProducts[existingProductIndex].quantity += 1;
      setSelectedProducts(updatedProducts);
    } else {
      const newData = { ...data, quantity: 1 };
      setSelectedProducts([...SelectedProducts, newData]);
    }

    if (data?.package_details?.length > 0) {
      const x = Plist.length - 1;
      if (
        Plist[x]["name"] === "" &&
        Plist[x]["length"] === "" &&
        Plist[x]["width"] === "" &&
        Plist[x]["height"] === "" &&
        Plist[x]["weight"] === "" &&
        Plist[x]["description"] === "" &&
        Plist[x]["insurance"] === ""
      ) {
        SetPlist((prevPlist) => {
          const newPackages = Object.entries(data.package_details).map(
            ([packageId, packageItem]) => ({
              name: data.name,
              length: setdata(() => packageItem.length, false, ""),
              width: setdata(() => packageItem.width, false, ""),
              height: setdata(() => packageItem.height, false, ""),
              weight: setdata(() => packageItem.weight, false, ""),
              description: packageItem.description,
              insurance: "",
              volume: (
                (setdata(() => packageItem.length, false, 0) *
                  setdata(() => packageItem.width, false, 0) *
                  setdata(() => packageItem.height, false, 0)) /
                1000000
              ).toFixed(2),
              warehouse_id: StepCheck["Warehouses"],
              product_id: data.id,
            })
          );
          return [...newPackages];
        });
      } else {
        SetPlist((prevPlist) => [
          ...prevPlist,
          ...Object.entries(data.package_details).map(
            ([packageId, packageItem]) => ({
              name: data.name,
              length: setdata(() => packageItem.length, false, ""),
              width: setdata(() => packageItem.width, false, ""),
              height: setdata(() => packageItem.height, false, ""),
              weight: setdata(() => packageItem.weight, false, ""),
              description: packageItem.description,
              insurance: "",
              volume: (
                (setdata(() => packageItem.length, false, 0) *
                  setdata(() => packageItem.width, false, 0) *
                  setdata(() => packageItem.height, false, 0)) /
                1000000
              ).toFixed(2),
              warehouse_id: StepCheck["Warehouses"],
              product_id: data.id,
            })
          ),
        ]);
      }
    } else {
      const x = Plist.length - 1;
      if (
        Plist[x]["name"] === "" &&
        Plist[x]["length"] === "" &&
        Plist[x]["width"] === "" &&
        Plist[x]["height"] === "" &&
        Plist[x]["weight"] === "" &&
        Plist[x]["description"] === "" &&
        Plist[x]["insurance"] === ""
      ) {
        const plist = [...Plist];
        plist[x]["name"] = data.name;
        plist[x]["length"] = setdata(() => data.length, false, "");
        plist[x]["width"] = setdata(() => data.width, false, "");
        plist[x]["height"] = setdata(() => data.height, false, "");
        plist[x]["weight"] = setdata(() => data.weight, false, "");
        plist[x]["volume"] = (
          (setdata(() => data.length, false, 0) *
            setdata(() => data.width, false, 0) *
            setdata(() => data.height, false, 0)) /
          1000000
        ).toFixed(2);
        plist[x]["description"] = data.full_name;
        plist[x]["insurance"] = "";
        plist[x]["warehouse_id"] = StepCheck["Warehouses"];
        plist[x]["product_id"] = data.id;
        SetPlist(plist);
      } else {
        SetPlist([
          ...Plist,
          {
            name: data.name,
            length: setdata(() => data.length, false, ""),
            width: setdata(() => data.width, false, ""),
            height: setdata(() => data.height, false, ""),
            weight: setdata(() => data.weight, false, ""),
            description: data.full_name,
            insurance: "",
            warehouse_id: StepCheck["Warehouses"],
            product_id: data.id,
            volume: (
              (setdata(() => data.length, false, 0) *
                setdata(() => data.width, false, 0) *
                setdata(() => data.height, false, 0)) /
              1000000
            ).toFixed(2),
          },
        ]);
      }
    }
    handleStepper();
    // handleClose();
  };

  const handleView = () => {
    if (view == "Grid") {
      setFilterComponent(null);
      setView("List");
    } else if (view == "List") {
      setView("Grid");
    }
  };

  const handleChange = (event) => {
    setSelectedProducts([]);
    SetPlist([
      {
        name: "",
        length: "",
        width: "",
        height: "",
        weight: "",
        volume: "",
        insurance: "",
        description: "",
        feright: "",
        nmfc: "",
        type: "",
        pieces: "",
      },
    ]);
    SetStepCheck((prevStepCheck) => ({
      ...prevStepCheck,
      Warehouses: event.target.value,
    }));
  };

  return (
    <>
      <div className="product__scroller_wrap_main_container">
        <div className="warehouse__selection_drop_main_wrap">
          <FormControl
            className="warehouse__selection_drop_sec"
            sx={{ minWidth: 250 }}
            size="small"
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                fontSize: "12px",
              }}
            >
              Warehouse
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={StepCheck["Warehouses"]}
              label="Warehouse"
              onChange={handleChange}
              size="small"
              sx={{
                fontSize: "11px",
              }}
            >
              {user?.user_approved_warehouses?.map((warehouse, index) => (
                <MenuItem value={warehouse?.warehouse_id} key={index}>
                  #{warehouse?.warehouse_id} : {warehouse?.warehouse?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Tooltip
            title={
              !StepCheck["Warehouses"]
                ? "Select A Warehouse"
                : "If you select different warehouse already selected Products and Packages will be removed."
            }
          >
            <IconButton>
              <IoInformationCircleOutline size={12} />
            </IconButton>
          </Tooltip>
        </div>
        {filterComponent}
        <ProductTypeSelector
          StepCheck={StepCheck}
          SetStepCheck={SetStepCheck}
          view={view}
          handleView={handleView}
        />
      </div>
      {view == "Grid" && (
        <>
          <ProductGridView
            StepCheck={StepCheck}
            handlescrollpackage={handlescrollpackage}
            setFilter={setFilter}
          />
        </>
      )}
      {view == "List" && (
        <>
          <div className="ship__page_myproduct_listing_bolt_table">
            <ProductListView
              StepCheck={StepCheck}
              handlescrollpackage={handlescrollpackage}
            />
          </div>
        </>
      )}
    </>
  );
}
