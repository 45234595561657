import axios from "../../axiosInstance";

export const fetchShipments = async (
  type,
  page,
  perPage,
  filter,
  record = ""
) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `get-all-shipments/${type}?page=${page}&record_type=${record}&perPage=${perPage}&name=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchShipmentDetailsHistoryById = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`parcels/tracking-history/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const cancelShipment = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`cancel-shipment/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchShipmentDataById = async (orderId) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`/shipment/get-by-id/${orderId}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchAdminShipmentDataById = async (orderId) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`/admin/shipment/get-by-id/${orderId}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
