import React, { useState, useEffect } from "react";
import "./editsupplier.css";
// components imports
import Loader from "../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import { updateSupplier } from "../../../../../services/warehouse.services";
// library imports
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";

const EditSupplier = ({ open, setOpen, setSnack, setSuppliers }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    website: "",
    email: "",
    phone: "",
  });

  const handelChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (formData.name === "") {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please enter supplier name",
          severity: "error",
        };
      });
      setLoading(false);
      return;
    }
    if (formData.address === "") {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please enter supplier address",
          severity: "error",
        };
      });
      setLoading(false);
      return;
    }
    if (formData.email === "") {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please enter supplier email",
          severity: "error",
        };
      });
      setLoading(false);
      return;
    }
    if (formData.website === "") {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please enter supplier website",
          severity: "error",
        };
      });
      setLoading(false);
      return;
    }
    if (formData.phone === "") {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please enter supplier phone",
          severity: "error",
        };
      });
      setLoading(false);
      return;
    }

    const response = await updateSupplier(formData.id, {
      name: formData?.name,
      address: formData?.address,
      website: formData?.website,
      email: formData?.email,
      phone: formData?.phone,
    });
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text:
            response?.error?.response?.data?.message || "Something went wrong",
          severity: "error",
        };
      });
      setLoading(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Supplier Updated Successfully",
          severity: "success",
        };
      });
      setSuppliers((prevSuppliers) => {
        return {
          ...prevSuppliers,
          data: prevSuppliers?.data?.map((p) => {
            return p.id === open?.id ? response?.data?.result : p;
          }),
        };
      });
      setLoading(false);
      setFormData({ name: "" });
      setOpen(false);
    }
  };

  useEffect(() => {
    setFormData({
      name: open?.name,
      address: open?.address,
      website: open?.website,
      email: open?.email,
      phone: open?.phone,
      id: open?.id,
    });
  }, [open]);

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen()}
        aria-labelledby="add-currency-modal-title"
        aria-describedby="add-currency-modal-description"
      >
        <div className="editsupplier__modal_wrap">
          <div className="editsupplier__header">
            <h3 className="editsupplier__title">Edit Supplier</h3>
          </div>
          <div className="editsupplier__body">
            <div className="editsupplier__inputwrapper">
              <FormControl variant="outlined" className="w-100">
                <InputLabel htmlFor="addCategoryName" size="small">
                  Name
                </InputLabel>
                <OutlinedInput
                  id="addCategoryName"
                  name="name"
                  value={formData.name}
                  size="small"
                  onChange={(e) => handelChange(e)}
                  label="Supplier Name"
                />
              </FormControl>
              <FormControl variant="outlined" className="w-100">
                <InputLabel htmlFor="addCategoryName" size="small">
                  Address
                </InputLabel>
                <OutlinedInput
                  id="addCategoryName"
                  name="address"
                  value={formData?.address}
                  size="small"
                  onChange={(e) => handelChange(e)}
                  label="Supplier Address"
                />
              </FormControl>
            </div>
            <div className="editsupplier__inputwrapper">
              <FormControl variant="outlined" className="w-100">
                <InputLabel htmlFor="addCategoryName" size="small">
                  Website
                </InputLabel>
                <OutlinedInput
                  id="addCategoryName"
                  name="website"
                  value={formData?.website}
                  size="small"
                  onChange={(e) => handelChange(e)}
                  label="Supplier Website"
                />
              </FormControl>
              <FormControl variant="outlined" className="w-100">
                <InputLabel htmlFor="addCategoryName" size="small">
                  Email
                </InputLabel>
                <OutlinedInput
                  id="addCategoryName"
                  name="email"
                  value={formData?.email}
                  size="small"
                  onChange={(e) => handelChange(e)}
                  label="Supplier Email"
                />
              </FormControl>
            </div>
            <div className="editsupplier__inputwrapper">
              <FormControl variant="outlined" className="w-100">
                <InputLabel htmlFor="addCategoryName" size="small">
                  Phone
                </InputLabel>
                <OutlinedInput
                  id="addCategoryName"
                  name="phone"
                  value={formData?.phone}
                  size="small"
                  onChange={(e) => handelChange(e)}
                  label="Supplier Phone"
                />
              </FormControl>
            </div>
          </div>
          <div className="editsupplier__modalfooter">
            <div
              className="editsupplier__modalreset"
              onClick={() => setOpen(false)}
            >
              Cancel
            </div>
            <div className="editsupplier__modalsubmit" onClick={handleSubmit}>
              {loading ? <Loader /> : "Save"}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditSupplier;
