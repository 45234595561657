import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { BsUpcScan } from "react-icons/bs";
import BarcodeScanner from "../../../../Components/BarcodeScanner/BarcodeScanner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  boxShadow: 24,
  p: 4,
};

export default function BarcodeModal({ open, setOpen, setHighlightedRow }) {
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div id="modal-modal-title" style={{ display: "flex" }}>
          <b> Scan Barcode</b>{" "}
          <BsUpcScan style={{ padding: "2px", marginLeft: "2px" }} />
        </div>
        <div id="modal-modal-description" sx={{ mt: 2 }}>
          <BarcodeScanner
            setData={setHighlightedRow}
            handleClose={handleClose}
          />
        </div>
      </Box>
    </Modal>
  );
}
