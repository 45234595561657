import React, { useState } from "react";
import "./ImageModal.css";

// components imports
// apis imports
// library imports

import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { MdClose, MdArrowCircleDown } from "react-icons/md";
import PlaceholderImage from "../../../../../assets/images/placeholder-image.png";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import noImage from "../../../../../assets/images/noImage.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImageModal({ open, setOpen, setSnack }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [invalidImage, setInvalidImage] = useState(false);
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleDownload = () => {
    if (!open) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "No image to download",
          severity: "error",
        };
      });
      return;
    }
    setDownloading(true);
    axios
      .get(open, {
        responseType: "blob",
      })
      .then((response) => {
        setDownloading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", open.split("/").pop());
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setDownloading(false);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Couldn't Download Image",
            severity: "error",
          };
        });
      });
  };

  const handleClose = () => {
    setOpen(false);
    setInvalidImage(false);
    setImageLoaded(false);
  };
  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="admn__img_modal">
          <div className="image__modalclose_btn" onClick={handleClose}>
            <MdClose size={18} />
          </div>
          <img
            src={open}
            onError={(e) => {
              e.target.src = noImage;
            }}
            onLoad={handleImageLoad}
          />
          {imageLoaded && !invalidImage ? (
            <button
              className={`image__imgdownload_btn ${
                downloading && "disable__click"
              }`}
              onClick={handleDownload}
            >
              {downloading ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                <>
                  <MdArrowCircleDown className="image__imgdownload_btnicon" />{" "}
                  Download Image
                </>
              )}
            </button>
          ) : null}
          {!imageLoaded && (
            <div className="admnimg__modal_spinnerwrap">
              <div class="admn__img_modalspinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
}
