import React, { useEffect, useState } from "react";
import "./StockReceivedForm.css";

//componenets
import AvailableProducts from "./Components/AvailableProducts/AvailableProducts.jsx";
import GRNDetails from "./Components/GRNDetails/GRNDetails.jsx";
import AttachDocument from "./Components/AttachDocument/AttachDocument.jsx";
import Loader from "../../Components/CircularLoader/CircularLoader.jsx";
import CarrierTableLoader from "../../Components/CarrierInnerTableLoader/CarrierInnerTableLoader";
import ImagesModal from "../../Pages/Shipper/Customer/MainPages/Orders/Components/OrderHistoryModal/ImagesModal/ImagesModal";
import StockReceivedGeneralDetails from "./Components/StockReceivedGeneralDetails/StockReceivedGeneralDetails.jsx";
import NotesAndAttachmentsStepper from "./Components/Notes&AttachmentsStepper/Notes&AttachmentsStepper.jsx";
import Snackbar from "../../Components/SnackBar/SnackBar.jsx";
// apis imports

import {
  addGRNByForm,
  editGRNByForm,
  manageWarehouseGRN,
  getGRNByID,
} from "../../services/warehouse.services.js";

// library imports
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import dayjs from "dayjs";
import Logo from "../../assets/images/hajex_logo.png";
import NotesAndAttachments from "./Components/Notes&Attachments/Notes&Attachments.jsx";
import SpeedDial from "../../Components/SpeedDial/SpeedDial.jsx";
import BarcodeModal from "./Components/BarcodeModal/BarcodeModal.jsx";

// general things that must implement to whole app
const initialSnackBarData = {
  open: false,
  vertical: "top",
  horizontal: "right",
  text: "",
  severity: "",
};
//

const initialData = {
  grn_no: "", //stock id
  warehouse_id: "",
  description: "", //note to warehouse
  supplier_id: "",
  arrival_date: "",
  tracking_id: "",
  carrier_name: "",
  media: [],
  grn_details: [],
  delete_details_ids: [],
  deleted_media_ids: [],
  notes: "",
  grnNotesMedia: [],
  //warehouse user
  received_quantity: "",
  warehouse_notes: "",
};

const addZeroToDate = (d) => {
  const date = d.toString();
  return date.length === 1 ? "0" + date : date;
};

const StockReceivedForm = ({ open, mode }) => {
  const user = useSelector((state) => state.user);

  const location = useLocation();
  const navigate = useNavigate();

  let grnID = open ? open : location?.state?.previousData?.id;
  let modeType = mode ? mode : location?.state?.mode;

  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [fullview, setfullview] = useState(false);
  const [barcodeModal, setBarcodeModal] = useState(false);
  const [highlightedRow, setHighlightedRow] = useState(null);

  const [submitType, setSubmitType] = useState("");
  const [additionalCost, setAdditionalCost] = useState("0");

  const [formData, setFormData] = useState(initialData);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [notesAndAttachments, setNotesAndAttachments] = useState([]);

  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  const deleteProduct = (rowNumber, rowData) => {
    const updatedProducts = selectedProducts.filter(
      (_, index) => index !== rowNumber
    );
    setSelectedProducts(updatedProducts);
    if (rowData?.id) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        delete_details_ids: [...prevFormData.delete_details_ids, rowData?.id],
      }));
    }
  };

  const handelChange = (e) => {
    if (e.target.name === "arrival_date") {
      const val = `${e?.target?.value.$y}-${addZeroToDate(
        e?.target?.value.$M + 1
      )}-${addZeroToDate(e?.target?.value.$D)}`;

      setFormData({ ...formData, [e.target.name]: `${val}` });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const subTotal = selectedProducts?.reduce((accumulator, product) => {
    return accumulator + Number(product.sale_price) * product.quantity;
  }, 0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedProducts.length == 0) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Select Atleast One Product",
          severity: "error",
        };
      });
      return;
    }

    try {
      setLoading(true);

      const Formdata = new FormData();
      //stock id
      if (user?.role === "warehouseUser") {
        Formdata.append("notes", formData?.notes);
        formData?.grnNotesMedia?.map((item, index) =>
          Formdata.append(`grnNotesMedia[${index}]`, item)
        );
        Formdata.append(
          "grn_details",
          JSON.stringify(
            selectedProducts?.map((d) => {
              return {
                id: d?.id,
                warehouse_notes: d?.warehouse_notes,
                received_quantity: parseInt(d?.new_received_quantity ?? 0),
              };
            })
          )
        );
        formData?.grnNotesMedia?.map((item, index) => {
          Formdata.append(`grnNotesMedia[${index}]`, item);
        });
      } else {
        Formdata.append("grn_no", formData.grn_no);

        Formdata.append("warehouse_id", formData.warehouse_id);
        Formdata.append(
          "supplier_id",
          formData?.supplier_id ? formData?.supplier_id : null
        );

        Formdata.append(
          "arrival_date",
          formData?.arrival_date ?? dayjs().format("YYYY-MM-DD")
        );
        Formdata.append("tracking_id", formData?.tracking_id);
        Formdata.append("carrier_name", formData?.carrier_name);
        for (let i = 0; i < formData.media.length; i++) {
          if (!formData?.media[i]?.id) {
            Formdata.append("media[]", formData.media[i]);
          }
        }

        Formdata.append("notes", formData?.notes);
        formData?.grnNotesMedia?.map((item, index) =>
          Formdata.append(`grnNotesMedia[${index}]`, item)
        );
        //note to warehouse
        Formdata.append(
          "description",
          formData.description ? formData.description : ""
        );
        if (submitType === "2") {
          Formdata.append("is_draft", true);
        }
        Formdata.append("total", Number(subTotal));
        Formdata.append("sub_total", subTotal);
        formData?.deleted_media_ids?.map((item) =>
          Formdata.append("deleted_media_ids[]", item)
        );
        formData?.delete_details_ids?.map((item) =>
          Formdata.append("delete_details_ids[]", item)
        );

        Formdata.append(
          "grn_details",
          JSON.stringify(
            selectedProducts?.map((d) => {
              return {
                id: d?.id,
                product_id: d?.product_id,
                quantity: d?.quantity,
                warehouse_id: formData?.warehouse_id,
                supplier_id: formData?.supplier_id,
                cost_price: d?.sale_price,
                shipper_notes: d?.shipper_notes,
              };
            })
          )
        );
      }

      const response =
        user.role === "warehouseUser"
          ? await manageWarehouseGRN(grnID, Formdata)
          : grnID
          ? await editGRNByForm(grnID, Formdata)
          : await addGRNByForm(Formdata);

      if (response.error) {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text:
              response?.error?.response?.data?.message ||
              "Something went wrong",
            severity: "error",
          };
        });
        setLoading(false);
      } else {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: response?.data?.message,
            severity: "success",
          };
        });

        setFormData(initialData);
        setSelectedProducts([]);
        setLoading(false);
        navigate(
          user.role === "warehouseUser" ? "/warehouse-products" : "/products",
          { state: { transferId: "stockreceived" } }
        );
      }
    } catch (error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: error?.response?.data?.message || "Something went wrong",
          severity: "error",
        };
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (grnID) {
      fetchGRNByID(grnID);
    }
  }, []);

  const fetchGRNByID = async (id) => {
    setDataLoading(true);
    const response = await getGRNByID(id);
    if (!response.error) {
      if (modeType === "view" || modeType === "edit") {
        let {
          media,
          grn_no,
          description,
          supplier,
          status,
          warehouse_id,
          warehouse,
          arrival_date,
          tracking_id,
          carrier_name,
          grn_notes,
          grn_details,
        } = response.data.result;
        setFormData({
          media: media,
          grn_no: grn_no,
          description: description !== "null" ? description : "",
          supplier: supplier,
          supplier_id: supplier?.id,
          status: status,
          warehouse_id: warehouse_id,
          warehouse: warehouse,
          arrival_date: arrival_date ?? dayjs().format("YYYY-MM-DD"),
          tracking_id: tracking_id ?? "",
          carrier_name: carrier_name ?? "",
          delete_details_ids: [],
          deleted_media_ids: [],
          notes: "",
          grnNotesMedia: [],
        });
        setNotesAndAttachments(grn_notes);
        setSelectedProducts(
          grn_details?.map((d) => {
            return {
              id: d?.id,
              quantity: d?.quantity,
              media: d?.product?.media,
              name: d?.product?.name,
              length: d?.product?.length,
              code: d?.product?.code,
              width: d?.product?.width,
              height: d?.product?.height,
              weight: d?.product?.weight,
              sale_price: d?.product?.sale_price,
              product_id: d?.product_id,
              shipper_notes: d?.shipper_notes,
              warehouse_notes: d?.warehouse_notes,
              received_quantity: d?.received_quantity,
              new_received_quantity: 0,
            };
          })
        );
      }
      setDataLoading(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
      setDataLoading(false);
    }
  };

  const renderBoltLogo = () => {
    return (
      <div className="inv_ship_hajex_logo">
        <img src={Logo} alt="Logo" className="invoice__modal_headerleftimg" />
      </div>
    );
  };

  const renderBackButton = () => {
    return (
      <div className="add__prod_sheader">
        <div
          onClick={() =>
            navigate(
              user.role === "warehouseUser"
                ? "/warehouse-products"
                : "/products",
              { state: { transferId: "stockreceived" } }
            )
          }
        >
          <BiArrowBack size={22} className="add__prod_sheaderback" />
        </div>
        Back
      </div>
    );
  };

  if (dataLoading) {
    return (
      <>
        <div
          style={{
            width: "auto",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <CarrierTableLoader />
        </div>
      </>
    );
  }
  return (
    <div
      className={user.role === "warehouseUser" ? "stock_received_section_screen warehouse__user_not_active" : "stock_received_section_screen warehouse__user_active"} 
      style={{ padding: mode === "view" ? "0px" : "" }}
    >
      <Snackbar Snack={snack} SetSnack={setSnack} />
      {user.role === "warehouseUser" && modeType !== "view" && (
        <SpeedDial setBarcodeModal={setBarcodeModal} />
      )}
      <ImagesModal setOpen={setfullview} open={fullview} />
      <BarcodeModal
        setOpen={setBarcodeModal}
        open={barcodeModal}
        setHighlightedRow={setHighlightedRow}
      />
      {modeType === "view" && renderBoltLogo()}
      {modeType !== "view" && renderBackButton()}
      <Form onSubmit={handleSubmit}>
        <div className="add__adj_byform_modalcontent">
          <StockReceivedGeneralDetails
            modeType={modeType}
            formData={formData}
            handelChange={handelChange}
            user={{
              role: user.role,
              user_approved_warehouses: user.user_approved_warehouses,
            }}
            setSnack={setSnack}
          />
          {user.role != "warehouseUser" && modeType != "view" && (
            <AvailableProducts
              formData={formData}
              SelectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
            />
          )}
          <GRNDetails
            SelectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            deleteProduct={deleteProduct}
            additionalCost={additionalCost}
            subTotal={subTotal}
            handelChange={handelChange}
            formData={formData}
            modeType={modeType}
            setfullview={setfullview}
            highlightedRow={highlightedRow}
            setHighlightedRow={setHighlightedRow}
            setSnack={setSnack}
          />

          {/* <AttachDocument
            formData={formData}
            setFormData={setFormData}
            modeType={modeType}
            setfullview={setfullview}
            user={{ role: user?.role }}
          /> */}

          {modeType != "view" && (
            <NotesAndAttachments
              formData={formData}
              setFormData={setFormData}
              modeType={modeType}
            />
          )}

          {(modeType === "edit" || modeType === "view") &&
            notesAndAttachments?.length > 0 && (
              <NotesAndAttachmentsStepper
                notesAndAttachments={notesAndAttachments}
                setfullview={setfullview}
              />
            )}

          {modeType != "view" && (
            <div className="stock_received_footer_buttons">
              <Button
                type="submit"
                onClick={() => setSubmitType("1")}
                disabled={loading}
                variant="contained"
                color="info"
                style={{ marginRight: "10px" }}
              >
                {loading && submitType === "1" ? <Loader /> : "Save"}
              </Button>
              {((user.role != "warehouseUser" &&
                location?.state?.previousData?.is_draft != null) ||
                modeType === "add") && (
                <Button
                  type="submit"
                  onClick={() => setSubmitType("2")}
                  disabled={loading}
                  variant="contained"
                  color="info"
                >
                  {loading && submitType === "2" ? <Loader /> : "Save As Draft"}
                </Button>
              )}
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};

export default StockReceivedForm;
