import React from "react";
import "./Requirements.css";
const KindersleyRequirements = () => {
  return (
    <div className="cuscarrier__reqrmnts_box">
      Contact Kindersley Transport at
      <a
        href={`mailto: anthony.vicenzino@siemenstransport.com`}
        className="email_space"
      >
        anthony.vicenzino@siemenstransport.com
      </a>
      to obtain your Account Name , Account Number , Bearer Token. These API
      credentials will allow you to connect your custom Kindersley Transport
      account to the application, enabling you to access Kindersley Transport
      shipping rates for your account and display them as quotes.
    </div>
  );
};

export default KindersleyRequirements;
