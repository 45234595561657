import React, { useEffect, useState } from "react";
import "./UpdateDetails.css";

// components imports
import Loader from "../../../../../Components/CircularLoader/CircularLoader";
import SwitchButton from "../../../../../Components/SwitchButton/SwitchButton";
// apis imports
import { storeCompanyDetails } from "../../../../../services/admin.services";
import Countries, { countriesMap } from "../../../../../data/countries";
// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import Upload from "@mui/icons-material/CloudUpload";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const initialData = {
  about: "",
  history: "",
  about_image: null,
  history_image: null,
  countries: [],
};

export default function UpdateDetails({
  open,
  setOpen,
  setSnack,
  companyData,
  setCompanyDetails,
}) {
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setData(initialData);
    setOpen(false);
  };
  const user = useSelector((state) => state.user);
  const handleChange = (e) => {
    // console.log(e.target.value);
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleServiceSwitch = (type, index) => {
    setData({
      ...data,
      countries: data.countries.map((country, indx) => {
        if (index === indx) {
          return {
            ...country,
            services: data.countries[indx].services.includes(type)
              ? data.countries[indx].services.filter(
                  (service) => service !== type
                )
              : [...data.countries[indx].services, type],
          };
        } else {
          return country;
        }
      }),
    });
  };
  const handleFileChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.files[0] });
  };
  const handleCountriesChange = (e, countries) => {
    const newArray = [];

    data?.countries?.forEach((firstObj) => {
      const secondObj = countries.find(
        (secondObj) => secondObj?.name === firstObj?.name
      );

      if (secondObj) {
        newArray.push(firstObj);
      } else {
      }
    });

    countries.forEach((secondObj) => {
      const isFirstArrayCountry = data?.countries?.some(
        (firstObj) => firstObj?.name === secondObj?.name
      );

      if (!isFirstArrayCountry) {
        newArray.push({
          name: secondObj.code,
          services: ["pickup", "delivery"],
        });
      }
    });

    setData({
      ...data,
      countries: newArray,
    });
  };
  const handleSave = async () => {
    setLoading(true);
    setLoading(true);
    let id = data.id;
    const payload = {
      ...data,
      history: {
        description: data.history,
      },
      about_us: {
        description: data.about,
      },
      history_image: data.history_image,
      about_us_image: data.about_image,
    };
    const formData = new FormData();
    formData.append("countries", JSON.stringify(payload.countries));
    formData.append("history", JSON.stringify(payload.history));
    formData.append("about_us", JSON.stringify(payload.about_us));
    payload.history_image &&
      formData.append("history_image", payload.history_image);
    payload.about_us_image &&
      formData.append("about_us_image", payload.about_us_image);

    // console.log(payload);
    const response = await storeCompanyDetails(user.token, formData, id);
    setLoading(false);
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Updated Successfully",
          severity: "success",
        };
      });
      setCompanyDetails((prevCompanyDetails) => {
        return {
          ...prevCompanyDetails,
          data: prevCompanyDetails?.data.map((company) => {
            return company.id === id
              ? response?.data?.result?.data[0]
              : company;
          }),
        };
      });
      handleClose();
    }
  };
  useEffect(() => {
    if (companyData) {
      setData({
        ...initialData,
        countries: companyData.rating_country,
        id: companyData.id,
        about: companyData.rating_setting
          ? companyData.rating_setting[1]?.description
          : "",
        history: companyData.rating_setting
          ? companyData.rating_setting[0]?.description
          : "",
      });
    }
  }, [companyData]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="admnadd__details_modal">
          <div className="admnadd__details_modalheading">
            Update Company Details
          </div>
          <div className="admnadd__details_modalcontent">
            <div className="admnadd__details_modalrow">
              <div className="admnadd__details_modalinputwrap">
                <label>About</label>
                <textarea
                  className="admnadd__details_modalinput"
                  placeholder="About"
                  name="about"
                  value={data.about}
                  onChange={handleChange}
                  rows={5}
                ></textarea>
              </div>
            </div>
            <div className="admnadd__details_modalrow">
              <div className="admnadd__details_modalinputwrap">
                <label>History</label>
                <textarea
                  className="admnadd__details_modalinput"
                  placeholder="History"
                  name="history"
                  value={data.history}
                  onChange={handleChange}
                  rows={5}
                ></textarea>
              </div>
            </div>

            <div className="admnadd__details_modalrow">
              <div className="admnadd__details_modalinputwrap">
                <label className="admnadd__details_profimagelbl">
                  <Upload /> {data.about_image?.name ?? "About Image"}
                  <input
                    type="file"
                    size="60"
                    className="admnadd__details_profimageinput"
                    onChange={handleFileChange}
                    name="about_image"
                    accept=".jpg,.png"
                  />
                </label>
              </div>
              <div className="admnadd__details_modalinputwrap">
                <label className="admnadd__details_profimagelbl">
                  <Upload /> {data.history_image?.name ?? "History Image"}
                  <input
                    type="file"
                    size="60"
                    className="admnadd__details_profimageinput"
                    onChange={handleFileChange}
                    name="history_image"
                    accept=".jpg,.png"
                  />
                </label>
              </div>
            </div>
            <br />
            <div className="admnadd__details_modalrow">
              <div className="admnadd__details_modalinputwrap">
                <Autocomplete
                  multiple
                  disablePortal
                  id="combo-box-demo"
                  options={Countries}
                  getOptionLabel={(option) => option?.name}
                  value={data?.countries ?? []}
                  sx={{}}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Countries" />
                  )}
                  onChange={(e, country) => {
                    handleCountriesChange(e, country);
                  }}
                />
              </div>
              <div className="admnadd__details_modalinputwrap">
                <div className="cdetails__selected_country">
                  <div className="country__list_headers">
                    <div className="cdetails__selected_countryheading">
                      Country
                    </div>
                    <div className="cdetails__selected_countryheading">
                      Pickup
                    </div>
                    <div className="cdetails__selected_countryheading">
                      Delivery
                    </div>
                  </div>
                  <div className="country__list_data">
                    {data?.countries?.map((country, index) => (
                      <div className="country__list_data_row">
                        <div className="cdetails__selected_countryname">
                          {countriesMap[country?.name] &&
                            countriesMap[country?.name].name}
                        </div>
                        <div className="cdetails__scountry_checkwrap">
                          <SwitchButton
                            checked={country?.services?.includes("pickup")}
                            onChange={(e) =>
                              handleServiceSwitch("pickup", index)
                            }
                          />
                        </div>
                        <div className="cdetails__scountry_checkwrap">
                          <SwitchButton
                            checked={country?.services?.includes("delivery")}
                            onChange={(e) =>
                              handleServiceSwitch("delivery", index)
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="admnadd__details_modalfooter">
            <div className="admnadd__details_modalcncl" onClick={handleClose}>
              Cancel
            </div>
            <div className="admnadd__details_modalsave" onClick={handleSave}>
              {loading ? <Loader /> : "Save"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
