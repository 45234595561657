import React, { useState } from "react";

// components imports
import AttachDocument from "../Components/AttachDocument/AttachDocument";
import Snackbar from "../../../../Components/SnackBar/SnackBar";
import AvailableProducts from "./AvailableProducts/AvailableProducts";
import Loader from "../../../../Components/CircularLoader/CircularLoader";
import SelectedProdDetails from "./SelectedProdDetails/SelectedProdDetails";

// apis imports
import { addTransferNote } from "../../../../services/warehouse.services";

// library imports
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Buttons from "@mui/material/Button";
import Form from "react-bootstrap/Form";

const CreateTransferNote = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });
  const [errors, setErrors] = useState({});

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [additionalCost, setAdditionalCost] = useState("0");

  const [formData, setFormData] = useState({
    from_warehouse: "",
    to_warehouse: "",
    status: "",
    media: [],
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const deleteProduct = (rowNumber) => {
    const updatedProducts = selectedProducts.filter(
      (_, index) => index !== rowNumber
    );
    setSelectedProducts(updatedProducts);
  };

  const totalWeight = selectedProducts?.reduce((accumulator, product) => {
    return accumulator + Number(product.weight);
  }, 0);
  const subTotal = selectedProducts?.reduce((accumulator, product) => {
    return accumulator + Number(product.sale_price) * product.quantity;
  }, 0);
  const totalQuantity = selectedProducts?.reduce((accumulator, product) => {
    return accumulator + Number(product.quantity);
  }, 0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (selectedProducts.length === 0) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please Select Atleast One Product",
          severity: "error",
        };
      });
      setLoading(false);
      return;
    }
    if (formData.from_warehouse === formData.to_warehouse) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please Select Alternative Warehouse",
          severity: "error",
        };
      });
      setLoading(false);
      return;
    }

    const Formdata = new FormData();
    for (let i = 0; i < formData.media.length; i++) {
      Formdata.append("media[]", formData.media[i]);
    }

    Formdata.append("status", formData.status);

    Formdata.append(
      "transferDetails",
      JSON.stringify(
        selectedProducts?.map((d) => {
          return {
            product_id: d?.product_id,
            quantity: d?.quantity,
          };
        })
      )
    );

    const response = await addTransferNote(
      formData.from_warehouse,
      formData.to_warehouse,
      Formdata
    );
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text:
            response?.error?.response?.data?.message || "Something went wrong",
          severity: "error",
        };
      });
      setLoading(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Transfer Note Added Successfully",
          severity: "success",
        };
      });
      setLoading(false);
      navigate("/products", { state: { transferId: "transfernote" } });
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Snackbar Snack={snack} SetSnack={setSnack} />
        <div className="createTransferNote__desc_page">
          <div className="add__prod_sheader hide_mobile_btn">
            <div
              onClick={() =>
                navigate("/products", { state: { transferId: "transfernote" } })
              }
            >
              <BiArrowBack size={22} className="add__prod_sheaderback" />
            </div>
            Back
          </div>
          <h3 className="heading__main_ctn transfer__title_main">
            Add Transfer
          </h3>

          <div className="form__field_atn_wrap">
            <FormControl
              variant="outlined"
              className="fromWarehouse_field_wrap"
            >
              <InputLabel id="from__warehouse" size="small">
                From Warehouse *
              </InputLabel>
              <Select
                required
                labelId="from__warehouse"
                id="from__warehouse"
                size="small"
                // value={formData.from_warehouse}
                label="From Warehouse *"
                name="from_warehouse"
                onChange={handleChange}
              >
                {user?.user_approved_warehouses?.map((warehouse) => (
                  <MenuItem key={warehouse.id} value={warehouse?.warehouse?.id}>
                    {warehouse?.warehouse?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className="toWarehouse_field_wrap">
              <InputLabel id="to__warehouse" size="small">
                To Warehouse *
              </InputLabel>
              <Select
                required
                labelId="to__warehouse"
                id="to__warehouse"
                size="small"
                // value={formData.to_warehouse}
                label="To Warehouse *"
                onChange={handleChange}
                name="to_warehouse"
              >
                {user?.user_approved_warehouses?.map((warehouse) => (
                  <MenuItem key={warehouse.id} value={warehouse?.warehouse?.id}>
                    {warehouse?.warehouse?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              className="warehouse_status_field_wrap"
            >
              <InputLabel id="demo-simple-select-label" size="small">
                Select Status *
              </InputLabel>
              <Select
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select-label"
                size="small"
                value={formData?.status || ""}
                onChange={(e) =>
                  handleChange({
                    target: {
                      name: "status",
                      value: e.target.value,
                    },
                  })
                }
                label="Select Status"
              >
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="received">Received</MenuItem>
                <MenuItem value="notReceived">Not Received</MenuItem>
              </Select>
            </FormControl>
          </div>
          <AttachDocument
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            setErrors={setErrors}
          />
          <AvailableProducts
            SelectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
          />

          <SelectedProdDetails
            SelectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            deleteProduct={deleteProduct}
            setAdditionalCost={setAdditionalCost}
            additionalCost={additionalCost}
            totalWeight={totalWeight}
            subTotal={subTotal}
            totalQuantity={totalQuantity}
          />
          <div className="prod__imag_es_modalfooter add_transfer_prod">
            <div
              className="prod__imag_es_modalfootercncl"
              onClick={() => navigate("/products")}
            >
              Close
            </div>
            <Buttons
              type="submit"
              variant="contained"
              size="small"
              style={{ marginLeft: "5px" }}
              disabled={loading ? true : false}
              className="prod__imag_es_modalfootersave"
            >
              {loading ? <Loader /> : "Submit"}
            </Buttons>
          </div>
        </div>
      </Form>
    </>
  );
};

export default CreateTransferNote;
