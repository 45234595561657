import React, { useEffect, useState } from "react";
import "../UserFormula/userformula.css";

import Confirm from "../../../../../Components/Popups/Confirm";
import Loader from "../../../../../Components/CircularLoader/CircularLoader";
import CarrierInnerTableLoader from "../../../../../Components/CarrierInnerTableLoader/CarrierInnerTableLoader";

import {
  getMarkupFormula,
  createUserPaymentFormula,
  updateUserPaymentFormula,
  deleteUserPaymentFormula,
} from "../../../../../services/AdminServices/PaymentsAdmin/payments.services";

import { MdAdd } from "react-icons/md";
import Sad from "@mui/icons-material/SentimentVeryDissatisfied";
import Form from "react-bootstrap/Form";
import TextFeild from "../CustomTextFeild/TextFeild";
import IconButton from "@mui/material/IconButton";
import { FaRegTimesCircle } from "react-icons/fa";

const UserMarkup = ({ setSnack, user, setOpen, updateIndex }) => {
  const [loading, setLoading] = useState(false);
  const [paymentFormula, setPaymentFormula] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const getFormulaData = async () => {
    setLoading("main");
    let response = await getMarkupFormula();
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.error?.response?.data?.message,
          severity: "error",
        };
      });
      setLoading(false);
    } else {
      setPaymentFormula(response?.data?.result);
      setIsEditable(false);
      setLoading(false);
    }
  };

  const createFormula = () => {
    setIsEditable(true);
    setPaymentFormula({
      type: "by_markup",
      formula_details: [
        {
          min: null,
          max: null,
          value: "",
        },
      ],
    });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    if (user?.markup_formula) {
      setPaymentFormula(user?.markup_formula);
      setIsEditable(false);
    } else {
      getFormulaData();
    }
  };

  const handleMarkup = (e) => {
    const updatedValue = e.target.value;
    setPaymentFormula((prevState) => ({
      ...prevState,
      formula_details: prevState.formula_details.map((detail, index) =>
        index === 0 ? { ...detail, value: updatedValue } : detail
      ),
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading("button");
    let response = await createUserPaymentFormula(
      {
        type: paymentFormula?.type,
        formulaDetails: paymentFormula?.formula_details,
      },
      user?.id
    );
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.error?.response?.data?.message,
          severity: "error",
        };
      });
      setLoading(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.data?.message,
          severity: "success",
        };
      });
      setIsEditable(false);
      setPaymentFormula({
        ...response?.data?.result,
      });
      updateIndex(
        user?.id,
        {
          ...response?.data?.result,
        },
        "markup_formula"
      );
      setLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    setLoading("button");
    let response = await updateUserPaymentFormula(
      {
        type: paymentFormula.type,
        formulaDetails: paymentFormula?.formula_details,
      },
      paymentFormula?.id
    );
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.error?.response?.data?.message,
          severity: "error",
        };
      });
      setLoading(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.data?.message,
          severity: "success",
        };
      });
      setPaymentFormula({
        ...paymentFormula,
      });
      updateIndex(
        user?.id,
        {
          ...response?.data?.result,
        },
        "markup_formula"
      );
      setLoading(false);
      setIsEditable(false);
    }
  };

  const handleDeleteFormula = async (e) => {
    setLoading("delete");
    let response = await deleteUserPaymentFormula(paymentFormula?.id);
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.error?.response?.data?.message,
          severity: "error",
        };
      });
      setLoading(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.data?.message,
          severity: "success",
        };
      });
      setLoading(false);
      setConfirmDelete(false);
      getFormulaData();
      updateIndex(user.id, null, "markup_formula");
    }
  };

  useEffect(() => {
    if (user?.markup_formula) {
      setPaymentFormula(user?.markup_formula);
    } else {
      getFormulaData();
    }
  }, []);

  return (
    <>
      {loading === "main" ? (
        <div style={{ position: "relative", minHeight: "300px" }}>
          <div className="custom__table_tableloading">
            <CarrierInnerTableLoader />
          </div>
        </div>
      ) : (
        <>
          <div style={{ position: "relative" }}>
            <div className="admnedit__user_modalheading">
              {paymentFormula?.is_default === "for_markup_default"
                ? "Default Markup"
                : "Custom Markup"}
            </div>
            <div
              className="aduser__details_modalclose"
              onClick={() => setOpen(false)}
            >
              <IconButton>
                <FaRegTimesCircle size={25} color="lightgrey" />
              </IconButton>
            </div>
          </div>
          {paymentFormula === null ? (
            <>
              <div className="payment__formula_btn_wrapper">
                <div>
                  <button
                    className="payment__formula_btn"
                    onClick={() => createFormula()}
                  >
                    <MdAdd className="payment__formula_btnicon" size={18} />
                    CREATE CUSTOM MARKUP
                  </button>
                </div>
              </div>
              <div className="driver__screen_empty" style={{ width: "auto" }}>
                <div className="driver__scrnempt_content">
                  <Sad className="driver__scrnempt_contenticon" />
                  <div>No Default Formula available to display!</div>
                </div>
              </div>
            </>
          ) : (
            <Form onSubmit={paymentFormula?.id ? handleUpdate : handleSave}>
              {isEditable === false &&
                paymentFormula?.is_default === "for_markup_default" && (
                  <div className="payment__formula_btn_wrapper">
                    <div>
                      <button
                        className="payment__formula_btn"
                        onClick={() => createFormula()}
                      >
                        <MdAdd className="payment__formula_btnicon" size={18} />
                        CREATE CUSTOM MARKUP
                      </button>
                    </div>
                  </div>
                )}
              {isEditable === false &&
                paymentFormula?.is_default !== "for_markup_default" &&
                paymentFormula?.id && (
                  <div className="payment__formula_btn_wrapper">
                    <div>
                      <button
                        className="payment__formula_btn"
                        onClick={() => setIsEditable(true)}
                      >
                        <MdAdd className="payment__formula_btnicon" size={18} />
                        UPDATE CUSTOM MARKUP
                      </button>
                    </div>
                  </div>
                )}

              {/* {paymentFormula?.formula_details?.[0]?.value} */}
              <div className="payment__formula_formrow">
                <div className="payment__formula_formcol">
                  <TextFeild
                    Label="Service Charge"
                    Name="value"
                    PlaceHolder="0"
                    Width="100%"
                    Adroment="%"
                    Data={paymentFormula?.formula_details?.[0]?.value ?? ""}
                    Handler={(e) => handleMarkup(e)}
                    Disabled={isEditable ? false : true}
                  />
                </div>
              </div>
              {isEditable === true && (
                <div className="prodidentifier__desc_modalfooter">
                  <button
                    className="prodidentifier__desc_modalcncl"
                    onClick={(e) => {
                      handleCancel(e);
                    }}
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    className="prodidentifier__desc_modalsave"
                  >
                    {loading ? (
                      <Loader />
                    ) : paymentFormula?.id ? (
                      "Update"
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              )}
              {isEditable === false &&
                paymentFormula?.is_default !== "for_markup_default" && (
                  <div className="prodidentifier__desc_modalfooter">
                    <button
                      style={{ width: "fit-content", padding: "0px 10px" }}
                      className="prodidentifier__desc_modalsave"
                      onClick={(e) => {
                        e.preventDefault();
                        setConfirmDelete(true);
                      }}
                    >
                      Set Back To Default
                    </button>
                  </div>
                )}
              <Confirm
                open={confirmDelete}
                setOpen={setConfirmDelete}
                loader={loading === "delete" ? true : false}
                handleConfirm={handleDeleteFormula}
                text="Do you want to switch back to default ?"
              />
            </Form>
          )}
        </>
      )}
    </>
  );
};

export default UserMarkup;
