import React, { useEffect, useState } from "react";
import "./EditUser.css";

// components imports
import Loader from "../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import { updateUser } from "../../../../../services/AdminServices/Users/users.services";
import Countries from "../../../../../data/countries";
// library imports

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { TextField } from "@mui/material";

const initialShipperData = {
  username: "",
  firstname: "",
  lastname: "",
  email: "",
  business_name: "",
  industry_type: "",
  phone_number: "",
  address: "",
  country: "",
  province: "",
  city: "",
  zip: "",
  about_us: "",
  shipment_per_month: "",
  revenue: "",
  role: "Shipper",
  market: [
    {
      value: "amazon",
    },
  ],
};

export default function EditUser({
  open,
  setOpen,
  previousData,
  setSnack,
  setUsers,
}) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleClose = () => setOpen(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleFieldChange = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleUpdate = async () => {
    setLoading(true);
    const newData = {};

    Object.keys(initialShipperData).forEach((field) => {
      if (data.hasOwnProperty(field)) {
        newData[field] = data[field];
      }
    });
    const response = await updateUser(data, data.id);
    setLoading(false);
    console.log(response);
    if (!response?.data?.success) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong!",
          severity: "error",
        };
      });
    } else {
      setUsers((prevUser) => {
        return {
          ...prevUser,
          data:
            previousData.role === "warehouseUser"
              ? prevUser?.data?.map((userData) => {
                  return userData.user.id === data.id
                    ? {
                        ...userData,
                        user: response?.data?.result,
                      }
                    : userData;
                })
              : prevUser?.data?.map((user) => {
                  return user.id === data.id ? response.data?.result : user;
                }),
        };
      });

      handleClose();
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "User Updated Successfully!",
          severity: "success",
        };
      });
    }
  };

  useEffect(() => {
    if (previousData) {
      // console.log("previousData", previousData?.role);
      setData({ ...previousData, logo_url: null });
    }
  }, [previousData]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="admnedit__user_modal">
          <div className="admnedit__user_modalheading">Update User</div>
          <div className="admnedit__user_modalcontent">
            <div className="admnedit__user_modalrow">
              <div className="admnedit__user_modalinputwrap">
                <label>First Name</label>
                <input
                  className="admnedit__user_modalinput"
                  placeholder="First Name"
                  name="firstname"
                  value={data.firstname}
                  onChange={handleChange}
                />
              </div>
              <div className="admnedit__user_modalinputwrap">
                <label>Last Name</label>
                <input
                  className="admnedit__user_modalinput"
                  placeholder="Last Name"
                  name="lastname"
                  value={data.lastname}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="admnedit__user_modalrow">
              <div className="admnedit__user_modalinputwrap">
                <label>Username</label>
                <input
                  className="admnedit__user_modalinput"
                  placeholder="Username"
                  name="username"
                  value={data.username}
                  onChange={handleChange}
                />
              </div>
              <div className="admnedit__user_modalinputwrap">
                <label>Business Name</label>
                <input
                  className="admnedit__user_modalinput"
                  placeholder="Business Name"
                  name="business_name"
                  value={data.business_name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="admnedit__user_modalrow">
              <div className="admnedit__user_modalinputwrap">
                <label>Email</label>
                <input
                  className="admnedit__user_modalinput"
                  placeholder="Email"
                  name="email"
                  value={data.email}
                  onChange={handleChange}
                />
              </div>
              <div className="admnedit__user_modalinputwrap">
                <label>Industry Type</label>
                <input
                  className="admnedit__user_modalinput"
                  placeholder="Industry Type"
                  name="industry_type"
                  value={data.industry_type}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="admnedit__user_modalrow">
              <div className="admnedit__user_modalinputwrap">
                <label>Address</label>
                <input
                  className="admnedit__user_modalinput"
                  placeholder="Address"
                  name="address"
                  type="text"
                  value={data.address}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="admnedit__user_modalrow">
              <div className="admnedit__user_modalinputwrap">
                <label>Country</label>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={Countries}
                  value={
                    Countries?.find((d) => d?.code === data?.country) || null
                  }
                  getOptionLabel={(option) => option.name}
                  sx={{
                    "&  div": {
                      maxHeight: "40px",
                      outline: "none",
                      padding: 0,
                      border: "none",
                      outline: "none",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Country"
                      className="admnedit__user_modalinput"
                    />
                  )}
                  onChange={(e, country) =>
                    handleFieldChange("country", country.code)
                  }
                />
              </div>
              <div className="admnedit__user_modalinputwrap">
                <label>Province</label>
                <input
                  className="admnedit__user_modalinput"
                  placeholder="Province"
                  name="province"
                  value={data.province}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="admnedit__user_modalrow">
              <div className="admnedit__user_modalinputwrap">
                <label>City</label>
                <input
                  className="admnedit__user_modalinput"
                  placeholder="City"
                  name="city"
                  value={data.city}
                  onChange={handleChange}
                />
              </div>
              <div className="admnedit__user_modalinputwrap">
                <label>Postal/Zip Code</label>
                <input
                  className="admnedit__user_modalinput"
                  placeholder="Postal/Zip Code"
                  name="zip"
                  value={data.zip}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="admnedit__user_modalrow">
              <div className="admnedit__user_modalinputwrap">
                <label>Phone Number</label>
                <input
                  className="admnedit__user_modalinput"
                  placeholder="Phone Number"
                  name="phone_number"
                  type="number"
                  value={data.phone_number}
                  onChange={handleChange}
                />
              </div>
              <div className="admnedit__user_modalinputwrap">
                <label>Role</label>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={["Shipper", "Carrier"]}
                  getOptionLabel={(option) => option}
                  sx={{
                    "&  div": {
                      maxHeight: "40px",
                      outline: "none",
                      padding: 0,
                      border: "none",
                      outline: "none",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Role"
                      className="admnedit__user_modalinput"
                    />
                  )}
                  value={data.role}
                  onChange={(e, role) => handleFieldChange("role", role)}
                />
              </div>
            </div>
            <div className="admnedit__user_modalrow">
              <div className="admnedit__user_modalinputwrap">
                <label>Shipments per month</label>
                <input
                  className="admnedit__user_modalinput"
                  placeholder="Shipments per month"
                  name="shipment_per_month"
                  value={data.shipment_per_month}
                  onChange={handleChange}
                />
              </div>
              <div className="admnedit__user_modalinputwrap">
                <label>Revenue Per Year</label>
                <input
                  className="admnedit__user_modalinput"
                  placeholder="Revenue Per Year"
                  name="revenue"
                  value={data.revenue}
                  onChange={handleChange}
                />
              </div>
            </div>
            {/* <div className="admnedit__user_modalrow">

              <div className="admnedit__user_modalinputwrap">
                <label>User Image</label>
                <label className="admnedit__user_profimagelbl">
                  <Upload /> {data.logo_url?.name ?? "Upload Image"}
                  <input
                    type="file"
                    size="60"
                    className="admnedit__user_profimageinput"
                    onChange={handleFileChange}
                    accept=".jpg,.png"
                  />
                </label>
              </div>
            </div> */}
          </div>
          <div className="admnedit__user_modalfooter">
            <div className="admnedit__user_modalcncl" onClick={handleClose}>
              Cancel
            </div>
            <div className="admnedit__user_modalsave" onClick={handleUpdate}>
              {loading ? <Loader /> : "Update"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
