import React, { useState, useEffect } from "react";
import "./Products.css";

// components imports
import Snackbar from "../../../../../Components/SnackBar/SnackBar";
import Product from "../../../../Products/Product/ShipperProduct";
import GRN from "../../../../Products/GRN/GRN";
import ProductStockCount from "../../../../Products/ProductStockCount/ProductStockCount";
import TransferNote from "../../../../Products/AddTransferNotes/TransferNote";
import BrandStock from "../../../../Products/BrandStock/BrandStock";
import Category from "../../../../Products/Category/Category";
import Suppliers from "../../../../Products/Suppliers/Suppliers";
import ShipperPallets from "../../../../Products/Pallets/Pallets";

// apis imports
// library imports

import { useLocation } from "react-router-dom";
import TabsComponent from "../../../../../Components/Common/TabsComponent";

const Products = () => {
  const location = useLocation();
  const transferId = location.state && location.state.transferId;
  const [tab, setTab] = useState(0);

  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (transferId === "transfernote") {
      setTab(3);
    } else if (transferId === "stockreceived") {
      setTab(1);
    } else {
      setTab(0);
    }
  }, [transferId]);

  return (
    <div className="warehouse__products__page_wrap">
      <Snackbar Snack={snack} SetSnack={setSnack} />

      <TabsComponent
        items={[
          { label: "ALL PRODUCTS", style: { fontWeight: "bold" }, value: 0 },
          { label: "ALL Pallets", style: { fontWeight: "bold" }, value: 1 },
          {
            label: "Stock Received List",
            style: { fontWeight: "bold" },
            value: 2,
          },
          {
            label: "PRODUCT COUNT STOCK",
            style: { fontWeight: "bold" },
            value: 3,
          },
          { label: "Transfer", style: { fontWeight: "bold" }, value: 4 },
          { label: "Brand", style: { fontWeight: "bold" }, value: 5 },
          { label: "Category", style: { fontWeight: "bold" }, value: 6 },
          { label: "Suppliers", style: { fontWeight: "bold" }, value: 7 },
        ]}
        tab={tab}
        handleTabChange={handleTabChange}
      />
      {tab === 0 && <Product snack={snack} setSnack={setSnack} />}
      {tab === 1 && <ShipperPallets setSnack={setSnack} />}
      {tab === 2 && <GRN setSnack={setSnack} />}
      {tab === 3 && (
        <ProductStockCount
          NonEditable={true}
          snack={snack}
          setSnack={setSnack}
        />
      )}
      {tab === 4 && <TransferNote snack={snack} setSnack={setSnack} />}
      {tab === 5 && <BrandStock snack={snack} setSnack={setSnack} />}
      {tab === 6 && <Category snack={snack} setSnack={setSnack} />}
      {tab === 7 && <Suppliers snack={snack} setSnack={setSnack} />}
      {/* {tab === 5 && <Currency />} */}
      {/* {tab === 7 && <Unit />} */}
    </div>
  );
};

export default Products;
