import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Autocomplete from "@mui/material/Autocomplete";
import "./AddShipperUser.css";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import {
  fetchAllPermissions,
  fetchAllRoles,
  updateShipperUserData,
  createNewShipperUser,
} from "../../../../../../../../services/settings.services";
import Countries from "../../../../../../../../data/countries";
import { TextField } from "@mui/material";

function Loader(props) {
  return (
    <Box
      sx={{
        position: "relative",
        top: "2px",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={20}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={20}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

export default function AddShipperUser({ open, setOpen, setUsers, setSnack }) {
  const [data, setData] = useState({
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    phone_number: "",
    address: "",
    country: "",
    province: "",
    city: "",
    zip: "",
    role: "",
    roles: [],
    password: "",
  });
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const user = useSelector((state) => state.user);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleFieldChange = (field, value) => {
    if (field === "role") {
      setData({
        ...data,
        [field]: roles.find((r) => r.name === value).name,
        roles: roles.filter((r) => r.name === value),
      });
    } else {
      setData({ ...data, [field]: value });
    }
  };
  const handleFileChange = (e) => {
    setData({ ...data, logo_url: e.target.files[0] });
  };
  const handleCreateNew = async () => {
    setLoading(true);
    let obj = { ...data, roles: data.roles.map((r) => r.id) };
    delete obj["role"];
    const response = await createNewShipperUser(user.token, obj);
    setLoading(false);
    // console.log(response.error.response.data);
    if (!response?.data?.success) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong!",
          severity: "error",
        };
      });
    } else {
      setUsers((prevRoles) => [
        {
          ...response.data.result,
        },
        ...prevRoles,
      ]);
      setData({
        username: "",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        address: "",
        country: "",
        province: "",
        city: "",
        zip: "",
        role: "",
        roles: [],
        password: "",
      });
      handleClose();
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "User Added Successfully!",
          severity: "success",
        };
      });
    }
  };

  const fetchAllRolesData = async () => {
    const saved = await fetchAllRoles(user.token);
    setRoles(
      saved?.data?.result?.data.map((role) => {
        return {
          id: role.id,
          name: role.name,
        };
      })
    );
  };

  useEffect(() => {
    fetchAllRolesData();
  }, []);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="add__shipper__user_modal">
          <div className="add__shipper__user_modalheading">Create User</div>
          <div className="add__shipper__user_modalcontent">
            <div className="add__shipper__user_modalrow">
              <div className="add__shipper__user_modalinputwrap">
                <label>First Name</label>
                <input
                  className="add__shipper__user_modalinput"
                  placeholder="First Name"
                  name="firstname"
                  value={data.firstname}
                  onChange={handleChange}
                />
              </div>
              <div className="add__shipper__user_modalinputwrap">
                <label>Last Name</label>
                <input
                  className="add__shipper__user_modalinput"
                  placeholder="Last Name"
                  name="lastname"
                  value={data.lastname}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="add__shipper__user_modalrow">
              <div className="add__shipper__user_modalinputwrap">
                <label>Username</label>
                <input
                  className="add__shipper__user_modalinput"
                  placeholder="Username"
                  name="username"
                  value={data.username}
                  onChange={handleChange}
                />
              </div>

              <div className="add__shipper__user_modalinputwrap">
                <label>Email</label>
                <input
                  className="add__shipper__user_modalinput"
                  placeholder="Email"
                  name="email"
                  value={data.email}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="add__shipper__user_modalrow">
              <div className="add__shipper__user_modalinputwrap">
                <label>Address</label>
                <input
                  className="add__shipper__user_modalinput"
                  placeholder="Address"
                  name="address"
                  type="text"
                  value={data.address}
                  onChange={handleChange}
                />
              </div>
              <div className="add__shipper__user_modalinputwrap">
                <label>Password</label>
                <input
                  className="add__shipper__user_modalinput"
                  placeholder="Password"
                  name="password"
                  type="password"
                  value={data.password}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="add__shipper__user_modalrow">
              <div className="add__shipper__user_modalinputwrap">
                <label>Country</label>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={Countries}
                  value={{ name: data.country } || null}
                  getOptionLabel={(option) => option.name}
                  sx={{
                    "&  div": {
                      maxHeight: "40px",
                      outline: "none",
                      padding: 0,
                      border: "none",
                      outline: "none",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Country"
                      className="add__shipper__user_modalinput"
                    />
                  )}
                  onChange={(e, country) =>
                    handleFieldChange("country", country.name)
                  }
                />
              </div>
              <div className="add__shipper__user_modalinputwrap">
                <label>Province</label>
                <input
                  className="add__shipper__user_modalinput"
                  placeholder="Province"
                  name="province"
                  value={data.province}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="add__shipper__user_modalrow">
              <div className="add__shipper__user_modalinputwrap">
                <label>City</label>
                <input
                  className="add__shipper__user_modalinput"
                  placeholder="City"
                  name="city"
                  value={data.city}
                  onChange={handleChange}
                />
              </div>
              <div className="add__shipper__user_modalinputwrap">
                <label>Postal/Zip Code</label>
                <input
                  className="add__shipper__user_modalinput"
                  placeholder="Postal/Zip Code"
                  name="zip"
                  value={data.zip}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="add__shipper__user_modalrow">
              <div className="add__shipper__user_modalinputwrap">
                <label>Phone Number</label>
                <input
                  className="add__shipper__user_modalinput"
                  placeholder="Phone Number"
                  name="phone_number"
                  type="number"
                  value={data.phone_number}
                  onChange={handleChange}
                />
              </div>
              <div className="add__shipper__user_modalinputwrap">
                <label>Role</label>
                <Autocomplete
                  // disablePortal
                  freeSolo
                  id="combo-box-demo"
                  // options={["Shipper", "Carrier"]}
                  options={roles.map((r) => r.name)}
                  getOptionLabel={(option) => option}
                  sx={{
                    "&  div": {
                      maxHeight: "40px",
                      outline: "none",
                      padding: 0,
                      border: "none",
                      outline: "none",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Role"
                      className="add__shipper__user_modalinput"
                    />
                  )}
                  value={data.role || null}
                  onChange={(e, role) => handleFieldChange("role", role)}
                />
              </div>
            </div>
          </div>
          <div className="add__shipper__user_modalfooter">
            <div className="add__shipper__user_modalcncl" onClick={handleClose}>
              Cancel
            </div>
            <div
              className="add__shipper__user_modalsave"
              onClick={handleCreateNew}
            >
              {loading ? <Loader /> : "Save"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
