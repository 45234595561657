import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import "./ImageModal.css";
import { MdClose } from "react-icons/md";
import noImage from "../../../../../../../assets/images/noImage.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImageModal({ open, setOpen }) {
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="image__modal_wrap payments_pending_transaction_list">
          <div className="image__modalclose_btn" onClick={handleClose}>
            <MdClose size={18} />
          </div>
          <img
            src={open || noImage}
            onError={(e) => {
              e.target.src = noImage;
            }}
          />
        </div>
      </Dialog>
    </div>
  );
}
