import IconButton from "@mui/material/IconButton";
import { MdCheck, MdNotificationsNone } from "react-icons/md";
import PlacesAutoComplete from "./PlacesAutoComplete";
import Collapses from "@mui/material/Collapse";
import countries from "./countries.json";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Autocomplete from "./AutoComplete";
import TextField from "./TextField";
import { geocodeByAddress } from "react-places-autocomplete";
import { setdata } from "../../../../../../helpers/helpers";
import Grid from "@mui/material/Grid";
import Tooltip from "../../../../../../Components/Tooltip/Tooltip";

export default function AddressForm(props) {
  const { List, SetList, StepCheck, SetStepCheck } = props;

  const [States, setStates] = useState();

  useEffect(() => {
    const GetStates = async () => {
      try {
        const response = await axios({
          method: "post",
          url: "https://countriesnow.space/api/v0.1/countries/states",
          data: {
            country: List["country"],
          },
        });
        setStates(response.data.data.states);
      } catch (error) {}
    };
    if (List["country"]) {
      GetStates();
    }
  }, [List["country"]]);

  // useEffect(() => {
  //   const lookupPostalCode = async () => {
  //     try {
  //       const response = await axios.get(
  //         `https://maps.googleapis.com/maps/api/geocode/json?address=${List["zip"]}&components=country:CA&key=AIzaSyBuUsrVtZzWrDbmOue1inXj7O2QoU0e3kU&libraries`
  //       );
  //       const { results } = response.data;
  //       if (results.length > 0) {
  //         const addressComponents = results[0].address_components;
  //         // console.log(addressComponents);
  //         const { long_name: state = "" } =
  //           addressComponents.find((c) =>
  //             c.types.includes("administrative_area_level_1")
  //           ) || {};
  //         const { long_name: county = "" } =
  //           addressComponents.find((c) => c.types.includes("country")) || {};

  //         const { short_name: Statecode = "" } =
  //           addressComponents.find((c) =>
  //             c.types.includes("administrative_area_level_1")
  //           ) || {};
  //         const { short_name: Countrycode = "" } =
  //           addressComponents.find((c) => c.types.includes("country")) || {};

  //         const { long_name: city1 = "" } =
  //           addressComponents.find((c) => c.types.includes("sublocality")) ||
  //           {};

  //         const { long_name: city2 = "" } =
  //           addressComponents.find((c) => c.types.includes("locality")) || {};
  //         SetList((prevList) => ({
  //           ...prevList,
  //           country: county,
  //           state: state,
  //           city: city1 ? city1 : city2,
  //           statecode: Statecode,
  //           countrycode: Countrycode,
  //         }));
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //       // Handle error, e.g., show an error message to the user
  //     }
  //   };

  //   if (List["zip"].length === 6) {
  //     lookupPostalCode();
  //   }
  // }, [List["zip"]]);

  useEffect(() => {
    function removeSpaces(str) {
      return str.replace(/\s/g, "");
    }
    SetList((prevList) => ({
      ...prevList,
      zip: removeSpaces(prevList["zip"]),
    }));
  }, [List["zip"]]);

  useEffect(() => {
    const formatPhoneNumber = (phoneNumber) => {
      const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
      return cleanedPhoneNumber;
    };
    SetList((prevList) => ({
      ...prevList,
      phone: formatPhoneNumber(prevList["phone"]),
    }));
  }, [List["phone"]]);

  const handleStepper = () => {
    if (List["type"] === "Flist") {
      SetStepCheck((prevStepCheck) => ({
        ...prevStepCheck,
        Pickup_Address_bit: 0,
        QuoteCheck: false,
      }));
    } else {
      SetStepCheck((prevStepCheck) => ({
        ...prevStepCheck,
        Delivery_Address_bit: 0,
        QuoteCheck: false,
      }));
    }
  };

  const handlecountry = (value) => {
    SetList({
      ...List,
      country: setdata(() => value, false, ""),
      countrycode: setdata(
        () => countries.filter((cn) => cn.label === value)[0].code,
        false,
        ""
      ),
    });
    handleStepper();
  };

  const handlestate = (value) => {
    SetList({
      ...List,
      state: setdata(() => value, false, ""),
      statecode: setdata(
        () => States.filter((cn) => cn.name === value)[0].state_code,
        false,
        ""
      ),
    });
    handleStepper();
  };

  const handlechange = (e) => {
    const { name, value } = e.target;
    handleStepper();
    SetList({ ...List, [name]: setdata(() => value, false, "") });
  };

  const handlenotification = () => {
    handleStepper();
    SetList({ ...List, notification: !List["notification"] });
  };

  const handleresidential = () => {
    handleStepper();
    SetList({ ...List, residential: !List["residential"] });
  };

  const handleaddressselect = async (address) => {
    const [results] = await geocodeByAddress(address);
    const { long_name: area = "" } =
      results.address_components.find((c) =>
        c.types.includes("locality", "political")
      ) || {};

    const { long_name: postalCode = "" } =
      results.address_components.find((c) => c.types.includes("postal_code")) ||
      {};
    const { long_name: State = "" } =
      results.address_components.find((c) =>
        c.types.includes("administrative_area_level_1")
      ) || {};
    const { long_name: Country = "" } =
      results.address_components.find((c) => c.types.includes("country")) || {};

    const { short_name: Statecode = "" } =
      results.address_components.find((c) =>
        c.types.includes("administrative_area_level_1")
      ) || {};
    const { short_name: Countrycode = "" } =
      results.address_components.find((c) => c.types.includes("country")) || {};

    const { long_name: City1 = "" } =
      results.address_components.find((c) => c.types.includes("sublocality")) ||
      {};

    const { long_name: City2 = "" } =
      results.address_components.find((c) => c.types.includes("locality")) ||
      {};

    handleStepper();
    SetList({
      ...List,
      address: setdata(() => address, false, ""),
      countrycode: setdata(() => Countrycode, false, ""),
      statecode: setdata(() => Statecode, false, ""),
      country: setdata(() => Country, false, ""),
      city: setdata(() => (City1 ? City1 : City2), false, ""),
      state: setdata(() => State, false, ""),
      zip: setdata(() => postalCode.replace(" ", ""), false, ""),
    });
  };

  const handleaddress = (value) => {
    SetList({ ...List, address: setdata(() => value, false, "") });
    handleStepper();
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="center" mb={2}>
        <Grid item xs={12}>
          <PlacesAutoComplete
            List={List}
            HandleAddressSelect={handleaddressselect}
            HandleAddress={handleaddress}
            HandleResidential={handleresidential}
            Color={List["residential"] === true ? "primary" : "default"}
            Required={StepCheck["fullform"]}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="center" mb={2}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            Option={countries.map((option) => option.label)}
            Handler={handlecountry}
            Value={List["country"]}
            Type="Country"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            Option={States ? States.map((option) => option.name) : []}
            Handler={handlestate}
            Value={List["state"]}
            Type="State"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="center" mb={2}>
        <Grid item xs={12} md={6}>
          <TextField
            Label="City/Town"
            Name="city"
            Data={List["city"]}
            Handler={handlechange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            Label="Zip/Postal"
            Name="zip"
            Data={List["zip"]}
            Handler={handlechange}
          />
        </Grid>
      </Grid>

      <Collapses in={StepCheck["fullform"]} timeout="auto" unmountOnExit>
        <br />
        <Grid container spacing={2} justifyContent="center" mb={2}>
          <Grid item xs={12} md={6}>
            <TextField
              Label="Company"
              Name="company"
              Data={List["company"]}
              Handler={handlechange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              Label="Attention"
              Name="attention"
              Data={List["attention"]}
              Handler={handlechange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} justifyContent="center" mb={2}>
          <Grid item xs={12} md={6}>
            <TextField
              Label="Phone"
              Name="phone"
              Data={List["phone"]}
              Handler={handlechange}
              Validation={{
                pattern: "^$|^\\d{10}$", // Exactly 10 digits or empty string
              }}
              Type="tel"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              Label="Email"
              Name="email"
              Data={List["email"]}
              Handler={handlechange}
              Type="email"
              Required={List["notification"]}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Email Notification">
                      <IconButton edge="end" onClick={handlenotification}>
                        {List["notification"] === true ? (
                          <MdCheck />
                        ) : (
                          <MdNotificationsNone />
                        )}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <br />

        <Grid container spacing={2} justifyContent="center" mb={2}>
          <Grid item xs={12} md={3} sx={{ paddingRight: "1px" }}>
            <TextField
              Label={
                List["type"] === "Flist"
                  ? "Pickup Time Open"
                  : "Delivery Time Open"
              }
              Name="otime"
              Data={List["otime"]}
              Handler={handlechange}
              Type="time"
              Required={false}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ paddingLeft: "1px" }}>
            <TextField
              Label={
                List["type"] === "Flist"
                  ? "Pickup Time Close"
                  : "Delivery Time Close"
              }
              Name="ctime"
              Data={List["ctime"]}
              Handler={handlechange}
              Type="time"
              Required={false}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              Label={
                List["type"] === "Flist"
                  ? "Pickup Date"
                  : "Preferred Delivery Date"
              }
              Name="date"
              Data={List["date"]}
              Handler={handlechange}
              Type="date"
              Required={List["type"] === "Flist" ? true : false}
              Validation={{
                min: new Date().toISOString().slice(0, 10),
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} justifyContent="center" mb={2}>
          <Grid item xs={12} md={6}>
            <TextField
              Label="Instructions"
              Name="instruction"
              Data={List["instruction"]}
              Handler={handlechange}
              Required={false}
              Multiline={true}
              Row={2}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              Label="Reference"
              Name="ref"
              Data={List["ref"]}
              Handler={handlechange}
              Required={false}
              Multiline={true}
              Row={2}
            />
          </Grid>
        </Grid>
      </Collapses>
    </>
  );
}
