import React, { useState, useEffect } from "react";
import "./taxes.css";
// apis imports
import { fetchShipperCalculatedTax } from "../../../../../../../services/ShipperServices/Payments/payments.services";

// library imports
import TextField from "../../../ShipPage/Components/TextField";
import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import Taxslip from "./TaxSlip/FullPageSlip";
import CircularLoader from "../../../../../../../Components/CircularLoader/CircularLoader";

const Taxes = ({ setSnack }) => {
  const [loader, setloader] = useState(false);
  const [data, setData] = useState(null);

  const [dates, setDates] = useState({
    from: "",
    to: "",
  });

  const handleChange = (e) => {
    setDates({ ...dates, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setloader(true);
    const saved = await fetchShipperCalculatedTax(dates);
    if (!saved.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: saved?.data?.message,
          severity: "success",
        };
      });
      setData(saved?.data?.result);
      // setDates({
      //   from: "",
      //   to: "",
      // });
      setloader(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: saved?.error?.response?.data?.message,
          severity: "error",
        };
      });
      setloader(false);
    }
  };
  return (
    <div className="taxes__slip_details_wrap">
      <div>
        <div className="payment__invoices_header">
          <div>
            <span className="payment__invoices_heading tax__page_title">Taxes Details</span>
          </div>
        </div>

        <Row className="mx-auto mt-3">
          <Col md={11} className="mx-auto">
            <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
              <b style={{ textAlign: "center" }} className="tax__form_heading">
                Select Interval For Tax Slip
              </b>
            </Row>
            <Form onSubmit={handleSubmit} className="form__tax_slip_wrap">
              <Row  className="to_from_tax_slip_date">
                <TextField
                  Label={"Start Date"}
                  Name="from"
                  Data={dates.from}
                  Handler={handleChange}
                  Width="40%"
                  Type="date"
                  Required={true}
                />
                <TextField
                  Label={"End Date"}
                  Name="to"
                  Data={dates.to}
                  Handler={handleChange}
                  Width="40%"
                  Type="date"
                  Required={true}
                />
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  disabled={loader}
                >
                  {loader ? <CircularLoader /> : "Get Taxes"}
                </Button>
              </Row>
            </Form>
            <Row>
              <Taxslip data={data} setdata={setData} dates={dates} />
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Taxes;
