import React, { useState, useEffect } from "react";
import "./currency.css";

// components imports
import CurrenciesTable from "../../../../../Components/BoltTable/BoltTable";
import AddCurrency from "./Components/AddCurrency/AddCurrency";
import Confirm from "../../../../../Components/Popups/Confirm";
import Tooltip from "../../../../../Components/Tooltip/Tooltip";
// apis imports
import {
  deleteCurrency,
  getCurrenciesList,
} from "../../../../../services/admin.services";
// library imports
import { FcPlus } from "react-icons/fc";
import { MdOutlineCancel } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import EditCurrency from "./Components/EditCurrency/EditCurrency";

const CurrencyStock = ({ setSnack }) => {
  const [open, setOpen] = useState(false);
  const [editCurrencyOpen, setEditCurrencyOpen] = useState(false);
  const [loader, setloader] = useState(false);

  const [transferCurrencies, setTransferCurrencies] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [refresh, setRefresh] = useState();

  const columns = [
    {
      id: 1,
      label: "Code",
      render: (row) => <>{row?.code ?? "-"}</>,
      width: "200px",
    },
    {
      id: 2,
      label: "Name",
      render: (row) => <>{row?.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Rate",
      render: (row) => <>{row?.rate ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Symbol",
      render: (row) => <>{row?.symbol ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "Actions",
      render: (row) => (
        <>
          <div className="table_action__wrap">
            <Tooltip title="Edit User Details">
              <div
                className="table_action__btn"
                onClick={() => {
                  setEditCurrencyOpen(row);
                }}
              >
                <FaRegEdit size={10} />
                Edit
              </div>
            </Tooltip>

            <Tooltip title="Delete User">
              <div
                className="table_action__btn"
                onClick={() => setConfirmDelete(row)}
              >
                <MdOutlineCancel size={10} />
                Delete
              </div>
            </Tooltip>
          </div>
        </>
      ),
      width: "200px",
    },
  ];

  const fetchCurrenciesList = async (page, perPage, filter) => {
    const response = await getCurrenciesList(page, perPage, filter);
    if (!response.error) {
      setTransferCurrencies(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const handleDeleteRequest = async (data) => {
    if (data.id) {
      setloader(true);
      const response = await deleteCurrency(data.id);
      setConfirmDelete(false);
      if (!response.error && response?.data?.success) {
        setTransferCurrencies((prevTransferCurrencies) => {
          return {
            ...prevTransferCurrencies,
            data: prevTransferCurrencies?.data?.filter(
              (d) => d?.id != data?.id
            ),
          };
        });
        setloader(false);
        setConfirmDelete(false);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Request deleted successfully",
            severity: "success",
          };
        });
      } else {
        setloader(false);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Couldn't cancel this request!",
            severity: "error",
          };
        });
      }
    }
  };

  return (
    <>
      <Confirm
        open={confirmDelete}
        setOpen={setConfirmDelete}
        handleConfirm={() => handleDeleteRequest(confirmDelete)}
        text="Do You Want To Delete This Request ?"
        loader={loader}
      />

      <div className="products__adpage_header">
        <div>
          <span className="products__adpage_hdrheading">Currency List</span>
        </div>
        <div className="products__adpage_buttonsWrapper">
          <div onClick={() => setOpen(true)}>
            <div className="products__adpage_hdraddprod">
              <FcPlus size={20} />
              <span>ADD CURRENCY</span>
            </div>
          </div>
        </div>
      </div>
      <div className="currencyStock__main_wrap">
        <div className="currencyStock__table_list">
          <div>
            <CurrenciesTable
              message={"No Currencies Available To Display!"}
              searchPlaceHolder="Search Currencies"
              columns={columns}
              data={transferCurrencies}
              getAllData={fetchCurrenciesList}
              refresh={refresh}
            />
          </div>
        </div>
      </div>
      <AddCurrency
        open={open}
        setOpen={setOpen}
        setSnack={setSnack}
        setRefresh={setRefresh}
      />
      <EditCurrency
        open={editCurrencyOpen}
        setOpen={setEditCurrencyOpen}
        setSnack={setSnack}
        setTransferCurrencies={setTransferCurrencies}
      />
    </>
  );
};

export default CurrencyStock;
