import { Outlet, Navigate } from "react-router-dom";
import NavBar from "../Navbar/CarrierNavbar";

const CarrierRoutes = () => {
  let role = localStorage.getItem("Role");
  if (role === "Shipper") {
    return <Navigate to="/" />;
  } else if (role === "Carrier") {
    return (
      <>
        <NavBar />
        <Outlet />
      </>
    );
  } else if (role === "Admin") {
    return <Navigate to="/Users" />;
  } else if (role === "warehouseUser") {
    return <Navigate to="/warehouse-orders" />;
  } else {
    return <Navigate to="/Login" />;
  }
};
export default CarrierRoutes;
