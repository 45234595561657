const Colours = [
    "rgb(255, 107, 107)",
    "rgb(29, 224, 133)",
    "rgb(13, 192, 232)",
    "rgb(255, 196, 57)",
    "rgb(230, 215, 128)",
    "rgb(16, 134, 147)",
    "rgb(111, 124, 151)",
    "rgb(149, 114, 224)",
    "rgb(61, 170, 141)",
    "rgb(209, 162, 102)",
    "rgb(79, 108, 168)",
    "rgb(232, 62, 140)",
    "rgb(206, 132, 15)",
    "rgb(14, 74, 178)",
    "rgb(178, 44, 104)"
]

export default Colours