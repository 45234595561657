import ExcelJS from "exceljs";
import { marketplacesFormat } from "../../../helpers/helpers";

export const generateExcelFile = async (Products, selectedRows, setSnack) => {
  try {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Products");

    const flattenedProductArray = await flattenProducts(Products, selectedRows);

    if (flattenedProductArray.length === 0) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Select Products to export.",
          severity: "error",
        };
      });
      return;
    }

    // const productMarkets = {};

    // if (MarketPlaces) {
    //   MarketPlaces.forEach((marketplace) => {
    //     const marketName = marketplacesFormat(marketplace.market);
    //     productMarkets[marketName + " SKU"] = "";
    //   });
    // }

    // let supplierCount = 1;

    // const productSuppliers = {};

    // if (Suppliers) {
    //   Suppliers.forEach((supplier) => {
    //     const supplierKey = "Supplier " + supplierCount;

    //     productSuppliers[supplierKey] = {
    //       Name: supplier?.name,
    //       Cost: "",
    //     };

    //     supplierCount++;
    //   });
    // }

    // let werehouseCount = 1;

    // const productWerehouse = {};

    // if (user_approved_warehouses) {
    //   user_approved_warehouses.forEach((warehouse) => {
    //     const supplierKey = "Warehouse " + werehouseCount;

    //     productWerehouse[supplierKey] = {
    //       Name: warehouse?.warehouse?.code + "-" + warehouse?.warehouse?.name,
    //       Quantity: "",
    //       Location: "",
    //     };
    //     werehouseCount++;
    //   });
    // }

    const data = flattenedProductArray.map((product) => {
      // const updatedProductSkus = deepClone(productMarkets);

      // if (product.product_skus && product.product_skus.length > 0) {
      //   product.product_skus.forEach((sku) => {
      //     const marketName = marketplacesFormat(sku?.market_place?.market);
      //     const matchingSku = updatedProductSkus[marketName + " SKU"];

      //     // If a matching SKU is found, update its properties
      //     if (matchingSku !== undefined) {
      //       updatedProductSkus[marketName + " SKU"] = sku?.sku;
      //     }
      //   });
      // }

      let additionalCount = 1;
      const updatedProductImage = {};

      if (product.media) {
        let primaryImageFound = false; // Flag to track if a primary image is found

        product.media.forEach((img) => {
          const imageKey =
            img?.is_primary === "true" ||
            (!primaryImageFound && additionalCount === 1)
              ? "Primary Image Link"
              : `Additional Image Link ${additionalCount}`;

          const imageUrl = img?.file;
          updatedProductImage[imageKey] = imageUrl;

          if (img?.is_primary === "true") {
            primaryImageFound = true; // Set the flag to true if a primary image is found
          } else {
            additionalCount++;
          }
        });
      }

      // const updatedProductSupplier = deepClone(productSuppliers);

      // if (product?.product_suppliers && product?.product_suppliers.length > 0) {
      //   product.product_suppliers.forEach((supplier) => {
      //     const matchingSupplier = Object.values(updatedProductSupplier).find(
      //       (s) => s.Name === supplier?.suppliers?.name
      //     );

      //     if (matchingSupplier) {
      //       matchingSupplier.Cost = supplier?.cost;
      //     }
      //   });
      // }

      // const updatedProductSku = deepClone(productMarkets);

      // if (product?.product_suppliers && product?.product_suppliers.length > 0) {
      //   product.product_suppliers.forEach((supplier) => {
      //     const matchingSupplier = Object.values(updatedProductSku).find(
      //       (s) => s.Name === supplier?.suppliers?.name
      //     );

      //     if (matchingSupplier) {
      //       matchingSupplier.Cost = supplier?.cost;
      //     }
      //   });
      // }

      // const updatedProductWerehouse = deepClone(productWerehouse);

      // if (product?.product_warehouse && product?.product_warehouse.length > 0) {
      //   product.product_warehouse.forEach((warehouse) => {
      //     const matchingWarehouse = Object.values(updatedProductWerehouse).find(
      //       (w) =>
      //         w.Name ===
      //         warehouse?.warehouse?.code + "-" + warehouse?.warehouse?.name
      //     );

      //     if (matchingWarehouse) {
      //       matchingWarehouse.Quantity = warehouse?.quantity;
      //     }
      //   });
      // }

      return {
        "Warehouse Clients": {
          Clients: product?.shipper?.username ?? "",
          Quantity: product?.product_warehouse[0]["quantity"],
          Location: product?.product_warehouse[0]["location"],
        },
        "Unique Identifier": {
          "Product ID": product?.code ?? "",
          "Parent ID": product?.parent_id ?? "",
        },
        "General Information": {
          Brand: product?.brand?.name ?? "",
          "Product Name": product?.name ?? "",
          Description: product?.description ?? "",
          "Sale Unit": product?.unit?.code ?? "",
          "Alert Quantity": product?.alert_quantity ?? "",
          "Fulfillment Cost": product?.fulfillment_cost ?? "",
        },
        // "Product SKU": updatedProductSkus,
        Category: {
          "Main Category": product?.category?.name ?? "",
          "Sub Category": product?.subcategory?.name ?? "",
        },
        Dimensions: {
          Length: product?.length ?? "",
          Width: product?.width ?? "",
          Height: product?.height ?? "",
          Unit: product?.dimension_unit ?? "",
        },
        Weight: {
          Weight: product?.weight ?? "",
          Unit: product?.weight_unit ?? "",
        },
        // Price: {
        //   "Sale Price": product?.sale_price ?? "",
        //   "Regular Price": product?.regular_price ?? "",
        //   Currency: product?.currency ?? "",
        // },
        Images: updatedProductImage,
        // ...updatedProductSupplier,
        // ...updatedProductWerehouse,
      };
    });

    // console.log(data);
    // return;

    // Determine headers and subheaders dynamically
    const headers = Object.keys(data[0]);
    const subheaders = headers.map((header) => Object.keys(data[0][header]));

    // Set styles for headers and subheaders
    const headerStyle = {
      font: { bold: true, color: { argb: "FFFFFF" } },
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "3498db" } },
      alignment: { horizontal: "center", vertical: "middle" },
      border: {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      },
    };

    const subheaderStyle = {
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "2ecc71" } },
      alignment: { horizontal: "center", vertical: "middle", wrapText: true },
      border: {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      },
    };

    const DescriptionStyle = {
      font: { color: { argb: "808080" }, size: 10 },
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFFFFF" } },
      alignment: { horizontal: "left", vertical: "top" },
      style: {
        whiteSpace: "pre-wrap",
        wordWrap: "break-word",
      },
      border: {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      },
    };

    // Write headers and subheaders to worksheet
    let columnIndex = 1;
    headers.forEach((header, index) => {
      const subheaderCount = subheaders[index].length;
      if (subheaderCount > 1) {
        worksheet.mergeCells(
          1,
          columnIndex,
          1,
          columnIndex + subheaderCount - 1
        );
      }
      worksheet.getCell(1, columnIndex).value = header;
      worksheet.getCell(1, columnIndex).style = headerStyle;

      subheaders[index].forEach((subheader, subheaderIndex) => {
        const description = getDescriptionForSubheader(header, subheader);
        worksheet.getCell(2, columnIndex + subheaderIndex).value = subheader;
        worksheet.getCell(3, columnIndex + subheaderIndex).value = description;
        worksheet.getCell(2, columnIndex + subheaderIndex).style =
          subheaderStyle;
        worksheet.getCell(3, columnIndex + subheaderIndex).style =
          DescriptionStyle;
      });

      columnIndex += subheaderCount;
    });

    // Write data to worksheet
    data.forEach((item, rowIndex) => {
      let columnIndex = 1;
      headers.forEach((header, headerIndex) => {
        subheaders[headerIndex].forEach((subheader) => {
          const cellValue = item[header][subheader];
          const cell = worksheet.getCell(rowIndex + 4, columnIndex);
          cell.value = cellValue;
          cell.alignment = {
            horizontal: "center",
            vertical: "middle",
            wrapText: true,
          };

          // Set the width of the column (adjust the width value as needed)
          const column = worksheet.getColumn(columnIndex);
          column.width = subheader.length + 2; // Set the width to your desired value

          columnIndex++;
        });
      });
    });

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "products.xlsx";
    a.click();
  } catch (error) {
    console.log(error);
    setSnack((snack) => {
      return {
        ...snack,
        open: true,
        text: "An error occurred while generating the Excel file",
        severity: "error",
      };
    });
  }
};

async function flattenProducts(products, selectedRows) {
  const filteredProducts = products.filter((product) =>
    selectedRows.includes(product.id)
  );

  // const flattenedProducts = [];

  // async function flattenProduct(product, parentId) {
  //   if (!showVariants) {
  //     flattenedProducts.push({ ...product, parent_id: parentId });
  //     return;
  //   }

  //   const flattenedProduct = { ...product, parent_id: parentId };
  //   delete flattenedProduct.children;
  //   flattenedProducts.push(flattenedProduct);

  //   if (product.children && product.children.length > 0) {
  //     for (const child of product.children) {
  //       await flattenProduct(child, product.code); // Use product.code as parentId
  //     }
  //   }
  // }

  // for (const product of filteredProducts) {
  //   await flattenProduct(product, null);
  // }

  return filteredProducts;
}

const getDescriptionForSubheader = (header, subheader) => {
  //Unique Identifier
  if (header === "Unique Identifier" && subheader === "Product ID") {
    return "Alphanumeric, 50 characters - The string of  unique letters and/or numbers to identify the product. Example: BARBELL-RACK-AN03";
  }
  if (header === "Unique Identifier" && subheader === "Parent ID") {
    return "Empty if this product is not a variation of other product. Alphanumeric, 50 characters - Product Id of the Parent Product to identify this product as variant of that Product . Example: BARBELL-RACK-AN03 ";
  }
  //Unique Identifier

  //General Information
  if (header === "General Information" && subheader === "Brand") {
    return "Alphanumeric, 50 characters - The string of  letters and/or numbers for the Brand Where the Product Belongs. Example: Bolt";
  }
  if (header === "General Information" && subheader === "Product Name") {
    return "Alphanumeric, 50 characters - The string of  letters and/or numbers for the name. Example: 4-levels Barbell Rack";
  }
  if (header === "General Information" && subheader === "Description") {
    return "Alphanumeric, 4000 characters - Contains the description of your product.";
  }
  if (header === "General Information" && subheader === "Sale Unit") {
    return "Alphanumeric, 2 characters - which states the Sale unit code of product. Example: Pc";
  }
  if (header === "General Information" && subheader === "Alert Quantity") {
    return "Alphanumeric, 50 characters - The string of  numbers to define when to notify if the quantity is below the given number. Example: 50";
  }
  if (header === "General Information" && subheader === "Fulfillment Cost") {
    return "Alphanumeric, 50 characters - The string of  numbers to define the Fulfillment cost of the product if any otherwise empty. Example: 25";
  }
  //General Information

  //Product SKU
  if (header === "Product SKU") {
    return "Alphanumeric, 50 characters - The string included the SKU from the MarketPlace You added in the name after SKU. Example For Name: SKU Amazon , Example SKU: BARBELL-RACK-AN03";
  }
  //Product SKU

  //Category
  if (header === "Category" && subheader === "Main Category") {
    return "Alphanumeric, 50 characters - The string of  letters and/or numbers set product to a Category. Example: Sports";
  }
  if (header === "Category" && subheader === "Sub Category") {
    return "Alphanumeric, 50 characters - The string of  letters and/or numbers to set product to a Sub Category in the Main Category. Example: Bats";
  }
  //Category

  //Dimensions
  if (header === "Dimensions" && subheader === "Length") {
    return "Alphanumeric, 50 characters - The string of  numbers to define the product Length. Example: 20";
  }
  if (header === "Dimensions" && subheader === "Width") {
    return "Alphanumeric, 50 characters - The string of  numbers to define the product Width. Example: 20";
  }
  if (header === "Dimensions" && subheader === "Height") {
    return "Alphanumeric, 50 characters - The string of  numbers to define the product Height. Example: 20";
  }
  if (header === "Dimensions" && subheader === "Unit") {
    return "Alphanumeric, 2 characters - which states the unit of dimensions of product in Inch/Cm. Example: Cm";
  }
  //Dimensions

  //Weight
  if (header === "Weight" && subheader === "Weight") {
    return "Alphanumeric, 50 characters - The string of  numbers to define the product Weight. Example: 20";
  }
  if (header === "Weight" && subheader === "Unit") {
    return "Alphanumeric, 2 characters - which states the unit of dimensions of product in Kg/Lb. Example: Lb";
  }
  //Weight

  //Price
  if (header === "Price" && subheader === "Sale Price") {
    return "Alphanumeric, 50 characters - The string of  numbers to define the Sale price of product if any otherwise empty. Example: 250";
  }
  if (header === "Price" && subheader === "Regular Price") {
    return "Alphanumeric, 50 characters - The string of  numbers to define the Regular price of product if any otherwise empty. Example: 200";
  }
  if (header === "Price" && subheader === "Currency") {
    return "Alphanumeric, 50 characters - which states the Currency of price. Example: CAD";
  }
  //Price

  //Images
  if (header === "Images") {
    return "URL, 2000 characters - Main image of the item. File Format: JPEG (.jpg). Image URLs should end in an image file type (.jpg) to follow best practices. They must link to a public image file that loads as an image";
  }
  //Images

  return "Description not available"; // Default description if not specified
};

function deepClone(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    const newArray = [];
    for (let i = 0; i < obj.length; i++) {
      newArray[i] = deepClone(obj[i]);
    }
    return newArray;
  }

  const newObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObj[key] = deepClone(obj[key]);
    }
  }

  return newObj;
}
