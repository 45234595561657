import React, { useEffect, useState } from "react";
import "./userformula.css";

// components imports
import Confirm from "../../../../../Components/Popups/Confirm";
import Loader from "../../../../../Components/CircularLoader/CircularLoader";
import CarrierInnerTableLoader from "../../../../../Components/CarrierInnerTableLoader/CarrierInnerTableLoader";
// apis imports
import {
  createUserPaymentFormula,
  deleteUserPaymentFormula,
  updateUserPaymentFormula,
  getUserPaymentFormula,
} from "../../../../../services/AdminServices/PaymentsAdmin/payments.services";
// library imports
import TextFeild from "../../Components/CustomTextFeild/TextFeild";
import Form from "react-bootstrap/Form";
import { MdClose } from "react-icons/md";
import { MdAdd } from "react-icons/md";
import Sad from "@mui/icons-material/SentimentVeryDissatisfied";
import AddDetail from "./AddDetail";
import IconButton from "@mui/material/IconButton";
import { FaRegTimesCircle } from "react-icons/fa";

const UserFormula = ({ setSnack, user, setOpen, updateIndex }) => {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  //Admin formula
  const [lastDetailedIndexData, setLastDetailedIndexData] = useState(null);

  const [confirmDelete, setConfirmDelete] = useState();
  const [isEditable, setIsEditable] = useState(false);
  const [userDefaultFormula, setUserDefaultFormula] = useState(null);
  const [formulaDetails, setFormulaDetails] = useState({ min: "", max: "" });
  const [paymentFormula, setPaymentFormula] = useState(null);
  const [error, setError] = useState(false);

  const changeSwitchValue = (value) => {
    setPaymentFormula({ ...paymentFormula, type: value });
  };
  const addMore = (key, val) => {
    setPaymentFormula({
      ...paymentFormula,
      [key]: [...paymentFormula[key], val],
    });
  };

  const handleRemove = (key, id, removedKey, index) => {
    if (index === 0 && key === "formula_taxes") {
      setPaymentFormula({
        ...paymentFormula,
        formula_taxes: [
          { ...paymentFormula.formula_taxes[0], name: "", value: "" },
        ],
      });
      return;
    }
    if (
      index === 0 &&
      key === "formula_details" &&
      paymentFormula.formula_details[0].id
    ) {
      setLastDetailedIndexData(paymentFormula.formula_details[0]);
    }
    setPaymentFormula((prevState) => ({
      ...prevState,
      [key]: paymentFormula[key].filter((_, i) => i !== index),
      [removedKey]:
        id && index != 0
          ? [...paymentFormula[removedKey], id]
          : paymentFormula[removedKey],
    }));
  };

  const handleFieldChange = (key, name, e, index) => {
    const newState = { ...paymentFormula };
    newState[key][index][name] = e.target.value;
    setPaymentFormula(newState);
  };
  const createFormula = () => {
    setIsEditable(true);
    setPaymentFormula({
      type: "by_shipment",
      formula_details: [],
      formula_taxes: [],
      deletedDetailsIds: [],
      deletedTaxesIds: [],
    });
  };
  const handleSave = async (e) => {
    e.preventDefault();

    if (paymentFormula?.formula_taxes?.length === 0) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please add atleast 1 formula tax detail",
          severity: "error",
        };
      });
      return;
    }
    if (paymentFormula?.formula_details?.length === 0) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please add atleast 1 formula detail",
          severity: "error",
        };
      });
      return;
    }
    setLoading2(true);
    let response = await createUserPaymentFormula(
      {
        type: paymentFormula.type,
        formulaDetails: paymentFormula?.formula_details,
        formulaTaxes: paymentFormula?.formula_taxes,
      },
      user?.id
    );
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.error?.response?.data?.message,
          severity: "error",
        };
      });
      setLoading2(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.data?.message,
          severity: "success",
        };
      });
      setIsEditable(false);
      setPaymentFormula({
        ...response?.data?.result,
        deletedDetailsIds: [],
        deletedTaxesIds: [],
      });
      if (user.formula != null) {
        setUserDefaultFormula({
          ...response?.data?.result,
          deletedDetailsIds: [],
          deletedTaxesIds: [],
        });
      }
      if (user) {
        // setOpen(false);
        updateIndex(
          user?.id,
          {
            ...response?.data?.result,
            deletedDetailsIds: [],
            deletedTaxesIds: [],
          },
          "formula"
        );
      }
    }
    setLoading2(false);
  };
  const handleDeleteFormula = async (e) => {
    setLoading2(true);
    let response = await deleteUserPaymentFormula(paymentFormula?.id);
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.error?.response?.data?.message,
          severity: "error",
        };
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.data?.message,
          severity: "success",
        };
      });
      if (user) {
        // setOpen(false);
        setPaymentFormula(
          userDefaultFormula
          // deletedDetailsIds: [],
          // deletedTaxesIds: [],
        );
        updateIndex(user.id, null, "formula");
      }
    }
    setLoading2(false);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (paymentFormula?.formula_taxes?.length === 0) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please add atleast 1 formula tax detail",
          severity: "error",
        };
      });
      return;
    }
    if (paymentFormula?.formula_details?.length === 0) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please add atleast 1 formula detail",
          severity: "error",
        };
      });
      return;
    }
    setLoading2(true);
    let response = await updateUserPaymentFormula(
      {
        type: paymentFormula.type,
        formulaDetails: paymentFormula?.formula_details,
        formulaTaxes: paymentFormula?.formula_taxes,
        deletedDetailsIds: paymentFormula?.deletedDetailsIds,
        deletedTaxesIds: paymentFormula?.deletedTaxesIds,
      },
      paymentFormula?.id
    );
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.error?.response?.data?.message,
          severity: "error",
        };
      });
      setLoading2(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.data?.message,
          severity: "success",
        };
      });
      setPaymentFormula({
        ...paymentFormula,
        deletedDetailsIds: [],
        deletedTaxesIds: [],
      });
      setIsEditable(false);
      if (user) {
        // setOpen(false);
        updateIndex(user?.id, response?.data?.result);
      }
    }
    setFormulaDetails({
      ...formulaDetails,
      deletedDetailsIds: [],
      deletedTaxesIds: [],
    });
    setLoading2(false);
  };
  const handleClose = () => {
    if (user) {
      if (user.formula === null) {
        setPaymentFormula(userDefaultFormula);
        setIsEditable(false);
      } else {
        setIsEditable(false);
        setPaymentFormula({
          ...user.formula,
          deletedDetailsIds: [],
          deletedTaxesIds: [],
        });
      }
    } else {
      setPaymentFormula(userDefaultFormula);
      setIsEditable(false);
    }
  };

  const getUserPaymentFormulaData = async (setOnlyDefault) => {
    setLoading(true);
    let response = await getUserPaymentFormula();
    let result =
      response?.data?.result != null
        ? {
            ...response?.data.result,
            deletedDetailsIds: [],
            deletedTaxesIds: [],
          }
        : response?.data?.result;
    if (!setOnlyDefault) {
      setPaymentFormula(result);
    }
    setUserDefaultFormula(result);
    if (response) {
      setLoading(false);
    }
  };

  const addDetail = (e) => {
    e.preventDefault();
    if (formulaDetails?.min === "" || formulaDetails?.max === "") {
      setError("Please fill all feilds");
      return;
    }
    if (
      Number(formulaDetails?.min) <
      Number(
        paymentFormula?.formula_details[
          paymentFormula?.formula_details?.length - 1
        ]?.max
      )
    ) {
      setError("min must be grateer then last max");
      return;
    }
    if (Number(formulaDetails.max) <= Number(formulaDetails.min)) {
      setError("max must be grater then min");
      return;
    }
    let value;

    if (lastDetailedIndexData != null) {
      // value = { ...formulaDetails, value: "10" };
      if ("formula_details" === "formula_details") {
        value = {
          ...formulaDetails,
          value: "10",
          id: lastDetailedIndexData.id,
          formula_id: lastDetailedIndexData.formula_id,
        };
      }
      setLastDetailedIndexData(null);
    } else {
      if ("formula_details" === "formula_details") {
        value = { ...formulaDetails, value: "10" };
      }
    }
    setPaymentFormula({
      ...paymentFormula,
      ["formula_details"]: [...paymentFormula["formula_details"], value],
    });
  };
  useEffect(() => {
    if (paymentFormula?.formula_details) {
      let nextMIN =
        Number(
          paymentFormula?.formula_details[
            paymentFormula?.formula_details?.length - 1
          ]?.max
        ) + 1;
      let nextMAX =
        nextMIN +
        Number(
          paymentFormula?.formula_details[
            paymentFormula?.formula_details?.length - 1
          ]?.max
        ) -
        Number(
          paymentFormula?.formula_details[
            paymentFormula?.formula_details?.length - 1
          ]?.min
        );
      setFormulaDetails({
        ...formulaDetails,
        min: nextMIN ? nextMIN : "1",
        max: nextMAX ? nextMAX : "50",
      });
    }
  }, [paymentFormula?.formula_details, isEditable]);

  const handleChangeDetails = (e) => {
    setError(false);
    setFormulaDetails({ ...formulaDetails, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (user) {
      if (user?.formula != null) {
        getUserPaymentFormulaData(true);
        setLoading(true);
        setPaymentFormula({
          ...user?.formula,
          deletedDetailsIds: [],
          deletedTaxesIds: [],
        });
        const intervalId = setInterval(() => {
          // Update the count every second
          setLoading(false);
        }, 1000);
        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
      } else {
        getUserPaymentFormulaData(false);
      }
    } else {
      getUserPaymentFormulaData(false);
    }
  }, []);
  return (
    <>
      {loading ? (
        <div style={{ position: "relative", minHeight: "300px" }}>
          <div className="custom__table_tableloading">
            <CarrierInnerTableLoader />
          </div>
        </div>
      ) : (
        <>
          {user && (
            <div style={{ position: "relative" }}>
              <div className="admnedit__user_modalheading">
                {paymentFormula === null ||
                paymentFormula?.is_default === "true"
                  ? "Default Formula"
                  : "Custom Formula"}
              </div>
              <div
                className="aduser__details_modalclose"
                onClick={() => setOpen(false)}
              >
                <IconButton>
                  <FaRegTimesCircle size={25} color="lightgrey" />
                </IconButton>
              </div>
            </div>
          )}
          {/* {!user && (
            <div className="admnedit__user_modalheading">
              {user?.formula ? "Custom Formula" : "Default Formula"}
            </div>
          )} */}
          <br />
          {user && (
            <div className="payment__formula_btn_wrapper">
              {(paymentFormula?.is_default === "true" ||
                paymentFormula === null) && (
                <div>
                  <button
                    className="payment__formula_btn"
                    onClick={() => createFormula()}
                  >
                    <MdAdd className="payment__formula_btnicon" size={18} />
                    CREATE CUSTOM FORMULA
                  </button>
                </div>
              )}
              {isEditable === false &&
                paymentFormula?.is_default === "false" && (
                  <div>
                    <button
                      className="payment__formula_btn"
                      onClick={() => setIsEditable(true)}
                    >
                      <MdAdd className="payment__formula_btnicon" size={18} />
                      UPDATE FORMULA
                    </button>
                  </div>
                )}
            </div>
          )}
          {!user && (
            <div className="payment__formula_btn_wrapper">
              {paymentFormula == null && (
                <div>
                  <button
                    className="payment__formula_btn"
                    onClick={() => createFormula()}
                  >
                    <MdAdd className="payment__formula_btnicon" size={18} />
                    CREATE FORMULA
                  </button>
                </div>
              )}

              {isEditable === false && paymentFormula != null && (
                <div>
                  <button
                    className="payment__formula_btn"
                    onClick={() => setIsEditable(true)}
                  >
                    <MdAdd className="payment__formula_btnicon" size={18} />
                    UPDATE FORMULA
                  </button>
                </div>
              )}
            </div>
          )}
          {paymentFormula === null && (
            <div className="driver__screen_empty" style={{ width: "auto" }}>
              <div className="driver__scrnempt_content">
                <Sad className="driver__scrnempt_contenticon" />
                <div>No Default Formula available to display!</div>
              </div>
            </div>
          )}
          {paymentFormula != null && (
            <>
              <Form onSubmit={paymentFormula?.id ? handleUpdate : handleSave}>
                <div className="payment__formula_switch_wrapper">
                  <div
                    onClick={() =>
                      isEditable ? changeSwitchValue("by_shipment") : null
                    }
                    className={
                      paymentFormula?.type === "by_shipment"
                        ? "payment__formula_switch_btn_active"
                        : "payment__formula_switch_btn"
                    }
                  >
                    By Shipment
                  </div>
                  <div
                    onClick={() =>
                      isEditable ? changeSwitchValue("by_payment") : null
                    }
                    className={
                      paymentFormula?.type === "by_payment"
                        ? "payment__formula_switch_btn_active"
                        : "payment__formula_switch_btn"
                    }
                  >
                    By Payment
                  </div>
                </div>
                {paymentFormula != null && (
                  <div className="payment__formula_box_wrapper">
                    <div className="payment__formula_formbox1">
                      {paymentFormula != null && (
                        <h5 className="payment__formula_formboxheading">
                          <div>Formula Details</div>
                        </h5>
                      )}
                      <AddDetail
                        addDetail={addDetail}
                        isEditable={isEditable}
                        formulaDetails={formulaDetails}
                        paymentFormula={paymentFormula}
                        handleChangeDetails={handleChangeDetails}
                        error={error}
                      />
                      <div className="customer_formula_value_table">
                        <table id="customers" className="w-50">
                          <tr>
                            <th className="cell__width">
                              Min ({" "}
                              {paymentFormula?.type === "by_shipment"
                                ? "Cn"
                                : "$"}{" "}
                              )
                            </th>
                            <th className="cell__width">
                              Max({" "}
                              {paymentFormula?.type === "by_shipment"
                                ? "Cn"
                                : "$"}{" "}
                              )
                            </th>
                            <th className="cell__width">
                              Value({" "}
                              {paymentFormula?.type === "by_shipment"
                                ? "Cn"
                                : "$"}{" "}
                              )
                            </th>
                            <th className="cell__width">Action</th>
                          </tr>
                          {paymentFormula?.formula_details?.map(
                            (detail, index) => (
                              <tr>
                                <td>{detail.min}</td>
                                <td>
                                  {index ===
                                  paymentFormula?.formula_details?.length -
                                    1 ? (
                                    <span style={{ fontSize: "30px" }}>∞</span>
                                  ) : (
                                    detail.max
                                  )}
                                </td>
                                <td>
                                  <TextFeild
                                    Data={detail.value}
                                    Disabled={isEditable ? false : true}
                                    Handler={(e) =>
                                      handleFieldChange(
                                        "formula_details",
                                        "value",
                                        e,
                                        index
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  {paymentFormula?.formula_details?.length -
                                    1 ===
                                    index &&
                                    isEditable && (
                                      <div className="addprod__seccancel_btnwrap">
                                        <button
                                          className="payment__formula_cancel_btn"
                                          onClick={(e) => {
                                            handleRemove(
                                              "formula_details",
                                              detail?.id,
                                              "deletedDetailsIds",
                                              index
                                            );
                                          }}
                                        >
                                          <MdClose size={22} />
                                        </button>
                                      </div>
                                    )}
                                </td>
                              </tr>
                            )
                          )}
                        </table>
                      </div>
                    </div>
                    <div className="payment__formula_formbox2">
                      {paymentFormula != null && (
                        <h5 className="payment__formula_formboxheading">
                          <div>Formula Taxes</div>
                        </h5>
                      )}
                      {paymentFormula?.formula_taxes?.map((tax, index) => (
                        <div className="payment__formula_formrow" key={index}>
                          <div className="payment__formula_formcol">
                            <TextFeild
                              Label="Tax Name"
                              Data={tax.name}
                              Type="text"
                              Disabled={isEditable ? false : true}
                              Adroment={""}
                              Handler={(e) =>
                                handleFieldChange(
                                  "formula_taxes",
                                  "name",
                                  e,
                                  index
                                )
                              }
                            />
                          </div>
                          <div className="payment__formula_formcol">
                            <TextFeild
                              Label="Tax Value"
                              Data={tax.value}
                              Type="number"
                              Disabled={isEditable ? false : true}
                              Adroment={"%"}
                              Handler={(e) =>
                                handleFieldChange(
                                  "formula_taxes",
                                  "value",
                                  e,
                                  index
                                )
                              }
                            />
                          </div>
                          {paymentFormula?.formula_taxes?.length - 1 ===
                            index &&
                            isEditable && (
                              <div className="addprod__seccancel_btnwrap">
                                <button
                                  className="payment__formula_cancel_btn"
                                  onClick={(e) => {
                                    handleRemove(
                                      "formula_taxes",
                                      tax?.id,
                                      "deletedTaxesIds",
                                      index
                                    );
                                  }}
                                >
                                  <MdClose size={22} />
                                </button>
                              </div>
                            )}
                        </div>
                      ))}
                      {isEditable === true && (
                        <div className="addprod__section_formrow">
                          <div className="payment__formula_formcol">
                            <div
                              className="payment__formula_addmore_btn"
                              onClick={() =>
                                addMore("formula_taxes", {
                                  name: "",
                                  value: "",
                                })
                              }
                            >
                              <MdAdd
                                className="payment__formula_addmore_btnicon"
                                size={18}
                              />
                              ADD MORE TAXES
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {isEditable === true && (
                  <div className="prodidentifier__desc_modalfooter">
                    <button
                      className="prodidentifier__desc_modalcncl"
                      onClick={(e) => {
                        e.preventDefault();
                        handleClose();
                      }}
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      className="prodidentifier__desc_modalsave"
                    >
                      {loading2 ? <Loader /> : "Save"}
                    </button>
                  </div>
                )}
                {paymentFormula?.is_default === "false" &&
                  isEditable === false && (
                    <div className="prodidentifier__desc_modalfooter">
                      <button
                        style={{ width: "fit-content", padding: "0px 10px" }}
                        className="prodidentifier__desc_modalsave"
                        onClick={(e) => {
                          e.preventDefault();
                          setConfirmDelete(paymentFormula);
                        }}
                      >
                        Set Back To Default
                      </button>
                    </div>
                  )}
                <Confirm
                  open={confirmDelete}
                  setOpen={setConfirmDelete}
                  handleConfirm={handleDeleteFormula}
                  text="Do you want to switch  formula?"
                />
              </Form>
            </>
          )}
        </>
      )}
    </>
  );
};

export default UserFormula;
