import axios from "../../axiosInstance";

export const fetchShipperTransactionswithuserID = async (
  page,
  perPage,
  filter,
  id
) => {
  let response = { data: null, error: null };

  try {
    const res = await axios.get(
      `admin/get-transaction-by-user-id/${id}?perPage=${perPage}&page=${page}&name=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const updateBalance = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/payment/balance/add/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchPendingPayments = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/payment/pending-payments?perPage=${perPage}&page=${page}&name=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchAllShippers = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/users/Shipper?perPage=${perPage}&page=${page}&name=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const createUserPaymentFormula = async (data, user_id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      user_id
        ? `admin/payment-formula/store/${user_id}`
        : "admin/payment-formula/store",
      data
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const deleteUserPaymentFormula = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.delete(`admin/payment-formula/delete/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateUserPaymentFormula = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/payment-formula/update/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const getUserPaymentFormula = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      id ? `admin/payment-formula/get/${id}` : `admin/payment-formula/get`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const getMarkupFormula = async () => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/payment-formula/get?default_type=for_markup_default`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const getWarehousePaymentFormula = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      id
        ? `admin/payment-formula/get/${id}`
        : `admin/payment-formula/get?default_type=for_warehouse_default`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchAdminCalculatedTax = async (data) => {
  let response = { data: null, error: null };

  try {
    const res = await axios.post(`admin/shipment/calculate-tax`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
