import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  fetchAllPalletsLocations,
  addPalletsLocation,
} from "../../../../services/product.service";
import { assignLocationToMap } from "../../../../services/WarehouseServices/storageMap.services";

const filter = createFilterOptions();

export default function LocationDropdown({
  setValue,
  value = [],
  setSnack,
  currentCell,
  mapId,
  assignedCells,
  setAssignedCells,
  getExistingMap,
}) {
  const [location, setLocation] = useState([]);

  const addNewLocation = async (newOption) => {
    let response;
    response = await addPalletsLocation(newOption);
    if (!response.error) {
      const newLocation = response?.data?.result;
      setLocation((prevLocations) => [...prevLocations, newLocation]);
      const updatedValue = [
        ...value,
        { id: newLocation.id, name: newOption.name },
      ];
      setValue(updatedValue);
      setSnack((snack) => ({
        ...snack,
        open: true,
        text: `${newOption.name} stored in locations`,
        severity: "success",
      }));
      assignLocations(updatedValue);
    } else {
      setSnack((snack) => ({
        ...snack,
        open: true,
        text: `${newOption.name} not stored in locations`,
        severity: "error",
      }));
    }
  };

  const getCostType = async () => {
    const response = await fetchAllPalletsLocations();
    if (!response.error) {
      setLocation(response?.data?.result || []);
    }
  };
  const handleRemoveAll = async () => {
    // Handle the removal of all locations
    const response = await assignLocationToMap(mapId, {
      cell: currentCell,
      locations: [],
    });
    if (response?.data?.success) {
      setSnack((snack) => ({
        ...snack,
        open: true,
        text: "Locations removed successfully",
        severity: "success",
      }));
    }
    const temp = { ...assignedCells };
    temp[currentCell] = {
      cell: currentCell,
      storage_map_id: `${mapId}`,
      pallet_locations: [],
    };
    setAssignedCells(temp);
  };
  const assignLocations = async (selectedLocations) => {
    const locationIds = selectedLocations.map((loc, index) => {
      return {
        id: loc?.id,
        order_by:
          loc?.order_by && loc?.order_by < index + 1
            ? loc?.order_by
            : index + 1,
      };
    });
    const response = await assignLocationToMap(mapId, {
      cell: currentCell,
      locations: locationIds,
    });
    if (response?.data?.success) {
      setSnack((snack) => ({
        ...snack,
        open: true,
        text: "Locations assigned successfully",
        severity: "success",
      }));
    }
    const temp = { ...assignedCells };
    // locationIds.forEach((locationId) => {
    //     temp[currentCell] = {
    //         cell: currentCell,
    //         storage_map_id: `${mapId}`,
    //         pallet_location_id: `${locationId}`,
    //         pallet_location: location.find(loc => loc.id === locationId)
    //     };
    // });
    getExistingMap(true);
    setAssignedCells(temp);
  };

  useEffect(() => {
    getCostType();
  }, []);

  return (
    <Autocomplete
      multiple
      value={value || []}
      onChange={async (event, newValue) => {
        if (newValue.length === 0) {
          // When all values are removed, handle the removal
          await handleRemoveAll();
        }
        if (newValue.length > 0) {
          const lastSelected = newValue[newValue.length - 1];

          if (typeof lastSelected === "string") {
            const selectedOption = location.find(
              (option) => option.name === lastSelected
            );
            setValue(
              selectedOption
                ? [...newValue.slice(0, -1), selectedOption]
                : newValue.slice(0, -1)
            );
          } else if (lastSelected && lastSelected.inputValue) {
            const newOption = { name: lastSelected.inputValue };
            await addNewLocation(newOption);
          } else {
            setValue(newValue);
            await assignLocations(newValue);
          }
        } else {
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            name: `Add "${inputValue}"`,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="product-location"
      options={location || []}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.name;
      }}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label="Select New Location(s)" size="small" />
      )}
    />
  );
}
