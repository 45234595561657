import React, { useState } from "react";
import "../../InvoiceModal.css";
import Attachments from "../Attachments/Attachments";
import { MdAttachment } from "react-icons/md";
import pdfIcon from "../../../../assets/icons/pdfIcon.png";
import excelIcon from "../../../../assets/icons/excelIcon.png";
import noImage from "../../../../assets/images/noImage.png";
import Tooltip from "../../../Tooltip/Tooltip";
import Snackbar from "../../../SnackBar/SnackBar";
import { checkFileType } from "../../../../helpers/helpers";
import ImagesModal from "../../../../Pages/Shipper/Customer/MainPages/Orders/Components/OrderHistoryModal/ImagesModal/ImagesModal";

const NotesAdmin = ({ data, setData, setShipments, getAllShipments }) => {
  const [attachmentsModal, setAttachmentsModal] = useState();
  const [fullview, setfullview] = useState();

  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  return (
    <>
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <ImagesModal setOpen={setfullview} open={fullview} />
      <div className="invmodal__note_box">
        <div className="invmodal__note_boxhding">Notes</div>
        <div className="invmodal__note_boxdesc">
          {data?.shipment_charges?.map((charge, index) => (
            <div className="invmodal__note_boxdescitem" key={index}>
              {" "}
              {attachmentsModal === `client${index}` && (
                <Attachments
                  onClose={() => setAttachmentsModal(false)}
                  attachments={charge?.media}
                  getAllShipments={getAllShipments}
                  setSnack={setSnack}
                  setData={setData}
                  setShipments={setShipments}
                  data={data}
                  chargeIndex={index}
                  type="user"
                />
              )}
              <div className="notes__attachments_modal_attachment_thumbnail_wrap">
                <div className="notes__attachments_modal_attachment_thumbnail notes__attachments_modal_attachment_thumbnail2">
                  {charge?.description || charge?.media?.length ? (
                    <b>{`${index + 1} `}</b>
                  ) : (
                    ""
                  )}
                  {charge?.description ?? ""}
                  {charge?.media?.length
                    ? // <Tooltip title="Download Attachments">
                      //   <span className="invmoda__note_atchmentdownloadwrap">
                      //     <MdAttachment
                      //       className="invmoda__note_atchmentdownload"
                      //       size={18}
                      //       onClick={() => setAttachmentsModal(`client${index}`)}
                      //     />
                      //     {charge?.media?.length ? (
                      //       <span className="invmodal__shippymnt_attachmenbadge2">
                      //         {charge?.media?.length ?? ""}
                      //       </span>
                      //     ) : (
                      //       ""
                      //     )}
                      //   </span>
                      // </Tooltip>
                      charge?.media
                        ?.filter((attachment) => attachment?.meta === "admin")
                        .map((attachment, index) => (
                          <img
                            className="note_attachment"
                            key={attachment?.id}
                            src={
                              attachment?.file &&
                              (checkFileType(attachment.file) === "Image"
                                ? attachment.file
                                : checkFileType(attachment.file) === "PDF"
                                ? pdfIcon
                                : checkFileType(attachment.file) === "Excel"
                                ? excelIcon
                                : noImage)
                            }
                            alt={`Attachment ${index + 1}`}
                            onError={(e) => {
                              e.target.src = noImage;
                            }}
                            onClick={() => {
                              if (checkFileType(attachment.file) === "Image") {
                                setfullview(attachment?.file || "");
                              } else {
                                window.open(attachment?.file, "_blank");
                              }
                            }}
                          />
                        ))
                    : ""}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default NotesAdmin;
