import React, { useState } from "react";
import "./SelectDriver.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { assignDriver } from "../../../../../services/shipments.services";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 4,
  padding: "30px",
  backgroundColor: "white",
};

export default function SelectDriver({
  open,
  setOpen,
  drivers,
  setSnack,
  setShipments,
}) {
  const [selectedDriver, setSelectedDriver] = useState("");
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const handleClose = () => setOpen(false);
  const handleSave = async () => {
    setLoading(true);
    const response = await assignDriver(
      user.token,
      open?.shipmentId,
      selectedDriver,
      open?.type
    );
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    } else {
      handleClose();
      setShipments((shipments) =>
        shipments.map((shipment) =>
          shipment.id === open.shipmentId ? response.data.result : shipment
        )
      );
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `${open.type} Driver Updated`,
          severity: "success",
        };
      });
    }
    setLoading(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="dispselect__driver_heading">
            Select Driver for {open?.type}
          </div>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={drivers}
            getOptionLabel={(option) => option.name}
            sx={{
              width: "100%",
              margin: "10px 0",
              height: 40,
              "& *": { maxHeight: "100%" },
            }}
            onChange={(e, value) => setSelectedDriver(value.id)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={`Select Driver for ${open?.type}`}
                // label={`Select Driver for ${open?.type}`}
              />
            )}
          />
          <div className="dispselect__driver_btns">
            <div className="dispselect__driver_cancelbtn" onClick={handleClose}>
              Cancel
            </div>
            <div
              className={`dispselect__driver_savebtn ${
                (loading || !selectedDriver) && "dispselect__driver_savebtndis"
              }`}
              onClick={handleSave}
            >
              {loading ? (
                <CircularProgress
                  sx={{
                    color: "white",
                  }}
                  size={20}
                />
              ) : (
                "Save"
              )}
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
