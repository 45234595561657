import React, { useState } from "react";
import "./Attachments.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  MdOutlineClose,
  MdDeleteForever,
  MdOutlineFileDownload,
} from "react-icons/md";

function extractFileNameFromUrl(url) {
  const parts = url.split("/");

  const fileName = parts[parts.length - 1];
  console.log(fileName);
  return fileName;
}

const Attachments = ({ onClose, attachments }) => {
  function downloadFiles(filesList) {
    const promises = [];

    filesList.forEach((fileObj, index) => {
      const url = fileObj.file;

      const promise = fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
          }

          // Extract the file extension from the URL
          const fileExtension = url.split(".").pop();

          return response.blob().then((blob) => ({ blob, fileExtension }));
        })
        .then(({ blob, fileExtension }) => {
          // Create a temporary URL for the blob
          const blobUrl = URL.createObjectURL(blob);

          // Create an anchor element to trigger the download
          const a = document.createElement("a");
          a.href = blobUrl;
          a.download = `attachment${index + 1}.${fileExtension}`;
          document.body.appendChild(a);

          // Trigger the download
          a.click();

          // Clean up by revoking the blob URL
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error(`Error downloading file ${index + 1}:`, error);
        });

      promises.push(promise);
    });

    // Wait for all download promises to complete
    return Promise.all(promises);
  }

  function downloadFile(url, fileName) {
    return fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }

        const contentType = response.headers.get("content-type");
        if (!contentType) {
          throw new Error("Content type header is missing in the response.");
        }

        // Extract the file extension from the content type
        const fileExtension = contentType.split("/")[1];

        return response.blob().then((blob) => ({ blob, fileExtension }));
      })
      .then(({ blob, fileExtension }) => {
        // Create a temporary URL for the blob
        const blobUrl = URL.createObjectURL(blob);

        // Create an anchor element to trigger the download
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = `${fileName}.${fileExtension}`;
        document.body.appendChild(a);

        // Trigger the download
        a.click();

        // Clean up by revoking the blob URL
        URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  }
  // console.log(attachments);
  return (
    <Dialog
      open={attachments}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      className="stockcount__attachment_modal_main"
    >
      <DialogTitle
        id="responsive-dialog-title"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        Attachments
        <span className="uploaded__attachments_listhdingicons">
          <MdOutlineFileDownload
            size={20}
            className="uploaded__attachments_downloadall"
            onClick={() =>
              downloadFiles(attachments?.map((attachment) => attachment))
            }
          />

          <MdOutlineClose
            size={20}
            className="uploaded__attachments_closeicon"
            onClick={onClose}
          />
        </span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {attachments?.map((attachment) => (
            <div
              className={`uploaded__attachments_listitem`}
              onClick={() => downloadFile(attachment?.file, "attachment")}
            >
              <div className="uploaded__attachments_litxt">
                {attachment?.created_at.split("T")[0]}_
                {extractFileNameFromUrl(attachment?.file)}
              </div>
              <div className="uploaded__attachments_liicondownload">
                <MdOutlineFileDownload size={20} />
              </div>
            </div>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Attachments;
