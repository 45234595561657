import ListGroup from "react-bootstrap/ListGroup";
import Tooltip from "../../../../../../../Components/Tooltip/Tooltip";
import { GoPackage } from "react-icons/go";
import React, { useState, useEffect } from "react";
import { setdata } from "../../../../../../../helpers/helpers";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import Sad from "@mui/icons-material/SentimentVeryDissatisfied";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import { fetchAllBundles } from "../../../../../../../services/ShipperServices/Packages/packages.services";

export default function BundleGridView(props) {
  const { handlescrollpackage, setFilter, refresh } = props;

  const [page, setPage] = useState(1); // current page number

  const [searchBundles, setsearchBundles] = useState("");
  const [Bundlesloader, setBundlesloader] = useState(false);
  const [Bundles, setBundles] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const handleBundleschange = (e) => {
    const { value } = e.target;
    setsearchBundles(value);
  };

  const handlePrevPage = () => {
    setPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const getAllBundles = async (page, perPage, filter) => {
    setBundlesloader(true);
    const response = await fetchAllBundles(page, perPage, filter);

    if (!response.error) {
      setBundles(response?.data?.result?.data);
      setTotalPages(
        Math.ceil(
          response?.data?.result?.total / response?.data?.result?.per_page
        )
      );
      setBundlesloader(false);
    } else {
      setBundlesloader(false);
    }
  };

  useEffect(() => {
    getAllBundles(page, 20, searchBundles);
  }, [page, searchBundles, refresh]);

  useEffect(() => {
    const filterElement = (
      <>
        {(Bundlesloader === true || searchBundles !== "" || totalPages > 0) && (
          <div className="product__scrollbar_list_filter_field_wrap">
            <input
              type="text"
              value={searchBundles}
              onChange={(e) => handleBundleschange(e)}
              placeholder="Filter Bundles"
              className="product__scrollbar_list_filter_field"
            />
            {Bundlesloader === true && (
              <CircularProgress
                size={12}
                style={{
                  color: "#C1C1C1",
                  marginLeft: "-20px",
                  marginTop: "15px",
                  transform: "translateY(-50%)",
                }}
              />
            )}
          </div>
        )}
      </>
    );

    setFilter(filterElement);
  }, [Bundlesloader, searchBundles, totalPages]);

  return (
    <>
      {totalPages > 0 ? (
        <div className="d-flex flex-row mt-2 bundle__list_main_wrap">
          <IconButton
            className="mr-1"
            disabled={page === 1}
            onClick={handlePrevPage}
          >
            <FaChevronLeft />
          </IconButton>
          <ListGroup
            horizontal
            className="overflow-auto horiscl p-2"
            style={{
              maxHeight: "108px",
              cursor: "pointer",
              display: "-webkit-box",
              overflowX: "auto",
              overflowY: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              WebkitScrollbarTrack: {
                background: "#000000",
              },
            }}
          >
            {Bundles.map((data, index) => (
              <Tooltip title={setdata(() => data.name, false, "")} key={index}>
                <ListGroup.Item
                  className="mr-2 mb-1 shadow single_list bundle__prod_card_wrap"
                  style={{
                    width: "150px",
                    border: "1px solid #C8C9CA",
                    borderRadius: "4px",
                    padding: "2px",
                    flexShrink: 0,
                  }}
                  onClick={() => handlescrollpackage(data)}
                >
                  <div className="productList__card_wrap">
                    <div className="productList__card_details_wrap">
                      <div className="productList__card_details_img">
                        {data.file ? (
                          <img src={data.file} loading="lazy" />
                        ) : (
                          <GoPackage size={25} style={{ color: "#C8C9CA" }} />
                        )}
                      </div>

                      <div className="productList__card_title">
                        <span>{setdata(() => data.name, false, "")}</span>
                      </div>
                      <div className="productList__card_details_measures">
                        <div className="productList__card_details_measures_weight">
                          <span>
                            {" "}
                            {setdata(
                              () =>
                                data?.packages
                                  .map((packageItem) =>
                                    parseFloat(packageItem.weight)
                                  )
                                  .reduce(
                                    (accumulator, weight) =>
                                      accumulator + weight,
                                    0
                                  ),
                              false,
                              ""
                            )}
                          </span>
                          Weight
                        </div>
                        <div className="productList__card_details_measures_LWH">
                          <div className="productList__card_details_measures_L">
                            <span>
                              {setdata(() => data.packages.length, false, "")}
                            </span>
                            Count
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ListGroup.Item>
              </Tooltip>
            ))}
          </ListGroup>
          <IconButton
            className="mr-1"
            disabled={page === totalPages || totalPages === 0}
            onClick={handleNextPage}
          >
            <FaChevronRight />
          </IconButton>
        </div>
      ) : (
        <div
          className="driver__screen_empty"
          style={{ minHeight: "12vh", width: "100%" }}
        >
          <div className="driver__scrnempt_content">
            <Sad className="driver__scrnempt_contenticon" />
            <div>No Bundles to display!</div>
          </div>
        </div>
      )}
    </>
  );
}
