import React, { useState } from "react";

// components imports
import CircularLoader from "../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import { createStockRecNote } from "../../../../../services/warehouse.services";
// library imports
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Button, TextField, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const AddStockRecNoteModal = ({
  open,
  setOpen,
  setSnack,
  grnData,
  setNotesData,
}) => {
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [notes, setnotes] = useState();
  const [loader, setloader] = useState(false);

  const handleClose = () => {
    setnotes();
    setAttachedFiles([]);
    setOpen(false);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    setAttachedFiles([...files, ...attachedFiles]);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...attachedFiles];
    updatedFiles.splice(index, 1);
    setAttachedFiles(updatedFiles);
  };

  const handleRenameFile = (index, newName) => {
    const updatedFiles = [...attachedFiles];
    updatedFiles[index].name = newName;
    setAttachedFiles(updatedFiles);
  };

  const handleSave = async () => {
    setloader(true);
    let response;
    try {
      const formData = new FormData();

      formData.append(`notes`, notes ?? "");
      if (attachedFiles?.length > 0) {
        attachedFiles?.map((file, index) => {
          formData.append(`grnNotesMedia[${index}]`, file);
        });
      }

      response = await createStockRecNote(grnData?.id, formData);
      if (!response.error) {
        setloader(false);
        setNotesData((prevGrn) => {
          return [response?.data?.result, ...prevGrn];
        });
        handleClose();
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: response?.data?.message,
            severity: "success",
          };
        });
      } else {
        setloader(false);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text:
              response?.error?.response?.data?.message ||
              "Something went wrong",
            severity: "error",
          };
        });
      }
    } catch (error) {
      setloader(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: error.response.data.message || "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="addnotes__detail_fulfilment_modal"
      >
        <Box
          sx={{ ...style, width: "51%", maxHeight: "90vh", overflow: "auto" }}
        >
          <h2 id="child-modal-title" className="sale__status_title">
            Add Note
          </h2>

          <>
            <TextField
              label="Note"
              type="text"
              name="Note"
              value={notes}
              className="addnotes__status_notes"
              onChange={(e) => setnotes(e.target.value)}
              placeholder={"Note for Sale "}
              sx={{
                width: "100%",
                input: { font: "menu" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              multiline={true}
              rows={2}
            />

            <div className="addnotes_status_update_attachment_wrap">
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="file-input"
              />
              <label htmlFor="file-input">
                <Button variant="contained" component="span" color="primary">
                  Attach File
                </Button>
              </label>
              <div className="addnotes_attachment_list_main_wrap">
                {attachedFiles?.map((attachedFile, index) => (
                  <div
                    key={index}
                    className="addnotes_update_attachment_detail_box"
                  >
                    <TextField
                      variant="outlined"
                      className="addnotes__field"
                      size="small"
                      value={attachedFile.name}
                      onChange={(e) => handleRenameFile(index, e.target.value)}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      style={{ border: "none", padding: "0" }}
                    />
                    <IconButton
                      onClick={() => handleRemoveFile(index)}
                      color="error"
                      className="adnotes_remove_attachment"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                ))}
              </div>
            </div>
          </>

          <div
            style={{ display: "flex", justifyContent: "end" }}
            className="addnotes_fulfilment_btn"
          >
            <Button onClick={handleSave} variant="contained" size="small">
              {loader ? <CircularLoader /> : "Confirm"}
            </Button>
            <Button
              onClick={handleCancel}
              variant="contained"
              color="error"
              size="small"
              style={{ marginLeft: "5px" }}
            >
              Decline
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddStockRecNoteModal;
