import React, { useEffect, useState } from "react";
import "./BlogDetails.css";

// components imports
// apis imports
// library imports

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import PlaceholderImage from "../../../../../../../assets/images/placeholder-image.png";
import { MdOutlineCreate, MdCreditScore } from "react-icons/md";
import IconButton from "@mui/material/IconButton";
import { FaRegTimesCircle } from "react-icons/fa";

export default function BlogDetails({ open, setOpen, blogData }) {
  const [data, setData] = useState({});
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (blogData) {
      setData(blogData);
    }
  }, [blogData]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="admnblog__details_modal">
          <div
            className="invoice__modal_closeinvoicewrap"
            onClick={handleClose}
          >
            <IconButton>
              <FaRegTimesCircle size={25} color="lightgrey" />
            </IconButton>
          </div>
          <div className="admnblog__details_modalheader">
            <div className="admnblog__details_modalheaderlft">
              <div className="admnblog__details_modalheaderimg">
                <img
                  src={data?.thumb_img ?? PlaceholderImage}
                  onError={(e) => {
                    e.target.src = PlaceholderImage;
                  }}
                />
              </div>
            </div>
            <div className="admnblog__details_modalheaderrt">
              <h1 className="admnblog__details_modalhdrrttitle">
                {data?.title}
              </h1>
              {data?.slug && (
                <h5 className="admnblog__details_modalhdrrtslug">
                  slug: {data?.slug}
                </h5>
              )}
              <div className="admnblog__details_modalhdrrtdates">
                {data?.created_at && (
                  <div className="admnblog__details_modalhdrrtdate">
                    <MdOutlineCreate size={12} />
                    {data?.created_at?.slice(0, 10)}
                  </div>
                )}
                {data?.updated_at && (
                  <div className="admnblog__details_modalhdrrtdate">
                    <MdCreditScore size={12} />
                    {data?.updated_at?.slice(0, 10)}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="admnblog__details_modalcontent">
            <h2>Body</h2>
            <p>{data?.body ?? "No Content Found!"}</p>
            <h2>Description</h2>
            <p>{data?.desc ?? "No Content Found!"}</p>
          </div>
        </div>
      </Modal>
    </div>
  );
}
