import React, { useEffect, useState } from "react";
import AddNewProductForm from "./components/AddNewProductForm";
import ProductCard from "./components/ProductCard";
import PaymentShippingDetailCard from "./components/PaymentShippingDetailCard";
import OrderSubmitBtn from "./components/OrderSubmitBtn";
import CostFulfilmentCard from "./components/CostFulfilmentCard";
import "./AddNewProductPage.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function AddNewProductPage() {
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalProductCount, setTotalProductCount] = useState([]);
  const [totalProductPrice, settotalProductPrice] = useState(0);
  const [productsTotalWeight, setProductsTotalWeight] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const handleProductsTotalWeightChange = (newTotalWeight) => {
    setTotalWeight(newTotalWeight);
  };
  const handleProductsTotalCount = (newTotalProductCount) => {
    setTotalProductCount(newTotalProductCount);
  };

  const jsonData = {
    result: {
      data: [
        {
          id: 1,
          name: "Rubber Hex Dumbbell Stack",
          file: "https://dev-ship.hajexbolt.com/ship_api/public/storage/packages/16916609971444.png",
          weight: 23,
          length: "83",
          width: "32",
          height: "47",
          price: 35,
          subCategoryName: "Rubber Dumbbells",
          brand: "Hajex",
          fulfilmentcost: "5",
          warehouse: [
            {
              warehouseId: 23,
              warehouseName: "Montreal",
              warehouseCount: "27",
              warehouseProductSKU: "ADJ-DUMB-MD-8069",
            },
            {
              warehouseId: "24",
              warehouseName: "langley",
              warehouseCount: "445",
              warehouseProductSKU: "ADJ-DUMB-MD-8069",
            },
          ],
        },
        {
          id: 2,
          name: "Rubber Hex Dumbbell 2",
          file: "https://dev-ship.hajexbolt.com/ship_api/public/storage/packages/16916609971444.png",
          weight: 23,
          length: "83",
          width: "32",
          height: "47",
          price: 35,
          subCategoryName: "Rubber Dumbbells",
          brand: "Hajex",
          fulfilmentcost: "10",
          warehouse: [
            {
              warehouseId: 23,
              warehouseName: "Montreal",
              warehouseCount: "27",
              warehouseProductSKU: "ADJ-DUMB-MD-8069",
            },
            {
              warehouseId: "24",
              warehouseName: "langley",
              warehouseCount: "445",
              warehouseProductSKU: "ADJ-DUMB-MD-8069",
            },
          ],
        },
        {
          id: 4,
          name: "mobilinkercd4",
          file: "https://dev-ship.hajexbolt.com/ship_api/public/storage/packages/16916609971444.png",
          weight: 88,
          length: "83",
          width: "32",
          height: "47",
          price: 35,
          fulfilmentcost: "",
          subCategoryName: "Rubber Dumbbells",
          brand: "Apple",
          warehouse: [
            {
              warehouseId: 23,
              warehouseName: "Montreal",
              warehouseCount: "27",
              warehouseProductSKU: "ADJ-DUMB-MD-8069",
            },
            {
              warehouseId: "24",
              warehouseName: "langley",
              warehouseCount: "445",
              warehouseProductSKU: "ADJ-DUMB-MD-8069",
            },
          ],
        },
      ],
    },
  };

  // console.log(totalProductPrice + "new");
  const fetchedPackages = jsonData.result.data;

  const jsonCatData = {
    result: {
      data: [
        {
          id: 8,
          brandName: "Hajex",
          category: [
            {
              categoryId: "1",
              categoryName: "Dumbbells",
              subCategory: [
                {
                  subCategoryId: "1",
                  subCategoryName: "Rubber Dumbbells",
                },
                {
                  subCategoryId: "2",
                  subCategoryName: "Steel Dumbbells",
                },
              ],
            },
            {
              categoryId: "1",
              categoryName: "Dumbbells",
              subCategory: [
                {
                  subCategoryId: "1",
                  subCategoryName: "Rubber Dumbbells",
                },
                {
                  subCategoryId: "2",
                  subCategoryName: "Steel Dumbbells",
                },
              ],
            },
            {
              categoryId: "1",
              categoryName: "Dumbbells",
              subCategory: [
                {
                  subCategoryId: "1",
                  subCategoryName: "Rubber Dumbbells",
                },
                {
                  subCategoryId: "2",
                  subCategoryName: "Steel Dumbbells",
                },
              ],
            },
          ],
        },
        {
          id: 8,
          brandName: "Hajex Fitness",
          category: [
            {
              categoryId: "1",
              categoryName: "Treadmill",
              subCategory: [
                {
                  subCategoryId: "1",
                  subCategoryName: "Hajex Treadmill",
                },
                {
                  subCategoryId: "2",
                  subCategoryName: "Hajex Auto Treadmill",
                },
              ],
            },
            {
              categoryId: "1",
              categoryName: "Bench Press",
              subCategory: [
                {
                  subCategoryId: "1",
                  subCategoryName: "Hajex Bench Press",
                },
                {
                  subCategoryId: "2",
                  subCategoryName: "Somu Bench Press",
                },
              ],
            },
            {
              categoryId: "1",
              categoryName: "Pulley ",
              subCategory: [
                {
                  subCategoryId: "1",
                  subCategoryName: "Cable Pully",
                },
                {
                  subCategoryId: "2",
                  subCategoryName: "Iron Pully",
                },
              ],
            },
          ],
        },
      ],
    },
  };
  const fetchedCatData = jsonCatData.result.data;
  return (
    <>
      <Row className="mx-auto ">
        <Col md={11} className="mx-auto">
          <AddNewProductForm />
          <ProductCard
            fetchedPackages={fetchedPackages}
            fetchedCatData={fetchedCatData}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}            
            totalProductPrice={totalProductPrice}
            settotalProductPrice={settotalProductPrice}


            onTotalWeightChange={handleProductsTotalWeightChange}
            onTotalProductCount={handleProductsTotalCount}
            productsTotalWeight={productsTotalWeight}
            setproductsTotalWeight={setProductsTotalWeight}
            
          />
          <CostFulfilmentCard
            onTotalWeightChange={totalWeight}
            onTotalProductCount={totalProductCount}
            totalProductPrice={totalProductPrice}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
          />
          <PaymentShippingDetailCard />
          <OrderSubmitBtn />
        </Col>
      </Row>
    </>
  );
}

export default AddNewProductPage;
