import React, { useState, useRef } from "react";
import "./TicketCreateModal.css";

// components imports
// apis imports
import { createTicket } from "../../../../../services/Tickets/ticket.service";

// library imports
import CircularLoader from "../../../../../Components/CircularLoader/CircularLoader";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Upload from "@mui/icons-material/CloudUpload";
import { useNavigate } from "react-router-dom";

export default function TicketCreateModal({
  open,
  setOpen,
  previousData,
  setSnack,
}) {
  const [data, setData] = useState(previousData);
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [ticketData, setTicketData] = useState({
    reason: "Package Lost",
    title: "",
    description: "",
    priority: "Low",
    attachment: null,
  });
  const navigate = useNavigate();

  const handleClose = () => {
    setTicketData({
      reason: "Package Lost",
      title: "",
      description: "",
      priority: "Low",
      attachment: null,
    });
    setData();
    setFile();
    setLoading(false);
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTicketData({ ...ticketData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const packageTrackingId = document.querySelector(
      'input[name="packageTrackingId"]'
    ).value;

    const formData = new FormData();

    formData.append("subject", ticketData.title);
    formData.append("description", ticketData.description);
    formData.append("reason", ticketData.reason);
    formData.append("priority", ticketData.priority);
    formData.append("shipment_id", packageTrackingId);
    formData.append("attachment", file);

    try {
      const response = await createTicket(formData);
      if (response.error) {
        setLoading(false);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Something went wrong",
            severity: "error",
          };
        });
      } else {
        setLoading(false);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Ticket Created Successfully",
            severity: "success",
          };
        });
        navigate({
          pathname: "/tickets-admin",
        });
      }
    } catch (error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
      setLoading(false);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="ticketCreate__modal_wrapper">
          <div className="ticketCreate__modal_heading">Create Ticket</div>
          <form onSubmit={handleSubmit} className="ticketModal__form_wrap">
            <div className="ticketModal__form_field">
              <label>Reason:</label>
              <select
                name="reason"
                value={ticketData.reason}
                onChange={handleChange}
                required
              >
                <option value="Package Lost">Package Lost</option>
                <option value="Damage">Damage</option>
                <option value="Stolen">Stolen</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="ticketModal__form_field">
              <label>Title/Subject:</label>
              <input
                type="text"
                name="title"
                value={ticketData.title}
                onChange={handleChange}
                required
              />
            </div>
            <div className="ticketModal__form_field">
              <label>Priority:</label>
              <select
                name="priority"
                value={ticketData.priority}
                onChange={handleChange}
              >
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </select>
            </div>
            <div className="ticketModal__form_field">
              <label
                className={
                  !file
                    ? "add__package_profimagelbl"
                    : "add__package_profimagelbl imageuploaded"
                }
              >
                <Upload />{" "}
                {!file ? "PROOF IMAGE UPLOADED" : "UPLOAD PROOF IMAGE"}
                <input
                  type="file"
                  size="60"
                  name="proofImage"
                  className="add__package_profimageinput"
                  onChange={handleFileChange}
                  accept=".jpg,.png"
                />
              </label>
            </div>
            <div className="ticketModal__form_field textarea_col">
              <label>Description:</label>
              <textarea
                name="description"
                value={ticketData.description}
                onChange={handleChange}
                required
              />
            </div>
            <div className="ticketModal__form_submit">
              <button type="submit">
                {loading ? <CircularLoader /> : "Create Ticket"}
              </button>
            </div>
            <input
              type="hidden"
              name="packageTrackingId"
              value={previousData?.id}
            />
          </form>
        </div>
      </Fade>
    </Modal>
  );
}
