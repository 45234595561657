import React, { useEffect, useState } from "react";
import "./Sales.css";

// components imports
import CarrierTableLoader from "../../Components/CarrierInnerTableLoader/CarrierInnerTableLoader";
import Attachments from "./Components/Attachment";
import OrderHistoryModal from "../Shipper/Customer/MainPages/Orders/Components/OrderHistoryModal/OrderHistoryModal";
import CircularLoader from "../../Components/CircularLoader/CircularLoader";
import Snackbar from "../../Components/SnackBar/SnackBar";
import PickupAddress from "./Components/PicupAddress";
import DeliveryAddress from "./Components/DeliveryAddress";
import AvailableProducts from "./Components/AvailableProducts";
import PaymentDetails from "./Components/PaymentDetails";
import ShipmentDetails from "./Components/ShipmentDetails";
import Products from "./Components/SelectedProducts";
// apis imports
import {
  storeOrder,
  updateOrder,
  fetchSingleOrder,
} from "../../services/orders.services";
// library imports

import { BiArrowBack } from "react-icons/bi";
import { Link, useLocation, useNavigate } from "react-router-dom";

const initialData = {
  order_id: "",
  order_type: "",
  type: "Manual",
  status: "Confirmed",
  currency: "CAD",
  customer_note: "",
  payment_method_title: "Direct Bank Transfer",
  discount_total: 0,
  discount_tax: 0,
  amount_received: "",
  amount_pending: "",
  order_status_id: "2",
  fulfillment_payment_status: "unpaid",
  formula: "",
  shipping_total: 0,
  shipping_tax: 0,
  total: 0,
  total_tax: 0,
  items: [],
  attachments: [],
  billing: {
    first_name: "",
    last_name: "",
    company: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    postcode: "",
    country: "",
    email: "",
    phone: "",
  },
  shipping: {
    first_name: "",
    last_name: "",
    company: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    postcode: "",
    country: "",
    phone: "",
  },
  warehouse: {
    full_address: "",
    country: "",
    country_name: "",
    state_provience: "",
    state_name: "",
    city_town: "",
    zip_postal: "",
    company: "",
    attention: "",
    phone: "",
    email: "",
  },
  OrderShipment: {
    shipment_status: "",
    shipment_tracking_id: "",
    pickup_id: "",
    pickup_date: "",
    shipping_company: null,
    prev_labels: [],
    prev_declaration: [],
    labels: [],
    declaration: [],
  },
};

const Sales = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialData);
  const [creatingOrder, setCreatingOrder] = useState();
  const [SelectedProducts, setSelectedProducts] = useState([]);
  const [Warehouse, setWarehouse] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [notesHistoryModal, setNotesHistoryModal] = useState();
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [formula, Setformula] = useState({
    TotalFulfilmentCost: 0,
    TotalWeightCost: 0,
    TotalPreCost: 0,
    TotalFulfilmentWeight: 0,
    TotalPreProduct: 0,
    UnitType: "",
  });

  const handleCreateOrder = async () => {
    let salePrice = 0;
    const itemsArray = SelectedProducts.map((product, index) => {
      // Extract relevant information from each product
      salePrice += parseFloat(product.sale_price) * product.quantity;
      const item = {
        item_id: product.id,
        product_lot_id: product?.product_lot_id,
        active_price: product.sale_price,
        regular_price: product.regular_price,
        sale_price: product.sale_price,
        sku: product.code,
        fulfillment_cost: product.fulfillment_cost,
        product_name: product.full_name,
        item_quantity: product.quantity,
        length: product.length,
        width: product.width,
        height: product.height,
        weight: product.weight,
        product_length_type: "Imperial",
        bolt_package_detail: product.package_details,
        item_subtotal: product.sale_price,
        item_subto_tax: 0,
        item_total: product.sale_price,
        item_total_tax: 0,
        item_tax_status: "taxable",
        note: product.note ?? "",
      };

      return item;
    });

    let payload = {
      ...data,
      total: salePrice,
      items: itemsArray,
      warehouse_id: Warehouse,
      formula: formula,
      fulfillment_cost: formula.TotalFulfilmentCost,
    };
    setCreatingOrder(true);
    const { note, attachments, ...payloadData } = payload;
    const formData = new FormData();
    if (attachments?.length > 0 || note) {
      formData.append(
        "orderNotes",
        JSON.stringify({ note: note, type: "global" })
      );

      if (attachments?.length > 0) {
        attachments.forEach((attachment, index) => {
          formData.append(`orderAttachments[${index}]`, attachment);
        });
      }
    }

    if (payload?.OrderShipment?.shipping_company) {
      let idx = 0;
      let shipment_data = {
        shipment_status: payload?.OrderShipment?.shipment_status,
        shipment_tracking_id: payload?.OrderShipment?.shipment_tracking_id,
        pickup_id: payload?.OrderShipment?.pickup_id,
        pickup_date: payload?.OrderShipment?.pickup_date,
        user_shipping_company_id:
          payload?.OrderShipment?.shipping_company?.type === "default"
            ? payload?.OrderShipment?.shipping_company?.id
            : null,
        manual_user_shipping_company_id:
          payload?.OrderShipment?.shipping_company?.type === "manual"
            ? payload?.OrderShipment?.shipping_company?.id
            : null,
      };
      if (payload?.OrderShipment?.labels?.length > 0) {
        payload?.OrderShipment?.labels.forEach((attachment, index) => {
          formData.append(`shipment_media[${idx}][file]`, attachment);
          formData.append(`shipment_media[${idx}][type]`, "label");
          formData.append(
            `shipment_media[${idx}][carrier]`,
            payload?.OrderShipment?.shipping_company?.name ?? ""
          );
          formData.append(
            `shipment_media[${idx}][tracking_id]`,
            payload?.OrderShipment?.shipment_tracking_id ?? ""
          );
          idx++;
        });
      }
      if (payload?.OrderShipment?.declaration?.length > 0) {
        payload?.OrderShipment?.declaration.forEach((attachment, index) => {
          formData.append(`shipment_media[${idx}][file]`, attachment);
          formData.append(`shipment_media[${idx}][type]`, "declaration");
          formData.append(`shipment_media[${idx}][carrier]`, "label");
          formData.append(
            `shipment_media[${idx}][carrier]`,
            payload?.OrderShipment?.shipping_company?.name ?? ""
          );
          formData.append(
            `shipment_media[${idx}][tracking_id]`,
            payload?.OrderShipment?.shipment_tracking_id ?? ""
          );
          idx++;
        });
      }
      formData.append("shipment_data", JSON.stringify(shipment_data));
    }

    formData.append("order", JSON.stringify(payloadData));

    try {
      const response = await storeOrder(formData);
      if (response.error) {
        setCreatingOrder(false);
        setSnack({
          ...snack,
          open: true,
          text:
            response?.error?.response?.data?.message ?? "Something went wrong!",
          severity: "error",
        });
      } else {
        setData(initialData);
        setCreatingOrder(false);
        setSnack({
          ...snack,
          open: true,
          text: response?.data?.message,
          severity: "success",
        });
        navigate("/orders");
      }
    } catch (error) {
      setCreatingOrder(false);
      console.log("error", error);
      setSnack({
        ...snack,
        open: true,
        text: "Something went wrong!",
        severity: "error",
      });
    }
  };
  const handleUpdateOrder = async () => {
    setCreatingOrder(false);
    // console.log(data?.warehouse);
    let salePrice = 0;
    const itemsArray = SelectedProducts.map((product, index) => {
      // Extract relevant information from each product
      salePrice += parseFloat(product.sale_price) * product.quantity;
      const additionalField = product.order_id ? { id: product.order_id } : {};
      const item = {
        ...additionalField,
        item_id: product.id,
        product_lot_id: product?.product_lot_id,
        active_price: product.sale_price,
        regular_price: product.regular_price,
        sale_price: product.sale_price,
        sku: product.code,
        fulfillment_cost: product.fulfillment_cost,
        product_name: product.full_name,
        item_quantity: product.quantity,
        length: product.length,
        width: product.width,
        height: product.height,
        weight: product.weight,
        product_length_type: "Imperial",
        bolt_package_detail: product.package_details,
        item_subtotal: product.sale_price,
        item_subto_tax: 0,
        item_total: product.sale_price,
        item_total_tax: 0,
        item_tax_status: "taxable",
        note: product.note ?? "",
      };

      return item;
    });

    let payload = {
      ...data,
      total: salePrice,
      items: itemsArray,
      warehouse_id: Warehouse,
      formula: formula,
      fulfillment_cost: formula.TotalFulfilmentCost,
    };

    if (
      !payload?.OrderShipment?.shipping_company &&
      payload?.manual_shipment_id
    ) {
      setSnack({
        ...snack,
        open: true,
        text: "Shipping Company Must Be Selected",
        severity: "error",
      });
      return;
    }

    setCreatingOrder(true);
    try {
      const { note, attachments, ...payloadData } = payload;
      let shipment_data = {
        shipment_status: payload?.OrderShipment?.shipment_status,
        shipment_tracking_id: payload?.OrderShipment?.shipment_tracking_id,
        pickup_id: payload?.OrderShipment?.pickup_id,
        pickup_date: payload?.OrderShipment?.pickup_date,
        user_shipping_company_id:
          payload?.OrderShipment?.shipping_company?.type === "default"
            ? payload?.OrderShipment?.shipping_company?.id
            : null,
        manual_user_shipping_company_id:
          payload?.OrderShipment?.shipping_company?.type === "manual"
            ? payload?.OrderShipment?.shipping_company?.id
            : null,
      };
      const formData = new FormData();
      if (attachments?.length > 0 || note) {
        formData.append(
          "orderNotes",
          JSON.stringify({ note: note, type: "global" })
        );

        if (attachments?.length > 0) {
          attachments.forEach((attachment, index) => {
            formData.append(`orderAttachments[${index}]`, attachment);
          });
        }
      }
      if (!payload?.shipment_id && payload?.OrderShipment?.shipping_company) {
        let idx = 0;
        if (payload?.OrderShipment?.labels?.length > 0) {
          payload?.OrderShipment?.labels.forEach((attachment, index) => {
            formData.append(`shipment_media[${idx}][file]`, attachment);
            formData.append(`shipment_media[${idx}][type]`, "label");
            formData.append(
              `shipment_media[${idx}][carrier]`,
              payload?.OrderShipment?.shipping_company?.name ?? ""
            );
            formData.append(
              `shipment_media[${idx}][tracking_id]`,
              payload?.OrderShipment?.shipment_tracking_id ?? ""
            );
            idx++;
          });
        }
        if (payload?.OrderShipment?.declaration?.length > 0) {
          payload?.OrderShipment?.declaration.forEach((attachment, index) => {
            formData.append(`shipment_media[${idx}][file]`, attachment);
            formData.append(`shipment_media[${idx}][type]`, "declaration");
            formData.append(`shipment_media[${idx}][carrier]`, "label");
            formData.append(
              `shipment_media[${idx}][carrier]`,
              payload?.OrderShipment?.shipping_company?.name ?? ""
            );
            formData.append(
              `shipment_media[${idx}][tracking_id]`,
              payload?.OrderShipment?.shipment_tracking_id ?? ""
            );
            idx++;
          });
        }
        formData.append(
          "deleted_shipment_media_id",
          JSON.stringify(payload?.OrderShipment?.deleted_shipment_media_id)
        );
        formData.append("shipment_data", JSON.stringify(shipment_data));
      }

      delete payloadData.OrderShipment;
      delete payloadData.order_shipping_address_details;
      delete payloadData.order_billing_address_details;
      delete payloadData.order_pickup_details;
      delete payloadData.fullfilment_status;
      delete payloadData.shipment;
      delete payloadData.manual_shipment;

      formData.append("order", JSON.stringify(payloadData));

      const response = await updateOrder(formData, data?.id);

      if (response.error) {
        setCreatingOrder(false);
        setSnack({
          ...snack,
          open: true,
          text:
            response?.error?.response?.data?.message ?? "Something went wrong!",
          severity: "error",
        });
      } else {
        setSnack({
          ...snack,
          open: true,
          text: response?.data?.message,
          severity: "success",
        });
        setCreatingOrder(false);
        navigate("/orders");
      }
    } catch (error) {
      setCreatingOrder(false);
      console.log("error", error);
      setSnack({
        ...snack,
        open: true,
        text: error?.message || "Something went wrong!",
        severity: "error",
      });
    }
  };

  const getOrder = async (id) => {
    setLoading(true);
    let response = await fetchSingleOrder(id);
    if (!response.error) {
      const {
        order_details,
        order_billing_address_details,
        order_pickup_details,
        order_shipping_address_details,
        type,
        warehouse_id,
      } = response?.data?.result;

      setEditMode(location.state?.editMode);

      const excludeFields = ({ created_at, updated_at, ...rest }) => rest;

      const warehouse = excludeFields(
        order_pickup_details[0] ?? order_pickup_details
      );

      const billing = excludeFields(
        order_billing_address_details[0] ?? order_billing_address_details
      );

      // const orderBillingAddressCopy = { ...order_billing_address_details };
      // delete orderBillingAddressCopy.email;

      const shipping = excludeFields(
        order_shipping_address_details[0] ?? order_shipping_address_details
      );

      let OrderShipment = {};

      if (response?.data?.result?.manual_shipment) {
        let shippingCompanmy = {};
        if (response?.data?.result?.manual_shipment?.manual_shipping_company) {
          shippingCompanmy = {
            id: response?.data?.result?.manual_shipment?.manual_shipping_company
              ?.id,
            type: "manual",
            name: response?.data?.result?.manual_shipment
              ?.manual_shipping_company?.shipping_company_name,
          };
        } else if (
          response?.data?.result?.manual_shipment?.shipping_company
            ?.shipping_company
        ) {
          shippingCompanmy = {
            id: response?.data?.result?.manual_shipment?.shipping_company?.id,
            type: "default",
            name: response?.data?.result?.manual_shipment?.shipping_company
              ?.shipping_company?.name,
          };
        }
        OrderShipment = {
          shipment_status:
            response?.data?.result?.manual_shipment?.shipment_status,
          shipment_tracking_id:
            response?.data?.result?.manual_shipment?.shipment_tracking_id,
          pickup_id: response?.data?.result?.manual_shipment?.pickup_id,
          pickup_date: response?.data?.result?.manual_shipment?.pickup_date,
          shipping_company: shippingCompanmy,
          prev_labels:
            response?.data?.result?.manual_shipment?.shipping_labels ?? [],
          prev_declaration:
            response?.data?.result?.manual_shipment?.shipping_declarations ??
            [],
          labels: [],
          declaration: [],
          deleted_shipment_media_id: [],
        };
      } else if (response?.data?.result?.shipment) {
        OrderShipment = {
          shipment_status: response?.data?.result?.shipment?.shipment_status,
          shipment_tracking_id:
            response?.data?.result?.shipment?.shipment_tracking_id,
          pickup_id: response?.data?.result?.shipment?.PickupID,
          pickup_date: response?.data?.result?.shipment?.PickupDate,
          shipping_company: {
            id: response?.data?.result?.shipment?.shipping_company?.id,
            type: "default",
            name: response?.data?.result?.shipment?.shipping_company
              ?.shipping_company?.name,
          },
          prev_labels: response?.data?.result?.shipment?.single_shipping_labels
            ? [response?.data?.result?.shipment?.single_shipping_labels]
            : [],
          prev_declaration: response?.data?.result?.shipment
            ?.single_shipping_invoice
            ? [response?.data?.result?.shipment?.single_shipping_invoice]
            : [],
          labels: [],
          declaration: [],
          deleted_shipment_media_id: [],
        };
      } else {
        OrderShipment = {
          shipment_status: "",
          shipment_tracking_id: "",
          pickup_id: "",
          pickup_date: "",
          shipping_company: null,
          prev_labels: [],
          prev_declaration: [],
          labels: [],
          declaration: [],
          deleted_shipment_media_id: [],
        };
      }
      setData({
        ...response?.data?.result,
        type: type,
        order_status_id:
          response?.data?.result?.fullfilment_status?.name?.toLowerCase() ===
          "attention"
            ? 5
            : response?.data?.result?.order_status_id,
        deletedItemIds: [],
        attachments: [],
        warehouse,
        billing,
        shipping,
        OrderShipment,
      });
      const orderDetails = order_details?.map((orderDetail) =>
        excludeFields(orderDetail)
      );

      setSelectedProducts(
        orderDetails?.map((orderDetail) => {
          return {
            ...orderDetail?.products,
            order_id: orderDetail?.id,
            product_lot_id: orderDetail?.product_lot_id,
            fulfillment_cost: orderDetail?.fulfillment_cost,
            quantity: parseInt(orderDetail?.item_quantity),
            length: orderDetail?.length,
            width: orderDetail?.width,
            height: orderDetail?.height,
            weight: orderDetail?.weight,
            note: orderDetail?.note ?? "",
          };
        })
      );
      setWarehouse(warehouse_id);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (location.state?.editMode === true) {
      const { id } = location.state?.previousData;
      if (id) {
        getOrder(id);
      }
    }
  }, [location]);

  return (
    <div className="sales__section_screen">
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <OrderHistoryModal
        open={notesHistoryModal}
        setOpen={setNotesHistoryModal}
      />

      {loading === false ? (
        <>
          <div className="saless__page_header_sec">
            <div className="add__prod_sheader hide_mobile_btn">
              <Link to={"/orders"}>
                <BiArrowBack size={22} className="add__prod_sheaderback" />
              </Link>
              Orders
            </div>
          </div>
          <div className="saless__address_section">
            {editMode && (
              <div className="saless__address_sectionleft">
                <PickupAddress data={data} setData={setData} />
              </div>
            )}
            <div className="saless__address_sectionright">
              <DeliveryAddress
                data={data}
                setData={setData}
                editMode={editMode}
              />
            </div>
          </div>
          <div className="saless__products_section">
            <div className="saless__products_sectionleft">
              <AvailableProducts
                data={data}
                setData={setData}
                SelectedProducts={SelectedProducts}
                setSelectedProducts={setSelectedProducts}
                Warehouse={Warehouse}
                setWarehouse={setWarehouse}
                editMode={editMode}
              />
            </div>
          </div>
          <div className="saless__products_section">
            <div className="saless__products_sectionright">
              <Products
                data={data}
                setData={setData}
                SelectedProducts={SelectedProducts}
                setSelectedProducts={setSelectedProducts}
                Warehouse={Warehouse}
                setWarehouse={setWarehouse}
                Setformula={Setformula}
                formula={formula}
                editMode={editMode}
              />
            </div>
          </div>
          <div className="saless__address_section">
            <div className="saless__address_sectionleft">
              <PaymentDetails
                data={data}
                setData={setData}
                editMode={editMode}
                setEditMode={setEditMode}
              />
            </div>
            <div className="saless__address_sectionright">
              <ShipmentDetails
                data={data}
                setData={setData}
                editMode={editMode}
                setEditMode={setEditMode}
                setSnack={setSnack}
              />
            </div>
          </div>
          <Attachments
            data={data}
            setData={setData}
            notesHistoryModal={notesHistoryModal}
            setNotesHistoryModal={setNotesHistoryModal}
            editMode={editMode}
          />
          <div className="saless__bottomcreatebtn_wrap">
            <button
              className="saless__bottomcreate_btn"
              onClick={editMode ? handleUpdateOrder : handleCreateOrder}
            >
              {creatingOrder ? (
                <CircularLoader />
              ) : (
                `${editMode ? "Update Order" : "Create Order"}`
              )}
            </button>
          </div>
        </>
      ) : (
        <div className="saless__order_loader">
          <CarrierTableLoader />
        </div>
      )}
    </div>
  );
};

export default Sales;
