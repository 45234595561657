import React, { useEffect, useState } from "react";
import "./AddBalance.css";

// components imports
// import StripeCreditCardForm from "./StripeCreditCardForm";
import Loader from "../../../../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import {
  addLTLBalance,
  getLTLdetails,
} from "../../../../../../../../services/ShipperServices/Payments/payments.services";
// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
// import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import Upload from "@mui/icons-material/CloudUpload";
import BankTransfer from "../../../../../../../../assets/images/bank-transfer.png";
import ETransfer from "../../../../../../../../assets/images/e-transfer.png";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import {
  FcNeutralTrading,
  FcReading,
  FcOrganization,
  FcCheckmark,
  FcBusinessContact,
  FcDepartment,
  FcFeedback,
  FcUnlock,
} from "react-icons/fc";
import { MdOutlineContentCopy } from "react-icons/md";
import InputLabel from "@mui/material/InputLabel";
import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import Tooltip from "../../../../../../../../Components/Tooltip/Tooltip";

const initialData = {
  balance: "",
  file: null,
  payment_type: "bank_transfer",
  transaction_id: "",
};
const bankInfo = {
  bank: "The Bank of Nova Scotia",
  title: "HAJEX Inc.",
  iban: "0313815",
  transit: "12781",
  institution: "002",
};
export default function AddBalance({ open, setOpen, setSnack, setRefresh }) {
  const user = useSelector((state) => state.user);
  const [selectedWarehouse, setSelectedWarehouse] = useState();

  const [data, setData] = useState(initialData);
  const [activeBox, setActiveBox] = useState(1);
  const [details, setDetails] = useState(1);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [eTransCopiedCode, seteTransCopiedCode] = useState(false);
  const [eTransCopiedId, seteTransCopiedId] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setActiveBox(1);
    setData(initialData);
    setSelectedWarehouse();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleNextClick = (type) => {
    if (selectedWarehouse) {
      type && setData({ ...data, payment_type: type });
      setActiveBox(activeBox + 1);
    }
  };

  const handlePrevClick = () => {
    setActiveBox(activeBox - 1);
  };
  function handleCopy() {
    navigator.clipboard.writeText(details?.account_no);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }

  const handleWarehouseChange = (event) => {
    setSelectedWarehouse(event.target.value);
    getDetails(event.target.value);
  };

  //eTrans Work start
  const eTransfer = {
    pymentId: "payments@hajex.com",
    code: "HAJEX",
  };
  const handleClickETrans = () => {
    setActiveBox(5);
  };
  const handlehomeClick = () => {
    setActiveBox(1);
  };
  function handleETransferCopy(textToCopy, activeThis) {
    navigator.clipboard.writeText(textToCopy);
    if (activeThis === "eTransCode") {
      seteTransCopiedCode(true);
    }

    if (activeThis === "eTransId") {
      seteTransCopiedId(true);
    }
    setTimeout(() => {
      seteTransCopiedCode(false);
      seteTransCopiedId(false);
    }, 2000);
  }
  // eTrans work end

  const handleSave = async () => {
    setLoading(true);

    const payload = {
      balance: data.balance,
      payment_type: data.payment_type,
      transaction_id: data.transaction_id,
      file: data.file,
    };
    const formData = new FormData();
    formData.append("balance", payload.balance);
    formData.append("transaction_id", payload.transaction_id);
    formData.append("payment_type", payload.payment_type);
    payload.file && formData.append("file", payload.file);
    const saved = await addLTLBalance(formData, selectedWarehouse);
    if (!saved.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Balance Added successfully",
          severity: "success",
        };
      });
      setRefresh(Math.floor(Math.random() * 100) + 1);
      setData(initialData);
      setLoading(false);
      handleClose();
    }
  };
  const getDetails = async (id) => {
    const response = await getLTLdetails(id);
    if (!response.error) {
      setDetails(response?.data?.result);
    }
  };
  const handleFileChange = (e) => {
    setData({ ...data, file: e.target.files[0] });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="pmtsadd__balance_modal">
          <div className="pmtsadd__balance_modalheading">Add Credit</div>
          <div className="pmtsadd__balance_modalcontent">
            <div
              className={`pmtsadd__balance_modalcontentbox ${
                activeBox === 1
                  ? "pmtsadd__balance_modalcontentactivebox"
                  : activeBox > 1
                  ? "pmtsadd__balance_modalcontentboxleft"
                  : "pmtsadd__balance_modalcontentboxright"
              }`}
            >
              <div className="pmtsadd__balance_methods">
                <div className="pmtsadd__balance_modalrow">
                  <div className="pmtsadd__balance_modalinputwrap">
                    <FormControl
                      size="small"
                      sx={{ width: "100%", marginBottom: "8px" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Select Warehouse
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedWarehouse}
                        label="Select Warehouse"
                        onChange={handleWarehouseChange}
                      >
                        {user?.user_approved_warehouses?.map((warehouse) => (
                          <MenuItem
                            key={warehouse?.warehouse?.id}
                            value={warehouse?.id}
                          >
                            {`#${warehouse?.warehouse?.id} ${warehouse?.warehouse?.name}`}{" "}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <Tooltip title={!selectedWarehouse ? "Select a Warehouse" : ""}>
                  <div
                    className="pmtsadd__balance_method"
                    // className="pmtsadd__balance_method disable__click "
                    onClick={() => handleNextClick("bank_transfer")}
                  >
                    <div className="pmtsadd__balance_methodicon">
                      <img src={BankTransfer} alt="" />
                    </div>
                    <div className="pmtsadd__balance_methodtxt">
                      Bank Transfer
                    </div>
                  </div>
                </Tooltip>
                {/* <div
                  className="pmtsadd__balance_method "
                  onClick={() => handleClickETrans("e_transfer")}
                >
                  <div className="pmtsadd__balance_methodicon">
                    <img src={ETransfer} alt="" />
                  </div>
                  <div className="pmtsadd__balance_methodtxt">E Transfer</div>
                </div> */}
              </div>
            </div>
            <div
              className={`pmtsadd__balance_modalcontentbox ${
                activeBox === 2
                  ? "pmtsadd__balance_modalcontentactivebox"
                  : activeBox > 2
                  ? "pmtsadd__balance_modalcontentboxleft"
                  : "pmtsadd__balance_modalcontentboxright"
              }`}
            >
              {details ? (
                <>
                  <div className="pmtsadd__balance_modaltxtrow">
                    <div className="pmtsadd__balance_modaltxtlft">
                      <FcReading size={25} />
                      Account Title
                    </div>
                    <div className="pmtsadd__balance_modaltxtrt">
                      {details?.account_title}
                    </div>
                  </div>
                  <div className="pmtsadd__balance_modaltxtrow">
                    <div className="pmtsadd__balance_modaltxtlft">
                      <FcBusinessContact size={25} />
                      Account
                    </div>
                    <div className="pmtsadd__balance_modaltxtrt">
                      {details?.account_no}{" "}
                      {!copied && (
                        <span
                          className="pmtsadd__balance_modaltxtrtcopy"
                          onClick={handleCopy}
                        >
                          <MdOutlineContentCopy
                            size={22}
                            style={{ marginLeft: "10px" }}
                          />
                        </span>
                      )}
                      {copied && (
                        <span
                          className="pmtsadd__balance_modaltxtrtcopy"
                          onClick={handleCopy}
                        >
                          <FcCheckmark
                            size={22}
                            style={{ marginLeft: "10px" }}
                          />
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="pmtsadd__balance_modaltxtrow">
                    <div className="pmtsadd__balance_modaltxtlft">
                      <FcNeutralTrading size={25} />
                      Transit
                    </div>
                    <div className="pmtsadd__balance_modaltxtrt">
                      {details?.transit}
                    </div>
                  </div>
                  <div className="pmtsadd__balance_modaltxtrow">
                    <div className="pmtsadd__balance_modaltxtlft">
                      <FcOrganization size={25} />
                      Institution
                    </div>
                    <div className="pmtsadd__balance_modaltxtrt">
                      {details?.institution}
                    </div>
                  </div>

                  <div className="pmtsadd__balance_modaltxtrow">
                    <div className="pmtsadd__balance_modaltxtlft">
                      <FcDepartment size={25} /> Bank Name
                    </div>
                    <div className="pmtsadd__balance_modaltxtrt">
                      {details?.bank_name}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  No Payment Details Available. <br /> Contact Warehouse For
                  Details.
                </>
              )}

              <br />
              <div className="pmtsadd__balance_modalbackbtns">
                {" "}
                <button
                  onClick={handlePrevClick}
                  className="pmtsadd__balance_modalbackbtn"
                >
                  <FaArrowAltCircleLeft size={15} color="grey" />
                  Back
                </button>
                <button
                  onClick={(e) => handleNextClick()}
                  className="pmtsadd__balance_modalbackbtn"
                >
                  Next
                  <FaArrowAltCircleRight
                    size={15}
                    color="grey"
                    style={{ marginRight: 0 }}
                  />
                </button>
              </div>
            </div>
            <div
              className={`pmtsadd__balance_modalcontentbox ${
                activeBox === 3
                  ? "pmtsadd__balance_modalcontentactivebox"
                  : activeBox > 3
                  ? "pmtsadd__balance_modalcontentboxleft"
                  : "pmtsadd__balance_modalcontentboxright"
              }`}
            >
              {/* <div className="pmtsadd__balance_modalrow">
                <div className="pmtsadd__balance_modalinputwrap">
                  <FormControl
                    size="small"
                    sx={{ width: "40%", marginBottom: "8px" }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Warehouse
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedWarehouse}
                      label="Warehouse"
                      onChange={handleWarehouseChange}
                    >
                      {user?.user_approved_warehouses?.map((warehouse) => (
                        <MenuItem
                          key={warehouse?.warehouse?.id}
                          value={warehouse?.id}
                        >
                          {`#${warehouse?.warehouse?.id} ${warehouse?.warehouse?.name}`}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div> */}
              <div className="pmtsadd__balance_modalrow">
                <div className="pmtsadd__balance_modalinputwrap">
                  <input
                    className="pmtsadd__balance_modalinput"
                    placeholder="Paid Amount"
                    name="balance"
                    value={data.balance}
                    onChange={handleChange}
                    type="number"
                  />
                </div>
              </div>
              <div className="pmtsadd__balance_modalrow">
                <div className="pmtsadd__balance_modalinputwrap">
                  <input
                    className="pmtsadd__balance_modalinput"
                    placeholder="Transaction ID"
                    name="transaction_id"
                    value={data.transaction_id}
                    onChange={handleChange}
                    type="text"
                  />
                </div>
              </div>
              <div className="pmtsadd__balance_modalrow">
                <div className="pmtsadd__balance_modalinputwrap">
                  <label className="pmtsadd__balance_profimagelbl">
                    <Upload /> {data.file?.name ?? "Upload Payment Reciept"}
                    <input
                      type="file"
                      size="60"
                      className="pmtsadd__balance_profimageinput"
                      onChange={handleFileChange}
                      accept=".jpg,.png"
                    />
                  </label>
                </div>
              </div>
              <button
                onClick={handlePrevClick}
                className="pmtsadd__balance_modalbackbtn"
              >
                <FaArrowAltCircleLeft size={15} color="grey" />
                Back
              </button>
            </div>

            <div
              className={`pmtsadd__balance_modalcontentbox ${
                activeBox === 5
                  ? "pmtsadd__balance_modalcontentactivebox"
                  : activeBox > 5
                  ? "pmtsadd__balance_modalcontentboxleft"
                  : "pmtsadd__balance_modalcontentboxright"
              }`}
            >
              <div className="pmtsadd__balance_modaltxtrow">
                <div className="pmtsadd__balance_modaltxtlft">
                  <FcFeedback size={25} />
                  Id
                </div>
                <div className="pmtsadd__balance_modaltxtrt">
                  {eTransfer.pymentId}{" "}
                  {!eTransCopiedId && (
                    <span
                      className="pmtsadd__balance_modaltxtrtcopy"
                      onClick={() =>
                        handleETransferCopy(eTransfer.pymentId, "eTransId")
                      }
                    >
                      <MdOutlineContentCopy
                        size={22}
                        style={{ marginLeft: "10px" }}
                      />
                    </span>
                  )}
                  {eTransCopiedId && (
                    <span
                      className="pmtsadd__balance_modaltxtrtcopy"
                      onClick={() =>
                        handleETransferCopy(eTransfer.pymentId, "eTransId")
                      }
                    >
                      <FcCheckmark size={22} style={{ marginLeft: "10px" }} />
                    </span>
                  )}
                </div>
              </div>
              <br />
              <div className="pmtsadd__balance_modaltxtrow">
                <div className="pmtsadd__balance_modaltxtlft">
                  <FcUnlock size={25} />
                  Code
                </div>
                <div className="pmtsadd__balance_modaltxtrt">
                  {eTransfer.code}{" "}
                  {!eTransCopiedCode && (
                    <span
                      className="pmtsadd__balance_modaltxtrtcopy"
                      onClick={() =>
                        handleETransferCopy(eTransfer.code, "eTransCode")
                      }
                    >
                      <MdOutlineContentCopy
                        size={22}
                        style={{ marginLeft: "10px" }}
                      />
                    </span>
                  )}
                  {eTransCopiedCode && (
                    <span
                      className="pmtsadd__balance_modaltxtrtcopy"
                      onClick={() =>
                        handleETransferCopy(eTransfer.code, "eTransCode")
                      }
                    >
                      <FcCheckmark size={22} style={{ marginLeft: "10px" }} />
                    </span>
                  )}
                </div>
              </div>
              <br />
              <div className="pmtsadd__balance_modalbackbtns">
                {" "}
                <button
                  onClick={handlehomeClick}
                  className="pmtsadd__balance_modalbackbtn"
                >
                  <FaArrowAltCircleLeft size={15} color="grey" />
                  Back
                </button>
              </div>
            </div>
          </div>
          <div className="pmtsadd__balance_modalfooter">
            <div className="pmtsadd__balance_modalcncl" onClick={handleClose}>
              Cancel
            </div>
            {activeBox >= 3 && (
              <div
                className={`pmtsadd__balance_modalsave ${
                  (!(
                    activeBox <= 3 &&
                    data.balance != "" &&
                    data.transaction_id != "" &&
                    data.file != null
                  ) ||
                    activeBox == 4) &&
                  "pmtsadd__balance_modalsavedisabled"
                }`}
                onClick={handleSave}
              >
                {loading ? <Loader /> : "Save"}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
