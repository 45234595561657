import axios from "axios";
import store from "../store/store";

const createAxiosInstance = () => {
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
      Authorization: "Bearer " + store.getState().user.token,
      Accept: "application/json",
      timeout: 1000,
    },
  });

  instance.interceptors.request.use((config) => {
    config.headers.Authorization = "Bearer " + store.getState().user.token;
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

const axiosInstance = createAxiosInstance();

export default axiosInstance;
