import axios from "../axiosInstance";

export const getAllTransferNotesRequest = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `warehouse/transfer-notes/get-received?perPage=${perPage}&page=${page}&filterValue=${filter}
        `
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateTransferNotesRequest = async (
  transferId,
  status,
  description
) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `warehouse/transfer-notes/update-status/${transferId}/${status}`,
      { description }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const addBalanceByWarehouse = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`warehouse/payment/add-balance/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
