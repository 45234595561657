import { Outlet, Navigate } from "react-router-dom";
import NavBar from "../Navbar/AuthenticationNavbar";

const AuthenticationRoutes = () => {
  let role = localStorage.getItem("Role");
  if (role === "Shipper" || role === "ShipperUser") {
    return <Navigate to="/" />;
  } else if (role === "Carrier") {
    return <Navigate to="/create-zone" />;
  } else if (role === "Admin") {
    return <Navigate to="/Users" />;
  } else {
    return (
      <>
        <Outlet />
      </>
    );
  }
  // else{
  //     return <><NavBar/><Outlet /></>;
  // }
};

export default AuthenticationRoutes;
