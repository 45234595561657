import React, { useEffect, useState } from "react";
import SearchField from "./elements/SearchField";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ProductDetailCard from "./ProductDetailCard";
import { FcSearch, FcEmptyFilter, FcFilledFilter } from "react-icons/fc";
import {
  BsFillBackspaceFill,
  BsFilter,
  BsGrid3X2,
  BsCardList,
} from "react-icons/bs";
import Tooltips from "@mui/material/Tooltip";

export default function PackagesScroller({
  fetchedPackages,
  fetchedCatData,
  onTotalWeightChange,
  onTotalProductCount,
  totalProductPrice,
  settotalProductPrice,
  setproductsTotalWeight,
  productsTotalWeight,
  selectedProducts,
  setSelectedProducts,
}) {
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const brandOptions = [
    ...new Set(fetchedCatData.map((item) => item.brandName)),
  ];
  const categoryOptions = selectedBrand
    ? [
        ...new Set(
          fetchedCatData
            .filter((item) => item.brandName === selectedBrand)
            .flatMap((item) => item.category.map((cat) => cat.categoryName))
        ),
      ]
    : [];
  const subCategoryOptions = selectedCategory
    ? [
        ...new Set(
          fetchedCatData
            .filter((item) => item.brandName === selectedBrand)
            .flatMap((item) =>
              item.category
                .find((cat) => cat.categoryName === selectedCategory)
                ?.subCategory.map((subCat) => subCat.subCategoryName)
            )
        ),
      ]
    : [];

  const [searchInput, setSearchInput] = useState("");
  const [productLayout, setProductLayout] = useState("grid");
  const [filteredPackages, setFilteredPackages] = useState(fetchedPackages);

  const [FilterBrandFilter, setFilterBrandFilter] = useState(false);
  const [totalQuantity, setTotalQuantity] = useState(0);

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchInput(inputValue);
    const filteredResults = fetchedPackages.filter((packageItem) =>
      packageItem.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredPackages(filteredResults);
  };
  const handleLayoutClick = (layout) => {
    setProductLayout(layout);
  };

  const handleProductClick = (product) => {
    let updatedSelectedProducts = [...selectedProducts];
    const isProductSelected = updatedSelectedProducts.some(
      (selectedProduct) => selectedProduct.id === product.id
    );

    if (isProductSelected) {
      updatedSelectedProducts = updatedSelectedProducts.map((selectedProduct) =>
        selectedProduct.id === product.id
          ? { ...selectedProduct, quantity: selectedProduct.quantity + 1 }
          : selectedProduct
      );
    } else {
      updatedSelectedProducts = [
        ...updatedSelectedProducts,
        { ...product, quantity: 1 },
      ];
    }

    const totalWeight = updatedSelectedProducts.reduce(
      (acc, selectedProduct) =>
        acc + selectedProduct.weight * selectedProduct.quantity,
      0
    );

    setSelectedProducts(updatedSelectedProducts);
    // setproductsTotalWeight(totalWeight);
    onTotalWeightChange(totalWeight);
  };

  const deleteProduct = (rowNumber) => {
    const deletedProduct = selectedProducts[rowNumber];
    const updatedProducts = selectedProducts.filter(
      (_, index) => index !== rowNumber
    );
    const deletedProductWeight =
      deletedProduct.weight * deletedProduct.quantity;
    const updatedTotalWeight = productsTotalWeight - deletedProductWeight;
    setSelectedProducts(updatedProducts);
    // setproductsTotalWeight(updatedTotalWeight);
    onTotalWeightChange(updatedTotalWeight);
  };

  useEffect(() => {
    let totalWeight = 0;
    selectedProducts.forEach((product) => {
      totalWeight += product.weight * product.quantity;
    });
    setproductsTotalWeight(totalWeight);
  }, [selectedProducts, totalQuantity]);

  const brandFilterRender = () => {
    setFilterBrandFilter(true);
  };

  const updateTotalQuantity = (newTotalQuantity) => {
    setTotalQuantity(newTotalQuantity);
    onTotalProductCount(newTotalQuantity);
  };

  const newQuantityManualAdd = (valChange, weight) => {
    // setproductsTotalWeight(weight * valChange);
    onTotalWeightChange(weight * valChange);
  };

  return (
    <div className="productcards__porductDetails_orderdetails">
      <div className="productCards__productDetails_main_wrap">
        <div className="productCards__productDetails_header_main">
          <h2>Products </h2>
          <SearchField
            label="Search Product"
            name="searchProduct"
            placeholder="Search Product"
            value={searchInput}
            onChange={handleSearchInputChange}
          />
        </div>
        <div className="productCards__productDetails_filters_sort_wrap">
          <div className="productCards__productDetails_filters_wrap">
            {/* <h2>Filters</h2> */}
            <div className="productCards__productDetails_filters_tags">
              <div
                className="productCards__productDetails_brand_btn"
                onClick={brandFilterRender}
              >
                Filters{" "}
                <BsFilter className="editabletab__searchbar_icon" size={20} />{" "}
              </div>
              {FilterBrandFilter && (
                <div className="productCards__productDetails_filters_brand">
                  <div
                    className="productCards__productDetails_filters_back"
                    onClick={() => setFilterBrandFilter(false)}
                  ></div>
                  <div className="productCards__productDetails_filters_box">
                    <Autocomplete
                      id="filters-autocomplete-brand"
                      options={brandOptions}
                      value={selectedBrand}
                      onChange={(event, newValue) => {
                        setSelectedBrand(newValue);
                        setSelectedCategory(null);
                        setSelectedSubCategory(null);
                      }}
                      style={{ minWidth: 200 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Brand Name"
                          variant="standard"
                        />
                      )}
                    />

                    {selectedBrand && (
                      <Autocomplete
                        id="filters-autocomplete-category"
                        options={categoryOptions}
                        value={selectedCategory}
                        onChange={(event, newValue) => {
                          setSelectedCategory(newValue);
                          setSelectedSubCategory(null);
                        }}
                        style={{ minWidth: 200 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Category"
                            variant="standard"
                          />
                        )}
                      />
                    )}

                    {selectedCategory && (
                      <Autocomplete
                        id="filters-autocomplete-subcategory"
                        options={subCategoryOptions}
                        value={selectedSubCategory}
                        onChange={(event, newValue) =>
                          setSelectedSubCategory(newValue)
                        }
                        style={{ minWidth: 200, fontSize: 12 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Subcategory"
                            variant="standard"
                            sx={{ fontSize: 12 }}
                          />
                        )}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="productCards__productDetails_sort_wrap">
            <div className="sort_tag">
              <Tooltips title="Grid View" placement="top">
                <a
                  type="button"
                  onClick={() => handleLayoutClick("grid")}
                  className={productLayout === "grid" ? "active__btn" : ""}
                >
                  <BsGrid3X2 size={35} className="p-2 overlaybtn" />
                  <span>Grid</span>
                </a>
              </Tooltips>
            </div>{" "}
            <div className="seprator__sort"> |</div>
            <div className="sort_tag">
              <Tooltips title="List View" placement="top">
                <a
                  type="button"
                  onClick={() => handleLayoutClick("list")}
                  className={productLayout === "list" ? "active__btn" : ""}
                >
                  <BsCardList size={35} className="p-2 overlaybtn" />

                  <span>List</span>
                </a>
              </Tooltips>
            </div>
          </div>
        </div>
        <div
          className={
            productLayout === "grid"
              ? "productCards__productDetails_body_main"
              : "productCards__productDetails_body_main productLayout__list"
          }
        >
          {filteredPackages.length > 0 ? (
            filteredPackages.map((packageItem) => (
              <div
                key={packageItem.id}
                className="product__box_main"
                onClick={() => handleProductClick(packageItem)}
              >
                <strong>{packageItem.name}</strong>
                {packageItem.file && <img src={packageItem.file} alt="-" />}

                {packageItem.warehouse ? (
                  <div>
                    {packageItem.warehouse.slice(0, 2).map((warehouse) => (
                      <div
                        key={warehouse.warehouseId}
                        className="warehouse__count_name"
                      >
                        <span>{warehouse.warehouseName}</span>
                        <span>{warehouse.warehouseCount} Left</span>
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
                <div className="productCards__productDetails_overlay_main">
                  <h3>{packageItem.name}</h3>
                  <div className="productCards__productDetails_overlay_dimension">
                    {packageItem.length}X{packageItem.width}X
                    {packageItem.height}
                  </div>
                  <div className="productCards__productDetails_overlay_weight">
                    {packageItem.weight}
                  </div>
                  <div className="productCards__productDetails_sku_col">
                    {packageItem.warehouse ? (
                      <div>
                        {packageItem.warehouse.map((warehouse) => (
                          <div key={warehouse.warehouseId}>
                            SKU {warehouse.warehouseName} :{" "}
                            {warehouse.warehouseProductSKU}
                          </div>
                        ))}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <h3>Stocks Information</h3>
                  <div className="productCards__productDetails_overlay_warehouse_inventory">
                    {packageItem.warehouse ? (
                      <div className="warehouse__count_name_overlay_wrap">
                        {packageItem.warehouse.map((warehouse) => (
                          <div
                            key={warehouse.warehouseId}
                            className="warehouse__count_name_overlay_col"
                          >
                            <span>{warehouse.warehouseName} &nbsp;</span>
                            <span> {warehouse.warehouseCount} Left</span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No Product Found.</p>
          )}
        </div>
      </div>
      <ProductDetailCard
        totalProductPrice={totalProductPrice}
        settotalProductPrice={settotalProductPrice}
        selectedProducts={selectedProducts}
        deleteProduct={deleteProduct}
        productsTotalWeight={productsTotalWeight}
        updateTotalQuantity={updateTotalQuantity}
        newQuantityManualAdd={newQuantityManualAdd}
      />
    </div>
  );
}
