import React from "react";
import ReactDOM from "react-dom";
import { Popper, Paper, Box } from "@mui/material";
import ClickAwayListener from '@mui/base/ClickAwayListener';


const colors = [
    "#F0F8FF",
    "#FAEBD7",
    "#7FFFD4",
    "#FFE4C4",
    "#8A2BE2",
    "#5F9EA0",
    "#DEB887",
    "#7FFF00",
    "#D2691E",
    "#6495ED",
    "#FF7F50",
    "#DC143C",
    "#00CED1",
    "#9400D3",
    "#FFD700",
    "#4B0082",
    "#FF69B4",
    "#F08080",
    "#90EE90",
    "#20B2AA",
    "#87CEFA",
    "#778899",
    "#FFB6C1",
    "#32CD32"
];







const CustomColorBox = ({ anchorEl, open, onClose, onSelectColor }) => {
    return ReactDOM.createPortal(
        <Popper
            open={open}
            anchorEl={anchorEl}
            placement="bottom"
            style={{ zIndex: 1500, position: 'absolute' }}
        >
            <ClickAwayListener onClickAway={onClose}>
                <Paper elevation={3} style={{ backgroundColor: '#fff' }}>
                    <Box className="grid grid-cols-6 gap-2 p-2">
                        {colors.map((color) => (
                            <Box
                                key={color}
                                sx={{
                                    width: 24,
                                    height: 24,
                                    backgroundColor: color,
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                    border: "1px solid #ccc",
                                }}
                                onClick={() => onSelectColor(color)}
                            />
                        ))}
                    </Box>
                </Paper>
            </ClickAwayListener>
        </Popper>,
        document.body // Render to the body to avoid clipping issues
    );
};

export default CustomColorBox;
