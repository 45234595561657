import React, { useState, useEffect } from "react";
import "../InvoiceModal.css";

// components imports
import CarrierTableLoader from "../../../Components/CarrierInnerTableLoader/CarrierInnerTableLoader";
// apis imports
// library imports
import { FaRegTimesCircle } from "react-icons/fa";
import { MdArrowCircleDown } from "react-icons/md";
import International from "./InvoiceSubComponents/International";
import ShipmentSummary from "./InvoiceSubComponents/ShipmentSummary";
import Tracking from "./InvoiceSubComponents/Tracking";
import Header from "./InvoiceSubComponents/Header";
import ShipmentDetails from "./InvoiceSubComponents/ShipmentDetails";
import Footer from "./InvoiceSubComponents/Footer";
import BillingUser from "./InvoiceSubComponents/BillingUser";
import Notes from "./InvoiceSubComponents/NotesUser";
import AdminActions from "./InvoiceSubComponents/AdminActions";
import { useSelector } from "react-redux";

const StaticInvoice = ({
  data,
  handleClose,
  downloadPdf,
  printLabel,
  setData,
  setShipments,
  getAllShipments,
  actions = true,
  loading,
}) => {
  const user = useSelector((state) => state.user);

  return (
    <>
      <div className="invoice__modal_wrap invoice__modal_style_optamize_wrap static__invoice_wrap">
        {loading === false ? (
          <>
            <div className="invoice__modal_box invoice__shipment_style_optamize_wrap">
              <div className="invoice__modal_downloadinvoicewrap">
                <button
                  onClick={() => downloadPdf(data)}
                  className="invoicemdl__download_btn"
                >
                  <MdArrowCircleDown size={16} />
                  <div className="labeltxt">
                  <span className="invoivemdl__btn_dwn_text">
                    Download
                  </span>{" "}
                  Invoice
                  </div>
                </button>
                <button
                  onClick={() => printLabel(data)}
                  className="invoicemdl__download_btnoutlined"
                >
                  <MdArrowCircleDown size={16} />
                  <div className="labeltxt">
                    <span className="invoivemdl__btn_dwn_text">
                    Download
                  </span>{" "}
                  Label
                  </div>                  
                </button>
                <button
                  onClick={handleClose}
                  className="invoicemdl__download_btn_danger"
                >
                  <FaRegTimesCircle size={16} />
                  <div className="labeltxt">
                  Close
                  </div>
                </button>
              </div>
              <Header data={data} />
              <ShipmentSummary data={data} />
              <International data={data} />
              <ShipmentDetails data={data} />
              {user?.role != "warehouseUser" && actions != false && (
                <AdminActions
                  type="user"
                  data={data}
                  setShipments={setShipments}
                  setData={setData}
                  getAllShipments={getAllShipments}
                />
              )}
              <BillingUser data={data} />
              <Notes data={data} />
              <Tracking data={data} />
              <Footer data={data} />
            </div>
          </>
        ) : (
          <div className="fulfilment_tier_loader">
            <CarrierTableLoader />
          </div>
        )}
      </div>
    </>
  );
};

export default StaticInvoice;
