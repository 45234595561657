import React, { useState } from "react";
import "./settings.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import SideBar from "./Components/SideBar/SideBar";
import ShipperUsers from "./Components/ManageShipperUsers/ManageShipperUsers";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function HelperUserSettings(props) {
  //create roles

  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    text: "",
    severity: "",
  });

  const handleSnackClose = () => {
    setSnack({ ...snack, open: false });
  };
  return (
    <>
      <div className="parent_wraper">
        {/* sidebar code  */}
        <SideBar active="1_0" />
        <div className="dash_table_wrapper">
          {/* right side secions  */}
          <Snackbar
            open={snack.open}
            autoHideDuration={6000}
            onClose={handleSnackClose}
          >
            <Alert
              onClose={handleSnackClose}
              severity={snack.severity}
              sx={{ width: "100%" }}
            >
              {snack.text}
            </Alert>
          </Snackbar>
          <ShipperUsers />
        </div>
      </div>
    </>
  );
}

export default HelperUserSettings;
