import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { CiMenuKebab } from "react-icons/ci";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  MdOutlineStickyNote2,
  MdOutlineInventory2,
  MdRemoveRedEye,
  MdAdd,
  MdOutlineHistory,
  MdLockReset,
} from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { RiSubtractLine } from "react-icons/ri";
import { PiUsersThree } from "react-icons/pi";
import { CiCalculator1 } from "react-icons/ci";

export default function CustomMenu({
  row,
  setAddBalanceModal,
  setSubtractBalanceModal,
  setChangePassword,
  setEditUserModal,
  setOpenDetailsModal,
  setOpenClientsModal,
  setOpenProductsModal,
  setHistoryModal,
  setConfirmDelete,
  setFormulaModal,
  setInvoicesHistoryModal,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="table_action__wrap">
      <div className="table_action__btn" onClick={handleClick}>
        <CiMenuKebab size={10} style={{ marginRight: "2px" }} />
        More
      </div>
      <Menu
        id={`basic-menu-${row.id}`} // Unique ID for each row
        anchorEl={anchorEl}
        open={open}
        size="small"
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": `basic-button-${row.id}`, // Unique ID for each row
          "box-shadow": "none",
        }}
      >
        <MenuItem
          className="boltTable__action_more_btn_label"
          key="Add"
          onClick={() => {
            setAddBalanceModal(row);
            handleClose();
          }}
        >
          <ListItemIcon>
            <MdAdd size={15} />
          </ListItemIcon>
          <ListItemText>Add Balance</ListItemText>
        </MenuItem>
        <MenuItem
          className="boltTable__action_more_btn_label"
          onClick={() => {
            setSubtractBalanceModal(row);
            handleClose();
          }}
        >
          <ListItemIcon>
            <RiSubtractLine size={15} />
          </ListItemIcon>
          <ListItemText>Subtract Balance</ListItemText>
        </MenuItem>
        <MenuItem
          className="boltTable__action_more_btn_label"
          onClick={() => {
            setChangePassword(row.user_id);
            handleClose();
          }}
        >
          <ListItemIcon>
            <MdLockReset size={15} />
          </ListItemIcon>
          <ListItemText>Reset Password</ListItemText>
        </MenuItem>
        <MenuItem
          className="boltTable__action_more_btn_label"
          onClick={() => {
            setEditUserModal(row);
            handleClose();
          }}
        >
          <ListItemIcon>
            <FaRegEdit size={15} />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem
          className="boltTable__action_more_btn_label"
          onClick={() => {
            setOpenDetailsModal(row);
            handleClose();
          }}
        >
          <ListItemIcon>
            <MdRemoveRedEye size={15} />
          </ListItemIcon>
          <ListItemText>Details</ListItemText>
        </MenuItem>
        <MenuItem
          className="boltTable__action_more_btn_label"
          onClick={() => {
            setFormulaModal(row);
            handleClose();
          }}
        >
          <ListItemIcon>
            <CiCalculator1 size={15} />
          </ListItemIcon>
          <ListItemText>Formula</ListItemText>
        </MenuItem>
        <MenuItem
          className="boltTable__action_more_btn_label"
          onClick={() => {
            setInvoicesHistoryModal(row);
            handleClose();
          }}
        >
          <ListItemIcon>
            <MdOutlineStickyNote2 size={15} />
          </ListItemIcon>
          <ListItemText>Invoices</ListItemText>
        </MenuItem>
        <MenuItem
          className="boltTable__action_more_btn_label"
          onClick={() => {
            setOpenClientsModal(row);
            handleClose();
          }}
        >
          <ListItemIcon>
            <PiUsersThree size={15} />
          </ListItemIcon>
          <ListItemText>Clients</ListItemText>
        </MenuItem>
        <MenuItem
          className="boltTable__action_more_btn_label"
          onClick={() => {
            setOpenProductsModal(row);
            handleClose();
          }}
        >
          <ListItemIcon>
            <MdOutlineInventory2 size={15} />
          </ListItemIcon>
          <ListItemText>Products</ListItemText>
        </MenuItem>
        <MenuItem
          className="boltTable__action_more_btn_label"
          onClick={() => {
            setHistoryModal(row);
            handleClose();
          }}
        >
          <ListItemIcon>
            <MdOutlineHistory size={15} />
          </ListItemIcon>
          <ListItemText>Transactions</ListItemText>
        </MenuItem>
        {/* <MenuItem
        className="boltTable__action_more_btn_label"
          onClick={() => {
            setConfirmDelete(row);
            handleClose();
          }}
        >
          <ListItemIcon>
            <BsTrash size={15} />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem> */}
      </Menu>
    </div>
  );
}
