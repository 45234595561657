import React, { useState } from "react";
import "./ManagePermissions.css";
import { useSelector } from "react-redux";
import { updateShipperUserData } from "../../../../../../../services/settings.services";

const ManagePermissions = ({
  users,
  roles,
  permisions,
  setUsers,
  setSnack,
  isShipper,
}) => {
  const user = useSelector((state) => state.user);

  const updateData = async (User, permissions, roles) => {
    let saved = await updateShipperUserData(
      user.token,
      {
        permissions: permissions,
        roles: roles,
      },
      User.id
    );
    if (!saved.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Permissions updated successfully",
          severity: "success",
        };
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: saved?.error?.response?.data?.message ?? "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const parentPermissions = async (User, parent, e) => {
    let array = [...users];
    let index = array.findIndex((u) => u.id === User.id);
    let ids = parent.children.map((c) => c.id);
    if (e.target.checked) {
      array[index].permissions = Array.from(
        new Set([...array[index].permissions, ...ids, parent.id])
      );
      setUsers(array);
      await updateData(
        User,
        [...array[index].permissions],
        [...array[index].roles]
      );
    } else {
      let elementsToRemove = [...ids, parent.id];
      array[index].permissions = array[index].permissions.filter((element) => {
        if (
          elementsToRemove.includes(element) &&
          array[index].rolesPermissions.includes(element)
        ) {
          return true;
        }
        return !elementsToRemove.includes(element);
      });
      setUsers(array);
      await updateData(
        User,
        [...array[index].permissions],
        [...array[index].roles]
      );
    }
  };

  const childPermissions = async (User, child, e) => {
    let array = [...users];
    let index = array.findIndex((u) => u.id === User.id);
    if (e.target.checked) {
      array[index].permissions.push(child.id);
      setUsers(array);
      await updateData(
        User,
        [...array[index].permissions],
        [...array[index].roles]
      );
    } else {
      array[index].permissions = array[index].permissions.filter(
        (p) => p !== child.id
      );
      setUsers(array);
      await updateData(
        User,
        [...array[index].permissions],
        [...array[index].roles]
      );
    }
  };

  const assignRoles = async (User, e) => {
    let array = [...users];
    let index = array.findIndex((u) => u.id === User.id);
    let rolePermissions = roles.find((r) => r.id == e.target.value).permissions;

    array[index].permissions = array[index].permissions.filter(
      (item) => !User.rolesPermissions.includes(item)
    );
    array[index].roles.splice(0, 1);
    array[index].roles.push(Number(e.target.value));
    array[index].permissions = Array.from(
      new Set([...array[index].permissions, ...rolePermissions])
    );
    array[index].rolesPermissions = Array.from(new Set([...rolePermissions]));
    setUsers(array);
    let saved = await updateShipperUserData(
      user.token,
      { roles: [Number(e.target.value)] },
      User.id
    );
    if (!saved.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Role Assigned successfully",
          severity: "success",
        };
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: saved?.error?.response?.data?.message ?? "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  return (
    <>
      <div className="mngpermission__tabel mt-5 ">
        <table id="customers">
          <tr className="mngpermissions__header_tr">
            <th className="mngpermissions__header_th">Permissions</th>
            {users.map((User, userIndex) => {
              return (
                <th key={userIndex} className="mngpermissions__header_th">
                  {User.name}
                  {isShipper ? (
                    <select
                      value={User?.roles[0]?.toString()}
                      onChange={(e) => assignRoles(User, e)}
                      className="ml-3"
                      sx={{
                        maxHeight: "30px",
                        "& *": {
                          maxHeight: "30px",
                          fontSize: "14px",
                        },
                      }}
                    >
                      {roles.map((role, index) => {
                        return (
                          <option value={role.id} key={index}>
                            {role.name}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    <>{" ( " + user.roles[0].name + " ) "}</>
                  )}
                </th>
              );
            })}
          </tr>
          {permisions?.map((data, index) => {
            return (
              <>
                <tr className="mngpermission__header" key={index}>
                  <td className="mngpermission__td1">{data?.description}</td>
                  {users.map((user, userIndex) => {
                    return (
                      <td className="mngpermission__td2" key={userIndex}>
                        <input
                          type="checkbox"
                          disabled={
                            isShipper
                              ? user.rolesPermissions.includes(data.id)
                              : true
                          }
                          checked={user.permissions.includes(data.id)}
                          onChange={(e) => parentPermissions(user, data, e)}
                        />
                      </td>
                    );
                  })}
                </tr>

                {data?.children?.map((child, cindex) => {
                  return (
                    <tr key={cindex} className="mngpermissions__sub_tr">
                      <td className="mngpermission__sub_td1">
                        {child.description}
                      </td>
                      {users.map((user, userIndex) => {
                        return (
                          <td
                            className="mngpermission__sub_td2"
                            key={userIndex}
                          >
                            <input
                              type="checkbox"
                              disabled={
                                isShipper
                                  ? user.rolesPermissions.includes(child.id)
                                  : true
                              }
                              checked={user.permissions.includes(child.id)}
                              onChange={(e) => childPermissions(user, child, e)}
                            />
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </>
            );
          })}
        </table>
      </div>
    </>
  );
};

export default ManagePermissions;
