import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function MapSelector({
  maps,
  selectedMap,
  setSelectedMap,
  handleSelectedMap,
}) {
  // Create a label function for each option
  const getOptionLabel = (option) => {
    return option ? `${option.name}` : "";
  };

  // Handle change of selected map
  const handleChange = (event, newValue) => {
    if (newValue) {
      setSelectedMap(newValue);
      handleSelectedMap(newValue);
    }
  };

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={maps}
      getOptionLabel={getOptionLabel}
      value={selectedMap}
      onChange={handleChange}
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField {...params} size="small" label="Selected Graph" />
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
}
