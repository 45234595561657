import React, { useState, useEffect, useRef } from "react";
import "./settings.css";

// components imports
import Snackbar from "../../../../../Components/SnackBar/SnackBar";
import Loader from "../../../../../Components/CircularLoader/CircularLoader";
import WarehouseTable from "../../../../../Components/BoltTable/BoltTable";
import Tooltip from "../../../../../Components/Tooltip/Tooltip";
import AddWarehouseUser from "./Components/ManageWarehouse/AddWarehouseUser/AddWarehouseUser";
import Confirm from "../../../../../Components/Popups/Confirm";
import SideBar from "./Components/SideBar/SideBar";

// apis imports

import {
  getWareHouseList,
  sendWareHouseRequest,
  getUserWarehouses,
  deleteWareHouseRequest,
} from "../../../../../services/ShipperServices/ShipperSettings/shippersettings.services";

// library imports
import { FcPlus } from "react-icons/fc";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { MdOutlineCancel } from "react-icons/md";
import { MdOutlineWarehouse } from "react-icons/md";
import StatusIcons from "../../../../../Components/StatusIcons/StatusIcons";

function WareHouseSettings(props) {
  const [loading, setLoading] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [wareHouseList, setWareHouseList] = useState([]);
  const [wareHouseData, setWareHouseData] = useState([]);
  const [deleteLoader, setdeleteLoader] = useState(false);
  const [refresh, setRefresh] = useState();

  const [addWarehouseUserModal, setAddWarehouseUserModal] = useState(false);

  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  const columns = [
    {
      id: 1,
      label: "#",
      render: (row) => <>{row?.warehouse?.id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 2,
      label: "Name",
      render: (row) => <>{row?.warehouse?.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Code",
      render: (row) => <>{row?.warehouse?.code ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "Address",
      render: (row) => <>{row?.warehouse?.address ?? "-"}</>,
      width: "200px",
    },
    {
      id: 5,
      label: "Status",
      render: (row) => (
        <StatusIcons
          status={
            row?.is_approved === "false"
              ? "Pending"
              : row?.is_approved === "notapplied"
              ? "Not Applied"
              : row?.is_approved === "true"
              ? "approved"
              : "-"
          }
        />
      ),
      width: "200px",
    },
    {
      id: 6,
      label: "Action",
      render: (row) => (
        <>
          <div className="table_action__wrap">
            {!(row?.is_approved === "false" || row?.is_approved === "true") && (
              <Tooltip title="Manage Request">
                <div
                  className="table_action__btn"
                  onClick={() => sendRequest(row)}
                >
                  {loading[row?.warehouse?.id] ? (
                    <Loader />
                  ) : (
                    <>
                      <MdOutlineWarehouse size={10} />
                      Request Access
                    </>
                  )}
                </div>
              </Tooltip>
            )}

            <Tooltip title="Delete Request">
              <div
                className="table_action__btn"
                onClick={() => setConfirmDelete(row)}
              >
                <MdOutlineCancel size={10} />
                Delete
              </div>
            </Tooltip>
          </div>
        </>
      ),
      width: "200px",
    },
  ];
  const sendRequest = async (data) => {
    setLoading((prevLoading) => ({
      ...prevLoading,
      [data?.warehouse?.id]: true,
    }));

    try {
      const saved = await sendWareHouseRequest(data?.warehouse?.id);
      setLoading((prevLoading) => ({
        ...prevLoading,
        [data?.warehouse?.id]: false,
      }));
      if (!saved.error) {
        setWareHouseData((prevData) => {
          const updatedData = prevData?.data?.map((item) => {
            if (item?.warehouse?.id === data?.warehouse.id) {
              return {
                warehouse: saved?.data?.result,
                is_approved: "false",
              };
            }
            return item;
          });
          return { ...prevData, data: updatedData };
        });

        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Request Sent successfully",
            severity: "success",
          };
        });
      } else {
        setLoading((prevLoading) => ({
          ...prevLoading,
          [data?.warehouse?.id]: false,
        }));
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text:
              saved?.error?.response?.data?.message ?? "Something went wrong",
            severity: "error",
          };
        });
      }
    } catch (error) {
      setLoading((prevLoading) => ({
        ...prevLoading,
        [data?.warehouse?.id]: false,
      }));
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };
  const selectWareHouse = (value) => {
    if (value === null) {
      return;
    }
    let isFound = wareHouseData?.data?.some(
      (w) => w.warehouse.code === value?.code
    );
    if (isFound) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Warehouse Already Exist",
          severity: "error",
        };
      });
      return;
    } else {
      let array = [...wareHouseData?.data];
      array.push({ warehouse: value, is_approved: "notapplied" });
      setWareHouseData({
        ...wareHouseData,
        data: array,
      });
    }
  };
  const fetchData = async (page, perPage, filter) => {
    const response = await getUserWarehouses(page, perPage, filter);
    if (!response.error) {
      setWareHouseData(response.data.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const handleDeleteRequest = async (data) => {
    setdeleteLoader(true);

    let array = wareHouseData?.data?.filter(
      (d) => d?.warehouse?.id != data?.warehouse?.id
    );
    setWareHouseData({
      ...wareHouseData,
      data: array,
    });

    if (data.id) {
      const response = await deleteWareHouseRequest(data.id);
      setConfirmDelete(false);
      setdeleteLoader(false);
      if (!response.error && response?.data?.success) {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Request deleted successfully.",
            severity: "success",
          };
        });
      } else {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Couldn't cancel this request!",
            severity: "error",
          };
        });
      }
    } else {
      setConfirmDelete(false);
      setdeleteLoader(false);
    }
  };
  const fetchAllWarehouses = async () => {
    try {
      let response1 = await getWareHouseList();
      setWareHouseList(response1.data.result);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    fetchAllWarehouses();
  }, []);

  return (
    <>
      <div className="parent_wraper">
        {/* sidebar code  */}
        <SideBar active="2_0" />
        <div className="dash_table_wrapper">
          {/* right side secions  */}
          <Snackbar Snack={snack} SetSnack={setSnack} />
          <Confirm
            open={confirmDelete}
            setOpen={setConfirmDelete}
            handleConfirm={() => handleDeleteRequest(confirmDelete)}
            text="Do You Want To Delete This Request ?"
            loader={deleteLoader}
          />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Autocomplete
              size="small"
              id="asynchronous-demo"
              sx={{ width: 300 }}
              getOptionLabel={(option) => option.name}
              options={wareHouseList}
              onChange={(e, value) => selectWareHouse(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select warehouse"
                  placeholder="Select warehouse"
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              )}
            />
            <div className="" onClick={() => setAddWarehouseUserModal(true)}>
              <div className="settings__page_hdraddprod">
                <FcPlus size={10} />
                <span>ADD WAREHOUSE</span>
              </div>
            </div>
          </div>

          <div>
            <WarehouseTable
              message={"No Warehouse Available To Display!"}
              searchPlaceHolder={"Search Warehouse"}
              columns={columns}
              data={wareHouseData}
              getAllData={fetchData}
              refresh={refresh}
            />
          </div>

          <AddWarehouseUser
            open={addWarehouseUserModal}
            setOpen={setAddWarehouseUserModal}
            setSnack={setSnack}
            setRefresh={setRefresh}
          />
        </div>
      </div>
    </>
  );
}

export default WareHouseSettings;
