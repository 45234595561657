import React, { useState, useEffect } from "react";
import "./taxes.css";
// apis imports
import { fetchAdminCalculatedTax } from "../../../../../services/AdminServices/PaymentsAdmin/payments.services";
import { fetchShipperUsers } from "../../../../../services/AdminServices/Users/users.services";
// library imports
import TextField from "../../../../Shipper/Customer/MainPages/ShipPage/Components/TextField";
import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import Taxslip from "./TaxSlip/FullPageSlip";
import CircularLoader from "../../../../../Components/CircularLoader/CircularLoader";

import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["Oliver Hansen"];
const shipmentTypes = ["Custom", "Hajex"];

const AdminTaxes = ({ setSnack }) => {
  const [loader, setloader] = useState(false);
  const [data, setData] = useState(null);
  const [shipperUsers, setShipperUsers] = useState([]);
  const [shipmentType, setShipmentType] = useState("");
  const [selectedShipperUsers, setSelectedShipperUsers] = useState([]);
  const [dates, setDates] = useState({
    from: "",
    to: "",
  });

  const getShipperUsers = async (page, perPage, filter) => {
    const response = await fetchShipperUsers(page, perPage, filter, "Shipper");
    if (!response.error) {
      setShipperUsers(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const handleDateChange = (e) => {
    setDates({ ...dates, [e.target.name]: e.target.value });
  };
  const handleUsersChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedUsers = typeof value === "string" ? value.split(",") : value;
    setSelectedShipperUsers(selectedUsers);
  };
  const handleShipmentType = (event) => {
    setShipmentType(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setloader(true);
    const payload = {
      ...dates,
      user_ids: selectedShipperUsers?.map((user) => user?.id),
      shipment_type: shipmentType,
    };
    const saved = await fetchAdminCalculatedTax(payload);
    if (!saved.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: saved?.data?.message,
          severity: "success",
        };
      });
      setData(saved?.data?.result);
      // setDates({
      //   from: "",
      //   to: "",
      // });
      setloader(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: saved?.error?.response?.data?.message,
          severity: "error",
        };
      });
      setloader(false);
    }
  };
  useEffect(() => {
    getShipperUsers();
  }, []);
  useEffect(() => {
    setData(null);
  }, [shipmentType, selectedShipperUsers, dates]);
  return (
    <div className="taxes__slip_details_wrap">
      <div>
        <div className="payment__invoices_header">
          <div>
            <span className="payment__invoices_heading tax__page_title">
              Taxes Details
            </span>
          </div>
        </div>

        <Row className="mx-auto mt-3">
          <Col md={11} className="mx-auto">
            <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
              <b style={{ textAlign: "center" }} className="tax__form_heading">
                Select Interval For Tax Slip
              </b>
            </Row>
            <Form onSubmit={handleSubmit} className="form__tax_slip_wrap">
              <Row className="to_from_tax_slip_date my-1">
                <TextField
                  Label={"Start Date"}
                  Name="from"
                  Data={dates.from}
                  Handler={handleDateChange}
                  Width="40%"
                  Type="date"
                  Required={true}
                />
                <TextField
                  Label={"End Date"}
                  Name="to"
                  Data={dates.to}
                  Handler={handleDateChange}
                  Width="40%"
                  Type="date"
                  Required={true}
                />
              </Row>
              <Row className="to_from_tax_slip_date my-1">
                <FormControl sx={{ width: "40%" }} size="small">
                  <InputLabel id="demo-multiple-name-label">Users</InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selectedShipperUsers}
                    onChange={handleUsersChange}
                    input={<OutlinedInput label="Users" />}
                    MenuProps={MenuProps}
                    renderValue={(selected) =>
                      selected.map((user) => user.username).join(", ")
                    }
                  >
                    {shipperUsers.map((user) => (
                      <MenuItem key={user.id} value={user}>
                        {user.username}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "40%" }} size="small">
                  <InputLabel id="demo-multiple-type-label">
                    Shipment Type
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-type-label"
                    id="demo-multiple-type"
                    value={shipmentType}
                    onChange={handleShipmentType}
                    input={<OutlinedInput label="Shipment Type" />}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {shipmentTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Row>
              <Row className="to_from_tax_slip_date my-1">
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  disabled={loader}
                >
                  {loader ? <CircularLoader /> : "Get Taxes"}
                </Button>
              </Row>
            </Form>
            <Row>
              <Taxslip
                data={data}
                setdata={setData}
                dates={dates}
                shipperUsers={shipperUsers}
                selectedShipperUsers={selectedShipperUsers}
              />
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AdminTaxes;
