import React, { useEffect, useState } from "react";
import "./Attachments.css";
import DragIcon from "../../../../../../assets/icons/dispatcher-drag.svg";
import RiseModal from "../../../../../../Components/RiseModal/RiseModal";
import {
  MdOutlineClose,
  MdDeleteForever,
  MdOutlineFileDownload,
} from "react-icons/md";
// import { deleteAttachmentFile } from "../../../../services/admin.services";

const Attachments = ({ onClose, style, type, open }) => {
  const [data, setData] = useState({});
  const [deletingItem, setDeletingItem] = useState(false);
  // const handleDeleteAttachment = async (id) => {
  //   try {
  //     setDeletingItem(id);
  //     const response = await deleteAttachmentFile(id);

  //     if (!response?.error) {
  //       if (type === "admin") {
  //         setData((prevData) => {
  //           return {
  //             ...prevData,
  //             media: prevData?.media?.filter((file) => file?.id !== id),
  //           };
  //         });
  //       }
  //       if (type === "user") {
  //         setData((prevData) => {
  //           return {
  //             ...prevData,
  //             shipment_charges: prevData?.shipment_charges?.map(
  //               (charge, index) => {
  //                 if (index === chargeIndex) {
  //                   return {
  //                     ...charge,
  //                     media: charge?.media?.filter((file) => file?.id !== id),
  //                   };
  //                 } else {
  //                   return charge;
  //                 }
  //               }
  //             ),
  //           };
  //         });
  //       }
  //       setDeletingItem(false);
  //     } else {
  //       setDeletingItem(false);
  //       setSnack((snack) => {
  //         return {
  //           ...snack,
  //           open: true,
  //           text: "Couldn't Delete this attachment.",
  //           severity: "error",
  //         };
  //       });
  //     }
  //   } catch (error) {
  //     setDeletingItem(false);
  //     setSnack((snack) => {
  //       return {
  //         ...snack,
  //         open: true,
  //         text: "Something went wrong.",
  //         severity: "error",
  //       };
  //     });
  //   }
  // };
  function downloadFiles(filesList) {
    const promises = [];

    filesList.forEach((fileObj, index) => {
      const url = fileObj.file;

      const promise = fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
          }

          // Extract the file extension from the URL
          const fileExtension = url.split(".").pop();

          return response.blob().then((blob) => ({ blob, fileExtension }));
        })
        .then(({ blob, fileExtension }) => {
          // Create a temporary URL for the blob
          const blobUrl = URL.createObjectURL(blob);

          // Create an anchor element to trigger the download
          const a = document.createElement("a");
          a.href = blobUrl;
          a.download = `${fileObj.original_name}`;
          document.body.appendChild(a);

          // Trigger the download
          a.click();

          // Clean up by revoking the blob URL
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error(`Error downloading file ${index + 1}:`, error);
        });

      promises.push(promise);
    });

    // Wait for all download promises to complete
    return Promise.all(promises);
  }

  function downloadFile(url, fileName) {
    return fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }

        const contentType = response.headers.get("content-type");
        if (!contentType) {
          throw new Error("Content type header is missing in the response.");
        }

        // Extract the file extension from the content type
        const fileExtension = contentType.split("/")[1];

        return response.blob().then((blob) => ({ blob, fileExtension }));
      })
      .then(({ blob, fileExtension }) => {
        // Create a temporary URL for the blob
        const blobUrl = URL.createObjectURL(blob);

        // Create an anchor element to trigger the download
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = `${fileName}`;
        document.body.appendChild(a);

        // Trigger the download
        a.click();

        // Clean up by revoking the blob URL
        URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  }

  useEffect(() => {
    setData(open);
  }, [open]);

  return (
    <>
      {data?.length > 0 && (
        <>
          <RiseModal closeOnLeave={true} style={style}>
            <div className="uploaded__attachments_list">
              <div className="uploaded__attachments_listheading">
                <span>Attachments</span>
                <span className="uploaded__attachments_listhdingicons">
                  <MdOutlineFileDownload
                    size={20}
                    className="uploaded__attachments_downloadall"
                    onClick={() =>
                      downloadFiles(data?.map((attachment) => attachment))
                    }
                  />
                  <MdOutlineClose
                    size={20}
                    className="uploaded__attachments_closeicon"
                    onClick={onClose}
                  />
                </span>
              </div>
              {data?.map((attachment, index) => (
                <div
                  className={`uploaded__attachments_listitem ${
                    deletingItem === attachment?.id
                      ? "uploaded__attachments_listitemdeleting"
                      : ""
                  }`}
                  key={index}
                >
                  <div className="uploaded__attachments_litxt">
                    {attachment?.original_name ?? ""}
                  </div>
                  <div className="uploaded__attachments_liicondownload">
                    <MdOutlineFileDownload
                      size={20}
                      onClick={() =>
                        downloadFile(
                          attachment?.file,
                          attachment?.original_name
                        )
                      }
                    />
                  </div>
                  {type !== "client" ? (
                    <div className="uploaded__attachments_liicondel">
                      <MdDeleteForever
                        size={20}
                        // onClick={() => handleDeleteAttachment(attachment?.id)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          </RiseModal>
        </>
      )}
    </>
  );
};

export default Attachments;
