import React, { useState } from "react";
import Tooltip from "../../../../../../../Components/Tooltip/Tooltip";
import { setdata } from "../../../../../../../helpers/helpers";
import BundleGridView from "./BundleGridView";
import AddNewBundle from "../AddBundle/AddBundle";
import BundleListView from "./BundleListView";
import ProductTypeSelector from "../ProductTypeSelector";
import { showSnackBar } from "../../../../../../../store/common/snackBarSlice";
import { useDispatch } from "react-redux";

export default function BundlesScroller(props) {
  const {
    Plist,
    SetPlist,
    StepCheck,
    SetStepCheck,
    bundleRefresh,
    setbundleRefresh,
    view,
    setView,
  } = props;

  const dispatch = useDispatch();

  const [filterComponent, setFilterComponent] = useState(null);
  const [bundleShow, setbundleShow] = useState(false);

  const handleView = () => {
    if (view == "Grid") {
      setView("List");
      setFilterComponent(null);
    } else if (view == "List") {
      setView("Grid");
    }
  };

  const handlebundleshow = () => {
    setbundleShow(!bundleShow);
  };

  const setFilter = (filterElement) => {
    setFilterComponent(filterElement);
  };

  const handlescrollpackage = (data) => {
    // console.log(data);
    dispatch(
      showSnackBar({
        text: `${data?.name ?? "Bundle"} Added Successfully`,
        severity: "success",
      })
    );
    const x = Plist.length - 1;
    if (
      Plist[x]["name"] === "" &&
      Plist[x]["length"] === "" &&
      Plist[x]["width"] === "" &&
      Plist[x]["height"] === "" &&
      Plist[x]["weight"] === "" &&
      Plist[x]["description"] === "" &&
      Plist[x]["insurance"] === ""
    ) {
      SetPlist((prevPlist) => {
        const updatedPlist = [...prevPlist];
        const lastPackageIndex = updatedPlist.length - 1;

        if (lastPackageIndex >= 0) {
          const lastPackage = updatedPlist[lastPackageIndex];
          const updatedLastPackage = {
            ...lastPackage,
            name: data.packages[0].name,
            length: setdata(() => data.packages[0].length, false, ""),
            width: setdata(() => data.packages[0].width, false, ""),
            height: setdata(() => data.packages[0].height, false, ""),
            weight: setdata(() => data.packages[0].weight, false, ""),
            description: data.packages[0].description,
            insurance: "",
            volume: (
              (setdata(() => data.packages[0].length, false, 0) *
                setdata(() => data.packages[0].width, false, 0) *
                setdata(() => data.packages[0].height, false, 0)) /
              1000000
            ).toFixed(2),
          };

          updatedPlist[lastPackageIndex] = updatedLastPackage;
        }

        const newPackages = data.packages.slice(1).map((packageItem) => ({
          name: packageItem.name,
          length: setdata(() => packageItem.length, false, ""),
          width: setdata(() => packageItem.width, false, ""),
          height: setdata(() => packageItem.height, false, ""),
          weight: setdata(() => packageItem.weight, false, ""),
          description: packageItem.description,
          insurance: "",
          volume: (
            (setdata(() => packageItem.length, false, 0) *
              setdata(() => packageItem.width, false, 0) *
              setdata(() => packageItem.height, false, 0)) /
            1000000
          ).toFixed(2),
        }));

        return [...updatedPlist, ...newPackages];
      });
    } else {
      SetPlist((prevPlist) => [
        ...prevPlist,
        ...data.packages.map((packageItem) => ({
          name: packageItem.name,
          length: setdata(() => packageItem.length, false, ""),
          width: setdata(() => packageItem.width, false, ""),
          height: setdata(() => packageItem.height, false, ""),
          weight: setdata(() => packageItem.weight, false, ""),
          description: packageItem.description,
          insurance: "",
          volume: (
            (setdata(() => packageItem.length, false, 0) *
              setdata(() => packageItem.width, false, 0) *
              setdata(() => packageItem.height, false, 0)) /
            1000000
          ).toFixed(2),
        })),
      ]);
    }
    SetStepCheck((prevStepCheck) => ({
      ...prevStepCheck,
      Packages_bit: 0,
      QuoteCheck: false,
    }));
  };

  return (
    <>
      <div className="bundle__scroller_wrap_main_container">
        <div className="warehouse__selection_drop_main_wrap">
          <Tooltip title="Add New Bundle">
            <button
              type="button"
              onClick={handlebundleshow}
              className="formdata__pagination_controler_btn_btnadd"
            >
              Add
            </button>
          </Tooltip>
        </div>
        {filterComponent}

        <ProductTypeSelector
          StepCheck={StepCheck}
          SetStepCheck={SetStepCheck}
          view={view}
          handleView={handleView}
        />
      </div>
      {view == "Grid" && (
        <>
          <BundleGridView
            handlescrollpackage={handlescrollpackage}
            setFilter={setFilter}
            refresh={bundleRefresh}
          />
        </>
      )}
      {view == "List" && (
        <>
          <div className="ship__page_myproduct_listing_bolt_table">
            <BundleListView
              handlescrollpackage={handlescrollpackage}
              refresh={bundleRefresh}
            />
          </div>
        </>
      )}

      <AddNewBundle
        open={bundleShow}
        setOpen={setbundleShow}
        setRefresh={setbundleRefresh}
      />
    </>
  );
}
