import React, { useState } from "react";
import "./Sections.css";
import { FaRegTrashAlt } from "react-icons/fa";
import SearchProduct from "../Components/SearchProduct/SearchProduct";
import { useEffect } from "react";

const ComboSection = ({ formData, setFormData }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const handleFieldChange = (name, e) => {
    setFormData({ ...formData, [name]: e.target.value });
  };
  const handleProductQuantity = (e, index) => {
    setSelectedProducts((selectedProds) => {
      return selectedProds.map((product, idx) => {
        if (idx === index) {
          return { ...product, quantity: e.target.value };
        } else {
          return product;
        }
      });
    });
  };
  const deleteSelectedProduct = (idx) => {
    setSelectedProducts(
      selectedProducts?.filter((prod, index) => index !== idx)
    );
  };
  useEffect(() => {
    setFormData({
      ...formData,
      product_combo: selectedProducts?.map((product) => {
        return { product_id: product.product_id, quantity: product.quantity };
      }),
    });
  }, [selectedProducts]);
  return (
    <section className="addprod__formsection_wrap" id="addproduct_combosection">
      <div className="addprod__formsection_heading">Product's Combo</div>
      <div className="addprod__custominput_box">
        <label className="addprod__custominput_title">Search Product</label>
        {/* <input
          className="addprod__custominputfield"
          type="text"
          placeholder="Enter product's title"
          onChange={(e) => handleFieldChange("title", e)}
        /> */}
        <SearchProduct setSelectedProducts={setSelectedProducts} />
      </div>
      <table className="addprod__prodcombo_table">
        <thead>
          <tr className="addprod__prodcombo_trow">
            <td className="addprod__prodcombo_tcell">Title </td>
            <td className="addprod__prodcombo_tcell">Quantity</td>
            <td className="addprod__prodcombo_tcell">Action</td>
          </tr>
        </thead>
        <tbody>
          {selectedProducts?.map((product, index) => (
            <tr key={index} className="addprod__prodcombo_trow">
              <td className="addprod__prodcombo_tcell">{product?.name}</td>
              <td className="addprod__prodcombo_tcell">
                <input
                  className="addprod__custominputfield"
                  type="number"
                  style={{ width: 80, height: "25px" }}
                  value={product.quantity}
                  onChange={(e) => handleProductQuantity(e, index)}
                  min="1"
                />
              </td>
              <td className="addprod__prodcombo_tcell">
                <FaRegTrashAlt
                  size={14}
                  className="addprod__prodcombo_tcelldel"
                  onClick={() => deleteSelectedProduct(index)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default ComboSection;
