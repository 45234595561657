import React, { useState } from "react";
// import "./Products.css";
import {
  editPallet,
  fetchAllWarehousePallets,
} from "../../../../services/product.service";
import ProductTable from "../../../../Components/BoltTable/BoltTable";
import {
  TotalSpaceColumn,
  Dimensions,
  Weight,
} from "../../../Products/Product/ProductService";
import Snackbar from "../../../../Components/SnackBar/SnackBar";
import { MdEditCalendar } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { FcPlus } from "react-icons/fc";
import Tooltip from "../../../../Components/Tooltip/Tooltip";
import AddPallet from "./Components/AddPallet";
import EditPallet from "./Components/EditPallet";
import { AiOutlineBarcode } from "react-icons/ai";
import AddImage from "../../../../assets/images/add-image.png";
import Image from "../../../../assets/images/photo.png";
import LabelModal from "../../../Products/Components/LabelModal/LabelModal";
import { PiExportLight } from "react-icons/pi";
import PalletProducts from "./Components/PalletProducts";
import ImagesModal from "../../../../Components/Modals/ImagesModal";
import { useDispatch } from "react-redux";
import { showSnackBar } from "../../../../store/common/snackBarSlice";
import { generateExcelFile } from "./Components/palletExportWarehouse";

const WarehousePallet = () => {
  const [Pallets, setPallets] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [productModal, setProductModal] = useState(false);
  const [labelModal, setLabelModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [imagesModal, setImagesModal] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  const dispatch = useDispatch();

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 1,
      label: "Client",
      field_name: "username",
      searchable: true,
      render: (row) => <>{row?.shipper?.username ?? "-"}</>,
      fixed: "left",
      width: "200px",
    },
    {
      id: 2,
      label: "Code",
      render: (row) => <>{row?.code ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Name",
      render: (row) => <>{row?.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "Location",
      field_name: "location",
      searchable: true,
      render: (row) => <>{row?.location?.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 5,
      label: "Dimensions (L*W*H)",
      render: (row) => (
        <>
          <Dimensions
            length={row?.length ?? 0}
            width={row?.width ?? 0}
            height={row?.height ?? 0}
            unit={row?.unit === "imperial" ? "inch" : "cm"}
            space={true}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 6,
      label: "Weight",
      render: (row) => (
        <>
          <Weight
            weight={row?.weight ?? 0}
            unit={row?.unit === "imperial" ? "lb" : "kg"}
            space={true}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 7,
      label: "Pallet Volume",
      render: (row) => (
        <>
          <TotalSpaceColumn
            length={row?.length ?? 0}
            width={row?.width ?? 0}
            height={row?.height ?? 0}
            unit={row?.unit === "imperial" ? "inch" : "cm"}
            quantity={1}
            space={true}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 8,
      label: "Product Volume",
      render: (row) => (
        <div>
          {row?.total_product_cbm_volume ?? "0 "}
          <small>
            (<b>cbm</b>)
          </small>{" "}
          <br />
          {row?.total_product_cbf_volume ?? "0 "}
          <small>
            (<b>cbf</b>)
          </small>
        </div>
      ),
      width: "200px",
    },
    {
      id: 9,
      label: "Products",
      render: (row) => (
        // <>
        //   <div
        //     className="product_dimension_parent_wrapper"
        //     style={{ maxWidth: "200px" }}
        //   >
        //     {parseInt(row?.product_pallets_count ?? 0) > 0 ? (
        //       <div
        //         className="product__notification_circle_wrapper"
        //         style={{ cursor: "pointer" }}
        //         onClick={() => setProductModal(row)}
        //       >
        //         <PiPackageLight size={10} />
        //         <div className="product__notification_circle">
        //           {row?.product_pallets_count ?? "0"}
        //         </div>
        //       </div>
        //     ) : null}
        //   </div>
        // </>
        <div onClick={() => setProductModal(row)} style={{ cursor: "pointer" }}>
          {row?.product_pallets_count ?? "0"}
        </div>
      ),
      width: "200px",
    },
    {
      id: 10,
      label: "Product Quantity",
      render: (row) => (
        <div style={{ cursor: "pointer" }} onClick={() => setProductModal(row)}>
          {row?.product_quantity ?? ""}
        </div>
      ),
      width: "200px",
    },
    {
      id: 11,
      label: "Product BreakDown",
      render: (row) => (
        <>
          {row?.product_pallets?.length > 0 && (
            <>
              {row?.product_pallets?.map((product, index) => (
                <div key={index}>
                  {product?.product_warehouse?.product?.product_sku ?? ""} (
                  {product?.quantity ?? "0"})
                </div>
              ))}
            </>
          )}
        </>
      ),
      width: "200px",
    },
    {
      id: 12,
      label: "Media",
      field_name: "media",
      render: (row) => (
        <div className="shipper__attachments_thumbnail_wrap">
          {row?.media?.length === 0 ? (
            <img
              src={AddImage}
              loading="lazy"
              onClick={() => setImagesModal(row)}
            />
          ) : (
            <img
              src={Image}
              loading="lazy"
              onClick={() => setImagesModal(row)}
            />
          )}
        </div>
      ),
      width: "200px",
    },
    {
      id: 13,
      label: "Created At",
      render: (row) => <>{row?.created_at?.slice(0, 10) ?? "-"}</>,
      width: "200px",
    },
    {
      id: 14,
      label: "Action",
      render: (row, rowIndex) => (
        <>
          <div className="table_action__wrap">
            <Tooltip title="View Pallet Details">
              <div
                className="table_action__btn"
                onClick={() => {
                  setProductModal(row);
                }}
              >
                <MdEditCalendar size={10} />
                View
              </div>
            </Tooltip>
            <Tooltip title="Edit Pallet">
              <div
                className="table_action__btn"
                onClick={() => setEditModal(row)}
              >
                <FaRegEdit size={10} />
                Edit
              </div>
            </Tooltip>
            {row?.code ? (
              <Tooltip title="Print Barcode">
                <div
                  className="table_action__btn"
                  onClick={() => setLabelModal({ row, rowIndex })}
                >
                  <AiOutlineBarcode size={10} />
                  Barcode
                </div>
              </Tooltip>
            ) : null}
          </div>
        </>
      ),
      width: "200px",
      fixed: "right",
    },
  ];

  const getAllPallets = async (page, perPage, filter, nestedSearch) => {
    const response = await fetchAllWarehousePallets(
      page,
      perPage,
      filter,
      nestedSearch
    );
    if (!response.error) {
      setPallets(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  function getCBFColor(first, secound) {
    const percentage = ((secound / first) * 100)?.toFixed(2);

    if (percentage > 100 || percentage >= 80) {
      return "#228B22"; // Dark green for 80%-100% or more
    } else if (percentage >= 60) {
      return "#DAA520"; // Dark yellow for 60%-80%
    } else if (percentage >= 40) {
      return "#FF8C00"; // Dark orange for 40%-60%
    } else {
      return "#B22222"; // Dark red for less than 40%
    }
  }
  const handleAddImages = async (images) => {
    const data = new FormData();
    for (let i = 0; i < images.length; i++) {
      data.append("media[]", images[i]);
    }
    const response = await editPallet(data, imagesModal?.id);
    if (!response.error) {
      const updatedPallets = Pallets?.data?.map((pallet) =>
        pallet.id === imagesModal?.id ? response?.data?.result : pallet
      );
      setPallets({ ...Pallets, data: updatedPallets });
      setImagesModal(response?.data?.result);
      dispatch(
        showSnackBar({
          text: response.message ?? "Images updated successfully",
          severity: "success",
        })
      );
    } else {
      dispatch(
        showSnackBar({
          text: response.message ?? "Something went wrong",
          severity: "error",
        })
      );
    }
    return response;
  };
  function calculateTotals(pallets) {
    if (!pallets?.data || !Array.isArray(pallets.data)) {
      return {
        totalWeight: 0,
        totalProducts: 0,
        totalProductQuantity: 0,
        totalCBM: 0,
        totalCBF: 0,
        totalProductCMB: 0,
        totalProductCBF: 0,
      };
    }

    const conversionFactor = 35.314667; // 1 cubic meter = 35.3147 cubic feet
    const inchToMeter = 0.0254; // 1 inch = 0.0254 meters

    return pallets.data.reduce(
      (totals, item) => {
        let weight = parseInt(item.weight) || 0;
        let productCount = parseInt(item.product_pallets_count) || 0;
        let productQuantityCount = parseInt(item.product_quantity) || 0;

        let length = parseFloat(item.length);
        let width = parseFloat(item.width);
        let height = parseFloat(item.height);
        let productCbm = parseFloat(item.total_product_cbm_volume) || 0;
        let productCbf = parseFloat(item.total_product_cbf_volume) || 0;
        let unit = item?.unit ?? "imperial";

        if (!isNaN(length) && !isNaN(width) && !isNaN(height)) {
          // Convert dimensions to meters based on the unit
          if (unit === "metric") {
            length /= 100; // Convert cm to meters
            width /= 100;
            height /= 100;
          } else if (unit === "imperial") {
            length *= inchToMeter; // Convert inches to meters
            width *= inchToMeter;
            height *= inchToMeter;
          }

          const cbm = length * width * height;
          const cbf = cbm * conversionFactor;

          totals.totalCBM += cbm;
          totals.totalCBF += cbf;
        }

        totals.totalWeight += weight;
        totals.totalProducts += productCount;
        totals.totalProductQuantity += productQuantityCount;
        totals.totalProductCMB += productCbm;
        totals.totalProductCBF += productCbf;

        return totals;
      },
      {
        totalWeight: 0,
        totalProducts: 0,
        totalProductQuantity: 0,
        totalCBM: 0,
        totalCBF: 0,
        totalProductCMB: 0,
        totalProductCBF: 0,
      }
    );
  }

  const totals = calculateTotals(Pallets);

  const footerRow = (
    <tr>
      <td></td>
      <td>
        <b>Total</b>
      </td>
      <td className="tablecol__fixed_left"></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>
        <b>{totals?.totalWeight}</b>
      </td>
      <td>
        <b>{`${totals?.totalCBM?.toFixed(2)} cbm ${totals?.totalCBF?.toFixed(
          2
        )} cbf`}</b>
      </td>
      <td
        style={{
          color: getCBFColor(totals?.totalCBM, totals?.totalProductCMB),
        }}
      >
        <b>{`${totals?.totalProductCMB?.toFixed(
          2
        )} cbm ${totals?.totalProductCBF?.toFixed(2)} cbf`}</b>
      </td>
      <td>
        <b>{totals?.totalProducts}</b>
      </td>
      <td>
        <b>{totals?.totalProductQuantity}</b>
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td className="tablecol__fixed_right"></td>
    </tr>
  );

  return (
    <>
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <AddPallet
        open={addModal}
        setOpen={setAddModal}
        setSnack={setSnack}
        setRefresh={setRefresh}
      />
      <EditPallet
        open={editModal}
        setOpen={setEditModal}
        setSnack={setSnack}
        setRefresh={setRefresh}
      />
      <ImagesModal
        heading="Pallets Media"
        open={imagesModal}
        setOpen={setImagesModal}
        images={imagesModal?.media}
        addImages={handleAddImages}
        setSnack={setSnack}
      />
      <LabelModal
        open={labelModal}
        setOpen={setLabelModal}
        setSnack={setSnack}
        labelData={{
          code: labelModal?.row?.code,
          name: labelModal?.row?.name,
          image: labelModal?.row?.media?.find((image) => image?.is_primary)
            ?.file,
        }}
        title="Pallet's Barcode"
        subtitle={`${labelModal?.row?.name ?? ""}`}
      />
      <PalletProducts open={productModal} setOpen={setProductModal} />
      <div className="products__page_header">
        <div className="products__page_headerlft">
          <span className="products__page_hdrheading">Pallets List</span>
        </div>
        <div className="products__page_headerrt_wrap">
          <div
            className="products__page_headerrt products__page_hdraddprod"
            onClick={() =>
              generateExcelFile(Pallets?.data, selectedRows, setSnack)
            }
          >
            <PiExportLight size={10} />
            <span>Export Selected</span>
          </div>
          <div
            onClick={() => setAddModal(true)}
            className="products__page_headerrt products__page_hdraddprod"
          >
            <FcPlus size={20} />
            <span>Add Pallet</span>
          </div>
        </div>
      </div>
      <div className="custom__table_size1 actionbtn__needtobe_fixwidth">
        <ProductTable
          message="No Pallets to display!"
          searchPlaceHolder="Search Pallets"
          columns={columns}
          data={Pallets}
          getAllData={getAllPallets}
          refresh={refresh}
          footer={footerRow}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          selectable={true}
        />
      </div>
    </>
  );
};

export default WarehousePallet;
