import React, { useState, useEffect, useRef } from "react";
import "./Payments.css";

// components imports
import ApprovedPaymentList from "./Components/ApprovedPaymentList/ApprovedPaymentList";
import PendingPaymentList from "./Components/PendingPaymentList/PendingPaymentList";
import CardManagement from "./Components/CardManagement/CardManagement";
import Snackbar from "../../../../../Components/SnackBar/SnackBar";
import Invoices from "./Components/Invoices/Invoices";
import Taxes from "./Components/Taxes/Taxes";
import Settings from "./Ltl/Settings/Settings";

// apis imports
import { removeUnderscoresAndCapitalize } from "../../../../../helpers/helpers";
// library imports

import { initialPaymentTab } from "../../../../../data/permissionsArray";
import { useSelector } from "react-redux";
import LtlApprovedPaymentList from "./Ltl/ApprovedPaymentList/ApprovedPaymentList";
import LtlPendingPaymentList from "./Ltl/PendingPaymentList/PendingPaymentList";
import LtlInvoices from "./Ltl/Invoices/Invoices";
import TabsComponent from "../../../../../Components/Common/TabsComponent";

const Payments = () => {
  const [tab, setTab] = useState(0);
  const [mainTab, setMainTab] = useState("shipper");
  const [tabPermissions, setTabPermissions] = useState(initialPaymentTab);

  const user = useSelector((state) => state.user);
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  useEffect(() => {
    if (user.role != "Shipper") {
      let validPermissions = initialPaymentTab.filter((t) =>
        user?.mergedPermissions?.includes(t.name)
      );
      if (validPermissions?.length > 0) {
        setTab(validPermissions[0]?.value);
      } else {
        // setTab("nothing");
        setTab(0);
      }
      setTabPermissions(validPermissions);
    } else {
      setTab(0);
    }
  }, []);

  const handleMainTabChange = (event, newValue) => {
    setMainTab(newValue);
    setTab(0);
  };
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div className="dispatcher__screen" style={{ maxWidth: "100%" }}>
      <Snackbar Snack={snack} SetSnack={setSnack} />

      <TabsComponent
        items={[
          {
            label: "SHIPPER",
            style: { fontWeight: "bold" },
            value: "shipper",
          },
          { label: "3PL", style: { fontWeight: "bold" }, value: "ltl" },
        ]}
        tab={mainTab}
        handleTabChange={handleMainTabChange}
      />
      {mainTab === "shipper" && (
        <>
          {" "}
          <TabsComponent
            items={[
              { label: "APPROVED", style: { fontWeight: "bold" }, value: 0 },
              { label: "PENDING", style: { fontWeight: "bold" }, value: 1 },
              {
                label: "CARD MANAGEMENT",
                style: { fontWeight: "bold" },
                value: 2,
              },
              { label: "INVOICES", style: { fontWeight: "bold" }, value: 3 },
              { label: "TAXES", style: { fontWeight: "bold" }, value: 4 },
            ]}
            tab={tab}
            handleTabChange={handleTabChange}
          />
          {tab === 0 && (
            <ApprovedPaymentList snack={snack} setSnack={setSnack} />
          )}
          {tab === 1 && (
            <PendingPaymentList snack={snack} setSnack={setSnack} />
          )}
          {tab === 2 && <CardManagement snack={snack} setSnack={setSnack} />}
          {tab === 3 && <Invoices snack={snack} setSnack={setSnack} />}
          {tab === 4 && <Taxes snack={snack} setSnack={setSnack} />}
        </>
      )}
      {mainTab === "ltl" && (
        <>
          {" "}
          <TabsComponent
            items={[
              { label: "APPROVED", style: { fontWeight: "bold" }, value: 0 },
              { label: "PENDING", style: { fontWeight: "bold" }, value: 1 },
              { label: "INVOICES", style: { fontWeight: "bold" }, value: 2 },
              ...(user.role === "warehouseUser"
                ? [
                  {
                    label: "SETTINGS",
                    style: { fontWeight: "bold" },
                    value: 3,
                  },
                ]
                : []),
            ]}
            tab={tab}
            handleTabChange={handleTabChange}
          />
          {tab === 0 && (
            <LtlApprovedPaymentList snack={snack} setSnack={setSnack} />
          )}
          {tab === 1 && (
            <LtlPendingPaymentList snack={snack} setSnack={setSnack} />
          )}
          {tab === 2 && <LtlInvoices snack={snack} setSnack={setSnack} />}
          {tab === 3 && <Settings snack={snack} setSnack={setSnack} />}
        </>
      )}
    </div>
  );
};

export default Payments;
