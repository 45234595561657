import React, { useState, useRef } from "react";
import "./Components.css";
import CustomDropdown from "../../Products/AddProduct/Components/CustomDropdown/CustomDropdown";
import CustomSelectDropdown from "./customdropdown";
import { checkFileType } from "../../../helpers/helpers";
import noImage from "../../../assets/images/noImage.png";
import pdfIcon from "../../../assets/icons/pdfIcon.png";
import excelIcon from "../../../assets/icons/excelIcon.png";
import docsIcon from "../../../assets/icons/docsIcon.png";
import ImagesModal from "../../Shipper/Customer/MainPages/Orders/Components/OrderHistoryModal/ImagesModal/ImagesModal";
import { MdAdd, MdClose } from "react-icons/md";
import { BorderColor, Padding } from "@mui/icons-material";

const FileInput = ({ id, onChange }) => {
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <div
        style={{ cursor: "pointer", width: "20px", marginTop: "14px" }}
        onClick={handleClick}
      >
        <MdAdd size={20} />
      </div>
      <input
        type="file"
        hidden
        multiple
        id={id}
        ref={fileInputRef}
        onChange={onChange}
      />
    </div>
  );
};

const ShipmentDetails = ({
  data,
  setData,
  editMode,
  setEditMode,
  setSnack,
}) => {
  const [fullview, setfullview] = useState(false);

  const handleShipmentLabel = (event) => {
    const uploadedImages = Array.from(event.target.files);
    setData((prevValue) => ({
      ...prevValue,
      OrderShipment: {
        ...prevValue?.OrderShipment,
        labels: uploadedImages,
      },
    }));
  };

  const handleShipmentDeclaration = (event) => {
    const uploadedImages = Array.from(event.target.files);
    setData((prevValue) => ({
      ...prevValue,
      OrderShipment: {
        ...prevValue?.OrderShipment,
        declaration: uploadedImages,
      },
    }));
  };

  const handleRemoveLabels = (index) => {
    const updatedImages = [...data?.OrderShipment?.labels];
    updatedImages.splice(index, 1);
    setData((prevValue) => ({
      ...prevValue,
      OrderShipment: {
        ...prevValue?.OrderShipment,
        labels: updatedImages,
      },
    }));
  };

  const handleRemoveDeclaration = (index) => {
    const updatedImages = [...data?.OrderShipment?.declaration];
    updatedImages.splice(index, 1);
    setData((prevValue) => ({
      ...prevValue,
      OrderShipment: {
        ...prevValue?.OrderShipment,
        declaration: updatedImages,
      },
    }));
  };

  const handleDeleteLabels = (index, id) => {
    const updatedImages = [...data?.OrderShipment?.prev_labels];
    updatedImages.splice(index, 1);
    setData((prevValue) => ({
      ...prevValue,
      OrderShipment: {
        ...prevValue?.OrderShipment,
        prev_labels: updatedImages,
        deleted_shipment_media_id: [
          ...(prevValue?.OrderShipment?.deleted_shipment_media_id || []),
          id,
        ],
      },
    }));
  };

  const handleDeleteDeclaration = (index, id) => {
    const updatedImages = [...data?.OrderShipment?.prev_declaration];
    updatedImages.splice(index, 1);
    setData((prevValue) => ({
      ...prevValue,
      OrderShipment: {
        ...prevValue?.OrderShipment,
        prev_declaration: updatedImages,
        deleted_shipment_media_id: [
          ...(prevValue?.OrderShipment?.deleted_shipment_media_id || []),
          id,
        ],
      },
    }));
  };

  return (
    <>
      <ImagesModal setOpen={setfullview} open={fullview} />
      <div className="sss__payment_section">
        <div className="sss__pmtsection_heading">Shipment Details</div>
        <div
          className="sss__addressection_inputrow"
          style={{ justifyContent: "space-between", alignItems: "flex-end" }}
        >
          <div className="sss__addressection_inputcol">
            <div className="sss__addressection_inputbox">
              <label className="sss__addressection_inputtitle">
                Shipment Type
              </label>
              <CustomDropdown
                options={["via_bolt", "manual"]}
                onSelect={(option) => {
                  setData({ ...data, shipment_type: option });
                }}
                selectedLabel={
                  data?.manual_shipment
                    ? "Manual"
                    : data?.shipment
                    ? "Via Bolt"
                    : ""
                }
                disabled={true}
              />
            </div>
          </div>
          <div className="sss__addressection_inputcol">
            <div className="sss__addressection_inputbox">
              <label className="sss__addressection_inputtitle">
                Shipping Company
              </label>
              <CustomSelectDropdown
                setValue={setData}
                value={data}
                setSnack={setSnack}
                editMode={editMode}
                disabled={data?.shipment ? true : false}
              />
            </div>
          </div>
        </div>

        {(data?.OrderShipment?.shipping_company ||
          data?.manual_shipment_id ||
          data?.shipment_id) && (
          <>
            <div
              className="sss__addressection_inputrow"
              style={{
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <div className="sss__addressection_inputcol">
                <div className="sss__addressection_inputbox">
                  <label className="sss__addressection_inputtitle">
                    Shipment Status
                  </label>
                  <CustomDropdown
                    options={[
                      "Creation",
                      "On Hold",
                      "In Transit",
                      "Delivered",
                      "Cancelled",
                    ]}
                    onSelect={(option) => {
                      setData((prevValue) => ({
                        ...prevValue,
                        OrderShipment: {
                          ...prevValue?.OrderShipment,
                          shipment_status: option,
                        },
                      }));
                    }}
                    disabled={data?.shipment ? true : false}
                    selectedLabel={data?.OrderShipment?.shipment_status ?? ""}
                  />
                </div>
              </div>
              <div className="sss__addressection_inputcol">
                <div className="sss__addressection_inputbox">
                  <label className="sss__addressection_inputtitle">
                    Carrier Tracking
                  </label>
                  <input
                    className="sss__addressection_inputfield"
                    type="text"
                    placeholder="Enter Shipment Tracking ID"
                    onChange={(e) =>
                      setData((prevValue) => ({
                        ...prevValue,
                        OrderShipment: {
                          ...prevValue?.OrderShipment,
                          shipment_tracking_id: e.target.value,
                        },
                      }))
                    }
                    value={data?.OrderShipment?.shipment_tracking_id ?? ""}
                    disabled={data?.shipment ? true : false}
                  />
                </div>
              </div>
            </div>
            <div
              className="sss__addressection_inputrow"
              style={{
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <div className="sss__addressection_inputcol">
                <div className="sss__addressection_inputbox">
                  <label className="sss__addressection_inputtitle">
                    Pickup ID
                  </label>
                  <input
                    className="sss__addressection_inputfield"
                    type="text"
                    placeholder="Enter Shipment Pickup ID"
                    onChange={(e) =>
                      setData((prevValue) => ({
                        ...prevValue,
                        OrderShipment: {
                          ...prevValue?.OrderShipment,
                          pickup_id: e.target.value,
                        },
                      }))
                    }
                    value={data?.OrderShipment?.pickup_id ?? ""}
                    disabled={data?.shipment ? true : false}
                  />
                </div>
              </div>
              <div className="sss__addressection_inputcol">
                <div className="sss__addressection_inputbox">
                  <label className="sss__addressection_inputtitle">
                    Pickup Date
                  </label>
                  <input
                    className="sss__addressection_inputfield"
                    type="date"
                    onChange={(e) =>
                      setData((prevValue) => ({
                        ...prevValue,
                        OrderShipment: {
                          ...prevValue?.OrderShipment,
                          pickup_date: e.target.value,
                        },
                      }))
                    }
                    value={data?.OrderShipment?.pickup_date ?? ""}
                    disabled={data?.shipment ? true : false}
                  />
                </div>
              </div>
            </div>
            <div
              className="sss__addressection_inputrow"
              style={{
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <div className="sss__addressection_inputcol">
                <div className="sss__addressection_inputbox">
                  <label className="sss__addressection_inputtitle">
                    Labels
                  </label>
                  <div
                    className="order_detail_view_thumbnails_wrap_main"
                    style={{ maxWidth: "100%" }}
                  >
                    <div className="order_detail_view_thumbnails_wrap">
                      {data?.OrderShipment?.prev_labels.length > 0 &&
                        data?.OrderShipment?.prev_labels?.map((list, index) => (
                          <div>
                            {!data?.shipment && (
                              <>
                                <MdClose
                                  style={{
                                    position: "relative",
                                    top: "3px",
                                    right: "0px",
                                    left: "30px",
                                    Padding: "2px",
                                    borderRadius: "50%",
                                    BorderColor: "white",
                                    boxShadow: "1px 1px 3px 0px lightgrey",
                                    cursor: "pointer",
                                    width: "16px",
                                    height: "16px",
                                  }}
                                  size={20}
                                  onClick={() =>
                                    handleDeleteLabels(index, list?.id)
                                  }
                                />
                              </>
                            )}
                            <img
                              src={
                                list?.file &&
                                checkFileType(list.file) === "Image"
                                  ? list.file
                                  : checkFileType(list.file) === "PDF"
                                  ? pdfIcon
                                  : checkFileType(list.file) === "Excel"
                                  ? excelIcon
                                  : checkFileType(list.file) === "Document"
                                  ? docsIcon
                                  : noImage
                              }
                              alt={`Attachment ${index + 1}`}
                              onError={(e) => {
                                e.target.src = noImage;
                              }}
                              loading="lazy"
                              onClick={() => {
                                if (checkFileType(list.file) === "Image") {
                                  setfullview(list?.file || "");
                                } else {
                                  window.open(list?.file, "_blank");
                                }
                              }}
                            />
                          </div>
                        ))}
                      {data?.OrderShipment?.labels.length > 0 &&
                        data?.OrderShipment?.labels?.map((list, index) => (
                          <div>
                            <MdClose
                              style={{
                                position: "relative",
                                top: "3px",
                                right: "0px",
                                left: "30px",
                                Padding: "2px",
                                borderRadius: "50%",
                                BorderColor: "white",
                                boxShadow: "1px 1px 3px 0px lightgrey",
                                cursor: "pointer",
                                width: "16px",
                                height: "16px",
                              }}
                              size={20}
                              onClick={() => handleRemoveLabels(index)}
                            />
                            <img
                              src={
                                checkFileType(list?.name) === "Image"
                                  ? URL.createObjectURL(list)
                                  : checkFileType(list?.name) === "PDF"
                                  ? pdfIcon
                                  : checkFileType(list?.name) === "Excel"
                                  ? excelIcon
                                  : checkFileType(list.file) === "Document"
                                  ? docsIcon
                                  : noImage
                              }
                              alt={`Attachment ${index + 1}`}
                              onError={(e) => {
                                e.target.src = noImage;
                              }}
                              loading="lazy"
                              onClick={() => {
                                if (checkFileType(list.name) === "Image") {
                                  setfullview(URL.createObjectURL(list) || "");
                                } else {
                                  window.open(
                                    URL.createObjectURL(list),
                                    "_blank"
                                  );
                                }
                              }}
                            />
                          </div>
                        ))}
                      {!data?.shipment && (
                        <>
                          <FileInput
                            id="order_shipment_labels"
                            onChange={handleShipmentLabel}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="sss__addressection_inputcol">
                <div className="sss__addressection_inputbox">
                  <label className="sss__addressection_inputtitle">
                    Declaration
                  </label>
                  <div
                    className="order_detail_view_thumbnails_wrap_main"
                    style={{ maxWidth: "100%" }}
                  >
                    <div className="order_detail_view_thumbnails_wrap">
                      {data?.OrderShipment?.prev_declaration.length > 0 &&
                        data?.OrderShipment?.prev_declaration?.map(
                          (list, index) => (
                            <div>
                              {!data?.shipment && (
                                <>
                                  <MdClose
                                    style={{
                                      position: "relative",
                                      top: "3px",
                                      right: "0px",
                                      left: "30px",
                                      Padding: "2px",
                                      borderRadius: "50%",
                                      BorderColor: "white",
                                      boxShadow: "1px 1px 3px 0px lightgrey",
                                      cursor: "pointer",
                                      width: "16px",
                                      height: "16px",
                                    }}
                                    size={20}
                                    onClick={() =>
                                      handleDeleteDeclaration(index, list?.id)
                                    }
                                  />
                                </>
                              )}
                              <img
                                src={
                                  list?.file &&
                                  checkFileType(list.file) === "Image"
                                    ? list.file
                                    : checkFileType(list.file) === "PDF"
                                    ? pdfIcon
                                    : checkFileType(list.file) === "Excel"
                                    ? excelIcon
                                    : checkFileType(list.file) === "Document"
                                    ? docsIcon
                                    : noImage
                                }
                                alt={`Attachment ${index + 1}`}
                                onError={(e) => {
                                  e.target.src = noImage;
                                }}
                                loading="lazy"
                                onClick={() => {
                                  if (checkFileType(list.file) === "Image") {
                                    setfullview(list?.file || "");
                                  } else {
                                    window.open(list?.file, "_blank");
                                  }
                                }}
                              />
                            </div>
                          )
                        )}
                      {data?.OrderShipment?.declaration.length > 0 &&
                        data?.OrderShipment?.declaration?.map((list, index) => (
                          <div>
                            <MdClose
                              style={{
                                position: "relative",
                                top: "3px",
                                right: "0px",
                                left: "30px",
                                Padding: "2px",
                                borderRadius: "50%",
                                BorderColor: "white",
                                boxShadow: "1px 1px 3px 0px lightgrey",
                                cursor: "pointer",
                                width: "16px",
                                height: "16px",
                              }}
                              size={20}
                              onClick={() => handleRemoveDeclaration(index)}
                            />

                            <img
                              src={
                                checkFileType(list?.name) === "Image"
                                  ? URL.createObjectURL(list)
                                  : checkFileType(list?.name) === "PDF"
                                  ? pdfIcon
                                  : checkFileType(list?.name) === "Excel"
                                  ? excelIcon
                                  : checkFileType(list.file) === "Document"
                                  ? docsIcon
                                  : noImage
                              }
                              alt={`Attachment ${index + 1}`}
                              onError={(e) => {
                                e.target.src = noImage;
                              }}
                              loading="lazy"
                              onClick={() => {
                                if (checkFileType(list.name) === "Image") {
                                  setfullview(URL.createObjectURL(list) || "");
                                } else {
                                  window.open(
                                    URL.createObjectURL(list),
                                    "_blank"
                                  );
                                }
                              }}
                            />
                          </div>
                        ))}
                      {!data?.shipment && (
                        <>
                          <FileInput
                            id="order_shipment_declaration"
                            onChange={handleShipmentDeclaration}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ShipmentDetails;
