import TextField from "@mui/material/TextField";

export default function AddressForm(props) {
  const {
    Label,
    Width = "100%",
    SX = {
      width: Width,
      input: { font: "menu" },
    },
    Name,
    Data,
    Handler,
    Required = true,
    Type = "text",
    InputProps = {},
    Multiline = false,
    Row = 0,
    PlaceHolder = Label,
    Disabled = false,
    Validation = {},
  } = props;

  return (
    <>
      <TextField
        label={Label}
        type={Type}
        name={Name}
        value={Data}
        onChange={(e) => Handler(e)}
        placeholder={PlaceHolder}
        required={Required}
        sx={SX}
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
        InputProps={InputProps}
        multiline={Multiline}
        rows={Row}
        disabled={Disabled}
        inputProps={Validation}
      />
    </>
  );
}
