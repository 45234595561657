export const calculateCBMAndCBF = (length, width, height, unit, quantity) => {
  if (length == null || width == null || height == null) {
    return { cbm: 0, cbf: 0 };
  }

  const conversionFactorsToMeters = {
    cm: 0.01,
    inch: 0.0254,
    m: 1,
  };

  const conversionFactorsToFeet = {
    cm: 0.0328084,
    inch: 0.0833333,
    ft: 1,
  };

  const lengthInMeters = length * (conversionFactorsToMeters[unit] || 1);
  const widthInMeters = width * (conversionFactorsToMeters[unit] || 1);
  const heightInMeters = height * (conversionFactorsToMeters[unit] || 1);

  const lengthInFeet = length * (conversionFactorsToFeet[unit] || 1);
  const widthInFeet = width * (conversionFactorsToFeet[unit] || 1);
  const heightInFeet = height * (conversionFactorsToFeet[unit] || 1);

  const cbm = lengthInMeters * widthInMeters * heightInMeters * quantity;
  const cbf = lengthInFeet * widthInFeet * heightInFeet * quantity;

  return { cbm, cbf };
};

export const TotalSpaceColumn = ({
  length,
  width,
  height,
  unit,
  quantity,
  space = false,
}) => {
  // const { length, width, height, dimension_unit } = row;
  // const unit = Dimension_unit === "imperial" ? "inch" : "cm";
  const { cbm, cbf } = calculateCBMAndCBF(
    length,
    width,
    height,
    unit,
    quantity
  );

  return (
    <div>
      {cbm.toFixed(2)}{" "}
      <small>
        (<b>cbm</b>)
      </small>{" "}
      {space && <br />}
      {cbf.toFixed(2)}{" "}
      <small>
        (<b>cbf</b>)
      </small>
    </div>
  );
};

export const Dimensions = ({ length, width, height, unit, space = false }) => {
  const lengthfloat = parseFloat(length) || 0;
  const widthfloat = parseFloat(width) || 0;
  const heightfloat = parseFloat(height) || 0;
  const isInches = unit === "inch";

  const inToCm = 2.54;
  const cmToIn = 1 / inToCm;

  const lengthIn = isInches ? lengthfloat : (lengthfloat * cmToIn).toFixed(2);
  const widthIn = isInches ? widthfloat : (widthfloat * cmToIn).toFixed(2);
  const heightIn = isInches ? heightfloat : (heightfloat * cmToIn).toFixed(2);

  const lengthCm = isInches ? (lengthfloat * inToCm).toFixed(2) : lengthfloat;
  const widthCm = isInches ? (widthfloat * inToCm).toFixed(2) : widthfloat;
  const heightCm = isInches ? (heightfloat * inToCm).toFixed(2) : heightfloat;
  return (
    <>
      {lengthIn} * {widthIn} * {heightIn}{" "}
      <small>
        (<b>in</b>)
      </small>{" "}
      {space && <br />}
      {lengthCm} * {widthCm} * {heightCm}{" "}
      <small>
        (<b>cm</b>)
      </small>
    </>
  );
};

export const Weight = ({ weight, unit, space = false }) => {
  const weightfloat = parseFloat(weight) || 0;
  const isImperial = unit === "lb";

  const lb = isImperial ? weightfloat : (weightfloat / 0.453592).toFixed(2);
  const kg = isImperial ? (weightfloat * 0.453592).toFixed(2) : weightfloat;
  return (
    <>
      {lb}{" "}
      <small>
        (<b>lb</b>)
      </small>{" "}
      {space && <br />}
      {kg}{" "}
      <small>
        (<b>kg</b>)
      </small>
    </>
  );
};
