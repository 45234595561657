import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

export default function TextFeild(props) {
  const {
    Adroment,
    Label,
    Width = "130px",
    Name,
    Data,
    Handler,
    Required = true,
    Type = "number",
    InputProps = {
      endAdornment: (
        <InputAdornment position="end" sx={{ color: "#1976D2" }}>
          <b>
            <small>{Adroment}</small>
          </b>
        </InputAdornment>
      ),
    },
    Multiline = false,
    Row = 0,
    PlaceHolder = Label,
    Disabled = false,
    Validation = {},
    HelperText = "",
    Error = false,
  } = props;

  return (
    <>
      <TextField
        autoFocus={false}
        error={Error}
        label={Label}
        type={Type}
        name={Name}
        value={Data}
        onChange={(e) => Handler(e)}
        placeholder={PlaceHolder}
        required={Required}
        sx={{
          width: Width,
          input: { font: "menu" },
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              display: "none",
            },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
        InputProps={Adroment ? InputProps : ""}
        multiline={Multiline}
        rows={Row}
        disabled={Disabled}
        inputProps={Validation}
        helperText={
          <span
            style={{
              color: "gray",
              fontSize: "10px",
              lineHeight: "1",
              letterSpacing: "0",
            }}
          >
            <small>{HelperText}</small>
          </span>
        }
      />
    </>
  );
}
