import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { MdOutlineCancel, MdArrowBack } from "react-icons/md";
import ImageListItem from "@mui/material/ImageListItem";
import noImage from "../../assets/images/noImage.png";
import AddImage from "../../assets/images/add-image.png";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Badge from "@mui/material/Badge";
import { HiMiniMinusSmall } from "react-icons/hi2";
import CircularLoader from "../../Components/CircularLoader/CircularLoader";

export default function ImagesModal({
  open,
  setOpen,
  images,
  addImages,
  heading,
  editable = true,
}) {
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImageFiles, setSelectedImageFiles] = useState([]);
  const [fullView, setFullView] = useState();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setFullView();
    setSelectedImages([]);
    setSelectedImageFiles([]);
  };

  const handleImageSelect = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to array
    const newImages = [];
    setSelectedImageFiles(files); // Store the array in state

    for (const file of files) {
      const reader = new FileReader();
      const lastDotIndex = file.name.lastIndexOf(".");

      if (lastDotIndex !== -1) {
        const name = file.name.substring(0, lastDotIndex);
        const extension = file.name.substring(lastDotIndex);

        reader.onload = (e) => {
          const newFile = {
            file: e.target.result,
            id: Math.random().toString(),
            name: name,
            extension: extension,
          };
          newImages.push(newFile);
          setSelectedImages((prevFiles) => [...prevFiles, newFile]);
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const handleRemoveSelected = (id, idx) => {
    const updatedImages = selectedImages.filter((image) => image.id !== id);
    const updatedFiles = selectedImageFiles?.filter(
      (_, index) => index !== idx
    ); // Now this works
    setSelectedImages(updatedImages);
    setSelectedImageFiles(updatedFiles);
  };

  const handleSave = async () => {
    setLoading(true);
    const response = await addImages(selectedImageFiles);
    setLoading(false);

    if (!response.error) {
      setSelectedImages([]);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="flex items-center justify-center"
    >
      <div className="bg-white rounded-lg p-6 w-4/5 max-w-2xl shadow-lg relative">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-800">
            {heading ?? "Image Attachments"}
          </h2>
          <div className="flex items-center">
            <MdOutlineCancel
              size={25}
              className="cursor-pointer text-gray-600 hover:text-gray-800 transition duration-200"
              onClick={handleClose}
            />
            {fullView && (
              <MdArrowBack
                size={25}
                className="cursor-pointer text-gray-600 hover:text-gray-800 transition duration-200 ml-2"
                onClick={() => setFullView(false)}
              />
            )}
          </div>
        </div>

        {!fullView ? (
          <div className="grid grid-cols-3 gap-4">
            {images?.map((image, index) => (
              <div key={index} className="relative">
                <ImageListItem className="w-full h-40 border border-dashed border-gray-300 rounded cursor-pointer">
                  <img
                    src={image?.file || noImage}
                    onClick={() => setFullView(image?.file || "")}
                    onError={(e) => {
                      e.target.src = noImage;
                    }}
                    className="object-cover w-full h-full cursor-pointer rounded transition duration-200 hover:shadow-sm"
                    loading="lazy"
                  />
                </ImageListItem>
              </div>
            ))}
            {selectedImages.map((image, index) => (
              <Badge
                key={index}
                badgeContent={
                  <HiMiniMinusSmall
                    size={20}
                    onClick={() => handleRemoveSelected(image.id, index)}
                    className="cursor-pointer text-white hover:text-gray-700"
                  />
                }
                color="error"
                sx={{
                  "& .MuiBadge-badge": {
                    borderRadius: "50%",
                    width: "24px",
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 0,
                  },
                }}
              >
                <div className="relative">
                  <ImageListItem className="w-full h-40 ">
                    <img
                      src={image.file || noImage}
                      onClick={() => setFullView(image.file || noImage)}
                      onError={(e) => {
                        e.target.src = noImage;
                      }}
                      className="object-cover w-full h-full cursor-pointer rounded transition duration-200 shadow-sm"
                    />
                    <ImageListItemBar
                      title={"New " + (index + 1)}
                      position="top"
                      sx={{
                        background:
                          "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                          "rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%)",
                      }}
                    />
                  </ImageListItem>
                </div>
              </Badge>
            ))}
            {editable ? (
              <label
                className="flex flex-col items-center justify-center w-full h-40 border border-dashed border-gray-300 rounded cursor-pointer hover:bg-gray-100 transition duration-200"
                htmlFor="upload_new_img"
              >
                <img src={AddImage} alt="Add" className="w-10 h-10" />
                <span className="mt-2 text-gray-700">
                  {selectedImages.length > 0
                    ? `${selectedImages.length} Images Selected`
                    : "Add New Image"}
                </span>
              </label>
            ) : null}
            <input
              type="file"
              hidden
              id="upload_new_img"
              multiple
              accept="image/*"
              onChange={handleImageSelect}
            />
          </div>
        ) : (
          <div className="flex justify-center items-center h-80">
            <img
              src={fullView || noImage}
              onError={(e) => {
                e.target.src = noImage;
              }}
              className="object-contain w-full h-full rounded-lg shadow-sm"
              loading="lazy"
            />
          </div>
        )}

        <div className="flex justify-end items-center mt-6">
          <button
            className="bg-gray-200 text-gray-700 py-2 px-4 mr-2 rounded hover:bg-gray-300 transition duration-200"
            onClick={handleClose}
          >
            Close
          </button>
          {selectedImages.length > 0 && (
            <button
              className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200"
              onClick={handleSave}
            >
              {loading ? <CircularLoader /> : "Save"}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
}
