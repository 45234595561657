import axios from "./axiosInstance";

export const fetchAllOrders = async (page, perPage, filter, Nested) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `get-orders?perPage=${perPage}&page=${page}&name=${filter}${Nested}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchOrderFullfillmentBillingStatusHistory = async (
  page,
  perPage,
  filter,
  id
) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `order/get-fullfillment-billing-status-history/${id}?perPage=${perPage}&page=${page}&name=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchSingleOrder = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`order/get-by-id/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchShippingCompanies = async () => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`user-shipping-companies/manual`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const addShippingCompany = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`user-shipping-companies/manual/store`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchOrderStatuses = async () => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`userWarehouse/get-order-statuses`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchCostType = async () => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`cost-type/get`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const searchAllOrders = async (token, name, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `get-orders?name=${name}&&${page && `page=${page}`}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const createNewOrder = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`store-order`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const storeOrder = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`order/store`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateOrder = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`order/update/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const addCostType = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`cost-type/store`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const storeAdditionlCost = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`order/store-additional-cost/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const storeOrderChange = async (data, order, status) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `order/update-status/${order}/${status}`,
      data
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const deleteSingleOrder = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.delete(`order/delete/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchOrderNotes = async (orderId) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`/order/order-notes/${orderId}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const createOrderNotes = async (orderId, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`order/store-general-notes/${orderId}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateFullfilmentBillingStatus = async (
  orderId,
  status,
  notes
) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `order/update-fulfillment-billing-status/${orderId}/${status}`,
      { notes: notes }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchOrderAttachments = async (orderId) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`order/order-notes/${orderId}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
