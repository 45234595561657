import React, { useState } from "react";

import Snackbar from "../../../Components/SnackBar/SnackBar";
import TabsComponent from "../../../Components/Common/TabsComponent";
import Locations from "./Locations";
import StorageMap from "./StorageMap";

const StorageLocations = () => {
    // new states
    const [tab, setTab] = useState("locations");

    const [snack, setSnack] = useState({
        open: false,
        vertical: "top",
        horizontal: "right",
        text: "",
        severity: "",
    });

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };


    return (
        <div className="dispatcher__screen" style={{ maxWidth: "100%" }}>
            <Snackbar Snack={snack} SetSnack={setSnack} />
            <TabsComponent
                items={[
                    { label: "Locations", style: { fontWeight: "bold" }, value: "locations" },
                    { label: "Graph", style: { fontWeight: "bold" }, value: "graph" },
                ]}
                tab={tab}
                handleTabChange={handleTabChange}
            />
            {tab === "locations" && <Locations setSnack={setSnack} />}
            {tab === "graph" && <StorageMap />}
        </div>
    );
};

export default StorageLocations;
