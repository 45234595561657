import React, { useState } from "react";
import "../InvoiceModal.css";
import Logo from "../../../../../../../../../assets/images/hajex_logo.png";
import { useSelector } from "react-redux";

const Header = ({ dates, data, type }) => {
  const user = useSelector((state) => state.user);

  function getNextDay() {
    const today = new Date();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${today.getFullYear()}-${month}-${day}`;
  }
  let contact_list = type === "Print" ? "tax__slip_view_contact_list_box_print" : "tax__slip_view_contact_list_box";
  let list_box = type === "Print" ? "tax__slip_view_list_box_print" : "tax__slip_view_list_box";
  
  return (
    <>
      <div className="ltlinvoice__modal_topbar"></div>

      <div className="tax__slip_view_details_wrap">
        <div className="tax__slip_view_details_header">
          <div className="tax__slip_view_details_header_logo">
            <img
              src={Logo}
              alt="Logo"
              className="ltlinvoice__modal_headerleftimg"
            />
          </div>
          <div className="tax__slip_view_details_interval_total"></div>
        </div>
        <div className="tax__slip_view_contact_details">
          <div className="tax__slip_view_contact_list">
            <div className={contact_list}>
              <h3>General inquiries:</h3>
              <p>855-999-6659</p>
            </div>
            <div className={contact_list}>
              <h3>Questions about your Invoices:</h3>
              <p>info@hajexbolt.com</p>
            </div>
          </div>
          <div className={type === "Print" ? "tax__slip_view_created_list_print" : "tax__slip_view_created_list"}>
            <div className={list_box}>
              <h3>Created At</h3>
              <p>{getNextDay()}</p>
            </div>
            <div className={list_box}>
              <h3>Created By</h3>
              <p>{user?.username ?? "-"}</p>
            </div>
            <div className={list_box}>
              <h3>Business Name</h3>
              <p>{user?.username}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
