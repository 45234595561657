import axios from "../axiosInstance";

export const getCardSetupIntent = async () => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`stripe/card-setup-intent`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const confirmAddCardWithCharge = async (payment_method_id, amount) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `stripe/confirm-add-card/${payment_method_id}`,
      { amount: amount }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const confirmAddCard = async (payment_method_id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `stripe/confirm-add-card/${payment_method_id}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const chargeAmount = async (amount, payment_method_id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`stripe/charge-amount`, {
      amount: amount,
      payment_method_id: payment_method_id,
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const getCards = async () => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`stripe/payment-methods`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const setDefaultCard = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`stripe/set-default-payment-method/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const deleteStripeCard = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`stripe/delete-payment-method/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
