import React from "react";
import "./Forms.css";
const NationxForm = ({ carrier }) => {
  return (
    <div className="cuscarriers__form_wrap">
      <div className="cuscarriers__form_logo">
        <img src={carrier?.logo_url} />
      </div>
      <div className="cuscarriers__form_content">
        <div className="cuscarriers__fcntnt_fieldwrap">
          <label className="cuscarriers__fcntnt_fieldlbl">Account Name*</label>
          <input
            type="text"
            className="cuscarriers__fcntnt_field"
            placeholder="Account Name To Be Displayed"
          />
        </div>
      </div>
    </div>
  );
};

export default NationxForm;
