import React, { useEffect, useState } from "react";
import "./transferRequestDetails.css";

// components imports
import Loader from "../../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import { updateTransferNotesRequest } from "../../../../../../services/WarehouseServices/warehouse.services";
// library imports
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const TransferRequestDetails = ({
  open,
  setOpen,
  setSnack,
  setTransferNotes,
}) => {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    status: "",
    description: "",
  });

  useEffect(() => {
    setFormData({
      status: open?.status,
      description: open.description,
    });
  }, [open]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUpdate = async () => {
    setLoading(true);
    const response = await updateTransferNotesRequest(
      open?.id,
      formData?.status,
      formData?.description
    );
    setLoading(false);
    // console.log(response);
    if (!response?.data?.success) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong!",
          severity: "error",
        };
      });
    } else {
      setTransferNotes((prevData) => {
        return {
          ...prevData,
          data: prevData?.data?.map((data) => {
            return data?.id === open?.id ? response?.data?.result : data;
          }),
        };
      });

      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Status Updated Successfully!",
          severity: "success",
        };
      });
    }
  };
  
  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="transfer_note_details_modal_title"
        aria-describedby="transfer_note_details_modal_description"
      >
        <div className="details_container_modal">
          <h3 className="details_container_modal_title">
            Details for # {open?.reference_no ?? "-"}
          </h3>

          <div className="details_container_modal_body">
            <div className="custabscreen__rdverttable_row">
              <div className="custabscreen__rdverttable_heading">Date:</div>
              <div className="custabscreen__rdverttable_data">
                {open?.created_at?.slice(0, 10) ?? "-"}
              </div>
            </div>

            <div className="custabscreen__rdverttable_row">
              <div className="custabscreen__rdverttable_heading">
                Reference Number:
              </div>
              <div className="custabscreen__rdverttable_data">
                {open?.reference_no ?? "-"}
              </div>
            </div>

            <div className="custabscreen__rdverttable_row">
              <div className="custabscreen__rdverttable_heading">
                From Warehouse:
              </div>
              <div className="custabscreen__rdverttable_data">
                {open?.from_warehouse?.name ?? "-"}
              </div>
            </div>

            <div className="custabscreen__rdverttable_row">
              <div className="custabscreen__rdverttable_heading">
                To Warehouse:
              </div>
              <div className="custabscreen__rdverttable_data">
                {open?.to_warehouse?.name ?? "-"}
              </div>
            </div>

            <div className="custabscreen__rdverttable_row">
              <div className="custabscreen__rdverttable_heading">
                Created By:
              </div>
              <div className="custabscreen__rdverttable_data">
                {user?.username ?? "-"}
              </div>
            </div>
          </div>
          <div className="transfer__request_statuschange_section">
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-label" size="small">
                Select Status *
              </InputLabel>
              <Select
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select-label"
                size="small"
                value={formData?.status || ""}
                onChange={(e) =>
                  handleChange({
                    target: {
                      name: "status",
                      value: e.target.value,
                    },
                  })
                }
                label="Select Status"
              >
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="received">Received</MenuItem>
                <MenuItem value="notReceived">Not Received</MenuItem>
              </Select>
            </FormControl>

            <div className="">
              <label className="edit__grn_byform_label">Note</label>
              <textarea
                className="edit__grn_byform_textarea"
                name="description"
                required
                value={formData?.description}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="details_container_modal_body">
            <TableContainer>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <strong>Product Code</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Name</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Quantity</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {open?.transfer_note_details?.map((transferDetail, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell
                          classes={{ padding: "0px 16px" }}
                          align="center"
                        >
                          {transferDetail?.id ?? "-"}
                        </TableCell>
                        <TableCell
                          classes={{ padding: "0px 16px" }}
                          align="center"
                        >
                          {transferDetail?.product?.full_name ?? "-"}
                        </TableCell>

                        <TableCell
                          classes={{ padding: "0px 16px" }}
                          align="center"
                        >
                          {transferDetail.quantity ?? "-"}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="transfer__request_modalfooter">
            <div
              className="transfer__request_modalreset"
              onClick={() => setOpen(false)}
            >
              Close
            </div>
            <div className="admnedit__user_modalsave" onClick={handleUpdate}>
              {loading ? <Loader /> : "Update"}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TransferRequestDetails;
