import axios from "../../axiosInstance";

export const fetchShipperTransactions = async (page, perPage, filter) => {
  let response = { data: null, error: null };

  try {
    const res = await axios.get(
      `get-user-transactions?perPage=${perPage}&page=${page}&name=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchLtlTransactions = async (page, perPage, filter) => {
  let response = { data: null, error: null };

  try {
    const res = await axios.get(
      `warehouse/payment/get-all-transactions?perPage=${perPage}&page=${page}&name=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchUserInvoices = async (page, perPage, filter) => {
  let response = { data: null, error: null };

  try {
    const res = await axios.get(
      `user-invoices/get?perPage=${perPage}&page=${page}&filterValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchShipperCalculatedTax = async (data) => {
  let response = { data: null, error: null };

  try {
    const res = await axios.post(`shipment/calculate-tax`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchLtlInvoiceOrders = async (data) => {
  let response = { data: null, error: null };

  try {
    const res = await axios.post(`order/filter-order`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchLtlInvoiceOrdersWarehouse = async (data, id) => {
  let response = { data: null, error: null };

  try {
    const res = await axios.post(`order/filter-order/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const addBalance = async (data) => {
  let response = { data: null, error: null };

  try {
    const res = await axios.post(`payment/store`, data, {});
    response = { ...response, data: res.data };
    // console.log(res);
  } catch (error) {
    response = { ...response, error: error.response.data.message };
  }
  return response;
};
export const addLTLBalance = async (data, id) => {
  let response = { data: null, error: null };

  try {
    const res = await axios.post(`warehouse/payment/add/${id}`, data, {});
    response = { ...response, data: res.data };
    // console.log(res);
  } catch (error) {
    response = { ...response, error: error.response.data.message };
  }
  return response;
};
export const getLTLdetails = async (id) => {
  let response = { data: null, error: null };

  try {
    const res = await axios.get(
      `warehouse/warehouse-payment/get-by-warehouse-id/${id}`,
      {}
    );
    response = { ...response, data: res.data };
    // console.log(res);
  } catch (error) {
    response = { ...response, error: error.response.data.message };
  }
  return response;
};
export const getCardSetupIntent = async () => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`stripe/card-setup-intent`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const confirmAddCard = async (payment_method_id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `stripe/confirm-add-card/${payment_method_id}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchShipperPendingTransactions = async (
  page,
  perPage,
  filter
) => {
  let response = { data: null, error: null };

  try {
    const res = await axios.get(
      `payment/get-by-user?perPage=${perPage}&page=${page}&filterValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchLtlPendingTransactions = async (page, perPage, filter) => {
  let response = { data: null, error: null };

  try {
    const res = await axios.get(
      `warehouse/payment/get-all/pending?perPage=${perPage}&page=${page}&filterValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const updateLTlBalance = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`warehouse/payment/update-status/${id}/paid`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const setDefaultCard = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`stripe/set-default-payment-method/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const deleteStripeCard = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`stripe/delete-payment-method/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchCreditCards = async () => {
  let response = { data: null, error: null };

  try {
    const res = await axios.get(`stripe/payment-methods`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchBankDetails = async (warehouseId) => {
  let response = { data: null, error: null };

  try {
    const res = await axios.get(
      `warehouse/warehouse-payment/get-detail-by-warehouse-id/${warehouseId}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const createBankDetails = async (data,warehouseId) => {
  let response = { data: null, error: null };

  try {
    const res = await axios.post(
      `warehouse/warehouse-payment/store/${warehouseId}`,data
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
