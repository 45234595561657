import React, { useEffect, useState } from "react";
import "./AddMarketplace.css";

// media imports
import BestBuy from "../../../../../../../assets/images/marketplaces/bestbuy.png";
import EBay from "../../../../../../../assets/images/marketplaces/ebay.png";
import WooCommerce from "../../../../../../../assets/images/marketplaces/woocommerce.png";
import Shopify from "../../../../../../../assets/images/marketplaces/shopify.png";
import Amazon from "../../../../../../../assets/images/marketplaces/amazon.png";
import Walmart from "../../../../../../../assets/images/marketplaces/walmart.png";

// components imports
import Loader from "../../../../../../../Components/CircularLoader/CircularLoader";
import AmazonForm from "./Forms/AmazonForm";

// apis imports
import { addCustomCarrier } from "../../../../../../../services/ShipperServices/Carriers/carriers.services";
import { toCamelCase } from "../../../../../../../helpers/helpers";

// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import { FaRegTimesCircle } from "react-icons/fa";
import { createMarketplaceAccount } from "../../../../../../../services/ShipperServices/MarketPlaces/marketplaces.services";
import WalmartForm from "./Forms/WalmartForm";
import Tooltip from "../../../../../../../Components/Tooltip/Tooltip";

const marketplaces = [
  {
    id: 1,
    title: "Amazon",
    name: "amazon",
    logo_url: Amazon,
  },
  {
    id: 2,
    title: "Walmart",
    name: "walmart",
    logo_url: Walmart,
  },
  {
    id: 3,
    title: "Woo Commerce",
    name: "woocommerce",
    logo_url: WooCommerce,
  },
  {
    id: 4,
    title: "Shopify",
    name: "shopify",
    logo_url: Shopify,
  },
  {
    id: 5,
    title: "eBay",
    name: "ebay",
    logo_url: EBay,
  },
  {
    id: 6,
    title: "Best Buy",
    name: "bestbuy",
    logo_url: BestBuy,
  },
];

export default function AddMarketplace({
  open,
  setOpen,
  setSnack,
  setMarketPlaces,
}) {
  const [activeBox, setActiveBox] = useState(1);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState();
  const [selectedMarketplace, setSelectedMarketplace] = useState();
  const handleClose = () => {
    setOpen(false);
    setActiveBox(1);
  };

  const handleMarketplaceClick = (carrier) => {
    setSelectedMarketplace(carrier);
    setActiveBox(2);
  };

  const handlePrevClick = () => {
    setActiveBox(activeBox - 1);
  };

  const handleSave = async () => {
    setLoading(true);
    console.log(payload);
    try {
      const saved = await createMarketplaceAccount({
        ...payload,
      });
      setLoading(false);
      if (saved?.data?.success) {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Account Added successfully",
            severity: "success",
          };
        });
        setMarketPlaces((prevState) => {
          return {
            ...prevState,
            data: saved.data.result.data,
          };
        });
        handleClose();
      } else {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text:
              saved?.error?.response?.data?.message ?? "Something went wrong",
            severity: "error",
          };
        });
      }
    } catch (error) {
      setLoading(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="addmkplc__account_modal">
          <div className="addmkplc__account_modalheading">
            Add Marketplace Account
          </div>
          <div className="manage__services_closewrap" onClick={handleClose}>
            <IconButton>
              <FaRegTimesCircle size={25} color="lightgrey" />
            </IconButton>
          </div>
          <div className="addmkplc__account_modalcontent">
            <div
              className={`addmkplc__account_modalcontentbox ${
                activeBox === 1
                  ? "addmkplc__account_modalcontentactivebox"
                  : activeBox > 1
                  ? "addmkplc__account_modalcontentboxleft"
                  : "addmkplc__account_modalcontentboxright"
              }`}
            >
              <div className="addmkplc__account_methods">
                {marketplaces?.map((marketplace) => (
                  <div
                    className={`addmkplc__account_method ${
                      [3, 4, 5, 6].includes(marketplace?.id)
                        ? "disable__click_fade"
                        : ""
                    }`}
                    onClick={() => handleMarketplaceClick(marketplace)}
                  >
                    <div className="addmkplc__account_methodicon">
                      <img src={marketplace?.logo_url} alt="" />{" "}
                    </div>
                    <div className="addmkplc__account_methodtxt">
                      {marketplace?.title}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div
              className={`addmkplc__account_modalcontentbox ${
                activeBox === 2
                  ? "addmkplc__account_modalcontentactivebox"
                  : activeBox > 2
                  ? "addmkplc__account_modalcontentboxleft"
                  : "addmkplc__account_modalcontentboxright"
              }`}
            >
              {selectedMarketplace?.id === 1 && (
                <>
                  <AmazonForm
                    marketplace={selectedMarketplace}
                    setFinalPayload={setPayload}
                  />
                </>
              )}
              {selectedMarketplace?.id === 2 && (
                <>
                  <WalmartForm
                    marketplace={selectedMarketplace}
                    setFinalPayload={setPayload}
                  />
                </>
              )}
            </div>
          </div>
          <div
            className="addmkplc__account_modalfooter"
            style={{
              justifyContent: activeBox > 1 ? "space-between" : "flex-end",
            }}
          >
            {activeBox > 1 && (
              <div
                className="addmkplc__account_modalcncl"
                onClick={handlePrevClick}
              >
                Back
              </div>
            )}

            {activeBox === 2 && (
              <>
                <Tooltip title="Coming Soon">
                  <div
                    className={`addmkplc__account_modalsave ${
                      activeBox < 2 && "addmkplc__account_modalsavedisabled"
                    }`}
                    style={{ opacity: "0.6" }}
                    // onClick={handleSave}
                  >
                    <>{loading ? <Loader /> : "Save"}</>
                  </div>
                </Tooltip>
                {/* <div
                  className={`addmkplc__account_modalsave ${
                    activeBox < 2 && "addmkplc__account_modalsavedisabled"
                  }`}
                  onClick={handleSave}
                >
                  <>{loading ? <Loader /> : "Save"}</>
                </div> */}
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
