import React, { useState, useEffect } from "react";
import "./ManageRoles.css";
import {
  updateRoleData,
  deleteRoleData,
} from "../../../../../../../services/settings.services";
import { useSelector } from "react-redux";
import { BsTrash } from "react-icons/bs";
import Confirm from "../../../../../../../Components/Popups/Confirm";

const ManageRoles = ({ roles, permisions, setRoles, setSnack, loader }) => {
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteRole, setDeleteRole] = useState(null);

  const updateData = async (role, permissions) => {
    let saved = await updateRoleData(
      user.token,
      {
        name: role.name,
        permissions: permissions,
      },
      role.id
    );
    if (!saved.error) {
      setSnack((snack) => {
        let index = roles.findIndex((r) => r.id === role.id);
        setRoles((prevRoles) => {
          const updatedRoles = [...prevRoles];
          updatedRoles[index].name = role.name;
          return updatedRoles;
        });
        return {
          ...snack,
          open: true,
          text: "Role updated successfully",
          severity: "success",
        };
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: saved?.error?.response?.data?.message ?? "Something went wrong",
          severity: "error",
        };
      });
    }
  };
  const parentPermissions = async (role, parent, e) => {
    console.log("permissions===", role, parent);
    let array = [...roles];
    let index = array.findIndex((u) => u.id === role.id);
    let ids = parent.children.map((c) => c.id);
    if (e.target.checked) {
      array[index].permissions = Array.from(
        new Set([...array[index].permissions, ...ids, parent.id])
      );
      setRoles(array);
      await updateData(role, [...array[index].permissions]);
    } else {
      let elementsToRemove = [...ids, parent.id];
      array[index].permissions = array[index].permissions.filter(
        (p) => !elementsToRemove.includes(p)
      );

      setRoles(array);
      await updateData(role, [...array[index].permissions]);
    }
  };

  const childPermissions = async (role, child, e) => {
    let array = [...roles];
    let index = array.findIndex((u) => u.id === role.id);
    if (e.target.checked) {
      array[index].permissions.push(child.id);
      setRoles(array);
      await updateData(role, [...array[index].permissions]);
    } else {
      array[index].permissions = array[index].permissions.filter(
        (p) => p !== child.id
      );
      setRoles(array);
      await updateData(role, [...array[index].permissions]);
    }
  };
  const confirmDeleteRole = async () => {
    console.log("delete role===", deleteRole);
    setIsLoading(true);
    let saved = await deleteRoleData(user.token, deleteRole.id);
    if (!saved.error) {
      setIsLoading(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Role deleted successfully",
          severity: "success",
        };
      });
      setRoles((prev) => {
        return prev.filter((r) => r.id != deleteRole.id);
      });
      setDeleteRole(null);
    } else {
      setIsLoading(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: saved?.error?.response?.data?.message ?? "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  // editing text code
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editedText, setEditedText] = useState({ index: -1 });

  const handleTextClick = (index, role) => {
    console.log("clicked");
    setEditingIndex(index);
    setEditedText({
      ...editedText,
      index,
      name: role.name,
      permissions: role.permissions,
      id: role.id,
    });
  };

  const handleInputChange = (event) => {
    setEditedText({ ...editedText, name: event.target.value });
  };

  const handleBlur = async () => {
    // console.log("Edited Text:", editedText.name);
    if (editingIndex !== -1) {
      // console.log("Edited Text:", editedText.name);
      setIsLoading(true);
      await updateData(editedText, editedText.permissions);
      setEditingIndex(-1);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="mngroles__tabel">
        <Confirm
          open={confirmDelete}
          setOpen={setConfirmDelete}
          handleConfirm={() => confirmDeleteRole()}
          text={
            <>
              Do you want to delete <b>{deleteRole?.name}</b> role?
            </>
          }
        />
        <table id="customers" className="tabel__scroll">
          <tr className="mngroles__header_tr">
            <th style={{ textAlign: "left" }} className="mngroles__header_th">
              Permissions
            </th>
            {roles.map((role, roleIndex) => {
              return (
                <th
                  key={roleIndex}
                  className="mngroles__header_th"
                  onClick={() => handleTextClick(roleIndex, role)}
                >
                  {editingIndex === roleIndex ? (
                    <>
                      {isLoading ? (
                        <div className="d-flex justify-center">{loader}</div>
                      ) : (
                        <input
                          className="mngroles__input_style"
                          type="text"
                          value={editedText.name} // Display editedText.name in the input
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          // autoFocus
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {editingIndex === roleIndex && isLoading ? (
                        <div className="d-flex justify-center">{loader}</div>
                      ) : (
                        <div className="mngroles__header_delete">
                          <span
                          // onClick={() => handleTextClick(roleIndex, role)}
                          >
                            {role.name}
                          </span>
                          <span>
                            <BsTrash
                              onClick={() => {
                                setConfirmDelete(true);
                                setDeleteRole(role);
                                // handleTextClick(roleIndex, role);
                              }}
                            />
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </th>
              );
            })}
          </tr>
          {permisions?.map((data, index) => {
            return (
              <>
                <tr className="mngroles__header" key={index}>
                  <td className="mngroles__td1">{data?.description}</td>
                  {roles.map((role, roleIndex) => {
                    return (
                      <td className="mngroles__td2" key={roleIndex}>
                        <input
                          type="checkbox"
                          // disabled={role.rolesPermissions.includes(
                          //   data.id
                          // )}
                          checked={role.permissions.includes(data.id)}
                          onChange={(e) => parentPermissions(role, data, e)}
                        />
                      </td>
                    );
                  })}
                </tr>

                {data?.children?.map((child, cindex) => {
                  return (
                    <tr key={cindex} className="mngroles__sub_tr">
                      <td className="mngroles__sub_td1">{child.description}</td>
                      {roles.map((role, roleIndex) => {
                        return (
                          <td className="mngroles__sub_td2" key={roleIndex}>
                            <input
                              type="checkbox"
                              // disabled={role.rolesPermissions.includes(
                              //   child.id
                              // )}
                              checked={role.permissions.includes(child.id)}
                              onChange={(e) => childPermissions(role, child, e)}
                            />
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </>
            );
          })}
        </table>
      </div>
    </>
  );
};

export default ManageRoles;
