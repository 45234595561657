import React, { useState } from "react";
import "./Products.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { IoInformationCircleOutline } from "react-icons/io5";
import IconButton from "@mui/material/IconButton";
import Tooltip from "../../../../../../Components/Tooltip/Tooltip";
import PackagesScroller from "./Packages/PackagesScroller";
import RadioStack from "./RadioStack";
import BundlesScroller from "./Bundles/BundlesScroller";
import ProductScroller from "./Products/ProductScroller";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";

export default function Products(props) {
  const {
    Plist,
    SetPlist,
    StepCheck,
    SetStepCheck,
    SelectedProducts,
    setSelectedProducts,
    bundleRefresh,
    setbundleRefresh,
    packageRefresh,
    setpackageRefresh,
  } = props;

  const [isExpanded, setIsExpanded] = useState(true);
  const [view, setView] = useState("List");

  const handleExpandToggle = () => {
    setIsExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <>
      <div className="card bg-white shadow wapper_like_shopify productDetailsCustom__main_package_wrap">
        <div className="card-header">
          <span>My Products</span>
          <Tooltip title="Saved Products , Bundles & Packages">
            <IconButton>
              <IoInformationCircleOutline size={12} />
            </IconButton>
          </Tooltip>
        </div>
        {/* <div className="expand_btn" onClick={handleExpandToggle}>
        {isExpanded ? (
          <>
            <Tooltip title="Collapse">
              <IconButton>
                <MdOutlineKeyboardArrowUp size={15} />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip title="Expand">
              <IconButton className="fullfilment__form_expand_btn">
                <MdOutlineKeyboardArrowDown size={15} />
              </IconButton>
            </Tooltip>
          </>
        )}
      </div> */}
        <div className="card-body product_scroll_my_product  myprod_card_body productDetailsCustom__main_package_bundles">
          {/* <div className="productDetails__main_package_bundles_btn productDetailsCustom__main_package_bundles_btn ">
            <RadioStack
              List={StepCheck}
              SetList={SetStepCheck}
              Type="Products"
              Type1="Products"
              Type2="Bundles"
              Type3="Packages"
            />
          </div> */}
          {StepCheck["Products"] === "Packages" && (
            <Row
              className={`mb-1 product__scrollbar_list ${
                isExpanded ? "expanded" : "collapse__row"
              }`}
            >
              <Col md={12}>
                <PackagesScroller
                  Plist={Plist}
                  SetPlist={SetPlist}
                  StepCheck={StepCheck}
                  SetStepCheck={SetStepCheck}
                  packageRefresh={packageRefresh}
                  setpackageRefresh={setpackageRefresh}
                  view={view}
                  setView={setView}
                />
              </Col>
            </Row>
          )}
          {StepCheck["Products"] === "Bundles" && (
            <Row
              className={`mb-1 product__scrollbar_list ${
                isExpanded ? "expanded" : "collapse__row"
              }`}
            >
              <Col md={12}>
                <BundlesScroller
                  Plist={Plist}
                  SetPlist={SetPlist}
                  StepCheck={StepCheck}
                  SetStepCheck={SetStepCheck}
                  bundleRefresh={bundleRefresh}
                  setbundleRefresh={setbundleRefresh}
                  view={view}
                  setView={setView}
                />
              </Col>
            </Row>
          )}
          {StepCheck["Products"] === "Products" && (
            <Row
              className={`mb-1 product__scrollbar_list ${
                isExpanded ? "expanded" : "collapse__row"
              }`}
            >
              <Col md={12}>
                <ProductScroller
                  Plist={Plist}
                  SetPlist={SetPlist}
                  StepCheck={StepCheck}
                  SetStepCheck={SetStepCheck}
                  SelectedProducts={SelectedProducts}
                  setSelectedProducts={setSelectedProducts}
                  view={view}
                  setView={setView}
                />
              </Col>
            </Row>
          )}
        </div>
      </div>
    </>
  );
}
