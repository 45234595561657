import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./AddressFormCard.css";
import IconButton from "@mui/material/IconButton";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { IoInformationCircleOutline } from "react-icons/io5";
import Tooltip from "../../../../../../Components/Tooltip/Tooltip";

export default function AddressFormCard(props) {
  const { StepCheck, SetStepCheck, Type, children } = props;

  const handleform = () => {
    SetStepCheck((prevStepCheck) => ({
      ...prevStepCheck,
      fullform: !StepCheck["fullform"],
    }));
  };

  return (
    <>
      <div className="card bg-white shadow address__form_card wapper_like_shopify">
        <div
          className="card-header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="card__header_heading">
            {Type === "Flist" ? (
              <span>PICKUP ADDRESS (SHIPPER)</span>
            ) : (
              <span>DELIVERY ADDRESS (CONSIGNEE)</span>
            )}
            <Tooltip
              title={
                Type === "Flist"
                  ? "Where are you shipping from"
                  : "Where are you shipping to"
              }
              
            >
              <IconButton>
                <IoInformationCircleOutline size={15}  className="tooltip__flist"/>
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <Tooltip
              title={StepCheck["fullform"] === true ? "Collapse" : "Expand"}
            >
              <IconButton onClick={handleform} className="arrow__card_box">
                {StepCheck["fullform"] === true ? (
                  <MdOutlineKeyboardArrowUp size={25} />
                ) : (
                  <MdOutlineKeyboardArrowDown size={25} />
                )}
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div className="card-body card_shipper_address_body">
          <Row className="mx-auto">
            <Col md={12} className="mx-auto">
              {children}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
