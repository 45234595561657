import React from "react";
import Sad from "@mui/icons-material/SentimentVeryDissatisfied";

const ProductNotAvailable = () => {
  return (
    <div className="driver__screen_empty  no_warehouse_found">
      <div className="driver__scrnempt_content">
        <Sad className="driver__scrnempt_contenticon" />
        <div>Connect Warehouse to Access this Page!</div>
      </div>
    </div>
  );
};

export default ProductNotAvailable;
