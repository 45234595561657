import React, { useEffect, useRef } from "react";
import Quagga from "quagga";

const BarcodeScanner = ({ setData, handleClose }) => {
  const scannerRef = useRef(null);

  const onScan = (data) => {
    if (data) {
      setData(data);
      handleClose();
    }
  };

  useEffect(() => {
    if (scannerRef.current) {
      Quagga.init(
        {
          inputStream: {
            type: "LiveStream",
            target: scannerRef.current,
            constraints: {
              facingMode: "environment",
            },
          },
          decoder: {
            readers: ["code_128_reader", "ean_reader"],
          },
        },
        (err) => {
          if (err) {
            console.error(err);
            return;
          }
          Quagga.start();
        }
      );

      Quagga.onDetected((data) => {
        onScan(data.codeResult.code);
      });

      return () => {
        Quagga.stop();
      };
    }
  }, []);


  const scannerWrapStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '200px',
    height: '100px',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'none',
  };

  const beforeAfterStyle = {
    display: 'block',
    content: '""',
    width: '30px',
    height: '30px',
    position: 'absolute',
    borderTop: '1px solid #1976d2',
    borderRight: '1px solid #1976d2',
  };

  const beforeStyle = {
    ...beforeAfterStyle,
    right: '-10px',
    transform: 'rotate(90deg)',
    bottom: '-10px',
  };

  const afterStyle = {
    ...beforeAfterStyle,
    top: '-10px',
    right: '-10px',
  };

  const spanBeforeStyle = {
    ...beforeAfterStyle,
    bottom: '-10px',
    left: '-10px',
    transform: 'rotate(180deg)',
  };

  const spanAfterStyle = {
    ...beforeAfterStyle,
    top: '-10px',
    left: '-10px',
    transform: 'rotate(-90deg)',
  };

  return (
    <div
      ref={scannerRef}
      style={{
        position: "relative",
        width: "100%",
        height: "200px",
        overflow: "hidden",
      }}
    >
      <div style={scannerWrapStyle}>
      <div style={beforeStyle}></div>
      <div style={afterStyle}></div>
      <span >
        <div style={spanBeforeStyle}></div>
        <div style={spanAfterStyle}></div>
      </span>
    </div>
    </div>
  );
};

export default BarcodeScanner;
