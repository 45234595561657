import React, { useEffect, useState } from "react";
import "./editCurrency.css";
// components imports
import Loader from "../../../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import { updateCurrency } from "../../../../../../../services/admin.services";
// library imports
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Modal from "@mui/material/Modal";

const EditCurrency = ({ open, setOpen, setSnack, setTransferCurrencies }) => {
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    code: "",
    symbol: "",
    rate: "",
    id: "",
  });

  const handelChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setFormData({
      name: open?.name,
      code: open?.code,
      symbol: open?.symbol,
      rate: open?.rate,
      id: open?.id,
    });
  }, [open]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.name === "") {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please enter currency name",
          severity: "error",
        };
      });
      return;
    }
    if (formData.code === "") {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please enter code for currency",
          severity: "error",
        };
      });
      return;
    }
    if (formData.rate === "") {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please enter rate for currency",
          severity: "error",
        };
      });
      return;
    }
    if (formData.symbol === "") {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please enter symbol for currency",
          severity: "error",
        };
      });
      return;
    }
    setLoading(true);
    const response = await updateCurrency(formData.id, {
      name: formData?.name,
      code: formData?.code,
      symbol: formData?.symbol,
      rate: formData?.rate,
    });

    // console.log("response", response.data.result);
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
      setLoading(false);
    } else {
      setTransferCurrencies((prevTransferCurrencies) => {
        return {
          ...prevTransferCurrencies,
          data: prevTransferCurrencies?.data?.map((cur) => {
            return cur.id === open?.id ? response?.data?.result : cur;
          }),
        };
      });
      setLoading(false);
      setOpen(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Currency updated successfully",
          severity: "success",
        };
      });
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="add-currency-modal-title"
        aria-describedby="add-currency-modal-description"
      >
        <div className="currencyStock__add_new_currency_modal">
          <div className="currencyStock__add_new_currency_modal_wrap">
            <div className="currencyStock__add_new_currency">
              <div className="currencyStock__add_new_currency_header">
                <h3 className="currencyStock__add_new_currency_title">
                  Edit Currency
                </h3>
              </div>
              <div className="currencyStock__add_new_currency_body">
                <div className="currencyStock__add_new_currency_body">
                  <FormControl
                    variant="outlined"
                    className="currencyStock__add_new_currency_code"
                  >
                    <InputLabel htmlFor="addCurrencyCode" size="small">
                      Code *
                    </InputLabel>
                    <OutlinedInput
                      id="addCurrencyCode"
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      name="code"
                      value={formData.code}
                      onChange={(e) => handelChange(e)}
                      size="small"
                      label="Code *"
                    />
                  </FormControl>

                  <FormControl
                    variant="outlined"
                    className="currencyStock__add_new_currency_name"
                  >
                    <InputLabel htmlFor="addCurrencyName" size="small">
                      Name *
                    </InputLabel>
                    <OutlinedInput
                      id="addCurrencyName"
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      name="name"
                      value={formData.name}
                      onChange={(e) => handelChange(e)}
                      size="small"
                      label="Name *"
                    />
                  </FormControl>

                  <FormControl
                    variant="outlined"
                    className="currencyStock__add_new_currency_rate"
                  >
                    <InputLabel htmlFor="addCurrencyRate" size="small">
                      Rate *
                    </InputLabel>
                    <OutlinedInput
                      id="addCurrencyRate"
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      name="rate"
                      value={formData.rate}
                      onChange={(e) => handelChange(e)}
                      size="small"
                      label="Rate *"
                    />
                  </FormControl>

                  <FormControl
                    variant="outlined"
                    className="currencyStock__add_new_currency_symbol"
                  >
                    <InputLabel htmlFor="addCurrencySymbol" size="small">
                      Symbol *
                    </InputLabel>
                    <OutlinedInput
                      id="addCurrencySymbol"
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      name="symbol"
                      value={formData.symbol}
                      onChange={(e) => handelChange(e)}
                      size="small"
                      label="Symbol *"
                    />
                  </FormControl>

                  <div className="currencyStock__edit_currency_modalfooter">
                    <div
                      className="currencyStock__edit_currency_modalreset"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </div>
                    <div
                      className="currencyStock__edit_currency_modalsubmit"
                      onClick={handleSubmit}
                    >
                      {loading ? <Loader /> : "Save"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditCurrency;
