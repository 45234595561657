import React, { useEffect, useState } from "react";
import "./ManageServices.css";

// components imports
// apis imports
// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import { FaRegTimesCircle } from "react-icons/fa";
import { updateServiceStatusByAdmin } from "../../../../../services/AdminServices/Companies/companies.services";

export default function ManageServices({
  open,
  setOpen,
  carrier,
  Android12Switch,
  setShippingCompanies,
  setSnack,
}) {
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleServiceChange = async (e, service) => {
    setLoader(true);
    const response = await updateServiceStatusByAdmin(
      { [service]: e.target.checked },
      data?.id
    );
    if (!response.error) {
      setData((data) => {
        return {
          ...data,
          services: response?.data?.result?.services,
        };
      });
      setShippingCompanies((prevShippingCompanies) => {
        return {
          ...prevShippingCompanies,
          data: prevShippingCompanies?.data.map((carr) => {
            if (carr.id === data?.id) {
              return {
                ...data,
                services: response?.data?.result?.services,
              };
            } else {
              return carr;
            }
          }),
        };
      });
      setLoader(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `${service} service ${
            !e.target.checked ? "enabled" : "disabled"
          } for ${data.name ?? "this carrier"}`,
          severity: !e.target.checked ? "success" : "info",
        };
      });
    } else {
      setLoader(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `Something went wrong!`,
          severity: "error",
        };
      });
    }
  };
  useEffect(() => {
    if (carrier) {
      setData(carrier);
    }
  }, [carrier]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="manage__services_modal">
          <div className="manage__services_closewrap" onClick={handleClose}>
            <IconButton>
              <FaRegTimesCircle size={25} color="lightgrey" />
            </IconButton>
          </div>
          <div className="manage__services_modalheader">
            <h2>
              <span className="manage__services_mhheadingcarr">
                {carrier?.name ? `${carrier?.name} ` : ""}
              </span>
              Services
            </h2>
            <img
              className="manage__services_modalhdrimg"
              src={data?.shipping_company_for_user?.logo_url}
            />
          </div>
          <div className="manage__services_modalcontent">
            <div className="manage__services_srvcsgrid">
              {data?.services &&
                Object.entries(data?.services)?.map(([service, status]) => {
                  return (
                    <div>
                      <div className="manage__services_srvcsgitem">
                        <Android12Switch
                          onChange={(e) => handleServiceChange(e, service)}
                          checked={status}
                          disabled={loader}
                        />
                        <span className="manage__services_srvcsgitxt">
                          {service.toUpperCase()}
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>
            {(data?.services?.length < 1 || !data?.services) && (
              <div className="manage__services_srvcsempt">
                No Services Available!
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
