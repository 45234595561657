import React, { useState, useEffect, useRef } from "react";
import { initialTrackingTab } from "../../../../../data/permissionsArray";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./Tracking.css";
import { fetchShipments } from "../../../../../services/ShipperServices/Shipments/shipments.services";

import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../../../store/userSlice";

import Snackbar from "../../../../../Components/SnackBar/SnackBar";
import ShipmentsTable from "../../../../../Components/BoltTable/BoltTable";
import StatusIcons from "../../../../../Components/StatusIcons/StatusIcons";
import TabsComponent from "../../../../../Components/Common/TabsComponent";

const Tracking = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [shipments, setShipments] = useState([]);
  const [tab, setTab] = useState("all");
  const [tabPermissions, setTabPermissions] = useState(initialTrackingTab);
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  useEffect(() => {
    if (user.role != "Shipper") {
      let validPermissions = initialTrackingTab.filter((t) =>
        user?.mergedPermissions?.includes(t.name)
      );
      if (validPermissions?.length > 0) {
        setTab(validPermissions[0]?.value);
      } else {
        setTab("nothing");
      }
      setTabPermissions(validPermissions);
    } else {
      setTab("all");
    }
  }, []);
  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 1,
      label: "Carrier",
      render: (row) => (
        <>
          <img src={row?.shipping_company?.shipping_company?.logo_url} />
        </>
      ),
      width: "200px",
      fixed: "left",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 2,
      label: "Service",
      render: (row) => <>{row?.service_type ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 3,
      label: "Reference ID",
      render: (row) => <>{row?.referance_code ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 4,
      label: "Bolt Tracking ID",
      render: (row) => <>{row?.tracking_id ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 5,
      label: "Carrier Tracking ID",
      render: (row) => (
        <>{tab === 2 ? row?.id ?? "-" : row?.shipment_tracking_id ?? "-"}</>
      ),
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 6,
      label: "Delivery",
      render: (row) => <>{row?.delivery_date?.slice(0, 10) ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 7,
      label: "Customer Name",
      render: (row) => <>{row?.tattension ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 8,
      label: "Delivery Address",
      render: (row) => <>{row?.taddress ?? "-"}</>,
      width: "250px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 9,
      label: "Delivery Postal/Zip",
      render: (row) => <>{row?.tzip ?? "-"}</>,
      width: "250px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 10,
      label: "Payment Status",
      render: (row) => <>{row?.payment_status ?? "Paid"}</>,
      box: (row) => {
        const status = row?.payment_status?.toLowerCase();
        if (["unpaid"].includes(status)) {
          return "error";
        } else if (["paid", ""].includes(status)) {
          return "success";
        } else {
          return "success";
        }
      },
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 11,
      label: "Shipment Status",
      render: (row) => <StatusIcons status={row?.shipment_status} />,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 12,
      label: "Created at",
      render: (row) => <>{row?.created_at?.slice(0, 10) ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
  ];

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const getAllShipments = async (page, perPage, filter) => {
    const response = await fetchShipments(
      tab,
      page,
      perPage,
      filter,
      "tracking"
    );
    if (!response.error) {
      setShipments(response?.data?.result?.shipments);
      dispatch(
        updateUser({
          unReadTrackings: response?.data?.result?.unReadShipmentCount,
        })
      );
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  return (
    <div className="dispatcher__screen" style={{ maxWidth: "100%" }}>
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <TabsComponent items={tabPermissions} tab={tab} handleTabChange={handleTabChange} />
      <div className="orders_header">
        <div>
          <span className="orders_hdrheading">Tracking List</span>
        </div>
      </div>
      <div className="custom__table_size1">
        <ShipmentsTable
          message={"No Shipments Available To Display!"}
          searchPlaceHolder={"Search Shipments"}
          getAllData={getAllShipments}
          data={shipments}
          columns={columns}
          refresh={tab}
        />
      </div>
    </div>
  );
};

export default Tracking;
