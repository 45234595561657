import React, { useState, useEffect } from "react";
import "../RegisterSteps/RegisterSteps.css";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Countries from "../../../../data/countries";
import { ReactComponent as Industry } from "../../../../assets/icons/industry.svg";
import { ReactComponent as Briefcase } from "../../../../assets/icons/briefcase.svg";

import { ReactComponent as Address } from "../../../../assets/icons/address.svg";
import { ReactComponent as Lock } from "../../../../assets/icons/lock.svg";
import { ReactComponent as User } from "../../../../assets/icons/user.svg";
import CustomTextField from "../CustomTextField/CustomTextField";
const RegisterLStep2 = ({ setCurrentLStep, formData, handleFieldChange }) => {
  const [disabled, setDisabled] = useState(true);
  const [recieveEmail, setRecieveEmail] = useState(false);
  const [terms, setTerms] = useState(false);
  useEffect(() => {
    if (
      [formData.business_name, formData.industry_type].includes("") ||
      !recieveEmail ||
      !terms
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formData, terms, recieveEmail]);
  return (
    <div className="rfsregister__steps_wrap">
      <div className="rfsregister__steps_fieldsrow">
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            icon={<Briefcase />}
            placeholder="Business Name"
            type="text"
            onChange={(e) => handleFieldChange("business_name", e.target.value)}
            name="business_name"
            value={formData.business_name}
          />
        </div>
      </div>
      <div className="rfsregister__steps_fieldsrow">
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            icon={<Industry />}
            placeholder="Industry Type"
            type="text"
            onChange={(e) => handleFieldChange("industry_type", e.target.value)}
            name="industry_type"
            value={formData.industry_type}
          />
        </div>
      </div>
      <div className="rfsregister__steps_next_previous">
        <div
          className="rfsregister__steps_nextbtn"
          onClick={() => {
            setCurrentLStep(1);
          }}
        >
          Previous
        </div>
        <div
          className={`rfsregister__steps_nextbtn ${
            disabled && "rfsregister__steps_nextbtndisabled"
          }`}
          onClick={() => {
            setCurrentLStep(3);
          }}
        >
          Next
        </div>
      </div>
      <div className="rfsregister__steps_fieldscol">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox onChange={(e) => setRecieveEmail(e.target.checked)} />
            }
            sx={{
              alignItems: "flex-start",
              "& .MuiTypography-root": {
                fontSize: 13,
                color: "#131466",
                marginTop: "5px",
              },
              "&.Mui-checked": {
                color: "#131466",
              },
            }}
            label="I understand that I will be receiving emails/sms related to freight quotes and my booked shipments and that I can unsubscribe at any time.
            "
          />
          <FormControlLabel
            control={<Checkbox onChange={(e) => setTerms(e.target.checked)} />}
            sx={{
              alignItems: "flex-start",
              "& .MuiTypography-root": {
                fontSize: 13,
                color: "#131466",
                marginTop: "5px",
              },
              "&.Mui-checked": {
                color: "#131466",
              },
            }}
            label="I have read and accept to be bound by the Terms and Conditions of Use with respect to the services being offered.
"
          />
        </FormGroup>
      </div>
    </div>
  );
};

export default RegisterLStep2;
