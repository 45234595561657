import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import "./NewTax.css";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";

function Loader(props) {
  return (
    <Box
      sx={{
        position: "relative",
        top: "2px",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={20}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={20}
        thickness={4}
        {...props}
      />
    </Box>
  );
}
const initialData = {
  tax_name: "",
  tax_amount: "",
};

export default function NewTax({
  open,
  setOpen,
  taxCharges,
  setTaxCharges,
  setNewTaxData,
  setFinalTotal,
  setNewlyAddedCharges,
}) {
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setData(initialData);
    setOpen(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  function handleSave() {
    // Find the maximum Id value in the existing objects
    const maxId = taxCharges.reduce((max, obj) => {
      return obj.Id > max ? obj.Id : max;
    }, 0);

    const newTax = {
      Id: maxId + 1,
      Charge: data?.tax_amount,
      NameEn: data?.tax_name,
    };
    setNewlyAddedCharges((prevCharges) => {
      return [...prevCharges, maxId + 1];
    });
    setTaxCharges([...taxCharges, newTax]);
    setNewTaxData((prevData) => {
      return { ...prevData, [data?.tax_name]: data?.tax_amount };
    });
    setFinalTotal((finalTotal) => {
      return {
        ...finalTotal,
        [data?.tax_name]: Number(data?.tax_amount),
      };
    });
    handleClose();
  }
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="admnnew__tax_modal">
          <div className="admnnew__tax_modalheading">
            Add New Entry For Tax / Charge
          </div>
          <div className="admnnew__tax_modalcontent">
            <div className="admnedit__user_modalrow"></div>

            <div className="admnnew__tax_modalrow">
              <div className="admnnew__tax_modalinputwrap">
                <label className="admnnew__tax_modallbl">Entry Name</label>
                <input
                  className="admnnew__tax_modalinput"
                  placeholder="Name Of Tax / Charge"
                  name="tax_name"
                  value={data.tax_name}
                  onChange={handleChange}
                  type="text"
                />
              </div>
            </div>
            <div className="admnnew__tax_modalrow">
              <div className="admnnew__tax_modalinputwrap">
                <label className="admnnew__tax_modallbl">Entry Amount*</label>
                <input
                  className="admnnew__tax_modalinput"
                  placeholder="Amount Of Tax / Charge"
                  name="tax_amount"
                  value={data.tax_amount}
                  onChange={handleChange}
                  type="number"
                />
              </div>
            </div>
          </div>
          <div className="admnnew__tax_modalfooter">
            <div className="admnnew__tax_modalcncl" onClick={handleClose}>
              Cancel
            </div>
            <div className="admnnew__tax_modalsave" onClick={handleSave}>
              {loading ? <Loader /> : "Add New Entry"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
