import React, { useEffect, useState } from "react";
import "./AddBalance.css";

// components imports
import StripeCreditCardForm from "./StripeCreditCardForm";
import Loader from "../../../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import { addBalance } from "../../../../../../../services/ShipperServices/Payments/payments.services";
import { getCardSetupIntent } from "../../../../../../../services/ShipperServices/Payments/payments.services";
// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
// import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import Upload from "@mui/icons-material/CloudUpload";
import CreditCard from "../../../../../../../assets/images/credit-card.png";
import BankTransfer from "../../../../../../../assets/images/bank-transfer.png";
import ETransfer from "../../../../../../../assets/images/e-transfer.png";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import {
  FcNeutralTrading,
  FcReading,
  FcOrganization,
  FcCheckmark,
  FcBusinessContact,
  FcDepartment,
  FcFeedback,
  FcUnlock,
} from "react-icons/fc";
import { MdOutlineContentCopy } from "react-icons/md";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const initialData = {
  balance: "",
  file: null,
  payment_type: "bank_transfer",
  transaction_id: "",
};
const bankInfo = {
  bank: "The Bank of Nova Scotia",
  title: "HAJEX Inc.",
  iban: "0313815",
  transit: "12781",
  institution: "002",
};
export default function AddBalance({
  open,
  setOpen,
  setSnack,
  getAllTransactions,
}) {
  const user = useSelector((state) => state.user);

  const [data, setData] = useState(initialData);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  const [clientSecret, setClientSecret] = useState(null); // used for stripe credit card setup
  const [activeBox, setActiveBox] = useState(1);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [eTransCopiedCode, seteTransCopiedCode] = useState(false);
  const [eTransCopiedId, seteTransCopiedId] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setActiveBox(1);
    setData(initialData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleNextClick = (type) => {
    type && setData({ ...data, payment_type: type });
    setActiveBox(activeBox + 1);
  };

  const handleCreditCardOptionClick = () => {
    setData({ ...data, payment_type: "card" });
    setActiveBox(4); // we will show stripe credit card form based on this option
    if (user.card_last_four != null) {
      console.log("just charge him simply instead of asking card setup again");
      getCreditCardSetup(); // replace this later with simple charge amount and pay button as customer has already his card added
    } else {
      // card_last_four is null, so simply this means that he is first time adding/using card
      // send SetupIntent request
      getCreditCardSetup();
    }
  };

  const getCreditCardSetup = async () => {
    setLoading(true);
    let response;
    try {
      response = await getCardSetupIntent();
      setClientSecret(response.data.result.client_secret);
      console.log("client_secret", response.data.result.client_secret);
    } catch (error) {
      console.log(error);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
    setLoading(false);
  };

  const handlePrevClick = () => {
    setActiveBox(activeBox - 1);
  };
  function handleCopy() {
    navigator.clipboard.writeText(bankInfo.iban);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }

  //eTrans Work start
  const eTransfer = {
    pymentId: "payments@hajex.com",
    code: "HAJEX",
  };
  const handleClickETrans = () => {
    setActiveBox(5);
  };
  const handlehomeClick = () => {
    setActiveBox(1);
  };
  function handleETransferCopy(textToCopy, activeThis) {
    navigator.clipboard.writeText(textToCopy);
    if (activeThis === "eTransCode") {
      seteTransCopiedCode(true);
    }

    if (activeThis === "eTransId") {
      seteTransCopiedId(true);
    }
    setTimeout(() => {
      seteTransCopiedCode(false);
      seteTransCopiedId(false);
    }, 2000);
  }
  // eTrans work end

  const handleSave = async () => {
    setLoading(true);
    // for (const key in data) {
    //   if (data[key] === "") {
    //     setSnack((snack) => {
    //       return {
    //         ...snack,
    //         open: true,
    //         text: `${key} is required!`,
    //         severity: "error",
    //       };
    //     });
    //     setLoading(false);
    //     return;
    //   }
    // }

    const payload = {
      balance: data.balance,
      payment_type: data.payment_type,
      transaction_id: data.transaction_id,
      file: data.file,
    };
    const formData = new FormData();
    formData.append("balance", payload.balance);
    formData.append("transaction_id", payload.transaction_id);
    formData.append("payment_type", payload.payment_type);
    payload.file && formData.append("file", payload.file);
    const saved = await addBalance(formData);
    if (!saved.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Balance Added successfully",
          severity: "success",
        };
      });
      getAllTransactions();
      setData(initialData);
      setLoading(false);
      handleClose();
    }
  };
  const handleFileChange = (e) => {
    setData({ ...data, file: e.target.files[0] });
  };
  useEffect(() => {
    console.log(data);
  }, [data]);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="pmtsadd__balance_modal">
          <div className="pmtsadd__balance_modalheading">Add Credit</div>
          <div className="pmtsadd__balance_modalcontent">
            <div
              className={`pmtsadd__balance_modalcontentbox ${
                activeBox === 1
                  ? "pmtsadd__balance_modalcontentactivebox"
                  : activeBox > 1
                  ? "pmtsadd__balance_modalcontentboxleft"
                  : "pmtsadd__balance_modalcontentboxright"
              }`}
            >
              <div className="pmtsadd__balance_methods">
                <div
                  className="pmtsadd__balance_method"
                  onClick={handleCreditCardOptionClick}
                >
                  <div className="pmtsadd__balance_methodicon">
                    <img src={CreditCard} alt="" />
                  </div>
                  <div className="pmtsadd__balance_methodtxt">
                    Credit/Debit Card
                  </div>
                </div>
                <div
                  className="pmtsadd__balance_method"
                  onClick={() => handleNextClick("bank_transfer")}
                >
                  <div className="pmtsadd__balance_methodicon">
                    <img src={BankTransfer} alt="" />
                  </div>
                  <div className="pmtsadd__balance_methodtxt">
                    Bank Transfer
                  </div>
                </div>
                <div
                  className="pmtsadd__balance_method "
                  onClick={() => handleClickETrans("e_transfer")}
                >
                  <div className="pmtsadd__balance_methodicon">
                    <img src={ETransfer} alt="" />
                  </div>
                  <div className="pmtsadd__balance_methodtxt">E Transfer</div>
                </div>
              </div>
            </div>
            <div
              className={`pmtsadd__balance_modalcontentbox ${
                activeBox === 2
                  ? "pmtsadd__balance_modalcontentactivebox"
                  : activeBox > 2
                  ? "pmtsadd__balance_modalcontentboxleft"
                  : "pmtsadd__balance_modalcontentboxright"
              }`}
            >
              <div className="pmtsadd__balance_modaltxtrow">
                <div className="pmtsadd__balance_modaltxtlft">
                  <FcReading size={25} />
                  Account Title
                </div>
                <div className="pmtsadd__balance_modaltxtrt">
                  {bankInfo.title}
                </div>
              </div>
              <div className="pmtsadd__balance_modaltxtrow">
                <div className="pmtsadd__balance_modaltxtlft">
                  <FcBusinessContact size={25} />
                  Account
                </div>
                <div className="pmtsadd__balance_modaltxtrt">
                  {bankInfo.iban}{" "}
                  {!copied && (
                    <span
                      className="pmtsadd__balance_modaltxtrtcopy"
                      onClick={handleCopy}
                    >
                      <MdOutlineContentCopy
                        size={22}
                        style={{ marginLeft: "10px" }}
                      />
                    </span>
                  )}
                  {copied && (
                    <span
                      className="pmtsadd__balance_modaltxtrtcopy"
                      onClick={handleCopy}
                    >
                      <FcCheckmark size={22} style={{ marginLeft: "10px" }} />
                    </span>
                  )}
                </div>
              </div>

              <div className="pmtsadd__balance_modaltxtrow">
                <div className="pmtsadd__balance_modaltxtlft">
                  <FcNeutralTrading size={25} />
                  Transit
                </div>
                <div className="pmtsadd__balance_modaltxtrt">
                  {bankInfo.transit}
                </div>
              </div>
              <div className="pmtsadd__balance_modaltxtrow">
                <div className="pmtsadd__balance_modaltxtlft">
                  <FcOrganization size={25} />
                  Institution
                </div>
                <div className="pmtsadd__balance_modaltxtrt">
                  {bankInfo.institution}
                </div>
              </div>

              <div className="pmtsadd__balance_modaltxtrow">
                <div className="pmtsadd__balance_modaltxtlft">
                  <FcDepartment size={25} /> Bank Name
                </div>
                <div className="pmtsadd__balance_modaltxtrt">
                  {bankInfo.bank}
                </div>
              </div>
              <br />
              <div className="pmtsadd__balance_modalbackbtns">
                {" "}
                <button
                  onClick={handlePrevClick}
                  className="pmtsadd__balance_modalbackbtn"
                >
                  <FaArrowAltCircleLeft size={15} color="grey" />
                  Back
                </button>
                <button
                  onClick={(e) => handleNextClick()}
                  className="pmtsadd__balance_modalbackbtn"
                >
                  Next
                  <FaArrowAltCircleRight
                    size={15}
                    color="grey"
                    style={{ marginRight: 0 }}
                  />
                </button>
              </div>
            </div>
            <div
              className={`pmtsadd__balance_modalcontentbox ${
                activeBox === 3
                  ? "pmtsadd__balance_modalcontentactivebox"
                  : activeBox > 3
                  ? "pmtsadd__balance_modalcontentboxleft"
                  : "pmtsadd__balance_modalcontentboxright"
              }`}
            >
              <div className="pmtsadd__balance_modalrow">
                <div className="pmtsadd__balance_modalinputwrap">
                  <input
                    className="pmtsadd__balance_modalinput"
                    placeholder="Paid Amount"
                    name="balance"
                    value={data.balance}
                    onChange={handleChange}
                    type="number"
                  />
                </div>
              </div>
              <div className="pmtsadd__balance_modalrow">
                <div className="pmtsadd__balance_modalinputwrap">
                  <input
                    className="pmtsadd__balance_modalinput"
                    placeholder="Transaction ID"
                    name="transaction_id"
                    value={data.transaction_id}
                    onChange={handleChange}
                    type="text"
                  />
                </div>
              </div>
              <div className="pmtsadd__balance_modalrow">
                <div className="pmtsadd__balance_modalinputwrap">
                  <label className="pmtsadd__balance_profimagelbl">
                    <Upload /> {data.file?.name ?? "Upload Payment Reciept"}
                    <input
                      type="file"
                      size="60"
                      className="pmtsadd__balance_profimageinput"
                      onChange={handleFileChange}
                      accept=".jpg,.png"
                    />
                  </label>
                </div>
              </div>
              <button
                onClick={handlePrevClick}
                className="pmtsadd__balance_modalbackbtn"
              >
                <FaArrowAltCircleLeft size={15} color="grey" />
                Back
              </button>
            </div>
            <div
              className={`pmtsadd__balance_modalcontentbox ${
                activeBox === 4
                  ? "pmtsadd__balance_modalcontentactivebox"
                  : activeBox > 4
                  ? "pmtsadd__balance_modalcontentboxleft"
                  : "pmtsadd__balance_modalcontentboxright"
              }`}
            >
              <div style={{ width: "100%", minHeight: "fit-content" }}>
                {activeBox === 4 && stripePromise && clientSecret && (
                  <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <StripeCreditCardForm
                      setOpen={setOpen}
                      setSnack={setSnack}
                      setActiveBox={setActiveBox}
                      getAllTransactions={getAllTransactions}
                    />
                  </Elements>
                )}
                {activeBox === 4 && clientSecret == null && (
                  <h4 style={{ color: "blue", marginBottom: "40px" }}>
                    Loading Payment Screen ....{" "}
                  </h4>
                )}
              </div>
            </div>

            <div
              className={`pmtsadd__balance_modalcontentbox ${
                activeBox === 5
                  ? "pmtsadd__balance_modalcontentactivebox"
                  : activeBox > 5
                  ? "pmtsadd__balance_modalcontentboxleft"
                  : "pmtsadd__balance_modalcontentboxright"
              }`}
            >
              <div className="pmtsadd__balance_modaltxtrow">
                <div className="pmtsadd__balance_modaltxtlft">
                  <FcFeedback size={25} />
                  Id
                </div>
                <div className="pmtsadd__balance_modaltxtrt">
                  {eTransfer.pymentId}{" "}
                  {!eTransCopiedId && (
                    <span
                      className="pmtsadd__balance_modaltxtrtcopy"
                      onClick={() =>
                        handleETransferCopy(eTransfer.pymentId, "eTransId")
                      }
                    >
                      <MdOutlineContentCopy
                        size={22}
                        style={{ marginLeft: "10px" }}
                      />
                    </span>
                  )}
                  {eTransCopiedId && (
                    <span
                      className="pmtsadd__balance_modaltxtrtcopy"
                      onClick={() =>
                        handleETransferCopy(eTransfer.pymentId, "eTransId")
                      }
                    >
                      <FcCheckmark size={22} style={{ marginLeft: "10px" }} />
                    </span>
                  )}
                </div>
              </div>
              <br />
              <div className="pmtsadd__balance_modaltxtrow">
                <div className="pmtsadd__balance_modaltxtlft">
                  <FcUnlock size={25} />
                  Code
                </div>
                <div className="pmtsadd__balance_modaltxtrt">
                  {eTransfer.code}{" "}
                  {!eTransCopiedCode && (
                    <span
                      className="pmtsadd__balance_modaltxtrtcopy"
                      onClick={() =>
                        handleETransferCopy(eTransfer.code, "eTransCode")
                      }
                    >
                      <MdOutlineContentCopy
                        size={22}
                        style={{ marginLeft: "10px" }}
                      />
                    </span>
                  )}
                  {eTransCopiedCode && (
                    <span
                      className="pmtsadd__balance_modaltxtrtcopy"
                      onClick={() =>
                        handleETransferCopy(eTransfer.code, "eTransCode")
                      }
                    >
                      <FcCheckmark size={22} style={{ marginLeft: "10px" }} />
                    </span>
                  )}
                </div>
              </div>
              <br />
              <div className="pmtsadd__balance_modalbackbtns">
                {" "}
                <button
                  onClick={handlehomeClick}
                  className="pmtsadd__balance_modalbackbtn"
                >
                  <FaArrowAltCircleLeft size={15} color="grey" />
                  Back
                </button>
              </div>
            </div>
          </div>
          <div className="pmtsadd__balance_modalfooter">
            <div className="pmtsadd__balance_modalcncl" onClick={handleClose}>
              Cancel
            </div>
            {activeBox <= 3 && (
              <div
                className={`pmtsadd__balance_modalsave ${
                  (!(
                    activeBox <= 3 &&
                    data.balance != "" &&
                    data.transaction_id != "" &&
                    data.file != null
                  ) ||
                    activeBox == 4) &&
                  "pmtsadd__balance_modalsavedisabled"
                }`}
                onClick={handleSave}
              >
                {loading ? <Loader /> : "Save"}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
