import React, { useEffect, useState } from "react";
import "./addwarehouseuser.css";

// components imports
import Loader from "../../../../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import { registerUser } from "../../../../../../../../services/auth.services";
import { fetchAllRoles } from "../../../../../../../../services/settings.services";
import Countries from "../../../../../../../../data/countries";

// library imports
import Modal from "@mui/material/Modal";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { TextField } from "@mui/material";
import Form from "react-bootstrap/Form";

const initialLogisticData = {
  address: "",
  city: "",
  country: "",
  name: "",
  passcode: "",
  role: "warehouseUser",
  state: "",
  business_name: "",
  email: "",
  firstname: "",
  lastname: "",
  industry_type: "",
  username: "",
  password: "",
  permissions: [],
};

export default function AddWarehouseUser({
  open,
  setOpen,
  //   setUsers,
  setSnack,
  setRefresh,
}) {
  const [formData, setFormData] = useState({ ...initialLogisticData });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disabled, setDisabled] = useState(true);

  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setFormData({ ...initialLogisticData });
    setConfirmPassword("");
  };
  const user = useSelector((state) => state.user);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleFieldChange = (field, value) => {
    if (field === "role") {
      setFormData({
        ...formData,
        [field]: roles.find((r) => r.name === value).name,
        roles: roles.filter((r) => r.name === value),
      });
    } else {
      setFormData({ ...formData, [field]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      name: formData?.name,
      address: formData?.address,
      city: formData?.city,
      country: formData?.country,
      state: formData?.state,
      passcode: formData?.passcode,
      role: "warehouseUser",
      user: {
        username: formData?.username,
        firstname: formData?.firstname,
        lastname: formData?.lastname,
        business_name: formData?.business_name,
        industry_type: formData?.industry_type,
        email: formData?.email,
        password: formData?.password,
        zip: formData?.passcode,
        city: formData?.city,
        country: formData?.country,
        province: formData?.state,
        address: formData?.address,
      },
    };
    const res = await registerUser(payload);
    setLoading(false);
    if (res.error) {
      let message = "";
      if (res?.error?.response?.data?.username) {
        message = res?.error?.response?.data?.username[0];
      } else if (res?.error?.response?.data?.email) {
        message = res?.error?.response?.data?.email[0];
      } else {
        message = res?.error?.response?.data?.message;
      }
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: message,
          severity: "error",
        };
      });
      setLoading(false);
    } else {
      handleClose();
      setFormData(initialLogisticData);
      setRefresh(Math.floor(Math.random() * 100) + 1);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: res?.data?.message,
          severity: "success",
        };
      });
    }
  };

  const fetchAllRolesData = async () => {
    const saved = await fetchAllRoles(user.token);
    setRoles(
      saved?.data?.result?.data.map((role) => {
        return {
          id: role.id,
          name: role.name,
        };
      })
    );
  };

  useEffect(() => {
    fetchAllRolesData();
  }, []);

  useEffect(() => {
    if (formData.password !== confirmPassword) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formData.password, confirmPassword]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Form onSubmit={handleSubmit}>
          <div className="add__warehouse__settings__user_modal">
            <div className="add__warehouse__settings__user_modalheading">
              Warehouse Register
            </div>

            <div className="add__warehouse__settings__user_modalcontent">
              <div className="add__warehouse__settings__user_modalrow">
                <div className="add__warehouse__settings__user_modalinputwrap">
                  <Form.Label>Warehouse Name</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Warehouse Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="add__warehouse__settings__user_modalinputwrap">
                  <Form.Label>Warehouse Address</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Warehouse Address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="add__warehouse__settings__user_modalrow">
                <div className="add__warehouse__settings__user_modalinputwrap">
                  <Form.Label>Warehouse Country</Form.Label>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={Countries}
                    getOptionLabel={(option) => option?.name}
                    sx={{
                      "&  div": {
                        maxHeight: "40px",
                        outline: "none",
                        padding: 0,
                        border: "none",
                        outline: "none",
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        placeholder="Warehouse Country"
                        className="add__warehouse__settings__user_modalinput"
                      />
                    )}
                    onChange={(e, country) =>
                      handleFieldChange("country", country ? country.code : "")
                    }
                  />
                </div>
                <div className="add__warehouse__settings__user_modalinputwrap">
                  <Form.Label>Warehouse State</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Warehouse State"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="add__warehouse__settings__user_modalrow">
                <div className="add__warehouse__settings__user_modalinputwrap">
                  <Form.Label>Warehouse City</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Warehouse City"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                </div>
                <div className="add__warehouse__settings__user_modalinputwrap">
                  <Form.Label>Warehouse Postal Code</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Warehouse Postal Code"
                    name="passcode"
                    value={formData.passcode}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="add__warehouse__settings__user_modalrow">
                <div className="add__warehouse__settings__user_modalinputwrap">
                  <Form.Label>Business Name</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Business Name"
                    name="business_name"
                    value={formData.business_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="add__warehouse__settings__user_modalinputwrap">
                  <Form.Label>Industry Type</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Industry Type"
                    name="industry_type"
                    value={formData.industry_type}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="add__warehouse__settings__user_modalrow">
                <div className="add__warehouse__settings__user_modalinputwrap">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="First Name"
                    name="firstname"
                    value={formData.firstname}
                    onChange={handleChange}
                  />
                </div>
                <div className="add__warehouse__settings__user_modalinputwrap">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Last Name"
                    name="lastname"
                    value={formData.lastname}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="add__warehouse__settings__user_modalrow">
                <div className="add__warehouse__settings__user_modalinputwrap">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                  />
                </div>
                <div className="add__warehouse__settings__user_modalinputwrap">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    required
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="add__warehouse__settings__user_modalrow">
                <div className="add__warehouse__settings__user_modalinputwrap">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                </div>
                <div className="add__warehouse__settings__user_modalinputwrap">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    name="confirmPassword"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    isInvalid={formData.password != confirmPassword}
                  />
                </div>
              </div>
            </div>
            <div className="add__warehouse__settings__user_modalfooter">
              <div
                className="add__warehouse__settings__user_modalcncl"
                onClick={handleClose}
              >
                Cancel
              </div>
              <button
                type="submit"
                className={`add__warehouse__settings__user_modalsave ${
                  (disabled || loading) && "rfsregister__steps_nextbtndisabled"
                }`}
              >
                {loading ? <Loader /> : "Submit"}
              </button>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
