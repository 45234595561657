import React, { useState } from "react";
import "./HistoryModal.css";

// components imports

// apis imports
import { fetchProductHistory } from "../../../../services/product.service";
// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import HistoryTable from "../../../../Components/BoltTable/BoltTable";
import { FaArrowAltCircleUp, FaArrowAltCircleDown } from "react-icons/fa";
import DetailsModal from "../../../Warehouse/WarehouseOrders/Components/OrderDetails/DetailsModal";
import Tooltip from "../../../../Components/Tooltip/Tooltip";
import GRNDetailsModal from "../../GRN/Components/GRNDetailsModal/GRNDetailsModal";

export default function HistoryModal({ open, setOpen, setSnack }) {
  const [data, setData] = useState({});
  const handleClose = () => {
    setOpen(false);
    setData({});
    setorderModal(false);
  };
  const [orderModal, setorderModal] = useState(false);
  const [grnModal, setGRNModal] = useState(false);

  const regex = /\(([^)]+)\)/;

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "130px",
    },
    {
      id: 1,
      label: "Customer",
      field_name: "payment_type",
      render: (row) => (
        <>{row?.order?.order_shipping_address_details?.first_name ?? "-"}</>
      ),
      width: "200px",
      fixed: "left",
    },
    {
      id: 2,
      label: "Type",
      field_name: "Type",
      render: (row) => (
        <>
          {row?.actions?.type?.charAt(0).toUpperCase() +
            row?.actions?.type?.slice(1)}
        </>
      ),
      width: "130px",
    },
    {
      id: 3,
      label: "Difference",
      field_name: "amount",
      render: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {parseInt(row?.actions?.afterUpdationQuantity, 10) -
            parseInt(row?.actions?.beforeUpdationQuantity, 10) ?? "-"}
          {parseInt(row?.actions?.beforeUpdationQuantity, 10) >
          parseInt(row?.actions?.afterUpdationQuantity, 10) ? (
            <FaArrowAltCircleDown
              style={{
                marginTop: "2px",
                marginLeft: "4px",
                color: "red",
              }}
            />
          ) : (
            <FaArrowAltCircleUp
              style={{
                marginTop: "2px",
                marginLeft: "4px",
                color: "green",
              }}
            />
          )}
        </div>
      ),
      width: "200px",
    },
    {
      id: 4,
      label: "New Qty",
      field_name: "balance",
      render: (row) => <>{row?.actions?.afterUpdationQuantity ?? "-"}</>,
      width: "200px",
    },
    {
      id: 5,
      label: "Description",
      field_name: "description",
      render: (row) => (
        <>{row.description?.replace(regex, "")?.trim() ?? "-"}</>
      ),
      width: "200px",
    },
    {
      id: 5,
      label: "Notes",
      field_name: "note",
      render: (row) => <>{row.description?.match(regex)?.[1] ?? "-"}</>,
      width: "200px",
    },

    {
      id: 6,
      label: "Preview",
      field_name: "Preview",
      render: (row) => (
        <>
          {row?.order?.order_id && (
            <Tooltip title="Click To View Order">
              <div
                onClick={() => {
                  setorderModal([row?.order?.id, false]);
                }}
                style={{
                  cursor: "pointer",
                  color: "#0D6EFD",
                  textDecoration: "underline",
                }}
              >
                {row?.order?.order_id}
              </div>
            </Tooltip>
          )}
          {row?.traceable?.grn_no && (
            <Tooltip title="Click To View Stock List">
              <div
                onClick={() => {
                  setGRNModal(row?.traceable);
                }}
                style={{
                  cursor: "pointer",
                  color: "#0D6EFD",
                  textDecoration: "underline",
                }}
              >
                {row?.traceable?.grn_no}
              </div>
            </Tooltip>
          )}
        </>
      ),
      width: "200px",
    },
    {
      id: 7,
      label: "Warehouse",
      field_name: "warehouse",
      render: (row) => <>{row?.warehouse?.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 8,
      label: "Created By",
      field_name: "Created By",
      render: (row) => <>{row?.user?.username ?? "-"}</>,
      width: "200px",
    },
    {
      id: 9,
      label: "Created At",
      field_name: "created_at",
      render: (row) => <>{row.created_at?.slice(0, 10) ?? "-"}</>,
      width: "140px",
      fixed: "right",
    },
  ];

  const getProductHistory = async (page, perPage, filter) => {
    let response = await fetchProductHistory(open?.row?.id, page, perPage, "");
    if (!response.error) {
      setData(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  return (
    <>
      <DetailsModal
        open={orderModal}
        setOpen={setorderModal}
        setSnack={setSnack}
      />
      <GRNDetailsModal
        open={grnModal}
        setOpen={setGRNModal}
        setSnack={setSnack}
      />
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <div className="prdtable__hist_modal">
            <div
              className="prdtable__hist_modalheading history__product_heading  history__main_heading"
              style={{
                border: "none",
              }}
            >
              Product's History.
            </div>
            <div className="prdtable__hist_modalheading history__product_heading history__sub_heading">
              {open?.row?.full_name ?? ""}({open?.row?.code ?? ""})
            </div>
            <div className="prdtable__hist_modalcontent history__product_content">
              <HistoryTable
                message={"No Product History Available To Display!"}
                searchPlaceHolder={"Search Not Available"}
                getAllData={getProductHistory}
                data={data}
                columns={columns}
                disableAutoResizing={true}
              />
            </div>
            <div className=" history__product_footer">
              <div
                className="history__product_footer_btn"
                onClick={handleClose}
              >
                x
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
