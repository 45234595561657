import React, { useState, useEffect, useRef } from "react";
import "./grn.css";

// components imports
import GRNTable from "../../../Components/BoltTable/BoltTable";
import Confirm from "../../../Components/Popups/Confirm";
import GRNDetailsModal from "./Components/GRNDetailsModal/GRNDetailsModal";
import StatusIcons from "../../../Components/StatusIcons/StatusIcons";
import StockRecNotesHistoryModal from "./Components/StockRecNotesHistoryModal/StockRecNotesHistoryModal";
import StockRecAttachmentsHistoryModal from "./Components/StockRecAttachmentsHistoryModal/StockRecAttachmentsHistoryModal";
// apis imports
import {
  fetchAllGRNs,
  fetchAllGRNsByWarehouse,
} from "../../../services/product.service";
import { deleteGRN } from "../../../services/warehouse.services";

// library imports
import { FcPlus } from "react-icons/fc";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import noImage from "../../../assets/images/noImage.png";
import AddImage from "../../../assets/images/add-image.png";
import LabelModal from "../Components/LabelModal/LabelModal";
import ActionDropDown from "./Components/ActionDropDown/ActionDropDown";

function LinearProgressWithLabel(props) {
  return (
    <div>
      <LinearProgress
        variant="determinate"
        {...props}
        value={(props.received_quantity / props.total) * 100 ?? 0}
        style={{ width: "100px" }}
        color={props.total == props.received_quantity ? "success" : "primary"}
      />
      {props?.received_quantity} / {props?.total}
    </div>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const GRN = ({ setSnack }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const [suppliers, setSuppliers] = useState([]);

  const [grn, setGRNS] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  // modal code
  const [openByForm, setOpenByForm] = useState(false);
  const [openByFile, setOpenByFile] = useState(false);
  const [deleteLoader, setdeleteLoader] = useState(false);
  const [grnDetailsModal, setGRNDetailsModal] = useState(false);
  const [imagesModal, setImagesModal] = useState();
  const [labelModal, setLabelModal] = useState(false);

  const [stockRecNotesHistoryModal, setStockRecNotesHistoryModal] =
    useState(false);
  // add adjustment

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 1,
      label: "Stock ID",
      render: (row) => <>{row.grn_no ?? "-"}</>,
      width: "200px",
    },
    {
      id: 2,
      label: "Status",
      field_name: "is_draft",
      render: (row) => (
        <StatusIcons
          status={row?.is_draft === null ? "confirmed" : "pending"}
        />
      ),
      width: "200px",
    },
    {
      id: 3,
      label: "Warehouse",
      render: (row) => <>{row?.warehouse?.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "Supplier",
      field_name: "supplier_name",
      render: (row) => <>{row?.supplier?.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 5,
      label: "Products",
      field_name: "grn_details",
      render: (row) => <>{row?.grn_details?.length ?? "-"}</>,
      width: "200px",
    },
    {
      id: 6,
      label: "Stock",
      field_name: "grn_details",
      render: (row) => (
        <>
          <LinearProgressWithLabel
            received_quantity={calculateTotalReceived(row)}
            total={calculateTotalQuantity(row)}
          />
        </>
      ),
      width: "200px",
    },

    {
      id: 7,
      label: "Arrival Date",
      render: (row) => <>{row?.arrival_date ?? "-"}</>,
      width: "200px",
    },

    {
      id: 8,
      label: "Created By",
      render: (row) => <>{row?.user?.username ?? "-"}</>,
      width: "200px",
    },
    {
      id: 9,
      label: "Attachments",
      field_name: "grn_attachments",
      render: (row) => (
        <div className="shipper__attachments_thumbnail_wrap">
          <img
            src={AddImage}
            loading="lazy"
            onClick={() => setImagesModal(row.id)}
            onError={(e) => {
              e.target.src = noImage;
            }}
          />
        </div>
      ),
      width: "200px",
    },
    {
      id: 10,
      label: "Created At",
      render: (row) => <>{row?.created_at?.slice(0, 10) ?? "-"}</>,
      width: "200px",
    },
    {
      id: 11,
      label: "Action",
      render: (row, rowIndex, handleFieldChange) => (
        <>
          <ActionDropDown
            row={row}
            rowIndex={rowIndex}
            handleFieldChange={handleFieldChange}
            setGRNDetailsModal={setGRNDetailsModal}
            setStockRecNotesHistoryModal={setStockRecNotesHistoryModal}
            setLabelModal={setLabelModal}
            user={user}
          />
        </>
      ),
      width: "200px",
      fixed: "right",
    },
  ];

  const calculateTotalQuantity = (row) => {
    let total = 0;
    if (row?.grn_details?.length > 0) {
      row.grn_details.forEach((currentItem) => {
        const quantity = parseFloat(currentItem?.quantity ?? 0);
        total += quantity;
      });
    }
    return total;
  };

  const calculateTotalReceived = (row) => {
    let total = 0;
    if (row?.grn_details?.length > 0) {
      row.grn_details.forEach((currentItem) => {
        const quantity = parseFloat(currentItem?.received_quantity ?? 0);
        total += quantity;
      });
    }
    return total;
  };

  const getAllGRNs = async (page, perPage, filter) => {
    const response =
      user.role === "warehouseUser"
        ? await fetchAllGRNsByWarehouse(page, perPage, filter)
        : await fetchAllGRNs(page, perPage, filter);
    if (!response.error) {
      if (user.role === "warehouseUser") {
        setGRNS(response?.data?.result);
      } else {
        setGRNS(response?.data?.result?.grns);
        setSuppliers(response?.data?.result?.supplier);
      }
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const handleDeleteRequest = async (data) => {
    try {
      if (data.id) {
        setdeleteLoader(true);
        const response = await deleteGRN(data.id);

        if (!response.error && response?.data?.success) {
          setGRNS((prevGRNS) => {
            return {
              ...prevGRNS,
              data: prevGRNS?.data?.filter((d) => d?.id != data?.id),
            };
          });
          setSnack((snack) => {
            return {
              ...snack,
              open: true,
              text: response?.data?.message,
              severity: "success",
            };
          });
          setdeleteLoader(false);
          setConfirmDelete(false);
        } else {
          setSnack((snack) => {
            return {
              ...snack,
              open: true,
              text: "Couldn't cancel this request!",
              severity: "error",
            };
          });
          setdeleteLoader(false);
        }
      }
    } catch (error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text:
            error?.response?.data?.message || "Couldn't cancel this request!",
          severity: "error",
        };
      });
    }
  };
  return (
    <div>
      <div className="products__adpage_header">
        <div>
          <span className="products__adpage_hdrheading">
            Stock Received List
          </span>
        </div>
        {user.role != "warehouseUser" && (
          <div className="products__adpage_buttonsWrapper">
            <div
              onClick={() =>
                navigate("/stock-received-form", {
                  state: { previousData: null, mode: "add" },
                })
              }
            >
              <div className="products__adpage_hdraddprod">
                <FcPlus size={20} />
                <span>Add Stock Received</span>
              </div>
            </div>
          </div>
        )}
      </div>
      <LabelModal
        open={labelModal}
        setOpen={setLabelModal}
        setSnack={setSnack}
        labelData={{
          code: labelModal?.row?.grn_no,
          name: labelModal?.row?.name,
          image: labelModal?.row?.media?.find((image) => image?.is_primary)
            ?.file,
        }}
        title="Stock Barcode"
        subtitle={labelModal?.row?.grn_no}
      />
      <StockRecAttachmentsHistoryModal
        open={imagesModal}
        setOpen={setImagesModal}
        setSnack={setSnack}
      />

      <StockRecNotesHistoryModal
        open={stockRecNotesHistoryModal}
        setOpen={setStockRecNotesHistoryModal}
        setSnack={setSnack}
        setGRNS={setGRNS}
      />
      <Confirm
        open={confirmDelete}
        setOpen={setConfirmDelete}
        handleConfirm={() => handleDeleteRequest(confirmDelete)}
        text="Do You Want To Delete This Request ?"
        loader={deleteLoader}
      />
      <GRNDetailsModal
        open={grnDetailsModal}
        setOpen={setGRNDetailsModal}
        setSnack={setSnack}
      />
      <div>
        <GRNTable
          message={"No Stock Received To Display!"}
          searchPlaceHolder={"Search Stock Received"}
          columns={
            user.role === "warehouseUser"
              ? columns?.filter((column) => column.label !== "Warehouse")
              : columns
          }
          data={grn}
          getAllData={getAllGRNs}
        />
      </div>
    </div>
  );
};

export default GRN;
