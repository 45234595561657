import "swiper/css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { Provider } from "react-redux";
import store from "./store/store";

import Animatedroutes from "./Components/Router/Animatedroutes";
import LoadMap from "./Components/LoadMap";
import CustomSnackbar from "./Components/Popups/CustomSnackBar";

function App() {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <LoadMap />
          <CustomSnackbar />
          <Animatedroutes />
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
