import React, { useState } from "react";
import "../../InvoiceModal.css";
import Logo from "../../../../assets/images/hajex_logo.png";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { MdOutlineContentCopy } from "react-icons/md";
import { FcCheckmark } from "react-icons/fc";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const Header = ({ data, type = "Show" }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    let shipmentIds = [];
    for (let i = 0; i < data?.c_p_shipment_details.length; i++) {
      let shipmentId = data?.c_p_shipment_details[i]?.shipment_tracking_id;
      shipmentIds.push(shipmentId);
    }
    let shipmentIdsString = shipmentIds.join(",\n");
    navigator.clipboard
      .writeText(shipmentIdsString)
      .then(() => {
        setCopied("sid");
      })
      .catch((error) => {
        console.error(
          "Failed to copy shipment tracking IDs to clipboard: " + error
        );
      });

    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <>
       
      <div className="invoice__modal_header">
        <div className="invoice__modal_header_logo_wrap">
          <img src={Logo} alt="Logo" className="invoice__modal_headerleftimg" />
        </div>
        <div className="invoice__modal_headerleft">
         
          {/* <div className="invmodal__hleft_infoitems">
            <div className="invmodal__hleft_infoitem">
              {data?.tattension ?? "-"}
            </div>
            <div className="invmodal__hleft_infoitem">
              {data?.tcompany ?? "-"}
            </div>
            <div className="invmodal__hleft_infoitem">
              {data?.taddress ?? "-"}
            </div>
            <div className="invmodal__hleft_infoitem">{data?.tzip ?? "-"}</div>
            <div className="invmodal__hleft_infoitem">
              {data?.temail ?? "-"}
            </div>
            <div className="invmodal__hleft_infoitem">
              {data?.tphone ?? "-"}
            </div>
          </div> */}
          <div className="invmodal__hright_infoitems">
            <div className="invmodal__hright_infoitem">
              <div className="invmodal__hright_infoitemleft">
                Invoice Number
              </div>
              <div className="invmodal__hright_infoitemright">
                {data?.invoice_id}
              </div>
            </div>
            <div className="invmodal__hright_infoitem">
              <div className="invmodal__hright_infoitemleft">Invoice Date</div>
              <div className="invmodal__hright_infoitemright">
                {data?.created_at ? data?.created_at.slice(0, 10) : "-"}
              </div>
            </div>
            <div className="invmodal__hright_infoitem">
              <div className="invmodal__hright_infoitemleft">Customer ID</div>
              <div className="invmodal__hright_infoitemright">
                {data?.created_by ?? "-"}
              </div>
            </div>
            <div className="invmodal__hright_infoitem">
              <div className="invmodal__hright_infoitemleft">
                Bolt Tracking ID
              </div>
              <div className="invmodal__hright_infoitemright">
                {data?.tracking_id ?? "-"}
              </div>
            </div>
            <div className="invmodal__hright_infoitem">
              <div className="invmodal__hright_infoitemleft">Reference ID</div>
              <div className="invmodal__hright_infoitemright">
                  {/* {data?.referance_code ?? "-"} */} -
                </div>
            </div>
            <div className="invmodal__hright_infoitem">
              <div className="invmodal__hright_infoitemleft">
                Carrier Tracking ID
              </div>
              {type == "Show" ? (
                <div className="invmodal__hright_infoitemright">
                  {data?.shipment_tracking_id ?? "-"}
                  {data?.c_p_shipment_details?.length && copied !== "sid" ? (
                    <LightTooltip
                      placement="top"
                      arrow
                      title="Copy All Tracking IDs"
                    >
                      <span
                        className="invmodal__shdetails_modaltxtrtcopy"
                        onClick={handleCopy}
                      >
                        <MdOutlineContentCopy
                          size={16}
                          style={{ marginLeft: "10px" }}
                        />
                      </span>
                    </LightTooltip>
                  ) : (
                    ""
                  )}
                  {data?.c_p_shipment_details?.length && copied === "sid" ? (
                    <span className="invmodal__shdetails_modaltxtrtcopy">
                      <FcCheckmark size={16} style={{ marginLeft: "10px" }} />
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div className="invmodal__hright_infoitemright">
                  {data?.shipment_tracking_id ?? "-"}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="invoice__modal_headerright">
          
          {/* <div className="invmodal_hright_contactitems">
            <div className="invmodal_hright_contacthding">Contact</div>
            <div className="invmodal_hright_contactitem">General inquiries</div>
            <div className="invmodal_hright_contactitem">855-999-6659</div>
            <div className="invmodal_hright_contactitem">
              Questions about your invoice/account
            </div>
            <div className="invmodal_hright_contactitem">
              info@hajexbolt.com
            </div>
          </div> */}
          <div className="invmodal__hleft_amntitems">
          <div className="invmodal__hleft_amntitem">
              <div className="invmodal__hleft_amntitemleft">
                Your payment is due by (Y-M-D)
              </div>
              <div className="invmodal__hleft_amntitemright">-</div>
            </div>
            <div className="invmodal__hleft_amntitem total__amount_wrap">
              <div className="invmodal__hleft_amntitemleft bold_700">
                Total amount of this invoice
              </div>
              <div className="invmodal__hleft_amntitemright">
                {data?.Total}$
                <sup>
                  {data?.shipment_charges?.length > 1
                    ? `*${data?.shipment_charges?.length}`
                    : ""}
                </sup>
                <span
                  className={
                    type == "Show"
                      ? "invmodal__hleft_airprevcharge"
                      : "invmodal__hleft_airprevcharge invmodal__paymentterms_linethrough2"
                  }
                >
                  {data?.shipment_charges?.length > 1
                    ? data?.shipment_charges[data?.shipment_charges?.length - 2]
                        ?.total
                    : ""}
                </span>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
