import { Stack, ButtonGroup } from "@mui/material";
import Buttons from "./Buttons";
import "./AdditionalServices.css";
import TextField from "./TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "../../../../../../Components/Tooltip/Tooltip";

export default function AdditionalServices(props) {
  const { List, SetList, StepCheck, SetStepCheck, Tlist, setTlist, Plist } =
    props;

  const handleStepper = () => {
    SetStepCheck((prevStepCheck) => ({
      ...prevStepCheck,
      Additional_services_bit: 0,
      QuoteCheck: false,
    }));
  };

  const handlechange = (e) => {
    const { name } = e.target;
    handleStepper();
    SetList({ ...List, [name]: !List[name] });
  };

  const handleinsurance = (e) => {
    const { name, value } = e.target;
    handleStepper();
    SetList({ ...List, [name]: value });
  };

  const handleinsurancetype = () => {
    SetStepCheck((prevStepCheck) => ({
      ...prevStepCheck,
      Additional_services_bit: 0,
      QuoteCheck: false,
      insurancetype:
        StepCheck["insurancetype"] === "Package" ? "Shipment" : "Package",
    }));
  };

  const handleresidential = () => {
    SetStepCheck((prevStepCheck) => ({
      ...prevStepCheck,
      Delivery_Address_bit: 0,
      QuoteCheck: false,
    }));
    setTlist({ ...Tlist, residential: !Tlist["residential"] });
  };

  const handletype = (e) => {
    const { name } = e.target;
    SetStepCheck((prevStepCheck) => ({
      ...prevStepCheck,
      [name]: !StepCheck[name],
      QuoteCheck: false,
    }));
  };

  return (
    <>
      <div className="service__card_box_ship_page main__service_card_wrap">
        <div className="card bg-white shadow wapper_like_shopify">
          <div className="card-body service__card_box_ship_body">
            <div className="package__type_main_wrap">
              <div className="package__type_package">
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  className={
                    StepCheck["Package"]
                      ? " package__type_package_main_btn package__block_active"
                      : "package__type_package_main_btn package__block_not_active"
                  }
                >
                  <Buttons
                    List={StepCheck}
                    Name="Package"
                    HandleChange={handletype}
                    Variant={StepCheck["Package"] ? "contained" : "outlined"}
                    Color={StepCheck["Package"] ? "primary" : "inherit"}
                  />
                </Stack>
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  className="package__type_package_main_btn_wrap"
                >
                  <Buttons
                    List={List}
                    Name="signature"
                    Content="Signature"
                    HandleChange={handlechange}
                    TYPE="services"
                    Disabled={StepCheck["Package"] === true ? false : true}
                  />

                  <Buttons
                    List={Tlist}
                    Name="residential"
                    Content="Residential"
                    HandleChange={handleresidential}
                    TYPE="services"
                    Disabled={StepCheck["Package"] === true ? false : true}
                  />

                  {/* <Buttons
                List={List}
                Name="returnlabel"
                Content="Return label"
                HandleChange={handlechange}
                TYPE="servicesnotavailable"
              />

              <Buttons
                List={List}
                Name="fragile"
                Content="Fragile Goods"
                HandleChange={handlechange}
                TYPE="servicesnotavailable"
              />

              <Buttons
                List={List}
                Name="paperinvoice"
                Content="Paper Invoice"
                HandleChange={handlechange}
                TYPE="servicesnotavailable"
              />

              <Buttons
                List={List}
                Name="dangerious"
                Content="Dangerous Goods"
                HandleChange={handlechange}
                TYPE="servicesnotavailable"
              />

              <Buttons
                List={List}
                Name="appointment"
                Content="Appointment Delivery"
                HandleChange={handlechange}
                Tooltip={true}
                Title="If you want the carrier to book the appointment for delivery"
                TYPE="servicesnotavailable"
              />

              <Buttons
                List={List}
                Name="thirdpartybilling"
                Content="Collect/Third Party Billing"
                HandleChange={handlechange}
                Tooltip={true}
                Title="If you want the reciver to pay for the shipment"
                TYPE="servicesnotavailable"
              />

              <Buttons
                List={List}
                Name="cashondelivery"
                Content="Cash On Delivery"
                HandleChange={handlechange}
                TYPE="servicesnotavailable"
              />*/}

                  <Buttons
                    List={List}
                    Name="insurance"
                    Content="Insurance"
                    HandleChange={handlechange}
                    TYPE="services"
                    Disabled={StepCheck["Package"] === true ? false : true}
                  />
                  {List["insurance"] === true && (
                    <>
                      <div className="insurance_check_body_container">
                        {List["insurance"] === true && (
                          <>
                            <ButtonGroup
                              disableElevation
                              className="insurance_check_body_btn_wrap"
                            >
                              <Tooltip title="P means Per Package">
                                <span>
                                  <Buttons
                                    List={StepCheck}
                                    Name="insurancetype"
                                    Content="P"
                                    HandleChange={handleinsurancetype}
                                    Variant={
                                      StepCheck["insurancetype"] === "Package"
                                        ? "contained"
                                        : "outlined"
                                    }
                                    Color={
                                      StepCheck["insurancetype"] === "Package"
                                        ? "primary"
                                        : "inherit"
                                    }
                                  />
                                </span>
                              </Tooltip>
                              <Tooltip title="S means Per Shipment">
                                <span>
                                  <Buttons
                                    List={List}
                                    Name="insurancetype"
                                    Content="S"
                                    HandleChange={handleinsurancetype}
                                    Variant={
                                      StepCheck["insurancetype"] === "Shipment"
                                        ? "contained"
                                        : "outlined"
                                    }
                                    Color={
                                      StepCheck["insurancetype"] === "Shipment"
                                        ? "primary"
                                        : "inherit"
                                    }
                                  />
                                </span>
                              </Tooltip>
                            </ButtonGroup>
                          </>
                        )}

                        <div className="insurance__field_perShipment">
                          {List["insurance"] === true &&
                            StepCheck["insurancetype"] === "Shipment" && (
                              <TextField
                                className="insurance__field_perShipment_input"
                                Label="Insurance"
                                Type="number"
                                Name="insurancevalue"
                                PlaceHolder="Insurance"
                                Data={List["insurancevalue"]}
                                Handler={(e) => handleinsurance(e)}
                                SX={{
                                  width: "30%",
                                  input: { font: "menu" },
                                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                    {
                                      display: "none",
                                    },
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      sx={{ color: "#1976D2" }}
                                    >
                                      <b>
                                        <small>$</small>
                                      </b>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                        </div>
                      </div>
                    </>
                  )}
                </Stack>
              </div>
            </div>
          </div>
        </div>
        <div className="card bg-white shadow wapper_like_shopify">
          <div className="card-body service__card_box_ship_body">
            <div className="package__type_main_wrap">
              <div className="package__type_pallet">
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  className={
                    StepCheck["Pallet"]
                      ? " package__type_package_main_btn package__block_active"
                      : "package__type_package_main_btn package__block_not_active"
                  }
                >
                  <Buttons
                    List={StepCheck}
                    Name="Pallet"
                    HandleChange={handletype}
                    Variant={StepCheck["Pallet"] ? "contained" : "outlined"}
                    Color={StepCheck["Pallet"] ? "primary" : "inherit"}
                  />
                </Stack>
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  className="package__type_package_main_btn_wrap"
                >
                  <Buttons
                    List={List}
                    Name="signature"
                    Content="Signature"
                    HandleChange={handlechange}
                    TYPE="services"
                    Disabled={StepCheck["Pallet"] === true ? false : true}
                  />

                  <Buttons
                    List={Tlist}
                    Name="residential"
                    Content="Residential"
                    HandleChange={handleresidential}
                    TYPE="services"
                    Disabled={StepCheck["Pallet"] === true ? false : true}
                  />

                  {/* <Buttons
                List={Plist}
                Name="returnlabel"
                Content="Return label"
                HandleChange={handlechange}
                TYPE="servicesnotavailable"
              />

              <Buttons
                List={Plist}
                Name="fragile"
                Content="Fragile Goods"
                HandleChange={handlechange}
                TYPE="servicesnotavailable"
              />

              <Buttons
                List={Plist}
                Name="paperinvoice"
                Content="Paper Invoice"
                HandleChange={handlechange}
                TYPE="servicesnotavailable"
              />

              <Buttons
                List={Plist}
                Name="dangerious"
                Content="Dangerous Goods"
                HandleChange={handlechange}
                TYPE="servicesnotavailable"
              />

              <Buttons
                List={Plist}
                Name="appointment"
                Content="Appointment Delivery"
                HandleChange={handlechange}
                Tooltip={true}
                Title="If you want the carrier to book the appointment for delivery"
                TYPE="servicesnotavailable"
              />

              <Buttons
                List={Plist}
                Name="thirdpartybilling"
                Content="Collect/Third Party Billing"
                HandleChange={handlechange}
                Tooltip={true}
                Title="If you want the reciver to pay for the shipment"
                TYPE="servicesnotavailable"
              />

              <Buttons
                List={Plist}
                Name="cashondelivery"
                Content="Cash On Delivery"
                HandleChange={handlechange}
                TYPE="servicesnotavailable"
              />

              <Buttons
                List={Plist}
                Name="insurance"
                Content="Insurance"
                HandleChange={handlechange}
                TYPE="servicesnotavailable"
              /> */}

                  <Buttons
                    List={List}
                    Name="tailgatepickup"
                    Content="Tailgate Pickup"
                    HandleChange={handlechange}
                    TYPE="services"
                    Disabled={StepCheck["Pallet"] === true ? false : true}
                  />

                  <Buttons
                    List={List}
                    Name="tailgatedelivery"
                    Content="Tailgate Delivery"
                    HandleChange={handlechange}
                    TYPE="services"
                    Disabled={StepCheck["Pallet"] === true ? false : true}
                  />

                  {/* {List["insurance"] === true && (
              <>
                <ButtonGroup
                  disableElevation
                  style={{ marginLeft: "7px" }}
                >
                  <Tooltip  title="P means Per Package">
                    <Buttons
                      List={StepCheck}
                      Name="insurancetype"
                      Content="P"
                      HandleChange={handleinsurancetype}
                      Variant={
                        StepCheck["insurancetype"] === "Package"
                          ? "contained"
                          : "outlined"
                      }
                      Color={
                        StepCheck["insurancetype"] === "Package"
                          ? "primary"
                          : "inherit"
                      }
                    />
                  </Tooltip>
                  <Tooltip title="S means Per Shipment">
                    <Buttons
                      List={Plist}
                      Name="insurancetype"
                      Content="S"
                      HandleChange={handleinsurancetype}
                      Variant={
                        StepCheck["insurancetype"] === "Shipment"
                          ? "contained"
                          : "outlined"
                      }
                      Color={
                        StepCheck["insurancetype"] === "Shipment"
                          ? "primary"
                          : "inherit"
                      }
                    />
                  </Tooltip>
                </ButtonGroup>
              </>
            )} */}
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
