import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  fetchAllPalletsLocations,
  addPalletsLocation,
} from "../../../../../services/product.service";

const filter = createFilterOptions();

export default function LocationDropdown({ setValue, value, setSnack }) {
  const [location, setLocation] = useState([]);

  const addNewCosttype = async (newOption) => {
    let response;
    response = await addPalletsLocation(newOption);
    if (!response.error) {
      setLocation([...location, response?.data?.result]);
      const Option = {
        id: response?.data?.result?.id,
        name: newOption.name,
      };
      setValue(Option);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: newOption.name + " stored in locations",
          severity: "success",
        };
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: newOption.name + " not stored in locations",
          severity: "error",
        };
      });
    }
  };

  const getCostType = async () => {
    let response;
    response = await fetchAllPalletsLocations();
    if (!response.error) {
      setLocation(response?.data?.result);
    }
  };

  useEffect(() => {
    getCostType();
  }, []);

  return (
    <Autocomplete
      value={location.find((option) => option.id === value?.id) || null}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          const selectedOption = location.find(
            (option) => option.name === newValue
          );
          setValue(selectedOption);
        } else if (newValue && newValue.inputValue) {
          const newOption = { name: newValue.inputValue };
          addNewCosttype(newOption);
        } else {
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            name: `Add "${inputValue}"`,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="product-location"
      options={location}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.name;
      }}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label="Location" size="small" />
      )}
    />
  );
}
