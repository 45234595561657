import React, { useState, useEffect } from "react";
import "./transfernote.css";
// components imports
import TransferNotesTable from "../../../Components/BoltTable/BoltTable";
import TransferNoteDetails from "./Components/TransferNoteDetails/TransferNoteDetails";
import Tooltip from "../../../Components/Tooltip/Tooltip";
import StatusIcons from "../../../Components/StatusIcons/StatusIcons";
// apis imports
import { getAllTransferNotesList } from "../../../services/warehouse.services";
// library imports
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FcPlus } from "react-icons/fc";
import { MdRemoveRedEye } from "react-icons/md";
import { showSnackBar } from "../../../store/common/snackBarSlice";


const AddTransferNote = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const [transferNotes, setTransferNotes] = useState([]);

  const columns = [
    {
      id: 1,
      label: "Date",
      render: (row) => <>{row?.created_at?.slice(0, 10) ?? "-"}</>,
      width: "200px",
    },
    {
      id: 2,
      label: "Reference",
      render: (row) => <>{row?.reference_no ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "From Warehouse",
      render: (row) => <>{row?.from_warehouse?.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "To Warehouse",
      field_name: "to_warehouse",
      render: (row) => <>{row?.to_warehouse?.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 5,
      label: "Created By",
      render: (row) => <>{user?.username ?? "-"}</>,
      width: "200px",
    },
    {
      id: 6,
      label: "Status",
      render: (row) => (
        <>
          <StatusIcons status={row?.status} />
        </>
      ),
      width: "200px",
    },
    {
      id: 7,
      label: "Actions",
      render: (row) => (
        <>
          <div className="table_action__wrap">
            <Tooltip title="View Transfer Details">
              <div className="table_action__btn" onClick={() => setOpen(row)}>
                <MdRemoveRedEye size={10} />
                Details
              </div>
            </Tooltip>
          </div>
        </>
      ),
      width: "200px",
    },
  ];

  const fetchAllTransferNotes = async (page, perPage, filter) => {
    const response = await getAllTransferNotesList(page, perPage, filter);

    if (!response.error) {
      setTransferNotes(response?.data?.result);
    } else {
      dispatch(showSnackBar({
        text: "Something went wrong",
        severity: "error",
      }))
    }
  };

  return (
    <>
      <div className="addtransfernote__main_wrap">
        <>
          <div className="transfnotes_header">
            <div>
              <span className="transfnotes_hdrheading">Transfer Notes</span>
            </div>
            <div className="transfnotes_buttonsWrapper">
              <Link to="/AddTransferNote" className="stockCount__add_new_btn">
                <div>
                  <div className="transfnotes_hdraddprod">
                    <FcPlus size={10} />
                    <span>ADD TRANSFER NOTE</span>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div>
            <TransferNotesTable
              message={"No Transfer Available To Display!"}
              searchPlaceHolder={"Search Transfer"}
              columns={columns}
              data={transferNotes}
              getAllData={fetchAllTransferNotes}
            />
          </div>
        </>
      </div>
      <TransferNoteDetails open={open} setOpen={setOpen} />
    </>
  );
};

export default AddTransferNote;
