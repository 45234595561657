import React, { useState, useEffect, useRef } from "react";
import "./CustomTable.css";
import "./MediumTable.css";
import "./LargeTable.css";
import { Swiper, SwiperSlide } from "swiper/react";
import Empty from "../../assets/images/dispatcher-empty.gif";
import { styled } from "@mui/material/styles";
import DropdownIcon from "../../assets/icons/dispatcher-dropdown.svg";
import CheckIcon from "../../assets/icons/dispatcher-check.svg";
import LoadingIcon from "../../assets/icons/dispatcher-loading.svg";
import FailedIcon from "../../assets/icons/dispatcher-failed.svg";
import InfoIcon from "../../assets/icons/dispatcher-info.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import AddPerson from "@mui/icons-material/GroupAdd";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/DriveFileRenameOutline";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import Flag from "react-world-flags";
import {
  cbfToCbm,
  cbmToCbf,
  kgsToLbs,
  lbsToKgs,
  toggleValue,
} from "../../helpers/helpers";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import CarrierInnerTableLoader from "../CarrierInnerTableLoader/CarrierInnerTableLoader";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

function Loader(props) {
  return (
    <Box
      sx={{
        position: "relative",
        top: "2.5px",
        left: "10px",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 600 : 800],
        }}
        size={16}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "lightgrey" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={16}
        thickness={4}
        {...props}
      />
    </Box>
  );
}
const sizeClasses = {
  small: "custom__table_size1",
  medium: "custom__table_size2",
  large: "custom__table_size3",
};
const colWidth = {
  small: "width",
  medium: "mediumWidth",
  large: "largeWidth",
};
const CustomTable = ({
  title,
  data,
  cols,
  weightUnit,
  volumeUnit,
  isEmpty,
  pagination,
  getAllData,
  loading,
  size,
  getAdditionalServices,
  indexing,
  expandable,
  disableParameter,
  highlightRows,
  headerLoading,
  detailsComponent,
  searchTerm,
  containerStyle,
  wrapperStlle,
}) => {
  const [page, setPage] = React.useState(1);
  const [tableData, setTableData] = useState([]);
  const [expandedRow, setExpandedRow] = useState();
  const [isDragging, setIsDragging] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const scrollbarRef = useRef(null);

  const handleRowExpand = (id) => {
    // console.log(id);
    setExpandedRow(expandedRow === id ? false : id);
  };
  const handlePerPage = (e) => {
    setPerPage(e.target.value);
    getAllData(`1&perPage=${e.target.value}`);
  };
  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartX(event.pageX - scrollbarRef.current.offsetLeft);
    setScrollLeft(scrollbarRef.current.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const x = event.pageX - scrollbarRef.current.offsetLeft;
    const distance = x - startX;
    scrollbarRef.current.scrollLeft = scrollLeft - distance;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  useEffect(() => {
    console.log(highlightRows);
    setTableData(
      data.map((row) => {
        const rowData = {};
        cols.forEach((col) => {
          rowData[col.name] = col.data(row);
        });
        return rowData;
      })
    );
  }, [data, cols, volumeUnit]);

  const handlePageChange = (event, value) => {
    getAllData(
      `${value}&perPage=${perPage}${searchTerm ? "&name=" + searchTerm : ""}`
    );
  };

  return (
    <div className={sizeClasses[size]} style={wrapperStlle}>
      <div
        style={containerStyle}
        className={`custable__screen_tablewrap ${
          isDragging ? "custable__screen_tablewrapdragging" : ""
        }`}
        ref={scrollbarRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <div className="custabscreen__table_topbar">
          {title}{" "}
          {headerLoading && (
            <span>
              <Loader />
            </span>
          )}
          <div className="custable__pagination_perpage">
            <span>Records Per Page:</span>
            <select
              onChange={handlePerPage}
              value={perPage}
              sx={{
                maxHeight: "30px",
                "& *": {
                  maxHeight: "30px",
                  fontSize: "14px",
                },
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={40}>40</option>
              <option value={50}>50</option>
              <option value={60}>60</option>
              <option value={70}>70</option>
              <option value={75}>75</option>
            </select>
            &nbsp;&nbsp;&nbsp;
            <span>( Total: {pagination?.total ?? 0} )</span>
          </div>
        </div>

        <div className="custable__screen_tablecontent">
          {!isEmpty && !loading && (
            <div className="custabscreen__table_main">
              <div className="custabscreen__table_header">
                {expandable && (
                  <div
                    className={`custabscreen__theader_heading`}
                    style={{
                      overflow: "hidden",
                      width: "50px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={DropdownIcon}
                      alt=""
                      className="custabscreen__theader_hdrophide"
                    />
                  </div>
                )}
                {indexing && (
                  <div
                    className={`custabscreen__theader_heading`}
                    style={{
                      overflow: "hidden",
                      width: "50px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    #
                  </div>
                )}
                {cols.map((col) => {
                  return (
                    col.checked && (
                      <div
                        className={`custabscreen__theader_heading ${
                          col.labelClass ?? ""
                        }`}
                        style={{
                          overflow: "hidden",
                          width: col[colWidth[size]],
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {col.label ?? col.name}{" "}
                        {col.infoBadge && (
                          <LightTooltip
                            placement="top"
                            arrow
                            title={col.infoBadge}
                          >
                            <img
                              src={InfoIcon}
                              className="custabscreen__thdata_info"
                            />
                          </LightTooltip>
                        )}
                      </div>
                    )
                  );
                })}
              </div>
              {data.map((item, shipmentindex) => (
                <div
                  className={`custabscreen__table_row ${
                    disableParameter &&
                    item[disableParameter[0]]?.toLowerCase() ===
                      disableParameter[1]?.toLowerCase() &&
                    "custabscreen__table_rowdisabled"
                  } ${
                    highlightRows &&
                    highlightRows.includes(item.id) &&
                    "custabscreen__table_rowhighlighted"
                  }`}
                >
                  <div className="custabscreen__table_innerrow">
                    {expandable && (
                      <div
                        className={`custabscreen__theader_heading`}
                        style={{
                          overflow: "hidden",
                          width: "50px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={DropdownIcon}
                          alt=""
                          className={`custabscreen__theader_hdrop ${
                            expandedRow === item.id &&
                            "custabscreen__theader_hdropreverse"
                          } `}
                          onClick={() => handleRowExpand(item.id)}
                        />
                      </div>
                    )}
                    {indexing && (
                      <div
                        className={`custabscreen__theader_heading`}
                        style={{
                          overflow: "hidden",
                          width: "50px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {shipmentindex + 1}
                      </div>
                    )}
                    {cols.map(
                      (col) =>
                        col.checked && (
                          <div
                            className={`custabscreen__theader_data  ${col.label}`}
                            style={{
                              overflow: "hidden",
                              width: col[colWidth[size]],
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {col.data(item)}
                          </div>
                        )
                    )}
                  </div>
                  {expandable && (
                    <div
                      className={`custabscreen__row_details ${
                        expandedRow === item.id &&
                        "custabscreen__row_detailsactive"
                      }`}
                    >
                      {detailsComponent ? (
                        detailsComponent(item)
                      ) : (
                        <div className="custabscreen__rdetails_tableswrap">
                          <div className="custabscreen__rdetails_tablesrow">
                            <div className="custabscreen__rdetails_tablescol">
                              <div className="custabscreen__rdetails_tablescolheading">
                                Address information
                              </div>
                              <div className="custabscreen__rdetails_verticletable">
                                <div className="custabscreen__rdverttable_row">
                                  <div className="custabscreen__rdverttable_heading">
                                    pickup country
                                  </div>
                                  <div className="custabscreen__rdverttable_data">
                                    <div className="custabscreen__theader_flagwrap">
                                      <Flag
                                        code={item.fcountrycode}
                                        fallback={<span>Unknown</span>}
                                        style={{
                                          height: "100%",
                                          overflow: "hidden",
                                          width: "100%",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="custabscreen__rdverttable_heading">
                                    delivery country
                                  </div>
                                  <div className="custabscreen__rdverttable_data">
                                    <div className="custabscreen__theader_flagwrap">
                                      <Flag
                                        code={item.tcountrycode}
                                        fallback={<span>Unknown</span>}
                                        style={{
                                          height: "100%",
                                          overflow: "hidden",
                                          width: "100%",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="custabscreen__rdverttable_row">
                                  <div className="custabscreen__rdverttable_heading">
                                    pickup city
                                  </div>
                                  <div className="custabscreen__rdverttable_data">
                                    {item.fcity}
                                  </div>
                                  <div className="custabscreen__rdverttable_heading">
                                    delivery city
                                  </div>
                                  <div className="custabscreen__rdverttable_data">
                                    {item.tcity}
                                  </div>
                                </div>
                                <div className="custabscreen__rdverttable_row">
                                  <div className="custabscreen__rdverttable_heading">
                                    pickup address
                                  </div>
                                  <div className="custabscreen__rdverttable_data">
                                    {item.faddress}
                                  </div>
                                  <div className="custabscreen__rdverttable_heading">
                                    delivery address
                                  </div>
                                  <div className="custabscreen__rdverttable_data">
                                    {item.taddress}
                                  </div>
                                </div>
                                <div className="custabscreen__rdverttable_row">
                                  <div className="custabscreen__rdverttable_heading">
                                    pickup postal/zip
                                  </div>
                                  <div className="custabscreen__rdverttable_data">
                                    {item.fzip}
                                  </div>
                                  <div className="custabscreen__rdverttable_heading">
                                    delivery postal/zip
                                  </div>
                                  <div className="custabscreen__rdverttable_data">
                                    {item.tzip}
                                  </div>
                                </div>
                                <div className="custabscreen__rdverttable_row">
                                  <div className="custabscreen__rdverttable_heading">
                                    pickup full address
                                  </div>
                                  <div className="custabscreen__rdverttable_data">
                                    {item.faddress}
                                  </div>
                                  <div className="custabscreen__rdverttable_heading">
                                    delivery full address
                                  </div>
                                  <div className="custabscreen__rdverttable_data">
                                    {item.taddress}
                                  </div>
                                </div>
                                <div className="custabscreen__rdverttable_row">
                                  <div className="custabscreen__rdverttable_heading">
                                    pickup notes
                                  </div>
                                  <div className="custabscreen__rdverttable_data">
                                    {item.finstructions}
                                  </div>
                                  <div className="custabscreen__rdverttable_heading">
                                    delivery notes
                                  </div>
                                  <div className="custabscreen__rdverttable_data">
                                    {item.tinstructions}
                                  </div>
                                </div>
                              </div>
                              <div className="custabscreen__rdetails_tablescolheading">
                                DATE
                              </div>
                              <div className="custabscreen__rdetails_verticletable">
                                <div className="custabscreen__rdverttable_row">
                                  <div className="custabscreen__rdverttable_heading">
                                    Pickup Date
                                  </div>
                                  <div className="custabscreen__rdverttable_data">
                                    {item.PickupDate?.split(" ")[0]}
                                  </div>
                                  <div className="custabscreen__rdverttable_heading">
                                    Pickup time
                                  </div>
                                  <div className="custabscreen__rdverttable_data">
                                    {item.deliveryopentime &&
                                      item.deliveryclosetime &&
                                      `${item.deliveryopentime?.slice(
                                        0,
                                        4
                                      )}-${item.deliveryclosetime?.slice(
                                        0,
                                        4
                                      )}`}
                                  </div>
                                </div>
                                <div className="custabscreen__rdverttable_row">
                                  <div className="custabscreen__rdverttable_heading">
                                    delivery Date
                                  </div>
                                  <div className="custabscreen__rdverttable_data">
                                    {item.deliverydate?.split(" ")[0]}
                                  </div>
                                  <div className="custabscreen__rdverttable_heading">
                                    delivery time
                                  </div>
                                  <div className="custabscreen__rdverttable_data">
                                    {item.deliveryopentime &&
                                      item.deliveryclosetime &&
                                      `${item.deliveryopentime?.slice(
                                        0,
                                        4
                                      )}-${item.deliveryclosetime?.slice(
                                        0,
                                        4
                                      )}`}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="custabscreen__rdetails_tablescol">
                              <div className="custabscreen__rdetails_tablescolheading">
                                SHIPMENT INFORMATION
                              </div>
                              <div className="custabscreen__rdetails_verticletable">
                                <div className="custabscreen__rdverttable_row">
                                  <div className="custabscreen__rdverttable_heading">
                                    Carrier Tracking ID
                                  </div>
                                  <div className="custabscreen__rdverttable_data">
                                    {item.shipment_tracking_id ?? "-"}
                                  </div>
                                  <div className="custabscreen__rdverttable_heading">
                                    Shipment status
                                  </div>
                                  <div className="custabscreen__rdverttable_data">
                                    <div
                                      className="custabscreenrdvt__shipment_status"
                                      style={{
                                        borderColor: [
                                          "returned",
                                          "cancelled",
                                        ].includes(
                                          item.shipment_status?.toLowerCase()
                                        )
                                          ? "#FFB9C5"
                                          : item.shipment_status?.toLowerCase() ===
                                            "pending"
                                          ? "#60A0FF"
                                          : "#71F46E",
                                        background: [
                                          "returned",
                                          "cancelled",
                                        ].includes(
                                          item.shipment_status?.toLowerCase()
                                        )
                                          ? "#FFECEF"
                                          : item.shipment_status?.toLowerCase() ===
                                            "pending"
                                          ? "#DBE9FF"
                                          : "#DEFFD9",
                                      }}
                                    >
                                      {item.shipment_status}
                                    </div>
                                  </div>
                                </div>
                                <div className="custabscreen__rdverttable_row">
                                  <div className="custabscreen__rdverttable_heading">
                                    Created at
                                  </div>
                                  <div className="custabscreen__rdverttable_data">
                                    {item.created_at ?? "-"}
                                  </div>
                                </div>
                              </div>
                              <div className="custabscreen__rdetails_tablescolheading">
                                tracking information
                              </div>
                              <div className="custabscreen__rdetails_verticletable">
                                <div className="custabscreen__rdverttable_row">
                                  <div className="custabscreen__rdverttable_heading">
                                    Bolt Tracking ID
                                  </div>
                                  <div className="custabscreen__rdverttable_data">
                                    {item.tracking_id ?? "-"}
                                  </div>
                                </div>
                              </div>
                              <div className="custabscreen__rdetails_tablescolheading">
                                package overall measurements
                              </div>
                              <div className="custabscreen__rdetails_verticletable">
                                <div className="custabscreen__rdverttable_row">
                                  <div className="custabscreen__rdverttable_heading">
                                    weight
                                  </div>
                                  <div className="custabscreen__rdverttable_data">
                                    {weightUnit === "lb" &&
                                      `${
                                        item.unit?.toLowerCase() === "imperial"
                                          ? item.total_weight
                                          : kgsToLbs(item.total_weight)
                                      } ${weightUnit}`}
                                    {weightUnit === "kg" &&
                                      `${
                                        item.unit?.toLowerCase() === "imperial"
                                          ? lbsToKgs(item.total_weight)
                                          : item.total_weight
                                      } ${weightUnit}`}
                                  </div>
                                  <div className="custabscreen__rdverttable_heading">
                                    Volume
                                  </div>
                                  <div className="custabscreen__rdverttable_data">
                                    {volumeUnit === "cbm" &&
                                      `${
                                        item.unit?.toLowerCase() === "imperial"
                                          ? cbfToCbm(item.volume_in_cbm)
                                          : item.volume_in_cbm
                                      } ${volumeUnit}`}
                                    {volumeUnit === "cbf" &&
                                      `${
                                        item.unit?.toLowerCase() === "imperial"
                                          ? item.volume_in_cbm
                                          : cbmToCbf(item.volume_in_cbm)
                                      } ${volumeUnit}`}
                                  </div>
                                </div>
                              </div>
                              <div className="custabscreen__rdetails_tablescolheading">
                                Shipment fees
                              </div>
                              <div className="custabscreen__rdetails_verticletable">
                                <div className="custabscreen__rdverttable_row">
                                  <div className="custabscreen__rdverttable_heading custabscreen__rdverttable_smhcol">
                                    signature
                                  </div>
                                  <div className="custabscreen__rdverttable_data custabscreen__rdverttable_smcol">
                                    {item.signature_charge ?? "-"}
                                  </div>
                                  <div className="custabscreen__rdverttable_heading custabscreen__rdverttable_smhcol">
                                    fuel
                                  </div>
                                  <div className="custabscreen__rdverttable_data custabscreen__rdverttable_smcol">
                                    {item.fuel_price ?? "-"}
                                  </div>
                                  <div className="custabscreen__rdverttable_heading custabscreen__rdverttable_smhcol">
                                    COD
                                  </div>
                                  <div className="custabscreen__rdverttable_data custabscreen__rdverttable_smcol">
                                    {item.cash_on_delivery_value ?? "-"}
                                  </div>
                                </div>
                                <div className="custabscreen__rdverttable_row">
                                  <div className="custabscreen__rdverttable_heading custabscreen__rdverttable_smhcol">
                                    Tax
                                  </div>
                                  <div className="custabscreen__rdverttable_data custabscreen__rdverttable_smcol">
                                    {item.tax ?? "-"}
                                  </div>
                                  <div className="custabscreen__rdverttable_heading custabscreen__rdverttable_smhcol">
                                    Total
                                  </div>
                                  <div className="custabscreen__rdverttable_data custabscreen__rdverttable_smcol">
                                    {item.Total ?? "-"}
                                  </div>
                                  <div className="custabscreen__rdverttable_heading custabscreen__rdverttable_smhcol">
                                    Payment Status
                                  </div>
                                  <div className="custabscreen__rdverttable_data custabscreen__rdverttable_smcol">
                                    <div
                                      className="custabscreenthd__rvtdata_badge"
                                      style={{
                                        borderColor:
                                          item.payment_status?.toLowerCase() ===
                                          "unpaid"
                                            ? "#FAE017"
                                            : item.payment_status?.toLowerCase() ===
                                                "paid" ||
                                              !item.payment_status?.toLowerCase()
                                            ? "#20CD04"
                                            : "#D21C3D",
                                        background:
                                          item.payment_status?.toLowerCase() ===
                                          "unpaid"
                                            ? "#FDFAE6"
                                            : item.payment_status?.toLowerCase() ===
                                                "paid" ||
                                              !item.payment_status?.toLowerCase()
                                            ? "#E8FCE5"
                                            : "#FFECEF",
                                      }}
                                    >
                                      <div className="custabscreenthd__rvtdata_badgeicon">
                                        {item.payment_status?.toLowerCase() ===
                                          "unpaid" && <img src={LoadingIcon} />}
                                        {(item.payment_status?.toLowerCase() ===
                                          "paid" ||
                                          !item.payment_status?.toLowerCase()) && (
                                          <img src={CheckIcon} />
                                        )}
                                        {item.payment_status?.toLowerCase() ===
                                          "failed" && <img src={FailedIcon} />}
                                      </div>
                                      <div className="custabscreenthd__rvtdata_badgetext">
                                        {item.payment_status ?? "Paid"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="custabscreen__rdetails_tablesrow">
                            <div className="custabscreen__rdetails_tablescol">
                              <div className="custabscreen__rdetails_tablescolheading">
                                package information
                              </div>
                              <div className="custabscreen__rdetails_horizontaltable">
                                <div className="custabscreen__rdhtable_header">
                                  <div
                                    className="custabscreen__rdhtable_col"
                                    style={{
                                      overflow: "hidden",
                                      width: "120px",
                                    }}
                                  >
                                    #Packages
                                  </div>
                                  <div
                                    className="custabscreen__rdhtable_col"
                                    style={{
                                      overflow: "hidden",
                                      width: "120px",
                                    }}
                                  >
                                    Weight
                                  </div>
                                  <div
                                    className="custabscreen__rdhtable_col"
                                    style={{
                                      overflow: "hidden",
                                      width: "120px",
                                    }}
                                  >
                                    Volume
                                  </div>
                                  <div
                                    className="custabscreen__rdhtable_col"
                                    style={{
                                      overflow: "hidden",
                                      width: "150px",
                                    }}
                                  >
                                    Size
                                    <LightTooltip
                                      placement="top"
                                      arrow
                                      title="Length, Width, Height"
                                    >
                                      <img
                                        src={InfoIcon}
                                        className="custabscreen__thdata_info"
                                      />
                                    </LightTooltip>
                                  </div>
                                  <div
                                    className="custabscreen__rdhtable_col"
                                    style={{
                                      overflow: "hidden",
                                      width: "120px",
                                    }}
                                  >
                                    PARCEL ID
                                  </div>
                                  {/* <div
                                className="custabscreen__rdhtable_col"
                                style={{ overflow: "hidden", width: "120px" }}
                              >
                                Status
                              </div> */}
                                  {/* <div
                                className="custabscreen__rdhtable_col"
                                style={{ overflow: "hidden", width: "150px" }}
                              >
                                status updated time
                              </div> */}
                                </div>
                                {item.shipment_details?.map((pkg, pkgIndex) => (
                                  <div className="custabscreen__rdhtable_row">
                                    <div
                                      className="custabscreen__rdhtable_col"
                                      style={{
                                        overflow: "hidden",
                                        width: "120px",
                                      }}
                                    >
                                      {pkg.description}
                                    </div>
                                    <div
                                      className="custabscreen__rdhtable_col"
                                      style={{
                                        overflow: "hidden",
                                        width: "120px",
                                      }}
                                    >
                                      {weightUnit === "lb" &&
                                        `${pkg.weight} ${weightUnit}`}
                                      {weightUnit === "kg" &&
                                        `${lbsToKgs(pkg.weight)} ${weightUnit}`}
                                    </div>
                                    <div
                                      className="custabscreen__rdhtable_col"
                                      style={{
                                        overflow: "hidden",
                                        width: "120px",
                                      }}
                                    >
                                      {volumeUnit === "cbm" &&
                                        `${
                                          item.unit.toLowerCase() === "imperial"
                                            ? cbfToCbm(item.volume_in_cbm)
                                            : item.volume_in_cbm
                                        } ${volumeUnit}`}
                                      {volumeUnit === "cbf" &&
                                        `${
                                          item.unit.toLowerCase() === "imperial"
                                            ? item.volume_in_cbm
                                            : cbmToCbf(item.volume_in_cbm)
                                        } ${volumeUnit}`}
                                    </div>
                                    <div
                                      className="custabscreen__rdhtable_col"
                                      style={{
                                        overflow: "hidden",
                                        width: "150px",
                                      }}
                                    >
                                      L: {pkg.length}, W: {pkg.width}, H:{" "}
                                      {pkg.height} (
                                      <span
                                        style={{ textTransform: "lowercase" }}
                                      >
                                        {item.unit.toLowerCase() === "imperial"
                                          ? "m"
                                          : "In"}
                                      </span>
                                      )
                                    </div>
                                    <div
                                      className="custabscreen__rdhtable_col"
                                      style={{
                                        overflow: "hidden",
                                        width: "120px",
                                      }}
                                    >
                                      {item.parcel_id[pkgIndex]}
                                    </div>
                                    {/* <div
                                  className="custabscreen__rdhtable_col"
                                  style={{ overflow: "hidden", width: "120px" }}
                                >
                                  <div
                                    className="custabscreen__rdhtable_smptstatus"
                                    style={{
                                      borderColor: [
                                        "returned",
                                        "cancelled",
                                      ].includes(pkg.status?.toLowerCase())
                                        ? "#FFB9C5"
                                        : pkg.status?.toLowerCase() ===
                                          "pending"
                                        ? "#60A0FF"
                                        : "#71F46E",
                                      background: [
                                        "returned",
                                        "cancelled",
                                      ].includes(pkg.status?.toLowerCase())
                                        ? "#FFECEF"
                                        : pkg.status?.toLowerCase() ===
                                          "pending"
                                        ? "#DBE9FF"
                                        : "#DEFFD9",
                                    }}
                                  >
                                    {pkg.status}
                                  </div>
                                </div> */}
                                    {/* <div
                                  className="custabscreen__rdhtable_col"
                                  style={{ overflow: "hidden", width: "150px" }}
                                >
                                  2022-12-06, 15:03
                                </div> */}
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="custabscreen__rdetails_tablescol">
                              <div className="custabscreen__rdetails_tablescolheading">
                                Additional Services
                              </div>
                              <div className="custabscreen__rdetails_additionserviceslist">
                                {getAdditionalServices(item)?.map((service) => (
                                  <div className="custabscreenrd__additionservices_badge bottom__margin_10">
                                    <div className="custabscreen__additionservices_badgeicon">
                                      <img src={CheckIcon} />
                                    </div>
                                    <div className="custabscreen__additionservices_badgetext">
                                      {service}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          {isEmpty && (
            <></>
            // <div className="custabscreen__table_placeholder">
            //   <img
            //     src={Empty}
            //     alt="Nothing to show"
            //     className="custabscreen__table_emptimg"
            //   />
            //   {/* <div className="custabscreen__table_empttext">No data to display</div> */}
            // </div>
          )}
          {loading && (
            <div className="custom__table_tableloading">
              <CarrierInnerTableLoader />
            </div>
          )}
        </div>

        {!isEmpty && (
          <div className="pagination__wrap">
            <Stack spacing={2}>
              <Pagination
                count={pagination.last_page}
                page={pagination.current_page}
                onChange={handlePageChange}
              />
            </Stack>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomTable;
