import React, { useState } from "react";
import "./AddBalance.css";

// components imports
import Loader from "../../../../../Components/CircularLoader/CircularLoader";

// apis imports
import { updateBalance } from "../../../../../services/AdminServices/PaymentsAdmin/payments.services";

// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { addBalanceByWarehouse } from "../../../../../services/WarehouseServices/warehouse.services";

const initialData = {
  balance: "",
  notes: "",
  payment_type: "by_hand",
};

export default function WarehouseAddBalance({
  open,
  setOpen,
  setSnack,
  setTableData,
  tableData,
}) {
  const user = useSelector((state) => state.user);

  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setData(initialData);
    setOpen(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleFieldChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const handleSave = async () => {
    setLoading(true);
    const id = open.id;
    const response = await addBalanceByWarehouse(data, id);
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Balance Updated Successfully",
          severity: "success",
        };
      });
    }
    setTableData({
      ...tableData,
      data: tableData?.data?.map((item) => {
        return item.id === id ? { ...item, ...response?.data?.result } : item;
      }),
    });
    setData(initialData);
    setLoading(false);
    handleClose();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="wrhsadd__balance_modal">
          <div className="wrhsadd__balance_modalheading">Add Balance</div>
          <div className="wrhsadd__balance_modalcontent">
            <div className="admnedit__user_modalrow">
              <div className="admnedit__user_modalinputwrap">
                <label className="wrhsadd__balance_modallbl">
                  Payment Type
                </label>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  initialValue="by_hand"
                  options={["by_hand", "bank_transfer", "cheque"]}
                  getOptionLabel={(option) => option}
                  sx={{
                    "&  div": {
                      outline: "none",
                      padding: 0,
                      border: "none",
                      outline: "none",
                      height: "50px",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Payment Type"
                      className="admnedit__user_modalinput"
                      sx={{
                        height: "50px",
                        "&  div": {
                          height: "50px",
                        },
                      }}
                    />
                  )}
                  value={data.payment_type}
                  onChange={(e, payment_type) =>
                    handleFieldChange("payment_type", payment_type)
                  }
                />
              </div>
            </div>
            <div className="wrhsadd__balance_modalrow">
              <div className="wrhsadd__balance_modalinputwrap">
                <label className="wrhsadd__balance_modallbl">Amount*</label>
                <input
                  className="wrhsadd__balance_modalinput"
                  placeholder="Enter Amount"
                  name="balance"
                  value={data.balance}
                  onChange={handleChange}
                  type="number"
                />
              </div>
            </div>
            <div className="wrhsadd__balance_modalrow">
              <div className="wrhsadd__balance_modalinputwrap">
                <label className="wrhsadd__balance_modallbl">
                  Instructions
                </label>
                <input
                  className="wrhsadd__balance_modalinput"
                  placeholder="Enter Instructions"
                  name="notes"
                  value={data.notes}
                  onChange={handleChange}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="wrhsadd__balance_modalfooter">
            <div className="wrhsadd__balance_modalcncl" onClick={handleClose}>
              Cancel
            </div>
            <div className="wrhsadd__balance_modalsave" onClick={handleSave}>
              {loading ? <Loader /> : "Save"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
