import React, { useState, useEffect } from "react";
import "./warehouseclientlist.css";

// components imports
import FullPageModal from "../../../../../../../Components/Modals/FullPageModal/FullPageModal";
import WarehouseClientsTable from "../../../../../../../Components/BoltTable/BoltTable";
// apis imports
import { getClientsByWarehouses } from "../../../../../../../services/AdminServices/Users/users.services";
// library imports

const WarehouseClientList = ({ open, setOpen, setSnack }) => {
  const [warehouseClients, setWarehouseClients] = useState([]);

  const fetchClientsByWarehouses = async (page, perPage, filter) => {
    const response = await getClientsByWarehouses(
      open.id,
      page,
      perPage,
      filter
    );
    if (!response.error) {
      setWarehouseClients(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text:
            response?.error?.response?.data?.message || "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const columns = [
    {
      id: 1,
      label: "User Name",
      render: (row) => <>{row?.username ?? "-"}</>,
      width: "200px",
    },
    {
      id: 2,
      label: "Bussiness Name",
      render: (row) => <>{row?.business_name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Email",
      render: (row) => <>{row?.email ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "Balance",
      render: (row) => <>{row?.balance ?? "-"}</>,
      width: "200px",
    },
  ];

  return (
    <FullPageModal open={open} setOpen={setOpen} heading={"Warehouse Clients"}>
      <div className="warehouses__clients_wrapper">
        <div>
          <WarehouseClientsTable
            message={"No Warehouse clients Available To Display!"}
            searchPlaceHolder="Search Clients"
            columns={columns}
            data={warehouseClients}
            getAllData={fetchClientsByWarehouses}
          />
        </div>
      </div>
    </FullPageModal>
  );
};

export default WarehouseClientList;
