import ListGroup from "react-bootstrap/ListGroup";
import "./availableproducts.css";
import Tooltips from "../../../../../Components/Tooltip/Tooltip";
import React, { useState, useEffect } from "react";
import { setdata } from "../../../../../helpers/helpers";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import Sad from "@mui/icons-material/SentimentVeryDissatisfied";
import { fetchAllProducts } from "../../../../../services/product.service";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import noImage from "../../../../../assets/images/noImage.png";

export default function ProductScroller(props) {
  const { SelectedProducts, setSelectedProducts } = props;

  const [page, setPage] = useState(1);
  const [Products, setProducts] = useState();
  const [totalPages, setTotalPages] = useState();
  const [search, setSearch] = useState("");
  const [isExpanded, setIsExpanded] = useState(true);

  const handleExpandToggle = () => {
    setIsExpanded((prevExpanded) => !prevExpanded);
  };

  const handlePrevPage = () => {
    setPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const getAllProducts = async () => {
    const response = await fetchAllProducts(page, 10, search);
    setTotalPages(
      Math.ceil(
        response?.data?.result?.products?.total /
          response?.data?.result?.products?.per_page
      )
    );
    const flattenedProducts = [];
    async function flattenProduct(product, parentId) {
      const flattenedProduct = { ...product, parent_id: parentId };
      delete flattenedProduct.children;
      flattenedProducts.push(flattenedProduct);
      if (product.children && product.children.length > 0) {
        for (const child of product.children) {
          await flattenProduct(child, product.code); // Use product.code as parentId
        }
      }
    }
    for (const product of response?.data?.result?.products?.data) {
      await flattenProduct(product, null);
    }
    if (!response.error) {
      setProducts(flattenedProducts);
    }
  };

  const handlescrollpackage = (data, index) => {
    const productId = data.id;
    const existingProductIndex = SelectedProducts.findIndex(
      (product) => product.product_id === productId
    );
    if (existingProductIndex !== -1) {
      const updatedProducts = [...SelectedProducts];
      updatedProducts[existingProductIndex].quantity += 1;
      setSelectedProducts(updatedProducts);
    } else {
      const newData = {
        // id: d?.id,
        quantity: 1,
        media: data?.media,
        name: data?.name,
        length: data?.length,
        width: data?.width,
        height: data?.height,
        weight: data?.weight,
        sale_price: data?.sale_price,
        product_id: data?.id,
        product_warehouse: data?.product_warehouse,
        code: data?.code,
        // ...data,
        // quantity: 1,
      };
      setSelectedProducts([...SelectedProducts, newData]);
    }
    // handleClose();
  };

  useEffect(() => {
    getAllProducts();
  }, [page, search]);

  const ImageUrl = (row) => {
    const url =
      row?.media.find((media) => media.is_primary === "true")?.file ||
      row?.media[0]?.file;
    return url || noImage;
  };

  return (
    <div className="grn__available_productswrapper">
      <div className="grn__available_heading">
        Products
        <div className="expand_btn" onClick={handleExpandToggle}>
          {isExpanded ? (
            <>
              <Tooltips title="Collapse">
                <IconButton>
                  <MdOutlineKeyboardArrowUp size={25} />
                </IconButton>
              </Tooltips>
            </>
          ) : (
            <>
              <Tooltips title="Expand">
                <IconButton className="fullfilment__form_expand_btn">
                  <MdOutlineKeyboardArrowDown size={25} />
                </IconButton>
              </Tooltips>
            </>
          )}
        </div>
      </div>
      <div className="grn__available_inputrow">
        <div className="grn__available_inputcol">
          <div className="grn__available_inputbox">
            <label className="grn__available_inputtitle">Search Product</label>
            <input
              className="grn__available_inputfield"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search Product"
            />
          </div>
        </div>
      </div>

      {totalPages > 0 ? (
        <div className="d-flex flex-row mt-2">
          <IconButton
            className="mr-1"
            disabled={page === 1}
            onClick={handlePrevPage}
          >
            <FaChevronLeft />
          </IconButton>
          <ListGroup
            horizontal
            className={`overflow-auto horiscl p-2 ${
              isExpanded ? "expanded" : ""
            }`}
            style={{
              maxHeight: "108px",
              cursor: "pointer",
              display: "-webkit-box",
              overflowX: "auto",
              overflowY: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              WebkitScrollbarTrack: {
                background: "#000000",
              },
            }}
          >
            {Products.map((data, index) => (
              <Tooltips title={"Click To Add"} key={index}>
                <ListGroup.Item
                  className="mr-2 mb-1 shadow single_list"
                  style={{
                    width: "150px",
                    border: "1px solid #C8C9CA",
                    borderRadius: "4px",
                    padding: "2px",
                    flexShrink: 0,
                  }}
                  onClick={() => handlescrollpackage(data, index)}
                >
                  <div class="grn__availableproductList__card_wrap">
                    <div class="grn__availableproductList__card_title">
                      {setdata(() => data.name, false, "")}
                    </div>
                    <div class="grn__availableproductList__card_details_wrap">
                      <div class="productList__card_details_img">
                        <img
                          src={ImageUrl(data)}
                          onError={(e) => {
                            e.target.src = noImage;
                          }}
                        />
                      </div>
                      <div class="grn__availableproductList__card_details_measures">
                        <div class="grn__availableproductList__card_details_measures_LWH">
                          {data?.product_warehouse?.length > 0 && (
                            <>
                              {data?.product_warehouse?.map((data, index) => {
                                return (
                                  <div class="">
                                    #{data.warehouse_id}
                                    <span>{data?.quantity}</span>
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </ListGroup.Item>
              </Tooltips>
            ))}
          </ListGroup>
          <IconButton
            className="mr-1"
            disabled={page === totalPages || totalPages === 0}
            onClick={handleNextPage}
          >
            <FaChevronRight />
          </IconButton>
        </div>
      ) : (
        <div
          className="grn__available_screen_empty"
          style={{ minHeight: "12vh", width: "100%" }}
        >
          <div className="grn__available_scrnempt_content">
            <Sad className="grn__available_scrnempt_contenticon" />
            <div>No Products to display!</div>
          </div>
        </div>
      )}
    </div>
  );
}
