import axios from "./axiosInstance";

export const createNewVehicle = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`vehicles/create`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const editVehicle = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`vehicles/edit/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchAllVehicles = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `vehicles/get?page=${page}&&perPage=${perPage}&filterValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const deleteVehicle = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`vehicles/delete/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
// export const deleteVehicle = async (token, id) => {
//   let response = { data: null, error: null };
//   try {
//     const res = await axios.post(`vehicles/delete/${id}`, {
//       headers: {
//         Authorization: "Bearer " + token,
//         Accept: "application/json",
//         timeout: 1000,
//       },
//     });
//     response = { ...response, data: res.data };
//   } catch (error) {
//     response = { ...response, error: error };
//   }
//   return response;
// };

// export const changeStatus = async (token, data) => {
//   let response = { data: null, error: null };
//   try {
//     const res = await axios.post(
//       `vehicles/edit/${data.record_id}`,
//       { active: data.active },
//       {
//         headers: {
//           Authorization: "Bearer " + token,
//           Accept: "application/json",
//           timeout: 1000,
//         },
//       }
//     );
//     response = { ...response, data: res.data };
//   } catch (error) {
//     response = { ...response, error: error };
//   }
//   return response;
// };
export const changeStatus = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`vehicles/edit/${data.record_id}`, {
      active: data.active,
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
