import React, { useEffect, useState } from "react";
import "./AttachmentsModal.css";

// components imports
import SkeletonLoading from "../../../../../Components/SkeletonLoading/SkeletonLoading";
import CircularLoader from "../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import { checkFileType } from "../../../../../helpers/helpers";
import {
  fetchOrderAttachments,
  createOrderNotes,
} from "../../../../../services/orders.services";
// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import { MdOutlineCancel, MdArrowBack } from "react-icons/md";
import ImageListItem from "@mui/material/ImageListItem";
import noImage from "../../../../../assets/images/noImage.png";
import excelIcon from "../../../../../assets/icons/excelIcon.png";
import pdfIcon from "../../../../../assets/icons/pdfIcon.png";
import Badge from "@mui/material/Badge";
import { FaRegStar, FaStar } from "react-icons/fa6";
import { HiMiniMinusSmall } from "react-icons/hi2";
import AddImage from "../../../../../assets/images/add-image.png";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import docsIcon from "../../../../../assets/icons/docsIcon.png";

export default function AttachmentsModal({
  open,
  setOpen,
  setSnack,
  setOrders,
}) {
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [data, setData] = useState([]);
  // const [deleted, setDeleted] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [fullView, setFullView] = useState();

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setData([]);
    setFullView();
    setSelectedImages([]);
  };

  useEffect(() => {
    const getOrderAttachments = async () => {
      setSkeletonLoading(true);
      const response = await fetchOrderAttachments(open);
      if (!response.error) {
        setSkeletonLoading(false);
        setData(
          response?.data?.result?.flatMap((item) => item.order_attachments) ??
            []
        );
      } else {
        setSkeletonLoading(false);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Something went wrong",
            severity: "error",
          };
        });
      }
    };
    if (open) {
      getOrderAttachments();
    }
  }, [open]);

  const handleImageSelect = (event) => {
    const files = event.target.files;

    for (const file of files) {
      const reader = new FileReader();
      const lastDotIndex = file.name?.lastIndexOf(".");

      if (lastDotIndex !== -1) {
        const name = file.name?.substring(0, lastDotIndex);
        const extension = file.name?.substring(lastDotIndex);

        // Create a closure to capture the correct newFiles array
        reader.onload = (function (currentFile) {
          return function (e) {
            const newFile = {
              src: e.target.result,
              id: Math.random().toString(),
              name: name,
              extension: extension,
            };
            setSelectedImages((prevFiles) => [...prevFiles, newFile]);
          };
        })(file);

        reader.readAsDataURL(file);
      } else {
        console.log("Invalid file name without extension");
      }
    }
  };

  const handleRemoveSeleceted = (id) => {
    const updatedImages = selectedImages.filter((image) => image.id !== id);
    setSelectedImages(updatedImages);
  };

  const handleSave = async () => {
    setLoading(true);
    const formData = new FormData();
    // if (deleted.length > 0) {
    //   formData.append(`deletedMediaIds`, JSON.stringify(deleted));
    // }

    if (selectedImages.length > 0) {
      selectedImages.forEach((image, index) => {
        const base64String = image.src.split(",")[1]; // Get the base64 string after the comma
        const blob = atob(base64String); // Decode the base64 string
        const arrayBuffer = new ArrayBuffer(blob.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < blob.length; i++) {
          uint8Array[i] = blob.charCodeAt(i);
        }

        const fileBlob = new Blob([arrayBuffer], { type: "image/png" }); // Adjust the type accordingly

        formData.append(
          `attachments[${index}]`,
          fileBlob,
          `${image.name}${image.extension}`
        );
      });
    } else {
      formData.append(`attachments`, []);
    }
    let response = await createOrderNotes(open, formData);
    if (!response.error) {
      setOrders((orders) => {
        return {
          ...orders,
          data: orders?.data?.map((order) => {
            if (order?.id === open) {
              return {
                ...order,
                attachments: [
                  {
                    order_note_id:
                      response?.data?.result?.order_attachments[
                        response?.data?.result?.order_attachments?.length - 1
                      ]?.order_note_id,
                    file: response?.data?.result?.order_attachments[
                      response?.data?.result?.order_attachments?.length - 1
                    ]?.file,
                  },
                ],
              };
            } else {
              return order;
            }
          }),
        };
      });
      setData((prevOrder) => {
        return [...response?.data?.result?.order_attachments, ...prevOrder];
      });
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.data?.message,
          severity: "success",
        };
      });
      setSelectedImages([]);
      // setDeleted([]);
      setLoading(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="prdtable__0rder_attachments_modal edit_table_attach_img_thumb_modal_wrap">
          <div className="prdtable__0rder_attachments_modaltopbar">
            <div className="imageSelection_orderAttachments_title edit_table_attach_img_thumb_modal">
              Attachments
            </div>
            <MdOutlineCancel
              size={25}
              className="prdtable__0rder_attachments_modalclose"
              onClick={handleClose}
            />
            {fullView && (
              <MdArrowBack
                size={25}
                className="prdtable__0rder_attachments_modalback"
                onClick={() => setFullView(false)}
              />
            )}
          </div>
          {!fullView ? (
            <div className="prdtable__0rder_attachments_modalcontent">
              <div className="prdtable__0rder_attachments_modalimgs">
                {skeletonLoading && (
                  <div className="prdtable__0rder_attachments_skeletonwrapper">
                    {["", ""].map(() => {
                      return (
                        <SkeletonLoading
                          className={
                            "prdtable__0rder_attachments_skeletonloader"
                          }
                        />
                      );
                    })}
                  </div>
                )}
                {data &&
                  data?.map((image, index) => (
                    <div className={"ordinary__image_wrap"}>
                      <div key={index}>
                        <ImageListItem
                          key={index}
                          className="prdtable__0rder_attachments_modalimg"
                        >
                          <img
                            src={
                              image?.file &&
                              checkFileType(image.file) === "Image"
                                ? image.file
                                : checkFileType(image.file) === "PDF"
                                ? pdfIcon
                                : checkFileType(image.file) === "Excel"
                                ? excelIcon
                                : checkFileType(image.file) === "Document"
                                ? docsIcon
                                : noImage
                            }
                            onClick={() => {
                              if (checkFileType(image.file) === "Image") {
                                setFullView(image?.file || "");
                              } else {
                                window.open(image?.file, "_blank");
                              }
                            }}
                            className="uploaded_order__attachment_img"
                            onError={(e) => {
                              e.target.src = noImage;
                            }}
                            style={{ cursor: "pointer", objectFit: "contain" }}
                            loading="lazy"
                          />
                        </ImageListItem>
                      </div>
                    </div>
                  ))}
                {selectedImages?.map((image, index) => (
                  <>
                    <Badge
                      badgeContent={
                        <HiMiniMinusSmall
                          size={20}
                          onClick={() => handleRemoveSeleceted(image.id)}
                          style={{ cursor: "pointer" }}
                        />
                      }
                      color="error"
                      sx={{
                        ".MuiBadge-badge": {
                          right: "12px",
                          top: "12px",
                          padding: "0px",
                        },
                      }}
                    >
                      <div key={index}>
                        <ImageListItem
                          key={index}
                          className="prdtable__0rder_attachments_modalimg"
                        >
                          <img
                            src={image.src || noImage}
                            onClick={() => setFullView(image.src || noImage)}
                            onError={(e) => {
                              e.target.src = noImage;
                            }}
                            style={{ cursor: "pointer", objectFit: "contain" }}
                          />
                          <ImageListItemBar
                            sx={{
                              background:
                                "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                                "rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%)",
                            }}
                            title={"New " + (index + 1)}
                            position="top"
                          />
                        </ImageListItem>
                      </div>
                    </Badge>
                  </>
                ))}
                <>
                  <label
                    className="prdtable__0rder_attachments_addnewbtn"
                    for="upload_new_img"
                  >
                    <img src={AddImage} />
                    <div>
                      {selectedImages.length > 0
                        ? selectedImages.length + " Images Selected"
                        : "Add New Image"}
                    </div>
                  </label>
                  <input
                    type="file"
                    hidden
                    id="upload_new_img"
                    multiple
                    onChange={handleImageSelect}
                  />
                </>
              </div>
            </div>
          ) : (
            <div className="prdtable__0rder_attachments_fullimgview">
              <img
                src={fullView || noImage}
                onError={(e) => {
                  e.target.src = noImage;
                }}
                loading="lazy"
              />
            </div>
          )}

          <div className="prdtable__0rder_attachments_modalfooter">
            <div
              className="prdtable__0rder_attachments_modalfootercncl"
              onClick={handleClose}
            >
              Close
            </div>
            {selectedImages.length > 0 && (
              // || deleted.length > 0
              <div
                className="prdtable__0rder_attachments_modalfootersave"
                onClick={handleSave}
              >
                {loading ? <CircularLoader /> : "Save"}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
