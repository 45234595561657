const data = [
  {
    id: 1,
    master_tracking: "HB78509274U60",
    carrier_tracking: "70770262",
    carrier:
      "https://logosbynick.com/wp-content/uploads/2018/03/final-logo-example.png",
    reference_id: "59918568",
    pickup_date: "Thur 19 Dec 2022 ",
    delivery_date: "Fri 29 Dec 2022 ",
    status: "Unpaid",
    total: "152$",
  },
  {
    id: 2,
    master_tracking: "HB78509274U60",
    carrier_tracking: "70770262",
    carrier:
      "https://logosbynick.com/wp-content/uploads/2018/03/final-logo-example.png",
    reference_id: "59918568",
    pickup_date: "Thur 19 Dec 2022 ",
    delivery_date: "Fri 29 Dec 2022 ",
    status: "Unpaid",
    total: "152$",
  },
  {
    id: 3,
    master_tracking: "HB78509274U60",
    carrier_tracking: "70770262",
    carrier:
      "https://logosbynick.com/wp-content/uploads/2018/03/final-logo-example.png",
    reference_id: "59918568",
    pickup_date: "Thur 19 Dec 2022 ",
    delivery_date: "Fri 29 Dec 2022 ",
    status: "Unpaid",
    total: "152$",
  },
];

export default data;
