import React from "react";
import "./ColumnsModal.css";
import DragIcon from "../../../assets/icons/dispatcher-drag.svg";
import RiseModal from "../../RiseModal/RiseModal";

const ColumnsModal = ({
  onClose,
  handleColSearch,
  filteredCols,
  handleColChecked,
  handleShowAllCols,
  style,
}) => {
  return (
    <RiseModal onClose={onClose} closeOnLeave={true} style={style}>
      <div className="">
        <div
          className="alltables__cols_modal"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="alltables__colsmodal_autocomplt">
            <input
              type="text"
              onChange={handleColSearch}
              placeholder="Search Column"
              className="alltables__colsmodal_autocompltfield"
            />
          </div>
          <div className="alltables__colsmodal_colslist">
            {filteredCols?.map((item) => {
              return (
                <div className="alltables__colsmodal_colslistitem">
                  <span className="alltables__colsmodal_colslistitemleft">
                    <input
                      type="checkbox"
                      className="alltables__colsmodal_colslistitemcheck"
                      checked={item.checked !== false}
                      onChange={(e) =>
                        handleColChecked(item.id, e.target.checked)
                      }
                    />
                    {item.label}
                  </span>
                  <span className="alltables__colsmodal_colslistitemright">
                    <img
                      src={DragIcon}
                      alt=""
                      className="alltables__colsmodal_colslistitemdrag"
                    />
                  </span>
                </div>
              );
            })}
          </div>
          <div className="alltables__colsmodal_footer">
            <div
              className="alltables__colsmodal_ftrleft"
              onClick={() => handleShowAllCols(false)}
            >
              HIDE ALL
            </div>
            <div
              className="alltables__colsmodal_ftrright"
              onClick={() => handleShowAllCols(true)}
            >
              SHOW ALL
            </div>
          </div>
        </div>
      </div>
    </RiseModal>
  );
};

export default ColumnsModal;
