import React, { useState, useEffect, useRef } from "react";
import "./VehicleForm.css";

// components imports
import Loader from "../../../../../Components/CircularLoader/CircularLoader";

// apis imports
import {
  createNewVehicle,
  editVehicle,
} from "../../../../../services/vehicles.services";

// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { CgColorPicker } from "react-icons/cg";

const initialData = {
  license_plate: "",
  color: "#5a6876",
  year: "",
  make: "",
  model: "",
  type: "",
  weight_in_lbs: "",
  volume_in_cbm: "",
  active: "false",
};

export default function VehicleForm({ open, setOpen, setSnack, setRefresh }) {
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);

  const colorPickerRef = useRef(null);

  const handleIconClick = () => {
    if (colorPickerRef.current) {
      colorPickerRef.current.click();
    }
  };

  useEffect(() => {
    setData(initialData);
  }, [open]);

  const handleClose = () => setOpen(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const saved = await createNewVehicle(data);
      if (saved.error) {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text:
              saved?.error?.response?.data?.message || "Something went wrong",
            severity: "error",
          };
        });
        setLoading(false);
      } else {
        setRefresh(Math.floor(Math.random() * 100) + 1);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: saved?.data?.message,
            severity: "success",
          };
        });
        setData(initialData);
        setLoading(false);
        handleClose();
      }
    } catch (error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: error?.response?.data?.message || "Something went wrong",
          severity: "error",
        };
      });
      setLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { created_at, updated_at, ...carDetails } = data;
      const saved = await editVehicle(carDetails, open?.id);
      if (saved.error) {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text:
              saved?.error?.response?.data?.message || "Something went wrong",
            severity: "error",
          };
        });
        setLoading(false);
      } else {
        setRefresh(Math.floor(Math.random() * 100) + 1);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: saved?.data?.message,
            severity: "success",
          };
        });
        setLoading(false);
        handleClose();
      }
    } catch (error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: error?.response?.data?.message || "Something went wrong",
          severity: "error",
        };
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    setData(open);
  }, [open?.id]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Form onSubmit={open?.id ? handleUpdate : handleSave}>
          <div className="manage__vehicle_modal">
            <div className="manage__vehicle_modalheading">
              {open?.id ? "Edit" : "Add"} Vehicle
            </div>
            <div className="manage__vehicle_modalcontent">
              <div className="manage__vehicle_modalrow">
                <div className="manage__vehicle_modalinputwrap">
                  <Form.Label>License Plate</Form.Label>
                  <Form.Control
                    required
                    className="manage__vehicle_modalinput"
                    placeholder="License Plate"
                    name="license_plate"
                    value={data.license_plate}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="manage__vehicle_modalrow">
                <div className="manage__vehicle_modalinputwrap">
                  <Form.Label>Color</Form.Label>
                  <div className="d-flex gap-2">
                    <CgColorPicker
                      size={30}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={handleIconClick}
                    />
                    <div
                      className="add__vehmodal_colorview"
                      style={{
                        background: data?.color,
                      }}
                    ></div>
                  </div>

                  <Form.Control
                    type="color"
                    placeholder="Color"
                    name="color"
                    value={data.color}
                    ref={colorPickerRef}
                    onChange={handleChange}
                    className="add__vehmodal_colorpicker"
                  />
                </div>
                <div className="manage__vehicle_modalinputwrap">
                  <Form.Label>Year</Form.Label>
                  <Form.Control
                    required
                    className="manage__vehicle_modalinput"
                    placeholder="Year"
                    type="number"
                    name="year"
                    value={data.year}
                    onChange={handleChange}
                  />
                </div>
                <div className="manage__vehicle_modalinputwrap">
                  <Form.Label>Make</Form.Label>
                  <Form.Control
                    required
                    className="manage__vehicle_modalinput"
                    placeholder="Make"
                    name="make"
                    value={data.make}
                    onChange={handleChange}
                  />
                </div>
                <div className="manage__vehicle_modalinputwrap">
                  <Form.Label>Model</Form.Label>
                  <Form.Control
                    required
                    className="manage__vehicle_modalinput"
                    placeholder="Model"
                    name="model"
                    value={data.model}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="manage__vehicle_modalrow">
                <div className="manage__vehicle_modalinputwrap">
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    required
                    className="manage__vehicle_modalinput"
                    type="text"
                    value={data.type}
                    placeholder="Type"
                    name="type"
                    onChange={handleChange}
                  />
                </div>
                <div className="manage__vehicle_modalinputwrap">
                  <Form.Label>Weight in Pounds</Form.Label>
                  <Form.Control
                    required
                    className="manage__vehicle_modalinput"
                    type="number"
                    value={data.weight_in_lbs}
                    placeholder="Weight in Pounds"
                    name="weight_in_lbs"
                    onChange={handleChange}
                  />
                </div>
                <div className="manage__vehicle_modalinputwrap">
                  <Form.Label>Volume in CBM</Form.Label>
                  <Form.Control
                    required
                    className="manage__vehicle_modalinput"
                    type="number"
                    name="volume_in_cbm"
                    placeholder="Volume in CBM"
                    value={data.volume_in_cbm}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="manage__vehicle_modalfooter">
              <div className="manage__vehicle_modalcncl" onClick={handleClose}>
                Cancel
              </div>
              <Button
                disabled={loading}
                className={`manage__vehicle_modalsave`}
                type="submit"
              >
                {loading ? <Loader /> : "Save"}
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
