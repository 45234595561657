import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import { BsTrash } from "react-icons/bs";
import Tooltips from "@mui/material/Tooltip";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import noImage from "../../../assets/images/noImage.png";
import TextField from "../../Shipper/Customer/MainPages/ShipPage/Components/TextField";
import { Select, MenuItem } from "@mui/material";

export default function ProductList(props) {
  const {
    SelectedProducts,
    setSelectedProducts,
    Warehouse,
    setWarehouse,
    data,
    setData,
    formula,
    Setformula,
    editMode,
  } = props;

  const user = useSelector((state) => state.user);
  function getMediaSrc(singlelist) {
    const primaryMedia = singlelist?.media?.find(
      (media) => media.is_primary === "true"
    );

    // const fileUrl = primaryMedia?.file_url || singlelist?.media?.[0]?.file_url;
    const file = primaryMedia?.file || singlelist?.media?.[0]?.file;

    return file ?? noImage;
  }
  const handlescrollpackage = (data) => {
    if (!Warehouse) {
      return;
    }
    const productId = data.id;

    const existingProductIndex = SelectedProducts?.findIndex(
      (product) => product.id === productId
    );

    if (existingProductIndex !== -1) {
      const updatedProducts = [...SelectedProducts];
      updatedProducts[existingProductIndex].quantity += 1;
      setSelectedProducts(updatedProducts);
    } else {
      const newData = { ...data, quantity: 1 };
      setSelectedProducts([...SelectedProducts, newData]);
    }
  };

  const handleNotes = (e, index) => {
    const updatedProducts = [...SelectedProducts];
    updatedProducts[index].note = e.target.value;
    setSelectedProducts(updatedProducts);
  };

  const handleAdd = (index) => {
    handlescrollpackage(SelectedProducts[index]);
  };
  const calculateTotalAdditionalCost = (additionalCosts) => {
    let y = 0;
    if (additionalCosts?.length > 0) {
      additionalCosts.forEach((element) => {
        y = y + parseFloat(element.cost);
      });
    }
    return y;
  };
  const handleSubtract = (index) => {
    const updatedProducts = [...SelectedProducts];

    if (updatedProducts[index].quantity > 1) {
      updatedProducts[index].quantity -= 1;
    } else {
      if (updatedProducts[index].order_id) {
        setData({
          ...data,
          deletedItemIds: [
            ...data?.deletedItemIds,
            updatedProducts[index].order_id,
          ],
        });
      }
      // RemovePackagesOnProductRemove(updatedProducts[index]["id"]);
      updatedProducts.splice(index, 1);
    }
    setSelectedProducts(updatedProducts);
  };

  const fetchData = async () => {
    let totalWeight = 0;
    let totalCost = 0;
    let totalweightcost = 0;
    let costcount = 0;
    let salePrice = 0;
    let totalproducts = 0;
    const userWarehouses = user?.user_approved_warehouses || [];
    const foundWarehouse = userWarehouses?.find(
      (warehouse) => warehouse.warehouse_id === Warehouse
    );
    const Formula = foundWarehouse?.warehouse?.formula[0];
    if (Formula.type === "by_weight") {
      const findFormula = async (weight) => {
        let lastFormulaValue = null;

        for (const formula of Formula.formula_details) {
          const min = parseFloat(formula.min) || Number.NEGATIVE_INFINITY;
          const max = formula.max
            ? parseFloat(formula.max)
            : Number.POSITIVE_INFINITY;

          if (weight >= min && weight <= max) {
            return parseFloat(formula.value);
          }

          if (weight > max) {
            lastFormulaValue = parseFloat(formula.value);
          }
        }
        return lastFormulaValue;
      };

      SelectedProducts?.forEach(async (product) => {
        const weight = product?.weight ?? 0;
        if (!product?.fulfillment_cost) {
          totalWeight = totalWeight + parseFloat(weight) * product.quantity;
        } else {
          costcount = costcount + 1 * product.quantity;
          totalCost =
            totalCost +
            parseFloat(product?.fulfillment_cost) * product.quantity;
        }
        totalproducts = totalproducts + product.quantity;
        salePrice +=
          parseFloat(product.sale_price ?? 0) * parseFloat(product.quantity);
      });

      totalweightcost = await findFormula(totalWeight);

      Setformula({
        ...formula,
        TotalFulfilmentCost: totalweightcost + totalCost ?? 0,
        TotalWeightCost: totalweightcost?.toFixed(2) ?? 0,
        TotalPreCost: totalCost ?? 0,
        TotalFulfilmentWeight: totalWeight?.toFixed(2) ?? 0,
        TotalPreProduct: costcount ?? 0,
        UnitType: Formula.unit,
        TotalProducts: totalproducts ?? 0,
        salePrice,
      });
      setData({
        ...data,
        formula: {
          ...formula,
          TotalFulfilmentCost: totalweightcost + totalCost ?? 0,
          TotalWeightCost: totalweightcost?.toFixed(2) ?? 0,
          TotalPreCost: totalCost ?? 0,
          TotalFulfilmentWeight: totalWeight?.toFixed(2) ?? 0,
          TotalPreProduct: costcount ?? 0,
          UnitType: "CBM",
          TotalProducts: totalproducts ?? 0,
          salePrice,
        },
      });
    } else {
      const findFormula = async (volume) => {
        let lastFormulaValue = null;

        for (const formula of Formula.dimensions) {
          const min =
            (
              (parseFloat(formula.min_length) *
                parseFloat(formula.min_width) *
                parseFloat(formula.min_height)) /
              1000000
            ).toFixed(2) || Number.NEGATIVE_INFINITY;
          const max =
            (
              (parseFloat(formula.max_length) *
                parseFloat(formula.max_width) *
                parseFloat(formula.max_height)) /
              1000000
            ).toFixed(2) || Number.POSITIVE_INFINITY;

          if (volume >= min && volume <= max) {
            return parseFloat(formula.value);
          }

          if (volume > max) {
            lastFormulaValue = parseFloat(formula.value);
          }
        }
        return lastFormulaValue;
      };

      SelectedProducts?.forEach(async (product) => {
        const volume = (
          (parseFloat(product.length) *
            parseFloat(product.width) *
            parseFloat(product.height)) /
          1000000
        ).toFixed(2);
        if (!product?.fulfillment_cost) {
          totalWeight = totalWeight + volume * product.quantity;
        } else {
          costcount = costcount + 1 * product.quantity;
          totalCost =
            totalCost +
            parseFloat(product?.fulfillment_cost) * product.quantity;
        }
        totalproducts = totalproducts + product.quantity;
        salePrice +=
          parseFloat(product.sale_price ?? 0) * parseFloat(product.quantity);
      });

      totalweightcost = await findFormula(totalWeight);

      Setformula({
        ...formula,
        TotalFulfilmentCost: totalweightcost + totalCost ?? 0,
        TotalWeightCost: totalweightcost?.toFixed(2) ?? 0,
        TotalPreCost: totalCost ?? 0,
        TotalFulfilmentWeight: totalWeight?.toFixed(2) ?? 0,
        TotalPreProduct: costcount ?? 0,
        UnitType: "CBM",
        TotalProducts: totalproducts ?? 0,
        salePrice,
      });
      setData({
        ...data,
        formula: {
          ...formula,
          TotalFulfilmentCost: totalweightcost + totalCost ?? 0,
          TotalWeightCost: totalweightcost?.toFixed(2) ?? 0,
          TotalPreCost: totalCost ?? 0,
          TotalFulfilmentWeight: totalWeight?.toFixed(2) ?? 0,
          TotalPreProduct: costcount ?? 0,
          UnitType: "CBM",
          TotalProducts: totalproducts ?? 0,
          salePrice,
        },
      });
    }
  };

  useEffect(() => {
    if (SelectedProducts?.length > 0) {
      fetchData();
    }
  }, [SelectedProducts]);
  // useEffect(() => {
  //   setData({ ...data, formula });
  // }, [formula]);

  const handleRemove = (index, id) => {
    const updatedProducts = [...SelectedProducts];
    if (updatedProducts[index].order_id) {
      setData({
        ...data,
        deletedItemIds: [
          ...data?.deletedItemIds,
          updatedProducts[index].order_id,
        ],
      });
    }
    const list = [...SelectedProducts];
    list.splice(index, 1);
    setSelectedProducts(list);
  };

  function filterProductWarehouses(singlelist) {
    try {
      if (singlelist?.product_warehouse) {
        const Data = singlelist.product_warehouse.filter((warehouse) => {
          return Warehouse.includes(warehouse.warehouse_id);
        });

        let AvailableQty = parseInt(Data?.[0]?.quantity ?? 0);
        let ChangedQty = parseInt(singlelist?.quantity ?? 0);

        return AvailableQty - ChangedQty;
      } else {
        return 0;
      }
    } catch (error) {
      console.error("Error occurred:", error);
      return 0; // Return a default value or handle the error as needed
    }
  }

  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    const updatedProducts = [...SelectedProducts];
    if (value != 0) {
      updatedProducts[index].quantity = parseFloat(value);
    } else {
      updatedProducts[index].quantity = parseFloat(1);
    }
    setSelectedProducts(updatedProducts);
  };

  const handleSelectChange = (e, index) => {
    const selectedLotId = e.target.value;
    const updatedProducts = [...SelectedProducts];

    updatedProducts[index] = {
      ...updatedProducts[index],
      product_lot_id: selectedLotId,
    };
    setSelectedProducts(updatedProducts);
  };

  return (
    <div className="sss__products_section">
      <div className="sss__addressection_heading">Selected Products</div>
      <Row>
        <Col md={9} className="package__name_list_wrap">
          <div className="package__name_list_names_header">
            <div className="package__name_list_heading package__name_head_product">
              <h6>My Products</h6>
            </div>
            <div className="package__name_list_heading package__name_head_name">
              <h6>Name</h6>
            </div>
            <div className="package__name_list_heading package__name_head_sku">
              <h6>Sku</h6>
            </div>
            <div className="package__name_list_heading package__name_head_size">
              <h6>Size (L*W*H)</h6>
            </div>
            <div className="package__name_list_heading package__name_head_weight">
              <h6>Weight</h6>
            </div>
            <div className="package__name_list_heading package__name_head_name">
              <h6>Quantity</h6>
            </div>
            <div className="package__name_list_heading package__name_head_stock">
              <h6>Stock (#{Warehouse})</h6>
            </div>
            <div className="package__name_list_heading package__name_head_notes">
              <h6>Lot/Batch</h6>
            </div>
            <div className="package__name_list_heading package__name_head_notes">
              <h6>Product Notes</h6>
            </div>
            <div className="package__name_list_heading package__name_head_action">
              <h6>Action</h6>
            </div>
          </div>

          {SelectedProducts?.map((singlelist, index) => (
            <>
              <div className="package__name_list_box_content_wrap" key={index}>
                <div className="package__name_list_box_content package__name_content_index">
                  {"#"} {index + 1}
                </div>
                <div className="package__name_list_box_content package__name_content_image">
                  <img
                    src={getMediaSrc(singlelist)}
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "10px",
                      objectFit: "contain",
                    }}
                    onError={(e) => {
                      e.target.src = noImage;
                    }}
                  />
                </div>

                <div className="package__name_list_box_content package__name_content_fullname">
                  {singlelist.full_name}
                </div>

                <div className="package__name_list_box_content package__name_content_code">
                  {singlelist.code}
                </div>

                <div className="package__name_list_box_content package__name_content_dimension">
                  {singlelist.length ?? "0"}*{singlelist.width ?? "0"}*
                  {singlelist.height ?? "0"} {singlelist.dimension_unit}
                </div>

                <div className="package__name_list_box_content package__name_content_weight">
                  {singlelist.weight ?? "0"} {singlelist.weight_unit}
                </div>

                <div className="package__name_list_box_content package__name_content_fullname">
                  <Tooltips title="Remove Quantity" placement="top">
                    <a
                      type="button"
                      onClick={() => handleSubtract(index)}
                      className="package__name_list_qty_btn"
                    >
                      <IoIosRemove
                        size={25}
                        style={{
                          border: "2px solid #E2EDFB",
                          borderRadius: "25%",
                          marginRight: "5px",
                        }}
                        className="p-1 overlaybtn"
                      />
                    </a>
                  </Tooltips>
                  {/* {singlelist.quantity ?? ""} */}
                  <TextField
                    // Label="Quantity"
                    Name="quantity"
                    Width="50%"
                    Type="number"
                    SX={{
                      fontSize: "12px",
                      width: "90%",
                      input: { font: "menu" },
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                    }}
                    Data={singlelist.quantity ?? ""}
                    Required={true}
                    Handler={(e) => handleFieldChange(e, index)}
                  />
                  <Tooltips title="Add Quantity" placement="top">
                    <a
                      type="button"
                      onClick={() => handleAdd(index)}
                      className="package__name_list_qty_btn"
                    >
                      <IoIosAdd
                        size={25}
                        style={{
                          border: "2px solid #E2EDFB",
                          borderRadius: "25%",
                          marginLeft: "5px",
                        }}
                        className="p-1 overlaybtn"
                      />
                    </a>
                  </Tooltips>
                </div>

                <div className="package__name_list_box_content package__name_content_stock">
                  {filterProductWarehouses(singlelist)}
                </div>

                <div
                  className="package__name_list_box_content package__name_content_notes"
                  style={{ justifyContent: "space-around" }}
                >
                  <Select
                    value={singlelist?.product_lot_id || ""}
                    onChange={(e) => handleSelectChange(e, index)}
                    displayEmpty
                    size="small"
                    sx={{ fontSize: "12px", width: "90%" }}
                  >
                    <MenuItem value="">
                      <em style={{ fontSize: "12px" }}>Select a lot</em>
                    </MenuItem>
                    {singlelist?.product_warehouse
                      ?.find((warehouse) =>
                        Warehouse.includes(warehouse?.warehouse_id)
                      )
                      ?.product_lots?.map((lot) => (
                        <MenuItem
                          key={lot?.id}
                          value={lot?.id}
                          sx={{ fontSize: "12px" }}
                        >
                          {lot?.name} ({lot?.product_pallets_sum_quantity})
                        </MenuItem>
                      ))}
                  </Select>
                </div>

                <div
                  className="package__name_list_box_content package__name_content_notes"
                  style={{ justifyContent: "space-around" }}
                >
                  <TextField
                    // Label="Product Notes"
                    Name="note"
                    Data={singlelist?.note ?? ""}
                    Required={false}
                    Handler={(e) => handleNotes(e, index)}
                    Multiline={true}
                    SX={{
                      fontSize: "12px",
                      width: "90%",
                      input: { font: "menu" },
                    }}
                  />
                </div>

                <div className="package__name_list_box_content package__name_content_delete">
                  <Tooltips title="Delete Product" placement="top">
                    <a
                      type="button"
                      onClick={() => handleRemove(index, singlelist.id)}
                      className="package__name_list_box_delete_prod"
                    >
                      <BsTrash
                        size={35}
                        style={{
                          border: "2px solid #E2EDFB",
                          borderRadius: "25%",
                        }}
                        className="p-2 overlaybtn"
                      />
                    </a>
                  </Tooltips>
                </div>
              </div>
            </>
          ))}
        </Col>
        <Col
          md={3}
          className="package__name_list_wrap package__name_list_wrap_fullfilment_cost"
          style={{
            borderLeft: "1px solid #d3d3d3",
            padding: "10px 15px 5px",
            // margin: "0px 0px 0px 17px",
            // width: "31%",
          }}
        >
          <Row style={{ marginBottom: "5px" }}>
            <h3>Fulfilment Cost </h3>{" "}
          </Row>
          {formula["TotalProducts"] - formula["TotalPreProduct"] > 0 && (
            <div className="package__name_fullfilment_cost_row">
              <label className="tile__label_fullfilment_cost">
                <strong>
                  {formula["UnitType"] === "CBM" ? "Volume" : "Weight"}: (
                  {formula["TotalFulfilmentWeight"]} {formula["UnitType"]}) (
                  {formula["TotalProducts"] - formula["TotalPreProduct"]})
                </strong>
              </label>
              <span className="tile__value_fullfilment_cost">
                <strong>{formula["TotalWeightCost"]} CAD</strong>
              </span>
            </div>
          )}
          {formula["TotalPreProduct"] > 0 && (
            <>
              <div className="package__name_fullfilment_cost_row">
                <label className="tile__label_fullfilment_cost">
                  <strong>Item Quantity: ({formula["TotalPreProduct"]})</strong>
                </label>
                {/* <span>{formula["TotalPreCost"]} CAD </span> */}
              </div>
              {SelectedProducts?.filter(
                (singlelist) => singlelist.fulfillment_cost
              ).map((singlelist, index) => (
                <div className="package__name_fullfilment_cost_row" key={index}>
                  <label className="tile__label_fullfilment_cost">
                    {singlelist.name} ({singlelist.quantity} x{" "}
                    {singlelist.fulfillment_cost})
                  </label>
                  <span className="tile__value_fullfilment_cost">
                    {singlelist.fulfillment_cost * singlelist.quantity} CAD{" "}
                  </span>
                </div>
              ))}
              <div
                className="package__name_fullfilment_cost_row"
                style={{ justifyContent: "flex-end" }}
              >
                {/* <label className="tile__label_fullfilment_cost">
              <strong>Total Items Fulfilment: ({formula["TotalPreProduct"]})</strong>
            </label> */}
                <span className="tile__value_fullfilment_cost">
                  {formula["TotalPreCost"]} CAD{" "}
                </span>
              </div>
            </>
          )}
          {data?.additional_costs?.length > 0 && (
            <>
              <div className="package__name_fullfilment_cost_row">
                <label className="tile__label_fullfilment_cost">
                  <strong>
                    Additional Cost: ({data?.additional_costs?.length})
                  </strong>
                </label>
                {/* <span className="tile__value_fullfilment_cost">{data?.formula?.["TotalPreCost"]} CAD </span> */}
              </div>
              {data?.additional_costs?.map((list, index) => (
                <div className="package__name_fullfilment_cost_row" key={index}>
                  <label className="tile__label_fullfilment_cost">
                    {list?.cost_type?.name}
                  </label>
                  <span className="tile__value_fullfilment_cost">
                    {list?.cost} CAD{" "}
                  </span>
                </div>
              ))}
              <div className="package__name_fullfilment_cost_row">
                <label>
                  <strong>Total Additional Cost</strong>
                </label>
                <span className="tile__value_fullfilment_cost">
                  <strong>
                    {calculateTotalAdditionalCost(data?.additional_costs)} CAD{" "}
                  </strong>
                </span>
              </div>
            </>
          )}
          <div className="package__name_fullfilment_cost_row">
            <label className="tile__label_fullfilment_cost">
              Total Products Selected:
            </label>
            <span className="tile__value_fullfilment_cost">
              {formula["TotalProducts"]}
            </span>
          </div>
          <div className="package__name_fullfilment_cost_row">
            <label className="tile__label_fullfilment_cost">
              Total Fulfilment COST:
            </label>
            <span className="tile__value_fullfilment_cost">
              {editMode &&
                parseFloat(data?.fulfillment_cost) +
                  calculateTotalAdditionalCost(data?.additional_costs) !=
                  formula["TotalFulfilmentCost"] +
                    calculateTotalAdditionalCost(data?.additional_costs) && (
                  <span
                    style={{
                      textDecoration: "line-through",
                      fontWeight: "100",
                    }}
                  >
                    {parseFloat(data?.fulfillment_cost) +
                      calculateTotalAdditionalCost(data?.additional_costs)}{" "}
                  </span>
                )}
              {parseFloat(
                formula["TotalFulfilmentCost"] +
                  calculateTotalAdditionalCost(data?.additional_costs)
              ).toFixed(2)}{" "}
              CAD{" "}
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
}
