import ListGroup from "react-bootstrap/ListGroup";
import "./availableproducts.css";

import Tooltips from "../../../../Components/Tooltip/Tooltip";
import React, { useState, useEffect } from "react";
import { setdata } from "../../../../helpers/helpers";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import Sad from "@mui/icons-material/SentimentVeryDissatisfied";
import { fetchOnlyProducts } from "../../../../services/product.service";
import noImage from "../../../../assets/images/noImage.png";
import CircularProgress from "@mui/material/CircularProgress";

export default function AvailableGridView(props) {
  const { handlescrollpackage, formData, setFilter } = props;

  const [page, setPage] = useState(1);
  const [Products, setProducts] = useState();
  const [totalPages, setTotalPages] = useState();
  const [Productsloader, setProductsloader] = useState(false);
  const [searchProducts, setsearchProducts] = useState("");

  const handlePrevPage = () => {
    setPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const getAllProducts = async () => {
    setProductsloader(true);
    const response = await fetchOnlyProducts(page, 21, searchProducts);
    if (!response.error) {
      setTotalPages(
        Math.ceil(
          response?.data?.result?.total / response?.data?.result?.per_page
        )
      );
      setProducts(response?.data?.result?.data);
      setProductsloader(false);
    } else {
      setProductsloader(false);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, [page, searchProducts]);

  const handleProductchange = (e) => {
    const { value } = e.target;
    setsearchProducts(value);
  };

  useEffect(() => {
    const filterElement = (
      <>
        {(Productsloader === true ||
          searchProducts !== "" ||
          totalPages > 0) && (
          <div className="product__scrollbar_list_filter_field_wrap">
            <input
              type="text"
              value={searchProducts}
              onChange={(e) => handleProductchange(e)}
              placeholder="Filter Products"
              className="product__scrollbar_list_filter_field"
            />
            {Productsloader === true && (
              <CircularProgress
                size={12}
                style={{
                  color: "#C1C1C1",
                  marginLeft: "-20px",
                  marginTop: "15px",
                  transform: "translateY(-50%)",
                }}
              />
            )}
          </div>
        )}
      </>
    );

    setFilter(filterElement);
  }, [Productsloader, searchProducts, totalPages]);

  return (
    <>
      {totalPages > 0 ? (
        <div className="d-flex flex-row mt-2">
          <IconButton
            className="mr-1"
            disabled={page === 1}
            onClick={handlePrevPage}
          >
            <FaChevronLeft />
          </IconButton>
          <ListGroup
            horizontal
            className="overflow-auto horiscl p-2 expanded"
            style={{
              maxHeight: "108px",
              cursor: "pointer",
              display: "-webkit-box",
              overflowX: "auto",
              overflowY: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              WebkitScrollbarTrack: {
                background: "#000000",
              },
            }}
          >
            {Products.map((data, index) => (
              <Tooltips
                title={
                  formData?.warehouse_id ? "Click To Add" : "Select Warehouse"
                }
                key={index}
              >
                <ListGroup.Item
                  className={
                    formData?.warehouse_id
                      ? "mr-2 mb-1 shadow single_list"
                      : "mr-2 mb-1 shadow single_list select_none_card"
                  }
                  style={{
                    width: "150px",
                    border: "1px solid #C8C9CA",
                    borderRadius: "4px",
                    padding: "2px",
                    flexShrink: 0,
                  }}
                  onClick={() => handlescrollpackage(data)}
                  key={index}
                >
                  <div class="grn__availableproductList__card_wrap">
                    <div class="grn__availableproductList__card_title">
                      {setdata(() => data.name, false, "")}
                    </div>
                    <div class="grn__availableproductList__card_details_wrap">
                      <div class="productList__card_details_img">
                        <img
                          src={
                            data?.media.find(
                              (media) => media.is_primary === "true"
                            )?.file ||
                            data?.media[0]?.file ||
                            noImage
                          }
                          onError={(e) => {
                            e.target.src = noImage;
                          }}
                          loading="lazy"
                        />
                      </div>
                      <div class="grn__availableproductList__card_details_measures">
                        <div class="grn__availableproductList__card_details_measures_LWH">
                          {data?.product_warehouse?.length > 0 && (
                            <>
                              {data?.product_warehouse?.map((data, index) => {
                                return (
                                  <div
                                    class="productList__card_details_measures_L"
                                    style={{
                                      fontWeight:
                                        formData?.warehouse_id ===
                                        data?.warehouse_id
                                          ? "bold"
                                          : "",
                                    }}
                                  >
                                    #{data.warehouse_id}
                                    <span>{data?.quantity}</span>
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </ListGroup.Item>
              </Tooltips>
            ))}
          </ListGroup>
          <IconButton
            className="mr-1"
            disabled={page === totalPages || totalPages === 0}
            onClick={handleNextPage}
          >
            <FaChevronRight />
          </IconButton>
        </div>
      ) : (
        <div
          className="grn__available_screen_empty"
          style={{ minHeight: "12vh", width: "100%" }}
        >
          <div className="grn__available_scrnempt_content">
            <Sad className="grn__available_scrnempt_contenticon" />
            <div>No Products to display!</div>
          </div>
        </div>
      )}
    </>
  );
}
