import React, { useEffect, useState, useRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Barcode from "react-barcode";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";



export default function LabelModal({ open, setOpen, setSnack, labelData, title, subtitle }) {
    const [labelType, setLabelType] = useState("standard");
    const [customSize, setCustomSize] = useState({ width: 50, height: 25 });
    const [standardSize, setStandardSize] = useState("64x25");
    const [numberOfLabels, setNumberOfLabels] = useState(1);
    const [labelInfo, setLabelInfo] = useState({});
    const [base64Image, setBase64Image] = useState();

    const barcodeRef = useRef(null);
    const labelRef = useRef(null);

    const standardSizes = {
        "64x25": {
            width: 64,
            height: 25,
            labelsPerSheet: 30,
            rows: 10,
            columns: 3,
        },
    };


    const generatePDF = async () => {
        const { width, height, rows, columns, labelsPerSheet } =
            labelType === "standard"
                ? standardSizes[standardSize]
                : { width: customSize.width, height: customSize.height };

        const labelElement = labelRef.current;

        // Render the label to a canvas
        const canvas = await html2canvas(labelElement, {
            scale: 3, // Increase the scale for better quality
            useCORS: true, // Ensure CORS is used for cross-origin images
            logging: true, // Enable logging for debugging purposes
            allowTaint: false,
        });

        const imgData = canvas.toDataURL("image/png");

        // Create a PDF
        const pdf = new jsPDF({
            orientation: labelType === "standard" || customSize.height > customSize.width ? "portrait" : "landscape",
            unit: "mm",
            format: labelType === "standard" ? "a4" : [customSize.height, customSize.width],
        });

        const addLabelToPDF = (x, y) => {
            const barcodeAspectRatio = canvas.width / canvas.height;
            let adjustedWidth, adjustedHeight;

            // Ensure the barcode fits within the label size without stretching
            if (barcodeAspectRatio > 1) {
                // If the barcode is wider than tall
                adjustedWidth = width;
                adjustedHeight = width / barcodeAspectRatio;
                if (adjustedHeight > height) {
                    adjustedHeight = height;
                    adjustedWidth = height * barcodeAspectRatio;
                }
            } else {
                // If the barcode is taller than wide
                adjustedHeight = height;
                adjustedWidth = height * barcodeAspectRatio;
                if (adjustedWidth > width) {
                    adjustedWidth = width;
                    adjustedHeight = width / barcodeAspectRatio;
                }
            }

            const offsetX = (width - adjustedWidth) / 2;
            const offsetY = (height - adjustedHeight) / 2;

            pdf.addImage(imgData, "PNG", x + offsetX, y + offsetY, adjustedWidth, adjustedHeight);
        };
        if (labelType === "thermal") {
            const thermalPageWidth = customSize.width;
            const thermalPageHeight = customSize.height;

            // Print one label per sheet
            for (let i = 0; i < numberOfLabels; i++) {
                if (i > 0) {
                    pdf.addPage([thermalPageWidth, thermalPageHeight]);
                }

                // Ensure label dimensions fit within the page
                if (width <= thermalPageWidth && height <= thermalPageHeight) {
                    const x = (thermalPageWidth - width) / 2; // Center the label horizontally
                    const y = 0; // Align the label to the top

                    if (x >= 0 && y >= 0 && x + width <= thermalPageWidth && y + height <= thermalPageHeight) {
                        addLabelToPDF(x, y);
                    } else {
                        console.error("Invalid coordinates for thermal label: ", {
                            x,
                            y,
                            width,
                            height,
                        });
                    }
                } else {
                    console.error("Label dimensions exceed page size: ", {
                        width,
                        height,
                        thermalPageWidth,
                        thermalPageHeight,
                    });
                }
            }
        }

        else {
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();

            const marginX = 10; // 10mm margin on left and right
            const marginY = 10; // 10mm margin on top and bottom
            const verticalSpacing = 2.5; // Increase this value to add more vertical spacing between barcodes

            for (let i = 0; i < numberOfLabels; i++) {
                const col = Math.floor(i / rows) % columns;
                const row = i % rows;

                if (i > 0 && i % labelsPerSheet === 0) {
                    pdf.addPage();
                }

                const x = marginX + col * width;
                const y = marginY + row * (height + verticalSpacing); // Add vertical spacing here

                if (x >= 0 && y >= 0 && x + width <= pageWidth && y + height <= pageHeight) {
                    addLabelToPDF(x, y);
                } else {
                    console.error("Invalid coordinates for standard label: ", {
                        x,
                        y,
                        width,
                        height,
                    });
                }
            }
        }

        pdf.save(`${open?.row?.code ?? "Product"}-barcode.pdf`);
    };


    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (labelData) {
            const {
                name,
                code,
                image
            } = labelData;
            setLabelInfo({
                code,
                name,
                image
            });
            const generateBase64 = async () => {
                if (image) {
                    const base64 = await fetch(image)
                        .then(response => response.blob())
                        .then(blob => {
                            const reader = new FileReader();
                            reader.readAsDataURL(blob);
                            return new Promise((res) => {
                                reader.onloadend = () => {
                                    res(reader.result);
                                }
                            })
                        })
                    setBase64Image(base64)
                }
            }
            generateBase64()
        }
        return () => {
            setBase64Image()
        }
    }, [labelData]);

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className="min-h-[250px] max-w-[1270px] w-[90%] max-h-[90%] overflow-auto bg-[white] absolute -translate-x-2/4 -translate-y-2/4 shadow-[24px] p-5 rounded-[5px] border-[none] left-2/4 top-2/4">
                    <div
                        className="prdtable__hist_modalheading history__product_heading  history__main_heading"
                        style={{
                            border: "none",
                        }}
                    >
                        {title}
                    </div>
                    <div className="prdtable__hist_modalheading history__product_heading history__sub_heading">
                        {subtitle}
                    </div>
                    <div className="p-6 flex flex-col lg:flex-row flex-wrap">
                        <div className="lg:w-1/2 min-w-[250px]">
                            <div className="mb-4">
                                <label className="block mb-2">Barcodes Type:</label>
                                <select
                                    value={labelType}
                                    onChange={(e) => setLabelType(e.target.value)}
                                    className="border p-2 rounded w-full"
                                >
                                    <option value="standard">Standard</option>
                                    <option value="thermal">Thermal</option>
                                </select>
                            </div>
                            {labelType === "standard" && (
                                <div className="mb-4">
                                    <label className="block mb-2">Standard Size:</label>
                                    <select
                                        value={standardSize}
                                        onChange={(e) => setStandardSize(e.target.value)}
                                        className="border p-2 rounded w-full"
                                    >
                                        {Object.keys(standardSizes).map((size) => (
                                            <option key={size} value={size}>
                                                {size.replace("x", " x ")} (
                                                {standardSizes[size].labelsPerSheet} Barcodes per sheet)
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            {labelType === "thermal" && (
                                <div className="mb-4">
                                    <label className="block mb-2">Width (mm)</label>
                                    <input
                                        type="number"
                                        name="width"
                                        value={customSize.width}
                                        onChange={(e) =>
                                            setCustomSize({ ...customSize, width: e.target.value })
                                        }
                                        className="border p-2 rounded w-full"
                                        min="25"
                                    />
                                    <label className="block mb-2 mt-2">Height (mm)</label>
                                    <input
                                        type="number"
                                        name="height"
                                        value={customSize.height}
                                        onChange={(e) =>
                                            setCustomSize({ ...customSize, height: e.target.value })
                                        }
                                        className="border p-2 rounded w-full"
                                        min="25"
                                    />
                                </div>
                            )}
                            <div className="mb-4">
                                <label className="block mb-2">Number of Barcodes:</label>
                                <input
                                    type="number"
                                    name="numberOfLabels"
                                    value={numberOfLabels}
                                    onChange={(e) => setNumberOfLabels(e.target.value)}
                                    className="border p-2 rounded w-full"
                                    min="1"
                                />
                            </div>
                            <div className="my-4">
                                <button
                                    onClick={generatePDF}
                                    className="bg-blue-500 text-white py-2 px-4 rounded"
                                >
                                    Download Barcode
                                </button>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex justify-center items-center min-w-[250px]">
                            <div className="p-4 border rounded">
                                <div
                                    className="flex flex-col items-center justify-center"
                                    ref={labelRef}
                                    style={{ padding: "20px 10px" }}
                                >

                                    {base64Image && labelType === "thermal" && customSize.height >= 0.55 * customSize.width && <img src={base64Image} alt="Product" className="w-20 h-18 mr-1 border" />}
                                    <div className="w-full flex justify-center max-w-[450px]" >
                                        <Barcode
                                            value={labelInfo?.code}
                                            ref={barcodeRef}
                                            format="CODE128"
                                            width={2}
                                            height={60}
                                            displayValue={true}
                                            fontOptions="bold"
                                            font="monospace"
                                            textAlign="center"
                                            textPosition="bottom"
                                            textMargin={2}
                                            fontSize={15}
                                            background="#ffffff"
                                            lineColor="#000000"
                                            margin={10}
                                        />
                                    </div>
                                    {labelInfo?.name && (
                                        <div className="m-[1px] font-semibold text-[14px]">
                                            {labelInfo.name}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}