import axios from "../../axiosInstance";

export const fetchAllAddresses = async () => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`addressbook/show`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const createAddress = async (payload) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`addressbook/add`, payload);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const editAddress = async (payload, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.put(`addressbook/update/${id}`, payload);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const deleteAddress = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`addressbook/delete/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
