import React, { useState, useEffect } from "react";
import styles from "./productdetails.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";

import { BsWordpress } from "react-icons/bs";
import { FaEbay } from "react-icons/fa";
import { FaShopify } from "react-icons/fa";
import { AiOutlineAmazon } from "react-icons/ai";
import { TbBrandWalmart } from "react-icons/tb";
import "./productDetailsModal.css";
import noImage from "../../../assets/images/noImage.png";
import Barcode from "react-barcode";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
// Initialize the Swiper core
SwiperCore.use([FreeMode, Navigation, Thumbs]);

const marketplaceIcons = {
  bestbuy: <BsWordpress size={20} />,
  eBay: <FaEbay size={20} />,
  Woocommerce: <BsWordpress size={20} />,
  shopify: <FaShopify size={20} />,
  amazon: <AiOutlineAmazon size={20} />,
  walmart: <TbBrandWalmart size={20} />,
};

const ProductDetails = ({ data }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const [activeVariation, setActiveVariation] = useState(0);

  return (
    <>
      <div className="product_main_modal_wrap">
        <div className="product_modal_image_col">
          <Swiper
            style={{
              "--swiper-navigation-color": "#1976d2",
              "--swiper-pagination-color": "#1976d2",
            }}
            loop={false}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[Navigation, Thumbs]}
            className={styles.mySwiper2}
          >
            {data?.row?.media?.map((img, index) => {
              return (
                <SwiperSlide key={index}>
                  <img
                    src={img.file}
                    className="swipper_image_big_product_modal"
                    onError={(e) => {
                      e.target.src = noImage;
                    }}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
          <Swiper
            onSwiper={setThumbsSwiper}
            loop={false}
            spaceBetween={10}
            // slidesPerView={8}
            freeMode={true}
            allowTouchMove={false}
            // watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className={styles.mySwiper}
          >
            {data?.row?.media?.map((img, index) => {
              return (
                <SwiperSlide key={index}>
                  <img
                    src={img.file}
                    className="swipper_image_thumnail_product_modal"
                    onError={(e) => {
                      e.target.src = noImage;
                    }}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="product_modal_detail_col">
          <div className="product_modal_detail_nameproduct_modal_detail">
            {data?.row?.name ?? "-"}
          </div>
          <div className="product_modal_detail_stock_base_warehouse_wrap">
            {data?.row?.product_warehouse?.map((warehouse, index) => {
              return (
                <div
                  // onClick={() => setActiveVariation(index)}
                  className="productdetails_product_warehouse_counts"
                  // className={
                  //   activeVariation === index
                  //     ? styles.product_warehouse_box_active
                  //     : styles.product_warehouse_box
                  // }
                  key={index}
                >
                  <div className="product_modal_detail_stock_base_warehouse_name">
                    {warehouse.warehouse.name ?? "-"} |{" "}
                    {warehouse.warehouse.address ?? "-"}
                  </div>
                  <div className="product_modal_detail_stock_base_warehouse_quantity">
                    {warehouse.quantity ?? "0"} in stock
                  </div>
                </div>
              );
            })}
          </div>
          <div className="product_modal_detail_price">
            ${data?.row?.sale_price ?? "-"}
          </div>
          <div className="product_modal_detail_brandName">
            <span className="product_modal_detail_brandName_title">
              Brand Name 1:{" "}
            </span>
            {data?.row?.brand?.name ?? "-"}
          </div>
          <div className="product_modal_detail_stock_base_warehouse_container"></div>
          <div className="product_modal_detail_product_varient_wrap">
            {data?.row?.children?.length != 0 && (
              <>
                <div className="product_modal_detail_product_varient_heading">
                  {data?.row?.children?.[activeVariation]["name"] ?? "-"}
                </div>
                <div className="product_details_variants_wrap_container">
                  <div className="product_details_variants_wrap">
                    {data?.row?.children?.map((variation, index) => {
                      return (
                        <div
                          onClick={() => {
                            setActiveVariation(index);
                            // setActiveSlider(data);
                          }}
                          key={index}
                          className={
                            activeVariation === index
                              ? "product_details_variants_box box_active"
                              : "product_details_variants_box"
                          }
                        >
                          <img
                            src={
                              variation?.media.find(
                                (media) => media.is_primary === "1"
                              )?.file ||
                              variation?.media[0]?.file ||
                              noImage
                            }
                            onError={(e) => {
                              e.target.src = noImage;
                            }}
                            className="product_details_variants_image"
                          />

                          <div className="product_details_variants_variant_name">
                            {variation?.name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* <div className="product_modal_detail_product_varient_list">
                  <div className="product_modal_detail_product_varient_list_heading">SKU</div>
                {data?.row?.children?.[activeVariation]?.product_skus?.map(
                    (sku, index) => {
                      return (
                        <div className="product_modal_detail_product_varient_list_box" key={index}>
                          {marketplaceIcons[sku?.market_place?.market]} : {sku?.sku}
                        </div>
                      );
                    }
                  )} 
                </div> */}
              </>
            )}
          </div>
          <div
            style={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Barcode
              value={data?.row?.code}
              format="CODE128"
              width={1}
              height={60}
              displayValue={true}
              fontOptions="bold"
              font="monospace"
              textAlign="center"
              textPosition="bottom"
              textMargin={2}
              fontSize={15}
              background="#ffffff"
              lineColor="#000000"
              margin={10}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
