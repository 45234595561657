import React from "react";
import "./adddetailsbydimension.css";
import TextFeild from "../../../CustomTextFeild/TextFeild";
import Form from "react-bootstrap/Form";

const AddDetailsByDimension = ({
  addDetail,
  formulaDetails,
  handleChangeDetails,
  tierFormula,
}) => {
  return (
    <>
      <div className="details_bydimension_formrow">
        <div className="details_bydimension_wrapper">
          <TextFeild
            Name="min_length"
            Label="Min Length"
            Required={false}
            Data={formulaDetails?.min_length}
            Disabled={tierFormula?.dimensions?.length === 0}
            Handler={(e) => handleChangeDetails(e)}
          />
          <TextFeild
            Name="min_width"
            Label="Min Width"
            Required={false}
            Data={formulaDetails?.min_width}
            Disabled={tierFormula?.dimensions?.length === 0}
            Handler={(e) => handleChangeDetails(e)}
          />
          <TextFeild
            Name="min_height"
            Label="Min Height"
            Required={false}
            Data={formulaDetails?.min_height}
            Disabled={tierFormula?.dimensions?.length === 0}
            Handler={(e) => handleChangeDetails(e)}
          />
        </div>
        <div className="details_bydimension_wrapper">
          <TextFeild
            Label="Max Length"
            Name="max_length"
            Required={false}
            Data={formulaDetails?.max_length}
            Handler={(e) => handleChangeDetails(e)}
          />
          <TextFeild
            Label="Max Width"
            Name="max_width"
            Required={false}
            Data={formulaDetails?.max_width}
            Handler={(e) => handleChangeDetails(e)}
          />
          <TextFeild
            Label="Max Height"
            Name="max_height"
            Required={false}
            Data={formulaDetails?.max_height}
            Handler={(e) => handleChangeDetails(e)}
          />
        </div>

        <button
          className="details_bydimension_modalsave details_bydimension_details"
          onClick={(e) => addDetail(e)}
        >
          <span>Add </span> +
        </button>
      </div>
    </>
  );
};

export default AddDetailsByDimension;
