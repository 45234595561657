import React, { useEffect, useState } from "react";
import "./EditCompany.css";

// components imports
import Loader from "../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import { updateShippingCompany } from "../../../../../services/AdminServices/Companies/companies.services";
// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import Upload from "@mui/icons-material/CloudUpload";

export default function EditCompany({
  open,
  setOpen,
  previousData,
  setSnack,
  setShippingCompanies,
}) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const user = useSelector((state) => state.user);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleFileChange = (e) => {
    setData({ ...data, logo_url: e.target.files[0] });
  };
  const handleUpdate = async () => {
    setLoading(true);
    for (const key in data) {
      if (data[key] === "") {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: `${key} is required!`,
            severity: "error",
          };
        });
        setLoading(false);
        return;
      }
    }
    const formData = new FormData();
    formData.append("user_name", data.user_name);
    formData.append("name", data.name);
    formData.append("api_url", data.api_url);
    formData.append("production_url", data.production_url);
    formData.append("account_no", data.account_no);
    formData.append("password", data.password);
    data.logo_url && formData.append("logo_url", data.logo_url);
    const saved = await updateShippingCompany(formData, data.id);
    if (saved.error) {
      console.log(saved.error);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Couldn't update company.",
          severity: "error",
        };
      });
    } else {
      setShippingCompanies((prevShippingCompanies) => {
        return {
          ...prevShippingCompanies,
          data: prevShippingCompanies?.data.map((company) => {
            return company.id === data.id ? saved.data.result : company;
          }),
        };
      });
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Company Updated successfully",
          severity: "success",
        };
      });
    }

    setLoading(false);
    handleClose();
  };

  useEffect(() => {
    setData({ ...previousData, logo_url: null });
  }, [previousData]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="edit__company_modal">
          <div className="edit__company_modalheading">Update Company</div>
          <div className="edit__company_modalcontent">
            <div className="edit__company_modalrow">
              <div className="edit__company_modalinputwrap">
                <label>Name</label>
                <input
                  className="edit__company_modalinput"
                  placeholder="Name"
                  name="name"
                  value={data.name}
                  onChange={handleChange}
                />
              </div>
              <div className="edit__company_modalinputwrap">
                <label>Username</label>
                <input
                  className="edit__company_modalinput"
                  placeholder="Username"
                  name="user_name"
                  value={data.user_name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="edit__company_modalrow">
              <div className="edit__company_modalinputwrap">
                <label>Sandbox URL</label>
                <input
                  className="edit__company_modalinput"
                  placeholder="Sandbox URL"
                  name="api_url"
                  value={data.api_url}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="edit__company_modalrow">
              <div className="edit__company_modalinputwrap">
                <label>Production URL</label>
                <input
                  className="edit__company_modalinput"
                  placeholder="Production URL"
                  name="production_url"
                  value={data.production_url}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="edit__company_modalrow">
              <div className="edit__company_modalinputwrap">
                <label>Account Number</label>
                <input
                  className="edit__company_modalinput"
                  placeholder="Account Number"
                  name="account_no"
                  value={data.account_no}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="edit__company_modalrow">
              <div className="edit__company_modalinputwrap">
                <label>Password</label>
                <input
                  className="edit__company_modalinput"
                  placeholder="Password"
                  name="password"
                  value={data.password}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="edit__company_modalrow">
              <div className="edit__company_modalinputwrap">
                <label className="edit__company_profimagelbl">
                  <Upload /> {data.logo_url?.name ?? "Company Logo"}
                  <input
                    type="file"
                    size="60"
                    className="edit__company_profimageinput"
                    onChange={handleFileChange}
                    accept=".jpg,.png"
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="edit__company_modalfooter">
            <div className="edit__company_modalcncl" onClick={handleClose}>
              Cancel
            </div>
            <div className="edit__company_modalsave" onClick={handleUpdate}>
              {loading ? <Loader /> : "Update"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
