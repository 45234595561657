import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import { useState, useEffect } from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import logo from "../logo.png";
import ProfileAvatar from "../../assets/images/profile-avatar.png";
import { useSelector } from "react-redux";
import { BiPackage } from "react-icons/bi";
import { MdManageAccounts, MdShoppingCart, MdStore } from "react-icons/md";
import { FaChevronDown, FaDigitalTachograph } from "react-icons/fa";
import moscot from "../moscot.png";
import WarehouseProfileModal from "./Components/ProfileModal/WarehouseProfileModal";
import DateBadge from "../DateBadge/DateBadge";
// import AuthComponent from "../../Pages/Authentication/Components/AuthComponent/AuthComponent";

const WarehouseNavBar = () => {
  const [show, setShow] = useState(false);
  const user = useSelector((state) => state.user);
  const [modal, setModal] = useState();
  const currentDate = new Date();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleMenuIconClick = () => {
    setMenuOpen(!isMenuOpen);
  };
  const [isMenuOpen, setMenuOpen] = useState(false);

  const authComponentKey = JSON.stringify(user);
  return (
    <div>
      {/* <AuthComponent key={authComponentKey} /> */}
      <div className="header__main_wrap">
        <div className="header__logo_wrap">
          <Navbar.Brand>
            <img alt="" src={logo} className="brand_image_header" />
          </Navbar.Brand>
        </div>
        <div
          className={
            isMenuOpen
              ? "header__navigation_main_wrap navigation__mobile_open "
              : "header__navigation_main_wrap "
          }
        >
          {isMenuOpen ? (
            <>
              <div
                className="header__mobile_overlay"
                onClick={handleMenuIconClick}
              ></div>
              <button
                className="headernav__btn_cross"
                onClick={handleMenuIconClick}
              >
                <span></span>
                <span></span>
              </button>
            </>
          ) : (
            " "
          )}
          <ul className="header__navigation_main_list">
            <li>
              <LinkContainer to="/warehouse-orders" className="mr-2">
                <Nav.Link className="navbar__link_wrap">
                  <MdShoppingCart size={20} className="m-auto" />
                  Orders
                </Nav.Link>
              </LinkContainer>
            </li>
            <li>
              <LinkContainer to="/warehouse-clients" className="mr-2">
                <Nav.Link className="navbar__link_wrap">
                  <MdManageAccounts size={20} className="m-auto" />
                  Clients
                </Nav.Link>
              </LinkContainer>
            </li>
            <li>
              <LinkContainer to="/warehouse-products" className="mr-2">
                <Nav.Link className="navbar__link_wrap">
                  <BiPackage size={20} className="m-auto" />
                  Products
                </Nav.Link>
              </LinkContainer>
            </li>
            <li>
              <LinkContainer to="/warehouse-payments" className="mr-2">
                <Nav.Link className="navbar__link_wrap">
                  <FaDigitalTachograph size={20} className="m-auto" />
                  Finances
                </Nav.Link>
              </LinkContainer>
            </li>
            <li>
              <LinkContainer to="/storage-locations" className="mr-2">
                <Nav.Link className="navbar__link_wrap">
                  <MdStore size={20} className="m-auto" />
                  Storage
                </Nav.Link>
              </LinkContainer>
            </li>
          </ul>
          <div className="headernav__navicon_wrap">
            <button
              className="headernav__navicon_btn"
              onClick={handleMenuIconClick}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
        <div className="header__dataUser-col_wrap">
          <div className="header__datebadge_col">
            <DateBadge
              month={months[currentDate.getMonth()]}
              day={currentDate.getDate()}
            />
          </div>
          <div className="header__userprofile_wrap">
            <div
              className="navbar__userprofile_section"
              onClick={() => setModal("profile")}
            >
              <div className="navbar__userprofile_avatar">
                {user.profile_image &&
                user.profile_image.split("/").pop() !== "undefined" ? (
                  <img
                    src={`${user?.profile_image}`}
                    alt="Profile"
                    className="profile__avatar_image 1"
                  />
                ) : (
                  <img
                    src={ProfileAvatar}
                    alt=""
                    className="profile__avatar_image 2"
                  />
                )}
                <FaChevronDown className="navbar__userprofile_sectiondrop" />
              </div>
              {modal === "profile" && (
                <WarehouseProfileModal
                  onClose={() => setModal()}
                  style={{
                    top: "50px",
                    right: "0 ",
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <ToastContainer position={"top-end"} className="p-3 mt-14">
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={2000}
          autohide
          className="ml-16"
        >
          <Toast.Header>
            <img src={moscot} className="rounded me-2" alt="" width="50" />
            <strong className="me-auto">Hajex Shipment</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body>Succefully Logged Out !!</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default WarehouseNavBar;
