import Tooltip from "../../../../../../Components/Tooltip/Tooltip";
import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import GridViewIcon from "@mui/icons-material/GridView";
import ListIcon from "@mui/icons-material/List";
import { TbPackages } from "react-icons/tb";
import { LuPackage2 } from "react-icons/lu";
import { GoPackage } from "react-icons/go";

export default function ProductTypeSelector(props) {
  const { StepCheck, SetStepCheck, view, handleView } = props;

  const handletype = (type) => {
    SetStepCheck((step) => {
      return {
        ...step,
        Products: type,
        QuoteCheck: false,
      };
    });
  };

  return (
    <>
      <div className="grid__list_btns_wrap">
        <div className="productDetailsCustom__icons_tabs_btn">
          <Tooltip title="Products">
            <div
              className={
                StepCheck["Products"] === "Products"
                  ? "productDetailsCustom__icons_product tabs__act_true"
                  : "productDetailsCustom__icons_product"
              }
              onClick={() => handletype("Products")}
            >
              <GoPackage />
            </div>
          </Tooltip>
          <Tooltip title="Bundles">
            <div
              className={
                StepCheck["Products"] === "Bundles"
                  ? "productDetailsCustom__icons_product tabs__act_true"
                  : "productDetailsCustom__icons_product"
              }
              onClick={() => handletype("Bundles")}
            >
              <TbPackages />
            </div>
          </Tooltip>
          <Tooltip title="Packages">
            <div
              className={
                StepCheck["Products"] === "Packages"
                  ? "productDetailsCustom__icons_product tabs__act_true"
                  : "productDetailsCustom__icons_product"
              }
              onClick={() => handletype("Packages")}
            >
              <LuPackage2 />
            </div>
          </Tooltip>
        </div>
        <Tooltip title="List View">
          <IconButton
            className="fullfilment__form_expand_btn"
            color={view == "List" ? "primary" : "default"}
            onClick={handleView}
          >
            <ListIcon size={15} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Grid View">
          <IconButton
            className="fullfilment__form_expand_btn"
            color={view == "Grid" ? "primary" : "default"}
            onClick={handleView}
          >
            <GridViewIcon size={15} />
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
}
