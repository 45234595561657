import React, { useState } from "react";

// components imports
import CircularLoader from "../../../../Components/CircularLoader/CircularLoader";

// apis imports
import { storeStorageMap } from "../../../../services/WarehouseServices/storageMap.services";

// library imports
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export default function AddGraphModal({
    open,
    setOpen,
    setSnack,
    setStorageMaps
}) {
    const [loader, setLoader] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        rows: 50,
        column: 50,
    });

    const [errors, setErrors] = useState({
        name: "",
        rows: "",
        column: "",
    });

    const handleClose = () => {
        setOpen(false);
    };

    const handleCancel = () => {
        handleClose();
    };

    const handleFieldChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: "" }); // Clear errors when user starts typing
    };

    const validateFields = () => {
        let valid = true;
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = "Name is required";
            valid = false;
        }

        if (!formData.rows || formData.rows <= 0) {
            newErrors.rows = "Rows must be greater than 0";
            valid = false;
        }

        if (!formData.column || formData.column <= 0) {
            newErrors.column = "Columns must be greater than 0";
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleSave = async () => {
        if (!validateFields()) return;

        setLoader(true);
        const response = await storeStorageMap(formData);
        setLoader(false);

        if (response?.data?.success) {
            setStorageMaps((prevMaps) => [response?.data?.result, ...prevMaps]);
            setSnack((snack) => ({
                ...snack,
                open: true,
                text: "Graph Added Successfully",
                severity: "success",
            }));
            handleClose();
        } else {
            setSnack((snack) => ({
                ...snack,
                open: true,
                text: response?.data?.message || "Something went wrong",
                severity: "error",
            }));
        }
    };

    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{ ...style, width: "100%", maxWidth: "600px", maxHeight: "90vh", overflow: "auto" }}
                >
                    <h4 id="child-modal-title" className="text-center mt-2 mb-4">
                        Add New Graph
                    </h4>

                    <div className="flex justify-between my-3">
                        <div className="flex-1 mr-2">
                            <TextField
                                label="Name"
                                type="text"
                                value={formData?.name}
                                onChange={(e) => handleFieldChange("name", e.target.value)}
                                placeholder={"Name"}
                                sx={{
                                    width: "100%",
                                    input: { font: "menu" },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                size="small"
                                error={!!errors.name}
                                helperText={errors.name}
                            />
                        </div>
                    </div>

                    <div className="flex justify-between my-3">
                        <div className="flex-1 mr-2">
                            <TextField
                                label="Rows"
                                type="number"
                                value={formData?.rows}
                                onChange={(e) => handleFieldChange("rows", e.target.value)}
                                placeholder={"Rows"}
                                sx={{
                                    width: "100%",
                                    input: { font: "menu" },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                size="small"
                                error={!!errors.rows}
                                helperText={errors.rows}
                            />
                        </div>
                    </div>

                    <div className="flex justify-between my-3">
                        <div className="flex-1 mr-2">
                            <TextField
                                label="Columns"
                                type="number"
                                value={formData?.column}
                                onChange={(e) => handleFieldChange("column", e.target.value)}
                                placeholder={"Columns"}
                                sx={{
                                    width: "100%",
                                    input: { font: "menu" },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                size="small"
                                error={!!errors.column}
                                helperText={errors.column}
                            />
                        </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "end" }} className="addnotes_fulfilment_btn">
                        <Button onClick={handleSave} variant="contained" size="small">
                            {loader ? <CircularLoader /> : "Add"}
                        </Button>
                        <Button
                            onClick={handleCancel}
                            variant="contained"
                            color="error"
                            size="small"
                            style={{ marginLeft: "5px" }}
                        >
                            Cancel
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
