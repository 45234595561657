import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Package from "./Package";
import ProductsSection from "./Products";
import React, { useState } from "react";

export default function Row2(props) {
  const {
    Plist,
    StepCheck,
    SetStepCheck,
    SetPlist,
    Alist,
    SetAlist,
    Stepper,
    SelectedProducts,
    setSelectedProducts,
    isInternational,
  } = props;

  const [bundleRefresh, setbundleRefresh] = useState(false);
  const [packageRefresh, setpackageRefresh] = useState(false);

  return (
    <>
      <Row className="mx-auto mt-3 mb-3">
        <Col md={11} className="mx-auto">
          <ProductsSection
            Plist={Plist}
            SetPlist={SetPlist}
            StepCheck={StepCheck}
            SetStepCheck={SetStepCheck}
            SelectedProducts={SelectedProducts}
            setSelectedProducts={setSelectedProducts}
            bundleRefresh={bundleRefresh}
            setbundleRefresh={setbundleRefresh}
            packageRefresh={packageRefresh}
            setpackageRefresh={setpackageRefresh}
          />
        </Col>
      </Row>
      <Row className="mx-auto mt-3 mb-3">
        <Col md={11} className="mx-auto">
          <Package
            isInternational={isInternational}
            Plist={Plist}
            SetPlist={SetPlist}
            Alist={Alist}
            SetAlist={SetAlist}
            StepCheck={StepCheck}
            SetStepCheck={SetStepCheck}
            setpackageRefresh={setpackageRefresh}
            Stepper={Stepper}
            SelectedProducts={SelectedProducts}
            setSelectedProducts={setSelectedProducts}
            setbundleRefresh={setbundleRefresh}
          />
        </Col>
      </Row>
    </>
  );
}
