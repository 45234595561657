import axios from "./axiosInstance";

export const fetchAllCarriers = async (token) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get("admin/get-all-carriers", {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchCompaniesDetails = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/rating-setting/get-all?page=${page}&perPage=${perPage}&name=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchShippingCompanies = async (token, page, param) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/shipping-company/get?${page ? `page=${page}` : ""}${param ?? ""}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const storeCompanyDetails = async (token, formData, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/rating-setting/edit/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: "Bearer " + token,
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateShippingCompany = async (token, data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/shipping-company/update/${id}`, data, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const updateShippingCompanyStatus = async (token, data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `admin/shipping-company/update-status/${id}`,
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchAllShippers = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/users/Shipper?perPage=${perPage}&page=${page}&name=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchPaymentSettings = async (token) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`payment/settings/get`, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updatePaymentsSettings = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `admin/payment/setting/update-payment-formula`,
      { payments: [data] }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const searchShippersByAdmin = async (token, name, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/users/Shipper?name=${name}&&${page && `page=${page}`}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchShippers = async (token, page, param) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/get-users/Shipper?${page && `page=${page}`}${param ?? ""}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchCarriers = async (token, page, param) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/get-users/Carrier?${page && `page=${page}`}${param ?? ""}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const updateBalance = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/payment/balance/add/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const subtractBalance = async (token, data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/payment/balance/subtract/${id}`, data, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateAccountStatus = async (token, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `admin/update-account-status/${data.id}`,
      // data,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateServicecharge = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.put(
      `admin/payment-formula/user/is-service-charge/${data.id}/${data.is_service_charge}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updatePluginStatus = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/update-plugin-status/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateCompanyStatus = async (token, name, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/shipping-company/update-user-company-status/${name}/${id}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateZonesPermission = async (token, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/update-zone-status/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const updateBlogStatus = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `admin/blog/update-status/${data.id}`,
      data,
      {}
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const searchCarrierUsers = async (token, name, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/get-users/Carrier?name=${name}&&${page && `page=${page}`}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const searchShipperUsers = async (token, name, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/get-users/Shipper?name=${name}&&${page && `page=${page}`}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchAllNewsLetters = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/get-newsletters/all?page=${page}&perPage=${perPage}&name=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchAllBlogs = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/blog/get-all-blogs?perPage=${perPage}&page=${page}&name=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const searchNewsLetters = async (token, name, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/get-newsletters/all?name=${name}&&${page && `page=${page}`}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const searchCompaniesDetails = async (token, name, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/rating-setting/get-all?name=${name}&&${page && `page=${page}`}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const searchUserMessages = async (token, name, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/contact-us/get-all?name=${name}&&${page && `page=${page}`}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const createNewBlog = async (token, formData) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/blog/create`, formData);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateBlog = async (formData, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/blog/edit/${id}`, formData);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const deleteBlog = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/blog/delete/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const searchBlogs = async (token, name, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/blog/get-all-blogs?name=${name}&&${page && `page=${page}`}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const searchShippingCompanies = async (token, name, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/shipping-company/get?name=${name}&&${page && `page=${page}`}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchPendingPayments = async (token, page, param) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/payment/pending-payments?${page && `page=${page}`}${param ?? ""}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const searchPendingPayments = async (token, name, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/payment/pending-payments?name=${name}&&${page && `page=${page}`}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateServiceStatusByAdmin = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `admin/shipping-company/update-services/${id}`,
      data
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateServiceStatusForUser = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `admin/shipping-company/update-user-company-services/${id}`,
      data
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateUser = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/update-user-details/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchAllShipmentsAdmin = async (token, page, param) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/get-shipments/all?${page && `page=${page}`}${param ?? ""}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchHajexShipmentsAdmin = async (token, page, param) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/get-shipments/Hajex?${page && `page=${page}`}${param ?? ""}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchCustomShipmentsAdmin = async (token, page, param) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/get-shipments/Custom?${page && `page=${page}`}${param ?? ""}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const searchAllShipmentsAdmin = async (token, name, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/get-shipments/all?name=${name}&&${page && `page=${page}`}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const searchHajexShipmentsAdmin = async (token, name, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/get-shipments/Hajex?name=${name}&&${page && `page=${page}`}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const searchCustomShipmentsAdmin = async (token, name, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/get-shipments/Custom?name=${name}&&${page && `page=${page}`}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const searchCarrierShipmentsAdmin = async (token, name, page) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `get-all-shipments/carrier?name=${name}&&${page && `page=${page}`}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchCarrierShipmentsAdmin = async (token, page, param) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `get-all-shipments/carrier?${page && `page=${page}`}${param ?? ""}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchUserMessages = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/contact-us/get-all?perPage=${perPage}&page=${page}&name=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const updatePasswordByAdmin = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/change-user-password`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateInvoice = async (data, charge_id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `admin/shipment/charges/update/${charge_id}`,
      data
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateShipment = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`shipment/manage-invoice/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateAdminShipment = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/shipment/manage-invoice/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateAdminBillingNotes = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/shipment/manage-invoice/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const addCharge = async (data, charge_id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `admin/shipment/charges/add/${charge_id}`,
      data
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const deleteUserByAdmin = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`admin/delete-user/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const deleteAttachmentFile = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.delete(`admin/shipment/charges/delete-media/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const getUserPaymentFormula = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      id ? `admin/payment-formula/get/${id}` : `admin/payment-formula/get`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const createUserPaymentFormula = async (data, user_id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      user_id
        ? `admin/payment-formula/store/${user_id}`
        : "admin/payment-formula/store",
      data
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateUserPaymentFormula = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/payment-formula/update/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const deleteUserPaymentFormula = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.delete(`admin/payment-formula/delete/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
// transfer units
export const getTransferUnits = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `product_unit?perPage=${perPage}&page=${page}&filterValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const addTransferUnits = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/product-unit/create`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateTransferUnits = async (id, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/product-unit/update/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const deleteTransferUnits = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.delete(`admin/product-unit/delete/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
// currency tab
export const getCurrenciesList = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/warehouse/currency?perPage=${perPage}&page=${page}&filterValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const addCurrency = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/warehouse/currency/store`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateCurrency = async (id, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`admin/warehouse/currency/update/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const deleteCurrency = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.delete(`admin/warehouse/currency/delete/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
