import React from "react";
import "./Requirements.css";
const FedexRequirements = () => {
  return (
    <div className="cuscarrier__reqrmnts_box">
      Signup on
      <a
        href={`https://developer.fedex.com/api/en-us/home.html`}
        target="_blank"
        className="email_space"
      >
        https://developer.fedex.com/api/en-us/home.html
      </a>
      it will require your existing account with Fedex. And link a webservice
      endpoint with your account or you can simply contact Fedex support at
      1.877.339.2774 and ask them for web services account setup. Get your
      Client ID, Client Secret and Account Number. These API credentials will
      allow you to connect your custom Fedex account to the application,
      enabling you to access Fedex's shipping rates for your account and display
      them as quotes.
    </div>
  );
};

export default FedexRequirements;
