import React, { useEffect, useState } from "react";
import "./Sections.css";
import { MdErrorOutline } from "react-icons/md";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CustomDropdown from "../Components/CustomDropdown/CustomDropdown";
import { useSelector } from "react-redux";
import AsyncAutoComplete from "../../../../Components/AsyncAutoComplete/AsyncAutoComplete";
import { addBrand, addCategory } from "../../../../services/warehouse.services";
import { showSnackBar } from "../../../../store/common/snackBarSlice";
import { useDispatch } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const CategorySection = ({
  formData,
  setFormData,
  errors,
  productTypes,
  productBrands,
  setProductBrands,
  categories,
  setCategories,
  selectedCategory,
  setSelectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
  selectedType,
  setSelectedType,
  selectedBrand,
  setSelectedBrand,
  client = null,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const addNewOption = async (option) => {
    const Formdata = new FormData();
    Formdata.append("name", option.title);
    if (client) {
      Formdata.append("shipper_id", client);
    }

    const response = await addBrand(Formdata);
    if (!response.error) {
      const opt = response?.data?.result;
      setProductBrands((prevData) => {
        return [{ title: opt?.name, value: opt?.id }, ...prevData];
      });
      setFormData({
        ...formData,
        brand: { title: opt?.name, value: opt?.id },
      });
      dispatch(
        showSnackBar({
          text: "New Brand Added successfully",
          severity: "success",
        })
      );
    } else {
      dispatch(
        showSnackBar({
          text: "Couldn't add new Brand",
          severity: "error",
        })
      );
    }
  };
  const addNewCategory = async (option) => {
    const Formdata = new FormData();
    Formdata.append("name", option.title);
    if (client) {
      Formdata.append("shipper_id", client);
    }

    const response = await addCategory(Formdata);
    if (!response.error) {
      const opt = response?.data?.result;
      setCategories((prevData) => {
        return [{ title: opt?.name, value: opt?.id }, ...prevData];
      });
      setSelectedCategory({ title: opt?.name, value: opt?.id });
      dispatch(
        showSnackBar({
          text: "Category Added successfully",
          severity: "success",
        })
      );
    } else {
      dispatch(
        showSnackBar({
          text: "Couldn't add new Category",
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    setFormData({ ...formData, type: selectedType });
  }, [selectedType]);
  useEffect(() => {
    setFormData({ ...formData, category: selectedCategory });
  }, [selectedCategory]);
  useEffect(() => {
    setFormData({ ...formData, sub_category: selectedSubCategory });
  }, [selectedSubCategory]);
  useEffect(() => {
    setFormData({ ...formData, brand: selectedBrand });
  }, [selectedBrand]);
  const user = useSelector((state) => state.user);
  const handleChange = (event) => {
    // Since you're using a multi-select, event.target.value will be an array
    setFormData({
      ...formData,
      warehouse_ids:
        typeof event.target.value === "string"
          ? event.target.value.split(",")
          : event.target.value,
    });
  };

  return (
    <section
      className="addprod__fsidesection_wrap"
      id="addproduct_basic_category"
    >
      <div className="addprod__custominput_flexrow">
        <div className="addprod__custominput_flexcol">
          <div className="addprod__custominput_box">
            <label className="addprod__custominput_title">
              Product Category / Division
            </label>
            {/* <CustomDropdown
              optionObjects={categories}
              onSelect={(option) => {
                setSelectedCategory(option);
              }}
              selectedOption={selectedCategory}
            /> */}
            <AsyncAutoComplete
              options={categories}
              addNewOption={addNewCategory}
              value={selectedCategory}
              setValue={(option) => {
                setSelectedCategory(option);
              }}
            />
            {Object.keys(errors)?.includes("category") && (
              <div className="addprod__custominputfield_error">
                <MdErrorOutline className="addprod__custominputfield_erroricn" />
                {errors?.category?.msg}
              </div>
            )}
          </div>
          <div className="addprod__custominput_box">
            <label className="addprod__custominput_title">Product Brand</label>
            {/* <CustomDropdown
              optionObjects={productBrands}
              onSelect={(option) => {
                setFormData({
                  ...formData,
                  brand: option,
                });
              }}
              selectedOption={formData?.brand}
            /> */}
            <AsyncAutoComplete
              options={Array.isArray(productBrands) ? productBrands : []}
              addNewOption={addNewOption}
              value={selectedBrand}
              setValue={(option) => {
                setSelectedBrand(option);
              }}
            />
            {Object.keys(errors)?.includes("brand") && (
              <div className="addprod__custominputfield_error">
                <MdErrorOutline className="addprod__custominputfield_erroricn" />
                {errors?.brand?.msg}
              </div>
            )}
          </div>
          {/* <div className="addprod__custominput_box">
            <label className="addprod__custominput_title">Sub Category</label>
            <CustomDropdown
              optionObjects={[
                { id: 1, title: "Equipments" },
                { id: 2, title: "Household items" },
              ]}
              onSelect={(option) => {
                setSelectedSubCategory(option);
              }}
              selectedOption={selectedSubCategory}
            />
            {Object.keys(errors)?.includes("category") && (
              <div className="addprod__custominputfield_error">
                <MdErrorOutline className="addprod__custominputfield_erroricn" />
                {errors?.sub_category?.msg}
              </div>
            )}
          </div> */}
          {/* <div className="addprod__custominput_box">
            <label className="addprod__custominput_title ">Product Type</label>
            <CustomDropdown
              optionObjects={productTypes}
              onSelect={(option) => {
                setSelectedType(option);
              }}
              selectedOption={selectedType}
              disabled={true}
            />
            {Object.keys(errors)?.includes("type") && (
              <div className="addprod__custominputfield_error">
                <MdErrorOutline className="addprod__custominputfield_erroricn" />
                {errors?.type?.msg}
              </div>
            )}
          </div> */}
          {!client ? (
            <>
              <div className="addprod__custominput_box">
                <label className="addprod__custominput_title">
                  Select Warehouses
                </label>
                <FormControl
                  size="small"
                  sx={{ width: "100%", marginTop: "8px" }}
                >
                  <Select
                    multiple
                    value={formData?.warehouse_ids || []} // Ensure the value is always an array
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    // MenuProps={MenuProps} // Make sure this is defined correctly
                    renderValue={(selected) => selected.join(", ")} // Adjust how the selected items are displayed
                  >
                    {user?.user_approved_warehouses?.map((warehouse) => (
                      <MenuItem
                        key={warehouse?.warehouse_id} // Use the correct key property
                        value={warehouse?.warehouse_id} // Just the ID for the value
                        // style={getStyles(
                        //   warehouse?.warehouse_id,
                        //   formData?.warehouse_ids,
                        //   theme
                        // )}
                      >
                        {`#${warehouse?.warehouse?.id} ${warehouse?.warehouse?.name}`}{" "}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {Object.keys(errors)?.includes("warehouse_ids") && (
                  <div className="addprod__custominputfield_error">
                    <MdErrorOutline className="addprod__custominputfield_erroricn" />
                    {errors?.warehouse_ids?.msg}
                  </div>
                )}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default CategorySection;
