import React, { useState, useEffect } from "react";
import "./Unit.css";
// components imports
import UnitsTable from "../../../../../Components/BoltTable/BoltTable";
import EditUnits from "./Components/EditUnits/EditUnits";
import Tooltip from "../../../../../Components/Tooltip/Tooltip";
import Confirm from "../../../../../Components/Popups/Confirm";

// apis imports
import {
  getTransferUnits,
  deleteTransferUnits,
} from "../../../../../services/admin.services";

// library imports
import { FcPlus } from "react-icons/fc";
import AddUnits from "./Components/AddUnits/AddUnits";
import { MdOutlineCancel } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";

const UnitStock = ({ setSnack }) => {
  const [transferUnits, setTransferUnits] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [refresh, setRefresh] = useState();

  const handleOpenAddUnitModal = () => {
    setOpen(true);
  };

  const columns = [
    {
      id: 1,
      label: "Code",
      render: (row) => <>{row?.code ?? "-"}</>,
      width: "200px",
    },
    {
      id: 2,
      label: "Name",
      render: (row) => <>{row?.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Action",
      render: (row) => (
        <>
          <div className="table_action__wrap">
            <Tooltip title="Edit User Details">
              <div
                className="table_action__btn"
                onClick={() => {
                  setEditOpen(row);
                }}
              >
                <FaRegEdit size={10} />
                Edit
              </div>
            </Tooltip>

            <Tooltip title="Delete User">
              <div
                className="table_action__btn"
                onClick={() => setConfirmDelete(row)}
              >
                <MdOutlineCancel size={10} />
                Delete
              </div>
            </Tooltip>
          </div>
        </>
      ),

      width: "200px",
    },
  ];

  const fetchUnits = async (page, perPage, filter) => {
    const response = await getTransferUnits(page, perPage, filter);

    if (!response.error) {
      setTransferUnits(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const handleDeleteRequest = async (data) => {
    if (data.id) {
      setLoader(true);
      const response = await deleteTransferUnits(data.id);
      setConfirmDelete(false);
      if (!response.error && response?.data?.success) {
        setTransferUnits((prevTransferUnits) => {
          return {
            ...prevTransferUnits,
            data: prevTransferUnits?.data?.filter((d) => d?.id != data?.id),
          };
        });
        setLoader(false);
        setConfirmDelete(false);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Transfer unit deleted successfully",
            severity: "success",
          };
        });
      } else {
        setLoader(false);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Couldn't cancel this request!",
            severity: "error",
          };
        });
      }
    }
  };

  return (
    <>
      <div className="units_header">
        <div>
          <span className="units_hdrheading">Units List</span>
        </div>
        <div className="units_buttonsWrapper">
          <div onClick={handleOpenAddUnitModal}>
            <div className="units_hdraddprod">
              <FcPlus size={20} />
              <span>ADD UNIT</span>
            </div>
          </div>
        </div>
      </div>
      <Confirm
        open={confirmDelete}
        setOpen={setConfirmDelete}
        handleConfirm={() => handleDeleteRequest(confirmDelete)}
        text="Do You Want To Delete This Unit ?"
        loader={loader}
      />
      <div className="unitStock__main_wrap">
        <div className="unitStock__table_list">
          <div>
            <UnitsTable
              message={"No Units Available To Display!"}
              searchPlaceHolder="Search Units"
              columns={columns}
              data={transferUnits}
              getAllData={fetchUnits}
              refresh={refresh}
            />
          </div>
        </div>
        <AddUnits
          setOpen={setOpen}
          open={open}
          setSnack={setSnack}
          setRefresh={setRefresh}
        />
        <EditUnits
          setOpen={setEditOpen}
          open={openEdit}
          setSnack={setSnack}
          setTransferUnits={setTransferUnits}
        />
      </div>
    </>
  );
};

export default UnitStock;
