import React from "react";
import "../Customer.css";

// components imports
import Snackbar from "../MainPages/Components/snackbar";
import { updateUser } from "../../../../store/userSlice";
import { updateProfileData } from "../../../../services/settings.services";
import Loader from "../../../../Components/CircularLoader/CircularLoader";
// apis imports
import Countries from "../../../../data/countries";
// library imports
import { Button, Container } from "react-bootstrap";
import Avatar from "react-avatar";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { BsPencil } from "react-icons/bs";
import { useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";

const Profile = () => {
  let navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [skeletonLoading, setSkeletonLoading] = useState(false);

  const [changed2, setchanged2] = useState(false);
  const [changed5, setchanged5] = useState(false);
  const [changed3, setchanged3] = useState(false);
  const [changed4, setchanged4] = useState(false);

  const [Profile, setProfile] = useState({});
  const [Profile2, setProfile2] = useState({});
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });
  const [image, setimage] = useState(false);
  const [file, setfile] = useState(null);
  const [old_password, setold_password] = useState("");
  const [new_password, setnew_password] = useState("");
  const [con_password, setcon_password] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    // setProfile(user);
    const getProfile = async () => {
      setSkeletonLoading(true);
      try {
        const response = await axios({
          method: "Get",
          url: `${process.env.REACT_APP_API_URL}get/profile`,
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("Token"),
          },
        });

        if (response.data.success === true) {
          setSkeletonLoading(false);
          setProfile(response.data.result);
          setProfile2(response.data.result);
        }
      } catch (error) {
        setSkeletonLoading(false);
        setSnack({
          ...snack,
          open: true,
          text: error.response.data.message,
          severity: "error",
          vertical: "top",
          horizontal: "right",
        });
      }
    };
    getProfile();
  }, []);

  useEffect(() => {
    // section 2
    let hasChanges2 = false;
    if (
      Profile2?.firstname != Profile.firstname ||
      Profile2?.lastname != Profile.lastname ||
      Profile2?.business_name != Profile.business_name ||
      Profile2?.country != Profile.country ||
      Profile2?.city != Profile.city ||
      Profile2?.address != Profile.address ||
      Profile2?.industry_type != Profile?.industry_type ||
      Profile2?.experience != Profile.experience ||
      Profile2?.province != Profile.province ||
      Profile2?.zip != Profile.zip ||
      Profile2?.phone_number != Profile.phone_number
    ) {
      hasChanges2 = true;
    }
    setchanged2(hasChanges2);

    let hasChanges5 = false;
    if (
      // warehouse
      Profile2?.warehouse?.name != Profile?.warehouse?.name ||
      Profile2?.warehouse?.country != Profile?.warehouse?.country ||
      Profile2?.warehouse?.state != Profile?.warehouse?.state ||
      Profile2?.warehouse?.city != Profile?.warehouse?.city ||
      Profile2?.warehouse?.address != Profile?.warehouse?.address ||
      Profile2?.warehouse?.postcode != Profile?.warehouse?.postcode
    ) {
      hasChanges5 = true;
    }
    setchanged5(hasChanges5);

    // section 3
    let hasChanges3 = false;

    if (old_password != "" || new_password != "" || con_password != "") {
      hasChanges3 = true;
    } else {
      hasChanges3 = false;
    }
    setchanged3(hasChanges3);

    // section 1.1

    let hasChanges4 = false;
    if (file != null) {
      hasChanges4 = true;
    }
    setchanged4(hasChanges4);
  }, [Profile, user, old_password, new_password, con_password, file]);
  const handleprofileimg = (e) => {
    setfile(e.target.files[0]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setProfile({ ...Profile, [name]: value });
  };
  const handleChangeForWarehouse = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      warehouse: {
        ...prevProfile.warehouse,
        [name]: value,
      },
    }));
  };

  const handlenewpassword = (e) => {
    setnew_password(e.target.value);
  };

  const handleconpassword = (e) => {
    setcon_password(e.target.value);
  };

  const handleoldpassword = (e) => {
    setold_password(e.target.value);
  };

  const [showEditContent, setShowEditContent] = useState(false);

  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [showAdditionalWarehouseInfo, setShowAdditionalWarehouseInfo] =
    useState(false);

  const additionalInfoListClick = () => {
    setShowAdditionalInfo(!showAdditionalInfo);
  };
  const additionalWarehouseInfoListClick = () => {
    setShowAdditionalWarehouseInfo(!showAdditionalWarehouseInfo);
  };

  const [showPasswordFields, setShowPasswordFields] = useState(false);

  const handlePasswordEdit = () => {
    setShowPasswordFields(!showPasswordFields);
  };

  const handlepassword = async (event) => {
    event.preventDefault();
    try {
      setLoading("updatepassword");

      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}change/password`,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("Token"),
        },
        data: {
          old_password: old_password,
          new_password: new_password,
        },
      });
      if (response.data.success === true) {
        setold_password("");
        setcon_password("");
        setnew_password("");
        setSnack({
          ...snack,
          open: true,
          text: response.data.message,
          severity: "success",
          vertical: "top",
          horizontal: "right",
        });
        setLoading(false);
      } else {
        setSnack({
          ...snack,
          open: true,
          text: response.data.message,
          severity: "error",
          vertical: "top",
          horizontal: "right",
        });
        setLoading(false);
      }
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        text: error.response.data.message,
        severity: "error",
        vertical: "top",
        horizontal: "right",
      });
      setLoading(false);
    }
    setShowPasswordFields(!showPasswordFields);
  };

  const handleadditionalinfo = async (event) => {
    event.preventDefault();
    try {
      setLoading("additionalInfo");

      const response = await updateProfileData({
        firstname: Profile["firstname"],
        lastname: Profile["lastname"],
        business_name: Profile["business_name"],
        industry_type: Profile["industry_type"],
        experience: Profile["experience"],
        phone_number: Profile["phone_number"],
        address: Profile["address"],
        country: Profile["country"],
        province: Profile["province"],
        city: Profile["city"],
        zip: Profile["zip"],
      });
      if (response.data.success === true) {
        setProfile(response.data.result);
        setProfile2(response.data.result);
        setSnack({
          ...snack,
          open: true,
          text: response.data.message,
          severity: "success",
          vertical: "top",
          horizontal: "right",
        });
        setLoading(false);
      } else {
        setSnack({
          ...snack,
          open: true,
          text: response.data.message,
          severity: "error",
          vertical: "top",
          horizontal: "right",
        });
        setLoading(false);
      }
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        text: error.response.data.message,
        severity: "error",
        vertical: "top",
        horizontal: "right",
      });
      setLoading(false);
    }
    setShowAdditionalInfo(!showAdditionalInfo);
  };
  const handleWarehouseInfo = async (event) => {
    event.preventDefault();
    try {
      setLoading("warehouseInfo");

      const response = await updateProfileData({
        warehouse: {
          name: Profile?.warehouse["name"],
          country: Profile?.warehouse["country"],
          state: Profile?.warehouse["state"],
          city: Profile?.warehouse["city"],
          postcode: Profile?.warehouse["postcode"],
          address: Profile?.warehouse["address"],
        },
      });
      if (response.data.success === true) {
        setProfile(response.data.result);
        setProfile2(response.data.result);
        setSnack({
          ...snack,
          open: true,
          text: response.data.message,
          severity: "success",
          vertical: "top",
          horizontal: "right",
        });
        setLoading(false);
      } else {
        setSnack({
          ...snack,
          open: true,
          text: response.data.message,
          severity: "error",
          vertical: "top",
          horizontal: "right",
        });
        setLoading(false);
      }
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        text: error.response.data.message,
        severity: "error",
        vertical: "top",
        horizontal: "right",
      });
      setLoading(false);
    }
    setShowAdditionalWarehouseInfo(!showAdditionalWarehouseInfo);
  };

  const handleimageupdate = async (event) => {
    event.preventDefault();

    setLoading("image");
    const formData = new FormData();
    formData.append("profile_image", file);
    try {
      const response = await updateProfileData(formData);
      if (response.data.success === true) {
        setProfile(response.data.result);
        setProfile2(response.data.result);
        localStorage.setItem(
          "profile_image",
          response.data.result.profile_image
        );
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...user,
            profile_image: response.data.result.profile_image,
          })
        );
        dispatch(
          updateUser({
            ...user,
            profile_image: response.data.result.profile_image,
          })
        );
        setimage(false);
        setSnack({
          ...snack,
          open: true,
          text: response.data.message,
          severity: "success",
          vertical: "top",
          horizontal: "right",
        });
        setLoading(false);
        setfile(null);
      } else {
        setSnack({
          ...snack,
          open: true,
          text: response.data.message,
          severity: "error",
          vertical: "top",
          horizontal: "right",
        });
        setLoading(false);
      }
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        text: error.response.data.message,
        severity: "error",
        vertical: "top",
        horizontal: "right",
      });
    }
  };

  return (
    <>
      <Container>
        <Snackbar Snack={snack} SetSnack={setSnack} />

        <div className="bg-white shadow  profile__main_wrap">
          {skeletonLoading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={210}
              style={{ marginTop: "40px" }}
            />
          ) : (
            <>
              <div className="profile__edit_content_wrap">
                <div className="profile__image_block">
                  <Avatar
                    name={
                      Profile
                        ? Profile["firstname"] + " " + Profile["lastname"]
                        : ""
                    }
                    size="150"
                    round="50%"
                    src={Profile ? Profile["profile_image"] : ""}
                    color="#0C6EFD"
                    className="shadow"
                  />
                  <Button
                    variant="light"
                    className="image__icon_upload"
                    onClick={() => {
                      setimage(true);
                    }}
                  >
                    <BsPencil />
                  </Button>
                </div>

                <div className="profile__data_edit">
                  <h3>Profile Information</h3>
                  <small>
                    Update your account`s public information and email address.
                  </small>

                  <div className="form__profile_field_wrap">
                    <div className="fieldset__list_col">
                      <Form.Label>Account Type</Form.Label>

                      <div className="label_background">
                        {Profile ? Profile["role"] : ""}
                      </div>
                    </div>
                    <div className="fieldset__list_col">
                      <Form.Label>UserName</Form.Label>
                      <div className="label_background">
                        {Profile ? Profile["username"] : ""}
                      </div>
                    </div>
                    <div className="fieldset__list_col">
                      <Form.Label>Email</Form.Label>
                      <div className="label_background">
                        {Profile ? Profile["email"] : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {skeletonLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={210}
            style={{ marginTop: "40px" }}
          />
        ) : (
          <div className="additional_info_wrap bg-white shadow">
            <h3>Additional Information</h3>
            <small>
              Update your account`s private information.
              {showAdditionalInfo ? (
                ""
              ) : (
                <div
                  className="additional_info_list_edit_btn"
                  onClick={additionalInfoListClick}
                >
                  <BsPencil />
                </div>
              )}
            </small>

            {showAdditionalInfo ? (
              <div className="additional_info_list_edit">
                <Form onSubmit={handleadditionalinfo}>
                  <div>
                    <div className="additional_info_list_edit_form">
                      <div className="field__main_additional_info">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          value={Profile ? Profile["firstname"] : ""}
                          name="firstname"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="field__main_additional_info">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          value={Profile ? Profile["lastname"] : ""}
                          name="lastname"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="field__main_additional_info">
                        <Form.Label>Bussiness Name</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          value={Profile ? Profile["business_name"] : ""}
                          name="business_name"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className="additional_info_list_edit_form">
                      <div className="field__main_additional_info address_feild">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          value={Profile ? Profile["address"] : ""}
                          name="address"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className="additional_info_list_edit_form">
                      <div className="field__main_additional_info">
                        <Form.Label>Country</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          value={Profile["country"]}
                          name="country"
                          onChange={(e) => handleChange(e)}
                        >
                          {Countries.map((c) => {
                            return (
                              <option value={c?.code} key={c?.code}>
                                {c?.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </div>
                      <div className="field__main_additional_info">
                        <Form.Label>Province</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          value={Profile ? Profile["province"] : ""}
                          name="province"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>

                      <div className="field__main_additional_info">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          value={Profile ? Profile["city"] : ""}
                          name="city"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="field__main_additional_info">
                        <Form.Label>Zip</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          value={Profile ? Profile["zip"] : ""}
                          name="zip"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className="additional_info_list_edit_form">
                      {Profile["role"] === "Carrier" ? (
                        <div className="field__main_additional_info">
                          <Form.Label>Industry Experience</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            value={Profile ? Profile["experience"] : ""}
                            name="experience"
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      ) : (
                        <div className="field__main_additional_info">
                          <Form.Label>Industry Type</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            value={Profile ? Profile["industry_type"] : ""}
                            name="industry_type"
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      )}

                      <div className="field__main_additional_info">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="phone"
                          required
                          value={Profile ? Profile["phone_number"] : ""}
                          name="phone_number"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="field__main_additional_info_footer_submit">
                    <Button
                      className="btn__cancle_wrap "
                      onClick={() => {
                        additionalInfoListClick();
                        setProfile({ ...Profile2 });
                      }}
                    >
                      Back
                    </Button>

                    {changed2 && (
                      <Button
                        disabled={loading}
                        id="additional"
                        type="submit"
                        className="profile_buttons"
                      >
                        {loading === "additionalInfo" ? <Loader /> : "Save"}
                      </Button>
                    )}
                  </div>
                </Form>
              </div>
            ) : (
              <div className="">
                <div className="additonal_info_list_view">
                  <div className="additonal_info_list_item">
                    <div className="additonal_info_list_item_label">
                      First Name
                    </div>
                    <div className="additonal_info_list_item_data">
                      {Profile["firstname"] ? Profile["firstname"] : "-"}
                    </div>
                  </div>
                  <div className="additonal_info_list_item">
                    <div className="additonal_info_list_item_label">
                      Last Name
                    </div>
                    <div className="additonal_info_list_item_data">
                      {Profile["lastname"] ? Profile["lastname"] : "-"}
                    </div>
                  </div>
                  <div className="additonal_info_list_item">
                    <div className="additonal_info_list_item_label">
                      Bussiness Name
                    </div>
                    <div className="additonal_info_list_item_data">
                      {Profile["business_name"]
                        ? Profile["business_name"]
                        : "-"}
                    </div>
                  </div>
                </div>
                <div className="additonal_info_list_view">
                  <div className="additonal_info_list_item additonal_info_list_item_address">
                    <div className="additonal_info_list_item_label">
                      Address
                    </div>
                    <div className="additonal_info_list_item_data">
                      {Profile["address"] ? Profile["address"] : " - "}
                    </div>
                  </div>
                </div>
                <div className="additonal_info_list_view">
                  <div className="additonal_info_list_item">
                    <div className="additonal_info_list_item_label">
                      Country
                    </div>
                    <div className="additonal_info_list_item_data">
                      {Profile["country"]
                        ? Countries?.find((c) => c.code === Profile["country"])
                            ?.name
                        : "-"}
                    </div>
                  </div>
                  <div className="additonal_info_list_item">
                    <div className="additonal_info_list_item_label">
                      Province
                    </div>
                    <div className="additonal_info_list_item_data">
                      {Profile["province"] ? Profile["province"] : " - "}
                    </div>
                  </div>
                  <div className="additonal_info_list_item">
                    <div className="additonal_info_list_item_label">City</div>
                    <div className="additonal_info_list_item_data">
                      {Profile["city"] ? Profile["city"] : " - "}
                    </div>
                  </div>
                  <div className="additonal_info_list_item">
                    <div className="additonal_info_list_item_label">Zip</div>
                    <div className="additonal_info_list_item_data">
                      {Profile["zip"] ? Profile["zip"] : " - "}
                    </div>
                  </div>
                </div>
                <div className="additonal_info_list_view">
                  {Profile["role"] === "Carrier" ? (
                    <div className="additonal_info_list_item">
                      <div
                        className="additonal_info_list_item_label"
                        style={{ maxWidth: "160px" }}
                      >
                        Industry Experience
                      </div>
                      <div className="additonal_info_list_item_data">
                        {Profile["experience"] ? Profile["experience"] : " - "}
                      </div>
                    </div>
                  ) : (
                    <div className="additonal_info_list_item">
                      <div className="additonal_info_list_item_label">
                        Industry Type
                      </div>
                      <div className="additonal_info_list_item_data">
                        {Profile["industry_type"]
                          ? Profile["industry_type"]
                          : " - "}
                      </div>
                    </div>
                  )}

                  <div className="additonal_info_list_item">
                    <div className="additonal_info_list_item_label">
                      Phone Number
                    </div>
                    <div className="additonal_info_list_item_data">
                      {Profile["phone_number"]
                        ? Profile["phone_number"]
                        : " - "}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {Profile?.role === "warehouseUser" && (
          <>
            {skeletonLoading ? (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={210}
                style={{ marginTop: "40px" }}
              />
            ) : (
              <div className="additional_info_wrap bg-white shadow">
                <h3>Warehouse Information</h3>
                <small>
                  Update your account`s private information.
                  {showAdditionalWarehouseInfo ? (
                    ""
                  ) : (
                    <div
                      className="additional_info_list_edit_btn"
                      onClick={additionalWarehouseInfoListClick}
                    >
                      <BsPencil />
                    </div>
                  )}
                </small>

                {showAdditionalWarehouseInfo ? (
                  <div className="additional_info_list_edit">
                    <Form onSubmit={handleWarehouseInfo}>
                      <div className="additional_info_list_edit_form">
                        <div className="field__main_additional_info">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            value={Profile ? Profile?.warehouse["name"] : ""}
                            name="name"
                            onChange={(e) => handleChangeForWarehouse(e)}
                          />
                        </div>
                      </div>
                      <div className="additional_info_list_edit_form">
                        <div className="field__main_additional_info address_feild">
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            value={Profile ? Profile?.warehouse["address"] : ""}
                            name="address"
                            onChange={(e) => handleChangeForWarehouse(e)}
                          />
                        </div>
                      </div>
                      <div className="additional_info_list_edit_form">
                        <div className="field__main_additional_info">
                          <Form.Label>Country</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            value={Profile?.warehouse["country"]}
                            name="country"
                            onChange={(e) => handleChangeForWarehouse(e)}
                          >
                            {Countries.map((c) => {
                              return (
                                <option value={c?.code} key={c?.code}>
                                  {c?.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </div>
                        <div className="field__main_additional_info">
                          <Form.Label>Province</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            value={Profile ? Profile?.warehouse["state"] : ""}
                            name="state"
                            onChange={(e) => handleChangeForWarehouse(e)}
                          />
                        </div>
                        <div className="field__main_additional_info">
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            value={Profile ? Profile?.warehouse["city"] : ""}
                            name="city"
                            onChange={(e) => handleChangeForWarehouse(e)}
                          />
                        </div>

                        <div className="field__main_additional_info">
                          <Form.Label>Zip</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            value={
                              Profile ? Profile?.warehouse["postcode"] : ""
                            }
                            name="postcode"
                            onChange={(e) => handleChangeForWarehouse(e)}
                          />
                        </div>
                      </div>

                      <div className="field__main_additional_info_footer_submit">
                        <Button
                          className="btn__cancle_wrap "
                          onClick={() => {
                            additionalWarehouseInfoListClick();
                            setProfile({ ...Profile2 });
                          }}
                        >
                          Back
                        </Button>

                        {changed5 && (
                          <Button
                            disabled={loading}
                            id="additional"
                            type="submit"
                            className="profile_buttons"
                          >
                            {loading === "warehouseInfo" ? <Loader /> : "Save"}
                          </Button>
                        )}
                      </div>
                    </Form>
                  </div>
                ) : (
                  <div className="">
                    <div className="additonal_info_list_view">
                      <div className="additonal_info_list_item">
                        <div className="additonal_info_list_item_label">
                          Code
                        </div>
                        <div className="additonal_info_list_item_data">
                          {Profile?.warehouse["code"]
                            ? Profile?.warehouse["code"]
                            : "-"}
                        </div>
                      </div>
                      <div className="additonal_info_list_item">
                        <div className="additonal_info_list_item_label">
                          Name
                        </div>
                        <div className="additonal_info_list_item_data">
                          {Profile?.warehouse["name"]
                            ? Profile?.warehouse["name"]
                            : "-"}
                        </div>
                      </div>
                    </div>

                    <div className="additonal_info_list_view">
                      <div className="additonal_info_list_item additonal_info_list_item_address">
                        <div className="additonal_info_list_item_label">
                          Address
                        </div>
                        <div className="additonal_info_list_item_data">
                          {Profile?.warehouse["address"]
                            ? Profile?.warehouse["address"]
                            : "-"}
                        </div>
                      </div>
                    </div>

                    <div className="additonal_info_list_view">
                      <div className="additonal_info_list_item">
                        <div className="additonal_info_list_item_label">
                          Country
                        </div>
                        <div className="additonal_info_list_item_data">
                          {Profile?.warehouse["country"]
                            ? Countries?.find(
                                (c) => c.code === Profile?.warehouse["country"]
                              )?.name
                            : "-"}
                        </div>
                      </div>
                      <div className="additonal_info_list_item">
                        <div className="additonal_info_list_item_label">
                          Province
                        </div>
                        <div className="additonal_info_list_item_data">
                          {Profile?.warehouse["state"]
                            ? Profile?.warehouse["state"]
                            : "-"}
                        </div>
                      </div>
                      <div className="additonal_info_list_item">
                        <div className="additonal_info_list_item_label">
                          City
                        </div>
                        <div className="additonal_info_list_item_data">
                          {Profile?.warehouse["city"]
                            ? Profile?.warehouse["city"]
                            : "-"}
                        </div>
                      </div>
                      <div className="additonal_info_list_item">
                        <div className="additonal_info_list_item_label">
                          Zip
                        </div>
                        <div className="additonal_info_list_item_data">
                          {Profile?.warehouse["postcode"]
                            ? Profile?.warehouse["postcode"]
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}

        {skeletonLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={210}
            style={{ marginTop: "40px" }}
          />
        ) : (
          <div className="bg-white shadow password__sec_wrap">
            <h3>Update Password</h3>
            <small>
              Ensure your account is using a long, random password to stay
              secure.
              {showPasswordFields ? (
                ""
              ) : (
                <div className="password_editable" onClick={handlePasswordEdit}>
                  <BsPencil />
                </div>
              )}
            </small>

            {showPasswordFields ? (
              <Form className="passwor__edit_wrap" onSubmit={handlepassword}>
                <div
                  className="additonal_info_list_item"
                  style={{ flexDirection: "column" }}
                >
                  <Form.Label>Current Password</Form.Label>
                  <Form.Control
                    type="password"
                    required
                    value={old_password}
                    name="old_password"
                    onChange={(e) => handleoldpassword(e)}
                  />
                </div>

                <div
                  className="additonal_info_list_item"
                  style={{ flexDirection: "column" }}
                >
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    required
                    value={new_password}
                    name="new_password"
                    onChange={(e) => handlenewpassword(e)}
                  />
                </div>
                <div
                  className="additonal_info_list_item"
                  style={{ flexDirection: "column" }}
                >
                  {/* <div> */}
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={con_password}
                    name="con_password"
                    onChange={(e) => handleconpassword(e)}
                    isInvalid={
                      con_password != "" && con_password != new_password
                        ? true
                        : false
                    }
                  />
                  {/* </div> */}
                  {con_password != "" && con_password != new_password && (
                    <small style={{ color: "red" }}>
                      Password does not match
                    </small>
                  )}
                </div>
                <div className="additonal_info_list_item">
                  <Button
                    className="btn__cancle_wrap"
                    onClick={() => {
                      handlePasswordEdit();
                      setcon_password("");
                      setnew_password("");
                      setold_password("");
                    }}
                  >
                    Back
                  </Button>

                  {changed3 && (
                    <>
                      <Button
                        id="additional"
                        type="submit"
                        disabled={con_password != new_password || loading}
                        className="profile_buttons"
                      >
                        {loading === "updatepassword" ? <Loader /> : "Save"}
                      </Button>
                    </>
                  )}
                </div>
              </Form>
            ) : (
              <div className="password__display_text">
                <div className="additonal_info_list_item_label">Password</div>
                <div className="additonal_info_list_item_data">
                  ***************
                </div>
              </div>
            )}
          </div>
        )}
      </Container>
      <Modal
        show={image}
        onHide={() => {
          setimage(false);
          setfile(null);
        }}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile Image</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body className="m-2">
            <Avatar
              name={
                Profile ? Profile["firstname"] + " " + Profile["lastname"] : ""
              }
              size="150"
              round="50%"
              src={
                file ? URL.createObjectURL(file) : `${Profile["profile_image"]}`
              }
              color="#0C6EFD"
              className="shadow"
            />
            <Form.Group controlId="formFile" className="mb-3 mt-3 col-6">
              <Form.Label>Upload New Profile Image</Form.Label>
              <Form.Control type="file" size="sm" onChange={handleprofileimg} />
            </Form.Group>
            {changed4 && (
              <Button
                disabled={loading}
                className="mb-3 col-3 profile_buttons"
                onClick={handleimageupdate}
                style={{ minWidth: "70px" }}
              >
                {loading === "image" ? <Loader /> : "Save"}
              </Button>
            )}
          </Modal.Body>
        </form>
      </Modal>
    </>
  );
};

export default Profile;
