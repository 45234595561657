import React from "react";
import "./Requirements.css";

const DHLRequirements = () => {
  return (
    <div className="cuscarrier__reqrmnts_box">
      Contact DHL Transport
       {/* at
      <a href={`mailto:DHL@DHL.com`} className="email_space">
        DHL@DHL.com
      </a> */}
      to obtain your User Name , Password , Account Number , Bearer Token. These
      API credentials will allow you to connect your custom DHL Transport
      account to the application, enabling you to access DHL Transport shipping
      rates for your account and display them as quotes.
    </div>
  );
};

export default DHLRequirements;
