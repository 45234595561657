import React, { useEffect } from "react";
import "./Sections.css";
import { MdAddCircleOutline, MdErrorOutline } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import { generateUniqueId } from "../../../../helpers/helpers";

const InfoSection = ({
  formData,
  setFormData,
  errors,
  additionalFields,
  setAdditionalFields,
  editMode,
}) => {
  const handleFieldChange = (name, e) => {
    setFormData({ ...formData, [name]: e.target.value });
  };
  const handleRemoveField = (index) => {
    if (additionalFields[index]?.created_at) {
      setFormData({
        ...formData,
        deletedAdditionalInformationIds: [
          ...formData?.deletedAdditionalInformationIds,
          additionalFields[index]?.id,
        ],
      });
    }
    setAdditionalFields((fields) => {
      const updatedFields = [...fields];
      updatedFields.splice(index, 1);
      return updatedFields;
    });
  };
  const handleAdditionalFieldChange = (name, event, index) => {
    setAdditionalFields(
      additionalFields.map((field, idx) => {
        if (idx === index) {
          return {
            ...field,
            [name]: event.target.value,
          };
        } else {
          return field;
        }
      })
    );
  };
  useEffect(() => {
    setFormData({ ...formData, additional_information: additionalFields });
  }, [additionalFields]);
  return (
    <section className="addprod__formsection_wrap" id="addproduct_basic_info">
      <div className="addprod__custominput_flexrow">
        <div
          className="addprod__custominput_flexcol"
          style={{ minWidth: "100px" }}
        >
          <div className="addprod__custominput_box">
            <label className="addprod__custominput_title">Title</label>
            <input
              className="addprod__custominputfield"
              type="text"
              placeholder="Enter product's title"
              onChange={(e) => handleFieldChange("title", e)}
              value={formData?.title}
            />
            {Object.keys(errors)?.includes("title") && (
              <div className="addprod__custominputfield_error">
                <MdErrorOutline className="addprod__custominputfield_erroricn" />
                {errors?.title?.msg}
              </div>
            )}
          </div>
        </div>
        <div
          className="addprod__custominput_flexcol"
          style={{ minWidth: "100px" }}
        >
          <div className="addprod__custominput_box">
            <label className="addprod__custominput_title">Sku</label>
            <input
              className="addprod__custominputfield"
              type="text"
              placeholder="Enter product's sku"
              onChange={(e) => handleFieldChange("sku", e)}
              value={formData?.sku}
            />
            {Object.keys(errors)?.includes("sku") && (
              <div className="addprod__custominputfield_error">
                <MdErrorOutline className="addprod__custominputfield_erroricn" />
                {errors?.sku?.msg}
              </div>
            )}
          </div>
        </div>
        <div
          className={`addprod__custominput_flexcol ${
            editMode ? "addprod__custominput_flexcoldisabled" : ""
          }`}
          style={{ minWidth: "100px" }}
        >
          <div className="addprod__custominput_box">
            <label className="addprod__custominput_title">Product Id</label>
            <input
              className="addprod__custominputfield"
              type="text"
              placeholder="Enter product's unique id"
              onChange={(e) => handleFieldChange("code", e)}
              value={formData?.code}
            />
            {Object.keys(errors)?.includes("code") && (
              <div className="addprod__custominputfield_error">
                <MdErrorOutline className="addprod__custominputfield_erroricn" />
                {errors?.code?.msg}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="addprod__custominput_box">
        <label className="addprod__custominput_title">Description</label>
        <textarea
          className=" addprod__custominputtextarea"
          rows="5"
          onChange={(e) => handleFieldChange("description", e)}
          value={formData?.description}
        ></textarea>
      </div>
      {additionalFields?.map((field, index) => (
        <div key={index} className="addprod__custominput_flexrow">
          <div className="addprod__custominput_flexcol">
            <div className="addprod__custominput_box">
              <label className="addprod__custominput_title">Field Name</label>
              <input
                className="addprod__custominputfield"
                type="text"
                placeholder="Enter Additinal Field Name"
                onChange={(e) => handleAdditionalFieldChange("label", e, index)}
                value={field.label}
              />
              {Object.keys(errors)?.includes("label") && (
                <div className="addprod__custominputfield_error">
                  <MdErrorOutline className="addprod__custominputfield_erroricn" />
                  {errors?.label?.msg}
                </div>
              )}
            </div>
          </div>
          <div className="addprod__custominput_flexcol">
            <div className="addprod__custominput_box">
              <label className="addprod__custominput_title">Value</label>
              <input
                className="addprod__custominputfield"
                type="text"
                placeholder="Enter Value"
                onChange={(e) => handleAdditionalFieldChange("value", e, index)}
                value={field.value}
              />
              {Object.keys(errors)?.includes("value") && (
                <div className="addprod__custominputfield_error">
                  <MdErrorOutline className="addprod__custominputfield_erroricn" />
                  {errors?.value?.msg}
                </div>
              )}
            </div>
          </div>

          <FaRegTrashAlt
            size={13}
            className="addprod__idensec_delrowicn"
            onClick={(e) => {
              handleRemoveField(index);
            }}
          />
        </div>
      ))}
      <button
        className="addprod__formsection_addvarbtn"
        onClick={() =>
          setAdditionalFields([
            ...additionalFields,
            { id: generateUniqueId(), label: "", value: "", type: "text" },
          ])
        }
      >
        <MdAddCircleOutline
          className="addprod__formsection_addvarbtnicn"
          size={14}
        />
        Additional Information
      </button>
    </section>
  );
};

export default InfoSection;
