import React, { useState } from "react";
import "./paymentlist.css";

// components imports
import ShipperPaymentsTable from "../../../../../Components/BoltTable/BoltTable";
import AddBalance from "../AddBalance/AddBalance";
import PaymentsHistory from "../PaymentsHistory/PaymentsHistory";
import Tooltip from "../../../../../Components/Tooltip/Tooltip";

// apis imports
import { fetchAllShippers } from "../../../../../services/AdminServices/PaymentsAdmin/payments.services";

// library imports
import { MdOutlineHistory } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";

const PaymentList = ({ setSnack, snack }) => {
  const [payments, setPayments] = useState([]);

  const [addBalanceModal, setAddBalanceModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);

  const getShippersPayments = async (page, perPage, filter) => {
    const response = await fetchAllShippers(page, perPage, filter);
    if (!response.error) {
      setPayments(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 1,
      label: "User Type",
      render: (row) => <>{row?.role ?? "-"}</>,
      width: "200px",
    },
    {
      id: 2,
      label: "UserName",
      render: (row) => <>{row?.username ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Bussiness Name",
      render: (row) => <>{row?.business_name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "Email",
      render: (row) => <>{row?.email ?? "-"}</>,
      width: "200px",
    },
    {
      id: 5,
      label: "Balance",
      render: (row) => <>{row?.balance ?? "-"}</>,
      width: "200px",
    },
    {
      id: 6,
      label: "Actions",
      render: (row) => (
        <div className="table_action__wrap">
          <Tooltip title="Add User Balance">
            <div
              className="table_action__btn"
              onClick={() => setAddBalanceModal(row)}
            >
              <FaRegEdit size={10} />
              Add Balance
            </div>
          </Tooltip>
          <Tooltip title="View User Transaction History">
            <div
              className="table_action__btn"
              onClick={() => setHistoryModal(row)}
            >
              <MdOutlineHistory size={10} />
              Transactions
            </div>
          </Tooltip>
        </div>
      ),
      width: "200px",
      fixed: "right",
    },
  ];

  return (
    <div>
      <div>
        <div className="paymentlist_header">
          <div>
            <span className="paymentlist_heading">Payments List</span>
          </div>
        </div>
        <AddBalance
          open={addBalanceModal}
          setOpen={setAddBalanceModal}
          setSnack={setSnack}
          snack={snack}
          setPayments={setPayments}
        />
        <PaymentsHistory
          open={historyModal}
          setOpen={setHistoryModal}
          User={historyModal}
          setSnack={setSnack}
          snack={snack}
        />
        <ShipperPaymentsTable
          message={"No Payments Available To Display!"}
          searchPlaceHolder="Search Payments"
          columns={columns}
          data={payments}
          getAllData={getShippersPayments}
        />
      </div>
    </div>
  );
};

export default PaymentList;
