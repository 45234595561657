import React, { useState } from "react";
import "./UserRegistration.css";
import WarehouseList from "./Components/Warehouses/WarehouseList/WarehouseList";
import ShippersList from "./Components/Shippers/ShippersList/ShippersList";
import TruckersList from "./Components/Truckers/TruckersList";
import Snackbar from "../../../Components/SnackBar/SnackBar";
import TabsComponent from "../../../Components/Common/TabsComponent";

const UserRegistration = () => {
  const [tab, setTab] = React.useState(0);
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div className="dispatcher__screen" style={{ maxWidth: "100%" }}>
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <TabsComponent
        items={[
          {
            label: "Shippers",
            style: { fontWeight: "bold" },
            value: 0,
          },
          { label: "Carriers", style: { fontWeight: "bold" }, value: 1 },
          { label: "Warehouses", style: { fontWeight: "bold" }, value: 2 },
        ]}
        tab={tab}
        handleTabChange={handleTabChange}
      />
      {tab === 0 && <ShippersList setSnack={setSnack} />}
      {tab === 1 && <TruckersList setSnack={setSnack} />}
      {tab === 2 && <WarehouseList setSnack={setSnack} />}
    </div>
  );
};

export default UserRegistration;
