import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  IconButton,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import CircularLoader from "../../../../Components/CircularLoader/CircularLoader";
import CustomColorBox from "./ColorBox";
import { updatePalletsLocation } from "../../../../services/product.service";
import Checkbox from "@mui/material/Checkbox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function UpdateLocationModal({
  open,
  setOpen,
  setSnack,
  previousData,
  assignedCells,
  setAssignedCells,
  currentCell,
}) {
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState(previousData ?? {});
  const [colorBoxAnchor, setColorBoxAnchor] = useState(null);
  const [colorBoxOpen, setColorBoxOpen] = useState(false);
  const isUsableLabel = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleFieldChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    setLoader(true);
    const response = await updatePalletsLocation(open?.id, formData);
    setLoader(false);
    if (response?.data?.success) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Location Updated Successfully",
          severity: "success",
        };
      });
      const temp = assignedCells;
      temp[currentCell] = {
        ...temp[currentCell],
        pallet_locations: temp[currentCell]?.pallet_locations?.map((location) =>
          location?.id === response?.data?.result?.id
            ? response?.data?.result
            : location
        ),
      };
      setAssignedCells(temp);
      handleClose();
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const handleColorClick = (event) => {
    setColorBoxAnchor(event.currentTarget);
    setColorBoxOpen((prev) => !prev);
  };

  const handleColorSelect = (color) => {
    setFormData({ ...formData, color });
    setColorBoxOpen(false);
  };

  useEffect(() => {
    if (previousData) {
      setFormData(previousData);
    }
  }, [previousData]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{ ...style, width: "51%", maxHeight: "90vh", overflow: "auto" }}
        >
          <h4 id="child-modal-title" className="text-center mt-2 mb-4">
            Update Location
          </h4>
          <div className="flex justify-between my-3">
            <div className="flex-1 ">
              <Checkbox
                {...isUsableLabel}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                checked={[true, "true", "1", 1].includes(formData?.is_use_able)}
                onChange={(e) =>
                  setFormData((prevData) => {
                    return { ...prevData, is_use_able: e.target.checked };
                  })
                }
              />
              Is Useable
            </div>
          </div>
          <div className="flex justify-between my-3">
            <div className="flex-1 mr-2 ">
              <TextField
                label="Name"
                type="text"
                value={formData?.name}
                onChange={(e) => handleFieldChange("name", e.target.value)}
                placeholder="Name"
                sx={{ width: "100%", input: { font: "menu" } }}
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            </div>
            <div className="flex-1">
              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small-label">Unit</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={formData.unit ?? "imperial"}
                  label="Unit"
                  onChange={(e) => handleFieldChange("unit", e.target.value)}
                  sx={{ height: "35px" }}
                >
                  <MenuItem value="imperial">Imperial</MenuItem>
                  <MenuItem value="metric">Metric</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="flex justify-between my-3">
            <div className="flex-1 mr-2 ">
              <TextField
                label="Length"
                type="number"
                value={formData?.length}
                onChange={(e) => handleFieldChange("length", e.target.value)}
                placeholder="Length"
                sx={{ width: "100%", input: { font: "menu" } }}
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            </div>
            <div className="flex-1">
              <TextField
                label="Width"
                type="number"
                value={formData?.width}
                onChange={(e) => handleFieldChange("width", e.target.value)}
                placeholder="Width"
                sx={{ width: "100%", input: { font: "menu" } }}
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            </div>
          </div>
          <div className="flex justify-between my-3">
            <div className="flex-1 mr-2 ">
              <TextField
                label="Height"
                type="number"
                value={formData?.height}
                onChange={(e) => handleFieldChange("height", e.target.value)}
                placeholder="Height"
                sx={{ width: "100%", input: { font: "menu" } }}
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            </div>
            <div className="flex-1">
              <TextField
                label="Weight Capacity"
                type="number"
                value={formData?.weight_capacity}
                onChange={(e) =>
                  handleFieldChange("weight_capacity", e.target.value)
                }
                placeholder="Weight Capacity"
                sx={{ width: "100%", input: { font: "menu" } }}
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            </div>
          </div>
          <div className="flex justify-between my-3">
            <div className="flex-1 mr-2 ">
              <TextField
                label="Cost"
                type="number"
                value={formData?.cost}
                onChange={(e) => handleFieldChange("cost", e.target.value)}
                placeholder="Cost"
                sx={{ width: "100%", input: { font: "menu" } }}
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            </div>
            <div className="flex-1">
              <div className="relative">
                <TextField
                  label="Color"
                  type="text"
                  value={formData?.color}
                  onClick={handleColorClick}
                  placeholder="Color"
                  sx={{ width: "100%", input: { font: "menu" } }}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                />
                <CustomColorBox
                  anchorEl={colorBoxAnchor}
                  open={colorBoxOpen}
                  onClose={() => setColorBoxOpen(false)}
                  onSelectColor={handleColorSelect}
                />
              </div>
            </div>
          </div>

          <div
            style={{ display: "flex", justifyContent: "end" }}
            className="addnotes_fulfilment_btn"
          >
            <Button onClick={handleSave} variant="contained" size="small">
              {loader ? <CircularLoader /> : "Update"}
            </Button>
            <Button
              onClick={handleCancel}
              variant="contained"
              color="error"
              size="small"
              style={{ marginLeft: "5px" }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
