import React, { useEffect, useState } from "react";
import "./FullfilmentTotalDetails.css";

// components imports
// library imports
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function FullfilmentTotalDetails({
  open,
  setOpen,
  calculateTotalCost,
  calculateTotalAdditionalCost,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="flmtbilingstatus__detail_fulfilment_modal"
      >
        <Box
          sx={{
            ...style,
            width: "95%",
            maxHeight: "90vh",
            overflow: "auto",
            maxWidth: "500px",
          }}
        >
          <div className="order_fulfilment_heading">
            {open?.order_id ?? "-"}
          </div>
          <div
            className="package__name_list_wrap fulfilment_cost_tooltip_content"
            style={{ border: "1px solid #d3d3d3", padding: "5px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginBottom: "5px",
              }}
            >
              <strong className="fulfilmentcost__headers">
                Fulfilment Cost{" "}
              </strong>{" "}
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                fontSize: "14px",
              }}
            >
              <label>
                <strong>
                  {open?.formula?.["UnitType"] === "CBM" ? "Volume" : "Weight"}:
                  ({open?.formula?.["TotalFulfilmentWeight"]}{" "}
                  {open?.formula?.["UnitType"]})
                </strong>
              </label>
              <span>
                <strong>{open?.formula?.["TotalWeightCost"]} CAD </strong>
              </span>
            </div>
            {open?.formula?.["TotalPreProduct"] > 0 && (
              <>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    fontSize: "14px",
                  }}
                >
                  <strong className="fulfilmentcost__headers">
                    Pre Cost Item Quantity: (
                    {open?.formula?.["TotalPreProduct"]})
                  </strong>
                </div>
                {open?.order_details
                  ?.filter(
                    (singlelist) => singlelist?.products?.fulfillment_cost
                  )
                  .map((list, index) => (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        fontSize: "14px",
                      }}
                      key={index} // It's a good 5practice to provide a unique key for each mapped element
                    >
                      <label>
                        {list.product_name} ({list.item_quantity} x{" "}
                        {list.products?.fulfillment_cost})
                      </label>
                      <span>
                        {list.products?.fulfillment_cost * list.item_quantity}{" "}
                        CAD{" "}
                      </span>
                    </div>
                  ))}
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "end",
                    fontSize: "14px",
                  }}
                >
                  <label>
                    <strong>
                      {/* Total: ({open?.formula?.["TotalPreProduct"]}) */}
                    </strong>
                  </label>
                  <span>
                    <strong>{open?.formula?.["TotalPreCost"]} CAD</strong>
                  </span>
                </div>
              </>
            )}
            {open?.additional_costs?.length > 0 && (
              <>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    fontSize: "14px",
                  }}
                >
                  <strong className="fulfilmentcost__headers">
                    Additional Cost: ({open?.additional_costs?.length})
                  </strong>
                </div>
                {open?.additional_costs?.map((list, index) => (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      fontSize: "14px",
                    }}
                    key={index} // It's a good 5practice to provide a unique key for each mapped element
                  >
                    <label>{list?.cost_type?.name}</label>
                    <span>{list?.cost} CAD </span>
                  </div>
                ))}
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "end",
                    fontSize: "14px",
                  }}
                >
                  <span>
                    <strong
                      style={{
                        borderTop: "1px solid black",
                      }}
                    >
                      {calculateTotalAdditionalCost(open)} CAD
                    </strong>{" "}
                  </span>
                </div>
                <hr />
              </>
            )}

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                fontSize: "14px",
              }}
            >
              <label>
                <strong>Total Fulfilment COST: </strong>
              </label>
              <span>
                <strong>{calculateTotalCost(open)} CAD </strong>
              </span>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
