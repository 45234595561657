import React, { useEffect, useState } from "react";

// components imports
import CircularLoader from "../../../../Components/CircularLoader/CircularLoader";

// apis imports
import { updateStorageMap } from "../../../../services/WarehouseServices/storageMap.services";

// library imports
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Button, TextField, IconButton } from "@mui/material";



const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export default function BasicInfoModal({
    open,
    setOpen,
    setSnack,
    previousData,
    mapId,
    setBasicInfo
}) {
    const [loader, setloader] = useState(false);
    const [formData, setFormData] = useState(previousData ?? {});

    const handleClose = () => {
        setOpen(false);
    };

    const handleCancel = () => {
        handleClose();
    };

    const handleFieldChange = (name, value) => {
        setFormData({ ...formData, [name]: value })
    }

    const handleSave = async () => {
        setloader(true)
        const response = await updateStorageMap(mapId, formData);
        setloader(false)
        if (response?.data?.success) {
            setSnack((snack) => {
                return {
                    ...snack,
                    open: true,
                    text: "Info Updated Successfully",
                    severity: "success",
                };
            });
            setBasicInfo(response?.data?.result)
            handleClose()
        }
        else {
            setSnack((snack) => {
                return {
                    ...snack,
                    open: true,
                    text: response?.data?.message || "Something went wrong",
                    severity: "error",
                };
            });
        }
    };

    useEffect(() => {
        if (previousData) {
            setFormData(previousData)
        }
    }, [previousData])

    return (
        <div>
            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{ ...style, width: "100%", maxWidth: "600px", maxHeight: "90vh", overflow: "auto" }}
                >
                    <h4 id="child-modal-title" className="text-center mt-2 mb-4">
                        Update Location
                    </h4>

                    <div className="flex justify-between my-3">
                        <div className="flex-1 mr-2 ">
                            <TextField
                                label="Name"
                                type="text"
                                value={formData?.name}
                                className=""
                                onChange={(e) => handleFieldChange("name", e.target.value)}
                                placeholder={"Name"}
                                sx={{
                                    width: "100%",
                                    input: { font: "menu" },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                size="small"
                            /></div>
                    </div>
                    <div className="flex justify-between my-3">
                        <div className="flex-1 mr-2 ">
                            <TextField
                                label="Rows"
                                type="number"
                                value={formData?.rows}
                                className=""
                                onChange={(e) => handleFieldChange("rows", e.target.value)}
                                placeholder={"Rows"}
                                sx={{
                                    width: "100%",
                                    input: { font: "menu" },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                size="small"
                            /></div>
                    </div>
                    <div className="flex justify-between my-3">
                        <div className="flex-1 mr-2 ">
                            <TextField
                                label="Columns"
                                type="text"
                                value={formData?.column}
                                className=""
                                onChange={(e) => handleFieldChange("column", e.target.value)}
                                placeholder={"Columns"}
                                sx={{
                                    width: "100%",
                                    input: { font: "menu" },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                size="small"
                            /></div>
                    </div>


                    <div
                        style={{ display: "flex", justifyContent: "end" }}
                        className="addnotes_fulfilment_btn"
                    >
                        <Button onClick={handleSave} variant="contained" size="small">
                            {loader ? <CircularLoader /> : "Update"}
                        </Button>
                        <Button
                            onClick={handleCancel}
                            variant="contained"
                            color="error"
                            size="small"
                            style={{ marginLeft: "5px" }}
                        >
                            Cancel
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
