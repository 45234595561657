import React, { useState, useEffect, useRef } from "react";
import { initialInvoiceTab } from "../../../../../data/permissionsArray";
import "./Invoices.css";
import { fetchShipments } from "../../../../../services/ShipperServices/Shipments/shipments.services";

import { MdOutlineStickyNote2, MdLabelImportantOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InvoiceModal from "../../../../../Components/InvoiceModal/InvoiceModal";
import { updateUser } from "../../../../../store/userSlice";
import { TbFileInvoice } from "react-icons/tb";

import Tooltip from "../../../../../Components/Tooltip/Tooltip";
import Snackbar from "../../../../../Components/SnackBar/SnackBar";
import CircularLoader from "../../../../../Components/CircularLoader/CircularLoader";
import ShipmentsTable from "../../../../../Components/BoltTable/BoltTable";
import TabsComponent from "../../../../../Components/Common/TabsComponent";

const Invoices = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [labelDownloading, setLabelDownloading] = useState("");
  const [shipments, setShipments] = useState([]);
  const [tab, setTab] = useState("all");
  const [tabPermissions, setTabPermissions] = useState(initialInvoiceTab);
  const [invoiceModal, setInvoiceModal] = useState();
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });
  useEffect(() => {
    if (user.role != "Shipper") {
      let validPermissions = initialInvoiceTab.filter((t) =>
        user?.mergedPermissions?.includes(t.name)
      );
      if (validPermissions?.length > 0) {
        setTab(validPermissions[0]?.value);
      } else {
        setTab("nothing");
      }
      setTabPermissions(validPermissions);
    } else {
      setTab("all");
    }
  }, []);

  const printLabel = async (row) => {
    setLabelDownloading("label" + row?.id);
    // console.log(row.single_shipping_labels.file);
    try {
      fetch(row?.single_shipping_labels.file, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      })
        .then((url) => url.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${row.tattension + " " + row.shipment_tracking_id} Label.pdf`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLabelDownloading("");
        });
    } catch (error) {
      setLabelDownloading("");
    }
  };

  const printDeclaration = async (row) => {
    setLabelDownloading("Declaration" + row?.id);
    // console.log(row.single_shipping_labels.file);
    try {
      fetch(row?.single_shipping_invoice.file, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      })
        .then((url) => url.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${row.tattension + " " + row.shipment_tracking_id} Declaration.pdf`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLabelDownloading("");
        });
    } catch (error) {
      setLabelDownloading("");
    }
  };

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 1,
      label: "Carrier",
      render: (row) => (
        <>
          <img
            src={row?.shipping_company?.shipping_company?.logo_url}
            loading="lazy"
          />
        </>
      ),
      width: "200px",
      fixed: "left",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 2,
      label: "Service",
      render: (row) => <>{row?.service_type ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 3,
      label: "Reference ID",
      render: (row) => <>{row?.referance_code ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 4,
      label: "Bolt Tracking ID",
      render: (row) => <>{row?.tracking_id ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 5,
      label: "Carrier Tracking ID",
      render: (row) => (
        <>{tab === 2 ? row?.id ?? "-" : row?.shipment_tracking_id ?? "-"}</>
      ),
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 6,
      label: "Est. Delivery Date",
      render: (row) => <>{row?.estimated_delivery_date?.slice(0, 10) ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 7,
      label: "Payment Status",
      render: (row) => <>{row?.payment_status ?? "Paid"}</>,
      box: (row) => {
        const status = row?.payment_status?.toLowerCase();
        if (["unpaid"].includes(status)) {
          return "error";
        } else if (["paid", ""].includes(status)) {
          return "success";
        } else {
          return "success";
        }
      },
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 8,
      label: "Total",
      render: (row) => (
        <>
          {" "}
          <div>
            <div
              className={`${row?.shipment_charges?.length > 1
                ? "shipments__total_updatedamount"
                : ""
                }`}
            >
              {row.Total ?? "-"}{" "}
              <sup>
                {row?.shipment_charges?.length > 1
                  ? `*${row?.shipment_charges?.length}`
                  : ""}
              </sup>
            </div>

            <div className="shipments__total_prevamount">
              {row?.shipment_charges?.length > 1
                ? row?.shipment_charges[row?.shipment_charges?.length - 2]
                  ?.total
                : ""}
            </div>
          </div>
        </>
      ),
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 9,
      label: "Created at",
      render: (row) => <>{row?.created_at?.slice(0, 10) ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 10,
      label: "Actions",
      render: (row) => (
        <div className="table_action__wrap">
          <Tooltip title="View Invoice">
            <div
              className="table_action__btn"
              onClick={() => setInvoiceModal(row)}
            >
              <MdOutlineStickyNote2 size={10} />
              Invoice
            </div>
          </Tooltip>
          {row?.single_shipping_invoice && (
            <Tooltip title="Download Declaration">
              <div
                className="table_action__btn"
                onClick={() => printDeclaration(row)}
              >
                {labelDownloading === "Declaration" + row?.id ? (
                  <CircularLoader />
                ) : (
                  <>
                    <TbFileInvoice size={10} />
                    Declaration
                  </>
                )}
              </div>
            </Tooltip>
          )}
          <Tooltip title="Download Label">
            <div className="table_action__btn" onClick={() => printLabel(row)}>
              {labelDownloading === "label" + row?.id ? (
                <CircularLoader />
              ) : (
                <>
                  <MdLabelImportantOutline size={10} />
                  Label
                </>
              )}
            </div>
          </Tooltip>
        </div>
      ),
      width: "200px",
      fixed: "right",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
  ];

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const getAllShipments = async (page, perPage, filter) => {
    const response = await fetchShipments(
      tab,
      page,
      perPage,
      filter,
      "invoice"
    );
    if (!response.error) {
      setShipments(response?.data?.result?.shipments);
      dispatch(
        updateUser({
          unReadInvoices: response?.data?.result?.unReadShipmentCount,
        })
      );
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  return (
    <div className="dispatcher__screen" style={{ maxWidth: "100%" }}>
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <InvoiceModal
        open={!!invoiceModal}
        data={invoiceModal}
        setOpen={setInvoiceModal}
      />

      <TabsComponent items={tabPermissions} tab={tab} handleTabChange={handleTabChange} />
      <div className="orders_header">
        <div>
          <span className="orders_hdrheading">Invoices List</span>
        </div>
      </div>
      <div className="custom__table_size1">
        <ShipmentsTable
          message={"No Shipments Available To Display!"}
          searchPlaceHolder={"Search Shipments"}
          getAllData={getAllShipments}
          data={shipments}
          columns={columns}
          refresh={tab}
        />
      </div>
    </div>
  );
};

export default Invoices;
