import React, { useState } from "react";
import "../InvoiceModal.css";
import Logo from "../../../../../../../../../assets/images/hajex_logo.png";
import { useSelector } from "react-redux";

const Header = ({ dates, data }) => {
  const user = useSelector((state) => state.user);

  function getNextDay() {
    const today = new Date();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${today.getFullYear()}-${month}-${day}`;
  }

  return (
    <>

      <div className="invoice__modal_topbar"></div>

      <div className="tax__slip_view_details_wrap">
        <div className="tax__slip_view_details_header">
          <div className="tax__slip_view_details_header_logo">
            <img src={Logo} alt="Logo" className="invoice__modal_headerleftimg" />
          </div>
          <div className="tax__slip_view_details_interval_total">
            <div className="tax__slip_view_details_total">
              <h3>Total Tax Paid :</h3>
              <p>{data?.totalTax ?? "-"}$</p>
            </div>
            <div className="tax__slip_view_details_interval">
              <h3>Taxes Interval <span>(Y-M-D)</span> :</h3>
              <p>
                {dates?.from}
                {"/"}
                {dates?.to}
              </p>
            </div>
          </div>
        </div>
        <div className="tax__slip_view_contact_details">
          <div className="tax__slip_view_contact_list">
            {/* <div className="tax__slip_view_contact_list_heading">
        <h2>Contact</h2>
      </div> */}
            <div className="tax__slip_view_contact_list_box">
              <h3>General inquiries:</h3>
              <p>855-999-6659</p>
            </div>
            <div className="tax__slip_view_contact_list_box">
              <h3>Questions about your taxes/account:</h3>
              <p>info@hajexbolt.com</p>
            </div>
          </div>
          <div className="tax__slip_view_created_list">
            <div className="tax__slip_view_list_box min-w-[120px]">
              <h3 >Created At</h3>
              <p>{getNextDay()}</p>
            </div>
            <div className="tax__slip_view_list_box min-w-[120px]">
              <h3 >Created By</h3>
              <p>{user?.username ?? "-"}</p>
            </div>
            <div className="tax__slip_view_list_box min-w-[150px]">
              <h3 >Business Name</h3>
              <p>{user?.business_name}</p>
            </div>
          </div>

        </div>
      </div>

      {/* 

      <div className="invoice__modal_topbar"></div>
      <div className="invoice__modal_header">
        <div className="invoice__modal_headerleft">
          <img src={Logo} alt="Logo" className="invoice__modal_headerleftimg" />
          <div className="invmodal__hleft_amntitems">
            <div className="invmodal__hleft_amntitem">
              <div className="invmodal__hleft_amntitemleft bold_700">
                Total Tax Paid
              </div>
              <div className="invmodal__hleft_amntitemright">
                {data?.totalTax ?? "-"}$
              </div>
            </div>
            <div className="invmodal__hleft_amntitem">
              <div className="invmodal__hleft_amntitemleft">
                Taxes Interval (Y-M-D)
              </div>
              <div className="invmodal__hleft_amntitemright">
                {dates?.from}
                {" - "}
                {dates?.to}
              </div>
            </div>
          </div>
        </div>

        <div className="invoice__modal_headerright">
          <div className="invmodal__hright_infoitems">
            <div className="invmodal__hright_infoitem">
              <div className="invmodal__hright_infoitemleft">Created At</div>
              <div className="invmodal__hright_infoitemright">
                {getNextDay()}
              </div>
            </div>
            <div className="invmodal__hright_infoitem">
              <div className="invmodal__hright_infoitemleft">Created By</div>
              <div className="invmodal__hright_infoitemright">
                {user?.username ?? "-"}
              </div>
            </div>
            <div className="invmodal__hright_infoitem">
              <div className="invmodal__hright_infoitemleft">Business Name</div>
              <div className="invmodal__hright_infoitemright">
                {user?.business_name}
              </div>
            </div>
          </div>
          <div className="invmodal_hright_contactitems">
            <div className="invmodal_hright_contacthding">Contact</div>
            <div className="invmodal_hright_contactitem">General inquiries</div>
            <div className="invmodal_hright_contactitem">855-999-6659</div>
            <div className="invmodal_hright_contactitem">
              Questions about your taxes/account
            </div>
            <div className="invmodal_hright_contactitem">
              info@hajexbolt.com
            </div>
          </div>
        </div>
      </div> 
      */}
    </>
  );
};

export default Header;
