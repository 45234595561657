import React, { useState, useEffect } from "react";
import { BsFillTrashFill } from "react-icons/bs";
import TextField from "@mui/material/TextField";
const ProductDetailCard = ({
  selectedProducts,
  deleteProduct,
  productsTotalWeight,
  updateTotalQuantity,
  totalProductPrice,
  settotalProductPrice,
  newQuantityManualAdd,
}) => {
  const [totalQuantity, setTotalQuantity] = useState(0);

  useEffect(() => {
    calculateTotalQuantity();
  }, [selectedProducts]);

  const calculateTotalQuantity = () => {
    let total = 0;
    let price = 0;
    selectedProducts.forEach((packageItem) => {
      const quantity = parseFloat(packageItem.quantity) || 1;
      price += packageItem.price * packageItem.quantity;
      total += quantity;
    });
    setTotalQuantity(total);
    settotalProductPrice(price);
    updateTotalQuantity(total);
  };

  const handleQuantityChange = (index, newQuantity) => {
    const updatedProducts = [...selectedProducts];
    updatedProducts[index].quantity = newQuantity;
    calculateTotalQuantity(updatedProducts);
    newQuantityManualAdd(newQuantity, updatedProducts[index].weight);
  };

  return (
    <>
      <div className="productCards__orderDetails_main_wrap">
        <div class="productCards__orderDetails_header_main">
          <h2>Selected Products </h2>
          
        </div>
        <div class="productCards__orderDetails_body_main">
          <div className="products_details_table_head_wrap">
            <div
              className="products_details_table_head_col"
              style={{
                display: "inline-flex",
                width: "50px",
              }}
            >
              #
            </div>
            <div
              className="products_details_table_head_col"
              style={{
                display: "inline-flex",
                width: "70px",
              }}
            >
              IMAGE
            </div>
            <div
              className="products_details_table_head_col"
              style={{
                display: "inline-flex",
                width: "200px",
              }}
            >
              PRODUCT NAME
            </div>
            <div
              className="products_details_table_head_col"
              style={{
                display: "inline-flex",
                width: "100px",
                padding: "0 8px",
              }}
            >
              Note
            </div>
            <div
              className="products_details_table_head_col"
              style={{
                display: "inline-flex",
                width: "90px",
                padding: "0 8px",
              }}
            >
              QUANTITY
            </div>
            <div
              className="products_details_table_head_col"
              style={{
                display: "inline-flex",
                width: "80px",
              }}
            >
              Dimentions
            </div>
            <div
              className="products_details_table_head_col"
              style={{
                display: "inline-flex",
                width: "80px",
              }}
            >
              WEIGHT
            </div>

            <div
              className="products_details_table_head_col"
              style={{
                display: "inline-flex",
                width: "60px",
              }}
            >
              Price
            </div>

            <div
              className="products_details_table_head_col"
              style={{
                display: "inline-flex",
                width: "30px",
              }}
            ></div>
          </div>
          <div className="products_details_table_body_wrap">
            {selectedProducts.length > 0
              ? selectedProducts.map((packageItem, index) => (
                  <div className="products_details_table_wrap">
                    <div
                      className="products_details_table_col"
                      style={{
                        display: "inline-flex",
                        width: "50px",
                      }}
                    >
                      {packageItem?.id}
                    </div>
                    <div
                      className="products_details_table_col"
                      style={{
                        display: "inline-flex",
                        width: "70px",
                      }}
                    >
                      <img src={packageItem?.file} alt="-" />
                    </div>
                    <div
                      className="products_details_table_col"
                      style={{
                        display: "inline-flex",
                        width: "200px",
                      }}
                    >
                      {packageItem?.name}
                    </div>

                    <div
                      className="products_details_table_col"
                      style={{
                        display: "inline-flex",
                        width: "100px",
                      }}
                    >
                      <TextField
                        className="products_details_note"
                        variant="standard"
                        value={""}
                      />
                    </div>

                    <div
                      className="products_details_table_col"
                      style={{
                        display: "inline-flex",
                        width: "90px",
                        padding: "0 8px",
                      }}
                    >
                      <TextField
                        className="products_details_table_weight"
                        variant="standard"
                        value={packageItem.quantity || 1}
                        onChange={(e) =>
                          handleQuantityChange(index, e.target.value)
                        }
                      />
                    </div>

                    <div
                      className="products_details_table_col"
                      style={{
                        display: "inline-flex",
                        width: "80px",
                      }}
                    >
                      {packageItem?.length}x
                      {packageItem?.width}x
                      {packageItem?.height}
                    </div>

                    <div
                      className="products_details_table_col"
                      style={{
                        display: "inline-flex",
                        width: "80px",
                      }}
                    >
                      {packageItem?.weight * packageItem?.quantity} kg
                    </div>
                    <div
                      className="products_details_table_col"
                      style={{
                        display: "inline-flex",
                        width: "60px",
                      }}
                    >
                      {packageItem?.price * packageItem?.quantity} CAD
                    </div>
                    <div
                      className="products_details_table_col col_trash_prod"
                      style={{
                        display: "inline-flex",
                        width: "30px",
                      }}
                      onClick={() => deleteProduct(index)}
                    >
                      <BsFillTrashFill />
                    </div>
                  </div>
                ))
              : ""}
          </div>
          {productsTotalWeight ? (
            <div className="total__main_product_footer">
              <div
    className="products_details_table_col"
    style={{
      display: "inline-flex",
      width: "100px",
    }}
  >TOTAL</div>
  <div
    className="products_details_table_col"
    style={{
      display: "inline-flex",
      width: "30px",
    }}
  ></div>
  <div
    className="products_details_table_col"
    style={{
      display: "inline-flex",
      width: "183px",
    }}
  ></div>
  <div
    className="products_details_table_col"
    style={{
      display: "inline-flex",
      width: "150px",
    }}
  ></div>
<div
    className="products_details_table_col"
    style={{
      display: "inline-flex",
      width: "90px",
    }}
  >
    {totalQuantity}
  </div>
  <div
    className="products_details_table_col"
    style={{
      display: "inline-flex",
      width: "80px",
    }}
  > </div>

<div
    className="products_details_table_col"
    style={{
      display: "inline-flex",
      width: "80px",
    }}
  >{productsTotalWeight} kg</div>
<div
    className="products_details_table_col"
    style={{
      display: "inline-flex",
      width: "90px",
    }}
  >{totalProductPrice} CAD</div>
  

              
              
              
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
export default ProductDetailCard;
