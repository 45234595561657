import React, { useEffect, useState } from "react";
import "./ProductWeightModal.css";

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { updateProductsDetails } from "../../../../services/product.service";
import CircularLoader from "../../../../Components/CircularLoader/CircularLoader";

export default function ProductWeightModal({
  open,
  setOpen,
  setSnack,
  setProducts,
}) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [changed, setchanged] = useState(false);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setLoading(false);
    setchanged(false);
    setData({});
    setOpen(false);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const productUpdateArray = {
        weight_unit: data?.weight_unit,
        weight: data?.weight,
      };

      const response = await updateProductsDetails(
        productUpdateArray,
        data?.id
      );
      if (!response.error) {
        setProducts((prevProducts) => {
          return {
            ...prevProducts,
            data: prevProducts?.data?.map((product, index) => {
              if (product.id === data?.id) {
                return {
                  ...product,
                  weight_unit: response?.data?.result?.weight_unit,
                  weight: response?.data?.result?.weight,
                };
              } else if (open?.parent_id == product.id) {
                let result = product.children.map((children, idx) => {
                  if (children.id === data?.id) {
                    return {
                      ...children,
                      weight_unit: response?.data?.result?.weight_unit,
                      weight: response?.data?.result?.weight,
                    };
                  } else {
                    return {
                      ...children,
                    };
                  }
                });
                return {
                  ...product,
                  children: result,
                };
              }
              return product;
            }),
          };
        });
        setOpen(data);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Product Updated successfully",
            severity: "success",
          };
        });
        setLoading(false);
      } else {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Something went wrong",
            severity: "error",
          };
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("try catch", error);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  useEffect(() => {
    setData(open);
  }, [open]);

  useEffect(() => {
    let hasChanges = false;

    if (
      open?.weight_unit != data?.weight_unit ||
      open?.weight != data?.weight
    ) {
      hasChanges = true;
    }
    if (open && data) {
      setchanged(hasChanges);
    }
  }, [open, data]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="prod__weight_modal ">
          <div className="prod__weight_modalheading">Edit Product Weight</div>
          <div className="prod__weightmdl_formbox " id="products_dimensions">
            <div className="prod__weightmdl_formrow">
              <div className="prod__weightmdl_formcol" style={{ width: "50%" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" size="small">
                    Weight Unit
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    size="small"
                    onChange={(e) => handleFieldChange(e)}
                    value={data?.weight_unit?.toLowerCase() || ""}
                    id="weight_unit"
                    label="Weight Unit"
                    name="weight_unit"
                  >
                    <MenuItem value="lb">lb</MenuItem>
                    <MenuItem value="kg">kg</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="prod__weightmdl_formcol" style={{ width: "50%" }}>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  onChange={(e) => handleFieldChange(e)}
                  label="Weight"
                  name="weight"
                  value={data?.weight || ""}
                  variant="outlined"
                  className="prod__weightmdl_formfield"
                />
              </div>
            </div>
          </div>
          <div className="prod__weight_modalfooter">
            <div className="prod__weight_modalcncl" onClick={handleClose}>
              Close
            </div>
            {changed === true ? (
              <div className="prod__weight_modalsave" onClick={handleSave}>
                {loading ? <CircularLoader /> : "Save"}
              </div>
            ) : null}
          </div>
        </div>
      </Modal>
    </div>
  );
}
