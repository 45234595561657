import React, { useState } from "react";
// components imports

import Confirm from "../../../../../Components/Popups/Confirm";
import EditSubCategory from "../EditSubCategory/EditSubCategory";
import Tooltip from "../../../../../Components/Tooltip/Tooltip";
// apis imports

import { deleteSubCategory } from "../../../../../services/warehouse.services";

// library imports
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { MdOutlineCancel } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { showSnackBar } from "../../../../../store/common/snackBarSlice";
import { useDispatch } from "react-redux";

const Expandable = ({ data, categories, setCategories }) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteLoader, setdeleteLoader] = useState(false);
  const [subCategoryEdit, setSubCategoryEdit] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteRequest = async (subCategory) => {
    if (subCategory.id) {
      setdeleteLoader(true);
      const response = await deleteSubCategory(subCategory.id);

      if (!response.error && response?.data?.success) {
        setCategories((prevCategories) => {
          return {
            ...prevCategories,
            data: prevCategories?.data?.map((category) => {
              if (category.id === data.id) {
                const updatedSubcategories = category.subcategory.filter(
                  (subcat) => subcat.id !== subCategory.id
                );
                return { ...category, subcategory: updatedSubcategories };
              }
              return category;
            }),
          };
        });
        setdeleteLoader(false);
        setConfirmDelete(false);
        dispatch(showSnackBar({
          text: "Request deleted successfully",
          severity: "success",
        }))
      } else {
        dispatch(showSnackBar({
          text:
            response?.error?.response?.data?.message ||
            "Couldn't cancel this request!",
          severity: "error",
        }))
        setdeleteLoader(false);
      }
    }
  };

  return (
    <div style={{ padding: "10px" }}>
      <Confirm
        open={confirmDelete}
        setOpen={setConfirmDelete}
        handleConfirm={() => handleDeleteRequest(confirmDelete)}
        text="Do You Want To Delete This Request ?"
        loader={deleteLoader}
      />
      <EditSubCategory
        data={data}
        open={subCategoryEdit}
        setOpen={setSubCategoryEdit}
        setCategories={setCategories}
      />
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" saria-label="a dense table">
          <TableHead>
            <TableRow
              sx={{
                "td,th": {
                  border: 0,
                },
              }}
            >
              <TableCell align="center" style={{ minWidth: "150px" }}>
                #
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Sub Category Name
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.subcategory?.map((sub_category, index) => {
              return (
                <TableRow
                  key={index}
                  sx={{
                    // "&:last-child td, &:last-child th": {
                    "td,th": {
                      border: 0,
                      padding: 0.5,
                    },
                  }}
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{sub_category?.name}</TableCell>
                  <TableCell align="center">
                    <div className="prodsec__button_wrapper">
                      <Tooltip title="Edit Sub Category Details">
                        <div
                          className="custabscreen__thdata_action"
                          onClick={() => setSubCategoryEdit(sub_category)}
                        >
                          <FaRegEdit size={14} />
                          <span className="custabscreen__thdata_acttext">
                            Edit
                          </span>
                        </div>
                      </Tooltip>
                      <Tooltip title="Delete Sub Category">
                        <div
                          className="custabscreen__thdata_action"
                          onClick={() => setConfirmDelete(sub_category)}
                        >
                          <MdOutlineCancel size={14} />
                          <span className="custabscreen__thdata_acttext">
                            Delete
                          </span>
                        </div>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Expandable;
