import React, { useEffect, useRef, useState } from "react";

// components imports
import CircularLoader from "../../../../../../Components/CircularLoader/CircularLoader";
import ConformModal from "../conformModal";
import CustomDropdown from "../customdropdown";
import Tooltip from "../../../../../../Components/Tooltip/Tooltip";
import FullfilmentBillingStatusHistoryModal from "../../../../../Shipper/Customer/MainPages/Orders/Components/FullfilmentBillingStatusHistoryModal/FullfilmentBillingStatusHistoryModal";

// apis imports
import {
  fetchOrderStatuses,
  storeAdditionlCost,
} from "../../../../../../services/orders.services";

// library imports
import { FcPlus } from "react-icons/fc";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { RxCross2 } from "react-icons/rx";
import Select from "@mui/material/Select";
import { MdOutlineHistory } from "react-icons/md";

export default function Fulfilment({
  data,
  setadditionalCosts,
  AdditionalCostTotal,
  setAdditionalCostTotal,
  additionalCosts,
  editable,
  deletedids,
  setdeletedids,
  setSnack,
  setData,
  setOrders,
  cost,
  setcost,
  value,
  setValue,
  open,
  additonalPrice,
  setadditonalPrice,
  selectedStatus,
  setselectedStatus,
  orderStatus,
  setorderStatus,
  type = "show",
}) {
  const [CostCheck, setCostCheck] = useState([false]);
  const [loader, setloader] = useState(false);
  const [statusModal, setstatusModal] = useState(false);
  const [billingNotesHistoryModal, setBillingNotesHistoryModal] =
    useState(false);
  const setAdditionalCostRef = useRef(false);
  setAdditionalCostRef.current = false;

  useEffect(() => {
    const selected =
      orderStatus.find(
        (status) => status.id === parseFloat(data?.fullfilment_status?.id)
      )?.id ?? "";
    setselectedStatus(selected);
  }, [orderStatus]);

  const getOrderStatuses = async () => {
    let response;
    response = await fetchOrderStatuses();
    if (!response.error) {
      setorderStatus(response?.data?.result);
    }
  };

  useEffect(() => {
    if (open && type !== "show") {
      getOrderStatuses();
    }
  }, [open]);

  useEffect(() => {
    if (setAdditionalCostRef.current === false && data?.additional_costs) {
      setadditionalCosts(
        data?.additional_costs.map((cost) => {
          return { ...cost, name: cost.cost_type?.name };
        })
      );
      let totalCost;
      if (data?.additional_costs?.length > 0) {
        totalCost = data?.additional_costs.reduce(
          (accumulator, currentItem) => {
            const cost = parseFloat(currentItem.cost);
            return accumulator + cost;
          },
          0
        );
      }
      setadditonalPrice(totalCost);
      setAdditionalCostRef.current = true;
    }
  }, [data.additional_costs]);

  const storeAdditonaldata = async () => {
    setloader(true);
    let response;
    try {
      const newData = additionalCosts.filter((item) => !item.id);
      const Array = {
        new: newData.map(({ name, ...rest }) => rest),
        del: deletedids,
      };
      response = await storeAdditionlCost(Array, data.id);
      if (!response.error) {
        setloader(false);
        setdeletedids([]);
        setOrders((prevOrder) => {
          return {
            ...prevOrder,
            data: prevOrder?.data.map((order) =>
              order.id === data.id
                ? { ...order, additional_costs: response?.data?.result }
                : order
            ),
          };
        });
        setData({
          ...data,
          additional_costs: response?.data?.result,
        });
        setCostCheck(false);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: response?.data?.message,
            severity: "success",
          };
        });
        // setadditionalCosts([]);
      } else {
        setloader(false);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Something went wrong try again later",
            severity: "error",
          };
        });
      }
    } catch (error) {
      setloader(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: error.response.data.message,
          severity: "error",
        };
      });
    }
  };

  const handlestatusChange = (e) => {
    setselectedStatus(e.target.value);
    setstatusModal(true);
  };

  const handleRemoveCost = async (index, data) => {
    if (data.id) {
      setdeletedids([...deletedids, data.id]);
    }
    const list = [...additionalCosts];
    list.splice(index, 1);
    setadditionalCosts(list);
  };

  const handleadditionalcost = () => {
    let x = parseFloat(additonalPrice) + parseFloat(cost);
    setadditonalPrice(x);
    setadditionalCosts([
      ...additionalCosts,
      {
        costtype_id: value.id,
        cost: cost,
        name: value.name,
        order_id: data?.id,
      },
    ]);
    setcost(null);
    setValue(null);
  };

  useEffect(() => {
    let y = 0;
    if (additionalCosts.length > 0) {
      additionalCosts.forEach((element) => {
        y = y + parseFloat(element.cost);
      });
    }
    setAdditionalCostTotal(y);
    const newData = additionalCosts.filter((item) => !item.id);
    if (deletedids.length > 0 || newData.length > 0) {
      setCostCheck(true);
    } else {
      setCostCheck(false);
    }
  }, [additionalCosts]);

  return (
    <>
      <ConformModal
        data={data}
        setData={setData}
        open={statusModal}
        setOpen={setstatusModal}
        selectedStatus={selectedStatus}
        orderStatus={orderStatus}
        setOrders={setOrders}
        setselectedStatus={setselectedStatus}
        setSnack={setSnack}
      />

      <FullfilmentBillingStatusHistoryModal
        open={billingNotesHistoryModal}
        setOpen={setBillingNotesHistoryModal}
        setSnack={setSnack}
      />
      <Row className="fulfilment_info_title_row">
        <div
          className="invmodal__shipment_detailshding"
          style={{ marginBottom: "10px" }}
        >
          Fulfilment information
        </div>
        {editable && (
          <Col
            md={8}
            className="package__name_list_wrap fulfilment_information_form_wrap"
          >
            <div className="package__name_list_fulfilment_list">
              <div className="addprod__custominput_box">
                <label className="addprod__custominput_title">
                  Sale Status
                </label>
                <Tooltip
                  title={
                    data?.type === "Return"
                      ? "This Order is a Return"
                      : data?.status !== "Confirmed"
                      ? "This Order Is not Confirmed Yet!"
                      : ""
                  }
                >
                  <Select
                    value={selectedStatus}
                    onChange={(e) => handlestatusChange(e)}
                    label="Order Status"
                    style={{ width: "100%", fontSize: "10px" }}
                    size="small"
                    disabled={
                      selectedStatus == "6" ||
                      data?.status !== "Confirmed" ||
                      data?.type === "Return"
                        ? true
                        : false
                    }
                  >
                    {orderStatus.map((Status) => (
                      <MenuItem key={Status.id} value={Status.id}>
                        {Status.name.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </Tooltip>
              </div>
              <div className="custom__drop_order_details">
                {type === "show" && (
                  <CustomDropdown
                    setValue={setValue}
                    value={value}
                    setSnack={setSnack}
                  />
                )}
              </div>
              <div className="details_modal_fulfilment_cost_wrap">
                <TextField
                  label="Cost"
                  type="text"
                  name="cost"
                  style={{ fontSize: "10px" }}
                  value={cost || ""}
                  onChange={(e) => setcost(e.target.value)}
                  placeholder="Cost"
                  inputProps={{ style: { fontSize: 10 } }}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: 10 },
                  }}
                  size="small"
                />
                <FcPlus
                  size={20}
                  onClick={() => handleadditionalcost()}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </Col>
        )}
        <Col
          md={4}
          className="package__name_list_wrap fulfiment_box_wrap"
          style={{ border: "1px solid #d3d3d3", padding: "20px" }}
        >
          <strong className="fulfilment_box_title">Fulfilment Cost </strong>{" "}
          <div className="fulfilment_data_row">
            <label>
              <strong>
                {data?.formula?.["UnitType"] === "CBM" ? "Volume" : "Weight"}: (
                {data?.formula?.["TotalFulfilmentWeight"]}{" "}
                {data?.formula?.["UnitType"]})
              </strong>
            </label>
            <span>
              <strong>{data?.formula?.["TotalWeightCost"]} CAD </strong>
            </span>
          </div>
          {data?.formula?.["TotalPreProduct"] > 0 && (
            <>
              <hr />
              <div className="fulfilment_data_row">
                <label>
                  <strong>
                    Pre Cost Item Quantity: (
                    {data?.formula?.["TotalPreProduct"]})
                  </strong>
                </label>
                {/* <span>{data?.formula?.["TotalPreCost"]} CAD </span> */}
              </div>
              {data?.order_details
                ?.filter((singlelist) => singlelist?.products?.fulfillment_cost)
                .map((list, index) => (
                  <div
                    className="fulfilment_data_row"
                    key={index} // It's a good 5practice to provide a unique key for each mapped element
                  >
                    <label>
                      {list.product_name} ({list.item_quantity} x{" "}
                      {list.products?.fulfillment_cost})
                    </label>
                    <span>
                      {list.products?.fulfillment_cost * list.item_quantity} CAD{" "}
                    </span>
                  </div>
                ))}
              <div className="fulfilment_data_row">
                <label>{/* <strong>Total:</strong> */}</label>
                <span>
                  <strong>{data?.formula?.["TotalPreCost"]} CAD</strong>{" "}
                </span>
              </div>
            </>
          )}
          {additionalCosts?.length > 0 && (
            <>
              <hr />
              <div className="fulfilment_data_row">
                <label>
                  <strong>Additional Cost: ({additionalCosts?.length})</strong>
                </label>
                {/* <span>{data?.formula?.["TotalPreCost"]} CAD </span> */}
              </div>
              {additionalCosts?.map((list, index) => (
                <div
                  className="fulfilment_data_row"
                  key={index} // It's a good 5practice to provide a unique key for each mapped element
                >
                  <label>{list.name}</label>
                  <span style={{ display: "flex" }}>
                    {list?.cost} CAD
                    {editable && (
                      <RxCross2
                        style={{
                          margin: "auto",
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() => handleRemoveCost(index, list)}
                      />
                    )}
                  </span>
                </div>
              ))}
              <div className="fulfilment_data_row">
                <label>
                  <strong>Total Additional Cost</strong>
                </label>
                <span>
                  <strong>{AdditionalCostTotal} CAD</strong>{" "}
                </span>
              </div>
              <hr />
            </>
          )}
          {type === "show" && (
            <>
              <label>
                <strong>Fullfullment Billing Note </strong>
              </label>
              <div className="details_billingstatus__history_icon_wrapper">
                <div style={{ fontSize: "12px" }}>
                  {data?.fullfullment_billing_status_history?.at(-1)?.properties
                    ?.notes ?? "No Note Available"}
                </div>
                <div>
                  <MdOutlineHistory
                    size={15}
                    className="details_billingstatus__history_icon"
                    onClick={() => setBillingNotesHistoryModal(data)}
                  />
                </div>
              </div>
            </>
          )}
          <div className="fulfilment_data_row total_fulfilment_cost">
            <label>
              <strong>Total Fulfilment COST: </strong>
            </label>
            <span>
              <strong>
                {parseFloat(
                  parseFloat(data?.formula?.["TotalFulfilmentCost"]) +
                    AdditionalCostTotal
                ).toFixed(2)}{" "}
                CAD{" "}
              </strong>
            </span>
          </div>
        </Col>
      </Row>
      {CostCheck && editable && (
        <div className="deatil_modal_footer_btn_save">
          <div
            className="prodidentifier__desc_modalsave"
            onClick={() => storeAdditonaldata()}
          >
            {loader ? <CircularLoader /> : "Save"}
          </div>
        </div>
      )}
    </>
  );
}
