import React, { useEffect, useState } from "react";

// components imports
import CircularLoader from "../../../../Components/CircularLoader/CircularLoader";
import Upload from "@mui/icons-material/CloudUpload";

// apis imports
import {
  addPalletsLocation,
  updatePalletsLocation,
} from "../../../../services/product.service";

// library imports
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Button, TextField, IconButton } from "@mui/material";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import CustomColorBox from "./ColorBox";
import Checkbox from "@mui/material/Checkbox";
import ImageIcon from "@mui/icons-material/Image";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function LocationModal({
  open,
  setOpen,
  setSnack,
  previousData,
  type = "add",
  locations,
  setLocations,
}) {
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState(
    previousData ?? { unit: "imperial", is_use_able: true }
  );
  const [colorBoxAnchor, setColorBoxAnchor] = useState(null);
  const [colorBoxOpen, setColorBoxOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [previousImages, setPreviousImages] = useState([]);

  const isUsableLabel = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleClose = () => {
    setOpen(false);
    setFormData({ unit: "imperial", is_use_able: true });
    setColorBoxAnchor(null);
    setColorBoxOpen(false);
    setSelectedImages([]);
    setImageFiles([]);
    setPreviousImages([]);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleColorClick = (event) => {
    setColorBoxAnchor(event.currentTarget);
    setColorBoxOpen((prev) => !prev);
  };

  const handleColorSelect = (color) => {
    setFormData({ ...formData, color });
    setColorBoxOpen(false);
  };

  const handleFieldChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    setLoader(true);
    const data = new FormData();

    // Append fields to formData
    data.append("is_use_able", formData?.is_use_able ? 1 : 0);
    data.append("name", formData?.name || "");
    data.append("unit", formData?.unit || "imperial");
    data.append("length", formData?.length || "");
    data.append("width", formData?.width || "");
    data.append("height", formData?.height || "");
    data.append("weight_capacity", formData?.weight_capacity || "");
    data.append("cost", formData?.cost || "");
    data.append("color", formData?.color || "");

    // Append selected image files to formData
    if (imageFiles.length > 0) {
      imageFiles.forEach((file, index) => {
        data.append(`media[${index}]`, file); // Append file objects
      });
    }

    if (formData?.deletedMediaIds?.length > 0) {
      formData?.deletedMediaIds?.forEach((id, index) => {
        data.append(`deletedMediaIds[${index}]`, id);
      });
    }

    let response;
    try {
      if (type === "add") {
        response = await addPalletsLocation(data);
      } else {
        response = await updatePalletsLocation(open?.id, data);
      }

      setLoader(false);
      if (response?.data?.success) {
        setSnack((snack) => ({
          ...snack,
          open: true,
          text: `Location ${type === "add" ? "Added" : "Updated"} Successfully`,
          severity: "success",
        }));

        if (type === "add") {
          setLocations({
            ...locations,
            data: [response?.data?.result, ...locations?.data],
          });
        } else {
          const updatedLocations = locations?.data?.map((location) =>
            location.id === open?.id ? response?.data?.result : location
          );
          setLocations({ ...locations, data: updatedLocations });
        }
        handleClose();
      } else {
        setSnack((snack) => ({
          ...snack,
          open: true,
          text: response?.data?.message || "Something went wrong",
          severity: "error",
        }));
      }
    } catch (error) {
      setLoader(false);
      setSnack((snack) => ({
        ...snack,
        open: true,
        text: error.message || "Something went wrong",
        severity: "error",
      }));
    }
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setImageFiles(files);
    const imagePreviews = files.map((file) => URL.createObjectURL(file));
    setSelectedImages((prev) => [...prev, ...imagePreviews]);
  };

  const handleRemoveImage = (index) => {
    const newImages = selectedImages.filter((_, i) => i !== index);
    const newFiles = imageFiles.filter((_, i) => i !== index);
    setSelectedImages(newImages);
    setImageFiles(newFiles);
  };
  const handleDeleteImage = (id) => {
    const newImages = previousImages.filter((img, i) => img?.id !== id);
    setFormData({
      ...formData,
      deletedMediaIds: [...formData.deletedMediaIds, id],
    });
    setPreviousImages(newImages);
  };

  useEffect(() => {
    if (previousData && type === "edit") {
      setFormData({ ...previousData, deletedMediaIds: [] });
      setPreviousImages(previousData?.media);
    }
  }, [previousData]);

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{ ...style, width: "51%", maxHeight: "90vh", overflow: "auto" }}
        >
          <h4 id="child-modal-title" className="text-center mt-2 mb-4">
            {type === "add" ? "Add Location" : "Update Location"}
          </h4>
          <div className="flex justify-between my-3">
            <div className="flex-1 ">
              <Checkbox
                {...isUsableLabel}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                checked={[true, "true", "1", 1].includes(formData?.is_use_able)}
                onChange={(e) =>
                  setFormData((prevData) => {
                    return { ...prevData, is_use_able: e.target.checked };
                  })
                }
              />
              Is Useable
            </div>
          </div>
          <div className="flex justify-between my-3">
            <div className="flex-1 mr-2 ">
              <TextField
                label="Name"
                type="text"
                value={formData?.name}
                className=""
                onChange={(e) => handleFieldChange("name", e.target.value)}
                placeholder={"Name"}
                sx={{
                  width: "100%",
                  input: { font: "menu" },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </div>
            <div className="flex-1">
              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small-label">Unit</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={formData.unit ?? "imperial"}
                  label="Unit"
                  onChange={(e) => handleFieldChange("unit", e.target.value)}
                  sx={{
                    height: "35px",
                  }}
                >
                  <MenuItem value="imperial">Imperial</MenuItem>
                  <MenuItem value="metric">Metric</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="flex justify-between my-3">
            <div className="flex-1 mr-2 ">
              <TextField
                label="Length"
                type="number"
                value={formData?.length}
                className=""
                onChange={(e) => handleFieldChange("length", e.target.value)}
                placeholder={"Length"}
                sx={{
                  width: "100%",
                  input: { font: "menu" },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </div>
            <div className="flex-1">
              <TextField
                label="Width"
                type="number"
                value={formData?.width}
                className=""
                onChange={(e) => handleFieldChange("width", e.target.value)}
                placeholder={"Width"}
                sx={{
                  width: "100%",
                  input: { font: "menu" },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </div>
          </div>
          <div className="flex justify-between my-3">
            <div className="flex-1 mr-2 ">
              <TextField
                label="Height"
                type="number"
                value={formData?.height}
                className=""
                onChange={(e) => handleFieldChange("height", e.target.value)}
                placeholder={"Height"}
                sx={{
                  width: "100%",
                  input: { font: "menu" },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </div>
            <div className="flex-1">
              <TextField
                label="Weight Capacity"
                type="number"
                value={formData?.weight_capacity}
                className=""
                onChange={(e) =>
                  handleFieldChange("weight_capacity", e.target.value)
                }
                placeholder={"Weight Capacity"}
                sx={{
                  width: "100%",
                  input: { font: "menu" },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </div>
          </div>
          <div className="flex justify-between my-3">
            <div className="flex-1 mr-2 ">
              <TextField
                label="Cost"
                type="number"
                value={formData?.cost}
                className=""
                onChange={(e) => handleFieldChange("cost", e.target.value)}
                placeholder={"Cost"}
                sx={{
                  width: "100%",
                  input: { font: "menu" },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </div>
            <div className="flex-1">
              <div className="relative">
                <TextField
                  label="Color"
                  type="text"
                  value={formData?.color}
                  onClick={handleColorClick}
                  placeholder="Color"
                  sx={{ width: "100%", input: { font: "menu" } }}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                />
                <CustomColorBox
                  anchorEl={colorBoxAnchor}
                  open={colorBoxOpen}
                  onClose={() => setColorBoxOpen(false)}
                  onSelectColor={handleColorSelect}
                />
              </div>
            </div>
          </div>
          <div className="my-3">
            <div className="add__package_modalinputwrap">
              <label
                className="add__package_profimagelbl"
                htmlFor="image-upload-input"
              >
                <Upload /> {"UPLOAD IMAGES"}
                <input
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={handleImageUpload}
                  style={{ display: "none" }}
                  id="image-upload-input"
                />
              </label>
            </div>
          </div>
          {/* Image preview section */}

          <div className="flex gap-2 flex-wrap">
            {selectedImages.map((image, index) => (
              <div
                key={index}
                className="relative w-20 h-20 rounded-sm overflow-hidden shadow-sm"
              >
                <img
                  src={image}
                  alt={`Preview ${index}`}
                  className="w-full h-full object-cover"
                />
                <button
                  onClick={() => handleRemoveImage(index)}
                  className="absolute top-1 right-1 shadow-sm bg-white bg-opacity-80 text-red-600 rounded-full p-1 hover:bg-opacity-100 transition duration-200 ease-in-out"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3 w-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            ))}
            {type === "edit" && previousImages?.length
              ? previousImages.map((image, index) => (
                  <div
                    key={index}
                    className="relative w-20 h-20 rounded-sm overflow-hidden shadow-sm"
                  >
                    <img
                      src={image?.file}
                      alt={`Preview ${index}`}
                      className="w-full h-full object-cover"
                    />
                    <button
                      onClick={() => handleDeleteImage(image?.id)}
                      className="absolute top-1 right-1 shadow-sm bg-white bg-opacity-80 text-red-600 rounded-full p-1 hover:bg-opacity-100 transition duration-200 ease-in-out"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3 w-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                ))
              : null}
          </div>

          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button onClick={handleSave} variant="contained" size="small">
              {loader ? (
                <CircularLoader />
              ) : (
                `${type === "add" ? "Add" : "Update"}`
              )}
            </Button>
            <Button
              onClick={handleCancel}
              variant="contained"
              color="error"
              size="small"
              style={{ marginLeft: "5px" }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
