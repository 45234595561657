import React, { useEffect, useState } from "react";
import "./FullfilmentBillingStatus.css";

// components imports
import CircularLoader from "../../../../../../../Components/CircularLoader/CircularLoader";
import FullfilmentBillingStatusHistoryModal from "../../Components/FullfilmentBillingStatusHistoryModal/FullfilmentBillingStatusHistoryModal";
// apis imports
import { updateFullfilmentBillingStatus } from "../../../../../../../services/orders.services";
// library imports
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MdOutlineHistory } from "react-icons/md";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function FullfilmentBillingStatusModal({
  open,
  setOpen,
  setSnack,
  setOrders,
  orders,
}) {
  const [status, setStatus] = useState("");
  const [notes, setNotes] = useState("");
  const [loader, setloader] = useState(false);
  const [billingNotesHistoryModal, setBillingNotesHistoryModal] =
    useState(false);

  const [additionalCosts, setadditionalCosts] = useState([]);

  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  const handleClose = () => {
    setStatus("");
    setNotes("");
    setadditionalCosts([]);
    setOpen(false);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleSave = async () => {
    setloader(true);
    let response;
    try {
      response = await updateFullfilmentBillingStatus(open?.id, status, notes);
      if (!response.error) {
        setloader(false);
        setOrders({
          ...orders,
          data: orders?.data?.map((order) => {
            if (order?.id === open?.id) {
              return {
                ...order,
                fulfillment_billing_status:
                  response?.data?.result?.fulfillment_billing_status,
              };
            } else {
              return order;
            }
          }),
        });
        handleClose();
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: response?.data?.message,
            severity: "success",
          };
        });
      } else {
        setloader(false);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Something went wrong try again later",
            severity: "error",
          };
        });
      }
    } catch (error) {
      setloader(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: error?.response?.data?.message,
          severity: "error",
        };
      });
    }
  };

  useEffect(() => {
    setStatus(open?.fulfillment_billing_status ?? "");
    setadditionalCosts(
      open?.additional_costs?.map((cost) => {
        return { ...cost, name: cost.cost_type?.name };
      })
    );
  }, [open]);

  const calculateTotalAdditionalCost = (data) => {
    let totalCost = 0;
    if (data?.additional_costs?.length > 0) {
      data.additional_costs.forEach((currentItem) => {
        const cost = parseFloat(currentItem.cost);
        totalCost += cost;
      });
    }
    return totalCost;
  };

  const calculateTotalCost = (data) => {
    let totalCost = 0;
    let total = 0;
    if (data?.additional_costs?.length > 0) {
      data.additional_costs.forEach((currentItem) => {
        const cost = parseFloat(currentItem.cost ?? 0);
        totalCost += cost;
      });
    }
    total = totalCost + parseFloat(data.fulfillment_cost ?? 0);
    return parseFloat(total).toFixed(2);
  };
  return (
    <div>
      <FullfilmentBillingStatusHistoryModal
        open={billingNotesHistoryModal}
        setOpen={setBillingNotesHistoryModal}
        setSnack={setSnack}
      />
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="flmtbilingstatus__detail_fulfilment_modal"
        s
      >
        <Box
          sx={{
            ...style,
            width: "95%",
            maxHeight: "90vh",
            overflow: "auto",
            maxWidth: "500px",
          }}
        >
          <h2 id="child-modal-title" className="flmtbilingstatus__status_title">
            Update Fullfilment Billing Status
          </h2>
          <div className="flmtbilingstatus__modal_orderId">
            {open?.order_id}
          </div>
          <div>
            <strong className="flmtbilingstatus_box_title">
              Fulfilment Cost{" "}
            </strong>
            <div className="flmtbilingstatus_data_row">
              <label>
                <strong>
                  {open?.formula?.["UnitType"] === "CBM" ? "Volume" : "Weight"}:
                  ({open?.formula?.["TotalFulfilmentWeight"]}{" "}
                  {open?.formula?.["UnitType"]})
                </strong>
              </label>
              <span>
                <strong>{open?.formula?.["TotalWeightCost"]} CAD </strong>
              </span>
            </div>

            {open?.formula?.["TotalPreProduct"] > "0" && (
              <>
                <hr />
                <div className="flmtbilingstatus_data_row">
                  <label>
                    <strong>
                      Pre Cost Item Quantity: (
                      {open?.formula?.["TotalPreProduct"]})
                    </strong>
                  </label>
                  <strong>{open?.formula?.["TotalPreCost"]} CAD </strong>
                </div>
                {/* {open?.order_details
                  ?.filter(
                    (singlelist) => singlelist?.products?.fulfillment_cost
                  )
                  .map((list, index) => (
                    <div
                      className="flmtbilingstatus_data_row"
                      key={index} // It's a good 5practice to provide a unique key for each mapped element
                    >
                      <label>
                        {list.product_name} ({list.item_quantity} x{" "}
                        {list.products?.fulfillment_cost})
                      </label>
                      <span>
                        {list.products?.fulfillment_cost * list.item_quantity}{" "}
                        CAD{" "}
                      </span>
                    </div>
                  ))} */}
                {/* <div className="flmtbilingstatus_data_row">
                  <label><strong>Total: </strong></label>
                  <span>
                    <strong>{open?.formula?.["TotalPreCost"]} CAD</strong>{" "}
                  </span>
                </div> */}
              </>
            )}

            {additionalCosts?.length > 0 && (
              <>
                <hr />
                <div className="flmtbilingstatus_data_row">
                  <label>
                    <strong>
                      Additional Cost: ({additionalCosts?.length})
                    </strong>
                  </label>
                </div>
                {additionalCosts?.map((list, index) => (
                  <div className="flmtbilingstatus_data_row" key={index}>
                    <label>{list.name}</label>
                    <span style={{ display: "flex" }}>{list?.cost} CAD</span>
                  </div>
                ))}
                <div className="flmtbilingstatus_data_row">
                  <label>
                    <strong>Total Additional Cost</strong>
                  </label>
                  <span>
                    <strong>{calculateTotalAdditionalCost(open)} CAD</strong>{" "}
                  </span>
                </div>
                <hr />
              </>
            )}
            <div className="flmtbilingstatus_data_row total_flmtbilingstatus_cost">
              <label>
                <strong>Total Fulfilment COST: </strong>
              </label>
              <span>
                <strong>{calculateTotalCost(open)} CAD </strong>
              </span>
            </div>
            <div className="flmtbilingstatus_data_row total_flmtbilingstatus_cost">
              <label>
                <strong>Fulfilment Status: </strong>
              </label>
              <span>
                <strong>
                  {open?.fullfilment_status?.name?.toUpperCase() ?? "-"}
                </strong>
              </span>
            </div>
            <br />
            <div className="flmtbilingstatus__history_icone_wrapper">
              <span>View Fulfilment Billing Status History</span>
              <span>
                <MdOutlineHistory
                  size={15}
                  className="flmtbilingstatus__history_icone"
                  onClick={() => setBillingNotesHistoryModal(open)}
                />
              </span>
            </div>
          </div>
          <br />
          <FormControl
            sx={{ m: 1, minWidth: 120, margin: "10px 1px" }}
            size="small"
            fullWidth
          >
            <InputLabel id="demo-select-small-label">Select Status</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={status}
              label="Select Status"
              onChange={handleChange}
              disabled={open?.fullfilment_status?.id == 4 ? false : true}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="paid">Paid</MenuItem>
              <MenuItem value="unpaid">Unpaid</MenuItem>
              <MenuItem value="dispute">Dispute</MenuItem>
            </Select>
          </FormControl>

          <label htmlFor="">Fulfillment Note</label>
          <textarea
            name="notes"
            disabled={
              open?.fullfilment_status?.id == 4 &&
              open?.fulfillment_billing_status != status &&
              !loader
                ? false
                : true
            }
            value={notes}
            className="flmtbilingstatus_text_area"
            onChange={(e) => setNotes(e.target.value)}
          />
          {open?.fullfilment_status?.id != 4 && (
            <strong>"The order is not yet fulfilled."</strong>
          )}

          <div
            style={{ display: "flex", justifyContent: "end" }}
            className="flmtbilingstatus_fulfilment_btn"
          >
            <Button
              onClick={handleSave}
              variant="contained"
              size="small"
              disabled={
                open?.fullfilment_status?.id == 4 &&
                open?.fulfillment_billing_status != status &&
                !loader
                  ? false
                  : true
              }
            >
              {loader ? <CircularLoader /> : "Update"}
            </Button>
            <Button
              onClick={handleCancel}
              variant="contained"
              color="error"
              size="small"
              style={{ marginLeft: "5px" }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
