import React, { useRef, useEffect } from "react";
import PrintableSlip from "./PrintableSlip";
import html2pdf from "html2pdf.js";
import { FaRegTimesCircle } from "react-icons/fa";
import { MdArrowDownward } from "react-icons/md";
import Header from "./Components/Header";
import Details from "./Components/Details";
import Footer from "./Components/Footer";

const FullPageSlip = ({ data, setdata, dates, Role }) => {
  const invoiceRef = useRef(null);

  function handleClick() {
    setdata(null);
  }

  const downloadPdf = async (data) => {
    const input = invoiceRef.current;
    const options = {
      filename: `Order.pdf`,
      format: "A4",
      jsPDF: { unit: "px", format: [1200, 1200], orientation: "p" },
      image: { type: "jpeg", quality: 0.98 },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      html2canvas: { scale: 2, useCORS: true },
    };

    await html2pdf().set(options).from(input).save();
  };

  return (
    <>
      {data && (
        <div className="fpltlinvoice__modal_wrap warehouse__invoice_interval_wrap">
          <div className="warehouse__invoice_interval_for_print warehouse__content_interval_wrap">
            <PrintableSlip
              invoiceRef={invoiceRef}
              data={data}
              dates={dates}
              Role={Role}
            />
          </div>
          <div className="fpltlinvoice__modal_box" style={{ width: "auto" }}>
            <div className="ltlinvoice__modal_downloadinvoicewrap warehouse__content_interval_btns">
              <button
                onClick={() => downloadPdf(data)}
                className="tax_slip_download_btn"
              >
                <MdArrowDownward size={16} />
              </button>
              <button onClick={handleClick} className="tax_slip_close_btn">
                <FaRegTimesCircle size={16} />
              </button>
            </div>

            <Header data={data} dates={dates} />
            <div className="warehouse__content_interval_wrap">
              <Details data={data} Role={Role} />
            </div>

            {/* <Footer data={data} /> */}
          </div>
        </div>
      )}
    </>
  );
};

export default FullPageSlip;
