import PlacesAutocomplete, {
    geocodeByAddress,
  } from "react-places-autocomplete";
  import TextField from "@mui/material/TextField";
  import InputAdornment from "@mui/material/InputAdornment";
  import Tooltips from "@mui/material/Tooltip";
  import IconButton from "@mui/material/IconButton";
  import { MdOutlineHome } from "react-icons/md";
  import CircularProgress from "@mui/material/CircularProgress";
  
  export default function PlacesAutoComplete(props) {
    const {
      List,
      HandleAddress,
      HandleAddressSelect,
      Required,
      HandleResidential,
      Color,
    } = props;
  
    return (
      <PlacesAutocomplete
        value={''}
        onChange={''}
        onSelect={''}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <TextField
              label="Full Address"
              type="text"
              {...getInputProps({
                placeholder: "Full Address",
              })}
              required={Required}
              sx={{
                width: "100%",
                input: { font: "menu" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {loading && (
                      <CircularProgress size={15} style={{ color: "#C1C1C1" }} />
                    )}
                    {List["type"] === "Tlist" && (
                      <Tooltips title="Residential Address" placement="top">
                        <IconButton
                          edge="end"
                          onClick={HandleResidential}
                          color={Color}
                        >
                          <MdOutlineHome />
                        </IconButton>
                      </Tooltips>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            {suggestions.length !== 0 && (
              <div
                style={{
                  position: "absolute",
                  zIndex: 1021,
                  width: "600px",
                }}
                className="p-1 form-select form-select-sm shadow"
              >
                <div className="mb-1 text-center">
                  <b>
                    <small>Suggestions</small>
                  </b>
                </div>
                {loading ? <div>...loading</div> : null}
                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: suggestion.active ? "#1976D2" : "#fff",
                    color: suggestion.active ? "#fff" : "#000000",
                    whiteSpace: "pre-line",
                  };
  
                  return (
                    <option
                      {...getSuggestionItemProps(suggestion, {
                        style,
                      })}
                      key={suggestion.description}
                    >
                      {suggestion.description}
                    </option>
                  );
                })}
              </div>
            )}
          </>
        )}
      </PlacesAutocomplete>
    );
  }
  