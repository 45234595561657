import React, { useState, useEffect } from "react";
import "./Products.css";

// components imports
import GRN from "../../../Pages/Products/GRN/GRN";
import Snackbar from "../../../Components/SnackBar/SnackBar";
import Product from "../../Products/Product/WarehouseProduct";
import ProductStockCount from "../../Products/ProductStockCount/ProductStockCount";
import TransferRequest from "./TransferRequest/TransferRequest";
import TabsComponent from "../../../Components/Common/TabsComponent";
import WarehousePallet from "./Pallets/Pallets";
// apis imports
// library imports
import { useLocation } from "react-router-dom";

const Products = () => {
  const location = useLocation();
  const transferId = location.state && location.state.transferId;

  const [tab, setTab] = useState(0);

  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (transferId === "stockreceived") {
      setTab(1);
    } else {
      setTab(0);
    }
  }, []);

  return (
    <div className="warehouse__products__page_wrap">
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <TabsComponent
        items={[
          {
            label: "ALL PRODUCTS",
            style: { fontWeight: "bold" },
            value: 0,
          },
          {
            label: "All Pallets",
            style: { fontWeight: "bold" },
            value: 1,
          },
          {
            label: "Stock Received List",
            style: { fontWeight: "bold" },
            value: 2,
          },
          {
            label: "PRODUCT COUNT STOCK",
            style: { fontWeight: "bold" },
            value: 3,
          },
          {
            label: "TRANSFER REQUEST",
            style: { fontWeight: "bold" },
            value: 4,
          },
        ]}
        tab={tab}
        handleTabChange={handleTabChange}
      />
      {tab === 0 && <Product setSnack={setSnack} />}
      {tab === 1 && <WarehousePallet setSnack={setSnack} />}
      {tab === 2 && <GRN setSnack={setSnack} />}
      {tab === 3 && <ProductStockCount setSnack={setSnack} />}
      {tab === 4 && <TransferRequest setSnack={setSnack} />}
      {/* {tab === 3 && <TransferNote />}
      {tab === 4 && <BrandStock />}
      {tab === 5 && <Currency />}
      {tab === 6 && <Category />}
      {tab === 7 && <Unit />} */}
    </div>
  );
};

export default Products;
