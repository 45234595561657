import React, { useEffect, useState } from "react";
import "./truckerslist.css";

// components imports
import TruckersTable from "../../../../../Components/BoltTable/BoltTable";
import CarrierDetails from "./Components/TruckersDetails/TruckerDetails";
import ChangePassword from "../ChangePassword/ChangePassword";
import Confirm from "../../../../../Components/Popups/Confirm";
import SwitchButton from "../../../../../Components/SwitchButton/SwitchButton";
import Tooltip from "../../../../../Components/Tooltip/Tooltip";
// apis imports
import {
  fetchUsers,
  updateAccountStatus,
  updateZonesPermission,
  deleteUserByAdmin,
} from "../../../../../services/AdminServices/Users/users.services";
// library imports
import { IconButton } from "@mui/material";
import { MdRemoveRedEye } from "react-icons/md";
import { IoInformationCircleOutline } from "react-icons/io5";
import { MdOutlineCancel } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";

const TruckersList = ({ setSnack }) => {
  const [Loader, setLoader] = useState(false);
  const [truckers, setTruckers] = useState([]);

  const [detailsModal, setDetailsModal] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState();

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 1,
      label: "UserName",
      render: (row) => <>{row?.username ?? "-"}</>,
      width: "200px",
    },
    {
      id: 2,
      label: "Company Name",
      render: (row) => <>{row?.business_name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Vehicles",
      render: (row) => <>{row?.vehicles_qty ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "Email",
      render: (row) => <>{row?.email ?? "-"}</>,
      width: "200px",
    },
    {
      id: 5,
      label: (
        <>
          Activate
          <Tooltip title="Account Approved / Disapproved">
            <IconButton>
              <IoInformationCircleOutline size={10} />
            </IconButton>
          </Tooltip>
        </>
      ),
      render: (row) => (
        <>
          <SwitchButton
            onChange={(e) => handleStatusChange(e, row)}
            checked={
              ![false, "false", "0", null, undefined].includes(
                row.account_status
              )
            }
            disabled={Loader === "active" ? true : false}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 6,
      label: (
        <>
          Enable Quote
          <Tooltip title="Enable / Disable Quote">
            <IconButton>
              <IoInformationCircleOutline size={10} />
            </IconButton>
          </Tooltip>
        </>
      ),
      render: (row) => (
        <>
          <SwitchButton
            onChange={(e) => handleZonesPermission(e, row)}
            checked={[true, "true", "1"].includes(row.is_questions_approved)}
            disabled={Loader === "quote" ? true : false}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 7,
      label: "Actions",
      render: (row) => (
        <div className="table_action__wrap">
          <Tooltip title="View Carrier Details">
            <div
              className="table_action__btn"
              onClick={() => setDetailsModal(row)}
            >
              <MdRemoveRedEye size={10} />
              Details
            </div>
          </Tooltip>
          <Tooltip title="Reset Carrier Password">
            <div
              className="table_action__btn"
              onClick={() => setChangePassword(row.id)}
            >
              <FaRegEdit size={10} />
              RESET PASSWORD
            </div>
          </Tooltip>
          <Tooltip title="Delete Carrier">
            <div
              className="table_action__btn"
              onClick={(e) => setConfirmDelete(row)}
            >
              <MdOutlineCancel size={10} />
              DELETE
            </div>
          </Tooltip>
        </div>
      ),
      width: "250px",
      fixed: "right",
    },
  ];
  const fetchAllTruckers = async (page, perPage, filter) => {
    const response = await fetchUsers(page, perPage, filter, "Carrier");
    if (!response.error) {
      setTruckers(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const handleStatusChange = async (e, record) => {
    setLoader("active");
    const updated = await updateAccountStatus({
      id: parseInt(record.id),
      account_status: e.target.checked,
    });

    if (updated.error) {
      setLoader(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: updated.error.message,
          severity: "error",
        };
      });
    } else {
      setTruckers((prevTruckers) => {
        return {
          ...prevTruckers,
          data: prevTruckers?.data.map((item) =>
            item.id === record.id
              ? {
                  ...item,
                  account_status: ![
                    false,
                    "false",
                    "0",
                    null,
                    undefined,
                  ].includes(updated?.data?.result?.account_status),
                }
              : item
          ),
        };
      });
      setLoader(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `Status of ${
            updated?.data?.result?.username ?? "user"
          } changed to ${
            ![false, "false", "0", null, undefined].includes(
              updated?.data?.result?.account_status
            )
              ? "Active"
              : "Inactive"
          }`,
          severity: ![false, "false", "0", null, undefined].includes(
            updated?.data?.result?.account_status
          )
            ? "success"
            : "info",
        };
      });
    }
  };
  const handleZonesPermission = async (e, record) => {
    setLoader("quote");
    const updated = await updateZonesPermission(record.id);

    if (updated.error) {
      setLoader(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: updated.error.message,
          severity: "error",
        };
      });
    } else {
      setTruckers((prevTruckers) => {
        return {
          ...prevTruckers,
          data: prevTruckers?.data.map((item) =>
            item.id === record.id
              ? {
                  ...item,
                  is_questions_approved: [true, "true", "1"].includes(
                    updated?.data?.result?.is_questions_approved
                  ),
                }
              : item
          ),
        };
      });
      setLoader(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `${record.username} ${
            !e.target.checked ? "allowed" : "restricted"
          } to draw zones `,
          severity: !e.target.checked ? "success" : "info",
        };
      });
    }
  };
  const handleDeleteTrucker = async (record) => {
    setLoader("delete");
    const response = await deleteUserByAdmin(record.id);
    if (response.error) {
      setLoader(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text:
            response?.error?.response?.data?.message || "Something went wrong",
          severity: "error",
        };
      });
    } else {
      setTruckers((prevTruckers) => {
        return {
          ...prevTruckers,
          data: prevTruckers?.data.filter((item) => item.id !== record.id),
        };
      });
      setLoader(false);
      setConfirmDelete(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `Carrier Deleted Successfully `,
          severity: "success",
        };
      });
    }
  };

  return (
    <div>
      <Confirm
        open={confirmDelete}
        setOpen={setConfirmDelete}
        handleConfirm={() => handleDeleteTrucker(confirmDelete)}
        text="Do you want to delete this user?"
        loader={Loader === "delete" ? true : false}
      />
      <CarrierDetails
        open={!!detailsModal}
        data={detailsModal}
        setOpen={setDetailsModal}
      />
      <ChangePassword
        open={!!changePassword}
        setOpen={setChangePassword}
        previousData={changePassword}
        setSnack={setSnack}
        setUsers={setTruckers}
      />
      <div>
        <div className="truckers_header">
          <div>
            <span className="truckers_heading">Carriers List</span>
          </div>
        </div>
        <div className="custom__table_size1 actionbtn__needtobe_fixwidth">
          <TruckersTable
            message={"No Carriers Available To Display!"}
            searchPlaceHolder="Search Carriers"
            columns={columns}
            data={truckers}
            getAllData={fetchAllTruckers}
          />
        </div>
      </div>
    </div>
  );
};

export default TruckersList;
