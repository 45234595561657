import React, { useState, useEffect } from "react";
import PackageModel from "../PackageModel";
import Tooltip from "../../../../../../../Components/Tooltip/Tooltip";
import { setdata } from "../../../../../../../helpers/helpers";
import PackageGridView from "./PackageGridView";
import PackageListView from "./PackageListView";
import ProductTypeSelector from "../ProductTypeSelector";
import { showSnackBar } from "../../../../../../../store/common/snackBarSlice";
import { useDispatch } from "react-redux";

export default function PackagesScroller(props) {
  const {
    Plist,
    SetPlist,
    StepCheck,
    SetStepCheck,
    packageRefresh,
    setpackageRefresh,
    view,
    setView,
  } = props;
  const dispatch = useDispatch();

  const [filterComponent, setFilterComponent] = useState(null);

  const handleView = () => {
    if (view == "Grid") {
      setFilterComponent(null);
      setView("List");
    } else if (view == "List") {
      setView("Grid");
    }
  };

  const handlescrollpackage = (data) => {
    dispatch(
      showSnackBar({
        text: `${data?.name ?? "Package"} Added Successfully`,
        severity: "success",
      })
    );
    const x = Plist.length - 1;
    if (
      Plist[x]["name"] === "" &&
      Plist[x]["length"] === "" &&
      Plist[x]["width"] === "" &&
      Plist[x]["height"] === "" &&
      Plist[x]["weight"] === "" &&
      Plist[x]["description"] === "" &&
      Plist[x]["insurance"] === ""
    ) {
      const plist = [...Plist];
      plist[x]["name"] = data.name;
      plist[x]["length"] = setdata(() => data.length, false, "");
      plist[x]["width"] = setdata(() => data.width, false, "");
      plist[x]["height"] = setdata(() => data.height, false, "");
      plist[x]["weight"] = setdata(() => data.weight, false, "");
      plist[x]["volume"] = (
        (setdata(() => data.length, false, 0) *
          setdata(() => data.width, false, 0) *
          setdata(() => data.height, false, 0)) /
        1000000
      ).toFixed(2);
      plist[x]["description"] = data.description;
      plist[x]["insurance"] = "";
      SetPlist(plist);
    } else {
      SetPlist([
        ...Plist,
        {
          name: data.name,
          length: setdata(() => data.length, false, ""),
          width: setdata(() => data.width, false, ""),
          height: setdata(() => data.height, false, ""),
          weight: setdata(() => data.weight, false, ""),
          description: data.description,
          insurance: "",
          volume: (
            (setdata(() => data.length, false, 0) *
              setdata(() => data.width, false, 0) *
              setdata(() => data.height, false, 0)) /
            1000000
          ).toFixed(2),
        },
      ]);
    }
    SetStepCheck((prevStepCheck) => ({
      ...prevStepCheck,
      Packages_bit: 0,
      QuoteCheck: false,
    }));
  };
  const setFilter = (filterElement) => {
    setFilterComponent(filterElement);
  };

  const [show, setshow] = useState(false);
  const handleshow = () => {
    setshow(!show);
  };

  return (
    <>
      <div className="packages__scroller_wrap_main_container">
        <div className="warehouse__selection_drop_main_wrap">
          <Tooltip title="Add New Package">
            <button
              type="button"
              onClick={handleshow}
              className="formdata__pagination_controler_btn_btnadd"
            >
              Add
            </button>
          </Tooltip>
        </div>

        {filterComponent}
        <ProductTypeSelector
          StepCheck={StepCheck}
          SetStepCheck={SetStepCheck}
          view={view}
          handleView={handleView}
        />
      </div>
      {view == "Grid" && (
        <>
          <PackageGridView
            handlescrollpackage={handlescrollpackage}
            setFilter={setFilter}
            refresh={packageRefresh}
          />
        </>
      )}
      {view == "List" && (
        <>
          <div className="ship__page_myproduct_listing_bolt_table">
            <PackageListView
              handlescrollpackage={handlescrollpackage}
              refresh={packageRefresh}
            />
          </div>
        </>
      )}
      <PackageModel
        Show={show}
        Handler={handleshow}
        setpackageRefresh={setpackageRefresh}
      />
    </>
  );
}
