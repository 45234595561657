import React, { useState, useEffect } from "react";
import "./pendingpaymentlist.css";

// components imports
import ApprovedTransectionTable from "../../../../../../../Components/BoltTable/BoltTable";
import InvoiceModal from "../../../../../../../Components/InvoiceModal/InvoiceModal";
import AddBalance from "../AddBalance/AddBalance";
import ImageModal from "../../Components/ImageModal/ImageModal";
import Tooltip from "../../../../../../../Components/Tooltip/Tooltip";
import noImage from "../../../../../../../assets/images/noImage.png";

// apis imports
import { fetchShipperPendingTransactions } from "../../../../../../../services/ShipperServices/Payments/payments.services";
import { removeUnderscoresAndCapitalize } from "../../../../../../../helpers/helpers";

// library imports
import { FaArrowAltCircleUp } from "react-icons/fa";
import { MdOutlineStickyNote2 } from "react-icons/md";

const PendingPaymentList = ({ setSnack }) => {
  const [transections, setTransections] = useState([]);

  const [invoiceModal, setInvoiceModal] = useState();
  const [addBalanceModal, setAddBalanceModal] = useState();
  const [imageModal, setImageModal] = useState();

  const getAllShippersTransections = async (page, perPage, filter) => {
    const response = await fetchShipperPendingTransactions(
      page,
      perPage,
      filter
    );
    if (!response.error) {
      setTransections(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 1,
      label: "Payment Type",
      field_name: "payment_type",
      render: (row, rowIndex, handleFieldChange) => (
        <>
          {row.payment_type
            ? removeUnderscoresAndCapitalize(row.payment_type)
            : "-"}
        </>
      ),
      width: "200px",
    },
    {
      id: 2,
      label: "Transaction ID",
      field_name: "transaction_id",
      render: (row, rowIndex, handleFieldChange) => (
        <>{row?.transaction_id ?? "-"}</>
      ),
      width: "130px",
    },

    // {
    //   id: 3,
    //   label: "Bolt Tracking ID",
    //   field_name: "bolt_tracking_id",
    //   render: (row, rowIndex, handleFieldChange) => (
    //     <>{row.shipment?.tracking_id ?? "-"}</>
    //   ),
    //   width: "200px",
    // },
    {
      id: 4,
      label: "Amount",
      field_name: "amount",
      render: (row, rowIndex, handleFieldChange) => (
        <div
          style={{
            color:
              row?.transaction_type?.startsWith("credit") || true
                ? "teal"
                : "tomato",
            display: "flex",
          }}
        >
          <>
            {row.balance ?? "-"}
            <FaArrowAltCircleUp
              style={{ marginTop: "2px", marginLeft: "4px" }}
            />
          </>
        </div>
      ),
      width: "200px",
    },
    {
      id: 5,
      label: "Status",
      field_name: "status",
      render: (row, rowIndex, handleFieldChange) => (
        <>{row.status?.toUpperCase() ?? "-"}</>
      ),
      width: "200px",
    },
    {
      id: 6,
      label: "Date",
      field_name: "date",
      render: (row, rowIndex, handleFieldChange) => (
        <>{row.created_at?.slice(0, 10) ?? "-"}</>
      ),
      width: "140px",
    },
    {
      id: 7,
      label: "Reference",
      field_name: "reference",
      render: (row, rowIndex, handleFieldChange) => (
        <>
          {row?.shipment_id ? (
            <div className="table_action__wrap">
              <Tooltip title="Invoice">
                <div
                  className="table_action__btn"
                  onClick={() => setInvoiceModal(row?.shipment_id)}
                >
                  <MdOutlineStickyNote2 size={10} />
                  Invoice
                </div>
              </Tooltip>
            </div>
          ) : (
            <img
              src={row.file || noImage}
              style={{ cursor: "pointer" }}
              onClick={() => setImageModal(row.file || noImage)}
              onError={(e) => {
                e.target.src = noImage;
              }}
            />
          )}
        </>
      ),
      width: "200px",
    },
  ];

  return (
    <div>
      <div>
        <div className="payment__pending_header">
          <div>
            <span className="payment__pending_heading">Transaction List</span>
          </div>
        </div>
        <ImageModal open={imageModal} setOpen={setImageModal} />
        <InvoiceModal
          open={!!invoiceModal}
          data={invoiceModal}
          setOpen={setInvoiceModal}
          actions={false}
        />
        <AddBalance
          open={addBalanceModal}
          setOpen={setAddBalanceModal}
          setSnack={setSnack}
          getAllTransactions={() => getAllShippersTransections(1, 20, "")}
        />

        <ApprovedTransectionTable
          message={"No Transaction  Available To Display!"}
          searchPlaceHolder={"Search Transactions"}
          getAllData={getAllShippersTransections}
          data={transections}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default PendingPaymentList;
