import React from "react";
import "./Requirements.css";
const CanparRequirements = () => {
  return (
    <div className="cuscarrier__reqrmnts_box">
      Contact Canpar at
      <a href={`mailto:servicedesk@canpar.com`} className="email_space">
        servicedesk@canpar.com
      </a>
      to obtain your Shipper Number, User Id, and Password. These API
      credentials will allow you to connect your custom Canpar account to the
      application, enabling you to access Canpar's shipping rates for your
      account and display them as quotes.
    </div>
  );
};

export default CanparRequirements;
