import React from "react";
import { motion } from "framer-motion";
import Navbar from "react-bootstrap/Navbar";
import { Col, Container, Row } from "react-bootstrap";
import '../Customer.css';

function Privacypolicy() {
  return (
    <>
    {/* <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    > */}
      <Navbar
        sticky="top"
        expand="lg"
        // style={{ border: "2px solid #E2EDFB",}}
        className="  bg-white drop-shadow p-2"
      >
        <Navbar.Brand className="m-0 px-3">
          <small>
            <b>Privacy policy</b>
          </small>
        </Navbar.Brand>
      </Navbar>

      <Container fluid></Container>
    {/* </motion.div> */}
    </>
  );
}

export default Privacypolicy;
