import React, { useState, useEffect, useRef } from "react";
import SearchIcon from "../../../assets/icons/dispatcher-search.svg";
import SizeIcon from "../../../assets/icons/dispatcher-size.svg";
import downloadIcon from "../../../assets/icons/dispatcher-download.svg";
import { styled } from "@mui/material/styles";
import SmallIcon from "../../../assets/icons/dispatcher-stable.svg";
import MediumIcon from "../../../assets/icons/dispatcher-mtable.svg";
import LargeIcon from "../../../assets/icons/dispatcher-ltable.svg";
import Empty from "../../../assets/images/dispatcher-empty.gif";
import Logo from "../../../assets/images/hajex_logo.png";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import "./Invoices.css";
import TableSize1 from "./Components/TableSizes/TableSize1";
import TableSize2 from "./Components/TableSizes/TableSize2";
import TableSize3 from "./Components/TableSizes/TableSize3";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Confirm from "../../../Components/Popups/Confirm";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import CarrierTableLoader from "../../../Components/CarrierTableLoader/CarrierTableLoader";
import Sad from "@mui/icons-material/SentimentVeryDissatisfied";
import { fetchAllShipments } from "../../../services/shipments.services";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const initialCols = [
  {
    id: 1,
    name: "id",
    checked: true,
    label: "#",
    mediumWidth: "50px",
    largeWidth: "50px",
    width: "50px",
  },
  {
    id: 2,
    name: "Master Tracking",
    checked: true,
    label: " ",
    mediumWidth: "200px",
    largeWidth: "240px",
    width: "160px",
  },
  {
    id: 3,
    name: "Carrier Tracking",
    checked: true,
    mediumWidth: "200px",
    largeWidth: "240px",
    width: "160px",
  },
  {
    id: 4,
    name: "Carrier",
    checked: true,
    label: "#",
    mediumWidth: "120px",
    largeWidth: "140px",
    width: "160px",
  },
  {
    id: 5,
    name: "Reference ID",
    checked: true,
    mediumWidth: "150px",
    largeWidth: "170px",
    width: "120px",
  },
  {
    id: 6,
    name: "Pickup Date",
    checked: true,
    mediumWidth: "180px",
    largeWidth: "200px",
    width: "160px",
  },
  {
    id: 7,
    name: "Delivery Date",
    checked: true,
    infoBadge: true,
    mediumWidth: "180px",
    largeWidth: "200px",
    width: "160px",
  },
  {
    id: 8,
    name: "Status",
    checked: true,
    mediumWidth: "135px",
    largeWidth: "150px",
    width: "120px",
  },
  {
    id: 9,
    name: "Total",
    checked: true,
    mediumWidth: "130px",
    largeWidth: "140px",
    width: "120px",
  },
  {
    id: 10,
    name: "Action",
    checked: true,
    mediumWidth: "140px",
    largeWidth: "160px",
    width: "120px",
  },
  {
    id: 11,
    name: "Edit",
    checked: true,
    mediumWidth: "120px",
    largeWidth: "120px",
    width: "120px",
  },
  {
    id: 12,
    name: "Delete",
    checked: true,
    mediumWidth: "120px",
    largeWidth: "120px",
    width: "120px",
  },
];

const Invoices = () => {
  const [search, setSearch] = useState();
  const [optionModal, setOptionModal] = useState();
  const [colTab, setColTab] = useState(0);
  const [expandedRow, setExpandedRow] = useState();
  const [cols, setCols] = useState(initialCols);
  const [filteredCols, setFilteredCols] = useState(initialCols);
  const [checkedRows, setCheckedRows] = useState([]);
  const [shipments, setShipments] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [tableSize, setTableSize] = useState("medium");
  const [isEmpty, setEmpty] = useState(false);
  const [updateDriverModal2, setUpdateDriverModal2] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState();
  const user = useSelector((state) => state.user);
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    text: "",
    severity: "",
  });
  const handleOptionModal = (modal) => {
    if (modal === optionModal) {
      setOptionModal(false);
    } else {
      setOptionModal(modal);
    }
  };
  const handleColTab = (tab) => {
    setColTab(tab);
  };
  const handleTableSize = (size) => {
    handleOptionModal("size");
    setTableSize(size);
  };
  const handleColSearch = (e) => {
    let filtered = cols.filter((item) =>
      item.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredCols(filtered);
  };
  const handleSnackClose = () => {
    setSnack({ ...snack, open: false });
  };

  const handleRowChecked = (value) => {
    let arr = checkedRows;
    const index = arr.indexOf(value);
    if (index === -1) {
      arr.push(value);
    } else {
      arr.splice(index, 1);
    }
    setCheckedRows([...arr]);
  };

  const handleRowExpand = (id) => {
    setExpandedRow(expandedRow === id ? false : id);
  };
  const handleDriverChange = (type, shipmentId, driver) => {
    setShipments((preData) => {
      return preData.map((shipment) => {
        if (shipment.id === shipmentId) {
          if (type === "pickup") {
            return { ...shipment, pickup_driver: driver };
          } else {
            return { ...shipment, delivery_driver: driver };
          }
        } else {
          return shipment;
        }
      });
    });
  };

  const getAllShipments = async (page, preventLoading) => {
    !preventLoading && setLoading(true);
    const response = await fetchAllShipments(
      user.token,
      page ?? pagination.current_page
    );
    // console.log(response.data.result.data);
    if (!response.error) {
      const { data, ...newObject } = response.data.result.shipments;
      setPagination(newObject);
      setShipments(response.data.result.shipments.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllShipments(1);
  }, []);

  return (
    <div className="dispatcher__screen">
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.text}
        </Alert>
      </Snackbar>

      <div className="dispatcher__screen_searchwrap">
        <img
          src={SearchIcon}
          className="dispatcher__screen_searchicon"
          alt=""
        />
        <input
          type="text"
          placeholder="Search"
          className="dispatcher__screen_searchfield"
        />
      </div>
      <div className="dispatcher__screen_options">
        <div
          className="dispatcher__screen_option"
          onClick={() => handleOptionModal("size")}
        >
          <img src={SizeIcon} className="dispatcher__screen_optionicon" />
          <span className="dispatcher__screen_optiontext">SIZE</span>
          {optionModal === "size" && (
            <div
              className="dispscreen__size_modal"
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className="dispscreen__size_modalitem"
                onClick={() => handleTableSize("small")}
              >
                <div className="dispscreen__size_mileft">
                  <img src={SmallIcon} alt="" />
                </div>
                <div className="dispscreen__size_miright">Small</div>
              </div>
              <div
                className="dispscreen__size_modalitem"
                onClick={() => handleTableSize("medium")}
              >
                <div className="dispscreen__size_mileft">
                  <img src={MediumIcon} alt="" />
                </div>
                <div className="dispscreen__size_miright">Medium</div>
              </div>
              <div
                className="dispscreen__size_modalitem"
                onClick={() => handleTableSize("large")}
              >
                <div className="dispscreen__size_mileft">
                  <img src={LargeIcon} alt="" />
                </div>
                <div className="dispscreen__size_miright">Large</div>
              </div>
            </div>
          )}
        </div>
        {/* <div
          className="dispatcher__screen_option"
          onClick={() => setAddDriverModal(true)}
        >
          <AddIcon />
          <span className="dispatcher__screen_optiontext">Add Invoice</span>
        </div> */}
        {/* <div
          className={`dispatcher__screen_option ${
            checkedRows.length !== 1 && "dispatcher__soption_disabled"
          }`}
          onClick={() => setUpdateDriverModal(true)}
        >
          <EditIcon />
          &nbsp;
          <span className="dispatcher__screen_optiontext">Update Driver</span>
        </div>
        <div
          className={`dispatcher__screen_option ${
            checkedRows.length === 0 && "dispatcher__soption_disabled"
          }`}
          onClick={() => setDeleteModal(true)}
        >
          <DeleteIcon />
          <span className="dispatcher__screen_optiontext">Delete Driver</span>
        </div> */}
      </div>
      {shipments.length > 0 && tableSize === "small" && (
        <TableSize1
          list={shipments}
          loading={loading}
          pagination={pagination}
          getAllShipments={getAllShipments}
          setSnack={setSnack}
          handleRowChecked={handleRowChecked}
          handleRowExpand={handleRowExpand}
          checkedRows={checkedRows}
          cols={cols}
          isEmpty={isEmpty}
          vehicles={vehicles}
        />
      )}
      {shipments.length > 0 && tableSize === "medium" && (
        <TableSize2
          list={shipments}
          loading={loading}
          pagination={pagination}
          getAllShipments={getAllShipments}
          setSnack={setSnack}
          handleRowChecked={handleRowChecked}
          handleRowExpand={handleRowExpand}
          checkedRows={checkedRows}
          cols={cols}
          isEmpty={isEmpty}
          vehicles={vehicles}
        />
      )}
      {shipments.length > 0 && tableSize === "large" && (
        <TableSize3
          list={shipments}
          loading={loading}
          pagination={pagination}
          getAllShipments={getAllShipments}
          setSnack={setSnack}
          handleRowChecked={handleRowChecked}
          handleRowExpand={handleRowExpand}
          checkedRows={checkedRows}
          cols={cols}
          isEmpty={isEmpty}
          vehicles={vehicles}
        />
      )}
      {shipments.length < 1 && loading && (
        <div className="driver__screen_loading">
          <CarrierTableLoader />
        </div>
      )}
      {shipments.length < 1 && !loading && (
        <div className="driver__screen_empty">
          <div className="driver__scrnempt_content">
            <Sad className="driver__scrnempt_contenticon" />
            <div>No Records to display!</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Invoices;
