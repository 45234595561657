import React, { useState, useEffect } from "react";
import "./UpdateAccount.css";

// components imports
import Loader from "../../../../../../../Components/CircularLoader/CircularLoader";

// apis imports
import { updateAccountName } from "../../../../../../../services/ShipperServices/Carriers/carriers.services";

// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";

const initialData = {
  record_id: "",
  account_name: "",
};

export default function UpdateAccount({
  open,
  setOpen,
  setSnack,
  setCarriers,
  preData,
}) {
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setData(initialData);
    setOpen(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSave = async () => {
    setLoading(true);

    const response = await updateAccountName(data);
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Account Updated Successfully",
          severity: "success",
        };
      });
      setCarriers((prevState) => {
        return {
          ...prevState,
          data: prevState?.data.map((carr) => {
            return carr.id === data?.record_id ? response?.data?.result : carr;
          }),
        };
      });
    }

    setData(initialData);
    setLoading(false);
    handleClose();
  };
  useEffect(() => {
    if (preData) {
      setData({
        account_name: preData?.account_name ?? "",
        record_id: preData?.id,
      });
    }
  }, [preData]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="updatecc_account_modal">
          <div className="updatecc_account_modalheading">Update Account</div>

          <div className="updatecc_account_modalcontent">
            <div className="updatecc_account_modalrow">
              <div className="updatecc_account_modalinputwrap">
                <label className="updatecc_account_modallbl">
                  Account Name
                </label>
                <input
                  className="updatecc_account_modalinput"
                  placeholder="Enter New Account Name"
                  name="account_name"
                  value={data?.account_name}
                  onChange={handleChange}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="updatecc_account_modalfooter">
            <div className="updatecc_account_modalcncl" onClick={handleClose}>
              Cancel
            </div>
            <div className="updatecc_account_modalsave" onClick={handleSave}>
              {loading ? <Loader /> : "Save"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
