import React, { useRef, useEffect } from "react";
import PrintableSlip from "./PrintableSlip";
import html2pdf from "html2pdf.js";
import { FaRegTimesCircle } from "react-icons/fa";
import { MdArrowDownward } from "react-icons/md";
import Header from "./Components/Header";
import Details from "./Components/Details";
import Footer from "./Components/Footer";

const FullPageSlip = ({ data, setdata, dates }) => {
  const invoiceRef = useRef(null);

  function handleClick() {
    setdata(null);
  }

  const downloadPdf = async (data) => {
    const input = invoiceRef.current;
    const options = {
      filename: `TaxSlip.pdf`,
      format: "A4",
      jsPDF: { unit: "px", format: [1200, 1200], orientation: "p" },
      image: { type: "jpeg", quality: 0.98 },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      html2canvas: { scale: 2, useCORS: true },
    };

    await html2pdf().set(options).from(input).save();
  };

  return (
    <>
      {data && (
        <div className="fpinvoice__modal_wrap">
          <div
            style={{
              zIndex: "-1000",
              position: "absolute",
              visibility: "hidden",
              left: "-1000",
            }}
          >
            <PrintableSlip invoiceRef={invoiceRef} data={data} dates={dates} />
          </div>
          <div className="fpinvoice__modal_box" style={{ width: "auto" }}>
            <div className="invoice__modal_downloadinvoicewrap tax_slip_Action_btn">
              <button
                onClick={() => downloadPdf(data)}
                className="tax_slip_download_btn"
              >
                <MdArrowDownward size={16} />
              </button>
              <button
                onClick={handleClick}
                className="tax_slip_close_btn"
              >
                <FaRegTimesCircle size={16} />
              </button>
            </div>

            <Header data={data} dates={dates} />
            <Details data={data} />
            <Footer data={data} />
          </div>
        </div>
      )}
    </>
  );
};

export default FullPageSlip;
