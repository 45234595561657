import React, { useEffect, useState } from "react";
import "./Components.css";
import { MdErrorOutline } from "react-icons/md";
import Countries from "../../../data/countries";
import CustomDropdown from "../../Products/AddProduct/Components/CustomDropdown/CustomDropdown";
import CustomAutocomplete from "../../../Components/CustomAutoComplete/CustomAutoComplete";
import axios from "axios";
import AutoComplete from "./AutoComplete";

const DeliveryAddress = ({ data, setData, editMode }) => {
  const [states, setStates] = useState([]);
  const handleAddressChange = (name, value) => {
    setData({
      ...data,
      billing: { ...data?.billing, [name]: value },
      shipping:
        name !== "email"
          ? { ...data?.shipping, [name]: value }
          : { ...data?.shipping },
    });
  };
  const handleOrderID = (value) => {
    setData({
      ...data,
      order_id: value,
    });
  };
  const handlecountry = (value) => {
    let code = Countries.filter((cn) => cn.name === value)[0].code;
    setData({
      ...data,
      billing: {
        ...data?.billing,
        country: code,
        country_name: value,
        // state: "",
        // state_name: "",
      },
      shipping: {
        ...data?.shipping,
        country: code,
        country_name: value,
        // state: "",
        // state_name: "",
      },
    });
  };
  const handlestate = (value) => {
    let code = states.filter((cn) => cn.name === value)[0].state_code;
    setData({
      ...data,
      billing: {
        ...data?.billing,
        state: code,
        state_name: value,
      },
      shipping: {
        ...data?.shipping,
        state: code,
        state_name: value,
      },
    });
  };
  useEffect(() => {
    const GetStates = async () => {
      try {
        const response = await axios({
          method: "post",
          url: "https://countriesnow.space/api/v0.1/countries/states",
          data: {
            country: data.shipping?.country_name,
          },
        });
        setStates(response.data.data.states);
      } catch (error) {}
    };
    if (data.shipping?.country_name) {
      GetStates();
    }
  }, [data.shipping?.country_name]);
  // console.log("data?.shipping", data?.shipping);
  return (
    <div className="sss__address_section">
      {" "}
      <div className="sss__addressection_heading">
        DELIVERY ADDRESS (CONSIGNEE)
      </div>
      <div className="sss__addressection_inputrow">
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "200px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">
              Name / Attention
            </label>
            <input
              className="sss__addressection_inputfield"
              type="text"
              placeholder="Enter Name or Attention"
              onChange={(e) =>
                handleAddressChange("first_name", e.target.value)
              }
              value={data.shipping?.first_name}
            />
          </div>
        </div>
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "200px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">Company</label>
            <input
              className="sss__addressection_inputfield"
              type="text"
              placeholder="Enter Company"
              value={data.shipping?.company}
              onChange={(e) => handleAddressChange("company", e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="sss__addressection_inputrow">
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "200px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">Email</label>
            <input
              className="sss__addressection_inputfield"
              type="text"
              placeholder="Enter Email"
              onChange={(e) => handleAddressChange("email", e.target.value)}
              value={data.billing?.email}
            />
          </div>
        </div>
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "200px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">
              Phone Number
            </label>
            <input
              className="sss__addressection_inputfield"
              type="text"
              placeholder="Enter Phone Number"
              onChange={(e) => handleAddressChange("phone", e.target.value)}
              value={data.billing?.phone}
            />
          </div>
        </div>
      </div>
      <div className="sss__addressection_inputrow">
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "200px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">
              Full Address
            </label>
            <input
              className="sss__addressection_inputfield"
              type="text"
              placeholder="Enter Delivery Address"
              onChange={(e) => handleAddressChange("address_1", e.target.value)}
              value={data.shipping?.address_1}
            />
          </div>
        </div>
      </div>
      <div className="sss__addressection_inputrow">
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "200px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">Country</label>
            <AutoComplete
              Option={Countries.map((option) => option.name)}
              Handler={handlecountry}
              Value={data?.shipping?.country_name}
              Type="Country"
            />
            {/* <CustomAutocomplete
              type="object"
              displayKey="name"
              suggestions={Countries}
              placeholder="Search For Country"
              value={data?.shipping?.country_name || ""}
              onSelect={(country) => {
                setData({
                  ...data,
                  billing: {
                    ...data?.billing,
                    country: country.code,
                    country_name: country.name,
                  },
                  shipping: {
                    ...data?.shipping,
                    country: country.code,
                    country_name: country.name,
                  },
                });
              }}
            /> */}
          </div>
        </div>
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "120px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">State</label>
            <AutoComplete
              Option={states ? states.map((option) => option.name) : []}
              Handler={handlestate}
              Value={data?.shipping?.state_name}
              Type="State"
            />
            {/* <CustomAutocomplete
              type="object"
              displayKey="name"
              suggestions={states}
              placeholder="Search For State"
              initialValue={editMode ? data?.billing?.state_name : ""}
              onSelect={(state) => {
                setData({
                  ...data,
                  billing: {
                    ...data?.billing,
                    state: state.code,
                    state_name: state.name,
                  },
                  shipping: {
                    ...data?.shipping,
                    state: state.code,
                    state_name: state.name,
                  },
                });
              }}
            /> */}
          </div>
        </div>
      </div>
      <div className="sss__addressection_inputrow">
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "200px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">City/Town</label>
            <input
              className="sss__addressection_inputfield"
              type="text"
              placeholder="Enter City Name"
              onChange={(e) => handleAddressChange("city", e.target.value)}
              value={data.shipping?.city}
            />
          </div>
        </div>
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "120px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">Zip/Postal</label>
            <input
              className="sss__addressection_inputfield"
              type="text"
              placeholder="Enter Zip/Postal Code"
              onChange={(e) => handleAddressChange("postcode", e.target.value)}
              value={data.shipping?.postcode}
            />
          </div>
        </div>
      </div>
      <div className="sss__addressection_inputrow">
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "200px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">
              Order ID {!editMode && "(optional)"}
            </label>
            <input
              className={`sss__addressection_inputfield ${
                editMode ? "sss__addressection_inputfielddisabled" : ""
              }`}
              type="text"
              placeholder="If not provided it will be generated automatically"
              value={data?.order_id}
              onChange={(e) => handleOrderID(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryAddress;
