import React, { useState } from "react";
import "../../InvoiceModal.css";
import Attachments from "../Attachments/Attachments";
import { MdAttachment } from "react-icons/md";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import pdfIcon from "../../../../assets/icons/pdfIcon.png";
import excelIcon from "../../../../assets/icons/excelIcon.png";
import noImage from "../../../../assets/images/noImage.png";
import { checkFileType } from "../../../../helpers/helpers";
import ImagesModal from "../../../../Pages/Shipper/Customer/MainPages/Orders/Components/OrderHistoryModal/ImagesModal/ImagesModal";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const Notes = ({ data, type = "Show" }) => {
  const [attachmentsModal, setAttachmentsModal] = useState();
  const [fullview, setfullview] = useState();
  let customstyle = {
    marginTop: "20px",
    minHeight: "fit-content",
    height: "auto",
  };

  if (type === "Show") {
    customstyle = {};
  }

  return (
    <>
      <div className="invmodal__note_box" style={customstyle}>
        <ImagesModal setOpen={setfullview} open={fullview} />
        <div className="invmodal__note_boxhding">Notes</div>
        {type == "Show" ? (
          <div className="invmodal__note_boxdesc">
            {data?.shipment_charges?.map((charge, index) => (
              <div className="invmodal__note_boxdescitem" key={index}>
                {" "}
                {attachmentsModal === `client${index}` && (
                  <Attachments
                    onClose={() => setAttachmentsModal(false)}
                    attachments={charge?.media}
                    type="client"
                  />
                )}
                <div className="notes__attachments_modal_attachment_thumbnail_wrap">
                  <div className="notes__attachments_modal_attachment_thumbnail notes__attachments_modal_attachment_thumbnail2">
                    {charge?.description || charge?.media?.length ? (
                      <b>{`${index + 1} `}</b>
                    ) : (
                      ""
                    )}
                    {charge?.description ?? ""}
                    {charge?.media?.length
                      ? //  (
                        //   <LightTooltip
                        //     placement="top"
                        //     arrow
                        //     title="Pinned Attachments"
                        //   >
                        //     <span className="invmoda__note_atchmentdownloadwrap">
                        //       <MdAttachment
                        //         className="invmoda__note_atchmentdownload"
                        //         size={18}
                        //         onClick={() => setAttachmentsModal(`client${index}`)}
                        //       />
                        //       {charge?.media?.length ? (
                        //         <span className="invmodal__shippymnt_attachmenbadge2">
                        //           {charge?.media?.length ?? ""}
                        //         </span>
                        //       ) : (
                        //         ""
                        //       )}
                        //     </span>
                        //   </LightTooltip>
                        // )
                        charge?.media
                          ?.filter((attachment) => attachment?.meta === "admin")
                          .map((attachment, index) => (
                            <img
                              className="note_attachment"
                              key={attachment?.id}
                              src={
                                attachment?.file &&
                                (checkFileType(attachment.file) === "Image"
                                  ? attachment.file
                                  : checkFileType(attachment.file) === "PDF"
                                  ? pdfIcon
                                  : checkFileType(attachment.file) === "Excel"
                                  ? excelIcon
                                  : noImage)
                              }
                              alt={`Attachment ${index + 1}`}
                              onError={(e) => {
                                e.target.src = noImage;
                              }}
                              onClick={() => {
                                if (
                                  checkFileType(attachment.file) === "Image"
                                ) {
                                  setfullview(attachment?.file || "");
                                } else {
                                  window.open(attachment?.file, "_blank");
                                }
                              }}
                            />
                          ))
                      : ""}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div
            className="invmodal__note_boxdesc"
            style={{ minHeight: "fit-content" }}
          >
            {data?.shipment_charges?.map((charge, index) => (
              <div className="invmodal__note_boxdescitem" key={index}>
                {charge?.description && <b>{`${index + 1} `}</b>}
                {charge?.description ?? ""}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Notes;
