import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import { MdOutlineCancel, MdArrowBack } from "react-icons/md";
import "./ImagesModal.css";
import noImage from "../../../../../../../../assets/images/noImage.png";

export default function ImagesModal({ open, setOpen }) {
  const [data, setData] = useState({});

  const handleClose = () => {
    setOpen(false);
    setData({});
  };

  useEffect(() => {
    setData(open);
  }, [open]);

  return (
    <>
      {open && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <div className="prdtable__images_modal note__attachment_modal_container">
            <div className="prdtable__images_fullimgview note__attachment_modal_wrap">
            <MdOutlineCancel
              size={25}
              className="prdtable__0rder_attachments_modalclose"
              onClick={handleClose}
            />
              <img
                src={data || noImage}
                onError={(e) => {
                  e.target.src = noImage;
                }}
              />
            </div>

          </div>
        </Modal>
      )}
    </>
  );
}
