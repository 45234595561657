import React, { useState, useEffect } from "react";
import "./approvedpaymentlist.css";
// components imports
import ApprovedTransectionTable from "../../../../../../../Components/BoltTable/BoltTable";
import InvoiceModal from "../../../../../../../Components/InvoiceModal/InvoiceModal";
import AddBalance from "../AddBalance/AddBalance";

// apis imports
import { fetchShipperTransactions } from "../../../../../../../services/ShipperServices/Payments/payments.services";
import { removeUnderscoresAndCapitalize } from "../../../../../../../helpers/helpers";

// library imports
import { useSelector } from "react-redux";
import { MdOutlineStickyNote2 } from "react-icons/md";
import CircularProgress from "@mui/material/CircularProgress";
import {
  FaPlus,
  FaArrowAltCircleUp,
  FaArrowAltCircleDown,
} from "react-icons/fa";

const ApprovedPaymentList = ({ setSnack }) => {
  const user = useSelector((state) => state.user);

  const [transactions, setTransactions] = useState([]);
  const [invoiceLoader, setinvoiceLoader] = useState(false);

  const [invoiceModal, setInvoiceModal] = useState();
  const [addBalanceModal, setAddBalanceModal] = useState();

  const getAllShippersTransactions = async (page, perPage, filter) => {
    const response = await fetchShipperTransactions(page, perPage, filter);
    if (!response.error) {
      setTransactions(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "130px",
    },
    {
      id: 1,
      label: "Transaction ID",
      field_name: "transaction_no",
      render: (row, rowIndex, handleFieldChange) => (
        <>{row?.transaction_no ?? "-"}</>
      ),
      width: "130px",
    },

    {
      id: 3,
      label: "Method",
      field_name: "method",
      render: (row, rowIndex, handleFieldChange) => (
        <>
          {row.transaction_type
            ? removeUnderscoresAndCapitalize(row.transaction_type)
            : "-"}
        </>
      ),
      width: "200px",
    },
    {
      id: 4,
      label: "Payment Type",
      field_name: "payment_type",
      render: (row, rowIndex, handleFieldChange) => (
        <>
          {row.payment_type
            ? removeUnderscoresAndCapitalize(row.payment_type)
            : "-"}
        </>
      ),
      width: "200px",
    },
    // {
    //   id: 5,
    //   label: "Bolt Tracking ID",
    //   field_name: "bolt_tracking_id",
    //   render: (row, rowIndex, handleFieldChange) => (
    //     <>{row.shipment?.tracking_id ?? "-"}</>
    //   ),
    //   width: "200px",
    // },
    {
      id: 6,
      label: "Amount",
      field_name: "amount",
      render: (row, rowIndex, handleFieldChange) => (
        <div
          style={{
            color: row?.transaction_type?.startsWith("credit")
              ? "teal"
              : "tomato",
            display: "flex",
          }}
        >
          <>
            {row.amount ?? "-"}
            {row?.transaction_type?.startsWith("credit") ? (
              <FaArrowAltCircleUp
                style={{ marginTop: "2px", marginLeft: "4px" }}
              />
            ) : (
              <FaArrowAltCircleDown
                style={{ marginTop: "2px", marginLeft: "4px" }}
              />
            )}
          </>
        </div>
      ),
      width: "200px",
    },
    {
      id: 7,
      label: "Balance",
      field_name: "balance",
      render: (row, rowIndex, handleFieldChange) => (
        <>{row.user_balance ?? "-"}</>
      ),
      width: "200px",
    },
    {
      id: 2,
      label: "Date",
      field_name: "date",
      render: (row, rowIndex, handleFieldChange) => (
        <>{row.created_at?.slice(0, 10) ?? "-"}</>
      ),
      width: "140px",
    },
    {
      id: 7,
      label: "Invoice",
      field_name: "invoice",
      render: (row, rowIndex, handleFieldChange) => (
        <div className="table_action__wrap">
          <div
            className={`table_action__btn   ${
              !row.shipment_id &&
              !row.formula_invoice &&
              !row.file &&
              "table_action__btn__disabled"
            }`}
            onClick={() => {
              if (row.shipment_id) {
                setInvoiceModal(row.shipment_id);
              } else {
                if (row.formula_invoice?.file) {
                  downloadPdf(row.id, row?.formula_invoice?.file);
                } else {
                  downloadPdf(row.id, row?.file);
                }
              }
            }}
          >
            {invoiceLoader === row.id ? (
              <CircularProgress size={10} />
            ) : (
              <MdOutlineStickyNote2 size={10} />
            )}
            Invoice
          </div>
        </div>
      ),
      width: "200px",
    },
    {
      id: 8,
      label: "Description",
      field_name: "description",
      render: (row, rowIndex, handleFieldChange) => (
        <>{row.description ?? "-"}</>
      ),
      width: "200px",
    },
  ];

  const downloadPdf = async (id, urlfull) => {
    setinvoiceLoader(id);
    const urlParts = urlfull.split("/");
    const filename = urlParts[urlParts.length - 1];
    try {
      fetch(urlfull, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      })
        .then((url) => url.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${filename}`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setinvoiceLoader(false);
        });
    } catch (error) {
      setinvoiceLoader(false);
    }
  };

  return (
    <div>
      <div>
        <div className="payment__approve_header">
          <div>
            <span className="payment__approve_heading">Transaction List</span>
          </div>
          <div className="">
            <div className="payment__approve_box">
              <div className="payment__approve_boxleft">
                <div className="payment__approve_boxleftlbl">
                  Available Balance
                </div>
                <div className="payment__approve_boxleftprice">
                  ${user.balance}
                </div>
              </div>

              <div className="payment__approve_boxright">
                <div
                  className="payment__approve_boxbtn"
                  onClick={() => setAddBalanceModal(true)}
                >
                  <FaPlus color="#0c6efd" size={20} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <InvoiceModal
          open={!!invoiceModal}
          data={invoiceModal}
          setOpen={setInvoiceModal}
          actions={false}
        />
        <AddBalance
          open={addBalanceModal}
          setOpen={setAddBalanceModal}
          setSnack={setSnack}
          getAllTransactions={() => getAllShippersTransactions(1, 20, "")}
        />

        <ApprovedTransectionTable
          message={"No Transactions Available To Display!"}
          searchPlaceHolder={"Search Transactions"}
          getAllData={getAllShippersTransactions}
          data={transactions}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default ApprovedPaymentList;
