import React, { useState } from "react";
import "./landingcompanydetails.css";
// components imports
import UnitsTable from "../../../../../Components/BoltTable/BoltTable";
import CompanyDetails from "../../Components/CompanyDetails/CompanyDetails";
import UpdateDetails from "../../Components/UpdateDetails/UpdateDetails";
import Tooltip from "../../../../../Components/Tooltip/Tooltip";

// apis imports
import { fetchCompaniesDetails } from "../../../../../services/admin.services";
import { truncateString } from "../../../../../helpers/helpers";
// library imports
import noImage from "../../../../../assets/images/noImage.png";
import Flag from "react-world-flags";
import { MdRemoveRedEye } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";

const LandingCompanyDetails = ({ setSnack }) => {
  const [companyDetails, setCompanyDetails] = useState([]);
  const [companyDetailsModal, setCompanyDetailsModal] = useState();
  const [updateDetailsModal, setUpdateDetailsModal] = useState();

  const columns = [
    {
      id: 1,
      label: "Logo",
      render: (row) => (
        <>
          <img
            src={row?.logo_url ?? noImage}
            onError={(e) => {
              e.target.src = noImage;
            }}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 2,
      label: "Name",
      render: (row) => <>{row?.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "About",
      render: (row) => (
        <>
          {row.rating_setting &&
            truncateString(row.rating_setting[1]?.description, 20)}
        </>
      ),
      width: "300px",
    },
    {
      id: 4,
      label: "History",
      render: (row) => (
        <>
          {row.rating_setting &&
            truncateString(row.rating_setting[0]?.description, 20)}
        </>
      ),
      width: "300px",
    },
    {
      id: 5,
      label: "Countries",
      render: (row) => (
        <>
          {row?.rating_country ? (
            <div className="custabscreen__theader_cntryflags">
              {row?.rating_country?.map((country) => (
                <div className="custabscreen__theader_flagwrap">
                  <Flag
                    code={country?.name}
                    fallback={<span>--</span>}
                    style={{
                      height: "100%",

                      width: "100%",
                    }}
                  />
                </div>
              ))}
            </div>
          ) : (
            "-"
          )}
        </>
      ),
      width: "200px",
    },
    {
      id: 6,
      label: "Actions",
      render: (row) => (
        <div className="table_action__wrap">
          <Tooltip title="View Company Details">
            <div
              className="table_action__btn"
              onClick={() => setCompanyDetailsModal(row)}
            >
              <MdRemoveRedEye size={10} />
              Details
            </div>
          </Tooltip>
          <Tooltip title="Edit Company Details">
            <div
              className="table_action__btn"
              onClick={() => setUpdateDetailsModal(row)}
            >
              <FaRegEdit size={10} />
              Edit
            </div>
          </Tooltip>
        </div>
      ),
      width: "200px",
    },
  ];

  const getCompanyDetails = async (page, perPage, filter) => {
    const response = await fetchCompaniesDetails(page, perPage, filter);
    if (!response.error) {
      setCompanyDetails(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  return (
    <>
      <div className="l_c_d_header">
        <div>
          <span className="l_c_d_hdrheading">Landing-Company-Details</span>
        </div>
      </div>

      <div className="l_c_d__main_wrap">
        <div className="l_c_d__table_list">
          <div>
            <UnitsTable
              message={"No Landing Company Available To Display!"}
              searchPlaceHolder="Search Landing Companies"
              columns={columns}
              data={companyDetails}
              getAllData={getCompanyDetails}
            />
          </div>
        </div>
      </div>
      <CompanyDetails
        open={!!companyDetailsModal}
        companyData={companyDetailsModal}
        setOpen={setCompanyDetailsModal}
      />
      <UpdateDetails
        open={!!updateDetailsModal}
        companyData={updateDetailsModal}
        setOpen={setUpdateDetailsModal}
        setSnack={setSnack}
        setCompanyDetails={setCompanyDetails}
      />
    </>
  );
};

export default LandingCompanyDetails;
