import React, { useState, useEffect } from "react";
import "./fulfilmentbyweight.css";
// components imports
import AddDetailsByWeight from "./Components/AddDetailsByWeight/AddDetailsByWeight";
import TextFeild from "../CustomTextFeild/TextFeild";
// apis imports
import {
  addFulfilmentTierFormula,
  updateFulfilmentTierFormula,
} from "../../../../../../../services/warehouse.services";
// library imports
import { MdClose, MdOutlineErrorOutline } from "react-icons/md";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import Loader from "../../../../../../../Components/CircularLoader/CircularLoader.jsx";

const FulfilmentByWeight = ({
  open,
  setOpen,
  formulaCoppy,
  setFormulaCoppy,
  tierFormula,
  setTierFormula,
  isEditable,
  error,
  setError,
  setIsEditable,
  setSnack,
}) => {
  const user = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);
  const [tierFlag, setTierFlag] = useState(true);
  const [lastDetailedIndexData, setLastDetailedIndexData] = useState(null);
  const [weightDetails, setWeightDetails] = useState({
    min: "",
    max: "",
    value: "",
  });
  const handleChangeDetails = (e) => {
    setError(false);
    setWeightDetails({ ...weightDetails, [e.target.name]: e.target.value });
  };

  const handleFieldChangeWeightFormula = (e, index) => {
    setTierFormula((formula) => {
      const clone = [...formula.formula_details];
      const updatedWeightDetails = {
        ...clone[index],
        value: e.target.value,
      };
      clone[index] = updatedWeightDetails;

      return {
        ...formula,
        formula_details: clone,
      };
    });
  };

  const addDetail = (e) => {
    e.preventDefault();
    if (weightDetails?.min === "" || weightDetails?.max === "") {
      setError("Please fill all feilds");
      return;
    }
    if (
      Number(weightDetails?.min) <
      Number(
        tierFormula?.formula_details[tierFormula?.formula_details.length - 1]
          ?.max
      )
    ) {
      setError("min must be grateer then last max");
      return;
    }
    if (Number(weightDetails.max) <= Number(weightDetails.min)) {
      setError("max must be grater then min");
      return;
    }

    if (lastDetailedIndexData != null) {
      setTierFormula((formula) => ({
        ...formula,
        formula_details: [
          {
            ...weightDetails,
            id: lastDetailedIndexData.id,
            formula_id: lastDetailedIndexData.formula_id,
          },
        ],
      }));
      setLastDetailedIndexData(null);
    } else {
      setTierFormula((formula) => ({
        ...formula,
        formula_details: [...tierFormula?.formula_details, weightDetails],
      }));
    }
  };
  const handleRemove = (id, indexToRemove) => {
    if (indexToRemove === 0) {
      setLastDetailedIndexData(tierFormula.formula_details[0]);
    }
    setTierFormula((formula) => {
      const clone = [...formula.formula_details];
      clone.splice(indexToRemove, 1);
      return {
        ...formula,
        formula_details: clone,
        deletedDetailsIds:
          id && indexToRemove != 0
            ? [...formula.deletedDetailsIds, id]
            : [...formula.deletedDetailsIds],
      };
    });
  };

  const createFulfilmentTierFormula = async (e) => {
    e.preventDefault();
    if (tierFormula?.formula_details?.length === 0) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please add atleast 1 formula  detail",
          severity: "error",
        };
      });
      return;
    }
    setLoader(true);
    const response = await addFulfilmentTierFormula(open?.shipper_id, {
      type: "by_weight",
      unit: tierFormula?.unit,
      formulaDetails: tierFormula?.formula_details,
    });
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text:
            response?.error?.response?.data?.message ?? "Something went wrong",
          severity: "error",
        };
      });
      setLoader(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response.data.message,
          severity: "success",
        };
      });
      // setFormulaCoppy({ ...tierFormula });
      setIsEditable(false);
      setFormulaCoppy({
        ...response.data.result,
        dimensions: [],
        deletedDimensionIds: [],
      });
      setTierFormula({
        ...response.data.result,
        dimensions: [],
        deletedDimensionIds: [],
      });
      setLoader(false);
    }
  };
  const updateTierFormula = async (e) => {
    e.preventDefault();
    if (tierFormula?.formula_details?.length === 0) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please add atleast 1 formula  detail",
          severity: "error",
        };
      });
      return;
    }
    setLoader(true);

    const response = await updateFulfilmentTierFormula(tierFormula?.id, {
      type: "by_weight",
      unit: tierFormula?.unit,
      formulaDetails: tierFormula?.formula_details,
      deletedDetailsIds: tierFormula?.deletedDetailsIds,
    });
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text:
            response?.error?.response?.data?.message ?? "Something went wrong",
          severity: "error",
        };
      });
      setLoader(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response.data.message,
          severity: "success",
        };
      });

      setFormulaCoppy({
        ...tierFormula,
        deletedDimensionIds: [],
        deletedDetailsIds: [],
      });
      setIsEditable(false);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (tierFormula) {
      let nextMIN = Number(
        tierFormula?.formula_details[tierFormula?.formula_details?.length - 1]
          ?.max
      );
      let nextMAX =
        (tierFormula?.formula_details?.length <= 1 ? 1 : 0) +
        nextMIN +
        Number(
          tierFormula?.formula_details[tierFormula?.formula_details?.length - 1]
            ?.max
        ) -
        Number(
          tierFormula?.formula_details[tierFormula?.formula_details?.length - 1]
            ?.min
        );
      setWeightDetails({
        ...weightDetails,
        min: nextMIN ? nextMIN : "1",
        max: nextMAX ? nextMAX : "50",
      });
    }
  }, [tierFormula, isEditable]);

  return (
    <>
      <Form
        onSubmit={
          tierFormula?.id === null || tierFormula?.id === "empty"
            ? createFulfilmentTierFormula
            : updateTierFormula
        }
      >
        {isEditable === true && (
          <>
            <div className="fulfilment_byweight__kg_lb_label">
              Select weight
            </div>
            <div className="fulfilment_byweight__kg_lb_wrapper">
              <div
                className={`${
                  tierFormula?.unit === "kg"
                    ? "fulfilment_byweight__kg_lb fulfilment_byweight__kg_lb_active"
                    : "fulfilment_byweight__kg_lb"
                } }`}
                onClick={() => {
                  setTierFormula((formula) => ({
                    ...formula,
                    unit: "kg",
                  }));
                }}
              >
                KG
              </div>
              <div
                className={`${
                  tierFormula?.unit === "lb"
                    ? "fulfilment_byweight__kg_lb fulfilment_byweight__kg_lb_active"
                    : "fulfilment_byweight__kg_lb"
                } }`}
                onClick={() => {
                  setTierFormula((formula) => ({
                    ...formula,
                    unit: "lb",
                  }));
                }}
              >
                LB
              </div>
            </div>
            <div className="fulfilment_byweight__addwrapper">
              <div>
                <AddDetailsByWeight
                  addDetail={addDetail}
                  formulaDetails={weightDetails}
                  tierFormula={tierFormula}
                  handleChangeDetails={handleChangeDetails}
                />
              </div>
            </div>
            <div
              style={{ justifyContent: "start", padding: "0px" }}
              className={`fulfilment_byweight_modalerrorwrap ${
                error && "fulfilment_byweight_modalerrorwrapopen"
              }`}
            >
              <div className="fulfilment_byweight_modalerrorbox">
                <span>
                  <MdOutlineErrorOutline
                    size={16}
                    style={{ marginTop: "2px" }}
                  />
                </span>
                {error}
              </div>
            </div>
          </>
        )}

        <div className="fulfilment_byweight_box_wrapper">
          <div className="fulfilment_byweight_formbox1">
            <h5 className="fulfilment_byweight_formboxheading">
              <div>Fulfilment Tier Details</div>
            </h5>

            <div className="customer_formula_value_table">
              <table id="customers" className="w-50">
                <tr>
                  <th className="fulfilment_byweight_cell__width">
                    Min ({tierFlag === true ? "KG" : "LB"})
                  </th>
                  <th className="fulfilment_byweight_cell__width">
                    Max ({tierFlag === true ? "KG" : "LB"})
                  </th>
                  <th className="fulfilment_byweight_cell__width">Price</th>
                  <th className="fulfilment_byweight_cell__width">Action</th>
                </tr>
                {tierFormula?.formula_details?.map((detail, index) => (
                  <tr>
                    <td>{detail.min}</td>
                    <td>
                      {index === tierFormula?.formula_details?.length - 1 ? (
                        <span style={{ fontSize: "30px" }}>∞</span>
                      ) : (
                        detail.max
                      )}
                    </td>
                    <td>
                      <TextFeild
                        PlaceHolder={"Price"}
                        Data={detail.value}
                        Disabled={isEditable ? false : true}
                        Handler={(e) =>
                          handleFieldChangeWeightFormula(e, index)
                        }
                      />
                    </td>
                    <td>
                      {tierFormula?.formula_details?.length - 1 === index &&
                        isEditable && (
                          <div className="addprod__seccancel_btnwrap">
                            <button
                              className="fulfilment_byweight_cancel_btn"
                              onClick={(e) => {
                                handleRemove(detail?.id, index);
                              }}
                            >
                              <MdClose size={22} />
                            </button>
                          </div>
                        )}
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
        {isEditable === true && (
          <div className="fulfilment_byweight_modalfooter">
            <button
              className="fulfilment_byweight_modalcncl"
              onClick={(e) => {
                e.preventDefault();
                setIsEditable(false);
                setTierFormula((formula) => ({
                  ...formula,
                  id: formulaCoppy?.id,
                  type: formulaCoppy?.type,
                  unit: formulaCoppy?.unit,
                  formula_details: [...formulaCoppy?.formula_details],
                  dimensions: [...formulaCoppy?.dimensions],
                }));
              }}
            >
              Cancel
            </button>

            <button className="fulfilment_byweight_modalsave">
              {loader ? <Loader /> : "Save"}
            </button>
          </div>
        )}
      </Form>
    </>
  );
};

export default FulfilmentByWeight;
