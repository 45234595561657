import ListGroup from "react-bootstrap/ListGroup";
import Tooltip from "../../../../../../../Components/Tooltip/Tooltip";
import { GoPackage } from "react-icons/go";
import React, { useEffect, useState } from "react";
import { setdata } from "../../../../../../../helpers/helpers";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import Sad from "@mui/icons-material/SentimentVeryDissatisfied";
import noImage from "../../../../../../../assets/images/noImage.png";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { fetchOnlyProducts } from "../../../../../../../services/product.service";

export default function ProductGridView(props) {
  const { StepCheck, handlescrollpackage, setFilter } = props;

  const [searchProducts, setsearchProducts] = useState("");
  const [Productsloader, setProductsloader] = useState(false);
  const [Products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handlePrevPage = () => {
    setPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const getAllProducts = async () => {
    setProductsloader(true);
    const response = await fetchOnlyProducts(page, 24, searchProducts);

    if (!response.error) {
      setTotalPages(
        Math.ceil(
          response?.data?.result?.total / response?.data?.result?.per_page
        )
      );
      setProducts(response?.data?.result?.data);
      setProductsloader(false);
    } else {
      setProductsloader(false);
    }
  };

  const handleProductchange = (e) => {
    const { value } = e.target;
    setsearchProducts(value);
  };

  useEffect(() => {
    getAllProducts();
  }, [page, searchProducts]);

  useEffect(() => {
    const filterElement = (
      <>
        {(Productsloader === true ||
          searchProducts !== "" ||
          totalPages > 0) && (
          <div className="product__scrollbar_list_filter_field_wrap">
            <input
              type="text"
              value={searchProducts}
              onChange={(e) => handleProductchange(e)}
              placeholder="Filter Products"
              className="product__scrollbar_list_filter_field"
            />
            {Productsloader === true && (
              <CircularProgress
                size={12}
                style={{
                  color: "#C1C1C1",
                  marginLeft: "-20px",
                  marginTop: "15px",
                  transform: "translateY(-50%)",
                }}
              />
            )}
          </div>
        )}
      </>
    );

    setFilter(filterElement);
  }, [Productsloader, searchProducts, totalPages]);

  return (
    <>
      {totalPages > 0 ? (
        <div className="d-flex flex-row mt-2">
          <IconButton
            className="mr-1"
            disabled={page === 1}
            onClick={handlePrevPage}
          >
            <FaChevronLeft />
          </IconButton>
          <ListGroup
            horizontal
            className="overflow-auto horiscl p-2 product__scroll_listgroup"
            style={{
              maxHeight: "108px",
              cursor: "pointer",
              display: "-webkit-box",
              overflowX: "auto",
              overflowY: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              WebkitScrollbarTrack: {
                background: "#000000",
              },
            }}
          >
            {Products.map((data, index) => (
              <>
                <ListGroup.Item
                  className={
                    StepCheck["Warehouses"]
                      ? "mr-2 mb-1 shadow single_list product__scroll_list_box"
                      : "mr-2 mb-1 shadow single_list product__scroll_list_box select_none_card"
                  }
                  style={{
                    width: "150px",
                    border: "1px solid #C8C9CA",
                    borderRadius: "4px",
                    padding: "2px",
                    flexShrink: 0,
                  }}
                  onClick={() => handlescrollpackage(data)}
                  key={index}
                >
                  {StepCheck["Warehouses"] ? (
                    ""
                  ) : (
                    <Tooltip
                      title={
                        StepCheck["Warehouses"]
                          ? "Click To Add"
                          : "Select Warehouse"
                      }
                      key={index}
                    >
                      <span className="non_selected_warehouse"></span>
                    </Tooltip>
                  )}
                  <div className="productList__card_wrap">
                    <Tooltip
                      title={setdata(() => data.name, false, "")}
                      key={index}
                      className="productList__card_wrap_main_box"
                    >
                      <div className="productList__card_details_wrap">
                        <div className="productList__card_details_img">
                          {data.media ? (
                            <img
                              src={
                                data?.media.find(
                                  (media) => media.is_primary === "true"
                                )?.file ||
                                data?.media[0]?.file ||
                                noImage
                              }
                              onError={(e) => {
                                e.target.src = noImage;
                              }}
                              loading="lazy"
                            />
                          ) : (
                            <GoPackage size={40} style={{ color: "#C8C9CA" }} />
                          )}
                        </div>
                        <div className="productList__card_title">
                          <span>{setdata(() => data.name, false, "")}</span>
                        </div>
                        <div className="productList__card_details_measures">
                          <div className="productList__card_details_measures_LWH">
                            {data?.product_warehouse?.length > 0 && (
                              <>
                                {data?.product_warehouse?.map((data, index) => {
                                  return (
                                    <div
                                      className="productList__card_details_measures_L"
                                      style={{
                                        fontWeight:
                                          StepCheck["Warehouses"] ===
                                          data?.warehouse_id
                                            ? "bold"
                                            : "",
                                      }}
                                      key={index}
                                    >
                                      #{data.warehouse_id}
                                      <span>{data?.quantity}</span>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                </ListGroup.Item>
              </>
            ))}
          </ListGroup>
          <IconButton
            className="mr-1"
            disabled={page === totalPages || totalPages === 0}
            onClick={handleNextPage}
          >
            <FaChevronRight />
          </IconButton>
        </div>
      ) : (
        <div
          className="driver__screen_empty"
          style={{ minHeight: "12vh", width: "100%" }}
        >
          <div className="driver__scrnempt_content">
            <Sad className="driver__scrnempt_contenticon" />
            <div>No Products to display!</div>
          </div>
        </div>
      )}
    </>
  );
}
