import React, { useRef, useState } from "react";
import "./AddProduct.css";
import { BiArrowBack } from "react-icons/bi";
import InfoSection from "./Sections/InfoSection";
import MediaSection from "./Sections/MediaSection";
import TableOfContent from "../Components/TableOfContent/TableOfContent";
import ClientSection from "./Sections/ClientSection";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DimensionsSection from "./Sections/DimensionsSection";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  createWarehouseProduct,
  fetchRawProductData,
  updateProduct,
} from "../../../services/product.service";
import CircularProgress from "@mui/material/CircularProgress";
import { showSnackBar } from "../../../store/common/snackBarSlice";
import { useDispatch } from "react-redux";
import CategorySection from "./Sections/CategorySection";
const initialContentList = [
  {
    heading: "Table of Content",
    menus: [
      {
        name: "Basic Information",
        id: "addproduct_basic_info",
      },
      {
        name: "Product's Media",
        id: "addproduct_media",
      },
      {
        name: "Dimensions",
        id: "addproduct_dimensions",
      },
    ],
  },
];
const initialFormData = {
  code: "",
  title: "",
  description: "",
  media: null,
  length: "",
  width: "",
  height: "",
  weight: "",
  currency: "cad",
  dimension_unit: "",
  weight_unit: "",
  user: "",
  brand: "",
  category: "",
  sku: "",
};

const AddWarehouseProduct = () => {
  const [contentList, setContentList] = useState(initialContentList);
  const [errors, setErrors] = useState({});
  const [uploadedImages, setUploadedImages] = useState([]);
  const [savingProduct, setSavingProduct] = useState(false);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [formData, setFormData] = useState(initialFormData);
  const [packageDetails, setPackageDetails] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [productBrands, setProductBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");

  const [mode, setMode] = useState("add");

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  function navigateToSection(data) {
    let sectionId = null;

    if (data.title || data.description || data.code) {
      sectionId = "addproduct_basic_info";
    } else if (data.regular_price || data.sales_price) {
      sectionId = "addproduct_pricing";
    } else if (
      data.length ||
      data.width ||
      data.height ||
      data.weight ||
      data.unit
    ) {
      sectionId = "addproduct_dimensions";
    } else if (data.children) {
      sectionId = "addproduct_variants";
    } else if (data.category || data.sub_category || data.type) {
      sectionId = "addproduct_basic_category";
    } else if (data.user) {
      sectionId = "addproduct_basic_status";
    } else if (data.warehouse_ids) {
      sectionId = "addproduct_basic_category";
    }

    if (sectionId) {
      window.location.hash = sectionId;
    }

    return sectionId;
  }

  function processErrorResponse(errorResponse) {
    const { message, success, request, ...rest } = errorResponse;
    const result = {};
    for (const key in rest) {
      if (Array.isArray(rest[key]) && rest[key].length > 0) {
        result[key] = { msg: rest[key][0] };
      }
    }
    navigateToSection(result);
    return result;
  }
  const fieldsToCheck = [
    "title",
    "code",
    "dimension_unit",
    "weight_unit",
    "user",
    "sku",
  ];
  function checkEmptyFields(inputObject, fieldsToValidate) {
    const emptyFields = {};

    function checkFieldsRecursive(obj, path = "") {
      for (const key in obj) {
        const fieldPath = path ? `${path}.${key}` : key;
        const value = obj[key];

        if (fieldsToValidate.includes(fieldPath)) {
          if (
            value === null ||
            value === undefined ||
            value === "" ||
            value?.length === 0
          ) {
            emptyFields[fieldPath] = {
              msg: `Field is required.`,
            };
          }
        }
      }
    }

    checkFieldsRecursive(inputObject);
    navigateToSection(emptyFields);
    setErrors(emptyFields);
    if (Object.keys(emptyFields).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  const createFormData = (payload) => {
    const formData = new FormData();

    const replaceUndefinedWithEmptyString = (value) => {
      return value === undefined ? "" : value;
    };

    const appendToFormData = (key, value) => {
      value = replaceUndefinedWithEmptyString(value);

      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (typeof item === "object") {
            for (const itemKey in item) {
              formData.append(
                `${key}[${index}][${itemKey}]`,
                replaceUndefinedWithEmptyString(item[itemKey])
              );
            }
          } else {
            formData.append(
              `${key}[${index}]`,
              replaceUndefinedWithEmptyString(item)
            );
          }
        });
      } else if (typeof value === "object" && value !== null) {
        for (const nestedKey in value) {
          formData.append(
            `${key}[${nestedKey}]`,
            replaceUndefinedWithEmptyString(value[nestedKey])
          );
        }
      } else {
        formData.append(key, value);
      }
    };

    for (const key in payload) {
      if (key === "product_variations") {
        payload[key].forEach((variation, index) => {
          for (const variationKey in variation) {
            appendToFormData(
              `product_variations[${index}][${variationKey}]`,
              variation[variationKey]
            );
          }
        });
      } else {
        appendToFormData(key, payload[key]);
      }
    }

    return formData;
  };

  const handleSaveProduct = async () => {
    let valid = checkEmptyFields(formData, fieldsToCheck);

    if (!valid) {
      return;
    }
    const payload = {
      product_sku: formData?.sku,
      code: formData?.code,
      name: formData?.title,
      description: formData?.description,
      category_id: formData?.category?.value ?? "",
      full_name: formData?.title,
      length: formData?.length,
      width: formData?.width,
      height: formData?.height,
      weight: formData?.weight,
      currency: formData?.currency,
      package_details: formData?.package_details ?? [],
      dimension_unit: formData?.dimension_unit?.value ?? "",
      weight_unit: formData?.weight_unit?.value ?? "",
      media: formData?.media ?? [],
      brand_id: formData?.brand?.value ?? "",
      deletedMediaIds: formData?.deletedMediaIds ?? [],
      ...(mode === "add" && { warehouse_ids: [user?.warehouse_id] }),
    };

    const formDataPayload = createFormData(payload);
    try {
      setSavingProduct(true);
      let response = "";
      if (mode === "add") {
        response = await createWarehouseProduct(
          formDataPayload,
          formData?.user
        );
      } else {
        response = await updateProduct(formDataPayload, formData?.id);
      }

      if (!response?.error) {
        dispatch(
          showSnackBar({
            text: `${
              mode === "add"
                ? "Product Created Successfully"
                : "Product Edited Successfully"
            }`,
            severity: "success",
          })
        );
        setFormData(initialFormData);
        setAdditionalFields([]);
        setSavingProduct(false);
        navigate("/warehouse-products");
      } else {
        setSavingProduct(false);
        setErrors(processErrorResponse(response?.error?.response?.data));
        dispatch(
          showSnackBar({
            text:
              response?.error?.response?.data?.message ||
              "Something went wrong",
            severity: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        showSnackBar({
          text: "Something went wrong",
          severity: "error",
        })
      );
    }
  };
  useEffect(() => {
    if (formData?.type?.id === 2) {
      setContentList(initialContentList);
    } else {
      setContentList((prevList) => {
        const menus = prevList[0].menus?.filter(
          (item) => item?.id !== "addproduct_combosection"
        );
        return [
          {
            ...prevList[0],
            menus,
          },
        ];
      });
    }
  }, [formData?.type]);

  const fetchInitialData = async (shipper) => {
    try {
      const response = await fetchRawProductData(shipper);
      if (!response.error) {
        setCategories(
          response?.data?.result?.categories?.map((category) => {
            return { value: category.id, title: category.name };
          })
        );
        setProductBrands(
          response?.data?.result?.brands?.map((brand) => {
            return { value: brand.id, title: brand.name };
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (formData?.user) {
      fetchInitialData(formData?.user);
    }
    if (mode === "add") {
      setCategories([]);
      setSelectedCategory("");
      setProductBrands([]);
      setSelectedBrand("");
    }
  }, [formData?.user]);

  useEffect(() => {
    const previousData = location?.state;

    if (previousData) {
      setMode("edit");
      setFormData({
        user: previousData?.shipper?.id ?? "",
        sku: previousData?.product_sku ?? "",
        id: previousData?.id,
        category: {
          title: previousData?.category?.name,
          value: previousData?.category?.id,
        },
        code: previousData?.code ?? "",
        title: previousData?.name ?? "",
        description: previousData?.description ?? "",
        media: previousData?.media ?? [],
        regular_price: previousData?.regular_price ?? 0,
        sales_price: previousData?.sale_price ?? 0,
        length: previousData?.length ?? "",
        width: previousData?.width ?? "",
        height: previousData?.height ?? "",
        weight: previousData?.weight ?? "",
        deletedMediaIds: [],
        status: previousData?.status ?? "",
        brand: {
          value: previousData?.brand?.id,
          title: previousData?.brand?.name,
        },
        currency: "cad",
        dimension_unit: previousData?.dimension_unit
          ? {
              title:
                previousData?.dimension_unit === "in"
                  ? "Inch (in)"
                  : "Centemeter (cm)",
              value: previousData?.dimension_unit,
            }
          : "",
        weight_unit: previousData?.weight_unit
          ? {
              title:
                previousData?.weight_unit === "kg"
                  ? "Kilogram (kg)"
                  : "Pound (lb)",
              value: previousData?.weight_unit,
            }
          : "",
      });
      previousData?.package_details &&
        setPackageDetails(previousData?.package_details);
      previousData?.category &&
        setSelectedCategory({
          title: previousData?.category?.name,
          value: previousData?.category?.id,
        });
      previousData?.brand &&
        setSelectedBrand({
          title: previousData?.brand?.name,
          value: previousData?.brand?.id,
        });
      setUploadedImages(previousData?.media ?? []);
    } else {
      // console.log("Prev Data False", previousData);
    }
  }, [location]);

  return (
    <div className="add__prod_screen">
      <div className="add__prod_screenleft">
        <TableOfContent contentList={contentList} />
      </div>
      <div className="add__prod_screencenter">
        <div className="add__prod_sheader hide_mobile_btn">
          <Link to="/warehouse-products">
            <BiArrowBack size={22} className="add__prod_sheaderback" />
          </Link>
          {mode === "add" ? "Add product" : "Edit Product"}
        </div>
        <InfoSection
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          additionalFields={additionalFields}
          setAdditionalFields={setAdditionalFields}
          editMode={mode === "edit" ? true : false}
        />
        <MediaSection
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          uploadedImages={uploadedImages}
          setUploadedImages={setUploadedImages}
        />
        <DimensionsSection
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          packageDetails={packageDetails}
          setPackageDetails={setPackageDetails}
        />
        <div className="add__prod_bottomsec">
          <button className="add__prod_saveprodbtn" onClick={handleSaveProduct}>
            {!savingProduct ? (
              "Save"
            ) : (
              <CircularProgress color="inherit" size={16} />
            )}
          </button>
        </div>
      </div>
      <div className="add__prod_screenright">
        <div className="add__prod_sheader show_mobile_btn">
          <Link
            to={user.role == "Shipper" ? "/products" : "/warehouse-products"}
          >
            <BiArrowBack size={22} className="add__prod_sheaderback" />
          </Link>
          {mode === "add" ? "Add product" : "Edit Product"}
        </div>
        <ClientSection
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          mode={mode}
        />
        {formData?.user ? (
          <CategorySection
            formData={formData}
            productBrands={productBrands}
            setProductBrands={setProductBrands}
            categories={categories}
            setCategories={setCategories}
            setFormData={setFormData}
            errors={errors}
            setErrors={setErrors}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            client={formData?.user}
            selectedBrand={selectedBrand}
            setSelectedBrand={setSelectedBrand}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AddWarehouseProduct;
