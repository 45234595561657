import React, { useState } from "react";
import "../../InvoiceModal.css";
import { MdOutlineContentCopy } from "react-icons/md";
import { FcCheckmark } from "react-icons/fc";
import StatusIcons from "../../../../Components/StatusIcons/StatusIcons";
import { MdOutlineHistory } from "react-icons/md";
import Tooltip from "../../../../Components/Tooltip/Tooltip";
import ShipmentHistoryModal from "../../../ShipmentHistoryModal/ShipmentHistoryModal";

const ShipmentDetails = ({ data, type = "Show" }) => {
  const [copied, setCopied] = useState(false);
  const [detailHistoryModal, setDetailHistoryModal] = useState(false);

  const handleCopy = () => {
    let shipmentIds = [];
    for (let i = 0; i < data?.c_p_shipment_details.length; i++) {
      let shipmentId = data?.c_p_shipment_details[i]?.shipment_tracking_id;
      shipmentIds.push(shipmentId);
    }
    let shipmentIdsString = shipmentIds.join(",\n");
    navigator.clipboard
      .writeText(shipmentIdsString)
      .then(() => {
        setCopied("sid");
      })
      .catch((error) => {
        console.error(
          "Failed to copy shipment tracking IDs to clipboard: " + error
        );
      });

    setTimeout(() => setCopied(false), 2000);
  };

  const handleCopyParcelId = () => {
    let parcelIds = [];
    data?.shipment_details.map((item) => {
      parcelIds.push(item?.parcel_id);
    });
    // for (let i = 0; i < data?.parcel_id?.length; i++) {
    //   let parcelId = data?.parcel_id[i] ?? "";
    //   parcelIds.push(parcelId);
    // }
    let parcelIdsString = parcelIds.join(",\n");
    navigator.clipboard
      .writeText(parcelIdsString)
      .then(() => {
        setCopied("pid");
      })
      .catch((error) => {
        console.error("Failed to copy parcel IDs to clipboard: " + error);
      });

    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <>
      <ShipmentHistoryModal
        open={detailHistoryModal}
        setOpen={setDetailHistoryModal}
      />
      <div className="invmodal__shipment_details">
        <div className="invmodal__shipment_detailshding">Shipment Detail</div>
        <table className="invmodal__shdetails_table">
          <thead>
            <th className="invmodal__shdetails_tablehd">Carrier</th>
            <th className="invmodal__shdetails_tablehd">Service Type</th>
            <th className="invmodal__shdetails_tablehd">Shipment Type</th>
            <th className="invmodal__shdetails_tablehd">Date Requested</th>
            <th className="invmodal__shdetails_tablehd">Pickup ID</th>
            <th className="invmodal__shdetails_tablehd">Pickup Date</th>
            <th className="invmodal__shdetails_tablehd">Package Count</th>
            {data?.insurance && data?.insurance_type === "Shipment" && (
              <th className="invmodal__shdetails_tablehd">Insurance Amount</th>
            )}
            <th className="invmodal__shdetails_tablehd">Open Time</th>
            <th className="invmodal__shdetails_tablehd">Close Time</th>
          </thead>
          <tbody>
            {" "}
            <tr>
              {" "}
              <td className="invmodal__shdetails_tableitem">
                <img
                  src={data?.shipping_company?.shipping_company?.logo_url}
                  className="invmodal__shdetailgi_carrlogo"
                />
              </td>
              <td className="invmodal__shdetails_tableitem">
                {data?.service_type}
              </td>
              <td className="invmodal__shdetails_tableitem">
                {data?.shipment_type}
              </td>
              <td className="invmodal__shdetails_tableitem">
                {data?.created_at?.slice(0, 10)}
              </td>
              <td className="invmodal__shdetails_tableitem">
                {data?.PickupID ?? "-"}
              </td>
              <td className="invmodal__shdetails_tableitem">
                {data?.PickupDate?.slice(0, 10)}
              </td>
              <td className="invmodal__shdetails_tableitem">
                {data?.shipment_details?.length ?? "-"}
              </td>
              {data?.insurance && data?.insurance_type === "Shipment" && (
                <td className="invmodal__shdetails_tableitem">
                  {data?.insurance_amount ?? "-"}
                </td>
              )}
              <td className="invmodal__shdetails_tableitem">
                {data?.readyopentime ?? "-"}
              </td>
              <td className="invmodal__shdetails_tableitem">
                {data?.readyclosetime ?? "-"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="invmodal__shdetails_content  package__details_row_custom">
        <div className="invmodal__shipment_packagedetails_heading">
          Packages Detail
        </div>
        <div className="invmodal__shdetails_cntleft">
          <div className="invmshdetails_cnleft_heading">Declared</div>
          <table className="invmshdetails_cnleft_table">
            <thead>
              <th className="invmodal__shdetails_tablehd content_center">#</th>
              <th className="invmodal__shdetails_tablehd">Description</th>
              <th className="invmodal__shdetails_tablehd ">
                {type == "Show" ? (
                  <div className="invmodal__shdetails_tablehdinner ">
                    Parcel ID{" "}
                    {data?.parcel_id?.length && copied !== "pid" ? (
                      <Tooltip title="Copy All Parcel IDs">
                        <span
                          className="invmodal__shdetails_modaltxtrtcopy"
                          onClick={handleCopyParcelId}
                        >
                          <MdOutlineContentCopy
                            size={16}
                            style={{ marginLeft: "10px" }}
                          />
                        </span>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                    {data?.parcel_id?.length && copied === "pid" ? (
                      <span className="invmodal__shdetails_modaltxtrtcopy">
                        <FcCheckmark size={16} style={{ marginLeft: "10px" }} />
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  "Parcel ID"
                )}
              </th>
              <th className="invmodal__shdetails_tablehd">Parcel Status</th>
              {data?.c_p_shipment_details?.length > 0 && (
                <th className="invmodal__shdetails_tablehd">
                  {type == "Show" ? (
                    <div className="invmodal__shdetails_tablehdinner ">
                      Carrier Tracking
                      {data?.c_p_shipment_details?.length &&
                      copied !== "sid" ? (
                        <Tooltip title="Copy All Tracking IDs">
                          <span
                            className="invmodal__shdetails_modaltxtrtcopy"
                            onClick={handleCopy}
                          >
                            <MdOutlineContentCopy
                              size={16}
                              style={{ marginLeft: "10px" }}
                            />
                          </span>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      {data?.c_p_shipment_details?.length &&
                      copied === "sid" ? (
                        <span className="invmodal__shdetails_modaltxtrtcopy">
                          <FcCheckmark
                            size={16}
                            style={{ marginLeft: "10px" }}
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    "Carrier Tracking"
                  )}
                </th>
              )}
              {data?.insurance && data?.insurance_type === "Package" && (
                <th className="invmodal__shdetails_tablehd">
                  Insurance Amount
                </th>
              )}
              <th className="invmodal__shdetails_tablehd">Dimensions</th>
              <th className="invmodal__shdetails_tablehd">Weight</th>
              <th className="invmodal__shdetails_tablehd">Volume</th>
            </thead>

            <tbody>
              {data?.shipment_details?.map((item, index) => {
                return (
                  <tr key={index}>
                    {" "}
                    <td className="invmodal__shdetails_tableitem content_center">
                      {index + 1}
                    </td>
                    <td className="invmodal__shdetails_tableitem">
                      {item.description ?? "-"}
                    </td>
                    <td className="invmodal__shdetails_tableitem">
                      {/* {data?.parcel_id ? data.parcel_id[index] ?? "-" : "-"} */}
                      {item?.parcel_id ?? "-"}
                    </td>
                    <td
                      className="invmodal__shdetails_tableitem"
                      style={{ display: "flex" }}
                    >
                      <StatusIcons status={item?.tracking_status ?? "N/A"} />
                      {item?.parcel_id &&
                        item?.tracking_status &&
                        type == "Show" && (
                          <>
                            {" "}
                            <Tooltip title="Click To View History">
                              <div>
                                <MdOutlineHistory
                                  size={15}
                                  className="parcel__history_icone"
                                  onClick={() =>
                                    setDetailHistoryModal(item?.id)
                                  }
                                />
                              </div>
                            </Tooltip>
                          </>
                        )}
                    </td>
                    {data?.c_p_shipment_details?.length > 0 && (
                      <td className="invmodal__shdetails_tableitem">
                        {data?.c_p_shipment_details
                          ? data.c_p_shipment_details[index]
                              ?.shipment_tracking_id ?? "-"
                          : "-"}
                      </td>
                    )}
                    {data?.insurance && data?.insurance_type === "Package" && (
                      <td className="invmodal__shdetails_tableitem">
                        {item.insurance ?? "-"}
                      </td>
                    )}
                    <td className="invmodal__shdetails_tableitem">
                      L:{item.length ?? "-"} W:{item.width ?? "-"} H:
                      {item.height ?? "-"}
                    </td>
                    <td className="invmodal__shdetails_tableitem">
                      {item.weight ?? "-"}
                      {item.weight && "Lbs"}
                    </td>
                    <td className="invmodal__shdetails_tableitem">
                      {item.volume ?? "-"} {item.volume && "Cbf"}
                    </td>
                  </tr>
                );
              })}

              <tr className="invmshdetails_cnleft_footer">
                <td></td>
                <td className="invmodal__shdetails_tableitem invmshdetails_cnleft_fitemttl">
                  Total
                </td>
                <td></td>
                <td></td>
                {data?.c_p_shipment_details?.length > 0 && <td></td>}
                {data?.insurance && data?.insurance_type === "Package" && (
                  <td className="invmodal__shdetails_tableitem"></td>
                )}
                <td></td>
                <td className="invmodal__shdetails_tableitem">
                  {data?.total_weight ?? ""} {data?.total_weight && "Lbs"}
                </td>
                <td className="invmodal__shdetails_tableitem">
                  {" "}
                  {data?.volume ?? "-"} {data?.volume && "Cbf"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="invmodal__shdetails_cntright">
          {/* <div className="add__dimension_button">
                <div className="add__dimension_buttonicon">
                  <AddIcon />{" "}
                </div>
                <div className="add__dimension_buttontext">
                  Billed Dimension, Weight and Volume
                </div>
              </div> */}
        </div>
      </div>
    </>
  );
};

export default ShipmentDetails;
