import React from "react";
import "./Requirements.css";
const CanadaPostRequirements = () => {
  return (
    <div className="cuscarrier__reqrmnts_box">
      Contact CanadaPost at
      <a
        href={`mailto:Ivan.Blasco@canadapost.postescanada.ca`}
        className="email_space"
      >
        Ivan.Blasco@canadapost.postescanada.ca
      </a>
      to obtain your Username, Password, Customer Number and Contact ID. These
      API credentials will allow you to connect your custom CanadaPost account
      to the application, enabling you to access CanadaPost's shipping rates for
      your account and display them as quotes.
    </div>
  );
};

export default CanadaPostRequirements;
