import React from "react";
import Skeleton from "@mui/material/Skeleton";

const SkeletonLoading = ({
  variant = "rectangular",
  width = "100%",
  height,
  style = { borderRadius: "5px" },
  className = "",
}) => {
  return (
    <Skeleton
      variant={variant}
      width={width}
      height={height}
      style={{ ...style }}
      className={className}
    />
  );
};

export default SkeletonLoading;
