import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Button from '@mui/material/Button';

const PaymentShippingDetailCard = () =>{
    
    const [shippingCompany, setShippingCompany] = React.useState('');
    const [shippingPaymentStatus, setShippingPaymentStatus] = React.useState('');
    const [isCustomShippingVisible, setIsCustomShippingVisible] = React.useState(false);
    const handleChange = (event) => {
        setShippingCompany(event.target.value);
      };

    const shippingPaymentStatushandleChange = (event) => {
        setShippingPaymentStatus(event.target.value);
      };
    
      const [shippingCompanies, setShippingCompanies] =  React.useState([
        'Kindersley Transport',
        'Ics',
        'Day & Ross',
        'UPS',
        'Loomis Express',
        'Fedex',
        'GLS',
        'Canada Post',
        'Canpar',
        'Nationex'
      ]);
      const [newShippingCompany, setNewShippingCompany] =  React.useState('');
  const handleAddShippingCompany = () => {
    if (newShippingCompany.trim() !== '') {
      setShippingCompanies([...shippingCompanies, newShippingCompany]);
      setNewShippingCompany('');
    }
    setIsCustomShippingVisible(!isCustomShippingVisible);
  };

  const handleNewShippingCompanyChange = (e) => {
    setNewShippingCompany(e.target.value);
  };

  const toggleCustShipVisibility = () => {
    setIsCustomShippingVisible(!isCustomShippingVisible);
  };
    return(
    <>
        <div className="paymentShipping__porductDetails_orderdetails">      
            <div className="paymentShipping__productDetails_main_wrap">
                <div className="paymentShipping__productDetails_header_main">
                    <h2>Payment Details</h2>
                </div>
                <div className="paymentShipping__orderDetails_body_main">
                    <div className='paymentShipping__order_status_radio'>
                        <FormControl className="paymentShipping_order_status_wrap">
                            <FormLabel id="paymentShipping_order_status"  className="paymentShipping_order_status_label">Order Status</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="paymentShipping_order_status"
                                name="radio-buttons-group"
                                className="paymentShipping_order_status_radio_group"
                            >
                                <FormControlLabel className="paymentShipping_order_status_radio_group_pending"  value="pending" control={<Radio />} label="Pending" />
                                <FormControlLabel className="paymentShipping_order_status_radio_group_confirm" value="confirmed" control={<Radio />} label="Confirmed" />
                                <FormControlLabel className="paymentShipping_order_status_radio_group_onhold" value="onhold" control={<Radio />} label="On Hold" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className='paymentShipping__payment_status_radio'>
                        <FormControl  className='paymentShipping__payment_status_wrap'>
                            <FormLabel  className='paymentShipping__payment_status_label' id="paymentShipping__payment_status_radio">Payment Status</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="paymentShipping__payment_status_radio"
                                name="radio-buttons-group"
                                className='paymentShipping__payment_status_group'
                            >
                                <FormControlLabel className='paymentShipping__payment_status_group_recieved' value="received" control={<Radio />} label="Received" />
                                <FormControlLabel className='paymentShipping__payment_status_group_cop' value="cashOnPickup" control={<Radio />} label="Cash On Pickup" />
                                <FormControlLabel className='paymentShipping__payment_status_group_cod' value="cashOnDelivery" control={<Radio />} label="Cash on delivery" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className='paymentShipping__payment_amount_recandpay'>
                        <h3 className='paymentShipping__payment_recandpay_heading'>Amount Payable/Receivable</h3>
                        <FormControl variant="outlined" className='paymentShipping__amount__received'>
                            <InputLabel htmlFor="paymentShipping__amount_received">
                            Ammount Received 
                            </InputLabel>
                            <OutlinedInput
                            id="paymentShipping__amount_received"
                            startAdornment={
                                <InputAdornment position="start">
                                $
                                </InputAdornment>
                            }
                            label="Ammount Received"
                            />
                        </FormControl>
                        <FormControl variant="outlined"  className='paymentShipping__amount__pending'>
                            <InputLabel htmlFor="paymentShipping__amount_pending">
                            Ammount Pending 
                            </InputLabel>
                            <OutlinedInput
                            id="paymentShipping__amount_pending"
                            startAdornment={
                                <InputAdornment position="start">
                                $
                                </InputAdornment>
                            }
                            label="Ammount Pending"
                            />
                        </FormControl>
                    </div>
                    <div className='paymentShipping__payment_amount_recandpay'>
                        <h3 className='paymentShipping__payment_recandpay_heading'>Total Order Amount:<span>{"(Same as Total in Order Information)"}</span></h3>
                        <FormControl variant="outlined" className='paymentShipping__amount_total_wrap'>
                            <InputLabel htmlFor="paymentShipping__amount_total">
                            Total Amount 
                            </InputLabel>
                            <OutlinedInput
                            id="paymentShipping__amount_total"
                            startAdornment={
                                <InputAdornment position="start">
                                $
                                </InputAdornment>
                            }
                            label="Total Amount"
                            />
                        </FormControl>
                        <FormControl variant="outlined" className='paymentShipping__amount_total_tax_wrap'>
                            <InputLabel htmlFor="paymentShipping__amount_total_tax">
                            Total Tax 
                            </InputLabel>
                            <OutlinedInput
                            id="paymentShipping__amount_total_tax"
                            startAdornment={
                                <InputAdornment position="start">
                                $
                                </InputAdornment>
                            }
                            label="Total Tax "
                            />
                        </FormControl>
                        <FormControl variant="outlined" className='paymentShipping__amount_shipping_total_wrap'>
                            <InputLabel htmlFor="paymentShipping__amount_shipping_total">
                            Shipping Total 
                            </InputLabel>
                            <OutlinedInput
                            id="paymentShipping__amount_shipping_total"
                            startAdornment={
                                <InputAdornment position="start">
                                $
                                </InputAdornment>
                            }
                            label="Shipping Total "
                            />
                        </FormControl>
                    </div>
                </div>
            </div>      
            <div className="paymentShipping__productDetails_main_wrap">
                <div className="paymentShipping__productDetails_header_main">
                    <h2>Shipping Details</h2>
                </div>
                <div className="paymentShipping__orderDetails_body_main">
                    <div className='paymentShipping__orderDetails_intro_content'>You can use Ship Button later to process this order via BOLT or your own accounts once order has been added. if you have already booked it please enter the information below.</div>
                    <div className='paymentShipping__orderDetails_form_fields_block'>
                    <h3 className='paymentShipping__link_field_personal_record'>Shipping Records</h3>
                    <div className='paymentShipping__shipping_company'>
                            <FormControl variant="outlined">
                            <InputLabel id="demo-simple-select-label">Shipping Company</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={shippingCompany}
                                label="Shipping Company"
                                onChange={handleChange}
                            >
                                {shippingCompanies.map((company, index) => (
                                <MenuItem key={index} value={company}>
                                    {company}
                                </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                            <div className='toggle__additional_Tax_btn' onClick={toggleCustShipVisibility} > +</div>
                            {isCustomShippingVisible && (
                            <div className='paymentShipping__shipping_company_add_more_panel_wrap'>
                                <div className='paymentShipping__shipping_company_add_more_overlay' onClick={toggleCustShipVisibility}></div>
                                <div className='paymentShipping__shipping_company_add_more_container'>
                                    <h3>Add Shipping Company</h3>
                                    <div className='paymentShipping__shipping_company_add_more_body'>
                                    <TextField
                                    label="Add More Shipping Company"
                                    value={newShippingCompany}
                                    onChange={handleNewShippingCompanyChange}
                                    />
                                        <Button variant="contained" onClick={handleAddShippingCompany}>
                                            Add
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            )}
                        </div>
                        <div className='paymentShipping__shipping_Tracking_code'>
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="paymentShipping__tracking_code">
                                Tracking Code
                                </InputLabel>
                                <OutlinedInput
                                id="paymentShipping__tracking_code"
                                startAdornment={
                                    <InputAdornment position="start">
                                    </InputAdornment>
                                }
                                label="Tracking Code"
                                />
                            </FormControl>
                        </div>
                        <div className='paymentShipping__link_field'>
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="paymentShipping__link">
                                Link {"(if any)"}
                                </InputLabel>
                                <OutlinedInput
                                id="paymentShipping__link"
                                startAdornment={
                                    <InputAdornment position="start">
                                    </InputAdornment>
                                }
                                label="Link (if any)"
                                />
                            </FormControl>
                        </div>
                        <h3 className='paymentShipping__link_field_personal_record'>Personal Records</h3>
                        <div className='paymentShipping__paid__field'>
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="paymentShipping__paid_field">
                                Shipping Paid {"(Optional)"}
                                </InputLabel>
                                <OutlinedInput
                                id="paymentShipping__paid_field"
                                startAdornment={
                                    <InputAdornment position="start">
                                    </InputAdornment>
                                }
                                label="Shipping Paid (Optional)"
                                />
                            </FormControl>
                        </div>
                       <div className='shipping__payment_status_field'>
                        <FormControl variant="outlined">
                                <InputLabel id="shipping__payment_status">Shipping Payment Status</InputLabel>
                                <Select
                                    labelId="shipping__payment_status"
                                    id="demo-simple-select"
                                    value={shippingPaymentStatus}
                                    label="Shipping Payment Status"
                                    onChange={shippingPaymentStatushandleChange}
                                >
                                    <MenuItem value={10}>Paid</MenuItem>
                                    <MenuItem value={20}>Unpaid</MenuItem>
                                    <MenuItem value={30}>Disputed</MenuItem>
                                    <MenuItem value={40}>Disputed Won</MenuItem>
                                    <MenuItem value={50}>Disputed Lost</MenuItem>
                                    <MenuItem value={60}>In Process</MenuItem>
                                    <MenuItem value={70}>Pending</MenuItem>
                                </Select>
                            </FormControl>
                       </div>

                    </div>
                </div>
            </div>
        </div>
    </>
    )
}
export default PaymentShippingDetailCard;