import React, { useState } from "react";
import "./subtractbalance.css";

// components imports
import Loader from "../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import { subtractBalance } from "../../../../../services/AdminServices/Users/users.services";
// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import { Input, Typography } from "@mui/material";
import { styled } from "@mui/system";

const StyledUploadContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: "2px dashed #2d76bf",
  borderRadius: "8px",
  backgroundColor: "#fff",
  cursor: "pointer",
  marginBottom: "15px",
});

const initialData = {
  balance: "",
  deduction_reason: "",
  reasonFile: "",
};

export default function SubtractBalance({
  open,
  setOpen,
  setSnack,
  updateUsers,
}) {
  const [data, setData] = useState(initialData);

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setData(initialData);
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSave = async () => {
    setLoading(true);
    const Formdata = new FormData();
    Formdata.append("balance", data.balance);
    Formdata.append("deduction_reason", data.deduction_reason);
    if (data.reasonFile != "") {
      Formdata.append("reasonFile", data.reasonFile);
    }
    const id = open.id;
    const response = await subtractBalance(Formdata, id);
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Balance Updated Successfully",
          severity: "success",
        };
      });
    }
    updateUsers((prevUser) => {
      return {
        ...prevUser,
        data:
          open.role === "warehouseUser"
            ? prevUser?.data.map((userData) => {
                return userData?.user?.id === id
                  ? { ...userData, user: response?.data?.result }
                  : userData;
              })
            : prevUser?.data.map((user) => {
                return user.id === id
                  ? { ...user, balance: response?.data?.result.balance }
                  : user;
              }),
      };
    });
    setData(initialData);
    setLoading(false);
    handleClose();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="admnsub__balance_modal">
          <div className="admnsub__balance_modalheading">Subtract Balance</div>
          <div className="admnsub__balance_modalcontent">
            <div className="">
              <div className="">
                <label className="admnsub__balance_modallbl">
                  Attachment Reason
                </label>
                <StyledUploadContainer className="file__upload_csv_row">
                  <label htmlFor="file-upload">
                    <Input
                      id="file-upload"
                      type="file"
                      accept=".jpg,.JPG,.png,.PNG,.gif,.GIF"
                      style={{ display: "none" }}
                      name="reasonFile"
                      onChange={(e) =>
                        handleChange({
                          target: {
                            value: e.target.files[0],
                            name: "reasonFile",
                          },
                        })
                      }
                    />
                    <Typography variant="h6" color="primary" component="div">
                      {data?.reasonFile === ""
                        ? "Upload File"
                        : data?.reasonFile?.name}
                    </Typography>
                  </label>
                </StyledUploadContainer>
              </div>
            </div>
            <div className="admnsub__balance_modalrow">
              <div className="admnsub__balance_modalinputwrap">
                <label className="admnsub__balance_modallbl">Amount*</label>
                <input
                  className="admnsub__balance_modalinput"
                  placeholder="Enter Amount"
                  name="balance"
                  value={data.balance}
                  onChange={handleChange}
                  type="number"
                />
              </div>
            </div>
            <div className="admnsub__balance_modalrow">
              <div className="admnsub__balance_modalinputwrap">
                <label className="admnsub__balance_modallbl">Reason</label>
                <input
                  className="admnsub__balance_modalinput"
                  placeholder="Enter Instructions"
                  name="deduction_reason"
                  value={data.deduction_reason}
                  onChange={handleChange}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="admnsub__balance_modalfooter">
            <div className="admnsub__balance_modalcncl" onClick={handleClose}>
              Cancel
            </div>
            <div className="admnsub__balance_modalsave" onClick={handleSave}>
              {loading ? <Loader /> : "Save"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
