import React, { useState } from "react";
import { useAutocomplete } from "@mui/base/AutocompleteUnstyled";
import { styled } from "@mui/system";

const Input = styled("input")(({ theme }) => ({
  width: "320px",
  height: "40px",
  backgroundColor: "white",
  padding: "0 10px",
  outline: "none",
}));

const Listbox = styled("ul")(({ theme }) => ({
  width: 340,
  margin: 0,
  padding: 0,
  zIndex: 1,
  position: "absolute",
  listStyle: "none",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000",
  overflow: "auto",
  maxHeight: 200,
  border: "1px solid rgba(0,0,0,.25)",
  "& li": {
    height: 40,
    padding: "0 5px",
    display: "flex",
    alignItems: "center",
  },
  "& li.Mui-focused": {
    backgroundColor: "#37388b",
    color: "white",
    cursor: "pointer",
  },
  "& li:active": {
    backgroundColor: "#37388b",
    color: "white",
  },
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#E4EFEF",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#1D388F61",
    borderRadius: "2px",
  },
}));

export default function CitySearch({ setCity }) {
  const [cities, setCities] = useState([
    { name: "montreal", center: { lat: 45.5035, lng: 73.5685 } },
    { name: "toronto", center: { lat: 43.6532, lng: 79.3832 } },
  ]);
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: "use-autocomplete-demo",
    options: cities,
    getOptionLabel: (option) => option.name,
  });

  return (
    <div>
      <div {...getRootProps()}>
        <Input {...getInputProps()} placeholder="Search City" />
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li
              {...getOptionProps({ option, index })}
              onClick={() => setCity(option.name)}
            >
              {option.name}
            </li>
          ))}
        </Listbox>
      ) : null}
    </div>
  );
}
