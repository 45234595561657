import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "./TextField";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IconButton from "@mui/material/IconButton";
import Tooltip from "../../../../../../Components/Tooltip/Tooltip";
import {
  IoInformationCircleOutline,
  IoInformationCircleSharp,
} from "react-icons/io5";
import countries from "./countries.json";
import Autocomplete from "./AutoComplete";
import { setdata } from "../../../../../../helpers/helpers";
import InputAdornment from "@mui/material/InputAdornment";

const ExportReasonTypes = [
  "permanent",
  "temporary",
  "return",
  "used_exhibition_goods_to_origin",
  "intercompany_use",
  "commercial_purpose_or_sale",
  "personal_belongings_or_personal_use",
  "sample",
  "gift",
  "return_to_origin",
  "warranty_replacement",
  "diplomatic_goods",
  "defence_material",
];

export default function InternationalForm(props) {
  const { StepCheck, SetStepCheck, Tlist, Flist, Plist } = props;

  const [ProductHelp, setProductHelp] = useState(false);
  const [ProducerHelp, setProducerHelp] = useState(false);

  const handleInternationChange = (e) => {
    const { name, value } = e.target;
    const [section, field] = name.split(".");

    SetStepCheck((prevStepcheck) => ({
      ...prevStepcheck,
      International: {
        ...prevStepcheck.International,
        [section]: {
          ...prevStepcheck.International[section],
          [field]: value,
        },
      },
    }));
  };

  const handleproductcountry = (value) => {
    SetStepCheck((prevStepcheck) => ({
      ...prevStepcheck,
      International: {
        ...prevStepcheck.International,
        product: {
          ...prevStepcheck.International.product,
          Country: setdata(() => value, false, ""),
          OriginCountryCode: setdata(
            () => countries.filter((cn) => cn.label === value)[0].code,
            false,
            ""
          ),
        },
      },
    }));
  };

  const handleproducercountry = (value) => {
    SetStepCheck((prevStepcheck) => ({
      ...prevStepcheck,
      International: {
        ...prevStepcheck.International,
        producer: {
          ...prevStepcheck.International.producer,
          Country: setdata(() => value, false, ""),
          CountryCode: setdata(
            () => countries.filter((cn) => cn.label === value)[0].code,
            false,
            ""
          ),
        },
      },
    }));
  };

  const handleExportReasonType = (value) => {
    SetStepCheck((prevStepcheck) => ({
      ...prevStepcheck,
      International: {
        ...prevStepcheck.International,
        product: {
          ...prevStepcheck.International.product,
          ExportReasonType: value,
        },
      },
    }));
  };

  return (
    <>
      {StepCheck["fullform"] === true &&
        StepCheck["QuoteCheck"] === true &&
        StepCheck["Shipmentby"] !== "" &&
        Tlist.country &&
        Tlist.countrycode &&
        Flist.country &&
        Flist.countrycode &&
        !(
          ["Canada", "canada"].includes(Tlist.country) &&
          ["CA", "ca"].includes(Tlist.countrycode) &&
          ["Canada", "canada"].includes(Flist.country) &&
          ["CA", "ca"].includes(Flist.countrycode)
        ) && (
          <>
            <Row className="mx-auto mt-3 mb-3">
              <Col md={11} className="mx-auto">
                <div className="card bg-white shadow wapper_like_shopify">
                  <div className="card-header">
                    <span> Customs Declaration Form </span>
                    <Tooltip title="Specifically For International Shipment">
                      <IconButton>
                        <IoInformationCircleOutline size={15} />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className="card-body">
                    <div className="package__type_main_wrap">
                      <div className="package__type_package">
                        <small>
                          <b>
                            Product Information{" "}
                            <Tooltip
                              title={
                                ProductHelp === true
                                  ? "Click To Hide Information"
                                  : "Click To Show Information"
                              }
                            >
                              <IconButton
                                onClick={() => setProductHelp(!ProductHelp)}
                              >
                                {ProductHelp === true ? (
                                  <IoInformationCircleSharp size={15} />
                                ) : (
                                  <IoInformationCircleOutline size={15} />
                                )}
                              </IconButton>
                            </Tooltip>
                          </b>
                        </small>
                        <Grid
                          container
                          spacing={2}
                          justifyContent="center"
                          mb={2}
                          mt={1}
                        >
                          <Grid item xs={12} md={6}>
                            <TextField
                              Label="Description"
                              Name="product.Description"
                              Data={StepCheck.International.product.Description}
                              Handler={handleInternationChange}
                              HelperText={
                                ProductHelp === true
                                  ? "Description of the product."
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Autocomplete
                              Option={countries.map((option) => option.label)}
                              Handler={handleproductcountry}
                              Value={StepCheck.International.product.Country}
                              Type="Country"
                              HelperText={
                                ProductHelp === true
                                  ? "The country or territory in which the good was manufactured, produced or grown."
                                  : ""
                              }
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          spacing={2}
                          justifyContent="center"
                          mb={2}
                        >
                          <Grid item xs={12} md={6}>
                            <TextField
                              Label="Insurance Amount"
                              Name="product.InsuranceAmount"
                              Type="number"
                              Data={
                                StepCheck.International.product.InsuranceAmount
                              }
                              Handler={handleInternationChange}
                              HelperText={
                                ProductHelp === true
                                  ? "The amount the shipper or receiver pays to cover the cost of replacing the shipment if it is lost or damaged."
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              Label="Monetary Amount"
                              Name="product.TotalPriceAmount"
                              Data={
                                StepCheck.International.product
                                  .TotalPriceAmount +
                                " * " +
                                Plist.length +
                                " = " +
                                parseFloat(
                                  StepCheck.International.product
                                    .TotalPriceAmount * Plist.length
                                ).toFixed(1)
                              }
                              Disabled={true}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    sx={{ color: "#1976D2" }}
                                  >
                                    <b>
                                      <small style={{ display: "flex" }}>
                                        {Plist.map((singlelist, index) => (
                                          <div key={index}>
                                            {singlelist?.declaration}{" "}
                                            {index < Plist.length - 1
                                              ? " + "
                                              : "= " +
                                                StepCheck?.TotalDeclaration +
                                                " / " +
                                                Plist.length +
                                                " = " +
                                                parseFloat(
                                                  StepCheck?.TotalDeclaration /
                                                    Plist.length
                                                ).toFixed(1)}
                                          </div>
                                        ))}
                                      </small>
                                    </b>
                                  </InputAdornment>
                                ),
                              }}
                              Handler={handleInternationChange}
                              HelperText={
                                ProductHelp === true
                                  ? "Monetary amount used to specify the worth or price of the commodity. Amount should be greater than zero."
                                  : ""
                              }
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          spacing={2}
                          justifyContent="center"
                          mb={2}
                        >
                          <Grid item xs={12} md={6}>
                            <TextField
                              Label="Reason For Export"
                              Name="product.ReasonForExport"
                              Data={
                                StepCheck.International.product.ReasonForExport
                              }
                              Handler={handleInternationChange}
                              HelperText={
                                ProductHelp === true
                                  ? "A reason to export the current international shipment. Valid values: SALE, GIFT, SAMPLE, RETURN, REPAIR, INTERCOMPANYDATA, Any other reason."
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Autocomplete
                              Option={
                                ExportReasonTypes
                                  ? ExportReasonTypes.map((option) => option)
                                  : []
                              }
                              Handler={handleExportReasonType}
                              Value={
                                StepCheck.International.product.ExportReasonType
                              }
                              Type="Export Reason Types"
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <div className="package__divider"></div>
                      <div className="package__type_pallet">
                        <small>
                          <b>
                            Producer Information
                            <Tooltip
                              title={
                                ProducerHelp === true
                                  ? "Click To Hide Information"
                                  : "Click To Show Information"
                              }
                            >
                              <IconButton
                                onClick={() => setProducerHelp(!ProducerHelp)}
                              >
                                {ProducerHelp === true ? (
                                  <IoInformationCircleSharp size={15} />
                                ) : (
                                  <IoInformationCircleOutline size={15} />
                                )}
                              </IconButton>
                            </Tooltip>
                          </b>
                        </small>

                        <Grid
                          container
                          spacing={2}
                          justifyContent="center"
                          mb={2}
                          mt={1}
                        >
                          <Grid item xs={12} md={6}>
                            <TextField
                              Label="Company Name"
                              Name="producer.CompanyName"
                              Data={
                                StepCheck.International.producer.CompanyName
                              }
                              Required={false}
                              Handler={handleInternationChange}
                              HelperText={
                                ProducerHelp === true
                                  ? "Company Name or the Individual name of the Producer. "
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Autocomplete
                              Option={countries.map((option) => option.label)}
                              Handler={handleproducercountry}
                              Value={StepCheck.International.producer.Country}
                              Type="Country"
                              HelperText={
                                ProducerHelp === true
                                  ? "Country or Territory of the Producer."
                                  : ""
                              }
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}
    </>
  );
}
