export const initialShippmentsTab = [
  {
    label: "All Carriers",
    value: "all",
    style: { fontWeight: "bold" },
    name: "shipper.access.shipment.view.all.carriers",
  },
  {
    label: "Hajex Carriers",
    value: "Hajex",
    style: { fontWeight: "bold" },
    name: "shipper.access.shipment.view.hajex.carriers",
  },
  {
    label: "Custom Carriers",
    value: "Custom",
    style: { fontWeight: "bold" },
    name: "shipper.access.shipment.view.custom.carriers",
  },
  {
    label: "Truckers",
    value: "carrier",
    style: { fontWeight: "bold" },
    name: "shipper.access.shipment.view.truckers.carriers",
  },
];
export const initialInvoiceTab = [
  {
    label: "All Carriers",
    value: "all",
    style: { fontWeight: "bold" },
    name: "shipper.access.invoice.view.all.carriers",
  },
  {
    label: "Hajex Carriers",
    value: "Hajex",
    style: { fontWeight: "bold" },
    name: "shipper.access.invoice.view.hajex.carriers",
  },
  {
    label: "Custom Carriers",
    value: "Custom",
    style: { fontWeight: "bold" },
    name: "shipper.access.invoice.view.custom.carriers",
  },
  {
    label: "Truckers",
    value: "carrier",
    style: { fontWeight: "bold" },
    name: "shipper.access.invoice.view.truckers.carriers",
  },
];
export const initialPickupTab = [
  {
    label: "All Carriers",
    value: "all",
    style: { fontWeight: "bold" },
    name: "shipper.access.pickup.view.all.carriers",
  },
  {
    label: "Hajex Carriers",
    value: "Hajex",
    style: { fontWeight: "bold" },
    name: "shipper.access.pickup.view.hajex.carriers",
  },
  {
    label: "Custom Carriers",
    value: "Custom",
    style: { fontWeight: "bold" },
    name: "shipper.access.pickup.view.custom.carriers",
  },
  {
    label: "Truckers",
    value: "carrier",
    style: { fontWeight: "bold" },
    name: "shipper.access.pickup.view.truckers.carriers",
  },
];
export const initialTrackingTab = [
  {
    label: "All Carriers",
    value: "all",
    style: { fontWeight: "bold" },
    name: "shipper.access.tracking.view.all.carriers",
  },
  {
    label: "Hajex Carriers",
    value: "Hajex",
    style: { fontWeight: "bold" },
    name: "shipper.access.tracking.view.hajex.carriers",
  },
  {
    label: "Custom Carriers",
    value: "Custom",
    style: { fontWeight: "bold" },
    name: "shipper.access.tracking.view.custom.carriers",
  },
  {
    label: "Truckers",
    value: "carrier",
    style: { fontWeight: "bold" },
    name: "shipper.access.tracking.view.truckers.carriers",
  },
];
export const initialPackagesTab = [
  {
    label: "Packages",
    value: 0,
    style: { fontWeight: "bold" },
    name: "shipper.access.product.view.packages",
  },
  {
    label: "Bundles",
    value: 1,
    style: { fontWeight: "bold" },
    name: "shipper.access.product.view.bundles",
  },
];
export const initialCarriersTab = [
  {
    label: "Hajex Carriers",
    value: 0,
    style: { fontWeight: "bold" },
    name: "shipper.access.carrier.view.hajex",
  },
  {
    label: "Custom Carriers",
    value: 1,
    style: { fontWeight: "bold" },
    name: "shipper.access.carrier.view.custom",
  },
];
export const initialPaymentTab = [
  {
    label: "APPROVED",
    value: 0,
    style: { fontWeight: "bold" },
    name: "shipper.access.payment.view.approved",
  },
  {
    label: "PENDING",
    value: 1,
    style: { fontWeight: "bold" },
    name: "shipper.access.payment.view.pending",
  },
  {
    label: "CARD MANAGEMENT",
    value: 2,
    style: { fontWeight: "bold" },
    name: "shipper.access.payment.view.pending",
  },
];
export const initialProductTab = [
  {
    label: "ALL PRODUCTS",
    value: 0,
    style: { fontWeight: "bold" },
    name: "",
  },
  {
    label: "PRODUCT ADJUSTMENT",
    value: 1,
    style: { fontWeight: "bold" },
    name: "",
  },
  {
    label: "PRODUCT COUNT STOCK",
    value: 2,
    style: { fontWeight: "bold" },
    name: "",
  },
];
