import React, { useState } from "react";
import "./ShipperUserDetails.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import { FaRegTimesCircle } from "react-icons/fa";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { BiUser } from "react-icons/bi";
import { AiOutlineEdit } from "react-icons/ai";
// import { updateCompanyStatus } from "../../../../../services/admin.services";
import { toCamelCase } from "../../../../../../../../helpers/helpers";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  boxShadow: 24,
  p: 4,
  padding: "30px",
  backgroundColor: "white",
  maxHeight: "95vh",
  overflowY: "auto",
  "&::scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar": {
    width: "5",
  },
};
const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));
export default function ShipperUserDetails({
  open,
  setOpen,
  data,
  setSnack,
  setUsers,
  users,
  user,
  setDetailsModal,
}) {
  const handleClose = () => setOpen(false);

  const [statusUpdating, setStatusUpdating] = useState();
  const [servicesModal, setServicesModal] = useState();
  const handleStatusChange = async (e, name) => {
    setStatusUpdating(true);
    setDetailsModal({
      ...data,
      companies_status: { ...data?.companies_status, [name]: e.target.checked },
    });
    const updated = true;
    // const updated = await updateCompanyStatus(user.token, name, data.id);
    setStatusUpdating(false);
    if (updated.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: updated.error.message,
          severity: "error",
        };
      });
      setUsers((shprs) =>
        shprs.map((item) =>
          item.id === data.id
            ? {
                ...item,
                companies_status: {
                  ...item.companies_status,
                  [name]: !e.target.checked,
                },
              }
            : item
        )
      );
    } else {
      setUsers((shprs) =>
        shprs.map((item) =>
          item.id === data.id ? updated?.data?.result : item
        )
      );
      setDetailsModal(updated?.data?.result);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `Status of ${name} changed to ${
            e.target.checked ? "Active" : "Inactive"
          }`,
          severity: e.target.checked ? "success" : "info",
        };
      });
    }
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="shipperuser__details_modal">
            <div
              className="shipperuser__details_modalclose"
              onClick={handleClose}
            >
              <IconButton>
                <FaRegTimesCircle size={25} color="lightgrey" />
              </IconButton>
            </div>
            <div className="shipperuser__details_heading">
              <BiUser size={35} /> <h2>User Details</h2>
            </div>
            <div className="shipperuser__details_modalrow">
              <div className="shipperuser__details_modalrowitem">
                <div className="shipperuser__details_modalcolbold">ID:</div>
                <div className="shipperuser__details_modalcol">
                  {data?.id ?? "-"}
                </div>
              </div>

              <div className="shipperuser__details_modalrowitem">
                <div className="shipperuser__details_modalcolbold">
                  User Name:
                </div>
                <div className="shipperuser__details_modalcol">
                  {data?.username ?? "-"}
                </div>
              </div>
            </div>
            <div className="shipperuser__details_modalrow">
              <div className="shipperuser__details_modalrowitem">
                <div className="shipperuser__details_modalcolbold">
                  First Name:
                </div>
                <div className="shipperuser__details_modalcol">
                  {data?.firstname ?? "-"}
                </div>
              </div>
              <div className="shipperuser__details_modalrowitem">
                <div className="shipperuser__details_modalcolbold">
                  Last Name:
                </div>
                <div className="shipperuser__details_modalcol">
                  {data?.lastname ?? "-"}
                </div>
              </div>
            </div>
            <div className="shipperuser__details_modalrow">
              <div className="shipperuser__details_modalrowitem">
                <div className="shipperuser__details_modalcolbold">Email:</div>
                <div className="shipperuser__details_modalcol">
                  {data?.email ?? "-"}
                </div>
              </div>
              <div className="shipperuser__details_modalrowitem">
                <div className="shipperuser__details_modalcolbold">
                  Phone Number:
                </div>
                <div className="shipperuser__details_modalcol">
                  {data?.phone_number ?? "-"}
                </div>
              </div>
            </div>
            <div className="shipperuser__details_modalrow">
              <div className="shipperuser__details_modalrowitem">
                <div className="shipperuser__details_modalcolbold">
                  Address:
                </div>
                <div className="shipperuser__details_modalcol">
                  {data?.address ?? "-"}
                </div>
              </div>
              <div className="shipperuser__details_modalrowitem">
                <div className="shipperuser__details_modalcolbold">
                  Country:
                </div>
                <div className="shipperuser__details_modalcol">
                  {data?.country ?? "-"}
                </div>
              </div>
            </div>
            <div className="shipperuser__details_modalrow">
              <div className="shipperuser__details_modalrowitem">
                <div className="shipperuser__details_modalcolbold">
                  Province:
                </div>
                <div className="shipperuser__details_modalcol">
                  {data?.province ?? "-"}
                </div>
              </div>
              <div className="shipperuser__details_modalrowitem">
                <div className="shipperuser__details_modalcolbold">City:</div>
                <div className="shipperuser__details_modalcol">
                  {data?.city ?? "-"}
                </div>
              </div>
            </div>
            <div className="shipperuser__details_modalrow">
              <div className="shipperuser__details_modalrowitem">
                <div className="shipperuser__details_modalcolbold">
                  Zip Code:
                </div>
                <div className="shipperuser__details_modalcol">
                  {data?.zip ?? "-"}
                </div>
              </div>
              <div className="shipperuser__details_modalrowitem">
                <div className="shipperuser__details_modalcolbold">Role:</div>
                <div className="shipperuser__details_modalcol">
                  {data?.role ?? "-"}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
