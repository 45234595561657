import React, { useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { TbFileInvoice } from "react-icons/tb";
import logo from "../logo.png";
import {
  FaShippingFast,
  FaTrailer,
  FaChevronDown,
  FaDigitalTachograph,
} from "react-icons/fa";
import { BiPackage } from "react-icons/bi";

import {
  MdOutlineManageSearch,
  MdStore,
  MdOutlineShoppingCart,
} from "react-icons/md";
import { TbTruckLoading, TbTruckDelivery, TbTicket } from "react-icons/tb";
import moscot from "../moscot.png";
import DateBadge from "../DateBadge/DateBadge";
import ProfileAvatar from "../../assets/images/profile-avatar.png";
import ProfileModal from "./Components/ProfileModal/ProfileModal";
import { useSelector } from "react-redux";
import AuthComponent from "../../Pages/Authentication/Components/AuthComponent/AuthComponent";

function NavBar() {
  const user = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const [modal, setModal] = useState();
  const [shipperPermissions, setShipperPermissions] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const currentDate = new Date();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleMenuIconClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    setShipperPermissions([
      {
        title: "Orders",
        url: "/orders",
        name: "shipper.access.order.view",
        unReadData: user?.unReadOrders,
        icon: <MdOutlineShoppingCart className="m-auto header__nav_btn_icon" />,
      },
      {
        title: "Ship",
        url: "/",
        name: "shipper.access.shipment.create",
        unReadData: null,
        icon: <FaShippingFast className="m-auto header__nav_btn_icon" />,
      },
      {
        title: "Shipments",
        url: "/shipments",
        name: "shipper.access.shipment.view",
        unReadData: user?.unReadShipments,
        icon: <FaTrailer className="m-auto header__nav_btn_icon" />,
      },
      // {
      //   title: "Invoices",
      //   url: "/Invoices",
      //   name: "shipper.access.invoice.view",
      //   unReadData: user?.unReadInvoices,
      //   icon: (
      //     <TbFileInvoice
      //       className="m-auto header__nav_btn_icon"
      //     />
      //   ),
      // },
      // {
      //   title: "Pickup",
      //   url: "/Pickup",
      //   name: "shipper.access.pickup.view",
      //   unReadData: user?.unReadPickups,
      //   icon: (
      //     <TbTruckLoading
      //       className="m-auto header__nav_btn_icon"
      //     />
      //   ),
      // },
      // {
      //   title: "Tracking",
      //   url: "/Tracking",
      //   name: "shipper.access.tracking.view",
      //   unReadData: user?.unReadTrackings,
      //   icon: (
      //     <MdOutlineManageSearch
      //       className="m-auto header__nav_btn_icon"
      //     />
      //   ),
      // },
      {
        title: "Finances",
        url: "/payments",
        name: "shipper.access.payment.view",
        unReadData: null,
        icon: <FaDigitalTachograph className="m-auto header__nav_btn_icon" />,
      },

      {
        title: "Tickets",
        url: "/Tickets",
        name: "shipper.access.ticket.view",
        unReadData: null,
        icon: <TbTicket className="m-auto header__nav_btn_icon" />,
      },
      {
        title: "Carriers",
        url: "/Carriers",
        name: "shipper.access.carrier.view",
        unReadData: null,
        icon: <TbTruckDelivery className="m-auto header__nav_btn_icon" />,
      },
      {
        title: "Products",
        url: "/products",
        name: "",
        unReadData: null,
        icon: <BiPackage className="m-auto header__nav_btn_icon" />,
      },
      {
        title: "Packages",
        url: "/Packages",
        name: "shipper.access.product.view",
        unReadData: null,
        icon: <BiPackage className="m-auto header__nav_btn_icon" />,
      },
      {
        title: "MarketPlaces",
        url: "/Marketplaces",
        name: "",
        unReadData: null,
        icon: <MdStore className="m-auto header__nav_btn_icon" />,
      },
    ]);
    let roles =
      user.roles.length > 0
        ? user?.roles[0].permissions?.map((p) => p.name)
        : [];
    setPermissions(user?.mergedPermissions);
  }, [user]);

  return (
    <>
      {user?.token &&
        (user?.role === "Shipper" || user?.role === "ShipperUser") && (
          <>
            <AuthComponent />
            <div className="header__main_wrap">
              <div className="header__logo_wrap">
                <Navbar.Brand>
                  <img alt="" src={logo} className="brand_image_header" />
                </Navbar.Brand>
              </div>
              <div
                className={
                  isMenuOpen
                    ? "header__navigation_main_wrap navigation__mobile_open "
                    : "header__navigation_main_wrap "
                }
              >
                {isMenuOpen ? (
                  <>
                    <div
                      className="header__mobile_overlay"
                      onClick={handleMenuIconClick}
                    ></div>
                    <button
                      className="headernav__btn_cross"
                      onClick={handleMenuIconClick}
                    >
                      <span></span>
                      <span></span>
                    </button>
                  </>
                ) : (
                  " "
                )}
                <ul className="header__navigation_main_list">
                  {shipperPermissions.map(
                    ({ title, url, name, unReadData, icon }, index) => {
                      return (
                        (user.role === "Shipper"
                          ? true
                          : permissions?.includes(name)) && (
                          <li key={index}>
                            <LinkContainer to={url} className="mr-2 ">
                              <Nav.Link className="navbar__link_wrap">
                                {unReadData ? (
                                  <span className="navbar__item_badge">
                                    {unReadData}
                                  </span>
                                ) : (
                                  ""
                                )}
                                {icon}
                                {title}
                              </Nav.Link>
                            </LinkContainer>
                          </li>
                        )
                      );
                    }
                  )}
                </ul>
                <div className="headernav__navicon_wrap">
                  <button
                    className="headernav__navicon_btn"
                    onClick={handleMenuIconClick}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>
              </div>
              <div className="header__dataUser-col_wrap">
                <div className="header__datebadge_col">
                  <DateBadge
                    month={months[currentDate.getMonth()]}
                    day={currentDate.getDate()}
                  />
                </div>
                <div className="header__userprofile_wrap">
                  <div
                    className="navbar__userprofile_section"
                    onClick={() => setModal("profile")}
                  >
                    <div className="navbar__userprofile_avatar">
                      {user.profile_image &&
                      user.profile_image.split("/").pop() !== "undefined" ? (
                        <img
                          src={`${user?.profile_image}`}
                          alt="Profile"
                          className="profile__avatar_image 1"
                        />
                      ) : (
                        <img
                          src={ProfileAvatar}
                          alt=""
                          className="profile__avatar_image 2"
                        />
                      )}
                      <FaChevronDown className="navbar__userprofile_sectiondrop" />
                    </div>

                    {modal === "profile" && (
                      <ProfileModal
                        onClose={() => setModal()}
                        style={{
                          top: "50px",
                          right: "0 ",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

      <ToastContainer position={"top-end"} className="p-3 mt-14">
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={2000}
          autohide
          className="ml-16"
        >
          <Toast.Header>
            <img src={moscot} className="rounded me-2" alt="" width="50" />
            <strong className="me-auto">Hajex Shipment</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body>Succefully Logged Out !!</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
}

export default NavBar;
