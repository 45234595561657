import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import "./productdimensionmodal.css";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { updateProductsDetails } from "../../../../services/product.service";
import CircularLoader from "../../../../Components/CircularLoader/CircularLoader";

export default function ProductDimensionModal({
  open,
  setOpen,
  setSnack,
  setProducts,
}) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [changed, setchanged] = useState(false);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    // console.log(name + " " + value);
    setData((prevData) => ({
      ...prevData,
      row: {
        ...prevData.row,
        [name]: value,
      },
    }));
  };

  const handleClose = () => {
    setLoading(false);
    setchanged(false);
    setData({});
    setOpen(false);
  };

  const handleSave = async () => {
    setLoading(true);
    const productUpdateArray = {
      dimension_unit: data?.row?.dimension_unit,
      length: data?.row?.length,
      width: data?.row?.width,
      height: data?.row?.height,
    };

    const response = await updateProductsDetails(
      productUpdateArray,
      data?.row?.id
    );
    if (!response.error) {
      setProducts((prevProducts) => {
        return {
          ...prevProducts,
          data: prevProducts?.data?.map((product, index) => {
            if (product.id === data?.row?.id) {
              return {
                ...product,
                dimension_unit: data?.row?.dimension_unit,
                length: data?.row?.length,
                width: data?.row?.width,
                height: data?.row?.height,
              };
            }
            return product;
          }),
        };
      });
      setOpen(data);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Product Updated successfully",
          severity: "success",
        };
      });
      setLoading(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    setData(open);
  }, [open]);

  useEffect(() => {
    let hasChanges = false;

    if (
      open?.row?.dimension_unit != data?.row?.dimension_unit ||
      open?.row?.length != data?.row?.length ||
      open?.row?.width != data?.row?.width ||
      open?.row?.height != data?.row?.height
    ) {
      hasChanges = true;
    }

    if (open?.row && data?.row) {
      setchanged(hasChanges);
    }
  }, [open?.row, data?.row]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="prod__dimension_modal ">
          <div className="prod__dimension_modalheading">
            Edit Product Dimension's
          </div>
          <div className="prod__dimensionmdl_formbox " id="products_dimensions">
            <div className="prod__dimensionmdl_formrow">
              <div
                className="prod__dimensionmdl_formcol"
                style={{ width: "99%" }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" size="small">
                    Size Unit
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    size="small"
                    onChange={(e) => handleFieldChange(e)}
                    value={data?.row?.dimension_unit?.toLowerCase() || ""}
                    id="dimension_unit"
                    label="Size Unit"
                    name="dimension_unit"
                  >
                    <MenuItem value="in">In</MenuItem>
                    <MenuItem value="cm">Cm</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="prod__dimensionmdl_formrow">
              <div
                className="prod__dimensionmdl_formcol"
                style={{ width: "33%" }}
              >
                <TextField
                  size="small"
                  type="number"
                  onChange={(e) => handleFieldChange(e)}
                  label="Length"
                  name="length"
                  value={data?.row?.length || ""}
                  variant="outlined"
                  className="prod__dimensionmdl_formfield"
                />
              </div>
              <div
                className="prod__dimensionmdl_formcol"
                style={{ width: "33%" }}
              >
                <TextField
                  size="small"
                  type="number"
                  onChange={(e) => handleFieldChange(e)}
                  label="Width"
                  name="width"
                  value={data?.row?.width || ""}
                  variant="outlined"
                  className="prod__dimensionmdl_formfield"
                />
              </div>
              <div
                className="prod__dimensionmdl_formcol"
                style={{ width: "33%" }}
              >
                <TextField
                  size="small"
                  type="number"
                  onChange={(e) => handleFieldChange(e)}
                  label="Height"
                  name="height"
                  value={data?.row?.height || ""}
                  variant="outlined"
                  className="prod__dimensionmdl_formfield"
                />
              </div>
            </div>
          </div>
          <div className="prod__dimension_modalfooter">
            <div className="prod__dimension_modalcncl" onClick={handleClose}>
              Close
            </div>
            {changed === true ? (
              <div className="prod__dimension_modalsave" onClick={handleSave}>
                {loading ? <CircularLoader /> : "Save"}
              </div>
            ) : null}
          </div>
        </div>
      </Modal>
    </div>
  );
}
