import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Row3.css";
import { IoInformationCircleOutline } from "react-icons/io5";
import IconButton from "@mui/material/IconButton";
import Tooltip from "../../../../../../Components/Tooltip/Tooltip";
import CustomLoader, { Spinnerbtn } from "./Customloader";
import Table from "./QuoteTable";
import Buttons from "@mui/material/Button";
import Collapses from "@mui/material/Collapse";
import { useEffect } from "react";
import FinalQuoteModel from "./FinalQuoteModel";

export default function Package(props) {
  const { StepCheck, SetStepCheck, Flist, Tlist, CreateShipment, Plist } =
    props;

  function handleRowSelect(row) {
    if (
      StepCheck["Shipmentby"] === row.comp_id &&
      StepCheck["ShipmentType"] === row.shipment_type &&
      StepCheck["serviceType"] === row.service_type
    ) {
      SetStepCheck((prevStepCheck) => ({
        ...prevStepCheck,
        ShipmentType: "",
        Shipmentby: "",
        serviceType: "",
      }));
    } else {
      SetStepCheck((prevStepCheck) => ({
        ...prevStepCheck,
        ShipmentType: row.shipment_type,
        Shipmentby: row.comp_id,
        serviceType: row.service_type,
      }));
    }
  }

  useEffect(() => {
    if (StepCheck["fullform"] === true) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [StepCheck["fullform"]]);

  return (
    <>
      {StepCheck["QuoteCheck"] === true && (
        <>
          <Row className="mb-5 mx-auto select__quote_main_wrap">
            <Col md={11} className="mx-auto">
              <div className="card bg-white shadow  wapper_like_shopify">
                <div className="card-header">
                  <span> SELECT QUOTE </span>
                  <Tooltip title="Select A Carrier To ship With">
                    <IconButton>
                      <IoInformationCircleOutline size={15} />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className="card-body bg-white shadow select__quote_card_body">
                  <div className="mx-auto d-flex selectQuote__table_wrap">
                    <Table
                      data={StepCheck["QuoteData"]}
                      selectedRows={StepCheck["Shipmentby"]}
                      type={StepCheck["ShipmentType"]}
                      onSelect={handleRowSelect}
                      service={StepCheck["serviceType"]}
                      className="select__quote_card_body_table"
                      loader={StepCheck["Pending"]}
                    />
                  </div>

                  {!StepCheck["Pending"] &&
                    StepCheck["QuoteData"]?.length > 0 && (
                      <Row>
                        <div className="d-flex flex-row-reverse">
                          {/* <div className="mb-2 mt-2 mr-2 p-2">
                                    <Button type="btn" onClick={()=> setsaveshipmentmodel(true)}>Save Shipment</Button>
                                  </div> */}
                          <Collapses
                            in={
                              StepCheck["fullform"] === false &&
                              StepCheck["Shipmentby"] !== ""
                                ? true
                                : false
                            }
                            timeout="auto"
                            unmountOnExit
                          >
                            <div className="mb-2 mt-2 mr-2 p-2">
                              <Buttons
                                variant="contained"
                                onClick={() =>
                                  SetStepCheck((prevStepCheck) => ({
                                    ...prevStepCheck,
                                    fullform: true,
                                  }))
                                }
                                style={{
                                  borderColor: "#0c69f3",
                                  borderRadius: "3px",
                                }}
                                disableElevation
                              >
                                Create Shipment
                              </Buttons>
                            </div>
                          </Collapses>
                          <Collapses
                            in={
                              StepCheck["fullform"] &&
                              StepCheck["Shipmentby"] !== ""
                                ? true
                                : false
                            }
                            timeout="auto"
                            unmountOnExit
                          >
                            <div className="mb-2 mt-2 mr-2 p-2">
                              <Buttons
                                type="submit"
                                variant="contained"
                                className="btn btn-primary"
                                disabled={
                                  StepCheck["Disable"] === "finalquote"
                                    ? true
                                    : false
                                }
                                style={{
                                  borderColor: "#0c69f3",
                                  borderRadius: "3px",
                                }}
                                disableElevation
                              >
                                {StepCheck["Disable"] === "finalquote" && (
                                  <Spinnerbtn
                                    style={{
                                      position: "absolute",
                                      minHeight: "24px",
                                      minWidth: "24px",
                                      // marginLeft: "8px",
                                      zIndex: 1,
                                    }}
                                    className="mx-auto"
                                  />
                                )}
                                Submit order
                              </Buttons>
                            </div>
                          </Collapses>
                          {/* <div className="mb-2 mt-2 mr-2 p-2">
                                <Button onClick={() => setquotecheck("false")}>
                                  Quote Again
                                </Button>
                              </div> */}
                        </div>
                      </Row>
                    )}
                </div>
              </div>
            </Col>
          </Row>

          <FinalQuoteModel
            Flist={Flist}
            Tlist={Tlist}
            Plist={Plist}
            StepCheck={StepCheck}
            SetStepCheck={SetStepCheck}
            CreateShipment={CreateShipment}
          />
        </>
      )}
    </>
  );
}
