import React from "react";
import "../InvoiceModal.css";

// components imports
import CarrierTableLoader from "../../../Components/CarrierInnerTableLoader/CarrierInnerTableLoader";
// apis imports
// library imports
import { FaRegTimesCircle } from "react-icons/fa";
import { MdArrowCircleDown } from "react-icons/md";
import International from "./InvoiceSubComponents/International";
import ShipmentSummary from "./InvoiceSubComponents/ShipmentSummary";
import Tracking from "./InvoiceSubComponents/Tracking";
import Header from "./InvoiceSubComponents/Header";
import ShipmentDetails from "./InvoiceSubComponents/ShipmentDetails";
import Footer from "./InvoiceSubComponents/Footer";
import BillingAdmin from "./InvoiceSubComponents/BillingAdmin";
import AdminActions from "./InvoiceSubComponents/AdminActions";
import NotesAdmin from "./InvoiceSubComponents/NotesAdmin";

const EditableInvoice = ({
  data,
  setData,
  handleClose,
  downloadPdf,
  printLabel,
  setShipments,
  getAllShipments,
  loading,
}) => {
  return (
    <div className="invoice__modal_wrap invoice__modal_style_optamize_wrap static__invoice_wrap">
      {loading === false ? (
        <div className="invoice__modal_box invoice__shipment_style_optamize_wrap">
          <div className="invoice__modal_downloadinvoicewrap">
            {/* <AiOutlineCloudDownload
            size={25}
            color="#0c69f3"
            onClick={downloadPdf}
          /> */}
            {/* <img src={Download} width="25px" onClick={downloadPdf} /> */}
            <button
              onClick={() => downloadPdf(data)}
              className="invoicemdl__download_btn"
            >
              <MdArrowCircleDown size={16} />
              <div className="labeltxt">
                <span className="invoivemdl__btn_dwn_text">
                    Download
                  </span> {" "} Invoice
              </div>
              
            </button>
            <button
              onClick={() => printLabel(data)}
              className="invoicemdl__download_btnoutlined"
            >
              <MdArrowCircleDown size={16} />
              <div className="labeltxt">
                <span className="invoivemdl__btn_dwn_text">
                    Download
                  </span> {" "} Label
              </div>
              
            </button>
            <button
              onClick={handleClose}
              className="invoicemdl__download_btn_danger"
            >
              <FaRegTimesCircle size={16} />
              <div className="labeltxt">
                Close
              </div>
              
            </button>
          </div>
          <Header data={data} />
          <ShipmentSummary data={data} />
          <International data={data} />
          <ShipmentDetails data={data} />

          <AdminActions
            data={data}
            setShipments={setShipments}
            setData={setData}
            getAllShipments={getAllShipments}
            type="admin"
          />
          <AdminActions
            data={data}
            setShipments={setShipments}
            setData={setData}
            getAllShipments={getAllShipments}
            type="admin-user"
          />

          <BillingAdmin
            data={data}
            setData={setData}
            setShipments={setShipments}
          />

          <NotesAdmin
            data={data}
            setShipments={setShipments}
            setData={setData}
            getAllShipments={getAllShipments}
          />
          <Tracking data={data} />
          <Footer data={data} />
        </div>
      ) : (
        <div className="fulfilment_tier_loader">
          <CarrierTableLoader />
        </div>
      )}
    </div>
  );
};

export default EditableInvoice;
