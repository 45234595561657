import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { CiMenuKebab } from "react-icons/ci";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  MdOutlineStickyNote2,
  MdLabelImportantOutline,
  MdOutlineCancel,
  MdComment,
  MdRepeat,
} from "react-icons/md";
import { TbFileInvoice } from "react-icons/tb";

export default function CustomMenu({
  row,
  handleTicketOpen,
  setInvoiceModal,
  printDeclaration,
  labelDownloading,
  CircularLoader,
  printLabel,
  handleReShip,
  setConfirmCancel,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="table_action__wrap">
      <div className="table_action__btn" onClick={handleClick}>
        <CiMenuKebab size={10} style={{ marginRight: "2px" }} />
        More
      </div>
      <Menu
        id={`basic-menu-${row.id}`} // Unique ID for each row
        anchorEl={anchorEl}
        open={open}
        size="small"
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": `basic-button-${row.id}`, // Unique ID for each row
          "box-shadow": "none",
        }}
      >
        {/* <MenuItem
          className="boltTable__action_more_btn_label"
          key="edit"
          onClick={() => {
            handleTicketOpen(row);
            handleClose();
          }}
          style={{
            backgroundColor:
              row?.tickets?.length > 0 &&
              (row?.tickets[0]?.status == "Open" ||
                row?.tickets[0]?.status == "Dispute" ||
                row?.tickets[0]?.status == "Disputed")
                ? "red"
                : "none",
            color:
              row?.tickets?.length > 0 &&
              (row?.tickets[0]?.status == "Open" ||
                row?.tickets[0]?.status == "Dispute" ||
                row?.tickets[0]?.status == "Disputed")
                ? "white"
                : "none",
          }}
        >
          <ListItemIcon>
            <MdComment size={15} />
          </ListItemIcon>
          <ListItemText>Ticket</ListItemText>
        </MenuItem> */}
        <MenuItem
          className="boltTable__action_more_btn_label"
          onClick={() => {
            setInvoiceModal(row?.id);
            handleClose();
          }}
        >
          <ListItemIcon>
            <MdOutlineStickyNote2 size={15} />
          </ListItemIcon>
          <ListItemText>View</ListItemText>
        </MenuItem>
        {row?.single_shipping_invoice && (
          <MenuItem
            className="boltTable__action_more_btn_label"
            key="Declaration"
            onClick={() => {
              printDeclaration(row);
              handleClose();
            }}
            disabled={
              row?.shipment_status?.toLowerCase() === "cancelled" ? true : false
            }
          >
            <ListItemIcon>
              {labelDownloading === "Declaration" + row?.id ? (
                <CircularLoader side={15} />
              ) : (
                <>
                  <TbFileInvoice size={15} />
                  {/* Declaration */}
                </>
              )}
            </ListItemIcon>
            <ListItemText>Declaration</ListItemText>
          </MenuItem>
        )}
        <MenuItem
          className="boltTable__action_more_btn_label"
          onClick={() => {
            printLabel(row);
            handleClose();
          }}
          disabled={
            row?.shipment_status?.toLowerCase() === "cancelled" ? true : false
          }
        >
          <ListItemIcon>
            {labelDownloading === "label" + row?.id ? (
              <CircularLoader size={15} />
            ) : (
              <>
                <MdLabelImportantOutline size={15} />
              </>
            )}
          </ListItemIcon>
          <ListItemText>Label</ListItemText>
        </MenuItem>
        <MenuItem
          className="boltTable__action_more_btn_label"
          onClick={() => {
            handleReShip(row);
            handleClose();
          }}
        >
          <ListItemIcon>
            <MdRepeat size={15} />
          </ListItemIcon>
          <ListItemText>Reship</ListItemText>
        </MenuItem>
        <MenuItem
          className="boltTable__action_more_btn_label"
          onClick={() => {
            setConfirmCancel(row.id);
            handleClose();
          }}
          disabled={
            row?.shipment_status?.toLowerCase() === "cancelled" ? true : false
          }
        >
          <ListItemIcon>
            <MdOutlineCancel size={15} />
          </ListItemIcon>
          <ListItemText>Cancel</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
