import React, { useState } from "react";
import "./TableSize3.css";
import { Swiper, SwiperSlide } from "swiper/react";
import Empty from "../../../../../assets/images/dispatcher-empty.gif";
import { styled } from "@mui/material/styles";
import DropdownIcon from "../../../../../assets/icons/dispatcher-dropdown.svg";
import CheckIcon from "../../../../../assets/icons/dispatcher-check.svg";
import LoadingIcon from "../../../../../assets/icons/dispatcher-loading.svg";
import FailedIcon from "../../../../../assets/icons/dispatcher-failed.svg";
import FileIcon from "../../../../../assets/icons/dispatcher-file.svg";
import LabelIcon from "../../../../../assets/icons/dispatcher-label.svg";
import DetailsIcon from "../../../../../assets/icons/dispatcher-details.svg";
import InfoIcon from "../../../../../assets/icons/dispatcher-info.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import AddPerson from "@mui/icons-material/GroupAdd";
import EditIcon from "@mui/icons-material/DriveFileRenameOutline";
import Flag from "react-world-flags";
import {
  cbfToCbm,
  cbmToCbf,
  kgsToLbs,
  lbsToKgs,
  toggleValue,
} from "../../../../../helpers/helpers";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import CarrierInnerTableLoader from "../../../../../Components/CarrierInnerTableLoader/CarrierInnerTableLoader";
import SelectDriver from "../SelectDriver/SelectDriver";
import InvoiceModal from "../../../../../Components/InvoiceModal/InvoiceModal";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const TableSize3 = ({
  shipments,
  handleRowChecked,
  handleRowExpand,
  checkedRows,
  handleAllChecked,
  handleDriverChange,
  cols,
  pickupDrivers,
  deliveryDrivers,
  expandedRow,
  weightUnit,
  setWeightUnit,
  volumeUnit,
  setVolumeUnit,
  isEmpty,
  pagination,
  getAllShipments,
  loading,
  drivers,
  setSnack,
  setShipments,
}) => {
  const [page, setPage] = React.useState(1);
  const [selectDriverModal, setSelectDriverModal] = React.useState();
  const [invoiceModal, setInvoiceModal] = React.useState();
  function getAdditionalServices(obj) {
    const fields = [
      "dangerous",
      "fresidential",
      "fragile",
      "insurance",
      "insurance_type",
      "insurance_amount",
      "tresidential",
      "return_label",
      "signature",
      "paper_invoice",
      "cash_on_delivery",
    ];

    return fields
      .filter((field) => ["true", true, "1"].includes(obj[field]))
      .map((field) => {
        if (field === "cash_on_delivery") {
          return "COD";
        } else {
          return field.replace(/_/g, " ").toUpperCase();
        }
      });
  }
  const handlePageChange = (event, value) => {
    getAllShipments(value);
  };
  return (
    <div className="dispatcher__table_size3">
      <SelectDriver
        open={selectDriverModal}
        setOpen={setSelectDriverModal}
        drivers={drivers}
        setSnack={setSnack}
        setShipments={setShipments}
      />
      <InvoiceModal
        open={!!invoiceModal}
        data={invoiceModal}
        setOpen={setInvoiceModal}
        type="editable"
      />
      <div
        className={`dispatcher__screen_tablewrap ${
          loading && "dispatcher__screen_tablewraploading"
        }`}
      >
        <div className="dispscreen__table_topbar">SHIPMENTS</div>
        <div className="dispatcher__screen_tablecontent">
          {!isEmpty && (
            <div className="dispscreen__table_main">
              <div className="dispscreen__table_header">
                {cols[0].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[0].largeWidth,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={DropdownIcon}
                      alt=""
                      className="dispscreen__theader_hdrophide"
                    />
                  </div>
                )}
                {/* {cols[1].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[1].largeWidth,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      className="dispscreen__theader_hcheck"
                      onChange={() => handleAllChecked()}
                      checked={checkedRows.length === shipments.length}
                    />
                  </div>
                )} */}
                {/* {cols[2].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[2].largeWidth,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    #
                  </div>
                )} */}
                {cols[3].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[3].largeWidth,
                    }}
                  >
                    Carrier Tracking ID
                  </div>
                )}
                {/* {cols[4].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[4].largeWidth,
                    }}
                  >
                    Carrier
                  </div>
                )} */}
                {cols[5].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[5].largeWidth,
                    }}
                  >
                    {`Created `}{" "}
                    <LightTooltip placement="top" arrow title="YYYY-MM-DD">
                      <img src={InfoIcon} className="dispscreen__thdata_info" />
                    </LightTooltip>
                  </div>
                )}
                {cols[6].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[6].largeWidth,
                    }}
                  >
                    Bolt Tracking ID
                  </div>
                )}
                {cols[7].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[7].largeWidth,
                    }}
                  >
                    Pickup
                    <LightTooltip placement="top" arrow title="YYYY-MM-DD">
                      <img src={InfoIcon} className="dispscreen__thdata_info" />
                    </LightTooltip>
                  </div>
                )}
                {cols[8].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[8].largeWidth,
                    }}
                  >
                    Delivery
                    <LightTooltip placement="top" arrow title="YYYY-MM-DD">
                      <img src={InfoIcon} className="dispscreen__thdata_info" />
                    </LightTooltip>
                  </div>
                )}
                {cols[9].checked && (
                  <div
                    className="dispscreen__theader_heading dispscreen__thdata_dropwrap"
                    style={{
                      overflow: "hidden",
                      width: cols[9].largeWidth,
                    }}
                  >
                    <Select
                      id="demo-simple-select-standard"
                      value={weightUnit}
                      onChange={(e) => {
                        setWeightUnit(e.target.value);
                      }}
                      sx={{
                        outline: "none",
                        border: "none",
                        marginLeft: "-10px",
                        fontFamily: "inherit",
                        fontSize: "17px",
                        fontWeight: 500,
                        "& *": {
                          outline: "none",
                          border: "none",
                          fontFamily: "inherit",
                        },
                        "& > div": {
                          marginTop: "4px",
                        },
                      }}
                    >
                      <MenuItem value={"lb"}>Weight (lb)</MenuItem>
                      <MenuItem value={"kg"}>Weight (kg)</MenuItem>
                    </Select>
                  </div>
                )}
                {cols[10].checked && (
                  <div
                    className="dispscreen__theader_heading dispscreen__thdata_dropwrap"
                    style={{
                      overflow: "hidden",
                      width: cols[10].largeWidth,
                    }}
                  >
                    <Select
                      id="demo-simple-select-standard"
                      value={volumeUnit}
                      onChange={(e) => {
                        setVolumeUnit(e.target.value);
                      }}
                      sx={{
                        outline: "none",
                        border: "none",
                        marginLeft: "-10px",
                        fontFamily: "inherit",
                        fontSize: "17px",
                        fontWeight: 500,
                        "& *": {
                          outline: "none",
                          border: "none",
                          fontFamily: "inherit",
                        },
                        "& > div": {
                          marginTop: "4px",
                        },
                      }}
                    >
                      <MenuItem value={"cbm"}>Volume (cbm)</MenuItem>
                      <MenuItem value={"cbf"}>Volume (cbf)</MenuItem>
                    </Select>
                  </div>
                )}
                {cols[11].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[11].largeWidth,
                    }}
                  >
                    Packages
                  </div>
                )}
                {cols[12].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[12].largeWidth,
                    }}
                  >
                    Pickup Zone
                  </div>
                )}
                {cols[13].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[13].largeWidth,
                    }}
                  >
                    Delivery Zone
                  </div>
                )}
                {cols[14].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[14].largeWidth,
                    }}
                  >
                    Pickup Country
                  </div>
                )}
                {cols[15].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[15].largeWidth,
                    }}
                  >
                    Delivery Country
                  </div>
                )}
                {cols[16].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[16].largeWidth,
                    }}
                  >
                    Pickup City
                  </div>
                )}
                {cols[17].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[17].largeWidth,
                    }}
                  >
                    Delivery City
                  </div>
                )}
                {/* {cols[18].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[18].largeWidth,
                    }}
                  >
                    Pickup Address
                  </div>
                )}
                {cols[19].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[19].largeWidth,
                    }}
                  >
                    Delivery Address
                  </div>
                )} */}
                {cols[20].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[20].largeWidth,
                    }}
                  >
                    Pickup Postal/Zip
                  </div>
                )}
                {cols[21].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[21].largeWidth,
                    }}
                  >
                    Delivery Postal/Zip
                  </div>
                )}
                {cols[22].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[22].largeWidth,
                    }}
                  >
                    Pickup Address
                  </div>
                )}
                {cols[23].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[23].largeWidth,
                    }}
                  >
                    Delivery Address
                  </div>
                )}
                {/* {cols[24].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[24].largeWidth,
                    }}
                  >
                    Packages Status
                  </div>
                )} */}
                {cols[25].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[25].largeWidth,
                    }}
                  >
                    Pickup Notes
                  </div>
                )}
                {cols[26].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[26].largeWidth,
                    }}
                  >
                    Delivery Notes
                  </div>
                )}
                {cols[27].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[27].largeWidth,
                    }}
                  >
                    Additional Services
                  </div>
                )}
                {cols[28].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[28].largeWidth,
                    }}
                  >
                    Total
                  </div>
                )}
                {cols[29].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[29].largeWidth,
                    }}
                  >
                    Payment Status
                  </div>
                )}
                {cols[30].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[30].largeWidth,
                    }}
                  >
                    Shipment Status
                  </div>
                )}
                {/* {cols[31].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[31].largeWidth,
                    }}
                  >
                    Priority
                  </div>
                )} */}
                {cols[32].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[32].largeWidth,
                    }}
                  >
                    Pickup Driver
                  </div>
                )}
                {cols[33].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[33].largeWidth,
                    }}
                  >
                    Delivery Driver
                  </div>
                )}
                {cols[34].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[34].largeWidth,
                    }}
                  >
                    Action
                  </div>
                )}
                {/* {cols[35].checked && (
                  <div
                    className="dispscreen__theader_heading"
                    style={{
                      overflow: "hidden",
                      width: cols[35].largeWidth,
                    }}
                  >
                    Offers
                  </div>
                )} */}
              </div>
              {shipments.map((item, shipmentindex) => (
                <div className="dispscreen__table_row">
                  <div className="dispscreen__table_innerrow">
                    {cols[0].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[0].largeWidth,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={DropdownIcon}
                          alt=""
                          className={`dispscreen__theader_hdrop ${
                            expandedRow === item.id &&
                            "dispscreen__theader_hdropreverse"
                          } `}
                          onClick={() => handleRowExpand(item.id)}
                        />
                      </div>
                    )}
                    {/* {cols[1].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[1].largeWidth,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <input
                          type="checkbox"
                          className="dispscreen__theader_hcheck"
                          onChange={(e) => handleRowChecked(item.id)}
                          checked={checkedRows.includes(item.id)}
                        />
                      </div>
                    )} */}
                    {/* {cols[2].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[2].largeWidth,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {shipmentindex + 1}
                      </div>
                    )} */}
                    {cols[3].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[3].largeWidth,
                        }}
                      >
                        {item.id ?? "-"}
                      </div>
                    )}
                    {/* {cols[4].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[4].largeWidth,
                        }}
                      >
                        <img
                          src={item.carrier}
                          className="dispscreen__theader_carrlogo"
                        />
                      </div>
                    )} */}
                    {cols[5].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[5].largeWidth,
                        }}
                      >
                        {item.created_at?.slice(0, 16) ?? "-"}
                      </div>
                    )}
                    {cols[6].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[6].largeWidth,
                        }}
                      >
                        {`${item.tracking_id ?? "-"}`}
                      </div>
                    )}
                    {cols[7].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[7].largeWidth,
                        }}
                      >
                        {item.PickupDate
                          ? `${item.PickupDate?.slice(0, 10)}  ${
                              item.readyopentime
                                ? item.readyopentime?.slice(0, 4)
                                : ""
                            }${
                              item.readyopentime && item.readyclosetime
                                ? "-"
                                : ""
                            }${
                              item.readyclosetime
                                ? item.readyclosetime?.slice(0, 4)
                                : ""
                            }`
                          : "-"}
                      </div>
                    )}
                    {cols[8].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[8].largeWidth,
                        }}
                      >
                        {item.deliverydate
                          ? `${
                              item.deliverydate &&
                              item.deliverydate?.slice(0, 10)
                            } ${
                              item.deliveryopentime
                                ? item.deliveryopentime?.slice(0, 4)
                                : ""
                            }${
                              item.deliveryopentime && item.deliveryclosetime
                                ? "-"
                                : ""
                            }${
                              item.deliveryclosetime
                                ? item.deliveryclosetime?.slice(0, 4)
                                : ""
                            }`
                          : "-"}
                      </div>
                    )}
                    {cols[9].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[9].largeWidth,
                        }}
                      >
                        {weightUnit === "lb" &&
                          `${
                            item.unit.toLowerCase() === "imperial"
                              ? item.total_weight
                              : kgsToLbs(item.total_weight)
                          } ${weightUnit}`}
                        {weightUnit === "kg" &&
                          `${
                            item.unit.toLowerCase() === "imperial"
                              ? lbsToKgs(item.total_weight)
                              : item.total_weight
                          } ${weightUnit}`}
                      </div>
                    )}
                    {cols[10].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[10].largeWidth,
                        }}
                      >
                        {volumeUnit === "cbm" &&
                          `${item.volume_in_cbm} ${volumeUnit}`}
                        {volumeUnit === "cbf" &&
                          `${cbmToCbf(item.volume_in_cbm)} ${volumeUnit}`}
                      </div>
                    )}
                    {cols[11].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[11].largeWidth,
                        }}
                      >
                        {item.shipment_details?.length}
                      </div>
                    )}
                    {cols[12].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[12].largeWidth,
                        }}
                      >
                        {item.pickup_zone?.name}
                      </div>
                    )}
                    {cols[13].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[13].largeWidth,
                        }}
                      >
                        {item.delivery_zone?.name}
                      </div>
                    )}
                    {cols[14].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[14].largeWidth,
                        }}
                      >
                        <div className="dispscreen__theader_flagwrap">
                          <Flag
                            code={item.fcountrycode}
                            fallback={<span>Unknown</span>}
                            style={{
                              height: "100%",
                              overflow: "hidden",
                              width: "100%",
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {cols[15].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[15].largeWidth,
                        }}
                      >
                        <div className="dispscreen__theader_flagwrap">
                          <Flag
                            code={item.tcountrycode}
                            fallback={<span>Unknown</span>}
                            style={{
                              height: "100%",
                              overflow: "hidden",
                              width: "100%",
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {cols[16].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[16].largeWidth,
                        }}
                      >
                        {item.fcity}
                      </div>
                    )}
                    {cols[17].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[17].largeWidth,
                        }}
                      >
                        {item.tcity}
                      </div>
                    )}
                    {/* {cols[18].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[18].largeWidth,
                        }}
                      >
                        {item.faddress}
                      </div>
                    )}
                    {cols[19].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[19].largeWidth,
                        }}
                      >
                        {item.taddress}
                      </div>
                    )} */}
                    {cols[20].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[20].largeWidth,
                        }}
                      >
                        {item.fzip}
                      </div>
                    )}
                    {cols[21].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[21].largeWidth,
                        }}
                      >
                        {item.tzip}
                      </div>
                    )}
                    {cols[22].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[22].largeWidth,
                        }}
                      >
                        {item.faddress}
                      </div>
                    )}
                    {cols[23].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[23].largeWidth,
                        }}
                      >
                        {item.taddress}
                      </div>
                    )}
                    {/* {cols[24].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[24].largeWidth,
                        }}
                      >
                        <div className="dispscreen__thdata_pkgstatus">
                          {item.packages_status}
                        </div>
                      </div>
                    )} */}
                    {cols[25].checked && (
                      <div
                        className="dispscreen__theader_data dispscreen__thdata_pnotes"
                        style={{
                          overflow: "hidden",
                          width: cols[25].largeWidth,
                        }}
                      >
                        {item.finstructions?.length < 60 && item.finstructions}
                        {item.finstructions?.length > 59 && (
                          <LightTooltip
                            placement="top"
                            arrow
                            title={item.finstructions}
                          >
                            <span>
                              {item.finstructions.substring(0, 58)}...
                            </span>
                          </LightTooltip>
                        )}
                      </div>
                    )}
                    {cols[26].checked && (
                      <div
                        className="dispscreen__theader_data dispscreen__thdata_pnotes"
                        style={{
                          overflow: "hidden",
                          width: cols[26].largeWidth,
                        }}
                      >
                        {item.tinstructions?.length < 60 && item.tinstructions}
                        {item.tinstructions?.length > 59 && (
                          <LightTooltip
                            placement="top"
                            arrow
                            title={item.tinstructions}
                          >
                            <span>
                              {item.tinstructions.substring(0, 58)}...
                            </span>
                          </LightTooltip>
                        )}
                      </div>
                    )}
                    {cols[27].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[27].largeWidth,
                        }}
                      >
                        <div className="dispscreen__thdata_badges">
                          {getAdditionalServices(item)?.map((service) => (
                            <div className="dispscreen__thdata_badge dispscreen__thdata_badgedrag">
                              <div className="dispscreen__thdata_badgeicon">
                                <img src={CheckIcon} />
                              </div>
                              <div className="dispscreen__thdata_badgetext">
                                {service}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {cols[28].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[28].largeWidth,
                        }}
                      >
                        {item.Total ?? "-"}
                      </div>
                    )}
                    {cols[29].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[29].largeWidth,
                        }}
                      >
                        <div
                          className="dispscreenthd__paystatus_badge"
                          style={{
                            borderColor:
                              item.payment_status?.toLowerCase() === "unpaid"
                                ? "#FAE017"
                                : item.payment_status?.toLowerCase() ===
                                    "paid" || !item.payment_status
                                ? "#20CD04"
                                : "#D21C3D",
                            background:
                              item.payment_status?.toLowerCase() === "unpaid"
                                ? "#FDFAE6"
                                : item.payment_status?.toLowerCase() ===
                                    "paid" || !item.payment_status
                                ? "#E8FCE5"
                                : "#FFECEF",
                          }}
                        >
                          <div className="dispscreenthd__paystatus_badgeicon">
                            {item.payment_status?.toLowerCase() ===
                              "unpaid" && <img src={LoadingIcon} />}
                            {(item.payment_status?.toLowerCase() === "paid" ||
                              !item.payment_status) && <img src={CheckIcon} />}
                            {item.payment_status?.toLowerCase() ===
                              "failed" && <img src={FailedIcon} />}
                          </div>
                          <div className="dispscreenthd__paystatus_badgetext">
                            {item.payment_status ?? "Paid"}
                          </div>
                        </div>
                      </div>
                    )}
                    {cols[30].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[30].largeWidth,
                        }}
                      >
                        <div
                          className="dispscreenthd__shipment_status"
                          style={{
                            borderColor: ["returned", "cancelled"].includes(
                              item.shipment_status?.toLowerCase()
                            )
                              ? "#FFB9C5"
                              : item.shipment_status?.toLowerCase() ===
                                "pending"
                              ? "#60A0FF"
                              : "#71F46E",
                            background: ["returned", "cancelled"].includes(
                              item.shipment_status?.toLowerCase()
                            )
                              ? "#FFECEF"
                              : item.shipment_status?.toLowerCase() ===
                                "pending"
                              ? "#DBE9FF"
                              : "#DEFFD9",
                          }}
                        >
                          {item.shipment_status}
                        </div>
                      </div>
                    )}
                    {/* {cols[31].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[31].largeWidth,
                        }}
                      >
                        <div
                          className="dispscreenthd__priority_badge"
                          style={{
                            color:
                              item.priority?.toLowerCase() === "high"
                                ? "#D21C3D"
                                : item.priority?.toLowerCase() === "low"
                                ? "#16A000"
                                : "#FFB406",
                          }}
                        >
                          <span
                            className="dispscreenthd__priority_badgeicon"
                            style={{
                              background:
                                item.priority?.toLowerCase() === "high"
                                  ? "#D21C3D"
                                  : item.priority?.toLowerCase() === "low"
                                  ? "#16A000"
                                  : "#FFB406",
                            }}
                          ></span>
                          <span className="dispscreenthd__priority_badgetext">
                            {item.priority}
                          </span>
                        </div>
                      </div>
                    )} */}
                    {cols[32].checked && (
                      <div
                        className="dispscreen__theader_data dispscreen__thdata_dropwrap"
                        style={{
                          overflow: "hidden",
                          width: cols[32].largeWidth,
                        }}
                      >
                        {/* <Select
                          id="demo-simple-select-standard"
                          defaultValue=""
                          value={item.pickup_driver}
                          onChange={(e) => {
                            handleDriverChange(
                              "pickup",
                              item.id,
                              e.target.value
                            );
                          }}
                          sx={{
                            outline: "none",
                            border: "none",
                            marginLeft: "-10px",
                            fontFamily: "inherit",
                            fontSize: "17px",
                            "& *": {
                              outline: "none",
                              border: "none",
                              fontFamily: "inherit",
                            },
                          }}
                        >
                          <MenuItem value="">Select Driver</MenuItem>
                          {drivers.map((driver) => (
                            <MenuItem value={driver.id}>{driver.name}</MenuItem>
                          ))}
                        </Select> */}
                        {item.pickup_driver ? (
                          <>
                            {item.pickup_driver.name}
                            <EditIcon
                              onClick={() => {
                                setSelectDriverModal({
                                  shipmentId: item.id,
                                  type: "pickup",
                                });
                              }}
                              className="dispscrn__selectdriver_editicon"
                            />
                          </>
                        ) : (
                          <span
                            className="dispscrn__selectdriver_btn"
                            onClick={() => {
                              setSelectDriverModal({
                                shipmentId: item.id,
                                type: "pickup",
                              });
                            }}
                          >
                            <AddPerson />
                            Select Driver
                          </span>
                        )}
                      </div>
                    )}
                    {cols[33].checked && (
                      <div
                        className="dispscreen__theader_data dispscreen__thdata_dropwrap"
                        style={{
                          overflow: "hidden",
                          width: cols[33].largeWidth,
                        }}
                      >
                        {/* <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          defaultValue=""
                          value={item.delivery_driver}
                          onChange={(e) => {
                            handleDriverChange(
                              "delivery",
                              item.id,
                              e.target.value
                            );
                          }}
                          sx={{
                            outline: "none",
                            border: "none",
                            marginLeft: "-10px",
                            fontFamily: "inherit",
                            fontSize: "17px",
                            "& *": {
                              outline: "none",
                              border: "none",
                              fontFamily: "inherit",
                            },
                          }}
                        >
                          <MenuItem value="">Select Driver</MenuItem>
                          {drivers.map((driver) => (
                            <MenuItem value={driver.id}>{driver.name}</MenuItem>
                          ))}
                        </Select> */}
                        {item.delivery_driver ? (
                          <>
                            {item.delivery_driver.name}
                            <EditIcon
                              onClick={() => {
                                setSelectDriverModal({
                                  shipmentId: item.id,
                                  type: "delivery",
                                });
                              }}
                              className="dispscrn__selectdriver_editicon"
                            />
                          </>
                        ) : (
                          <span
                            className="dispscrn__selectdriver_btn"
                            onClick={() => {
                              setSelectDriverModal({
                                shipmentId: item.id,
                                type: "delivery",
                              });
                            }}
                          >
                            <AddPerson />
                            Select Driver
                          </span>
                        )}
                      </div>
                    )}
                    {cols[34].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[34].largeWidth,
                        }}
                      >
                        <div className="dispscreen__thdata_actions">
                          <LightTooltip placement="top" arrow title="Invoice">
                            <div
                              className="dispscreen__thdata_action"
                              onClick={() => setInvoiceModal(item)}
                            >
                              <img src={FileIcon} />
                            </div>
                          </LightTooltip>
                          {/* <LightTooltip placement="top" arrow title="Label">
                            <div className="dispscreen__thdata_action">
                              <img src={LabelIcon} />
                            </div>
                          </LightTooltip>
                          <LightTooltip placement="top" arrow title="Details">
                            <div
                              className="dispscreen__thdata_action"
                              onClick={() => handleRowExpand(item.id)}
                            >
                              <img src={DetailsIcon} />
                            </div>
                          </LightTooltip> */}
                        </div>
                      </div>
                    )}
                    {/* {cols[35].checked && (
                      <div
                        className="dispscreen__theader_data"
                        style={{
                          overflow: "hidden",
                          width: cols[35].largeWidth,
                        }}
                      >
                        <div
                          className="dispscreenthd__offerstatus_badge"
                          style={{
                            borderColor:
                              item.offer_status?.toLowerCase() === "accepted"
                                ? "#20CD04"
                                : item.offer_status?.toLowerCase() ===
                                  "declined"
                                ? "#D21C3D"
                                : "#D21C3D",
                            background:
                              item.offer_status?.toLowerCase() === "accepted"
                                ? "#E8FCE5"
                                : item.offer_status?.toLowerCase() ===
                                  "declined"
                                ? "#FFECEF"
                                : "#D21C3D",
                            color:
                              item.offer_status?.toLowerCase() === "cancelled"
                                ? "white"
                                : "black",
                          }}
                        >
                          <div className="dispscreenthd__offerstatus_badgeicon">
                            {item.offer_status?.toLowerCase() ===
                              "accepted" && <img src={CheckIcon} />}
                            {item.offer_status?.toLowerCase() ===
                              "declined" && <img src={FailedIcon} />}
                          </div>
                          <div className="dispscreenthd__offerstatus_badgetext">
                            {item.offer_status}
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>
                  <div
                    className={`dispscreen__row_details ${
                      expandedRow === item.id && "dispscreen__row_detailsactive"
                    }`}
                  >
                    <div className="dispscreen__rdetails_tableswrap">
                      <div className="dispscreen__rdetails_tablesrow">
                        <div className="dispscreen__rdetails_tablescol">
                          <div className="dispscreen__rdetails_tablescolheading">
                            Address information
                          </div>
                          <div className="dispscreen__rdetails_verticletable">
                            <div className="dispscreen__rdverttable_row">
                              <div className="dispscreen__rdverttable_heading">
                                pickup zone
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                {item.pickup_zone?.name}
                              </div>
                              <div className="dispscreen__rdverttable_heading">
                                delivery zone
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                {item.delivery_zone?.name}
                              </div>
                            </div>
                            <div className="dispscreen__rdverttable_row">
                              <div className="dispscreen__rdverttable_heading">
                                pickup country
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                <div className="dispscreen__theader_flagwrap">
                                  <Flag
                                    code={item.fcountrycode}
                                    fallback={<span>Unknown</span>}
                                    style={{
                                      height: "100%",
                                      overflow: "hidden",
                                      width: "100%",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="dispscreen__rdverttable_heading">
                                delivery country
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                <div className="dispscreen__theader_flagwrap">
                                  <Flag
                                    code={item.tcountrycode}
                                    fallback={<span>Unknown</span>}
                                    style={{
                                      height: "100%",
                                      overflow: "hidden",
                                      width: "100%",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="dispscreen__rdverttable_row">
                              <div className="dispscreen__rdverttable_heading">
                                pickup city
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                {item.fcity}
                              </div>
                              <div className="dispscreen__rdverttable_heading">
                                delivery city
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                {item.tcity}
                              </div>
                            </div>
                            <div className="dispscreen__rdverttable_row">
                              <div className="dispscreen__rdverttable_heading">
                                pickup address
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                {item.faddress}
                              </div>
                              <div className="dispscreen__rdverttable_heading">
                                delivery address
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                {item.taddress}
                              </div>
                            </div>
                            <div className="dispscreen__rdverttable_row">
                              <div className="dispscreen__rdverttable_heading">
                                pickup postal/zip
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                {item.fzip}
                              </div>
                              <div className="dispscreen__rdverttable_heading">
                                delivery postal/zip
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                {item.tzip}
                              </div>
                            </div>
                            <div className="dispscreen__rdverttable_row">
                              <div className="dispscreen__rdverttable_heading">
                                pickup full address
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                {item.faddress}
                              </div>
                              <div className="dispscreen__rdverttable_heading">
                                delivery full address
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                {item.taddress}
                              </div>
                            </div>
                            <div className="dispscreen__rdverttable_row">
                              <div className="dispscreen__rdverttable_heading">
                                pickup notes
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                {item.finstructions}
                              </div>
                              <div className="dispscreen__rdverttable_heading">
                                delivery notes
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                {item.tinstructions}
                              </div>
                            </div>
                          </div>
                          <div className="dispscreen__rdetails_tablescolheading">
                            DATE
                          </div>
                          <div className="dispscreen__rdetails_verticletable">
                            <div className="dispscreen__rdverttable_row">
                              <div className="dispscreen__rdverttable_heading">
                                Pickup Date
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                {item.PickupDate?.split(" ")[0]}
                              </div>
                              <div className="dispscreen__rdverttable_heading">
                                Pickup time
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                {item.deliveryopentime &&
                                  item.deliveryclosetime &&
                                  `${item.deliveryopentime?.slice(
                                    0,
                                    4
                                  )}-${item.deliveryclosetime?.slice(0, 4)}`}
                              </div>
                            </div>
                            <div className="dispscreen__rdverttable_row">
                              <div className="dispscreen__rdverttable_heading">
                                delivery Date
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                {item.deliverydate?.split(" ")[0]}
                              </div>
                              <div className="dispscreen__rdverttable_heading">
                                delivery time
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                {item.deliveryopentime &&
                                  item.deliveryclosetime &&
                                  `${item.deliveryopentime?.slice(
                                    0,
                                    4
                                  )}-${item.deliveryclosetime?.slice(0, 4)}`}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dispscreen__rdetails_tablescol">
                          <div className="dispscreen__rdetails_tablescolheading">
                            SHIPMENT INFORMATION
                          </div>
                          <div className="dispscreen__rdetails_verticletable">
                            <div className="dispscreen__rdverttable_row">
                              <div className="dispscreen__rdverttable_heading">
                                Carrier Tracking ID
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                {item.id ?? "-"}
                              </div>
                              <div className="dispscreen__rdverttable_heading">
                                Shipment status
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                <div
                                  className="dispscreenrdvt__shipment_status"
                                  style={{
                                    borderColor: [
                                      "returned",
                                      "cancelled",
                                    ].includes(
                                      item.shipment_status?.toLowerCase()
                                    )
                                      ? "#FFB9C5"
                                      : item.shipment_status?.toLowerCase() ===
                                        "pending"
                                      ? "#60A0FF"
                                      : "#71F46E",
                                    background: [
                                      "returned",
                                      "cancelled",
                                    ].includes(
                                      item.shipment_status?.toLowerCase()
                                    )
                                      ? "#FFECEF"
                                      : item.shipment_status?.toLowerCase() ===
                                        "pending"
                                      ? "#DBE9FF"
                                      : "#DEFFD9",
                                  }}
                                >
                                  {item.shipment_status}
                                </div>
                              </div>
                            </div>
                            <div className="dispscreen__rdverttable_row">
                              <div className="dispscreen__rdverttable_heading">
                                Created at
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                {item.created_at ?? "-"}
                              </div>
                            </div>
                          </div>
                          <div className="dispscreen__rdetails_tablescolheading">
                            tracking information
                          </div>
                          <div className="dispscreen__rdetails_verticletable">
                            <div className="dispscreen__rdverttable_row">
                              <div className="dispscreen__rdverttable_heading">
                                Bolt Tracking ID
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                {item.tracking_id ?? "-"}
                              </div>
                            </div>
                          </div>
                          <div className="dispscreen__rdetails_tablescolheading">
                            package overall measurements
                          </div>
                          <div className="dispscreen__rdetails_verticletable">
                            <div className="dispscreen__rdverttable_row">
                              <div className="dispscreen__rdverttable_heading">
                                weight
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                {weightUnit === "lb" &&
                                  `${
                                    item.unit.toLowerCase() === "imperial"
                                      ? item.total_weight
                                      : kgsToLbs(item.total_weight)
                                  } ${weightUnit}`}
                                {weightUnit === "kg" &&
                                  `${
                                    item.unit.toLowerCase() === "imperial"
                                      ? lbsToKgs(item.total_weight)
                                      : item.total_weight
                                  } ${weightUnit}`}
                              </div>
                              <div className="dispscreen__rdverttable_heading">
                                Volume
                              </div>
                              <div className="dispscreen__rdverttable_data">
                                {volumeUnit === "cbm" &&
                                  `${
                                    item.unit.toLowerCase() === "imperial"
                                      ? cbfToCbm(item.volume_in_cbm)
                                      : item.volume_in_cbm
                                  } ${volumeUnit}`}
                                {volumeUnit === "cbf" &&
                                  `${
                                    item.unit.toLowerCase() === "imperial"
                                      ? item.volume_in_cbm
                                      : cbmToCbf(item.volume_in_cbm)
                                  } ${volumeUnit}`}
                              </div>
                            </div>
                          </div>
                          <div className="dispscreen__rdetails_tablescolheading">
                            Shipment fees
                          </div>
                          <div className="dispscreen__rdetails_verticletable">
                            <div className="dispscreen__rdverttable_row">
                              <div className="dispscreen__rdverttable_heading dispscreen__rdverttable_smhcol">
                                signature
                              </div>
                              <div className="dispscreen__rdverttable_data dispscreen__rdverttable_smcol">
                                {item.signature_price ?? "-"}
                              </div>
                              <div className="dispscreen__rdverttable_heading dispscreen__rdverttable_smhcol">
                                fuel
                              </div>
                              <div className="dispscreen__rdverttable_data dispscreen__rdverttable_smcol">
                                {item.fuel_price ?? "-"}
                              </div>
                              <div className="dispscreen__rdverttable_heading dispscreen__rdverttable_smhcol">
                                COD
                              </div>
                              <div className="dispscreen__rdverttable_data dispscreen__rdverttable_smcol">
                                {item.cash_on_delivery_value ?? "-"}
                              </div>
                            </div>
                            <div className="dispscreen__rdverttable_row">
                              <div className="dispscreen__rdverttable_heading dispscreen__rdverttable_smhcol">
                                Tax
                              </div>
                              <div className="dispscreen__rdverttable_data dispscreen__rdverttable_smcol">
                                {item.tax ?? "-"}
                              </div>
                              <div className="dispscreen__rdverttable_heading dispscreen__rdverttable_smhcol">
                                Total
                              </div>
                              <div className="dispscreen__rdverttable_data dispscreen__rdverttable_smcol">
                                {item.Total ?? "-"}
                              </div>
                              <div className="dispscreen__rdverttable_heading dispscreen__rdverttable_smhcol">
                                Payment Status
                              </div>
                              <div className="dispscreen__rdverttable_data dispscreen__rdverttable_smcol">
                                <div
                                  className="dispscreenthd__rvtdata_badge"
                                  style={{
                                    borderColor:
                                      item.payment_status?.toLowerCase() ===
                                      "unpaid"
                                        ? "#FAE017"
                                        : item.payment_status?.toLowerCase() ===
                                            "paid" ||
                                          !item.payment_status?.toLowerCase()
                                        ? "#20CD04"
                                        : "#D21C3D",
                                    background:
                                      item.payment_status?.toLowerCase() ===
                                      "unpaid"
                                        ? "#FDFAE6"
                                        : item.payment_status?.toLowerCase() ===
                                            "paid" ||
                                          !item.payment_status?.toLowerCase()
                                        ? "#E8FCE5"
                                        : "#FFECEF",
                                  }}
                                >
                                  <div className="dispscreenthd__rvtdata_badgeicon">
                                    {item.payment_status?.toLowerCase() ===
                                      "unpaid" && <img src={LoadingIcon} />}
                                    {(item.payment_status?.toLowerCase() ===
                                      "paid" ||
                                      !item.payment_status?.toLowerCase()) && (
                                      <img src={CheckIcon} />
                                    )}
                                    {item.payment_status?.toLowerCase() ===
                                      "failed" && <img src={FailedIcon} />}
                                  </div>
                                  <div className="dispscreenthd__rvtdata_badgetext">
                                    {item.payment_status ?? "Paid"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="dispscreen__rdetails_tablesrow">
                        <div className="dispscreen__rdetails_tablescol">
                          <div className="dispscreen__rdetails_tablescolheading">
                            package information
                          </div>
                          <div className="dispscreen__rdetails_horizontaltable">
                            <div className="dispscreen__rdhtable_header">
                              <div
                                className="dispscreen__rdhtable_col"
                                style={{ overflow: "hidden", width: "120px" }}
                              >
                                #Packages
                              </div>
                              <div
                                className="dispscreen__rdhtable_col"
                                style={{ overflow: "hidden", width: "120px" }}
                              >
                                Weight
                              </div>
                              <div
                                className="dispscreen__rdhtable_col"
                                style={{ overflow: "hidden", width: "120px" }}
                              >
                                Volume
                              </div>
                              <div
                                className="dispscreen__rdhtable_col"
                                style={{ overflow: "hidden", width: "150px" }}
                              >
                                Size
                                <LightTooltip
                                  placement="top"
                                  arrow
                                  title="Length, Width, Height"
                                >
                                  <img
                                    src={InfoIcon}
                                    className="dispscreen__thdata_info"
                                  />
                                </LightTooltip>
                              </div>
                              <div
                                className="dispscreen__rdhtable_col"
                                style={{ overflow: "hidden", width: "120px" }}
                              >
                                Barcode
                              </div>
                              {/* <div
                                className="dispscreen__rdhtable_col"
                                style={{ overflow: "hidden", width: "120px" }}
                              >
                                Status
                              </div> */}
                              {/* <div
                                className="dispscreen__rdhtable_col"
                                style={{ overflow: "hidden", width: "150px" }}
                              >
                                status updated time
                              </div> */}
                            </div>
                            {item.shipment_details?.map((pkg, pkgIndex) => (
                              <div className="dispscreen__rdhtable_row">
                                <div
                                  className="dispscreen__rdhtable_col"
                                  style={{ overflow: "hidden", width: "120px" }}
                                >
                                  {pkg.description}
                                </div>
                                <div
                                  className="dispscreen__rdhtable_col"
                                  style={{ overflow: "hidden", width: "120px" }}
                                >
                                  {weightUnit === "lb" &&
                                    `${pkg.weight} ${weightUnit}`}
                                  {weightUnit === "kg" &&
                                    `${lbsToKgs(pkg.weight)} ${weightUnit}`}
                                </div>
                                <div
                                  className="dispscreen__rdhtable_col"
                                  style={{ overflow: "hidden", width: "120px" }}
                                >
                                  {volumeUnit === "cbm" &&
                                    `${
                                      item.unit.toLowerCase() === "imperial"
                                        ? cbfToCbm(item.volume_in_cbm)
                                        : item.volume_in_cbm
                                    } ${volumeUnit}`}
                                  {volumeUnit === "cbf" &&
                                    `${
                                      item.unit.toLowerCase() === "imperial"
                                        ? item.volume_in_cbm
                                        : cbmToCbf(item.volume_in_cbm)
                                    } ${volumeUnit}`}
                                </div>
                                <div
                                  className="dispscreen__rdhtable_col"
                                  style={{
                                    overflow: "hidden",
                                    width: "150px",
                                  }}
                                >
                                  L: {pkg.length}, W: {pkg.largeWidth}, H:{" "}
                                  {pkg.height} (
                                  <span style={{ textTransform: "lowercase" }}>
                                    {item.unit.toLowerCase() === "imperial"
                                      ? "m"
                                      : "In"}
                                  </span>
                                  )
                                </div>
                                <div
                                  className="dispscreen__rdhtable_col"
                                  style={{ overflow: "hidden", width: "120px" }}
                                >
                                  {item.parcel_id[pkgIndex]}
                                </div>
                                {/* <div
                                  className="dispscreen__rdhtable_col"
                                  style={{ overflow: "hidden", width: "120px" }}
                                >
                                  <div
                                    className="dispscreen__rdhtable_smptstatus"
                                    style={{
                                      borderColor: [
                                        "returned",
                                        "cancelled",
                                      ].includes(pkg.status?.toLowerCase())
                                        ? "#FFB9C5"
                                        : pkg.status?.toLowerCase() ===
                                          "pending"
                                        ? "#60A0FF"
                                        : "#71F46E",
                                      background: [
                                        "returned",
                                        "cancelled",
                                      ].includes(pkg.status?.toLowerCase())
                                        ? "#FFECEF"
                                        : pkg.status?.toLowerCase() ===
                                          "pending"
                                        ? "#DBE9FF"
                                        : "#DEFFD9",
                                    }}
                                  >
                                    {pkg.status}
                                  </div>
                                </div> */}
                                {/* <div
                                  className="dispscreen__rdhtable_col"
                                  style={{ overflow: "hidden", width: "150px" }}
                                >
                                  2022-12-06, 15:03
                                </div> */}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="dispscreen__rdetails_tablescol">
                          <div className="dispscreen__rdetails_tablescolheading">
                            Additional Services
                          </div>
                          <div className="dispscreen__rdetails_additionserviceslist">
                            {getAdditionalServices(item)?.map((service) => (
                              <div className="dispscreenrd__additionservices_badge bottom__margin_10">
                                <div className="dispscreen__additionservices_badgeicon">
                                  <img src={CheckIcon} />
                                </div>
                                <div className="dispscreen__additionservices_badgetext">
                                  {service}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {isEmpty && (
            <></>
            // <div className="dispscreen__table_placeholder">
            //   <img
            //     src={Empty}
            //     alt="Nothing to show"
            //     className="dispscreen__table_emptimg"
            //   />
            //   {/* <div className="dispscreen__table_empttext">No shipments to display</div> */}
            // </div>
          )}
          {loading && (
            <div className="driver__screen_tableloading">
              <CarrierInnerTableLoader />
            </div>
          )}
        </div>

        {!isEmpty && (
          <div className="pagination__wrap">
            <Stack spacing={2}>
              <Pagination
                count={pagination.last_page}
                page={pagination.current_page}
                onChange={handlePageChange}
              />
            </Stack>
          </div>
        )}
      </div>
    </div>
  );
};

export default TableSize3;
