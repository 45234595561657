import axios from "./axiosInstance"
export const createNewZone = async (token, data) => {
    let response = { data: null, error: null }
    try {
        const res = await axios.post("save-zone", data, {
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                timeout: 1000,
            }
        })
        response = { ...response, data: res.data }
    } catch (error) {
        response = { ...response, error: error }
    }
    return response
}

export const fetchAllZones = async (token) => {
    let response = { data: null, error: null }
    try {
        const res = await axios.get("get-zone?pagination=false", {
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                timeout: 1000,
            }
        })
        response = { ...response, data: res.data }
    } catch (error) {
        response = { ...response, error: error }
    }
    return response
}
export const deleteZoneById = async (token, id) => {
    let response = { data: null, error: null }
    try {
        const res = await axios.post("delete-zone", { id }, {
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                timeout: 1000,
            }
        })
        response = { ...response, data: res.data }
    } catch (error) {
        response = { ...response, error: error }
    }
    return response
}
export const updateZoneById = async (token, data, id) => {
    let response = { data: null, error: null }
    try {
        const res = await axios.post(`edit-zone/${id}`, data, {
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                timeout: 1000,
            }
        })
        response = { ...response, data: res.data }
    } catch (error) {
        response = { ...response, error: error }
    }
    return response
}
export const updateZoneArea = async (token, data) => {
    let response = { data: null, error: null }
    try {
        const res = await axios.post(`handle-zone`, data, {
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                timeout: 1000,
            }
        })
        response = { ...response, data: res.data }
    } catch (error) {
        response = { ...response, error: error }
    }
    return response
}

export const fetchAllQuestions = async (token) => {
    let response = { data: null, error: null }
    try {
        const res = await axios.get("get-all-questions", {
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                timeout: 1000,
            }
        })
        response = { ...response, data: res.data }
    } catch (error) {
        response = { ...response, error: error }
    }
    return response
}

export const tempSaveZoneSettings = async (token, data) => {
    let response = { data: null, error: null }
    try {
        const res = await axios.post("temp-save-setting", data, {
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                timeout: 1000,
            }
        })
        response = { ...response, data: res.data }
    } catch (error) {
        response = { ...response, error: error }
    }
    return response
}
export const fetchTempZoneSettings = async (token) => {
    let response = { data: null, error: null }
    try {
        const res = await axios.get("get-temp-setting", {
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                timeout: 1000,
            }
        })
        response = { ...response, data: res.data }
    } catch (error) {
        response = { ...response, error: error }
    }
    return response
}

export const saveZoneSettings = async (token, data) => {
    let response = { data: null, error: null }
    try {
        const res = await axios.post("zone-setting/save", data, {
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                timeout: 1000,
            }
        })
        response = { ...response, data: res.data }
    } catch (error) {
        response = { ...response, error: error }
    }
    return response
}
export const fetchPostalCodes = async (token, city) => {
    let response = { data: null, error: null }
    try {
        const res = await axios.get(`get-postal-codes-by-places-name?placeName=${city}&pagination=false`, {
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                timeout: 1000,
            }
        })
        response = { ...response, data: res.data }
    } catch (error) {
        response = { ...response, error: error }
    }
    return response
}