import React, { useState, useEffect } from "react";
import "./Packages.css";

// components imports
import Snackbar from "../../../../../Components/SnackBar/SnackBar";
import PackagesList from "./Components/Packages/PackagesList";
import BundlesList from "./Components/Bundles/BundlesList";

// apis imports
// library imports
import { initialPackagesTab } from "../../../../../data/permissionsArray";
import { useSelector } from "react-redux";
import TabsComponent from "../../../../../Components/Common/TabsComponent";

const Packages = () => {
  const user = useSelector((state) => state.user);

  const [tab, setTab] = useState(0);
  const [tabPermissions, setTabPermissions] = useState(initialPackagesTab);

  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  useEffect(() => {
    if (user.role != "Shipper") {
      let validPermissions = initialPackagesTab.filter((t) =>
        user?.mergedPermissions?.includes(t.name)
      );
      if (validPermissions?.length > 0) {
        setTab(validPermissions[0]?.value);
      } else {
        setTab("nothing");
      }
      setTabPermissions(validPermissions);
    } else {
      setTab(0);
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div className="packages_dispatcher__screen">
      <Snackbar Snack={snack} SetSnack={setSnack} />

      <TabsComponent
        tab={tab}
        handleTabChange={handleTabChange}
        items={tabPermissions}
      />

      {tab === 0 && <PackagesList snack={snack} setSnack={setSnack} />}
      {tab === 1 && <BundlesList snack={snack} setSnack={setSnack} />}
    </div>
  );
};

export default Packages;
