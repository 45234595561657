import React, { useEffect } from "react";
import "./Sections.css";
import { MdAddCircleOutline, MdErrorOutline } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";

import CustomDropdown from "../Components/CustomDropdown/CustomDropdown";

const initialProductIdentifier = { marketplace: "", sku: "", slug: "" };
const IdentifiersSection = ({
  formData,
  setFormData,
  errors,
  productIdentifiers,
  setProductIdentifiers,
}) => {
  const handleIdentifierFieldChange = (name, value, index) => {
    const data = productIdentifiers;
    setProductIdentifiers(
      data?.map((item, idx) => {
        if (index === idx) {
          return { ...item, [name]: value };
        } else {
          return item;
        }
      })
    );
  };
  const handleRemoveIdentifier = (index) => {
    setProductIdentifiers((identifiers) => {
      const updatedIdentifiers = [...identifiers];
      updatedIdentifiers.splice(index, 1);
      return updatedIdentifiers;
    });
  };
  const handleFieldChange = (name, e) => {
    setFormData({ ...formData, [name]: e.target.value });
  };
  useEffect(() => {
    setFormData({ ...formData, identifiers: productIdentifiers });
  }, [productIdentifiers]);
  return (
    <section className="addprod__formsection_wrap" id="addproduct_identifiers">
      <div className="addprod__formsection_heading">Identifiers</div>
      {productIdentifiers?.map((identifier, index) => (
        <div key={index} className="addprod__custominput_flexrow">
          <div className="addprod__custominput_flexcol">
            <div className="addprod__custominput_box">
              <label className="addprod__custominput_title">Marketplace</label>
              <CustomDropdown
                optionObjects={[
                  { id: 1, title: "Amazon" },
                  { id: 2, title: "Woocommerce" },
                  { id: 3, title: "Ebay" },
                  { id: 4, title: "Walmart" },
                  { id: 5, title: "Shopify" },
                  { id: 6, title: "Best Buy" },
                ]}
                onSelect={(option) => {
                  handleIdentifierFieldChange("marketplace", option, index);
                }}
                selectedOption={identifier.marketplace}
              />
              {Object.keys(errors)?.includes("marketplaces") &&
                errors?.marketplaces?.includes(index)(
                  <div className="addprod__custominputfield_error">
                    <MdErrorOutline className="addprod__custominputfield_erroricn" />
                    Marketplace is required
                  </div>
                )}
            </div>
          </div>
          <div className="addprod__custominput_flexcol">
            <div className="addprod__custominput_box">
              <label className="addprod__custominput_title">SKU</label>
              <input
                className="addprod__custominputfield"
                type="text"
                placeholder="Enter SKU"
                value={identifier.sku}
                onChange={(e) =>
                  handleIdentifierFieldChange("sku", e.target.value, index)
                }
              />
              {Object.keys(errors)?.includes("skus") &&
                errors?.skus?.includes(index)(
                  <div className="addprod__custominputfield_error">
                    <MdErrorOutline className="addprod__custominputfield_erroricn" />
                    SKU is required
                  </div>
                )}
            </div>
          </div>
          <div className="addprod__custominput_flexcol">
            <div className="addprod__custominput_box">
              <label className="addprod__custominput_title">Slug</label>
              <input
                className="addprod__custominputfield"
                type="text"
                placeholder="Enter Slug"
                value={identifier.slug}
                onChange={(e) =>
                  handleIdentifierFieldChange("slug", e.target.value, index)
                }
              />
              {Object.keys(errors)?.includes("slugs") &&
                errors?.slugs?.includes(index)(
                  <div className="addprod__custominputfield_error">
                    <MdErrorOutline className="addprod__custominputfield_erroricn" />
                    Slug is required
                  </div>
                )}
            </div>
          </div>
          {productIdentifiers?.length > 1 && (
            <FaRegTrashAlt
              size={13}
              className="addprod__idensec_delrowicn"
              onClick={(e) => {
                handleRemoveIdentifier(index);
              }}
            />
          )}
        </div>
      ))}

      <button
        className="addprod__formsection_addvarbtn"
        onClick={() => {
          let maxId = 0;

          for (const obj of productIdentifiers) {
            if (obj.id > maxId) {
              maxId = obj.id;
            }
          }
          setProductIdentifiers([
            ...productIdentifiers,
            {
              ...initialProductIdentifier,
            },
          ]);
        }}
      >
        <MdAddCircleOutline
          className="addprod__formsection_addvarbtnicn"
          size={14}
        />
        Add More
      </button>
    </section>
  );
};

export default IdentifiersSection;
