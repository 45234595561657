import React, { useState } from "react";
import "./packageslist.css";

// components imports
import PackagesTable from "../../../../../../../Components/BoltTable/BoltTable";
import Tooltip from "../../../../../../../Components/Tooltip/Tooltip";
import AddPackage from "../AddPackage/AddPackage";
import UpdatePackage from "../UpdatePackage/UpdatePackage";
import Confirm from "../../../../../../../Components/Popups/Confirm";

// apis imports
import {
  fetchAllPackages,
  deletePackage,
} from "../../../../../../../services/ShipperServices/Packages/packages.services";

// library imports
import { FaRegEdit } from "react-icons/fa";
import { FcPlus } from "react-icons/fc";
import { MdOutlineCancel } from "react-icons/md";
import noImage from "../../../../../../../assets/images/noImage.png";

const Packages = ({ snack, setSnack }) => {
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [addPackageModal, setAddPackageModal] = useState(false);
  const [deletePackageModal, setDeletePackageModal] = useState(false);
  const [updatePackageModal2, setUpdatePackageModal2] = useState(false);

  const [packages, setPackages] = useState([]);

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 1,
      label: "image",
      field_name: "Image",
      render: (row, rowIndex, handleFieldChange) => (
        <>
          <img
            src={row.file ?? noImage}
            onError={(e) => {
              e.target.src = noImage;
            }}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 2,
      label: "Package Name",
      field_name: "package_name",
      render: (row, rowIndex, handleFieldChange) => <>{row.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Description",
      field_name: "description",
      render: (row, rowIndex, handleFieldChange) => (
        <>{row.description ?? "-"}</>
      ),
      width: "200px",
    },
    {
      id: 4,
      label: "Length",
      field_name: "length",
      render: (row, rowIndex, handleFieldChange) => <>{row.length ?? "-"}</>,
      width: "200px",
    },
    {
      id: 5,
      label: "width",
      field_name: "Width",
      render: (row, rowIndex, handleFieldChange) => <>{row.width ?? "-"}</>,
      width: "200px",
    },
    {
      id: 6,
      label: "Height",
      field_name: "height",
      render: (row, rowIndex, handleFieldChange) => <>{row.height ?? "-"}</>,
      width: "200px",
    },
    {
      id: 7,
      label: "Weight",
      field_name: "weight",
      render: (row, rowIndex, handleFieldChange) => <>{row.weight ?? "-"}</>,
      width: "200px",
    },
    {
      id: 8,
      label: "Unit",
      field_name: "unit",
      render: (row, rowIndex, handleFieldChange) => <>{row.unit ?? "-"}</>,
      width: "200px",
    },
    {
      id: 9,
      label: "Action",
      field_name: "action",
      render: (row, rowIndex, handleFieldChange) => (
        <>
          <div className="table_action__wrap">
            <Tooltip title="Edit This Package">
              <div
                className="table_action__btn"
                onClick={() => setUpdatePackageModal2(row)}
              >
                <FaRegEdit size={10} />
                Edit
              </div>
            </Tooltip>
            <Tooltip title="Delete This Package">
              <div
                className="table_action__btn"
                onClick={() => setDeletePackageModal(row.id)}
              >
                <MdOutlineCancel size={10} />
                Delete
              </div>
            </Tooltip>
          </div>
        </>
      ),
      width: "200px",
    },
  ];

  const getAllPackages = async (page, perPage, filter) => {
    const response = await fetchAllPackages(page, perPage, filter);

    if (!response.error) {
      setPackages(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const deleteSinglePackage = async () => {
    setDeleteLoader(true);
    let id = deletePackageModal;
    const deleted = await deletePackage(deletePackageModal);
    if (deleted.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Couldn't delete package",
          severity: "error",
        };
      });
      setDeleteLoader(false);
    } else {
      setPackages((prevPackages) => {
        return {
          ...prevPackages,
          data: prevPackages?.data?.filter((pkg) => pkg.id !== id),
        };
      });
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Package deleted successfully",
          severity: "success",
        };
      });
      setDeleteLoader(false);
      setDeletePackageModal(false);
    }
  };

  return (
    <div>
      <div className="packages__header">
        <div>
          <span className="packages__hdrheading">Packages List</span>
        </div>

        <div className="packages__buttonsWrapper">
          <div onClick={() => setAddPackageModal(true)}>
            <div className="packages__hdraddprod">
              <FcPlus size={20} />
              <span>Add Package</span>
            </div>
          </div>
        </div>
      </div>

      <AddPackage
        open={addPackageModal}
        setOpen={setAddPackageModal}
        setSnack={setSnack}
        setRefresh={setRefresh}
      />
      <UpdatePackage
        open={updatePackageModal2}
        setOpen={setUpdatePackageModal2}
        previousData={updatePackageModal2}
        setSnack={setSnack}
        setPackages={setPackages}
      />
      <Confirm
        open={deletePackageModal}
        setOpen={setDeletePackageModal}
        handleConfirm={deleteSinglePackage}
        text="Do you want to delete this package ?"
        loader={deleteLoader}
      />

      <div>
        <PackagesTable
          message={`No Packages Available To Display!`}
          searchPlaceHolder={"Search Packages"}
          columns={columns}
          data={packages}
          getAllData={getAllPackages}
          refresh={refresh}
        />
      </div>
    </div>
  );
};

export default Packages;
