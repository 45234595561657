import React, { useState, useEffect } from "react";
import "./selectedproddetails.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import { BsTrash } from "react-icons/bs";
import Tooltips from "@mui/material/Tooltip";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import TextField from "@mui/material/TextField";
import noImage from "../../../../../assets/images/noImage.png";

export default function ProductList(props) {
  const {
    SelectedProducts,
    setSelectedProducts,
    deleteProduct,
    setAdditionalCost,
    additionalCost,
    totalWeight,
    subTotal,
    totalQuantity,
  } = props;

  const ImageUrl = (row) => {
    const url =
      row?.media.find((media) => media.is_primary === "true")?.file ||
      row?.media[0]?.file;
    return url || noImage;
  };

  const handleUnitPriceChange = (index, newSalePrice) => {
    const updatedProducts = [...SelectedProducts];
    updatedProducts[index].sale_price = newSalePrice;
    setSelectedProducts(updatedProducts);
  };

  const handlescrollpackage = (data) => {
    const productId = data.product_id;

    const existingProductIndex = SelectedProducts?.findIndex(
      (product) => product.product_id === productId
    );
    console.log("logger====", existingProductIndex, data);
    if (existingProductIndex !== -1) {
      const updatedProducts = [...SelectedProducts];
      updatedProducts[existingProductIndex].quantity =
        Number(updatedProducts[existingProductIndex].quantity) + 1;
      setSelectedProducts(updatedProducts);
    } else {
      const newData = { ...data, quantity: 1 };
      setSelectedProducts([...SelectedProducts, newData]);
    }
  };

  const handleAdd = (index) => {
    handlescrollpackage(SelectedProducts[index]);
  };

  const handleSubtract = (index) => {
    const updatedProducts = [...SelectedProducts];
    if (updatedProducts[index].quantity > 1) {
      updatedProducts[index].quantity -= 1;
    } else {
      updatedProducts.splice(index, 1);
    }
    setSelectedProducts(updatedProducts);
  };

  return (
    <div className="grn__products_section">
      <div className="grn__addressection_heading">Selected Products</div>
      <Row>
        <Col md={12} className="package__name_list_wrap GRN_selected-product">
          <Row className="mt-1 package__name_list_names">
            <Col md={4} className="text-center">
              <h6>My Products</h6>
            </Col>
            <Col md={2} className="text-center">
              <h6>Product Code</h6>
            </Col>
            <Col md={2} className="text-center">
              <h6>Name</h6>
            </Col>
            <Col md={2} className="text-center">
              <h6>Quantity</h6>
            </Col>
            <Col md={2} className="text-center">
              <h6>Action</h6>
            </Col>
          </Row>

          {SelectedProducts?.map((singlelist, index) => (
            <>
              <Row key={index} className="mt-2 package__name_list_names_row">
                <Col>
                  <Row className="mt-2">
                    <Col md={4} className="package__listing_field  text-center">
                      <Row>
                        <Col
                          md={4}
                          className="text-center my-auto field_col_sequence"
                        >
                          {"#"}
                          {index + 1}
                        </Col>
                        <Col
                          md={8}
                          className="package__listing_field text-center"
                        >
                          <img
                            src={ImageUrl(singlelist)}
                            onError={(e) => {
                              e.target.src = noImage;
                            }}
                            style={{
                              width: "60px",
                              height: "60px",
                              borderRadius: "10px",
                              objectFit: "contain",
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col md={2} className="package__listing_field text-center">
                      {singlelist?.code}
                    </Col>

                    <Col md={2} className="package__listing_field text-center">
                      {singlelist?.name}
                    </Col>

                    <Col
                      md={2}
                      className="package__listing_field text-center flex-col"
                    >
                      {singlelist?.product_warehouse?.map(
                        (warehouse, wIndex) => (
                          <div key={wIndex}>
                            <b>
                              {warehouse?.warehouse?.name}:{""}
                            </b>
                            {warehouse?.quantity}
                          </div>
                        )
                      )}
                    </Col>

                    <Col md={2} className="package__listing_field  text-center">
                      <Tooltips title="Delete Product" placement="top">
                        <a
                          type="button"
                          onClick={() => deleteProduct(index, singlelist)}
                        >
                          <BsTrash
                            size={35}
                            style={{
                              border: "2px solid #E2EDFB",
                              borderRadius: "25%",
                            }}
                            className="p-2 overlaybtn"
                          />
                        </a>
                      </Tooltips>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          ))}
        </Col>

        {/* <Col md={3}>
          <Row
            style={{
              padding: "10px",
            }}
          >
            <TextField
              label="Additional Cost"
              id="outlined-size-small"
              size="small"
              className=""
              name="additionalCost"
              value={additionalCost}
              onChange={(e) => setAdditionalCost(e.target.value)}
            />
          </Row>
          <div
            className="package__name_list_wrap_fullfilment_cost"
            style={{ border: "1px solid #d3d3d3", padding: "20px" }}
          >
            <div className="package__name_fullfilment_cost_row">
              <label className="tile__label_fullfilment_cost">
                Total Weight:
              </label>
              <span className="tile__value_fullfilment_cost">
                {totalWeight}kg{" "}
              </span>
            </div>
            <div className="package__name_fullfilment_cost_row">
              <label className="tile__label_fullfilment_cost">
                Total Quantity:{" "}
              </label>
              <span className="tile__value_fullfilment_cost">
                {totalQuantity}kg{" "}
              </span>
            </div>

            <div className="package__name_fullfilment_cost_row">
              <label className="tile__label_fullfilment_cost">
                Sub Total:{" "}
              </label>
              <span className="tile__value_fullfilment_cost">
                {Number(subTotal) + " " + "CAD"}
              </span>
            </div>
            <div className="package__name_fullfilment_cost_row">
              <label className="tile__label_fullfilment_cost">
                Additional Cost:{" "}
              </label>
              <span className="tile__value_fullfilment_cost">
                {additionalCost + " " + "CAD"}
              </span>
            </div>
            <div className="package__name_fullfilment_cost_row">
              <label className="tile__label_fullfilment_cost">Total :</label>
              <span className="tile__value_fullfilment_cost">
                {Number(additionalCost) + Number(subTotal) + " " + "CAD"}
              </span>
            </div>
          </div>
        </Col> */}
      </Row>
    </div>
  );
}
