import React, { useEffect, useState } from "react";
import "./CompanyDetails.css";

// components imports

// apis imports
import { countriesMap } from "../../../../../data/countries";

// library imports

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import PlaceholderImage from "../../../../../assets/images/placeholder-image.png";
import IconButton from "@mui/material/IconButton";
import { FaRegTimesCircle } from "react-icons/fa";
import { MdTaskAlt, MdHighlightOff } from "react-icons/md";

export default function CompanyDetails({ open, setOpen, companyData }) {
  const [data, setData] = useState({});

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (companyData) {
      setData(companyData);
    }
  }, [companyData]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="admncompany__details_modal">
          <div className="admncompany__details_closewrap" onClick={handleClose}>
            <IconButton>
              <FaRegTimesCircle size={25} color="lightgrey" />
            </IconButton>
          </div>
          <div className="admncompany__details_modalheader">
            <div className="admncompany__details_modalheaderlft">
              <h1 className="admncompany__details_modalhdrrttitle">
                {data?.name ?? "-"}
              </h1>
            </div>
            <div className="admncompany__details_modalheaderrt">
              <div className="admncompany__details_modalheaderimg">
                <img
                  src={data?.logo_url ?? PlaceholderImage}
                  onError={(e) => {
                    e.target.src = PlaceholderImage;
                  }}
                />
              </div>
            </div>
          </div>

          <div className="admncompany__details_modalcontent">
            <div className="admncompany__details_modalcontentrow">
              <div className="admncompany__details_modalcontentlft">
                <h2>About</h2>
                <p>
                  <img
                    src={
                      (data.rating_setting && data.rating_setting[1]?.image) ??
                      PlaceholderImage
                    }
                    onError={(e) => {
                      e.target.src = PlaceholderImage;
                    }}
                    alt="your image description"
                    className="adcmdetails__modal_detimg"
                  />
                  {data?.rating_setting
                    ? data?.rating_setting[1]?.description ??
                      "No Content Found!"
                    : "No Content Found!"}
                </p>
              </div>
              <div className="admncompany__details_modalcontentrt">
                <h2>Countries</h2>
                <div className="adcmdetails__modal_contriestable">
                  <div className="adcmdetails__modal_ctheading">Name</div>
                  <div className="adcmdetails__modal_ctheading content_center">
                    Pickup
                  </div>
                  <div className="adcmdetails__modal_ctheading content_center">
                    Delivery
                  </div>
                  {data.rating_country &&
                    data.rating_country?.map((country) => (
                      <>
                        <div className="adcmdetails__modal_ctdata">
                          {countriesMap[country.name]?.name}
                        </div>
                        <div className="adcmdetails__modal_ctdata content_center">
                          {country?.services?.includes("pickup") ? (
                            <MdTaskAlt size={23} style={{ color: "green" }} />
                          ) : (
                            <MdHighlightOff
                              size={23}
                              style={{ color: "red" }}
                            />
                          )}
                        </div>
                        <div className="adcmdetails__modal_ctdata content_center">
                          {country?.services?.includes("delivery") ? (
                            <MdTaskAlt size={23} style={{ color: "green" }} />
                          ) : (
                            <MdHighlightOff
                              size={23}
                              style={{ color: "red" }}
                            />
                          )}
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </div>
            <h2>History</h2>
            <p>
              <img
                src={
                  (data.rating_setting && data.rating_setting[0]?.image) ??
                  PlaceholderImage
                }
                onError={(e) => {
                  e.target.src = PlaceholderImage;
                }}
                alt="your image description"
                className="adcmdetails__modal_detimg"
              />
              {data?.rating_setting
                ? data?.rating_setting[0]?.description ?? "No Content Found!"
                : "No Content Found!"}
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
}
