import React from "react";

export default function SearchField({ label, name, value, onChange, Handler }) {
  return (
    <div className="SearchFields__main_wrap">
      <div className="SearchFields__main_wrap">
        <label className="SearchFields__input__box">{label}</label>
        <input
          type="text"
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          className="SearchFields__input_field"
        />
      </div>
    </div>
  );
}
