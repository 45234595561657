import React, { useEffect, useState } from "react";
import "./settings.css";

// components imports
import Loader from "../../../../../../../Components/CircularLoader/CircularLoader";
import SkeletonLoading from "../../../../../../../Components/SkeletonLoading/SkeletonLoading";
// apis imports
import {
  fetchBankDetails,
  createBankDetails,
} from "../../../../../../../services/ShipperServices/Payments/payments.services";
// library imports
import BankTransfer from "../../../../../../../assets/images/bank-transfer.png";
import ETransfer from "../../../../../../../assets/images/e-transfer.png";
import {
  FcNeutralTrading,
  FcReading,
  FcOrganization,
  FcBusinessContact,
  FcDepartment,
  FcFeedback,
  FcUnlock,
} from "react-icons/fc";
import { useSelector } from "react-redux";

const Settings = ({ setSnack }) => {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [skeletonLoading, setSkeletonLoading] = useState(false);

  const [preservedData, setPreservedData] = useState(null);
  const [changed, setchanged] = useState(false);
  const [data, setData] = useState(null);

  let array = [
    {
      headerTitle: "Bank Transfer",
      headerImg: BankTransfer,
      type: "bank_transfer",
    },
    {
      headerTitle: "E Transfer",
      headerImg: ETransfer,
      type: "e_transfer",
    },
  ];

  useEffect(() => {
    const getBankDetails = async () => {
      try {
        setSkeletonLoading(true);
        const response = await fetchBankDetails(user.warehouse_id);
        if (response.error) {
          setSkeletonLoading(false);
          console.log(response.error);
        } else {
          setData(response.data.result);
          setPreservedData(response.data.result);
          setSkeletonLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getBankDetails();
  }, []);

  useEffect(() => {
    if (
      data?.transit !== preservedData?.transit ||
      data?.institution !== preservedData?.institution ||
      data?.bank_name !== preservedData?.bank_name ||
      data?.code !== preservedData?.code ||
      data?.account_title !== preservedData?.account_title ||
      data?.mail !== preservedData?.mail ||
      data?.account_no !== preservedData?.account_no
    ) {
      setchanged(true);
    } else {
      setchanged(false);
    }
  }, [data]);

  const handelChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    setLoading(true);
    const response = await createBankDetails(data, user.warehouse_id);
    if (!response.error) {
      setData(response.data.result);
      setPreservedData(response.data.result);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Bank Details Updated Successfully",
          severity: "success",
        };
      });
      setLoading(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
      setLoading(false);
    }
  };
  return (
    <>
      <div className="settings__3pl_mainwrapper">
        <div className="settings__3pl_boxwrapper">
          {array.map((item, index) => {
            return (
              <div className="settings__3pl_box" key={index}>
                <div className="settings__3pl_header">
                  <div className="settings__3pl_methodicon">
                    <img src={item.headerImg} alt="" />
                  </div>
                  <div className="settings__3pl_heading">
                    {item.headerTitle}
                  </div>
                </div>

                <div className="settings__3pl_boxcontent">
                  {item.type === "bank_transfer" && (
                    <div
                      className={`settings__3pl_boxcontentbox settings__3pl_boxcontentactivebox`}
                    >
                      <div className="settings__3pl_boxtxtrow">
                        <div className="settings__3pl_modaltxtlft">
                          <FcReading size={25} />
                          Account Title
                        </div>
                        <div className="settings__3pl_modaltxtrt">
                          {skeletonLoading ? (
                            <SkeletonLoading height={50} />
                          ) : (
                            <input
                              className="settings__3pl_modalinput"
                              placeholder="Account Title"
                              name="account_title"
                              value={data?.account_title}
                              onChange={handelChange}
                              type="text"
                            />
                          )}
                        </div>
                      </div>

                      <div className="settings__3pl_boxtxtrow">
                        <div className="settings__3pl_modaltxtlft">
                          <FcBusinessContact size={25} />
                          Account
                        </div>
                        <div className="settings__3pl_modaltxtrt">
                          {skeletonLoading ? (
                            <SkeletonLoading height={50} />
                          ) : (
                            <>
                              <input
                                className="settings__3pl_modalinput"
                                placeholder="Account No"
                                name="account_no"
                                value={data?.account_no}
                                onChange={handelChange}
                                type="text"
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <div className="settings__3pl_boxtxtrow">
                        <div className="settings__3pl_modaltxtlft">
                          <FcNeutralTrading size={25} />
                          Transit
                        </div>
                        <div className="settings__3pl_modaltxtrt">
                          {skeletonLoading ? (
                            <SkeletonLoading height={50} />
                          ) : (
                            <>
                              <input
                                className="settings__3pl_modalinput"
                                placeholder="Transit"
                                name="transit"
                                value={data?.transit}
                                onChange={handelChange}
                                type="text"
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <div className="settings__3pl_boxtxtrow">
                        <div className="settings__3pl_modaltxtlft">
                          <FcOrganization size={25} />
                          Institution
                        </div>
                        <div className="settings__3pl_modaltxtrt">
                          {skeletonLoading ? (
                            <SkeletonLoading height={50} />
                          ) : (
                            <>
                              <input
                                className="settings__3pl_modalinput"
                                placeholder="Institution"
                                name="institution"
                                value={data?.institution}
                                onChange={handelChange}
                                type="text"
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <div className="settings__3pl_boxtxtrow">
                        <div className="settings__3pl_modaltxtlft">
                          <FcDepartment size={25} /> Bank Name
                        </div>
                        <div className="settings__3pl_modaltxtrt">
                          {skeletonLoading ? (
                            <SkeletonLoading height={50} />
                          ) : (
                            <>
                              <input
                                className="settings__3pl_modalinput"
                                placeholder="Bank Name"
                                name="bank_name"
                                value={data?.bank_name}
                                onChange={handelChange}
                                type="text"
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {item.type === "e_transfer" && (
                    <div
                      className={`settings__3pl_boxcontentbox settings__3pl_boxcontentactivebox`}
                    >
                      <div className="settings__3pl_boxtxtrow">
                        <div className="settings__3pl_modaltxtlft">
                          <FcFeedback size={25} />
                          Id
                        </div>
                        <div className="settings__3pl_modaltxtrt">
                          {skeletonLoading ? (
                            <SkeletonLoading height={50} />
                          ) : (
                            <>
                              <input
                                className="settings__3pl_modalinput"
                                placeholder="Id"
                                name="mail"
                                value={data?.mail}
                                onChange={handelChange}
                                type="text"
                              />
                            </>
                          )}
                        </div>
                      </div>
                      <br />
                      <div className="settings__3pl_boxtxtrow">
                        <div className="settings__3pl_modaltxtlft">
                          <FcUnlock size={25} />
                          Code
                        </div>
                        <div className="settings__3pl_modaltxtrt">
                          {skeletonLoading ? (
                            <SkeletonLoading height={50} />
                          ) : (
                            <>
                              <input
                                className="settings__3pl_modalinput"
                                placeholder="Code"
                                name="code"
                                value={data?.code}
                                onChange={handelChange}
                                type="text"
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {changed && (
          <div className="settings__3pl_boxfooter">
            <div className="settings__3pl_boxcncl">Cancel</div>
            <div className={`settings__3pl_boxsave`} onClick={handleSave}>
              {loading ? <Loader /> : "Save"}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Settings;
