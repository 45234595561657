import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import LocationDropdown from "./LocationDropdown";
import UpdateLocationModal from "./UpdateLocationModal";
import BasicInfoModal from "./BasicInfoModal";
import { truncateString } from "../../../../helpers/helpers";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import MapSelector from "./MapSelector";
import AddGraphModal from "./AddGraphModal";
import RearrangeModal from "./RearrangeModal";
import { assignLocationToMap } from "../../../../services/WarehouseServices/storageMap.services";
const colorContrastMap = {
  "#F0F8FF": "#003366",
  "#FAEBD7": "#8B4513",
  "#7FFFD4": "#006400",
  "#FFE4C4": "#8B4513",
  "#8A2BE2": "#FFFFFF",
  "#5F9EA0": "#FFFFFF",
  "#DEB887": "#8B4513",
  "#7FFF00": "#006400",
  "#D2691E": "#FFFFFF",
  "#6495ED": "#003366",
  "#FF7F50": "#8B0000",
  "#DC143C": "#FFFFFF",
  "#00CED1": "#006400",
  "#9400D3": "#FFFFFF",
  "#FFD700": "#8B4513",
  "#4B0082": "#FFFFFF",
  "#FF69B4": "#8B0000",
  "#F08080": "#8B0000",
  "#90EE90": "#006400",
  "#20B2AA": "#FFFFFF",
  "#87CEFA": "#003366",
  "#778899": "#FFFFFF",
  "#FFB6C1": "#8B0000",
  "#32CD32": "#003366",
};

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const StorageGraph = ({
  name,
  rows,
  column,
  snack,
  setSnack,
  id,
  assignedCells,
  setAssignedCells,
  handleSelectedMap,
  getExistingMap,
  setBasicInfo,
  storageMaps,
  setStorageMaps,
  selectedMap,
  setSelectedMap,
}) => {
  const [currentCell, setCurrentCell] = useState();
  const [cellData, setCellData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [locationValue, setLocationValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [updateLocationModal, setUpdateLocationModal] = useState();
  const [basicInfoModal, setBasicInfoModal] = useState();
  const [selectedLevel, setSelectedLevel] = useState();
  const [graphModal, setGraphModal] = useState();
  const [reArrangeModal, setReArrangeModal] = useState();

  function cbmStatus(length, width, height, unit, productCbm) {
    length = parseFloat(length);
    width = parseFloat(width);
    height = parseFloat(height);
    productCbm = parseFloat(productCbm);

    if (isNaN(length) || isNaN(width) || isNaN(height) || isNaN(productCbm)) {
      return "green";
    }

    let boxCbm;

    if (unit === "imperial") {
      boxCbm = length * 0.0254 * (width * 0.0254) * (height * 0.0254);
    } else if (unit === "metric") {
      boxCbm = (length / 100) * (width / 100) * (height / 100);
    } else {
      return "green";
    }

    if (isNaN(boxCbm)) {
      return "green";
    }

    const filledPercentage = (productCbm / boxCbm) * 100;

    if (filledPercentage >= 75) {
      return "red";
    } else if (filledPercentage >= 50) {
      return "orange";
    } else if (filledPercentage > 25) {
      return "yellow";
    } else if (filledPercentage <= 25) {
      return "green";
    } else {
      return "green";
    }
  }
  function filledLocation(length, width, height, unit, productCbm) {
    length = parseFloat(length);
    width = parseFloat(width);
    height = parseFloat(height);
    productCbm = parseFloat(productCbm);

    if (isNaN(length) || isNaN(width) || isNaN(height) || isNaN(productCbm)) {
      return 0;
    }

    let boxCbm;

    if (unit === "imperial") {
      boxCbm = length * 0.0254 * (width * 0.0254) * (height * 0.0254);
    } else if (unit === "metric") {
      boxCbm = (length / 100) * (width / 100) * (height / 100);
    } else {
      return 0;
    }

    if (isNaN(boxCbm)) {
      return 0;
    }

    const filledPercentage = (productCbm / boxCbm) * 100;
    return parseInt(filledPercentage);
  }

  const handleCellClick = (event, rowIndex, colIndex) => {
    const cell = `${rowIndex + 1}${getColumnName(colIndex)}`;
    setCurrentCell(cell);
    setAnchorEl(anchorEl === event.currentTarget ? null : event.currentTarget);
    assignedCells[cell]
      ? setLocationValue(assignedCells[cell]?.pallet_locations ?? [])
      : setLocationValue([]);
  };
  const popper = Boolean(anchorEl);
  const cellId = popper ? "simple-popper" : undefined;

  const getColumnName = (index) => {
    let columnName = "";
    while (index >= 0) {
      columnName = String.fromCharCode((index % 26) + 65) + columnName;
      index = Math.floor(index / 26) - 1;
    }
    return columnName;
  };
  const reArrageLocations = async (updatedLocations) => {
    const locationIds = updatedLocations.map((loc, index) => {
      return {
        id: loc?.id,
        order_by:
          loc?.order_by && loc?.order_by < index + 1
            ? loc?.order_by
            : index + 1,
      };
    });
    const response = await assignLocationToMap(id, {
      cell: currentCell,
      locations: locationIds,
    });
    if (response?.data?.success) {
      setSnack((snack) => ({
        ...snack,
        open: true,
        text: "Locations Updated successfully",
        severity: "success",
      }));
    }
    const temp = { ...assignedCells };
    getExistingMap(true);
    setAssignedCells(temp);
  };

  useEffect(() => {
    const initializeData = async () => {
      // Simulate an asynchronous data fetch
      // await new Promise((resolve) => setTimeout(resolve, 1000));
      const initialData = Array.from({ length: rows }, () =>
        Array.from({ length: column }, () => "")
      );
      setCellData(initialData);
      setLoading(false);
    };
    initializeData();
  }, [rows, column, setLoading]);
  useEffect(() => {
    setCurrentCell();
  }, [selectedMap]);
  return (
    <div>
      <UpdateLocationModal
        setSnack={setSnack}
        open={updateLocationModal}
        setOpen={setUpdateLocationModal}
        previousData={{
          name: updateLocationModal?.name,
          unit: updateLocationModal?.unit ?? "imperial",
          length: updateLocationModal?.length,
          width: updateLocationModal?.width,
          height: updateLocationModal?.height,
          weight_capacity: updateLocationModal?.weight_capacity,
          cost: updateLocationModal?.cost,
          color: updateLocationModal?.color ?? "white",
          is_use_able: ["true", true, "1"].includes(
            updateLocationModal?.is_use_able
          ),
        }}
        assignedCells={assignedCells}
        setAssignedCells={setAssignedCells}
        currentCell={currentCell}
      />
      <RearrangeModal
        open={reArrangeModal}
        setOpen={setReArrangeModal}
        items={reArrangeModal}
        onRearrange={(sortedArray) => reArrageLocations(sortedArray)}
      />
      <BasicInfoModal
        setSnack={setSnack}
        open={basicInfoModal}
        setOpen={setBasicInfoModal}
        previousData={{
          name: name,
          rows: rows,
          column: column,
        }}
        mapId={id}
        setBasicInfo={setBasicInfo}
      />
      <AddGraphModal
        open={graphModal}
        setOpen={setGraphModal}
        setStorageMaps={setStorageMaps}
        setSnack={setSnack}
      />
      <div className="flex justify-center items-center my-3">
        <MapSelector
          maps={storageMaps}
          selectedMap={selectedMap}
          setSelectedMap={setSelectedMap}
          handleSelectedMap={handleSelectedMap}
        />
        <button
          onClick={() => setGraphModal(true)}
          class="bg-blue-600 rounded-md text-white text-xs text-center self-center px-3 py-[11px] my-2 mx-2"
        >
          <AddIcon sx={{ fontSize: "16px" }} /> Add New
        </button>
      </div>
      {loading ? (
        <div className="flex">
          <Stack spacing={1}>
            <Skeleton variant="rectangular" width="90vw" height="10vh" />
            <Skeleton variant="rectangular" width="90vw" height="5vh" />
            <Skeleton variant="rectangular" width="90vw" height="5vh" />
            <Skeleton variant="rectangular" width="90vw" height="5vh" />
            <Skeleton variant="rectangular" width="90vw" height="5vh" />
            <Skeleton variant="rectangular" width="90vw" height="5vh" />
            <Skeleton variant="rectangular" width="90vw" height="5vh" />
            <Skeleton variant="rectangular" width="90vw" height="5vh" />
            <Skeleton variant="rectangular" width="90vw" height="5vh" />
            <Skeleton variant="rectangular" width="90vw" height="5vh" />
          </Stack>
        </div>
      ) : (
        <div className="flex">
          <div className="overflow-auto max-w-[70vw] max-h-[70vh]">
            <div className="inline-block whitespace-nowrap">
              <div className="flex  sticky z-[100] top-0">
                <div className="w-10 h-10 border bg-gray-100"></div>
                {Array.from({ length: column }).map((_, colIndex) => (
                  <div
                    key={colIndex}
                    className="w-20 h-10 border bg-gray-100 flex items-center justify-center"
                  >
                    {getColumnName(colIndex)}
                  </div>
                ))}
              </div>
              {cellData.map((row, rowIndex) => {
                return (
                  <div key={rowIndex} className="flex min-h-[60px]">
                    <div className="w-10 h-[auto] z-[100] sticky left-0 border bg-gray-100 flex items-center justify-center">
                      {rowIndex + 1}
                    </div>
                    {row.map((cell, colIndex) => {
                      const assignedCell =
                        typeof assignedCells === "object" &&
                        assignedCells[
                          `${rowIndex + 1}${getColumnName(colIndex)}`
                        ]
                          ? assignedCells[
                              `${rowIndex + 1}${getColumnName(colIndex)}`
                            ]
                          : false;
                      return (
                        <div
                          key={colIndex}
                          className="w-20 h-[auto] min-h-16 border flex flex-col items-center justify-start"
                          onClick={(event) =>
                            handleCellClick(event, rowIndex, colIndex)
                          }
                          aria-describedby={cellId}
                        >
                          {assignedCell
                            ? assignedCell?.pallet_locations?.map(
                                (location) => {
                                  const color = cbmStatus(
                                    location?.length,
                                    location?.width,
                                    location?.height,
                                    location?.unit,
                                    location?.total_pallet_cbm
                                  );
                                  const percentage = filledLocation(
                                    location?.length,
                                    location?.width,
                                    location?.height,
                                    location?.unit,
                                    location?.total_pallet_cbm
                                  );
                                  return (
                                    <LightTooltip
                                      placement="top"
                                      arrow
                                      title={
                                        <>
                                          {["true", "1", true].includes(
                                            location?.is_use_able
                                          ) ? (
                                            <span>
                                              {rowIndex + 1}
                                              {getColumnName(colIndex)}
                                              <span>:</span> {location?.name}{" "}
                                              <span style={{ color }}>
                                                ({percentage}%)
                                              </span>
                                            </span>
                                          ) : (
                                            <span>Disabled</span>
                                          )}
                                        </>
                                      }
                                    >
                                      <div
                                        className={`w-20 overflow-hidden px-2 py-1 text-xs min-w-full cursor-pointer relative`}
                                        onClick={() =>
                                          setSelectedLevel(location?.id)
                                        }
                                        style={{
                                          backgroundColor: `${
                                            location?.color ?? "white"
                                          }`,
                                          opacity: `${
                                            !["true", "1", true].includes(
                                              location?.is_use_able
                                            )
                                              ? "0.6"
                                              : "1"
                                          }`,
                                          color: `${
                                            location?.color
                                              ? colorContrastMap[
                                                  location?.color
                                                ]
                                              : "black"
                                          }`,
                                          borderBottom: `0.5px solid lightgrey`,
                                        }}
                                      >
                                        {truncateString(location?.name, 7) ??
                                          ""}
                                        <div
                                          className={`w-[5px] h-[5px] rounded absolute right-1 top-1`}
                                          style={{ backgroundColor: color }}
                                        ></div>
                                        <div
                                          className={`absolute right-1 top-2  text-[8px]`}
                                          style={{ color: color }}
                                        >
                                          {percentage ? `${percentage}%` : ""}
                                        </div>
                                      </div>
                                    </LightTooltip>
                                  );
                                }
                              )
                            : null}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-[20vw] max-w-[300px] min-w-[250px] bg-[#F4F4F4] p-4 h-[70vh] overflow-auto">
            <div className="text-center text-lg font-semibold mb-3">
              Basic Info
            </div>
            <div className="text-[12px] mb-1 w-full relative">
              <span className="font-semibold">name:</span> {name ?? "N/A"}
              <div
                onClick={() => setBasicInfoModal(true)}
                className="absolute right-0 top-0 text-[12px] w-[40px] h-[22px] flex items-center justify-center rounded-sm bg-blue-700 text-light cursor-pointer"
              >
                Edit
              </div>
            </div>
            <div className="text-[12px] mb-1">
              <span className="font-semibold">rows:</span> {rows ?? "N/A"}
            </div>
            <div className="text-[12px] mb-1">
              <span className="font-semibold">columns:</span> {column ?? "N/A"}
            </div>
            <div class="flex items-center w-full max-w-[250px] h-[30px] text-xs">
              <div class="flex items-center w-full space-x-2">
                <div class="flex items-center space-x-1">
                  <div class="w-2 h-2 rounded-full bg-green-500"></div>
                  <span class="text-[10px]">0-25%</span>
                </div>
                <div class="flex items-center space-x-1">
                  <div class="w-2 h-2 rounded-full bg-yellow-500"></div>
                  <span class="text-[10px]">26-50%</span>
                </div>
                <div class="flex items-center space-x-1">
                  <div class="w-2 h-2 rounded-full bg-orange-500"></div>
                  <span class="text-[10px]">51-75%</span>
                </div>
                <div class="flex items-center space-x-1">
                  <div class="w-2 h-2 rounded-full bg-red-500"></div>
                  <span class="text-[10px]">76-100%</span>
                </div>
              </div>
            </div>

            <div className="text-center text-lg font-semibold mb-3">
              Details
            </div>

            {currentCell && (
              <div className="flex justify-between">
                <div className="text-[12px]">
                  <span className="font-semibold">Current Cell:</span>&nbsp;
                  <span>{currentCell}</span>
                </div>
                {assignedCells[currentCell]?.pallet_locations?.length > 1 && (
                  <div
                    className="text-[12px] cursor-pointer py-1 px-3 rounded-sm bg-blue-700 text-light"
                    onClick={() => {
                      setReArrangeModal(
                        assignedCells[currentCell]?.pallet_locations
                      );
                    }}
                  >
                    Reorder
                  </div>
                )}
              </div>
            )}
            {currentCell && (
              <div className="my-3">
                <LocationDropdown
                  value={locationValue}
                  setValue={setLocationValue}
                  snack={snack}
                  setSnack={setSnack}
                  mapId={id}
                  currentCell={currentCell}
                  assignedCells={assignedCells}
                  setAssignedCells={setAssignedCells}
                  getExistingMap={getExistingMap}
                />
              </div>
            )}
            {typeof assignedCells === "object" &&
              assignedCells[currentCell] &&
              assignedCells[currentCell]?.pallet_locations.map(
                (location, index) => {
                  const color = cbmStatus(
                    location?.length,
                    location?.width,
                    location?.height,
                    location?.unit,
                    location?.total_pallet_cbm
                  );
                  const percentage = filledLocation(
                    location?.length,
                    location?.width,
                    location?.height,
                    location?.unit,
                    location?.total_pallet_cbm
                  );
                  return (
                    <div
                      className={`${
                        selectedLevel === location?.id ? "bg-[#FCFCFC]" : ""
                      } p-2 rounded-sm`}
                    >
                      <div className="my-2 font-semibold flex justify-between items-start">
                        <div>Level {index + 1}</div>
                        <div
                          onClick={() => setUpdateLocationModal(location)}
                          className="text-[12px] w-[40px] h-[22px] flex items-center justify-center rounded-sm bg-blue-700 text-light cursor-pointer"
                        >
                          Edit
                        </div>
                      </div>
                      <div className="text-[12px] mb-1">
                        <span className="font-semibold">name:</span>{" "}
                        {location?.name ?? "N/A"}
                      </div>
                      <div className="text-[12px] mb-1">
                        <span className="font-semibold">space used:</span>{" "}
                        <span style={{ color }}>
                          {percentage ? `${percentage}%` : "0%"}
                        </span>
                      </div>
                      <div className="text-[12px] mb-1">
                        <span className="font-semibold">unit:</span>{" "}
                        {location?.unit ?? "N/A"}
                      </div>
                      <div className="text-[12px] mb-1">
                        <span className="font-semibold">cost:</span>{" "}
                        {`${location?.cost ?? "0"}$`}
                      </div>
                      <div className="text-[12px] mb-1">
                        <span className="font-semibold">color:</span>{" "}
                        {location?.color ?? "N/A"}
                      </div>
                      <div className="text-[12px] mb-1">
                        <span className="font-semibold">length:</span>{" "}
                        {location?.length ?? "N/A"}
                      </div>
                      <div className="text-[12px] mb-1">
                        <span className="font-semibold">width:</span>{" "}
                        {location?.width ?? "N/A"}
                      </div>
                      <div className="text-[12px] mb-1">
                        <span className="font-semibold">height:</span>{" "}
                        {location?.height ?? "N/A"}
                      </div>
                      <div className="text-[12px] mb-1">
                        <span className="font-semibold">weight capacity:</span>{" "}
                        {location?.weight_capacity ?? "N/A"}
                      </div>
                    </div>
                  );
                }
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default StorageGraph;
