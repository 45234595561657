import React, { useEffect, useState } from 'react';
import StorageMapForm from './Components/Form';
import StorageGraph from './Components/StorageGraph';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { getStorageMap, storeStorageMap, updateStorageMap } from '../../../services/WarehouseServices/storageMap.services';
import Snackbar from "../../../Components/SnackBar/SnackBar";

const StorageMap = () => {
    const [step, setStep] = useState();
    const [assignedCells, setAssignedCells] = useState([]);
    const [storageMaps, setStorageMaps] = useState([]);
    const [selectedMap, setSelectedMap] = useState();
    const [graphData, setGraphData] = useState({
        name: "",
        rows: 50,
        column: 50,
    });
    const [loading, setLoading] = useState(false);
    const [snack, setSnack] = useState({
        open: false,
        vertical: "top",
        horizontal: "right",
        text: "",
        severity: "",
    });

    const handleSubmit = async () => {
        setLoading(true);
        const response = await storeStorageMap(graphData);
        setLoading(false)
        if (response?.data?.success) {
            setGraphData(response?.data?.result)
            setStep(2);
        }

    };
    const getExistingMap = async (preventLoading) => {
        !preventLoading && setLoading(true);
        const response = await getStorageMap();
        setLoading(false)
        if (response?.data?.success) {
            if (response?.data?.result?.data?.length) {
                setStorageMaps(response.data?.result?.data)
                if (!selectedMap) {
                    setSelectedMap(response.data?.result?.data[0])
                }
                const { storage_map_cells, ...data } = selectedMap ? response.data?.result?.data?.find(map => map?.id === selectedMap?.id) : response.data?.result?.data[0];
                const assignedCellsObject = storage_map_cells?.reduce((acc, item) => {
                    acc[item.cell] = item;
                    return acc;
                }, {});
                setAssignedCells(assignedCellsObject ?? {})
                setGraphData(data);
                setStep(2);
            }
            else {
                setStep(1);
            }
        } else {
            setStep(1);
            setSnack((snack) => {
                return {
                    ...snack,
                    open: true,
                    text: "Failed to fetch data",
                    severity: "error",
                };
            });
        }
    }
    const updateExistingMap = async () => {

        const response = await updateStorageMap(1, { name: "Storage Map", rows: 50, column: 50 });
        if (response?.data?.success) { }
        else {
            setSnack((snack) => {
                return {
                    ...snack,
                    open: true,
                    text: "Something went wrong",
                    severity: "error",
                };
            });
        }
    }
    const handleSelectedMap = (selectedMap) => {
        const { storage_map_cells, ...data } = selectedMap;
        const assignedCellsObject = storage_map_cells?.reduce((acc, item) => {
            acc[item.cell] = item;
            return acc;
        }, {});
        setAssignedCells(assignedCellsObject ?? {})
        setGraphData(data);
    }
    useEffect(() => {
        getExistingMap();
    }, [])


    return (
        <div className='flex items-center justify-center min-h-[80vh]'>
            <Snackbar Snack={snack} SetSnack={setSnack} />
            {loading ? <div className="flex">
                <Stack spacing={1}>
                    <Skeleton variant="rectangular" width="90vw" height="10vh" />
                    <Skeleton variant="rectangular" width="90vw" height="5vh" />
                    <Skeleton variant="rectangular" width="90vw" height="5vh" />
                    <Skeleton variant="rectangular" width="90vw" height="5vh" />
                    <Skeleton variant="rectangular" width="90vw" height="5vh" />
                    <Skeleton variant="rectangular" width="90vw" height="5vh" />
                    <Skeleton variant="rectangular" width="90vw" height="5vh" />
                    <Skeleton variant="rectangular" width="90vw" height="5vh" />
                    <Skeleton variant="rectangular" width="90vw" height="5vh" />
                    <Skeleton variant="rectangular" width="90vw" height="5vh" />
                </Stack>
            </div> : step === 1 ? (
                <StorageMapForm
                    formData={graphData}
                    setFormData={setGraphData}
                    handleSubmit={handleSubmit}
                />
            ) : step === 2 ? (
                <StorageGraph
                    name={graphData?.name}
                    rows={graphData?.rows}
                    column={graphData?.column}
                    snack={snack}
                    setSnack={setSnack}
                    id={graphData?.id}
                    assignedCells={assignedCells}
                    setAssignedCells={setAssignedCells}
                    basicInfo={graphData}
                    setBasicInfo={setGraphData}
                    getExistingMap={getExistingMap}
                    storageMaps={storageMaps}
                    setStorageMaps={setStorageMaps}
                    selectedMap={selectedMap}
                    setSelectedMap={setSelectedMap}
                    handleSelectedMap={handleSelectedMap}
                />
            ) : null}
        </div>
    );
};

export default StorageMap;
