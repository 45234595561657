import React, { useState, useEffect, useRef } from "react";
import "./ManageShipperUsers.css";
import CustomTable from "../../../../../../../Components/CustomTable/CustomTable";
import CarrierTableLoader from "../../../../../../../Components/CarrierTableLoader/CarrierTableLoader";
import Sad from "@mui/icons-material/SentimentVeryDissatisfied";
import {
  fetchShipperUsers,
  deleteShipperUser,
  updateAccountStatus,
  searchShipperUsers,
} from "../../../../../../../services/settings.services";
import SearchIcon from "../../../../../../../assets/icons/dispatcher-search.svg";
import SizeIcon from "../../../../../../../assets/icons/dispatcher-size.svg";
import DragIcon from "../../../../../../../assets/icons/dispatcher-drag.svg";
import SmallIcon from "../../../../../../../assets/icons/dispatcher-stable.svg";
import MediumIcon from "../../../../../../../assets/icons/dispatcher-mtable.svg";
import LargeIcon from "../../../../../../../assets/icons/dispatcher-ltable.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { FaRegEdit } from "react-icons/fa";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { MdOutlineCancel } from "react-icons/md";
import { MdRemoveRedEye } from "react-icons/md";
import ShipperUserDetails from "./ShipperUserDetails/ShipperUserDetails";
import ChangePassword from "./ChangePassword/ChangePassword";
import Confirm from "../../../../../../../Components/Popups/Confirm";
import EditShipperUser from "./EditShipperUser/EditShipperUser";
import AddShipperUser from "./AddShipperUser/AddShipperUser";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));
const ManageManageShipperUsers = () => {
  const searchRef = useRef(null);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [cols, setCols] = useState([]);
  const [filteredCols, setFilteredCols] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(false);
  const [isEmpty, setEmpty] = useState(false);
  const [pagination, setPagination] = useState({});
  const [statusUpdating, setStatusUpdating] = useState();
  const [expandedRow, setExpandedRow] = useState();
  const [tableSize, setTableSize] = useState("small");
  const [optionModal, setOptionModal] = useState();
  const [detailsModal, setDetailsModal] = useState();
  const [editUserModal, setEditUserModal] = useState("");
  const [addUserModal, setAddUserModal] = useState(false);
  const [changePassword, setChangePassword] = useState();
  const [confirmDelete, setConfirmDelete] = useState();

  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    text: "",
    severity: "",
  });
  const initialCols = [
    {
      name: "username",
      label: "UserName",
      data: (row) => row.username,
      checked: true,
      visible: true,
      width: "130px",
      mediumWidth: "150px",
      largeWidth: "170px",
    },
    {
      name: "email",
      label: "Email",
      data: (row) => row.email ?? "-",
      checked: true,
      visible: true,
      width: "200px",
      mediumWidth: "210px",
      largeWidth: "230px",
    },
    {
      name: "roles",
      label: "Role",
      data: (row) => row?.roles[0]?.name ?? "-",
      checked: true,
      visible: true,
      width: "104px",
      mediumWidth: "115px",
      largeWidth: "125px",
    },
    {
      name: "activate",
      label: "Activate",
      data: (row) => (
        <div
          className={`${
            statusUpdating ? "dispscreen__statusswitch_datainactive" : ""
          }`}
        >
          <Android12Switch
            onChange={(e) => handleStatusChange(e, row)}
            checked={
              ![false, "false", "0", null, undefined]?.includes(
                row.account_status
              )
            }
          />
        </div>
      ),
      checked: true,
      visible: true,
      width: "120px",
      mediumWidth: "130px",
      largeWidth: "140px",
    },
    {
      name: "actions",
      label: "Actions",
      data: (row) => (
        <div className="custabscreen__thdata_actions">
          <LightTooltip placement="top" arrow title="View Details About User">
            <div
              className="custabscreen__thdata_action"
              onClick={() => setDetailsModal(row)}
            >
              <MdRemoveRedEye size={20} />
              <span className="custabscreen__thdata_acttext">Details</span>
            </div>
          </LightTooltip>
          <LightTooltip placement="top" arrow title="Edit User">
            <div
              className="custabscreen__thdata_action"
              onClick={() => setEditUserModal(row)}
            >
              <FaRegEdit size={15} />
              <span className="custabscreen__thdata_acttext">Edit</span>
            </div>
          </LightTooltip>
          {/* <LightTooltip placement="top" arrow title="Reset User Password">
            <div
              className="custabscreen__thdata_action"
              onClick={() => setChangePassword(row.id)}
            >
              <FaRegEdit size={15} />
              <span className="custabscreen__thdata_acttext">
                Reset Password
              </span>
            </div>
          </LightTooltip> */}
          <LightTooltip placement="top" arrow title="Delete User">
            <div
              className="custabscreen__thdata_action"
              onClick={() => setConfirmDelete(row)}
            >
              <MdOutlineCancel size={20} />
              <span className="custabscreen__thdata_acttext">Delete</span>
            </div>
          </LightTooltip>
        </div>
      ),
      checked: true,
      visible: true,
      width: "200px",
      mediumWidth: "200px",
      largeWidth: "200px",
    },
  ];
  const fetchShipperUsersData = async (page) => {
    setLoading(true);
    const response = await fetchShipperUsers(
      user.token,
      page,
      `&name=${searchRef.current.value}`
    );
    // console.log(response);
    if (response?.data?.success === true) {
      setPagination(response?.data?.result);
      setUsers(response?.data?.result?.data);
      setLoading(false);
    } else if (response?.error?.response?.status === 401) {
      setLoading(false);
      localStorage.clear();
      navigate("/Login");
    } else {
      setLoading(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.error?.message ?? "Something went wrong!",
          severity: "error",
          vertical: "top",
          horizontal: "right",
        };
      });
    }
  };
  useEffect(() => {
    if (cols.every((obj) => obj.checked === false) || users.length === 0) {
      setEmpty(true);
    } else {
      setEmpty(false);
    }
  }, [cols, users]);

  useEffect(() => {
    fetchShipperUsersData(1);
  }, []);

  useEffect(() => {
    setCols(initialCols);
    setFilteredCols(initialCols);
  }, []);

  useEffect(() => {
    if (cols?.length > 1 && filteredCols?.length > 1) {
      const tempCols = cols;

      setCols(
        initialCols.map((item, index) => {
          return { ...item, checked: tempCols[index]?.checked };
        })
      );
    }
  }, [expandedRow, users, statusUpdating]);
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSearch = debounce(async (searchTerm, page) => {
    setSearching(true);

    let response = await searchShipperUsers(
      user.token,
      searchTerm,
      page ?? pagination.current_page
    );

    if (!response.error) {
      const { data, ...newObject } = response?.data?.result;
      // console.log(response);
      setPagination(newObject);
      setUsers(response?.data?.result?.data);
    }
    setSearching(false);
  }, 500);
  const handleInputChange = () => {
    const searchTerm = searchRef.current.value;
    handleSearch(searchTerm, 1);
  };
  const handleTableSize = (size) => {
    handleOptionModal("size");
    setTableSize(size);
  };
  const handleOptionModal = (modal) => {
    if (modal === optionModal) {
      setOptionModal(false);
    } else {
      setOptionModal(modal);
    }
  };
  const handleColSearch = (e) => {
    let filtered = cols.filter((item) =>
      item.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredCols(filtered);
  };
  const handleColChecked = (name, checked) => {
    setFilteredCols((prevCols) =>
      prevCols.map((col) => (col.name === name ? { ...col, checked } : col))
    );
    setCols((prevCols) =>
      prevCols.map((col) => (col.name === name ? { ...col, checked } : col))
    );
  };
  const handleShowAllCols = (show) => {
    setCols((prevCols) => {
      return prevCols.map((col) => {
        return { ...col, checked: show };
      });
    });
    setFilteredCols((prevCols) => {
      return prevCols.map((col) => {
        return { ...col, checked: show };
      });
    });
  };
  const handleRowChecked = (value) => {
    let arr = checkedRows;
    const index = arr.indexOf(value);
    if (index === -1) {
      arr.push(value);
    } else {
      arr.splice(index, 1);
    }
    setCheckedRows([...arr]);
  };
  const handleAllChecked = () => {
    if (checkedRows.length === users.length) {
      setCheckedRows([]);
    } else {
      setCheckedRows(users.map((item) => item.id));
    }
  };
  const handleStatusChange = async (e, record) => {
    setStatusUpdating(true);
    setUsers((shprs) =>
      shprs.map((item) =>
        item.id === record.id
          ? { ...item, account_status: e.target.checked }
          : item
      )
    );

    const updated = await updateAccountStatus(user.token, {
      id: parseInt(record.id),
      status: e.target.checked ? "active" : "inactive",
    });
    setStatusUpdating(false);
    if (updated.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: updated.error.message,
          severity: "error",
        };
      });
      setUsers(
        users.map((item) =>
          item.id === record.id
            ? {
                ...item,
                account_status: !e.target.checked,
              }
            : item
        )
      );
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `Status of ${
            updated?.data?.result?.username ?? "user"
          } changed to ${
            ![false, "false", "0", null, undefined].includes(
              updated?.data?.result?.account_status
            )
              ? "Active"
              : "Inactive"
          }`,
          severity: ![false, "false", "0", null, undefined].includes(
            updated?.data?.result?.account_status
          )
            ? "success"
            : "info",
        };
      });
      setUsers(
        users.map((item) =>
          item.id === record.id
            ? {
                ...item,
                account_status: ![
                  false,
                  "false",
                  "0",
                  null,
                  undefined,
                ].includes(updated?.data?.result?.account_status),
              }
            : item
        )
      );
    }
  };
  const handleDeleteShipperUser = async (record) => {
    const response = await deleteShipperUser(user.token, record.id);
    setStatusUpdating(false);
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `User Deleted Successfully `,
          severity: "success",
        };
      });
      setUsers(users.filter((item) => item.id !== record.id));
    }
  };
  const handleSnackClose = () => {
    setSnack({ ...snack, open: false });
  };
  return (
    <>
      <div className="mng__shipper__bar">
        <div className="mng__shipper__text">Manage Helper Users</div>
        <div
          className="mng__shipper__button"
          onClick={() => setAddUserModal(true)}
        >
          Create new
        </div>
      </div>
      <div className="dispatcher__screen_searchwrap">
        <img
          src={SearchIcon}
          className="dispatcher__screen_searchicon"
          alt=""
        />
        <input
          type="text"
          placeholder="Search"
          className="dispatcher__screen_searchfield"
          ref={searchRef}
          onChange={handleInputChange}
        />
        {searching && (
          <CircularProgress size={20} style={{ color: "#C1C1C1" }} />
        )}
      </div>
      <div
        className="dispatcher__screen_options"
        style={{ visibility: users.length < 1 ? "hidden" : "visible" }}
      >
        <div
          className="dispatcher__screen_option"
          onClick={() => handleOptionModal("columns")}
        >
          <span className="dispatcher__screen_optcolicon">
            <span className="dispatcher__screen_optcolbar"></span>
            <span className="dispatcher__screen_optcolbar"></span>
            <span className="dispatcher__screen_optcolbar"></span>
          </span>
          <span className="dispatcher__screen_optiontext">COLUMNS</span>
          {optionModal === "columns" && (
            <div
              className="dispscreen__cols_modal"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="dispscreen__colsmodal_autocomplt">
                <input
                  type="text"
                  onChange={handleColSearch}
                  placeholder="Search Column"
                  className="dispscreen__colsmodal_autocompltfield"
                />
              </div>
              <div className="dispscreen__colsmodal_colslist">
                {filteredCols.map((item) => {
                  if (item.visible) {
                    return (
                      <div className="dispscreen__colsmodal_colslistitem">
                        <span className="dispscreen__colsmodal_colslistitemleft">
                          <input
                            type="checkbox"
                            className="dispscreen__colsmodal_colslistitemcheck"
                            checked={item.checked}
                            onChange={(e) =>
                              handleColChecked(item.name, e.target.checked)
                            }
                          />
                          {item.name}
                        </span>
                        <span className="dispscreen__colsmodal_colslistitemright">
                          <img
                            src={DragIcon}
                            alt=""
                            className="dispscreen__colsmodal_colslistitemdrag"
                          />
                        </span>
                      </div>
                    );
                  }
                })}
              </div>
              <div className="dispscreen__colsmodal_footer">
                <div
                  className="dispscreen__colsmodal_ftrleft"
                  onClick={() => handleShowAllCols(false)}
                >
                  HIDE ALL
                </div>
                <div
                  className="dispscreen__colsmodal_ftrright"
                  onClick={() => handleShowAllCols(true)}
                >
                  SHOW ALL
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="dispatcher__screen_option"
          onClick={() => handleOptionModal("size")}
        >
          <img src={SizeIcon} className="dispatcher__screen_optionicon" />
          <span className="dispatcher__screen_optiontext">SIZE</span>
          {optionModal === "size" && (
            <div
              className="dispscreen__size_modal"
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className="dispscreen__size_modalitem"
                onClick={() => handleTableSize("small")}
              >
                <div className="dispscreen__size_mileft">
                  <img src={SmallIcon} alt="" />
                </div>
                <div className="dispscreen__size_miright">Small</div>
              </div>
              <div
                className="dispscreen__size_modalitem"
                onClick={() => handleTableSize("medium")}
              >
                <div className="dispscreen__size_mileft">
                  <img src={MediumIcon} alt="" />
                </div>
                <div className="dispscreen__size_miright">Medium</div>
              </div>
              <div
                className="dispscreen__size_modalitem"
                onClick={() => handleTableSize("large")}
              >
                <div className="dispscreen__size_mileft">
                  <img src={LargeIcon} alt="" />
                </div>
                <div className="dispscreen__size_miright">Large</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.text}
        </Alert>
      </Snackbar>
      <Confirm
        open={confirmDelete}
        setOpen={setConfirmDelete}
        handleConfirm={() => handleDeleteShipperUser(confirmDelete)}
        text="Do you want to delete this user?"
      />
      <ShipperUserDetails
        open={!!detailsModal}
        data={detailsModal}
        setOpen={setDetailsModal}
        users={users}
        setUsers={setUsers}
        user={user}
        setSnack={setSnack}
        setDetailsModal={setDetailsModal}
      />
      <EditShipperUser
        open={!!editUserModal}
        setOpen={setEditUserModal}
        previousData={editUserModal}
        setUsers={setUsers}
        setSnack={setSnack}
      />
      <AddShipperUser
        open={addUserModal}
        setOpen={setAddUserModal}
        setUsers={setUsers}
        setSnack={setSnack}
      />
      <ChangePassword
        open={!!changePassword}
        setOpen={setChangePassword}
        previousData={changePassword}
        setSnack={setSnack}
        setUsers={setUsers}
      />
      {users.length > 0 && (
        <CustomTable
          indexing
          title={"Shipper Users"}
          headerLoading={statusUpdating}
          size={tableSize}
          data={users}
          handleRowChecked={handleRowChecked}
          checkedRows={checkedRows}
          handleAllChecked={handleAllChecked}
          cols={cols}
          isEmpty={isEmpty}
          pagination={pagination}
          getAllData={fetchShipperUsersData}
          loading={loading}
          setSnack={setSnack}
          // tab={tab}
          searchTerm={searchRef.current.value}
          containerStyle={{ minWidth: "0px", width: "100%" }}
          wrapperStlle={{ width: "100%" }}
        />
      )}
      {users.length < 1 && loading && (
        <div style={{ width: "60vw" }} className="driver__screen_loading">
          <CarrierTableLoader />
        </div>
      )}
      {users.length < 1 && !loading && (
        <div className="driver__screen_empty">
          <div className="driver__scrnempt_content">
            <Sad className="driver__scrnempt_contenticon" />
            <div>No Records to display!</div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageManageShipperUsers;
