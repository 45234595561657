import React from "react";
import Tooltips, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltips {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
const Tooltip = ({ children, title = "" }) => {
  return (
    <>
      <LightTooltip placement="top" arrow title={title}>
        {children}
      </LightTooltip>
    </>
  );
};

export default Tooltip;
