import React, { useState, useEffect } from "react";
import { initialPickupTab } from "../../../../../data/permissionsArray";
import { FaEdit } from "react-icons/fa";
import "./Pickup.css";
import Flag from "react-world-flags";
import { fetchShipments } from "../../../../../services/ShipperServices/Shipments/shipments.services";
import { useDispatch, useSelector } from "react-redux";
import AssignPickup from "./Components/AssignPickup/AssignPickup";
import { updateUser } from "../../../../../store/userSlice";
import Snackbar from "../../../../../Components/SnackBar/SnackBar";
import ShipmentsTable from "../../../../../Components/BoltTable/BoltTable";
import TabsComponent from "../../../../../Components/Common/TabsComponent";

const Pickup = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [shipments, setShipments] = useState([]);
  const [assignPickupModal, setAssignPickupModal] = useState();
  const [tab, setTab] = useState("all");
  const [tabPermissions, setTabPermissions] = useState(initialPickupTab);
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  useEffect(() => {
    if (user.role != "Shipper") {
      let validPermissions = initialPickupTab.filter((t) =>
        user?.mergedPermissions?.includes(t.name)
      );
      console.log("validPermissions===", validPermissions);
      if (validPermissions?.length > 0) {
        setTab(validPermissions[0]?.value);
      } else {
        setTab("nothing");
      }
      setTabPermissions(validPermissions);
    } else {
      setTab("all");
    }
  }, []);

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 1,
      label: "Carrier",
      render: (row) => (
        <>
          <img
            src={row?.shipping_company?.shipping_company?.logo_url}
            loading="lazy"
          />
        </>
      ),
      width: "200px",
      fixed: "left",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 2,
      label: "Service",
      render: (row) => <>{row?.service_type ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 3,
      label: "Reference ID",
      render: (row) => <>{row?.referance_code ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 4,
      label: "Pickup ID",
      render: (row) => <>{row?.PickupID ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 5,
      label: "Bolt Tracking ID",
      render: (row) => <>{row?.tracking_id ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 6,
      label: "Carrier Tracking ID",
      render: (row) => (
        <>{tab === 2 ? row?.id ?? "-" : row?.shipment_tracking_id ?? "-"}</>
      ),
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 7,
      label: "Pickup",
      render: (row) => (
        <>
          {" "}
          {row.PickupDate &&
            `${row.PickupDate?.slice(0, 10)}  ${row.readyopentime ? row.readyopentime?.slice(0, 5) : ""
            }${row.readyopentime && row.readyclosetime ? "-" : ""}${row.readyclosetime ? row.readyclosetime?.slice(0, 5) : ""
            }`}
          {!row.PickupDate && (
            <div
              className="putable__arrangepickup_btn"
              onClick={() => setAssignPickupModal(row)}
            >
              Arrange Pickup <FaEdit size={10} style={{ marginLeft: "5px" }} />
            </div>
          )}
        </>
      ),
      width: "250px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 8,
      label: "Pickup Country",
      render: (row) => <>{row?.fcountrycode ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 9,
      label: "Pickup City",
      render: (row) => <>{row?.fcity ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 10,
      label: "Pickup Address",
      render: (row) => <>{row?.faddress ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 11,
      label: "Pickup Postal/Zip",
      render: (row) => <>{row?.fzip ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 12,
      label: "Pickup Notes",
      render: (row) => <>{row?.finstructions ?? "-"}</>,
      width: "250px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 13,
      label: "Created at",
      render: (row) => <>{row?.created_at?.slice(0, 10) ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
  ];
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const getAllShipments = async (page, perPage, filter) => {
    const response = await fetchShipments(tab, page, perPage, filter, "pickup");
    if (!response.error) {
      setShipments(response?.data?.result?.shipments);
      dispatch(
        updateUser({
          unReadPickups: response?.data?.result?.unReadShipmentCount,
        })
      );
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  return (
    <div className="dispatcher__screen" style={{ maxWidth: "100%" }}>
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <AssignPickup
        open={assignPickupModal}
        setOpen={setAssignPickupModal}
        setSnack={setSnack}
        setShipments={setShipments}
      />


      <TabsComponent items={tabPermissions} tab={tab} handleTabChange={handleTabChange} />
      <div className="orders_header">
        <div>
          <span className="orders_hdrheading">Pickup List</span>
        </div>
      </div>
      <div className="custom__table_size1">
        <ShipmentsTable
          message={"No Shipments Available To Display!"}
          searchPlaceHolder={"Search Shipments"}
          getAllData={getAllShipments}
          data={shipments}
          columns={columns}
          refresh={tab}
        />
      </div>
    </div>
  );
};

export default Pickup;
