import React, { useState, useEffect } from "react";
import "../RegisterSteps/RegisterSteps.css";

import { ReactComponent as Address } from "../../../../assets/icons/address.svg";
import MenuItem from "@mui/material/MenuItem";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "@mui/material/Select";

import CustomTextField from "../CustomTextField/CustomTextField";
const platforms = [
  "Search Engine",
  "Facebook",
  "Linkedin",
  "Word of mouth",
  "Email",
  "News Letter",
  "Other",
];
const BtnLoader = () => {
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
const RegisterStep3 = ({
  setCurrentStep,
  formData,
  handleFieldChange,
  handleSubmit,
  loading,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [marketplace, setMarketplace] = useState([]);
  const [verified, setVerified] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMarketplace(typeof value === "string" ? value.split(",") : value);
  };
  function onChange(value) {
    setVerified(value);
  }
  useEffect(() => {
    if (
      [
        formData.username,
        formData.password,
        confirmPassword,
        formData.about_us,
        formData.experience,
        formData.revenue,
      ].includes("") ||
      formData.password !== confirmPassword ||
      !verified
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formData, confirmPassword, verified]);
  return (
    <div className="rfsregister__steps_wrap">
      <div className="rfsregister__steps_fieldsrow">
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            icon={<Address />}
            placeholder="Username"
            type="text"
            name="username"
            value={formData.username}
            onChange={(e) => handleFieldChange("username", e.target.value)}
          />
        </div>
      </div>
      <div className="rfsregister__steps_fieldsrow">
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            type="password"
            placeholder="Password"
            name="password"
            value={formData.password}
            onChange={(e) => handleFieldChange("password", e.target.value)}
          />
        </div>
        <div
          className={`rfsregister__steps_fieldscol ${
            formData.password !== confirmPassword &&
            "rfsregister__steps_fieldscolerror"
          }`}
        >
          <CustomTextField
            type="password"
            placeholder="Confirm Password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
      </div>
      <div className="rfsregister__steps_fieldsrow">
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            placeholder="Industry Experience"
            type="text"
            name="experience"
            value={formData.experience}
            onChange={(e) => handleFieldChange("experience", e.target.value)}
          />
        </div>
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            placeholder="Gross Revenue Per Year "
            type="number"
            name="revenue"
            value={formData.revenue}
            onChange={(e) => handleFieldChange("revenue", e.target.value)}
          />
        </div>
      </div>
      <div className="rfsregister__steps_fieldsrow">
        <div className="rfsregister__steps_fieldscol  fieldC__selectBox">
          <div className="rfsregister__steps_fieldscollabel">
            How did you hear about us?
          </div>
          <Select
            sx={{ width: "100%" }}
            variant="outlined"
            name="about_us"
            value={formData.about_us}
            onChange={(e) => handleFieldChange("about_us", e.target.value)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {platforms.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className="recaptcha__checkbox_wrap">
        <ReCAPTCHA
          sitekey="6Ld9BMcjAAAAAE-VKFPaecDHGEqeSKQdQ_GsZ-0W"
          onChange={onChange}
          size="normal"
        />
      </div>
      <div className="rfsregister__steps_next_previous">
        <div
          className="rfsregister__steps_nextbtn"
          onClick={() => {
            setCurrentStep(2);
          }}
        >
          Previous
        </div>
        <div
          className={`rfsregister__steps_nextbtn ${
            (disabled || loading) && "rfsregister__steps_nextbtndisabled"
          }`}
          onClick={() => {
            handleSubmit({
              ...formData,
              market: marketplace.map((item) => {
                return { value: item };
              }),
            });
          }}
        >
          {loading ? <BtnLoader /> : "Sign Up"}
        </div>
      </div>
    </div>
  );
};

export default RegisterStep3;
