import React, { useState, useEffect } from "react";
import "./invoices.css";
// apis imports
import {
  fetchLtlInvoiceOrders,
  fetchLtlInvoiceOrdersWarehouse,
} from "../../../../../../../services/ShipperServices/Payments/payments.services";
import { getWarehouseShippers } from "../../../../../../../services/warehouse.services";

// library imports
import TextField from "../../../ShipPage/Components/TextField";
import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import Invoice from "./OrderInvoice/FullPageSlip";
import CircularLoader from "../../../../../../../Components/CircularLoader/CircularLoader";
import { fetchOrderStatuses } from "../../../../../../../services/orders.services";
import { getUserWarehousesByWar_Id } from "../../../../../../../services/warehouse.services";
import CustomDropdown from "../../../../../../Products/AddProduct/Components/CustomDropdown/CustomDropdown";
import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import { useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";

const LtlInvoices = ({ setSnack }) => {
  const [loader, setloader] = useState(false);
  const [data, setData] = useState(null);
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState([]);
  const [users, setUsers] = useState([]);
  const user = useSelector((state) => state.user);
  const [dates, setDates] = useState({
    from: "",
    to: "",
  });

  const handleChange = (e) => {
    setDates({ ...dates, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      ...dates,
      fulfillmentStatusIds: selectedStatuses,
      ...(user?.role === "warehouseUser"
        ? { userIds: selectedWarehouse }
        : { warehouseIds: selectedWarehouse }),
    };
    setloader(true);
    let saved = "";
    if (user?.role === "warehouseUser") {
      saved = await fetchLtlInvoiceOrdersWarehouse(payload, user?.warehouse_id);
    } else {
      saved = await fetchLtlInvoiceOrders(payload);
    }
    if (!saved.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: saved?.data?.message,
          severity: "success",
        };
      });
      console.log(saved);
      setData(saved?.data?.result);
      // setDates({
      //   from: "",
      //   to: "",
      // });
      setloader(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: saved?.error?.response?.data?.message,
          severity: "error",
        };
      });
      setloader(false);
    }
  };
  const handleStatusChange = (event) => {
    setSelectedStatuses(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };
  const handleWarehouseChange = (event) => {
    setSelectedWarehouse(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };
  const getOrderStatuses = async () => {
    try {
      const response = await fetchOrderStatuses();
      if (response.error) {
        console.log(response.error);
      } else {
        setOrderStatuses(response.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchWareHouseClientsList = async () => {
    const response = await getWarehouseShippers(user?.warehouse_id);
    if (!response.error) {
      setUsers(response?.data?.result?.data);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };
  useEffect(() => {
    getOrderStatuses();
    if (user?.role === "warehouseUser") {
      fetchWareHouseClientsList();
    }
  }, []);
  return (
    <div className="taxes__slip_details_wrap">
      <div>
        <div className="payment__invoices_header">
          <div>
            <span className="payment__invoices_heading tax__page_title">
              Invoices
            </span>
          </div>
        </div>

        <Row className="mx-auto mt-3">
          <Col md={11} className="mx-auto">
            <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
              <b style={{ textAlign: "center" }} className="tax__form_heading">
                Select Interval For Orders History
              </b>
            </Row>
            <Form onSubmit={handleSubmit} className="form__tax_slip_wrap">
              <Row className="to_from_tax_slip_date">
                <TextField
                  Label={"Start Date"}
                  Name="from"
                  Data={dates.from}
                  Handler={handleChange}
                  Width="40%"
                  Type="date"
                  Required={true}
                />
                <TextField
                  Label={"End Date"}
                  Name="to"
                  Data={dates.to}
                  Handler={handleChange}
                  Width="40%"
                  Type="date"
                  Required={true}
                />
              </Row>
              <Row className="to_from_tax_slip_date">
                <FormControl
                  size="small"
                  sx={{ width: "40%", marginBottom: "8px" }}
                >
                  <InputLabel id="order-status-select">
                    Fulfillment Status
                  </InputLabel>
                  <Select
                    labelId="order-status-select"
                    id="order-status"
                    label="Fulfillment Status"
                    multiple
                    value={selectedStatuses || []}
                    onChange={handleStatusChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) =>
                      orderStatuses
                        ?.filter((status) => selected?.includes(status?.id))
                        ?.map((status) => status?.name?.toUpperCase())
                        .join(", ")
                    }
                    style={{ marginTop: "10px" }}
                  >
                    {orderStatuses.map((status) => (
                      <MenuItem key={status?.id} value={status?.id}>
                        {status?.name?.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {user?.role === "Shipper" && (
                  <FormControl
                    size="small"
                    sx={{ width: "40%", marginBottom: "8px" }}
                  >
                    <InputLabel id="order-Warehouse-select">
                      Warehouse
                    </InputLabel>
                    <Select
                      labelId="order-Warehouse-select"
                      id="order-Warehouse"
                      label="Warehouse"
                      multiple
                      value={selectedWarehouse || []}
                      onChange={handleWarehouseChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) =>
                        user?.user_approved_warehouses
                          ?.filter((warehouse) =>
                            selected?.includes(warehouse?.warehouse?.id)
                          )
                          ?.map((warehouse) => warehouse?.warehouse?.name)
                          .join(", ")
                      }
                      style={{ marginTop: "10px" }}
                    >
                      {user?.user_approved_warehouses.map((warehouse) => (
                        <MenuItem
                          key={warehouse?.warehouse?.id}
                          value={warehouse?.warehouse?.id}
                        >
                          {`#${warehouse?.warehouse?.id} ${warehouse?.warehouse?.name}`}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {user?.role === "warehouseUser" && (
                  <FormControl
                    size="small"
                    sx={{ width: "40%", marginBottom: "8px" }}
                  >
                    <InputLabel id="order-users-select">Users</InputLabel>
                    <Select
                      labelId="order-users-select"
                      id="order-users"
                      label="Users"
                      multiple
                      value={selectedWarehouse || []}
                      onChange={handleWarehouseChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) =>
                        users
                          ?.filter((user) => selected?.includes(user?.id))
                          ?.map((user) => user?.username?.toUpperCase())
                          .join(", ")
                      }
                      style={{ marginTop: "10px" }}
                    >
                      {users.map((user) => (
                        <MenuItem key={user?.id} value={user?.id}>
                          {user?.username}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Row>
              <Row className="to_from_tax_slip_date">
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  disabled={loader}
                >
                  {loader ? <CircularLoader /> : "Get Orders"}
                </Button>
              </Row>
            </Form>
            <Row>
              <Invoice
                data={data}
                setdata={setData}
                dates={dates}
                Role={user?.role === "warehouseUser" ? "User" : "Warehouse"}
              />
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LtlInvoices;
