import AllTickets from "../../../../../Components/Tickets/Components/AllTickets/AllTickets";

const TicketsBorad = () => {
  return (
    <div className="tickets__page_main_wrap">
      <AllTickets />
    </div>
  );
};

export default TicketsBorad;
