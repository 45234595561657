import React from "react";
import Header from "./Components/Header";
import Details from "./Components/Details";
import Footer from "./Components/Footer";

import "./InvoiceModal.css";
const PrintableSlip = ({ data, invoiceRef, dates, shipperUsers, selectedShipperUsers }) => {
  return (
    <div className="invoice__modal_wrap ">
      <div className="invoice__modal_box printable__invoice" ref={invoiceRef} >
        <Header data={data} dates={dates} type="Print" shipperUsers={shipperUsers} selectedShipperUsers={selectedShipperUsers} />
        <Details data={data} type="Print" />
        <Footer data={data} type="Print" />
      </div>
    </div>
  );
};

export default PrintableSlip;
