import React from "react";
import Modal from "@mui/material/Modal";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import "./Popups.css";
const Confirm = ({ open, setOpen, handleConfirm, text }) => {
  return (
    <Modal open={open}>
      <div className="confirm_dialogue_box">
        <div className="confirm_dialogue_boxicon">
          <HelpOutlineIcon />
        </div>
        <div className="confirm_dialogue_text">
          {text ? text : "Are you sure you want to perform this action?"}
        </div>
        <div className="confirm_dialogue_btns">
          <div
            className="confirm_dialogue_cancel"
            onClick={() => setOpen(false)}
          >
            Cancel
          </div>
          <div
            className="confirm_dialogue_confirm"
            onClick={() => {
              handleConfirm();
              setOpen(false);
            }}
          >
            Confirm
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Confirm;
