import React, { useState, useEffect } from "react";
import "../RegisterSteps/RegisterSteps.css";

import { ReactComponent as Address } from "../../../../assets/icons/address.svg";
import { ReactComponent as User } from "../../../../assets/icons/user.svg";
import { ReactComponent as Lock } from "../../../../assets/icons/lock.svg";
import { ReactComponent as Envelop } from "../../../../assets/icons/envelop.svg";
import MenuItem from "@mui/material/MenuItem";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "@mui/material/Select";

import CustomTextField from "../CustomTextField/CustomTextField";
const platforms = [
  "Search Engine",
  "Facebook",
  "Linkedin",
  "Word of mouth",
  "Email",
  "News Letter",
  "Other",
];
const BtnLoader = () => {
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
const RegisterStep3 = ({
  setCurrentLStep,
  formData,
  handleFieldChange,
  handleSubmit,
  loading,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [marketplace, setMarketplace] = useState([]);
  const [verified, setVerified] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMarketplace(typeof value === "string" ? value.split(",") : value);
  };
  function onChange(value) {
    setVerified(value);
  }
  useEffect(() => {
    if (
      [
        formData.username,
        formData.firstname,
        formData.lastname,
        formData.password,
        confirmPassword,
        formData.email,
      ].includes("") ||
      formData.password !== confirmPassword ||
      !verified
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formData, confirmPassword, verified]);
  const handleRegister = () => {
    const payload = {
      name: formData?.warehousename,
      address: formData?.warehouseaddress,
      city: formData?.city,
      country: formData?.country,
      state: formData?.state,
      passcode: formData?.passcode,
      role: "warehouseUser",
      user: {
        username: formData?.username,
        firstname: formData?.firstname,
        lastname: formData?.lastname,
        business_name: formData?.business_name,
        industry_type: formData?.industry_type,
        email: formData?.email,
        password: formData?.password,
        zip: formData?.passcode,
        city: formData?.city,
        country: formData?.country,
        province: formData?.state,
        address: formData?.warehouseaddress,
      },
    };
    handleSubmit(payload);
  };
  return (
    <div className="rfsregister__steps_wrap">
      <div className="rfsregister__steps_fieldsrow">
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            icon={<User />}
            placeholder="First Name"
            type="text"
            name="firstname"
            value={formData.firstname}
            onChange={(e) => handleFieldChange("firstname", e.target.value)}
          />
        </div>
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            icon={<User />}
            placeholder="Last Name"
            type="text"
            name="lastname"
            value={formData.lastname}
            onChange={(e) => handleFieldChange("lastname", e.target.value)}
          />
        </div>
      </div>

      <div className="rfsregister__steps_fieldsrow">
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            icon={<User />}
            placeholder="Username"
            type="text"
            name="username"
            value={formData.username}
            onChange={(e) => handleFieldChange("username", e.target.value)}
          />
        </div>
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            icon={<Envelop />}
            placeholder="Email"
            type="email"
            name="email"
            value={formData.email}
            onChange={(e) => handleFieldChange("email", e.target.value)}
          />
        </div>
      </div>
      <div className="rfsregister__steps_fieldsrow">
        <div className="rfsregister__steps_fieldscol">
          <CustomTextField
            type="password"
            placeholder="Password"
            name="password"
            value={formData.password}
            onChange={(e) => handleFieldChange("password", e.target.value)}
          />
        </div>
        <div
          className={`rfsregister__steps_fieldscol ${
            formData.password !== confirmPassword &&
            "rfsregister__steps_fieldscolerror"
          }`}
        >
          <CustomTextField
            type="password"
            placeholder="Confirm Password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
      </div>
      <div className="recaptcha__checkbox_wrap">
        <ReCAPTCHA
          sitekey="6Ld9BMcjAAAAAE-VKFPaecDHGEqeSKQdQ_GsZ-0W"
          onChange={onChange}
          size="normal"
        />
      </div>

      <div className="rfsregister__steps_next_previous">
        <div
          className="rfsregister__steps_nextbtn"
          onClick={() => {
            setCurrentLStep(2);
          }}
        >
          Previous
        </div>

        <div
          className={`rfsregister__steps_nextbtn ${
            (disabled || loading) && "rfsregister__steps_nextbtndisabled"
          }`}
          onClick={() => {
            handleRegister();
          }}
        >
          {loading ? <BtnLoader /> : "Sign Up"}
        </div>
      </div>
    </div>
  );
};

export default RegisterStep3;
