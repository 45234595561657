import React, { useEffect, useState } from "react";
import "./Components.css";
import CustomDropdown from "../../Products/AddProduct/Components/CustomDropdown/CustomDropdown";
import { removeUnderscoresAndCapitalize } from "../../../helpers/helpers";
import { fetchOrderStatuses } from "../../../services/orders.services";
const PaymentDetails = ({ data, setData, editMode, setEditMode }) => {
  const [orderStatuses, setOrderStatuses] = useState();
  const handleFieldChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };
  const getOrderStatuses = async () => {
    try {
      const response = await fetchOrderStatuses();
      if (response.error) {
        console.log(response.error);
      } else {
        setOrderStatuses(response.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getOrderStatuses();
  }, []);
  const handleShippingTotal = (value) => {
    setData({
      ...data,
      shipping_total: value,
    });
  };
  function capitalizeFirstLetter(str) {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1) ?? "";
  }
  return (
    <div className="sss__payment_section">
      <div className="sss__pmtsection_heading">Order Details</div>
      <div
        className="sss__addressection_inputrow"
        style={{ justifyContent: "space-between", alignItems: "flex-end" }}
      >
        <div className="sss__addressection_inputcol">
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">Order Type</label>
            <CustomDropdown
              options={[
                "Walkin",
                "Manual",
                "MarketPlace",
                "Self Delivery",
                "Self Pickup",
                "Return",
              ]}
              onSelect={(option) => {
                setData({ ...data, type: option });
              }}
              selectedLabel={data.type}
            />
          </div>
        </div>
        {data?.type === "Self Pickup" && (
          <div className="sss__addressection_inputcol">
            <div className="sss__addressection_inputbox">
              <label className="sss__addressection_inputtitle">
                Pickup Date
              </label>
              <input
                className="sss__addressection_inputfield"
                type="date"
                onChange={(e) =>
                  setData({ ...data, pickup_date: e.target.value })
                }
                value={data.pickup_date}
              />
            </div>
          </div>
        )}
      </div>
      <div className="sss__addressection_inputrow">
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "200px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">
              Order Status
            </label>
            <CustomDropdown
              options={["Pending", "Confirmed", "On Hold", "Cancelled"]}
              onSelect={(option) => {
                setData({ ...data, status: option });
              }}
              disabled={
                (editMode && data?.fullfilment_status?.id == 4) ||
                data.status == "Cancelled"
              }
              selectedLabel={data.status}
            />
          </div>
        </div>
        <div className={"sss__addressection_inputbox"}>
          <label className="sss__addressection_inputtitle">
            Fulfilment Status
          </label>
          {data?.fullfilment_status?.name?.toLowerCase() === "attention" ? (
            <CustomDropdown
              optionObjects={orderStatuses
                ?.filter((status) =>
                  ["attention", "attended"].includes(
                    status?.name?.toLowerCase()
                  )
                )
                ?.map((status) => {
                  return { title: status.name, value: status.id };
                })}
              onSelect={(option) => {
                setData({ ...data, order_status_id: option.value });
              }}
              selectedLabel={
                orderStatuses?.find((item) => item.id === data.order_status_id)
                  ?.name
              }
            />
          ) : (
            <input
              className="sss__addressection_inputfield"
              type="text"
              value={
                capitalizeFirstLetter(data?.fullfilment_status?.name) || ""
              }
              style={{ color: "#A1A8B4" }}
              disabled
            />
            // <input
            //   className="sss__addressection_inputfield sss__addressection_inputfielddisabled"
            //   type="text"
            //   // placeholder="Enter Total Amount"
            //   value={data?.fullfilment_status?.name?.toUpperCase()}
            // />
          )}

          {/* {Object.keys(errors)?.includes("width") && (
              <div className="addprod__custominputfield_error">
                <MdErrorOutline className="addprod__custominputfield_erroricn" />
                {errors?.width?.msg}
              </div>
            )} */}
        </div>
      </div>{" "}
      <div
        style={{ borderBottom: "1px solid #e5e5e5", padding: "5px 0px" }}
      ></div>
      <div className="sss__pmtsection_labelbold">Payment</div>
      <div className="sss__addressection_inputrow">
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "200px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">
              Amount Received
            </label>
            <input
              className="sss__addressection_inputfield"
              type="text"
              placeholder="Enter Recieved Amount"
              onChange={(e) =>
                handleFieldChange("amount_received", e.target.value)
              }
              value={data.amount_received}
            />
            {/* <div className="sss__addressection_inputfielderror">
              <MdErrorOutline className="sss__addressection_inputfielderroricn" />
              Supplier Name is required
            </div> */}
          </div>
        </div>
        {(data?.payment_method_title === "Cash On Pickup" ||
          data?.payment_method_title === "Cash On Delivery") && (
          <>
            <div
              className="sss__addressection_inputcol"
              style={{ minWidth: "120px" }}
            >
              <div className="sss__addressection_inputbox">
                <label className="sss__addressection_inputtitle">
                  Amount Pending
                </label>
                <input
                  className="sss__addressection_inputfield"
                  type="text"
                  placeholder="Enter Amount Receiveable By Warehouse"
                  onChange={(e) =>
                    handleFieldChange("amount_pending", e.target.value)
                  }
                  value={data.amount_pending}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className="sss__addressection_inputrow">
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "200px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">
              Payment Method
            </label>
            <CustomDropdown
              options={[
                "Direct Bank Transfer",
                "Debit or Credit Card",
                "E-Transfer",
                "Cash On Delivery",
                "Cash On Pickup",
              ]}
              onSelect={(option) => {
                setData({ ...data, payment_method_title: option });
              }}
              selectedLabel={data.payment_method_title}
            />
          </div>
        </div>
        {editMode &&
          (data?.payment_method_title === "Cash On Pickup" ||
            data?.payment_method_title === "Cash On Delivery") && (
            <>
              <div
                className="sss__addressection_inputcol"
                style={{ minWidth: "200px" }}
              >
                <div className="sss__addressection_inputbox">
                  <label className="sss__addressection_inputtitle">
                    Amount Received By Warehouse
                  </label>
                  <input
                    className="sss__addressection_inputfield "
                    type="text"
                    placeholder="Amount Received By Warehouse"
                    // onChange={(e) =>
                    //   handleFieldChange(
                    //     "warehouse_amount_recieved",
                    //     e.target.value
                    //   )
                    // }
                    disabled
                    value={data?.amount_received_by_warehouse}
                  />
                  {/* <div className="sss__addressection_inputfielderror">
              <MdErrorOutline className="sss__addressection_inputfielderroricn" />
              Supplier Name is required
            </div> */}
                </div>
              </div>{" "}
            </>
          )}
      </div>
      <div
        className="sss__addressection_inputrow"
        style={{ margin: "5px 0px" }}
      >
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "200px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">
              Total Amount
            </label>
            <input
              className="sss__addressection_inputfield sss__addressection_inputfielddisabled"
              type="text"
              placeholder="Enter Total Amount"
              value={data.formula?.salePrice}
            />
          </div>
        </div>
        {/* <div
          className="addprod__custominput_flexcol"
          style={{ minWidth: "100px" }}
        >
          
        </div> */}
        {/*  )} */}
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "120px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">
              Shipping Total
            </label>
            <input
              className="sss__addressection_inputfield"
              type="text"
              placeholder="Enter Shipping Total"
              value={data?.shipping_total}
              onChange={(e) => handleShippingTotal(e.target.value)}
            />
            {/* <div className="sss__addressection_inputfielderror">
              <MdErrorOutline className="sss__addressection_inputfielderroricn" />
              Supplier Cost is required
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
