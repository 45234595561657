import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import {
  TotalSpaceColumn,
  Dimensions,
  Weight,
} from "../Product/ProductService";
import Button from "@mui/material/Button";
import { fetchAllShipperPallets } from "../../../services/product.service";
import Loader from "../../../Components/CircularLoader/CircularLoader";
import Checkbox from "@mui/material/Checkbox";

export default function AddExistingPallet({ open, setOpen, setData }) {
  const handleClose = () => {
    setLoading(false);
    setPallets([]);
    setOpen(false);
  };
  const [loading, setLoading] = useState(false);
  const [pallets, setPallets] = useState([]);

  const handleChange = (pallet) => {
    const newData = {
      lot_number: "",
      quantity: 0,
      pallet_id: pallet?.id,
      pallet: pallet,
    };
    setData((prevPallets) => [...prevPallets, newData]);
  };

  const getAssignedPallets = async (id) => {
    const response = await fetchAllShipperPallets(id);
    if (!response.error) {
      setPallets(response?.data?.result);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getAssignedPallets(open);
    }
  }, [open]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="existing__pallet_list_modal_wrap">
          <div className="pexisting__pallet_list_modal_main_heading">
            Existing Pallets List
          </div>
          <div className="existing__pallet_list_modal_content">
            <div className="cexisting__pallet_list_modal_content_wrapper">
              {/* <div
                style={{
                  marginBottom: "16px",
                  borderBottom: "0.5px solid lightgray",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <b> Pallets / Skids / Bins</b>
              </div> */}
              <table className="existing__pallet_list_modal_content_table">
                <thead>
                  <th className="stockreciveform__tablehd">#</th>
                  <th className="stockreciveform__tablehd">Name</th>
                  <th className="stockreciveform__tablehd">Location</th>
                  <th className="stockreciveform__tablehd">
                    Dimentions (l*w*H)
                  </th>
                  <th className="stockreciveform__tablehd">Pallet Volume</th>
                  <th className="stockreciveform__tablehd">Weight Capacity</th>
                  <th className="stockreciveform__tablehd">Product Quantity</th>
                  <th className="stockreciveform__tablehd">Created At</th>
                  <th className="stockreciveform__tablehd">Action</th>
                </thead>
                <tbody>
                  {pallets?.length > 0 ? (
                    pallets?.map((pallet, index) => {
                      return (
                        <tr key={index}>
                          <td className="stockreciveform__tableitem font_size">
                            {pallet?.id ?? ""}
                          </td>
                          <td className="stockreciveform__tableitem font_size">
                            {pallet?.name ?? "-"}
                          </td>
                          <td className="stockreciveform__tableitem font_size">
                            {pallet?.location?.name ?? "-"}
                          </td>
                          <td className="stockreciveform__tableitem font_size">
                            <Dimensions
                              length={pallet?.length ?? 0}
                              width={pallet?.width ?? 0}
                              height={pallet?.height ?? 0}
                              unit={pallet?.unit === "imperial" ? "inch" : "cm"}
                              space={true}
                            />
                          </td>
                          <td className="stockreciveform__tableitem font_size">
                            <TotalSpaceColumn
                              length={pallet?.length ?? 0}
                              width={pallet?.width ?? 0}
                              height={pallet?.height ?? 0}
                              unit={pallet?.unit === "imperial" ? "inch" : "cm"}
                              quantity={1}
                              space={true}
                            />
                          </td>
                          <td className="stockreciveform__tableitem font_size">
                            <Weight
                              weight={pallet?.weight ?? 0}
                              unit={pallet?.unit === "imperial" ? "lb" : "kg"}
                              space={true}
                            />
                          </td>

                          <td className="stockreciveform__tableitem font_size">
                            {pallet?.product_quantity ?? "0"}
                          </td>
                          <td className="stockreciveform__tableitem font_size">
                            {pallet?.created_at?.slice(0, 10) ?? ""}
                          </td>
                          <td className="stockreciveform__tableitem font_size">
                            <Checkbox
                              onChange={(e) => handleChange(pallet)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan="11"
                        className="stockreciveform__tableitem font_size"
                        style={{
                          textAlign: "center",
                          height: "100px",
                          borderTop: "0.5px solid lightgray",
                          marginTop: "20px",
                        }}
                      >
                        No Pallets Available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className=" history__product_footer">
            <div className="history__product_footer_btn" onClick={handleClose}>
              x
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
