import React, { useState, useEffect } from "react";
import "./Authentication.css";
import Logo from "../../assets/images/logo_login.png";
import bg from "../../assets/images/logistic.png";
import bgTab from "../../assets/images/login_tab_bg.png";
import { ReactComponent as Envelop } from "../../assets/icons/envelop.svg";
import { ReactComponent as Lock } from "../../assets/icons/lock.svg";
import { signinUser } from "../../services/auth.services";
import CustomTextField from "./Components/CustomTextField/CustomTextField";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
// import { FcGoogle } from "react-icons/fc";
import { useDispatch } from "react-redux";
import { addUser } from "../../store/userSlice";
import Snackbar from "../../Components/SnackBar/SnackBar";

const initialData = {
  email: "",
  password: "",
};

const BtnLoader = () => {
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

const AdminLoginForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialData);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);

  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  const dispatch = useDispatch();

  function onChange(value) {
    setVerified(value);
  }

  const handleLogin = async () => {
    try {
      setLoading(true);
      const res = await signinUser({
        ...formData,
        role: "Admin",
      });
      if (res.error) {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: res?.error?.response?.data?.message ?? "Something went wrong",
            severity: "error",
          };
        });
        setLoading(false);
      } else {
        let user = {
          ...res?.data?.result?.user,
          token: res?.data?.result?.token,
        };
        localStorage.setItem("user", JSON.stringify(user));
        dispatch(addUser(user));
        localStorage.setItem("Token", res?.data?.result?.token);
        localStorage.setItem("Id", res?.data?.result?.user?.id);
        localStorage.setItem("Name", res?.data?.result?.user?.username);
        localStorage.setItem("Email", res?.data?.result?.user?.email);
        localStorage.setItem("Role", res?.data?.result?.user?.role);
        localStorage.setItem("firstname", res?.data?.result?.user?.firstname);
        localStorage.setItem("lastname", res?.data?.result?.user?.lastname);
        localStorage.setItem(
          "profile_image",
          res?.data?.result?.user?.profile_image
        );
        localStorage.setItem("Balance", res?.data?.result?.user?.balance);

        if (res?.data?.result?.user?.role === "Admin") {
          navigate("/Users");
        }

        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: res?.data?.message,
            severity: "success",
          };
        });
        setLoading(false);
      }
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        text: error?.message || "Something went wrong!",
        severity: "error",
      });
      setLoading(false);
    }
  };
  const handleFieldChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if ([formData.email, formData.password].includes("") || !verified) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formData, verified]);

  return (
    <div
      className={"register__form_screen"}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <div className="loginpage__new_wrap">
        <div className="loginpage__new_form_shipper">
          <div className="shipper__tab_main_wrap">
            <div className="shipper__tab_left_col">
              <div className="shipper__tab_left_logo">
                <img
                  src={Logo}
                  alt=""
                  className="regform_sheader_image"
                  onClick={() => {
                    window.location.href = "https://www.hajexbolt.com";
                  }}
                />
              </div>
              <div className="shipper__tab_left_image">
                <img src={bgTab} alt="" />
              </div>
            </div>
            <div className="shipper__tab_right_col">
              <h3 className="loginpage__new_form_heading">Admin Login</h3>
              <div className="loginpage__new_form_field">
                <CustomTextField
                  icon={<Envelop />}
                  placeholder="Username / Email"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={(e) => handleFieldChange("email", e.target.value)}
                />
              </div>
              <div className="loginpage__new_form_field">
                <CustomTextField
                  icon={<Lock style={{ fontSize: "35px" }} />}
                  placeholder="Password"
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={(e) =>
                    handleFieldChange("password", e.target.value)
                  }
                />
              </div>
              <div className="forget__lin_wrap">
                <Link className="forgot__password_link" to="/get-code">
                  Forgot password?
                </Link>
              </div>
              <div className="recaptcha__checkbox_wrap">
                <ReCAPTCHA
                  sitekey="6Ld9BMcjAAAAAE-VKFPaecDHGEqeSKQdQ_GsZ-0W"
                  onChange={onChange}
                  size="normal"
                />
              </div>
              <button
                disabled={loading || disabled}
                className={`rfsregister__steps_nextbtn login__page_login_btn ${
                  (loading || disabled) && "rfsregister__steps_nextbtndisabled"
                }`}
                onClick={() => {
                  handleLogin();
                }}
              >
                {loading ? <BtnLoader /> : "Login"}
              </button>
              {/* <div
                className={`rfsregister__steps_googlebtn login__page_google_btn`}
              >
                <FcGoogle size={21} /> &nbsp; Login with Google
              </div> */}
              {/* <div className="register__now_link">
                Don't have an account?
                <span
                  onClick={() => {
                    let destination = "/Register";
                    navigate(destination);
                  }}
                >
                  Register.
                </span>
              </div> */}
              <br />
              <div
                className="loginpage__new_form_back_btn"
                onClick={() => {
                  navigate(`/Login`);
                  setFormData({ ...initialData });
                  setVerified(false);
                }}
              >
                Back
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLoginForm;
