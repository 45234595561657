import React, { useEffect, useState } from "react";
import "./WarehouseFormula.css";

// components imports
import Confirm from "../../../../../Components/Popups/Confirm";
import Loader from "../../../../../Components/CircularLoader/CircularLoader";
import CarrierInnerTableLoader from "../../../../../Components/CarrierInnerTableLoader/CarrierInnerTableLoader";
// apis imports
import {
  createUserPaymentFormula,
  deleteUserPaymentFormula,
  updateUserPaymentFormula,
  getWarehousePaymentFormula,
} from "../../../../../services/AdminServices/PaymentsAdmin/payments.services";
// library imports
import TextFeild from "../../Components/CustomTextFeild/TextFeild";
import Form from "react-bootstrap/Form";
import { MdClose } from "react-icons/md";
import { MdAdd } from "react-icons/md";
import Sad from "@mui/icons-material/SentimentVeryDissatisfied";
import AddDetail from "./AddDetail";
import IconButton from "@mui/material/IconButton";
import { FaRegTimesCircle } from "react-icons/fa";

const WarehouseFormula = ({ setSnack, user, setOpen, updateIndex }) => {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  //Admin formula
  const [lastDetailedIndexData, setLastDetailedIndexData] = useState(null);

  const [confirmDelete, setConfirmDelete] = useState();
  const [isEditable, setIsEditable] = useState(false);
  const [userDefaultFormula, setUserDefaultFormula] = useState(null);
  const [formulaDetails, setFormulaDetails] = useState({
    min: "",
    max: "",
    value: "",
  });
  const [paymentFormula, setPaymentFormula] = useState(null);
  const [error, setError] = useState(false);

  const addMore = (key, val) => {
    setPaymentFormula({
      ...paymentFormula,
      [key]: [...paymentFormula[key], val],
    });
  };

  const handleRemove = (key, id, removedKey, index) => {
    setPaymentFormula((prevState) => ({
      ...prevState,
      [key]: paymentFormula[key].filter((_, i) => i !== index),
      [removedKey]: id
        ? [...paymentFormula[removedKey], id]
        : paymentFormula[removedKey],
    }));
  };

  const handleFieldChange = (key, name, e, index) => {
    const newState = { ...paymentFormula };
    newState[key][index][name] = e.target.value;
    setPaymentFormula(newState);
  };
  const createFormula = () => {
    setIsEditable(true);
    setPaymentFormula({
      type: "for_warehouse",
      formula_details: [
        {
          min: "",
          max: "",
          value: "",
        },
      ],
      formula_taxes: [],
      deletedTaxesIds: [],
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();

    setLoading2(true);
    let response = await createUserPaymentFormula(
      {
        type: paymentFormula.type,
        formulaDetails: paymentFormula?.formula_details,
        formulaTaxes: paymentFormula?.formula_taxes,
      },
      user?.user?.id
    );
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.error?.response?.data?.message,
          severity: "error",
        };
      });
      setLoading2(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.data?.message,
          severity: "success",
        };
      });
      setIsEditable(false);
      setPaymentFormula({
        ...response?.data?.result,
        deletedTaxesIds: [],
      });
      if (user?.user?.formula_with_data != null) {
        setUserDefaultFormula({
          ...response?.data?.result,
          deletedTaxesIds: [],
        });
      }
      if (user.user) {
        updateIndex(user?.id, {
          ...response?.data?.result,
          deletedTaxesIds: [],
        });
      }
    }
    setLoading2(false);
  };
  const handleDeleteFormula = async (e) => {
    setLoading2(true);
    let response = await deleteUserPaymentFormula(paymentFormula?.id);
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.error?.response?.data?.message,
          severity: "error",
        };
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.data?.message,
          severity: "success",
        };
      });
      if (user.user) {
        setConfirmDelete();
        setPaymentFormula(userDefaultFormula);
        updateIndex(user.id, null);
      }
    }
    setLoading2(false);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (paymentFormula?.formula_details?.length === 0) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please add atleast 1 formula detail",
          severity: "error",
        };
      });
      return;
    }
    setLoading2(true);
    let response = await updateUserPaymentFormula(
      {
        type: paymentFormula.type,
        formulaDetails: paymentFormula?.formula_details,
        formulaTaxes: paymentFormula?.formula_taxes,
        deletedTaxesIds: paymentFormula?.deletedTaxesIds,
      },
      paymentFormula?.id
    );
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.error?.response?.data?.message,
          severity: "error",
        };
      });
      setLoading2(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response?.data?.message,
          severity: "success",
        };
      });
      setPaymentFormula({
        ...paymentFormula,
        deletedTaxesIds: [],
      });
      setIsEditable(false);
      if (user.user) {
        updateIndex(user?.id, response?.data?.result);
      }
    }
    setFormulaDetails({
      ...formulaDetails,
      deletedTaxesIds: [],
    });
    setLoading2(false);
  };
  const handleClose = () => {
    if (user.user) {
      if (user.user.formula_with_data === null) {
        setPaymentFormula(userDefaultFormula);
        setIsEditable(false);
      } else {
        setIsEditable(false);
        setPaymentFormula({
          ...user.user.formula_with_data,
          deletedTaxesIds: [],
        });
      }
    } else {
      setPaymentFormula(userDefaultFormula);
      setIsEditable(false);
    }
  };

  const getUserPaymentFormulaData = async (setOnlyDefault) => {
    setLoading(true);
    let response = await getWarehousePaymentFormula();
    let result =
      response?.data?.result != null
        ? {
            ...response?.data.result,
            deletedTaxesIds: [],
          }
        : response?.data?.result;
    setFormulaDetails(result);
    if (!setOnlyDefault) {
      setPaymentFormula(result);
    }
    setUserDefaultFormula(result);
    if (response) {
      setLoading(false);
    }
  };

  const addDetail = (e) => {
    e.preventDefault();
    if (formulaDetails?.min === "" || formulaDetails?.max === "") {
      setError("Please fill all feilds");
      return;
    }
    if (
      Number(formulaDetails?.min) <
      Number(
        paymentFormula?.formula_details[
          paymentFormula?.formula_details?.length - 1
        ]?.max
      )
    ) {
      setError("min must be grateer then last max");
      return;
    }
    if (Number(formulaDetails.max) <= Number(formulaDetails.min)) {
      setError("max must be grater then min");
      return;
    }
    let value;

    if (lastDetailedIndexData != null) {
      if ("formula_details" === "formula_details") {
        value = {
          ...formulaDetails,
          value: "10",
          id: lastDetailedIndexData.id,
          formula_id: lastDetailedIndexData.formula_id,
        };
      }
      setLastDetailedIndexData(null);
    } else {
      if ("formula_details" === "formula_details") {
        value = { ...formulaDetails, value: "10" };
      }
    }
    setPaymentFormula({
      ...paymentFormula,
      ["formula_details"]: [...paymentFormula["formula_details"], value],
    });
  };

  const handleChangeDetails = (e) => {
    setError(false);
    setFormulaDetails({ ...formulaDetails, [e.target.name]: e.target.value });
    handleFieldChange("formula_details", "value", e, 0);
  };
  useEffect(() => {
    if (user.user) {
      if (user?.user?.formula_with_data != null) {
        getUserPaymentFormulaData(true);
        setLoading(true);
        setPaymentFormula({
          ...user?.user?.formula_with_data,
          deletedTaxesIds: [],
        });
        const intervalId = setInterval(() => {
          // Update the count every second
          setLoading(false);
        }, 1000);
        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
      } else {
        getUserPaymentFormulaData(false);
      }
    } else {
      getUserPaymentFormulaData(false);
    }
  }, []);
  
  return (
    <>
      {loading ? (
        <div style={{ position: "relative", minHeight: "300px" }}>
          <div className="custom__table_tableloading">
            <CarrierInnerTableLoader />
          </div>
        </div>
      ) : (
        <>
          {user.user && (
            <div style={{ position: "relative" }}>
              <div className="admnedit__user_modalheading">
                {paymentFormula === null ||
                paymentFormula?.is_default === "for_warehouse_default"
                  ? "Default Formula"
                  : "Custom Formula"}
              </div>
              <div
                className="aduser__details_modalclose"
                onClick={() => setOpen(false)}
              >
                <IconButton>
                  <FaRegTimesCircle size={25} color="lightgrey" />
                </IconButton>
              </div>
            </div>
          )}
          {!user.user && (
            <div className="admnedit__user_modalheading">
              {user?.formula_with_data ? "Custom Formula" : "Default Formula"}
            </div>
          )}
          <br />
          {user.user && (
            <div className="warehouse_payment__formula_btn_wrapper">
              {(paymentFormula?.is_default === "for_warehouse_default" ||
                paymentFormula === null) && (
                <div>
                  <button
                    className="warehouse_payment__formula_btn"
                    onClick={() => createFormula()}
                  >
                    <MdAdd
                      className="warehouse_payment__formula_btnicon"
                      size={18}
                    />
                    CREATE CUSTOM FORMULA
                  </button>
                </div>
              )}
              {isEditable === false &&
                paymentFormula?.is_default === "false" && (
                  <div>
                    <button
                      className="warehouse_payment__formula_btn"
                      onClick={() => setIsEditable(true)}
                    >
                      <MdAdd
                        className="warehouse_payment__formula_btnicon"
                        size={18}
                      />
                      UPDATE FORMULA
                    </button>
                  </div>
                )}
            </div>
          )}
          {!user.user && (
            <div className="warehouse_payment__formula_btn_wrapper">
              {paymentFormula == null && (
                <div>
                  <button
                    className="warehouse_payment__formula_btn"
                    onClick={() => createFormula()}
                  >
                    <MdAdd
                      className="warehouse_payment__formula_btnicon"
                      size={18}
                    />
                    CREATE FORMULA
                  </button>
                </div>
              )}

              {isEditable === false && paymentFormula != null && (
                <div>
                  <button
                    className="warehouse_payment__formula_btn"
                    onClick={() => setIsEditable(true)}
                  >
                    <MdAdd
                      className="warehouse_payment__formula_btnicon"
                      size={18}
                    />
                    UPDATE FORMULA
                  </button>
                </div>
              )}
            </div>
          )}
          {paymentFormula === null && (
            <div className="driver__screen_empty" style={{ width: "auto" }}>
              <div className="driver__scrnempt_content">
                <Sad className="driver__scrnempt_contenticon" />
                <div>No Default Formula available to display!</div>
              </div>
            </div>
          )}
          {paymentFormula != null && (
            <>
              <Form onSubmit={paymentFormula?.id ? handleUpdate : handleSave}>
                {paymentFormula != null && (
                  <div className="warehouse_payment__formula_box_wrapper">
                    <div className="warehouse_payment__formula_formbox1">
                      {paymentFormula != null && (
                        <h5 className="warehouse_payment__formula_formboxheading">
                          <div>Formula Details</div>
                        </h5>
                      )}
                      <AddDetail
                        addDetail={addDetail}
                        isEditable={isEditable}
                        formulaDetails={formulaDetails}
                        paymentFormula={paymentFormula}
                        handleChangeDetails={handleChangeDetails}
                        error={error}
                      />
                    </div>
                    <div className="warehouse_payment__formula_formbox2">
                      {paymentFormula != null && (
                        <h5 className="warehouse_payment__formula_formboxheading">
                          <div>Formula Taxes</div>
                        </h5>
                      )}
                      {paymentFormula?.formula_taxes?.map((tax, index) => (
                        <div
                          className="warehouse_warehouse_payment__formula_formrow"
                          key={index}
                        >
                          <div className="warehouse_payment__formula_formcol">
                            <TextFeild
                              Label="Tax Name"
                              Data={tax.name}
                              Type="text"
                              Disabled={isEditable ? false : true}
                              Adroment={""}
                              Handler={(e) =>
                                handleFieldChange(
                                  "formula_taxes",
                                  "name",
                                  e,
                                  index
                                )
                              }
                            />
                          </div>
                          <div className="warehouse_payment__formula_formcol">
                            <TextFeild
                              Label="Tax Value"
                              Data={tax.value}
                              Type="number"
                              Disabled={isEditable ? false : true}
                              Adroment={"%"}
                              Handler={(e) =>
                                handleFieldChange(
                                  "formula_taxes",
                                  "value",
                                  e,
                                  index
                                )
                              }
                            />
                          </div>
                          {isEditable && (
                            <div className="addprod__seccancel_btnwrap">
                              <div
                                className="warehouse_payment__formula_cancel_btn"
                                onClick={(e) => {
                                  handleRemove(
                                    "formula_taxes",
                                    tax?.id,
                                    "deletedTaxesIds",
                                    index
                                  );
                                }}
                              >
                                <MdClose size={22} />
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                      {isEditable === true && (
                        <div className="addprod__section_formrow">
                          <div className="warehouse_payment__formula_formcol">
                            <div
                              className="warehouse_payment__formula_addmore_btn"
                              onClick={() =>
                                addMore("formula_taxes", {
                                  name: "",
                                  value: "",
                                })
                              }
                            >
                              <MdAdd
                                className="warehouse_payment__formula_addmore_btnicon"
                                size={18}
                              />
                              ADD MORE TAXES
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {isEditable === true && (
                  <div className="warehouse_prodidentifier__desc_modalfooter">
                    <button
                      className="warehouse_prodidentifier__desc_modalcncl"
                      onClick={(e) => {
                        e.preventDefault();
                        handleClose();
                      }}
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      className="warehouse_prodidentifier__desc_modalsave"
                    >
                      {loading2 ? <Loader /> : "Save"}
                    </button>
                  </div>
                )}
                {paymentFormula?.is_default === "false" &&
                  isEditable === false && (
                    <div className="warehouse_prodidentifier__desc_modalfooter">
                      <button
                        style={{ width: "fit-content", padding: "0px 10px" }}
                        className="warehouse_prodidentifier__desc_modalsave"
                        onClick={(e) => {
                          e.preventDefault();
                          setConfirmDelete(paymentFormula);
                        }}
                      >
                        Set Back To Default
                      </button>
                    </div>
                  )}
                <Confirm
                  open={confirmDelete}
                  setOpen={setConfirmDelete}
                  handleConfirm={handleDeleteFormula}
                  text={`Do you want to switch to ${
                    paymentFormula?.is_default != "for_warehouse_default"
                      ? "Default Formula"
                      : "Custom Formula"
                  }  formula?`}
                  loader={loading2}
                />
              </Form>
            </>
          )}
        </>
      )}
    </>
  );
};

export default WarehouseFormula;
