import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import { TotalSpaceColumn } from "../Product/ProductService";
import SwitchButton from "../../../Components/SwitchButton/SwitchButton";
import Button from "@mui/material/Button";
import {
  updateWarehouseProductsDetails,
  fetchAllAssignedPallets,
  assignProductTOPallet,
  fetchAllProductLot,
} from "../../../services/product.service";
import AddPallet from "./AddPallet";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import Tooltip from "../../../Components/Tooltip/Tooltip";
import Loader from "../../../Components/CircularLoader/CircularLoader";
import AddExistingPallet from "./AddExistingPallet";
import "./StorageBreakdown.css";
import { RxCross1 } from "react-icons/rx";
import LotDropdown from "./LotDropdown";

export default function StorageBreakdownModal({
  open,
  setOpen,
  setSnack,
  setProducts,
}) {
  const handleClose = () => {
    setLoading(false);
    setPallets([]);
    setOpen(false);
  };
  const [loading, setLoading] = useState(false);
  const [palletAddModal, setPalletAddModal] = useState(false);
  const [palletExistingModal, setPalletExistingModal] = useState(false);
  const [pallets, setPallets] = useState([]);
  const [lots, setLots] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const UnAssignedProducts = () => {
    let totalQuantity = 0;
    if (pallets?.length > 0) {
      pallets?.forEach((pallet) => {
        const quantity = parseInt(pallet?.quantity ?? 0);
        totalQuantity += quantity;
      });
    }
    return (
      parseInt(open?.product_warehouse?.[0]?.["quantity"] ?? 0) - totalQuantity
    );
  };

  const handlePalletized = async (e) => {
    try {
      setLoading("switch");
      const response = await updateWarehouseProductsDetails(
        {
          palletise: e.target.checked,
        },
        open?.product_warehouse?.[0]?.id
      );
      if (!response.error) {
        setProducts((prevProducts) => {
          return {
            ...prevProducts,
            data: prevProducts?.data?.map((product) => {
              if (product.id == open?.product_warehouse[0]?.product_id) {
                return {
                  ...product,
                  product_warehouse: [
                    {
                      ...product?.product_warehouse[0],
                      palletise: response?.data?.result?.palletise,
                    },
                  ],
                };
              }
              return product;
            }),
          };
        });
        setOpen((prevOpen) => {
          return {
            ...prevOpen,
            product_warehouse: prevOpen?.product_warehouse?.map((item, index) =>
              index === 0
                ? { ...item, palletise: response?.data?.result?.palletise }
                : item
            ),
          };
        });
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: response?.data?.message,
            severity: "success",
          };
        });
        setLoading(false);
      } else {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: response?.data?.message ?? "Something went wrong",
            severity: "error",
          };
        });
        setLoading(false);
      }
    } catch (error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: error?.response?.data?.message || "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const getAssignedPallets = async (id) => {
    const response = await fetchAllAssignedPallets(id);
    if (!response.error) {
      setPallets(response?.data?.result);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getAssignedPallets(open?.product_warehouse?.[0]?.id);
    }
  }, [open, refresh]);

  useEffect(() => {
    if (open) {
      getProductLot(open?.id);
    }
  }, [open]);

  const updatePalletQuantity = (index, newQuantity) => {
    const updatedPallets = [...pallets];
    updatedPallets[index] = {
      ...updatedPallets[index],
      quantity: parseInt(newQuantity),
    };
    setPallets(updatedPallets);
  };

  const handleChange = (e, index) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value)) {
      updatePalletQuantity(index, value);
    }
  };

  const handleDecrement = (index) => {
    const currentQuantity = pallets[index]?.quantity || 0;
    updatePalletQuantity(index, parseInt(currentQuantity) - 1);
  };

  const handleIncrement = (index) => {
    const currentQuantity = pallets[index]?.quantity || 0;
    updatePalletQuantity(index, parseInt(currentQuantity) + 1);
  };

  const handleSave = async () => {
    const newPallets = pallets.map((pallet) => {
      const { id, pallet_id, quantity } = pallet;
      return {
        ...(id && { id }),
        pallet_id,
        product_warehouse_id: open?.product_warehouse?.[0]?.id,
        quantity,
        product_lot_id: pallet?.product_lot?.id ?? "",
      };
    });
    const payload = {
      data: newPallets,
      deletedPalletIds: [],
    };
    try {
      setLoading("save");
      const response = await assignProductTOPallet(payload);
      if (!response.error) {
        setRefresh(Math.floor(Math.random() * 100) + 1);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: response?.data?.message,
            severity: "success",
          };
        });
        // setLoading(false);
      } else {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: response?.data?.message ?? "Something went wrong",
            severity: "error",
          };
        });
        setLoading(false);
      }
    } catch (error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: error?.response?.data?.message || "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const getProductLot = async (id) => {
    const response = await fetchAllProductLot(id);
    if (!response.error) {
      setLots(response?.data?.result);
    }
  };

  return (
    <div className="storagBrreakDown__modal_wrap">
      <AddPallet
        open={palletAddModal}
        setOpen={setPalletAddModal}
        setSnack={setSnack}
        setPallets={setPallets}
      />
      <AddExistingPallet
        open={palletExistingModal}
        setOpen={setPalletExistingModal}
        setData={setPallets}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="min-h-[250px] max-w-[1060px] w-[95%] bg-[white] absolute -translate-x-2/4 -translate-y-2/4 shadow-[24px] rounded-[5px] border-[none] left-2/4 top-2/4 storagBrreakDown__modal_container">
          <div className="p-[15px] bg-[#e3e3e3] rounded-t-[5px]">
            <div className="storagBrreakDown__modal_main_heading">
              Storage Breakdown
            </div>
            <div className="storagBrreakDown__modal_sub_heading">
              {open?.full_name ?? ""}({open?.code ?? ""})
            </div>
          </div>
          <div className="prdtable__hist_modalcontent history__product_content p-3">
            <div className="stockreciveform__table_header">
              <div className="stockreciveform__table_wrapper">
                <div className="stockreciveform__table_qty_col">
                  <b>Product Quantity:</b>{" "}
                  {open?.product_warehouse?.[0]?.["quantity"] ?? 0}
                </div>
                <div className="stockreciveform__table_qty_col">
                  <b>Pallet Count:</b>{" "}
                  {open?.product_warehouse?.[0]?.product_pallets_count ?? 0}
                </div>
                <div className="stockreciveform__table_vol_col">
                  <b>Volume:</b>&nbsp;{" "}
                  <TotalSpaceColumn
                    length={open?.length ?? 0}
                    width={open?.width ?? 0}
                    height={open?.height ?? 0}
                    unit={open?.dimension_unit === "in" ? "inch" : "cm"}
                    quantity={open?.product_warehouse?.[0]?.["quantity"] ?? 0}
                  />
                </div>
                <div className="stockreciveform__table_palletized_col">
                  <b>Enable Sorting:</b>
                  &nbsp;{" "}
                  <SwitchButton
                    onChange={(e) => handlePalletized(e)}
                    checked={
                      open?.product_warehouse?.[0]?.palletise ? true : false
                    }
                    disabled={loading === "switch" ? true : false}
                  />
                </div>
              </div>
            </div>
            {open?.product_warehouse?.[0]?.palletise ? (
              <div className="stockreciveform__table_content_wrap">
                <div className="stockreciveform__table_content_container">
                  <div className="stockreciveform__table_content_header_wrap">
                    <div className="stockreciveform__table_content_header_title">
                      Pallets / Skids / Bins
                    </div>
                    <div className="stockreciveform__table_content_header_btn_col">
                      <Button
                        onClick={() => setPalletExistingModal(open?.shipper_id)}
                      >
                        + Add Existing
                      </Button>
                      <Button
                        onClick={() => setPalletAddModal(open?.shipper_id)}
                      >
                        + Add New
                      </Button>
                    </div>
                  </div>
                  <table className="stockreciveform__table stockreciveform__detail_table">
                    <thead>
                      <th
                        className="stockreciveform__tablehd"
                        style={{ padding: "0px 2px" }}
                      >
                        #
                      </th>
                      <th
                        className="stockreciveform__tablehd"
                        style={{ padding: "0px 2px" }}
                      >
                        Code
                      </th>
                      <th
                        className="stockreciveform__tablehd"
                        style={{ padding: "0px 2px" }}
                      >
                        Name
                      </th>
                      <th
                        className="stockreciveform__tablehd"
                        style={{ padding: "0px 2px" }}
                      >
                        Lot / Batch
                      </th>
                      <th
                        className="stockreciveform__tablehd"
                        style={{ padding: "0px 2px" }}
                      >
                        Quantity
                      </th>
                      <th
                        className="stockreciveform__tablehd"
                        style={{ padding: "0px 2px" }}
                      >
                        Location
                      </th>
                      <th
                        className="stockreciveform__tablehd"
                        style={{ padding: "0px 2px" }}
                      >
                        Pallet Volume
                      </th>
                      <th
                        className="stockreciveform__tablehd"
                        style={{ padding: "0px 2px" }}
                      >
                        Product Volume
                      </th>
                      <th
                        className="stockreciveform__tablehd"
                        style={{ padding: "0px 2px" }}
                      >
                        Created At
                      </th>
                    </thead>
                    <tbody>
                      {pallets?.length > 0 ? (
                        pallets?.map((pallet, index) => {
                          return (
                            <tr key={index}>
                              <td className="stockreciveform__tableitem font_size">
                                {pallet?.id ?? "New"}
                              </td>
                              <td className="stockreciveform__tableitem font_size">
                                {pallet?.pallet?.code ?? "-"}
                              </td>
                              <td className="stockreciveform__tableitem font_size">
                                {pallet?.pallet?.name ?? "-"}
                              </td>
                              <td
                                className="stockreciveform__tableitem font_size"
                                style={{ width: "100px" }}
                              >
                                <LotDropdown
                                  value={pallet?.product_lot}
                                  open={open}
                                  rowId={index}
                                  pallets={pallets}
                                  setPallets={setPallets}
                                  setSnack={setSnack}
                                  lots={lots}
                                  setLots={setLots}
                                />
                              </td>
                              <td className="stockreciveform__tableitem font_size">
                                <div className="flex justify-center items-center">
                                  {/* Decrement Button */}
                                  <Tooltip
                                    title="Remove Quantity"
                                    placement="top"
                                  >
                                    <button
                                      onClick={() => handleDecrement(index)}
                                      className="p-1 border-2 border-[#E2EDFB] rounded-md"
                                    >
                                      <IoIosRemove size={20} />
                                    </button>
                                  </Tooltip>

                                  {/* Quantity Input */}
                                  <input
                                    type="number"
                                    value={pallet?.quantity}
                                    name="quantity"
                                    onChange={(e) => handleChange(e, index)}
                                    className="text-center border border-[#E2EDFB] p-1 mx-2 w-20"
                                    onWheel={(e) => e.target.blur()} // Disable scroll increment
                                  />

                                  {/* Hide number input arrows */}
                                  <style>
                                    {`
                                      input[type="number"]::-webkit-outer-spin-button,
                                      input[type="number"]::-webkit-inner-spin-button {
                                        -webkit-appearance: none;
                                        margin: 0;
                                      }
                                    `}
                                  </style>

                                  {/* Increment Button */}
                                  <Tooltip title="Add Quantity" placement="top">
                                    <button
                                      onClick={() => handleIncrement(index)}
                                      className="p-1 border-2 border-[#E2EDFB] rounded-md"
                                    >
                                      <IoIosAdd size={20} />
                                    </button>
                                  </Tooltip>
                                </div>
                              </td>
                              <td className="stockreciveform__tableitem font_size">
                                {pallet?.pallet?.location?.name ?? "-"}
                              </td>
                              <td className="stockreciveform__tableitem font_size">
                                <TotalSpaceColumn
                                  length={pallet?.pallet?.length ?? 0}
                                  width={pallet?.pallet?.width ?? 0}
                                  height={pallet?.pallet?.height ?? 0}
                                  unit={
                                    pallet?.pallet?.unit === "imperial"
                                      ? "inch"
                                      : "cm"
                                  }
                                  quantity={1}
                                />
                              </td>
                              <td className="stockreciveform__tableitem font_size">
                                <TotalSpaceColumn
                                  length={open?.length ?? 0}
                                  width={open?.width ?? 0}
                                  height={open?.height ?? 0}
                                  unit={
                                    open?.dimension_unit === "in"
                                      ? "inch"
                                      : "cm"
                                  }
                                  quantity={pallet?.quantity ?? 0}
                                />
                              </td>
                              <td className="stockreciveform__tableitem font_size">
                                {pallet?.created_at?.slice(0, 10) ?? "Just Now"}
                              </td>{" "}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            colSpan="11"
                            className="stockreciveform__tableitem font_size"
                            style={{
                              textAlign: "center",
                              height: "100px",
                              borderTop: "0.5px solid lightgray",
                              marginTop: "20px",
                            }}
                          >
                            No Pallet Available
                          </td>
                        </tr>
                      )}
                      <tr key="raw_products">
                        <td className="stockreciveform__tableitem font_size"></td>
                        <td className="stockreciveform__tableitem font_size"></td>
                        <td className="stockreciveform__tableitem font_size">
                          Raw Products (Non Palletized)
                        </td>
                        <td className="stockreciveform__tableitem font_size"></td>
                        <td className="stockreciveform__tableitem font_size">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {UnAssignedProducts()}
                          </div>
                        </td>
                        <td className="stockreciveform__tableitem font_size"></td>
                        <td className="stockreciveform__tableitem font_size"></td>
                        <td className="stockreciveform__tableitem font_size">
                          <TotalSpaceColumn
                            length={open?.length ?? 0}
                            width={open?.width ?? 0}
                            height={open?.height ?? 0}
                            unit={open?.dimension_unit === "in" ? "inch" : "cm"}
                            quantity={UnAssignedProducts()}
                          />
                        </td>
                        <td className="stockreciveform__tableitem font_size"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="stockreciveform__detail_table_footer">
                  <Button
                    variant="contained"
                    disabled={loading === "save" ? true : false}
                    style={{ marginTop: "5px", marginRight: "5px" }}
                    onClick={handleSave}
                  >
                    {loading === "save" ? <Loader /> : "Save"}
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
          <div className=" history__product_footer">
            <div
              className="w-full h-full flex justify-center items-center text-center bg-red-500 text-white font-[cursive] leading-none cursor-pointer"
              onClick={handleClose}
            >
              <RxCross1 />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
