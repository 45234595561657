import * as React from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import { BsUpcScan } from "react-icons/bs";

export default function SpeedDialComponent({ setBarcodeModal }) {
  return (
    <Box sx={{ position: "fixed", bottom: 16, right: 16, zIndex: 1 }}>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        icon={<BsUpcScan />}
        onClick={() => setBarcodeModal(true)}
      />
    </Box>
  );
}
