import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  fetchShippingCompanies,
  addShippingCompany,
} from "../../../services/orders.services";

export default function FreeSoloCreateOption({
  setValue,
  value,
  editMode,
  setSnack,
  disabled,
}) {
  const [companies, setComponies] = useState([]);

  const handleChange = async (event, newValue) => {
    if (newValue && newValue.inputValue) {
      const newCompany = { shipping_company_name: newValue.inputValue };
      const response = await addShippingCompany(newCompany);
      if (!response.error) {
        const updatedCompanies = [
          ...companies,
          {
            id: response?.data?.result?.id,
            name: response?.data?.result?.shipping_company_name,
            type: "manual",
          },
        ];
        setComponies(updatedCompanies);
        setValue((prevValue) => ({
          ...prevValue,
          OrderShipment: {
            ...prevValue?.OrderShipment,
            shipping_company: {
              id: response?.data?.result?.id,
              name: response?.data?.result?.shipping_company_name,
              type: "manual",
            },
          },
        }));
        setSnack((snack) => ({
          ...snack,
          open: true,
          text: `${newValue.inputValue} stored successfully`,
          severity: "success",
        }));
      } else {
        setSnack((snack) => ({
          ...snack,
          open: true,
          text: `${newValue.inputValue} failed to store`,
          severity: "error",
        }));
      }
    } else {
      setValue((prevValue) => ({
        ...prevValue,
        OrderShipment: {
          ...prevValue?.OrderShipment,
          shipping_company: newValue,
        },
      }));
    }
  };

  const shippingCompany = async () => {
    let response = await fetchShippingCompanies();
    if (!response.error) {
      setComponies(response?.data?.result);
    }
  };

  useEffect(() => {
    shippingCompany();
  }, []);

  return (
    <Autocomplete
      value={value?.OrderShipment?.shipping_company ?? null}
      onChange={handleChange}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="order_shipment_carrier"
      options={companies}
      getOptionLabel={(option) =>
        option.inputValue
          ? option.inputValue
          : option
          ? `${option.name} (${
              option.type == "default" ? "Trackable" : "Manual"
            })`
          : ""
      }
      renderOption={(props, option) => {
        return (
          <li key={option.id + option.type} {...props}>
            {option
              ? `${option.name} (${
                  option.type == "default" ? "Trackable" : "Manual"
                })`
              : ""}
          </li>
        );
      }}
      filterOptions={(options, params) => {
        const filtered = options.filter((option) =>
          option.name.toLowerCase().includes(params.inputValue.toLowerCase())
        );

        if (params.inputValue !== "") {
          filtered.push({
            inputValue: params.inputValue,
            name: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      freeSolo
      disabled={disabled}
      sx={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          variant="outlined"
          error={
            editMode &&
            !value?.OrderShipment?.shipping_company &&
            value?.manual_shipment_id
          }
        />
      )}
    />
  );
}
