import React, { useState } from "react";
import "./NewsLetter.css";

// components imports
import Unit from "../NewsLetter/Components/Unit/Unit";
import Currency from "../NewsLetter/Components/Currency/Currency";
import Subscribers from "./Components/Subscribers/Subscribers";
import LandingCompanyDetails from "./Components/LandingCompanyDetails/LandingCompanyDetails";
import Blogs from "./Components/Blogs/Blogs";
import Messages from "./Components/Messages/Messages";
import Snackbar from "../../../Components/SnackBar/SnackBar";

// apis imports
// library imports
import { useSelector } from "react-redux";
import TabsComponent from "../../../Components/Common/TabsComponent";

const NewsLetter = () => {
  const user = useSelector((state) => state.user);
  const [tab, setTab] = useState(0);

  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div className="newswrapper__wrapper">
      <Snackbar Snack={snack} SetSnack={setSnack} />

      <TabsComponent items={[
        {
          label: "Subscribers",
          style: { fontWeight: "bold" },
          value: 0,
        },
        { label: "Blogs", style: { fontWeight: "bold" }, value: 1 },
        { label: "Landing- CompanyDetails", style: { fontWeight: "bold" }, value: 2 },
        { label: "Messages", style: { fontWeight: "bold" }, value: 3 },
        { label: "Unit", style: { fontWeight: "bold" }, value: 4 },
        { label: "Currency", style: { fontWeight: "bold" }, value: 5 },
      ]} tab={tab} handleTabChange={handleTabChange} />
      {tab === 0 && <Subscribers setSnack={setSnack} />}
      {tab === 1 && <Blogs setSnack={setSnack} />}
      {tab === 2 && <LandingCompanyDetails setSnack={setSnack} />}
      {tab === 3 && <Messages setSnack={setSnack} />}
      {tab === 4 && <Unit setSnack={setSnack} />}
      {tab === 5 && <Currency setSnack={setSnack} />}
    </div>
  );
};

export default NewsLetter;
