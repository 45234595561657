import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

const filter = createFilterOptions();

export default function AsyncAutoComplete({
  setOptions,
  options,
  setValue,
  value,
  addNewOption,
  label = "",
}) {
  return (
    <Autocomplete
      sx={{ width: "100%" }}
      value={value}
      onChange={async (event, newValue) => {
        if (typeof newValue === "string") {
          // When a string is entered, newValue is the Title
          const selectedOption = options.find(
            (option) => option.title === newValue
          );
          setValue(selectedOption);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          const newOption = {
            title: newValue.inputValue,
          };

          // Add the new option to options
          addNewOption(newOption);

          // setOptions((prevCostType) => [...prevCostType, newOption]);
        } else {
          // When an existing option is selected, newValue is the selected option
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.title
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      //   clearOnBlur
      handleHomeEndKeys
      id="async_auto_complete"
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(props, option) => {
        return <li {...props}>{option.title}</li>;
      }}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size="small"
          sx={{ width: "100%" }}
        />
      )}
    />
  );
}
