import ListGroup from "react-bootstrap/ListGroup";
import Tooltips from "../../../Components/Tooltip/Tooltip";
import { GoPackage } from "react-icons/go";
import { IoInformationCircleOutline } from "react-icons/io5";
import React, { useState, useEffect } from "react";
import { setdata } from "../../../helpers/helpers";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import Sad from "@mui/icons-material/SentimentVeryDissatisfied";
import { fetchOnlyProducts } from "../../../services/product.service";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";

import noImage from "../../../assets/images/noImage.png";

export default function ProductScroller(props) {
  const {
    data,
    setData,
    SelectedProducts,
    setSelectedProducts,
    Warehouse,
    setWarehouse,
    editMode,
  } = props;

  const [page, setPage] = useState(1); // current page number
  const [Products, setProducts] = useState();
  const [totalPages, setTotalPages] = useState();
  const [search, setSearch] = useState("");
  const [isExpanded, setIsExpanded] = useState(true);

  const handleExpandToggle = () => {
    setIsExpanded((prevExpanded) => !prevExpanded);
  };

  const user = useSelector((state) => state.user);

  const handlePrevPage = () => {
    setPage((prevPage) => prevPage - 1);
  };

  // const Warehouse = "";

  const handleChange = (e) => {
    setSelectedProducts([]);
    setWarehouse(e.target.value);
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const getAllProducts = async () => {
    const response = await fetchOnlyProducts(page, 21, search);

    if (!response.error) {
      setTotalPages(
        Math.ceil(
          response?.data?.result?.total / response?.data?.result?.per_page
        )
      );
      setProducts(response?.data?.result?.data);
    }
  };

  const handlescrollpackage = (data, index) => {
    if (!Warehouse) {
      return;
    }
    const productId = data.id;

    const existingProductIndex = SelectedProducts.findIndex(
      (product) => product.id === productId
    );

    if (existingProductIndex !== -1) {
      const updatedProducts = [...SelectedProducts];
      updatedProducts[existingProductIndex].quantity += 1;
      setSelectedProducts(updatedProducts);
    } else {
      const newData = { ...data, quantity: 1 };
      setSelectedProducts([...SelectedProducts, newData]);
    }
    // handleClose();
  };

  useEffect(() => {
    getAllProducts();
  }, [page, search]);
  useEffect(() => {
    setData({ ...data, items: SelectedProducts });
  }, [SelectedProducts]);
  return (
    <div
      style={{
        width: "100%",
        boxShadow: "0 0 5px 4px rgba(235, 235, 235, 0.616)",
        padding: "10px 20px 30px 20px",
        borderRadius: "10px",
        marginTop: "20px",
      }}
    >
      <div className="sss__addressection_heading">
        Products
        <div className="expand_btn" onClick={handleExpandToggle}>
          {isExpanded ? (
            <>
              <Tooltips title="Collapse">
                <IconButton>
                  <MdOutlineKeyboardArrowUp size={25} />
                </IconButton>
              </Tooltips>
            </>
          ) : (
            <>
              <Tooltips title="Expand">
                <IconButton className="fullfilment__form_expand_btn">
                  <MdOutlineKeyboardArrowDown size={25} />
                </IconButton>
              </Tooltips>
            </>
          )}
        </div>
      </div>
      <div className="sss__addressection_inputrow">
        {" "}
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "200px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">
              Search Product
            </label>
            <input
              className="sss__addressection_inputfield"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search Product"
            />
            {/* <div className="sss__addressection_inputfielderror">
              <MdErrorOutline className="sss__addressection_inputfielderroricn" />
              Supplier Name is required
            </div> */}
          </div>
        </div>
        <div
          className="sss__addressection_inputcol sss__addressection_inputcol_warehouse"
          style={{ minWidth: "200px" }}
        >
          <div
            className={`sss__addressection_inputbox ${
              editMode && Warehouse
                ? "sss__addressection_inputfielddisabled"
                : ""
            }`}
          >
            <label className="sss__addressection_inputtitle">
              Select Warehouse
            </label>
            <FormControl fullWidth size="small" sx={{ marginTop: "5px" }}>
              <Select
                id="demo-simple-select"
                value={Warehouse}
                onChange={handleChange}
              >
                {user?.user_approved_warehouses?.map((warehouse, index) => (
                  <MenuItem value={warehouse?.warehouse_id} key={index}>
                    #{warehouse?.warehouse_id} : {warehouse?.warehouse?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormHelperText className="form__warhouse_select_dropdown_helper_text">
              {!Warehouse ? (
                <>
                  <Tooltips title="Select A Warehouse" placement="top">
                    <IconButton>
                      <IoInformationCircleOutline size={15} />
                    </IconButton>
                  </Tooltips>
                </>
              ) : (
                <>
                  <Tooltips
                    title="If you select different warehouse already selected Products and Packages will be removed."
                    placement="top"
                  >
                    <IconButton className="cation__main_title">
                      <IoInformationCircleOutline size={15} />
                    </IconButton>
                  </Tooltips>
                </>
              )}
            </FormHelperText>
            {/* <div className="sss__addressection_inputfielderror">
              <MdErrorOutline className="sss__addressection_inputfielderroricn" />
              Supplier Name is required
            </div> */}
          </div>
        </div>
        {/* <Box sx={{ minWidth: 250 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Warehouse</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={Warehouse}
              label="Warehouse"
              onChange={handleChange}
            >
              {user?.user_approved_warehouses?.map((warehouse, index) => (
                <MenuItem value={warehouse?.warehouse_id} key={index}>
                  #{warehouse?.warehouse_id} : {warehouse?.warehouse?.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {!Warehouse
                ? "Select A Warehouse"
                : "If you select different warehouse already selected Products and Packages will be removed."}
            </FormHelperText>
          </FormControl>
        </Box> */}
      </div>

      {totalPages > 0 ? (
        <div className="d-flex flex-row mt-2">
          <IconButton
            className="mr-1"
            disabled={page === 1}
            onClick={handlePrevPage}
          >
            <FaChevronLeft />
          </IconButton>
          <ListGroup
            horizontal
            className={`overflow-auto horiscl p-2 ${
              isExpanded ? "expanded" : ""
            }`}
            style={{
              maxHeight: "108px",
              cursor: "pointer",
              display: "-webkit-box",
              overflowX: "auto",
              overflowY: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              WebkitScrollbarTrack: {
                background: "#000000",
              },
            }}
          >
            {Products.map((data, index) => (
              <Tooltips
                title={Warehouse ? "Click To Add" : "Select Warehouse"}
                placement="top"
                key={index}
              >
                <ListGroup.Item
                  className={
                    Warehouse
                      ? "mr-2 mb-1 shadow single_list"
                      : "mr-2 mb-1 shadow single_list select_none_card"
                  }
                  style={{
                    width: "144px",
                    border: "1px solid #C8C9CA",
                    borderRadius: "4px",
                    padding: "2px",
                    flexShrink: 0,
                  }}
                  onClick={() => handlescrollpackage(data, index)}
                >
                  <div class="productList__card_wrap">
                    <div class="productList__card_title">
                      {setdata(() => data.name, false, "")}
                    </div>
                    <div class="productList__card_details_wrap">
                      <div class="productList__card_details_img">
                        {data.media ? (
                          <img
                            src={
                              data?.media.find(
                                (media) => media.is_primary === "true"
                              )?.file ||
                              data?.media[0]?.file ||
                              noImage
                            }
                            onError={(e) => {
                              e.target.src = noImage;
                            }}
                          />
                        ) : (
                          <GoPackage size={40} style={{ color: "#C8C9CA" }} />
                        )}
                      </div>
                      <div class="productList__card_details_measures">
                        <div class="productList__card_details_measures_LWH">
                          {data?.product_warehouse?.length > 0 && (
                            <>
                              {data?.product_warehouse?.map((data, index) => {
                                return (
                                  <div
                                    class="productList__card_details_measures_L"
                                    style={{
                                      fontWeight:
                                        Warehouse === data?.warehouse_id
                                          ? "bold"
                                          : "",
                                    }}
                                  >
                                    #{data.warehouse_id}
                                    <span>{data?.quantity}</span>
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </ListGroup.Item>
              </Tooltips>
            ))}
          </ListGroup>
          <IconButton
            className="mr-1"
            disabled={page === totalPages || totalPages === 0}
            onClick={handleNextPage}
          >
            <FaChevronRight />
          </IconButton>
        </div>
      ) : (
        <div
          className="driver__screen_empty"
          style={{ minHeight: "12vh", width: "100%" }}
        >
          <div className="driver__scrnempt_content">
            <Sad className="driver__scrnempt_contenticon" />
            <div>No Products to display!</div>
          </div>
        </div>
      )}
    </div>
  );
}
