import React from "react";
import "./DateBadge.css";
const DateBadge = ({ month, day }) => {
  return (
    <div className="date__badge_component">
      <div className="date__badge_componentdot1"></div>
      <div className="date__badge_componentdot2"></div>
      <div className="date__badge_componentdot3"></div>
      <div className="date__badge_componentdot4"></div>
      <div className="date__badge_componentdot5"></div>
      <div className="date__badge_componentdot6"></div>
      <div className="date__badge_componentheader">
        <div className="date__badge_componenthiconlft"></div>
        <div className="date__badge_componenthiconrt"></div>
        <div className="date__badge_componenthtxt">{month}</div>
      </div>
      <div className="date__badge_componentbody">{day}</div>
    </div>
  );
};

export default DateBadge;
