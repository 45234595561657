import React, { useState, useEffect } from "react";
import "./Forms.css";
const WalmartForm = ({ marketplace, setFinalPayload }) => {
  const [payload, setPayload] = useState({
    account_name: "",
    market: "walmart",
    consumer_key: "",
    consumer_secret: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };
  useEffect(() => {
    setFinalPayload(payload);
  }, [payload]);
  return (
    <div className="addmarketplace__form_wrap">
      <div className="addmarketplace__form_logo">
        <img src={marketplace?.logo_url} />
      </div>
      <div className="addmarketplace__form_content">
        <div className="addmarketplace__fcntnt_fieldwrap">
          <label className="addmarketplace__fcntnt_fieldlbl">
            Account Name*
          </label>
          <input
            type="text"
            className="addmarketplace__fcntnt_field"
            name="account_name"
            onChange={handleChange}
            value={payload?.account_name ?? ""}
          />
        </div>
        <div className="addmarketplace__fcntnt_fieldwrap">
          <label className="addmarketplace__fcntnt_fieldlbl">
            Consumer Key*
          </label>
          <input
            type="text"
            className="addmarketplace__fcntnt_field"
            name="consumer_key"
            onChange={handleChange}
            value={payload?.consumer_key ?? ""}
          />
        </div>
        <div className="addmarketplace__fcntnt_fieldwrap">
          <label className="addmarketplace__fcntnt_fieldlbl">
            Consumer Secret*
          </label>
          <input
            type="text"
            className="addmarketplace__fcntnt_field"
            name="consumer_secret"
            onChange={handleChange}
            value={payload?.consumer_secret ?? ""}
          />
        </div>
      </div>
    </div>
  );
};

export default WalmartForm;
