import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { CiMenuKebab } from "react-icons/ci";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  MdOutlineStickyNote2,
  MdLabelImportantOutline,
  MdHistory,
  MdEditCalendar,
} from "react-icons/md";
import { FaShippingFast } from "react-icons/fa";
import { TbFileInvoice } from "react-icons/tb";

export default function CustomMenu({
  row,
  setdetailModal,
  setProdHistoryModal,
  setInvoiceModal,
  handleOrderShipemnt,
  printLabel,
  printDeclaration,
  labelDownloading,
  CircularProgress,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const printMultipleFiles = async (data, shipment) => {
    try {
      data?.forEach(async (label, index) => {
        const response = await fetch(label.file, {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
          },
        });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));

        // Extract file extension from the URL
        const fileExtension = label.file.split(".").pop();

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${shipment}_${label.type}_${index + 1}.${fileExtension}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    } catch (error) {
      console.error("Error downloading labels:", error);
      // Handle error here
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="table_action__wrap">
      <div className="table_action__btn" onClick={handleClick}>
        <CiMenuKebab size={10} style={{ marginRight: "2px" }} />
        More
      </div>
      <Menu
        id={`basic-menu-${row.id}`} // Unique ID for each row
        anchorEl={anchorEl}
        open={open}
        size="small"
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": `basic-button-${row.id}`, // Unique ID for each row
          "box-shadow": "none",
        }}
      >
        {row?.shipment &&
          row?.shipment?.shipment_status?.toLowerCase() !== "cancelled" && [
            <MenuItem
              className="boltTable__action_more_btn_label"
              key="invoice"
              onClick={() => {
                setInvoiceModal(row?.shipment?.id);
                handleClose();
              }}
            >
              <ListItemIcon>
                <MdOutlineStickyNote2 size={15} />
              </ListItemIcon>
              <ListItemText>View Shipment</ListItemText>
            </MenuItem>,
            <MenuItem
              className="boltTable__action_more_btn_label"
              key="label"
              onClick={() => {
                printLabel(row);
                handleClose();
              }}
            >
              <ListItemIcon>
                {labelDownloading === "label" + row?.id ? (
                  <CircularProgress size={15} color="grey" />
                ) : (
                  <>
                    <MdLabelImportantOutline size={15} />
                  </>
                )}
              </ListItemIcon>
              <ListItemText>Label</ListItemText>
            </MenuItem>,
            row?.shipment?.single_shipping_invoice && (
              <MenuItem
                className="boltTable__action_more_btn_label"
                key="declaration"
                onClick={() => {
                  printDeclaration(row);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  {labelDownloading === "Declaration" + row?.id ? (
                    <CircularProgress size={15} />
                  ) : (
                    <>
                      <TbFileInvoice size={15} />
                    </>
                  )}
                </ListItemIcon>
                <ListItemText>Declaration</ListItemText>
              </MenuItem>
            ),
          ]}
        {row?.manual_shipment?.shipping_labels?.length > 0 && [
          <MenuItem
            className="boltTable__action_more_btn_label"
            key="label"
            onClick={() => {
              printMultipleFiles(
                row?.manual_shipment?.shipping_labels,
                row?.manual_shipment?.shipment_tracking_id
              );
              handleClose();
            }}
          >
            <ListItemIcon>
              <MdLabelImportantOutline size={15} />
            </ListItemIcon>
            <ListItemText>Label</ListItemText>
          </MenuItem>,
        ]}
        {row?.manual_shipment?.shipping_declarations?.length > 0 && [
          <MenuItem
            className="boltTable__action_more_btn_label"
            key="declaration"
            onClick={() => {
              printMultipleFiles(
                row?.manual_shipment?.shipping_declarations,
                row?.manual_shipment?.shipment_tracking_id
              );
              handleClose();
            }}
          >
            <ListItemIcon>
              <TbFileInvoice size={15} />
            </ListItemIcon>
            <ListItemText>Declaration</ListItemText>
          </MenuItem>,
        ]}
        {!row?.shipment && !row?.manual_shipment && (
          <MenuItem
            className="boltTable__action_more_btn_label"
            key="ship"
            onClick={() => {
              handleOrderShipemnt(row);
              handleClose();
            }}
          >
            <ListItemIcon>
              <FaShippingFast size={15} />
            </ListItemIcon>
            <ListItemText>Ship Order</ListItemText>
          </MenuItem>
        )}
        <MenuItem
          className="boltTable__action_more_btn_label"
          onClick={() => {
            setProdHistoryModal(row);
            handleClose();
          }}
        >
          <ListItemIcon>
            <MdHistory
              size={15}
              style={{
                headerColor: "#0C6EFD",
                cursor: "pointer",
              }}
            />
          </ListItemIcon>
          <ListItemText>View Notes</ListItemText>
        </MenuItem>
        <MenuItem
          className="boltTable__action_more_btn_label"
          onClick={() => {
            setdetailModal([row?.id, true]);
            handleClose();
          }}
        >
          <ListItemIcon>
            <MdEditCalendar size={15} />
          </ListItemIcon>
          <ListItemText>View Packing List</ListItemText>
        </MenuItem>
        <MenuItem
          className="boltTable__action_more_btn_label"
          onClick={() => {
            setdetailModal([row?.id, false]);
            handleClose();
          }}
        >
          <ListItemIcon>
            <MdEditCalendar size={15} />
          </ListItemIcon>
          <ListItemText>View Order</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
