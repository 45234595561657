import axios from "../../axiosInstance";

export const getWareHouseList = async () => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`get-all-warehouse`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const sendWareHouseRequest = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`userWarehouse/send-request/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const getUserWarehouses = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `warehouse/get-user-warehouses?page=${page}&perPage=${perPage}&filterValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const deleteWareHouseRequest = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`warehouse/delete-user-warehouse/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
