import * as React from "react";
import Alert from "@mui/material/Alert";
import FullPageInvoice from "../../../../../../Components/InvoiceModal/Components/FullPageInvoice";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import AssignPickup from "./AssignPickup/AssignPickup";

export default function FinalStep(props) {
  const { StepCheck, reset, SetStepCheck } = props;
  const [open, setOpen] = React.useState(true);
  const [pickup, setpickup] = React.useState(true);
  const Navigate = useNavigate();

  //   console.log(
  //     StepCheck["FinalData"]?.shipping_company?.shipping_company?.id !== 2
  //       ? "true"
  //       : "false"
  //   );
  //   console.log(open);

  function handleClick() {
    setpickup(true);
    // Navigate("/Pickup");
  }

  return (
    <>
      {StepCheck["ShipmentCheck"] === true && (
        <>
          {!StepCheck["FinalData"]?.PickupDate && (
            <>
              <Collapse in={open}>
                <Alert
                  variant="filled"
                  severity="info"
                  className="mt-3 mx-auto"
                  style={{ width: "75%" }}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  You have to arrange pickup for this shipment from Shipment
                  page!
                  <a
                    onClick={handleClick}
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                      //   color: "#fff",
                      //   backgroundColor: "#007bff",
                      //   padding: "0.5rem 1rem",
                      //   borderRadius: "0.25rem",
                      //   display: "inline-block",
                    }}
                  >
                    <b> Click Here </b>
                  </a>
                  to Arrange Now.
                </Alert>
              </Collapse>
            </>
          )}

          <FullPageInvoice data={StepCheck["FinalData"]} Reset={reset} />

          <AssignPickup
            open={pickup}
            setOpen={setpickup}
            StepCheck={StepCheck}
            SetStepCheck={SetStepCheck}
          />
        </>
      )}
    </>
  );
}
