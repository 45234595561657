import React, { useState } from "react";
import "./warehousedetails.css";
// components imports
// apis imports
// library imports
import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { BiUser } from "react-icons/bi";
import { FaRegTimesCircle } from "react-icons/fa";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  boxShadow: 24,
  p: 4,
  padding: "30px",
  backgroundColor: "white",
  maxHeight: "95vh",
  overflowY: "auto",
  "&::scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar": {
    width: "5",
  },
};
const WarehouseDetails = ({ open, setOpen }) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style} className="warehouse__details_modal_wrap">
          <div className="warehouse__details_modal">
            <div
              className="warehouse__details_modalclose"
              onClick={() => setOpen(false)}
            >
              <IconButton>
                <FaRegTimesCircle size={25} color="lightgrey" />
              </IconButton>
            </div>
            <div className="warehouse__details_heading">
              <BiUser size={35} /> <h2>Warehouse Details</h2>
            </div>
            <div className="warehouse__details_modalrow">
              <div className="warehouse__details_modalrowitem">
                <div className="warehouse__details_modalcolbold">
                  Warehouse Name:
                </div>
                <div className="warehouse__details_modalcol">
                  {open?.name ?? "-"}
                </div>
              </div>
              <div className="warehouse__details_modalrowitem">
                <div className="warehouse__details_modalcolbold">Address:</div>
                <div className="warehouse__details_modalcol">
                  {open?.address ?? "-"}
                </div>
              </div>
            </div>
            <div className="warehouse__details_modalrow">
              <div className="warehouse__details_modalrowitem">
                <div className="warehouse__details_modalcolbold">City:</div>
                <div className="warehouse__details_modalcol">
                  {open?.city ?? "-"}
                </div>
              </div>
              <div className="warehouse__details_modalrowitem">
                <div className="warehouse__details_modalcolbold">Country:</div>
                <div className="warehouse__details_modalcol">
                  {open?.country ?? "-"}
                </div>
              </div>
            </div>
            <div className="warehouse__details_modalrow">
              <div className="warehouse__details_modalrowitem">
                <div className="warehouse__details_modalcolbold">State:</div>
                <div className="warehouse__details_modalcol">
                  {open?.state ?? "-"}
                </div>
              </div>
              <div className="warehouse__details_modalrowitem">
                <div className="warehouse__details_modalcolbold">
                  Postal Code:
                </div>
                <div className="warehouse__details_modalcol">
                  {open?.postcode ?? "-"}
                </div>
              </div>
            </div>
            <div className="warehouse__details_modalrow">
              <div className="warehouse__details_modalrowitem">
                <div className="warehouse__details_modalcolbold">
                  Business Name:
                </div>
                <div className="warehouse__details_modalcol">
                  {open?.email ?? "-"}
                </div>
              </div>
              <div className="warehouse__details_modalrowitem">
                <div className="warehouse__details_modalcolbold">
                  Industry Type:
                </div>
                <div className="warehouse__details_modalcol">
                  {open?.phone_number ?? "-"}
                </div>
              </div>
            </div>

            <div className="warehouse__details_modalrow">
              <div
                className="warehouse__details_companiesbox"
                style={{ width: "100%" }}
              >
                <h3 className="warehouse__details_comboxheading">User Info</h3>
                <div className="warehouse__details_modalrow">
                  <div className="warehouse__details_modalrowitem">
                    <div className="warehouse__details_modalcolbold">
                      Fisrt Name:
                    </div>
                    <div className="warehouse__details_modalcol">
                      {open?.user?.firstname ?? "-"}
                    </div>
                  </div>
                  <div className="warehouse__details_modalrowitem">
                    <div className="warehouse__details_modalcolbold">
                      Last Name:
                    </div>
                    <div className="warehouse__details_modalcol">
                      {open?.user?.lastname ?? "-"}
                    </div>
                  </div>
                </div>
                <div className="warehouse__details_modalrow">
                  <div className="warehouse__details_modalrowitem">
                    <div className="warehouse__details_modalcolbold">
                      Username:
                    </div>
                    <div className="warehouse__details_modalcol">
                      {open?.user?.username ?? "-"}
                    </div>
                  </div>
                  <div className="warehouse__details_modalrowitem">
                    <div className="warehouse__details_modalcolbold">
                      Email:
                    </div>
                    <div className="warehouse__details_modalcol">
                      {open?.user?.email ?? "-"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default WarehouseDetails;
