import React, { useState } from "react";
import BarcodeScanner from "./test";
import Button from "@mui/material/Button";

export default function CountrySelect() {
  const [scan, setScan] = useState(false);
  const [prodHistoryModal, setProdHistoryModal] = useState("");

  const handleScan = (data) => {
    console.log("Scanned data:", data);
    setProdHistoryModal(data);
    setScan(false);
    // You can handle the scanned data here (e.g., save it to state, send it to an API, etc.)
  };

  const handleButton = () => {
    setScan(!scan);
    setProdHistoryModal(false);
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {scan && (
        <div>
          <h1>Barcode Scanner</h1>
          <BarcodeScanner onScan={handleScan} />
        </div>
      )}
      <div>
        <Button variant="contained" onClick={() => handleButton()}>
          Handle Scan
        </Button>
        <h1>Value: </h1>
        <small>{prodHistoryModal}</small>
      </div>
    </div>
  );
}
