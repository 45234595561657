import React, { useEffect, useRef, useState } from "react";
import "./GRNDetailsModal.css";

//componenets
import StockReceivedForm from "../../../../StockReceived/StockReceivedForm";
// apis imports
// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import ImagesModal from "../../../../Shipper/Customer/MainPages/Orders/Components/OrderHistoryModal/ImagesModal/ImagesModal";
import { FcPlus } from "react-icons/fc";

const GRNDetailsModal = ({ open, setOpen, setSnack }) => {
  const [fullview, setfullview] = useState();
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <ImagesModal setOpen={setfullview} open={fullview} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          className="proddetails__desc_modal detail__modal_order_view grn__modal_block  "
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
            backgroundColor: "white",
            padding: "20px",
            maxHeight: "80%",
            overflowY: "auto",
          }}
        >
          {/* modal header  */}
          <div className="proddetails__desc_modalheading">
            Stock Received Details
            <div className="proddetails__desc_btn_cross_header">
              <FcPlus
                size={20}
                onClick={handleClose}
                className="order_detail_cross"
              />
            </div>
          </div>
          <StockReceivedForm open={open?.id} mode={"view"} />
        </div>
      </Modal>
    </div>
  );
};

export default GRNDetailsModal;
