import React, { useEffect, useState } from "react";
import "../../InvoiceModal.css";
import { fixedDecimals } from "../../../../helpers/helpers";
import { addCharge, updateInvoice } from "../../../../services/admin.services";
import AddImage from "../../../../assets/images/add-image.png";
import NewTax from "../NewCharge/NewTax";

import Snackbar from "../../../SnackBar/SnackBar";
import CircularLoader from "../../../CircularLoader/CircularLoader";
import AdditionalCharge from "../NewCharge/AdditionalCharge";

const compareCharges = (previousCharge, newCharge) => {
  previousCharge = parseFloat(previousCharge);
  newCharge = parseFloat(newCharge);

  if (isNaN(previousCharge)) previousCharge = 0;
  if (isNaN(newCharge)) newCharge = 0;

  var difference = Math.abs(newCharge - previousCharge);
  difference = Number(difference.toFixed(2));

  var type;
  if (difference === 0) {
    type = "equal";
  } else if (newCharge < previousCharge) {
    type = "low";
  } else {
    type = "high";
  }

  return {
    difference: difference === NaN ? "-" : !difference ? "-" : difference,
    type: type,
  };
};
const ChargeDifference = ({ previousCharge, newCharge }) => {
  const [type, setType] = useState();
  const [value, setValue] = useState();

  const getColor = () => {
    if (type === "low") return "tomato";
    if (type === "high") return "teal";
    return "";
  };

  const getSign = () => {
    if (type === "low") return "-";
    if (type === "high") return "+";
    return "";
  };
  useEffect(() => {
    const diff = compareCharges(previousCharge, newCharge);
    setType(diff?.type);
    setValue(diff?.difference ?? "-");
    // console.log(previousCharge, newCharge, diff);
  }, [previousCharge, newCharge]);
  return (
    <span style={{ color: getColor() }}>
      {getSign()}
      {value}
    </span>
  );
};

const BillingAdmin = ({ data, setData, setShipments }) => {
  const [taxCharges, setTaxCharges] = useState([]);
  const [additionalCharges, setAdditionalCharges] = useState([]);
  const [additionalChargesData, setAdditionalChargesData] = useState([]);
  const [newCharge, setNewCharge] = useState();
  const [newChargeData, setNewChargeData] = useState({});
  const [newTaxData, setNewTaxData] = useState({});
  const [modifyInvoice, setModifyInvoice] = useState();
  const [chargeDescription, setChargeDescription] = useState();
  const [savingCharge, setSavingCharge] = useState();
  const [finalTotal, setFinalTotal] = useState({});
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [newTaxModal, setNewTaxModal] = useState();
  const [additionalChargeModal, setAdditionalChargeModal] = useState();
  const [newlyAddedCharges, setNewlyAddedCharges] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });
  const [finaltotaldata, setfinaltotaldata] = useState(0);
  const [finaltotaldifference, setfinaltotaldifference] = useState(0);

  function checkNumber(str) {
    if (!str) return null;
    if (typeof str === "number") return str;
    str = str.trim();
    if (/^[-+]?(\d+(\.\d*)?|\.\d+)([eE][-+]?\d+)?$/.test(str)) {
      return parseFloat(str);
    } else {
      var numericCharacters = str.replace(/[^0-9.]/g, "");
      if (/^[-+]?(\d+(\.\d*)?|\.\d+)([eE][-+]?\d+)?$/.test(numericCharacters)) {
        return parseFloat(numericCharacters);
      } else {
        return null;
      }
    }
  }
  const handleSnackClose = () => {
    setSnack({ ...snack, open: false });
  };
  function createChargeObject(inputArray) {
    const chargeObject = {};

    for (const item of inputArray) {
      const key = item.NameEn;
      const value = item.Charge;
      chargeObject[key] = value;
    }

    return chargeObject;
  }
  const handleChargeChange = (preVal, e) => {
    const { name, value } = e.target;
    if (modifyInvoice) {
      setNewChargeData({ ...newChargeData, [name]: value });
      setFinalTotal({ ...finalTotal, [name]: value });
    } else {
      setNewChargeData({ ...newChargeData, [name]: value });
      setFinalTotal({
        ...finalTotal,
        [name]: Number((checkNumber(value) + checkNumber(preVal)).toFixed(2)),
      });
    }
  };
  const handleAttachmentChange = (e) => {
    const files = e.target.files;
    setSelectedAttachment(Array.from(files));
  };
  const handleTaxChange = (preVal, e) => {
    const { name, value } = e.target;
    if (modifyInvoice) {
      setNewTaxData({ ...newTaxData, [name]: value });
      setFinalTotal({ ...finalTotal, [name]: value });
    } else {
      setNewTaxData({ ...newTaxData, [name]: value });
      const tax = data?.shipment_charges[
        data?.shipment_charges?.length - 1
      ]?.total_updated_charges?.tax_charges?.find(
        (charge) => charge?.NameEn === name
      );
      setFinalTotal({
        ...finalTotal,
        [name]: Number(
          (checkNumber(value) + checkNumber(tax?.Charge)).toFixed(2)
        ),
      });
    }
  };
  const handleAdditionalChargeChange = (preVal, e) => {
    const { name, value } = e.target;
    if (modifyInvoice) {
      setAdditionalChargesData({ ...additionalChargesData, [name]: value });
      setFinalTotal({ ...finalTotal, [name]: value });
    } else {
      setAdditionalChargesData({ ...additionalChargesData, [name]: value });
      const tax = data?.shipment_charges[
        data?.shipment_charges?.length - 1
      ]?.total_updated_charges?.additional_charges?.find(
        (charge) => charge?.NameEn === name
      );
      setFinalTotal({
        ...finalTotal,
        [name]: Number(
          (checkNumber(value) + checkNumber(tax?.Charge)).toFixed(2)
        ),
      });
    }
  };
  const handleChargeClose = () => {
    setNewCharge(false);
    setModifyInvoice(false);
    setNewChargeData({});
    setNewTaxData({});
    setFinalTotal({});
    setChargeDescription(false);
  };
  function convertPayloadToFormData(payload) {
    const formData = new FormData();

    // Iterate through the payload object and append its properties to formData
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        const value = payload[key];

        if (key === "file") {
          if (Array.isArray(value)) {
            // Handle the 'file' field as an array of File objects
            value.forEach((file, index) => {
              formData.append(`file[${index}]`, file);
            });
          } else if (value instanceof File) {
            // Handle a single File object
            formData.append("file[0]", value);
          }
        } else if (Array.isArray(value)) {
          // Handle arrays
          value.forEach((item, index) => {
            for (const subKey in item) {
              if (item.hasOwnProperty(subKey)) {
                const subValue = item[subKey];
                formData.append(`${key}[${index}][${subKey}]`, subValue);
              }
            }
          });
        } else if (typeof value === "object") {
          // Handle nested objects
          for (const subKey in value) {
            if (value.hasOwnProperty(subKey)) {
              const subValue = value[subKey];
              formData.append(`${key}[${subKey}]`, subValue);
            }
          }
        } else {
          // Handle all other fields
          formData.append(key, value);
        }
      }
    }

    return formData;
  }

  const handleSaveCharge = async (id) => {
    setSavingCharge("add");
    const tax_charges = [];
    const additional_charges = [];

    taxCharges?.forEach((tax) => {
      // console.log(tax);
      if (newTaxData[tax.NameEn]) {
        tax_charges.push({
          ...tax,
          Charge: newTaxData[tax.NameEn],
        });
      }
    });
    additionalCharges?.forEach((charge) => {
      // console.log(charge);
      if (additionalChargesData[charge.NameEn]) {
        additional_charges.push({
          ...charge,
          Charge: additionalChargesData[charge.NameEn],
        });
      }
    });
    const response = await addCharge(
      convertPayloadToFormData({
        ...newChargeData,
        file: selectedAttachment,
        tax_charges,
        additional_charges,
      }),
      id
    );
    setSelectedAttachment(null);
    setSavingCharge(false);
    if (!response.error) {
      setData({
        ...data,
        Total: response?.data?.result?.total,
        shipment_charges: [
          ...(data?.shipment_charges ?? []),
          response.data?.result,
        ],
      });
      setShipments((prevShipments) => {
        return {
          ...prevShipments,
          data: prevShipments?.data?.map((shipment) => {
            if (shipment.id === data.id) {
              return {
                ...shipment,
                Total: response?.data?.result?.total,
                shipment_charges: [
                  ...(shipment?.shipment_charges ?? []),
                  response.data?.result,
                ],
              };
            } else {
              return shipment;
            }
          }),
        };
      });
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Charge Added Successfully",
          severity: "success",
        };
      });
      handleChargeClose();
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };
  const handleUpdateCharge = async (id) => {
    setSavingCharge("update");
    let payload = {
      base_price: 0,
      fuel_charge: 0,
      freight_charge: 0,
      sub_total: 0,
      PickupCharge: 0,
      residential_charge: 0,
      signature_charge: 0,
      description: "",
      additional_price: 0,
      file: selectedAttachment,
    };

    payload = {
      ...payload,
      ...newChargeData,
    };
    const tax_charges = [];

    taxCharges?.forEach((tax) => {
      // console.log(tax);
      if (newTaxData[tax.NameEn]) {
        tax_charges.push({
          ...tax,
          Charge: newTaxData[tax.NameEn],
        });
      }
    });
    const response = await updateInvoice(
      convertPayloadToFormData({
        ...payload,
        tax_charges,
      }),
      id
    );
    setSelectedAttachment(null);
    setSavingCharge(false);
    if (!response.error) {
      setData({
        ...data,
        Total: response?.data?.result?.total,
        shipment_charges: [
          ...(data?.shipment_charges ?? []),
          response.data?.result,
        ],
      });
      setShipments((prevShipments) => {
        return {
          ...prevShipments,
          data: prevShipments?.data?.map((shipment) => {
            if (shipment.id === data.id) {
              return {
                ...shipment,
                Total: response?.data?.result?.total,
                shipment_charges: [
                  ...(shipment?.shipment_charges ?? []),
                  response.data?.result,
                ],
              };
            } else {
              return shipment;
            }
          }),
        };
      });
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Invoice Updated Successfully",
          severity: "success",
        };
      });
      handleChargeClose();
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const findTaxChargeByNameEn = (nameEn, index) => {
    const taxCharges = data?.shipment_charges[index]?.tax_charges;
    const matchingCharge = taxCharges?.find(
      (charge) => charge?.NameEn === nameEn
    );

    if (matchingCharge) {
      return matchingCharge;
    } else {
      return false;
    }
  };
  const findAdditionalChargeByNameEn = (nameEn, index) => {
    const additionalCharges = data?.shipment_charges[index]?.additional_charges;
    const matchingCharge = additionalCharges?.find(
      (charge) => charge?.NameEn === nameEn
    );

    if (matchingCharge) {
      return matchingCharge;
    } else {
      return false;
    }
  };
  useEffect(() => {
    setTaxCharges(
      data?.shipment_charges
        ?.flatMap((item) => item?.tax_charges)
        ?.filter(
          (charge, index, self) =>
            index === self?.findIndex((c) => c?.NameEn === charge?.NameEn) &&
            charge
        )
    );
    setAdditionalCharges(
      data?.shipment_charges
        ?.flatMap((item) => item?.additional_charges)
        ?.filter(
          (charge, index, self) =>
            index === self?.findIndex((c) => c?.NameEn === charge?.NameEn) &&
            charge
        )
    );
  }, [data]);

  useEffect(() => {
    const Size = data?.shipment_charges?.length;
    let prevTotal =
      +Size > 0 ? parseFloat(data?.shipment_charges[Size - 1]?.total) || 0 : 0;

    let total = 0;
    // console.log(newChargeData);
    for (const key in newChargeData) {
      if (newChargeData.hasOwnProperty(key) && key !== "description") {
        const chargeValue = parseFloat(newChargeData[key]);
        if (!isNaN(chargeValue) && newChargeData[key] !== "") {
          total += chargeValue;
        }
      }
    }
    for (const key in newTaxData) {
      if (newTaxData.hasOwnProperty(key)) {
        const chargeValue = parseFloat(newTaxData[key]);
        if (!isNaN(chargeValue) && newTaxData[key] !== "") {
          total += chargeValue;
        }
      }
    }
    for (const key in additionalChargesData) {
      if (additionalChargesData.hasOwnProperty(key)) {
        const chargeValue = parseFloat(additionalChargesData[key]);
        if (!isNaN(chargeValue) && additionalChargesData[key] !== "") {
          total += chargeValue;
        }
      }
    }
    setfinaltotaldifference(total);
    setfinaltotaldata(total + prevTotal);
  }, [
    finalTotal,
    newChargeData,
    newCharge,
    data,
    newTaxData,
    additionalChargesData,
  ]);

  const calculateServiceChargePercentage = (total, serviceCharge) => {
    const totalFloat = parseFloat(total ?? 0);
    const serviceChargeFloat = parseFloat(serviceCharge ?? 0);

    const originalPrice = totalFloat - serviceChargeFloat;

    const serviceChargePercentage = (serviceChargeFloat / originalPrice) * 100;

    return serviceChargePercentage.toFixed(1);
  };

  return (
    <div className="invoice__billing_admin_wrap">
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <NewTax
        open={newTaxModal}
        setOpen={setNewTaxModal}
        taxCharges={taxCharges}
        setTaxCharges={setTaxCharges}
        setNewTaxData={setNewTaxData}
        setFinalTotal={setFinalTotal}
        setNewlyAddedCharges={setNewlyAddedCharges}
      />
      <AdditionalCharge
        open={additionalChargeModal}
        setOpen={setAdditionalChargeModal}
        additionalCharges={additionalCharges}
        setAdditionalCharges={setAdditionalCharges}
        setAdditionalChargesData={setAdditionalChargesData}
        setFinalTotal={setFinalTotal}
        setNewlyAddedCharges={setNewlyAddedCharges}
      />
      <div className="invmodal__shipment_detailshding">
        Billing information{" "}
        <div className="invmodal__editinv_actionbtns">
          {modifyInvoice || newCharge ? (
            <span
              className="payments__add_invchargebtn"
              onClick={() => {
                if (newCharge) {
                  setTaxCharges((charges) => {
                    return charges?.filter(
                      (charge) => !newlyAddedCharges.includes(charge?.Id)
                    );
                  });
                  setNewChargeData({});
                  setNewlyAddedCharges([]);
                }
                handleChargeClose();
              }}
            >
              Cancel
            </span>
          ) : null}
          {!modifyInvoice && !newCharge && (
            <>
              <td className="invmodal__shdetails_griditem">
                {" "}
                <span
                  className="payments__add_invchargebtn"
                  onClick={() => {
                    setModifyInvoice({
                      data: data?.shipment_charges[
                        data?.shipment_charges?.length - 1
                      ],
                      index: data?.shipment_charges?.length - 1,
                    });

                    setChargeDescription(
                      data?.shipment_charges[data?.shipment_charges?.length - 1]
                        ?.id
                    );
                    setNewChargeData(
                      data?.shipment_charges[data?.shipment_charges?.length - 1]
                        ?.total_updated_charges
                    );
                    setFinalTotal({
                      ...data?.shipment_charges[
                        data?.shipment_charges?.length - 1
                      ]?.total_updated_charges,
                      ...createChargeObject(
                        data?.shipment_charges[
                          data?.shipment_charges?.length - 1
                        ]?.total_updated_charges?.tax_charges
                      ),
                      ...createChargeObject(
                        data?.shipment_charges[
                          data?.shipment_charges?.length - 1
                        ]?.total_updated_charges?.additional_charges
                      ),
                    });
                    setNewTaxData({
                      ...createChargeObject(
                        data?.shipment_charges[
                          data?.shipment_charges?.length - 1
                        ]?.total_updated_charges?.tax_charges
                      ),
                      ...createChargeObject(
                        data?.shipment_charges[
                          data?.shipment_charges?.length - 1
                        ]?.total_updated_charges?.additional_charges
                      ),
                    });
                  }}
                >
                  Modify Invoice
                </span>
              </td>
              <td className="invmodal__shdetails_griditem">
                {" "}
                <span
                  className="payments__add_invchargebtn"
                  onClick={() => {
                    setChargeDescription(data?.id);
                    setNewCharge({
                      data: data?.shipment_charges[
                        data?.shipment_charges?.length - 1
                      ]?.total_updated_charges,
                      index: data?.shipment_charges?.length - 1,
                    });
                  }}
                >
                  Add Charge
                </span>
              </td>
            </>
          )}
        </div>
      </div>

      <table className="invmodal__shbilling_grid">
        <thead>
          <th className="invmodal__shdetails_gridhd">#</th>
          <th className="invmodal__shdetails_gridhd">Base</th>
          <th className="invmodal__shdetails_gridhd">Fuel</th>
          <th className="invmodal__shdetails_gridhd">Freight Charge</th>
          <th className="invmodal__shdetails_gridhd">Signature</th>
          <th className="invmodal__shdetails_gridhd">Residential</th>
          <th className="invmodal__shdetails_gridhd">Pickup Charge</th>
          {data?.insurance && (
            <th className="invmodal__shdetails_gridhd">Insurance Charge</th>
          )}
          <th className="invmodal__shdetails_gridhd">Sub Total</th>
          {data?.shipment_charges &&
            taxCharges?.map((tax, index) => (
              <th className="invmodal__shdetails_gridhd" key={index}>
                {tax?.NameEn}
              </th>
            ))}
          {data?.shipment_charges &&
            additionalCharges?.map((charge, index) => (
              <th className="invmodal__shdetails_gridhd" key={index}>
                {charge?.NameEn}
              </th>
            ))}
          {newCharge && (
            <th className="invmodal__shdetails_gridhd">
              <button
                className="invmodal__shdetails_newchargebtn"
                onClick={() => setNewTaxModal(true)}
              >
                New Tax
              </button>
              <button
                className="invmodal__shdetails_newchargebtn"
                onClick={() => setAdditionalChargeModal(true)}
              >
                New Charge
              </button>
            </th>
          )}
          {parseFloat(data?.service_charge ?? "0", 10) > 0 && (
            <th className="invmodal__shdetails_gridhd">Service Charge</th>
          )}
          <th className="invmodal__shdetails_gridhd">Total</th>
        </thead>
        <tbody>
          {data?.shipment_charges?.map((charge, index) => {
            return (
              <tr key={index}>
                <td className="invmodal__shdetails_griditem">
                  <span>{index + 1}</span>
                </td>
                <td className={`invmodal__shdetails_griditem`}>
                  <span>
                    {data?.shipment_charges[index + 1]?.type !== "add" &&
                    index !== data?.shipment_charges.length - 1 &&
                    charge?.base_price ? (
                      <span className="invmodal__shdetails_griditemdisabled"></span>
                    ) : (
                      ""
                    )}

                    {charge?.base_price && charge?.base_price !== 0
                      ? charge?.base_price
                      : "-"}
                  </span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span>
                    {data?.shipment_charges[index + 1]?.type !== "add" &&
                    index !== data?.shipment_charges.length - 1 &&
                    charge?.fuel_charge ? (
                      <span className="invmodal__shdetails_griditemdisabled"></span>
                    ) : (
                      ""
                    )}

                    {charge?.fuel_charge && charge?.fuel_charge !== 0
                      ? charge?.fuel_charge
                      : "-"}
                  </span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span>
                    {data?.shipment_charges[index + 1]?.type !== "add" &&
                    index !== data?.shipment_charges.length - 1 &&
                    charge?.freight_charge ? (
                      <span className="invmodal__shdetails_griditemdisabled"></span>
                    ) : (
                      ""
                    )}

                    {charge?.freight_charge && charge?.freight_charge !== 0
                      ? charge?.freight_charge
                      : "-"}
                  </span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span>
                    {data?.shipment_charges[index + 1]?.type !== "add" &&
                    index !== data?.shipment_charges.length - 1 &&
                    charge?.signature_charge ? (
                      <span className="invmodal__shdetails_griditemdisabled"></span>
                    ) : (
                      ""
                    )}

                    {charge?.signature_charge && charge?.signature_charge !== 0
                      ? charge?.signature_charge
                      : "-"}
                  </span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span>
                    {data?.shipment_charges[index + 1]?.type !== "add" &&
                    index !== data?.shipment_charges.length - 1 &&
                    charge?.residential_charge ? (
                      <span className="invmodal__shdetails_griditemdisabled"></span>
                    ) : (
                      ""
                    )}

                    {charge?.residential_charge &&
                    charge?.residential_charge !== 0
                      ? charge?.residential_charge
                      : "-"}
                  </span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span>
                    {data?.shipment_charges[index + 1]?.type !== "add" &&
                    index !== data?.shipment_charges.length - 1 &&
                    charge?.PickupCharge ? (
                      <span className="invmodal__shdetails_griditemdisabled"></span>
                    ) : (
                      ""
                    )}

                    {charge?.PickupCharge && charge?.PickupCharge !== 0
                      ? charge?.PickupCharge
                      : "-"}
                  </span>
                </td>
                {data?.insurance && (
                  <td className="invmodal__shdetails_griditem">
                    <span>
                      {data?.shipment_charges[index + 1]?.type !== "add" &&
                      index !== data?.shipment_charges.length - 1 &&
                      charge?.insurance_charge ? (
                        <span className="invmodal__shdetails_griditemdisabled"></span>
                      ) : (
                        ""
                      )}

                      {charge?.insurance_charge &&
                      charge?.insurance_charge !== 0
                        ? charge?.insurance_charge
                        : "-"}
                    </span>
                  </td>
                )}
                <td className="invmodal__shdetails_griditem">
                  <span>
                    {data?.shipment_charges[index + 1]?.type !== "add" &&
                    index !== data?.shipment_charges.length - 1 &&
                    charge?.sub_total ? (
                      <span className="invmodal__shdetails_griditemdisabled"></span>
                    ) : (
                      ""
                    )}

                    {charge?.sub_total && charge?.sub_total !== 0
                      ? charge?.sub_total
                      : "-"}
                  </span>
                </td>
                {data?.shipment_charges &&
                  taxCharges?.map((tax, idx) => (
                    <td className="invmodal__shdetails_griditem" key={idx}>
                      <span>
                        {data?.shipment_charges[index + 1]?.type !== "add" &&
                        index !== data?.shipment_charges.length - 1 ? (
                          <span className="invmodal__shdetails_griditemdisabled"></span>
                        ) : (
                          ""
                        )}

                        {findTaxChargeByNameEn(tax?.NameEn, index)
                          ? findTaxChargeByNameEn(tax?.NameEn, index)?.Charge
                          : "-"}
                      </span>
                    </td>
                  ))}
                {data?.shipment_charges &&
                  additionalCharges?.map((charge, idx) => (
                    <td className="invmodal__shdetails_griditem" key={idx}>
                      <span>
                        {data?.shipment_charges[index + 1]?.type !== "add" &&
                        index !== data?.shipment_charges.length - 1 ? (
                          <span className="invmodal__shdetails_griditemdisabled"></span>
                        ) : (
                          ""
                        )}

                        {findAdditionalChargeByNameEn(charge?.NameEn, index)
                          ? findAdditionalChargeByNameEn(charge?.NameEn, index)
                              ?.Charge
                          : "-"}
                      </span>
                    </td>
                  ))}
                {newCharge && (
                  <td className="invmodal__shdetails_griditem"> </td>
                )}
                {parseFloat(data?.service_charge ?? "0") > 0 && (
                  <td className="invmodal__shdetails_griditem">
                    {index === 0 ? (
                      <>
                        {parseFloat(data?.service_charge ?? "0").toFixed(2)} (
                        {calculateServiceChargePercentage(
                          charge?.total,
                          parseFloat(data?.service_charge ?? "0")
                        )}{" "}
                        %)
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                )}

                <td className="invmodal__shdetails_griditem">
                  {" "}
                  <span>
                    {data?.shipment_charges[index + 1]?.type !== "add" &&
                    index !== data?.shipment_charges.length - 1 &&
                    charge?.total ? (
                      <span className="invmodal__shdetails_griditemdisabled"></span>
                    ) : (
                      ""
                    )}
                    {charge?.total && charge?.total !== 0
                      ? fixedDecimals(charge?.total)
                      : "-"}
                  </span>
                </td>
              </tr>
            );
          })}
          {newCharge && (
            <>
              <tr>
                <td className="invmodal__shdetails_griditem"></td>
                <td className={`invmodal__shdetails_griditem`}>
                  <ChargeDifference
                    previousCharge={0}
                    newCharge={newChargeData?.base_price}
                  />
                </td>
                <td className="invmodal__shdetails_griditem">
                  <ChargeDifference
                    previousCharge={0}
                    newCharge={newChargeData?.fuel_charge}
                  />
                </td>
                <td className="invmodal__shdetails_griditem">
                  <ChargeDifference
                    previousCharge={0}
                    newCharge={newChargeData?.freight_charge}
                  />
                </td>
                <td className="invmodal__shdetails_griditem">
                  <ChargeDifference
                    previousCharge={0}
                    newCharge={newChargeData?.signature_charge}
                  />
                </td>
                <td className="invmodal__shdetails_griditem">
                  <ChargeDifference
                    previousCharge={0}
                    newCharge={newChargeData?.residential_charge}
                  />
                </td>
                <td className="invmodal__shdetails_griditem">
                  <ChargeDifference
                    previousCharge={0}
                    newCharge={newChargeData?.PickupCharge}
                  />
                </td>
                <td className="invmodal__shdetails_griditem">
                  <ChargeDifference
                    previousCharge={0}
                    newCharge={newChargeData?.sub_total}
                  />
                </td>
                {data?.shipment_charges &&
                  taxCharges?.map((tax, index) => (
                    <td className="invmodal__shdetails_griditem" key={index}>
                      <ChargeDifference
                        previousCharge={0}
                        newCharge={newTaxData[tax?.NameEn]}
                      />
                    </td>
                  ))}
                {data?.shipment_charges &&
                  additionalCharges?.map((charge, index) => (
                    <td className="invmodal__shdetails_griditem" key={index}>
                      <ChargeDifference
                        previousCharge={0}
                        newCharge={additionalChargesData[charge?.NameEn]}
                      />
                    </td>
                  ))}
                {newCharge && (
                  <td className="invmodal__shdetails_griditem"> </td>
                )}
                {parseFloat(data?.service_charge ?? "0") > 0 && (
                  <td className="invmodal__shdetails_griditem">-</td>
                )}
                <td className="invmodal__shdetails_griditem">
                  <ChargeDifference
                    previousCharge={0}
                    newCharge={finaltotaldifference}
                  />
                </td>
                <div
                  className="invmodal__shdetails_sidebadge"
                  style={{ top: "6px" }}
                >
                  Difference
                </div>
              </tr>
              <tr>
                <td className="invmodal__shdetails_griditem">
                  <span className="invmodal__shdgi_inputwrap">
                    {data?.shipment_charges?.length + 1}
                  </span>
                </td>
                <td className={`invmodal__shdetails_griditem`}>
                  <span className="invmodal__shdgi_inputwrap">
                    <input
                      type="text"
                      className="invmodal__shdgi_input"
                      style={{ width: "40px" }}
                      onChange={(e) =>
                        handleChargeChange(newCharge?.data?.base_price, e)
                      }
                      name="base_price"
                    />
                  </span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span className="invmodal__shdgi_inputwrap">
                    <input
                      type="text"
                      className="invmodal__shdgi_input"
                      style={{ width: "40px" }}
                      onChange={(e) =>
                        handleChargeChange(newCharge?.data?.fuel_charge, e)
                      }
                      name="fuel_charge"
                    />
                  </span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span className="invmodal__shdgi_inputwrap">
                    <input
                      type="text"
                      className="invmodal__shdgi_input"
                      style={{ width: "40px" }}
                      onChange={(e) =>
                        handleChargeChange(newCharge?.data?.freight_charge, e)
                      }
                      name="freight_charge"
                    />
                  </span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span className="invmodal__shdgi_inputwrap">
                    <input
                      type="text"
                      className="invmodal__shdgi_input"
                      style={{ width: "60px" }}
                      onChange={(e) =>
                        handleChargeChange(newCharge?.data?.signature_charge, e)
                      }
                      name="signature_charge"
                    />
                  </span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span className="invmodal__shdgi_inputwrap">
                    <input
                      type="text"
                      className="invmodal__shdgi_input"
                      style={{ width: "70px" }}
                      onChange={(e) =>
                        handleChargeChange(
                          newCharge?.data?.residential_charge,
                          e
                        )
                      }
                      name="residential_charge"
                    />
                  </span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span className="invmodal__shdgi_inputwrap">
                    <input
                      type="text"
                      className="invmodal__shdgi_input"
                      style={{ width: "90px" }}
                      onChange={(e) =>
                        handleChargeChange(newCharge?.data?.PickupCharge, e)
                      }
                      name="PickupCharge"
                    />
                  </span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span className="invmodal__shdgi_inputwrap">
                    <input
                      type="text"
                      className="invmodal__shdgi_input"
                      style={{ width: "62px" }}
                      onChange={(e) =>
                        handleChargeChange(newCharge?.data?.sub_total, e)
                      }
                      name="sub_total"
                    />
                  </span>
                </td>
                {data?.shipment_charges &&
                  taxCharges?.map((tax, index) => (
                    <td className="invmodal__shdetails_griditem" key={index}>
                      <span className="invmodal__shdgi_inputwrap">
                        <input
                          type="text"
                          className="invmodal__shdgi_input"
                          style={{ width: "35px" }}
                          value={newTaxData[tax.NameEn]}
                          name={tax.NameEn}
                          onChange={(e) => handleTaxChange(tax.Charge, e)}
                        />
                      </span>
                    </td>
                  ))}
                {data?.shipment_charges &&
                  additionalCharges?.map((charge, index) => (
                    <td className="invmodal__shdetails_griditem" key={index}>
                      <span className="invmodal__shdgi_inputwrap">
                        <input
                          type="text"
                          className="invmodal__shdgi_input"
                          style={{ width: "35px" }}
                          value={additionalChargesData[charge.NameEn]}
                          name={charge.NameEn}
                          onChange={(e) =>
                            handleAdditionalChargeChange(charge.Charge, e)
                          }
                        />
                      </span>
                    </td>
                  ))}
                {newCharge && (
                  <td className="invmodal__shdetails_griditem"> </td>
                )}
                <td className="invmodal__shdetails_griditem">
                  <span className="invmodal__shdgi_inputwrap">-</span>
                </td>
                <td
                  className="invmodal__shdetails_griditem"
                  style={{ display: "flex" }}
                >
                  {" "}
                </td>
              </tr>

              <tr className="invmodal__blfinaltotal_row">
                <td className="invmodal__shdetails_griditem"></td>
                <td className={`invmodal__shdetails_griditem`}>
                  <span>{finalTotal?.base_price ?? "-"}</span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span>{finalTotal?.fuel_charge ?? "-"}</span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span>{finalTotal?.freight_charge ?? "-"}</span>
                </td>

                <td className="invmodal__shdetails_griditem">
                  <span>{finalTotal?.signature_charge ?? "-"}</span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span>{finalTotal?.residential_charge ?? "-"}</span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span>{finalTotal?.PickupCharge ?? "-"}</span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span>
                    {finalTotal?.sub_total
                      ? fixedDecimals(finalTotal?.sub_total)
                      : "-"}
                  </span>
                </td>
                {data?.shipment_charges &&
                  taxCharges?.map((tax, index) => (
                    <td className="invmodal__shdetails_griditem" key={index}>
                      <span>{finalTotal[tax.NameEn] ?? "-"}</span>
                    </td>
                  ))}
                {data?.shipment_charges &&
                  additionalCharges?.map((charge, index) => (
                    <td className="invmodal__shdetails_griditem" key={index}>
                      <span>{finalTotal[charge.NameEn] ?? "-"}</span>
                    </td>
                  ))}
                {newCharge && (
                  <td className="invmodal__shdetails_griditem"> </td>
                )}
                {parseFloat(data?.service_charge ?? "0") > 0 && (
                  <td className="invmodal__shdetails_griditem">-</td>
                )}
                <td className="invmodal__shdetails_griditem">
                  {" "}
                  <span>
                    {finaltotaldata ? fixedDecimals(finaltotaldata) : "-"}
                  </span>
                </td>
                <div
                  className="invmodal__shdetails_sidebadge"
                  style={{ top: "6px" }}
                >
                  Total
                </div>
              </tr>
            </>
          )}

          {modifyInvoice && (
            <>
              <tr>
                <td className="invmodal__shdetails_griditem">
                  <span className="invmodal__shdgi_inputwrap">
                    {data?.shipment_charges?.length + 1}
                  </span>
                </td>
                <td className={`invmodal__shdetails_griditem`}>
                  <span className="invmodal__shdgi_inputwrap">
                    <input
                      type="text"
                      className="invmodal__shdgi_input"
                      style={{ width: "40px" }}
                      onChange={(e) =>
                        handleChargeChange(modifyInvoice?.data?.base_price, e)
                      }
                      value={newChargeData?.base_price}
                      name="base_price"
                    />
                  </span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span className="invmodal__shdgi_inputwrap">
                    <input
                      type="text"
                      className="invmodal__shdgi_input"
                      style={{ width: "40px" }}
                      onChange={(e) =>
                        handleChargeChange(modifyInvoice?.data?.fuel_charge, e)
                      }
                      value={newChargeData?.fuel_charge}
                      name="fuel_charge"
                    />
                  </span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span className="invmodal__shdgi_inputwrap">
                    <input
                      type="text"
                      className="invmodal__shdgi_input"
                      style={{ width: "40px" }}
                      onChange={(e) =>
                        handleChargeChange(
                          modifyInvoice?.data?.freight_charge,
                          e
                        )
                      }
                      value={newChargeData?.freight_charge}
                      name="freight_charge"
                    />
                  </span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span className="invmodal__shdgi_inputwrap">
                    <input
                      type="text"
                      className="invmodal__shdgi_input"
                      style={{ width: "60px" }}
                      onChange={(e) =>
                        handleChargeChange(
                          modifyInvoice?.data?.signature_charge,
                          e
                        )
                      }
                      value={newChargeData?.signature_charge}
                      name="signature_charge"
                    />
                  </span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span className="invmodal__shdgi_inputwrap">
                    <input
                      type="text"
                      className="invmodal__shdgi_input"
                      style={{ width: "70px" }}
                      onChange={(e) =>
                        handleChargeChange(modifyInvoice.residential_charge, e)
                      }
                      value={newChargeData?.residential_charge}
                      name="residential_charge"
                    />
                  </span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span className="invmodal__shdgi_inputwrap">
                    <input
                      type="text"
                      className="invmodal__shdgi_input"
                      style={{ width: "90px" }}
                      onChange={(e) =>
                        handleChargeChange(modifyInvoice?.data?.PickupCharge, e)
                      }
                      value={newChargeData?.PickupCharge}
                      name="PickupCharge"
                    />
                  </span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span className="invmodal__shdgi_inputwrap">
                    <input
                      type="text"
                      className="invmodal__shdgi_input"
                      style={{ width: "62px" }}
                      onChange={(e) =>
                        handleChargeChange(modifyInvoice?.data?.sub_total, e)
                      }
                      value={newChargeData?.sub_total}
                      name="sub_total"
                    />
                  </span>
                </td>
                {data?.shipment_charges &&
                  taxCharges?.map((tax, index) => (
                    <td className="invmodal__shdetails_griditem" key={index}>
                      <span className="invmodal__shdgi_inputwrap">
                        <input
                          type="text"
                          className="invmodal__shdgi_input"
                          style={{ width: "35px" }}
                          value={newTaxData[tax.NameEn]}
                          name={tax.NameEn}
                          onChange={(e) => handleTaxChange(tax.Charge, e)}
                        />
                      </span>
                    </td>
                  ))}
                {data?.shipment_charges &&
                  additionalCharges?.map((charge, index) => (
                    <td className="invmodal__shdetails_griditem" key={index}>
                      <span className="invmodal__shdgi_inputwrap">
                        <input
                          type="text"
                          className="invmodal__shdgi_input"
                          style={{ width: "35px" }}
                          value={additionalChargesData[charge.NameEn]}
                          name={charge.NameEn}
                          onChange={(e) =>
                            handleAdditionalChargeChange(charge.Charge, e)
                          }
                        />
                      </span>
                    </td>
                  ))}

                {parseFloat(data?.service_charge ?? "0") > 0 && (
                  <td className="invmodal__shdetails_griditem">-</td>
                )}

                <td className="invmodal__shdetails_griditem">
                  <span className="invmodal__shdgi_inputwrap">
                    <input
                      type="text"
                      className="invmodal__shdgi_input"
                      style={{ width: "62px" }}
                      onChange={(e) =>
                        handleChargeChange(modifyInvoice?.data?.total, e)
                      }
                      value={newChargeData?.total ?? 0}
                      name="total"
                    />
                  </span>
                </td>
                <td
                  className="invmodal__shdetails_griditem"
                  style={{ display: "flex" }}
                ></td>
              </tr>

              <tr className="invmodal__blfinaltotal_row">
                <td className="invmodal__shdetails_griditem"></td>
                <td className={`invmodal__shdetails_griditem`}>
                  <span>{finalTotal?.base_price ?? "-"}</span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span>{finalTotal?.fuel_charge ?? "-"}</span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span>{finalTotal?.freight_charge ?? "-"}</span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span>{finalTotal?.signature_charge ?? "-"}</span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span>{finalTotal?.residential_charge ?? "-"}</span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span>{finalTotal?.PickupCharge ?? "-"}</span>
                </td>
                <td className="invmodal__shdetails_griditem">
                  <span>
                    {finalTotal?.sub_total
                      ? fixedDecimals(finalTotal?.sub_total)
                      : "-"}
                  </span>
                </td>
                {data?.shipment_charges &&
                  taxCharges?.map((tax, index) => (
                    <td className="invmodal__shdetails_griditem" key={index}>
                      <span>{finalTotal[tax.NameEn] ?? "-"}</span>
                    </td>
                  ))}
                {data?.shipment_charges &&
                  additionalCharges?.map((charge, index) => (
                    <td className="invmodal__shdetails_griditem" key={index}>
                      <span>{finalTotal[charge.NameEn] ?? "-"}</span>
                    </td>
                  ))}
                {parseFloat(data?.service_charge ?? "0") > 0 && (
                  <td className="invmodal__shdetails_griditem">-</td>
                )}
                <td className="invmodal__shdetails_griditem">
                  {" "}
                  <span>{finalTotal?.total ?? 0}</span>
                </td>
                <div
                  className="invmodal__shdetails_sidebadge"
                  style={{ top: "6px" }}
                >
                  Total
                </div>
              </tr>
            </>
          )}
        </tbody>
      </table>
      {newCharge && chargeDescription && (
        <>
          <div className="update__charge_reasonbox">
            <div className="update__charge_reasonboxlft">
              <textarea
                className="invmodal__charge_desc"
                placeholder="Write a Note about updated charge."
                name="description"
                onChange={(e) => {
                  setNewChargeData({
                    ...newChargeData,
                    description: e.target.value,
                  });
                }}
                value={newChargeData?.description ?? ""}
              ></textarea>
            </div>
            <div className="update__charge_reasonboxrt">
              <label
                for="update_charge_attachment"
                className="update_charge_attachment"
              >
                <input
                  type="file"
                  multiple
                  hidden
                  id="update_charge_attachment"
                  onChange={handleAttachmentChange}
                />
                <img src={AddImage} className="update_charge_atchmentimg" />
                <span>
                  {selectedAttachment?.length > 0
                    ? `${selectedAttachment?.length} file${
                        selectedAttachment?.length > 1 ? "s" : ""
                      } selected`
                    : "Upload Attachment For Client"}
                </span>
              </label>
            </div>
          </div>
          <div className="update__charge_rblftbtns">
            <button
              className="update__charge_rblftbtncncl"
              onClick={() => handleChargeClose()}
            >
              Cancel
            </button>
            <button
              className="update__charge_rblftbtnsave"
              onClick={() => handleSaveCharge(chargeDescription)}
            >
              {savingCharge === "add" ? (
                <CircularLoader size={16} color="grey" />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </>
      )}
      {modifyInvoice && chargeDescription && (
        <>
          <div className="update__charge_reasonbox">
            <div className="update__charge_reasonboxlft">
              <textarea
                className="invmodal__charge_desc"
                placeholder="Write a Note about updated charge."
                name="description"
                onChange={(e) => {
                  setNewChargeData({
                    ...newChargeData,
                    description: e.target.value,
                  });
                }}
                value={newChargeData?.description ?? ""}
              ></textarea>
            </div>
            <div className="update__charge_reasonboxrt">
              <label
                for="update_charge_attachment"
                className="update_charge_attachment"
              >
                <input
                  type="file"
                  multiple
                  hidden
                  id="update_charge_attachment"
                  onChange={handleAttachmentChange}
                />
                <img src={AddImage} className="update_charge_atchmentimg" />
                <span>
                  {selectedAttachment?.length > 0
                    ? `${selectedAttachment?.length} file${
                        selectedAttachment?.length > 1 ? "s" : ""
                      } selected`
                    : "Upload Attachment"}
                </span>
              </label>
            </div>
          </div>
          <div className="update__charge_rblftbtns">
            <button
              className="update__charge_rblftbtncncl"
              onClick={() => handleChargeClose()}
            >
              Cancel
            </button>
            <button
              className="update__charge_rblftbtnsave"
              onClick={() => handleUpdateCharge(chargeDescription)}
            >
              {savingCharge === "update" ? (
                <CircularLoader size={16} color="grey" />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default BillingAdmin;
