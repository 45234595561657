import React, { useState, useEffect } from "react";
import "./editCategory.css";
// components imports
import Loader from "../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import { updateCategory } from "../../../../../services/warehouse.services";
// library imports
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useDispatch } from "react-redux";
import { showSnackBar } from "../../../../../store/common/snackBarSlice";

const EditCategory = ({ open, setOpen, setCategories }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
  });
  const dispatch = useDispatch();

  const handelChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (formData.name === "") {
      dispatch(showSnackBar({
        text: "Please enter category name",
        severity: "error",
      }))
      setLoading(false);
      return;
    }

    const response = await updateCategory(formData.id, { name: formData.name });
    // console.log("response", response.data.result);
    if (response.error) {
      dispatch(showSnackBar({
        text:
          response?.error?.response?.data?.message || "Something went wrong",
        severity: "error",
      }))
      setLoading(false);
    } else {
      dispatch(showSnackBar({
        text: "Category Updated Successfully",
        severity: "success",
      }))
      setCategories((prevCategories) => {
        return {
          ...prevCategories,
          data: prevCategories?.data?.map((p) => {
            return p.id === open?.id ? response?.data?.result : p;
          }),
        };
      });
      setLoading(false);
      setFormData({ name: "" });
      setOpen(false);
    }
  };

  useEffect(() => {
    setFormData({ name: open?.name, id: open?.id });
  }, [open]);

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen()}
        aria-labelledby="add-currency-modal-title"
        aria-describedby="add-currency-modal-description"
      >
        <div className="categoryPop__add_new_category_modal">
          <div className="categoryPop__add_new_category_modal_wrap">
            <div className="categoryPop__add_new_currency">
              <div className="categoryPop__add_new_category_header">
                <h3 className="categoryPop__add_new_category_title">
                  Edit Category
                </h3>
              </div>
              <div className="categoryPop__add_new_category_body">
                <FormControl variant="outlined" className="">
                  <InputLabel htmlFor="addCategoryName" size="small">
                    Category Name
                  </InputLabel>
                  <OutlinedInput
                    id="addCategoryName"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    name="name"
                    value={formData.name}
                    size="small"
                    onChange={(e) => handelChange(e)}
                    label="Category Name"
                  />
                </FormControl>

                <div className="categoryPop__edit_category_modalfooter">
                  <div
                    className="categoryPop__edit_category_modalreset"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </div>
                  <div
                    className="categoryPop__edit_category_modalsubmit"
                    onClick={handleSubmit}
                  >
                    {loading ? <Loader /> : "Save"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditCategory;
