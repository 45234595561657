import React, { useState } from "react";
import "./Carriers.css";

// components imports
import HajexCarriersTable from "../../../../../Components/BoltTable/BoltTable";
import Snackbar from "../../../../../Components/SnackBar/SnackBar";
import SwitchButton from "../../../../../Components/SwitchButton/SwitchButton";
import Tooltip from "../../../../../Components/Tooltip/Tooltip";
import ManageServices from "./Components/ManageServices/ManageServices";
import Confirm from "../../../../../Components/Popups/Confirm";
import UpdateAccount from "./Components/UpdateAccount/UpdateAccount";
import AddCustomCarrier from "./Components/AddCustomCarrier/AddCustomCarrier";

// apis imports
import { initialCarriersTab } from "../../../../../data/permissionsArray";
import {
  deleteCarrier,
  fetchHajexCarriers,
  updateCarrierStatus,
} from "../../../../../services/ShipperServices/Carriers/carriers.services";

// library imports
import { MdOutlineEditCalendar, MdOutlineCancel } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { FcPlus } from "react-icons/fc";
import TabsComponent from "../../../../../Components/Common/TabsComponent";

const Carriers = () => {
  const [tab, setTab] = useState(0);

  const [deleteLoader, setDeleteLoader] = useState(false);
  const [statusUpdating, setStatusUpdating] = useState();

  const [servicesModal, setServicesModal] = useState();
  const [updateAccountModal, setUpdateAccountModal] = useState();
  const [deleteCarrierModal, setDeleteCarrierModal] = useState(false);

  const [carriers, setCarriers] = useState([]);
  const [addAccountModal, setAddAccountModal] = useState();

  const [tabPermissions, setTabPermissions] = useState(initialCarriersTab);

  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  const columns = [
    {
      id: 1,
      label: "Status",
      field_name: "status",
      render: (row, rowIndex, handleFieldChange) => (
        <>
          <SwitchButton
            onChange={(e) => handleStatusChange(e, row)}
            checked={[true, "true", "1"].includes(row.is_enable?.user)}
            disabled={statusUpdating}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 2,
      label: "Carrier Name",
      field_name: "name",
      render: (row, rowIndex, handleFieldChange) => (
        <>{row?.shipping_company_for_user?.name ?? "-"}</>
      ),
      width: "200px",
    },
    {
      id: 3,
      label: "Carrier Image",
      field_name: "carrier_image",
      render: (row, rowIndex, handleFieldChange) => (
        <img src={row?.shipping_company_for_user?.logo_url} />
      ),
      width: "200px",
    },
    {
      id: 4,
      label: "Action",
      field_name: "action",
      render: (row, rowIndex, handleFieldChange) => (
        <>
          <div className="table_action__wrap">
            <Tooltip title="Manage Services">
              <div
                className="table_action__btn"
                onClick={() => setServicesModal(row)}
              >
                <MdOutlineEditCalendar size={10} />
                Services
              </div>
            </Tooltip>
          </div>
        </>
      ),
      width: "200px",
    },
  ];
  const columns2 = [
    {
      id: 1,
      label: "Status",
      field_name: "status",
      render: (row, rowIndex, handleFieldChange) => (
        <>
          <SwitchButton
            onChange={(e) => handleStatusChange(e, row)}
            checked={[true, "true", "1"].includes(row.is_enable?.user)}
            disabled={statusUpdating}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 2,
      label: "Account Name",
      field_name: "account_name",
      render: (row, rowIndex, handleFieldChange) => (
        <>{row?.account_name ?? "-"}</>
      ),
      width: "200px",
    },
    {
      id: 3,
      label: "Carrier Name",
      field_name: "name",
      render: (row, rowIndex, handleFieldChange) => (
        <>{row?.shipping_company_for_user?.name ?? "-"}</>
      ),
      width: "200px",
    },
    {
      id: 4,
      label: "Carrier Image",
      field_name: "carrier_image",
      render: (row, rowIndex, handleFieldChange) => (
        <img src={row?.shipping_company_for_user?.logo_url} />
      ),
      width: "200px",
    },
    {
      id: 5,
      label: "Action",
      field_name: "action",
      render: (row, rowIndex, handleFieldChange) => (
        <>
          <div className="table_action__wrap">
            <Tooltip title="Manage Services">
              <div
                className="table_action__btn"
                onClick={() => setServicesModal(row)}
              >
                <MdOutlineEditCalendar size={10} />
                Services
              </div>
            </Tooltip>
            <Tooltip title="Edit Account">
              <div
                className="table_action__btn"
                onClick={() => setUpdateAccountModal(row)}
              >
                <FaRegEdit size={10} />
                Edit
              </div>
            </Tooltip>
            <Tooltip title="Delete Account">
              <div
                className="table_action__btn"
                onClick={() => setDeleteCarrierModal(row.id)}
              >
                <MdOutlineCancel size={10} />
                Delete
              </div>
            </Tooltip>
          </div>
        </>
      ),
      width: "200px",
    },
  ];

  const getAllCarriers = async (page, perPage, filter) => {
    const response = await fetchHajexCarriers(
      tab === 0 ? 1 : 0,
      page,
      perPage,
      filter
    );

    if (!response.error) {
      setCarriers(response?.data?.data);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const handleStatusChange = async (e, record) => {
    setStatusUpdating(true);

    const updated = await updateCarrierStatus({
      record_id: parseInt(record.id),
      is_enable: e.target.checked,
    });
    if (updated.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: updated.error.message,
          severity: "error",
        };
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `Status of ${
            record.shipping_company_for_user?.name
          } changed to ${!e.target.checked ? "Active" : "Inactive"}`,
          severity: !e.target.checked ? "success" : "info",
        };
      });
      setCarriers((prevState) => {
        return {
          ...prevState,
          data: prevState?.data.map((item) =>
            item.id === record.id
              ? {
                  ...item,
                  is_enable: { ...item?.is_enable, user: !e.target.checked },
                }
              : item
          ),
        };
      });
    }
    setStatusUpdating(false);
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const deleteSingleCarrier = async () => {
    setDeleteLoader(true);
    let id = deleteCarrierModal;
    const deleted = await deleteCarrier(deleteCarrierModal);
    if (deleted.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Couldn't delete carrier",
          severity: "error",
        };
      });
      setDeleteLoader(false);
    } else {
      setCarriers((prevState) => {
        return {
          ...prevState,
          data: prevState?.data?.filter((carr) => carr.id !== id),
        };
      });
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Carrier deleted successfully",
          severity: "success",
        };
      });
      setDeleteLoader(false);
      setDeleteCarrierModal(false);
    }
  };

  return (
    <div className="carrier__dispatcher__screen">
      <Snackbar Snack={snack} SetSnack={setSnack} />

      <TabsComponent
        items={tabPermissions}
        tab={tab}
        handleTabChange={handleTabChange}
      />

      <AddCustomCarrier
        open={!!addAccountModal}
        data={addAccountModal}
        setOpen={setAddAccountModal}
        setCarriers={setCarriers}
        setSnack={setSnack}
        getAllCarriers={getAllCarriers}
      />
      <UpdateAccount
        open={!!updateAccountModal}
        preData={updateAccountModal}
        setOpen={setUpdateAccountModal}
        setCarriers={setCarriers}
        setSnack={setSnack}
      />
      <Confirm
        open={deleteCarrierModal}
        setOpen={setDeleteCarrierModal}
        handleConfirm={deleteSingleCarrier}
        text="Do you want to delete this custom carrier?"
        loader={deleteLoader}
      />
      <ManageServices
        open={servicesModal}
        setOpen={setServicesModal}
        setCarriers={setCarriers}
        setSnack={setSnack}
        Android12Switch={SwitchButton}
      />
      <div className="carriers__header">
        <div>
          <span className="carriers__hdrheading">
            {tab === 0 ? "Hajex Carriers" : "Custom Carriers"}
          </span>
        </div>
        {tab === 1 && (
          <div className="carriers__buttonsWrapper">
            <div onClick={() => setAddAccountModal(true)}>
              <div className="carriers__hdraddprod">
                <FcPlus size={20} />
                <span>ADD ACCOUNT</span>
              </div>
            </div>
          </div>
        )}
      </div>

      <div>
        <HajexCarriersTable
          message={`No ${
            tab === 0 ? "Hajex" : "Custom"
          } Carriers Available To Display!`}
          searchPlaceHolder={"Search Carriers"}
          columns={tab === 0 ? columns : columns2}
          data={carriers}
          getAllData={getAllCarriers}
          refresh={tab}
        />
      </div>
    </div>
  );
};

export default Carriers;
