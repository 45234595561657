import React, { useState } from "react";
import "./Payments.css";

// components imports
import Snackbar from "../../../Components/SnackBar/SnackBar";
import PaymentList from "./Components/PaymentList/PaymentList";
import PendingPayments from "./Components/PendingPayments/PendingPayments";
import PaymentsSettings from "./Components/PaymentsSettings/PaymentsSettings";
// apis imports
// library imports
import TabsComponent from "../../../Components/Common/TabsComponent";
import AdminTaxes from "./Components/Taxes/Taxes";

const Payments = () => {
  const [tab, setTab] = React.useState(0);
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div className="dispatcher__screen" style={{ maxWidth: "100%" }}>
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <TabsComponent items={[
        {
          label: "Payments",
          style: { fontWeight: "bold" },
          value: 0,
        },
        { label: "Pending Payments", style: { fontWeight: "bold" }, value: 1 },
        { label: "Payment Settings", style: { fontWeight: "bold" }, value: 2 },
        { label: "Taxes", style: { fontWeight: "bold" }, value: 3 },
      ]} tab={tab} handleTabChange={handleTabChange} />
      {tab === 0 && <PaymentList snack={snack} setSnack={setSnack} />}
      {tab === 1 && <PendingPayments snack={snack} setSnack={setSnack} />}
      {tab === 2 && <PaymentsSettings snack={snack} setSnack={setSnack} />}
      {tab === 3 && <AdminTaxes snack={snack} setSnack={setSnack} />}
    </div>
  );
};

export default Payments;
