import React from "react";
import "../../InvoiceModal.css";

const ShipmentSummary = ({ data }) => {
  return (
    <>
      <div className="invmodal__shipment_summary">
        {/* <div className="invmodal__shipment_summaryhding">Shipment summary</div> */}
        <div className="invmodal__shipment_summarycntnt">
        <div className="invmodal__shipment_summaryfrom">
            <div className="invmodal__shipment_sumitemhding">Ship from</div>
            <div className="invmodal__shipment_sumitem">
              <div className="invmodal__shipment_sumitemleft">Reference</div>
              <div className="invmodal__shipment_sumitemright">
                {data?.f_reference ?? "-"}
              </div>
            </div>
            <div className="invmodal__shipment_sumitem">
              <div className="invmodal__shipment_sumitemleft">Name</div>
              <div className="invmodal__shipment_sumitemright">
                {data?.fattension ?? "-"}
              </div>
            </div>
            <div className="invmodal__shipment_sumitem">
              <div className="invmodal__shipment_sumitemleft">Company</div>
              <div className="invmodal__shipment_sumitemright">
                {data?.fcompany ?? "-"}
              </div>
            </div>
            <div className="invmodal__shipment_sumitem">
              <div className="invmodal__shipment_sumitemleft">Address</div>
              <div className="invmodal__shipment_sumitemright">
                {data?.faddress ?? "-"}
              </div>
            </div>
            <div className="invmodal__shipment_sumitem">
              <div className="invmodal__shipment_sumitemleft">Country</div>
              <div className="invmodal__shipment_sumitemright">
                {data?.fcountry ?? "-"}
              </div>
            </div>
            <div className="invmodal__shipment_sumitem">
              <div className="invmodal__shipment_sumitemleft">State</div>
              <div className="invmodal__shipment_sumitemright">
                {data?.fstate ?? "-"}
              </div>
            </div>
            <div className="invmodal__shipment_sumitem">
              <div className="invmodal__shipment_sumitemleft">City</div>
              <div className="invmodal__shipment_sumitemright">
                {data?.fcity ?? "-"}
              </div>
            </div>
            <div className="invmodal__shipment_sumitem">
              <div className="invmodal__shipment_sumitemleft">Zip/Postal</div>
              <div className="invmodal__shipment_sumitemright">
                {data?.fzip ?? "-"}
              </div>
            </div>

            <div className="invmodal__shipment_sumitem">
              <div className="invmodal__shipment_sumitemleft">Phone</div>
              <div className="invmodal__shipment_sumitemright">
                {data?.fphone ?? "-"}
              </div>
            </div>
            <div className="invmodal__shipment_sumitem">
              <div className="invmodal__shipment_sumitemleft">Email</div>
              <div className="invmodal__shipment_sumitemright">
                {data?.femail ?? "-"}
              </div>
            </div>
          </div>
          <div className="invmodal__shipment_summaryto">
            <div className="invmodal__shipment_sumitemhding">Ship to</div>
            <div className="invmodal__shipment_sumitem">
              <div className="invmodal__shipment_sumitemleft">Reference</div>
              <div className="invmodal__shipment_sumitemright">
                {data?.referance_code ?? "-"}
              </div>
            </div>
            <div className="invmodal__shipment_sumitem">
              <div className="invmodal__shipment_sumitemleft">Name</div>
              <div className="invmodal__shipment_sumitemright">
                {data?.tattension ?? "-"}
              </div>
            </div>
            <div className="invmodal__shipment_sumitem">
              <div className="invmodal__shipment_sumitemleft">Company</div>
              <div className="invmodal__shipment_sumitemright">
                {data?.tcompany ?? "-"}
              </div>
            </div>
            <div className="invmodal__shipment_sumitem">
              <div className="invmodal__shipment_sumitemleft">Address</div>
              <div className="invmodal__shipment_sumitemright">
                {data?.taddress ?? "-"}
              </div>
            </div>
            <div className="invmodal__shipment_sumitem">
              <div className="invmodal__shipment_sumitemleft">Country</div>
              <div className="invmodal__shipment_sumitemright">
                {data?.tcountry ?? "-"}
              </div>
            </div>
            <div className="invmodal__shipment_sumitem">
              <div className="invmodal__shipment_sumitemleft">State</div>
              <div className="invmodal__shipment_sumitemright">
                {data?.tstate ?? "-"}
              </div>
            </div>
            <div className="invmodal__shipment_sumitem">
              <div className="invmodal__shipment_sumitemleft">City</div>
              <div className="invmodal__shipment_sumitemright">
                {data?.tcity ?? "-"}
              </div>
            </div>
            <div className="invmodal__shipment_sumitem">
              <div className="invmodal__shipment_sumitemleft">Zip/Postal</div>
              <div className="invmodal__shipment_sumitemright">
                {data?.tzip ?? "-"}
              </div>
            </div>

            <div className="invmodal__shipment_sumitem">
              <div className="invmodal__shipment_sumitemleft">Phone</div>
              <div className="invmodal__shipment_sumitemright">
                {data?.tphone ?? "-"}
              </div>
            </div>
            <div className="invmodal__shipment_sumitem">
              <div className="invmodal__shipment_sumitemleft">Email</div>
              <div className="invmodal__shipment_sumitemright">
                {data?.temail ?? "-"}
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default ShipmentSummary;
