import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import "./import.css";
import { Input, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { importProduct } from "../../../../services/product.service";
import CircularLoader from "../../../../Components/CircularLoader/CircularLoader";

const StyledUploadContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  border: "2px dashed #3f51b5",
  borderRadius: "8px",
  backgroundColor: "#f5f5f5",
  cursor: "pointer",
});

export default function ImportModal({
  open,
  setOpen,
  setSnack,
  getAllProducts,
}) {
  const [loading, setLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleUpload = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);

    const response = await importProduct(formData);
    if (!response.error) {
      getAllProducts(1, 20, "");
      setLoading(false);
      handleClose();
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: response.data.message,
          severity: "success",
        };
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFile(null);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="prod__dimension_modal ">
          <div className="prod__dimension_modalheading">
            Import Excel Data Of Products
          </div>
          <div className="prod__dimensionmdl_formbox " id="products_dimensions">
            <div className="prod__dimensionmdl_formrow">
              <div
                className="prod__dimensionmdl_formcol"
                style={{ minWidth: "120px" }}
              >
                <StyledUploadContainer>
                  <label htmlFor="file-upload">
                    <Input
                      id="file-upload"
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    <Typography variant="h6" color="primary" component="div">
                      Click to Upload Excel File
                    </Typography>
                  </label>
                  {selectedFile && (
                    <Typography variant="body1">{selectedFile.name}</Typography>
                  )}
                </StyledUploadContainer>
              </div>
            </div>
          </div>
          <div className="prod__dimension_modalfooter">
            <div
              className={`prod__dimension_modalcncl ${
                loading ? "prod__dimension_modal_disabled" : ""
              }`}
              onClick={handleClose}
            >
              Cancel
            </div>
            <div
              className={`prod__dimension_modalsave ${
                selectedFile === null ? "prod__dimension_modal_disabled" : ""
              }`}
              onClick={handleUpload}
            >
              {loading ? <CircularLoader /> : "Save"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
