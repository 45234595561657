import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import "./DescriptionModal.css";
import CircularLoader from "../../../../Components/CircularLoader/CircularLoader";

export default function DescriptionModal({ open, setOpen, setSnack }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setData({});
  };
  const handleChange = (e) => {
    // console.log(e.target.value);
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSave = async () => {
    // setLoading(true);
    if (open?.handleFieldChange) {
      open?.handleFieldChange(open?.rowIndex, "description", data?.description);
    }
    // setDrivers((drivers) => [...drivers, saved.data?.result]);
    setSnack((snack) => {
      return {
        ...snack,
        open: true,
        text: "Driver Added successfully",
        severity: "success",
      };
    });
    setLoading(false);
    handleClose();
  };
  useEffect(() => {
    if (open?.row?.description) {
      setData({ ...data, description: open?.row?.description });
    }
  }, [open]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="prdtable__desc_modal">
          <div className="prdtable__desc_modalheading">
            Update Product's Description.
          </div>
          <div className="prdtable__desc_modalcontent">
            <div className="prdtable__desc_modalrow">
              <div
                className="prdtable__desc_modalinputwrap"
                style={{ minWidth: "65%" }}
              >
                <textarea
                  className="prdtable__desc_modalinput"
                  placeholder="Product's Description"
                  name="description"
                  value={data.description}
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="prdtable__desc_modalfooter">
            <div className="prdtable__desc_modalcncl" onClick={handleClose}>
              Cancel
            </div>
            <div className="prdtable__desc_modalsave" onClick={handleSave}>
              {loading ? <CircularLoader /> : "Save"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
