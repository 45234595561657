import React, { useState, useEffect } from "react";
import "./SideBar.css";
import Person4Icon from "@mui/icons-material/Person4";
import TableChartIcon from "@mui/icons-material/TableChart";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Tooltip from "../../../../../../../Components/Tooltip/Tooltip";
const shipperSideBar = [
  {
    heading: "ROLES & PERMISSIONS",
    menus: [
      { name: "Manage Roles", url: "/settings/manage-roles" },
      { name: "Manage Permissions", url: "/settings/manage-permissions" },
    ],
    hidden: true,
  },
  {
    heading: "HELPER USERS",
    menus: [
      { name: "Manage Helper Users", url: "/settings/manage-helper-users" },
    ],
    hidden: true,
  },
  {
    heading: "WAREHOUSE",
    menus: [{ name: "Manage Warehouse", url: "/settings/manage-warehouse" }],
  },
  {
    heading: "GENERAL SETTINGS",
    menus: [
      {
        name: "Table Display & Pagination",
        url: "/settings/table-pagination",
        icon: <TableChartIcon className="shipper__sidebar__nitems_icon" />,
      },
    ],
  },
];
const warehouseSideBar = [
  {
    heading: "ROLES & PERMISSIONS",
    menus: [
      { name: "Manage Roles", url: "/warehouse/settings/manage-roles" },
      {
        name: "Manage Permissions",
        url: "/warehouse/settings/manage-permissions",
      },
    ],
    hidden: true,
  },
  {
    heading: "HELPER USERS",
    menus: [
      {
        name: "Manage Helper Users",
        url: "/warehouse/settings/manage-helper-users",
      },
    ],
    hidden: true,
  },
  {
    heading: "WAREHOUSE",
    menus: [{ name: "Manage Warehouse", url: "/settings/manage-warehouse" }],
    hidden: true,
  },
  {
    heading: "GENERAL SETTINGS",
    menus: [
      {
        name: "Table Display & Pagination",
        url: "/warehouse/settings/table-pagination",
        icon: <TableChartIcon className="shipper__sidebar__nitems_icon" />,
      },
    ],
  },
];
const adminSideBar = [
  {
    heading: "ROLES & PERMISSIONS",
    menus: [
      { name: "Manage Roles", url: "/admin/settings/manage-roles" },
      { name: "Manage Permissions", url: "/admin/settings/manage-permissions" },
    ],
    hidden: true,
  },
  {
    heading: "HELPER USERS",
    menus: [
      {
        name: "Manage Helper Users",
        url: "/admin/settings/manage-helper-users",
      },
    ],
    hidden: true,
  },
  {
    heading: "WAREHOUSE",
    menus: [{ name: "Manage Warehouse", url: "/settings/manage-warehouse" }],
    hidden: true,
  },
  {
    heading: "GENERAL SETTINGS",
    menus: [
      {
        name: "Table Display & Pagination",
        url: "/admin/settings/table-pagination",
        icon: <TableChartIcon className="shipper__sidebar__nitems_icon" />,
      },
    ],
  },
];
const carrierSideBar = [
  {
    heading: "ROLES & PERMISSIONS",
    menus: [
      { name: "Manage Roles", url: "/carrier/settings/manage-roles" },
      {
        name: "Manage Permissions",
        url: "/carrier/settings/manage-permissions",
      },
    ],
    hidden: true,
  },
  {
    heading: "HELPER USERS",
    menus: [
      {
        name: "Manage Helper Users",
        url: "/carrier/settings/manage-helper-users",
      },
    ],
    hidden: true,
  },
  {
    heading: "WAREHOUSE",
    menus: [{ name: "Manage Warehouse", url: "/settings/manage-warehouse" }],
    hidden: true,
  },
  {
    heading: "GENERAL SETTINGS",
    menus: [
      {
        name: "Table Display & Pagination",
        url: "/carrier/settings/table-pagination",
        icon: <TableChartIcon className="shipper__sidebar__nitems_icon" />,
      },
    ],
  },
];

const shipperUserSiderBar = [
  {
    heading: "ROLES & PERMISSIONS",
    menus: [
      { name: "Manage Roles", url: "/carrier/settings/manage-roles" },
      {
        name: "Manage Permissions",
        url: "/carrier/settings/manage-permissions",
      },
    ],
    hidden: true,
  },
  {
    heading: "HELPER USERS",
    menus: [
      {
        name: "Manage Helper Users",
        url: "/carrier/settings/manage-helper-users",
      },
    ],
    hidden: true,
  },
  {
    heading: "WAREHOUSE",
    menus: [{ name: "Manage Warehouse", url: "/settings/manage-warehouse" }],
    hidden: true,
  },
  {
    heading: "GENERAL SETTINGS",
    menus: [
      {
        name: "Table Display & Pagination",
        url: "/carrier/settings/table-pagination",
        icon: <TableChartIcon className="shipper__sidebar__nitems_icon" />,
      },
    ],
    hidden: true,
  },
];
const SideBar = ({ active }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [sideBarMenus, setSideBarMenus] = useState([]);

  useEffect(() => {
    setSideBarMenus(
      user.role === "Shipper"
        ? shipperSideBar
        : user.role === "warehouseUser"
        ? warehouseSideBar
        : user.role === "Carrier"
        ? carrierSideBar
        : user.role === "Admin"
        ? adminSideBar
        : shipperUserSiderBar
    );
  }, []);

  return (
    <div className="shipper__sidebar_wrapper">
      <div className="shipper__sidebar__itemsbox ">
        {sideBarMenus?.map(({ heading, menus, hidden }, index) => {
          return (
            <Tooltip title={hidden ? "Coming Soon" : ""} key={index}>
              <div className="shipper__sidebar__itemsactive">
                <div
                  className="shipper__sidebar_heading"
                  style={{
                    pointerEvents: hidden ? "none" : "visible",
                  }}
                >
                  {heading}
                </div>
                {menus.map(({ name, url, icon }, mindex) => {
                  return (
                    <div
                      style={{
                        pointerEvents: hidden ? "none" : "visible",
                      }}
                      className={
                        active === (index + "_" + mindex).toString()
                          ? "shipper__sidebar__nitems_parent shipper__sidebar__active_class"
                          : "shipper__sidebar__nitems_parent"
                      }
                      key={mindex}
                      onClick={() => navigate(url)}
                    >
                      <div>
                        {icon ?? (
                          <Person4Icon className="shipper__sidebar__nitems_icon" />
                        )}
                      </div>
                      <div className="shipper__sidebar__nitems">{name}</div>
                    </div>
                  );
                })}
                <div className="shipper__sidebar__divider_line"></div>
              </div>
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
};

export default SideBar;
