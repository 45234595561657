import React, { useState, useEffect } from "react";

//componenets

// apis imports
import { getAllSuppliersList } from "../../../../services/warehouse.services";

// library imports
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "@mui/material/TextField";
import Barcode from "react-barcode";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const StockReceivedGeneralDetails = ({
  modeType,
  formData,
  handelChange,
  user,
  setSnack,
}) => {
  const [suppliers, setSuppliers] = useState([]);

  const fetchAllSuppliers = async () => {
    const response = await getAllSuppliersList("", "", "");
    if (!response.error) {
      setSuppliers(response?.data?.result?.data);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  useEffect(() => {
    if (modeType != "view") {
      fetchAllSuppliers();
    }
  }, []);

  const renderStockDetails = () => {
    return (
      <div
        className="card bg-white shadow stockRcieved__genral_details_wrap"
        style={{
          borderRadius: "15px",
          padding: "15px",
          marginTop: "10px",
          minHeight: "140px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <div
            style={{
              width: "50%",
              marginTop: "10px",
            }}
          >
            {modeType === "add" ? (
              <TextField
                size="small"
                className="w-100"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                name="grn_no"
                value={formData?.grn_no}
                onChange={(e) => handelChange(e)}
                label="Stock ID"
                variant="outlined"
                sx={{
                  input: { font: "menu" },
                }}
              />
            ) : (
              <div className="stockRcieved__genral_stock_id">
                <div className="stockRcieved__genral_stock_id_text">Purchase Order</div>
                <div className="stockRcieved__genral_stock_id_num">
                  {formData?.grn_no ?? "-"}
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              width: "50%",
              minHeight:"50px",
              display: "flex",
              justifyContent: "flex-end",
            }}
            className="stockRcieved__genral_barcode_wrap"
          >
            {formData?.grn_no && modeType != "add" && (
              <Barcode
                value={formData?.grn_no}
                format="CODE128"
                width={1}
                height={20}
                displayValue={true}
                fontOptions="bold"
                font="monospace"
                textAlign="center"
                textPosition="bottom"
                textMargin={1}
                fontSize={13}
                background="#ffffff"
                lineColor="#000000"
                margin={8}
              />
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderTop: "0.5px solid lightgray",
            marginTop: "10px",
            gap: "10px",
          }}
          className="stockRcieved__genral_destination_wrap"
        >
          <div
            style={{
              width: "50%",
              marginTop: "10px",
            }}
          >
            <div className="stockRcieved__genral_destination_wrap_text">Destination</div>
            {modeType === "view" || user?.role === "warehouseUser" ? (
              <div className="stockRcieved__genral_destination_wrap_value">
                {formData?.warehouse?.name ?? "-"}
              </div>
            ) : (
              <div className="add__adj_byform_col stockRcieved__genral_details_wrap_destination">
                <FormControl fullWidth size="small" variant="outlined">
                  <InputLabel
                    id="demo-simple-select-label"
                    size="small"
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    Select Warehouse
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="warehouse_id"
                    required
                    value={formData?.warehouse_id || ""}
                    label="Select Warehouse"
                    onChange={(e) =>
                      handelChange({
                        target: {
                          name: "warehouse_id",
                          value: e.target.value,
                        },
                      })
                    }
                    size="small"
                    sx={{
                      input: { font: "menu" },
                      fontSize: "11px",
                    }}
                  >
                    {user?.user_approved_warehouses?.map((warehouse, index) => (
                      <MenuItem value={warehouse?.warehouse_id} key={index}>
                        #{warehouse?.warehouse_id} :{" "}
                        {warehouse?.warehouse?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
          </div>

          <div style={{ borderRight: "0.5px solid lightgray" }}></div>

          <div className="stockRcieved__genral_supplier"  style={{ width: "50%", marginTop: "10px" }}>
            <div className="stockRcieved__genral_supplier_title">Supplier</div>

            {modeType === "view" || user?.role === "warehouseUser" ? (
              <div className="stockRcieved__genral_supplier_value">
                {formData?.supplier?.name ?? "-"}
              </div>
            ) : (
              <div className="add__adj_byform_col stockRcieved__genral_details_wrap_supplier">
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    id="demo-simple-select-autowidth-label"
                    size="small"
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    Select Supplier
                  </InputLabel>
                  <Select
                    size="small"
                    onChange={(e) =>
                      handelChange({
                        target: {
                          name: "supplier_id",
                          value: e.target.value,
                        },
                      })
                    }
                    labelId="demo-simple-select-autowidth-label"
                    label="Select Supplier"
                    value={formData?.supplier_id || ""}
                    sx={{
                      fontSize: "11px",
                    }}
                  >
                    {suppliers && suppliers.length > 0 ? (
                      suppliers.map((s) => (
                        <MenuItem key={s.id} value={s.id}>
                          {s.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={formData?.supplier?.name}>
                        {formData?.supplier?.name}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderShipmentDetails = () => {
    return (
      <div
        className="card bg-white shadow"
        style={{
          borderRadius: "15px",
          padding: "15px",
          marginTop: "10px",
          minHeight: "140px",
        }}
      >
        <div
          style={{
            marginBottom: "5px",
            borderBottom:
              modeType === "view" || user?.role === "warehouseUser"
                ? " 0.5px solid lightgray"
                : "",
          }}
          className="stockRcieved__genral_shipment_details_wrap"
        >
          <div className="stockRcieved__genral_shipment_details_heading">Shipment Details</div>
        </div>

        {modeType === "view" || user?.role === "warehouseUser" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              fontSize: "13px",
            }}
          >
            <div className="stockRcieved__genral_shipment_details_title">Estimated Arrival Date</div>
            <div className="stockRcieved__genral_shipment_details_content">{formData?.arrival_date ?? "-"}</div>
          </div>
        ) : (
          <div
            style={{
              marginBottom: "15px",
              marginTop: "10px",
            }}
          >
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={
                    formData?.arrival_date
                      ? dayjs(formData.arrival_date)
                      : dayjs().startOf("day")
                  }
                  label="Estimated Arrival Date"
                  views={["year", "month", "day"]}
                  onChange={(value) =>
                    handelChange({
                      target: {
                        name: "arrival_date",
                        value: value,
                      },
                    })
                  }
                  slotProps={{
                    textField: { size: "small" },
                  }}
                  sx={{
                    input: { font: "menu" },
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </div>
        )}

        {modeType === "view" || user?.role === "warehouseUser" ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                fontSize: "13px",
              }}
            >
              <div className="stockRcieved__genral_shipment_details_title">Shipper Carrier</div>
              <div className="stockRcieved__genral_shipment_details_content">{formData?.carrier_name ?? "-"}</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                fontSize: "13px",
              }}
            >
              <div className="stockRcieved__genral_shipment_details_title">Tracking Number</div>
              <div className="stockRcieved__genral_shipment_details_content">{formData?.tracking_id}</div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              marginTop: "10px",
            }}
          >
            <TextField
              size="small"
              fullWidth
              label="Shipper Carrier"
              variant="outlined"
              name="carrier_name"
              value={formData.carrier_name}
              onChange={(e) => handelChange(e)}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                input: { font: "menu" },
              }}
            />
            <TextField
              size="small"
              fullWidth
              label="Tracking Number"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              name="tracking_id"
              value={formData?.tracking_id}
              onChange={(e) => handelChange(e)}
              sx={{
                input: { font: "menu" },
              }}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <Row>
        <Col md={6}>{renderStockDetails()}</Col>
        <Col md={6}>{renderShipmentDetails()}</Col>
      </Row>
    </div>
  );
};

export default StockReceivedGeneralDetails;
