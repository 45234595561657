import React from "react";
import TextFeild from "../../Components/CustomTextFeild/TextFeild";
import { MdOutlineErrorOutline } from "react-icons/md";

const AddDetail = ({
  addDetail,
  isEditable,
  paymentFormula,
  handleChangeDetails,
  formulaDetails,
  error,
}) => {
  return (
    isEditable === true && (
      <>
        <div className="payment__formula_formrow">
          <div className="payment__formula_formcol">
            <TextFeild
              Name="min"
              Label="Min"
              Required={false}
              Disabled={paymentFormula?.formula_details?.length === 0}
              Data={formulaDetails.min}
              Adroment={paymentFormula?.type === "by_shipment" ? "Cn" : "$"}
              Handler={(e) => handleChangeDetails(e)}
            />
          </div>
          <div className="payment__formula_formcol">
            <TextFeild
              Label="Max"
              Data={formulaDetails.max}
              Name="max"
              Required={false}
              Adroment={paymentFormula?.type === "by_shipment" ? "Cn" : "$"}
              Handler={(e) => handleChangeDetails(e)}
            />
          </div>

          <button
            className="prodidentifier__desc_modalsave prodidentifier__formula_details"
            onClick={addDetail}
          >
           <span>Add </span> +
          </button>
        </div>

        <div
          style={{ justifyContent: "start", padding: "0px" }}
          className={`assign__pickup_modalerrorwrap ${
            error && "assign__pickup_modalerrorwrapopen"
          }`}
        >
          <div className="assign__pickup_modalerrorbox">
            <span>
              <MdOutlineErrorOutline size={16} style={{ marginTop: "2px" }} />
            </span>
            {error}
          </div>
        </div>
      </>
    )
  );
};

export default AddDetail;
