import React from "react";
import "./Requirements.css";
const UpsRequirements = () => {
  return (
    <div className="cuscarrier__reqrmnts_box">
      Contact Ups at
      <a href={`tel:1-800-782-7892`} className="email_space">
        1-800-782-7892
      </a>
      to obtain your Client Id, Client Secret and Account no. These API
      credentials will allow you to connect your custom Ups account to the
      application, enabling you to access Ups shipping rates for your account
      and display them as quotes.
    </div>
  );
};

export default UpsRequirements;
