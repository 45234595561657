import React from "react";
import "../InvoiceModal.css";

const ShipmentDetails = ({ data }) => {
  const keysToDisplay = Object.keys(data?.details);

  const formatValue = (value) => {
    return typeof value === "number" ? value.toFixed(2) : "N/A";
  };

  return (
    <>
      <div className="invmodal__shipment_details">
        <div className="invmodal__shipment_detailshding">Tax Details</div>
        <table className="invmodal__shdetails_table">
          <thead>
            <th className="invmodal__shdetails_tablehd">#</th>
            {keysToDisplay.map((key) => (
              <th className="invmodal__shdetails_tablehd">{key}</th>
            ))}
            <th className="invmodal__shdetails_tablehd">Total Tax</th>
          </thead>
          <tbody>
            {" "}
            <tr>
              {" "}
              <td className="invmodal__shdetails_tableitem"> </td>
              {keysToDisplay.map((key) => (
                <td className="invmodal__shdetails_tableitem">
                  {formatValue(data?.details[key])}{" "}
                </td>
              ))}
              <td className="invmodal__shdetails_tableitem">
                {data?.totalTax ?? "N/A"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ShipmentDetails;
