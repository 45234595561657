import React, { useState, useEffect } from "react";
import "./DriverForm.css";

// components imports
import Loader from "../../../../../Components/CircularLoader/CircularLoader";

// apis imports
import {
  createNewDriver,
  editDriver,
} from "../../../../../services/drivers.services";
import { fetchAllVehicles } from "../../../../../services/vehicles.services";

// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Autocomplete from "@mui/material/Autocomplete";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Upload from "@mui/icons-material/CloudUpload";

const initialData = {
  username: "",
  name: "",
  email: "",
  phone: "",
  start_time: "",
  end_time: "",
  vehicle: "",
  picture: null,
  password: "",
};

export default function DriverForm({ open, setOpen, setSnack, setRefresh }) {
  const [data, setData] = useState(initialData);
  const [confirmPassword, setConfirmPassword] = useState("");

  const [vehicles, setVehicles] = useState([]);

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setData(initialData);
    setOpen(false);
  };

  const getAllVehicles = async (page, perPage, filter) => {
    const response = await fetchAllVehicles(page, perPage, filter);
    if (!response.error) {
      setVehicles(response?.data?.result?.data);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  useEffect(() => {
    getAllVehicles("1", "100", "");
    if (open?.id) {
      setData({
        id: open?.id ?? "",
        username: open?.driver?.username ?? "",
        name: open?.driver?.name ?? "",
        email: open?.driver?.email ?? "",
        phone: open?.driver?.phone ?? "",
        start_time: open?.driver?.start_time ?? "",
        end_time: open?.driver?.end_time ?? "",
        vehicle: open?.driver?.vehicle?.id ?? "",
      });
    } else {
      setData(initialData);
    }
  }, [open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleFileChange = (e) => {
    setData({ ...data, picture: e.target.files[0] });
  };
  function validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  const handleSave = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      if (data.password.length < 5) {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: `The password must be at least 5 characters.`,
            severity: "error",
          };
        });
        setLoading(false);
        return;
      }
      const emailValidated = validateEmail(data.email);
      if (!emailValidated) {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: `Invalid Email address`,
            severity: "error",
          };
        });
        setLoading(false);
        return;
      }
      const formData = new FormData();
      formData.append("username", data.username);
      formData.append("name", data.name);
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("start_time", data.start_time);
      formData.append("end_time", data.end_time);
      formData.append("vehicle", data.vehicle);
      formData.append("picture", data.picture);
      formData.append("password", data.password);
      const saved = await createNewDriver(formData);

      if (saved.error) {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: saved?.error?.response?.data?.email
              ? saved?.error?.response?.data?.email[0]
              : saved?.error?.response?.data?.message || "Something went wrong",
            severity: "error",
          };
        });
        setLoading(false);
      } else {
        setRefresh(Math.floor(Math.random() * 100) + 1);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: saved?.data?.message,
            severity: "success",
          };
        });
        setData(initialData);
        setConfirmPassword("");
        setLoading(false);
        handleClose();
      }
    } catch (error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: error?.response?.data?.message || "Something went wrong",
          severity: "error",
        };
      });
      setLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("username", data.username);
      formData.append("name", data.name);
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("start_time", data.start_time);
      formData.append("end_time", data.end_time);
      formData.append("vehicle", data.vehicle);
      data.picture && formData.append("picture", data.picture);
      const saved = await editDriver(formData, data.id);
      if (saved.error) {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: saved?.error?.response?.data?.email
              ? saved?.error?.response?.data?.email[0]
              : saved?.error?.response?.data?.message || "Something went wrong",
            severity: "error",
          };
        });
        setLoading(false);
      } else {
        setRefresh(Math.floor(Math.random() * 100) + 1);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: saved?.data?.message,
            severity: "success",
          };
        });
        setLoading(false);
        handleClose();
      }
    } catch (error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: error?.response?.data?.message || "Something went wrong",
          severity: "error",
        };
      });
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Form onSubmit={open?.id ? handleUpdate : handleSave}>
          <div className="manage__driver_modal">
            <div className="manage__driver_modalheading">
              {open?.id ? "Update" : "Add"} Driver
            </div>
            <div className="manage__driver_modalcontent">
              <div className="manage__driver_modalrow">
                <div
                  className="manage__driver_modalinputwrap"
                  style={{ minWidth: "65%" }}
                >
                  <Form.Label>Full name</Form.Label>
                  <Form.Control
                    required
                    className="manage__driver_modalinput"
                    placeholder="Full name"
                    name="name"
                    value={data.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="manage__driver_modalinputwrap">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    required
                    className="manage__driver_modalinput"
                    placeholder="Username"
                    name="username"
                    value={data.username}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="manage__driver_modalrow">
                <div
                  className="manage__driver_modalinputwrap"
                  style={{ minWidth: "65%" }}
                >
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    className="manage__driver_modalinput"
                    placeholder="Email"
                    name="email"
                    value={data.email}
                    onChange={handleChange}
                    disabled={open?.id}
                  />
                </div>
                <div className="manage__driver_modalinputwrap">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    className="manage__driver_modalinput"
                    placeholder="Phone"
                    name="phone"
                    value={data.phone}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="manage__driver_modalrow">
                <div className="manage__driver_modalinputwrap">
                  <Form.Label>Vehicle</Form.Label>
                  <Autocomplete
                    sx={{
                      display: "inline-block",
                      width: "100%",
                    }}
                    onChange={(e, value) =>
                      setData({ ...data, vehicle: value.id })
                    }
                    id="custom-input-demo"
                    options={vehicles}
                    value={
                      data?.vehicle
                        ? vehicles.find((v) => v?.id === data?.vehicle) || null
                        : null
                    }
                    getOptionLabel={(option) =>
                      `${option.license_plate}, ${option.model}, ${option.type}`
                    }
                    renderInput={(params) => (
                      <div ref={params.InputProps.ref}>
                        <Form.Control
                          type="text"
                          {...params.inputProps}
                          // required
                          placeholder="Vehicle"
                          className="manage__driver_modalinput"
                          name="vehicle"
                        />
                      </div>
                    )}
                  />
                </div>
              </div>

              <div className="manage__driver_modalrow">
                <div className="manage__driver_modallabel">Work Time: </div>
                <div className="manage__driver_modalinputwrap">
                  <Form.Control
                    required
                    className="manage__driver_modalinput"
                    type="time"
                    value={data.start_time}
                    name="start_time"
                    onChange={handleChange}
                  />
                </div>
                <div className="manage__driver_modallabel2">to </div>
                <div className="manage__driver_modalinputwrap">
                  <Form.Control
                    required
                    className="manage__driver_modalinput"
                    type="time"
                    name="end_time"
                    value={data.end_time}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="manage__driver_modalrow">
                <div className="manage__driver_modalinputwrap">
                  <Form.Label className="manage__driver_profimagelbl">
                    <Upload /> {data.picture?.name ?? "Upload profile picture"}
                    <Form.Control
                      type="file"
                      size="60"
                      className="manage__driver_profimageinput"
                      onChange={handleFileChange}
                      accept=".jpg,.png"
                    />
                  </Form.Label>
                </div>
              </div>

              {!open?.id && (
                <div className="manage__driver_modalrow">
                  <div className="manage__driver_modalinputwrap">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      required
                      className="manage__driver_modalinput"
                      placeholder="Password"
                      name="password"
                      value={data.password}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="manage__driver_modalinputwrap">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      required
                      className="manage__driver_modalinput"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      isInvalid={data.password != confirmPassword}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="manage__driver_modalfooter">
              <div className="manage__driver_modalcncl" onClick={handleClose}>
                Cancel
              </div>
              <Button
                className="manage__driver_modalsave"
                disabled={
                  loading ||
                  (open?.id ? false : data.password != confirmPassword)
                }
                type="submit"
              >
                {loading ? <Loader /> : "Save"}
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
