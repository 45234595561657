import React from "react";
import "./CarrierInnerTableLoader.css";
const CarrierInnerTableLoader = () => {
  return (
    <div className="carrierinner__table_loader">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default CarrierInnerTableLoader;
