import React, { useState, useRef, useEffect } from "react";
import "./suppliers.css";

// components imports
import SupplierTable from "../../../Components/BoltTable/BoltTable";
import Confirm from "../../../Components/Popups/Confirm";
import Tooltip from "../../../Components/Tooltip/Tooltip";

// apis imports
import {
  getAllSuppliersList,
  deleteSupplier,
} from "../../../services/warehouse.services";
// library imports
import { MdOutlineCancel } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { FcPlus } from "react-icons/fc";
import EditSupplier from "./Components/EditSupplier/EditSupplier";
import AddSupplier from "./Components/AddSupplier/AddSupplier";

const Suppliers = ({ snack, setSnack }) => {
  const [addSupplierOpen, setAddSupplierOpen] = useState(false);
  const [editSupplierOpen, setEditSupplierOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [deleteLoader, setdeleteLoader] = useState(false);

  const [suppliers, setSuppliers] = useState([]);

  const columns = [
    {
      id: 1,
      label: "Name",
      render: (row) => <>{row?.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 2,
      label: "Address",
      render: (row) => <>{row?.address ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Website",
      render: (row) => <>{row?.website ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "Email",
      render: (row) => <>{row?.email ?? "-"}</>,
      width: "200px",
    },
    {
      id: 5,
      label: "Phone",
      render: (row) => <>{row?.phone ?? "-"}</>,
      width: "200px",
    },
    {
      id: 6,
      label: "Action",
      render: (row) => (
        <>
          <div className="table_action__wrap">
            <Tooltip title="Edit User Details">
              <div
                className="table_action__btn"
                onClick={() => setEditSupplierOpen(row)}
              >
                <FaRegEdit size={10} />
                Edit
              </div>
            </Tooltip>

            <Tooltip title="Delete User">
              <div
                className="table_action__btn"
                onClick={() => setConfirmDelete(row)}
              >
                <MdOutlineCancel size={10} />
                Delete
              </div>
            </Tooltip>
          </div>
        </>
      ),
      width: "200px",
    },
  ];

  const fetchAllSuppliers = async (page, perPage, filter) => {
    const response = await getAllSuppliersList(page, perPage, filter);

    if (!response.error) {
      setSuppliers(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const handleDeleteRequest = async (data) => {
    if (data.id) {
      setdeleteLoader(true);
      const response = await deleteSupplier(data.id);

      if (!response.error && response?.data?.success) {
        setSuppliers((prevSuppliers) => {
          return {
            ...prevSuppliers,
            data: prevSuppliers?.data?.filter((d) => d?.id != data?.id),
          };
        });
        setdeleteLoader(false);
        setConfirmDelete(false);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Request deleted successfully",
            severity: "success",
          };
        });
      } else {
        setdeleteLoader(false);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text:
              response?.error?.response?.data?.message ||
              "Couldn't cancel this request!",
            severity: "error",
          };
        });
      }
    }
  };
  return (
    <>
      <Confirm
        open={confirmDelete}
        setOpen={setConfirmDelete}
        handleConfirm={() => handleDeleteRequest(confirmDelete)}
        text="Do You Want To Delete This Request ?"
        loader={deleteLoader}
      />

      <div className="supplier_header">
        <div>
          <span className="supplier_hdrheading">Suppliers List</span>
        </div>
        <div className="supplier_buttonsWrapper">
          <div onClick={() => setAddSupplierOpen(true)}>
            <div className="supplier_hdraddprod">
              <FcPlus size={10} />
              <span>ADD SUPPLIER</span>
            </div>
          </div>
        </div>
      </div>

      <div>
        <SupplierTable
          message={"No Supplier Available To Display!"}
          searchPlaceHolder={"Search Suppliers"}
          columns={columns}
          data={suppliers}
          getAllData={fetchAllSuppliers}
          refresh={refresh}
        />
      </div>

      <AddSupplier
        open={addSupplierOpen}
        setOpen={setAddSupplierOpen}
        setRefresh={setRefresh}
        setSnack={setSnack}
      />
      <EditSupplier
        open={editSupplierOpen}
        setOpen={setEditSupplierOpen}
        setSuppliers={setSuppliers}
        setSnack={setSnack}
      />
    </>
  );
};
export default Suppliers;
