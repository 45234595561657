import React from "react";
import "./ManageWarehouseFormula.css";
// components imports
import WarehouseFormula from "../../../../../Payments/Components/WarehouseFormula/WarehouseFormula";
// apis imports
// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";

const ManageWarehouseFormula = ({ open, setOpen, setSnack, updateIndex }) => {

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className="manage__warehouseformula_modal">
        <WarehouseFormula
          user={open}
          setSnack={setSnack}
          setOpen={setOpen}
          updateIndex={updateIndex}
        />
      </div>
    </Modal>
  );
};

export default ManageWarehouseFormula;
