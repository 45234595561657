import axios from "../axiosInstance";

export const getStorageMap = async () => {
    let response = { data: null, error: null };
    try {
        const res = await axios.get(
            `warehouse/storage-map/get
        `
        );
        response = { ...response, data: res.data };
    } catch (error) {
        response = { ...response, error: error };
    }
    return response;
};
export const updateStorageMap = async (id, data) => {
    let response = { data: null, error: null };
    try {
        const res = await axios.post(
            `warehouse/storage-map/update/${id}`,
            data
        );
        response = { ...response, data: res.data };
    } catch (error) {
        response = { ...response, error: error };
    }
    return response;
};
export const storeStorageMap = async (data) => {
    let response = { data: null, error: null };
    try {
        const res = await axios.post(`warehouse/storage-map/store`, data);
        response = { ...response, data: res.data };
    } catch (error) {
        response = { ...response, error: error };
    }
    return response;
};
export const assignLocationToMap = async (mapId, data) => {
    let response = { data: null, error: null };
    try {
        const res = await axios.post(`warehouse/storage-map/manage-location/assign-location/${mapId}`, data);
        response = { ...response, data: res.data };
    } catch (error) {
        response = { ...response, error: error };
    }
    return response;
};
export const getStorageLocations = async (page, perPage, filter, Nested) => {
    let response = { data: null, error: null };
    try {
        const res = await axios.get(`warehouse/product-location/pallet-location-paginated?perPage=${perPage}&page=${page}&filterValue=${filter}${Nested}`);
        response = { ...response, data: res.data };
    } catch (error) {
        response = { ...response, error: error };
    }
    return response;
};

export const deleteStorageLocations = async (id) => {
    let response = { data: null, error: null };
    try {
        const res = await axios.delete(`warehouse/product-location/pallet-location/${id}`);
        response = { ...response, data: res.data };
    } catch (error) {
        response = { ...response, error: error };
    }
    return response;
};