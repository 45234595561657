import React from "react";
import "../../InvoiceModal.css";

const International = ({ data }) => {
  return (
    <>
      {data?.international !== null && data?.international?.length !== 0 && (
        <>
          <div className="invmodal__shipment_summary">
            <div className="invmodal__shipment_summaryhding internation__declaration_header">
              Customs Declaration Forms summary
            </div>
            <div className="invmodal__shipment_summarycntnt">
              <div className="invmodal__shipment_summaryto">
                <div className="invmodal__shipment_sumitemhding">
                  Product Information
                </div>
                <div className="invmodal__shipment_sumitem">
                  <div className="invmodal__international_sumitemleft">
                    Description
                  </div>
                  <div className="invmodal__shipment_sumitemright">
                    {data?.international?.product?.Description ?? "-"}
                  </div>
                </div>

                <div className="invmodal__shipment_sumitem">
                  <div className="invmodal__international_sumitemleft">
                    Country
                  </div>
                  <div className="invmodal__shipment_sumitemright">
                    {data?.international?.product?.Country ?? "-"}
                  </div>
                </div>

                <div className="invmodal__shipment_sumitem">
                  <div className="invmodal__international_sumitemleft">
                    Insurance Amount
                  </div>
                  <div className="invmodal__shipment_sumitemright">
                    {data?.international?.product?.InsuranceAmount ?? "-"}
                  </div>
                </div>
                <div className="invmodal__shipment_sumitem">
                  <div className="invmodal__international_sumitemleft">
                    Reason For Export
                  </div>
                  <div className="invmodal__shipment_sumitemright">
                    {data?.international?.product?.ReasonForExport ?? "-"}
                  </div>
                </div>
                <div className="invmodal__shipment_sumitem">
                  <div className="invmodal__international_sumitemleft">
                    Monetary Amount
                  </div>
                  <div className="invmodal__shipment_sumitemright">
                    {data?.international?.product?.TotalPriceAmount ?? "-"}
                  </div>
                </div>
              </div>
              <div className="invmodal__shipment_summaryfrom">
                <div className="invmodal__shipment_sumitemhding">
                  Producer Information
                </div>
                <div className="invmodal__shipment_sumitem">
                  <div className="invmodal__international_sumitemleft">
                    Company Name
                  </div>
                  <div className="invmodal__shipment_sumitemright">
                    {data?.international?.producer?.CompanyName ?? "-"}
                  </div>
                </div>
                <div className="invmodal__shipment_sumitem">
                  <div className="invmodal__international_sumitemleft">
                    Country
                  </div>
                  <div className="invmodal__shipment_sumitemright">
                    {data?.international?.producer?.Country ?? "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default International;
