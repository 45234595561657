import React, { useState } from "react";
import "./Plugins.css";
import { updateCarrierStatus } from "../../../../../services/shipments.services";
import { fetchHajexCarriers } from "../../../../../services/ShipperServices/Carriers/carriers.services";

import frame1 from "../../../../../Components/Images/Frame 1.png";
import frame2 from "../../../../../Components/Images/Frame 2.png";
import frame3 from "../../../../../Components/Images/Frame 3.png";
import frame4 from "../../../../../Components/Images/Frame 4.png";
import frame5 from "../../../../../Components/Images/Frame 5.png";
import frame6 from "../../../../../Components/Images/Frame 6.png";

import { MdOutlineEditCalendar, MdArrowBackIos } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import ManageServices from "./Components/ManageServices/ManageServices";

import SwitchButton from "../../../../../Components/SwitchButton/SwitchButton";
import Snackbar from "../../../../../Components/SnackBar/SnackBar";
import BoltTable from "../../../../../Components/BoltTable/BoltTable";
import Tooltip from "../../../../../Components/Tooltip/Tooltip";
import TabsComponent from "../../../../../Components/Common/TabsComponent";

const Plugins = () => {
  const [statusUpdating, setStatusUpdating] = useState();
  const [carriers, setCarriers] = useState([]);
  const [servicesModal, setServicesModal] = useState();
  const [tab, setTab] = useState(0);
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  const user = useSelector((state) => state.user);
  const { plugin } = useParams();

  const handleStatusChange = async (e, record) => {
    setStatusUpdating(true);
    setCarriers((prevState) => {
      return {
        ...prevState,
        data: prevState?.data?.map((item) =>
          item.id === record.id
            ? {
              ...item,
              is_enable: {
                ...item?.is_enable,
                [plugin]: e.target.checked,
              },
            }
            : item
        ),
      };
    });
    const updated = await updateCarrierStatus(user.token, {
      record_id: parseInt(record.id),
      request_from: plugin,
    });
    if (updated.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: updated.error.message,
          severity: "error",
        };
      });
      setCarriers((prevState) => {
        return {
          ...prevState,
          data: prevState?.data?.map((item) =>
            item.id === record.id
              ? {
                ...item,
                is_enable: {
                  ...item?.is_enable,
                  [plugin]: !e.target.checked,
                },
              }
              : item
          ),
        };
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `Status of Plugin changed to ${e.target.checked ? "Active" : "Inactive"
            }`,
          severity: e.target.checked ? "success" : "info",
        };
      });
    }
    setStatusUpdating(false);
  };

  const columns = [
    {
      id: 1,
      label: "Status",
      field_name: "status",
      render: (row) => (
        <>
          <SwitchButton
            onChange={(e) => handleStatusChange(e, row)}
            checked={[true, "true", "1"].includes(row.is_enable?.[plugin])}
            disabled={statusUpdating}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 2,
      label: "Carrier Name",
      field_name: "name",
      render: (row) => <>{row?.shipping_company_for_user?.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Carrier Image",
      field_name: "carrier_image",
      render: (row) => <img src={row?.shipping_company_for_user?.logo_url} />,
      width: "200px",
    },
    {
      id: 4,
      label: "Action",
      field_name: "action",
      render: (row) => (
        <>
          <div className="table_action__wrap">
            <Tooltip title="Manage Services">
              <div
                className="table_action__btn"
                onClick={() => setServicesModal(row)}
              >
                <MdOutlineEditCalendar size={10} />
                Services
              </div>
            </Tooltip>
          </div>
        </>
      ),
      width: "200px",
    },
  ];

  const columns2 = [
    {
      id: 1,
      label: "Status",
      field_name: "status",
      render: (row) => (
        <>
          <SwitchButton
            onChange={(e) => handleStatusChange(e, row)}
            checked={[true, "true", "1"].includes(row.is_enable?.[plugin])}
            disabled={statusUpdating}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 2,
      label: "Account Name",
      field_name: "account_name",
      render: (row) => <>{row?.account_name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Carrier Name",
      field_name: "name",
      render: (row) => <>{row?.shipping_company_for_user?.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "Carrier Image",
      field_name: "carrier_image",
      render: (row) => <img src={row?.shipping_company_for_user?.logo_url} />,
      width: "200px",
    },
    {
      id: 5,
      label: "Action",
      field_name: "action",
      render: (row) => (
        <>
          <div className="table_action__wrap">
            <Tooltip title="Manage Services">
              <div
                className="table_action__btn"
                onClick={() => setServicesModal(row)}
              >
                <MdOutlineEditCalendar size={10} />
                Services
              </div>
            </Tooltip>
          </div>
        </>
      ),
      width: "200px",
    },
  ];

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const getAllCarriers = async (page, perPage, filter) => {
    const response = await fetchHajexCarriers(
      tab === 0 ? 1 : 0,
      page,
      perPage,
      filter
    );

    if (!response.error) {
      setCarriers(response?.data?.data);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  return (
    <div className="carrier__dispatcher__screen">
      <Snackbar Snack={snack} SetSnack={setSnack} />

      <ManageServices
        open={!!servicesModal}
        carrier={servicesModal}
        setOpen={setServicesModal}
        setCarriers={setCarriers}
        setSnack={setSnack}
        Android12Switch={SwitchButton}
      />
      <div className="pluginsscrn__plugin_tabwrap">
        <Link
          to="/plugins/plugin_wordpress"
          className="pluginsscrn__plugin_tab pluginsscrn__ptab_comingsoon"
        >
          <img src={frame1} className="pluginsscrn__plugin_tabimg" />
        </Link>
        <Link
          to="/plugins/plugin_wordpress"
          className="pluginsscrn__plugin_tab  pluginsscrn__ptab_comingsoon"
        >
          <img src={frame2} className="pluginsscrn__plugin_tabimg " />
        </Link>
        <Link
          to="/plugins/plugin_wordpress"
          className="pluginsscrn__plugin_tab pluginsscrn__plugin_tabactive"
        >
          <img src={frame3} className="pluginsscrn__plugin_tabimg " />
        </Link>
        <Link
          to="/plugins/plugin_wordpress"
          className="pluginsscrn__plugin_tab pluginsscrn__ptab_comingsoon"
        >
          <img src={frame4} className="pluginsscrn__plugin_tabimg " />
        </Link>
        <Link
          to="/plugins/plugin_wordpress"
          className="pluginsscrn__plugin_tab pluginsscrn__ptab_comingsoon"
        >
          <img src={frame5} className="pluginsscrn__plugin_tabimg " />
        </Link>
        <Link
          to="/plugins/plugin_wordpress"
          className="pluginsscrn__plugin_tab pluginsscrn__ptab_comingsoon"
        >
          <img src={frame6} className="pluginsscrn__plugin_tabimg " />
        </Link>
      </div>

      <Link to="/Marketplaces" className="dispatcher__screentb_gobackbtn">
        <MdArrowBackIos />
        Back To Marketplaces
      </Link>


      <TabsComponent tab={tab} handleTabChange={handleTabChange} items={[
        {
          label: "Hajex Carriers",
          style: { fontWeight: "bold" },
          value: 0,
        },
        { label: "Custom Carriers", style: { fontWeight: "bold" }, value: 1 },
      ]} />

      <div className="carriers__header">
        <div>
          <span className="carriers__hdrheading">
            {tab === 0 ? "Hajex Carriers" : "Custom Carriers"}
          </span>
        </div>
      </div>

      <div>
        <BoltTable
          message={`No ${tab === 0 ? "Hajex" : "Custom"
            } Carriers Available To Display!`}
          searchPlaceHolder={"Search Carriers"}
          columns={tab === 0 ? columns : columns2}
          data={carriers}
          getAllData={getAllCarriers}
          refresh={tab}
        />
      </div>
    </div>
  );
};

export default Plugins;
