import React, { useEffect, useState } from "react";
import "./Components.css";
import { MdErrorOutline } from "react-icons/md";
import Countries, { countriesMap } from "../../../data/countries";
import axios from "axios";
import AutoComplete from "./AutoComplete";

const PickupAddress = ({ data, setData, editMode }) => {
  const [states, setStates] = useState([]);
  const handleAddressChange = (name, value) => {
    // console.log(value);
    setData({ ...data, warehouse: { ...data?.warehouse, [name]: value } });
  };
  const handlecountry = (value) => {
    let code = Countries.filter((cn) => cn.name === value)[0].code;
    setData({
      ...data,
      warehouse: {
        ...data?.warehouse,
        country: code,
        country_name: value,
      },
    });
  };
  const handlestate = (value) => {
    let code = states.filter((cn) => cn.name === value)[0].state_code;
    setData({
      ...data,
      warehouse: {
        ...data?.warehouse,
        state_provience: code,
        state_name: value,
      },
    });
  };
  const handleFieldChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };
  useEffect(() => {
    const GetStates = async () => {
      try {
        const response = await axios({
          method: "post",
          url: "https://countriesnow.space/api/v0.1/countries/states",
          data: {
            country: data.warehouse?.country_name,
          },
        });
        setStates(response.data.data.states);
      } catch (error) {}
    };
    if (data.warehouse?.country_name) {
      GetStates();
    }
  }, [data.warehouse?.country_name]);
  useEffect(() => {
    // console.log(data);
  }, [data]);
  return (
    <div className="sss__address_section">
      {" "}
      <div className="sss__addressection_heading">PICKUP ADDRESS (SHIPPER)</div>
      <div className="sss__addressection_inputrow">
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "200px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">
              Name / Attention
            </label>
            <input
              className="sss__addressection_inputfield"
              type="text"
              placeholder="Enter Attention"
              onChange={(e) => handleAddressChange("attention", e.target.value)}
              value={data.warehouse?.attention}
            />
            {/* <div className="sss__addressection_inputfielderror">
              <MdErrorOutline className="sss__addressection_inputfielderroricn" />
              Supplier Name is required
            </div> */}
          </div>
        </div>
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "200px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">Company</label>
            <input
              className="sss__addressection_inputfield"
              type="text"
              placeholder="Enter Company"
              onChange={(e) => handleAddressChange("company", e.target.value)}
              value={data.warehouse?.company}
            />
            {/* <div className="sss__addressection_inputfielderror">
              <MdErrorOutline className="sss__addressection_inputfielderroricn" />
              Supplier Name is required
            </div> */}
          </div>
        </div>
      </div>
      <div className="sss__addressection_inputrow">
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "200px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">Email</label>
            <input
              className="sss__addressection_inputfield"
              type="text"
              placeholder="Enter Email"
              onChange={(e) => handleAddressChange("email", e.target.value)}
              value={data.warehouse?.email}
            />
            {/* <div className="sss__addressection_inputfielderror">
              <MdErrorOutline className="sss__addressection_inputfielderroricn" />
              Supplier Name is required
            </div> */}
          </div>
        </div>
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "200px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">
              Phone Number
            </label>
            <input
              className="sss__addressection_inputfield"
              type="text"
              placeholder="Enter Phone Number"
              onChange={(e) => handleAddressChange("phone", e.target.value)}
              value={data.warehouse?.phone}
            />
            {/* <div className="sss__addressection_inputfielderror">
              <MdErrorOutline className="sss__addressection_inputfielderroricn" />
              Supplier Name is required
            </div> */}
          </div>
        </div>
      </div>
      <div className="sss__addressection_inputrow">
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "200px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">
              Full Address
            </label>
            <input
              className="sss__addressection_inputfield"
              type="text"
              placeholder="Enter Pickup Address"
              onChange={(e) =>
                handleAddressChange("full_address", e.target.value)
              }
              value={data.warehouse?.full_address}
            />
            {/* <div className="sss__addressection_inputfielderror">
              <MdErrorOutline className="sss__addressection_inputfielderroricn" />
              Supplier Name is required
            </div> */}
          </div>
        </div>
      </div>
      <div className="sss__addressection_inputrow">
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "200px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">Country</label>
            <AutoComplete
              Option={Countries.map((option) => option.name)}
              Handler={handlecountry}
              Value={data?.warehouse?.country_name}
              Type="Country"
            />
            {/* <CustomAutocomplete
              type="object"
              displayKey="name"
              suggestions={Countries}
              placeholder="Search For Country"
              initialValue={editMode ? data?.warehouse?.country_name : ""}
              onSelect={(country) => {
                setData({
                  ...data,
                  warehouse: {
                    ...data?.warehouse,
                    country: country.code,
                    country_name: country.name,
                  },
                });
                // handleAddressChange("country", country.code);
                // handleAddressChange("country_name", country.name);
              }}
            /> */}
            {/* <div className="sss__addressection_inputfielderror">
              <MdErrorOutline className="sss__addressection_inputfielderroricn" />
              Supplier Name is required
            </div> */}
          </div>
        </div>
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "120px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">State</label>
            <AutoComplete
              Option={states ? states.map((option) => option.name) : []}
              Handler={handlestate}
              Value={data?.warehouse?.state_name}
              Type="State"
            />
            {/* <CustomAutocomplete
              type="object"
              displayKey="name"
              suggestions={states}
              placeholder="Search For State"
              initialValue={editMode ? data?.warehouse?.state_name : ""}
              onSelect={(state) => {
                setData({
                  ...data,
                  warehouse: {
                    ...data?.warehouse,
                    state: state.code,
                    state_name: state.name,
                  },
                });
              }}
            /> */}
            {/* <input
              className="sss__addressection_inputfield"
              type="text"
              placeholder="Enter State"
              onChange={(e) => handleAddressChange("state", e.target.value)}
            /> */}
            {/* <div className="sss__addressection_inputfielderror">
              <MdErrorOutline className="sss__addressection_inputfielderroricn" />
              Supplier Cost is required
            </div> */}
          </div>
        </div>
      </div>
      <div className="sss__addressection_inputrow">
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "200px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">City/Town</label>
            <input
              className="sss__addressection_inputfield"
              type="text"
              placeholder="Enter City Name"
              onChange={(e) => handleAddressChange("city_town", e.target.value)}
              value={data.warehouse?.city_town}
            />
            {/* <div className="sss__addressection_inputfielderror">
              <MdErrorOutline className="sss__addressection_inputfielderroricn" />
              Supplier Name is required
            </div> */}
          </div>
        </div>
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "120px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">Zip/Postal</label>
            <input
              className="sss__addressection_inputfield"
              type="text"
              placeholder="Enter Zip/Postal Code"
              onChange={(e) =>
                handleAddressChange("zip_postal", e.target.value)
              }
              value={data.warehouse?.zip_postal}
            />
            {/* <div className="sss__addressection_inputfielderror">
              <MdErrorOutline className="sss__addressection_inputfielderroricn" />
              Supplier Cost is required
            </div> */}
          </div>
        </div>
      </div>
      <div className="sss__addressection_inputrow">
        <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "200px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">
              Instructions
            </label>
            <textarea
              className=" sss__addressection_textarea"
              rows="3"
              onChange={(e) =>
                handleFieldChange("customer_note", e.target.value)
              }
              value={data.customer_note}
            ></textarea>
            {/* <div className="sss__addressection_inputfielderror">
              <MdErrorOutline className="sss__addressection_inputfielderroricn" />
              Supplier Name is required
            </div> */}
          </div>
        </div>
        {/* <div
          className="sss__addressection_inputcol"
          style={{ minWidth: "120px" }}
        >
          <div className="sss__addressection_inputbox">
            <label className="sss__addressection_inputtitle">Reference</label>
            <textarea
              className=" sss__addressection_textarea"
              rows="3"
            ></textarea>
            <div className="sss__addressection_inputfielderror">
              <MdErrorOutline className="sss__addressection_inputfielderroricn" />
              Supplier Cost is required
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default PickupAddress;
