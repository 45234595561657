import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import {
  TotalSpaceColumn,
  Dimensions,
  Weight,
} from "../../../../Products/Product/ProductService";
import { fetchAllProductPallets } from "../../../../../services/product.service";
import { RxCross1 } from "react-icons/rx";

export default function PalletProducts({ open, setOpen }) {
  const handleClose = () => {
    setPallets([]);
    setOpen(false);
  };
  const [pallets, setPallets] = useState([]);

  const getAssignedPallets = async (id) => {
    const response = await fetchAllProductPallets(id);
    if (!response.error) {
      setPallets(response?.data?.result?.data);
    }
  };

  useEffect(() => {
    if (open) {
      getAssignedPallets(open?.id);
    }
  }, [open]);

  return (
    <div className="storagBrreakDown__modal_wrap">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="prdtable__hist_modal storagBrreakDown__modal_container">
          <div className="storagBrreakDown__modal_header">
            <div className="storagBrreakDown__modal_main_heading">
              Pallet Breakdown
            </div>
            <div className="storagBrreakDown__modal_sub_heading">
              {open?.name ?? ""}({open?.code ?? ""})
            </div>
          </div>
          <div className="prdtable__hist_modalcontent history__product_content">
            <div className="stockreciveform__table_header">
              <div className="stockreciveform__table_wrapper">
                <div className="stockreciveform__table_qty_col">
                  <small>
                    <b>Total Products: </b>
                  </small>
                  {pallets?.length ?? 0}
                  <br />
                  <small>
                    <b>Products Quantity: </b>
                  </small>
                  {open?.product_quantity ?? 0}
                  <br />
                  <small>
                    <b>Products Location: </b>
                  </small>
                  {open?.location?.name ?? 0}
                </div>
                <div className="stockreciveform__table_qty_col">
                  <div style={{ display: "flex" }}>
                    <small>
                      <b>Pallet Dimensions: </b>
                    </small>
                    &nbsp;
                    <Dimensions
                      length={open?.length ?? 0}
                      width={open?.width ?? 0}
                      height={open?.height ?? 0}
                      unit={open?.unit === "imperial" ? "inch" : "cm"}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <small>
                      <b>Pallet Volume: </b>
                    </small>
                    &nbsp;
                    <TotalSpaceColumn
                      length={open?.length ?? 0}
                      width={open?.width ?? 0}
                      height={open?.height ?? 0}
                      unit={open?.unit === "imperial" ? "inch" : "cm"}
                      quantity={1}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <small>
                      <b>Pallet Weight Capacity: </b>
                    </small>
                    &nbsp;
                    <Weight
                      weight={open?.weight ?? 0}
                      unit={open?.unit === "imperial" ? "lb" : "kg"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="stockreciveform__table_content_wrap">
              <div className="stockreciveform__table_content_container">
                <div className="stockreciveform__table_content_header_wrap">
                  <div className="stockreciveform__table_content_header_title">
                    Products
                  </div>
                </div>
                <table className="stockreciveform__table stockreciveform__detail_table">
                  <thead>
                    <th
                      className="stockreciveform__tablehd"
                      style={{ padding: "0px 2px" }}
                    >
                      #
                    </th>
                    <th
                      className="stockreciveform__tablehd"
                      style={{ padding: "0px 2px" }}
                    >
                      Product ID
                    </th>
                    <th
                      className="stockreciveform__tablehd"
                      style={{ padding: "0px 2px" }}
                    >
                      Sku
                    </th>
                    <th
                      className="stockreciveform__tablehd"
                      style={{ padding: "0px 2px" }}
                    >
                      Name
                    </th>
                    <th
                      className="stockreciveform__tablehd"
                      style={{ padding: "0px 2px" }}
                    >
                      Brand
                    </th>
                    <th
                      className="stockreciveform__tablehd"
                      style={{ padding: "0px 2px" }}
                    >
                      Lot/Batch
                    </th>
                    <th
                      className="stockreciveform__tablehd"
                      style={{ padding: "0px 2px" }}
                    >
                      Quantity
                    </th>
                    <th
                      className="stockreciveform__tablehd"
                      style={{ padding: "0px 2px" }}
                    >
                      Product Volume
                    </th>
                    <th
                      className="stockreciveform__tablehd"
                      style={{ padding: "0px 2px" }}
                    >
                      Product Pallets
                    </th>
                  </thead>
                  <tbody>
                    {pallets?.length > 0 ? (
                      <>
                        {pallets?.map((pallet, index) => {
                          return (
                            <tr key={index}>
                              <td className="stockreciveform__tableitem font_size">
                                {pallet?.id ?? "-"}
                              </td>
                              <td className="stockreciveform__tableitem font_size">
                                {pallet?.product_warehouse?.product?.code ??
                                  "-"}
                              </td>
                              <td className="stockreciveform__tableitem font_size">
                                {pallet?.product_warehouse?.product
                                  ?.product_sku ?? "-"}
                              </td>
                              <td className="stockreciveform__tableitem font_size">
                                {pallet?.product_warehouse?.product?.name ??
                                  "-"}
                              </td>
                              <td className="stockreciveform__tableitem font_size">
                                {pallet?.product_warehouse?.product?.brand
                                  ?.name ?? "-"}
                              </td>
                              <td className="stockreciveform__tableitem font_size">
                                {pallet?.product_lot?.name ?? "-"}
                              </td>
                              <td className="stockreciveform__tableitem font_size">
                                {pallet?.quantity ?? "-"}
                              </td>
                              <td className="stockreciveform__tableitem font_size">
                                <TotalSpaceColumn
                                  length={
                                    pallet?.product_warehouse?.product
                                      ?.length ?? 0
                                  }
                                  width={
                                    pallet?.product_warehouse?.product?.width ??
                                    0
                                  }
                                  height={
                                    pallet?.product_warehouse?.product
                                      ?.height ?? 0
                                  }
                                  unit={
                                    pallet?.product_warehouse?.product
                                      ?.dimension_unit === "in"
                                      ? "inch"
                                      : "cm"
                                  }
                                  quantity={pallet?.quantity ?? 0}
                                />
                              </td>
                              <td className="stockreciveform__tableitem font_size">
                                {pallet?.product_warehouse?.product
                                  ?.product_pallets_count ?? "0"}
                              </td>
                            </tr>
                          );
                        })}
                        {/* <tr key="raw_products">
                          <td className="stockreciveform__tableitem font_size">
                            {pallets?.length ?? "0"}
                          </td>
                          <td className="stockreciveform__tableitem font_size"></td>
                          <td className="stockreciveform__tableitem font_size"></td>
                          <td className="stockreciveform__tableitem font_size">
                            Total
                          </td>
                          <td className="stockreciveform__tableitem font_size">
                            cbm , cbf
                          </td>
                        </tr> */}
                      </>
                    ) : (
                      <tr>
                        <td
                          colSpan="11"
                          className="stockreciveform__tableitem font_size"
                          style={{
                            textAlign: "center",
                            height: "100px",
                            borderTop: "0.5px solid lightgray",
                            marginTop: "20px",
                          }}
                        >
                          No Products Available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className=" history__product_footer">
            <div className="history__product_footer_btn" onClick={handleClose}>
              <RxCross1 />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
