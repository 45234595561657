import React, { useState } from "react";
import "./invoiceshistory.css";
// components imports
import FullPageModal from "../../../../../Components/Modals/FullPageModal/FullPageModal";
import InvoiceTable from "../../../../../Components/BoltTable/BoltTable";
import Tooltip from "../../../../../Components/Tooltip/Tooltip";
// apis imports
import { fetchShipperInvoicesHistoryByID } from "../../../../../services/AdminServices/Users/users.services";
// library imports
import { MdOutlineStickyNote2 } from "react-icons/md";
import CircularProgress from "@mui/material/CircularProgress";

const InvoicesHistory = ({ open, setOpen, User, setSnack }) => {
  const [payments, setPayments] = useState([]);
  const [actionLoader, setactionLoader] = useState("");

  const getShippersInvoicesHistory = async (page, perPage, filter) => {
    const response = await fetchShipperInvoicesHistoryByID(
      User?.id,
      page,
      perPage,
      filter
    );

    if (!response.error) {
      setPayments(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const columns = [
    {
      id: 1,
      label: "Invoice Number",
      render: (row) => <>{row?.invoice_no ?? "-"}</>,
      width: "200px",
    },
    {
      id: 2,
      label: "Sub Total",
      render: (row) => <>{Number(row?.actual_price)?.toFixed(2) ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Taxes",
      render: (row) => (
        <>
          {row?.tax_details?.length > 0 ? (
            <>
              {row?.tax_details?.map(({ name }, index) => {
                return (
                  <>
                    <span key={index}>
                      {name}
                      {index === 0 ? "" : ","}
                    </span>
                  </>
                );
              })}
            </>
          ) : (
            "-"
          )}
        </>
      ),
      width: "200px",
    },
    {
      id: 4,
      label: "Tax Total",
      render: (row) => <>{Number(row?.tax_price)?.toFixed(2) ?? "-"}</>,
      width: "200px",
    },
    {
      id: 5,
      label: "Total",
      render: (row) => <>{Number(row?.total_price)?.toFixed(2) ?? "-"}</>,
      width: "200px",
    },
    {
      id: 6,
      label: "Created At",
      render: (row) => <>{row?.created_at?.slice(0, 10) ?? "-"}</>,
      width: "200px",
    },
    {
      id: 7,
      label: "Actions",
      render: (row) => (
        <div className="table_action__wrap">
          <Tooltip title="Download Invoice">
            <div className="table_action__btn" onClick={() => downloadPdf(row)}>
              {actionLoader === row?.id ? (
                <CircularProgress size={10} />
              ) : (
                <MdOutlineStickyNote2 size={10} />
              )}
              INVOICE
            </div>
          </Tooltip>
        </div>
      ),
      width: "200px",
    },
  ];

  const downloadPdf = async (data) => {
    setactionLoader(data.id);
    const urlfull = data?.file;
    const urlParts = urlfull.split("/");
    const filename = urlParts[urlParts.length - 1];
    try {
      fetch(urlfull, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      })
        .then((url) => url.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${filename}`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setactionLoader("");
        });
    } catch (error) {
      setactionLoader("");
    }
  };

  return (
    <FullPageModal open={open} setOpen={setOpen} heading={"Invoices History"}>
      <div className="warehouses__invoices_wrapper">
        <div>
          <InvoiceTable
            message={"No Invoices Available To Display!"}
            searchPlaceHolder="Search Invoices"
            columns={columns}
            data={payments}
            getAllData={getShippersInvoicesHistory}
          />
        </div>
      </div>
    </FullPageModal>
  );
};

export default InvoicesHistory;
