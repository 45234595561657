import React, { useEffect, useState } from "react";
import "./BundleDetails.css";

// components imports
// apis imports

// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import PlaceholderImage from "../../../../../../../assets/images/placeholder-image.png";
import IconButton from "@mui/material/IconButton";
import { FaRegTimesCircle } from "react-icons/fa";

export default function BundleDetails({ open, setOpen, bundleData }) {
  const [data, setData] = useState({});
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (bundleData) {
      setData(bundleData);
    }
  }, [bundleData]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="pkgbundle__details_modal">
          <div className="pkgbundle__details_closewrap" onClick={handleClose}>
            <IconButton>
              <FaRegTimesCircle size={25} color="lightgrey" />
            </IconButton>
          </div>
          <div className="pkgbundle__details_modalheader">
            <div className="pkgbundle__details_modalheaderlft">
              <div className="pkgbundle__details_modalheaderimg">
                <img
                  src={data?.file ?? PlaceholderImage}
                  onError={(e) => {
                    e.target.src = PlaceholderImage;
                  }}
                />
              </div>
            </div>
            <div className="pkgbundle__details_modalheaderrt">
              <h1 className="pkgbundle__details_modalhdrrttitle">
                {data?.name ?? "-"}
              </h1>
              <p> {data?.description ?? "-"}</p>
            </div>
          </div>

          <div className="pkgbundle__details_modalcontent">
            <div className="pkgbundle__details_modalcontentrow">
              <div className="pkgbundle__details_modalcontentlft">
                <h2>Packages</h2>
                <div className="pkgbundle__details_pkgslist">
                  <div className="pkgbundle__details_pkgslistheading">Name</div>
                  <div className="pkgbundle__details_pkgslistheading">
                    Description
                  </div>
                  <div className="pkgbundle__details_pkgslistheading">
                    Length
                  </div>
                  <div className="pkgbundle__details_pkgslistheading">
                    Width
                  </div>
                  <div className="pkgbundle__details_pkgslistheading">
                    Height
                  </div>
                  <div className="pkgbundle__details_pkgslistheading">
                    Weight
                  </div>
                  {data?.packages?.map((pkg) => (
                    <>
                      <div className="pkgbundle__details_pkgslistdata">
                        {pkg.name}
                      </div>
                      <div className="pkgbundle__details_pkgslistdata">
                        {pkg.description}
                      </div>
                      <div className="pkgbundle__details_pkgslistdata">
                        {pkg.length}
                      </div>
                      <div className="pkgbundle__details_pkgslistdata">
                        {pkg.width}
                      </div>
                      <div className="pkgbundle__details_pkgslistdata">
                        {pkg.height}
                      </div>
                      <div className="pkgbundle__details_pkgslistdata">
                        {pkg.weight}
                      </div>
                    </>
                  ))}
                </div>
                {!data?.packages?.length && (
                  <div className="pkgbundle__details_pkgslistdataempt">
                    No Packages To Display!
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
