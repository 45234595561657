import React, { useEffect, useState } from "react";
import "./AddShipper.css";

// components imports
import Loader from "../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import { registerWarehouseClient } from "../../../../../services/auth.services";
import Countries from "../../../../../data/countries";

// library imports
import Modal from "@mui/material/Modal";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { TextField } from "@mui/material";
import Form from "react-bootstrap/Form";

const initialData = {
  address: "",
  city: "",
  country: "",
  zip: "",
  province: "",
  business_name: "",
  email: "",
  firstname: "",
  lastname: "",
  industry_type: "",
  username: "",
  password: "",
};

export default function AddShipper({ open, setOpen, setSnack, setRefresh }) {
  const [formData, setFormData] = useState({ ...initialData });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setFormData({ ...initialData });
    setConfirmPassword("");
    setDisabled(true);
    setLoading(false);
    setOpen(false);
  };
  const user = useSelector((state) => state.user);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleFieldChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await registerWarehouseClient(user?.warehouse_id, formData);
    if (res.error) {
      let message = "";
      if (res?.error?.response?.data?.username) {
        message = res?.error?.response?.data?.username[0];
      } else if (res?.error?.response?.data?.email) {
        message = res?.error?.response?.data?.email[0];
      } else {
        message = res?.error?.response?.data?.message;
      }
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: message,
          severity: "error",
        };
      });
      setLoading(false);
    } else {
      setRefresh(Math.floor(Math.random() * 100) + 1);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: res?.data?.message,
          severity: "success",
        };
      });
      setLoading(false);
      handleClose();
    }
  };

  useEffect(() => {
    if (formData.password !== confirmPassword) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formData.password, confirmPassword]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Form onSubmit={handleSubmit}>
          <div className="add__warehouse__client__user_modal">
            <div className="add__warehouse__client__user_modalheading">
              Client Register
            </div>

            <div className="add__warehouse__client__user_modalcontent">
              <div className="add__warehouse__client__user_modalrow">
                <div className="add__warehouse__client__user_modalinputwrap">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Client First Name"
                    name="firstname"
                    value={formData.firstname}
                    onChange={handleChange}
                  />
                </div>
                <div className="add__warehouse__client__user_modalinputwrap">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Client Last Name"
                    name="lastname"
                    value={formData.lastname}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="add__warehouse__client__user_modalrow">
                <div className="add__warehouse__client__user_modalinputwrap">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Client Username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                  />
                </div>
                <div className="add__warehouse__client__user_modalinputwrap">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    required
                    placeholder="Client Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="add__warehouse__client__user_modalrow">
                <div className="add__warehouse__client__user_modalinputwrap">
                  <Form.Label>Business Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Client Business Name"
                    name="business_name"
                    value={formData.business_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="add__warehouse__client__user_modalinputwrap">
                  <Form.Label>Industry Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Client Industry Type"
                    name="industry_type"
                    value={formData.industry_type}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div
                className="add__warehouse__client__user_modalrow"
                style={{
                  borderBottom: "0.5px solid lightgray",
                  margin: "15px 0px",
                }}
              ></div>

              <div className="add__warehouse__client__user_modalrow">
                {/* <div className="add__warehouse__client__user_modalinputwrap">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Client Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div> */}
                <div className="add__warehouse__client__user_modalinputwrap">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Client Address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="add__warehouse__client__user_modalrow">
                <div className="add__warehouse__client__user_modalinputwrap">
                  <Form.Label>Country</Form.Label>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={Countries}
                    getOptionLabel={(option) => option?.name}
                    sx={{
                      "&  div": {
                        maxHeight: "40px",
                        outline: "none",
                        padding: 0,
                        border: "none",
                        outline: "none",
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Client Country"
                        className="add__warehouse__client__user_modalinput"
                      />
                    )}
                    onChange={(e, country) =>
                      handleFieldChange("country", country ? country.code : "")
                    }
                  />
                </div>
                <div className="add__warehouse__client__user_modalinputwrap">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Client State"
                    name="province"
                    value={formData.province}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="add__warehouse__client__user_modalrow">
                <div className="add__warehouse__client__user_modalinputwrap">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Client City"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                </div>
                <div className="add__warehouse__client__user_modalinputwrap">
                  <Form.Label>Postal Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Client Postal Code"
                    name="zip"
                    value={formData.zip}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div
                className="add__warehouse__client__user_modalrow"
                style={{
                  borderBottom: "0.5px solid lightgray",
                  margin: "15px 0px",
                }}
              ></div>

              <div className="add__warehouse__client__user_modalrow">
                <div className="add__warehouse__client__user_modalinputwrap">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                </div>
                <div className="add__warehouse__client__user_modalinputwrap">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    name="confirmPassword"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    isInvalid={formData.password != confirmPassword}
                  />
                </div>
              </div>
            </div>
            <div className="add__warehouse__client__user_modalfooter">
              <div
                className="add__warehouse__client__user_modalcncl"
                onClick={handleClose}
              >
                Cancel
              </div>
              <button
                type="submit"
                className={`add__warehouse__client__user_modalsave ${
                  (disabled || loading) && "rfsregister__steps_nextbtndisabled"
                }`}
              >
                {loading ? <Loader /> : "Submit"}
              </button>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
