import React, { useState, useEffect } from "react";
import "./settings.css";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  fetchAllPermissions,
  fetchAllRoles,
} from "../../../../../services/settings.services";
import { useSelector } from "react-redux";
import SideBar from "./Components/SideBar/SideBar";
import AddRole from "./Components/ManageRoles/AddRole";
import ManageRoles from "./Components/ManageRoles/ManageRoles";
import CarrierTableLoader from "../../../../../Components/CarrierTableLoader/CarrierTableLoader";
import Sad from "@mui/icons-material/SentimentVeryDissatisfied";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function RolesSettings(props) {
  const user = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);

  //create roles
  const [open, setOpen] = useState(false);

  const [permisions, setPermissions] = useState([]);
  const [permisionsAlter, setPermissionsAlter] = useState([]);
  const [roles, setRoles] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    text: "",
    severity: "",
  });

  const openModel = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchPermissionsData = async () => {
    const saved = await fetchAllPermissions(user.token);
    setPermissions(saved?.data?.result?.permissions?.data);
    const array = saved?.data?.result?.permissions?.data?.map((obj) => {
      const newKey1 = false;
      const newKey2 = obj.children.map(() => ({
        subKey: false,
      }));
      return {
        key1: newKey1,
        key2: newKey2,
      };
    });
    setPermissionsAlter(array);
  };
  const fetchAllRolesData = async () => {
    const saved = await fetchAllRoles(user.token);
    setRoles(
      saved?.data?.result?.data.map((role) => {
        return {
          id: role.id,
          name: role.name,
          permissions: role.permissions.map((p) => p.id),
        };
      })
    );
  };

  // useEffect(() => {
  //   fetchPermissionsData();
  //   fetchAllRolesData();
  // }, []);
  const fetchData = async () => {
    try {
      setLoading(true);
      // Use Promise.all to wait for both requests to complete
      const [permissionsData, rolesData] = await Promise.all([
        fetchPermissionsData(),
        fetchAllRolesData(),
      ]);

      // Turn off the loading state
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error here
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleSnackClose = () => {
    setSnack({ ...snack, open: false });
  };
  return (
    <>
      <div className="parent_wraper">
        {/* sidebar code  */}
        <SideBar active="0_0" />
        <div className="dash_table_wrapper">
          {/* right side secions  */}
          <Snackbar
            open={snack.open}
            autoHideDuration={6000}
            onClose={handleSnackClose}
          >
            <Alert
              onClose={handleSnackClose}
              severity={snack.severity}
              sx={{ width: "100%" }}
            >
              {snack.text}
            </Alert>
          </Snackbar>

          <div className="permission__bar">
            <div className="heading__text">Manage Roles</div>
            <div className="permissions__button" onClick={() => openModel()}>
              Create Role
            </div>
          </div>
          {roles.length >= 1 && permisions.length >= 1 && (
            <ManageRoles
              roles={roles}
              permisions={permisions}
              setRoles={setRoles}
              setSnack={setSnack}
              loader={<Loader />}
            />
          )}
          <AddRole
            handleClose={handleClose}
            open={open}
            permisions={permisions}
            setRoles={setRoles}
            setSnack={setSnack}
          />
          {roles.length < 1 && permisions.length < 1 && loading && (
            <div style={{ width: "60vw" }} className="driver__screen_loading">
              <CarrierTableLoader />
            </div>
          )}
          {roles.length < 1 && permisions.length < 1 && !loading && (
            <div className="driver__screen_empty" style={{ width: "auto" }}>
              <div className="driver__scrnempt_content">
                <Sad className="driver__scrnempt_contenticon" />
                <div>You currently do not have any role to manage!</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default RolesSettings;

function Loader(props) {
  return (
    <Box
      sx={{
        position: "relative",
        top: "2px",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={20}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={20}
        thickness={4}
        {...props}
      />
    </Box>
  );
}
