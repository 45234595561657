import React, { useEffect, useState } from "react";
import "./AddCustomCarrier.css";

// components imports
import Loader from "../../../../../../../Components/CircularLoader/CircularLoader";
import CanparForm from "./Forms/CanparForm";
import NationxForm from "./Forms/NationxForm";
import CanadaPostForm from "./Forms/CanadaPostForm";
import GlsForm from "./Forms/GlsForm";
import FedexForm from "./Forms/FedexForm";
import LoomisForm from "./Forms/LoomisForm";
import UpsForm from "./Forms/UpsForm";
import DayAndRoseForm from "./Forms/DayAndRoseForm";
import IcsForm from "./Forms/IcsForm";
import DHLForm from "./Forms/DHLForm";
import XPOForm from "./Forms/XPOForm";
import NationxRequirements from "./Requirements/NationxRequirements";
import CanparRequirements from "./Requirements/CanparRequirements";
import CanadaPostRequirements from "./Requirements/CanadaPostRequirements";
import GlsRequirements from "./Requirements/GlsRequirements";
import FedexRequirements from "./Requirements/FedexRequirements";
import LoomisRequirements from "./Requirements/LoomisRequirements";
import UpsRequirements from "./Requirements/UpsRequirements";
import DayAndRoseRequirements from "./Requirements/DayAndRoseRequirements";
import IcsRequirements from "./Requirements/IcsRequirements";
import KindersleyForm from "./Forms/KindersleyForm";
import KindersleyRequirements from "./Requirements/KindersleyRequirements";
import DHLRequirements from "./Requirements/DHLRequirements";
import XPORequirements from "./Requirements/XPORequirements";

// apis imports
import { addCustomCarrier } from "../../../../../../../services/ShipperServices/Carriers/carriers.services";
import { toCamelCase } from "../../../../../../../helpers/helpers";

// library imports
import Form from "react-bootstrap/Form";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import { FaRegTimesCircle } from "react-icons/fa";

export default function AddCustomCarrier({
  open,
  setOpen,
  setSnack,
  setCarriers,
}) {
  const [activeBox, setActiveBox] = useState(1);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState();
  const [carriers, setCarriersOld] = useState(
    JSON.parse(localStorage.getItem("Carriers"))
  );
  const [selectedCarrier, setSelectedCarrier] = useState();
  const handleClose = () => {
    setOpen(false);
    setActiveBox(1);
  };

  const handleCarrierClick = (carrier) => {
    setSelectedCarrier(carrier);
    setActiveBox(2);
  };

  const handlePrevClick = () => {
    setActiveBox(activeBox - 1);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(payload);
    try {
      const saved = await addCustomCarrier({
        ...payload,
        company_name: toCamelCase(selectedCarrier?.name),
      });
      setLoading(false);
      if (!saved.error) {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Carrier Added successfully",
            severity: "success",
          };
        });
        setCarriers((prevState) => {
          return {
            ...prevState,
            data: saved.data.result.data,
          };
        });
        handleClose();
      } else {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text:
              saved?.error?.response?.data?.message ?? "Something went wrong",
            severity: "error",
          };
        });
      }
    } catch (error) {
      setLoading(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="addcus__account_modal">
          <div className="addcus__account_modalheading">Add Custom Carrier</div>
          <div className="manage__services_closewrap" onClick={handleClose}>
            <IconButton>
              <FaRegTimesCircle size={25} color="lightgrey" />
            </IconButton>
          </div>

          <Form onSubmit={(e) => handleSave(e)}>
            <div className="addcus__account_modalcontent">
              <div
                className={`addcus__account_modalcontentbox ${
                  activeBox === 1
                    ? "addcus__account_modalcontentactivebox"
                    : activeBox > 1
                    ? "addcus__account_modalcontentboxleft"
                    : "addcus__account_modalcontentboxright"
                }`}
              >
                <div className="addcus__account_methods">
                  {carriers
                    ?.filter((carrier) => ![1, 6].includes(carrier?.id))
                    ?.map((carrier) => (
                      <div
                        className="addcus__account_method"
                        onClick={() => handleCarrierClick(carrier)}
                      >
                        <div className="addcus__account_methodicon">
                          <img src={carrier?.logo_url} alt="" />{" "}
                        </div>
                        <div className="addcus__account_methodtxt">
                          {carrier?.name}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div
                className={`addcus__account_modalcontentbox ${
                  activeBox === 2
                    ? "addcus__account_modalcontentactivebox"
                    : activeBox > 2
                    ? "addcus__account_modalcontentboxleft"
                    : "addcus__account_modalcontentboxright"
                }`}
              >
                {selectedCarrier?.id === 1 && (
                  <>
                    <NationxForm
                      carrier={selectedCarrier}
                      setFinalPayload={setPayload}
                    />
                    <NationxRequirements carrier={selectedCarrier} />
                  </>
                )}
                {selectedCarrier?.id === 2 && (
                  <>
                    <CanparForm
                      carrier={selectedCarrier}
                      setFinalPayload={setPayload}
                    />
                    <CanparRequirements carrier={selectedCarrier} />
                  </>
                )}
                {selectedCarrier?.id === 3 && (
                  <>
                    <CanadaPostForm
                      carrier={selectedCarrier}
                      setFinalPayload={setPayload}
                    />
                    <CanadaPostRequirements carrier={selectedCarrier} />
                  </>
                )}
                {selectedCarrier?.id === 4 && (
                  <>
                    <GlsForm
                      carrier={selectedCarrier}
                      setFinalPayload={setPayload}
                    />
                    <GlsRequirements carrier={selectedCarrier} />
                  </>
                )}
                {selectedCarrier?.id === 5 && (
                  <>
                    <FedexForm
                      carrier={selectedCarrier}
                      setFinalPayload={setPayload}
                    />
                    <FedexRequirements carrier={selectedCarrier} />
                  </>
                )}
                {selectedCarrier?.id === 6 && (
                  <>
                    <LoomisForm
                      carrier={selectedCarrier}
                      setFinalPayload={setPayload}
                    />
                    <LoomisRequirements carrier={selectedCarrier} />
                  </>
                )}
                {selectedCarrier?.id === 7 && (
                  <>
                    <UpsForm
                      carrier={selectedCarrier}
                      setFinalPayload={setPayload}
                    />
                    <UpsRequirements carrier={selectedCarrier} />
                  </>
                )}
                {selectedCarrier?.id === 8 && (
                  <>
                    <DayAndRoseForm
                      carrier={selectedCarrier}
                      setFinalPayload={setPayload}
                    />
                    <DayAndRoseRequirements carrier={selectedCarrier} />
                  </>
                )}
                {selectedCarrier?.id === 9 && (
                  <>
                    <IcsForm
                      carrier={selectedCarrier}
                      setFinalPayload={setPayload}
                    />
                    <IcsRequirements carrier={selectedCarrier} />
                  </>
                )}
                {selectedCarrier?.id === 10 && (
                  <>
                    <KindersleyForm
                      carrier={selectedCarrier}
                      setFinalPayload={setPayload}
                    />
                    <KindersleyRequirements carrier={selectedCarrier} />
                  </>
                )}
                {selectedCarrier?.id === 12 && (
                  <>
                    <DHLForm
                      carrier={selectedCarrier}
                      setFinalPayload={setPayload}
                    />
                    <DHLRequirements carrier={selectedCarrier} />
                  </>
                )}
                {selectedCarrier?.id === 13 && (
                  <>
                    <XPOForm
                      carrier={selectedCarrier}
                      setFinalPayload={setPayload}
                    />
                    <XPORequirements carrier={selectedCarrier} />
                  </>
                )}
              </div>
            </div>
            <div
              className="addcus__account_modalfooter"
              style={{
                justifyContent: activeBox > 1 ? "space-between" : "flex-end",
              }}
            >
              {activeBox > 1 && (
                <div
                  className="addcus__account_modalcncl"
                  onClick={handlePrevClick}
                >
                  Back
                </div>
              )}

              {activeBox === 2 && (
                <button
                  type="submit"
                  className={`addcus__account_modalsave ${
                    activeBox < 2 && "addcus__account_modalsavedisabled"
                  }`}
                  // onClick={handleSave}
                >
                  {loading ? <Loader /> : "Save"}
                </button>
              )}
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
