import React, { useState } from "react";
import "./CustomDropdown.css"; // Import the external CSS file
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";
const CustomDropdown = ({
  options,
  optionObjects,
  selectedOption,
  onSelect,
  customStyles = {},
  selectedLabel,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    onSelect(option);
    setIsOpen(false);
  };

  const containerStyles = {
    ...customStyles,
    opacity: disabled ? 0.5 : 1, // Optionally reduce opacity if disabled
    pointerEvents: disabled ? "none" : "auto", // Disable pointer events if disabled
  };

  const dropdownListStyles = {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  };

  return (
    <div className="custom__dropdown_container" style={containerStyles}>
      <div className="custom__dropdown_header" onClick={toggleDropdown}>
        {selectedOption
          ? optionObjects
            ? selectedOption?.title && selectedOption?.title !== ""
              ? selectedOption?.title
              : "Select an option"
            : selectedOption && selectedOption !== ""
            ? selectedOption
            : "Select an option"
          : selectedLabel}
        <div className="custom__dropdown_headericon">
          {isOpen ? (
            <MdOutlineArrowDropUp size={20} />
          ) : (
            <MdOutlineArrowDropDown size={20} />
          )}
        </div>
      </div>
      <ul
        className={`custom__dropdown_list ${isOpen ? "open" : ""}`}
        style={dropdownListStyles}
      >
        {options &&
          options.map((option, index) => (
            <li
              key={index}
              className="custom__dropdown_item"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </li>
          ))}
        {optionObjects &&
          optionObjects.map((option, index) => (
            <li
              key={index}
              className="custom__dropdown_item"
              onClick={() => handleOptionClick(option)}
            >
              {option?.title}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default CustomDropdown;
