import React, { useState } from "react";
import "./pendingpaymentlist.css";

// components imports
import ApprovedTransectionTable from "../../../../../../../Components/BoltTable/BoltTable";
import ImageModal from "../../Components/ImageModal/ImageModal";
import Tooltip from "../../../../../../../Components/Tooltip/Tooltip";
import noImage from "../../../../../../../assets/images/noImage.png";

// apis imports
import { fetchLtlPendingTransactions } from "../../../../../../../services/ShipperServices/Payments/payments.services";
import { removeUnderscoresAndCapitalize } from "../../../../../../../helpers/helpers";
// library imports
import { FaArrowAltCircleUp } from "react-icons/fa";
import { MdOutlineStickyNote2 } from "react-icons/md";

import { useSelector } from "react-redux";

import ApprovePayment from "./ApprovePayment/ApprovePayment";

const LtlPendingPaymentList = ({ setSnack }) => {
  const [transections, setTransections] = useState([]);

  const [imageModal, setImageModal] = useState();
  const [approvePaymentModal, setApprovePaymentModal] = useState();
  const [refresh, setRefresh] = useState();

  const user = useSelector((state) => state.user);

  const getAllShippersTransections = async (page, perPage, filter) => {
    const response = await fetchLtlPendingTransactions(page, perPage, filter);
    if (!response.error) {
      setTransections(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 1,
      label: "Transaction ID",
      field_name: "transaction_id",
      render: (row) => <>{row?.transaction_id ?? "-"}</>,
      width: "130px",
    },
    {
      id: 2,
      label: "Date",
      field_name: "date",
      render: (row) => <>{row.created_at?.slice(0, 10) ?? "-"}</>,
      width: "140px",
    },
    {
      id: 3,
      label: "Payment Type",
      field_name: "payment_type",
      render: (row) => (
        <>
          {row.payment_type
            ? removeUnderscoresAndCapitalize(row.payment_type)
            : "-"}
        </>
      ),
      width: "200px",
    },
    {
      id: 4,
      label: <>{user?.role === "warehouseUser" ? "User" : "Warehouse"}</>,
      field_name: "warehouse_name",
      render: (row) => (
        <>
          {user?.role === "warehouseUser"
            ? row?.user?.username ?? "-"
            : row?.warehouse?.name ?? "-"}
        </>
      ),
      width: "200px",
    },
    {
      id: 5,
      label: "Amount",
      field_name: "amount",
      render: (row) => (
        <div
          style={{
            color:
              row?.transaction_type?.startsWith("credit") || true
                ? "teal"
                : "tomato",
            display: "flex",
          }}
        >
          <>
            {row.balance ?? "-"}
            <FaArrowAltCircleUp
              style={{ marginTop: "2px", marginLeft: "4px" }}
            />
          </>
        </div>
      ),
      width: "200px",
    },
    {
      id: 6,
      label: "Status",
      field_name: "status",
      render: (row) => <>{row.status?.toUpperCase() ?? "-"}</>,
      width: "200px",
    },
    {
      id: 7,
      label: "Reference",
      field_name: "reference",
      render: (row) => (
        <>
          <img
            src={row.file || noImage}
            style={{ cursor: "pointer" }}
            onClick={() => setImageModal(row.file || noImage)}
            onError={(e) => {
              e.target.src = noImage;
            }}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 9,
      label: "Actions",
      render: (row) => (
        <div className="table_action__wrap">
          {user?.role === "warehouseUser" && (
            <Tooltip title="Approve Balance">
              <div
                className="table_action__btn"
                onClick={() => setApprovePaymentModal(row)}
              >
                <MdOutlineStickyNote2 size={10} />
                Approve
              </div>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <div>
        <div className="payment__pending_header">
          <div>
            <span className="payment__pending_heading">Transaction List</span>
          </div>
        </div>
        <ImageModal open={imageModal} setOpen={setImageModal} />

        <ApprovePayment
          open={!!approvePaymentModal}
          setOpen={setApprovePaymentModal}
          previousData={approvePaymentModal}
          setRefresh={setRefresh}
        />

        <ApprovedTransectionTable
          message={"No Transaction  Available To Display!"}
          searchPlaceHolder={"Search Transactions"}
          getAllData={getAllShippersTransections}
          data={transections}
          columns={columns}
          refresh={refresh}
        />
      </div>
    </div>
  );
};

export default LtlPendingPaymentList;
