import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { addCostType } from "../../../../../services/orders.services";
import { fetchCostType } from "../../../../../services/orders.services";

const filter = createFilterOptions();

export default function FreeSoloCreateOption({ setValue, value, setSnack }) {
  const [costs, setCosts] = useState([]);

  const addNewCosttype = async (newOption) => {
    let response;
    response = await addCostType(newOption);
    if (!response.error) {
      setCosts([...costs, response?.data?.result]);
      const Option = {
        id: response?.data?.result?.id,
        name: newOption.name,
      };
      setValue(Option);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: newOption.name + " stored in cost types",
          severity: "success",
        };
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: newOption.name + " not stored in cost types",
          severity: "error",
        };
      });
    }
  };

  const getCostType = async () => {
    let response;
    response = await fetchCostType();
    if (!response.error) {
      setCosts(response?.data?.result);
    }
  };

  useEffect(() => {
    getCostType();
  }, []);

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          // When a string is entered, newValue is the name
          const selectedOption = costs.find(
            (option) => option.name === newValue
          );
          setValue(selectedOption);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          const newOption = {
            name: newValue.inputValue,
          };

          // Add the new option to costs
          addNewCosttype(newOption);
          // setCosts((prevCostType) => [...prevCostType, newOption]);
        } else {
          // When an existing option is selected, newValue is the selected option
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            name: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="cost_type_comoplete"
      options={costs}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      sx={{ width: 300 }}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label="Cost Type" size="small" />
      )}
    />
  );
}
