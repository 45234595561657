import React, { useState, useEffect, useRef } from "react";
import "./Sections.css";
import { MdAddCircleOutline, MdErrorOutline } from "react-icons/md";
import { FaRegTrashAlt, FaRegEdit } from "react-icons/fa";
import CustomDropdown from "../Components/CustomDropdown/CustomDropdown";
import UpdateVariantModal from "../Components/UpdateVariantModal/UpdateVariantModal";
import noImage from "../../../../assets/images/noImage.png";

import AsyncAutoComplete from "../../../../Components/AsyncAutoComplete/AsyncAutoComplete";
import { addVariantAttribute } from "../../../../services/product.service";
import { useDispatch } from "react-redux";
import { showSnackBar } from "../../../../store/common/snackBarSlice";
const VariantSection = ({
  attributes,
  setAttributes,
  variants,
  setVariants,
  formData,
  setFormData,
  errors,
  variationAttributes,
  productSuppliers,
  setVariationAttributes,
  editMode,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);
  const [selectedOptionValues, setSelectedOptionValues] = useState([""]);
  const [addVariantOption, setAddVariantOption] = useState(false);
  const [editVariantOption, setEditVariantOption] = useState(false);
  const [updateVariantModal, setUpdateVariantModal] = useState(false);
  const [variantErrors, setVariantErrors] = useState();
  const userAddingAttribute = useRef(false);
  const previousVariants = useRef(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (userAddingAttribute.current) {
      generateCombinations();
    }
  }, [attributes]);

  const generateCombinations = () => {
    if (attributes.length === 0) {
      setVariants([]);
      return;
    }

    const combinations = generateCombinationsRecursive(attributes, 0);

    // Helper function to check if a variant already exists
    const filterDuplicates = (newCombinations, existingVariants = []) => {
      const uniqueVariants = new Set(
        existingVariants.map((item) => JSON.stringify(item.variant)) // Converting objects to strings for comparison
      );

      return newCombinations.filter((comb) => {
        const variantString = JSON.stringify(comb);
        if (!uniqueVariants.has(variantString)) {
          uniqueVariants.add(variantString);
          return true;
        }
        return false;
      });
    };

    // Check and remove duplicate variants before setting state
    if (previousVariants.current?.length) {
      const filteredCombinations = filterDuplicates(
        combinations,
        previousVariants.current
      );
      setVariants([
        ...previousVariants.current,
        ...filteredCombinations.map((comb) => {
          return { variant: comb };
        }),
      ]);
    } else {
      const filteredCombinations = filterDuplicates(combinations);
      setVariants(
        filteredCombinations.map((comb) => {
          return { variant: comb };
        })
      );
    }
  };

  const addNewOption = async (option) => {
    const response = await addVariantAttribute({ name: option?.title });
    if (!response.error) {
      setVariationAttributes((prevData) => {
        const attr = response?.data?.result;
        return [{ title: attr?.name, value: attr?.id }, ...prevData];
      });
      setSelectedOption({ title: option?.title, value: option?.id });
      dispatch(
        showSnackBar({
          text: "Option Added successfully",
          severity: "success",
        })
      );
    } else {
      dispatch(
        showSnackBar({
          text: "Couldn't add new option",
          severity: "error",
        })
      );
    }
  };

  const generateCombinationsRecursive = (
    data,
    currentIndex,
    currentCombination = []
  ) => {
    if (currentIndex === data.length) {
      return [currentCombination.join(" / ")];
    }
    const currentCategory = data[currentIndex];
    const newCombinations = [];

    for (const value of currentCategory?.value) {
      const updatedCombination = [...currentCombination, value];
      const combinationsForNextCategories = generateCombinationsRecursive(
        data,
        currentIndex + 1,
        updatedCombination
      );
      newCombinations.push(...combinationsForNextCategories);
    }

    return newCombinations;
  };

  const handleVariantFieldChange = (index, name, e) => {
    setVariants((vars) =>
      vars.map((variant, idx) => {
        if (index === idx) {
          return { ...variant, [name]: e.target.value };
        } else {
          return variant;
        }
      })
    );
  };
  const handleDeleteAttribute = (index) => {
    userAddingAttribute.current = false;
    if (attributes[index]?.id) {
      setFormData({
        ...formData,
        deletedAttributeIds: [
          ...(Array.isArray(formData?.deletedAttributeIds)
            ? formData.deletedAttributeIds
            : []),
          attributes[index]?.id,
        ],
      });
    }

    setAttributes([...attributes?.filter((atr, idx) => idx !== index)]);
  };
  const handleDeleteVariant = (index) => {
    if (formData.variants[index]?.id) {
      setFormData({
        ...formData,
        deletedVariationIds: [
          ...(Array.isArray(formData?.deletedVariationIds)
            ? formData.deletedVariationIds
            : []),
          formData.variants[index]?.id,
        ],
      });
    }
    setVariants([...variants.filter((varr, idx) => index !== idx)]);
  };

  useEffect(() => {
    setFormData({ ...formData, attributes, variants });
  }, [variants]);

  function findDuplicateIndexes(inputArray) {
    const seen = {};
    const duplicates = [];

    for (let i = 0; i < inputArray.length; i++) {
      const value = inputArray[i];
      if (value !== "" && seen[value] !== undefined) {
        duplicates.push(i);
      } else {
        seen[value] = i;
      }
    }

    return duplicates;
  }

  const validateOption = (name, values) => {
    if (!name || name === "") {
      setVariantErrors({
        ...variantErrors,
        optionName: "Option name is required",
      });
      return false;
    }
    if (values[0] === "") {
      setVariantErrors({
        ...variantErrors,
        firstValue: "Add a value please",
      });
      return false;
    }
    setVariantErrors({
      ...variantErrors,
      optionName: false,
      firstValue: false,
    });
    return true;
  };

  useEffect(() => {
    setVariantErrors({
      ...variantErrors,
      duplicateValues: findDuplicateIndexes(selectedOptionValues),
    });
  }, [selectedOptionValues]);
  useEffect(() => {
    if (editMode && !previousVariants.current) {
      previousVariants.current = variants;
    }
  }, [variants]);

  return (
    <section className="addprod__formsection_wrap" id="addproduct_variants">
      <UpdateVariantModal
        open={updateVariantModal !== false}
        variantIndex={updateVariantModal}
        variant={variants ? variants[updateVariantModal] : {}}
        setOpen={setUpdateVariantModal}
        handleVariantFieldChange={handleVariantFieldChange}
        formData={formData}
        setFormData={setFormData}
        setVariants={setVariants}
        variants={variants ?? []}
        errors={errors}
        productSuppliers={productSuppliers}
        editMode={editMode}
      />
      <div className="addprod__formsection_heading">Variants</div>
      <div className="addprod__variants_attributes">
        {attributes?.map((attr, index) =>
          `${index}` !== editVariantOption ? (
            <div className="addprod__variants_attribute" key={index}>
              <div className="addprod__variants_attributetop">
                <h6 className="addprod__variants_attopheading">{attr?.name}</h6>
                <div className="addprod__variants_attopactions">
                  <button className="addprod__variants_attopaction">
                    <FaRegEdit
                      className="addprod__variants_attopactionicon"
                      onClick={() => {
                        setEditVariantOption(`${index}`);
                        setSelectedOption(
                          variationAttributes?.find(
                            (attribute) => attribute.title === attr.name
                          )
                        );
                        setSelectedOptionValues([...attr.value, ""]);
                      }}
                    />
                  </button>
                  <button className="addprod__variants_attopaction">
                    <FaRegTrashAlt
                      className="addprod__variants_attopactionicon"
                      size={14}
                      onClick={() => handleDeleteAttribute(index)}
                    />
                  </button>
                </div>
              </div>
              <div className="addprod__variants_attributbottom">
                {attr?.value?.map((value, index) => (
                  <div key={index} className="addprod__variants_atrbtmvalue">
                    {value}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="aprodforms__addvarr_section" key={index}>
              <div className="addprod__custominput_flexrow">
                <div className="addprod__custominput_flexcol">
                  <div className="addprod__custominput_box">
                    <label className="addprod__custominput_title">
                      Option Name
                    </label>
                    <CustomDropdown
                      optionObjects={variationAttributes}
                      onSelect={(option) => {
                        setSelectedOption(option);
                      }}
                      selectedOption={selectedOption}
                    />
                    {variantErrors?.optionName && (
                      <div className="addprod__custominputfield_error">
                        <MdErrorOutline className="addprod__custominputfield_erroricn" />
                        {variantErrors?.optionName}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="addprod__custominput_flexrow">
                <div className="addprod__custominput_flexcol">
                  <div className="addprod__custominput_box">
                    <label className="addprod__custominput_title">
                      Option Values
                    </label>
                    {selectedOptionValues?.map((value, index) => (
                      <div
                        key={index}
                        className="addprod__custominputfield_cloneable"
                      >
                        <input
                          className="addprod__custominputfield"
                          type="text"
                          placeholder={`Enter Value ${index + 1}`}
                          onChange={(e) => {
                            setSelectedOptionValues([
                              ...selectedOptionValues?.map((value, idx) =>
                                idx === index ? e.target.value : value
                              ),
                            ]);
                            if (index === selectedOptionValues?.length - 1) {
                              if (timeoutId) {
                                clearTimeout(timeoutId);
                              }
                              const newTimeoutId = setTimeout(() => {
                                setSelectedOptionValues((prevval) => [
                                  ...prevval,
                                  "",
                                ]);
                              }, 700);
                              setTimeoutId(newTimeoutId);
                            }
                          }}
                          value={value}
                        />

                        {selectedOptionValues?.length > 1 && (
                          <FaRegTrashAlt
                            size={18}
                            className="addprod__custominputfield_cloneicn"
                            onClick={() =>
                              setSelectedOptionValues([
                                ...selectedOptionValues?.filter(
                                  (value, idx) => idx !== index
                                ),
                              ])
                            }
                          />
                        )}
                      </div>
                    ))}
                    {variantErrors?.duplicateValues?.includes(index) && (
                      <div className="addprod__custominputfield_error">
                        <MdErrorOutline className="addprod__custominputfield_erroricn" />
                        Can not add duplicate values
                      </div>
                    )}
                    {index === 0 && variantErrors?.firstValue && (
                      <div className="addprod__custominputfield_error">
                        <MdErrorOutline className="addprod__custominputfield_erroricn" />
                        {variantErrors?.firstValue}
                      </div>
                    )}
                  </div>
                  <button
                    className="addpaddvar__done_btbcancel"
                    onClick={() => {
                      setEditVariantOption(false);
                      setSelectedOption("");
                      setSelectedOptionValues([""]);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="addpaddvar__done_btb"
                    onClick={() => {
                      const validated = validateOption(
                        selectedOption?.title,
                        selectedOptionValues
                      );
                      if (!validated) {
                        return;
                      }
                      userAddingAttribute.current = true;
                      setAttributes([
                        ...attributes?.map((item, idx) =>
                          `${idx}` === editVariantOption
                            ? {
                                ...item,
                                name: selectedOption?.title,
                                value: selectedOptionValues?.filter(
                                  (item) => item !== ""
                                ),
                              }
                            : item
                        ),
                      ]);
                      setSelectedOption("");
                      setSelectedOptionValues([""]);
                      setEditVariantOption(false);
                    }}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          )
        )}
      </div>
      {addVariantOption && (
        <div className="aprodforms__addvarr_section">
          <div className="addprod__custominput_flexrow">
            <div className="addprod__custominput_flexcol">
              <div className="addprod__custominput_box">
                <label className="addprod__custominput_title addprod__custominput_titleflex">
                  <div>Option Name</div>
                  {/* <div
                    className="addprod__cusinputitle_addnewopt"
                    onClick={() => setAddVariantAttributeModal(true)}
                  >
                    Add New Option
                  </div> */}
                </label>
                {/* {addVariantOption === "new" ? (
                  <input
                    className="addprod__custominputfield"
                    type="text"
                    placeholder={`Enter New Option Name`}
                    onChange={(e) => {
                      setSelectedOption({
                        title: e.target.value,
                        value: e.target.value.toLowerCase(),
                      });
                    }}
                  />
                ) : (
                  <CustomDropdown
                    optionObjects={variationAttributes}
                    onSelect={(option) => {
                      setSelectedOption(option);
                    }}
                    selectedOption={selectedOption}
                  />
                )} */}
                <AsyncAutoComplete
                  options={variationAttributes}
                  addNewOption={addNewOption}
                  value={selectedOption}
                  setValue={setSelectedOption}
                />
                {variantErrors?.optionName && (
                  <div className="addprod__custominputfield_error">
                    <MdErrorOutline className="addprod__custominputfield_erroricn" />
                    {variantErrors?.optionName}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="addprod__custominput_flexrow">
            <div className="addprod__custominput_flexcol">
              <div className="addprod__custominput_box">
                <label className="addprod__custominput_title">
                  Option Values
                </label>
                {selectedOptionValues?.map((value, index) => (
                  <div
                    key={index}
                    className="addprod__custominputfield_cloneablewrap"
                  >
                    {" "}
                    <div className="addprod__custominputfield_cloneable">
                      <input
                        className={`addprod__custominputfield ${
                          variantErrors?.duplicateValues?.includes(index)
                            ? "addprod__custominputfielderror"
                            : ""
                        }`}
                        type="text"
                        placeholder={`Enter Value ${index + 1}`}
                        onChange={(e) => {
                          setSelectedOptionValues([
                            ...selectedOptionValues?.map((value, idx) =>
                              idx === index ? e.target.value : value
                            ),
                          ]);
                          if (index === selectedOptionValues?.length - 1) {
                            if (timeoutId) {
                              clearTimeout(timeoutId);
                            }
                            const newTimeoutId = setTimeout(() => {
                              setSelectedOptionValues((prevval) => [
                                ...prevval,
                                "",
                              ]);
                            }, 500);
                            setTimeoutId(newTimeoutId);
                          }
                        }}
                        value={value}
                      />

                      {selectedOptionValues?.length > 1 && (
                        <FaRegTrashAlt
                          size={18}
                          className="addprod__custominputfield_cloneicn"
                          onClick={() =>
                            setSelectedOptionValues([
                              ...selectedOptionValues?.filter(
                                (value, idx) => idx !== index
                              ),
                            ])
                          }
                        />
                      )}
                    </div>
                    {variantErrors?.duplicateValues?.includes(index) && (
                      <div className="addprod__custominputfield_error">
                        <MdErrorOutline className="addprod__custominputfield_erroricn" />
                        Can not add duplicate values
                      </div>
                    )}
                    {index === 0 && variantErrors?.firstValue && (
                      <div className="addprod__custominputfield_error">
                        <MdErrorOutline className="addprod__custominputfield_erroricn" />
                        {variantErrors?.firstValue}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <button
                className="addpaddvar__done_btbcancel"
                onClick={() => {
                  setAddVariantOption(false);
                  setSelectedOption("");
                  setSelectedOptionValues([""]);
                }}
              >
                Cancel
              </button>
              <button
                className="addpaddvar__done_btb"
                onClick={() => {
                  const validated = validateOption(
                    selectedOption?.title,
                    selectedOptionValues
                  );
                  if (!validated) {
                    return;
                  }
                  setAttributes([
                    ...attributes,
                    {
                      name: selectedOption?.title,
                      value: selectedOptionValues?.filter(
                        (item) => item !== ""
                      ),
                    },
                  ]);
                  setSelectedOption("");
                  setSelectedOptionValues([""]);
                  setAddVariantOption(false);
                }}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}

      {!addVariantOption && !editVariantOption && attributes?.length < 3 && (
        <button
          className="addprod__formsection_addvarbtn"
          onClick={() => {
            userAddingAttribute.current = true;
            setAddVariantOption(true);
          }}
        >
          <MdAddCircleOutline
            className="addprod__formsection_addvarbtnicn"
            size={14}
          />
          Add Variant Option
        </button>
      )}
      {formData?.variants?.length > 0 && (
        <div className="variants__combinations_table_wrap">
          <table className="variants__combinations_table">
            <thead>
              <tr>
                <td>#</td>
                <td>Name</td>
                <td>Images</td>
                <td>SKU</td>
                <td>Code</td>
                <td>Regular Price</td>
                <td>Sale Price</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {formData?.variants?.map((variant, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <input
                      className="addprod__custominputfield"
                      type="text"
                      onChange={(e) =>
                        handleVariantFieldChange(index, "variant", e)
                      }
                      defaultValue={variant?.variant}
                      style={{
                        height: "26px",
                        width: "200px",
                      }}
                    />
                  </td>
                  <td>
                    <div className="flex items-center ">
                      <div className="w-[35px] h-[30px] overflow-hidden rounded-sm cursor-pointer">
                        {variant?.media
                          ?.filter((image) =>
                            [true, "true"].includes(image?.is_primary)
                          )
                          .map((image, index) => (
                            <img
                              key={index}
                              className="w-full h-full object-cover"
                              src={
                                image && image.file
                                  ? image.file
                                  : image.media instanceof Blob
                                  ? URL.createObjectURL(image.media)
                                  : noImage
                              }
                              accept="image/*"
                              alt=""
                            />
                          ))}
                        {(!variant?.media?.length ||
                          variant?.media?.length === 0) && (
                          <img
                            src={noImage}
                            alt=""
                            className="w-full h-full object-cover"
                          />
                        )}
                      </div>
                      {variant?.media?.length > 1 && (
                        <div className="mx-1">
                          <div className="text-[13px] cursor-pointer">
                            +{variant?.media?.length - 1}
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <input
                      className={`addprod__custominputfield `}
                      type="text"
                      onChange={(e) =>
                        handleVariantFieldChange(index, "sku", e)
                      }
                      defaultValue={variant.sku}
                      style={{
                        height: "26px",
                        width: "150px",
                      }}
                    />
                  </td>
                  <td>
                    <input
                      className={`addprod__custominputfield ${
                        editMode && index < previousVariants.current?.length
                          ? "disable__click_fade"
                          : ""
                      }`}
                      type="text"
                      onChange={(e) =>
                        handleVariantFieldChange(index, "code", e)
                      }
                      defaultValue={variant.code}
                      style={{
                        height: "26px",
                        width: "120px",
                      }}
                    />
                  </td>
                  <td>
                    <input
                      className="addprod__custominputfield"
                      type="text"
                      onChange={(e) =>
                        handleVariantFieldChange(index, "regular_price", e)
                      }
                      defaultValue={variant.regular_price ?? 0}
                      style={{
                        height: "26px",
                        width: "100px",
                      }}
                    />
                  </td>
                  <td>
                    <input
                      className="addprod__custominputfield"
                      type="text"
                      onChange={(e) =>
                        handleVariantFieldChange(index, "sales_price", e)
                      }
                      defaultValue={variant.sales_price ?? 0}
                      style={{
                        height: "26px",
                        width: "100px",
                      }}
                    />
                  </td>
                  <td>
                    <div className="addprod__variants_attopactions">
                      <button className="addprod__variants_attopaction">
                        <FaRegEdit
                          className="addprod__variants_attopactionicon"
                          onClick={() => setUpdateVariantModal(index)}
                        />
                      </button>
                      <button className="addprod__variants_attopaction">
                        <FaRegTrashAlt
                          className="addprod__variants_attopactionicon"
                          size={14}
                          onClick={() => handleDeleteVariant(index)}
                        />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </section>
  );
};

export default VariantSection;
