import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const StorageMapForm = ({ formData, setFormData, handleSubmit }) => {
    const [errors, setErrors] = useState({});

    const handleFieldChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' }); // Clear error on change
    };

    const validate = () => {
        let tempErrors = {};
        if (!formData.name) tempErrors.name = "Graph Name is required.";
        if (!formData.rows || formData.rows < 5 || formData.rows > 1000) {
            tempErrors.rows = "Rows must be between 5 and 1000.";
        }
        if (!formData.column || formData.column < 5 || formData.column > 1000) {
            tempErrors.column = "Columns must be between 5 and 1000.";
        }
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const onSubmit = () => {
        if (validate()) {
            handleSubmit();
        }
    };

    return (
        <form className='w-full max-w-[400px] py-8 px-4 rounded-md box-border shadow-[rgba(0,0,0,0.09)_0px_3px_12px]'>
            <Typography variant="h6" gutterBottom className='text-center '>
                Storage Graph
            </Typography>
            <TextField
                id="storage-map-name"
                label="Graph Name"
                size='small'
                className='my-2 w-full'
                value={formData.name}
                onChange={(e) => handleFieldChange('name', e.target.value)}
                error={!!errors.name}
                helperText={errors.name}
            /><br />
            <TextField
                id="storage-map-rows"
                label="No of Rows"
                type='number'
                size='small'
                value={formData.rows}
                className='my-2 w-full'
                onChange={(e) => handleFieldChange('rows', parseInt(e.target.value, 10))}
                error={!!errors.rows}
                helperText={errors.rows}
            /><br />
            <TextField
                id="storage-map-columns"
                label="No of Columns"
                type='number'
                size='small'
                value={formData.column}
                className='my-2 w-full'
                onChange={(e) => handleFieldChange('column', parseInt(e.target.value, 10))}
                error={!!errors.column}
                helperText={errors.column}
            /><br />
            <Button className='my-2' variant="contained" size="medium" fullWidth onClick={onSubmit}>
                Create Graph
            </Button>
        </form>
    );
};

export default StorageMapForm;
