import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
};

export const snackBarSlice = createSlice({
    name: "snackBar",
    initialState,
    reducers: {
        showSnackBar: (state, action) => {
            return { ...state, ...action.payload, open: true };
        },
        hideSnackBar: (state) => {
            state.open = false;
        },
    },
});


export const { showSnackBar, hideSnackBar } = snackBarSlice.actions;

export default snackBarSlice.reducer;
