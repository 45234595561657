import React, { useState } from "react";
import "./MarketPlaces.css";

import frame1 from "../../../../../Components/Images/Frame 1.png";
import frame2 from "../../../../../Components/Images/Frame 2.png";
import frame3 from "../../../../../Components/Images/Frame 3.png";
import frame4 from "../../../../../Components/Images/Frame 4.png";
import frame5 from "../../../../../Components/Images/Frame 5.png";
import frame6 from "../../../../../Components/Images/Frame 6.png";

// components imports
import MarketplaceTable from "../../../../../Components/BoltTable/BoltTable";
import Snackbar from "../../../../../Components/SnackBar/SnackBar";
import SwitchButton from "../../../../../Components/SwitchButton/SwitchButton";
import Tooltip from "../../../../../Components/Tooltip/Tooltip";
import Confirm from "../../../../../Components/Popups/Confirm";
import UpdateAccount from "./Components/UpdateAccount/UpdateAccount";
import AddMarketplace from "./Components/AddMarketplace/AddMarketplace";

// apis imports
import {
  deleteMarketplaceAccount,
  fetchMarketplaceCompanies,
  updateMarketplaceStatus,
} from "../../../../../services/ShipperServices/MarketPlaces/marketplaces.services";
// library imports

import { MdOutlineCancel, MdOutlineSettings } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { FcPlus } from "react-icons/fc";
import { Link } from "react-router-dom";

const settingsUrls = {
  Woocommerce: "/plugins/plugin_wordpress",
};

const Marketplaces = () => {
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [statusUpdating, setStatusUpdating] = useState();

  const [updateAccountModal, setUpdateAccountModal] = useState();
  const [deleteMarketplaceModal, setDeleteMarketplaceModal] = useState(false);

  const [marketplaces, setMarketPlaces] = useState([]);
  const [addAccountModal, setAddAccountModal] = useState();

  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  const columns = [
    {
      id: 1,
      label: "Status",
      field_name: "status",
      render: (row, rowIndex, handleFieldChange) => (
        <>
          <SwitchButton
            onChange={(e) => handleStatusChange(e, row)}
            checked={[true, "true", "1"].includes(row.status)}
            disabled={statusUpdating}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 2,
      label: "Account Name",
      field_name: "account_name",
      width: "200px",
    },
    {
      id: 3,
      label: "Marketplace",
      field_name: "market",
      width: "200px",
    },
    {
      id: 4,
      label: "Action",
      field_name: "action",
      render: (row, rowIndex, handleFieldChange) => (
        <>
          <div className="table_action__wrap">
            <Tooltip title="Edit Account">
              <div
                className="table_action__btn"
                onClick={() => setUpdateAccountModal(row)}
              >
                <FaRegEdit size={10} />
                Edit
              </div>
            </Tooltip>

            <Link
              className={`table_action__btn ${
                !settingsUrls[row?.market] ? "disable__click_fade" : ""
              }`}
              to={settingsUrls[row?.market] ? settingsUrls[row?.market] : ""}
            >
              <MdOutlineSettings size={10} />
              Settings
            </Link>

            <Tooltip title="Delete Account">
              <div
                className="table_action__btn"
                onClick={() => setDeleteMarketplaceModal(row.id)}
              >
                <MdOutlineCancel size={10} />
                Delete
              </div>
            </Tooltip>
          </div>
        </>
      ),
      width: "200px",
    },
  ];

  const getAllMarketplaces = async (page, perPage, filter) => {
    // const response = await fetchMarketplaceCompanies(page, perPage, filter);
    setMarketPlaces([]);
    // if (!response.error) {
    //   setMarketPlaces(response?.data?.data);
    // } else {
    //   setSnack((snack) => {
    //     return {
    //       ...snack,
    //       open: true,
    //       text: "Something went wrong",
    //       severity: "error",
    //     };
    //   });
    // }
  };

  const handleStatusChange = async (e, record) => {
    setStatusUpdating(true);

    const updated = await updateMarketplaceStatus(parseInt(record.id));
    if (updated.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: updated.error.message,
          severity: "error",
        };
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `Status of ${record?.account_name} changed to ${
            !e.target.checked ? "Active" : "Inactive"
          }`,
          severity: !e.target.checked ? "success" : "info",
        };
      });
      setMarketPlaces((prevState) => {
        return {
          ...prevState,
          data: prevState?.data.map((item) =>
            item.id === record.id
              ? {
                  ...item,
                  status: !e.target.checked,
                }
              : item
          ),
        };
      });
    }
    setStatusUpdating(false);
  };

  const deleteSingleMarketplace = async () => {
    setDeleteLoader(true);
    let id = deleteMarketplaceModal;
    const deleted = await deleteMarketplaceAccount(deleteMarketplaceModal);
    if (deleted.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Couldn't delete Marketplace Account",
          severity: "error",
        };
      });
      setDeleteLoader(false);
    } else {
      setMarketPlaces((prevState) => {
        return {
          ...prevState,
          data: prevState?.data?.filter((marketplace) => marketplace.id !== id),
        };
      });
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Marketplace Account deleted successfully",
          severity: "success",
        };
      });
      setDeleteLoader(false);
      setDeleteMarketplaceModal(false);
    }
  };

  return (
    <div className="marketplace__dispatcher__screen">
      <Snackbar Snack={snack} SetSnack={setSnack} />

      <UpdateAccount
        open={!!updateAccountModal}
        preData={updateAccountModal}
        setOpen={setUpdateAccountModal}
        setMarketPlaces={setMarketPlaces}
        setSnack={setSnack}
      />
      <Confirm
        open={deleteMarketplaceModal}
        setOpen={setDeleteMarketplaceModal}
        handleConfirm={deleteSingleMarketplace}
        text="Do you want to delete this Marketplace Account?"
        loader={deleteLoader}
      />
      <AddMarketplace
        open={!!addAccountModal}
        data={addAccountModal}
        setOpen={setAddAccountModal}
        setMarketPlaces={setMarketPlaces}
        setSnack={setSnack}
        getAllMarketplaces={getAllMarketplaces}
      />

      <div className="marketplaces_marketplace_tabwrap">
        <span className="marketplaces_marketplace_tab ">
          <img src={frame1} className="marketplaces_marketplace_tabimg" />
        </span>
        <span className="marketplaces_marketplace_tab  ">
          <img src={frame2} className="marketplaces_marketplace_tabimg " />
        </span>
        <span className="marketplaces_marketplace_tab ">
          <img src={frame3} className="marketplaces_marketplace_tabimg " />
        </span>
        <span className="marketplaces_marketplace_tab ">
          <img src={frame4} className="marketplaces_marketplace_tabimg " />
        </span>
        <span className="marketplaces_marketplace_tab ">
          <img src={frame5} className="marketplaces_marketplace_tabimg " />
        </span>
        <span className="marketplaces_marketplace_tab ">
          <img src={frame6} className="marketplaces_marketplace_tabimg " />
        </span>
      </div>
      <div className="marketplaces__header">
        <div className="marketplaces__buttonsWrapper">
          <div onClick={() => setAddAccountModal(true)}>
            <div className="marketplaces__hdraddprod">
              <FcPlus size={20} />
              <span>ADD MARKETPLACE</span>
            </div>
          </div>
        </div>
      </div>

      <div>
        <MarketplaceTable
          message={`No Marketplaces Available To Display!`}
          searchPlaceHolder={"Search Marketplace"}
          columns={columns}
          data={marketplaces}
          getAllData={getAllMarketplaces}
        />
      </div>
    </div>
  );
};

export default Marketplaces;
