import React from "react";
import Logo from "../../../../../../assets/images/hajex_logo.png";
import { FcPlus } from "react-icons/fc";
import { BsDownload } from "react-icons/bs";

export default function header({
  orderId,
  handleClose,
  downloadPdf,
  type = "show",
}) {
  return (
    <>
      {type !== "print" && (
        <div className="proddetails__desc_modalheading">
          Order ({orderId ?? ""}) Details
          <div className="proddetails__desc_btn_cross_header">
            <FcPlus
              size={20}
              onClick={handleClose}
              className="order_detail_cross"
            />
            <BsDownload
              size={20}
              onClick={() => downloadPdf(orderId)}
              className="order_detail_download"
            />
          </div>
        </div>
      )}
      <div className="inv_ship_hajex_logo">
        <img src={Logo} alt="Logo" className="invoice__modal_headerleftimg" />
      </div>
    </>
  );
}
