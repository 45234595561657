import React, { useEffect, useState } from "react";
import "./editunits.css";

// components imports
import Loader from "../../../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import { updateTransferUnits } from "../../../../../../../services/admin.services";
// library imports
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Modal from "@mui/material/Modal";

const EditUnits = ({ setOpen, open, setSnack, setTransferUnits }) => {
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    code: "",
    id: "",
  });

  const handelChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.name === "") {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Please Enter Name For Unit",
          severity: "error",
        };
      });
      return;
    }
    setLoading(true);
    const response = await updateTransferUnits(formData.id, {
      code: formData.code,
      name: formData.name,
    });
    // console.log("response", response.data.result);
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
      setLoading(false);
    } else {
      setTransferUnits((prevTransferUnits) => {
        return {
          ...prevTransferUnits,
          data: prevTransferUnits?.data?.map((p) => {
            return p.id === open?.id ? response?.data?.result : p;
          }),
        };
      });
      setLoading(false);
      setOpen(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Unit Updated Successfully",
          severity: "success",
        };
      });
    }
  };

  useEffect(() => {
    setFormData({ name: open.name, code: open.code, id: open.id });
  }, [open]);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="add-unit-modal-title"
        aria-describedby="add-unit-modal-description"
      >
        <div className="unitStock__edit_unit_modal">
          <div className="unitStock__edit_unit_modal_wrap">
            <div className="unitStock__edit_unit">
              <div className="unitStock__edit_unit_header">
                <h3 className="unitStock__edit_unit_title">Edit Unit</h3>
              </div>
              <div className="unitStock__edit_unit_body">
                <FormControl
                  variant="outlined"
                  className="unitStock__edit_unit_code"
                >
                  <InputLabel htmlFor="addUnitCode" size="small">
                    Code *
                  </InputLabel>
                  <OutlinedInput
                    id="addUnitCode"
                    name="code"
                    value={formData.code}
                    onChange={(e) => handelChange(e)}
                    size="small"
                    label="Code *"
                  />
                </FormControl>

                <FormControl
                  variant="outlined"
                  className="unitStock__edit_unit_name"
                >
                  <InputLabel htmlFor="addUnitName" size="small">
                    Name *
                  </InputLabel>
                  <OutlinedInput
                    id="addUnitName"
                    name="name"
                    value={formData.name}
                    onChange={(e) => handelChange(e)}
                    size="small"
                    label="Name *"
                  />
                </FormControl>

                <div className="unitStock__edit_unit_modalfooter">
                  <div
                    className="unitStock__edit_unit_modalreset"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </div>
                  <div
                    className="unitStock__edit_unit_modalsubmit"
                    onClick={handleSubmit}
                  >
                    {loading ? <Loader /> : "Save"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditUnits;
