import React from "react";
import "./Requirements.css";
const IcsRequirements = () => {
  return (
    <div className="cuscarrier__reqrmnts_box">
      Contact Ics at
      <a href={`mailto: kpetrie@icscourier.ca`} className="email_space">
        kpetrie@icscourier.ca
      </a>
      to obtain your Account ID and Password. These API credentials will allow
      you to connect your custom Ics account to the application, enabling you to
      access Ics shipping rates for your account and display them as quotes.
    </div>
  );
};

export default IcsRequirements;
