import React from "react";
import "./Requirements.css";
const GlsRequirements = () => {
  return (
    <div className="cuscarrier__reqrmnts_box">
      Contact Gls at
      <a href={`mailto:tech.support@dicomexpress.com`} className="email_space">
        tech.support@dicomexpress.com
      </a>
      to obtain your Username, Password and Billing Account Number. These API
      credentials will allow you to connect your custom Gls account to the
      application, enabling you to access Gls's shipping rates for your account
      and display them as quotes.
    </div>
  );
};

export default GlsRequirements;
