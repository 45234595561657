import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IconButton from "@mui/material/IconButton";
import Tooltips from "@mui/material/Tooltip";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { IoInformationCircleOutline } from "react-icons/io5";

export default function AddressFormCard(props) {
  const { StepCheck, SetStepCheck, Type, children } = props;

  const handleform = () => {
    SetStepCheck((prevStepCheck) => ({
      ...prevStepCheck,
      fullform: !StepCheck["fullform"],
    }));
  };

  return (
    <>
      <div className="card bg-white shadow address__form_card">
        <div className="card-header">
          <Row>
            <Col md={12}>
              <div className="addressForm__main_wrap">
                <div className="addressForm__title_col">
                  {Type === "Flist" ? (
                    <b>PICKUP ADDRESS (SHIPPER)</b>
                  ) : (
                    <b>DELIVERY ADDRESS (CONSIGNEE)</b>
                  )}
                  <Tooltips
                    title={
                      Type === "Flist"
                        ? "Where are you shipping from"
                        : "Where are you shipping to"
                    }
                    placement="top"
                  >
                    <IconButton>
                      <IoInformationCircleOutline size={15} />
                    </IconButton>
                  </Tooltips>
                </div>

                <div className="addressForm__stepchecker_col fullfilment__form_expand">
                  {StepCheck["fullform"] === true ? (
                    <Tooltips
                      title="Collapse"
                      placement="top"
                      onClick={handleform}
                    >
                      <IconButton>
                        <MdOutlineKeyboardArrowUp size={25} />
                      </IconButton>
                    </Tooltips>
                  ) : (
                    <Tooltips
                      title="Expand"
                      placement="top"
                      onClick={handleform}
                    >
                      <IconButton className="fullfilment__form_expand_btn">
                        <MdOutlineKeyboardArrowDown size={25} />
                      </IconButton>
                    </Tooltips>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="card-body">{children}</div>
      </div>
    </>
  );
}
