import axios from "../axiosInstance";

// create ticket
export const createTicket = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`tickets`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

// get tickets list
export const getTickets = async (
  page = 1,
  status = "",
  keyword = "",
  ticket_no = null
) => {
  let response = { data: null, error: null };
  try {
    let res = null;
    if (ticket_no != null)
      res = await axios.get(`tickets?ticket_no=${ticket_no}`);
    else
      res = await axios.get(
        `tickets?page=${page}&status=${status}&keyword=${keyword}`
      );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const deleteTicketMedia = async (mediaId) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.delete(`ticket-responses/${mediaId}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const closeTicketService = async (ticketId) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`tickets/${ticketId}/close`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

// update ticket status
export const updateTicketStatusService = async (ticketId, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`tickets/${ticketId}/updateStatus`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

// get ticket media
export const getTicketMediaService = async (ticketId) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`tickets/${ticketId}/media`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

// Get Tickets Details
export const getTicketDetailsService = async (ticketId) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`tickets/${ticketId}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

// send reply/response
export const sendReply = async (ticketId, data) => {
  let response = { data: null, error: null };
  try {
    axios.interceptors.request.use(async (config) => {
      config.headers["Content-Type"] = "multipart/form-data";
      return config;
    });
    const res = await axios.post(`tickets/${ticketId}/sendReply`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
