import React from "react";

//componenets

// apis imports
import { checkFileType } from "../../../../helpers/helpers.js";

// library imports
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Typography from "@mui/material/Typography";
import StepLabel from "@mui/material/StepLabel";
import pdfIcon from "../../../../assets/icons/pdfIcon.png";
import excelIcon from "../../../../assets/icons/excelIcon.png";
import noImage from "../../../../assets/images/noImage.png";
import docsIcon from "../../../../assets/icons/docsIcon.png";

// can make it general
function returnAttachments(attachment, isImage = false) {
  if (!attachment) {
    return noImage;
  }
  const fileType = checkFileType(attachment);
  switch (fileType) {
    case "Image":
      return isImage ? URL.createObjectURL(isImage) : attachment;
    case "PDF":
      return pdfIcon;
    case "Excel":
      return excelIcon;
    case "Document":
      return docsIcon;
    default:
      return noImage;
  }
}

const NotesAndAttachmentsStepper = ({ notesAndAttachments, setfullview }) => {
  return (
    <div>
      <div className="sss__prodsection_heading mt-2">Note's & Attachment's</div>
      <Stepper activeStep={notesAndAttachments?.length} orientation="vertical">
        {notesAndAttachments?.map((step, index) => (
          <Step key={index}>
            <StepLabel
              optional={
                <>
                  <b style={{ fontSize: "10px" }}>
                    {step?.user?.username ?? "-"}
                  </b>
                  <Typography variant="caption" style={{ fontSize: "10px" }}>
                    {" "}
                    {"At " + step?.created_at?.slice(0, 10) ?? "-"}({" "}
                    {step?.created_at?.slice(11, 16) ?? "-"})
                  </Typography>
                </>
              }
            >
              <div style={{ display: "flex", gap: "20px" }}>
                <div style={{ maxWidth: "50%" }}>{step?.description}</div>
                <div style={{ maxWidth: "50%" }}>
                  <div className="notes__attachments_modal_attachment_thumbnail">
                    {step?.media?.map((attachment, index) => (
                      <img
                        style={{
                          height: "35px",
                          width: "35px",
                        }}
                        key={index}
                        src={returnAttachments(attachment?.file)}
                        alt={`Attachment ${index + 1}`}
                        onError={(e) => {
                          e.target.src = noImage;
                        }}
                        onClick={() => {
                          if (checkFileType(attachment.file) === "Image") {
                            setfullview(attachment?.file || "");
                          } else {
                            window.open(attachment?.file, "_blank");
                          }
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default NotesAndAttachmentsStepper;
