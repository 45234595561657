import React, { useEffect, useState } from "react";
import "./ChangePassword.css";
// components imports
import Loader from "../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import { updatePasswordByAdmin } from "../../../../../services/AdminServices/Users/users.services";
// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";

const initialData = {
  user_id: "",
  password: "",
};

export default function ChangePassword({
  open,
  setOpen,
  setSnack,
  previousData,
}) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setData(initialData);
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleUpdate = async () => {
    setLoading(true);
    const response = await updatePasswordByAdmin(data);
    setLoading(false);
    // console.log(response);
    if (!response?.data?.status || response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong!",
          severity: "error",
        };
      });
    } else {
      handleClose();
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Password Updated Successfully!",
          severity: "success",
        };
      });
    }
  };

  useEffect(() => {
    if (previousData) {
      setData({ ...data, user_id: previousData });
    }
  }, [previousData]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="admnchng__pass_modal">
          <div className="admnchng__pass_modalheading">Change Password</div>
          <div className="admnchng__pass_modalcontent">
            <div className="admnchng__pass_modalrow">
              <div className="admnchng__pass_modalinputwrap">
                <label>New Password</label>
                <input
                  className="admnchng__pass_modalinput"
                  placeholder="New Password"
                  name="password"
                  value={data.password}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="admnchng__pass_modalfooter">
            <div className="admnchng__pass_modalcncl" onClick={handleClose}>
              Cancel
            </div>
            <button
              disabled={loading}
              className={`admnchng__pass_modalsave ${
                loading && "admnchng__pass_btndisabled"
              }`}
              onClick={handleUpdate}
            >
              {loading ? <Loader /> : "Update"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
